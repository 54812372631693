import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import * as fromAdmin from '../../../stores/ducks/admin'
import * as fromColors from '../../../stores/ducks/colors'

import ColorCard from '../../common/color-card'
import Button from '../../common/button'
import AddColorModal from './add-colors-modal'
import EditColorsModal from './edit-colors-modal'
import InputSearch from '../../common/form/input-search'
import Grid from '../../common/grid/grid'
import NotificationBubble from '../../common/notification-bubble'
import AutoFill from '../../common/grid/AutoFill'
import Pill from '../../common/Pill'
import type { SearchableColor } from '../../project/colors/SearchableColor'

// Selectors
import * as fromAdminSelectors from '../../../stores/ducks/admin/selectors'
import * as fromColorsSelectors from '../../../stores/ducks/colors/selectors'

const AdminColors = () => {
  const [color, setColor] = useState({})
  const [id, setId] = useState('')
  const [name, setName] = useState('')
  const [ncsTitle, setNcsTitle] = useState('')
  const [pantoneTitle, setPantoneTitle] = useState('')
  const [fy, setFy] = useState('')
  const [colorsArray, setColorsArray] = useState<SearchableColor[] | undefined>(undefined)
  const [pages, setPages] = useState(1)
  const [colorsPage, setColorsPage] = useState(1)
  const [searchFilter, setSearchFilter] = useState<'standard' | 'fabric'>('standard')

  const dispatch = useDispatch()
  const { colors } = useSelector(fromAdminSelectors.selectAdminColors)
  const notification = useSelector(fromColorsSelectors.selectColorsNotification)
  const error = useSelector(fromColorsSelectors.selectColorsError)
  const newColorModalOpen = useSelector(fromAdminSelectors.selectAdminNewColorModalOpen)
  const editColorModalOpen = useSelector(fromAdminSelectors.selectAdminEditColorModalOpen)
  const colorSearchString = useSelector(fromAdminSelectors.selectAdminColorSearchString)

  const ITEMS_PER_PAGE = window.innerWidth > 1920 ? 160 : 120
  const standardColors = colors.filter(color => !color.isPrototypeFabricColor)
  const fabricColors = colors.filter(color => color.isPrototypeFabricColor)

  const setSearchString = (value: string) => {
    dispatch(fromAdmin.setColorSearchString(value))
  }

  useEffect(() => {
    dispatch(fromColors.getColorsAdmin())
    setSearchString('')
  }, [])

  useEffect(() => {
    if (searchFilter === 'standard') {
      const page = pages < colorsPage ? 1 : colorsPage
      setPages(Math.ceil(standardColors.length / ITEMS_PER_PAGE))
      pagination(page, standardColors, ITEMS_PER_PAGE)
    } else {
      const page = pages < colorsPage ? 1 : colorsPage
      setPages(Math.ceil(fabricColors.length / ITEMS_PER_PAGE))
      pagination(page, fabricColors, ITEMS_PER_PAGE)
    }
  }, [colors, colorsPage, searchFilter])

  function pagination (page:number, items:any, imagesPerPage:number) {
    setColorsPage(page)
    setColorsArray(items.slice((page - 1) * imagesPerPage, (page * imagesPerPage)))
  }

  function receiveNotification () {
    dispatch(fromColors.receiveNotification)
  }

  function receiveErrorNotification () {
    dispatch(fromColors.receiveErrorNotification)
  }

  return (
    <div style={{ height: 'calc(100vh - 270px)' }}>
      <NotificationBubble
        className={'mtn2'}
        notification={notification}
        onClearOrTimeout={receiveNotification}
      />
      <NotificationBubble
        className={'mtn2'}
        error={error}
        onClearOrTimeout={receiveErrorNotification}
      />
      <Grid
        columns={['1fr', 'auto', 'auto', 'auto']}
        gridGap={24}
        style={{ alignItems: 'center' }}
        className='mb2'
      >
        <InputSearch
          placeholder='Search...'
          onChange={setSearchString}
          onClearSearch={() => setSearchString('')}
          value={colorSearchString}
        />
        <div className='flex items-center'>
          <div title='IKEA Standard colors'>
            <Pill
              className='mr1'
              active={searchFilter === 'standard'}
              onClick={() => {
                setSearchFilter('standard')
                setColorsPage(1)
              }}>Standard</Pill>
          </div>
          <div title='Non-standard textile colors'>
            <Pill
              className='mr1'
              active={searchFilter === 'fabric'}
              onClick={() => {
                setSearchFilter('fabric')
                setColorsPage(1)
              }}>Non-standard textile</Pill>
          </div>
        </div>
        <Button btnType='primary' onClick={() => dispatch(fromAdmin.closeAddColorModal(true))}>Add new color</Button>
      </Grid>
      <div className='height-100 overflow-auto'>
        <AutoFill width={185}>
          {colorsArray && colorsArray.map(color => (
            <ColorCard
              {...color}
              key={color.id}
              onClick={() => {
                // Non-standard colors aren't editable
                if (!color.isPrototypeFabricColor) {
                  setColor(color)
                  setId(color.id)
                  setName(color.name)
                  setNcsTitle(color.ncsTitle)
                  setPantoneTitle(color.pantoneTitle)
                  setFy(color.fy)
                  dispatch(fromAdmin.closeEditColorModal(true))
                }
              }}
              isPrototypeFabricColor={color.isPrototypeFabricColor}
            />
          ))}
        </AutoFill>

        {pages > 1 && (
          <div className="width-100 flex flex-wrap justify-center p2">
            {[...Array(pages)].map((unused, i) =>
              <Pill style={{ margin: '2px' }} key={i} active={colorsPage === i + 1} onClick={() => { setColorsPage(i + 1) }}>{i + 1}</Pill>
            )}
          </div>
        )}
      </div>

      <AddColorModal
        isOpen={newColorModalOpen}
        onRequestClose={() => dispatch(fromAdmin.closeAddColorModal(false))}
        width={700}
      />
      {editColorModalOpen && (
        <EditColorsModal
          isOpen={editColorModalOpen}
          onRequestClose={() => dispatch(fromAdmin.closeEditColorModal(false))}
          width={700}
          id={id}
          name={name}
          ncsTitle={ncsTitle}
          pantoneTitle={pantoneTitle}
          fy={fy}
          color={color}
        />
      )}
    </div>
  )
}

export default AdminColors
