const THREE = require('three')
const Go3DShaderChunk = require('../shaders/ShaderChunk')

module.exports = `
#define STANDARD

varying vec3 vViewPosition;

varying vec3 vWorldPosition;

#ifndef FLAT_SHADED

  varying vec3 vNormal;

  #ifdef USE_TANGENT

    varying vec3 vTangent;
    varying vec3 vBitangent;

  #endif

#endif

varying mat4 vInstanceMatrix;

${THREE.ShaderChunk.common}

${Go3DShaderChunk.decal_map_pars_vertex}

${THREE.ShaderChunk.uv_pars_vertex}
${THREE.ShaderChunk.uv2_pars_vertex}
${THREE.ShaderChunk.displacementmap_pars_vertex}
${THREE.ShaderChunk.color_pars_vertex}
${THREE.ShaderChunk.fog_pars_vertex}
${THREE.ShaderChunk.morphtarget_pars_vertex}
${THREE.ShaderChunk.skinning_pars_vertex}
${THREE.ShaderChunk.shadowmap_pars_vertex}
${THREE.ShaderChunk.logdepthbuf_pars_vertex}
${THREE.ShaderChunk.clipping_planes_pars_vertex}
${Go3DShaderChunk.triplanar_map_pars_vertex}
${Go3DShaderChunk.envmap_pars_vertex}

void main() {
    ${THREE.ShaderChunk.uv_vertex}

    ${Go3DShaderChunk.decal_map_vertex}

    ${THREE.ShaderChunk.uv2_vertex}
    ${THREE.ShaderChunk.color_vertex}

    ${THREE.ShaderChunk.beginnormal_vertex}
    ${THREE.ShaderChunk.morphnormal_vertex}
    ${THREE.ShaderChunk.skinbase_vertex}
    ${THREE.ShaderChunk.skinnormal_vertex}
    ${THREE.ShaderChunk.defaultnormal_vertex}

    #ifndef FLAT_SHADED // Normal computed with derivatives when FLAT_SHADED
        vNormal = normalize( transformedNormal );
    #endif

    ${THREE.ShaderChunk.begin_vertex}
    ${THREE.ShaderChunk.morphtarget_vertex}
    ${THREE.ShaderChunk.skinning_vertex}
    ${THREE.ShaderChunk.displacementmap_vertex}
    ${THREE.ShaderChunk.project_vertex}
    ${THREE.ShaderChunk.logdepthbuf_vertex}
    ${THREE.ShaderChunk.clipping_planes_vertex}
    ${Go3DShaderChunk.triplanar_map_vertex}

    vViewPosition = - mvPosition.xyz;

    ${Go3DShaderChunk.worldpos_vertex}
    ${THREE.ShaderChunk.shadowmap_vertex}
    ${THREE.ShaderChunk.fog_vertex}
    vWorldPosition = worldPosition.xyz;

    vInstanceMatrix = instanceMatrix;
}
`
