import React, { Component } from 'react'

import Select from 'react-select'

import './index.css'

class InputSelect extends Component {
  render () {
    const reactSelectDefaultProps = this.props
    return (
      <Select
        {...reactSelectDefaultProps}
        className='react-select-container'
        classNamePrefix='react-select'
        formatGroupLabel={this.props.formatGroupLabel}
      />
    )
  }
}

export default InputSelect
