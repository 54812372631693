var _ = require('lodash')

module.exports = {
  addLocomotionHandler (params) {
    var locomotionEvent = params.event || this.TriggerClicked

    var interactionTargets = params.interactionTargets

    this.on(locomotionEvent, () => {
      var raycaster = this.getRaycaster(this)

      var floors = _(this.app)
        .chain()
        .get('objectTracker.interactions', {})
        .pick(interactionTargets)
        .reduce((result, value, key) => {
          return Object.assign(result, value)
        }, {})
        .map((val, key) => { return val })
        .value()

      var intersections = raycaster.intersectObjects(floors, true)

      if (intersections.length > 0) {
        var point = intersections[0].point
        var cameraParent = this.app.cameraParent
        var cameraWorld = this.app.camera.getWorldPosition()

        var dX = point.x - cameraWorld.x
        var dZ = point.z - cameraWorld.z

        cameraParent.translateX(dX)
        cameraParent.translateZ(dZ)
      }
    })
  }
}
