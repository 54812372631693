import React from 'react'
import { Column, Table } from 'react-virtualized'
import styles from './styles.css'
import cs from 'classnames'

const _rowClassName = (index) => {
  if (index < 0) {
    return ''
  }

  return cs(
    styles.row,
    {
      'bg-black-10': index % 2 === 0
    }
  )
}

const createTable = ({
  width, height,
  data,
  cols,
  headerTitle, noDataText,
  tableStyle
}) => {
  const textStyle = { width: width }
  const textStyleBold = { width: width, fontWeight: 'bold' }
  let _width = width
  for (const col of cols) {
    if (col.width) _width = _width - col.width
  }

  const generalColWidth = _width / (cols.length)

  if (data === undefined) return <div>Loading...</div>

  return (
    <div style={tableStyle}>
      <div style={textStyleBold} className="pt2">{headerTitle}</div>
      { data.length < 1
        ? (<div style={textStyle}>{noDataText}</div>)
        : (<Table
          ref="table-active-users"
          width={width}
          height={height || 200}
          rowGetter={({ index }) => data[index]}
          rowCount={data.length}
          headerHeight={40}
          rowHeight={30}
          rowClassName={({ index }) => _rowClassName(index)}
          gridClassName='border-left border-right bc-black-55 '
        >
          {cols.map(({ label, key }, index) => (<Column
            key={index}
            dataKey={key}
            label={label}
            width={ cols[index].width ? cols[index].width : generalColWidth}
            flexGrow={1}
            className={styles.col}
          />))}
        </Table>)}
    </div>
  )
}

export default createTable
