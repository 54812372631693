import Immutable from 'seamless-immutable'
import { createAction, handleActions } from 'redux-actions'

import _keyBy from 'lodash/keyBy'
import _get from 'lodash/get'
import _reduce from 'lodash/reduce'

import * as fromSelectors from './selectors'
import * as fromCombinations from '../combinations'
import { fetchForVisualize } from '../combinations/actions/json'
import { getChangedModels } from '../combinations/actions/get-changed-models'
import * as fromThreeviewerFiles from '../threeviewer/files'
import * as fromSelection from '../selection'
import * as fromUndoRedo from '../undo-redo'

import * as fromThreeviewerSelectors from '../threeviewer/selectors'
import * as fromCombinationSelectors from '../combinations/selectors'
import * as fromProjectSelectors from '../projects/selectors'

import DEFAULT_INTERACTIONS from '../combinations/interactions'

const status = {
  PENDING: 'PENDING',
  FETCHING: 'FETCHING',
  FAILED: 'FAILED'
}

export const receive = createAction('image-packages/RECEIVE')
export const setCurrent = createAction('image-packages/SET_CURRENT')
const fetchFailed = createAction('image-packages/FETCH_FAILED')
const fetching = createAction('image-packages/FETCHING')
const confirmRemove = createAction('image-packages/CONFIRM_RECEIVE')

export const selectBatch = (batchId) => (dispatch, getState) => {
  const state = getState()

  const viewer = fromThreeviewerSelectors.getViewer(state)
  const combination = fromCombinationSelectors.getCurrentEntry(state)
  const projectId = fromProjectSelectors.getCurrentId(state)
  const batch = fromSelectors.getSelectedBatchById(combination.imagePackageId, batchId)(state)

  const masterCombinationId = batch.masterCombinationId

  let modificationsKeyedByType = {}

  return Promise.resolve()
    .then(() => dispatch(fromCombinations.setCurrent(masterCombinationId)))
    .then(() => {
      const changedModels = dispatch(getChangedModels())
      modificationsKeyedByType = _reduce(changedModels, (acc, model) => {
        if (
          model.isTemplate ||
          !_get(model, 'parts.length') ||
          !_get(model, 'metadata.type')
        ) return acc
        return { ...acc, [model.metadata.type]: model.parts }
      }, {})
    })
    .then(() => {
      const state = getState()
      const viewer = fromThreeviewerSelectors.getViewer(state)
      const nodesToRemove = viewer.scene.children.filter(child => child.userData.isCombination)

      nodesToRemove.forEach((node) => {
        dispatch(fromCombinations.removeModel(node, false))
      })
    })
    .then(() => dispatch(fromThreeviewerFiles.load('combination', masterCombinationId)))
    .then(() => dispatch(fetchForVisualize(masterCombinationId, false)))
    .then((masterCombination) => {
      masterCombination.models = _reduce(masterCombination.models, (acc, model, id) => {
        const type = _get(model, 'metadata.type')
        let modifications = _get(modificationsKeyedByType, type)

        // BUSINESS LOGIC SPECIFIC TO CURRENT WIP OF BEDSETS
        if (!modifications && type === 'folding') {
          modifications = _get(modificationsKeyedByType, 'duvet')
        }

        if (!modifications) return { ...acc, [id]: model }
        const modification = modifications[0]

        return {
          ...acc,
          [id]: {
            ...model,
            parts: model.parts.map((part) => ({
              ...modification,
              canSetColor: !!modification.color,
              partId: part.partId
            }))
          }
        }
      }, {})

      const url = `/visualize/${projectId}/${combination.projectType}/${masterCombinationId}`

      window.history.pushState(null, null, url)
      dispatch(fromUndoRedo.dispose())
      return dispatch(fromCombinations.loadCombination({
        instanceId: masterCombinationId,
        combination: masterCombination,
        interactions: DEFAULT_INTERACTIONS,
        isComplementary: false,
        isReplacingRoomset: false
      }))
    })
    .then(() => {
      const objects = viewer.objectTracker.returnObjectsOfType('combinations')
      const uuids = objects.map((child) => (child.uuid))
      dispatch(fromSelection.selectFromUuids(uuids))
    })
    .then(() => dispatch(fromThreeviewerFiles.loaded('combination', masterCombinationId)))
}

const initialState = Immutable({
  entries: {},
  currentId: null,
  status: status.PENDING
})

export default handleActions({
  [setCurrent]: (state, { payload }) => state.merge({ currentId: payload }),
  [receive]: (state, { payload }) => state.merge({
    status: status.PENDING,
    entries: _keyBy(payload, 'id')
  }, { deep: true }),
  [confirmRemove]: (state, { payload }) => state.merge({ entries: state.entries.without(payload) }),
  [fetchFailed]: (state) => state.merge({ status: status.FAILED }),
  [fetching]: (state) => state.merge({ status: status.FETCHING })
}, initialState)
