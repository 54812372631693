import React from 'react'
import cs from 'classnames'
// @ts-ignore
import css from './index.css'

type Props = {
  htmlFor?: string
  subtle?: boolean
  inverted?: boolean
  noMargin?: boolean
  block?: boolean
  className?: string
  small?: boolean
  style?: React.CSSProperties
  children: any
}

export default function Label (props: Props) {
  return (
    <label
      htmlFor={props.htmlFor}
      className={cs({
        [css.subtleLabel]: props.subtle,
        [css.labelInverted]: props.inverted,
        [css.label]: !props.subtle && !props.inverted,
        'block mb1': props.block && !props.noMargin,
        mr1: !props.block && !props.noMargin,
        f8: props.small
      }, props.className)}
      style={props.style}
    >
      {props.children}
    </label>
  )
}

Label.defaultProps = {
  htmlFor: '',
  subtle: false,
  block: true,
  small: false,
  className: '',
  style: {}
} as Partial<Props>
