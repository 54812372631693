import React from 'react'
import PropTypes from 'prop-types'

import { FaPencilAlt as Pencil } from 'react-icons/fa'

import css from './editable-text.css'
import KeyboardListener, { KeyCode, KeyBinding } from '../keyboard-listener'

function EditableText (props) {
  const [isEditing, setIsEditing] = React.useState(false)
  const [value, setValue] = React.useState(props.defaultValue || null)
  const inputRef = React.useRef(null)

  React.useEffect(() => {
    if (props.editing) {
      setIsEditing(true)
    }

    if (isEditing && !props.editing) {
      setIsEditing(false)
    }
  }, [props.editing])

  React.useEffect(() => {
    if (isEditing) {
      handleFocus()
    }
  }, [isEditing])

  React.useEffect(() => {
    setValue(props.defaultValue)
  }, [props.defaultValue])

  function handleBlur () {
    setIsEditing(false)
    const trimmedValue = value.trim()
    if (trimmedValue !== props.defaultValue) {
      props.onChange(trimmedValue)
    }
  }

  function handleFocus () {
    if (inputRef.current) {
      inputRef.current.focus()
      inputRef.current.setSelectionRange(0, value.length)
    }
  }

  function toggleEdit () {
    if (!props.editable) return
    setIsEditing(!isEditing)
  }

  function handleEscKey () {
    setValue(props.defaultValue)
    setIsEditing(false)
  }

  if (isEditing) {
    return (
      <>
        <input
          ref={inputRef}
          tabIndex={props.tabIndex}
          type='text'
          className={`width-100 ${props.className.replace('truncate', '')} ${props.activeClassName} ${css.text}`}
          onChange={e => setValue(e.target.value)}
          onBlur={() => handleBlur()}
          value={value}
          style={props.style}
        />
        <KeyboardListener
          eventTarget={document}
          keyboardEvent='keyup'
          bindings={[
            KeyBinding(KeyCode.enter, handleBlur),
            KeyBinding(KeyCode.esc, handleEscKey)
          ]}
        />
      </>
    )
  }
  return (
    <div
      data-testid='editable-text'
      className={`relative text-cursor ${css.edit} ${props.editable ? '' : css.nohover}`}
      title={value}
      onClick={() => { props.singleClickEdit && toggleEdit() }}
      onDoubleClick={() => { !props.singleClickEdit && toggleEdit() }}
      onFocus={toggleEdit}
      tabIndex={props.tabIndex}
    >
      <Pencil className='absolute' size={props.iconSize} style={props.iconStyle} />
      {props.children(value || props.defaultValue, { className: props.className, style: props.style })}
    </div>
  )
}

EditableText.propTypes = {
  onClick: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
  activeClassName: PropTypes.string,
  editable: PropTypes.bool,
  editing: PropTypes.bool,
  children: PropTypes.func.isRequired,
  singleClickEdit: PropTypes.bool,
  tabIndex: PropTypes.number,
  className: PropTypes.string,
  style: PropTypes.object,
  iconSize: PropTypes.number,
  iconStyle: PropTypes.object
}

EditableText.defaultProps = {
  activeClassName: '',
  className: '',
  editable: true,
  singleClickEdit: false,
  tabIndex: null,
  editing: false
}

export default EditableText
