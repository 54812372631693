import Immutable from 'seamless-immutable'
import { createAction, handleActions } from 'redux-actions'

import _isEmpty from 'lodash/isEmpty'
import _keyBy from 'lodash/keyBy'

import * as fromRoomsetSelectors from './selectors'
import * as api from './api'
import { AppThunk } from '..'
import { Roomset } from './Roomset'

export const status = {
  PENDING: 'PENDING',
  FETCHING: 'FETCHING',
  FETCHED: 'FETCHED',
  FAILED: 'FAILED'
}

export const setCurrent = createAction<string | null>('roomsets/SET_CURRENT')
export const receive = createAction<Roomset[]>('roomsets/RECEIVE')
export const clearDeadlineJobs = createAction<string>('roomsets/CLEAR_DEADLINE_JOBS')
export const setPlacementCoordinate = createAction<[number, number, number]>('roomsets/SET_PLACEMENT_COORDINATE')
export const setFilter = createAction<{ key: 'tags' | 'area' | 'marketArea' | 'rooms'; value: any }>('roomsets/filters/SET')
const confirmRemove = createAction<string>('roomsets/REMOVE')
const fetching = createAction('roomsets/FETCHING')
const fetchFailed = createAction<Error>('roomsets/FETCH_FAIL')

export const fetchImageTemplatesAndSetups = (): AppThunk => async (dispatch) => {
  const json = await api.getImageTemplatesAndSetups()
  dispatch(receive(json))
}

export const fetchIfNeeded = (id?: string): AppThunk => async (dispatch, getState) => {
  const state = getState()
  const roomset = id && state.roomsets.entries.getIn([id])
  const isFetching = fromRoomsetSelectors.getIsFetching(state)

  if (isFetching || (id && !_isEmpty(roomset))) {
    return Promise.resolve(roomset)
  }

  dispatch(fetching())

  if (id) {
    try {
      const result = await api.get(id)
      dispatch(receive([result]))
      return result
    } catch (err) {
      return dispatch(fetchFailed(err))
    }
  }

  try {
    return dispatch(fetchAll())
  } catch (err) {
    return dispatch(fetchFailed(err))
  }
}

export const fetchAll = (): AppThunk<Promise<Roomset[]>> => (dispatch) => (
  api.getAll().then((result: Roomset[]) => {
    dispatch(receive(result))
    return result
  })
)

export const fetchByIds = (ids: string[]): AppThunk<Promise<Roomset[]>> => (dispatch) => (
  api.getByIds(ids).then((result: Roomset[]) => {
    dispatch(receive(result))
    return result
  })
)

export const create = (payload: Roomset): AppThunk<Promise<Roomset>> => (dispatch) => (
  api.create(payload).then((result) => {
    dispatch(receive([result]))
    return result
  })
)

export const update = (payload: Roomset): AppThunk<Promise<Roomset>> => (dispatch) => (
  api.update(payload).then((result) => {
    dispatch(receive([result]))
    return result
  })
)

export const remove = (id: string): AppThunk<Promise<Roomset>> => (dispatch) => (
  api.remove(id).then((result) => {
    dispatch(confirmRemove(id))
    return result
  })
)

const initialState = Immutable<{
  currentId: string | null
  status: 'PENDING' | 'FETCHING' | 'FETCHED' | 'FAILED'
  placementCoordinate: [number, number, number]
  entries: { [id: string]: Roomset }
  error: null | Error
  filters: {
    tags: string[],
    area: {
      min: number,
      max: number
    },
    marketArea: string[],
    rooms: {
      min: number,
      max: number
    }
  }
}>({
  currentId: null,
  error: null,
  status: 'PENDING',
  placementCoordinate: [0, 0, 0],
  entries: {},
  filters: {
    tags: [],
    area: {
      min: 10,
      max: 250
    },
    marketArea: [],
    rooms: {
      min: 1,
      max: 22
    }
  }
})

type State = typeof initialState

export default handleActions<State, any>({
  [setCurrent.toString()]: (state, { payload }: { payload: string | null }) => state.merge({
    currentId: payload
  }),
  [clearDeadlineJobs.toString()]: (state, { payload }: { payload: string }) => {
    return state.setIn(['entries', payload, 'deadlineJobs'], {})
  },
  [setPlacementCoordinate.toString()]: (state, { payload }: { payload: [number, number, number] }) => {
    return state.setIn(['placementCoordinate'], payload)
  },
  [fetching.toString()]: (state) => state.merge({ status: 'FETCHING' }),
  [fetchFailed.toString()]: (state, { payload }: { payload: Error }) => state.merge({
    status: 'FAILED',
    error: payload
  }),
  [receive.toString()]: (state, { payload }: { payload: Roomset[] }) => {
    return state.merge({
      status: 'FETCHED',
      entries: _keyBy(payload, 'id')
    }, { deep: true })
  },
  [setFilter.toString()]: (state, { payload }) => state.merge({
    filters: { [payload.key]: payload.value }
  }, { deep: true }),
  [confirmRemove.toString()]: (state, { payload }) => state.setIn(['entries'], state.entries.without(payload))
}, initialState)
