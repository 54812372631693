import { connect } from 'react-redux'
import { createSelector } from 'reselect'

import _get from 'lodash/get'

import * as fromThreeviewerFilesSelectors from '../../../../stores/ducks/threeviewer/files/selectors'
import * as fromThreeviewerUI from '../../../../stores/ducks/threeviewer/ui'
import { LOCKED_MODES } from '../../../../stores/ducks/threeviewer/camera'
import { enableKeyboardListeners, disableKeyboardListeners } from '../../../../stores/ducks/threeviewer/viewer'

const mapStateToProps = () => createSelector(
  fromThreeviewerFilesSelectors.getIsSceneLoaded,
  (state) => _get(state, 'threeviewer.ui.savedIsSafeFrameVisible'),
  (state) => _get(state, 'threeviewer.camera.mode'),
  (state) => state.threeviewer.customHome.scenePanelState,
  (isSceneLoaded, savedIsSafeFrameVisible, mode, scenePanelState) => {
    return {
      savedIsSafeFrameVisible,
      disabled: (
        !isSceneLoaded ||
        mode === LOCKED_MODES.FLOORPLAN ||
        scenePanelState === 'CUSTOM_HOME'
      )
    }
  }
)

const mapDispatchToProps = (dispatch, ownProps) => ({
  enableKeyBoardBindings () {
    if (ownProps.disabled) return
    dispatch(fromThreeviewerUI.setKeyBoardBindings(true))
    dispatch(enableKeyboardListeners())
  },
  disableKeyBoardBindings () {
    if (ownProps.disabled) return
    dispatch(fromThreeviewerUI.setKeyBoardBindings(false))
    dispatch(disableKeyboardListeners())
  },
  showSafeFrame () { dispatch(fromThreeviewerUI.setSafeFrameVisibility(true)) },
  setSafeFrameVisibility (visibility) { dispatch(fromThreeviewerUI.setSafeFrameVisibility(visibility)) }
})

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  resetSafeFrameVisibility: () => dispatchProps.setSafeFrameVisibility(stateProps.savedIsSafeFrameVisible)
})

export default (type) => connect(
  mapStateToProps(type),
  mapDispatchToProps,
  mergeProps
)
