import React from 'react'

const IconFolderOpen = props => (
  <svg width={props.size} height={props.size} viewBox='0 0 20 20' {...props}>
    <path
      fill={props.color}
      d='M15.7282366,9.11160714 C15.7282366,9.28459908 15.6417419,9.46874902 15.46875,9.6640625 L12.65625,12.9787946 C12.4162934,13.2633943 12.0800803,13.5047423 11.6476004,13.702846 C11.2151206,13.9009497 10.814734,14 10.4464286,14 L1.33928571,14 C1.14955262,14 0.980748507,13.963728 0.832868304,13.891183 C0.6849881,13.818638 0.611049107,13.6986616 0.611049107,13.53125 C0.611049107,13.3582581 0.697543778,13.1741081 0.870535714,12.9787946 L3.68303571,9.6640625 C3.92299227,9.37946286 4.25920543,9.13811483 4.69168527,8.94001116 C5.12416511,8.74190749 5.52455173,8.64285714 5.89285714,8.64285714 L15,8.64285714 C15.1897331,8.64285714 15.3585372,8.6791291 15.5064174,8.75167411 C15.6542976,8.82421911 15.7282366,8.94419559 15.7282366,9.11160714 Z M12.8571429,6.23214286 L12.8571429,7.57142857 L5.89285714,7.57142857 C5.36830095,7.57142857 4.81864127,7.70396073 4.24386161,7.96902902 C3.66908195,8.23409731 3.21149724,8.56752031 2.87109375,8.96930804 L0.0502232143,12.2840402 L0.00837053571,12.3342634 C0.00837053571,12.3119419 0.00697546038,12.277065 0.00418526786,12.2296317 C0.00139507533,12.1821984 0,12.1473215 0,12.125 L0,4.08928571 C0,3.57589029 0.184149944,3.13504648 0.552455357,2.76674107 C0.92076077,2.39843566 1.36160458,2.21428571 1.875,2.21428571 L4.55357143,2.21428571 C5.06696685,2.21428571 5.50781066,2.39843566 5.87611607,2.76674107 C6.24442148,3.13504648 6.42857143,3.57589029 6.42857143,4.08928571 L6.42857143,4.35714286 L10.9821429,4.35714286 C11.4955383,4.35714286 11.9363821,4.5412928 12.3046875,4.90959821 C12.6729929,5.27790363 12.8571429,5.71874743 12.8571429,6.23214286 Z'
    />
  </svg>
)

IconFolderOpen.defaultProps = {
  color: 'currentcolor',
  size: '1em'
}

export default IconFolderOpen
