import React from 'react'
import { useSelector } from 'react-redux'

import zIndexHandler from '../../enhancers/zindex'

// Icons
import IconMaterial from '../../common/icons/icon-material'
import IconCreateImage from '../../common/icons/icon-create-image'
import { MdCenterFocusStrong as IconImageTemplate, MdTexture as IconCarrier } from 'react-icons/md'

import PanelContainer from '../../common/panels/panel-container'
import PanelButton from '../../common/panels/panel-button'

// Panels
import MaterialAndColorPanel from './material-and-color/material-and-color-panel'
import Carriers from './carriers/CarrierPanel'
import ImageTemplatesPanel from './image-templates'

// Buttons
import { ModalButton } from '../../common/modal'

import CreateRender from './buttons/create-render'
import CreateImagePackage from './buttons/create-image-package'

import connectSave from './buttons/connect-save'

import { getAnnotationsActive, getIsFeatureActive } from '../../../stores/ducks/threeviewer/selectors'

import { PROJECT_TYPES, FEATURES } from '../../../constants'

const CreateImageButton = connectSave('render')((props) => (
  <ModalButton
    small
    modalContent={(closeModal) => {
      if (props.projectType === PROJECT_TYPES.IMAGE_PACKAGE) {
        return <CreateImagePackage onClose={(params) => {
          props.enableKeyBoardBindings()
          closeModal(params)
        }} />
      }
      return <CreateRender
        onClose={(params) => {
          props.resetSafeFrameVisibility()
          props.enableKeyBoardBindings()
          closeModal(params)
        }} />
    }}
    button={(openModal) => (
      <PanelButton
        inverted
        onClick={(params) => {
          props.showSafeFrame()
          props.disableKeyBoardBindings()
          openModal(params)
        }}
        disabled={props.disabled}
        icon={(<IconCreateImage width={28} height={28} />)}
        title={(props.projectType === PROJECT_TYPES.IMAGE_PACKAGE && props.isMaster) ? 'Create Images' : 'Create Image'}
      />
    )}
    onModalClose={() => {
      props.enableKeyBoardBindings()
      props.resetSafeFrameVisibility()
    }}
  />
))

const RightPanel = ({ isTop, setAsTop, defaultIndex, projectType, isMaster }) => {
  const isFeatureActive = (feature) => useSelector(state => getIsFeatureActive(state)(feature, true))
  const isMasterScene = (isMaster && projectType === PROJECT_TYPES.IMAGE_PACKAGE)
  const annotationsActive = useSelector(state => getAnnotationsActive(state))
  const isColorStrengthOpen = useSelector(state => state.colors.colorStrengthOpen)

  const tabs = [
    {
      icon: (<IconMaterial width={26} height={26} />),
      title: 'Appearance',
      content: <MaterialAndColorPanel height={500} />,
      shouldDisplay: ({ projectType }) => projectType === PROJECT_TYPES.DEFAULT || isMasterScene,
      isDisabled: annotationsActive
    },
    {
      icon: (<IconImageTemplate size={26} />),
      title: 'Image Templates',
      keepMounted: true,
      content: <ImageTemplatesPanel />,
      defaultWidth: 250,
      shouldDisplay: ({ projectType }) => isFeatureActive(FEATURES.IMAGE_TEMPLATES) && projectType === PROJECT_TYPES.DEFAULT,
      isDisabled: annotationsActive
    },
    {
      icon: (<IconCarrier size='26' />),
      title: 'Materials',
      content: <Carriers height={500} />,
      shouldDisplay: ({ projectType }) => isFeatureActive(FEATURES.PRODUCT_CONCEPT_ANALYSIS) && (projectType === PROJECT_TYPES.DEFAULT || isMasterScene),
      isDisabled: annotationsActive
    }
  ]

  const filteredTabs = tabs
    .filter(tab => tab.shouldDisplay({ projectType, annotationsActive }))
    .map(tab => {
      tab.disabled = tab.isDisabled
      return tab
    })

  return (
    <PanelContainer
      className={isTop ? 'z3' : 'z2'}
      defaultIndex={defaultIndex}
      defaultWidth={projectType === PROJECT_TYPES.IMAGE_PACKAGE ? 380 : 400}
      minWidth={isColorStrengthOpen ? 380 : 300}
      maxWidth={500}
      onClick={setAsTop}
      position='right'
      tabs={filteredTabs}
    >
      <CreateImageButton projectType={projectType} isMaster={isMaster} />

    </PanelContainer>
  )
}

export default zIndexHandler(RightPanel)
