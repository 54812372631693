import React from 'react'
import cs from 'classnames'
// @ts-ignore
import css from './button.css'

type ButtonProps = React.HTMLAttributes<HTMLButtonElement> & {
  pad?: false | 0 | 1 | 2 | 3 | 4
  btnType?: 'primary' | 'secondary' | false
  size?: 'small' | 'normal' | false
  uppercase?: boolean
  disabled?: boolean
  inverted?: boolean
  icon?: React.ReactNode
  children?: React.ReactNode
}

const getClassName = ({
  pad,
  btnType,
  size,
  inverted,
  disabled,
  uppercase,
  onlyIcon
}: { onlyIcon: boolean } & Pick<ButtonProps, 'pad' | 'btnType' | 'size' | 'inverted' | 'disabled' | 'uppercase'>) => {
  const base = cs(
    css.base,
    'flex',
    'items-center',
    'ease-in-out',
    'bold',
    'pointer',
    'br-big',
    {
      uppercase: uppercase,
      [css['pad' + pad]]: pad && size !== 'small' && !onlyIcon,
      [css['onlyIconPad' + pad]]: pad && onlyIcon,
      [`${css.pad1} f8`]: size === 'small',
      f7: size !== 'small'
    }
  )

  const normal = !btnType
  const filled = btnType && !inverted

  if (disabled) {
    return `${base} pointer-disabled bg-gray-light c-gray border bc-gray-light`
  }

  return cs(base, 'border', {
    'bg-primary': btnType === 'primary' && !inverted,
    'bc-primary': btnType === 'primary',
    'bg-secondary c-white fill-white': btnType === 'secondary' && !inverted,
    [`bc-secondary ${css.secondaryFocus}`]: btnType === 'secondary',
    'bc-gray bc-black-hover': normal && !inverted && !onlyIcon,
    'border-none': normal && !inverted && onlyIcon,
    'bc-white c-white': normal && inverted,
    'bg-white': (normal && !inverted && !onlyIcon) || (btnType && inverted),
    'bg-gray-light-hover': normal || (btnType && inverted),
    'c-black-hover bc-gray-light-hover': normal && inverted,
    [css.filled]: filled,
    [css.inverted]: inverted
  })
}

function Button ({
  pad = 2,
  btnType,
  size = 'normal',
  uppercase,
  disabled,
  inverted,
  icon,
  children,
  className = '',
  style = {},
  ...props
}: ButtonProps) {
  const buttonRef = React.useRef<HTMLButtonElement | null>(null)
  return (
    <button {...props}
      ref={buttonRef}
      className={cs(
        getClassName({
          pad,
          btnType,
          size,
          uppercase,
          disabled,
          inverted,
          onlyIcon: Boolean(icon && !children)
        }),
        className
      )}
      style={style}
    >
      {icon}
      {children && (
        <div className='height-100 flex items-center nowrap'>
          {children}
        </div>
      )}
    </button>
  )
}

export default Button
