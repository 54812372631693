import Immutable from 'seamless-immutable'
import { createAction, handleActions } from 'redux-actions'

import _keyBy from 'lodash/keyBy'
import _map from 'lodash/map'

import * as fromUploads from '../uploads'

export const receiveArticle = createAction('news/RECEIVE_ARTICLE')
const receiveArticles = createAction('news/RECEIVE_ARTICLES')
export const removeArticle = createAction('news/REMOVE_ARTICLE')
const receiveAdminArticles = createAction('news/RECEIVE_ADMIN_ARTICLES')
const receiveAdminArticle = createAction('news/RECEIVE_ADMIN_ARTICLE')

export const fetchAllPublished = () => (dispatch, getState, { api }) =>
  api.news.getAllPublished().then(result => {
    dispatch(receiveArticles(result))
    return result
  })

export const fetchAll = () => (dispatch, getState, { api }) =>
  api.news.getAll().then(result => {
    dispatch(receiveAdminArticles(result))
    return result
  })

export const createAdminArticle = ({
  title,
  popupTitle,
  popupBody,
  content,
  publish
}) => (dispatch, getState, { api }) => {
  api.news
    .createArticle({ title, popupTitle, popupBody, content, publish })
    .then(result => {
      dispatch(receiveAdminArticle(result))
      return result
    })
}

export const updateAdminArticle = (
  id,
  { title, publish, popupTitle, popupBody, remove }
) => (dispatch, getState, { api }) => {
  api.news
    .updateArticle(id, { title, publish, popupTitle, popupBody, remove })
    .then(result => {
      dispatch(receiveAdminArticle(result))
      return result
    })
}

export const addAdminContent = (id, type, body) => (
  dispatch,
  getState,
  { api }
) => {
  api.news.addContent(id, type, body).then(result => {
    dispatch(receiveAdminArticle(result))
    return result
  })
}

export const updateAdminContent = (id, contentId, { body }) => (
  dispatch,
  getState,
  { api }
) => {
  api.news.updateContent(id, contentId, { body }).then(result => {
    dispatch(receiveAdminArticle(result))
    return result
  })
}

export const uploadImageContent = (files) => (dispatch) => {
  return dispatch(fromUploads.uploadToStorageApi(files, {}))
    .then((manifest) => {
      return manifest
    })
}

export const removeAdminContent = (id, contentId) => (
  dispatch,
  getState,
  { api }
) => {
  api.news.deleteContent(id, contentId).then(result => {
    dispatch(receiveAdminArticle(result))
    return result
  })
}

const initialState = Immutable({
  articles: {},
  adminArticles: {}
})

const fixArticleDates = (article) => {
  if (article.createdAt) {
    article.createdAt = new Date(article.createdAt)
  }

  if (article.publishedAt) {
    article.publishedAt = new Date(article.publishedAt)
  }

  if (article.updatedAt) {
    article.updatedAt = new Date(article.updatedAt)
  }
  return article
}

export default handleActions(
  {
    [receiveArticle]: (state, { payload }) =>
      state.merge(
        {
          articles: {
            [payload.id]: fixArticleDates(payload)
          }
        },
        { deep: true }
      ),
    [receiveAdminArticle]: (state, { payload }) =>
      state.merge(
        {
          adminArticles: {
            [payload.id]: fixArticleDates(payload)
          }
        },
        { deep: true }
      ),
    [receiveArticles]: (state, { payload }) =>
      state.merge(
        {
          articles: _keyBy(_map(payload, fixArticleDates), 'id')
        },
        { deep: true }
      ),
    [receiveAdminArticles]: (state, { payload }) =>
      state.merge(
        {
          adminArticles: _keyBy([].concat(_map(payload, fixArticleDates)), 'id')
        },
        { deep: true }
      ),
    [removeArticle]: (state, { payload }) =>
      state.merge({
        articles: state.articles.without(payload)
      })
  },
  initialState
)
