import React from 'react'

const IconRemoveSwatch = props => (
  <svg width="1em" height="1em" viewBox="0 0 10 10" {...props}>
    <g fill="none" stroke={props.color} strokeWidth={props.strokeWidth}>
      <line x1="2" x2="8" y1="8" y2="2" />
      <circle cx="5" cy="5" r="4.4" />
    </g>
  </svg>
)

IconRemoveSwatch.defaultProps = {
  color: 'currentcolor',
  strokeWidth: 1
}

export default IconRemoveSwatch
