import { BufferAttribute, BufferGeometry, Mesh, Vector2, PlaneBufferGeometry, MeshBasicMaterial, Object3D, CircleBufferGeometry, Box3 } from 'three'

const BLUE_TRANSPARENT_MATERIAL = new MeshBasicMaterial({ color: 0x0000ff, transparent: true, opacity: 0.3 })
const BLUE_MATERIAL = new MeshBasicMaterial({ color: 0x0000ff })
const RED_MATERIAL = new MeshBasicMaterial({ color: 0xff0000 })
const GREEN_MATERIAL = new MeshBasicMaterial({ color: 0x00ff00 })
const GREEN_TRANSPARENT_MATERIAL = new MeshBasicMaterial({ color: 0x00ff00, transparent: true, opacity: 0.3 })
const BLACK_MATERIAL = new MeshBasicMaterial({ color: 0x000000 })
const WHITE_MATERIAL = new MeshBasicMaterial({ color: 0xffffff })
const RED_TRANSPARENT_MATERIAL = new MeshBasicMaterial({ color: 0xff0000, transparent: true, opacity: 0.3 })
export const FLOOR_MATERIAL = new MeshBasicMaterial({ color: 0x334477 })

const CORNER_HOVER_GEOMETRY = new CircleBufferGeometry(0.3, 16)
const CORNER_GEOMETRY = new CircleBufferGeometry(0.1)
const CORNER_SELECTED_GEOMETRY = new CircleBufferGeometry(0.1)

const WALL_HOVER_GEOMETRY = new PlaneBufferGeometry(1, 0.3)
const WALL_GEOMETRY = new PlaneBufferGeometry(1, 0.1)
const WALL_SELECTED_GEOMETRY = new PlaneBufferGeometry(1, 0.1)

const DOOR_OUTLINE_GEOMETRY = new PlaneBufferGeometry(1.1, 0.2)
const DOOR_GEOMETRY = new PlaneBufferGeometry(1, 0.1)

const WINDOW_OUTLINE_GEOMETRY = new PlaneBufferGeometry(1.1, 0.4)
const WINDOW_BOTTOM_GEOMETRY = new PlaneBufferGeometry(1, 0.3)
const WINDOW_MIDDLE_GEOMETRY = new PlaneBufferGeometry(0.8, 0.2)
const WINDOW_TOP_GEOMETRY = new PlaneBufferGeometry(0.8, 0.03)

export function mod (n: number, m: number): number {
  return ((n % m) + m) % m
}

export function setObjectOutline (object: Object3D, outlined: boolean) {
  let changed = false
  object.traverse((child) => {
    if (child.userData.roomEditorOutline && child.visible !== outlined) {
      changed = true
      child.visible = outlined
    }
  })
  return changed
}

export function setObjectOutlineError (object: Object3D, outlined: boolean) {
  let changed = false
  object.traverse((child) => {
    if (child.userData.roomEditorOutlineError && child.visible !== outlined) {
      changed = true
      child.visible = outlined
    }
  })
  return changed
}
export function setObjectHover (object: Object3D, hovered: boolean) {
  let changed = false
  object.traverse(child => {
    if (child.userData.roomEditorHover && child.visible !== hovered) {
      changed = true
      child.visible = hovered
    }
  })
  return changed
}
export function createCustomMesh (
  positions: Float32Array,
  indices?: Uint32Array,
  normals?: Float32Array,
  uv0?: Float32Array,
  uv1?: Float32Array
) {
  const geometry = new BufferGeometry()
  geometry.setAttribute('position', new BufferAttribute(positions, 3))
  if (normals) geometry.setAttribute('normal', new BufferAttribute(normals, 3))
  if (indices) geometry.setIndex(new BufferAttribute(indices, 1))
  return new Mesh(geometry)
}
export function createDrawingPointer () {
  const object = new Object3D()

  const layer0 = new Mesh(
    new CircleBufferGeometry(0.16, 16),
    BLACK_MATERIAL
  )
  const layer1 = new Mesh(
    new CircleBufferGeometry(0.1, 16),
    WHITE_MATERIAL
  )

  object.add(layer0)
  object.add(layer1)

  layer0.visible = true
  object.rotation.x = Math.PI / -2

  return object
}

export function createWallNode () {
  const object = new Object3D()

  const layer0 = new Mesh(
    CORNER_HOVER_GEOMETRY,
    BLUE_TRANSPARENT_MATERIAL
  )
  const layer1 = new Mesh(
    CORNER_GEOMETRY,
    BLUE_MATERIAL
  )
  const layer2 = new Mesh(
    CORNER_SELECTED_GEOMETRY,
    GREEN_MATERIAL
  )

  const userData = { roomEditorObjectType: 'wallNode', sortOrder: 0 }
  object.userData = { ...userData, isRootNode: true }
  layer0.userData = { ...userData, roomEditorHover: true }
  layer1.userData = userData
  layer2.userData = { ...userData, roomEditorOutline: true }

  object.add(layer0)
  object.add(layer1)
  object.add(layer2)

  layer0.visible = false
  layer2.visible = false
  object.rotation.x = Math.PI / -2

  return object
}

export function createWall () {
  const object = new Object3D()

  const layers = [
    new Mesh(
      WALL_HOVER_GEOMETRY,
      GREEN_TRANSPARENT_MATERIAL
    ),
    new Mesh(
      WALL_HOVER_GEOMETRY,
      RED_TRANSPARENT_MATERIAL
    ),
    new Mesh(
      WALL_GEOMETRY,
      BLACK_MATERIAL
    ),
    new Mesh(
      WALL_SELECTED_GEOMETRY,
      GREEN_MATERIAL
    )
  ]

  const userData = { roomEditorObjectType: 'wall', sortOrder: 2 }
  object.userData = { ...userData, isRootNode: true }
  layers.forEach((layer, index) => {
    layer.userData = userData

    if (index === 0) {
      layer.userData = { ...userData, roomEditorHover: true }
      layer.visible = false
    }

    if (index === 1) {
      layer.userData = { ...userData, roomEditorOutlineError: true }
      layer.visible = false
    }

    if (index === layers.length - 1) {
      layer.userData = { ...userData, roomEditorOutline: true }
      layer.visible = false
    }

    object.add(layer)
  })

  object.rotation.x = Math.PI / -2

  const box = new Box3()
  layers[0].geometry.computeBoundingBox()
  if (layers[0].geometry.boundingBox) {
    object.userData.boundingBox = box.copy(layers[0].geometry.boundingBox)
  }

  return object
}

export function createDoor () {
  const object = new Object3D()

  const layers = [
    new Mesh(
      DOOR_OUTLINE_GEOMETRY,
      GREEN_MATERIAL
    ),
    new Mesh(
      WINDOW_OUTLINE_GEOMETRY,
      RED_MATERIAL
    ),
    new Mesh(
      DOOR_GEOMETRY,
      FLOOR_MATERIAL
    )
  ]

  const userData = { roomEditorObjectType: 'door', sortOrder: 1 }
  object.userData = { ...userData, isRootNode: true }

  layers.forEach((layer, index) => {
    layer.userData = userData
    if (index === 0) {
      layer.userData = { ...userData, roomEditorOutline: true }
      layer.visible = false
    }
    if (index === 1) {
      layer.userData = { ...userData, roomEditorOutlineError: true }
      layer.visible = false
    }
    object.add(layer)
  })

  object.rotation.x = Math.PI / -2
  return object
}

export function createWindow () {
  const object = new Object3D()

  const layers = [
    new Mesh(
      WINDOW_OUTLINE_GEOMETRY,
      GREEN_MATERIAL
    ),
    new Mesh(
      WINDOW_OUTLINE_GEOMETRY,
      RED_MATERIAL
    ),
    new Mesh(
      WINDOW_BOTTOM_GEOMETRY,
      BLACK_MATERIAL
    ),
    new Mesh(
      WINDOW_MIDDLE_GEOMETRY,
      WHITE_MATERIAL
    ),
    new Mesh(
      WINDOW_TOP_GEOMETRY,
      BLACK_MATERIAL
    )
  ]

  const userData = { roomEditorObjectType: 'window', sortOrder: 1 }
  object.userData = { ...userData, isRootNode: true }

  layers.forEach((layer, index) => {
    layer.userData = userData

    if (index === 0) {
      layer.userData = { ...userData, roomEditorOutline: true }
      layer.visible = false
    }

    if (index === 1) {
      layer.userData = { ...userData, roomEditorOutlineError: true }
      layer.visible = false
    }
    object.add(layer)
  })

  object.rotation.x = Math.PI / -2
  return object
}

export function getSignedArea (points: Vector2[]) {
  let signedArea = 0
  for (let index = 0; index < points.length; index++) {
    const point = points[index]
    const x1 = point.x
    const y1 = point.y
    let x2 = 0
    let y2 = 0
    if (index === points.length - 1) {
      x2 = points[0].x
      y2 = points[0].y
    } else {
      x2 = points[index + 1].x
      y2 = points[index + 1].y
    }
    signedArea += (x1 * y2 - x2 * y1)
  }
  return signedArea / 2
}

export function flipBufferGeometryNormals (geometry: BufferGeometry) {
  let temp
  const indices = Array.from(geometry.index!.array)
  for (let i = 0; i < indices.length; i += 3) {
    // swap the first and third values
    temp = indices[i]
    indices[i] = indices[i + 2]
    indices[i + 2] = temp
  }
  geometry.setIndex(new BufferAttribute(new Uint32Array(indices), 1))

  const normals = Array.from(geometry.attributes.normal!.array)
  for (let i = 0; i < normals.length; i += 1) {
    normals[i] = -1 * normals[i]
  }
  geometry.setAttribute('normal', new BufferAttribute(new Float32Array(normals), 3))
}
