import React from 'react'
import css from './default-scene.css'
import { createSelector } from 'reselect'

import { connect } from 'react-redux'
import * as fromThreeviewerUI from '../../../../stores/ducks/threeviewer/ui'
import * as fromThreeviewerSelectors from '../../../../stores/ducks/threeviewer/selectors'

class DefaultScene extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      svgSettings: {
        width: 268,
        height: 180,
        skirtingHeight: 0.1,
        skirtingDepth: 0.02,
        sideX: 0.8,
        origoX: 0.5,
        origoY: 0.6,
        dashedPattern: '4, 4'
      }
    }
  }

  toggle (id) {
    this.props.toggleVisibility(id)
  }

  getLineCss (disabled) {
    return disabled ? `${css.line} ${css.disabled}` : `${css.line}`
  }

  render () {
    const {
      width,
      height,
      skirtingHeight,
      skirtingDepth,
      dashedPattern,
      origoX,
      origoY,
      sideX
    } = this.state.svgSettings

    const {
      wallLeft,
      wallRight,
      skirtingLeft,
      skirtingRight,
      floorBox
    } = this.props.components

    // Dynamic positions and measurements
    const skirtingSideLower = sideX + skirtingHeight
    const skirtingMiddleLower = origoY + skirtingHeight
    const skirtingFrontSideUpper = sideX + skirtingDepth
    const skirtingFrontMiddleUpper = origoY + skirtingDepth
    const skirtingFrontSideLower = skirtingSideLower + skirtingDepth
    const skirtingFrontMiddleLower = skirtingMiddleLower + skirtingDepth

    // vertical line that separates walls
    const midLineDisabled = (!wallLeft.active && !wallRight.active) || this.props.disabled
    const midLineYpos = (skirtingLeft.active || skirtingRight.active) && !midLineDisabled ? skirtingFrontMiddleLower : skirtingMiddleLower
    const lineMiddle = <line
      className={this.getLineCss(midLineDisabled)}
      x1={origoX * width}
      y1={0}
      x2={origoX * width}
      y2={midLineYpos * height}
    />

    // diagonal line that separates left wall and floor
    const leftLineDisabled = skirtingLeft.active || (!wallLeft.active && !floorBox.active) || this.props.disabled
    const lineLeft = <line
      className={this.getLineCss(leftLineDisabled)}
      x1={0}
      y1={skirtingSideLower * height}
      x2={origoX * width}
      y2={skirtingMiddleLower * height}
    />

    // diagonal line that separates right wall and floor
    const rightLineDisabled = skirtingRight.active || (!wallRight.active && !floorBox.active) || this.props.disabled
    const lineRight = <line
      className={this.getLineCss(rightLineDisabled)}
      x1={origoX * width}
      y1={skirtingMiddleLower * height}
      x2={width}
      y2={skirtingSideLower * height}
    />

    // lines that make up left skirting
    const skirtingClassLeft = skirtingLeft.active ? css.line : css.dashedLine
    const dashedLeft = skirtingLeft.active ? 'none' : dashedPattern
    const skirtingLineLeft = <line
      className={skirtingClassLeft}
      x1={0}
      y1={sideX * height}
      x2={origoX * width}
      y2={origoY * height}
      strokeDasharray={dashedLeft}
    />
    const skirtingFrontLeft = <line
      className={skirtingClassLeft}
      x1={0}
      y1={skirtingFrontSideUpper * height}
      x2={origoX * width}
      y2={skirtingFrontMiddleUpper * height}
      strokeDasharray={dashedLeft}
    />
    const skirtingFrontLowerLeft = <line
      className={skirtingClassLeft}
      x1={0}
      y1={skirtingFrontSideLower * height}
      x2={origoX * width}
      y2={skirtingFrontMiddleLower * height}
      strokeDasharray={dashedLeft}
    />

    // lines that make up right skirting
    const skirtingClassRight = skirtingRight.active ? css.line : css.dashedLine
    const dashedRight = skirtingRight.active ? 'none' : dashedPattern
    const skirtingLineRight = <line
      className={skirtingClassRight}
      x1={origoX * width}
      y1={origoY * height}
      x2={width}
      y2={sideX * height}
      strokeDasharray={dashedRight}
    />
    const skirtingFrontRight = <line
      className={skirtingClassRight}
      x1={origoX * width}
      y1={skirtingFrontMiddleUpper * height}
      x2={width}
      y2={skirtingFrontSideUpper * height}
      strokeDasharray={dashedRight}
    />
    const skirtingFrontLowerRight = <line
      className={skirtingClassRight}
      x1={origoX * width}
      y1={skirtingFrontMiddleLower * height}
      x2={width}
      y2={skirtingFrontSideLower * height}
      strokeDasharray={dashedRight}
    />

    // clickable floor area
    const floorArea = <polygon className={`${css.polygon} ${floorBox.active && css.selected}`}
      points={[
        `0,${height} 0,${skirtingSideLower * height}`,
        `${origoX * width},${skirtingMiddleLower * height}`,
        `${width},${skirtingSideLower * height}`,
        `${width},${height}`
      ].join(' ')}
      onClick={() => this.toggle('floorBox')}
    />

    // clickable wall area - left
    const wallLeftArea = <polygon className={`${css.polygon} ${wallLeft.active && css.selected}`}
      points={[
        '0,0',
        `${origoX * width},0`,
        `${origoX * width},${skirtingMiddleLower * height}`,
        `0,${skirtingSideLower * height}`
      ].join(' ')}
      onClick={() => this.toggle('wallLeft')}
    />

    // clickable wall area - right
    const wallRightArea = <polygon className={`${css.polygon} ${wallRight.active && css.selected}`}
      points={[
        `${origoX * width},0`,
        `${width},0`,
        `${width},${height * skirtingSideLower}`,
        `${origoX * width},${skirtingMiddleLower * height}`
      ].join(' ')}
      onClick={() => this.toggle('wallRight')}
    />

    // clickable skirting area - left
    const skirtingLeftArea = <polygon className={`${css.polygon} ${skirtingLeft.active && wallLeft.active && css.selected}`}
      points={[
        `0,${sideX * height}`,
        `${origoX * width},${origoY * height}`,
        `${origoX * width},${skirtingFrontMiddleLower * height}`,
        `0,${skirtingFrontSideLower * height}`
      ].join(' ')}
      onClick={() => this.toggle('skirtingLeft')}
    />

    // clickable skirting area - left
    const skirtingRightArea = <polygon className={`${css.polygon} ${skirtingRight.active && wallRight.active && css.selected}`}
      points={[
        `${origoX * width},${skirtingFrontMiddleLower * height}`,
        `${origoX * width},${origoY * height}`,
        `${width},${sideX * height}`,
        `${width},${skirtingFrontSideLower * height}`

      ].join(' ')}
      onClick={() => this.toggle('skirtingRight')}
    />

    if (this.props.disabled) {
      return (
        <svg
          className={`${css.svgDisabled} width-100`}
          viewBox={`0 0 ${width} ${height}`}
          preserveAspectRatio='none'
          style={{ height }}
        >
          {lineMiddle}
          {lineLeft}
          {lineRight}
        </svg>
      )
    }

    return (
      <svg
        className={`${css.svg} width-100`}
        viewBox={`0 0 ${width} ${height}`}
        preserveAspectRatio='none'
        style={{ height }}
      >
        {floorArea}
        {wallLeftArea}
        {wallRightArea}
        {wallLeft.active && skirtingLeftArea}
        {wallRight.active && skirtingRightArea}

        {lineMiddle}
        {!skirtingLeft.active && lineLeft}
        {!skirtingRight.active && lineRight}

        {wallLeft.active && skirtingLineLeft}
        {wallLeft.active && skirtingFrontLeft}
        {wallLeft.active && skirtingFrontLowerLeft}

        {wallRight.active && skirtingLineRight}
        {wallRight.active && skirtingFrontRight}
        {wallRight.active && skirtingFrontLowerRight}
      </svg>
    )
  }
}

const mapStateToProps = createSelector(
  fromThreeviewerSelectors.getComponents,
  (components) => ({ components })
)

const mapDispatchToProps = (dispatch) => ({
  toggleVisibility: (id) => dispatch(fromThreeviewerUI.toggleTemplateModelVisibility(id))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DefaultScene)
