import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _xor from 'lodash/xor'
import { SCENE_TAGS as tags } from '../../../../constants'
import { setTempTags, setTempTitle } from '../../../../stores/ducks/scenes'
import { getTempTags } from '../../../../stores/ducks/scenes/selectors'
import InputGroup from '../../../common/form/input-group'
import InputText from '../../../common/form/input-text'
import Label from '../../../common/form/label'
import Grid from '../../../common/grid/grid'
import { MultiSelectGroup } from './MultiSelectGroup'
import NestedMultiSelect from './NestedMultiSelect'

type Props = {
  errorMsg?: string
  selectedTags: number[]
  title: string
  readOnly: boolean
  isUpdated?: boolean
  passLocalTitle?: (title: string) => void
}

export const General = ({ errorMsg, selectedTags, title, readOnly, isUpdated, passLocalTitle }: Props) => {
  const dispatch = useDispatch()
  const tempTags = useSelector(getTempTags)
  const [localTitle, setLocalTitle] = useState<null | string>(null)

  const required = 'Required'
  const scrollRef = useRef<HTMLInputElement>(null)

  const scrollToBottom = () => {
    if (scrollRef.current) scrollRef.current.scrollTo({ top: 1000, behavior: 'smooth' })
  }

  const showRequiredTitle = () => !!((errorMsg && localTitle !== null && localTitle.length < 1))

  useEffect(() => { if (passLocalTitle && localTitle) passLocalTitle(localTitle) }, [localTitle])

  const shareSceneGlobally = readOnly && isUpdated === undefined
  const editContentGlobal = !readOnly && !isUpdated

  return (
    <div ref={scrollRef} className='overflow-x-hidden overflow-y-auto custom-scrollbar height-100'>
      <Grid rows={['auto', 'auto', 'auto', 'auto']} className='ml2 mr2'>
        {shareSceneGlobally && <span className='c-gray-dark mt1 italic'>When a scene has been saved as global this can not be undone</span>}
        {editContentGlobal && <span className='c-gray-dark mt1 italic'>When saving changes to this scene it will effect all users</span>}
        <InputGroup className='width-40'>
          <Label className='flex justify-between'>
            <span>Title</span>
            {showRequiredTitle() && <span className='c-error regular'>{required}</span>}
          </Label>
          <InputText
            focus={false}
            value={localTitle === null ? title : localTitle}
            onChange={(input: string) => {
              setLocalTitle(input.length >= 0 ? input : '')
            }}
            onBlur={(event: React.FocusEvent<HTMLInputElement>) => dispatch(setTempTitle({ title: event.target.value }))}
            disabled={readOnly}
          />
        </InputGroup>
        <div className='width-100 flex'>
          <div className='width-40 mr2'>
            <NestedMultiSelect
              label={'Regions'}
              errorMsg={errorMsg}
              readOnly={readOnly}
              selectedTags={selectedTags}
              tags={tags.REGION}
              className='mb1'
              onChange={(tags:number[]) => { dispatch(setTempTags({ key: 'REGION', tags: _xor(tempTags.REGION, tags) })) }}
              isUpdated={isUpdated ?? false}
            />
          </div>
          <div className='width-40 mr2'>
            <NestedMultiSelect
              label={'Living situation'}
              errorMsg={errorMsg}
              readOnly={readOnly}
              selectedTags={selectedTags}
              tags={tags.LIVING_SITUATION}
              className='mb1'
              onChange={(tags:number[]) => dispatch(setTempTags({ key: 'LIVING_SITUATION', tags: _xor(tempTags.LIVING_SITUATION, tags) }))}
              isUpdated={isUpdated ?? false}
            />
          </div>
        </div>
        <div className='width-100 flex'>
          <MultiSelectGroup
            errorMsg={errorMsg}
            readOnly={readOnly}
            className='width-40 mr2'
            label='IKEA long-term priorities'
            multi={true}
            selectedTags={selectedTags}
            options={tags.PRIORITIES}
            onChange={(option:number[]) => dispatch(setTempTags({ key: 'PRIORITIES', tags: option }))}
          />
        </div>
        <div className='width-100 flex'>
          <MultiSelectGroup
            errorMsg={errorMsg}
            readOnly={readOnly}
            className='width-40 mr2'
            multi={false}
            label='Home size'
            selectedTags={selectedTags}
            options={tags.SIZE}
            onChange={(option:number[]) => dispatch(setTempTags({ key: 'SIZE', tags: option }))}
          />
          <MultiSelectGroup
            errorMsg={errorMsg}
            readOnly={readOnly}
            className='width-40 mr2'
            label='Rooms'
            selectedTags={selectedTags}
            options={tags.ROOMS}
            onChange={(option:number[]) => dispatch(setTempTags({ key: 'ROOMS', tags: option }))}
          />
        </div>
        <div className='width-100 flex'>
          <MultiSelectGroup
            readOnly={readOnly}
            className='width-40 mr2'
            multi={false}
            label='IKEA Style'
            selectedTags={selectedTags}
            options={tags.STYLE}
            onChange={(option:number[]) => dispatch(setTempTags({ key: 'STYLE', tags: option }))}
          />
          <MultiSelectGroup
            readOnly={readOnly}
            className='width-40 mr2'
            multi={false}
            label='Income level'
            selectedTags={selectedTags}
            options={tags.COST}
            onChange={(option:number[]) => dispatch(setTempTags({ key: 'COST', tags: option }))}
          />
        </div>
        <div className='width-100 flex pb2 mb2' onClick={scrollToBottom}>
          <MultiSelectGroup
            readOnly={readOnly}
            className='width-40 mr2'
            multi={true} label='Activities'
            selectedTags={selectedTags}
            options={tags.ACTIVITIES}
            errorMsg={errorMsg}
            onChange={(option:number[]) => dispatch(setTempTags({ key: 'ACTIVITIES', tags: option }))}
          />
          <MultiSelectGroup
            disabled
            readOnly={readOnly}
            className='width-40 mr2'
            multi={true} label='Coming soon - Segments'
            selectedTags={selectedTags}
            options={tags.BEHAVIORS}
            onChange={(option:number[]) => dispatch(setTempTags({ key: 'BEHAVIORS', tags: option }))}
          />
        </div>
      </Grid>
    </div >)
}
