import React from 'react'
import cs from 'classnames'
import styled from 'styled-components'
import { Tooltip, TooltipWrapper } from './tooltip'
import type { User } from '../../stores/ducks/users/User'

const px = (value: number | string) => isNaN(Number(value)) ? value : value + 'px'

const getUserName = (user: User) => {
  return user.firstName
    ? `${user.firstName} ${(user.lastName || '')}`
    : user.email
}

const getUserInitials = (user: User) => {
  if (user.firstName) {
    return user.lastName
      ? user.firstName.charAt(0) + user.lastName.charAt(0)
      : user.firstName.charAt(0) + user.firstName.charAt(1)
  }

  const fragments = user.email && user.email
    .substr(0, user.email.indexOf('@'))
    .replace(/external/g, '')
    .split('.')

  if (fragments && fragments.length === 2) return fragments[0].charAt(0) + fragments[1].charAt(0)
  if (fragments && fragments.length === 1) return fragments[0].charAt(0) + fragments[0].charAt(1)

  return 'X'
}

const Container = styled(TooltipWrapper)<{ size: number | string, imageSrc?: string }>`
  width: ${props => px(props.size)};
  height: ${props => px(props.size)};
  border-radius: ${props => px(props.size)};
  background-image: ${props => props.imageSrc && `url(${props.imageSrc})`};
  background-size: cover;
  background-position: center center;
`

type Props = {
  user: User
  imageSrc?: string
  size: number
  onClick?: () => void
  style?: React.CSSProperties
  className?: string
  withTooltip?: boolean
  tooltipLocation?: 'over' | 'under'
}

function Avatar (props: Props) {
  const name = React.useRef(getUserName(props.user || {}))
  const initials = React.useRef(getUserInitials(props.user || {}))

  return (
    <Container
      imageSrc={props.imageSrc}
      size={props.size}
      onClick={props.onClick}
      style={props.style}
      className={cs(
        'bg-gray-light',
        'c-gray-dark',
        'flex',
        'justify-center',
        'items-center',
        'flex-none',
        'uppercase',
        'f7',
        'z1',
        props.className
      )}
      location={props.tooltipLocation}
    >
      {!props.imageSrc && initials.current}
      {props.withTooltip && (<Tooltip location={props.tooltipLocation}>{name.current}</Tooltip>)}
    </Container>
  )
}

Avatar.defaultProps = {
  onClick: () => {},
  className: '',
  style: {},
  withTooltip: false,
  size: 34
}

export default Avatar
