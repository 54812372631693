const noop = () => {}

export const createSelectCommand = ({
  oldValue,
  newValue,
  select,
  afterUndo = noop,
  afterExecute = noop
}) => ({
  undo () {
    select(oldValue)
    afterUndo()
  },

  execute () {
    select(newValue)
    afterExecute()
  }
})

export const createHideCommand = ({
  uuids,
  hide,
  show,
  afterUndo = noop,
  afterExecute = noop
}) => ({
  undo () {
    show(uuids)
    afterUndo()
  },

  execute () {
    hide(uuids)
    afterExecute()
  }
})

export const createShowCommand = ({
  uuids,
  hide,
  show,
  afterUndo = noop,
  afterExecute = noop
}) => ({
  undo () {
    hide(uuids)
    afterUndo()
  },

  execute () {
    show(uuids)
    afterExecute()
  }
})
