import colors from '../../../css/colors'

export const stylesProjectView = {
  gridAreas: {
    default: [
      'top folders',
      'tools folders',
      'content folders',
      'footer footer'
    ],
    scenesTab: [
      'top top',
      'content content',
      'content content',
      'footer footer'
    ],
    noFolders: [
      'top top',
      'tools tools',
      'content content',
      'footer footer'
    ]
  },
  leftBar: {
    backgroundColor: colors.grayBlueDark,
    width: 250,
    height: '100%'
  },
  toolStyles: {
    gridArea: 'tools',
    marginLeft: 24
  }
}
