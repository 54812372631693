import * as actions from './stores/ducks/visibility'

import store from './stores'

export default register()

function register () {
  var hidden
  var visibilityChange

  if (typeof window.document.hidden !== 'undefined') {
    hidden = 'hidden'
    visibilityChange = 'visibilitychange'
  } else if (typeof window.document.msHidden !== 'undefined') {
    hidden = 'msHidden'
    visibilityChange = 'msvisibilitychange'
  } else if (typeof window.document.webkitHidden !== 'undefined') {
    hidden = 'webkitHidden'
    visibilityChange = 'webkitvisibilitychange'
  }

  if (window.document.addEventListener && typeof window.document[hidden] !== 'undefined') {
    window.document.addEventListener(visibilityChange, onChange, false)
  }

  function onChange () {
    store.dispatch(actions.change(!window.document[hidden]))
  }
}
