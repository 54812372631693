import React, { useMemo } from 'react'
import cs from 'classnames'
import { connect, ConnectedProps } from 'react-redux'

import _orderBy from 'lodash/orderBy'
import colors from '../../../../css/colors'
import { MdWbSunny as IconLightSetup } from 'react-icons/md'

import Button from '../../common/button'
import Grid from '../../common/grid/grid'
import ProgressRadial from '../../common/progress-radial'
import StorageApiImage, { StorageApiThumbnail } from '../../common/storage-api-image'
import Badge from '../../common/Badge'
import Scrollable from '../../common/scrollable'

import { VisualizedCombination } from '../../../stores/ducks/combinations/Combination'
import { RootState } from '../../../stores/ducks'
import { update as updateCombination, downloadRenderBatch } from '../../../stores/ducks/combinations'
import { fetchByIds } from '../../../stores/ducks/roomsets'
import { Render } from '../../../stores/ducks/renders/Render'
import { retry } from '../../../stores/ducks/renders'
import { DEADLINE_STATUS } from '../../../constants'

type PendingProps = {
  deadlineStatus: string
  renderPercentage: number
  onRetry?: () => void
  label?: string
}

function Pending (props: PendingProps) {
  return (
    <>
      {props.deadlineStatus !== 'Failed' && (
        <ProgressRadial
          hideLabel
          strokeWidth={10}
          background={colors.success}
          percentage={props.renderPercentage}
        />
      )}
      {props.deadlineStatus === 'Failed' && (
        <>
          <ProgressRadial
            hideLabel
            strokeWidth={10}
            background={colors.errorLightAlt}
            percentage={95}
          />
          {props.onRetry && (
            <button
              title='Retry render'
              className='absolute pointer c-gray-dark bc-gray bc-gray-dark-hover bg-white bg-gray-light-hover border br-2 flex justify-center'
              style={{ top: 8, right: 8, width: 32, height: 32 }}
              onClick={event => {
                event.stopPropagation()
                props.onRetry!()
              }}
            >
              <i className='icon-ccw' />
            </button>
          )}
        </>
      )}
      {props.label && (
        <div className='mt1 flex justify-center'>
          {props.label}
        </div>
      )}
    </>
  )
}

type OwnProps = {
  combination: VisualizedCombination
  isCompleteBatchRendered: boolean
}

const mapState = (state: RootState, ownProps: OwnProps) => {
  const setupTitleByRenderId = new Map<string, string | null | undefined>()
  ownProps.combination.renders!.forEach((render) => {
    setupTitleByRenderId.set(render.id, state.roomsets.entries.getIn([render.setupId || '', 'title']))
  })
  const renders = _orderBy(ownProps.combination.renders, [
    r => !setupTitleByRenderId.get(r.id),
    r => setupTitleByRenderId.get(r.id)
  ], ['desc', 'asc'])
  return {
    imageTemplate: ownProps.combination.imageTemplateId
      ? state.roomsets.entries.getIn([ownProps.combination.imageTemplateId])
      : null,
    roomsets: state.roomsets.entries,
    renders,
    setupTitleByRenderId,
    creator: state.users.entries.getIn([ownProps.combination.createdBy || '']),
    title: ownProps.combination.title
  }
}

const mapDispatch = (dispatch: any) => {
  return {
    retry: (id: string) => dispatch(retry(id)),
    getRoomsets: (ids: string[]) => dispatch(fetchByIds(ids)),
    downloadRenderBatch: (id: string) => dispatch(downloadRenderBatch(id)),
    onSelectDefaultRenderId: (combinationId: string, defaultRenderId: string) => {
      dispatch(updateCombination(combinationId, { defaultRenderId }))
    }
  }
}

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>
type Props = PropsFromRedux & OwnProps

function MultipleRendersTab (props: Props) {
  const [renderPreview, setRenderPreview] = React.useState<Render>(props.combination.renders![0])
  const pending = renderPreview.deadlineStatus !== DEADLINE_STATUS.COMPLETED
  const isFinal = renderPreview.id === props.combination.defaultRenderId

  const cameraSetupRenders = useMemo(
    () => {
      const cameraTypeRenders = props.renders.filter((render) => render.cameraType)
      if (cameraTypeRenders.length) {
        return props.renders
      }
      return cameraTypeRenders
    }
    , [props.renders])

  const lightSetupRenders = useMemo(
    () => props.renders.filter((render) => !render.cameraType)
    , [props.renders])

  React.useEffect(() => {
    if (props.combination.renders) {
      let renderPreview = props.combination.renders.find(r => r.id === props.combination.defaultRenderId)
      if (!renderPreview) renderPreview = props.combination.renders[0]
      renderPreview && setRenderPreview(renderPreview)
    }
  }, [props.combination.id])

  React.useEffect(() => {
    if (props.combination.imageTemplateId) {
      const roomsetIds = [props.combination.imageTemplateId]
      props.combination.renders!.forEach(render => {
        render.setupId && roomsetIds.push(render.setupId)
      })
      props.getRoomsets(roomsetIds)
    }
  }, [props.combination.id])

  return (
    <>
      <div
        className='bg-black-40 relative height-100'
        style={{ gridArea: 'image' }}
      >
        <StorageApiImage
          manifest={renderPreview.manifest}
          type='large'
          format='jpg'
          style={{
            maxHeight: 'calc(100vh - 196px)',
            objectFit: 'scale-down',
            objectPosition: 'center'
          }}
          className='height-100 userselect-none'
        />
        {pending && (
          <div
            className='absolute top-0 left-0 right-0 bottom-0 c-black flex justify-center items-center'
          >
            <div
              style={{ width: 300, height: 300 }}
            >
              <Pending
                deadlineStatus={renderPreview.deadlineStatus || ''}
                renderPercentage={renderPreview.renderPercentage || 0}
              />
            </div>
          </div>
        )}

        <Button
          btnType='secondary'
          className='mb2 absolute bottom-0 right-0 m2'
          disabled={(
            (props.combination.renders && (props.combination.renders.length === 1 || isFinal)) ||
            renderPreview.deadlineStatus !== DEADLINE_STATUS.COMPLETED
          )}
          onClick={() => {
            props.onSelectDefaultRenderId(props.combination.id, renderPreview.id)
          }}
        >
          Select as final
        </Button>
      </div>

      <Grid
        rows={['auto', 'auto', '1fr']}
        gridGap={0}
        style={{ gridArea: 'info', width: 320 }}
        className='f7 c-white ml3'
      >
        <div>
          {props.creator && (
            <div className='mb3'>
              <div style={{ marginBottom: 4 }}><b>Creator</b></div>
              <div>{props.creator.email}</div>
            </div>
          )}
          {props.title && (
            <div className='mb3'>
              <div style={{ marginBottom: 4 }}><b>Title</b></div>
              <div>{props.title}</div>
            </div>
          )}
          {props.imageTemplate && (
            <div className='mb3'>
              <div><b>Template</b></div>
              <div style={{ width: 60, height: 60, marginTop: 4, marginBottom: 4 }}>
                <StorageApiThumbnail
                  file={props.imageTemplate.metadata?.thumbnail}
                />
              </div>
              <div>{props.imageTemplate.title}</div>
            </div>
          )}

          {((props.combination.connectedBatchRenderCombinationIds || []).length > 0 || props.combination.batchParentId) && (
            <div className='mb3'>
              <Button
                disabled={!props.isCompleteBatchRendered}
                onClick={() => {
                  props.downloadRenderBatch(props.combination.batchParentId || props.combination.id)
                }}>
                Download image package
              </Button>
            </div>
          )}
        </div>
        <div>
          <div
            className='p1 flex items-center c-black bg-gray-light br-2'
            style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
          >
            {cameraSetupRenders.length === 0 &&
            <>
              <IconLightSetup className='f6 mr1' />
              Light setup
            </>
            }
            {cameraSetupRenders.length !== 0 &&
              'Camera setup'
            }
          </div>
        </div>
        <Scrollable>
          <div
            className='p2'
            style={{ background: colors.todo_333 }}
          >
            {cameraSetupRenders.length === 0 && lightSetupRenders.map((render, index) => {
              return (
                <Grid
                  key={render.id}
                  columns={['2fr', '3fr']}
                  onClick={() => setRenderPreview(render)}
                  style={{
                    boxShadow: renderPreview.id === render.id ? `0 0 0 4px ${colors.secondary}` : 'none'
                  }}
                  className={cs('pointer', {
                    'bg-secondary bg-secondary-light-hover': renderPreview.id === render.id,
                    'bg-white-10-hover': renderPreview.id !== render.id,
                    mb2: index !== props.renders.length - 1
                  })}
                >
                  <div className='bg-white relative'>
                    <StorageApiThumbnail
                      manifest={render.manifest}
                      className='pointer'
                    />

                    {render.deadlineStatus !== 'Completed' && (
                      <div className='absolute top-0 left-0 right-0 bottom-0 flex justify-center p2'>
                        <Pending
                          deadlineStatus={render.deadlineStatus || ''}
                          renderPercentage={render.renderPercentage || 0}
                          onRetry={() => props.retry(render.id)}
                        />
                      </div>
                    )}
                  </div>
                  <div>
                    <div className='bold'>
                      {props.setupTitleByRenderId.get(render.id) || render.cameraType || 'Default light'}
                    </div>

                    {props.combination.defaultRenderId === render.id && (
                      <Badge className='inline-block mt1'>
                      Final
                      </Badge>
                    )}
                  </div>
                </Grid>
              )
            })}

            {cameraSetupRenders.length !== 0 && cameraSetupRenders.map((render, index) => {
              return (
                <Grid
                  key={render.id}
                  columns={['2fr', '3fr']}
                  onClick={() => setRenderPreview(render)}
                  style={{
                    boxShadow: renderPreview.id === render.id ? `0 0 0 4px ${colors.secondary}` : 'none'
                  }}
                  className={cs('pointer', {
                    'bg-secondary bg-secondary-light-hover': renderPreview.id === render.id,
                    'bg-white-10-hover': renderPreview.id !== render.id,
                    mb2: index !== props.renders.length - 1
                  })}
                >
                  <div className='bg-white relative'>
                    <StorageApiThumbnail
                      manifest={render.manifest}
                      className='pointer'
                    />

                    {render.deadlineStatus !== 'Completed' && (
                      <div className='absolute top-0 left-0 right-0 bottom-0 flex justify-center p2'>
                        <Pending
                          deadlineStatus={render.deadlineStatus || ''}
                          renderPercentage={render.renderPercentage || 0}
                          onRetry={() => props.retry(render.id)}
                        />
                      </div>
                    )}
                  </div>
                  <div>
                    <div className='bold'>
                      {render.title || props.setupTitleByRenderId.get(render.id) || 'Perspective'}
                    </div>

                    {props.combination.defaultRenderId === render.id && (
                      <Badge className='inline-block mt1'>
                      Final
                      </Badge>
                    )}
                  </div>
                </Grid>
              )
            })}
          </div>
        </Scrollable>
      </Grid>
    </>
  )
}

export default connector(MultipleRendersTab)
