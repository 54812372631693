import React from 'react'
import Button from '../common/button'
import Filterbar from './filterbar'
import List from './list'

interface Props {
  onClose: () => void
}

const TemplateLoader = ({ onClose }: Props) => {
  return (
    <div className='relative height-100 overflow-hidden py3 px3'>
      <div className='height-100 flex flex-column'>
        <h2 className='mt0 f4'>Load Home</h2>
        <div className='width-100 height-100 flex'>
          <Filterbar />
          <List onClose={onClose}/>
        </div>
        <div className='flex justify-end width-100 pt2'>
          <Button onClick={onClose}>
              Cancel
          </Button>
        </div>
      </div>
    </div>
  )
}

export default TemplateLoader
