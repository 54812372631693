import handleProjects from './handlers/projects'
import handleUploads from './handlers/uploads'
import handleBigUploads from './handlers/big-uploads'
import handleConverts from './handlers/converts'
import handleUsers from './handlers/users'
import handleComments from './handlers/comments'
import handleLikes from './handlers/likes'
import handleRoomsets from './handlers/roomsets'
import handleRenders from './handlers/renders'
import handleCombinations from './handlers/combinations'
import handlePatterns from './handlers/patterns'
import handleJobs from './handlers/jobs'
import handleFolders from './handlers/folders'
import handleNews from './handlers/news'
import handleMaterials from './handlers/materials'
import handleScenes from './handlers/scenes'
import handleExports from './handlers/exports'

import show from './show-notification'

const pubsub = require('@inter-ikea-digital/iig-rpd-dpd-packages-pubsub-client')

export default { show }

on('projects', handleProjects)
on('uploads', handleUploads)
on('big-uploads', handleBigUploads)
on('converts', handleConverts)
on('users', handleUsers)
on('comments', handleComments)
on('likes', handleLikes)
on('roomsets', handleRoomsets)
on('renders', handleRenders)
on('combinations', handleCombinations)
on('patterns', handlePatterns)
on('jobs', handleJobs)
on('folders', handleFolders)
on('news', handleNews)
on('materials', handleMaterials)
on('scenes', handleScenes)
on('exports', handleExports)

function on (key, cb) {
  pubsub.subscribe(key, (_, data) => {
    cb(Object.assign(data, { tag: key }))
  })
}
