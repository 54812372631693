import * as THREE from 'three'
import { EventEmitter } from 'events'
import _ from 'lodash'
import { Animation } from '../../AnimationManager'

class FirstPersonControlsWidget extends EventEmitter {
  constructor (app) {
    super()

    this._app = app

    this._domElement = app.domElement
    this._picker = app.picker
    this._objectTracker = app.objectTracker
    this._mousePoint = new THREE.Vector2()

    this._material = new THREE.ShaderMaterial({
      transparent: true,
      uniforms: {
        // Used to animate outer cirle, goes from 0.2 to -0.4
        radOffset: { value: 0.2 }
      },
      vertexShader: `
        varying vec2 guv;

        void main(){
          guv = uv;
          gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
        }
      `,
      fragmentShader: `
        varying vec2 guv;
        uniform float radOffset;

        void main(){
          float d = length(guv * 2.0 - 1.0);
          float innerCircle = smoothstep(0.65, 0.7, 1.5 * d) * 
                              smoothstep(0.85, 0.8, 1.5 * d);
    
          float outerCircle = smoothstep(0.55, 0.6, d + radOffset) *
                              smoothstep(0.7, 0.65, d + radOffset) *
                              smoothstep(0.5, 0.55, d) *
                              smoothstep(0.01, 0.3, 1.0 - d);
    
          float alpha = innerCircle + outerCircle * 0.8;
    
          gl_FragColor = vec4(vec3(1.0), alpha * 0.7);
        }
      `
    })

    this._geometry = new THREE.PlaneBufferGeometry()

    this._widget = new THREE.Mesh(this._geometry, this._material)
    this._widget.rotation.x = Math.PI / -2
    this._widget.scale.set(0.75, 0.75, 0.75)
    this._widget.visible = false
    this._enabled = false
    this._rejectFromHitFilter = null

    app.overlayScene.add(this._widget)

    this._listeners = [
      { type: 'dblclick', target: app.domElement, handler: this.onDblClick.bind(this) },
      { type: 'mousemove', target: app.domElement, handler: this.onMouseMove.bind(this) },
      { type: 'mouseleave', target: app.domElement, handler: this.onMouseLeave.bind(this) }
    ]

    this._listeners.forEach(({ type, target, handler, params }) => {
      target.addEventListener(type, handler, params)
    })
  }

  set rejectFromHitFilter (fn) {
    if (typeof fn === 'function') {
      this._rejectFromHitFilter = fn
    }
  }

  get enabled () {
    return this._enabled
  }

  set enabled (enabled) {
    this._enabled = enabled
    if (!enabled) {
      this._widget.visible = false
    }
  }

  dispose () {
    this._app.overlayScene.remove(this._widget)
    this._listeners.forEach(({ type, target, handler, params }) => {
      target.removeEventListener(type, handler, (params || {}))
    })
  }

  hitNavigationArea (sceneGraphID) {
    return _.get(this._objectTracker, `interactions.navigationArea.${sceneGraphID}`)
  }

  getHit (event, filter) {
    return this._picker.getIntersects(this.calculateMousePoint(event), filter)
  }

  calculateMousePoint (event) {
    const mousePosition = this._picker.getMousePosition(this._domElement, event.clientX, event.clientY)
    this._mousePoint.fromArray(mousePosition)
    return this._mousePoint
  }

  onMouseLeave (event) {
    this._widget.visible = false
    this._app.renderOnNextFrame()
  }

  onDblClick (event) {
    if (!this.enabled) return
    this.calculateMousePoint(event)
    const hit = this._picker.getIntersects(this._mousePoint, (mesh) => this.hitNavigationArea(mesh.sceneGraphID))
    const hitRejectedObject = this._rejectFromHitFilter && this._picker.getIntersects(this._mousePoint, this._rejectFromHitFilter)

    if (hit && !hitRejectedObject) {
      const target = -0.4
      const current = 0.2

      this._app.animationManager
        .addAnimation(new Animation(current))
        .lerpTo(target, 2)
        .onUpdate((current, target) => {
          this._widget.material.uniforms.radOffset.value = current
          this._widget.material.needsUpdate = true
        })
        .endIf((target, current) => {
          return !this.enabled || Math.abs(current - target) < 0.01
        })
        .onEnd(() => {
          this._widget.visible = false
          this.onMouseMove(event)
        })
      hit.point.y = this._app.cameraManager.camera.position.y
      this._app.cameraManager.moveToPoint(hit.point)
    }
  }

  onMouseMove (event) {
    if (!this.enabled) return
    this.calculateMousePoint(event)
    const hit = this._picker.getIntersects(this._mousePoint, (mesh) => this.hitNavigationArea(mesh.sceneGraphID))
    const hitRejectedObject = this._rejectFromHitFilter && this._picker.getIntersects(this._mousePoint, this._rejectFromHitFilter)

    let changed = false

    if (hit && !hitRejectedObject) {
      const distance = this._widget.position.distanceTo(hit.point)

      if (distance > 0) {
        this._widget.position.x = hit.point.x
        this._widget.position.z = hit.point.z
        changed = true
      }

      if (!this._widget.visible) {
        this._widget.visible = true
        changed = true
      }
    } else {
      if (this._widget.visible) {
        this._widget.visible = false
        changed = true
      }
    }

    if (changed) {
      this._app.renderOnNextFrame()
    }
  }
}

export default FirstPersonControlsWidget
