import React from 'react'
import PropTypes from 'prop-types'

import colors from '../../../css/colors'

const KEYCODE = 83 // S
const LEFT_MOUSE_BUTTON = 0

class RectangleSelect extends React.PureComponent {
  constructor (props, ctx) {
    super(props, ctx)
    this._root = React.createRef()
    this.state = {}
  }

  componentDidMount () {
    const parent = (this._root && this._root.current && this._root.current.parentElement) || window

    let isKeyPressed = false
    let isMouseDown = false

    const onKeyDown = (event) => {
      if (isKeyPressed) return

      if (event.keyCode === KEYCODE) {
        isKeyPressed = true
        this._update({ shiftKey: event.shiftKey, active: true })
      }
    }

    const onKeyUp = (event) => {
      isKeyPressed = false

      if (event.keyCode === KEYCODE && !isMouseDown) {
        this._update({ start: null, end: null, shiftKey: false, active: false })
      }
    }

    const onMouseLeave = () => {
      if (isMouseDown || isKeyPressed) {
        this._update({ start: null, end: null, shiftKey: false, active: false })
      }
    }

    const onMouseDown = (event) => {
      if (event.button !== LEFT_MOUSE_BUTTON) return

      isMouseDown = true

      if (isKeyPressed) {
        this._update({ start: [event.clientX, event.clientY] })
      }
    }

    const onMouseMove = (event) => {
      if (!isMouseDown) return

      if (isKeyPressed) {
        this._update({ end: [event.clientX, event.clientY] })
      }
    }

    const onMouseUp = (event) => {
      isMouseDown = false
      if (event.button !== LEFT_MOUSE_BUTTON) return
      this._update({ start: null, end: null, shift: false })
    }

    this._listeners = [
      { target: window, event: 'keydown', handler: onKeyDown },
      { target: window, event: 'keyup', handler: onKeyUp },
      { target: window, event: 'mouseleave', handler: onMouseLeave },
      { target: parent, event: 'mousedown', handler: onMouseDown },
      { target: window, event: 'mousemove', handler: onMouseMove },
      { target: window, event: 'mouseup', handler: onMouseUp }
    ]

    this._listeners.forEach(l => l.target.addEventListener(l.event, l.handler))
  }

  componentWillUnmount () {
    this._listeners.forEach(l => l.target.removeEventListener(l.event, l.handler))
  }

  _update (data) {
    const { active, start, end } = this.state
    if (start && end && data.end === null && data.start === null) {
      this.props.onSelect(start, end, this.state.shiftKey)
    }

    if (active && data.active === false) {
      this.props.onDeactivate()
    }

    if (!active && data.active === true) {
      this.props.onActivate()
    }

    this.setState(data)
  }

  _calculateScreenPos (start, end) {
    return [
      [Math.max(Math.min(start[0], end[0]), 0), Math.max(Math.min(start[1], end[1]), 0)],
      [Math.max(start[0], end[0]), Math.max(start[1], end[1])]
    ]
  }

  render () {
    const { start, end } = this.state

    let _render = null

    if (start && end) {
      const [min, max] = this._calculateScreenPos(start, end)

      const style = {
        width: max[0] - min[0],
        height: max[1] - min[1],
        left: min[0],
        top: min[1],
        border: `2px dashed ${colors.primary}`
      }

      _render = (
        <div className='fixed bg-white-40' style={style} />
      )
    }

    return (
      <div ref={this._root}>{_render}</div>
    )
  }
}

RectangleSelect.defaultProps = {
  onDeactivate () {},
  onActivate () {}
}

RectangleSelect.propTypes = {
  onDeactivate: PropTypes.func,
  onActivate: PropTypes.func,
  onSelect: PropTypes.func.isRequired
}

export default RectangleSelect
