import { combineReducers } from 'redux'
import { handleActions, createAction } from 'redux-actions'

import defaultTemplate, * as fromDefaultTemplate from './default-template'
import * as fromLights from './lights'

import * as fromRoomsets from '../roomsets'
import * as fromRoomsetsScene from '../roomsets/scene'
import * as fromThreeviewerSelectors from '../threeviewer/selectors'
import * as fromTemplatesSelectors from './selectors'

import { placeInstances } from './utils'

export const DEFAULT_TEMPLATE_ID = 'default'

export const setActive = createAction('templates/active/SET_ACTIVE')
const disposeActive = createAction('templates/active/DISPOSE')

const getOrigoFromTemplate = (state) => {
  const { THREE } = fromThreeviewerSelectors.getViewer(state)
  return new THREE.Vector3()
}

export const dispose = () => (dispatch) => {
  dispatch(disposeActive())
  dispatch(fromDefaultTemplate.dispose())
}

export const getCurrentOrigo = () => (dispatch, getState) => {
  const { THREE } = fromThreeviewerSelectors.getViewer(getState)
  return Promise.resolve(new THREE.Vector3())
}

export const load = (nextTemplateId, params = {}) => (dispatch, getState) => {
  const { modifications, cameraSettings } = params

  const state = getState()
  const viewer = fromThreeviewerSelectors.getViewer(state)
  const prevTemplateId = fromTemplatesSelectors.getActiveTemplateId(state)

  if (!nextTemplateId || prevTemplateId === nextTemplateId) return Promise.resolve()

  const isNextDefaultTemplate = nextTemplateId === DEFAULT_TEMPLATE_ID

  const prevOrigo = getOrigoFromTemplate(getState())

  viewer.roomManager.trash()

  return Promise.resolve()
    .then(() => {
      if (!prevTemplateId) return Promise.resolve()

      if (prevTemplateId === DEFAULT_TEMPLATE_ID) {
        return dispatch(fromDefaultTemplate.remove())
      }

      return dispatch(fromRoomsetsScene.dispose())
    })
    .then(() => {
      dispatch(setActive(nextTemplateId))

      if (isNextDefaultTemplate) {
        dispatch(fromRoomsets.setCurrent(null))
        return dispatch(fromDefaultTemplate.load(
          getState().threeviewer.settings.template,
          modifications
        ))
      }

      dispatch(fromRoomsets.setCurrent(nextTemplateId))
      return dispatch(fromRoomsetsScene.load(
        nextTemplateId, {
          setCamera: !!prevTemplateId,
          cameraSettings,
          modifications
        }))
    })
    .then(({ placeCoordinate, moveCamera, instanceId }) => {
      if (prevTemplateId && (prevTemplateId !== nextTemplateId)) {
        const nextOrigo = (placeCoordinate) || getOrigoFromTemplate(nextTemplateId, getState())
        const origo = prevOrigo.negate().add(nextOrigo)

        const inRoomset = !(isNextDefaultTemplate)
        placeInstances(viewer, origo, [instanceId], inRoomset)
        if (!placeCoordinate || moveCamera) {
          viewer.camera.position.add(origo)
        }
      }
    })
    .then(() => isNextDefaultTemplate && dispatch(fromDefaultTemplate.adjustWallInScene()))
    .then(() => dispatch(fromLights.collectLights()))
}

const activeId = handleActions({
  [disposeActive]: () => null,
  [setActive]: (_, action) => action.payload
}, null)

export default combineReducers({
  defaultTemplate,
  activeId
})
