import React from 'react'

import UploadFiles from '../../upload-files/index'
import Modal from '../../common/modal'
import GeometryUploadView from '../../upload-files/geometry-upload-view'
import uploadUtils from '../../upload-files/utils'
import UploadCard from './UploadCard'

type Props = {
  onUploaded: (data: any) => void
}

export default function UploadGeometry (props: Props) {
  const [files, setFiles] = React.useState<File[]>([])
  const [hashProgress, setHashProgress] = React.useState(100)
  const reset = () => setFiles([])

  return (
    <div>
      <UploadFiles
        onUpload={(files: File[]) => setFiles(files)}
        supportedFormats={uploadUtils.acceptedFileTypes.map(t => `.${t}`)}
        multiple
      >
        {({ isDragging }: { isDragging: boolean }) => (
          <UploadCard
            type='Geometry'
            isDragging={isDragging}
            progress={hashProgress}
          />
        )}
      </UploadFiles>
      <Modal
        isOpen={files.length > 0}
        onRequestClose={reset}
      >
        <GeometryUploadView
          files={files}
          onReset={reset}
          onUploaded={props.onUploaded}
          onHashProgress={(progress: { percentage: number }) => setHashProgress(progress.percentage)}
        />
      </Modal>
    </div>
  )
}
