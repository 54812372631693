import React from 'react'
import { ModalButton } from '../../../../common/modal'
import DesignLoader from '../../../design-loader'

type LoadDesignModalButtonProps = {
  toggleKeyboardBindings: (value: boolean) => void
  children: React.ReactNode
}

export const LoadDesignModalButton = (props: LoadDesignModalButtonProps) => {
  return (
    <ModalButton
      fullSize
      onModalClose={() => props.toggleKeyboardBindings(true)}
      modalContent={(closeModal: any) => (
        <DesignLoader
          onClose={(params: any) => {
            props.toggleKeyboardBindings(true)
            closeModal(params)
          }}
          type='load'
        />
      )}
      button={props.children}
    />
  )
}
