import { Vector3, Vector4 } from 'three'
import _get from 'lodash/get'

export default class AutoGroupManager {
  constructor (app) {
    this._app = app
    this._enabled = false
    this._material = null
    this._resetVec = new Vector4(0, 0, 0, 0)
    this._upVec = new Vector3(0, 1, 0)
    this._bbVec = new Vector3()
  }

  set enabled (v) {
    if (!v && this._enabled) {
      if (this._material !== null) {
        this._material.markerPoint = this._resetVec.clone()
      }
    }
    this._enabled = v
  }

  get enabled () {
    return this._enabled
  }

  updateMarkerPoint (event) {
    if (!this.enabled) {
      return
    }

    const { prevIntersection, objects } = event.target
    const { object, point } = prevIntersection

    if (this._material === null) {
      this._material = object.material
    } else if (this._material.uuid !== object.material.uuid) {
      this._material.markerPoint = this._resetVec.clone()
      this._material = object.material
    }

    const size = this._app.viewerUtils.getBoundingBox(objects).getSize(this._bbVec)
    const max = Math.max(size.x, size.z)
    this._material.markerPoint = new Vector4(point.x, point.y, point.z, max)
  }

  inGroup (obj) {
    let current = obj.parent
    while (current) {
      if (current.userData && current.userData.isGroup) {
        return true
      }

      current = current.parent
    }
    return false
  }

  getGroup (obj) {
    let current = obj.parent
    while (true) {
      if (!current) {
        return null
      }

      if (current.userData && current.userData.isGroup) {
        return current
      }

      current = current.parent
    }
  }

  getAutoSnappingData (event) {
    const { prevIntersection, faceWorldNormal } = event.target
    const movedObjects = event.target.objects
    const intersectionObject = prevIntersection ? prevIntersection.object : {}
    const normalPointsUp = faceWorldNormal.equals(this._upVec)

    if (normalPointsUp && intersectionObject && movedObjects.length === 1) {
      const intersectionIsTemplate = _get(intersectionObject, 'userData.isTemplate')
      const intersectionIsRoomset = _get(intersectionObject, 'userData.modelType') === 'roomset'

      const movedObj = movedObjects[0]
      const movedObjectsParentIsGroup = _get(movedObj, 'parent.userData.isGroup')

      if (intersectionIsTemplate || intersectionIsRoomset) {
        if (movedObjectsParentIsGroup) {
          const group = this.getGroup(movedObj)
          if (group.children.length === 1) {
            return {
              action: 'UNGROUP',
              group: group,
              selectionCallback: () => [movedObj]
            }
          } else if (group.children.length > 1) {
            return {
              action: 'REMOVE_NODE',
              group: group,
              node: movedObj,
              selectionCallback: () => [movedObj]
            }
          }
        }
      } else {
        if (!this.inGroup(movedObj) && !this.inGroup(intersectionObject)) {
          return {
            action: 'GROUP',
            nodeIds: [movedObj.uuid, intersectionObject.uuid],
            selectionCallback: () => [this.getGroup(intersectionObject)]
          }
        }

        if (!this.inGroup(movedObj) && this.inGroup(intersectionObject)) {
          return {
            action: 'ADD_NODE',
            node: movedObj,
            group: this.getGroup(intersectionObject),
            selectionCallback: () => [this.getGroup(intersectionObject)]
          }
        }
      }
    }

    return {
      action: 'NO_OP'
    }
  }
}
