import { get } from 'lodash'
import { ImmutableObject } from 'seamless-immutable'
import { VirtualProductProjectListItem, ElasticVirtualProduct } from '../../../stores/ducks/virtual-products/VirtualProduct'
import { Item } from '../card/Item'
import type { StorageApiFile as ManifestFile } from '../../../utils'
import type { Combination } from '../../../stores/ducks/combinations/Combination'

export function parseListItem (
  virtualProduct: VirtualProductProjectListItem | ImmutableObject<VirtualProductProjectListItem>,
  projectId: string | null
): Item {
  return {
    isNew: false,
    savedAsScene: false,
    updated: '',
    deadlineStatus: '',
    renderStatus: '',
    renderPercentage: 100,
    uploadMetadata: { title: '' },
    roomsetModels: {},
    models: {},
    templateModels: {},
    status: 'Completed',
    // ---
    createdAt: get(virtualProduct, ['projects', projectId || '', 'connectedAt'], virtualProduct.virtualProductDatabaseData.createdAt),
    createdBy: '',
    combinationType: 'virtual-product',
    id: virtualProduct.id,
    title: virtualProduct.virtualProductDatabaseData.name,
    manifest: virtualProduct.thumbnail ? { files: [virtualProduct.thumbnail] } : undefined,
    subtitle: virtualProduct.virtualProductDatabaseData.itemNumber ?? undefined,
    currentVersion: virtualProduct.virtualProductDatabaseData.versionData?.versionNr ?? undefined,
    combinationId: virtualProduct.currentCombinationId ?? undefined
  }
}

export type VirtualProductItem = Omit <Item, 'createdBy'| 'manifest'| 'combinationId' | 'thumbnail'> & {
  thumbnail: ManifestFile | string | null
  subTitle: string | undefined
  combinationType: Combination['combinationType']
}

export function parseConnectVirtualProductItem (
  virtualProduct: ElasticVirtualProduct | ImmutableObject<ElasticVirtualProduct>)
  : VirtualProductItem {
  return {
    isNew: false,
    savedAsScene: false,
    updated: '',
    deadlineStatus: '',
    renderStatus: '',
    renderPercentage: 100,
    uploadMetadata: { title: '' },
    roomsetModels: {},
    models: {},
    templateModels: {},
    status: 'Completed',
    // ---
    createdAt: virtualProduct.createdAt,
    combinationType: 'virtual-product',
    id: virtualProduct._id,
    title: virtualProduct.name,
    thumbnail: virtualProduct.thumbnail ?? null,
    subTitle: virtualProduct.itemNumber ?? undefined,
    currentVersion: virtualProduct.versionData.versionNr ?? undefined
  }
}
