import React from 'react'

type Props = {
  icon?: string
  text?: string
  children?: React.ReactNode
  className?:string
  style?: object
}

const iconStyle = {
  fontSize: 80
}

const Info = ({
  icon,
  text,
  children,
  style,
  className,
}:Props) => (
  <div className={`py2 border bc-gray-light br-2 pointer center block flex items-center justify-around px2 ${className}`} style={style}>
    <div className='width-100'>
      {icon &&
        <i className={icon} style={iconStyle} />
      }
      <div className='my1'>
        <div className='pb2'>
          {text}
        </div>
        {children}
      </div>
    </div>
  </div>
)

export default Info
