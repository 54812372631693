import Immutable from 'seamless-immutable'
import _omit from 'lodash/omit'
import _uniq from 'lodash/uniq'
import _xor from 'lodash/xor'
import { v4 as uuid } from 'uuid'

import INTERACTIONS from '../combinations/interactions'
import { createGroupCommand, createMoveNodeCommand, createUngroupCommand } from './commands'

import { AppThunk } from '..'
import * as fromUndoRedo from '../undo-redo'
import * as fromThreeviewerSelectors from '../threeviewer/selectors'
import * as fromSelection from '../selection'
import * as fromCombinations from '../combinations'
import * as fromSelectionSelectors from '../selection/selectors'
import * as fromTreeSelectors from './selectors'

import { ITreeNode, ITreeNodes, FlattenedNodes, FlattenedNode, NodeList } from './TreeNode'
import { SceneGraphMesh as ISceneGraphMesh, SceneGraphNode3d as ISceneGraphNode3d } from '../../../../../go3dthree/types/SceneGraph'

import { buildNodeTree, findLowestGroup, findNodeUuidToScrollTo, findPath, transformForTree, flattenNodes, findLowestCommonAncestor } from './utils'

// Action types
const ADD = 'tree/ADD'
const ADD_TO_GROUP = 'tree/ADD_TO_GROUP'
const REMOVE = 'tree/REMOVE'
const CLEAR = 'tree/CLEAR'
const NODE_METADATA_UPDATED = 'tree/NODE_METADATA_UPDATED'

const OPEN_GROUP_NODE = 'tree/OPEN_GROUP_NODE'
const TOGGLE_GROUP_NODE = 'tree/TOGGLE_GROUP_NODE'
const CLOSE_ALL_GROUPS = 'tree/CLOSE_ALL_GROUPS'
const OPEN_ALL_GROUPS = 'tree/OPEN_ALL_GROUPS'

const SCROLL_TO_NODE_UUID = 'tree/SCROLL_TO_NODE'
const UPDATE_IS_DRAGGING = 'tree/UPDATE_IS_DRAGGING'

const FLAT_NODES_SHOULD_UPDATE = 'tree/FLAT_NODES_SHOULD_UPDATE'

// Action creators
export function openGroup (uuids: string | string[]) {
  return {
    type: OPEN_GROUP_NODE,
    payload: uuids
  }
}

export function toggleGroup (uuids: string | string[]) {
  return {
    type: TOGGLE_GROUP_NODE,
    payload: uuids
  }
}

export function openAllGroups () {
  return {
    type: OPEN_ALL_GROUPS,
  }
}

export function closeAllGroups () {
  return {
    type: CLOSE_ALL_GROUPS,
  }
}

export function scrollToNodeUuid (uuid: string | null) {
  return {
    type: SCROLL_TO_NODE_UUID,
    payload: uuid
  }
}

export function updateIsDragging (isDragging: boolean) {
  return {
    type: UPDATE_IS_DRAGGING,
    payload: isDragging
  }
}

export function flatNodesShouldUpdate (flatNodes: FlattenedNodes) {
  return {
    type: FLAT_NODES_SHOULD_UPDATE,
    payload: flatNodes,
  }
}

export function add (node: ISceneGraphNode3d | ISceneGraphMesh) {
  return {
    type: ADD,
    payload: transformForTree(node)
  }
}

function addToGroup (node: ITreeNode, group: any) {
  return {
    type: ADD_TO_GROUP,
    payload: { node, group }
  }
}

export function clear () {
  return {
    type: CLEAR
  }
}

export function remove (uuid: string, removeParent = false) {
  return {
    type: REMOVE,
    payload: {
      uuid,
      removeParent
    }
  }
}

// Helper actions

/**
 * Moves a node to a given destination
 * If node is moved from a group which will become empty the group will be removed
 * Conditionally uses different dispatches dependent on where the node is moved to
 *
 * @param node Node to move
 * @param destination Destination for the node to be moved
 * @param createCommand Create a command (undo/redo event) for this action. Default is true
 */
export function moveNode (nodeUuid: string, destinationUuid: string, createCommand = true): AppThunk {
  return (dispatch, getState) => {
    const state = getState()
    const nodeList = fromThreeviewerSelectors.getNodeList(state)
    if (!nodeList) return
    const node = nodeList[nodeUuid]
    // If the destination cannot be found in the nodeList, move the node to the root (scene graph) instead
    const destination = nodeList[destinationUuid] ?? state.threeviewer?.viewer?.scene

    if (!node || !destination) return

    const source = node.parent
    const hidden = Immutable.asMutable<string>(state.selection.hidden)
    if (source.userData && source.userData.isGroup && source.children.length === 1) {
      // If node is being moved from a group were it is the only child, ungroup(remove) the group
      createCommand = false // ungroup will create command instead. Ungroup creates a command which saves the deleted group to be able to restore it with redo
      dispatch(ungroup(source.uuid))
    } else if (destination.parent === null) {
      // If destination is ISceneGraph use add command instead of addToGroup
      destination.addChild(node)
      dispatch(remove(node.uuid))
      dispatch(add(node))
      /**
       * If node is in group that is hidden, it should be set to visible when removed from group
       * -> this isn't working as expected though and I haven't figured out why
       * -> the item/node will be removed from the group in the tree and it says it is visible in the tree (the eye), but the geometry will not show in the scene -> it is not possible to get it visible from here
       * -> seems like the item still belongs to the group? -> have checked this in the FE though, and it seems the group gets dissolved
       * -> if you check nodes _parentVisible (or something), you can see that it says false, and I'm guessing it should be true, since the parent in this case is the scenegraph -> a lead?
       * -> tried to set it to true, but it didn't change the behavior
        */
      if (hidden.includes(source.uuid)) {
        dispatch(fromSelection.show([node.uuid]))
      }
    } else {
      // If destination is a group use addToGroup
      destination.addChild(node)
      dispatch(remove(node.uuid))
      dispatch(addToGroup(buildNodeTree(node) as ITreeNode, destination))
      dispatch(openGroup(destination.uuid))
      /**
       * If destination is hidden, set node to hidden as well
       * -> there are some issues with this though, the last hidden item won't behave as the items already in group
       * -> had to disable the eye-button in geometry-node if node and ancestor is hidden, otherwise it was possible to toggle visibility on the last added item, which should not be possible
       * -> you can also use dispatch(hide) on either the node or the group again -> the behavior on the last item will still be the same (if hte eye-button would not be disabled)
       * -> check the redux selection/hidden state on what happens
      */
      if (hidden.includes(destination.uuid)) {
        dispatch(fromSelection.toggleVisibility(node.uuid))
      }
    }

    if (createCommand) {
      const command = createMoveNodeCommand({
        node,
        destination,
        source,
        moveNode: (node, destination) => dispatch(moveNode(node.uuid, destination.uuid, false)),
      })
      // @ts-ignore Dunno why this is an error
      dispatch(fromUndoRedo.addCommand(command))
    }
  }
}

/**
 * Updates a node by rebuilding the corresponding branch and re-adding the node
 * to the parent group.
 *
 * @param node Node to update
 */
export function updateNode (node: ISceneGraphNode3d | ISceneGraphMesh): AppThunk {
  return (dispatch) => {
    const tree = buildNodeTree(node)
    dispatch(addToGroup(tree, node.parent))
  }
}

/**
 * Create group node from either parameter nodesToGroup or from picker selection state.
 * Conditionally creates a undo/redo command.
 *
 * If an existing group node is provided it will be used instead if creating a new one. This is used by undo/redo command to be able to create
 * the same group again (id needs to be the same to go back and forth in the history)
 *
 * @param nodesToGroup If provided the nodes to add to a group. Otherwise the selection state from picker will be used
 * @param createCommand Create a command (undo/redo event) for this action. Default is true
 * @param existingGroupNode If provided the existingGroupNode will be added to the viewer instead of a new one.
 * @param groupData Additional group user data
 */
export function group (nodesToGroup?: string[], createCommand = true, existingGroupNode?: ISceneGraphNode3d, groupData: Record<string, unknown> = {}): AppThunk {
  return (dispatch, getState) => {
    const state = getState()
    const viewer = state.threeviewer.viewer

    if (viewer) {
      const nodeList: NodeList = fromThreeviewerSelectors.getNodeList(getState())
      const groupNode: any = existingGroupNode || new viewer.SceneGraph.SceneGraphNode3d()
      if (!existingGroupNode) {
        groupNode.userData = {
          ...groupNode.userData,
          ...INTERACTIONS.default.userData,
          isCombination: true,
          isGroup: true,
          name: 'Group',
          params: INTERACTIONS.default.params,
          id: uuid(),
          ...groupData
        }
      }
      viewer.addModel(groupNode, groupNode.userData.params)

      const selection = (nodesToGroup && Array.isArray(nodesToGroup)) ? nodesToGroup : Immutable.asMutable(state.selection.selection)
      const nodes = new Set<ISceneGraphNode3d | ISceneGraphMesh>()
      const parents = new Set<ISceneGraphNode3d>() // Stores parent nodes, later used to remove empty group nodes

      selection.forEach((uuid: string) => {
        let node = nodeList[uuid]

        if (!node.userData.isGroup) {
          node = viewer.viewerUtils.findRootNode(node)
        }

        nodes.add(node)
        parents.add(node.parent)
      })

      if (createCommand) {
        // A command is only created if grouping is done with the actual group option and not when it is called from within a command (undo/redo is pressed)
        // Since a command is moved between the undo/redo state and never "consumed" we would end up with adding more and more commands
        const command = createUngroupCommand({
          groupId: groupNode.uuid,
          groupNode,
          nodesToGroup: selection,
          group: (nodesToGroup, groupNode) => dispatch(group(nodesToGroup, false, groupNode)),
          ungroup: (groupId) => dispatch(ungroup(groupId, false))
        })
        // @ts-ignore Dunno why this is an error
        dispatch(fromUndoRedo.addCommand(command))
      }

      const sceneGraph = findLowestCommonAncestor(nodes)

      nodes.forEach((node) => {
        groupNode.addChild(node)
        dispatch(remove(node.uuid))
      })
      if (sceneGraph) {
        if (sceneGraph.parent === null) {
          groupNode.userData.isGroupRoot = true
          dispatch(add(groupNode))
        } else {
          const lowestGroup = findLowestGroup(sceneGraph) || sceneGraph
          sceneGraph.addChild(groupNode)
          dispatch(add(lowestGroup))
        }
      }
      dispatch(openGroup(groupNode.uuid))
      dispatch(fromSelection.select([groupNode], false))
      dispatch(scrollToNodeUuid(groupNode.uuid)) // Automatically scroll to new groups

      // Remove empty groups
      parents.forEach((parent) => {
        if (parent.userData.isGroup && parent.children.length === 0) {
          dispatch(ungroup(parent.uuid))
        }
      })
    }
  }
}

/**
 * Ungroup(remove) a provided groupId and select the children
 * Conditionally creates a undo/redo command.
 *
 * @param groupId Id of the group to be ungroup (removed)
 * @param createCommand Create a command (undo/redo event) for this action. Default is true
 */
export function ungroup (groupId: string, createCommand = true): AppThunk {
  return (dispatch, getState) => {
    const state = getState()
    const viewer = state.threeviewer.viewer
    if (viewer) {
      const nodeList: NodeList = fromThreeviewerSelectors.getNodeList(getState())
      const groupNode = nodeList[groupId]
      const hidden = Immutable.asMutable<string>(state.selection.hidden)
      const parent = groupNode.parent
      const children = groupNode.children
      const rootGroup = findLowestGroup(groupNode)
      if (hidden.includes(groupId)) {
        dispatch(fromSelection.toggleVisibility(groupId))
      }
      dispatch(remove(groupNode.uuid))

      children.forEach((child: any) => {
        parent.addChild(child)
        if (!rootGroup) dispatch(add(child))
      })

      viewer.objectTracker.removeObject(groupNode)
      parent.remove(groupNode)

      if (rootGroup) dispatch(add(rootGroup))

      if (createCommand) {
        // A command is only created if ungroup is done with the actual ungroup option and not when it is called from within a command (undo/redo is pressed)
        // Since a command is moved between the undo/redo state and never "consumed" we would end up with adding more and more commands
        const command = createGroupCommand({
          groupId: groupNode.uuid,
          groupNode,
          nodesToGroup: children.map((child: any) => child.uuid),
          group: (nodesToGroup, groupNode) => dispatch(group(nodesToGroup, false, groupNode)),
          ungroup: (groupId) => dispatch(ungroup(groupId, false))
        })
        // @ts-ignore Dunno why this is an error
        dispatch(fromUndoRedo.addCommand(command))
      }
      dispatch(fromSelection.select(children, false, false))
    }
  }
}

/**
 * Removes a node from an existing group
 *
 * @param nodeUuid The uuid of the node to remove
 */
export function ungroupNode (nodeUuid: string): AppThunk {
  return (dispatch, getState) => {
    const flatNodes = fromTreeSelectors.getFlatNodes(getState())
    const destinationUuid = flatNodes[flatNodes[nodeUuid].parent].parent
    dispatch(moveNode(nodeUuid, destinationUuid))
  }
}

/**
 * Updates the metadata of a specified node. This updates both the tree and the scene graph.
 *
 * NOTE: At the moment, only updating the name of the node is supported
 *
 * @param uuid The node to update
 * @param data The new metadata
 */
export function updateMetadata (
  uuid: string,
  data: { name: string }
): AppThunk {
  return (dispatch, getState) => {
    const nodeList = fromThreeviewerSelectors.getNodeList(getState())
    const node = nodeList[uuid]
    node.userData = {
      ...node.userData,
      ...data
    }

    dispatch({
      type: NODE_METADATA_UPDATED,
      payload: {
        uuid: uuid,
        value: data
      }
    })
  }
}

/**
 * Unlocks a virtual product. This updates the tree as well as the scene graph
 *
 * @param uuid The node to unlock
 */
export function unlockVirtualProduct (uuid: string): AppThunk {
  return (dispatch, getState) => {
    const state = getState()
    const nodeList = fromThreeviewerSelectors.getNodeList(state)
    const node = nodeList[uuid]

    if (node) {
      delete node.userData.isVirtualProductRoot
      delete node.userData.virtualProductId
      unlockRecursive(node.children as ISceneGraphNode3d[])
      dispatch(updateNode(node))
    }

    function unlockRecursive (children: ISceneGraphNode3d[]) {
      children.forEach((child) => {
        if (!child.userData.isVirtualProductRoot || !child.userData.virtualProductId) {
          if (child.isMesh) child.userData.setMaterial = true
          if (child.userData.setMaterial === false) child.userData.setMaterial = true
          unlockRecursive(child.children)
        }
      })
    }
  }
}

/**
 * Filter through selected nodes, remove only those that are allowed to be removed
 * If a node is not removable, leave it as selected, and remove the rest
 *
 * @param uuid The node that triggered the action (not necessarily the only node that will be removed)
 */
export function removeNodes (uuid: string): AppThunk {
  return (dispatch, getState) => {
    const selection = fromSelectionSelectors.getSelection(getState())
    const flatNodes = fromTreeSelectors.getFlatNodes(getState())

    const removableNodes = selection.map((uuid: string) => flatNodes[uuid]).filter((node: FlattenedNode) => node && node.canRemove)

    if (removableNodes.length > 1) {
      removableNodes.forEach((node: FlattenedNode) => {
        dispatch(fromCombinations.removeModel(node.uuid))
      })
    } else {
      dispatch(fromCombinations.removeModel(uuid))
    }
  }
}

/**
 * Opens or closes a single node
 *
 * @param uuid The node to open or close
 */
export function toggleOpen (uuid: string): AppThunk {
  return (dispatch) => {
    dispatch(toggleGroup(uuid))
  }
}

/**
 * Selects a single node
 *
 * @param uuid The node to select
 * @param selectMany Indicates if the pre-existing selection should be preserved or not
 */
export function select (uuid: string, selectMany: boolean): AppThunk {
  return (dispatch) => {
    dispatch(fromSelection.toggleSelection(uuid, selectMany, true, false))
  }
}

/**
 * Selects a node and focuses it in the visualizer
 *
 * @param uuid The node to select and focus
 */
export function selectFocus (uuid: string): AppThunk {
  return (dispatch) => {
    dispatch(fromSelection.doubleClickSelection(uuid, true, false))
  }
}

/**
 * Selects multiple nodes
 *
 * @param uuids The nodes to select
 */
export function selectFromUuids (uuids: string[]): AppThunk {
  return (dispatch) => {
    dispatch(fromSelection.selectFromUuids(uuids, false, false))
  }
}

/**
 * Drops the current selection on a specified node. Often triggered in the context of the
 * drag/drop grouping functionality.
 *
 * @param uuid The uuid to drop the selection on
 * @param selectMany If the current selection should be preserved
 */
export function dropSelection (uuid: string, selectMany: boolean): AppThunk {
  return (dispatch, getState) => {
    const node = fromTreeSelectors.getFlatNodes(getState())[uuid]
    const selection = fromSelectionSelectors.getSelection(getState())

    // If the drop area is a group add the selection to the existing group
    if (node.type === 'group') {
      selection.forEach((selectionUuid: string) => {
        dispatch(moveNode(selectionUuid, uuid))
      })
    } else {
      // Add selection to a new group
      select(uuid, selectMany)
      dispatch(group(_uniq([...selection, uuid])))
    }
  }
}

/**
 * Recalculates the flattened nodes.
 */
export function updateFlatNodes (): AppThunk {
  return (dispatch, getState) => {
    const nodes = fromTreeSelectors.getNodes(getState())
    const nodeList = fromThreeviewerSelectors.getNodeList(getState())
    const flatNodes = flattenNodes(nodes, nodeList)
    dispatch(flatNodesShouldUpdate(flatNodes))
  }
}

// State
const initialState = Immutable<{
  nodes: ITreeNodes
  flatNodes: FlattenedNodes
  openedNodeUuids: string[]
  scrollToNode: string | null
  isDragging: boolean
}>({
  nodes: {},
  flatNodes: {},
  openedNodeUuids: [],
  scrollToNode: null,
  isDragging: false,
})

export default function reducer (state = initialState, action: { type: string, payload: any }) {
  switch (action.type) {
    case OPEN_GROUP_NODE: {
      const groups = Array.isArray(action.payload) ? action.payload : [action.payload]
      let uuids: string[] = []

      groups.forEach(groupUuid => {
        uuids = [...uuids, ...findPath(groupUuid, state.nodes).filter((i: any) => i !== 'nodes')]
      })

      return state
        .merge({ openedNodeUuids: _uniq(uuids) })
        .setIn(['scrollToNode'], null)
    }

    case TOGGLE_GROUP_NODE: {
      const groups = Array.isArray(action.payload) ? action.payload : [action.payload]
      const uuids = _xor(state.openedNodeUuids, groups)
      return state
        .setIn(['openedNodeUuids'], uuids)
        .setIn(['scrollToNode'], null)
    }

    case CLOSE_ALL_GROUPS: {
      return state
        .setIn(['openedNodeUuids'], [])
        .setIn(['scrollToNode'], null)
    }

    case OPEN_ALL_GROUPS: {
      const uuids = Object.keys(state.getIn(['flatNodes']))
      return state
        .setIn(['openedNodeUuids'], uuids)
        .setIn(['scrollToNode'], null)
    }

    case SCROLL_TO_NODE_UUID: {
      return state.setIn(['scrollToNode'], action.payload)
    }

    case UPDATE_IS_DRAGGING: {
      return state.setIn(['isDragging'], action.payload)
    }

    case FLAT_NODES_SHOULD_UPDATE: {
      return state.setIn(['flatNodes'], action.payload)
    }

    /**
     * Listen for selection dispatches to detect when the tree should scroll to a new node.
     * This solution is a lot simpler than letting the tree keep track of when selection was
     * performed using the tree or using the viewer.
     */
    case fromSelection.SELECT: {
      // NOTE: Casting to any is necessary because the payload of selection dispatches is not,
      // for some reason, placed in the payload property.
      const {
        uuids,
        scrollToNode // Indicates if the tree should scroll or not
      } = (action as any)

      if (!scrollToNode || !uuids || !uuids.length) return state

      // Ugly casting is necessary due to seamless-immutable (not nice)
      const flatNodes = state.getIn(['flatNodes']) as unknown as FlattenedNodes
      const node = flatNodes[uuids[0]]

      if (!node) return state

      const openedNodeUuids = state.getIn(['openedNodeUuids'])
      const uuid = findNodeUuidToScrollTo(node, flatNodes, openedNodeUuids)

      return state.setIn(['scrollToNode'], uuid)
    }

    case NODE_METADATA_UPDATED: {
      const path = findPath(action.payload.uuid, state.nodes)

      const metaData = state.getIn(['nodes', ...path, 'metaData'])
      const newMetaData = metaData.merge(action.payload.value, { deep: true })
      return state
        .setIn(['nodes', ...path, 'metaData'], newMetaData)
    }

    case ADD: {
      return state
        .merge({
          nodes: action.payload,
        }, { deep: true })
    }

    case ADD_TO_GROUP: {
      const { node, group } = action.payload

      const path = findPath(group.uuid, state.nodes)
      if (path.length > 0) {
        return state
          .setIn(['nodes', ...path, 'nodes', node.uuid], node)
      }
      return state
        .setIn(['nodes', node.uuid], node)
    }

    case REMOVE: {
      const path = findPath(action.payload.uuid, state.nodes)

      if (action.payload.removeParent) {
        const parentPath = path
        if (path.length > 1) {
          parentPath.pop()
        }
        const without = _omit(state.nodes.asMutable({ deep: true }), parentPath.join('.'))

        return state
          .setIn(['nodes'], without)
      }

      const without = _omit(state.nodes.asMutable({ deep: true }), path.join('.'))
      return state
        .setIn(['nodes'], without)
    }

    case CLEAR:
      return initialState

    default:
      return state
  }
}
