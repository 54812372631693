import _sortBy from 'lodash/sortBy'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { HFB, MONTHS, PA, PROJECT_TYPES, PROJECT_TYPES_OPTIONS } from '../../constants'
import Button from '../common/button'
import Dropdown from '../common/form/input-dropdown'
import InputGroup from '../common/form/input-group'
import InputRadioGroup from '../common/form/input-radio-group'
import InputText from '../common/form/input-text'
import Label from '../common/form/label'

type Props = Omit<React.HTMLAttributes<HTMLElement>, 'onSubmit'> & {
  formTitle: String
  title: String
  projectTypeId: string
  HFB: String
  PA: String
  salesStart: Date
  PPM: String
  onSubmit: (data: ProjectFormData, id: string) => void
  onCancel: () => void
  projectId: string
}

interface ProjectTypeOption {
  label: String
  type: String
  id: String
}
interface Error {
  HFB: 'required' | false
  title: 'required' | false
  PPM: 'required' | false
}
interface SelectOptionType {
  label: String
  key: String
  value: String
}

export interface ProjectFormData {
  title?: String
  HFB?: String
  PA?: String,
  PPM?: String,
  salesStart?: String,
  projectType?: String,
  projectTypeId?: String
}
const DEFAULT_HFB:SelectOptionType = { label: 'Other', key: 'other', value: 'other' }
const OPTIONS_HFB = [DEFAULT_HFB].concat(
  _sortBy(Object.keys(HFB).map((key) => (
    { label: `${key} - ${(HFB as {[key: string]: string })[key]}`, key, value: key }
  )), 'key')
)

const OPTIONS_MONTHS = Object.keys(MONTHS).map((key) => (
  { label: (MONTHS as {[key: string]: string })[key], key, value: key }
))

const START_YEAR = (new Date()).getFullYear() - 5

const OPTIONS_YEARS = (new Array(20)).fill(1).map((_, index) => ({
  value: `${START_YEAR + index}`, key: index, label: `${START_YEAR + index}`
}))

const OPTIONS_PROJECT_TYPES = PROJECT_TYPES_OPTIONS.sort((a: ProjectTypeOption, b: ProjectTypeOption) => {
  if (a.id === PROJECT_TYPES.DEFAULT || b.id === PROJECT_TYPES.DEFAULT) {
    return a.id === PROJECT_TYPES.DEFAULT ? -1 : 1
  } else {
    return a.id === b.id ? 0 : (a.id > b.id ? 1 : -1)
  }
}).map((item: ProjectTypeOption) => ({
  key: item.id, value: item.id, label: item.label
}))
function getPAOptions (hfbId: String) {
  const options = Object.keys(PA as {[key: string]: string })
    .filter((key) => (hfbId ? key.substr(0, 2) === hfbId : true))
    .map((key) => ({ label: `${key} - ${(PA as {[key: string]: string })[key]}`, key, value: key }))

  return _sortBy(options, 'key')
}
function EditProjectForm (props: Props) {
  const [title, setTitle] = useState<String>(props.title)
  const [PA, setPA] = useState<String|undefined>(props.PA)
  const [PPM, setPPM] = useState<String>(props.PPM)
  const [HFB, setHFB] = useState<String>(props.HFB)
  const [salesStartYear, setSalesStartYear] = useState<String>()
  const [salesStartMonth, setSalesStartMonth] = useState<String>()
  const [projectTypeId, setProjectTypeId] = useState<String>(props.projectTypeId)
  const [error, setError] = useState<Error>()

  useEffect(() => {
    if (props.salesStart) {
      setSalesStartYear(moment(props.salesStart).format('YYYY'))
      setSalesStartMonth(moment(props.salesStart).format('M'))
    }
  }, [props.salesStart])
  useEffect(() => {
    setError({
      HFB: !HFB && 'required',
      title: !title && 'required',
      PPM: PPM === undefined && 'required',
    })
  }, [HFB, title, PPM])
  function handleConfirm (event: React.MouseEvent) {
    event.preventDefault()
    const data:ProjectFormData = {
      title,
      HFB,
      PA,
      PPM,
      projectType: PROJECT_TYPES.DEFAULT,
      projectTypeId,
    }
    if (!HFB || !title || PPM === undefined) {
      return setError({
        HFB: !HFB && 'required',
        title: !title && 'required',
        PPM: PPM === undefined && 'required',
      })
    }

    if (projectTypeId) {
      const foundProjectType = PROJECT_TYPES_OPTIONS.find(x => x.id === projectTypeId)
      data.projectType = foundProjectType ? foundProjectType.type : ''
      data.projectTypeId = projectTypeId
    }
    if (salesStartYear) {
      data.salesStart = `${salesStartYear}-${(salesStartMonth || 1)}-1`
    }
    props.onSubmit(data, props.projectId)
  }

  return (
    <div
      data-testid='edit-project-form'
    >
      <div>
        <h2 className='mt0'>
          {props.formTitle}
        </h2>
        <InputGroup>
          <Label className='flex justify-between'>
            <span>Title *</span>
            {error && error.title && <span className='c-error regular'>{error.title}</span>}
          </Label>
          <InputText
            onChange={(value: string) => {
              setTitle(value)
            }}
            defaultValue={title}
            placeholder='Title'
            data-testid='edit-project-title'
          />
        </InputGroup>

        <InputGroup>
          <Label>
          Project type
          </Label>
          <Dropdown
            className='width-100'
            onChange={setProjectTypeId }
            options={OPTIONS_PROJECT_TYPES}
            value={projectTypeId}
            data-testid='edit-project-project-types'
          />
        </InputGroup>

        <InputGroup className='flex'>
          <div className='pr1'>
            <Label className='flex justify-between'>
              <span>HFB</span>
            </Label>
            <Dropdown
              className='width-100'
              onChange={(value: string) => {
                setHFB(value)
                // reset chosen PA
                setPA(undefined)
              }}
              options={OPTIONS_HFB}
              value={HFB}
              data-testid='edit-project-hfb'
            />
          </div>

          <div className='pl1 width-50'>
            <Label>
            Product area (optional)
            </Label>
            <Dropdown
              disabled={!(HFB !== DEFAULT_HFB.value)}
              className='width-100'
              onChange={setPA}
              value={PA}
              options={[{ label: 'Choose product area' }].concat(getPAOptions(HFB || ''))}
              data-testid='edit-project-product-area'
            />
          </div>
        </InputGroup>

        <InputGroup>
          <div className='flex'>
            <div className='pr1 width-50'>
              <Label>
          Sales start (optional)
              </Label>
              <div className='flex items-center'>
                <Dropdown
                  className='width-100'
                  onChange={setSalesStartMonth}
                  value={salesStartMonth}
                  options={[{ label: 'Month' }].concat(OPTIONS_MONTHS)}
                  data-testid='edit-project-sales-start-month'
                />
                <div className='mx1'>/</div>
                <Dropdown
                  className='width-100'
                  onChange={setSalesStartYear}
                  value={salesStartYear}
                  options={[{ label: 'Year' }].concat(OPTIONS_YEARS)}
                  data-testid='edit-project-sales-start-year'
                />
              </div>
            </div>
            <div className='width-50'>
              <Label className='flex justify-between'>
                <span>PPM project exist *</span>
                {error && error.PPM && <span className='c-error regular'>{error.PPM}</span>}
              </Label>
              <div className='flex items-center'>
                <InputRadioGroup
                  name={'PPM'}
                  options={[
                    {
                      name: 'Yes',
                      label: 'Yes',
                      value: true,
                      selected: PPM
                    },
                    {
                      name: 'No',
                      label: 'No',
                      value: false,
                      selected: !PPM
                    }
                  ]}
                  direction={'horizontal'}
                  onChange={(val: string) => {
                    setPPM(val)
                  }}
                  data-testid='edit-project-ppm'
                />
              </div>
            </div>
          </div>
        </InputGroup>
        <div className="f8">* required</div>
        <div className='flex justify-end items-end mt3'>
          <Button onClick={props.onCancel}>
          Cancel
          </Button>
          <Button btnType='primary' disabled={!title || PPM === undefined} className='ml2' onClick={handleConfirm}>
          Save
          </Button>
        </div>
      </div>
    </div>
  )
}

export default EditProjectForm
