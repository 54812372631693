import fetch from '../../utils/fetch'

const assertError = (msg) => (err) => {
  console.error(msg)
  console.error(err)
  throw err
}

export const renders = (combinationIds) => (
  fetch('/api/spreadsheet/renders', {
    method: 'POST',
    body: JSON.stringify({ ids: combinationIds })
  })
    .then((res) => Promise.all([
      res.blob(),
      res.headers.get('content-disposition').replace('filename=', '')
    ]))
    .catch(assertError('unable to convert response to blob'))
    .then(([blob, filename]) => {
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.download = filename
      link.href = url
      link.click()
    })
    .catch(assertError('unable to download file'))
)

export const users = () => (
  fetch('/api/spreadsheet/users', {
    method: 'POST',
    body: JSON.stringify({})
  })
    .then((res) => Promise.all([
      res.blob(),
      res.headers.get('content-disposition').replace('filename=', '')
    ]))
    .catch(assertError('unable to convert response to blob'))
    .then(([blob, filename]) => {
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.download = filename
      link.href = url
      link.click()
    })
    .catch(assertError('unable to download file'))
)
