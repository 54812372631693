import React from 'react'

const IconScene = props => (
  <svg viewBox='0 0 20 16' width={props.size} height={props.size} {...props}>
    <path
      d='M1.35 1.35c-.067.067-.067 13.233 0 13.3.067.067 17.233.067 17.3 0 .067-.067.067-13.233 0-13.3-.067-.067-17.233-.067-17.3 0zM20 1.667V16H0V1.667 0H20v1.667zM3 13h4v-1H4V9H3v4zm14 0V9h-1v3h-3v1h4zM3 3v4h1V4h3V3H3zm14 0h-4v1h3v3h1V3z'
      fillRule='nonzero'
      fill={props.color}
    />
  </svg>
)

IconScene.defaultProps = {
  color: 'currentcolor',
  size: '1em'
}

export default IconScene
