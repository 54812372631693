import Immutable from 'seamless-immutable'
import { handleActions, createAction } from 'redux-actions'

import { v4 as uuid } from 'uuid'
import _isEmpty from 'lodash/isEmpty'
import _keyBy from 'lodash/keyBy'
import _orderBy from 'lodash/orderBy'
import _filter from 'lodash/filter'
import _get from 'lodash/get'

import * as fromProjectsSelectors from '../projects/selectors'

const assertError = (msg) => (err) => {
  console.error(msg)
  console.error(err)
  throw err
}

const failed = createAction('folders/FAILED')
const fetching = createAction('folders/FETCHING')
export const receive = createAction('folders/RECEIVE')
export const setCurrent = createAction('folders/SET_CURRENT')
export const removed = createAction('folders/REMOVED')
export const removedCombinations = createAction('folders/REMOVED_COMBINATIONS')
const removedPatterns = createAction('folders/REMOVED_PATTERNS')

const status = {
  PENDING: 'PENDING',
  FETCHING: 'FETCHING',
  FETCHED: 'FETCHED',
  FAILED: 'FAILED'
}

export const create = (folder) => (dispatch, getState, { api }) => {
  const _folder = {
    data: folder,
    projectId: fromProjectsSelectors.getCurrentId(getState()),
    id: folder.id || uuid()
  }

  return api.folders.create(_folder)
    .then((payload) => dispatch(receive([payload])))
    .catch(assertError('Unable to create folder', (err) => dispatch(failed(err))))
}

export function remove (id) {
  return (dispatch, getState, { api }) => {
    return api.folders.remove(id)
      .then(() => dispatch(removed(id)))
      .catch((err) => dispatch(failed(err)))
  }
}

export function update (data, id, projectId) {
  return (dispatch, getState, { api }) => {
    return api.folders.update({ data, id, projectId })
      .then((json) => dispatch(receive(json)))
      .catch((err) => dispatch(failed(err)))
  }
}

export function addConnectedIds (combinationIds, patternIds, folderId) {
  return (dispatch, _, { api }) => {
    return api.folders.addConnectedIds({ combinationIds, patternIds, folderId })
      .then((json) => dispatch(receive(json)))
      .catch((err) => dispatch(failed(err)))
  }
}

export function removeConnectedIds (combinationIds, patternIds, folderId) {
  return (dispatch, getState, { api }) => {
    return api.folders.removeConnectedIds({ combinationIds, patternIds, folderId })
      .then(() => dispatch(removedCombinations({ combinationIds, folderId })))
      .then(() => dispatch(removedPatterns({ patternIds, folderId })))
      .catch((err) => dispatch(failed(err)))
  }
}

export const getFolders = () => (dispatch, getState, { api }) => {
  const projectId = fromProjectsSelectors.getCurrentId(getState())
  const folders = api.folders.getByProject(projectId)
    .then((payload) => {
      payload = _filter(payload, folder => !folder.removedAt)
      dispatch(receive(payload))
    })
    .catch(assertError('Unable to get folders', err => dispatch(failed(err))))
  return _orderBy(folders, ['createdAt'], ['desc'])
}

const initialState = Immutable({
  status: status.PENDING,
  error: null,
  entries: Immutable({})
})

export default handleActions({
  [fetching]: (state) => state.merge({
    status: status.FETCHING
  }),
  [failed]: (state, { payload }) => state.merge({
    error: _isEmpty(payload) ? payload : { msg: 'Something went wrong :(' },
    status: status.FAILED
  }),
  [receive]: (state, { payload }) => state.merge({
    status: status.FETCHED,
    entries: _keyBy([].concat(payload), 'id')
  }, { deep: true }),
  [setCurrent]: (state, { payload }) => state.merge({
    currentId: payload
  }),
  [removed]: (state, { payload }) => state.merge({
    entries: state.entries.without(payload)
  }),
  [removedCombinations]: (state, { payload }) => {
    const combinations = _get(state, ['entries', payload.folderId, 'combinations']).without(payload.combinationIds)
    return state.setIn(['entries', payload.folderId, 'combinations'], combinations)
  },
  [removedPatterns]: (state, { payload }) => {
    const patterns = _get(state, ['entries', payload.folderId, 'patterns']).without(payload.patternIds)
    return state.setIn(['entries', payload.folderId, 'patterns'], patterns)
  }
}, initialState)
