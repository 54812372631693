import React, { useLayoutEffect, useState } from 'react'
import { v4 as uuid } from 'uuid'

import _difference from 'lodash/difference'
import _includes from 'lodash/includes'
import _map from 'lodash/map'
import _xor from 'lodash/xor'
import _intersection from 'lodash/intersection'

// JSX Components : Common
import InputGroup from '../../../common/form/input-group'
import Label from '../../../common/form/label'
import InputCheckbox from '../../../common/form/input-checkbox'
import IconArrowDown from '../../../common/icons/icon-arrow-down'

type Props = {
  label: string
  onChange: (options:any) => void
  tags: any
  selectedTags: any
  className?: string
  readOnly?: boolean
  errorMsg?: string
  presentKeys?: number[]
  isUpdated?: boolean
}

const NestedMultiSelect = ({ label, tags, selectedTags, onChange, className, readOnly, errorMsg, presentKeys, isUpdated }:Props) => {
  const [openGroups, setOpenGroups] = useState<string[]>([])
  const getChildKeys = (options: {key: number}[]) => _map(options, option => (option.key))
  const reducerConcatArrays = (accumulator:any[], currentValue:any) => accumulator.concat(currentValue)
  const allChildKeys : number[] = _map(tags, (subGroup) => getChildKeys(subGroup.options)).reduce(reducerConcatArrays, [])

  useLayoutEffect(() => {
    if (!readOnly) return setOpenGroups([])
    const open = tags.filter((tag:any) => tag.options.reduce((acc:any, val:any) => acc || selectedTags.includes(val.key), false)).map((tag:any) => tag.label)
    setOpenGroups(open)
    // isUpdated triggers to set the openGroups after saving changes
  }, [readOnly, isUpdated])

  const handleChildCheckbox = (values:string[]) => {
    onChange(values)
  }

  const handleParentChildrenCheckboxRelation = (childKeys:any) => {
    const allGroupOptionsAreChecked = _difference(childKeys, selectedTags).length === 0
    const unCheckedGroupOptions = _difference(childKeys, selectedTags)

    const _options = allGroupOptionsAreChecked ? childKeys : unCheckedGroupOptions
    handleChildCheckbox(_options)
  }

  const handleToggleGroupDropdown = (group:string) => {
    setOpenGroups(_xor(openGroups, [group]))
  }

  const isGroupOpen = (group:string) => {
    return _includes(openGroups, group)
  }

  return <div className={className}>
    <InputGroup
      className='width-100 mr2'>
      <Label className='flex justify-between'>
        <span>{label}</span>
        {errorMsg && (_intersection(selectedTags, allChildKeys).length < 1) && <span className='c-error regular'>{'Required'}</span>}

      </Label>
      <div className='height-100 width-100 overflow-x-hidden overflow-y-auto custom-scrollbar'>
        {_map(tags, (subGroup) => {
          const childKeys = getChildKeys(subGroup.options)
          const checked = _intersection(childKeys, selectedTags).length > 0
          return (<div
            style={ readOnly ? { pointerEvents: 'none' } : {} }
            key={subGroup.label}
            className='flex flex-column bc-gray-light br-2 p1 bg-white'
          >
            <div
              className='flex justify-between items-center'
            >
              <InputCheckbox
                id={uuid()}
                label={subGroup.label}
                checked={checked}
                onChange={() => handleParentChildrenCheckboxRelation(childKeys)}
                noMargin
                selected={true}
                disabled={presentKeys ? childKeys.reduce((accumulator:any, item:any) => {
                  return accumulator && (!presentKeys.includes(item))
                }, true) : false}

              />
              {!readOnly && <IconArrowDown
                onClick={() => handleToggleGroupDropdown(subGroup.label)}
                className='border-left c-gray-light bc-gray-light pointer'
                size={'1.6rem'}
                style={{
                  paddingLeft: '10px',
                  paddingRight: '2px'
                }}
              />}
            </div>
            {isGroupOpen(subGroup.label) && (
              <div className='ml2 pt1'>
                {_map(subGroup.options, (item) => (
                  <InputCheckbox
                    key={item.value}
                    id={item.value}
                    label={item.label}
                    checked={selectedTags.includes(item.key)}
                    onChange={() => (handleChildCheckbox([item.key]))}
                    disabled={presentKeys ? !presentKeys.includes(item.key) : false}
                  />)
                )}
              </div>
            )}
          </div>)
        }
        )}
      </div>
    </InputGroup>
  </div>
}

export default NestedMultiSelect
