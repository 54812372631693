const THREE = require('three')

const outlineShader = {

  uniforms: {
    tDiffuse: { value: null },
    maskTexture: { value: null },
    edgeColor: { value: new THREE.Vector3(1.0, 1.0, 1.0) },
    edgeWidth: { value: 2.0 },
    texelSize: { value: new THREE.Vector2(0.0, 0.0) }
  },

  vertexShader: `
    varying vec2 vUv;
    void main() {
      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
    }
  `,

  fragmentShader: `
    varying vec2 vUv;
    uniform sampler2D tDiffuse;
    uniform sampler2D maskTexture;
    uniform vec2 texelSize;
    uniform vec3 edgeColor;
    uniform float edgeWidth;
    
    #define saturate(a) clamp( a, 0.0, 1.0 )

    void main() {
      vec4 mask = texture2D(maskTexture, vUv);
      vec4 maskU = texture2D(maskTexture, vUv + vec2(0.0, texelSize.y * edgeWidth));
      vec4 maskR = texture2D(maskTexture, vUv + vec2(texelSize.x * edgeWidth, 0.0));
      vec4 maskD = texture2D(maskTexture, vUv + vec2(0.0, -texelSize.y * edgeWidth));
      vec4 maskL = texture2D(maskTexture, vUv + vec2(-texelSize.x * edgeWidth, 0.0));
      vec4 maskRU = texture2D(maskTexture, vUv + vec2(texelSize.x*edgeWidth, texelSize.y*edgeWidth));
      vec4 maskRD = texture2D(maskTexture, vUv + vec2(texelSize.x*edgeWidth, -texelSize.y*edgeWidth));
      vec4 maskLD = texture2D(maskTexture, vUv + vec2(-texelSize.x*edgeWidth, -texelSize.y*edgeWidth));
      vec4 maskLU = texture2D(maskTexture, vUv + vec2(-texelSize.x*edgeWidth, texelSize.y*edgeWidth));

      vec4 background = texture2D(tDiffuse, vUv);

      float alpha = saturate(maskU.a + maskR.a + maskD.a + maskL.a + maskRU.a + maskRD.a + maskLD.a + maskLU.a);

      float value = float(alpha > 0.5 && mask.a < 0.5);

      gl_FragColor = mix(background, vec4(edgeColor.rgb, 1.0), value);
    }
  `
}

class OutlinePostProcess {
  constructor (width, height) {
    this.outlinePass = new THREE.ShaderPass(outlineShader)
    this.outlineMaskMaterial = createOutlineMaskMaterial()
    this.outlineMaskMaterial.side = THREE.DoubleSide
    this.enabled = false

    this.edgeColor = new THREE.Color(1, 0, 0)
    this.edgeWidth = 2.0
    this.outlinePass.uniforms.edgeColor.value = this.edgeColor
    this.outlinePass.uniforms.edgeWidth.value = this.edgeWidth

    this.outlineObjectsScene = new THREE.Scene()
    this.outlineObjectsScene.autoUpdate = false

    this.outlineMaskBuffer = new THREE.WebGLRenderTarget({ depthBuffer: false, format: THREE.RGBAFormat })
    this.outlinePass.uniforms.maskTexture.value = this.outlineMaskBuffer.texture

    this.setSize(width, height)
  }

  dispose () {
    this.outlinePass.dispose()
    this.outlineMaskMaterial.dispose()
    this.outlineMaskBuffer.dispose()
    this.outlinePass = null
    this.outlineMaskMaterial = null
    this.outlineMaskBuffer = null
    this.outlineObjectsScene = null
  }

  setSize (width, height) {
    this.outlinePass.uniforms.texelSize.value.set(1.0 / width, 1.0 / height)
    this.outlineMaskBuffer.setSize(width, height)
  }

  setEdgeWidth (value) {
    this.edgeWidth = value
    this.outlinePass.uniforms.edgeWidth.value = value
  }

  render (renderer, buffers, camera, outlineObjects) {
    if (outlineObjects.length === 0) return

    const input = buffers.input
    const output = buffers.output

    const oldAlphaClear = renderer.getClearAlpha()
    renderer.setClearAlpha(0)

    this.outlineObjectsScene.children = outlineObjects
    this.outlineObjectsScene.overrideMaterial = this.outlineMaskMaterial
    renderer.setRenderTarget(this.outlineMaskBuffer)
    renderer.clear()
    renderer.render(this.outlineObjectsScene, camera)
    this.outlineObjectsScene.overrideMaterial = null
    this.outlineObjectsScene.children = []

    renderer.setClearAlpha(oldAlphaClear)

    this.outlinePass.render(renderer, output, input, 0, false)

    buffers.input = output
    buffers.output = input
  }
}

module.exports = OutlinePostProcess

function createOutlineMaskMaterial () {
  return new THREE.ShaderMaterial({
    vertexShader: `
      void main() {
        gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
      }
    `,

    fragmentShader: `
      void main() {
        gl_FragColor = vec4(1.0, 1.0, 1.0, 1.0);
      }
    `
  })
}
