import { combineReducers } from 'redux'

import json from './json'
import textures from './textures'
import convert from './convert'

export default combineReducers({
  json,
  textures,
  convert
})
