import Immutable from 'seamless-immutable'
import { createAction, handleActions } from 'redux-actions'

import _keyBy from 'lodash/keyBy'
import _get from 'lodash/get'
import _filter from 'lodash/filter'
import _map from 'lodash/map'
import _uniq from 'lodash/uniq'
import _isUndefined from 'lodash/isUndefined'

import * as fromThreeviewerFiles from '../threeviewer/files'
import * as fromThreeviewerUI from '../threeviewer/ui'
import * as fromDefaultTemplate from '../templates/default-template'
import * as fromUndoRedo from '../undo-redo'

import * as materialCommands from './commands'
import * as materialUtils from './utils'

// State selectors
import * as fromMaterialsSelectors from '../materials/selectors'
import * as fromThreeviewerSelectors from '../threeviewer/selectors'
import * as fromRoomsetSelectors from '../roomsets/selectors'

const assign = Object.assign

const ERROR_MATERIAL = {
  id: 'ERROR_MATERIAL',
  repeat: {
    x: 1,
    y: 1
  },
  metalness: 0,
  roughness: 1,
  materialType: 'triplanarMaterial',
  textures: {
    map: {
      type: 'map',
      uri: '/img/error-pattern.png'
    }
  }
}

// 3 Actions
export const receiveJson = createAction('materials/json/RECEIVE')
const replaceSingleMaterialJson = createAction('materials/json/REPLACE_SINGLE')
const fetchedInitialJson = createAction('materials/json/FETCHED_INTIIAL')
const receiveMaterial = createAction('materials/webgl/RECEIVE')
const error = createAction('materials/ERROR')
export const dispose = createAction('materials/DISPOSE')
const _setEnvMapSettings = createAction('materials/SET_ENVMAP_SETTINGS')
const confirmMaterialUploadProgress = createAction('materials/json/RECEIVE_UPLOAD_PROGRESS')
export const receive = createAction('materials/RECIVE')
const receiveCarriers = createAction('materials/carriers/GET_ALL')

export const setEnvMapSettings = (settings) => (dispatch, getState) => {
  const viewer = fromThreeviewerSelectors.getViewer(getState())
  const { intensity } = settings
  const isRoomsetActive = fromRoomsetSelectors.getIsRoomsetActive(getState())

  viewer.scene.traverse((child) => {
    if (!child.material) return
    const rootNode = viewer.viewerUtils.findRootNode(child)

    if (isRoomsetActive) {
      if (intensity) { child.material.envMapIntensity = intensity }
      child.material.useLocalEnvMap = false
      return
    }

    if (child.material.isTriplanarMaterial) {
      if (rootNode.userData.isTemplate) {
        child.material.useLocalEnvMap = false
      } else {
        child.material.envMapIntensity = 1
        child.material.useLocalEnvMap = true
      }
    }
  })
  dispatch(_setEnvMapSettings(settings))
}

const fetchMaterial = (id) => async (dispatch, getState, { api }) => {
  const json = await api.materials.get(id)
  if (json) {
    dispatch(receiveJson(json))
    return json
  }

  return ERROR_MATERIAL
}

export const fetchCarriers = () => async (dispatch, getState, { api }) => {
  const json = await api.materials.getAllCarriers()
  dispatch(receiveCarriers(json))
}

export const prefetchPreloadMaterials = (ids) => (dispatch, getState) => {
  const state = getState()
  const envMap = fromMaterialsSelectors.getEnvMap(state)

  const fetchIds = _uniq(_filter(ids, (id) => {
    const material = fromMaterialsSelectors.getMaterialById(id, state)
    const materialJson = fromMaterialsSelectors.getJsonEntryById(id, state)

    return !material || !materialJson
  }))

  return Promise.resolve()
    .then(() => {
      if (!envMap) {
        return dispatch(loadEnvMap())
      }
    })
    .then(() => {
      return Promise.all(_map(fetchIds, (id) => {
        return dispatch(fetchMaterial(id))
          .then((materialJson) => dispatch(loadMaterial(materialJson, id)))
      }))
    })
}

function materialUploadProgress (progress, type, name) {
  return confirmMaterialUploadProgress({ progress, type, name })
}

export const getMaterialsJSON = ({ initialFetch, ...params } = {}) => (dispatch, getState, { api }) => {
  if (fromMaterialsSelectors.getShouldFetch(initialFetch)(getState())) {
    return api.materials.getAll(params)
      .then((json) => {
        if (initialFetch) {
          dispatch(fetchedInitialJson())
        }
        dispatch(receiveJson(json))
      })
      .catch((err) => dispatch(error(err)))
  }
}

const loadMaterialFromViewer = (viewer, materialJson, loadFailed = false) => new Promise((resolve) => {
  viewer.materialLoader.loadMaterial(materialJson, (err, material) => {
    if (err) {
      if (!loadFailed) {
        loadMaterialFromViewer(viewer, ERROR_MATERIAL, true)
          .then(resolve)
      } else {
        resolve(null)
      }
    } else {
      resolve(material)
    }
  })
})

// This will load the envMap (required for proper material functioning)
// If you need to load multiple materials (and preload the required envMap), check out prefetchPreloadMaterials instead
export function loadMaterial (materialJson, materialId) {
  return (dispatch, getState) => {
    const state = getState()
    const viewer = fromThreeviewerSelectors.getViewer(state)

    const envMapIntensity = (
      _get(state, 'materials.envMapSettings.intensity') ||
      _get(state, 'threeviewer.settings.envMap.intensity', 1)
    )

    if (!materialJson) {
      materialJson = ERROR_MATERIAL
    }

    const textureUris = _map(materialJson.textures, (texture) => (texture.uri))
    const materialName = materialJson.name || materialJson.id
    dispatch(fromThreeviewerFiles.loadFile({
      id: materialId,
      name: materialName,
      type: 'material',
      uris: textureUris
    }))

    return new Promise((resolve) => {
      loadMaterialFromViewer(viewer, materialJson)
        .then((material) => {
          if (!material) {
            return resolve(null)
          }

          textureUris.forEach((uri) => {
            dispatch(fromThreeviewerFiles.fileProgress({
              file: uri,
              progress: 100,
              type: 'material'
            }))
          })

          resolve(assign(material, {
            name: materialName,
            materialId: materialId
          }))
        })
    })
      .then(material => {
        if (material) {
          material.envMapIntensity = envMapIntensity

          dispatch(receiveMaterial({ [materialId]: material }))
          return material
        }
      })
      // TODO: Where should catch go?
      .catch((err) => dispatch(error(err)))
  }
}

function getRandomTriplanarTranslation () {
  const translations = [[0.03, 0.4, 0.43], [0.63, 0.28, 0.57], [0.01, 0.22, 0.09], [0.42, 0.85, 0.43], [0.09, 0.37, 0.98]]
  return translations[Math.floor(Math.random() * translations.length)]
}

/**
 * Transfers a clone of a material from a node to another node,
 * along with the relevant metadata.
 */
export const transferMaterial = (sourceNode, targetNode) => {
  return (dispatch) => {
    const materialId = sourceNode.userData.materialId
    const parts = { [targetNode.uuid]: targetNode }

    if (!materialId) return

    dispatch(
      setMaterial(materialId, parts, false)
    ).then(() => {
      // Copy all material properties to the target node (Note: material.copy copies properties TO the argument material, not to the caller)
      sourceNode.material.copy(targetNode.material)

      // And update relevant userData. This is required to make sure toolbar values update correctly

      const sourceUserData = sourceNode.userData
      const targetUserData = targetNode.userData

      targetUserData.colorId = sourceUserData.colorId
      targetUserData.colorName = sourceUserData.colorName

      targetUserData.patternFilePath = sourceUserData.patternFilePath
      targetUserData.patternId = sourceUserData.patternId

      sourceUserData.decalScale && (
        targetUserData.decalScale = sourceUserData.decalScale.clone()
      )

      sourceUserData.mapRepeat && (
        targetUserData.mapRepeat = sourceUserData.mapRepeat.clone()
      )

      sourceUserData.mapRotation && (
        targetUserData.mapRotation = [...sourceUserData.mapRotation]
      )

      sourceUserData.mapOffset && (
        targetUserData.mapOffset = sourceUserData.mapOffset.clone()
      )

      targetUserData.uvMapRotation = sourceUserData.uvMapRotation
      targetUserData.uvFlipped = sourceUserData.uvFlipped
    })
  }
}

export const applyMaterial = ({ node, material, materialJson }) => {
  // Set map rotation
  material.colorTextureMix = node.userData.colorTextureMix ? node.userData.colorTextureMix : 0
  const materialType = material.useTriplanar ? 'triplanarMaterial' : 'standardMaterial'

  if (material.useTriplanar) {
    if (node.userData.mapRotation) {
      material.setMapRotationXYZ(...node.userData.mapRotation)
    } else {
      material.setDefaultMapRotationFromBoundingBox(node.geometry.boundingBox)
      node.userData.mapRotation = [
        material.mapRotationX,
        material.mapRotationY,
        material.mapRotationZ
      ]
    }

    if (node.userData.triplanarOrientation) {
      const { x, y, z } = node.userData.triplanarOrientation
      material.triplanarOrientation.set(x, y, z)
    }

    if (node.userData.triplanarTranslation) {
      const { x, y, z } = node.userData.triplanarTranslation
      material.triplanarTranslation.set(x, y, z)
    } else if (
      node.userData.isCombination &&
      materialJson.type === 'wood'
    ) {
      const [x, y, z] = getRandomTriplanarTranslation()
      material.triplanarTranslation.set(x, y, z)
      node.userData.triplanarTranslation = material.triplanarTranslation
    }
  }

  if (typeof node.userData.uvMapRotation !== 'undefined') {
    material.uvMapRotation = node.userData.uvMapRotation
  }

  if (node.userData.mapOffset) {
    const { x, y } = node.userData.mapOffset.clone()
    material.mapOffset.set(x, y)
  }

  // TODO: src\web\src\assets\js\stores\ducks\combinations\actions\load-combination applies some of the userdata relating to materials,
  // such as userDecalRotation decalUVTransform and colorTexturemix, ideally it should all be handled from here , as all model combinations takes this route (?)

  // Assign userData

  const materialName = materialJson.name || materialJson.displayName
  Object.assign(node.userData, {
    materialId: materialJson.id,
    materialName,
    materialSource: materialJson.source,
    materialType: materialType,
    canSetColor: materialJson.canSetColor,
    changed: true
  })

  let decalMap
  let mirrorRotation
  let userDecalRotation
  let decalScale
  let decalRotation
  let decalUVTransform
  let decalDimensions

  if (node.userData.patternId) {
    decalMap = node.material.decalMap
    decalUVTransform = node.material.decalUVTransform.clone()
    userDecalRotation = node.material.userDecalRotation
    decalScale = node.material.decalScale
    decalRotation = node.material.decalRotation
    mirrorRotation = node.material.mirrorRotation
    decalDimensions = node.material.decalDimensions
  }
  // Set material
  node.material = material

  // reset color
  node.userData.colorId = null
  node.userData.colorName = null

  if (node.userData.patternId) {
    node.material.decalDimensions = decalDimensions
    node.material.decalMap = decalMap
    node.material.decalUVTransform = decalUVTransform
    node.material.mirrorRotation = mirrorRotation
    node.material.transformDecal(
      undefined,
      decalScale,
      decalRotation,
      userDecalRotation
    )
  }

  if (node.userData.uvFlipped && node.material.map) {
    node.material.map.flipY = false
  }
}

export function getMaterialById (materialId) {
  return (dispatch) => {
    return dispatch(fetchMaterial(materialId))
  }
}

/**
 * Used for PLM integration
 * @param {*} list
 * <{cadNumber: string, materialIds: string[]}>[]
 */
export function setMaterialFromPartId (list) {
  // recursive method to get all child nodes flat
  const getChildNodes = (node) => {
    if (!node) return []
    if (node.children.length > 0) {
      let children = [node]
      node.children.forEach(childNode => {
        children = children.concat(getChildNodes(childNode))
      })
      return children
    } else return [node]
  }

  return async (dispatch, getState) => {
    const state = getState()
    const nodeList = fromThreeviewerSelectors.getNodeList(state)
    const selection = state.selection.selection

    // get all tree nodes which are selected
    let sources = []
    if (selection) {
      selection.forEach(uuid => {
        const node = nodeList[uuid]
        sources = sources.concat(getChildNodes(node))
      })
    }

    const includedNode = (partName, node) => {
      const nodeName = node?.userData?.ptc_wm_number || node?.userData?.name
      if (!partName) return false
      if (nodeName && nodeName === partName) return true
      else return false
    }

    // map tree nodes to matching items in list
    let nodesToSetMaterialOn = []
    list.forEach(({ cadNumber, icomId }, index) => {
      nodesToSetMaterialOn[index] = { nodes: [], material: icomId }

      sources.forEach((node) => {
        if (includedNode(cadNumber, node)) {
          node.traverse((child) => {
            if (child.isMesh) {
              nodesToSetMaterialOn[index].nodes.push(child)
            }
          })
        }
      })
    })

    // filter away materials which have no selected nodes
    nodesToSetMaterialOn = nodesToSetMaterialOn.filter((obj) => obj.nodes.length > 0)

    // apply the materials to the selected nodes
    for (const obj of nodesToSetMaterialOn) {
      await dispatch(setMaterial(obj.material, obj.nodes))
    }
  }
}

export function setCarrier (carrierId, parts) {
  return (dispatch, getState) => {
    const state = getState()
    const viewer = fromThreeviewerSelectors.getViewer(state)
    const { picker } = viewer

    const nodes = Object.values(parts || picker.selection)
    nodes.map(data => {
      Object.assign(data.userData, {
        carrier_id: carrierId,
        carrierName: carrierId,
        changed: true
      })
    })
    dispatch(fromThreeviewerUI.forceUpdate())
    dispatch(fromThreeviewerUI.forceUpdateTree()) // The tree is updated separately from the rest of the UI
  }
}

export function setMaterial (materialId, parts, addToUndoHistory = true) {
  return (dispatch, getState) => {
    const state = getState()
    const viewer = fromThreeviewerSelectors.getViewer(state)
    const { viewerUtils, picker } = viewer

    let materialJson = fromMaterialsSelectors.getJsonEntryById(materialId, state)
    parts = parts || picker.selection

    return Promise.resolve()
      .then(() => {
        if (materialJson) return materialJson
        return dispatch(fetchMaterial(materialId))
          .then((result) => {
            materialJson = result
            return result
          })
      })
      .then((materialJson) => {
        return dispatch(loadMaterial(materialJson, materialId))
      })
      .then((material) => {
        if (!material) {
          return Promise.resolve()
        }
        const nodeGroups = materialUtils.getNodesToSetMaterialOn(_map(parts))

        let oldValues
        let newValues

        if (addToUndoHistory) {
          // Save values before setting new material
          oldValues = materialUtils.getMaterialValuesFromNodeGroups(nodeGroups)
        }
        const materialJsonClone = { ...materialJson }

        // Set new material base on nodeGroup
        nodeGroups.forEach((group) => {
          const { node, meshes } = group

          // Create material clone
          const materialClone = material.clone()
          // Set mapRepeat
          const rootNode = viewerUtils.findRootNode(node)

          const meterScale = material.useTriplanar ? 1.0 : 0.001
          material.meterScale = meterScale

          // Modelbank models have good uv-mapping, and should have a StandardMeshMaterial applied
          // to them. Instead of having a duplicate set of materialbank materials in db (one with
          // materialTypes set to 'standardMaterial' for modelbank models and the other with
          // 'triplanarMaterial' or 'decalMaterial' materialType for cad-models or soft products)
          // we override the materialType of the materialJson used to load the material onto the
          // modelbank model here.
          const nodeIds = node.userData ? _get(node, 'userData.nodeId', '').match(/(MAT)([0-9])+(?=V1)/g) : []
          const isOriginalAssignment = ((nodeIds) && (+nodeIds[0].replace('MAT', '') === +materialJson.id))
          const isOldModel = (_get(rootNode, 'userData.article.articlenr', 'artnr') === _get(rootNode, 'userData.dbModelId'))
          const isSoft = (_get(rootNode, 'userData.productType', 'hard') === 'soft') || materialJsonClone.type === 'pile'
          const isUga = (_get(rootNode, 'userData.modelSource', '') === 'ugabank')
          const isModelBank = (_get(rootNode, 'userData.modelSource', '') === 'modelbank')
          const useTriplanar = _get(node, 'userData.useTriplanar', true)

          if (isOriginalAssignment || isOldModel || isUga || isModelBank) {
            // modelbank and ugabank, NO applied materials
            materialJsonClone.materialType = 'decalMaterial' // TODO: can we remove this?? shouldn't be needed
            materialClone.useTriplanar = false
            materialClone.meterScale = 1
          } else if (isSoft || !useTriplanar) {
            // UV-mapped uploaded stuff
            materialClone.useTriplanar = false
            materialClone.meterScale = 0.001
          } else {
            // CADs, modelbank with APPLIED materials
            materialClone.useTriplanar = true
          }

          const mixValueObj = new materialUtils.ColorTextureMixVal(1.0)
          const colorTextureMix = mixValueObj.value

          if (materialClone.useTriplanar) {
            materialClone.mapOffset.set(0, 0)
            const mapRepeat = material.mapRepeat.clone()

            // Floor is scaled in x and z, not x and y, so we have
            // to replace y value for the multiplication to work
            const shouldReplaceScaleY = _get(rootNode, 'userData.isTemplate', false) && _get(rootNode, 'userData.dbModelId') === 'floor'

            if (shouldReplaceScaleY) {
              mapRepeat.multiply(rootNode.scale.clone().set(rootNode.scale.x, rootNode.scale.z, rootNode.scale.z))
            } else {
              mapRepeat.multiply(rootNode.scale)
            }

            node.userData.mapRepeat = mapRepeat
            materialClone.mapRepeat.copy(mapRepeat)
          }

          if (materialJsonClone.type !== 'wood' && !_isUndefined(node.userData.colorTextureMix)) {
            delete node.userData.colorTextureMix
          }

          // Apply cloned material on meshes in group
          meshes.forEach((mesh) => {
            if (!_get(mesh, 'userData.mapRotation') && _get(node, 'userData.mapRotation')) {
              mesh.userData.mapRotation = node.userData.mapRotation
            }
            if (_get(node, 'userData.colorTextureMix')) {
              mesh.userData.colorTextureMix = colorTextureMix
            }
            applyMaterial({
              node: mesh,
              material: materialClone,
              materialJson: materialJsonClone
            }, viewer.THREE)
          })
        })

        if (addToUndoHistory) {
          // Save values after setting new material
          newValues = materialUtils.getMaterialValuesFromNodeGroups(nodeGroups)

          const command = materialCommands.createSetMaterialCommand({
            newValues,
            oldValues,
            setMaterial (value) {
              value.node.material = value.material
              value.node.userData = value.userData
            },
            afterUndo () {
              dispatch(fromThreeviewerUI.forceUpdate())
              dispatch(fromThreeviewerUI.forceUpdateTree())
            },
            afterExecute () {
              dispatch(fromThreeviewerUI.forceUpdate())
              dispatch(fromThreeviewerUI.forceUpdateTree())
            }
          })

          dispatch(fromUndoRedo.addCommand(command))
        }
      })
      .then(() => {
        dispatch(fromThreeviewerUI.forceUpdate())
        dispatch(fromThreeviewerUI.forceUpdateTree())

        const didTemplatesChange = _filter(parts, (part) => _get(part, 'userData.isTemplate')).length

        if (didTemplatesChange) {
          dispatch(fromDefaultTemplate.updateTemplateMaterial())
        }
      })
      .then(() => {
        const isRoomsetActive = fromRoomsetSelectors.getIsRoomsetActive(getState())
        var utils = viewer.viewerUtils
        viewer.scene.traverse(function (child) {
          if (child.isMesh) {
            const rootNode = utils.findRootNode(child)
            if (child.material.isTriplanarMaterial) {
              if (!rootNode.userData.isTemplate && !isRoomsetActive) {
                child.material.envMapIntensity = 1
                child.material.useLocalEnvMap = true
              } else {
                child.material.useLocalEnvMap = false
              }
            }
          }
        })
      })
  }
}

export function loadEnvMap (prefix = '/envmaps/', postfix = '.exr') {
  return (dispatch, getState) => {
    const state = getState()
    const viewer = fromThreeviewerSelectors.getViewer(state)
    return new Promise((resolve) => {
      resolve(viewer.setEnvMap(prefix, postfix))
    })
  }
}

// 4 Reducer
var initialState = {
  materials: {},
  carriers: [],
  materialsJson: Immutable({}),
  materialsProgress: Immutable({}),
  error: null,
  hasFetchedInitial: false,
  envMapSettings: Immutable({}),
  entries: {}
}

export default handleActions({
  [dispose]: () => initialState,
  [_setEnvMapSettings]: (state, { payload }) => ({
    ...state,
    envMapSettings: state.envMapSettings.merge(payload)
  }),
  [error]: (state, { payload }) => ({
    ...state,
    error: payload
  }),
  // json handlers
  [fetchedInitialJson]: (state) => ({
    ...state,
    hasFetchedInitial: true
  }),
  [receiveJson]: (state, { payload }) => ({
    ...state,
    materialsJson: state.materialsJson.merge(_keyBy([].concat(payload), 'id'), { deep: true })
  }),
  [replaceSingleMaterialJson]: (state, { payload }) => ({
    ...state,
    materialsJson: state.materialsJson.set(payload.id, payload, { deep: true })
  }),
  [materialUploadProgress]: (state, { payload }) => ({
    ...state,
    materialsProgress: state.materialsProgress.merge({
      [payload.name]: { type: payload.type, progress: payload.progress }
    })
  }),
  // webGL handlers
  [receiveMaterial]: (state, { payload }) => ({
    ...state,
    materials: assign(state.materials, payload)
  }),
  [receive]: (state, { payload }) => {
    return state.merge({
      error: null,
      entries: _keyBy([].concat(payload || []), 'id')
    }, { deep: true })
  },
  [receiveCarriers]: (state, { payload }) => ({
    ...state,
    carriers: payload
  }),

}, initialState)
