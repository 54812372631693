// State selectors
import { v4 as uuid } from 'uuid'
import * as fromThreeviewerSelectors from '../../threeviewer/selectors'
import _get from 'lodash/get'
import _isUndefined from 'lodash/isUndefined'

// Actions
import * as fromSelection from '../../selection'
import { loadCombination } from './load-combination'
import { fetchForVisualize } from './json'
import * as fromDefaultTemplate from '../../templates/default-template'
import { addCommand } from '../../undo-redo'
import * as fromTree from '../../tree'
import { load, loaded } from '../../threeviewer/files'
import DEFAULT_INTERACTIONS from '../interactions'
import { reapplyAppearancesAndMaterials } from './reapply-appearances-and-materials'
import { hide, show } from '../../selection/index'
import { getTrueRoot, getTrueRoots } from '../../tree/utils'

const getInstanceObjects = (children, instanceIds) => {
  return children.reduce((acc, node) => {
    const userData = node.userData
    if (
      _isUndefined(userData.instanceId) ||
      !instanceIds.includes(userData.instanceId)
    ) return acc

    return userData.isModelRoot
      ? acc.concat(node)
      : acc.concat(node.children.filter((n) => n.userData.isModelRoot))
  }, [])
}

export const replaceModel = (
  combinationId,
  reapplyAppearances = true,
  reapplyMaterials = true,
  reapplyUsingMetadata = true,
  reapplyUsingBoundingBoxes = false
) => async (dispatch, getState) => {
  const state = getState()
  const viewer = fromThreeviewerSelectors.getViewer(state)
  const utils = viewer.viewerUtils
  const transformGizmo = viewer.transformGizmo
  const THREE = viewer.THREE

  const selectedRootNodes = []
  const uuidsToHide = []

  // Groups each selected node to their corresponding root node
  // Useful for setting correct rotations for multi-model variants
  const rootNodeToSelectionMap = {}

  Object.values(viewer.picker.selection).forEach(node => {
    let rootNode = utils.findRootNode(node)

    // Needs to be here as the RootNode of variants ISN'T the same as the actual model rootNode
    // Thus we need to have this check in order to avoid potential bugs with variants consisting of multiple models.
    rootNode = getTrueRoot(rootNode)
    if (rootNode && !selectedRootNodes.includes(rootNode)) {
      selectedRootNodes.push(rootNode)
      uuidsToHide.push(rootNode.uuid)
    }

    if (!rootNodeToSelectionMap[rootNode.uuid]) {
      rootNodeToSelectionMap[rootNode.uuid] = []
    }

    rootNodeToSelectionMap[rootNode.uuid].push(node)
  })

  const selectedCenter = new viewer.THREE.Vector3()
  const replacementCenter = new viewer.THREE.Vector3()

  const combination = await dispatch(
    fetchForVisualize(
      combinationId,
      { initialLoad: false }
    )
  )

  const replaceWith = []

  for (const selected of selectedRootNodes) {
    const instanceId = uuid()
    dispatch(load('combination', instanceId))
    await dispatch(loadCombination({
      instanceId,
      combination,
      isComplementary: true,
      interactions: DEFAULT_INTERACTIONS
    }))
    dispatch(loaded('combination', instanceId))

    // All the models that will replace the selected model.
    // In most cases, this will be a single model, but variants may include multiple.
    const replacements = getInstanceObjects(viewer.scene.children, [instanceId])

    // The root model. Often the same as the only entry in the replacements list, but may be different for variants.
    const replacementRoot = getTrueRoot(replacements[0])
    replacementRoot.userData.replaced = true

    replaceWith.push(replacementRoot)

    // Get rotation of current selection
    transformGizmo.attach(rootNodeToSelectionMap[selected.uuid], {
      noEmit: true,
      attachToRoot: true
    })
    const rotation = transformGizmo.dummyMesh.rotation.clone()

    // Attach the replacement models to the transform gizmo. This allows them to be moved and rotated together
    transformGizmo.attach(replacements, {
      noEmit: true // Not emitting an event ensures that transform outline is not set
    })

    // Rotate replacement models
    transformGizmo.setRotationOnSelected(
      THREE.MathUtils.radToDeg(rotation.x),
      THREE.MathUtils.radToDeg(rotation.y),
      THREE.MathUtils.radToDeg(rotation.z)
    )

    // The "alternativeCalcLocalBoundingBox" function is necessary to correctly calculate bounding boxes for variants
    const bbSelected = utils.alternativeCalcLocalBoundingBox(selected)
    const bbReplace = utils.alternativeCalcLocalBoundingBox(replacementRoot)
    bbSelected.getCenter(selectedCenter)
    bbReplace.getCenter(replacementCenter)

    // Instead of setting an absolute position for each replacement model, an offset is calculated.
    // This ensures that relative positions is preserved for multi-model variants
    const offset = selectedCenter.sub(replacementCenter)

    // Move object up if it's intersecting the floor
    if (bbReplace.min.y + offset.y < 0) {
      offset.y -= bbReplace.min.y + offset.y
    }

    transformGizmo.offsetPositionOnSelected(offset.x, offset.y, offset.z)

    // As the object is moved holemarkers should also be updated
    // TODO: should handle this in a more general case,
    // for example via a "move" function which should be used insted of
    // directly updating the position variable
    transformGizmo.updateHoleMarkers()
    transformGizmo.detach()
  }

  const removeNodes = (nodes) => {
    const trueRoots = getTrueRoots(nodes)
    trueRoots.forEach(node => {
      dispatch(fromTree.remove(node.uuid, _get(node, 'userData.replaced', false)))
      viewer.transformGizmo.removeSnappableModel(node)
    })
    dispatch(hide(trueRoots.map(trueRoot => trueRoot.uuid)))
  }

  const restoreNodes = (nodes) => {
    const trueRoots = getTrueRoots(nodes)
    trueRoots.forEach(node => {
      dispatch(fromTree.add(node))
      viewer.transformGizmo.addSnappableModel(node)
    })
    dispatch(show(trueRoots.map(trueRoot => trueRoot.uuid)))
  }

  removeNodes(selectedRootNodes)

  viewer.transformGizmo.detach()
  dispatch(fromSelection.clearSelection())
  dispatch(fromDefaultTemplate.adjustWallInScene())

  // Reapply apperances

  // NOTE: This timeout is necessary to avoid issues with mesh instances

  // The timeout can be 0, we just have to ensure that the appearances are re-applied in another
  // update cycle. If not, copying materials and re-assigning them to meshes may cause instancing problems,
  // resulting in invisible meshes (instance count exceeding the maximum number of instances).
  // Why this happens is unclear.
  setTimeout(() => {
    selectedRootNodes.forEach((selected, i) => {
      const replacement = getTrueRoot(replaceWith[i])
      dispatch(
        reapplyAppearancesAndMaterials(
          selected,
          replacement,
          reapplyAppearances,
          reapplyMaterials,
          reapplyUsingMetadata,
          reapplyUsingBoundingBoxes
        )
      )
    })
  }, 0)

  const command = {
    undo () {
      removeNodes(replaceWith)
      restoreNodes(selectedRootNodes)

      dispatch(fromSelection.clearSelection())
      dispatch(fromDefaultTemplate.adjustWallInScene())
    },

    execute () {
      removeNodes(selectedRootNodes)
      restoreNodes(replaceWith)

      dispatch(fromSelection.clearSelection())
      dispatch(fromDefaultTemplate.adjustWallInScene())
    }
  }

  dispatch(addCommand(command))
}
