import React from 'react'

const IconGroup = props => (
  <svg viewBox="0 0 24 24" width={props.size} height={props.size} {...props}>
    <path
      d="M15.9291111,16 C15.4438815,19.3922941 12.5264719,22 9,22 C5.13400675,22 2,18.8659932 2,15 C2,11.4735281 4.60770586,8.55611852 8,8.07088886 L8,4 C8,2.8954305 8.8954305,2 10,2 L20,2 C21.1045695,2 22,2.8954305 22,4 L22,14 C22,15.1045695 21.1045695,16 20,16 L15.9291111,16 Z M20,14 L20,4 L10,4 L10,9.80516777 L8.28319516,10.0507375 C5.8399048,10.4002228 4,12.5049253 4,15 C4,17.7614237 6.23857625,20 9,20 C11.4950747,20 13.5997772,18.1600952 13.9492625,15.7168048 L14.1948322,14 L20,14 Z"
      fill={props.color}
    >
    </path>
  </svg>
)

IconGroup.defaultProps = {
  color: 'currentcolor',
  size: '1rem'
}

export default IconGroup
