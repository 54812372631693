import React from 'react'

import Modal from '../../common/modal'
import Button from '../../common/button'
import StorageApiImage from '../../common/storage-api-image'
import moment from 'moment'

export default function PreviewPostModal (props) {
  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      width={'50vw'}
    >
      <div className="flex flex-column justify-between" style={{ height: '70vh' }}>

        <h2 className="mt0 mb1 truncate pr3">{props.article.title}</h2>
        <time className={'mb1 truncate f7 c-gray-accessible'}>{props.article.published ? 'Published:' : 'Last edited:'} {moment(props.article.publishedAt).calendar()}</time>

        <div className="overflow-y-auto custom-scrollbar mb2 flex-1">
          <div className="width-80">
            {props.article.content.map(content => (
              <React.Fragment key={content.id}>
                {content.type === 'text' && (
                  <div className="my2" style={{ whiteSpace: 'pre-line' }}>{content.body}</div>
                )}
                {content.type === 'image' && (
                  <StorageApiImage
                    className="my2 userselect-none"
                    style={{
                      objectFit: 'scale-down',
                      maxHeight: 400,
                      objectPosition: 'left'
                    }}
                    file={content.body}
                  />
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>

      <div className="width-100 flex justify-end items-end">
        {(!props.isCurrentUserAdmin && (
          <div
            className='block f7 border br-big bold bc-gray bg-gray-light-hover bg-white bc-black-hover c-black c-black-hover bc-gray-light-hover ease-in-out'
            style={{ padding: '0.75rem 1rem' }}
            onClick={props.onRequestShowNavigatePostsModal}
          >Previous notices</div>
        ))}
        {(props.isCurrentUserAdmin && !props.article.published) && (
          <Button btnType='primary' className='ml2' onClick={(event) => {
            event.preventDefault()
            props.onPublish(props.article.id)
          }}
          >
            Publish
          </Button>
        )}
      </div>
    </Modal>
  )
}
