import React from 'react'

import _map from 'lodash/map'
import _get from 'lodash/get'
import _orderBy from 'lodash/orderBy'

import { StorageApiThumbnail } from '../../common/storage-api-image'
import Button from '../../common/button'
import Label from '../../common/form/label'
import { MdVisibilityOff as IconVisibilityOff } from 'react-icons/md'

import { TagsPreview } from './tags'

class RoomsetsList extends React.Component {
  render () {
    const props = this.props
    const byDate = _orderBy(props.roomsets, ['createdAt'], ['desc'])

    return (
      <div style={{ height: '70vh', overflowY: 'auto', paddingTop: 16 }}>
        {_map(byDate, (roomset) => {
          const metadata = _get(roomset, 'metadata', {})
          const {
            tags,
            marketArea,
            thumbnail,
            isHidden
          } = metadata

          const title = roomset.title

          return (
            <div
              key={roomset.id}
              className='flex flex-auto p2 border-bottom bc-gray-light'
            >
              <div className='mr2'>
                <StorageApiThumbnail
                  className='border bc-gray-light pointer'
                  style={{ width: 150 }}
                  file={thumbnail}
                  onClick={(event) => {
                    event.preventDefault()
                    props.onEdit(roomset.id)
                  }}
                />
              </div>

              <div className='f6'>
                <div
                  className='mb1 bold pointer flex items-end'
                  onClick={(event) => {
                    event.preventDefault()
                    props.onEdit(roomset.id)
                  }}
                >
                  <span className='mr1'>{title || 'untitled'}</span>
                  <span>{isHidden && (<IconVisibilityOff size={'1rem'} />)}</span>
                </div>
                <Label subtle>{`${roomset.id}`}</Label>
                <div className='mb1'>converted: {_get(roomset, 'combinationId') ? 'yes' : 'no'}</div>
                <div className='mb1'>cameras: {_map(_get(roomset, 'cameras'), 'id').length}</div>
                <div className='mb1'><TagsPreview tags={marketArea} tagColor={'bg-secondary'} textColor={'c-gray-lighter'} /></div>
                <div className='mb1'><TagsPreview tags={tags} /></div>
              </div>

              <div className=' ml-auto flex self-end'>
                <Button
                  className='mr3'
                  onClick={(event) => {
                    event.preventDefault()
                    props.onEdit(roomset.id)
                  }}>
                  Edit
                </Button>
                <Button
                  className='mr3'
                  onClick={(event) => {
                    event.preventDefault()
                    props.onDelete(roomset.id)
                  }}>
                  Delete
                </Button>
              </div>
            </div>
          )
        })}

      </div>
    )
  }
}

export default RoomsetsList
