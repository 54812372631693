import * as React from 'react'
import cs from 'classnames'

import Button from '../button'
import IconCross from '../icons/icon-cross'
import colors from '../../../../css/colors'

const RepeatableField = ({ children, hideRemoveButton, onRemove, noBorder, noPadding }) => {
  return (
    <div
      className={cs(
        'flex',
        'items-start',
        'mb2',
        {
          p2: !noPadding,
          border: !noBorder,
          'br-small': !noBorder,
          'bc-gray-light': !noBorder
        }
      )}>
      {children}
      {!hideRemoveButton && (
        <Button
          size='small'
          onClick={onRemove}
          className={cs(
            'ml2',
            {
              mr2: !noPadding,
              my2: !noPadding
            }
          )}
        >
          <IconCross
            color={colors.black}
            size={'1em'}
          />
        </Button>
      )}
    </div>
  )
}

export default RepeatableField
