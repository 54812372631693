import React from 'react'
import PropTypes from 'prop-types'
import cs from 'classnames'

const getToolbarButtonClassName = ({ disabled, active }) => {
  const base = cs(
    'relative',
    'px1',
    'height-100',
    'pointer',
    'flex',
    'flex-column',
    'justify-center',
    'items-center',
    'ease-in-out',
    'bg-white-10-hover'
  )

  return cs(base, {
    'c-white-25 fill-white-25 pointer-disabled': disabled,
    'c-primary fill-primary bg-white-10': !disabled && active,
    'c-white fill-white bg-white-10-hover': !disabled && !active
  })
}

const propTypes = {
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  title: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.element,
  testid: PropTypes.string
}

function ToolbarButton (props) {
  return (
    <div
      onClick={props.disabled ? () => {} : props.onClick}
      data-testid={props.testid || 'ToolBarBtn'}
      className={getToolbarButtonClassName({ disabled: props.disabled, active: props.active })}>
      {props.icon}
      <div className="f8 width-100 center mt1">{props.title}</div>
    </div>
  )
}

ToolbarButton.propTypes = propTypes

export default ToolbarButton
