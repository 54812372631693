import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import * as fromTree from '../../../../../stores/ducks/tree'
import * as fromTreeSelectors from '../../../../../stores/ducks/tree/selectors'
import * as fromVisualizerSelectors from '../../../../visualizer/visualizer-selectors'
import * as fromClone from '../../../../../stores/ducks/clone'
import * as fromSelection from '../../../../../stores/ducks/selection'
import * as fromThreeviewerSplit from '../../../../../stores/ducks/threeviewer/split'
import * as fromThreeviewerSelectors from '../../../../../stores/ducks/threeviewer/selectors'
import * as fromSpreadsheet from '../../../../../stores/ducks/spreadsheet'
import type { ITreeNodeMetaData } from '../../../../../stores/ducks/tree/TreeNode'
import { OverlayMenu, OverlayMenuItem } from '../../../../common/OverlayMenu'
import { FEATURES } from '../../../../../constants'
import { RootState } from '../../../../../stores/ducks'

type Props = {
  nodeUuid: string,
  metaData: ITreeNodeMetaData,
  menuTriggerRef: React.MutableRefObject<any>,
  toggleBocModal: (open: boolean) => void,
  toggleUnlockVirtualProductModalOpen: (open: boolean, uuid?: string) => void
  onShouldClose: () => void,
}

/**
 * Overlay menu for performing various node-related actions like trigger isolation, grouping, ungrouping, etc
 */
export const ActionOverlayMenu = (props: Props) => {
  const disabled = useSelector(fromTreeSelectors.getIsDisabled)
  const canClone = useSelector(fromVisualizerSelectors.getHasValidSelection)
  const canAssemble = useSelector(fromVisualizerSelectors.getHasValidAssembleSelection)
  const canGroup = useSelector(fromTreeSelectors.getCanGroup)
  const canUngroup = useSelector(fromTreeSelectors.getCanUngroup)
  const isolationEnabled = useSelector(fromTreeSelectors.getIsIsolationEnabled)
  const isGrouped = useSelector((state: RootState) => fromTreeSelectors.getIsGrouped(state, props.nodeUuid))
  const isRegularType = ['model', 'variant', 'group', 'virtual-product'].includes(props.metaData.type)
  const nodeList = useSelector(fromThreeviewerSelectors.getNodeList)
  const isPlmActive = useSelector((state: any) => fromThreeviewerSelectors.getIsFeatureActive(state)(FEATURES.PLM, true))

  const canSplit = props.metaData.canSplit && (
    props.metaData.type === 'splitMesh' ||
    (props.metaData.type === 'mesh' && !props.metaData.generated)
  )

  const dispatch = useDispatch()

  return (
    <OverlayMenu
      isOpen={true} // Always set to open since the parent component unmounts the overlay if it should not be open
      menuTriggerRef={props.menuTriggerRef}
      position='right'
      onClose={() => props.onShouldClose()}
    >
      {isRegularType && (
        <>
          <OverlayMenuItem
            handleClick={() => {
              !disabled && canClone && dispatch(fromClone.clone())
              props.onShouldClose()
            }}
            title='Duplicate'
            shortCode='Ctrl + D'
            disabled={!canClone}
          />
          <OverlayMenuItem
            handleClick={() => {
              !disabled && props.metaData.canRemove && dispatch(fromTree.removeNodes(props.nodeUuid))
              props.onShouldClose()
            }}
            title='Delete'
            disabled={!props.metaData.canRemove}
          />
          <OverlayMenuItem
            handleClick={() => {
              !disabled && canGroup && dispatch(fromTree.group())
              props.onShouldClose()
            }}
            title='Group'
            disabled={!canGroup}
          />
          {!props.metaData.locked && (
            <OverlayMenuItem
              handleClick={() => {
                !disabled && canUngroup && props.metaData.type === 'group' && dispatch(fromTree.ungroup(props.nodeUuid))
                props.onShouldClose()
              }}
              title='Ungroup'
              disabled={!canUngroup}
            />
          )}
          {props.metaData.type !== 'group' && (
            <OverlayMenuItem
              handleClick={() => {
                dispatch(fromTree.ungroupNode(props.nodeUuid))
                props.onShouldClose()
              }}
              title='Remove from group'
              disabled={!isGrouped}
            />
          )}
          <OverlayMenuItem
            handleClick={() => {
              !disabled && canAssemble && dispatch(fromSelection.assembleSelectedModels())
              props.onShouldClose()
            }}
            title='Assemble'
            disabled={!canAssemble}
          />

          <OverlayMenuItem
            handleClick={() => {
              dispatch(fromSpreadsheet.getPartData())
              props.onShouldClose()
            }}
            title="Download part data xls"
          />
        </>
      )}
      <OverlayMenuItem
        handleClick={() => {
          !disabled && dispatch(fromSelection.toggleIsolation())
          props.onShouldClose()
        }}
        title="Isolate"
        shortCode='I'
        disabled={!isolationEnabled}
      />

      {isPlmActive && !props.metaData.locked && (
        <OverlayMenuItem
          handleClick={() => {
            props.toggleBocModal(true)
            props.onShouldClose()
          }}
          title='Appearance from PLM'
          disabled={false}
        />
      )}
      {canSplit && nodeList[props.nodeUuid].userData.splitFaces && (
        <>
          <OverlayMenuItem
            disabled={disabled || props.metaData.type !== 'splitMesh'}
            handleClick={() => {
              !disabled && dispatch(fromThreeviewerSplit.mergeMesh(props.nodeUuid))
              props.onShouldClose()
            }}
            title="Merge"
          />
          <OverlayMenuItem
            disabled={disabled || props.metaData.generated}
            handleClick={() => {
              !disabled && dispatch(fromThreeviewerSplit.splitFace(props.nodeUuid))
              props.onShouldClose()
            }}
            title="Unlock mesh"
          />
        </>
      )}

      {/* Unlock Virtual Product from UPPLYSA will be inactive during the beta period.
      Remove this comment to activate the Unlock Virtual Product feature from the conext. */}

      {/* {props.metaData.locked && (
        <OverlayMenuItem
          handleClick={() => {
            props.toggleUnlockVirtualProductModalOpen(true, props.nodeUuid)
            props.onShouldClose()
          }}
          title='Unlock virtual product'
          disabled={false}
        />
      )} */}
    </OverlayMenu>
  )
}
