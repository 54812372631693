import Immutable from 'seamless-immutable'
import { handleActions, createAction } from 'redux-actions'

const receive = createAction('reports/RECEIVE')

export const getUserData = () => (dispatch, getState, { api }) => {
  return api.reports.getUserData()
    .then((result) => dispatch(receive(result)))
    .catch((err) => console.error(err))
}

export const getProjectData = () => (dispatch, getState, { api }) => {
  return api.reports.getProjectData()
    .then((result) => dispatch(receive(result)))
    .catch((err) => console.error(err))
}

const initialState = Immutable({})

export default handleActions({
  [receive]: (state, { payload }) => state.merge(payload)

}, initialState)
