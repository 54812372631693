import Immutable from 'seamless-immutable'
import { createAction, handleActions } from 'redux-actions'

import fetch from '../../../utils/fetch'
import { AppThunk } from '..'
import { GlobalScene, Scene } from './Scene'
import { Temp, Comment, TempTags } from './Temp'
import _keyBy from 'lodash/keyBy'
import _omit from 'lodash/omit'

export const share = createAction('scenes/SHARE')
export const unshare = createAction('scenes/UNSHARE')
export const receiveScenes = createAction('scenes/RECEIVE')
export const updateScenes = createAction('scenes/UPDATE')
export const removeScene = createAction('scenes/REMOVE_ID')
export const error = createAction('scenes/ERROR')
export const receiveUpdate = createAction('scenes/RECEIVE_UPDATE')
const setCurrent = createAction('scenes/SET_CURRENT')
export const setTempTitle = createAction('scenes/SET_TEMP_TITLE')
export const setTempTags = createAction('scenes/SET_TEMP_TAGS')
export const setTempTagsGroup = createAction('scenes/SET_TEMP_TAGS_GROUP')
export const clearTempTags = createAction('scenes/CLEAR_TEMP_TAGS')
export const setTempSelectedCombination = createAction('scenes/SET_TEMP_COMBINATION')
export const addTempComment = createAction('scenes/ADD_TEMP_COMMENT')
export const updateTempComment = createAction('scenes/UPDATE_TEMP_COMMENT')
export const removeTempComment = createAction('scenes/REMOVE_TEMP_COMMENT')
export const clearTempComments = createAction('scenes/CLEAR_TEMP_COMMENTS')
export const setTempComments = createAction('scenes/SET_TEMP_COMMENTS')
export const updateGlobalScenes = createAction('scenes/UPDATE_GLOBAL')
export const statusTempComment = createAction('scenes/STATUS_TEMP_COMMENT')

export function getScene (id: string, setAsCurrent = false): AppThunk {
  return async (dispatch) => {
    const res = await fetch(`/api/scenes/${id}`)
    const json = await res.json()
    dispatch(receiveScenes([json]))
    if (setAsCurrent) {
      dispatch(setCurrent(id))
    }
  }
}

export function fetchGlobalScenes (): AppThunk {
  return (dispatch) => {
    return fetch('/api/scenes/global')
      .then(res => {
        return res.json()
      }).then(res => {
        dispatch(receiveScenes(res))
      })
      .catch(() => dispatch(error('Error when fetching the scenes')))
  }
}

export function fetchUserScenes (): AppThunk {
  return (dispatch) => {
    return fetch('/api/scenes/user')
      .then(res => {
        return res.json()
      }).then(res => {
        dispatch(receiveScenes(res))
      })
      .catch(() => dispatch(error('Error when fetching the scenes')))
  }
}

export const shareScene = (payload: { id: string, title: string }): AppThunk => {
  return (dispatch) => {
    return fetch('/api/scenes', {
      method: 'PUT',
      body: JSON.stringify({ combinationId: payload.id, title: payload.title, global: false })
    })
      .then((res) => res.json())
      .then(json => dispatch(updateScenes(json)))
      .catch(() => dispatch(error('Error when creating scene')))
  }
}

export const unShareScene = (payload: { id: string, removeAsAdmin?: boolean }): AppThunk => {
  return (dispatch) => {
    return fetch(`/api/scenes/${payload.id}`, {
      method: 'DELETE',
      body: JSON.stringify({ id: payload.id })
    })
      .then((res) => res.json())
      .then(() => dispatch(removeScene({ id: payload.id, removeAsAdmin: payload.removeAsAdmin }))
      )
      .catch(() => dispatch(error('Error when removing scene')))
  }
}

export const updateScene = (payload: { id: string, title: string, tags?: string[], comments?: any[], global: boolean }): AppThunk => {
  return (dispatch) => {
    return fetch(`/api/scenes/${payload.id}`, {
      method: 'PATCH',
      body: JSON.stringify({ title: payload.title, tags: payload.tags, comments: payload.comments, global: payload.global })
    })
      .then((res) => res.json())
      .then(json => dispatch(updateScenes(json)))
      .catch(() => dispatch(error('Error when updating scene')))
  }
}

export const saveGlobalScene = (payload: { id: string, title: string, tags: number[], comments: any[], global?: boolean }): AppThunk => {
  return (dispatch) => {
    return fetch(`/api/scenes/${payload.id}`, {
      method: 'PATCH',
      body: JSON.stringify({ title: payload.title, tags: payload.tags, comments: payload.comments, global: payload.global ?? false })
    })
      .then((res) => res.json())
      .then(json => dispatch(updateGlobalScenes(json)))
      .catch(() => dispatch(error('Error when updating scene')))
  }
}

const initialState = Immutable<{
  entries: { [id: string]: Scene },
  globalEntries: { [id: string]: GlobalScene },
  error: string | null
  currentId: string | null
  temporary: Temp,
}>({
  entries: {},
  globalEntries: {},
  error: null,
  currentId: null,
  temporary:
    {
      title: null,
      comments: [] as Comment[],
      tags: {} as TempTags
    },
})

type State = typeof initialState

export default handleActions<State, any>({
  [setCurrent.toString()]: (state, action) => state.setIn(['currentId'], action.payload),
  [receiveScenes.toString()]: (state, { payload }) => {
    const userScenesChanged = payload.userScenes
    const globalScenes = payload.globalScenes
    const newState = {
      error: null,
      entries: userScenesChanged ? _keyBy(payload.userScenes, 'id') : state.entries,
      globalEntries: globalScenes ? _keyBy(payload.globalScenes, 'id') : state.globalEntries
    }
    return state.merge(newState)
  },
  [updateScenes.toString()]: (state, { payload }) => {
    return state
      .setIn(['entries', payload.id], payload)
      .setIn(['error'], null)
  },
  [updateGlobalScenes.toString()]: (state, { payload }) => {
    return state
      .setIn(['entries', payload.id], payload)
      .setIn(['globalEntries', payload.id], payload)
      .setIn(['error'], null)
  },
  [removeScene.toString()]: (state, { payload }) => {
    if (payload.removeAsAdmin) state.setIn(['globalEntries'], _omit(state.globalEntries, [payload.id]))
    return state.setIn(['entries'], _omit(state.entries, [payload.id]))
  },
  [error.toString()]: (state, { payload }) => state.merge({ error: payload }),
  [receiveUpdate.toString()]: (state, { payload }) => {
    const parameter = payload.global ? 'globalEntries' : 'entries'
    if (payload.removedAt) return state.setIn([parameter], _omit(state[parameter], [payload.id]))
    return state
      .setIn([parameter, payload.id], payload)
      .setIn(['error'], null)
  },
  [setTempTitle.toString()]: (state, { payload }) => {
    return state
      .setIn(['temporary', 'title'], payload.title)
  },
  [setTempSelectedCombination.toString()]: (state, { payload }) => {
    return state
      .setIn(['temporary', 'combination'], payload.combination)
  },
  [addTempComment.toString()]: (state, { payload } : { payload: Comment }) => {
    return state.setIn(['temporary', 'comments'], state.temporary.comments.concat([payload]))
  },
  [updateTempComment.toString()]: (state, { payload } : { payload: { id: string, body: Comment } }) => {
    return state.setIn(['temporary', 'comments', payload.id], payload.body)
  },
  [removeTempComment.toString()]: (state, { payload }) => {
    const comment = state.getIn(['temporary', 'comments']).filter(comment => comment.id === payload)[0]
    // if comment has anchor we need to update the other comments with anchors index
    if (comment.index) {
      const allComments = state.getIn(['temporary', 'comments']).filter(comment => comment.id !== payload)
      let index = comment.index
      const updatedComments = allComments.map(c => {
        if (!c.index || c.index < index) return c
        index++
        return c.setIn(['index'], (index - 1))
      })
      return state.setIn(['temporary', 'comments'], updatedComments)
    }
    return state.setIn(['temporary', 'comments'], state.temporary.comments.filter(comment => comment.id !== payload))
  },
  [clearTempComments.toString()]: (state) => {
    return state.setIn(['temporary', 'comments'], [])
  },
  [setTempComments.toString()]: (state, { payload }) => {
    return state.setIn(['temporary', 'comments'], payload)
  },
  [setTempTags.toString()]: (state, { payload }: { payload: {
    key: string,
    tags: any[]
  }}) => {
    return state
      .setIn(['temporary', 'tags', payload.key], payload.tags)
  },
  [setTempTagsGroup.toString()]: (state, { payload }: { payload: any}) => {
    return state
      .setIn(['temporary', 'tags'], payload)
  },
  [clearTempTags.toString()]: (state) => {
    return state
      .setIn(['temporary', 'tags'], {})
  },
  [statusTempComment.toString()]: (state, { payload }: {payload: any}) => {
    return state.setIn(['temporary', 'comments', payload.id, 'hasTempAnchor'], payload.hasTempAnchor)
  }
}, initialState)
