import React, { Component } from 'react'
import PropTypes from 'prop-types'
import autoBind from 'react-autobind'
import { GoEye as IconEye } from 'react-icons/go'
import css from './index.css'

class InputPassword extends Component {
  constructor (props) {
    super(props)
    autoBind(this)
    this.state = {
      typeOfInput: this.props.typeOfInput
    }
  }

  toggleInputType () {
    this.state.typeOfInput === 'password' ? this.setState({ typeOfInput: 'text' }) : this.setState({ typeOfInput: 'password' })
  }

  get root () {
    return this._root
  }

  render () {
    const props = this.props
    return (
      <div className='relative width-100 mr4'>
        <input
          style={props.style}
          name={props.name}
          required={props.required}
          placeholder={this.props.placeholder}
          type={this.state.typeOfInput}
          ref={(node) => (this._root = node)}
          onChange={props.onChangePassword}
          className={`${css.inputPassword} ${props.className} ${props.fullWidth ? 'block width-100' : ''}`}
        />

        {this.props.allowConfirmField &&
          <input
            placeholder={this.props.placeholderConfirmPassword}
            type={this.state.typeOfInput}
            ref={(node) => (this._root = node)}
            onChange={props.onChangeConfirmPassword}
            className={`${css.inputPassword} ${props.className} ${props.fullWidth ? 'block width-100' : ''}`}
          />
        }

        {this.props.allowToShowPassword &&
          <div
            className='absolute c-gray pointer'
            style={{ top: 7, right: 10 }}
          >
            <IconEye onClick={this.toggleInputType} size={16} />
          </div>
        }
      </div>
    )
  }
}

InputPassword.propTypes = {
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  allowToShowPassword: PropTypes.bool,
  allowConfirmField: PropTypes.bool
}

InputPassword.defaultProps = {
  placeholder: '*****',
  className: '',
  fullWidth: true,
  typeOfInput: 'password',
  allowToShowPassword: false,
  allowConfirmField: false
}

export default InputPassword
