
/**
 * Linear interpolation between two numbers
 * @constructor
 * @param {number} a - from.
 * @param {number} b - to.
 * @param {number} alpha - interpolation amount.
 * @returns {number}
 */
export function lerp (a, b, alpha) {
  return a + (b - a) * alpha
}

export const toRadians = (degrees) => {
  return degrees * (Math.PI / 180)
}

export function clamp (val, min, max) {
  return Math.min(Math.max(val, min), max)
}
