import * as THREE from 'three'
import Immutable, { ImmutableObject } from 'seamless-immutable'
import { createAction, handleActions } from 'redux-actions'
import { v4 as uuid } from 'uuid'
import _get from 'lodash/get'
import _throttle from 'lodash/throttle'
import CameraManager from '../../../../../go3dthree/src/scenegraph/CameraManager'
import { update as updateProject } from '../projects'
import * as fromProjectsSelectors from '../projects/selectors'
import { RENDER_RESOLUTIONS } from '../../../constants'
import { resetSafeFrameVisibility, setSafeFrameVisibility, saveSafeFrameVisibility } from './ui'
import type { AppThunk } from '..'
import type { Go3DViewer } from '../../../../../go3dthree/types/Go3DViewer'
import type { Roomset } from '../roomsets/Roomset'
import { getSettings } from './selectors'
import { SceneGraphNode3d } from '../../../../../go3dthree/src/scenegraph/SceneGraph'

const BEHAVIORS = CameraManager.BEHAVIORS
export const LOCKED_MODES = CameraManager.LOCKED_MODES
export type CameraMode = 'FLOORPLAN' | 'PREDEFINED' | 'OFFSET_VIEW_CAMERA' | 'VARIANT'
export const CAMERA_MODES: { [key in CameraMode]: string } = { ...LOCKED_MODES, VARIANT: 'VARIANT' }
const STATES = CameraManager.STATES
const ROOMSET_NAVIGATION_FOV = CameraManager.ROOMSET_NAVIGATION_FOV

export const dispose = createAction('threeviewer/camera/DISPOSE')

type ActiveCamera = {
  id: string | number | null
  isPredefined?: boolean
  imageTemplate?: null | Roomset
  name?: string
}

type CameraBackup = {
  activeCamera: ActiveCamera | ImmutableObject<ActiveCamera>
  settings: any
}

type UpdateCamera = {
  near: number
  fov: number
  aspectRatio: { x: number, y: number }
  activeCamera: ActiveCamera | ImmutableObject<ActiveCamera>
}

export const backupCamera = createAction<null | CameraBackup>('threeviewer/camera/BACKUP_CAMERA')
const updateCamera = createAction<UpdateCamera>('threeviewer/camera/UPDATE')
const setActiveCamera = createAction<ActiveCamera>('threeviewer/camera/SET_ACTIVE_CAMERA')
const resetActiveCamera = createAction('threeviewer/camera/RESET_ACTIVE_CAMERA')
const saveFov = createAction<number | string>('threeviewer/camera/SET_FOV')
const confirmSetAspectRatio = createAction<{ x: number, y: number }>('threeviewer/camera/CONFIRM_ASPECT_RATIO')
const saveAspectRatio = createAction<{ x: number, y: number }>('threeviewer/camera/SAVE_ASPECT_RATIO')
const saveNear = createAction('threeviewer/camera/SAVE_NEAR')
export const setCameraMode = createAction('threeviewer/camera/SET_MODE')
const saveCameraTarget = createAction<THREE.Vector3>('threeviewer/camera/SET_TARGET')

export const setupControls = (viewer: Go3DViewer, settings: any, dispatch: any) => {
  const targetPosition = _get(settings, 'cameraSettings.targetPosition', [0, 0, 0])
  const target = (new THREE.Vector3()).fromArray(targetPosition)

  viewer.cameraManager.useOrbitControls(target)
  viewer.cameraManager.updateOrbitControls()

  viewer.enableSpaceMouse(viewer.cameraManager.controls)
  viewer.cameraManager.on('change', () => {
    if (viewer.alignTool) {
      viewer.alignTool.updateAlignObjects()
    }

    if (viewer.triplanarTool) {
      viewer.triplanarTool.updateWidget()
    }
  })

  // TODO: Use this more!
  viewer.cameraManager.on('stateChange', (data: { prevStateData: any; stateData: any }) => {
    const { prevStateData, stateData } = data
    if (prevStateData.lockedMode !== stateData.lockedMode) {
      dispatch(setCameraMode(stateData.lockedMode))
    }
  })
}

export const useDefaultCameraAndControls = (): AppThunk => (dispatch, getState) => {
  const state = getState()
  const viewer = state.threeviewer.viewer
  if (!viewer) return
  const settings = getSettings(state)
  const targetPosition = _get(settings, 'cameraSettings.targetPosition', [0, 0, 0])
  const fov = viewer.cameraManager.cameraFOV0
  const aspectRatio = RENDER_RESOLUTIONS.square

  const target = (new viewer.THREE.Vector3()).fromArray(targetPosition)

  viewer.cameraManager.change({ lockedMode: null })

  viewer.cameraManager.change({
    target: target,
    behavior: BEHAVIORS.DEFAULT
  })

  viewer.camera.position.fromArray(settings.cameraSettings.position)
  viewer.camera.lookAt(target)
  viewer.cameraManager.desiredFov = fov

  viewer.setAspectRatio(aspectRatio)
  viewer.updateCameraSize(null)

  dispatch(saveFov(viewer.camera.getEffectiveFOV()))

  dispatch(updateCamera({
    fov: viewer.camera.getEffectiveFOV(),
    aspectRatio: aspectRatio,
    near: 0.01,
    activeCamera: {
      id: 1,
      isPredefined: false
    }
  }))
}

export function setupCamera (viewer: Go3DViewer, settings: any, dispatch: any) {
  const fov = viewer.camera.getEffectiveFOV()
  viewer.camera.position.fromArray(settings.position)
  viewer.cameraManager.desiredFov = fov
  dispatch(saveFov(fov))
}

export const setAspectRatio = (aspectRatio: { x: number, y: number }): AppThunk => (dispatch, getState) => {
  const viewer = getState().threeviewer.viewer
  if (!viewer) return
  viewer.setAspectRatio(aspectRatio)
  dispatch(confirmSetAspectRatio(aspectRatio))
  dispatch(saveAspectRatio(aspectRatio))
}

const resetAspectRatio = (): AppThunk => (dispatch, getState) => {
  dispatch(setAspectRatio(getState().threeviewer.camera.savedAspectRatio))
}

export const setCameraFOV = (fov: number | string): AppThunk => (dispatch, getState) => {
  const viewer = getState().threeviewer.viewer
  if (!viewer) return
  viewer.cameraManager.desiredFov = Number(fov)
  dispatch(saveFov(fov))
}

const setNear = (near: number): AppThunk => (dispatch, getState) => {
  const viewer = getState().threeviewer.viewer
  if (!viewer) return
  viewer.cameraManager.near = Number(near)
  dispatch(saveNear(near))
}

export const setCameraTarget = (object : SceneGraphNode3d): AppThunk => (dispatch, getState) => {
  const targetPos = object.position.clone()
  const viewer = getState().threeviewer.viewer
  if (!viewer || Object.values(viewer.picker.selection).length) return
  const objList : SceneGraphNode3d[] = [object]
  viewer.cameraManager.useOrbitControls(targetPos)
  viewer.cameraManager.centerControlsAroundObjects(objList as any)
  dispatch(saveCameraTarget(targetPos))
}

export const setCameraSettings = ({
  settings,
  cameraId,
  isPredefined = false,
  imageTemplate = null
}: {
  settings: any
  cameraId: string | number | null
  imageTemplate?: Roomset | null
  isPredefined?: boolean
}): AppThunk => (dispatch, getState) => {
  const state = getState()
  const viewer = state.threeviewer.viewer
  if (!viewer) return
  let _aspectRatio = settings.aspectRatio || settings.resolution

  if (imageTemplate) {
    _aspectRatio = imageTemplate.aspectRatio
  }

  viewer.setAspectRatio(_aspectRatio)

  if (isPredefined) {
    // Need to set behaviour before setting lockedMode.
    // Making the assumption that all predefined cameras are in a roomset.
    viewer.cameraManager.change({
      behavior: CameraManager.BEHAVIORS.ROOMSETS
    })
    viewer.cameraManager.change({
      lockedMode: CameraManager.LOCKED_MODES.PREDEFINED
    })
    dispatch(saveSafeFrameVisibility(viewer.postProcessManager.safeFrame.enabled))
    dispatch(setSafeFrameVisibility(true))
  } else if (state.threeviewer.camera.mode === CAMERA_MODES.PREDEFINED) {
    viewer.cameraManager.change({
      behavior: CameraManager.BEHAVIORS.DEFAULT
    })
    viewer.cameraManager.change({
      lockedMode: null
    })
    const activeRoomset = state.roomsets.currentId
    if (activeRoomset) {
      viewer.cameraManager.change({
        behavior: CameraManager.BEHAVIORS.ROOMSETS
      })
    }
  }

  viewer.cameraManager.setCameraSettings({
    transform: settings.transform,
    fov: settings.fov,
    target: settings.target,
    near: settings.near || 0.01,
    far: settings.far || 100
  })

  if (settings.fovDeg) {
    viewer.camera.fov = settings.fovDeg
  }

  dispatch(updateCamera({
    fov: viewer.camera.getEffectiveFOV(),
    aspectRatio: _aspectRatio,
    near: settings.near,
    activeCamera: {
      id: cameraId,
      name: settings.name,
      imageTemplate: imageTemplate,
      isPredefined
    }
  }))
}

export const createNewCameraSetting = (id: string | undefined, title: string, roomsetId: string | null = null): AppThunk => (dispatch, getState) => {
  const state = getState()
  const viewer = state.threeviewer.viewer
  if (!viewer) return

  const currentProjectId = fromProjectsSelectors.getCurrentId(state)
  const cameraSettings = {
    ...viewer.cameraManager.getCameraSettings(),
    aspectRatio: viewer.postProcessManager.aspectRatio
  }

  const _id = id || uuid()

  const project = {
    cameraList: { [_id]: { ...cameraSettings, title, roomsetId } }
  }

  dispatch(updateProject({
    id: currentProjectId,
    ...project
  }))

  dispatch(setActiveCamera({ id: _id, isPredefined: false }))
}

export const activateFloorplanView = (shouldBackupCamera = true): AppThunk => (dispatch, getState) => {
  const state = getState()
  const viewer = state.threeviewer.viewer
  if (!viewer) return

  if (shouldBackupCamera) {
    const cameraSettings = {
      ...viewer.cameraManager.getCameraSettings(),
      aspectRatio: viewer.postProcessManager.aspectRatio,
      fovDeg: viewer.camera.fov
    }

    dispatch(backupCamera({
      settings: cameraSettings,
      activeCamera: state.threeviewer.camera.activeCamera
    }))
  }

  dispatch(setCameraFOV(10))
  dispatch(setNear(0.01))

  viewer.cameraManager.change({
    isInsideRoomset: false,
    lockedMode: LOCKED_MODES.FLOORPLAN
  })

  dispatch(saveSafeFrameVisibility(viewer.postProcessManager.safeFrame.enabled))
  dispatch(setSafeFrameVisibility(false))

  dispatch(resetActiveCamera())
}

export const activate3DView = (useSavedCamera = true): AppThunk => (dispatch, getState) => {
  const state = getState()
  const viewer = state.threeviewer.viewer
  if (!viewer) return
  const cameraBackup = state.threeviewer.camera.cameraBackup
  const camera = viewer.camera

  viewer.cameraManager.change({ lockedMode: null })

  dispatch(resetSafeFrameVisibility())

  if (cameraBackup && useSavedCamera) {
    dispatch(useCameraBackup(cameraBackup))
  }

  const objectSelected = Object.keys(viewer.picker.selection).length > 0

  if (viewer.cameraManager.state === STATES.ROOMSETS) {
    if (!cameraBackup && useSavedCamera) {
      if (!objectSelected) {
        camera.position.y = (170 / 100) // average person height
        camera.lookAt(camera.position.clone().setX(1))
      } else {
        camera.position.y = 5
      }
      dispatch(setCameraFOV(ROOMSET_NAVIGATION_FOV))
    }

    return viewer.cameraManager.change({
      isInsideRoomset: viewer.cameraManager.getIsInsideRoomset(),
      objectSelected: objectSelected
    })
  }

  viewer.cameraManager.change({ objectSelected })
}

export const disablePredefinedCamera = (): AppThunk => (dispatch, getState) => {
  const state = getState()
  const viewer = state.threeviewer.viewer
  if (!viewer) return

  if (state.threeviewer.camera.mode === CAMERA_MODES.PREDEFINED) {
    viewer.cameraManager.change({
      throttledFovCb: _throttle((fov: number) => {
        viewer.cameraManager.desiredFov = fov
        dispatch(saveFov(fov))
        dispatch(saveNear(0.01))
      }, 100),
      lockedMode: null,
      objectSelected: Object.keys(viewer.picker.selection).length > 0
    })

    dispatch(resetAspectRatio())
    dispatch(resetSafeFrameVisibility())
    dispatch(resetActiveCamera())
  }
}

export function useCameraBackup (cameraBackup: CameraBackup): AppThunk {
  return (dispatch, getState) => {
    const state = getState()
    const viewer = state.threeviewer.viewer
    let cameraId = cameraBackup.activeCamera.id
    let settings = cameraBackup.settings

    if (viewer && cameraBackup.activeCamera.isPredefined) {
      settings = CameraManager.getPredefinedCameraLeaveSettings(settings.transform, settings.near)
      settings.aspectRatio = RENDER_RESOLUTIONS.square
      cameraId = null
    }

    dispatch(setCameraSettings({
      settings,
      cameraId
    }))
    dispatch(backupCamera(null))
  }
}

export function activateVariantMode (): AppThunk {
  return (dispatch, getState) => {
    const state = getState()
    const viewer = state.threeviewer.viewer
    if (viewer && viewer.variantManager) {
      if (state.threeviewer.camera.activeCamera.isPredefined) {
        const cameraSettings = viewer.cameraManager.getCameraSettings()
        dispatch(backupCamera({
          settings: {
            ...cameraSettings,
            fovDeg: viewer.camera.fov,
            aspectRatio: viewer.postProcessManager.aspectRatio
          },
          activeCamera: state.threeviewer.camera.activeCamera
        }))
      }
      viewer.variantManager.activate()
    }
  }
}

export function deactivateVariantMode (): AppThunk {
  return (dispatch, getState) => {
    const state = getState()
    const viewer = state.threeviewer.viewer
    if (viewer && viewer.variantManager) {
      viewer.variantManager.reset()
      const cameraBackup = state.threeviewer.camera.cameraBackup
      if (cameraBackup && cameraBackup.activeCamera.isPredefined) {
        dispatch(useCameraBackup(cameraBackup))
        viewer.cameraManager.change({ lockedMode: null })
      } else {
        viewer.variantManager.resetCamera()
      }
    }
  }
}

const initialState = Immutable<{
  activeCamera: ActiveCamera
  aspectRatio: { x: number, y: number }
  savedAspectRatio: { x: number, y: number }
  fov: number
  near: number
  mode: 'PREDEFINED' | 'OFFSET_VIEW_CAMERA' | null
  cameraBackup: null | {
    activeCamera: ActiveCamera
    settings: any
  }
}>({
  aspectRatio: { x: 1, y: 1 },
  savedAspectRatio: { x: 1, y: 1 },
  fov: 0,
  near: 0.01,
  activeCamera: {
    id: null,
    isPredefined: false,
    imageTemplate: null
  },
  mode: null,
  cameraBackup: null
})

type State = typeof initialState

export default handleActions<State, any>({
  [updateCamera.toString()]: (state, action) => state.merge({
    fov: action.payload.fov,
    aspectRatio: action.payload.aspectRatio,
    activeCamera: action.payload.activeCamera,
    near: action.payload.near
  }, { deep: true }),
  [backupCamera.toString()]: (state, action) => state.merge({ cameraBackup: action.payload }),
  [confirmSetAspectRatio.toString()]: (state, action) => state.merge({ aspectRatio: action.payload }),
  [saveAspectRatio.toString()]: (state, action) => state.merge({ savedAspectRatio: action.payload }),
  [saveFov.toString()]: (state, action) => state.merge({ fov: action.payload }),
  [resetActiveCamera.toString()]: (state) => state.merge({ activeCamera: initialState.activeCamera }),
  [setActiveCamera.toString()]: (state, action) => state.merge({ activeCamera: { id: action.payload.id, isPredefined: action.payload.isPredefined } }),
  [setCameraMode.toString()]: (state, action) => state.merge({ mode: action.payload }),
  [saveNear.toString()]: (state, action) => state.merge({ near: action.payload }),
  [dispose.toString()]: () => initialState
}, initialState)
