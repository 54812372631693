const THREE = require('three')
const ObjectNormalMaterial = require('../materials/ObjectNormalsMaterial')

export default class Actions {
  constructor (app) {
    this.app = app

    this.WSBoundingBoxes = []
    this.OSBoundingBoxes = []
    this.OSBoundingSpheres = []

    this.shadowCameras = []
    this.vertexNormalHelpers = []
    this.lightHelpers = []

    this.mock = { update: {}, active: {} }
    this.setMockDefaults()
    this.controlsTargetMesh = new THREE.Mesh(new THREE.SphereGeometry(0.02, 0.02), new THREE.MeshBasicMaterial({ color: 0xff0000, wireframe: true }))
    this.updateSphere = (event) => {
      const controls = event.target
      this.controlsTargetMesh.position.copy(controls.target)
    }
  }

  reset () {
    this.app.postProcessManager.overrideMaterial = null
    this.clearWSBoundingBoxes()
    this.clearOSBoundingBoxes()
    this.clearOSBoundingSpheres()
    this.clearShadowCameras()
    this.clearVertexNormals()
    this.clearLightHelpers()
    this.removeControlsTarget()

    // Reset debug geometry and materials
    this.app.renderScene.scene.traverse(mesh => {
      if (!mesh.isMesh) return
      if (mesh.debugMaterial !== undefined) {
        mesh.material = mesh.debugMaterial
        mesh.debugMaterial.dispose()
        delete mesh.debugMaterial
      }
      if (mesh.debugGeometry !== undefined) {
        mesh.geometry = mesh.debugGeometry
        mesh.debugGeometry.dispose()
        delete mesh.debugGeometry
      }
    })

    this.setMockDefaults()
    this.app.doRenderOnNextFrame = true
  }

  setMockDefaults () {
    this.mock.update.material = 'keep'
    this.mock.update.geometry = 'keep'
    this.mock.update.simplifyStart = 150
    this.mock.update.simplifyPercentage = 0.1
    this.mock.active.material = 'keep'
    this.mock.active.geometry = 'keep'
    this.mock.active.simplifyStart = 150
    this.mock.active.simplifyPercentage = 0.1
  }

  wireframe () {
    this.app.postProcessManager.overrideMaterial = new THREE.MeshBasicMaterial({
      color: 0x000000,
      wireframe: true
    })
    this.app.doRenderOnNextFrame = true
  }

  showObjectNormals () {
    this.app.postProcessManager.overrideMaterial = new ObjectNormalMaterial()
    this.app.doRenderOnNextFrame = true
  }

  clearWSBoundingBoxes () {
    this.WSBoundingBoxes.forEach((b) => {
      this.app.renderScene.scene.remove(b)
    })
    this.WSBoundingBoxes = []
  }

  showWSBoundingBoxes () {
    this.clearWSBoundingBoxes()

    const addBoundingBox = (bbCalculator, node, color) => {
      const box = bbCalculator(node)
      const boxMesh = new THREE.Box3Helper(box, color)

      box.getCenter(boxMesh.position)
      box.getSize(boxMesh.scale)
      boxMesh.updateMatrixWorld()

      this.app.renderScene.scene.add(boxMesh)
      this.WSBoundingBoxes.push(boxMesh)
    }

    this.app.scene.traverse((node) => {
      if (node.userData && node.userData.isModelRoot) {
        addBoundingBox(n => this.app.viewerUtils.alternativeCalcLocalBoundingBox(n), node, 0xEE2222)
        addBoundingBox(n => this.app.viewerUtils.getObjectBoundingBox(n), node, 0x22EE22)
      }
    })

    this.app.doRenderOnNextFrame = true
  }

  clearOSBoundingBoxes () {
    this.OSBoundingBoxes.forEach((b) => {
      b.parent.remove(b)
    })
    this.OSBoundingBoxes = []
  }

  showOSBoundingBoxes () {
    this.clearOSBoundingBoxes()
    const sources = []
    this.app.renderScene.scene.traverse((node) => {
      if (node instanceof THREE.Mesh) {
        sources.push(node)
      }
    })

    sources.forEach(node => {
      for (let i=0; i<node.count; i++) {
        const bounds = node.geometry.boundingBox.clone()
        bounds.expandByScalar(0.001) // Boxes with zero volume leads to degenerate matrices.
        const box = new THREE.Box3Helper(bounds, 0xEE2222)

        let matrixToApply = new THREE.Matrix4()
        let scaleVec = new THREE.Vector3()
        node.getMatrixAt(i, matrixToApply)
        scaleVec.setFromMatrixScale(matrixToApply)

        if (scaleVec.equals(new THREE.Vector3(0))) continue

        bounds.applyMatrix4(matrixToApply)
        box.updateMatrixWorld()
        node.add(box)
        this.OSBoundingBoxes.push(box)
      }
      
    })
    this.app.doRenderOnNextFrame = true
  }

  clearOSBoundingSpheres () {
    this.OSBoundingSpheres.forEach((b) => {
      b.parent.remove(b)
    })
    this.OSBoundingSpheres = []
  }

  showOSBoundingSpheres () {
    this.clearOSBoundingSpheres()
    const sources = []
    this.app.renderScene.scene.traverse((node) => {
      if (node instanceof THREE.Mesh) {
        sources.push(node)
      }
    })

    sources.forEach(node => {
      let scaleVec = new THREE.Vector3()
      node.geometry.computeBoundingSphere()
      for (let i=0; i<node.count; i++) {
        const sphere = new THREE.LineSegments(
          new THREE.WireframeGeometry(new THREE.SphereBufferGeometry(node.geometry.boundingSphere.radius, 24, 24)),
          new THREE.MeshBasicMaterial({ color: 0x2222EE })
        )
        let matrixToApply = new THREE.Matrix4()
        node.getMatrixAt(i, matrixToApply)
        scaleVec.setFromMatrixScale(matrixToApply)

        if (scaleVec.equals(new THREE.Vector3(0))) continue

        sphere.position.copy(node.geometry.boundingSphere.center)
        sphere.position.applyMatrix4(matrixToApply)
        sphere.updateMatrixWorld()
        node.add(sphere)
        this.OSBoundingSpheres.push(sphere)
      }
    })
    this.app.doRenderOnNextFrame = true
  }

  clearShadowCameras () {
    this.shadowCameras.forEach((c) => {
      this.app.scene.remove(c)
    })
    this.shadowCameras = []
  }

  showShadowCameras () {
    this.clearShadowCameras()
    this.app.scene.traverse((node) => {
      if (node instanceof THREE.Light && node.castShadow) {
        const shadowCamera = new THREE.CameraHelper(node.shadow.camera)
        this.shadowCameras.push(shadowCamera)
        this.app.scene.add(shadowCamera)
      }
    })
    this.app.doRenderOnNextFrame = true
  }

  showCubeCamera () {
    this.app.cubeCamera.traverse((node) => {
      if (node.type === 'PerspectiveCamera') {
        const cubeCameraHelper = new THREE.CameraHelper(node)
        this.app.scene.add(cubeCameraHelper)
      }
    })
  }

  clearVertexNormals () {
    this.vertexNormalHelpers.forEach((h) => {
      this.app.scene.remove(h)
    })
    this.vertexNormalHelpers = []
  }

  showControlsTarget () {
    this.app.cameraManager.on('change', this.updateSphere)
    this.controlsTargetMesh.position.copy(this.app.cameraManager.controls.target)
    this.app.scene.add(this.controlsTargetMesh)
  }

  removeControlsTarget () {
    this.app.cameraManager.removeListener('change', this.updateSphere)
    this.app.scene.remove(this.controlsTargetMesh)
  }

  showVertexNormals () {
    this.app.scene.traverse((node) => {
      if (node instanceof THREE.Mesh) {
        const helper = new THREE.VertexNormalsHelper(node, 0.2, 0x00ffff, 1)
        this.vertexNormalHelpers.push(helper)
        this.app.scene.add(helper)
      }
    })
    this.app.doRenderOnNextFrame = true
  }

  clearLightHelpers () {
    this.lightHelpers.forEach((h) => {
      this.app.scene.remove(h)
    })
    this.lightHelpers = []
  }

  showLights () {
    this.app.scene.traverse((node) => {
      if (node instanceof THREE.Light) {
        let helper
        if (node instanceof THREE.PointLight) {
          helper = new THREE.PointLightHelper(node, node.distance || 10)
        } else if (node instanceof THREE.SpotLight) {
          helper = new THREE.SpotLightHelper(node)
        } else if (node instanceof THREE.HemisphereLight) {
          helper = new THREE.HemisphereLightHelper(node, 10)
        } else if (node instanceof THREE.DirectionalLight) {
          helper = new THREE.DirectionalLightHelper(node)
        }
        this.app.scene.add(helper)
        this.lightHelpers.push(helper)
      }
    })
    this.app.doRenderOnNextFrame = true
  }

  mockUpdateMaterial () {
    const curMat = this.mock.active.material
    const newMat = this.mock.update.material

    if (curMat === newMat) return

    if (newMat === 'keep') {
      this.app.renderScene.scene.traverse(mesh => {
        if (!mesh.isMesh || mesh.debugMaterial === undefined) return
        mesh.material = mesh.debugMaterial
        mesh.debugMaterial.dispose()
        delete mesh.debugMaterial
      })
    } else if (newMat === 'white triplanar') {
      const newMat = new this.app.TriplanarMaterial()
      this.app.assetManager.addMaterial(newMat)

      this.app.renderScene.scene.traverse(mesh => {
        if (!mesh.isMesh) return
        if (mesh.debugMaterial === undefined) {
          mesh.debugMaterial = mesh.material
        }
        mesh.material = newMat
      })
    }
  }

  mockUpdateGeometry () {
    const curGeo = this.mock.active.geometry
    const newGeo = this.mock.update.geometry

    const curSimplifyStart = this.mock.active.simplifyStart
    const cursimplifyPercentage = this.mock.active.simplifyPercentage
    const simplifyStart = this.mock.update.simplifyStart
    const simplifyPercentage = this.mock.update.simplifyPercentage

    if (newGeo === 'spheres') {
      if (curGeo === 'spheres' && (curSimplifyStart === simplifyStart && cursimplifyPercentage === simplifyPercentage)) {
        // Simplify settings did not change
        return
      }
    } else if (curGeo === newGeo) {
      return
    }

    this.app.doRenderOnNextFrame = true

    if (newGeo === 'keep') {
      this.app.renderScene.scene.traverse(mesh => {
        if (!mesh.isMesh || mesh.debugGeometry === undefined) return
        mesh.geometry = mesh.debugGeometry
        mesh.debugGeometry.dispose()
        delete mesh.debugGeometry
      })
      return
    }

    const instanceReuser = new Map()

    this.app.renderScene.scene.traverse(mesh => {
      if (!mesh.isMesh) return

      // Special case; avoid the fading floor in DPD
      if (mesh.material.useModelFading !== null && mesh.material.useModelFading) return

      // If we already have replaced the real geometry, make sure we use the original geometry
      let sourceGeometry = mesh.geometry
      if (mesh.debugGeometry !== undefined) {
        sourceGeometry = mesh.debugGeometry
      }

      let useGeometry
      let useGeometryNew = false
      // One unique cube/sphere for every unique geometry
      const key = sourceGeometry.uuid

      if (key in instanceReuser) {
        useGeometry = instanceReuser.get(key)
      } else if (newGeo === 'cubes') {
        useGeometry = new THREE.BoxBufferGeometry(1, 1, 1)
        useGeometryNew = true
      } else if (newGeo === 'spheres') {
        let originalTriangles = Math.floor(sourceGeometry.attributes.position.count / 3)
        if (sourceGeometry.index !== null) {
          originalTriangles = Math.floor(sourceGeometry.index.count / 3)
        }
        let targetTriangles = originalTriangles
        if (simplifyPercentage < 1.0) {
          targetTriangles = simplifyStart + (originalTriangles - simplifyStart) * simplifyPercentage
        }
        const targetQuads = Math.floor(targetTriangles / 2)
        const sideA = Math.round(Math.sqrt(targetQuads))
        const sideB = Math.round(targetQuads / sideA)
        useGeometry = new THREE.SphereBufferGeometry(1.0, sideA, sideB)
        useGeometryNew = true
      }

      if (!useGeometry) return

      if (useGeometryNew) {
        const size = new THREE.Vector3()
        const center = new THREE.Vector3()
        sourceGeometry.boundingBox.getSize(size)
        sourceGeometry.boundingBox.getCenter(center)
        const positions = useGeometry.attributes.position.array
        for (let i = 0; i < positions.length; i += 3) {
          const x = positions[i + 0]
          const y = positions[i + 1]
          const z = positions[i + 2]
          positions[i + 0] = x * size.x + center.x
          positions[i + 1] = y * size.y + center.y
          positions[i + 2] = z * size.z + center.z
        }
        useGeometry.computeBoundingBox()
        useGeometry.computeBoundingSphere()
        instanceReuser.set(key, useGeometry)
      }

      if (mesh.debugGeometry === undefined) {
        // Remember original mesh
        mesh.debugGeometry = mesh.geometry
      } else {
        mesh.geometry.dispose() // Remove previous debug mesh
        mesh.geometry = null
      }
      mesh.geometry = useGeometry
    })
  }

  mockUpdate () {
    this.mockUpdateGeometry()
    this.mockUpdateMaterial()
    this.mock.active = JSON.parse(JSON.stringify(this.mock.update))
  }
}
