import _get from 'lodash/get'
import _isNil from 'lodash/isNil'
import _keyBy from 'lodash/keyBy'
import _map from 'lodash/map'
import _pick from 'lodash/pick'
import _reduce from 'lodash/reduce'
import * as fromMaterialAndColorSelectors from '../../../components/visualizer/panels/material-and-color/material-and-color-selectors'
import { RENDER_PRESETS } from '../../../constants'
import * as fromClone from '../clone'
import * as fromColors from '../colors'
import * as fromCombinations from '../combinations'
import * as fromCombinationsSelectors from '../combinations/selectors'
import * as fromMaterials from '../materials'
import * as fromPatterns from '../patterns/textures'
import * as fromProjectsSelectors from '../projects/selectors'
import * as fromSelection from '../selection'
import * as fromDefaultTemplate from '../templates/default-template'
import * as fromThreeviewerFiles from '../threeviewer/files'
import * as fromThreeviewerSelectors from '../threeviewer/selectors'
import * as fromThreeviewerUI from '../threeviewer/ui'
import * as fromThreeviewerVive from '../threeviewer/vive'
import { group, moveNode, ungroup } from '../tree'
import * as fromUndoRedo from '../undo-redo'
import { initControls as initTriplanarControls } from './triplanar'

const cloneObjectsPropertyState = (objects, property) => {
  if (objects.length <= 0 || !objects[0][property] || !objects[0][property].clone) return {}

  return objects.reduce((acc, obj) => {
    return Object.assign(acc, {
      [obj.uuid]: obj[property].clone()
    })
  }, {})
}

const cloneTrackedRotations = (trackedRotations) => {
  return new Map(trackedRotations)
}

const getChangedObjects = (objects, objectsStateOnDown, property) => {
  return objects.filter((object) => {
    const oldState = objectsStateOnDown[object.uuid]
    return oldState && !oldState.equals(object[property])
  })
}

const getProgressPercentage = ({ total, loaded, target }) => {
  let tot = 0
  try {
    tot = parseInt(target.getResponseHeader('file-size'))
  } catch (e) {
    console.error(e)
  }
  if (total) {
    tot = total
  }

  var retVal = tot === 0 ? 100 : (loaded / (tot)) * 100
  if (Number.isNaN(retVal)) return 100

  return retVal
}

function getUrlPathname (url) {
  var _url = new window.URL(url)
  return _url.pathname + _url.search
}

// Used for comparing two floating point numbers to avoid floating point errors. Epsilon is a measure of "close enough"
function approxEquality (a, b) {
  const epsilon = 0.00001
  return Math.abs(a - b) <= epsilon
}

async function setAppearanceFromPipette (sceneGraphObject, dispatch) {
  const patternID = sceneGraphObject.userData.patternId
  const colorID = sceneGraphObject.userData.colorId

  dispatch(fromPatterns.removePattern())
  await dispatch(fromMaterials.setMaterial(sceneGraphObject.userData.materialId))

  if (colorID) {
    await dispatch(fromColors.setColor({ id: colorID }))
  }

  if (patternID) {
    await dispatch(fromPatterns.setPattern(patternID))
  }
}

export function setupFileLoaders (viewer, dispatch) {
  const createHandleProgress = (type) => (event) => {
    dispatch(fromThreeviewerFiles.fileProgress({
      file: _get(event, 'currentTarget.responseURL') && getUrlPathname(event.currentTarget.responseURL),
      progress: getProgressPercentage(event),
      type
    }))
  }

  const createHandleError = (type) => (event) => {
    let errorObject = { type }

    if (typeof event === 'string') {
      errorObject.message = event
    }

    if (event.currentTarget) {
      errorObject.file = event.currentTarget.responseURL && getUrlPathname(event.currentTarget.responseURL)
      errorObject = { ...errorObject, ...(_pick(event.currentTarget, 'statusText', 'status')) }
    }

    dispatch(fromThreeviewerFiles.fileError(errorObject))
  }

  const createHandleStartLoad = (type) => (file) => {
    dispatch(fromThreeviewerFiles.fileProgress({
      file,
      progress: 0,
      type
    }))
  }

  const createHandleFinishedLoading = (type) => (file) => {
    dispatch(fromThreeviewerFiles.fileProgress({
      file,
      progress: 100,
      type
    }))
  }

  // Models
  const handleModelProgress = createHandleProgress('model')
  const handleModelStartLoad = createHandleStartLoad('model')

  viewer.loader.addListener('onProgress', handleModelProgress)
  viewer.loader.addListener('onStartLoad', handleModelStartLoad)

  // Materials
  const handleMaterialProgress = createHandleProgress('material')
  const handleMaterialError = createHandleError('material')
  const handleMaterialStartLoad = createHandleStartLoad('material')
  const handleMaterialEndLoad = createHandleFinishedLoading('material')

  viewer.materialLoader.addListener('onProgress', handleMaterialProgress)
  viewer.materialLoader.addListener('onError', handleMaterialError)
  viewer.materialLoader.addListener('onStartLoad', handleMaterialStartLoad)
  viewer.materialLoader.addListener('loaded', handleMaterialEndLoad)
}

export function setupPicker (viewer, getState, dispatch) {
  viewer.picker.on('select', (objects) => {
    const addToUndoHistory = !viewer.snappingTool.enabled
    dispatch(fromSelection.select(objects, addToUndoHistory, true))
    viewer.triplanarTool.select(objects.filter((node) => !_get(node, 'userData.isTemplate', false))[0])
    dispatch(initTriplanarControls())
    dispatch(fromClone.clear())
  })

  viewer.picker.on('deselect', (objects, reselection) => {
    if (reselection) return
    const selections = Object.values(viewer.picker.selection)

    const diff = selections.reduce((prev, curr) =>
      objects.includes(curr) ? prev : [...prev, curr], [])

    const addToUndoHistory = !viewer.snappingTool.enabled
    dispatch(fromSelection.select(diff, addToUndoHistory))
    dispatch(fromClone.clear())
    if (objects.includes(viewer.triplanarTool.source)) {
      viewer.triplanarTool.disable()
    }
  })

  viewer.picker.on('select-KeyP', (sceneGraphObject) => {
    const state = getState()
    const selectionMode = fromMaterialAndColorSelectors.getSelectionMode(state)

    // Returns if picked node is eather undefined/uga or template.
    if (!sceneGraphObject) return
    if (sceneGraphObject.userData.modelSource === 'ugabank') return
    if (sceneGraphObject.userData.modelType === 'template') return
    if (sceneGraphObject.userData.materialId === undefined) return

    // Return if selection is uga or template
    if (!selectionMode) return
    if (selectionMode === 'template') return

    // Return if pipette is used on the same single item which is also selected.
    const selectedNodeIds = Object.keys(viewer.picker.selection)
    if (selectedNodeIds.length === 1 && selectedNodeIds.includes(sceneGraphObject.uuid)) return

    setAppearanceFromPipette(sceneGraphObject, dispatch)
  })
}

export function setupTransformTool (viewer, gizmo, dispatch) {
  let objectsPositionOnDown = {}
  let objectsRotationOnDown = {}
  let trackedRotationsOnDown

  gizmo.control.rotationSnap = (Math.PI / 180) * 5

  const updateToolbarRotationFields = () => {
    if (gizmo.dummyMesh) {
      const commonRotation = {
        x: gizmo.dummyMesh.rotation.x,
        y: gizmo.dummyMesh.rotation.y,
        z: gizmo.dummyMesh.rotation.z
      }

      dispatch(fromThreeviewerUI.updateToolbarRotationFields(commonRotation))
    }
  }

  const updateToolbarPositionFields = (objects) => {
    // If there is a selection of objects, get their transforms and update the toolbar panel
    if (objects.length > 0) {
      const commonPosition = objects.reduce((acc, next) => {
        const newX = approxEquality(acc.x, next.position.x) ? acc.x : '-'
        const newY = approxEquality(acc.y, next.position.y) ? acc.y : '-'
        const newZ = approxEquality(acc.z, next.position.z) ? acc.z : '-'
        return { x: newX, y: newY, z: newZ }
      }, objects[0].position)

      dispatch(fromThreeviewerUI.updateToolbarPositionFields(commonPosition))
    }
  }

  // Save positions of selected objects before we start manual snapping in order to build the undo/redo command on mouseUp event
  gizmo.on('snapStart', () => {
    const objects = gizmo.getObjects()
    objectsPositionOnDown = cloneObjectsPropertyState(objects, 'position')
    objectsRotationOnDown = cloneObjectsPropertyState(objects, 'rotation')
  })

  gizmo.on('mouseDown', (event) => {
    const mode = gizmo.getMode()
    const axis = gizmo.control.axis
    const objects = gizmo.getObjects()
    const trackedRotations = gizmo.getTrackedRotations()

    if (mode === 'translate' || (mode === 'snapping' && axis === 'SNAP')) {
      objectsPositionOnDown = cloneObjectsPropertyState(objects, 'position')
    }

    if (mode === 'rotate' || (mode === 'snapping' && (axis === 'SNAP_X' || axis === 'SNAP_Y' || axis === 'SNAP_Z'))) {
      objectsPositionOnDown = cloneObjectsPropertyState(objects, 'position')
      objectsRotationOnDown = cloneObjectsPropertyState(objects, 'rotation')
      trackedRotationsOnDown = cloneTrackedRotations(trackedRotations)
    }

    viewer.cameraManager.disable('mouse')

    if (event.altKey) {
      const isDragging = true
      dispatch(fromClone.clone(isDragging))
    }
  })

  gizmo.on('mouseUp', (event) => {
    const mode = gizmo.getMode()
    const axis = gizmo.control.axis
    const holeSnappingEnabled = gizmo.control.holeSnappingEnabled
    const objects = gizmo.getObjects()
    const trackedRotations = gizmo.getTrackedRotations()
    event = event || {}

    if (event.autoGroupingData) {
      const data = event.autoGroupingData
      switch (data.action) {
        case 'UNGROUP':
          dispatch(ungroup(data.group.uuid))
          dispatch(fromSelection.select(data.selectionCallback(), false))
          break
        case 'REMOVE_NODE':
          dispatch(moveNode(data.node.uuid, data.node.parent.parent.uuid))
          dispatch(fromSelection.select(data.selectionCallback(), false))
          break
        case 'GROUP':
          dispatch(group(data.nodeIds))
          dispatch(fromSelection.select(data.selectionCallback(), false))
          break
        case 'ADD_NODE':
          dispatch(moveNode(data.node.uuid, data.group.uuid))
          dispatch(fromSelection.select(data.selectionCallback(), false))
          break
        case 'NO_OP':
          break
        default: console.warn('Unhandled'); break
      }
    }

    viewer.transformGizmo.autoGroupManager.enabled = false // move?

    if (mode === 'translate' || (mode === 'snapping' && axis === 'SNAP' && !holeSnappingEnabled)) {
      const changedObjects = getChangedObjects(objects, objectsPositionOnDown, 'position')

      if (changedObjects.length) {
        const command = viewer.commands.createSetPositionCommand({
          objects: changedObjects,
          newValuesMap: cloneObjectsPropertyState(changedObjects, 'position'),
          optionalOldValuesMap: objectsPositionOnDown,
          afterExecute () {
            gizmo.reattachObjects()
            gizmo.updateHoleMarkers()
            dispatch(fromDefaultTemplate.adjustWallInScene())
            updateToolbarPositionFields(gizmo.getObjects())
          },
          afterUndo () {
            gizmo.reattachObjects()
            gizmo.updateHoleMarkers()
            dispatch(fromDefaultTemplate.adjustWallInScene())
            updateToolbarPositionFields(gizmo.getObjects())
          }
        })
        if (!viewer.roomManager.enabled) {
          dispatch(fromUndoRedo.addCommand(command))
        }
      }
    }

    if (mode === 'rotate' || (mode === 'snapping' && (axis === 'SNAP_X' || axis === 'SNAP_Y' || axis === 'SNAP_Z' || holeSnappingEnabled))) {
      const changedObjectsRotation = getChangedObjects(objects, objectsRotationOnDown, 'rotation')
      const changedObjectsPosition = getChangedObjects(objects, objectsPositionOnDown, 'position')
      const changedObjects = [...changedObjectsPosition, ...changedObjectsRotation]

      if (changedObjects.length) {
        const command = viewer.commands.createSetPositionAndRotationCommand({
          objects: changedObjects,
          gizmo,
          newRotationValuesMap: cloneObjectsPropertyState(changedObjects, 'rotation'),
          newPositionValuesMap: cloneObjectsPropertyState(changedObjects, 'position'),
          newTrackedRotations: cloneTrackedRotations(trackedRotations),
          optionalOldRotationValuesMap: objectsRotationOnDown,
          optionalOldPositionValuesMap: objectsPositionOnDown,
          oldTrackedRotations: trackedRotationsOnDown,
          afterExecute () {
            gizmo.reattachObjects()
            gizmo.updateHoleMarkers()
            dispatch(fromDefaultTemplate.adjustWallInScene())
            updateToolbarRotationFields()
          },
          afterUndo () {
            gizmo.reattachObjects()
            gizmo.updateHoleMarkers()
            dispatch(fromDefaultTemplate.adjustWallInScene())
            updateToolbarRotationFields()
          }
        })
        if (!viewer.roomManager.enabled) {
          dispatch(fromUndoRedo.addCommand(command))
        }
      }
    }

    viewer.cameraManager.enable('mouse')
    viewer.cloneTool.saveTransform()
  })

  // When an object has moved (not currently moving) and when a new object is selected, update the transform toolbar values
  gizmo.on('moveDone', () => {
    function findParentRoot (node) {
      if (!node.parent) {
        return null
      }
      if (node.parent.userData.isModelRoot) {
        return node.parent
      }

      return findParentRoot(node.parent)
    }

    if (Object.values(viewer.picker.selection).length > 0) {
      const transforms = Object.values(viewer.picker.selection).map(node => {
        const parent = findParentRoot(node)
        if (!parent) {
          return null
        }
        return { rotation: parent.rotation.clone(), position: parent.position.clone() }
      }).filter(node => node) // filter walls from selection

      updateToolbarPositionFields(transforms)
      updateToolbarRotationFields()
    }
  })

  gizmo.on('moved', () => {
    dispatch(fromDefaultTemplate.adjustWallInScene())
  })

  gizmo.on('offset-position', event => {
    const { prevPositions } = event
    const objects = gizmo.getObjects()
    const changedObjects = getChangedObjects(objects, prevPositions, 'position')

    if (changedObjects.length) {
      const command = viewer.commands.createSetPositionCommand({
        objects: changedObjects,
        newValuesMap: cloneObjectsPropertyState(changedObjects, 'position'),
        optionalOldValuesMap: prevPositions,
        afterExecute () {
          gizmo.reattachObjects()
          gizmo.updateHoleMarkers()
          dispatch(fromDefaultTemplate.adjustWallInScene())
          updateToolbarPositionFields(gizmo.getObjects())
        },
        afterUndo () {
          gizmo.reattachObjects()
          gizmo.updateHoleMarkers()
          dispatch(fromDefaultTemplate.adjustWallInScene())
          updateToolbarPositionFields(gizmo.getObjects())
        }
      })
      if (!viewer.roomManager.enabled) {
        dispatch(fromUndoRedo.addCommand(command))
      }
    }
  })

  gizmo.on('offset-rotation', event => {
    const { prevRotations, prevPositions, prevTrackedRotations } = event
    const objects = gizmo.getObjects()
    const trackedRotations = gizmo.getTrackedRotations()
    const changedObjectsRotation = getChangedObjects(objects, prevRotations, 'rotation')
    const changedObjectsPosition = getChangedObjects(objects, prevPositions, 'position')
    const changedObjects = [...changedObjectsPosition, ...changedObjectsRotation]

    if (changedObjects.length) {
      const command = viewer.commands.createSetPositionAndRotationCommand({
        objects: changedObjects,
        gizmo,
        newRotationValuesMap: cloneObjectsPropertyState(changedObjects, 'rotation'),
        newPositionValuesMap: cloneObjectsPropertyState(changedObjects, 'position'),
        newTrackedRotations: cloneTrackedRotations(trackedRotations),
        optionalOldRotationValuesMap: prevRotations,
        optionalOldPositionValuesMap: prevPositions,
        oldTrackedRotations: prevTrackedRotations,
        afterExecute () {
          gizmo.reattachObjects()
          gizmo.updateHoleMarkers()
          dispatch(fromDefaultTemplate.adjustWallInScene())
          updateToolbarRotationFields()
        },
        afterUndo () {
          gizmo.reattachObjects()
          gizmo.updateHoleMarkers()
          dispatch(fromDefaultTemplate.adjustWallInScene())
          updateToolbarRotationFields()
        }
      })
      if (!viewer.roomManager.enabled) {
        dispatch(fromUndoRedo.addCommand(command))
      }
    }
  })

  viewer.overlayScene.add(gizmo.control)
}

export function setupSnapping (viewer, dispatch) {
  let oldValuesMap = {}

  viewer.snappingTool.on('snappedStart', ({ object, position, rotation }) => {
    oldValuesMap[object.uuid] = { position, rotation }
  })

  viewer.snappingTool.on('snapped', ({ object, position, rotation }) => {
    dispatch(fromDefaultTemplate.adjustWallInScene())

    const oldValue = oldValuesMap[object.uuid]

    if (oldValue) {
      const command = viewer.commands.createSnappingCommand({
        object,
        newValue: { position, rotation },
        oldValue,
        afterUndo () { dispatch(fromDefaultTemplate.adjustWallInScene()) },
        afterExecute () { dispatch(fromDefaultTemplate.adjustWallInScene()) }
      })

      if (!viewer.roomManager.enabled) {
        dispatch(fromUndoRedo.addCommand(command))
      }
    }

    oldValuesMap = {}
  })
}

export function setupCloneTool (viewer) {
  viewer.cloneTool = new viewer.CloneTool(viewer.viewerUtils)
}

export function setupPostProcess (dispatch, viewer, settings) {
  const THREE = viewer.THREE

  // Load LUT table
  const lutTable = new THREE.TextureLoader().load('/img/lut/dpd_lut.png')
  viewer.postProcessManager.lut.enabled = true
  viewer.postProcessManager.lut.setLutTable = lutTable
  viewer.renderScene.scene.background = new THREE.Color('rgb(250, 250, 250)')

  // Enabled/disabled some processes
  Object.assign(viewer.postProcessManager.fxaa, settings.postProcess.fxAA)
  Object.assign(viewer.postProcessManager.overlayEnabled, settings.postProcess.overlay)
  Object.assign(viewer.postProcessManager.outline.enabled, settings.postProcess.outline)
  Object.assign(viewer.postProcessManager.ssao.enabled, settings.postProcess.ssao)

  const safeFrameVisibility = _isNil(settings.postProcess.safeFrame) ? true : settings.postProcess.safeFrame
  dispatch(fromThreeviewerUI.setSafeFrameVisibility(safeFrameVisibility))
  dispatch(fromThreeviewerUI.saveSafeFrameVisibility(safeFrameVisibility))

  // SSAO specific settings
  Object.assign(viewer.postProcessManager.ssao.enabled, settings.ssao)
  Object.assign(viewer.postProcessManager.ssao.originalLumInfluence, settings.ssao.lumInfluence)
  Object.assign(viewer.postProcessManager.ssao.originalAoClamp, settings.ssao.aoClamp)

  // ToneMapping
  Object.assign(viewer.renderer, settings.toneMapping)

  // PostFXs env toggle
  if (process.env.NO_POSTFX) {
    viewer.postProcessManager.ssao = false
    viewer.postProcessManager.bloom = false
  }
}

export const setupVive = (getState, dispatch) => {
  const { vive } = fromThreeviewerSelectors.getViewer(getState())

  if (vive && vive.isVRAvailable()) {
    dispatch(fromThreeviewerVive.setAsAvailable())

    vive.initVR('/models/')

    dispatch(fromThreeviewerVive.loadMaterialsForVR())
      .then((materials) => {
        vive.initFirstController({
          locomotion: {
            event: vive.controllerEvents.TriggerClicked
          }
        })

        vive.initSecondController({
          materialEditor: {
            materials: materials
          },
          polaroid: {
            onTriggered: (cameraSettings) => {
              const state = getState()

              const combinationId = fromCombinationsSelectors.getCurrentId(state)
              const projectId = fromProjectsSelectors.getCurrentId(state)

              dispatch(fromCombinations.postRender({
                combinationId,
                projectId,
                cameraSettings,
                renderSettings: {
                  preset: {
                    ...RENDER_PRESETS['1'],
                    vrscenes: { default: 'roomset2.vrscene' }
                  }
                }
              }))
            }
          },
          specialSnapping: {
            event: vive.controllerEvents.TriggerClicked
          }
        })
      })
  }
}

export function setupSelectParentObjects (viewer, getState, dispatch) {
  const KEYBOARD_KEYCODE = 71 // 'g'-key

  const keyupHandler = (event) => {
    if (event.keyCode === KEYBOARD_KEYCODE) {
      const state = getState()
      const selectionState = _get(state, 'selection.selection', [])

      const selection = _reduce(selectionState, (memo, next) => {
        const obj = _get(viewer, ['objectTracker', 'interactions', 'nodeList', next])

        if (!obj) return memo

        return Object.assign(memo, { [obj.uuid]: obj })
      }, {})

      const parents = _map(selection, (value) => {
        return value.parent && value.parent.parent ? value.parent : value
      })

      const newSelection = _reduce(parents, (memo, obj) => {
        return Object.assign(memo, { [obj.uuid]: obj })
      }, {})

      const uuids = Object.keys(newSelection)
      const enabled = fromThreeviewerSelectors.getKeyBoardBindingsEnabled(state)
      if (uuids.length && enabled) {
        dispatch(fromSelection.selectFromUuids(uuids))
      }
    }
  }

  window.addEventListener('keyup', keyupHandler)

  setupSelectParentObjects.listeners = [
    { key: 'keyup', func: keyupHandler }
  ]
}

export function setupSelectSimilarMeshes (viewer, getState, dispatch) {
  const KEYBOARD_KEYCODE = 70 // 'f'-key

  const keyupHandler = (event) => {
    if (event.keyCode === KEYBOARD_KEYCODE) {
      const selection = viewer.picker.selection
      const state = getState()

      const getGeometryIds = (objs) => {
        return _reduce(objs, (memo, obj) => {
          if (obj.geometry) {
            return memo.concat(obj.geometry.uuid)
          } else if (obj.children) {
            return memo.concat(getGeometryIds(obj.children))
          }
        }, [])
      }

      var geometryIds = getGeometryIds(selection)
      var geometryMap = _pick(viewer.scene.geometryMap, geometryIds)
      var newSelection = _reduce(geometryMap, (memo, meshes) => {
        return Object.assign(memo, _keyBy(meshes, 'uuid'))
      }, {})

      var uuids = Object.keys(newSelection)
      const enabled = fromThreeviewerSelectors.getKeyBoardBindingsEnabled(state)
      if (uuids.length && enabled) {
        dispatch(fromSelection.selectFromUuids(uuids))
      }
    }
  }

  window.addEventListener('keyup', keyupHandler)

  setupSelectSimilarMeshes.listeners = [
    { key: 'keyup', func: keyupHandler }
  ]
}

export function setupAlignTool (viewer, dispatch) {
  viewer.overlayScene.add(viewer.alignTool.controlPreviews)
  viewer.overlayScene.add(viewer.alignTool.control)

  viewer.alignTool.on('preview', () => {
  })

  viewer.alignTool.on('aligned', (changedRootNodes, originalPositions) => {
    dispatch(fromDefaultTemplate.adjustWallInScene())

    const command = viewer.commands.createSetPositionCommand({
      objects: changedRootNodes,
      newValuesMap: cloneObjectsPropertyState(changedRootNodes, 'position'),
      optionalOldValuesMap: originalPositions,
      afterExecute () {
        dispatch(fromDefaultTemplate.adjustWallInScene())
        dispatch(fromSelection.select(changedRootNodes, false))
        dispatch(fromSelection.setPickerSelection())
      },
      afterUndo () {
        dispatch(fromDefaultTemplate.adjustWallInScene())
        dispatch(fromSelection.select(changedRootNodes, false))
        dispatch(fromSelection.setPickerSelection())
      }
    })

    if (!viewer.roomManager.enabled) {
      dispatch(fromUndoRedo.addCommand(command))
    }
  })
}

export function setupAssembleTool (viewer, dispatch) {
  viewer.assembleTool.on('group', e => {
    dispatch(group(
      e.nodeIds,
      true, // add undo/redo action
      undefined, // no existing group node
      {
        // Set the name of the group
        name: `Assembled ${new Date().toISOString().split('T')[0]}`
      }
    ))
  })
}
