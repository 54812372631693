import * as THREE from 'three'
import AlchemyAOPostProcess from './plugins/postprocessing/AlchemyAOPostProcess.js'
import CopyPostProcess from './plugins/postprocessing/CopyPostProcess.js'
import FxAAPostProcess from './plugins/postprocessing/fxAAPostProcess.js'
import LUTPostProcess from './plugins/postprocessing/LUTPostProcess.js'
import OutlinePostProcess from './plugins/postprocessing/OutlinePostProcess.js'
import SafeFramePostProcess from './plugins/postprocessing/SafeFramePostProcess.js'
import SSAOPostProcess from './plugins/postprocessing/SSAOPostProcess.js'

export default class PostProcessManager {
  constructor (renderer, params, statsFunction, renderOnNextFrameFunction) {
    params = params || {}
    this.renderOnNextFrame = renderOnNextFrameFunction
    this.renderer = renderer
    this.enabled = true
    const size = new THREE.Vector2()
    this.renderer.getSize(size)
    const width = size.x
    const height = size.y
    this.width = width
    this.height = height
    this.overlayScene = params.overlayScene
    this.overrideMaterial = null
    this.overrideDebugScene = null
    this.sceneRender = true
    this.statsFunction = statsFunction
    this._resolutionScale = 1.0
    this._aspectRatio = new THREE.Vector2()

    // Resolution downscaling
    this.timeSinceLast = window.performance.now()
    this.cutoffFrameRate = 40
    this.frameRate = 0.0
    this.frameMs = 0.0

    // Used to override the resolution scaling when debugging.
    this.forceResolutionScaling = false
    this.forceResolutionScalingValue = 0.5

    this.setupRenderTargets(renderer, width, height)

    this.graphicsTypes = {
      ACCUMULATION: 0,
      STANDARD: 1,
      MOBILE: 2
    }

    this.needsUpdate = false

    this.resolutions = {
      STANDING: 2,
      WIDE: 1,
      SQUARE: 0
    }

    this._resolutions = [{ x: 1, y: 1 }, { x: 16, y: 9 }, { x: 9, y: 16 }]

    this.copy = new CopyPostProcess()
    this.outline = new OutlinePostProcess(width, height)
    this.outline.setEdgeWidth(4)

    this.transformOutline = new OutlinePostProcess(width, height)
    this.transformOutline.edgeColor.setRGB(0, 0, 0)
    this.transformOutline.setEdgeWidth(1)

    this.fxaa = new FxAAPostProcess(width, height)
    this.ssao = new SSAOPostProcess(renderer, width, height)

    this.alchemyAO = false
    this.ssao2 = new AlchemyAOPostProcess(renderer, width, height)

    this.safeFrame = new SafeFramePostProcess(width, height, this._resolutions)
    this.lut = new LUTPostProcess()
    this.overlayEnabled = false

    // Note: Setting this one last since it will currently trigger a render
    this.graphicsType = this.graphicsTypes.STANDARD
  }

  setupRenderTargets (renderer, width, height) {
    var capabilities = renderer.capabilities

    var options = {
      minFilter: THREE.NearestFilter,
      magFilter: THREE.NearestFilter,
      format: THREE.RGBAFormat,
      anisotropy: capabilities.getMaxAnisotropy(),
      stencilBuffer: false,
      type: THREE.UnsignedByteType,
      depthBuffer: false,
      encoding: THREE.GammaEncoding
    }

    this.frameBufferB = new THREE.WebGLRenderTarget(width, height, options)
    this.frameBufferB.texture.name = 'PostProcessB'

    options.depthBuffer = true
    options.depthTexture = new THREE.DepthTexture(width, height)
    options.depthTexture.name = 'PostProcessA depth'
    options.depthTexture.type = THREE.UnsignedIntType

    this.frameBufferA = new THREE.WebGLRenderTarget(width, height, options)
    this.frameBufferA.texture.name = 'PostProcessA'
  }

  dispose () {
    this.frameBufferA.dispose()
    this.frameBufferB.dispose()
  }

  setSize (width, height) {
    if (width === this.width && height === this.height) {
      return
    }

    this.width = width
    this.height = height
    this.frameBufferA.setSize(width, height)
    this.frameBufferB.setSize(width, height)
    if (this.ssao !== undefined) {
      this.ssao.setSize(width, height)
      this.ssao2.setSize(width, height)
    }
    if (this.fxaa !== undefined) {
      this.fxaa.setSize(width, height)
    }
    if (this.outline !== undefined) {
      this.outline.setSize(width, height)
    }
    if (this.transformOutline !== undefined) {
      this.transformOutline.setSize(width, height)
    }

    if (this.safeFrame !== undefined) {
      this.safeFrame.setSize(width, height)
    }
    this.renderOnNextFrame()
  }

  get safeFrameHeight () {
    return this.safeFrame.safeFrameHeight
  }

  setOutlineColors (r, g, b) {
    // Outline selection colors
    this.outline.edgeColor.setRGB(r, g, b)
    this.safeFrame.safeFramePass.material.uniforms.uSafeFrameColor.value.setRGB(r, g, b)
    this.renderOnNextFrame()
  }

  updateFrameRate (time) {
    const delta = time - this.timeSinceLast
    this.timeSinceLast = time
    this.frameRate = 1000.0 / delta
    this.frameMs = delta
    return delta / 1000
  }

  render (renderScene, camera) {
    var renderer = this.renderer
    var gl = this.renderer.getContext()

    const oldDepthTest = gl.getParameter(gl.DEPTH_TEST)
    const oldDepthMask = gl.getParameter(gl.DEPTH_WRITEMASK)
    const oldColorMask = gl.getParameter(gl.COLOR_WRITEMASK)

    // Turn off auto-reset so statiatics is gathered for _all_ rendering including post-processing
    // Otherwise is is reset after each render call
    var oldAutoInfoReset = renderer.info.autoReset
    renderer.info.autoReset = false

    // Turn off auto-clear so render targets are not cleared before rendering
    // We use force=true instead. This way we don't clear render targets that we are filling completely anyway
    var oldAutoClear = renderer.autoClear
    renderer.autoClear = false

    gl.colorMask(true, true, true, true)

    const scaling = this.scalingActive()

    // Render at a scale determined by the resolutionScale.
    if (scaling) {
      const w = this.getScaledWidth()
      const h = this.getScaledHeight()
      this.frameBufferA.viewport.set(0, 0, w, h)
      this.frameBufferB.viewport.set(0, 0, w, h)
    }

    if (this.sceneRender) {
      renderer.sortObjects = true
      // Render scene
      // We always render to an off screen buffer even if we are sending straight to the canvas. This let us use another resolution
      gl.depthMask(true)
      gl.enable(gl.DEPTH_TEST)
      const useScene = this.overrideDebugScene === null ? renderScene.scene : this.overrideDebugScene
      const oldOverrideMaterial = useScene.overrideMaterial
      useScene.overrideMaterial = this.overrideMaterial
      if (this.overrideDebugScene !== null) {
        camera.updateMatrixWorld()
      }
      renderer.setRenderTarget(this.frameBufferA)
      renderer.clear()
      renderer.render(useScene, camera)
      useScene.overrideMaterial = oldOverrideMaterial
    }
    renderer.sortObjects = false

    this.statsFunction(renderer, 'render', renderer.info.render.calls, renderer.info.render.triangles, renderer.info.render.lines, renderer.info.render.points)
    renderer.info.reset()

    let ppCalls = 0
    let ppTriangles = 0
    let ppLines = 0
    let ppPoints = 0

    // None of our post-processes writes to the depth buffer so we disable depth writes here
    // THREE will turn it on again so we pretend that our framebuffer with depth buffer has no depth buffer
    gl.disable(gl.DEPTH_TEST)
    gl.depthMask(false)
    this.frameBufferA.depthBuffer = false

    // These buffers will be swapped by passes
    var buffers = {
      input: this.frameBufferA,
      output: this.frameBufferB
    }

    // SSAO
    if (this.ssao.enabled) {
      if (this.alchemyAO) {
        this.ssao2.onlySSAO = this.ssao.onlySSAO
        this.ssao2.setUVScale(this.resolutionScale)
        this.ssao2.render(this.renderer, buffers, camera)
        if (this.ssao2.accumulate && this.ssao2.iteration < 256) {
          this.renderOnNextFrame()
        }
      } else if (this.ssao) {
        this.ssao.setUVScale(this.resolutionScale)
        // NOTE: SSAO need depth buffer as input so must be run after scene render with nothing in-between
        this.ssao.render(this.renderer, buffers, camera)
      }
    }

    // FXAA
    if (this.fxaa && this.fxaa.enabled) {
      this.fxaa.setUVScale(this.resolutionScale)
      this.fxaa.render(renderer, buffers)
    }

    // Lut
    if (this.lut && this.lut.enabled) {
      this.lut.setUVScale(this.resolutionScale)
      this.lut.render(renderer, buffers)
    }

    if (scaling) {
      this.frameBufferA.viewport.set(0, 0, this.width, this.height)
      this.frameBufferB.viewport.set(0, 0, this.width, this.height)

      this.copy.setUVScale(this.resolutionScale, this.resolutionScale)
      this.copy.renderToBuffer(renderer, buffers)
      this.copy.setUVScale(1.0, 1.0)
    }

    // Outline for transform before selection outline
    if (this.transformOutline && this.transformOutline.enabled && renderScene.meshes.length > 2) {
      this.transformOutline.render(renderer, buffers, camera, renderScene.transformOutlineObjects)
    }

    // Outline
    if (this.outline && this.outline.enabled) {
      this.outline.render(renderer, buffers, camera, renderScene.outlineObjects)
    }

    // Reset stats so we can get stat on overlayScene
    ppCalls += renderer.info.render.calls
    ppTriangles += renderer.info.render.triangles
    ppLines += renderer.info.render.lines
    ppPoints += renderer.info.render.points
    renderer.info.reset()

    // Overlay
    if (this.overlayScene && this.overlayEnabled) {
      renderer.setRenderTarget(buffers.input)
      renderer.render(this.overlayScene, camera)
    }

    this.statsFunction(renderer, 'memory')
    this.statsFunction(renderer, 'overlay', renderer.info.render.calls, renderer.info.render.triangles, renderer.info.render.lines, renderer.info.render.points)
    // Continue with stats for post-process
    renderer.info.render.calls = ppCalls
    renderer.info.render.triangles = ppTriangles
    renderer.info.render.lines = ppLines
    renderer.info.render.points = ppPoints

    // Safe-frame
    // Add mode to fade out from safe-frame to use less memory for backbuffers
    // TODO: Tempting to merge safe-frame and the copy when both are active
    if (this.safeFrame && this.safeFrame.enabled) {
      this.safeFrame.render(renderer, buffers)
    }

    // Blit final output to canvas
    if (this.copy.enabled) {
      this.copy.renderToScreen(renderer, buffers.input)
    }

    // Make sure that we reset depth testing and color mask
    // NOTE: It is not guaranteed that three.js thinks that this is the state that is bound... So a bit hacky
    gl.depthMask(oldDepthMask)
    if (oldDepthTest) {
      gl.enable(gl.DEPTH_TEST)
    } else {
      gl.disable(gl.DEPTH_TEST)
    }
    gl.colorMask(...oldColorMask)

    this.statsFunction(renderer, 'postprocess', renderer.info.render.calls, renderer.info.render.triangles, renderer.info.render.lines, renderer.info.render.points)
    renderer.info.autoReset = oldAutoInfoReset
    renderer.info.reset()

    renderer.autoClear = oldAutoClear

    this.frameBufferA.depthBuffer = true
  }

  scalingActive () {
    return this.resolutionScale < 1.0 || this.forceResolutionScaling
  }

  getScaledWidth () {
    return Math.round(this.width * this.resolutionScale)
  }

  getScaledHeight () {
    return Math.round(this.height * this.resolutionScale)
  }

  get resolutionScale () {
    return this.forceResolutionScaling ? this.forceResolutionScalingValue : this._resolutionScale
  }

  set resolutionScale (s) {
    if (s !== this._resolutionScale) {
      this._resolutionScale = s
      this.renderOnNextFrame()
    }
  }

  get graphicsType () {
    return this.currentGraphicsType
  }

  set graphicsType (value) {
    var val = parseInt(value)
    this.outline.enabled = true
    this.transformOutline.enabled = true
    this.overlayEnabled = true
    this.safeFrame.enabled = true
    this.lut.enabled = false
    this.fxaa.enabled = false
    this.copy.enabled = true
    this.ssao.enabled = false
    if (val === this.graphicsTypes.ACCUMULATION) {
    } else if (val === this.graphicsTypes.MOBILE) {
    } else if (val === this.graphicsTypes.STANDARD) {
      this.ssao.enabled = true
      this.fxaa.enabled = true
    }
    this.currentGraphicsType = val
    this.renderOnNextFrame()
  }

  get aspectRatio () { return this._aspectRatio }
  set aspectRatio (v) {
    if (!this._aspectRatio.equals(v)) {
      this._aspectRatio.copy(v)
      this.safeFrame.safeFrameRes = v
      this.renderOnNextFrame()
    }
  }
}
