import React, { useState, useEffect } from 'react'
// import fetch from '../../utils/fetch'
import { useDispatch } from 'react-redux'

import Label from '../common/form/label'
import InputSelect from '../common/form/input-select'

import * as fromDesigns from '../../stores/ducks/designs'
import * as fromVirtualProduct from '../../stores/ducks/virtual-products'

// import _cloneDeep from 'lodash/cloneDeep'

import _capitalize from 'lodash/capitalize'

import { MODEL_RESOURCE_TYPES } from '../../constants'

/**
 * OBS: This file is a work in progress.
 * Current goal is to merge this wth search-filters.tsx or share more code.
 * This file is currently only used for RESOURCE_VIRTUAL_PRODUCTS in designs.
 */

const {
  RESOURCE_VIRTUAL_PRODUCTS
} = MODEL_RESOURCE_TYPES

export type Option = {
  label: string
  value: any
}

export type Filter = {
  value: Option[],
  type: 'text' | 'radio' | 'inputSelect' | 'dropdown',
  label: string
  placeholder: string
  defaultValue: Option
  options: Option[]
  direction: any
  disabled?: boolean
  key: string
}

type FilterInformation = {
  key: string
  options: null | Option[]
  value: null | Option
  type: 'inputSelect'
  direction?: any
  defaultValue?: Option
  disabled?: boolean
  label?: string
  placeholder?: string
}

type FilterState = {
  status: FilterInformation
}

type FilterKeys = keyof FilterState

const VirtualProductStatus = {
  status: {
    key: 'status',
    label: 'status',
    options: [{
      label: 'All',
      value: null
    },
    {
      label: 'Design Prototype',
      value: 'DESIGN_PROTOTYPE'
    },
    {
      label: 'Product Concept',
      value: 'PRODUCT_CONCEPT'
    },
    {
      label: 'Development Prototype',
      value: 'DEVELOPMENT_PROTOTYPE'
    },
    {
      label: 'Verified Virtual Product',
      value: 'VERIFIED_VIRTUAL_PRODUCT',
    },
    {
      label: 'Validated Virtual Product',
      value: 'VALIDATED_VIRTUAL_PRODUCT'
    }
    ],
    type: 'inputSelect',
    value: {
      label: 'All',
      value: null
    }
  }
}
export default function NewSearchFilterComponent () {
  const [filters, setFilters] = useState<any>(VirtualProductStatus)
  const dispatch = useDispatch()

  // This code is commented out due to connecting to UPPLYSA to fetch the virtual products.
  // This will in the future be re-written to communicate trough GrapthQL.

  /*
  const getFilterOptionsVirtualProduct = async () => {
    const res = await fetch('/api/virtual-products/filter-options')
    const resjson = await res.json()
    if (resjson) {
      const keys = Object.keys(resjson) as FilterKeys[]
      const updatedFilters = _cloneDeep(filters)
      keys.forEach((key: FilterKeys) => {
        updatedFilters[key].options = [defaultValue].concat(createOptionsStructure(resjson[key]))
      })
      setFilters(statusObject)
    }
  }

  useEffect(() => {
    getFilterOptionsVirtualProduct()
  }, [])
  */

  useEffect(() => {
    return () => {
      handleInputSelectChange({ label: 'All', value: null }, filters.status)
    }
  }, [])

  const handleInputSelectChange = (option: Option, filter: FilterInformation) => {
    const key = filter.key as FilterKeys
    const updatedFilters = filters
    updatedFilters[key].value = option
    setFilters(updatedFilters)

    dispatch(fromDesigns.setResourceFilters({
      resource: RESOURCE_VIRTUAL_PRODUCTS,
      filters: { status: option.value }
    }))
    // dispatch(fromDesigns.performSearch())
    dispatch(fromVirtualProduct.filterVirtualProductFromUpplysa(option.value))
  }

  const createSelectionElement = (information: FilterInformation) => {
    const elements = {
      inputSelect: (
        <InputSelect
          options={information.options}
          value={information.value}
          defaultValue={information.defaultValue}
          onChange={(option: Option) => handleInputSelectChange(option, information)}
          isDisabled={information.disabled}
        />
      )
    }
    return (
      <div key={information.key} className='flex flex-column flex-auto'>
        <Label>{_capitalize(information?.label ?? information.key)}</Label>
        {elements[information.type] || <div>input type not implemented</div>}
      </div>
    )
  }
  return (
    <div>
      {Object.entries(filters).map(([key, information]: [string, any]) => (
        <div key={key} className='mb2 flex flex-auto width-100'>
          {createSelectionElement(information)}
        </div>
      )
      )}
    </div>
  )
}
