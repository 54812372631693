
import { AppThunk } from '..'
import Immutable from 'seamless-immutable'
import { createAction, handleActions } from 'redux-actions'
import { isNil, keyBy, omitBy, omit } from 'lodash'
import { StorageApiManifest } from '../../../utils/storage'
import fetch from '../../../utils/fetch'
import type { VirtualProductProjectListItem, VirtualProductCombined, UpdateVirtualProductPayload, VirtualProduct, ElasticVirtualProduct } from './VirtualProduct'
import * as fromThreeviewerFiles from '../threeviewer/files'

export enum FetchStatus {
    idle,
    fetching,
    fetched,
    failed
}

const receive = createAction<VirtualProductProjectListItem[]>('virtualProduct/receive')
const setSearchResult = createAction<ElasticVirtualProduct[]>('virtualProduct/setSearchResult')
const setFetchStatus = createAction<FetchStatus>('virtualProduct/setFetchStatus')
const setSearchStatus = createAction<FetchStatus>('virtualProduct/setSearchStatus')
const removeDisconnectedVP = createAction<string>('virtualProduct/removeDisconnectedVP')
const updateVirtualProductDatabaseData = createAction<VirtualProduct>('virtualProduct/updateVirtualProductDatabaseData')
const updateEntryAttachments = createAction('virtualProduct/updateEntryAttachments')
export const setUpdateStatus = createAction<FetchStatus>('virtualProduct/setUpdateStatus')
export const setErrorResponseStatus = createAction<string | null>('virtualProduct/setErrorResponseStatus')
export const resetVirtualProductEntries = createAction('virtualProduct/resetVirtualProductEntries')
export const updateEntryItem = createAction('virtualProduct/updateEntryItem')
export const receiveVirtualProductsFromUpplysa = createAction('virtualProducts/getVirtualProductsFromUpplysa')
export const filterVirtualProductsFromUpplysa = createAction('virtualProducts/filterVirtualProductsFromUpplysa')
export const fetchingVirtualProduct = createAction('virtualProduct/fetchingVirtualProduct')
const setManifestResponseCode = createAction('virtualProduct/setManifestResponseCode')

type Action = 'save' | 'search' | 'get virtual products' | 'connect virtual product(s)'
              | 'connect variant' | 'disconnect virtual product' | 'upload image attachment'
              | 'delete image attachment' | 'create virtual product'

const error = (dispatch: any, action: Action, e?: any) => {
  const msg = `Action: ${action} failed, we are sorry. Please try again`
  console.error(msg, e)
  dispatch(setErrorResponseStatus(msg))
}

export const getVirtualProductsByProjectId = (projectId: string): AppThunk => {
  return async (dispatch) => {
    try {
      dispatch(setFetchStatus(FetchStatus.fetching))
      const res = await fetch(`/api/virtual-products-projects/${projectId}`)
      if (res.status === 200) {
        const json = (await res.json()) as VirtualProductProjectListItem[]
        dispatch(receive(json))
        dispatch(setFetchStatus(FetchStatus.fetched))
      } else {
        throw new Error(`Failed to fetch. with status ${res.status}`)
      }
    } catch (e) {
      error(dispatch, 'get virtual products', e)
      dispatch(setFetchStatus(FetchStatus.failed))
    }
  }
}

export const connectCombination = (currentId: string, ids: string[]): AppThunk => {
  return async (dispatch) => {
    try {
      const res = await fetch('/api/virtual-products-combinations/connect', {
        method: 'PUT',
        body: JSON.stringify({
          id: currentId,
          combinationId: ids[0]
        })
      })
      if (res.status === 200) {
        const json = (await res.json()) as VirtualProductProjectListItem
        dispatch(updateEntryItem(json))
      } else {
        throw new Error(`Failed to connect combination. with status ${res.status}`)
      }
    } catch (e) {
      error(dispatch, 'connect variant', e)
    }
  }
}

export const connectVirtualProduct = (projectId: string, selected: string[]): AppThunk => {
  // Help function
  const connectOneVp = (projectId: string, selected: string) => {
    return (
      fetch('/api/virtual-products-projects/connect', {
        method: 'POST',
        body: JSON.stringify({
          projectId: projectId,
          virtualProductDatabaseId: selected
        })
      })
    )
  }
  return async (dispatch) => {
    try {
      const responses: Response[] = await Promise.all(selected.map((id:string) => connectOneVp(projectId, id)))
      for (const res of responses) {
        if (res.status === 200) {
          const json = (await res.json()) as VirtualProductProjectListItem
          dispatch(updateEntryItem(json))
        } else {
          throw new Error(`Failed to connect. with status ${res.status}`)
        }
      }
    } catch (e) {
      error(dispatch, 'connect virtual product(s)', e)
    }
  }
}

export const disconnectVirtualProduct = (projectId: string, currentId: string): AppThunk => {
  return async (dispatch) => {
    try {
      const res = await fetch('/api/virtual-products-projects/connect', {
        method: 'DELETE',
        body: JSON.stringify({
          projectId: projectId,
          id: currentId
        })
      })
      if (res.status === 200) {
        const json = (await res.json())
        dispatch(removeDisconnectedVP(json.id))
      } else {
        throw new Error(`Failed to disconnect, status: ${res.status}`)
      }
    } catch (e) {
      error(dispatch, 'disconnect virtual product', e)
    }
  }
}

export const getSearchResults = (debouncedQueryString: string, page: number, projectId: string | null) : AppThunk => {
  return async (dispatch) => {
    try {
      dispatch(setSearchStatus(FetchStatus.fetching))
      const res = await fetch('/api/virtual-products-search', {
        method: 'POST',
        body: JSON.stringify({
          query: debouncedQueryString,
          page: page,
          perPage: 40,
          projectId: projectId
        })
      })
      if (res.status === 200) {
        const json = (await res.json()) as ElasticVirtualProduct[]
        dispatch(setSearchResult(json))
        dispatch(setSearchStatus(FetchStatus.fetched))
      } else {
        throw new Error(`Failed to make search, status: ${res.status}`)
      }
    } catch (e) {
      error(dispatch, 'search', e)
      dispatch(setSearchStatus(FetchStatus.failed))
      console.error(e)
    }
  }
}

export const updateVirtualProduct = (currentId: string, body: UpdateVirtualProductPayload): AppThunk => {
  return async (dispatch) => {
    try {
      const res = await fetch(`/api/virtual-products/${currentId}`, {
        method: 'PUT',
        body: JSON.stringify(omitBy(body, isNil)),
      })
      if (res.status === 200) {
        const json = (await res.json()) as VirtualProduct
        dispatch(updateVirtualProductDatabaseData(json))
      } else {
        throw new Error(`Failed to update/save virtual product, status: ${res.status}`)
      }
    } catch (e) {
      error(dispatch, 'save', e)
    }
  }
}

export const createVirtualProduct = (projectId: string, data: UpdateVirtualProductPayload): AppThunk => {
  return async (dispatch) => {
    try {
      const res = await fetch('/api/virtual-products-projects/create', {
        method: 'POST',
        body: JSON.stringify({
          projectId: projectId,
          data
        })
      })
      if (res.status === 200) {
        const json = (await res.json())
        dispatch(updateEntryItem(json))
      } else {
        throw new Error(`Failed to create, status: ${res.status}`)
      }
    } catch (e) {
      error(dispatch, 'create virtual product', e)
    }
  }
}

export const uploadVirtualProductAttachments = (currentId: string, manifest: StorageApiManifest): AppThunk => {
  return async (dispatch) => {
    try {
      const res = await fetch('/api/virtual-products-attachments/upload', {
        method: 'POST',
        body: JSON.stringify({
          id: currentId,
          manifest,
          type: 'image'
        })
      })
      if (res.status === 200) {
        const json = (await res.json()) as VirtualProductProjectListItem
        dispatch(updateEntryAttachments(json))
        // update versionData
        dispatch(updateVirtualProductDatabaseData(json))
      } else {
        throw new Error(`Failed to upload. with status ${res.status}`)
      }
    } catch (e) {
      // if file already is uploaded we got that specific error from res.error in api
      const msg = `${e}. Please try again`
      dispatch(setErrorResponseStatus(msg))
      console.error(msg, e)
    }
  }
}

export const deleteVirtualProductAttachment = (currentId: string, dpdKey: string, vpdbKey: string): AppThunk => {
  return async (dispatch) => {
    try {
      const res = await fetch('/api/virtual-products-attachments/upload', {
        method: 'PUT',
        body: JSON.stringify({
          id: currentId,
          dpdKey: dpdKey,
          vpdbKey: vpdbKey
        })
      })
      if (res.status === 200) {
        const json = (await res.json()) as VirtualProductProjectListItem
        dispatch(updateEntryAttachments(json))
        // update versionData
        dispatch(updateVirtualProductDatabaseData(json))
      } else {
        throw new Error(`Failed to delete, status: ${res.status}`)
      }
    } catch (e) {
      error(dispatch, 'delete image attachment', e)
    }
  }
}

export const getVirtualProductFromUpplysa = (): AppThunk => {
  return async (dispatch) => {
    dispatch(fetchingVirtualProduct(true))
    try {
      const res = await fetch('/api/upplysa-manifest/get', {
        method: 'GET'
      })
      if (res.status === 200) {
        const json = (await res.json())
        dispatch(receiveVirtualProductsFromUpplysa(json))
        dispatch(filterVirtualProductsFromUpplysa(json))
        dispatch(fetchingVirtualProduct(false))
        dispatch(setManifestResponseCode(200))
      } else {
        throw new Error(`Failed to disconnect, status: ${res.status}`)
      }
    } catch (e) {
      dispatch(receiveVirtualProductsFromUpplysa([]))
      dispatch(filterVirtualProductsFromUpplysa([]))
      dispatch(setManifestResponseCode(JSON.parse(e)))
      error(dispatch, 'disconnect virtual product', e)
      dispatch(fetchingVirtualProduct(false))
    }
  }
}

export const filterVirtualProductFromUpplysa = (filter: string): AppThunk => {
  return async (dispatch, getState) => {
    const state = getState()
    const allVirtualProducts = state.virtualProducts.virtualProductsUpplysa
    if (filter === null) {
      dispatch(filterVirtualProductsFromUpplysa(allVirtualProducts))
    } else {
      const filteredList = allVirtualProducts.filter((data: any) => {
        return data.lifecycleStatus === filter
      })
      dispatch(filterVirtualProductsFromUpplysa(filteredList))
    }
  }
}

export const getVirtualProductById = (uuid: string): AppThunk<Promise<void>> => {
  return async (dispatch) => {
    try {
      const uri = '/api/combinations/upplysa/' + uuid

      dispatch(fromThreeviewerFiles.loadFile({
        id: uuid,
        name: uuid,
        type: 'Virtual Product',
        uris: [uri]
      }))

      const res = await fetch(uri, {
        method: 'GET'
      })

      dispatch(fromThreeviewerFiles.fileProgress({
        finalize: true,
        file: uri,
        progress: 100,
        type: 'Virtual Product'
      }))
      if (res.status !== 200) {
        throw new Error(`Failed to get VirtualProduct: ${res.status}`)
      }
    } catch (e) {
      const error = { message: 'Something went wrong. Contact support.' }
      dispatch(fromThreeviewerFiles.fileError({
        file: uuid as string,
        error
      }))
    }
  }
}

const initialState = Immutable<{
  fetchStatus: FetchStatus
  searchStatus: FetchStatus
  entries: { [id: string]: VirtualProductProjectListItem }
  searchResult: ElasticVirtualProduct[]
  errorResponseStatus: string | null,
  virtualProductsUpplysa: any,
  filteredVirtualProductsUpplysa: any
  fetchingVirtualProduct: boolean
  manifestResponseCode: number
}>({
  fetchStatus: FetchStatus.idle,
  searchStatus: FetchStatus.idle,
  entries: {},
  searchResult: [],
  errorResponseStatus: null,
  virtualProductsUpplysa: [],
  filteredVirtualProductsUpplysa: [],
  fetchingVirtualProduct: false,
  manifestResponseCode: 200
})

type State = typeof initialState

export default handleActions<State, any>({
  // Fetch by project id
  [receive.toString()]: (state:State, { payload }: { payload: VirtualProductProjectListItem[] }) => state.merge({
    entries: keyBy(payload ?? [], 'id')
  }, { deep: true }),
  [setFetchStatus.toString()]: (state: State, { payload }: { payload: FetchStatus}) => {
    return state.merge({ fetchStatus: payload })
  },
  // Entries
  [resetVirtualProductEntries.toString()]: (state: State) => {
    return state.set('entries', {})
  },
  [removeDisconnectedVP.toString()]: (state: State, { payload }: { payload: string}) => {
    return state.setIn(['entries'], omit(state.entries, payload))
  },
  [updateVirtualProductDatabaseData.toString()]: (state: State, { payload } : { payload: VirtualProduct}) => {
    return state.setIn(['entries', payload.id, 'virtualProductDatabaseData'], payload.virtualProductDatabaseData)
  },
  [updateEntryItem.toString()]: (state: State, { payload }: { payload: any}) => {
    return state.setIn(['entries', payload.id], payload)
  },
  [updateEntryAttachments.toString()]: (state: State, { payload }: { payload: any}) => {
    return state.setIn(['entries', payload.id, 'attachments'], payload.attachments)
  },
  // Search result
  [setSearchResult.toString()]: (state: State, { payload }: { payload: VirtualProductCombined[]}) => {
    return state.merge({ searchResult: payload })
  },
  [setSearchStatus.toString()]: (state: State, { payload }: { payload: FetchStatus}) => {
    return state.merge({ searchStatus: payload })
  },
  // Error
  [setErrorResponseStatus.toString()]: (state: State, { payload }: { payload: string}) => {
    return state.merge({ errorResponseStatus: payload })
  },
  [receiveVirtualProductsFromUpplysa.toString()]: (state: State, { payload }: { payload: any }) => {
    return state.merge({ virtualProductsUpplysa: payload })
  },
  [filterVirtualProductsFromUpplysa.toString()]: (state: State, { payload }: { payload: any }) => {
    return state.merge({ filteredVirtualProductsUpplysa: payload })
  },
  [fetchingVirtualProduct.toString()]: (state: State, { payload }: { payload: any }) => {
    return state.merge({ fetchingVirtualProduct: payload })
  },
  [setManifestResponseCode.toString()]: (state: State, { payload }: { payload: any }) => {
    return state.merge({ manifestResponseCode: payload })
  }
}, initialState)
