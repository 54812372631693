import React from 'react'

const IconRotatematerial = props => (
  <svg width='1em' height='1em' viewBox='0 0 26 25' {...props}>
    <g fill={props.color} fillRule='evenodd'>
      <path d='M21.954 4.749C19.701 1.856 16.215 0 12.302 0 5.508 0 0 5.596 0 12.5S5.508 25 12.302 25c6.367 0 11.603-4.913 12.238-11.212h-2.477c-.622 4.915-4.755 8.712-9.76 8.712-5.436 0-9.843-4.477-9.843-10s4.407-10 9.842-10c3.227 0 6.091 1.578 7.886 4.015L17.543 9.16c-.196.197-.134.381.14.414l6.91.821c.265.032.467-.162.435-.435l-.822-6.91c-.031-.266-.217-.335-.413-.14L21.954 4.75z' />
      <path d='M16.32 6.757l2.923 9.563-9.563 2.923L6.757 9.68l9.563-2.923zm1.363 8.733l-1.097-3.586L13 13l1.096 3.586 3.587-1.096zM13 13l-1.096-3.586-3.587 1.096 1.097 3.586L13 13z' />
    </g>
  </svg>
)

IconRotatematerial.defaultProps = {
  color: 'currentcolor'
}

export default IconRotatematerial
