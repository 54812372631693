import React from 'react'

const IconDesignView = props => (
  <svg width={props.size} height={props.size} viewBox="0 0 20 22">
    <path
      d="M1.7573,16.2246,1.0013,2.6162a.8259.8259,0,0,1,.7424-.8676l7.413-.7413a1.4673,1.4673,0,0,1,.2684-.0022l9.2352.77a.65.65,0,0,1,.5954.6842l-.7584,13.652a1.3,1.3,0,0,1-.667,1.0647l-6.3908,3.55a1.0942,1.0942,0,0,1-.984.04L2.34,17.077A.9972.9972,0,0,1,1.7573,16.2246Z"
      fill="none"
      stroke={props.color}
      strokeMiterlimit="10"
      strokeWidth="2"
    />
    <polyline
      points="8.957 0.994 8.957 14.994 2.104 16.969"
      fill="none"
      stroke={props.color}
      strokeMiterlimit="10"
      strokeWidth="2"
    />
    <line
      x1="18.4744"
      y1="16.5221"
      x2="8.9575"
      y2="14.9939"
      fill="none"
      stroke={props.color}
      strokeMiterlimit="10"
      strokeWidth="2"
    />
    <polygon
      points="1.957 16.994 8.957 14.994 17.957 16.994 10.824 20.864 1.957 16.994"
      fill={props.color}
    />
  </svg>

)

IconDesignView.defaultProps = {
  color: 'currentcolor',
  size: '1em'
}

export default IconDesignView
