import cs from 'classnames'
import React, { useState } from 'react'
import { MdInfoOutline as IconInfo } from 'react-icons/md'
// Constants
import {
  mapToValueLabel,
  ROOMSET_COUNTRIES
} from '../../constants'
import type { FilteredRoomset } from '../../stores/ducks/roomsets/Roomset'
import type { StorageApiFile } from '../../utils'
import Button from '../common/button'
import Grid from '../common/grid/grid'
import StorageApiImage from '../common/storage-api-image'

const ROOMSET_LABELS = mapToValueLabel(ROOMSET_COUNTRIES)

interface ItemImageProps {
  file: StorageApiFile | null
  constrainRatio?: any
}

const ItemImage = ({ file, constrainRatio }: ItemImageProps) => {
  return (
    <StorageApiImage
      constrainRatio={constrainRatio}
      type='thumbnail'
      file={file}
      className='height-100 userselect-none'
      style={{
        objectFit: 'scale-down',
        objectPosition: 'center'
      }}
    />
  )
}

interface Props {
  currentRoomsetId: string
  onLoad: (id:string) => void
  onOpenCameras: () => void
  roomset: FilteredRoomset
}

const Item = ({
  currentRoomsetId,
  onLoad,
  onOpenCameras,
  roomset
}: Props) => {
  const [showTags, setShowTags] = useState(false)

  const shouldShowCameras = (cameras: FilteredRoomset['cameras']) => (
    (cameras && cameras.length) || false
  )

  const getMarketArea = () => {
    const marketAreas = roomset.metadata?.marketArea

    return marketAreas.map(area => {
      const country = ROOMSET_LABELS.find(label => label.value === area)
      return country && country.label
    }).join('/')
  }

  const renderPreviews = () => {
    if (!roomset.cameras) return null
    const numberOfCams = roomset.cameras.length
    const maxNumOfPreviews = 2
    const camArr = roomset.cameras.slice(0, maxNumOfPreviews)

    const previews = camArr.map((camera: any, idx) => {
      const isLast = idx === camArr.length - 1 || false
      return (
        <div
          key={camera.id}
          className='bg-gray-light relative pointer opacity-hover-lighter'
          onClick={onOpenCameras}
        >
          <ItemImage file={camera.image} />
          {isLast && (
            <div
              className='absolute right-0 bottom-0 c-white flex justify-center items-center f6 bg-black-55'
              style={{ padding: '0.2rem 0.3rem' }}
            >
              +{numberOfCams}
            </div>
          )}
        </div>
      )
    })

    return previews
  }

  const getGridColumns = (cameras: FilteredRoomset['cameras']) => (
    shouldShowCameras(cameras) ? ['1fr', '5rem'] : ['1fr']
  )

  const marketArea = React.useMemo(
    () => getMarketArea(),
    [roomset]
  )

  const testid = roomset.title ? 'item_' + roomset.title.replace(/ /g, '_') : 'template-loader-parent'

  return (
    <div
      className={cs(
        'clearfix bg-white p2'
      )}
      data-testid={testid}
    >
      <div className='flex justify-between items-end width-100'>
        <h4 className='m0 truncate'>{roomset.title || 'No title'}</h4>
        <h4 className='m0'>{roomset.metadata?.area}m<sup>2</sup></h4>
      </div>
      <small
        className='f7 truncate block'
        style={{ height: '1.2rem' }}>{marketArea}
      </small>

      {showTags && (
        <div
          className='relative flex justify-center items-center flex-wrap width-100 border bc-gray-light mt2 mb1 overflow-x-hidden overflow-y-auto custom-scrollbar'
          style={{ height: '12rem' }}
        >
          <div
            className='flex justify-center items-start flex-wrap width-100 p1 my-auto'
          >
            {roomset.metadata?.tags.map((tag) => (
              <span key={tag} className='tag'>{tag}</span>
            ))}
          </div>
        </div>
      )}

      {!showTags && (
        <Grid
          columns={getGridColumns(roomset.cameras)}
          rows={['12rem']}
          gridGap={'0.5rem'}
          className='mt2 mb1'
        >
          <div className='bg-gray-light'>
            <ItemImage file={roomset.metadata?.thumbnail ?? null} />
          </div>
          {shouldShowCameras(roomset.cameras) && (
            <Grid
              rows={['5.75rem', '5.75rem']}
              gridGap={'0.5rem'}
            >
              {renderPreviews()}
            </Grid>
          )}
        </Grid>
      )}

      <div
        className={cs(
          'c-gray-dark c-gray-hover pointer ease-in-out',
          { disabled: roomset.metadata.tags.length === 0 }
        )}
        onClick={() => setShowTags(!showTags)}
      >
        <IconInfo
          size={'1.2rem'}
        />
        <small className='ml1'>
          {showTags ? 'Hide' : 'Show'} tags
        </small>
      </div>

      <Button
        className='block mt2 ml1 right'
        btnType='primary'
        data-testid='load-roomset'
        onClick={() => {
          if (!roomset.id) return
          onLoad(roomset.id)
        }
        }
        disabled={currentRoomsetId === roomset.id}
      >
        {currentRoomsetId === roomset.id ? 'Current Home' : 'Load Home'}
      </Button>

      {shouldShowCameras(roomset.cameras) && (
        <Button
          className='block mt2 right'
          onClick={onOpenCameras}
          disabled={currentRoomsetId === roomset.id}
        >
            View All Cameras
        </Button>
      )}
    </div>
  )
}
export default React.memo(Item)
