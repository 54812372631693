import React, { useEffect } from 'react'
import cs from 'classnames'

import IconMaterialGroup from '../../../common/icons/icon-material-group'
import IconArrowRight from '../../../common/icons/icon-arrow-right'
import IconArrowDown from '../../../common/icons/icon-arrow-down'
import IconCube from '../../../common/icons/icon-cube'

import EmptyState from '../../../common/empty-state/empty-state'
import DesignLoader from '../../design-loader'
import { ModalButton } from '../../../common/modal'
import Button from '../../../common/button'
import NumericPin from '../../../common/Numeric-pin'

import {
  PanelSection,
  PanelSectionHeader
} from '../../../common/panels/panel-section'

import appearanceGroupsConnect from './appearance-groups-connect'
import { ConnectedProps } from 'react-redux'
import { AppearanceGroupRow } from './components/appearance-group-row'
import { AppearanceGroupClassLabel } from './components/appearance-group-class-label'
import { AppearanceGroupCollection } from './components/appearance-group-collection'
import { appearanceGroupStyles } from './appearance-group-styles'

const EmptyStateData = {
  icon: <IconMaterialGroup size={80} />,
  title: 'Appearances define the look of your models',
  desc: 'Once you apply appearances to a design or model, all used appearances are displayed here in a hierarchy, so that you easily can select groups of parts with the same appearance.',
  docLink: 'appearances-colors-patterns.md',
  linkText: 'Learn more',
  actionTitle: 'Load design'
}

type PropsFromRedux = ConnectedProps<typeof appearanceGroupsConnect>
type Props = PropsFromRedux & {}

function AppearanceGroupsPanel (props: Props) {
  const [isDragging, setIsDragging] = React.useState(false)

  React.useEffect(() => {
    return () => {
      if (props.annotationsActive) {
        props.toggleAnnotations(props.annotationsActive)
      }
    }
  }, [props.annotationsActive])
  useEffect(() => {
    props.fetchColors()
  }, [])

  function handleDragStart () {
    setIsDragging(true)
  }

  function handleDragEnd () {
    setIsDragging(false)
  }

  const totalCost = props.appearanceGroups.reduce((acc: number, materialGroup) => {
    acc = acc + materialGroup.totalCost
    return acc
  }, 0)

  return (
    <PanelSection className='overflow-auto'>
      <PanelSectionHeader title='Appearances in scene' />
      {props.appearanceGroups.length === 0 && !props.notSetPartsGroup && (
        <ModalButton
          modalContent={(closeModal: any) => (
            <DesignLoader
              onClose={closeModal}
              type='load'
            />
          )}
          button={(openModal: any) => (
            <EmptyState
              className='mt4'
              icon={EmptyStateData.icon}
              title={EmptyStateData.title}
              desc={EmptyStateData.desc}
              docLink={EmptyStateData.docLink}
              linkText={EmptyStateData.linkText}
              onAction={openModal}
              actionTitle={EmptyStateData.actionTitle}
            />
          )}
        />
      )}
      {(!props.isNoMaterialsAssigned || props.notSetPartsGroup || props.appearanceGroups.length > 0) && <Button
        pad={1}
        style={{ margin: '1rem' }}
        disabled={props.appearanceGroups.length === 0}
        onClick={() => props.toggleAnnotations(props.annotationsActive)}
      >
        {props.annotationsActive ? 'Hide annotations' : 'Show annotations'}
      </Button>}
      {props.appearanceGroups.map((appearanceGroup) => (
        <AppearanceGroupCollection
          key={appearanceGroup.id}
          appearanceGroup={appearanceGroup}
          disableKeyboardListeners={props.disableKeyboardListeners}
          enableKeyboardListeners={props.enableKeyboardListeners}
          pickerSelection={props.pickerSelection}
          selectFromUuids={props.selectFromUuids}
          isAppearanceGroupOpen={props.isAppearanceGroupOpen.includes(appearanceGroup.id)}
          isPartsOpen={props.isPartsGroupOpen.includes(appearanceGroup.id)}
          isCostAndAnnotationsOpen={props.isCostAndAnnotationsOpen.includes(appearanceGroup.id)}
          toggleIsAppearanceGroupOpen={props.toggleIsMaterialGroupOpen}
          toggleIsPartsOpen={props.toggleIsPartsGroupOpen}
          toggleIsCostAndAnnotationsOpen={props.toggleIsCostAndAnnotationsOpen}
          updateCostEstimation={props.updateCostEstimation}
          updateAnnotation={props.updateAnnotation}
          setMaterial={props.setMaterial}
          setColor={props.setColor}
          setPattern={props.setPattern}
          isDragging={isDragging}
          handleDragStart={handleDragStart}
          handleDragEnd={handleDragEnd}
          getSelectionMode={props.getSelectionMode}
        />
      ))}

      {props.notSetPartsGroup && (
        <>
          <AppearanceGroupRow
            style={appearanceGroupStyles.row}
            onClick={props.toggleIsNoAppearanceSetOpen}
            className={`${props.notSetPartsGroup?.parts.some(part => part.isSelected) ? 'bg-primary-lighter' : 'bg-gray-light'}`}
          >
            <div style={{ display: 'flex', alignItems: 'center' }} className={cs('f7')}>
              <div>
                <div style={appearanceGroupStyles.arrowContainer}>
                  {props.isNoAppearanceSetOpen
                    ? <IconArrowDown size={11} />
                    : <IconArrowRight size={11} />
                  }
                </div>
              </div>
              <div style={appearanceGroupStyles.materialType}>
                <div style={appearanceGroupStyles.noMateriaTypebox}>
                  <div style={appearanceGroupStyles.crossIconLeft}></div>
                  <div style={appearanceGroupStyles.crossIconRight}></div>
                </div>
                <p style={{ margin: 0 }}>No appearance set</p>
              </div>
            </div>
            <div className='flex'>
              <div style={{ width: 28, display: 'flex', alignItems: 'center' }}>
                <NumericPin number={props.notSetPartsGroup.parts.length} title={props.notSetPartsGroup.parts.length + ' parts in no appearance set'} />
              </div>
              <div style={{ width: 76, display: 'flex', justifyContent: 'flex-start' }}>
                <AppearanceGroupClassLabel materialClass={'Not set'} />
              </div>
            </div>
          </AppearanceGroupRow>
          {props.isNoAppearanceSetOpen && props.notSetPartsGroup?.parts.map(part => {
            return (
              <AppearanceGroupRow
                draggable
                onDragStart={() => {
                  setIsDragging(true)
                  if (props.pickerSelection.length < 2) {
                    props.selectFromUuids([part.id])
                  }
                }}
                onDragEnd={() => setIsDragging(false)}
                onClick={e => {
                  const isMultiSelect = (e.ctrlKey || e.shiftKey || e.metaKey)
                  if (!part.isSelected) {
                    props.selectFromUuids([part.id], isMultiSelect)
                  } else {
                    if (props.pickerSelection.length > 1 && !isMultiSelect) {
                      props.selectFromUuids([part.id])
                    } else {
                      props.selectFromUuids(props.pickerSelection.filter(selection => selection !== part.id))
                    }
                  }
                }}
                key={part.id}
                style={{ borderBottom: 0 }}
                className={cs('f7', { 'bg-primary-light': part.isSelected })}
              >
                <div>
                  <IconCube size={12} style={appearanceGroupStyles.iconCube} />
                </div>
                <span title={part.name} className='truncate' style={{ paddingBottom: 3, fontSize: 11, userSelect: 'none' }}>{part.name}</span>
              </AppearanceGroupRow>
            )
          })}
        </>
      )}
      {props.appearanceGroups.length > 0 &&
        <AppearanceGroupRow style={appearanceGroupStyles.costTotalStyle}>
          <h6>Total Cost Estimation: {totalCost.toFixed(2)}</h6>
        </AppearanceGroupRow>
      }
    </PanelSection>
  )
}

export default appearanceGroupsConnect(AppearanceGroupsPanel)
