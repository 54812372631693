import React from 'react'
import _map from 'lodash/map'

const styles = {
  tagsPreview: {
    padding: '0.3rem 0.5rem',
    margin: '0.1rem'
  }
}

export const TagsPreview = ({
  tags,
  tagColor = 'bg-gray-light',
  textColor = 'c-gray-dark'
}) => {
  return (
    <div className='flex flex-wrap'>
      {_map(tags, (tag, index) => {
        return (
          <div
            key={index}
            className={`border-none rounded f8 ${textColor} ${tagColor}`}
            style={styles.tagsPreview}
          >
            {tag}
          </div>
        )
      })}
    </div>
  )
}
