import _isEmpty from 'lodash/isEmpty'
import React, { FormEvent, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import colors from '../../../css/colors'
import { RootState } from '../../stores/ducks'
import Button from '../common/button'
import Fieldset from '../common/form/fieldset'
import LoginFieldset from './login-fieldset'

const Notification = ({ message, type = 'primary' } : {message: string, type?: 'primary' | 'error'}) => (
  <div
    style={{ width: 320 }}
    className={`br-2 mx-auto border mtn1 mb1 py1 px1 f6 bc-${type}`}
  >
    {message}
  </div>
)

export const FieldWrapper = styled(Fieldset)`
  padding: 0 15px 5px 15px;
  margin: 0 auto !important;
  max-width: 350px;
`

const Background = styled.div`
  background-image: url('/img/background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  
  input {
    color: var(--todo_A9A9A9);
    background: var(--white);
    width: 100%;
    margin: 5px 0;
  }
`

const Container = styled.div`
  transform: translateY(-10%);
  max-width: 500px;
  width: 100%;
  background-color: var(--todo_f5f5f5);
  box-shadow: 0 0 40px 0 rgba(0,0,0,0.18);
  border-radius: 5px;
  margin: 0;
  text-align: center;
`

const Header = styled.div`
  padding: 10px 10px 0 10px;
  max-width: 350px;
  margin: 0px auto;
`

const Title = styled.div`
  padding: 20px 0;
  text-align: center;
  width: 100%;
  color: var(--grayDark);
  font-size: 20px;
  font-weight: bold;
`

export const StyledLinkContainer = styled.div<{justify?: 'center' | 'flex-start'}>`
  display: flex;
  justify-content: ${(props) => props.justify || 'flex-end'};
 
  a {
    padding: 10px 15px;
    font-size: 12px;
    font-family: 'Verdana';
    color: var(--grayDark);
    cursor: pointer;
  }

  a:hover {
    color: var(--primary)
  }
`

export const inputTextColor = colors.todo_333
export type ActionType = 'ssologin' | 'login'

const Login = () => {
  const formRef = useRef(null)
  const [actionType] = useState<ActionType>('ssologin')
  const { notification, error } = useSelector((state: RootState) => state.users)

  const renderNotification = () => {
    if (!_isEmpty(notification)) {
      return <Notification message={notification} />
    }

    if (!_isEmpty(error)) {
      return <Notification message={error} type='error' />
    }
    return null
  }

  const submit = (event: FormEvent) => {
    event.preventDefault()
  }

  const getFieldSet = (type: ActionType) => {
    return (
      <form ref={formRef} onSubmit={submit}>
        {renderNotification()}
        {type === 'ssologin' && (
          <div>
            <div className={'flex justify-center mb3'}>
              <Button
                btnType='primary'
                onClick={performADLogin}
              >
                Log in
              </Button>
            </div>
          </div>
        )}
        {type === 'login' && <LoginFieldset/>}
      </form>
    )
  }

  const performADLogin = () => {
    window.location.href =
      '/api/users/ad' + '?' + (window.location.href.split('?')[1] || 'ref=/')
  }

  return (
    <Background>
      <Container>
        <Header>
          <Title>Welcome to DPD</Title>
        </Header>
        {getFieldSet(actionType)}
      </Container>
    </Background>
  )
}

export default Login
