var THREE
var HTCViveController = require('./HTCViveController')

function HTCVive (app) {
  this.app = app
  THREE = app.THREE
  this._update = {}
}

HTCVive.prototype.isVRAvailable = () => { return navigator.getVRDisplays !== undefined }

HTCVive.prototype.initVR = function (controllerRoot) {
  this.controllerRoot = controllerRoot || ''
  var app = this.app
  this.VRControls = new THREE.VRControls(app.camera)
  this.VRControls.standing = true
  this.VREffect = new THREE.VREffect(app.renderer)
  this._pickerObjects = []
}

HTCVive.prototype.startVR = function () {
  return this.VREffect.requestPresent()
}

HTCVive.prototype.exitVR = function () {
  return this.VREffect.exitPresent()
}

HTCVive.prototype.render = function (next) {
  var app = this.app
  if (this._update) {
    Object.keys(this._update).forEach((key) => {
      this._update[key]()
    })
  }

  if (this.VRControls) {
    this.VRControls.update()
  }

  if (this.firstController) {
    this.firstController.update()
  }

  if (this.secondController) {
    this.secondController.update()
  }

  this.VREffect.render(app.scene, app.camera)
  this.VREffect.requestAnimationFrame(next)
}

HTCVive.prototype.initFirstController = function (params) {
  var cameraParent = this.app.cameraParent

  params = params || {}
  this.firstControllerWrapper = new HTCViveController(0, 0xff0000, this.controllerRoot, this.app)
  this.firstController = this.firstControllerWrapper.controller
  this.addControllerHandlers(this.firstController, params)
  cameraParent.add(this.firstController)
}

HTCVive.prototype.initSecondController = function (params) {
  var cameraParent = this.app.cameraParent

  params = params || {}
  this.secondControllerWrapper = new HTCViveController(1, 0x00ff00, this.controllerRoot, this.app)
  this.secondController = this.secondControllerWrapper.controller
  this.addControllerHandlers(this.secondController, params)
  cameraParent.add(this.secondController)
}

HTCVive.prototype.addControllerHandlers = function (controller, params) {
  if (params.materialEditor) {
    controller.addMaterialEditorHandler(params.materialEditor)
  }

  if (params.polaroid) {
    controller.addPolaroidHandler(params.polaroid)
  }

  if (params.locomotion) {
    controller.addLocomotionHandler(params.locomotion)
  }

  if (params.specialSnapping) {
    controller.addSpecialSnappingHandler(params.specialSnapping)
  }
}

HTCVive.prototype.attachToController = function (obj, controller) {
  this.app.scene.updateMatrixWorld(true)
  obj.updateMatrixWorld(true)

  THREE.SceneUtils.detach(obj, obj.parent, this.app.scene)
  THREE.SceneUtils.attach(obj, this.app.scene, controller)

  this.app.scene.updateMatrixWorld(true)
  obj.updateMatrixWorld(true)
}

HTCVive.prototype.detachFromController = function (obj, controller) {
  this.app.scene.updateMatrixWorld(true)
  obj.updateMatrixWorld(true)

  THREE.SceneUtils.detach(obj, obj.parent, this.app.scene)

  this.app.scene.updateMatrixWorld(true)
  obj.updateMatrixWorld(true)
}

HTCVive.prototype.controllerEvents = {
  PadTouched: 'PadTouched',
  PadUntouched: 'PadUntouched',
  PadPressed: 'PadPressed',
  PadUnpressed: 'PadUnpressed',
  TriggerClicked: 'TriggerClicked',
  TriggerUnclicked: 'TriggerUnclicked',
  MenuClicked: 'MenuClicked',
  MenuUnclicked: 'MenuUnclicked',
  Gripped: 'Gripped',
  Ungripped: 'Ungripped',
  PadDragged: 'PadDragged',
  MenuPressed: 'MenuPressed',
  MenuUnpressed: 'MenuUnpressed',
  Connected: 'Connected',
  Disconnected: 'Disconnected'
}

export default HTCVive
