import React from 'react'
import { FaAngleDown, FaAngleUp } from 'react-icons/fa'
import styled from 'styled-components'

type Overflow = 'auto' | 'y' | 'x'
type Props = {
  overflow: Overflow
  outerClassName?: string,
  innerClassName?: string,
  children: React.ReactNode
}

const scrollAreaSize = 30
const scrollSpeed = 30

function getOverflowStyle (overflow: Overflow): React.CSSProperties {
  if (overflow === 'y') return { overflowY: 'auto' }
  if (overflow === 'x') return { overflowX: 'auto' }
  return { overflow: 'auto' }
}

export const ScrollArea = styled.div<{size: number}>`
  display: flex;
  align-items: center;
  border: 2px dashed var(--black25);
  visibility: hidden;
  justify-content: center;
  position: absolute;
  height: ${({ size }) => size}px;
  background: rgba(255, 255, 255, 0.8);
  z-index: 2;
`

/**
 * Component which overrides HTML5s scroll trigger areas for DND.
 * This is done to increase the trigger area for scrolling since it is quite small in HTML5
 *
 * TODO This component is a copy of Scrollable component, it should just use that component instead. This requires scrollable to use forwardRef
 */
export function DndScrollable (props: Props) {
  const overflowStyleRef = React.useRef(getOverflowStyle(props.overflow))
  const scrollContainerRef = React.useRef<HTMLDivElement>(null)
  const scrollableContainerRef = React.useRef<HTMLDivElement>(null)
  const topScrollAreaRef = React.useRef<HTMLDivElement>(null)
  const bottomScrollAreaRef = React.useRef<HTMLDivElement>(null)

  const [isDragging, setIsDragging] = React.useState(false)

  /**
   * When scrolling, based on position determine if a scroll area should be shown
   * If scroll is at top the topScrollArea should be hidden to be able to drop on the top element(s) in the list
   * Vice versa for the bottom one.
   */
  React.useEffect(() => {
    function scrolling () {
      showScrollAreas(isDragging)
    }

    if (scrollableContainerRef.current) {
      scrollableContainerRef.current.addEventListener('scroll', scrolling)
    }

    return () => {
      if (scrollableContainerRef.current) {
        scrollableContainerRef.current.removeEventListener('scroll', scrolling)
      }
    }
  })
  /**
   * Listen to drag events to determine if an element is being dragged and when the dragging stops
   * Drag start/end will change the visibility of scrollAreas
   */
  React.useEffect(() => {
    function handleDragEnd () {
      setIsDragging(false)
      showScrollAreas(false)
    }
    function handleDrag () {
      setIsDragging(true)
      showScrollAreas(true)
    }
    if (scrollableContainerRef.current) {
      scrollableContainerRef.current.addEventListener('drag', handleDrag)
      scrollableContainerRef.current.addEventListener('dragend', handleDragEnd)
      // drop does not trigger a dragend event
      scrollableContainerRef.current.addEventListener('drop', handleDragEnd)
    }
    return () => {
      if (scrollableContainerRef.current) {
        scrollableContainerRef.current.removeEventListener('drag', handleDrag)
        scrollableContainerRef.current.removeEventListener('dragend', handleDragEnd)
        scrollableContainerRef.current.removeEventListener('drop', handleDragEnd)
      }
    }
  })

  /**
   * Sets the visibility of scroll areas. This is based on the position of the scroll and if an element is being dragged.
   * If scroll is at the top position the topScrollArea is not shown and vice versa for the bottom one
   *
   * Visibility is changed directly on an elements style instead of using a state due to the fact that the state change seems to
   * introduce a bit of lag when scrolling
   */
  function showScrollAreas (dragging: boolean) {
    if (scrollableContainerRef.current) {
      if (topScrollAreaRef.current) {
        topScrollAreaRef.current.style.visibility = scrollableContainerRef.current.scrollTop !== 0 && dragging ? 'visible' : 'hidden'
      }
      if (bottomScrollAreaRef.current) {
        bottomScrollAreaRef.current.style.visibility = ((scrollableContainerRef.current.scrollHeight - scrollableContainerRef.current.scrollTop) !== scrollableContainerRef.current.clientHeight) && dragging ? 'visible' : 'hidden'
      }
    }
  }

  return (
    <div ref={scrollContainerRef} className={`${props.outerClassName} relative height-100 overflow-hidden`}>
      <ScrollArea
        ref={topScrollAreaRef}
        size={scrollAreaSize}
        style={{
          top: 0,
          left: 0,
          width: (scrollContainerRef.current && scrollContainerRef.current.getBoundingClientRect().width) || 0,
        }}
        onDragOver={() => {
          if (scrollableContainerRef.current) {
            scrollableContainerRef.current.scrollBy({
              top: -scrollSpeed,
              behavior: 'smooth'
            })
          }
        }}
      >
        <FaAngleUp
          size={18}
          className='opacity-hover-lighter-performant-alt'
        />
      </ScrollArea>
      <div
        ref={scrollableContainerRef}
        style={overflowStyleRef.current}
        className={`${props.innerClassName} absolute top-0 bottom-0 width-100`}
      >
        {props.children}
      </div>
      <ScrollArea
        ref={bottomScrollAreaRef}
        size={scrollAreaSize}
        style={{
          bottom: 0,
          left: 0,
          width: (scrollContainerRef.current && scrollContainerRef.current.getBoundingClientRect().width) || 0,
        }}
        onDragOver={() => {
          if (scrollableContainerRef.current) {
            scrollableContainerRef.current.scrollBy({
              top: scrollSpeed,
              behavior: 'smooth'
            })
          }
        }}
      >
        <FaAngleDown
          size={18}
          className='opacity-hover-lighter-performant-alt'
        />
      </ScrollArea>
    </div>
  )
}
