import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../stores/ducks'
import * as fromThreeviewerSelectors from '../../../stores/ducks/threeviewer/selectors'
import { getSelection } from '../../../stores/ducks/selection/selectors'
import { FormSingleInputField, FormThreeInputFields } from './subtoolbar-inputField'

export default function TransformSettings () {
  const MILLIMETER_TO_METER : number = 0.001

  const gizmo = useSelector((state: RootState) => fromThreeviewerSelectors.getTransformGizmo(state))
  const { x, y, z } = useSelector((state: RootState) => fromThreeviewerSelectors.getToolbarPositionFields(state))
  const selection = useSelector((state: RootState) => getSelection(state)) as [string]
  const nodelist = useSelector((state: RootState) => fromThreeviewerSelectors.getNodeList(state))

  const onlyWallsSelected = selection.every(id => (nodelist[id].userData.isTemplate || nodelist[id].userData.kvadratMeshId))

  const noSelection = selection.length < 1 || onlyWallsSelected // If selection is empty or contains only walls, the input fields should be read only

  return (
    <div className='flex justify-between f8 c-gray'>

      <div style={{ paddingRight: '50px' }}>
        <div style={{ textAlign: 'center' }}>Offset position (mm)</div>
        <div className='flex items-center'>
          <div className='flex items-center c-gray f8'>

            <FormSingleInputField
              readOnly = {noSelection}
              borderColor = '#ff0000'
              fieldName = 'x'
              value = {'0'}
              onSubmitCallback = { (value: number) => {
                if (!isNaN(value)) {
                  gizmo.offsetAxisPositionOnSelected('x', value * MILLIMETER_TO_METER)
                }
              }}
            />

            <FormSingleInputField
              readOnly = {noSelection}
              borderColor = '#00ff00'
              fieldName = 'y'
              value = {'0'}
              onSubmitCallback = { (value: number) => {
                if (!isNaN(value)) {
                  gizmo.offsetAxisPositionOnSelected('y', value * MILLIMETER_TO_METER)
                }
              }}
            />

            <FormSingleInputField
              readOnly = {noSelection}
              borderColor = '#0000ff'
              fieldName = 'z'
              value = {'0'}
              onSubmitCallback = { (value: number) => {
                if (!isNaN(value)) {
                  gizmo.offsetAxisPositionOnSelected('z', value * MILLIMETER_TO_METER)
                }
              }}
            />
          </div>
        </div>
      </div>

      <div style={{ paddingLeft: '50px' }}>
        <div style={{ textAlign: 'center' }}>Set position (m)</div>
        <div className='flex items-center'>
          <div className='flex items-center c-gray f8'>
            <FormThreeInputFields
              propX = {isNaN(x) || noSelection ? '-' : x.toFixed(3)}
              propY = {isNaN(y) || noSelection ? '-' : y.toFixed(3)}
              propZ = {isNaN(z) || noSelection ? '-' : z.toFixed(3)}
              readOnly = {noSelection}
              onSubmitCallback = { (xArg: number, yArg: number, zArg: number) => {
                gizmo.setPositionOnSelected(xArg, yArg, zArg)
              }
              }/>
          </div>
        </div>
      </div>

    </div>
  )
}
