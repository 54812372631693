var _ = require('lodash')
var THREE
let util

module.exports = {
  addMaterialEditorHandler (params = {}) {
    THREE = this.app.THREE
    util = this.app.viewerUtils
    const MODE = 'materialEditor'
    const materials = params.materials

    const total = materials.length + 1
    const step = THREE.Math.degToRad(360) / total
    const countdown = 5
    let counter = 0
    let direction = 1
    let isActive = false

    let currIndex = 0
    let lastIndex = 0
    let target = null
    let tick = 0

    const size = 0.02
    const radius = 0.2
    const scaleAdd = 0.6

    const placeholderTargetMaterial = new THREE.MeshStandardMaterial({
      color: 0xffffff,
      opacity: 0.4,
      transparent: true
    })

    const parent = new THREE.Object3D()
    const swatchGeometry = new THREE.SphereGeometry(size, 24, 24)

    const scaleAll = (object, scale) => object.scale.set(scale, scale, scale)

    const getClonedMaterial = (material) => {
      const materialClone = material.clone()

      const materialData = {
        materialId: material.materialId,
        materialName: material.materialName,
        name: material.name
      }

      if (material.colorId) {
        materialData.colorId = material.colorId
        materialData.colorName = material.colorName
      }

      return Object.assign(materialClone, materialData)
    }

    const swatches = materials.concat(placeholderTargetMaterial)
      .map((material) => new THREE.Mesh(swatchGeometry, getClonedMaterial(material)))

    swatches.forEach((swatch, index) => {
      swatch.translateZ(radius * Math.cos(index * step))
      swatch.translateX(radius * Math.sin(index * step))

      index === 0 && scaleAll(swatch, 1 + scaleAdd)
      parent.add(swatch)
    })

    parent.rotateY(THREE.Math.degToRad(180))
    parent.translateZ(-0.04)
    parent.visible = false
    this.add(parent)

    const applyMaterial = (node, material) => {
      const materialClone = getClonedMaterial(material)

      const userData = {
        materialId: material.materialId,
        materialName: material.materialName,
        name: material.name
      }

      if (material.colorId) {
        userData.colorId = material.colorId
        userData.colorName = material.colorName
      }

      if (materialClone.type === 'TriplanarMaterial') {
        if (_.get(node, 'userData.mapRotation')) {
          materialClone.setMapRotationXYZ.apply(material, node.userData.mapRotation)
        } else {
          materialClone.setDefaultMapRotationFromBoundingBox(node.geometry.boundingBox)

          userData.mapRotation = [
            materialClone.mapRotationX,
            materialClone.mapRotationY,
            materialClone.mapRotationZ
          ]
        }
      }
      node.userData = Object.assign(
        node.userData || {},
        userData,
        { changed: true }
      )
      const rootNode = util.findRootNode(node)
      rootNode.userData = Object.assign(rootNode.userData || {}, node.userData)

      node.material = materialClone
    }

    const update = () => {
      const perStep = (step * (direction * -1)) / countdown
      const currSwatch = swatches[currIndex]

      if (--counter >= 0) {
        var d = 1 - (counter * (1 / countdown))
        var smooth = THREE.Math.smoothstep(d, 0, 1)

        scaleAll(currSwatch, 1 + (scaleAdd * smooth))
        scaleAll(swatches[lastIndex], 1 + scaleAdd - (scaleAdd * smooth))

        parent.rotateY(perStep)
      } else {
        counter = 0
      }

      scaleAll(currSwatch, currSwatch.scale.x + (Math.sin(++tick * 0.08) * 0.003))
    }

    this.on(this.PadPressed, () => {
      if (this.mode && this.mode !== MODE) return

      if (isActive) {
        // Deactivate
        isActive = false
        target = null
        parent.visible = false
        tick = 0
        swatches[total - 1].material = placeholderTargetMaterial
        this.mode = null
        delete this._update.materialEditor
      } else {
        // Activate
        isActive = true
        parent.visible = true
        this.mode = MODE
        this._update.materialEditor = update
      }
    })

    this.on(this.TriggerClicked, () => {
      if (!isActive || this.mode !== MODE) return

      const materialTargets = this.app.objectTracker
        .returnObjectsOfType('canSetMaterial')
        .filter((object) => object.visible)

      const raycaster = this.getRaycaster(this)
      const materialTargetsIntersections = raycaster.intersectObjects(materialTargets)
      const hitTarget = _.get(materialTargetsIntersections, '0.object')

      if (hitTarget) {
        // Is new target?
        if (_.get(hitTarget, 'uuid') !== _.get(target, 'uuid')) {
          swatches[total - 1].material = hitTarget.material.clone()
        }

        target = hitTarget
        applyMaterial(target, swatches[currIndex].material)
      }
    })

    this.detectDrag({
      dragHorizontal: (swipeDirection) => {
        if (counter === 0 && isActive) {
          direction = swipeDirection
          counter = countdown
          lastIndex = currIndex

          if (direction === 1) {
            currIndex = currIndex >= total - 1 ? 0 : currIndex + 1
          } else {
            currIndex = currIndex <= 0 ? total - 1 : currIndex - 1
          }
        }
      }
    })
  },

  detectDrag (handlers) {
    var sumVec = new THREE.Vector2()

    this.on(this.PadDragged, (dx, dy) => sumVec.add(new THREE.Vector2(dx, dy)))
    this.on(this.PadTouched, () => (sumVec = new THREE.Vector2()))

    this.on(this.PadUntouched, () => {
      if (sumVec.length() < 1) {
        sumVec = new THREE.Vector2()
        return
      }

      if (Math.abs(sumVec.x) > Math.abs(sumVec.y)) {
        handlers.dragHorizontal(sumVec.x > 0 ? 1 : -1)
      }
      sumVec = new THREE.Vector2()
    })
  }
}
