import React from 'react'
import Tab from './tab'

type Props = React.HTMLAttributes<HTMLDivElement> & {
  active: string | number
  onToggleActive: (id: string | number) => void
  onToggleDefault: () => void
  tabs: {
    id: string | number,
    label: string,
    disabled?: boolean
  }[]
}

function TabGroup (props: Props) {
  if (!props.tabs.length) return null
  return (
    <div
      className={`flex center bc-secondary bg-white border-left border-top border-bottom ${props.tabs.length === 1 && 'width-50'}`}
      data-testid='common-tabs'
    >
      {props.tabs.map((tab) => (
        <Tab
          id={tab.id}
          key={tab.id}
          label={tab.label}
          active={props.active === tab.id}
          disabled={tab.disabled}
          onToggleActive={props.onToggleActive}
          onToggleDefault={props.onToggleDefault}
        />
      ))}
    </div>
  )
}

TabGroup.defaultProps = {
  style: {},
  tabs: [],
  onToggleDefault () {},
}

export default TabGroup
