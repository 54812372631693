import React from 'react'
import styled from 'styled-components'

import Spinner from '../../../../common/spinner'

const CenteredDiv = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`

export default function CenteredLoader () {
  return (
    <CenteredDiv>
      <Spinner spinnerstyle={{ fontSize: '7rem' }}/>
      <div className='pt2'>Loading concept analysis data</div>
    </CenteredDiv>
  )
}
