import React, { useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { createSelector } from 'reselect'
import cs from 'classnames'

import _keyBy from 'lodash/keyBy'
import _map from 'lodash/map'

// JSX Components : Common
import Button from '../../../common/button'
import Label from '../../../common/form/label'
import Dropdown from '../../../common/form/input-dropdown'
import Checkbox from '../../../common/form/input-checkbox'
import InputGroup from '../../../common/form/input-group'
import InputText from '../../../common/form/input-text'
import KeyboardListener, { KeyCode, KeyBinding } from '../../../common/keyboard-listener'
import NotificationBubble from '../../../common/notification-bubble'
import Scrollable from '../../../common/scrollable'
import Grid from '../../../common/grid/grid'
import AutoFill from '../../../common/grid/AutoFill'
import { StorageApiThumbnail } from '../../../common/storage-api-image'

import * as fromCombinations from '../../../../stores/ducks/combinations'
import * as fromProjects from '../../../../stores/ducks/projects'
import * as fromThreeViewerUi from '../../../../stores/ducks/threeviewer/ui'

import * as fromRoomsetSelectors from '../../../../stores/ducks/roomsets/selectors'
import { LOCKED_MODES } from '../../../../stores/ducks/threeviewer/camera'
import { RenderPresets } from '../../../../stores/ducks/renders/Render'
import { getCombinationsWithRender } from '../../../../stores/ducks/projects/selectors'
import { AddGeometryCombination } from '../image-templates/AddGeometryModal'

import {
  RENDER_PRESETS,
  ROOMSET_RENDER_PRESETS,
  TEMPLATE_IMAGE_RENDER_PRESETS
} from '../../../../constants'
import { RootState } from '../../../../stores/ducks/index'

import * as fromThreeviewerSelectors from '../../../../stores/ducks/threeviewer/selectors'
// @ts-ignore
import Client from '@inter-ikea-digital/iig-rpd-dpd-packages-storage-api-client'
import fetch from '../../../../utils/fetch'

const getPresets = createSelector(
  fromRoomsetSelectors.getIsRoomsetActive,
  fromRoomsetSelectors.getCurrentPresets,
  fromThreeviewerSelectors.getCameraMode,
  (isRoomsetActive, currentRoomsetPresets, cameraMode): RenderPresets => {
    if (cameraMode === LOCKED_MODES.OFFSET_VIEW_CAMERA) {
      return TEMPLATE_IMAGE_RENDER_PRESETS
    }
    if (isRoomsetActive) {
      return currentRoomsetPresets || ROOMSET_RENDER_PRESETS
    }
    return RENDER_PRESETS
  }
)

const getRenderSetups = (state: RootState) => {
  const activeCamera = state.threeviewer.camera.activeCamera

  if (activeCamera.imageTemplate) {
    const setups = Object
      .values(state.roomsets.entries)
      .filter(roomset => roomset.parentId === activeCamera.imageTemplate?.id)

    return setups
  }
  return []
}

const mapStateToProps = createSelector(
  fromRoomsetSelectors.getIsRoomsetActive,
  getPresets,
  (state: RootState) => state.threeviewer.ui.render,
  (state: RootState) => state.threeviewer.camera.mode,
  (state: RootState) => state.projects.notificationMessage,
  getRenderSetups,
  (state: RootState) => !!state.threeviewer.camera.activeCamera.imageTemplate,
  getCombinationsWithRender,
  (state: RootState) => state.combinations.connectedBatchGeometryCombinationIds,
  fromThreeviewerSelectors.getViewer,
  (state: RootState) => state.threeviewer.imageTemplates.designRenderEnabled,

  (
    isRoomsetActive,
    presets,
    renderSettings,
    cameraMode,
    notificationMessage,
    renderSetups,
    isImageTemplateRender,
    combinations,
    connectedIds,
    viewer,
    designRenderEnabled
  ) => {
    let title = 'Create image'
    let description = null

    const isBatchRender = connectedIds.length > 0

    if (isImageTemplateRender) {
      if (isBatchRender) {
        title = 'Create image package'
        description = 'An image package with the following geometries will be rendered. Find them in the Images panel in the project view.'
      } else if (designRenderEnabled) {
        title = 'Create Design Registration'
        description = 'A design-registration package will be rendered. Find them in the Images panel in the project view'
      } else if (renderSetups.length > 0) {
        title = 'Multiple images will be created'
        description = `This image template has ${renderSetups.length + 1} light setups, so ${renderSetups.length + 1} images will be created. You can see all of them by clicking the image in the Images panel to the left, and choose which light setup you want to use.`
      }
    }

    const combinationsWithRenders = _keyBy(combinations, 'id')
    const imageTemplateCombinations = connectedIds.map((id) => {
      return combinationsWithRenders[id]
    }).filter(Boolean) as unknown as AddGeometryCombination[]

    return {
      isImageTemplateRender,
      renderSetups,
      notificationMessage,
      renderSettings,
      hideEnvironmentOption: cameraMode === LOCKED_MODES.OFFSET_VIEW_CAMERA || isRoomsetActive,
      presets: presets,
      presetOptions: _map(presets, (preset, id) => ({
        key: id, label: preset.title, value: preset.id
      })),
      title,
      description,
      isBatchRender,
      imageTemplateCombinations: imageTemplateCombinations,
      viewer: viewer,
      designRenderEnabled
    }
  }
)

const mapDispatchToProps = (dispatch: any) => {
  return {
    setUseEnvironment: (value: boolean) => dispatch(fromThreeViewerUi.setUseEnvironment(value)),
    setRenderPreset: (quality: string) => dispatch(fromThreeViewerUi.setRenderPreset(quality)),
    postRender: (args: any) => dispatch(fromCombinations.postRender(args)),
    resetNotificationMessage: () => dispatch(fromProjects.setNotificationMessage(null)),
    setNotificationMessage: (text: string) => dispatch(fromProjects.notify(text))
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>
type Props = PropsFromRedux & {
  onClose: () => void
  error: any
  combinationId?: string
}

function CreateRender (props: Props) {
  const [previewThumbnail, setPreviewThumbnail] = useState<any>(undefined)
  const date = new Date().toISOString().split('T')[0]
  const defaultTitle = props.isBatchRender ? `Image_package_${date}` : `Image ${date}`
  const [renderTitle, setRenderTitle] = React.useState(defaultTitle)

  React.useEffect(() => {
    const createPreviewImage = async () => {
      const client = Client('/api/storage')
      const file = props.viewer.canvasImageCapturer.generateFilefromCanvas('canvas-image.jpeg')
      const manifestKey = await client.upload([file])

      const res = await fetch(`/api/storage/get/${manifestKey}.json`)
      const files = await res.json()
      setPreviewThumbnail(files?.files.length > 0 ? files?.files[0] : undefined)
    }
    createPreviewImage()
  }, [])

  const handleCreateRender = React.useCallback(() => {
    props.onClose()

    const preset = props.presets[props.renderSettings.quality] || Object.values(props.presets)[0]
    props.postRender({
      renderSettings: {
        preset,
        useEnvironment: props.renderSettings.useEnvironment
      },
      title: (renderTitle.split(' ').join('').length > 1) ? renderTitle : defaultTitle,
      combinationType: 'render',
      combinationId: props.combinationId
    })

    if (props.designRenderEnabled) {
      props.setNotificationMessage('Creating design registration')
    } else if (props.isImageTemplateRender) {
      props.setNotificationMessage(`Creating template image${props.renderSetups.length > 1 ? 's' : ''}`)
    } else {
      props.setNotificationMessage('Creating image')
    }
  }, [props.renderSettings, props.renderSetups, props.isImageTemplateRender, renderTitle])

  return (
    <div>
      <h2 className='mt0'>
        {props.title}
      </h2>

      {props.description && (<p>{props.description}</p>)}
      <InputGroup>
        <Label className='flex justify-between'>
          <span>Title</span>
        </Label>
        <InputText
          onChange={(value:string) => setRenderTitle(value)}
          placeholder={defaultTitle}
          value={renderTitle}
          testid='CreateImageTitle'
        />
      </InputGroup>
      <InputGroup>
        <Label className='flex justify-between'>
          <span>Image quality</span>
        </Label>
        <Dropdown
          value={props.renderSettings.quality}
          className='inline width-100 py2'
          options={props.presetOptions}
          onChange={(quality: string) => props.setRenderPreset(quality)}
          testid='imgQualityDropdown'
        />
      </InputGroup>

      <InputGroup className={cs({ hidden: props.hideEnvironmentOption })}>
        <Label className='flex justify-between'>
          <span>Environment</span>
        </Label>
        <Checkbox
          checked={props.renderSettings.useEnvironment}
          name='renderSettings'
          onChange={(value: boolean) => props.setUseEnvironment(value)}
          label='Include environment in image'
          data-testid='includeEnvChk'
        />
      </InputGroup>

      {props.isImageTemplateRender && props.isBatchRender &&
        <Grid rows={['1fr']} style={props.imageTemplateCombinations.length > 5 ? { height: 180 } : { height: 100 }} gridGap={0}>
          <Scrollable innerClassName="custom-scrollbar">
            <AutoFill width={70}>
              {previewThumbnail &&
                <StorageApiThumbnail
                  file={previewThumbnail}
                  constrainRatio={[1, 1]}
                />
              }
              {props.imageTemplateCombinations.map((item, index) => (
                <StorageApiThumbnail
                  key={index}
                  src={(typeof item.thumbnail === 'string') ? item.thumbnail : undefined}
                  file={typeof item.thumbnail !== 'string' ? item.thumbnail : undefined}
                  constrainRatio={[1, 1]}
                />
              ))}
            </AutoFill>
          </Scrollable>
        </Grid>
      }

      <div className='mt2 clearfix flex justify-end items-baseline'>
        <Button className='mr2' onClick={props.onClose}>Cancel</Button>
        <Button btnType='primary' onClick={handleCreateRender}>
          Create
        </Button>
      </div>

      <NotificationBubble
        notification={props.notificationMessage}
        onClearOrTimeout={() => props.resetNotificationMessage()}
      />

      <KeyboardListener
        bindings={[
          KeyBinding(KeyCode.enter, handleCreateRender)
        ]}
      />
    </div>
  )
}

export default connector(CreateRender)
