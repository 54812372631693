import React, { Component } from 'react'
import PropTypes from 'prop-types'
import autoBind from 'react-autobind'
import _omit from 'lodash/omit'

import css from './index.css'

const getClassName = ({ fullWidth, className, mode }) => {
  let output = `${className} ${css.inputText}`

  if (fullWidth) {
    output = `${output} block width-100`
  }

  if (mode) {
    return `${output} ${css[mode]}`
  }

  return output
}

class InputText extends Component {
  constructor (props) {
    super(props)
    autoBind(this)
  }

  get root () {
    return this._root
  }

  handleChange (event) {
    this.props.onChange && this.props.onChange(event.target.value)
  }

  componentDidMount () {
    if (this.props.focus) {
      this._root.focus()
    }
  }

  componentDidUpdate () {
    if (this.props.focusOnUpdate) {
      this._root.focus()
    }
  }

  render () {
    const props = this.props

    return (
      <input
        {..._omit(props, 'mode', 'fullWidth', 'focus', 'focusOnUpdate', 'textTransform')}
        type='text'
        placeholder={props.placeholder}
        data-testid={props['data-testid'] || props.testid || 'inputText' }
        onChange={this.handleChange}
        ref={(node) => (this._root = node)}
        className={getClassName(props)}
        style={{ textTransform: props.textTransform }}
        onBlur={props.onBlur}
      />
    )
  }
}

InputText.propTypes = {
  mode: PropTypes.oneOf(['error', false]),
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  focus: PropTypes.bool,
  focusOnUpdate: PropTypes.bool
}

InputText.defaultProps = {
  className: '',
  fullWidth: true,
  focus: false,
  focusOnUpdate: false,
  placeholder: '',
  spellCheck: true,
  textTransform: 'none'
}

export default InputText
