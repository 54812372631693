import * as THREE from 'three'
import { EventEmitter } from 'events'
import { yawFromRotation, rotationFromYawPitchRoll, pitchFromRotation, clamp } from '../../math'

const KEY_MODES = {
  WASD: 'WASD',
  ARROWS: 'ARROWS'
}

const KEYS = {
  [KEY_MODES.WASD]: { UP: 87, LEFT: 65, DOWN: 83, RIGHT: 68 },
  [KEY_MODES.ARROWS]: { UP: 38, LEFT: 37, DOWN: 40, RIGHT: 39 }
}

const DOLLY_MODES = {
  '2D': '2D',
  '3D': '3D'
}

const MOUSE_BUTTONS = {
  ROTATE: THREE.MOUSE.LEFT,
  ZOOM: THREE.MOUSE.MIDDLE,
  PAN: THREE.MOUSE.RIGHT,
  PAN_ALT: THREE.MOUSE.LEFT,
  NONE: -1
}

export default class FirstPersonControls extends EventEmitter {
  constructor (camera, domElement) {
    super()
    this.isFirstPersonControls = true
    this.isOrbitControls = false
    this.target = undefined
    this._enabled = true

    this._camera = camera
    this._domElement = domElement || document

    this._velocity = new THREE.Vector3()
    this._yaw = yawFromRotation(this._camera.quaternion)
    this._pitch = pitchFromRotation(this._camera.quaternion)
    this._panOffset = new THREE.Vector3()
    this._panHorizontal = new THREE.Vector3()
    this._panVertical = new THREE.Vector3()

    this._enableDolly = true
    this._enablePan = true
    this._enableRotate = true
    this._enableZoom = true

    this.enableMouse = true
    this.enableKeys = true

    this.dollyMode = DOLLY_MODES['3D']

    this.speed = 2
    this.scrollDamping = 0.05
    this.keepCameraY = false

    this.mouseButtons = { ...MOUSE_BUTTONS }
    this.mouseButtons.PAN_ALT = MOUSE_BUTTONS.NONE

    this._keyMode = KEY_MODES.ARROWS
    this._keys = KEYS[this._keyMode]

    this._input = {
      rotate: new THREE.Vector2(),
      pan: new THREE.Vector2(),
      zoom: 0,
      mouseState: {},
      keyState: {}
    }

    this._listeners = [
      { target: window, type: 'keydown', handler: this.onKeyDown.bind(this) },
      { target: window, type: 'keyup', handler: this.onKeyUp.bind(this) },
      { target: window, type: 'blur', handler: () => { this._input.keyState = {} } },
      { target: this._domElement, type: 'mousedown', handler: this.onMouseDown.bind(this) },
      { target: this._domElement, type: 'mouseup', handler: this.onMouseUpOrLeave.bind(this) },
      { target: this._domElement, type: 'mousemove', handler: this.onMouseMove.bind(this) },
      { target: this._domElement, type: 'mouseleave', handler: this.onMouseUpOrLeave.bind(this) },
      { target: this._domElement, type: 'wheel', handler: this.onMouseWheel.bind(this) }
    ]

    this._listeners.forEach(({ target, type, handler, params }) => {
      target.addEventListener(type, handler, params)
    })
  }

  get KEY_MODES () { return KEY_MODES }
  get DOLLY_MODES () { return DOLLY_MODES }
  get MOUSE_BUTTONS () { return MOUSE_BUTTONS }

  set dollyMode (mode) {
    if (!Object.values(DOLLY_MODES).includes(mode)) {
      return console.warn(`Dolly mode ${mode} is not supported. Please use '2D' or '3D' mode.`)
    }
    this._dollyMode = mode
  }

  get dollyMode () { return this._dollyMode }

  set enableDolly (enabled) {
    this._enableDolly = enabled
    if (!enabled) {
      Object.keys(KEYS[this._keyMode]).forEach((key) => {
        this._input.keyState[key] = false
      })
    }
  }

  get enableDolly () { return this._enableDolly }

  set enablePan (enabled) {
    this._enablePan = enabled
    if (!enabled) {
      this._input.mouseState[this.mouseButtons.PAN] = false
      this._input.mouseState[this.mouseButtons.PAN_ALT] = false
      this._input.pan.set(0, 0)
    }
  }

  get enablePan () { return this._enablePan }

  set enableZoom (enabled) {
    this._enableZoom = enabled
    if (!enabled) {
      this._input.mouseState[this.mouseButtons.ZOOM] = false
      this._input.zoom = 0
    }
  }

  get enableZoom () { return this._enableZoom }

  set enableRotate (enabled) {
    this._enableRotate = enabled
    if (!enabled) {
      this._input.mouseState[this.mouseButtons.ROTATE] = false
      this._input.rotate.set(0, 0)
    }
  }

  get enableRotate () { return this._enableRotate }

  set enabled (enabled) {
    this._enabled = enabled
    if (!enabled) {
      this.reset()
    }
  }

  get enabled () { return this._enabled }

  set keyMode (keyMode) {
    if (keyMode === this._keyMode) return
    if (!KEY_MODES[keyMode]) {
      return console.warn(`keyMode ${keyMode} not implemented. Please use ${Object.keys(KEY_MODES).join(', ')} instead.`)
    }
    Object.keys(this._keys).forEach((key) => (this._input.keyState[key] = false))
    this._keyMode = keyMode
    this._keys = KEYS[this._keyMode]
  }

  get keyMode () { return this._keyMode }

  useWASDKeys () { this.keyMode = KEY_MODES.WASD }
  useArrowKeys () { this.keyMode = KEY_MODES.ARROWS }

  addMaxCameraBB (bb) {
    this._maxBB = bb
  }

  set lockedRotationMode (value) {
    if (value) {
      this.enableRotate = false
      this.dollyMode = DOLLY_MODES['2D']
      this.mouseButtons.PAN = MOUSE_BUTTONS.PAN
      this.mouseButtons.PAN_ALT = MOUSE_BUTTONS.PAN_ALT
      this.mouseButtons.ROTATE = MOUSE_BUTTONS.NONE
    } else {
      this.enableRotate = true
      this.dollyMode = DOLLY_MODES['3D']
      this.mouseButtons.PAN = MOUSE_BUTTONS.PAN
      this.mouseButtons.ROTATE = MOUSE_BUTTONS.ROTATE
      this.mouseButtons.PAN_ALT = MOUSE_BUTTONS.NONE
    }
  }

  get lockedRotationMode () {
    return this.enableRotate
  }

  start () {
    this.reset()
    this._enabled = true
  }

  onMouseWheel (event) {
    if (!this._enabled || !this.enableMouse) return

    event.preventDefault()
    event.stopPropagation()

    const scrollDamping = this.dollyMode === '2D'
      ? (this.scrollDamping * 20)
      : this.scrollDamping

    this._input.zoom = event.deltaY * scrollDamping
  }

  onMouseMove (event) {
    if (!this._enabled || !this.enableMouse) return

    if (this._input.mouseState[this.mouseButtons.ROTATE]) {
      this._input.rotate.set(event.movementX, event.movementY)
    }

    if (this._input.mouseState[this.mouseButtons.PAN] || this._input.mouseState[this.mouseButtons.PAN_ALT]) {
      this._input.pan.set(event.movementX, event.movementY)
    }

    if (this._input.mouseState[this.mouseButtons.ZOOM]) {
      this._input.zoom = event.movementY
    }
  }

  onKeyDown (event) {
    if (this.enableKeys && (this._enabled && Object.values(KEYS[this._keyMode]).includes(event.keyCode))) {
      this._input.keyState[event.keyCode] = true
    }
  }

  onKeyUp (event) {
    if (Object.values(KEYS[this._keyMode]).includes(event.keyCode)) {
      this._input.keyState[event.keyCode] = false
    }
  }

  onMouseDown (event) {
    if (!this._enabled || !this.enableMouse) return

    this._input.mouseState = {}

    if (this.enablePan && event.button === this.mouseButtons.PAN) {
      this._input.mouseState[this.mouseButtons.PAN] = true
    }

    if (this.enablePan && event.button === this.mouseButtons.PAN_ALT) {
      this._input.mouseState[this.mouseButtons.PAN_ALT] = true
    }

    if (this.enableRotate && event.button === this.mouseButtons.ROTATE) {
      this._input.mouseState[this.mouseButtons.ROTATE] = true
    }

    if (this.enableZoom && event.button === this.mouseButtons.ZOOM) {
      this._input.mouseState[this.mouseButtons.ZOOM] = true
    }
  }

  onMouseUpOrLeave (event) {
    this._input.mouseState[this.mouseButtons.PAN] = false
    this._input.mouseState[this.mouseButtons.PAN_ALT] = false
    this._input.mouseState[this.mouseButtons.ROTATE] = false
    this._input.mouseState[this.mouseButtons.ZOOM] = false
  }

  isInputActive () {
    const isKeysActive = Object.values(this._input.keyState).find(Boolean)
    const isMouseActive = Object.values(this._input.mouseState).find(Boolean)

    return (isKeysActive || isMouseActive || this._input.zoom !== 0)
  }

  update (delta) {
    if (!(this._enabled && this.isInputActive())) return

    const dollyIn2D = this.dollyMode === '2D'
    const dollyIn3D = !dollyIn2D

    const keys = KEYS[this._keyMode]
    const horizontal = (this._input.keyState[keys.RIGHT] || 0) - (this._input.keyState[keys.LEFT] || 0)
    const vertical = (this._input.keyState[keys.DOWN] || 0) - (this._input.keyState[keys.UP] || 0)

    // Pan
    if (this._input.pan.x !== 0 || (dollyIn2D && horizontal !== 0)) {
      this._panHorizontal.setFromMatrixColumn(this._camera.matrix, 0) // get X column
      const horizontalDirection = (-this._input.pan.x * 0.004) + (horizontal * 0.008)
      this._panHorizontal.multiplyScalar(horizontalDirection)
      this._panOffset.add(this._panHorizontal)
    }
    if (this._input.pan.y !== 0 || (dollyIn2D && vertical !== 0)) {
      this._panVertical.setFromMatrixColumn(this._camera.matrix, 1) // get Y column
      const verticalDirection = (this._input.pan.y * 0.004) + (-vertical * 0.008)
      this._panVertical.multiplyScalar(verticalDirection)
      this._panOffset.add(this._panVertical)
    }
    this._camera.position.add(this._panOffset)

    // Save height from pan movement
    const height = this._camera.position.y

    // Rotate
    this._yaw += -this._input.rotate.x * 0.002
    this._pitch += -this._input.rotate.y * 0.002
    this._pitch = clamp(this._pitch, -Math.PI / 2, Math.PI / 2)
    this._camera.quaternion.copy(rotationFromYawPitchRoll(this._yaw, this._pitch, 0))

    // Zoom and Dolly
    const dollyX = dollyIn3D ? horizontal : 0
    const dollyZ = dollyIn3D ? vertical : 0
    this._velocity
      .set(dollyX, 0, dollyZ + this._input.zoom)
      .multiplyScalar(this.speed * delta)
      .applyQuaternion(this._camera.quaternion)
    this._camera.position.add(this._velocity)

    if (this.keepCameraY && this._input.zoom === 0 && dollyIn3D) {
      this._camera.position.y = height
    }

    this._panOffset.set(0, 0, 0)
    this._input.pan.set(0, 0)
    this._input.rotate.set(0, 0)
    this._input.zoom = 0

    this.emit('change')
  }

  setCameraState (matrix) {
    this._camera.position.setFromMatrixPosition(matrix)
    this._camera.rotation.setFromRotationMatrix(matrix)
    this._camera.updateMatrixWorld(true)

    this._yaw = yawFromRotation(this._camera.quaternion)
    this._pitch = pitchFromRotation(this._camera.quaternion)
  }

  resetYawAndPitch () {
    this._yaw = yawFromRotation(this._camera.quaternion)
    this._pitch = pitchFromRotation(this._camera.quaternion)
  }

  reset () {
    this._yaw = yawFromRotation(this._camera.quaternion)
    this._pitch = pitchFromRotation(this._camera.quaternion)

    this._velocity.set(0, 0, 0)
    this._panOffset.set(0, 0, 0)
    this._panHorizontal.set(0, 0, 0)
    this._panVertical.set(0, 0, 0)

    this._input.pan.set(0, 0)
    this._input.rotate.set(0, 0)
    this._input.keyState = {}

    this.enableMouse = true
    this.enableKeys = true
  }

  stop () {
    this._enabled = false
    this.reset()
  }

  dispose () {
    this.reset()

    this._listeners.forEach(({ target, type, handler, params }) => {
      target.addEventListener(type, handler, params)
    })
  }
}
