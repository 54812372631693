import React, { PureComponent } from 'react'
import autoBind from 'react-autobind'
import PropTypes from 'prop-types'
import cs from 'classnames'

class PanelContent extends PureComponent {
  constructor (props, ctx) {
    super(props, ctx)

    autoBind(this)

    this.state = {
      isResizing: false
    }
  }

  handleMouseMove (event) {
    const delta = event.movementX * (this.props.position === 'left' ? 1 : -1)
    this.props.onWidthChange(delta)
  }

  handleMouseDown () {
    if (this.state.isResizing) return

    this.setState({
      isResizing: true
    })

    document.body.classList.add('userselect-none')

    window.addEventListener('mouseup', this.handleMouseUp)
    window.addEventListener('mousemove', this.handleMouseMove)
  }

  handleMouseUp () {
    this.setState({
      isResizing: false
    })

    document.body.classList.remove('userselect-none')

    window.removeEventListener('mouseup', this.handleMouseUp)
    window.removeEventListener('mousemove', this.handleMouseMove)
  }

  render () {
    const {
      position,
      active,
      width,
      maxWidth,
      minWidth,
      keepChildrenMounted,
      grid,
      onBeforeNavigate,
      children
    } = this.props

    const childrenWithProp = React.Children.map(children, (child) => {
      return React.cloneElement(child, { onBeforeNavigate: onBeforeNavigate, active })
    })

    return (
      <div
        className={cs(`
          absolute
          height-100
          top-0
          bg-white paper-1
        `, {
          hidden: !active
        })}
        style={{
          width,
          maxWidth,
          minWidth,
          left: position === 'left' && '100%',
          right: position === 'right' && '100%'
        }}
      >
        <div
          onMouseDown={this.handleMouseDown}
          className={`
            top-0
            height-100
            absolute
          `}
          style={{
            width: 8,
            cursor: 'ew-resize',
            [position === 'left' ? 'right' : 'left']: -8,
            order: position === 'left' ? 1 : 0
          }}
        />
        <div className='height-100'
          style={{ display: grid ? 'grid' : 'inline' }}>
          {(keepChildrenMounted || active) && childrenWithProp}
        </div>
      </div>
    )
  }
}

PanelContent.defaultProps = {
  defaultWidth: 300,
  keepChildrenMounted: false,
  maxWidth: 500,
  minWidth: 200
}

PanelContent.propTypes = {
  defaultWidth: PropTypes.number,
  maxWidth: PropTypes.number,
  minWidth: PropTypes.number,
  maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  position: PropTypes.oneOf(['left', 'right']).isRequired,
  keepChildrenMounted: PropTypes.bool,
  active: PropTypes.bool.isRequired
}

export default PanelContent
