import React, { useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { createSelector } from 'reselect'

import _get from 'lodash/get'
import _debounce from 'lodash/debounce'

// Actions
import * as fromMaterialSearch from '../../../../../stores/ducks/material-search'
import * as fromThreeviewerUI from '../../../../../stores/ducks/threeviewer/ui'
import * as fromThreeviewerViewer from '../../../../../stores/ducks/threeviewer/viewer'

// Selectors
import * as fromMaterialAndColorSelectors from '../material-and-color-selectors'
import * as fromImagePackagesSelectors from '../../../../../stores/ducks/image-packages/selectors'
import * as fromProjectsSelectors from '../../../../../stores/ducks/projects/selectors'

// Components
import { PanelSectionHeader } from '../../../../common/panels/panel-section'
import InputSearch from '../../../../common/form/input-search'
import Label from '../../../../common/form/label'
import Pill from '../../../../common/Pill'

import MaterialList from './material-list'
import LoadMore from './load-more'
import EmptyState from '../../../../common/empty-state/empty-state'
import { MdThumbDown as IconThumbDown } from 'react-icons/md'
import IconAppearances from '../../../../common/icons/icon-material'
import IconArrowDown from '../../../../common/icons/icon-arrow-down'
import IconArrowUp from '../../../../common/icons/icon-arrow-up'

import { PROJECT_TYPES } from '../../../../../constants'
import colors from '../../../../../../css/colors'
import { RootState } from '../../../../../stores/ducks'

const materialClass = ['DPD', 'Standard', 'Specialized']

const mapStateToProps = createSelector(
  (state: RootState) => state.materialSearch.searchResults,
  (state: RootState) => state.materialSearch.error,
  fromMaterialAndColorSelectors.getSelectionMode,
  (state: RootState) => state.materialSearch.queryString,
  (state: RootState) => state.materialSearch.isSearching,
  (state: RootState) => state.materialSearch.shouldPerformFirstDefaultSearch,
  fromProjectsSelectors.getCurrentEntry,
  fromImagePackagesSelectors.getCurrentEntry,
  (state: RootState) => state.materialSearch.materialCategories,
  (state: RootState) => state.materialSearch.materialClass,
  (state: RootState) => state.materialSearch.allMaterialCategories,
  (state: RootState) => state.materialSearch.isUncategorizedSelected,
  (
    searchResults,
    error,
    selectionMode,
    queryString,
    isSearching,
    shouldPerformFirstDefaultSearch,
    project,
    imagePackage,
    materialCategories,
    materialClass,
    allMaterialCategories,
    isUncategorizedSelected
  ) => {
    const projectType = project.projectType
    return {
      searchResults,
      error,
      selectionMode,
      queryString,
      isSearching,
      shouldPerformFirstDefaultSearch,
      ids: projectType === PROJECT_TYPES.IMAGE_PACKAGE && _get(imagePackage, 'materials'),
      tabs: [
        {
          label: 'Standard',
          id: 'materialbank',
          shouldDisplay: projectType !== PROJECT_TYPES.IMAGE_PACKAGE
        },
        {
          label: 'DPD',
          id: 'dpd',
          shouldDisplay: projectType !== PROJECT_TYPES.IMAGE_PACKAGE
        }
      ].filter(tab => tab.shouldDisplay),
      materialCategories,
      materialClass,
      allMaterialCategories,
      isUncategorizedSelected
    }
  }
)

const mapDispatchToProps = (dispatch: any) => {
  const debouncedFunction = _debounce(() => dispatch(fromMaterialSearch.performSearch()), 250)
  return {
    performDefaultSearch: () => {
      dispatch(fromMaterialSearch.performSearch())
      dispatch(fromMaterialSearch.setShouldPerformFirstDefaultSearch(false))
    },
    performSearch: () => {
      dispatch(fromMaterialSearch.performSearch())
    },
    setQueryString: (value: string) => {
      dispatch(fromMaterialSearch.setQueryString(value))
      debouncedFunction()
    },
    setMaterialSource: (value: string) => {
      dispatch(fromThreeviewerUI.setMaterialSource(value))
      dispatch(fromMaterialSearch.performSearch())
    },
    disableKeyboardListeners: () => {
      dispatch(fromThreeviewerUI.setKeyBoardBindings(false))
      dispatch(fromThreeviewerViewer.disableKeyboardListeners())
    },
    enableKeyboardListeners: () => {
      dispatch(fromThreeviewerUI.setKeyBoardBindings(true))
      dispatch(fromThreeviewerViewer.enableKeyboardListeners())
    },
    loadMore: () => {
      dispatch(fromMaterialSearch.incrementPage())
      dispatch(fromMaterialSearch.performSearch())
    },
    toggleMaterialCategories: (category: string) => {
      dispatch(fromMaterialSearch.toggleMaterialCategories(category))
      dispatch(fromMaterialSearch.performSearch())
    },
    toggleMaterialClass: (mClass: string) => {
      dispatch(fromMaterialSearch.toggleMaterialClass(mClass))
      dispatch(fromMaterialSearch.performSearch())
    },
    toggleUncategorized: () => {
      dispatch(fromMaterialSearch.toggleIsUncategorizedSelected())
      dispatch(fromMaterialSearch.performSearch())
    }
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>
type Props = PropsFromRedux & {}

const MaterialSearch = (props: Props) => {
  const [isCategoriesExpanded, setIsCategoriesExpanded] = useState(false)

  React.useEffect(() => {
    if (props.shouldPerformFirstDefaultSearch) {
      props.performDefaultSearch()
    }
  }, [])

  React.useEffect(() => {
    props.performSearch()
  }, [props.selectionMode])

  return (
    <div
      className='pt2 flex flex-column'
      data-testid = 'material-search'
    >
      <div className='flex justify-between'>
        <PanelSectionHeader
          title='Appearance'
        />
      </div>
      <div className='relative px2 flex flex-column height-100'>
        {props.selectionMode !== 'template' &&
          <div>
            <Label style={{ marginBottom: 4 }}>Type</Label>
            <div className='flex flex-wrap'>
              {materialClass.map((mClass) => {
                return (
                  <Pill
                    data-testid={mClass}
                    active={props.materialClass.includes(mClass)}
                    onClick={() => props.toggleMaterialClass(mClass)}
                    key={mClass}
                    style={{ margin: 2, padding: '4px 12px 4px 12px', userSelect: 'none' }}
                  >
                    {mClass}
                  </Pill>
                )
              })}
            </div>
            <div className="flex items-center mt1" onClick={() => setIsCategoriesExpanded(!isCategoriesExpanded)}>
              <Label style={{ marginBottom: 4, userSelect: 'none' }}>Categories</Label>
              {isCategoriesExpanded
                ? <IconArrowUp size={11} color={colors.grayDark} style={{ margin: '0 0 3px 8px', cursor: 'pointer' }} />
                : <IconArrowDown size={11} color={colors.grayDark} style={{ margin: '0 0 3px 8px', cursor: 'pointer' }} />
              }
            </div>
            <div id='categoriesContainer' className='flex flex-wrap'>
              {isCategoriesExpanded && props.allMaterialCategories.map((category) => {
                return (
                  <Pill
                    active={props.materialCategories.includes(category)}
                    onClick={() => props.toggleMaterialCategories(category)}
                    key={category}
                    style={{ margin: 2, userSelect: 'none' }}
                  >
                    {category}
                  </Pill>
                )
              })}

              {isCategoriesExpanded &&
                  <Pill
                    active={props.isUncategorizedSelected}
                    onClick={() => props.toggleUncategorized()}
                    style={{ margin: 2, userSelect: 'none' }}
                  >
                    Uncategorized
                  </Pill>
              }

              {!isCategoriesExpanded && props.materialCategories.map((selectedCategory) => {
                return (
                  <Pill
                    active={true}
                    key={selectedCategory}
                    onClick={() => props.toggleMaterialCategories(selectedCategory)}
                    style={{ margin: 2, userSelect: 'none' }}
                  >
                    {selectedCategory}
                  </Pill>
                )
              })}

              {!isCategoriesExpanded && props.isUncategorizedSelected &&
                  <Pill
                    active={props.isUncategorizedSelected}
                    onClick={() => props.toggleUncategorized()}
                    style={{ margin: 2, userSelect: 'none' }}
                  >
                    Uncategorized
                  </Pill>
              }
            </div>
          </div>
        }
        <div>
          <Label style={{ marginBottom: 4, marginTop: 12 }}>Search</Label>
          <InputSearch
            onFocus={props.disableKeyboardListeners}
            onBlur={props.enableKeyboardListeners}
            onChange={props.setQueryString}
            value={props.queryString}
            onClearSearch={() => props.setQueryString('')}
            placeholder='Search...'
          />
        </div>
        {props.error &&
          <EmptyState
            icon={<IconThumbDown size={40} color={colors.grayDark} />}
            title={'Something went wrong'}
            desc={'No materials available.'}
          />
        }
        <div style={{ height: 26, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {props.isSearching &&
            <div
              className="mx2 pb2 center f4 absolute"
            >
              ...
            </div>
          }
        </div>
        {!props.searchResults.length && !props.isSearching &&
          <EmptyState
            icon={<IconAppearances size={40} color={colors.grayDark} />}
            title={'No materials found'}
            desc={props.queryString.length > 0 ? `No materials found for '${props.queryString}'. Request an appearance from Virtual Prototype Shop ` : ''}
            linkText='here'
            link='https://prototypeshop-ikea.creatics.se/'
          />
        }
        {!props.error &&
          <div style={{ position: 'relative', height: '100%' }}>
            <LoadMore
              className={'overflow-x-hidden overflow-y-scroll absolute pb4 top-0 bottom-0 left-0 right-0 custom-scrollbar'}
              style={{ marginTop: 0 }}
              onLoadMore={() => props.loadMore()}
            >
              <MaterialList materials={props.searchResults} />
            </LoadMore>
          </div>
        }
      </div>
    </div>
  )
}

export default connector(MaterialSearch)
