import React from 'react'

import Avatar from '../../../common/avatar'
import CommentHeader from '../../../common/comments/comment-header'
import CommentCounter from '../../../common/comments/comment-counter'

import Tags from './comment-tags'
import { Comment } from '../../../../stores/ducks/scenes/Temp'

const generalDpdUser = {
  firstName: 'User',
  lastName: ' ',
  email: 'User',
  id: '',
  scopes: [],
  active: true,
  hasAcceptedGDPR: true
}

type Props = {
  isSelected: boolean
  onRemove: () => void
  removeEnabled: boolean
  onToggleSelect: () => void
  comment: Comment
  addComments?: boolean
  onEdit?: () => void
}

function CommentComponent (props:Props) {
  const addCommentRef = React.useRef(null)
  const counterRef = React.useRef<any>(null) // what type has scroll into view? it does not work with HTMLDivElement

  React.useEffect(() => {
    window.requestAnimationFrame(() => {
      if (counterRef.current) counterRef.current.scrollIntoViewIfNeeded()
    })
  }, [props.isSelected])

  return (
    <div
      className='relative py1 pl1'
    >
      <div className={'br-2 bg-gray-light f6 p1 paper-0 pl1'}>
        <div className='flex'>
          <Avatar user={generalDpdUser} style={{ backgroundColor: 'white' }} />

          <div className='ml1 width-100'>
            <CommentHeader
              commentId={props.comment.id}
              author={generalDpdUser}
              currentUserId={generalDpdUser.id}
              createdAt={props.comment.createdAt ? props.comment.createdAt.slice(0, 10) : ''}
              likes={[]}
              likeEnabled={false}
              likedByCurrentUser={false}
              onEdit={props.onEdit}
              onRemove={props.onRemove}
              removeEnabled={props.removeEnabled}
              theme={'light'}
              editEnabled={props.addComments}
            />
            <div
              className='c-gray-lighter mb1'
              ref={addCommentRef}
            >
              <div style={{ whiteSpace: 'pre-wrap', color: 'black' }}>{props.comment.comment}</div>
            </div>

            <div className='width-100'>
              <Tags tags={props.comment.tags} />
            </div>
          </div>
        </div>
      </div>
      {props.comment.position && (
        <>
          <div
            ref={counterRef}
            className='absolute top-0 left-0'
          />
          <CommentCounter
            className='absolute left-0 top-0'
            size={22}
            isActive={props.isSelected}
            count={props.comment.index}
            onClick={props.onToggleSelect}
          />
        </>
      )}
    </div>
  )
}

export default CommentComponent
