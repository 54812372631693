import cssColorsString from '!!raw-loader!./colors.css'

const toNumeric = (val) => isNaN(val) ? val : parseFloat(val)

const colors = cssColorsString.split('\n')
  .map(l => l.trim())
  .filter(l => /^--([_a-zA-Z]+[_a-zA-Z0-9-]*):/.test(l))
  .map(l => l.replace(';', '').replace('--', ''))
  .map(l => l.split(':').map(x => x.trim()))
  .reduce((acc, [k, v]) => ({ ...acc, [k]: toNumeric(v) }), {})

export default colors
