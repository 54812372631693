// TODO: app.objectTracker => this.objectTracker (it is the same!)

import { ColorWrapper, Vector3Wrapper, QuaternionWrapper, EulerWrapper } from '../ThreeWrappers'

const ObjectTracker = require('./ObjectTracker.js')
const RenderScene = require('../RenderScene.js').default
var THREE = require('three')

var SceneGraphNodeId = 0
const tempVector1 = new THREE.Vector3()
const tempVector2 = new THREE.Vector3()

export class SceneGraphNode {
  constructor () {
    this.id = SceneGraphNodeId++ // TODO: This is only used by one tool or so and perhaps it can instead use uuid? Investigate
    this.uuid = THREE.Math.generateUUID() // DO we need uuid or can they go from 0 and increase? What are they used for?
    this.parent = null // When do we need parent? Can we always go through ObjectTracker? Is nice to be able to have same subgraph in many scenes :)
    this.name = ''
    this.children = []
    this.userData = {}
    this._visible = true
    this._outline = false
    this._transformOutline = false
    this._parentsVisible = true // All parents .visible &&-together (only when mounted in scene graph)
    this._hasParentWithOutline = false // Only when mounted in scene graph
    this._featureFlags = 0
  }

  addChild (child) {
    if (!(child instanceof SceneGraphNode)) {
      console.error('Trying to add an incorrect node to the scene graph', this, child)
      return
    }

    if (child._ownerSceneGraph !== this._ownerSceneGraph) {
      console.error('OwnerSceneGraphs are not equal.', this, child)
      return
    }

    const oldParent = child.parent

    child.parent = this
    this.children.push(child)

    if (oldParent) {
      oldParent.children = oldParent.children.filter(e => e !== child)
    }
  }

  removeChild (child) {
    if (!(child instanceof SceneGraphNode)) {
      console.error('Trying to add an incorrect node to the scene graph', this, child)
      return
    }
    this.children = this.children.filter(e => e !== child)
    child.traverse(node => {
      node._outline = false
      node._transformOutline = false
    })
  }

  add (child) {
    if (!(child instanceof SceneGraphNode)) {
      console.error('Trying to add an incorrect node to the scene graph', this, child)
      return
    }

    child.parent = this
    this.children.push(child)
    const owner = this._ownerSceneGraph
    if (owner === undefined) {
      return
    }
    child.traverse(function (node) {
      node._createRenderAsset(owner)
      const parent = node.parent
      node._hasParentWithOutline = parent._outline || parent._hasParentWithOutline
      node._parentsVisible = parent._visible && parent._parentsVisible
      node._setFeatureFlag(RenderScene.UPDATE_VISIBLE, node._parentsVisible && node._visible)
      node._setFeatureFlag(RenderScene.UPDATE_OUTLINE, node._hasParentWithOutline || node._outline)
      node._setFeatureFlag(RenderScene.UPDATE_TRANSFORM_OUTLINE, node._transformOutline)
    })
  }

  remove (child) {
    if (!(child instanceof SceneGraphNode)) {
      console.error('Trying to add an incorrect node to the scene graph', this, child)
      return
    }
    this.children = this.children.filter(e => e !== child)
    const owner = child._ownerSceneGraph
    child.parent = null
    child.traverse(function (node) {
      if (owner !== undefined) {
        node._removeRenderAsset()
      }
      node._outline = false
      node._transformOutline = false
    })
  }

  dispose (assetManager) {
    if (this._ownerSceneGraph) {
      this._removeRenderAsset()
    }
    this.children.forEach(function (node) {
      node.dispose(assetManager)
    })
    this.children = []
    this.parent = null
  }

  traverse (callback) {
    callback(this)
    for (let i = 0, l = this.children.length; i < l; i++) {
      this.children[i].traverse(callback)
    }
  }

  traverseRoots (callback) {
    const { isModelRoot } = this.userData
    if (isModelRoot) {
      callback(this)
    } else {
      for (let i = 0, l = this.children.length; i < l; i++) {
        this.children[i].traverseRoots(callback)
      }
    }
  }

  traverseAncestors (callback) {
    let parent = this.parent
    while (parent !== null) {
      callback(parent)
      parent = parent.parent
    }
  }

  traverseMeshes (callback) {
    this.traverse(node => {
      if (node instanceof SceneGraphMesh) {
        callback(node)
      }
    })
  }

  find (uuid) {
    let target
    this.traverse(node => {
      if (node.uuid === uuid) {
        target = node
      }
    })
    return target
  }

  copyProperties (target) {
    // NOTE: uuid is not copied since we want it to be unique
    target.parent = null
    target.name = this.name
    target._visible = this._visible
    target._outline = false // NOTE: Outline is really a rendering state and when cloning/copying we clear it
    target._transformOutline = false // NOTE: Outline is really a rendering state and when cloning/copying we clear it
    target._featureFlags = this._featureFlags
  }

  clone (rootNode = true, nodeMapping = undefined) {
    // NOTE: A cloned node is never mounted in the scene graph (since the root node has no parent)

    // It is very rare but special case when there is a node with a light target
    let hasNodeWithLightTarget = false

    if (rootNode) {
      this.traverse(oldNode => {
        if ((oldNode.isSpotLight || oldNode.isDirectionalLight) && oldNode.target !== null) {
          hasNodeWithLightTarget = true
        }
      })
      if (hasNodeWithLightTarget) {
        nodeMapping = new Map()
      }
    }

    const scope = this
    const n = new this.constructor()
    if (nodeMapping !== undefined) {
      nodeMapping.set(this.uuid, n)
    }
    scope.copyProperties(n)
    n.userData = JSON.parse(JSON.stringify(this.userData))
    this.children.forEach(function (c) {
      const newChild = c.clone(false, nodeMapping)
      newChild.parent = n
      n.children.push(newChild)
    })

    // Now that we know what old uuid maps to new node we can set target on all nodes
    if (rootNode && hasNodeWithLightTarget) {
      this.traverse(oldNode => {
        if ((oldNode.isSpotLight || oldNode.isDirectionalLight) && oldNode.target !== null) {
          const newTargetNode = nodeMapping[oldNode.target.uuid]
          if (newTargetNode !== undefined) {
            nodeMapping[oldNode.uuid].target = nodeMapping[oldNode.target.uuid]
          } else {
            console.warn('Cloned a SceneGraphNode with a light source with a target outside the cloned hierarchy ')
          }
        }
      })
    }

    return n
  }

  get visible () { return this._visible }
  get outline () { return this._outline }
  get transformOutline () { return this._transformOutline }

  // NOTE: There is a difference between node.visible and the render state visible for meshes/lights
  set visible (value) {
    if (value === this._visible) return
    this._visible = value
    if (this._ownerSceneGraph === undefined) {
      return
    }

    function propagateVisibility (node) {
      const effectiveVisibility = node._parentsVisible && node.visible
      node._setFeatureFlag(RenderScene.UPDATE_VISIBLE, effectiveVisibility)
      node.children.forEach(child => {
        if (effectiveVisibility !== child._parentsVisible) {
          child._parentsVisible = effectiveVisibility
          propagateVisibility(child) // Recurse
        }
      })
    }
    propagateVisibility(this)
  }

  set outline (value) {
    if (value === this._outline) return
    this._outline = value
    if (this._ownerSceneGraph === undefined) {
      return
    }

    function propagateOutline (node) {
      const effectiveOutline = node._hasParentWithOutline || node.outline
      node._setFeatureFlag(RenderScene.UPDATE_OUTLINE, effectiveOutline)
      node.children.forEach(child => {
        if (effectiveOutline !== child._hasParentWithOutline) {
          child._hasParentWithOutline = effectiveOutline
          propagateOutline(child) // Recurse
        }
      })
    }
    propagateOutline(this)
  }

  set transformOutline (value) {
    this._transformOutline = value
    function propagateOutline (node) {
      node._setFeatureFlag(RenderScene.UPDATE_TRANSFORM_OUTLINE, value)
      node.children.forEach(child => {
        propagateOutline(child)
      })
    }
    propagateOutline(this)
  }

  get matrixWorld () {
    if (this.parent === null) {
      return new THREE.Matrix4() // Identity matrix
    }
    return this.parent.matrixWorld
  }

  set castShadow (value) {
    this._setFeatureFlag(RenderScene.UPDATE_CAST_SHADOW, value)
  }

  get castShadow () {
    return !!(this._featureFlags & RenderScene.UPDATE_CAST_SHADOW)
  }

  set receiveShadow (value) {
    this._setFeatureFlag(RenderScene.UPDATE_RECEIVE_SHADOW, value)
  }

  get receiveShadow () {
    return !!(this._featureFlags & RenderScene.UPDATE_RECEIVE_SHADOW)
  }

  // These three functions will be overriden by base classes that have a rendering representation
  _createRenderAsset (owner) {
    this._ownerSceneGraph = owner
    this._changeFlags = RenderScene.UPDATE_CREATE
    this._parentsVisible = true
    this._hasParentWithOutline = false
  }

  _updateRenderAsset () {}
  _removeRenderAsset () {
    delete this._changeFlags
    delete this._parentsVisible
    delete this._hasParentWithOutline
    this._ownerSceneGraph = null
  }

  _markDirty (changeMask) {
    if (this._ownerSceneGraph !== undefined && this._changeFlags === 0) {
      this._updateRenderAsset()
    }
    this._changeFlags |= changeMask
  }

  _setFeatureFlag (mask, value) {
    if (value !== !!(this._featureFlags & mask)) {
      this._markDirty(mask)
      if (value) {
        this._featureFlags |= mask
      } else {
        this._featureFlags &= ~mask
      }
    }
  }

  get isMesh () { return false }
  get isLight () { return false }
  get isDirectionalLight () { return false }
  get isSpotLight () { return false }
  get isPointLight () { return false }
}

export class SceneGraphNode3d extends SceneGraphNode {
  constructor () {
    super()
    const onChange = () => this._setLocalTransformDirty()
    const onQuatChange = () => {
      // NOTE: Since quaternion and rotation are coupled we need to disable onChange event event here
      const oldChange = this._rotation.__onChange
      this._rotation.__onChange = undefined
      this._rotation.setFromQuaternion(this.quaternion, undefined, false)
      this._rotation.__onChange = oldChange
      this._setLocalTransformDirty()
    }
    const onRotationChange = () => {
      // NOTE: Since quaternion and rotation are coupled we need to disable onChange event event here
      const oldChange = this._quaternion.__onChange
      this._quaternion.__onChange = undefined
      this._quaternion.setFromEuler(this.rotation, false)
      this._quaternion.__onChange = oldChange
      this._setLocalTransformDirty()
    }

    this._matrix = new THREE.Matrix4()
    this._matrixWorld = new THREE.Matrix4()
    this._position = new Vector3Wrapper(onChange, 0, 0, 0)
    this._scale = new Vector3Wrapper(onChange, 1, 1, 1)
    this._quaternion = new QuaternionWrapper(onQuatChange, 0, 0, 0, 1)
    this._rotation = new EulerWrapper(onRotationChange, 0, 0, 0)
    this._localTransformDirty = true
    this._worldTransformDirty = true
    this._matrixWorldDirtyListener = {}
  }

  _addMatrixWorldDirtyListener (uuid, callback) {
    this._matrixWorldDirtyListener[uuid] = callback
  }

  _removeMatrixWorldDirtyListener (uuid) {
    delete this._matrixWorldDirtyListener[uuid]
  }

  get position () { return this._position }
  get scale () { return this._scale }
  get quaternion () { return this._quaternion }
  get rotation () { return this._rotation }

  set position (value) {
    console.warn('SceneGraphNode3d.position was assigned, used .copy instead!')
  }

  set scale (value) {
    console.warn('SceneGraphNode3d.scale was assigned, used .copy instead!')
  }

  set quaternion (value) {
    console.warn('SceneGraphNode3d.quaternion was assigned, used .copy instead!')
  }

  set rotation (value) {
    console.warn('SceneGraphNode3d.rotation was assigned, used .copy instead!')
  }

  _setLocalTransformDirty () {
    // If this node already had a dirty local transform then we don't need to tell anyone. They are already dirty.
    if (this._localTransformDirty) return
    this._localTransformDirty = true
    function recurseChildren (node) {
      node.children.forEach(child => {
        if (!child._worldTransformDirty) {
          child._setWorldTransformDirtyNonPropagating()
          recurseChildren(child)
        }
      })
    }
    // If this node already has a dirty world transform then all children will as well so no need to tell anyone
    // Otherwise propagate until we find a dirty world transform (or a leaf)
    if (!this._worldTransformDirty) {
      this._setWorldTransformDirtyNonPropagating()
      recurseChildren(this)
    }
  }

  _setWorldTransformDirtyNonPropagating () {
    // If a light source is using this node as a target, we need to mark the world transform of that light source dirty as well
    for (const key in this._matrixWorldDirtyListener) {
      const callback = this._matrixWorldDirtyListener[key]
      callback(this)
    }
    this._worldTransformDirty = true
  }

  get matrix () {
    if (this._localTransformDirty) {
      // We compose lazily. That way user can modify .position, .scale and .quaternion/.scale many times without generating matrix operations
      this._matrix.compose(this._position, this._quaternion, this._scale)
      this._localTransformDirty = false
    }
    return this._matrix
  }

  get matrixWorld () {
    if (this._worldTransformDirty) {
      // Note: This can trigger recursion down to root and also compose of matrix (via this.matrix-getter)
      //       If many leafs are called they will eventually have the result cached in the parents
      if (this.parent !== undefined && this.parent !== null) {
        this._matrixWorld.multiplyMatrices(this.parent.matrixWorld, this.matrix)
      } else {
        this._matrixWorld.copy(this.matrix)
      }
      this._worldTransformDirty = false
    }
    return this._matrixWorld
  }

  set matrix (value) {
    console.error('Not allowed to set .matrix on', this)
  }

  set matrixWorld (value) {
    console.error('Not allowed to set .matrixWorld on', this)
  }

  updateMatrixWorld () {
    console.trace('No need to call updateMatrixWorld on', this)
  }

  setLocalMatrix (matrix) {
    const scale = new THREE.Vector3()
    const position = new THREE.Vector3()
    const rotation = new THREE.Quaternion()

    matrix.decompose(position, rotation, scale)

    this._position.copy(position)
    this._rotation.setFromQuaternion(rotation)
    this._scale.copy(scale)
  }

  copyProperties (target) {
    super.copyProperties(target)
    target.position.copy(this._position)
    target.scale.copy(this._scale)
    target.quaternion.copy(this._quaternion)
    // NOTE: We don't copy _matrixWorldDirtyListener, assumed to be handled by clone
  }

  applyMatrix (matrix) {
    // This first line might cause a compose of this._matrix (via this.matrix getter)
    this.matrix.premultiply(matrix)
    // This call will cause dirty marking of local and world matrix, but since we recalculate them lazily it does not corrupt this._matrix during .decompose-call
    this._matrix.decompose(this._position, this._quaternion, this._scale)
  }

  translateX (distance) { this.position.x += distance }
  translateY (distance) { this.position.y += distance }
  translateZ (distance) { this.position.z += distance }

  rotateX (angle) { this.rotation.x += angle }
  rotateY (angle) { this.rotation.y += angle }
  rotateZ (angle) { this.rotation.z += angle }
}

export class SceneGraphMeshGeometry {
  constructor (uuid, boundingBox) {
    this._uuid = uuid
    this._boundingBox = boundingBox
  }

  get uuid () { return this._uuid }
  get boundingBox () { return this._boundingBox }

  clone () {
    // We are read-only so no need for unique objects
    console.warn('SceneGraphMeshGeometry::clone called!')
    return this
  }

  get isGeometry () {
    console.error('SceneGraphMeshGeometry::isGeometry called, probably means someone tries to use SceneGraph with THREE-functions!')
    return false
  }
}

export class SceneGraphMesh extends SceneGraphNode3d {
  constructor () {
    super()
    this._material = undefined
    this._geometry = undefined
    this._featureFlags |= RenderScene.UPDATE_VISIBLE | RenderScene.UPDATE_CAST_SHADOW | RenderScene.UPDATE_RECEIVE_SHADOW
  }

  copyProperties (target) {
    super.copyProperties(target)
    target._geometry = this._geometry
    if (Array.isArray(this.material)) {
      target.material = this.material.slice(0)
    } else {
      target.material = this.material
    }
  }

  get material () { return this._material }
  set material (material) {
    if (material !== this._material) {
      this._material = material
      this._markDirty(RenderScene.UPDATE_MATERIAL)
    }
  }

  get geometry () { return this._geometry }

  set geometry (geometry) {
    if (!(geometry instanceof SceneGraphMeshGeometry)) {
      console.error('SceneGraphNodeMesh.setGeometry called with wrong type of geometry', this, geometry)
      return
    }
    if (this._geometry === undefined || geometry.uuid !== this._geometry.uuid) {
      this._markDirty(RenderScene.UPDATE_GEOMETRY)
    }
    this._geometry = geometry
  }

  _setWorldTransformDirtyNonPropagating () {
    // World transform for this node became dirty
    super._setWorldTransformDirtyNonPropagating()
    this._markDirty(RenderScene.UPDATE_TRANSFORM)
  }

  _createRenderAsset (owner) {
    super._createRenderAsset(owner)
    this._renderId = owner.renderScene.allocateAssetId(RenderScene.AssetTypeEnum.mesh, this.uuid)
    this._updateRenderAsset()
  }

  _updateRenderAsset () {
    const owner = this._ownerSceneGraph
    owner.meshChanges.set(this.uuid, this)
  }

  _removeRenderAsset () {
    const owner = this._ownerSceneGraph
    super._removeRenderAsset()
    owner.meshChanges.delete(this.uuid)
    owner.meshDeletes.push(this)
  }

  calculateVolume () {
    const volume = this._ownerSceneGraph.renderScene.calculateMeshVolume(this._matrixWorld, this._instancedMeshId)
    return volume
  }

  calculateArea () {
    const area = this._ownerSceneGraph.renderScene.calculateMeshArea(this._matrixWorld, this._instancedMeshId)
    return area
  }

  dispose () {
    if (this._ownerSceneGraph) {
      this._removeRenderAsset()
    }
    super.dispose()
    this._material = undefined
    this._geometry = undefined
    this._featureFlags = 0
  }

  get isMesh () { return true }
}

export class SceneGraphLight extends SceneGraphNode3d {
  constructor (color = undefined, intensity = undefined) {
    super()
    // NOTE: This weird construction is to support hex colors coming in. Then .g and .b should be undefined
    this._color = new ColorWrapper(() => { this._markDirty(RenderScene.UPDATE_COLOR) }, color !== undefined ? color : 1.0, undefined, undefined)
    this._intensity = (intensity !== undefined) ? intensity : 1.0
  }

  get color () { return this._color }
  get intensity () { return this._intensity }

  set intensity (v) {
    if (v !== this._intensity) {
      this._intensity = v
      this._markDirty(RenderScene.UPDATE_INTENSITY)
    }
  }

  _updateRenderAsset () {
    const owner = this._ownerSceneGraph
    owner.lightChanges.set(this.uuid, this)
  }

  _removeRenderAsset () {
    const owner = this._ownerSceneGraph
    super._removeRenderAsset()
    owner.lightChanges.delete(this.uuid)
    owner.lightDeletes.push(this._renderId)
    delete this._renderId
  }

  copyProperties (target) {
    super.copyProperties(target)
    target._color.copy(this.color)
    target._intensity = this.intensity
  }

  get isLight () { return true }
}

/*
  Light sources with target is rotated such that they look at the world space position of another node.
  If not specified then the matrixWorld specifies what is forward. RenderScene.lightForward shows convention for forward

  THREE has three target modes:
  * Look at world space (0,0,0)
    * Must reorient light when object (or parents move)
  * Look at world space position relative to some object (due to adding light.target as child in scene)
    * Must reorient if that parent moves, or we move
  * Look at another object in the scene hiarchy
    * Must reorient
  Only position of light and light.target is looked on, rotation and scale is ignored (but parents scales affect position)

  We represent this with a target object (optional) and a world space position that is relative to that target (object) or (0,0,0).
*/
class SceneGraphLightWithTarget extends SceneGraphLight {
  constructor (color = undefined, intensity = undefined) {
    super(color, intensity)
    // Target must part of same scene node hiarchy tree as this light.
    // When target is moved the rotation of this node will be updated to face that object
    // No rotation is supported on this node
    // If _target is null then -position of transform will be used as direction
    this._targetPosition = new Vector3Wrapper(() => { this._markDirty(RenderScene.UPDATE_TRANSFORM) }, 0, 0, 0)
    this._target = null
  }

  get matrixWorld () {
    return super.matrixWorld
  }

  get matrix () {
    const parentMatrix = this.parent.matrixWorld
    const forward = tempVector1
    // NOTE: We can't use our world space matrix since we are updating it!
    forward.setFromMatrixPosition(parentMatrix)
    forward.add(this.position) // NOTE: We assume no scale/rotation on our light source

    if (this._target !== null) {
      const targetNodePosition = tempVector2
      targetNodePosition.setFromMatrixPosition(this._target.matrixWorld)
      forward.sub(targetNodePosition)
    }
    forward.sub(this._targetPosition)
    forward.negate()
    forward.normalize()

    // Make the quaternion orient forward of light to the forward we want
    this.quaternion.setFromUnitVectors(RenderScene.lightForward, forward)

    // Now we can compose the matrix as usual
    return super.matrix
  }

  // Position is in world space unless target is set, then targetPosition is relative to position of target node
  get targetPosition () { return this._targetPosition }

  get target () {
    return this._target
  }

  dispose () {
    if (this._target) {
      this._target._removeMatrixWorldDirtyListener(this.uuid)
      this._target = null
    }
    super.dispose()
  }

  set target (v) {
    if (this._target === v) {
      return
    } else if (this._target !== null) {
      this._target._removeMatrixWorldDirtyListener(this.uuid)
    }
    this._target = v
    this._markDirty(RenderScene.UPDATE_TRANSFORM)
    if (v === null) {
      return
    }
    const scope = this
    v._addMatrixWorldDirtyListener(this.uuid, (node) => {
      // If matrixWorld changes for our target, our matrixWorld must be updated as well
      // When transform is dirty matrix will be called that will recalculate quaterion so that we face the target node
      scope._worldTransformDirty = true
      scope._localTransformDirty = true
      scope._markDirty(RenderScene.UPDATE_TRANSFORM)
    })
    // If this is a node that is never used otherwise it might never gets dirty marked (since it is already dirty)
    // Hence we ask the target for the proper world matrix once to get out of that
    const _ = v.matrixWorld // eslint-disable-line
  }

  copyProperties (target) {
    super.copyProperties(target)
    target._relativeTargetPosition.copy(this._relativeTargetPosition)
    // NOTE: We don't copy ._target. clone will hook it up anyway
  }
}

export class SceneGraphDirectionalLight extends SceneGraphLightWithTarget {
  constructor (color = undefined, intensity = undefined) {
    super(color, intensity)

    this.type = 'SceneGraphDirectionalLight'

    this._shadow = {
      bias: -0.0002,
      radius: 1,
      mapSizeX: 2048.0,
      mapSizeY: 2048.0,
      camera: {
        bottom: -20.0,
        left: -20.0,
        top: 20.0,
        right: 20.0
      }
    }
  }

  _createRenderAsset (owner) {
    super._createRenderAsset(owner)
    this._renderId = owner.renderScene.allocateAssetId(RenderScene.AssetTypeEnum.directional, this.uuid)
    this._updateRenderAsset()
  }

  get shadow () {
    return this._shadow
  }

  set shadow (value) {
    if (
      this.shadow.bias !== value.bias ||
      this.shadow.mapSizeX !== value.mapSizeX ||
      this.shadow.mapSizeY !== value.mapSizeY ||
      this.shadow.radius !== value.radius ||
      this.shadow.camera.bottom !== value.camera.bottom ||
      this.shadow.camera.left !== value.camera.left ||
      this.shadow.camera.top !== value.camera.top ||
      this.shadow.camera.right !== value.camera.right
    ) {
      this._shadow = value
      this._markDirty(RenderScene.UPDATE_CAST_SHADOW)
    }
  }

  get isDirectionalLight () { return true }
}

export class SceneGraphPointLight extends SceneGraphLight {
  constructor (color = undefined, intensity = undefined) {
    super(color, intensity)
    this.type = 'SceneGraphPointLight'
    this._distance = 0
    this._decay = 1
  }

  get distance () { return this._distance }
  get decay () { return this._decay }

  set distance (v) {
    this._distance = v
    this._markDirty(RenderScene.UPDATE_DISTANCE)
  }

  set decay (v) {
    this._decay = v
    this._markDirty(RenderScene.UPDATE_DECAY)
  }

  _createRenderAsset (owner) {
    super._createRenderAsset(owner)
    this._renderId = owner.renderScene.allocateAssetId(RenderScene.AssetTypeEnum.pointlight, this.uuid)
    this._updateRenderAsset()
  }

  get isPointLight () { return true }
}

export class SceneGraphSpotLight extends SceneGraphLightWithTarget {
  constructor (color = undefined, intensity = undefined) {
    super(color, intensity)
    this.type = 'SceneGraphSpotLight'
    this._distance = 0
    this._decay = 1
    this._angle = Math.PI / 3
    this._penumbra = 0
  }

  get distance () { return this._distance }
  get decay () { return this._decay }

  get angle () { return this._angle }
  get penumbra () { return this._penumbra }

  set distance (v) {
    this._distance = v
    this._markDirty(RenderScene.UPDATE_DISTANCE)
  }

  set decay (v) {
    this._decay = v
    this._markDirty(RenderScene.UPDATE_DECAY)
  }

  set angle (v) {
    this._angle = v
    this._markDirty(RenderScene.UPDATE_ANGLE)
  }

  set penumbra (v) {
    this._penumbra = v
    this._markDirty(RenderScene.UPDATE_PENUMBRA)
  }

  _createRenderAsset (owner) {
    super._createRenderAsset(owner)
    this._renderId = owner.renderScene.allocateAssetId(RenderScene.AssetTypeEnum.spotlight)
    this._updateRenderAsset()
  }

  get isSpotLight () { return true }
}

export class SceneGraph extends SceneGraphNode {
  constructor (app, assetManager, renderScene) {
    super()
    this.app = app
    this.assetManager = assetManager
    this.renderScene = renderScene
    this.objectTracker = ObjectTracker
    this.geometryMap = {}
    this.meshChanges = new Map()
    this.meshDeletes = [] // Containers renderId
    this.lightChanges = new Map()
    this.lightDeletes = [] // Containers renderId
    this._ownerSceneGraph = this // Root node mounted in scene graph!
  }

  dispose () {
    super.dispose()
    this.objectTracker.dispose()
    this.objectTracker = null
    this.meshChanges.clear()
    this.renderScene = null
    this.assetManager = null
    this.geometryMap = {}
    this.app = null
  }

  addModel (model, params) {
    const scope = this
    const app = this.app

    if (params.interactions) {
      this.objectTracker.addObject(model, params.interactions)
      model.localBoundingBox = this.app.viewerUtils.calcLocalBoundingBox(model)
    }

    if (params.addToNodeList) {
      app.objectTracker.addObject(model, { nodeList: { recursive: true } })
    }

    if (params.addToTransformGizmo) {
      app.objectTracker.addObject(model, { transformGizmo: { recursive: true } })
    }

    if (params.setToOrigo) {
      const boundingBoxCenter = new THREE.Vector3(0, 0, 0)
      model._contentBox.getCenter(boundingBoxCenter)
      model.position.set(model.position.x - boundingBoxCenter.x, model.position.y - boundingBoxCenter.y, model.position.z - boundingBoxCenter.z)
    }

    // Set features needed on meshes based on params
    model.traverseMeshes(function (mesh) {
      if (params.addToPicker) {
        // We can only pick meshes so lets not add anything else
        app.objectTracker.addObject(mesh, { picker: { recursive: false } })
      }

      if (params.localReflections) {
        mesh._setFeatureFlag(RenderScene.UPDATE_CAST_LOCAL_REFLECTION, true)
      }
      mesh._setFeatureFlag(RenderScene.UPDATE_IGNORE_RAYCAST, params.ignoreRaycast)
      mesh._setFeatureFlag(RenderScene.UPDATE_OUTLINE, false)
      mesh._setFeatureFlag(RenderScene.UPDATE_TRANSFORM_OUTLINE, false)
    })

    if (params.addToTriplanarTool && app.triplanarTool) {
      model.traverse(node => {
        const { isModelRoot, params } = node.userData
        if (isModelRoot && params.addToTriplanarTool) {
          node.traverseMeshes(mesh => { mesh._triplanarTool = true })
        }
      })
    }

    if (params.addToGeometryMap) {
      model.traverse((child) => {
        if (child.geometry) {
          var geometryList = scope.geometryMap[child.geometry.uuid] || []
          scope.geometryMap[child.geometry.uuid] = geometryList
          geometryList.push(child)
        }
      })
    }

    const addToScene = params.addToScene ?? true
    if (addToScene) {
      this.add(model)
    }
  }

  removeModel (model) {
    if (model.parent !== this) {
      console.error('SceneGraph.removeModel was called on a model that does not have the SceneGraph as the parent', model)
    }
    model.parent.remove(model)
    this.objectTracker && this.objectTracker.removeObject(model)
  }

  removeAll () {
    this.children.length = 0
    this.objectTracker.dispose()
    this.objectTracker = ObjectTracker
  }

  commitChanges () {
    const scope = this

    // Delete meshes
    this.meshDeletes.forEach(function (smesh) {
      scope.renderScene.removeMesh(smesh)
    })
    scope.meshDeletes.length = 0

    // Delete lights
    this.lightDeletes.forEach(function (renderId) {
      scope.renderScene.removeLight(renderId)
    })
    scope.lightDeletes.length = 0

    // Update lights
    if (this.lightChanges.size !== 0) {
      this.lightChanges.forEach((light, lightUUID, _) => {
        scope.renderScene.updateLight(
          light._renderId,
          light._changeFlags,
          light.matrixWorld,
          light._intensity,
          light._color,
          light._direction,
          light._distance,
          light._decay,
          light._penumbra,
          light._angle,
          light._featureFlags,
          light._shadow
        )
        light._changeFlags = 0
      })
      scope.lightChanges.clear()
    }

    // Update meshes
    if (this.meshChanges.size !== 0) {
      this.meshChanges.forEach((mesh, meshUUID, _) => {
        const key = mesh.geometry.uuid + '_' + mesh.material._hash
        if (mesh._instGroup !== key) {
          scope.renderScene.updateMeshGroups(mesh)
        }
      })

      this.meshChanges.forEach((mesh, meshUUID, _) => {
        // NOTE: We must use the mesh.matrixWorld-getter since world matrix is lazily generated
        const materialIds = Array.isArray(mesh.material) ? mesh.material.map(mat => mat._renderMaterialId) : mesh.material._renderMaterialId
        scope.renderScene.updateMesh(
          mesh._renderId,
          mesh._changeFlags,
          mesh.geometry.uuid,
          materialIds,
          mesh.matrixWorld,
          mesh._featureFlags,
          mesh._instGroup,
          mesh
        )
        mesh._changeFlags = 0
      })

      // Update instances that have a new material
      scope.renderScene.updateInstances()

      scope.meshChanges.clear()
      scope.renderScene.instanceMaterialUpdates = []
    }
  }
}

export function convertIntermediateSceneToSceneGraphNode (intermediateScene) {
  const targetFixupMap = new Map()
  const nodeMapping = new Map()

  function recursiveConvert (threeObject, parent) {
    let node
    let nodeIs3D = false
    if (threeObject instanceof THREE.Mesh) {
      node = new SceneGraphMesh()
      node.geometry = new SceneGraphMeshGeometry(threeObject.geometry.id, threeObject.geometry.boundingBox)
      node.material = threeObject.material
      node.receiveShadow = threeObject.receiveShadow
      nodeIs3D = true
    } else if (threeObject instanceof THREE.DirectionalLight) {
      node = new SceneGraphDirectionalLight(threeObject.color, threeObject.intensity)
      nodeIs3D = true
    } else if (threeObject instanceof THREE.SpotLight) {
      node = new SceneGraphSpotLight(threeObject.color, threeObject.intensity)
      node.decay = threeObject.decay
      node.distance = threeObject.distance
      node.angle = threeObject.angle
      node.penumbra = threeObject.penumbra
      nodeIs3D = true
    } else if (threeObject instanceof THREE.PointLight) {
      node = new SceneGraphPointLight(threeObject.color, threeObject.intensity)
      node.decay = threeObject.decay
      node.distance = threeObject.distance
      nodeIs3D = true
    } else if (threeObject instanceof THREE.Light) {
      console.warn('convertIntermediateSceneToSceneGraphNode; Unsupported light type')
    } else if (threeObject instanceof THREE.Object3D || threeObject instanceof THREE.Scene) {
      nodeIs3D = true
      // TOOD: Here we could create SceneGraphNode if we know that transform will never be touched... Maybe allow it to be configurated per SceneGraph (load)
      node = new SceneGraphNode3d()
    }

    // Fix target (or orientation) for light with a target
    if (threeObject instanceof THREE.DirectionalLight || threeObject instanceof THREE.SpotLight) {
      const light = threeObject
      if (light.target.parent === null) {
        // We have a fixed world space position, no need for a target object
        node.targetPosition.copy(light.target.position)
      } else {
        // NOTE: We can assume that the user never adds light.target to the scene but rather set light.target to an existing node
        // Make a not that we need to set the .target of this node to the node that had three three uuid light.target.uuid
        targetFixupMap.set(light.target.uuid, node)
      }
    }

    if (node) {
      node.castShadow = threeObject.castShadow

      if (nodeIs3D) {
        node.position.copy(threeObject.position)
        node.scale.copy(threeObject.scale)
        node.quaternion.copy(threeObject.quaternion)
      }

      if (threeObject.userData !== undefined) {
        node.userData = JSON.parse(JSON.stringify(threeObject.userData))
      }

      node.visible = threeObject.visible
      node.name = threeObject.name

      if (parent) {
        parent.add(node)
      }

      for (let i = 0; i < threeObject.children.length; i++) {
        recursiveConvert(threeObject.children[i], node)
      }

      nodeMapping.set(threeObject.uuid, node)
    }
    return node
  }

  const root = recursiveConvert(intermediateScene, undefined)

  // If there was a light source with a target we need to set it properly now that we know what node all three object maps to
  targetFixupMap.forEach((nodeWithTargetToPatch, threeUuid, map) => {
    if (nodeMapping.has(threeUuid)) {
      nodeWithTargetToPatch.target = nodeMapping.get(threeUuid)
    } else {
      console.warn('When converting three scene a light had a target that was not part of that three scene')
    }
  })

  root._contentBox = intermediateScene.contentBox
  const metaScene = { scene: root }
  return metaScene
}

export function loadModel (assetManager, url) {
  return assetManager.loadModel(url).then(
    intermediateScene => {
      return convertIntermediateSceneToSceneGraphNode(intermediateScene)
    },
    error => {
      console.error(error)
      throw error
    }
  )
}

export function loadMesh (assetManager, mesh) {
  assetManager.updateThreeModelToIntermediateFormat(mesh, mesh.uuid)
  return convertIntermediateSceneToSceneGraphNode(mesh)
}
