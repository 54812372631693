import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  outerClassName: PropTypes.string,
  innerClassName: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  overflow: PropTypes.oneOf(['auto', 'y', 'x', 'hidden'])
}

function getOverflowStyle (overflow) {
  if (overflow === 'hidden') return { overflowY: 'hidden', overflowX: 'hidden' }
  if (overflow === 'y') return { overflowY: 'auto' }
  if (overflow === 'x') return { overflowX: 'auto' }
  return { overflow: 'auto' }
}

function Scrollable (props) {
  const overflowStyle = React.useRef(getOverflowStyle(props.overflow))

  return (
    <div className={`${props.outerClassName} relative height-100 overflow-hidden`}>
      <div
        style={overflowStyle.current}
        className={`${props.innerClassName} absolute top-0 bottom-0 width-100`}
      >
        {props.children}
      </div>
    </div>
  )
}

Scrollable.defaultProps = {
  outerClassName: '',
  innerClassName: '',
  overflow: 'auto'
}

Scrollable.propTypes = propTypes

export default Scrollable
