export const tabs = {
  PREVIEW: 'preview',
  COMMENTS: 'comments',
  OVERVIEW: 'overview'
}

export const modes = {
  SELECT: 'select',
  SELECT_TO_PREVIEW: 'select to preview'
}
