import { createSelector } from 'reselect'

import _get from 'lodash/get'
import _curry from 'lodash/curry'

import * as fromVisualizerSelectors from '../../../components/visualizer/visualizer-selectors'
import * as fromReapplyAppearancesAndMaterials from './actions/reapply-appearances-and-materials'

const getLocalState = (state) => state.combinations
export const selectCombinationById = (id) => (state) => _get(getLocalState(state), `entries.${id}`, false)
export const getCurrentId = (state) => getLocalState(state).currentId

export const getEntries = (state) => _get(getLocalState(state), 'entries', {})

export const getEntryById = _curry((id, state) => _get(getEntries(state), id, {}))

export const getCurrentEntry = createSelector(
  state => getEntries(state),
  state => state.combinations.currentId,
  (entries, currentId) => _get(entries, currentId, {})
)

export const getCostEstimationsFromCurrentEntry = (state) => getCurrentEntry(state).costEstimations

export const getCanReapplyAppearancesAndMaterialsOnSelection = fromVisualizerSelectors.evaluateSelection(node => {
  return fromReapplyAppearancesAndMaterials.canReapplyAppearanceAndMaterial(node)
}, 'some')

/**
 * Determines if the reapply appearances/materials settings should be visible
 * Intended to be used with the load geometry modal.
 *
 * The settings are visible if
 * 1. geometry is being loaded in "replace" mode,
 * 2. the current selection is valid
 * 3. and the reapply feature can be applied to models in the curren tab
 */
export const getShowReapplyAppearancesAndMaterialsSettings = createSelector(
  getCanReapplyAppearancesAndMaterialsOnSelection,
  (_, mode, activeTab) => ({ mode, activeTab }), // these are external arguments, i.e not from the redux store
  (validSelection, { mode, activeTab }) => {
    return (
      mode === 'replace' &&
      validSelection &&
      fromReapplyAppearancesAndMaterials.ALLOWED_MODEL_TABS.includes(activeTab)
    )
  }
)
