import React from 'react'

class Image extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      failedToLoad: false
    }

    this.onRef = this.onRef.bind(this)
    this.onImageError = this.onImageError.bind(this)
  }

  onImageError (e) {
    this.props.onError(e)
  }

  onRef (ref) {
    this.element = ref
  }

  render () {
    const useFallback = this.props.isItOkToUseFallback()

    return (
      <img
        style={{
          width: this.props.alignToHeight ? 'auto' : '100%',
          height: this.props.alignToHeight ? '100%' : 'auto'
        }}
        ref={this.onRef}
        onError={this.onImageError}
        onEmptied={this.onImageError}
        src={useFallback ? this.props.fallback : this.props.src}
      />
    )
  }
}

export class TryMultipleImages extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      currentIndex: 0,
      triedAllImages: false
    }

    this.onImageError = this.onImageError.bind(this)
  }

  onImageError () {
    if (this.state.currentIndex < this.props.srcs.length) {
      this.setState({
        currentIndex: this.state.currentIndex + 1
      })
    } else {
      this.setState({
        triedAllImages: true
      })
    }
  }

  render () {
    return (
      <Image
        src={this.props.srcs[this.state.currentIndex]}
        fallback={this.props.fallback}
        onError={this.onImageError}
        alignToHeight={this.props.alignToHeight}
        isItOkToUseFallback={() => { return this.state.triedAllImages }}
      />
    )
  }
}
