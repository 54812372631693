import {
  Matrix4,
  Vector3,
  Quaternion,
  MathUtils,
  Mesh,
  SphereBufferGeometry,
  MeshBasicMaterial,
  Box3Helper,
  Object3D,
  Box3,
  PerspectiveCamera
} from 'three'
import { SceneGraphNode3d } from './SceneGraph'
import OffsetViewControls, { OffsetView } from '../plugins/OffsetViewControls'
import { EventEmitter } from 'events'
import ViewerUtils from '../viewer-utils'
import type { SceneGraphNode3d as TypeSceneGraphNode3d } from '../../types/SceneGraph'
import type { Go3DViewer } from '../../types/Go3DViewer'

type Matrix3x4 = [
  number, number, number, number,
  number, number, number, number,
  number, number, number, number
]

// TODO: Almost all calculations only work if the aspect ratio is 1:1
export default class ImageTemplateManager extends EventEmitter {
  private offsetViewControls: OffsetViewControls
  private app: Go3DViewer
  private viewerUtils: ViewerUtils
  private camera: PerspectiveCamera
  private debugEnabled = false
  private alignDebugger = new Mesh(new SphereBufferGeometry(0.015), new MeshBasicMaterial({ transparent: true, opacity: 0.5, color: 'purple' }))
  private box3Debugger = new Box3Helper(new Box3())
  private debugObjects = new Object3D()
  private _enabled = false
  private _autoCropEnabled = true
  private visibilityBackup = new Map()
  private positionAndRotationBackup = new Map()
  public targetPosition = new Vector3()
  public targetPlacement: [number, number, number] | null = null
  private target: null | { transform: Matrix3x4, id: string } = null

  private stagedMeshes: TypeSceneGraphNode3d[] = []
  public productRatioToFullSize = 1
  private rootModels: TypeSceneGraphNode3d[] = []
  private rotationGroupHelper = new SceneGraphNode3d()

  constructor (app: Go3DViewer) {
    super()
    this.app = app
    this.viewerUtils = app.viewerUtils
    this.camera = app.camera
    this.offsetViewControls = new OffsetViewControls(this.app)

    this.debugObjects.add(this.box3Debugger)
    this.debugObjects.add(this.alignDebugger)
    this.debugObjects.visible = false
    this.app.overlayScene.add(this.debugObjects)

    this.app.scene.add(this.rotationGroupHelper)

    // @ts-ignore - TransformGizmo uses inherits(TransformGizmo, EventEmitter) which typescript cannot pickup.
    this.app.transformGizmo.on('mouseUp', this.onTransformGizmoMouseUp)
    this.app.picker.on('change', this.onPickerChange)
    this.offsetViewControls.on('change', this.onOffsetViewChange)

    return this
  }

  onOffsetViewChange = (offsetView: OffsetView) => {
    this.emit('change', offsetView)
    const fovScale = this.app.cameraManager.calculateFovScale(this.app.cameraManager.desiredFov)
    this.app.transformGizmo.control.setSize(fovScale / this.offsetViewControls.view.zoom)
    this.app.transformGizmo.reattachObjects()
  }

  get autoCropEnabled () { return this._autoCropEnabled }
  set autoCropEnabled (value) { this._autoCropEnabled = value }

  get debug () { return this.debugEnabled }
  set debug (value) {
    if (value && this._enabled) {
      this.debugObjects.visible = true
    } else {
      this.debugObjects.visible = false
    }
    this.app.renderOnNextFrame()
    this.debugEnabled = value
  }

  get enabled () { return this._enabled }

  private savedGizmoMode: string | null = null
  private savedGizmoSpace: string | null = null
  private savedGizmoSize: number | null = null

  enable () {
    this._enabled = true
    if (this.debugEnabled) this.debugObjects.visible = true
    this.savedGizmoMode = this.savedGizmoMode || this.app.transformGizmo.getMode()
    this.savedGizmoSpace = this.savedGizmoSpace || this.app.transformGizmo.control.space
    this.savedGizmoSize = this.savedGizmoSize || this.app.transformGizmo.control.size
    this.app.transformGizmo.setMode('rotateY')
    this.app.transformGizmo.setSpace('world')
    return this
  }

  disable () {
    this._enabled = false
    if (this.debugEnabled) this.debugObjects.visible = false
    this.disableManualControls()
    this.app.transformGizmo.reattachObjects()
    this.app.transformGizmo.setMode(this.savedGizmoMode || 'snapping')
    this.app.transformGizmo.setSpace(this.savedGizmoSpace || 'world')
    this.app.transformGizmo.control.size = this.savedGizmoSize || 1
    if (this.app.cameraManager.camera) {
      // NOTE: Monkey path!
      // We might be disposing ImageTemplateManager after CameraManager, which sets camera to null.
      // Setting desiredFov emits 'fov-change' which updates the camera inside CameraManager.
      // If no camera exist on the object, we get a nasty error. Will need to rewrite CameraManager to TS to
      // make it easier to pick up on these kind of nasty things.
      this.app.cameraManager.desiredFov = this.camera.getEffectiveFOV()
    }
    this.savedGizmoMode = null
    this.savedGizmoSpace = null
    this.savedGizmoSize = null
    return this
  }

  dispose () {
    this.clear()
    this.disable()
  }

  clear () {
    this.offsetViewControls.clear()

    this.visibilityBackup.forEach((visible, node) => (node.visible = visible))

    this.positionAndRotationBackup.forEach(({ position, rotation }, node) => {
      node.position.copy(position)
      node.rotation.copy(rotation)
    })

    this.visibilityBackup.clear()
    this.positionAndRotationBackup.clear()
    this.stagedMeshes = []

    return this
  }

  setProductRatioToFullSize (value: number) {
    this.productRatioToFullSize = value
    return this
  }

  enableManualControls () {
    this.offsetViewControls.enableManual()
  }

  disableManualControls () {
    this.offsetViewControls.disableManual()
  }

  update (delta: number) {
    this.offsetViewControls.update(delta)
  }

  private onPickerChange = (selected: TypeSceneGraphNode3d[]) => {
    if (this._enabled && this._autoCropEnabled && this.stagedMeshes.length) {
      if (selected.length === 0) {
        this.cropToStagedOrSelected()
        this.app.transformGizmo.detach()

        this.app.scene.traverse((node: TypeSceneGraphNode3d) => {
          node.transformOutline = false
        })

        return
      }
      const validSelection = this.getValidSelection(selected)
      if (validSelection.length) this.cropViewOffsetToNodes(validSelection)
    }
  }

  private getValidSelection (selection: TypeSceneGraphNode3d[]) {
    const validSelection: TypeSceneGraphNode3d[] = []
    selection.forEach(node => {
      if (this.stagedMeshes.includes(node) && !validSelection.includes(node)) {
        validSelection.push(node)
      }
    })
    return validSelection
  }

  private onTransformGizmoMouseUp = () => {
    if (this._enabled && this._autoCropEnabled && this.stagedMeshes.length) {
      return this.cropViewOffsetToNodes(this.stagedMeshes)
    }
    return this
  }

  resetStagedProductsPositionAndRotation () {
    if (!this._enabled) return this

    this.positionAndRotationBackup.forEach(({ position, rotation }, node) => {
      node.position.copy(position)
      node.rotation.copy(rotation)
    })

    return this
  }

  rotateStagedProducts (deg: number) {
    const pivot = new Vector3()
    this.viewerUtils
      .getBoundingBox(this.rootModels)
      .getCenter(pivot)

    const rotationMatrix = new Matrix4()
    rotationMatrix.makeRotationY(MathUtils.DEG2RAD * deg)
    const deltaMatrix = new Matrix4()
    deltaMatrix.makeTranslation(-pivot.x, -pivot.y, -pivot.z)
    deltaMatrix.premultiply(rotationMatrix)

    const position = new Vector3()
    const rotation = new Quaternion()
    const scale = new Vector3()

    this.rootModels.forEach(rootModel => {
      const newMatrix = rootModel.matrix.clone()
      newMatrix.premultiply(deltaMatrix)
      newMatrix.decompose(position, rotation, scale)
      rootModel.rotation.setFromQuaternion(rotation)
      rootModel.position.copy(position.add(pivot))
      rootModel.scale.copy(scale)
    })
    return this
  }

  cropToStagedOrSelected () {
    if (!this._enabled) return this
    const validSelection = this.getValidSelection(Object.values(this.app.picker.selection))
    if (validSelection.length) {
      return this.cropViewOffsetToNodes(validSelection)
    }
    if (this.stagedMeshes.length) {
      return this.cropViewOffsetToNodes(this.stagedMeshes)
    }
    return this
  }

  stageProducts (rootModels: TypeSceneGraphNode3d[], shouldHide = (node: SceneGraphNode3d) => true) {
    this.app.scene.children.forEach((node: TypeSceneGraphNode3d) => {
      // Take care of rootModels separately
      if (shouldHide(node) && !node.userData.isModelRoot) {
        this.visibilityBackup.set(node, node.visible)
        node.visible = false
      }
    })

    const hiddenRootModels: TypeSceneGraphNode3d[] = []
    // Take care of rootModels separately
    this.app.scene.traverse((node: TypeSceneGraphNode3d) => {
      if (
        node.userData.isModelRoot &&
        !rootModels.includes(node) &&
        !hiddenRootModels.includes(node)
      ) {
        this.visibilityBackup.set(node, node.visible)
        hiddenRootModels.push(node)
        node.visible = false
      }
    })

    this.stagedMeshes = []
    this.rootModels = []
    rootModels.forEach(rootModel => {
      let hasVisibleMeshes = false

      rootModel.traverse((child: TypeSceneGraphNode3d) => {
        if (child.isMesh && child.visible && !this.stagedMeshes.includes(child)) {
          this.stagedMeshes.push(child)
          hasVisibleMeshes = true
        }
      })

      if (!this.rootModels.includes(rootModel) && hasVisibleMeshes) {
        this.rootModels.push(rootModel)
      }
    })

    rootModels.forEach(rootModel => {
      this.positionAndRotationBackup.set(rootModel, {
        position: rootModel.position.clone(),
        rotation: rootModel.rotation.clone()
      })
    })

    const bb = this.viewerUtils.getBoundingBox(this.rootModels.filter(m => m.visible))
    this.box3Debugger.box.copy(bb)

    return this
  }

  private moveNodesToTarget (nodes: TypeSceneGraphNode3d[]) {
    const placement = this.targetPlacement
    if (!placement) return

    const bb = this.viewerUtils.getBoundingBox(nodes.filter(m => m.visible))
    const center = new Vector3()
    bb.getCenter(center)

    if (placement[0]) center.x = placement[0] === 1 ? bb.max.x : bb.min.x
    if (placement[1]) center.y = placement[1] === 1 ? bb.max.y : bb.min.y
    if (placement[2]) center.z = placement[2] === 1 ? bb.max.z : bb.min.z

    const translation = new Vector3()
    translation.subVectors(this.targetPosition, center)

    this.rootModels.forEach(rootModel => {
      rootModel.position.add(translation)
    })

    const bbAfterTranslation = this.viewerUtils.getBoundingBox(nodes.filter(m => m.visible))
    this.box3Debugger.box.copy(bbAfterTranslation)
  }

  setupTargetAndPlacement (target: { transform: Matrix3x4, id: string }, placement: [number, number, number]) {
    this.target = target
    this.targetPlacement = placement
    const transform = target.transform

    const matrix = new Matrix4()
    matrix.set(
      transform[0], transform[3], transform[6], transform[9],
      transform[1], transform[4], transform[7], transform[10],
      transform[2], transform[5], transform[8], transform[11],
      0, 0, 0, 1
    )
    const rotation = new Quaternion()
    const scale = new Vector3()
    matrix.decompose(this.targetPosition, rotation, scale)
    this.alignDebugger.position.copy(this.targetPosition)
    return this
  }

  moveProductsToTarget () {
    this.moveNodesToTarget(this.stagedMeshes)
    return this
  }

  setOffsetView (horizontalOffset: number, verticalOffset: number, zoom: number) {
    this.offsetViewControls.setOffsetView(horizontalOffset, verticalOffset, zoom)
    return this
  }

  updateOffsetViewSize () {
    this.setOffsetView(this.offsetView.horizontalOffset, this.offsetView.verticalOffset, this.offsetView.zoom)
    return this
  }

  zoom (zoomLvl: number) { this.offsetViewControls.zoom(zoomLvl) }
  panStart (direction: 'up' | 'down' | 'left' | 'right') { this.offsetViewControls.panStart(direction) }
  panEnd (direction: 'up' | 'down' | 'left' | 'right') { this.offsetViewControls.panEnd(direction) }

  get offsetView () {
    return this.offsetViewControls.view
  }

  private cropViewOffsetToNodes (nodes: TypeSceneGraphNode3d[]) {
    if (this.target && this.targetPlacement) {
      this.moveNodesToTarget(nodes)
    }

    this.offsetViewControls.clear()

    const screenWidth = this.app.width
    const screenHeight = this.app.height
    const safeFrameWidth = this.app.postProcessManager.safeFrame.safeFrameWidth * 2
    const safeFrameHeight = this.app.postProcessManager.safeFrame.safeFrameHeight * 2
    const safeFrameAspect = safeFrameWidth / safeFrameHeight

    const box2d = this.viewerUtils.getProjectedBox2SLOW(
      nodes.filter(node => node.visible),
      screenWidth,
      screenHeight,
      this.camera
    )

    const sizeX = box2d.max.x - box2d.min.x
    const sizeY = box2d.max.y - box2d.min.y
    const centerX = box2d.min.x + sizeX * 0.5
    const centerY = box2d.min.y + sizeY * 0.5

    const boxAspect = sizeX / sizeY
    const horizontal = boxAspect >= safeFrameAspect

    const paddingX = safeFrameWidth * (1 - this.productRatioToFullSize)
    const paddingY = safeFrameHeight * (1 - this.productRatioToFullSize)

    const zoom = horizontal
      ? (safeFrameWidth - paddingX) / sizeX
      : (safeFrameHeight - paddingY) / sizeY

    const _x = 0.5
    const _y = 0.5

    const translateX = centerX - screenWidth * _x
    const translateY = centerY - screenHeight * _y

    this.offsetViewControls.updateViewOffset(translateX, translateY, zoom)
    this.app.renderOnNextFrame()
    return this
  }
}
