import { AppThunk } from '..'
import { SceneGraphMesh as ISceneGraphMesh, SceneGraphNode3d as ISceneGraphNode3d } from '../../../../../go3dthree/types/SceneGraph'
import { updateNode } from '../tree'
import { deselectAfterRemove, SHOW } from '../selection'
import { v4 as uuidv4 } from 'uuid'
import { getNodeList } from './selectors'
import * as undoRedo from '../undo-redo'
import { forceUpdate } from './ui'
import * as api from './api'

// [x+ x- y+ y- z+ z-]
const cubeSplit: [number, number, number, number, number, number] = [0, 1, 2, 3, 4, 5]
const cylinderSplit: [number, number, number, number, number, number] = [0, 0, 2, 3, 0, 0]

export function mergeMesh (uuid: string): AppThunk {
  return (dispatch, getState) => {
    const nodeList = getNodeList(getState())
    let splitNode = nodeList[uuid] as ISceneGraphNode3d
    if (!splitNode) return
    if (splitNode.isMesh) splitNode = splitNode.parent
    const parent = splitNode.parent
    const originalMesh = parent.children.find((child: ISceneGraphNode3d) => child.isMesh)

    if (!parent) return

    const command = {
      undo: () => {
        splitNode.visible = true
        splitNode.userData.visible = true
        splitNode.userData.ignore = false
        dispatch(deselectAfterRemove(originalMesh))
        originalMesh.visible = false
        originalMesh.userData.visible = false
        originalMesh.userData.ignore = true
        originalMesh.userData.overriddenBy = splitNode.uuid
        dispatch(updateNode(parent))
        dispatch(forceUpdate())
      },
      execute: () => {
        originalMesh.visible = true
        originalMesh.userData.visible = true
        originalMesh.userData.overriddenBy = null
        originalMesh.userData.ignore = false
        // @ts-ignore
        dispatch({ type: SHOW, uuids: [originalMesh.uuid] })
        dispatch(deselectAfterRemove(splitNode))
        splitNode.visible = false
        splitNode.userData.visible = false
        splitNode.userData.ignore = true
        dispatch(updateNode(parent))
        dispatch(forceUpdate())
      }
    }

    // @ts-ignore
    dispatch(undoRedo.addCommand(command))
    command.execute()
  }
}

function findMesh (node: ISceneGraphNode3d) {
  let found: ISceneGraphMesh | undefined
  node.traverse((child: any) => { if (child.isMesh) found = child })
  return found
}

export function preview (type: 'cube' | 'cylinder' | null, uuid?: string): AppThunk {
  return async (dispatch, getState) => {
    const viewer = getState().threeviewer.viewer
    if (!viewer) return
    const node = (getNodeList(getState())[uuid || ''] || Object.values(viewer.picker.selection)[0]) as ISceneGraphNode3d | undefined
    if (!node) return
    const sourceMesh = findMesh(node)
    const splitTool = viewer.splitTool
    if (type === null) {
      splitTool.stopPreview()
    } else {
      splitTool.preview(type === 'cube' ? cubeSplit : cylinderSplit, sourceMesh)
    }
  }
}

export const splitFace =
(
  uuid: string
): AppThunk<Promise<string | undefined>> => async (dispatch, getState) => {
  const nodeList = getNodeList(getState())
  const dbModelId = nodeList[uuid].userData.dbModelId
  const brepDataKey = await api.getSplitFaces(dbModelId)
  const viewer = getState().threeviewer.viewer
  if (!viewer) return undefined
  const splitTool = viewer.splitTool
  const node = (getNodeList(getState())[uuid || ''] || Object.values(viewer.picker.selection)[0]) as ISceneGraphNode3d | undefined
  if (!node) return undefined

  const sourceMesh = findMesh(node)

  if (sourceMesh && splitTool) {
    splitTool.stopPreview()

    const rootNode = viewer.viewerUtils.findRootNode(sourceMesh)
    const splitGroups = cubeSplit

    // Get the brep json data from that mesh
    const res = await fetch(`/api/storage/get/${brepDataKey}`)
    const data = await res.json()
    const nodeId = sourceMesh.userData.nodeId
    let nodeKey
    await data.files.forEach((file: any) => {
      if (file.name.includes(nodeId)) {
        nodeKey = file.key
      }
    })
    const res2 = await fetch(`/api/storage/get/${nodeKey}`)
    const jsonData = await res2.json()

    const models = splitTool.split(sourceMesh, splitGroups, jsonData)
    const parent = sourceMesh.parent
    const splitNode = new viewer.SceneGraph.SceneGraphNode3d()

    splitNode.name = `Unlocked mesh - ${sourceMesh.name}`
    splitNode.userData = {
      ...splitNode.userData,
      ...sourceMesh.userData,
      name: splitNode.name,
      nodeId: `brep_${sourceMesh.userData.nodeId}`,
      override: {
        nodeId: sourceMesh.userData.nodeId,
        dbModelId: rootNode.userData.dbModelId,
        combinationModelInternalId: rootNode.uuid,
      },
      dbModelId: uuidv4(),
      generated: true
    } as { [key: string]: any }
    models.forEach((model, index) => {
      model.scene.traverse((child: ISceneGraphMesh) => {
        if (child.isMesh) {
          child.userData.Splitted = true
          child.material = sourceMesh.material
        }
      })

      const nodeId = `${uuidv4().replace(/-/g, '_')}`
      model.scene.name = model.scene.name ? model.scene.name : nodeId

      model.scene.userData = {
        ...model.scene.userData,
        ...sourceMesh.userData,
        name: `${index} ${sourceMesh.userData.name || sourceMesh.name}`,
        generated: true,
        nodeId: model.scene.name,
        overriddenNodeId: sourceMesh.userData.nodeId
      }
      splitNode.add(model.scene)
    })

    if (parent) {
      if (parent === viewer.scene) {
        const matrixWorld = sourceMesh.matrixWorld
        splitNode.applyMatrix(matrixWorld)
      }

      if (!parent) return undefined

      viewer.addModel(splitNode, {
        addToScene: false,
        ...rootNode.userData.params
      })

      parent.add(splitNode)

      const command = {
        undo: () => {
          sourceMesh.visible = true
          sourceMesh.userData.visible = true
          sourceMesh.userData.overriddenBy = null
          sourceMesh.userData.ignore = false
          dispatch(deselectAfterRemove(splitNode))
          splitNode.visible = false
          // @ts-ignore
          splitNode.userData.visible = false
          // @ts-ignore
          splitNode.userData.ignore = true
          dispatch(updateNode(parent))
          dispatch(forceUpdate())
        },
        execute: () => {
          splitNode.visible = true
          // @ts-ignore
          splitNode.userData.visible = true
          // @ts-ignore
          splitNode.userData.ignore = false
          dispatch(deselectAfterRemove(sourceMesh))
          sourceMesh.visible = false
          sourceMesh.userData.visible = false
          sourceMesh.userData.ignore = true
          sourceMesh.userData.overriddenBy = splitNode.uuid
          dispatch(updateNode(parent))
          dispatch(forceUpdate())
        }
      }

      // @ts-ignore
      dispatch(undoRedo.addCommand(command))
      command.execute()
    }
    return splitNode.uuid
  }
  return undefined
}

export function split (type: 'cube' | 'cylinder', uuid?: string): AppThunk {
  return async (dispatch, getState) => {
    const viewer = getState().threeviewer.viewer
    if (!viewer) return

    const splitTool = viewer.splitTool
    const node = (getNodeList(getState())[uuid || ''] || Object.values(viewer.picker.selection)[0]) as ISceneGraphNode3d | undefined
    if (!node) return
    const sourceMesh = findMesh(node)

    if (sourceMesh && splitTool) {
      splitTool.stopPreview()

      const rootNode = viewer.viewerUtils.findRootNode(sourceMesh)
      const splitGroups = type === 'cube' ? cubeSplit : cylinderSplit
      const models = splitTool.split(sourceMesh, splitGroups)
      const parent = sourceMesh.parent
      const splitNode = new viewer.SceneGraph.SceneGraphNode3d()

      splitNode.name = `Split ${sourceMesh.name}`
      splitNode.userData = {
        ...splitNode.userData,
        ...sourceMesh.userData,
        name: splitNode.name,
        nodeId: `split_${sourceMesh.userData.nodeId}`,
        override: {
          nodeId: sourceMesh.userData.nodeId,
          dbModelId: rootNode.userData.dbModelId,
          combinationModelInternalId: rootNode.uuid,
        },
        dbModelId: uuidv4(),
        generated: true
      } as { [key: string]: any }
      models.forEach((model, index) => {
        model.scene.traverse((child: ISceneGraphMesh) => {
          if (child.isMesh) {
            child.userData.splitted = true
            child.material = sourceMesh.material
          }
        })

        const nodeId = `${uuidv4().replace(/-/g, '_')}`
        model.scene.name = model.scene.name ? model.scene.name : nodeId

        model.scene.userData = {
          ...model.scene.userData,
          ...sourceMesh.userData,
          name: `${index} ${sourceMesh.userData.name || sourceMesh.name}`,
          generated: true,
          nodeId: model.scene.name,
          overriddenNodeId: sourceMesh.userData.nodeId
        }
        splitNode.add(model.scene)
      })

      if (parent) {
        if (parent === viewer.scene) {
          const matrixWorld = sourceMesh.matrixWorld
          splitNode.applyMatrix(matrixWorld)
        }

        if (!parent) return

        viewer.addModel(splitNode, {
          addToScene: false,
          ...rootNode.userData.params
        })

        parent.add(splitNode)

        const command = {
          undo: () => {
            sourceMesh.visible = true
            sourceMesh.userData.visible = true
            sourceMesh.userData.overriddenBy = null
            sourceMesh.userData.ignore = false
            dispatch(deselectAfterRemove(splitNode))
            splitNode.visible = false
            // @ts-ignore
            splitNode.userData.visible = false
            // @ts-ignore
            splitNode.userData.ignore = true
            dispatch(updateNode(parent))
            dispatch(forceUpdate())
          },
          execute: () => {
            splitNode.visible = true
            // @ts-ignore
            splitNode.userData.visible = true
            // @ts-ignore
            splitNode.userData.ignore = false
            dispatch(deselectAfterRemove(sourceMesh))
            sourceMesh.visible = false
            sourceMesh.userData.visible = false
            sourceMesh.userData.ignore = true
            sourceMesh.userData.overriddenBy = splitNode.uuid
            dispatch(updateNode(parent))
            dispatch(forceUpdate())
          }
        }

        // @ts-ignore
        dispatch(undoRedo.addCommand(command))
        command.execute()
      }
    }
  }
}
