import React, { MouseEvent, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import cs from 'classnames'
import { ImmutableObject } from 'seamless-immutable'

import _last from 'lodash/last'

import Label from '../../common/form/label'
import InputFile from '../../common/form/input-file'
import Button from '../../common/button'
import Grid from '../../common/grid/grid'
import InputText from '../../common/form/input-text'
import KeyboardListener, { KeyCode, KeyBinding } from '../../common/keyboard-listener'
import Badge from '../../common/Badge'

import * as fromRoomsetsAdmin from '../../../stores/ducks/roomsets-admin'
import * as fromRoomsets from '../../../stores/ducks/roomsets'
import { Roomset } from '../../../stores/ducks/roomsets/Roomset'

import { Files } from '../admin-roomsets/files'
import UploadProgress from '../admin-roomsets/upload-progress'
import DeadlineProgress from '../admin-roomsets/deadline-progress'
import { AdditionalInformation } from './additionalInformation'
import { StorageApiFile } from '../../../utils'

import * as fromAdminRoomsetsSelectors from '../../../stores/ducks/roomsets-admin/selectors'
import * as fromUploadsSelectors from '../../../stores/ducks/uploads/selectors'

function Section (props: React.HTMLAttributes<HTMLElement> & { small?: boolean }) {
  return (
    <section className={cs('border-bottom bc-gray-light border-last-none', {
      'pb3 mb2': !props.small,
      'pb2 mb2': props.small,
      '': props.small === undefined
    })}>
      {props.children}
    </section>
  )
}

type LightSetupProps = {
  setup: Roomset,
  jobStatuses: { status: 'Completed' | 'Failed', createdAt: string }[]
  upload: any
  onUpload: (files: FileList) => void
  onDelete: () => void
  onStartConvert: () => void
  onUpdate: (payload: Roomset) => void
  convertDisabled: boolean
  addImageLightSetup: (roomset: Roomset, files: FileList) => void
  deleteImageLightSetup: (roomset: Roomset, key: string) => void
}

function LightSetup (props: LightSetupProps) {
  const domNode = React.useRef(null)
  const [title, setTitle] = React.useState(props.setup.title)
  const [infoText, setInfoText] = React.useState<string>(props.setup?.metadata?.infoText || '')
  const [imageFiles, setImageFiles] = React.useState<StorageApiFile[] | []>(props.setup?.metadata?.imageFiles || [])
  const [editing, setEditing] = React.useState(false)
  const uploadDone = props.upload && props.upload.extractStatus === 'EXTRACT_DONE'
  const completedConvert = props.jobStatuses.length && props.jobStatuses.length === props.jobStatuses.filter(job => job.status === 'Completed').length
  const uploadConverted = props.jobStatuses.length && uploadDone && completedConvert && _last(props.jobStatuses)!.createdAt > props?.upload.createdAt

  useEffect(() => {
    setImageFiles(props.setup.metadata?.imageFiles || [])
  }, [props.setup.metadata])

  const handleEnterKey = async () => {
    if (editing && title && title.trim().length) {
      await props.onUpdate({ id: props.setup.id!, title, metadata: { infoText, imageFiles } })
    }
    setEditing(false)
  }

  return (
    <>
      <div className='border bc-gray-light mb3 px1 pt1' ref={domNode}>
        <Section small>
          <Grid columns={['50%', '50%']}>
            <div>
              <Label>
                <span>Title</span>
              </Label>
              {!editing && (<div>{props.setup.title}</div>)}
              {editing && (
                <InputText
                  value={title}
                  onChange={(title: string) => setTitle(title)}
                />
              )}
            </div>

            <div className='flex justify-end items-start pr2'>
              <Button
                size='small'
                className='mr1'
                disabled={!editing}
                onClick={(event: MouseEvent) => {
                  event.preventDefault()
                  setEditing(false)
                }}
              >
                Cancel
              </Button>
              <Button
                size='small'
                className='mr1'
                onClick={async (event: MouseEvent) => {
                  event.preventDefault()
                  if (editing) {
                    if (title && title.trim().length) {
                      await props.onUpdate({ id: props.setup.id!, title, metadata: { infoText, imageFiles } })
                    }
                    setEditing(false)
                  } else {
                    setEditing(true)
                  }
                }}
              >
                {editing ? 'Save' : 'Edit'}
              </Button>
              <Button
                size='small'
                onClick={(event: MouseEvent) => {
                  event.preventDefault()
                  props.onDelete()
                }}
              >
                Delete
              </Button>
            </div>
          </Grid>
        </Section>

        <Section small>
          <Label>
            <span>File</span>
            {!!uploadConverted && (<Badge className='ml2 c-success inline-block'>Converted</Badge>)}
          </Label>
          <div className='mt2 f7 c-gray-accessible'>Supported formats <span className='bold'>.max .zip .tar.gz</span>. Zip must contain one <span className='bold'>.max</span> file and a <span className='bold'>.hdr</span> file.</div>
          {(uploadDone && !completedConvert) && (
            <div className='f7 mt1 c-gray-accessible'>A conversion typically takes a couple of minutes (depending on the size and complexity of the scene).</div>
          )}
          <div className='mt2 flex items-end'>
            <InputFile
              style={{ margin: 0, minHeight: 'initial' }}
              size='small'
              accept='application/gzip,application/zip'
              onChange={props.onUpload}
            >
              Replace file
            </InputFile>
            <Button
              size='small'
              className='ml1'
              btnType='primary'
              disabled={props.convertDisabled}
              onClick={(event: MouseEvent) => {
                event.preventDefault()
                props.onStartConvert()
              }}
            >
              Start convert
            </Button>
          </div>
          <div className='f7 mt2'>
            <Files
              uploadFile={props?.upload?.filename}
              files={props?.upload?.manifest?.files}
            />
            {!uploadDone && (<UploadProgress uploadProgress={props?.upload} />)}
          </div>

          {(uploadDone && !completedConvert) && (
            <div className='mt2'>
              <DeadlineProgress jobStatuses={props.jobStatuses} />
            </div>
          )}
        </Section>

        <Section small>
          <Label>Roomset id</Label>
          {props.setup.id}
        </Section>

        <Section>
          <AdditionalInformation
            label={'Light setup information'}
            uploadImage={(files) => props.addImageLightSetup({ id: props.setup.id!, title, metadata: { infoText, imageFiles } }, files)}
            removeImage={(file) => props.deleteImageLightSetup({ id: props.setup.id!, title, metadata: { infoText, imageFiles } }, file.key)}
            uploadText={setInfoText}
            images={imageFiles}
            text={infoText}
            editing={editing}
          />
        </Section>
      </div>

      {domNode.current && (
        <KeyboardListener
          eventTarget={domNode.current}
          keyboardEvent='keyup'
          bindings={[
            KeyBinding(KeyCode.enter, handleEnterKey)
          ]}
        />
      )}
    </>
  )
}

type Props = {
  active: boolean
  setups: ImmutableObject<Roomset>[]
  uploadImage?: any
}

function TabLightSetups (props: Props) {
  const dispatch = useDispatch()
  const currentId = useSelector(fromAdminRoomsetsSelectors.selectCurrentId)
  const getDeadlineJobsForRoomset = useSelector(fromAdminRoomsetsSelectors.selectGetDeadlineJobsForRoomset)
  const editingRoomset = useSelector(fromAdminRoomsetsSelectors.selectEditingRoomset)
  const canStartSetupDeadlineJob = useSelector(fromAdminRoomsetsSelectors.selectCanStartSetupDeadlineJob)

  const uploadEntries = useSelector(fromUploadsSelectors.getEntries)
  const getSetupUpload = (uploadId: string) => uploadEntries.getIn([uploadId])

  const [disableStartDeadlineJobButton, setDisableStartDeadlineJobButton] = React.useState<{ [key: string]: boolean }>({})
  const [deadlineTimeout, setDeadlineTimeout] = React.useState<{ [key: string]: number | undefined }>({})
  const countSetups = Object.keys(props.setups || {}).length

  React.useEffect(() => {
    return () => {
      if (Object.keys(deadlineTimeout).length) {
        Object.values(deadlineTimeout).forEach(timeout => {
          clearTimeout(timeout)
        })
      }
    }
  }, [])

  const handleStartSetupConvert = async (setup: Roomset) => {
    if (!currentId) return
    setDisableStartDeadlineJobButton({ [setup.id!]: true })
    const timeout = setTimeout(() => setDisableStartDeadlineJobButton({ [setup.id!]: false }), 4000)
    setDeadlineTimeout({ [setup.id!]: timeout })
    dispatch(fromRoomsetsAdmin.startRoomsetDeadlineJob(setup.id!))
  }

  const handleUploadSetupMaxFile = async (files: FileList, type: Roomset['type'], id?: string) => {
    if (!currentId) return
    if (files && files.length) {
      const result: any = await dispatch(fromRoomsetsAdmin.createOrUpdate(editingRoomset))
      dispatch(fromRoomsetsAdmin.uploadSetupFile(files, result.id, type, id))
    }
  }

  if (!props.active) return null

  return (
    <>
      <Section>
        <div className='my2 f7 c-gray-accessible'>Supported formats <span className='bold'>.max .zip .tar.gz</span>. Zip must contain one <span className='bold'>.max</span> file and a <span className='bold'>.hdr</span> file.</div>
        <InputFile
          style={{ margin: 0, minHeight: 'initial' }}
          disabled={!currentId}
          type='primary'
          accept='application/gzip,application/zip'
          onChange={(files: FileList) => handleUploadSetupMaxFile(files, 'light-setup')}
        >
          Add new light setup
        </InputFile>
      </Section>

      {countSetups > 0 && (
        <Section>
          <Label>
            <span>Light setups</span>
          </Label>
          <div className='f7 mt2'>
            {Object.values(props.setups).map((setup) => (
              <LightSetup
                key={setup.id!}
                setup={setup as Roomset}
                upload={getSetupUpload(setup.uploadId!)}
                convertDisabled={
                  !currentId ||
                  disableStartDeadlineJobButton[setup.id!] ||
                  !canStartSetupDeadlineJob(setup.id!)
                }
                jobStatuses={getDeadlineJobsForRoomset(setup.id!)}
                onUpdate={(payload) => dispatch(fromRoomsetsAdmin.createOrUpdate(payload, false))}
                onUpload={(files) => handleUploadSetupMaxFile(files, 'light-setup', setup.id!)}
                onDelete={() => dispatch(fromRoomsets.remove(setup.id!))}
                onStartConvert={() => handleStartSetupConvert(setup as Roomset)}
                addImageLightSetup={dispatch(fromRoomsetsAdmin.addImageLightSetup)}
                deleteImageLightSetup={dispatch(fromRoomsetsAdmin.deleteImageLightSetup)}
              />
            ))}
          </div>
        </Section>
      )}
    </>
  )
}

export default TabLightSetups
