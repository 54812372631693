import React from 'react'
import { FaAngleDown, FaAngleUp } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { AutoSizer, List, ListRowProps, ScrollParams } from 'react-virtualized'
import Immutable from 'seamless-immutable'

import * as fromTree from '../../../../../stores/ducks/tree'
import * as fromTreeSelectors from '../../../../../stores/ducks/tree/selectors'

import { ITreeNodes, ITreeNode } from '../../../../../stores/ducks/tree/TreeNode'

import { ScrollArea } from '../../../../common/DndScrollable'
import { ActionOverlayMenu } from '../action-overlay/ActionOverlayMenu'

import BocModal from '../plm/BocModal'
import UnlockVirtualProductModal from '../virtual-products/UnlockVirtualProductModal'
import { flattenVisibleTreeNodes } from '../utils/helpers'

import { GeometryNode } from './geometry-node'

const SCROLL_AREA_SIZE = 22

// The height of a single row in a react-virtualized list has to be set explicitly
const NODE_HEIGHT = 33

type Props = {
  filteredNodesUuids: string[],
  active: boolean // If the geometry panel is visible or not
}

/**
 * Component responsible for only rendering the actual geometry tree (not the entire panel)
 * The responsibility is separated from the entire panel to allow for more effective rendering
 * and state management.
 */
const GeometryTree = React.memo((props: Props) => {
  // Refs for keeping track of scroll areas and scroll containers
  const scrollContainerRef = React.useRef<HTMLDivElement>(null)
  const topScrollAreaRef = React.useRef<HTMLDivElement>(null)
  const bottomScrollAreaRef = React.useRef<HTMLDivElement>(null)

  // Ref for keeping track of current scroll position
  // Is used to determine which scroll areas are currently visible
  const scrollTopRef = React.useRef<number>(0)

  const nodes: ITreeNodes = useSelector(fromTreeSelectors.getNodes)

  const [bocModalOpen, setBocModalOpen] = React.useState<boolean>(false)
  const [unlockVirtualProductModalOpen, setUnlockVirtualProductModalOpen] = React.useState(false)
  const [virtualProductUuidToUnlock, setVirtualProductUuidToUnlock] = React.useState<string | null>(null)

  // Data used to keep track of the action overlay and it's associated node
  // Stores the index of the node and a reference to the trigger button.
  // This reference is required to determine when the user is clicking inside our outside the modal area
  const [actionOverlayNodeData, setActionNodeOverlayData] = React.useState<{
    index: number,
    ref: React.MutableRefObject<any>
  } | null>(null)

  const openedNodeUuids = useSelector(fromTreeSelectors.getOpenedNodeUuids)
  const nodeUuidToScrollTo = useSelector(fromTreeSelectors.getScrollToNode)
  const isDragging = useSelector(fromTreeSelectors.getIsDragging)

  // A dummy value that changes when the flattened nodes should be recalculated
  const updateFlatNodes = useSelector(fromTreeSelectors.getUpdateFlatNodes)

  const dispatch = useDispatch()

  /**
   * Hook responsible for telling redux to recalculate the flattened nodes, using the
   * getUpdateFlatNodes selector. This pattern is a bit awkward, but better than explicitly
   * updating the flat nodes whenever update is needed. This lets the tree manage this on its own.
   */
  React.useEffect(() => {
    dispatch(fromTree.updateFlatNodes())
  }, [updateFlatNodes])

  const toggleBocModal = React.useCallback(
    (open: boolean) => setBocModalOpen(open),
    []
  )

  const toggleUnlockVirtualProductModalOpen = React.useCallback((open: boolean, uuid?: string) => {
    setUnlockVirtualProductModalOpen(open)
    if (uuid) setVirtualProductUuidToUnlock(uuid)
  }, [])

  /**
   * Updates the current scroll position and sets the visibility of the scroll areas
   */
  const handleScroll = ({ scrollTop }: ScrollParams) => {
    scrollTopRef.current = scrollTop
    if (isDragging) setScrollAreaVisibility()
  }

  /**
   * Calculates which scroll areas should be visible.
   * Scroll areas are only visible while dragging a node.
   *
   * If the entire tree is visible in the viewport, no scroll areas are visible.
   * If scrolled to the top, only the bottom scroll area is visible.
   * If scrolled to the bottom, only the top scroll area is visible.
   * If somewhere in between, both scroll areas will be visible
   *
   * NOTE: Much of this functionality is copied from the DndScrollable component,
   * which is not compatible with react-virtualized.
   */
  const setScrollAreaVisibility = () => {
    const scrollHeight = allNodes.length * NODE_HEIGHT
    if (!scrollContainerRef.current) return

    if (topScrollAreaRef.current) {
      const topVisibility = isDragging &&
        scrollTopRef.current > 0 ? 'visible' : 'hidden'

      topScrollAreaRef.current.style.visibility = topVisibility
    }

    if (bottomScrollAreaRef.current) {
      const bottomVisibility = isDragging &&
        // A buffer of 1.0 scroll units is added here, since react-virtualized does not always
        // set the height of the scroll container accurately
        (scrollTopRef.current + scrollContainerRef.current?.clientHeight) < (scrollHeight - 1.0) ? 'visible' : 'hidden'

      bottomScrollAreaRef.current.style.visibility = bottomVisibility
    }
  }

  /**
   * Hook for setting the visiblity of the scroll areas immediately when dragging starts
   * This ensures that they become visible even when the user hasn't started scrolling
   *
   * The hook also adds listeners to abort dragging if no nodes pick up the dragEnd or drop events
   * This might happen if the user drops a dragged node within a few milliseconds after scrolling.
   * For some reason, react-virtualized block events right after scroll. This hook takes care of that
   * edge case.
   */
  React.useEffect(() => {
    setScrollAreaVisibility()

    const dragOverHandler = (e: DragEvent) => e.preventDefault()
    const dragEndHandler = (e: any) => {
      e.preventDefault()

      // Dragging is canceled after a 100ms delay. This ensures that
      // the normal drag-drop behavior works as intended.
      // If isDragging is set to false instantly, grouping rouping two nodes
      // together might fail, for example.
      setTimeout(() => {
        dispatch(fromTree.updateIsDragging(false))
      }, 100)
    }

    // Only add drag cancel listeners if dragging is active
    // Listeners are added to the body of the document, to ensure
    // that they can be registered even if the user moves the pointer
    // away from the tree.
    if (isDragging) {
      // This listeners ensures that drop events can be registered
      document.body.addEventListener('dragover', dragOverHandler)

      // Sometimes, mouseup event is triggered instead of a drop event.
      // Therefore, a listener for mouseup is registered as well.
      // A mousemove listener is also added as a final backup.
      document.body.addEventListener('drop', dragEndHandler, { once: true })
      document.body.addEventListener('mouseup', dragEndHandler, { once: true })
      document.body.addEventListener('mousemove', dragEndHandler, { once: true })
    }

    return () => {
      if (isDragging) document.body.removeEventListener('dragover', dragOverHandler)
    }
  }, [isDragging])

  // Memo for all visible nodes, and a lookup table for uuid to list index
  // NOTE: Could possibly be optimized by partially rebuilding the list when changes occur, instead of rebuilding entire list
  // NOTE: However, building the list is very fast, even for large scenes, since nothing is rendered or mounted to the DOM
  const { allNodes, uuidToIndex } = React.useMemo(
    () => {
      // A flat list of all visible tree nodes (i.e, nodes that match the search term and the filters)
      // NOTE: This is not the same as the flattenedNodes, that contain ALL nodes, not just the visible ones
      let allNodes: ITreeNode[] = []
      // The lookup table for node uuid to index in the list
      // This is used to automatically scroll to a specific node in the list
      const uuidToIndex: {[uuid: string]: number} = {}

      // Creates the flat list of visible tree nodes (allNodes) and the lookup table (uuidToIndex)
      Object.values(nodes).forEach(node => {
        flattenVisibleTreeNodes(
          node,
          props.filteredNodesUuids,
          Immutable.asMutable(openedNodeUuids),
          allNodes,
          uuidToIndex,
        )
      })

      // Filtering out all the unlocked brep meshes.
      allNodes = allNodes.filter(node => {
        if (node.metaData.generated && node.metaData.type === 'mesh') return null
        return node
      })

      return { allNodes, uuidToIndex }
    },
    [nodes, props.filteredNodesUuids, openedNodeUuids]
  )

  /**
   * Function for rendering a single row (node) in the tree
   * This is the function used by react-virtualied to determine what to render
   */
  const rowRenderer = ({ key, index, style }: ListRowProps) => {
    const node = allNodes[index]

    return (
      <div
        key={key}
        style={style}
      >
        <GeometryNode
          key={node.uuid}
          uuid={node.uuid}
          index={index}
          node={node}
          metaData={node.metaData}
          filteredNodesUuids={props.filteredNodesUuids}
          toggleBocModal={toggleBocModal}
          toggleUnlockVirtualProductModalOpen={toggleUnlockVirtualProductModalOpen}
          handleActionOverlayShouldOpen={(index, nodeRef) => setActionNodeOverlayData({ index, ref: nodeRef })}
          handleActionOverlayShouldClose={() => setActionNodeOverlayData(null)}
        />
      </div>
    )
  }

  // The final react-virtualized list containing all the visible tree nodes.
  // Memoized to prevent react-virtualized to re-render the list unnecessarily
  const tree = React.useMemo(() => {
    // The node index to scroll to (updated if the user for example selected a node in the visualizer)
    const scrollToIndex = nodeUuidToScrollTo ? uuidToIndex[nodeUuidToScrollTo] : undefined

    return (
      <AutoSizer>
        {({ width, height }) => {
          return <List
            className="List"
            style={{
              outline: 'none'
            }}
            width={width}
            height={height}
            rowCount={allNodes.length}
            rowHeight={NODE_HEIGHT}
            rowRenderer={rowRenderer}

            // This value controls how many rows are pre-rendered in the direction the user is scrolling
            // Should be kept as low as possible for performance reasons. The fewer nodes to render the better
            overscanRowCount={5}

            scrollToIndex={scrollToIndex}
            scrollToAlignment="start"
            onScroll={handleScroll}
          />
        }}
      </AutoSizer>
    )
  }, [allNodes, nodeUuidToScrollTo, isDragging, props.active])

  // The currently visible action overlay, or null
  const actionOverlay = React.useMemo(() => {
    if (!actionOverlayNodeData) return null

    const node = allNodes[actionOverlayNodeData.index]
    return (
      <ActionOverlayMenu
        nodeUuid={node.uuid}
        metaData={node.metaData}
        menuTriggerRef={actionOverlayNodeData.ref}
        toggleBocModal={toggleBocModal}
        toggleUnlockVirtualProductModalOpen={toggleUnlockVirtualProductModalOpen}
        onShouldClose={() => setActionNodeOverlayData(null)}
      />
    )
  }, [actionOverlayNodeData])

  return (
    <>
      <div ref={scrollContainerRef}>
        { /* Top scroll area */ }
        { isDragging && (
          <ScrollArea
            ref={topScrollAreaRef}
            size={SCROLL_AREA_SIZE}
            style={{
              top: 0,
              left: 0,
              width: (scrollContainerRef.current && scrollContainerRef.current.getBoundingClientRect().width) || 0,
              pointerEvents: 'none'
            }}
          >
            <FaAngleUp
              size={18}
              className='opacity-hover-lighter-performant-alt'
            />
          </ScrollArea>
        )}

        { /* The actual geometry tree */ }
        { tree }

        { /* Bottom scroll area */ }
        { isDragging && (
          <ScrollArea
            ref={bottomScrollAreaRef}
            size={SCROLL_AREA_SIZE}
            style={{
              bottom: 0,
              left: 0,
              width: (scrollContainerRef.current && scrollContainerRef.current.getBoundingClientRect().width) || 0,
              pointerEvents: 'none'
            }}
          >
            <FaAngleDown
              size={18}
              className='opacity-hover-lighter-performant-alt'
            />
          </ScrollArea>
        )}
      </div>

      { /* Modals and overlays */ }
      { bocModalOpen && (
        <BocModal
          isOpen={bocModalOpen}
          onClose={() => toggleBocModal(false)}
        />
      )}
      { unlockVirtualProductModalOpen && (
        <UnlockVirtualProductModal
          uuid={virtualProductUuidToUnlock}
          isOpen={unlockVirtualProductModalOpen}
          onClose={() => setUnlockVirtualProductModalOpen(false)}
        />
      )}
      { actionOverlay }
    </>
  )
})

export default GeometryTree
