import _get from 'lodash/get'
import _map from 'lodash/map'
import _compact from 'lodash/compact'

const getLocalState = (state) => state.jobs
export const getEntries = (state) => _get(getLocalState(state), 'entries')

export const getEntriesByIds = (ids, state) => {
  const entries = getEntries(state)
  return _compact(_map(ids, (id) => _get(entries, id)))
}
