export const getLocalState = (state) => state.patterns

// Json
const getJsonState = (state) => getLocalState(state).json
export const getJsonEntries = (state) => getJsonState(state).entries

export const getEntries = getJsonEntries

// Textures
const getTextureState = (state) => getLocalState(state).textures
export const getTextureEntries = (state) => getTextureState(state).entries
export const getHasPatternChanged = (state) => getTextureState(state).patternChanged

// Convert
export const getConvertState = (state) => getLocalState(state).convert

export const getSavedDecalState = (state) => getLocalState(state).textures.savedDecalState
