import React from 'react'
import PropTypes from 'prop-types'

const Badge = ({
  count,
  style,
  className
}) => {
  return (
    <div
      style={{
        marginRight: -16,
        transform: 'translate(-4px, -10px)',
        borderRadius: 999,
        padding: '4px 8px',
        opacity: count ? 1 : 0,
        ...style
      }}
      className={`c-gray-dark bg-primary f8 center ${className}`}
    >
      {count || 0}
    </div>
  )
}

Badge.propTypes = {
  count: PropTypes.number
}

export default Badge
