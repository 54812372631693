import _get from 'lodash/get'
import _isNil from 'lodash/isNil'
import _omitBy from 'lodash/omitBy'
import _isFinite from 'lodash/isFinite'

import * as fromThreeviewerSelectors from '../../threeviewer/selectors'
import * as fromMaterials from '../../materials/selectors'

import { getTransform, lin2srgb, quaternionFromVec3 } from './utils'
import { AppThunk } from '../..'
import { CombinationModel, Part } from '../Combination'
import type { SceneGraphMesh as ISceneGraphMesh, SceneGraphNode3d as ISceneGraphNode3d, SceneGraph as ISceneGraph } from '../../../../../../go3dthree/types/SceneGraph'

export function getPatternData (node: ISceneGraphMesh) {
  const renderDecalUVTransform = node.material.renderDecalUVTransform
  const userDecalRotation = node.material.userDecalRotation
  const flipDecal = (node.material.isTriplanarMaterial && node.material.useTriplanar)
  const decalUVTransform = renderDecalUVTransform ? renderDecalUVTransform.toArray() : null
  if (flipDecal) {
    decalUVTransform[13] = -decalUVTransform[13]
  }
  return {
    userDecalRotation,
    decalUVTransform,
    patternFilePath: node.userData.patternFilePath
  }
}

export function findVisibleNodesRecursive (node: ISceneGraph | ISceneGraphNode3d, found = new Set<ISceneGraphNode3d>()) {
  let hasVisibleNodes = false
  if (node && node.children) {
    node.children.forEach((child: any) => {
      if (
        ('removed' in child.userData) ||
        !child.visible ||
        !child.userData.isCombination ||
        child.userData.ignore
      ) {
        return
      }

      if (child.userData.isGroup) {
        const _found = findVisibleNodesRecursive(child, found)
        if (_found.size) {
          hasVisibleNodes = true
          found.add(child)
          _found.forEach(f => found.add(f))
        }
      }

      if (child.userData.isModelRoot) {
        hasVisibleNodes = true
        found.add(child)
      }
    })
  }

  if (hasVisibleNodes && node.parent) {
    found.add(node as ISceneGraphNode3d)
  }
  return found
}

export function getChangedModels (nodes?: Set<ISceneGraphNode3d>): AppThunk<{ [id: string]: CombinationModel }> {
  return (_, getState) => {
    const state = getState()
    const viewer = fromThreeviewerSelectors.getViewer(state)
    const materialJson = fromMaterials.getJsonEntries(state)
    const THREE = viewer.THREE
    const models: { [id: string]: CombinationModel } = {}

    const _rootModelsAndGroups = nodes || new Set<ISceneGraphNode3d>()
    if (!nodes && !_rootModelsAndGroups.size) {
      viewer.scene.traverse((node: ISceneGraphNode3d) => {
        if (node.userData.isGroup) _rootModelsAndGroups.add(node)
        if (node.userData.isModelRoot) _rootModelsAndGroups.add(node)
      })

      viewer.scene.children.forEach((node: ISceneGraphNode3d) => {
        if (node.userData.isTemplate && getIsNodeVisible(node)) _rootModelsAndGroups.add(node)
      })
    }

    const filteredRootModelsAndGroups = new Set<ISceneGraphNode3d>()
    _rootModelsAndGroups.forEach((rootNode: ISceneGraphNode3d) => {
      if (
        'removed' in rootNode.userData ||
        !_isNil(rootNode.userData.removed) ||
        rootNode.userData.ignore
      ) return
      if (
        rootNode.userData.isModelRoot &&
        !rootNode.userData.dbModelId &&
        rootNode.userData.modelType !== 'template'
      ) return
      filteredRootModelsAndGroups.add(rootNode)
    })

    filteredRootModelsAndGroups.forEach((rootNode: ISceneGraphNode3d) => {
      if (rootNode.userData.isGroup) {
        const existingChildren = rootNode.children
          .filter(child => filteredRootModelsAndGroups.has(child))
          .map(child => child.uuid)

        if (!existingChildren.length) return // to skip empty groups

        const groupData: CombinationModel = {
          uuid: rootNode.uuid,
          id: rootNode.uuid,
          name: rootNode.userData.name,
          isGroup: true,
          children: existingChildren
        }
        if (rootNode.userData.virtualProductTransform) {
          groupData.virtualProductTransform = rootNode.userData.virtualProductTransform
        }

        if (rootNode.userData.virtualProductId) {
          groupData.virtualProductId = rootNode.userData.virtualProductId
          groupData.isVirtualProductRoot = rootNode.userData.isVirtualProductRoot ?? false
        }

        models[rootNode.uuid] = groupData
      } else {
        const isTemplateModel = rootNode.userData.modelType === 'template'
        const isRoomsetModel = rootNode.userData.modelType === 'roomset'
        const parts: Part[] = []
        rootNode.traverse((child: ISceneGraphMesh) => {
          if (
            child === rootNode ||
            !child.isMesh ||
            (isRoomsetModel && !child.userData.changed) ||
            (isTemplateModel && !child.material)
          ) { return }
          const part = getPartData(child, rootNode, THREE, materialJson)
          if (part) parts.push(part)
        })

        // We only want to save changed roomset models to the doc in the db.
        // Empty parts array means no changes to appearance or visibility.
        if (isRoomsetModel && !parts.length) return

        if (rootNode.userData.override) {
          const actualRootNode = viewer.viewerUtils.findRootNode(rootNode)
          if (actualRootNode) {
            rootNode.userData.override.combinationModelInternalId = actualRootNode.uuid
          }
        }

        // override setMaterial if the model is a virtualProduct or generated
        // otherwise the build script with not apply any appearance
        const setMaterial =
        rootNode.userData.virtualProductId ||
        rootNode.userData.generated
          ? true : _get(rootNode.userData, 'setMaterial', false)

        const modelData: Omit<CombinationModel, 'id' | 'uuid'> = {
          override: rootNode.userData.override,
          generated: rootNode.userData.generated,
          name: rootNode.userData.name,
          article: rootNode.userData.article,
          parts: parts,
          transform: getTransform(rootNode.matrixWorld),
          bbCenter: rootNode.parent.userData.bbCenter,
          visible: getIsNodeVisible(rootNode),
          interactions: { setMaterial: setMaterial },
          modelType: rootNode.userData.modelType || 'combination',
          metadata: _omitBy({
            ...rootNode.userData.metadata,
            modelType: rootNode.userData.modelType || 'combination',
            setMaterial: _get(rootNode.userData, 'setMaterial', false)
          }, _isNil),
          virtualProductId: rootNode.userData.virtualProductId
        }
        models[rootNode.uuid] = {
          id: rootNode.userData.dbModelId,
          uuid: rootNode.uuid,
          ..._omitBy(modelData, _isNil)
        }
      }
    })

    return models
  }
}

export function getMapRot (val: any) {
  return _isFinite(Number(val)) ? Number(val) : 0
}

function getIsNodeVisible (node: ISceneGraphNode3d) {
  if (!node || !node.visible) return false

  let isVisible = false

  node.traverse((child: ISceneGraphMesh) => {
    if (child.geometry && child.visible) {
      isVisible = child.visible
    }
  })

  return isVisible
}

function getPartData (node: ISceneGraphMesh, rootNode: ISceneGraphNode3d, THREE: any, materialJson: any) {
  const nodeUserData = ('nodeId' in node.userData || 'kvadratMeshId' in node.userData) ? node.userData : (node.parent && node.parent.userData)
  let partId = rootNode.userData.modelType === 'roomset'
    ? rootNode.userData.modelId
    : (nodeUserData && nodeUserData.nodeId)

  partId = partId || (nodeUserData && nodeUserData.kvadratMeshId)
  if (!partId) return

  const isTemplateModel = rootNode.userData.modelType === 'template'

  const part: Part = {
    partId,
    tags: node.userData.tags,
    visible: node.userData.visible ?? true,
  }

  if (node.userData.overriddenBy) {
    part.overriddenBy = node.userData.overriddenBy
  }

  let isVisibleTemplateModel = false
  if (isTemplateModel) {
    part.visible = isVisibleTemplateModel = rootNode.visible
  }

  if (node.material && (isVisibleTemplateModel || node.userData.changed)) {
    if (node.userData.materialId || node.userData.carrier_id) {
      const canSetColor = _get(materialJson, [_get(node.material, 'materialId', node.userData.materialId), 'canSetColor'], false)
      const convertColor = lin2srgb(node.material.color)
      const materialColor = canSetColor ? `0x${convertColor.getHexString()}` : '0xffffff'

      part.colorId = node.userData.colorId
      part.materialId = node.userData.materialId
      part.materialSource = node.userData.materialSource
      part.colorTextureMix = node.userData.colorTextureMix
      part.carrier_id = node.userData.carrier_id
      part.mapOrientation = node.userData.mapOrientation
      part.mapReferenceTransform = node.userData.mapReferenceTransform
      part.materialType = node.userData.materialType
      part.triplanarTranslation = node.userData.triplanarTranslation
      part.triplanarOrientation = node.userData.triplanarOrientation
      part.color = materialColor
      part.rgb = `${convertColor.r * 255} ${convertColor.g * 255} ${convertColor.b * 255}`
      part.splitData = node.userData.splitData
      part.name = node.userData.name
      part.kvadratMeshId = node.userData.kvadratMeshId

      // TODO: JENS make sure mapReferenceTransform is CORRECT changedPart.mapReferenceTransform = getTransform(THREE, node.matrixWorld)
      if (node.material.isTriplanarMaterial && node.material.useTriplanar) {
        const triplanarTranslation = node.material.triplanarTranslation
        const triplanarOrientation = node.material.triplanarOrientation
        const translationMatrix = new THREE.Matrix4()
          .makeTranslation(triplanarTranslation.x, triplanarTranslation.y, triplanarTranslation.z)
        const orientationMatrix = new THREE.Matrix4()
          .makeRotationFromQuaternion(quaternionFromVec3(triplanarOrientation))
        const mapReferenceTransform = node.matrixWorld
          .clone()
          .multiply(orientationMatrix)
          .multiply(translationMatrix)
        part.mapReferenceTransform = getTransform(mapReferenceTransform)
        part.triplanarTranslation = triplanarTranslation.toArray()
        part.triplanarOrientation = triplanarOrientation.toArray()

        part.mapRotation = [
          getMapRot(node.material.mapRotationX),
          getMapRot(node.material.mapRotationY),
          getMapRot(node.material.mapRotationZ)
        ]
      }
      if (node.userData.patternId) {
        const {
          userDecalRotation,
          decalUVTransform,
          patternFilePath
        } = getPatternData(node)
        part.patternId = node.userData.patternId
        part.userDecalRotation = userDecalRotation
        part.decalUVTransform = decalUVTransform
        part.patternFilePath = patternFilePath
      }
      if (!node.material.useTriplanar) {
        part.mapOffset = node.material.mapOffset.toArray()
        part.uvMapRotation = node.material.uvMapRotation
      }
    }
  }

  part.modelSource = node.userData.modelSource
  if (node.userData.modelType === 'combination') {
    part.uvMeterScale = node.material.meterScale ? node.material.meterScale : 0.001
  }
  if (node.userData.productType === 'soft' || node.userData.modelSource === 'modelbank') {
    // soft products have UV in mm for historic reasons, modelbank vrscene UVs are in mm, all other UV-mapped objects are m?
    part.uvMeterScale = 0.001
  }
  return {
    partId: part.partId,
    ..._omitBy(part, _isNil)
  }
}
