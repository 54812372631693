import React from 'react'

const IconCube = props => (
  <svg viewBox='0 0 20 20' width={props.size} height={props.size} {...props}>
    <path
      d='M10.77 18.04l7.692-4.194V6.202l-7.693 2.8v9.039zM10 7.645l8.39-3.053L10 1.538 1.61 4.591 10 7.644zm10-3.029v9.231a1.535 1.535 0 0 1-.805 1.346l-8.462 4.616A1.452 1.452 0 0 1 10 20c-.264 0-.509-.064-.733-.192L.805 15.192A1.535 1.535 0 0 1 0 13.846v-9.23c0-.321.092-.614.276-.878.185-.264.43-.453.734-.565L9.47.096C9.647.032 9.824 0 10 0c.176 0 .353.032.529.096l8.461 3.077c.305.112.55.3.734.565a1.5 1.5 0 0 1 .276.877z'
      fill={props.color}
    />
  </svg>
)

IconCube.defaultProps = {
  color: 'currentcolor',
  size: '1em'
}

export default IconCube
