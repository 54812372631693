import _map from 'lodash/map'

const mapToKeys = (xs) => xs.reduce((acc, x) => (Object.assign(acc, { [x]: x })), {})

export const DEADLINE_STATUS = {
  FAILED: 'Failed',
  COMPLETED: 'Completed',
  RENDERING: 'Rendering'
}

const DEFAULT_VRSCENE_PATHS = {
  default: 'default_scene2023_1000x1000.vrscene',
  1: 'default_scene2023_1000x1000.vrscene',
  2: 'default_scene2023_3000x3000.vrscene'
}

const NO_ENVIRONMENT_VRSCENE_PATHS = {
  default: 'default_scene2023_1000x1000.vrscene',
  1: 'default_scene2023_1000x1000.vrscene',
  2: 'default_scene2023_3000x3000.vrscene'
}

const EASY_HOME_VRSCENE_PATHS = {
  default: 'Easy_home_scene_1000x1000.vrscene',
  1: 'Easy_home_scene_1000x1000.vrscene',
  2: 'Easy_home_scene_3000x3000.vrscene'
}

export const DEFAULT_ENVMAP = '/envmaps/lightroom_14b'
export const DEFAULT_ROOMSET_ENVMAP = '/envmaps/int'

export const RENDER_RESOLUTIONS = {
  draft: { x: 1280, y: 720 },
  hd: {
    x: 1920,
    y: 1080
  },
  uhd: {
    x: 4000,
    y: 2250
  },
  square: {
    x: 1000,
    y: 1000
  },
  'square-uhd': {
    x: 4000,
    y: 4000
  },
  'square-hd': {
    x: 2000,
    y: 2000
  }
}

export const RENDER_PRESETS = {
  1: {
    isDefault: true,
    id: '1',
    title: 'Draft',
    resolutions: {
      default: RENDER_RESOLUTIONS.square,
      square: RENDER_RESOLUTIONS.square,
      landscape: RENDER_RESOLUTIONS.draft,
      portrait: {
        x: RENDER_RESOLUTIONS.draft.y,
        y: RENDER_RESOLUTIONS.draft.x
      }
    },
    vrscenes: {
      default: DEFAULT_VRSCENE_PATHS['1'],
      no_env: NO_ENVIRONMENT_VRSCENE_PATHS['1'],
      easyHome: EASY_HOME_VRSCENE_PATHS['1']
    }
  },
  2: {
    id: '2',
    title: 'Final',
    resolutions: {
      default: RENDER_RESOLUTIONS['square-hd'],
      square: RENDER_RESOLUTIONS['square-hd'],
      landscape: RENDER_RESOLUTIONS.hd,
      portrait: {
        x: RENDER_RESOLUTIONS.hd.y,
        y: RENDER_RESOLUTIONS.hd.x
      }
    },
    vrscenes: {
      default: DEFAULT_VRSCENE_PATHS['2'],
      no_env: NO_ENVIRONMENT_VRSCENE_PATHS['2'],
      easyHome: EASY_HOME_VRSCENE_PATHS['2']
    }
  }
}

export const ROOMSET_RENDER_PRESETS = {
  roomset_draft: {
    isDefault: true,
    id: 'roomset_draft',
    title: 'Draft',
    resolutions: {
      default: RENDER_RESOLUTIONS.draft
    },
    vrscenes: {
      default: '/mnt/ri-romberg/ri-rs/DPD/roomset/resources/Roomsets_2023_Draft.vrscene'
    }
  },
  roomset_final: {
    id: 'roomset_final',
    title: 'Final',
    resolutions: {
      default: RENDER_RESOLUTIONS.hd
    },
    vrscenes: {
      default: '/mnt/ri-romberg/ri-rs/DPD/roomset/resources/Roomsets_2023_Final.vrscene'
    }
  }
}

export const TEMPLATE_IMAGE_RENDER_PRESETS = {
  imagetemplate_draft: {
    id: 'imagetemplate_draft',
    title: 'Draft',
    isDefault: true,
    extension: 'jpg',
    resolutions: {
      default: RENDER_RESOLUTIONS.square
    },
    aspectRatio: { x: 1, y: 1 },
    vrscenes: {
      default: '/mnt/ri-romberg/ri-rs/DPD/roomset/resources/imageTemplate_2023_Draft.vrscene'
    }
  },
  imagetemplate_final: {
    id: 'imagetemplate_final',
    title: 'Final',
    extension: 'jpg',
    resolutions: {
      default: RENDER_RESOLUTIONS['square-uhd']
    },
    aspectRatio: { x: 1, y: 1 },
    vrscenes: {
      default: '/mnt/ri-romberg/ri-rs/DPD/roomset/resources/imageTemplate_2023_Final.vrscene'
    }
  }
}

if (process.env.NODE_ENV === 'development') {
  ROOMSET_RENDER_PRESETS.dev = {
    ...ROOMSET_RENDER_PRESETS.roomset_draft,
    isDefault: false,
    id: 'roomset_dev',
    title: 'Dev',
    resolutions: {
      default: { x: 100, y: 70 }
    }
  }

  RENDER_PRESETS.dev = {
    ...RENDER_PRESETS['1'],
    isDefault: false,
    id: 'dev',
    title: 'Dev',
    resolutions: {
      default: { x: 100, y: 100 },
      square: { x: 100, y: 100 },
      landscape: { x: 192, y: 108 },
      portrait: { y: 192, x: 108 }
    }
  }

  TEMPLATE_IMAGE_RENDER_PRESETS.dev = {
    ...RENDER_PRESETS['1'],
    isDefault: true,
    extension: 'jpg',
    id: 'dev',
    title: 'Dev',
    resolutions: {
      default: { x: 100, y: 100 },
      square: { x: 100, y: 100 }
    }
  }
}

// should match @dpd/core/constants -> need to include in web in future
export const EXTRACT_STATUSES = mapToKeys([
  'WAITING_TO_PROCESS',
  'EXTRACTING',
  'EXTRACT_FAILED',
  'EXTRACT_DONE'
])

// should match @dpd/core/constants -> need to include in web in future
export const PRODUCT_TYPES = mapToKeys([
  'hard',
  'soft'
])

export const MONTHS = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December'
}

export const MODEL_RESOURCE_TYPES = {
  RESOURCE_DPD: 'upload',
  RESOURCE_VARIANT: 'variant',
  RESOURCE_MODEL_BANK: 'modelbank',
  RESOURCE_PROPPING_BANK: 'ugabank',
  RESOURCE_NON_CAD: 'non-cad',
  RESOURCE_VIRTUAL_PRODUCTS: 'virtualProduct'
}

export const HFB = {
  '01': 'Living room seating',
  '02': 'Store and organise furniture',
  '03': 'Workspaces',
  '04': 'Bedroom furniture',
  '05': 'Mattresses',
  '06': 'Bathroom',
  '07': 'Kitchen',
  '08': 'Dining',
  '09': 'Children\'s IKEA',
  10: 'Lighting',
  11: 'Bed and bath textiles',
  12: 'Home textiles',
  13: 'Rugs',
  14: 'Cooking',
  15: 'Eating',
  16: 'Decoration',
  17: 'Outdoor',
  18: 'Home organisation',
  19: 'Secondary storage',
  20: 'Other business opportunities',
  92: 'Family',
  94: 'Administrative Area 94',
  95: 'Administrative Area 95',
  96: 'IKEA Food'
}

export const PA = {
  '0111': 'Sofas',
  '0113': 'Sofa beds',
  '0121': 'Sofa tables',
  '0125': 'Armchairs',
  '0126': 'Footstools',
  '0211': 'Multipurpose storage systems',
  '0212': 'Closed storage solitaires',
  '0214': 'Bookcases',
  '0221': 'Closed storage',
  '0222': 'Display cabinets',
  '0223': 'Open shelving units & Bookcases',
  '0231': 'Tv furniture',
  '0232': 'Media electronics & accessories',
  '0311': 'Desk and tables for home',
  '0312': 'Desks and tables for professionals',
  '0313': 'Workspaces table bar',
  '0314': 'Workspaces mobile solutions',
  '0315': 'Workspace accessories',
  '0321': 'Workspaces storage for home',
  '0322': 'Workspaces storage for professionals',
  '0331': 'Workspaces seating',
  '0351': 'Office desks and tables',
  '0411': 'Bed frames',
  '0412': 'Loft beds and day beds',
  '0413': 'Beds others',
  '0421': 'System wardrobes',
  '0422': 'Interior organisers',
  '0423': 'Solitaire wardrobes',
  '0431': 'Chest of drawers',
  '0432': 'Bedside tables',
  '0433': 'Other bedroom furniture',
  '0441': 'Mirrors',
  '0511': 'Mattresses',
  '0512': 'Wooden base mattresses',
  '0513': 'Mattress pads',
  '0514': 'Bases and legs',
  '0515': 'Continental beds',
  '0611': 'Bathroom furniture',
  '0612': 'Bathroom small furniture',
  '0613': 'Bathroom sinks',
  '0621': 'Bathroom around the sink',
  '0622': 'Bathroom towel solutions',
  '0623': 'Bathroom, shower and toilet solutions',
  '0631': 'Bathroom taps',
  '0632': 'Shower mixers and accessories',
  '0711': 'Kitchen cabinets',
  '0721': 'Kitchen fronts, coverpanels, plinths and deco strips',
  '0731': 'Kitchen fixed interiors',
  '0741': 'Kitchen worktops',
  '0742': 'Kitchen wall panels',
  '0751': 'Kitchen taps',
  '0752': 'Kitchen sinks and sink accessories',
  '0761': 'Kitchen interior organisers',
  '0762': 'Kitchen organisers',
  '0771': 'Ovens and cookers',
  '0772': 'Hobs',
  '0773': 'Kitchen extractors',
  '0774': 'Fridges and freezers',
  '0775': 'Microwaves',
  '0776': 'Dishwashers',
  '0777': 'Laundry appliances, integrated',
  '0778': 'Other kitchen appliances',
  '0779': 'Kitchen appliance accessories',
  '0781': 'Freestanding kitchens',
  '0782': 'Kitchen furniture',
  '0783': 'Complete kitchen cabinets',
  '0791': 'Knobs and handles',
  '0811': 'Tables',
  '0812': 'High tables',
  '0821': 'Chairs incl covers, folding chairs and benches',
  '0822': 'Stools incl covers',
  '0823': 'Bar stools incl covers',
  '0911': 'Cots',
  '0912': 'Cot mattresses',
  '0913': 'Accessories for babies',
  '0914': 'Textiles for babies',
  '0915': 'Toys for babies',
  '0917': 'Highchairs',
  '0918': 'Changing tables',
  '0921': 'Children´s storage furniture',
  '0922': 'Children´s small storage',
  '0931': 'Beds for small children',
  '0932': 'Mattresses for small children',
  '0934': 'Textiles for small children',
  '0935': 'Accessories for small children',
  '0941': 'Play for small children',
  '0942': 'Toys for small children',
  '0944': 'Furniture for small children',
  '0945': 'Creativity for small children',
  '0951': 'Beds for children',
  '0952': 'Accessories for children',
  '0953': 'Textiles for children',
  '0954': 'Desks and chairs for children',
  1011: 'Floor lamps',
  1012: 'Table lamps',
  1015: 'Shades, bases and cord sets',
  1016: 'Multipurpose lighting ',
  1022: 'Ceiling lamps',
  1023: 'Pendants lamps',
  1024: 'Wall lamps',
  1025: 'Bathroom lighting',
  1031: 'Light sources',
  1043: 'Integrated lighting',
  1044: 'Speciality lighting',
  1045: 'Spotlights',
  1051: 'Batteries',
  1052: 'Electrics',
  1061: 'Outdoor lighting',
  1071: 'Children\'s lighting',
  1081: 'Seasonal lighting',
  1111: 'Sheets and pillow cases',
  1112: 'Quilt covers',
  1113: 'Bedspreads',
  1121: 'Quilts',
  1122: 'Pillows',
  1123: 'Mattress and pillow protectors',
  1131: 'Towels',
  1132: 'Bathmats',
  1133: 'Shower curtains',
  1134: 'Shower curtain rods',
  1211: 'Curtains and window panels',
  1213: 'Blinds',
  1214: 'Curtain rods and systems',
  1221: 'Metre fabrics',
  1222: 'Textile accessories',
  1231: 'Cushions',
  1232: 'Throws',
  1233: 'Chairpads',
  1311: 'Rugs',
  1312: 'Oriental rugs',
  1313: 'Doormats & accessories',
  1411: 'Pots and cooking accessories',
  1412: 'Pans',
  1413: 'Ovenware',
  1414: 'Bakeware',
  1415: 'Cooking textiles',
  1421: 'Cooking utensils',
  1422: 'Kitchen gadgets',
  1423: 'Knives',
  1424: 'Chopping boards',
  1431: 'Food containers',
  1432: 'Jar and tins',
  1433: 'Organisers and washing-up',
  1511: 'Dinnerware and serving',
  1521: 'Drinking glasses',
  1522: 'Jugs and carafes',
  1531: 'Cutlery',
  1541: 'Napkins',
  1542: 'Trays',
  1543: 'Textilies, place mats and coasters',
  1551: 'Mugs and cups',
  1552: 'Coffee and tea accessories',
  1621: 'Plant pots',
  1622: 'Plants',
  1623: 'Artificial plants',
  1631: 'Candleholders',
  1632: 'Candles',
  1633: 'Vases and bowls',
  1634: 'Artificial flowers',
  1636: 'Holiday gift wrap',
  1637: 'Holiday decoration',
  1641: 'Frames',
  1642: 'Pictures',
  1643: 'Clocks',
  1651: 'Gift wrap',
  1652: 'Stationery',
  1653: 'Paper decoration, themes',
  1711: 'Outdoor dining tables',
  1712: 'Outdoor relax furniture',
  1713: 'Outdoor lounge furniture',
  1714: 'Outdoor storage',
  1715: 'Outdoor dining seating',
  1716: 'Outdoor cooking',
  1717: 'Parasols and protections',
  1718: 'Outdoor accessories',
  1719: 'Outdoor  cushions',
  1811: 'Boxes and organisers',
  1812: 'Media and workspace organisers',
  1821: 'Clothes and shoes storage',
  1822: 'Clothes organisers and stands',
  1823: 'Hangers, hooks and accessories',
  1831: 'Laundry organisers',
  1832: 'Cleaning accessories',
  1833: 'Laundry sorting',
  1834: 'Freestanding laundry appliances',
  1835: 'Waste sorting and recycling',
  1911: 'Secondary storage systems',
  1912: 'Secondary storage units',
  1921: 'Transport products',
  1922: 'Assembly products',
  2011: 'Pets and people',
  2012: 'Flooring',
  2014: 'Flooring underlays',
  2015: 'Flooring accessories',
  2016: 'Skirting boards and profiles',
  2017: 'Walls',
  2041: 'Eating supplies',
  2042: 'Sleeping',
  2043: 'Walking and travelling',
  2044: 'Grooming and cleaning accessories',
  2045: 'Playing and training',
  2046: 'Storage solutions',
  9211: 'On the go',
  9212: 'Games and leisure',
  9221: 'Home safety',
  9222: 'Child safety',
  9223: 'Personal safety',
  9231: 'Sustainable life at home',
  9241: 'Books',
  9411: 'Packing material',
  9412: 'Handling aids',
  9414: 'Home transportation, products',
  9511: 'Activities and sales groups',
  9512: 'Hardware and spare parts',
  9517: 'Additional services to customer',
  9522: 'Gift vouchers',
  9611: 'Meat, fish & seafood',
  9612: 'Easy to prepare',
  9613: 'Sauces, jam & condiments',
  9614: 'Bread & dairy',
  9615: 'Cookies, pastries & desserts',
  9616: 'Sweets, chocolate & snacks',
  9617: 'Grab & Go',
  9618: 'Beverage',
  9619: 'Activities',
  9621: 'Breakfasts and brunches',
  9622: 'Cold plates and starters',
  9623: 'Sandwiches and wraps',
  9624: 'Salads',
  9625: 'Main courses and buffets',
  9626: 'Desserts and pastries',
  9627: 'Add on',
  9628: 'Cold beverages',
  9629: 'Hot beverages',
  9631: 'Hot snacks Bistro',
  9632: 'Cold snacks Bistro',
  9633: 'Cold beverages Bistro',
  9634: 'Hot beverages Bistro',
  9635: 'Desserts and pastries Bistro',
  9636: 'Add-on Bistro',
  9641: 'Hot snacks Café',
  9651: 'Breakfasts and brunches CW Rest',
  9652: 'Cold plates and starters CW Rest',
  9653: 'Sandwiches and wraps CW Rest',
  9654: 'Salads CW Rest',
  9655: 'Main courses and buffets CW Rest',
  9656: 'Desserts and pastries CW Rest',
  9657: 'Add-on CW Rest',
  9658: 'Cold beverages CW Rest',
  9661: 'Others, non food',
  9662: 'Catering service'
}

const IMAGE_PACKAGE_IDS = {
  BEDSETS: 'bedsets'
}

export const PROJECT_TYPES = {
  IMAGE_PACKAGE: 'image-package',
  DEFAULT: 'default'
}

export const PROJECT_TYPES_OPTIONS = [
  {
    label: 'Bedsets',
    type: PROJECT_TYPES.IMAGE_PACKAGE,
    id: IMAGE_PACKAGE_IDS.BEDSETS
  },
  {
    label: 'Default DPD',
    type: PROJECT_TYPES.DEFAULT,
    id: PROJECT_TYPES.DEFAULT
  }
]

// ------- ROOMSET SPECIFICS ------- //
export const mapToValueLabel = (constantObject) => {
  return _map(constantObject, (label, value) => ({ value, label }))
}

export const mapToKeysValueLabel = (constantArray) => {
  return _map(constantArray, (e) => ({ value: e, label: e }))
}

export const ROOMSET_TAGS_FULL = ['70/80th', 'Al fresco', 'Apartment', 'Air conditioner', 'Balcony', 'Bay window', 'Beams', 'Built-in storage', 'Ceiling fan', 'Cinema', 'Closet', 'Concrete pannels building', 'Cork', 'Cottage', 'Fire place', 'First floor', 'French door', 'Garage', 'Gas', 'Ground floor', 'Highrise Building', 'Herringbone floor', 'House', 'Khrushchevka', 'Kitchen island', 'Laundry', 'Lowrise building', 'Modern', 'Multistory building', 'Neubauwohnung', 'New York', 'Altbauwohnung', 'Old architecture', 'Old building', 'Pantry', 'Sauna', 'Semi-detatched terraced house', 'Shower', 'sliding doors', 'Sliding windows', 'Stairs', 'Small city', 'Small space', 'Small village', 'Standard', 'Storage room', 'Studio', 'Suburb', 'Terrace', 'Tiled flooring', 'Village', 'Wheelchair access', 'Wooden floor', 'Water heater']

const asia = {
  cn: 'China',
  in: 'India',
  jp: 'Japan',
  sg: 'Singapore',
  kr: 'South Korea'
}

const europe = {
  at: 'Austria',
  dk: 'Denmark',
  fi: 'Finland',
  de: 'Germany',
  it: 'Italy',
  nl: 'Netherlands',
  ru: 'Russia',
  es: 'Spain',
  see: 'South East Europe',
  se: 'Sweden',
  ch: 'Switzerland',
  gb: 'United Kingdom'
}

const northAmerica = {
  ca: 'Canada',
  us: 'United States'
}

export const ROOMSET_COUNTRIES = {
  ...asia,
  ...europe,
  ...northAmerica
}

export const GROUPED_ROOMSET_COUNTRIES = [
  {
    label: 'Asia',
    value: 'asia',
    options: mapToValueLabel(asia)
  },
  {
    label: 'Europe',
    value: 'europe',
    options: mapToValueLabel(europe)
  },
  {
    label: 'North America',
    value: 'north-america',
    options: mapToValueLabel(northAmerica)
  }
]

export const COMBINATION_TYPES = {
  variant: 'variant',
  render: 'render',
  convert: 'convert'
}

export const PROJECT_TABS = {
  geometry: 'geometry',
  images: 'images',
  variants: 'variants',
  patterns: 'patterns',
  colors: 'colors',
  scenes: 'scenes',
  virtualProducts: 'virtualProducts'
}

export const ROOMSET_TYPES = {
  IMAGE_TEMPLATE: 'image-template',
  LIGHT_SETUP: 'light-setup'
}

export const FEATURES = {
  IMAGE_TEMPLATES: 'product-picture-templates',
  BATCH_RENDER: 'batchRender',
  CUSTOM_HOME: 'customHome',
  PLM: 'plm',
  PRODUCT_CONCEPT_ANALYSIS: 'productConceptAnalysis',
  VIRTUAL_PRODUCT: 'virtualProduct'
}

export const SUPPORT_EMAIL = 'support.dpd@inter-ikea.atlassian.net'

export const SCENE_TAGS = {
  ROOMS: [
    { value: 'hallway', label: 'Hallway', key: 200 },
    { value: 'kitchen', label: 'Kitchen', key: 201 },
    { value: 'bathroom', label: 'Bathroom', key: 202 },
    { value: 'outdoor space', label: 'Outdoor space', key: 203 },
    { value: 'balcony', label: 'Balcony', key: 204 },
    { value: 'bedroom', label: 'Bedroom', key: 205 },
    { value: 'kids room', label: 'Kids room', key: 206 },
    { value: 'work space', label: 'Work space', key: 207 },
    { value: 'dining area', label: 'Dining area', key: 208 },
    { value: 'storage area', label: 'Storage area', key: 209 },
    { value: 'living room', label: 'Living room', key: 210 }
  ],
  REGION: [
    {
      label: 'Scandinavia',
      options: [
        { value: 'denmark', label: 'Denmark', parent: 'Scandinavia', key: 301 },
        { value: 'sweden', label: 'Sweden', parent: 'Scandinavia', key: 302 },
        { value: 'norway', label: 'Norway', parent: 'Scandinavia', key: 303 },
        { value: 'finland', label: 'Finland', parent: 'Scandinavia', key: 304 },
        { value: 'iceland', label: 'Iceland', parent: 'Scandinavia', key: 305 }
      ],
    },
    {
      label: 'Europe',
      options: [
        { value: 'germany', label: 'Germany', parent: 'Europe', key: 306 },
        { value: 'belgium', label: 'Belgium', parent: 'Europe', key: 307 },
        { value: 'netherlands', label: 'Netherlands', parent: 'Europe', key: 308 },
        { value: 'united Kingdom', label: 'United Kingdom', parent: 'Europe', key: 309 },
        { value: 'austria', label: 'Austria', parent: 'Europe', key: 310 },
        { value: 'switzerland', label: 'Switzerland', parent: 'Europe', key: 311 },
        { value: 'france', label: 'France', parent: 'Europe', key: 312 },
        { value: 'spain', label: 'Spain', parent: 'Europe', key: 313 },
        { value: 'portugal', label: 'Portugal', parent: 'Europe', key: 314 },
        { value: 'italy', label: 'Italy', parent: 'Europe', key: 315 },
        { value: 'greece', label: 'Greece', parent: 'Europe', key: 316 },
        { value: 'poland', label: 'Poland', parent: 'Europe', key: 317 },
        { value: 'czech Republic', label: 'Czech Republic', parent: 'Europe', key: 318 },
        { value: 'hungary', label: 'Hungary', parent: 'Europe', key: 319 }
      ]
    },
    {
      label: 'Asia-Pacific',
      options: [
        { value: 'china', label: 'China', parent: 'Asia-Pacific', key: 320 },
        { value: 'japan', label: 'Japan', parent: 'Asia-Pacific', key: 321 },
        { value: 'South korea', label: 'South Korea', parent: 'Asia-Pacific', key: 322 },
        { value: 'taiwan', label: 'Taiwan', parent: 'Asia-Pacific', key: 323 },
        { value: 'malaysia', label: 'Malaysia', parent: 'Asia-Pacific', key: 324 },
        { value: 'australia', label: 'Australia', parent: 'Asia-Pacific', key: 325 },
        { value: 'india', label: 'India', parent: 'Asia-Pacific', key: 326 }
      ]
    },
    {
      label: 'North America',
      options: [
        { value: 'canada', label: 'Canada', parent: 'North America', key: 327 },
        { value: 'usa', label: 'USA', parent: 'North America', key: 328 },
        { value: 'mexico', label: 'Mexico', parent: 'North America', key: 329 }
      ]
    },
    {
      label: 'South America',
      options: [
        { value: 'chile', label: 'Chile', parent: 'South America', key: 330 },
        { value: 'peru', label: 'Peru', parent: 'South America', key: 331 },
        { value: 'colombia', label: 'Colombia', parent: 'South America', key: 332 }
      ]
    },
    {
      label: 'Other',
      options: [
        { value: 'turkey', label: 'Turkey', parent: 'Other', key: 333 },
        { value: 'russia', label: 'Russia', parent: 'Other', key: 334 }
      ]
    },
  ],
  LIVING_SITUATION: [
    {
      label: 'Multi-generational living',
      options: [{ value: 'multi-generational living', label: 'Multi-generational living', key: 401 }],
    },
    {
      label: 'Living with children',
      options: [
        { value: 'baby', label: 'Baby', key: 402 },
        { value: 'toddler', label: 'Toddler', key: 403 },
        { value: 'starting school', label: 'Starting school', key: 404 },
        { value: 'teens', label: 'Teens', key: 405 }
      ],
    },
    {
      label: 'Living single',
      options: [
        { value: 'starting out', label: 'Starting out', key: 406 },
        { value: 'established', label: 'Established', key: 407 },
      ],
    },
    {
      label: 'Living together',
      options: [
        { value: 'couple', label: 'Couple', key: 408 },
        { value: 'co-living', label: 'Co-living', key: 409 },
      ]
    }
  ],
  SIZE: [
    { value: 'S (1 bedroom)', label: 'S (1 bedroom)', key: 10 },
    { value: 'M (2 bedroom)', label: 'M (2 bedroom)', key: 11 },
    { value: 'L (>3 bedrooms)', label: 'L (>3 bedrooms)', key: 12 },
    { value: 'XS (studio/one room living)', label: 'XS (studio/one room living)', key: 9 }],
  STYLE: [
    { value: 'modern', label: 'Modern', key: 50 },
    { value: 'traditional', label: 'Traditional', key: 51 }
  ],
  COST: [
    { value: 'low', label: 'Low', key: 47 },
    { value: 'high', label: 'High', key: 49 },
    { value: 'medium', label: 'Medium', key: 48 }
  ],
  PRIORITIES: [
    { value: 'organise your living', label: 'Organise your living', key: 60 },
    { value: 'living with children', label: 'Living with children', key: 61 },
    { value: 'small space living', label: 'Small space living', key: 62 }
  ],
  ACTIVITIES: [
    { value: 'eat', label: 'Eat', key: 101 },
    { value: 'sleep', label: 'Sleep', key: 102 },
    { value: 'wash and clean', label: 'Wash and clean', key: 104 },
    { value: 'cook', label: 'Cook', key: 105 },
    { value: 'come and go', label: 'Come and go', key: 106 },
    { value: 'socialise', label: 'Socialise', key: 107 },
    { value: 'play, create and learn', label: 'Play, create and learn', key: 108 },
    { value: 'work', label: 'Work', key: 103 },
    { value: 'personal care', label: 'Personal care', key: 109 },
    { value: 'stay healthy', label: 'Stay healthy', key: 110 },
    { value: 'store and organise', label: 'Store and organise', key: 111 },
    { value: 'hobby', label: 'Hobby', key: 112 },
  ],
  BEHAVIORS: [
    { value: 'simplicity', label: 'Simplicity', key: 113 },
    { value: 'self expression', label: 'Self expression', key: 114 },
    { value: 'social recognition', label: 'Social recognition', key: 115 },
    { value: 'caring', label: 'Caring', key: 116 },
    { value: 'performance', label: 'Performance', key: 117 }
  ]
}

export const TOP_BAR_HEIGHT = 64
