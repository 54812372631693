import React from 'react'
import styled from 'styled-components'

type Props = {
    actionTitle?: string
    actionDesc?: string
    onAction?: () => void
    iconComponent?: any
    style?: React.CSSProperties
}

const Card = styled.div<Props>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  padding-top: 21px;
  cursor: pointer;
  border: 2px solid #B3B3B3;
  background-color: white;
  color: #B3B3B3;
  &:hover { 
    background-color: #E5E5E5;
    color: black;
    border-color: black;
  }
`

export default function ActionCard (props: Props) {
  return (
    <Card onClick={props.onAction} style={props.style}>
      {props.iconComponent}
      <p className='h4 m0 pt1 center'>{props.actionTitle}</p>
      <p className='h6 m0'><i>{props.actionDesc}</i></p>
    </Card>
  )
}
