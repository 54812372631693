import _map from 'lodash/map'

// State selectors
import * as fromThreeviewerSelectors from '../../threeviewer/selectors'

// Actions
import * as fromDefaultTemplate from '../../templates/default-template'
import * as fromTree from '../../tree'
import * as fromUndoRedo from '../../undo-redo'
import * as fromSelection from '../../selection'

// Commands
import * as combinationCommands from '../../combinations/commands'

function addModel (model) {
  return (dispatch, getState) => {
    const viewer = fromThreeviewerSelectors.getViewer(getState())
    viewer.addModel(model, model.userData.params)
    viewer.transformGizmo.addSnappableModel(model)
    dispatch(fromTree.add(model))
  }
}

export function addModels (models, isComplementary) {
  return (dispatch, getState) => {
    const viewer = fromThreeviewerSelectors.getViewer(getState())

    if (isComplementary) {
      const command = combinationCommands.createAddModelCommand({
        models: _map(models),
        addModel (model, params) {
          viewer.addModel(model, params)
          viewer.transformGizmo.addSnappableModel(model)
          dispatch(fromTree.add(model))
        },
        removeModel (model) {
          viewer.transformGizmo.holeSnapHelper.removeSnappableModel(model)
          dispatch(fromSelection.deselectAfterRemove(model))
          dispatch(fromTree.remove(model.uuid))
          removeNode(model, viewer)
        },
        afterExecute: () => {
          dispatch(fromDefaultTemplate.adjustWallInScene())
        },
        afterUndo: () => {
          dispatch(fromDefaultTemplate.adjustWallInScene())
        }
      })

      dispatch(fromUndoRedo.addCommand(command))
      command.execute()
    } else {
      _map(models, (model) => dispatch(addModel(model)))
    }
  }
}

export function removeModel (nodeOrUUID, addToUndoHistory = true) {
  return (dispatch, getState) => {
    const viewer = fromThreeviewerSelectors.getViewer(getState())

    if (!nodeOrUUID) return

    const {
      objectTracker: { interactions },
      transformGizmo
    } = viewer

    const node = nodeOrUUID.uuid ? nodeOrUUID : interactions.nodeList[nodeOrUUID]

    if (addToUndoHistory) {
      const command = combinationCommands.createRemoveModelCommand({
        models: [node],
        addModel (model, params) {
          viewer.addModel(model, params)
          viewer.transformGizmo.addSnappableModel(model)
          dispatch(fromTree.add(model))
        },
        removeModel (model) {
          viewer.transformGizmo.holeSnapHelper.removeSnappableModel(model)
          dispatch(fromSelection.deselectAfterRemove(model))
          dispatch(fromTree.remove(model.uuid))
          removeNode(model, viewer)
        },
        afterUndo: () => {
          dispatch(fromDefaultTemplate.adjustWallInScene())
        },
        afterExecute: () => {
          transformGizmo.detach()
          dispatch(fromDefaultTemplate.adjustWallInScene())
        }
      })

      command.execute()

      dispatch(fromUndoRedo.addCommand(command))
    } else {
      dispatch(fromSelection.deselectAfterRemove(node))
      dispatch(fromDefaultTemplate.adjustWallInScene())
      removeNode(node, viewer)
    }
  }
}

function removeNode (node, viewer) {
  node.parent.remove(node)
  viewer.objectTracker && viewer.objectTracker.removeObject(node)
}
