import React, { PureComponent } from 'react'
import autoBind from 'react-autobind'

import _map from 'lodash/map'

import Button from '../button'

class Preview extends PureComponent {
  constructor (props) {
    super(props)
    this.state = { previewUrl: null }
  }

  componentDidUpdate () {
    const { files } = this.props

    if (!files || files.length !== 1) return

    const file = files[0]

    if (/^image\//.test(file.type)) {
      const reader = new window.FileReader()

      reader.onload = () => {
        this.setState({ previewUrl: reader.result })
      }

      reader.readAsDataURL(file)
    }
  }

  renderPreviewImage () {
    const { previewUrl } = this.state
    return previewUrl && <img className='mr2' src={previewUrl} width={30} />
  }

  render () {
    const { files } = this.props

    if (!files || !files.length) return null

    return (
      <div className='f7'>
        {files.length === 1 && (
          <div className='flex items-center'>
            {this.renderPreviewImage()}
            <span className='muted'>{files[0].name}</span>
          </div>
        )}
        {files.length > 1 && (<span className='muted'>{files.length} files</span>)}
      </div>
    )
  }
}

class InputFile extends PureComponent {
  constructor (props) {
    super(props)

    autoBind(this)

    this.state = {
      files: []
    }
  }

  handleInputChange (event) {
    event.persist()

    const { files } = event.target
    if (!files) return

    const { handleFilelist } = this.props

    if (handleFilelist) {
      this.props.onChange(files)
    } else {
      this.props.onChange(_map(files))
    }
    if (this.props.clear) event.target.value = null
  }

  handleTriggerInputClick (event) {
    event.preventDefault()
    this._input.click()
  }

  render () {
    const {
      accept,
      multiple,
      children,
      value,
      inverted,
      type,
      size,
      uppercase,
      icon,
      disabled,
      style,
      buttonClassName
    } = this.props

    return (
      <label>
        <div className='mb1' style={{ minHeight: 15, ...style }}>
          <Preview files={value} />
        </div>

        <input
          disabled={disabled}
          ref={(node) => (this._input = node)}
          className='hidden'
          accept={accept}
          onChange={this.handleInputChange}
          type='file'
          multiple={multiple}
        />
        <Button
          disabled={disabled}
          inverted={inverted}
          btnType={type}
          size={size}
          uppercase={uppercase}
          icon={icon}
          onClick={this.handleTriggerInputClick}
          className={buttonClassName}
        >
          {children}
        </Button>
      </label>
    )
  }
}

InputFile.defaultProps = {
  accept: '*',
  style: {},
  handleFilelist: false,
  multiple: false,
  clear: false
}

export default InputFile
