import _get from 'lodash/get'
import _map from 'lodash/map'
import _orderBy from 'lodash/orderBy'
import _groupBy from 'lodash/groupBy'
import _last from 'lodash/last'
import { createSelector } from 'reselect'

import { Roomset } from '../roomsets/Roomset'
import { ROOMSET_TYPES, DEADLINE_STATUS } from '../../../constants'
import * as fromRoomsetSelectors from '../roomsets/selectors'
import * as fromUploadsSelectors from '../uploads/selectors'
import * as fromJobsSelectors from '../jobs/selectors'

import {
  hasMaxFile,
  hasFailedJobs,
  hasCompletedAllJobs,
  hasOkExtractStatus
} from './utils'
import { RootState } from '..'
import SeamlessImmutable from 'seamless-immutable'

export const getLocalState = (state: RootState) => _get(state, 'roomsetsAdmin')

const getCurrentId = (state: RootState) => state.roomsetsAdmin.currentId

export const getCurrentRoomset = (state: RootState) => {
  return state.roomsets.entries.getIn([getCurrentId(state) || ''])
}

const getEditingRoomset = (state: RootState) => {
  return _get(getLocalState(state), 'editingRoomset')
}

export const getUpload = (state: RootState) => {
  const uploadId = _get(getEditingRoomset(state), 'uploadId')

  if (!uploadId) {
    return null
  }

  const upload = fromUploadsSelectors.getEntryById(uploadId)(state)
  if (upload.id) {
    return upload
  }
}

export const getSetupUpload = (state: RootState) => {
  const uploadId = _get(getEditingRoomset(state), 'setupUploadId')

  if (!uploadId) {
    return null
  }

  const upload = fromUploadsSelectors.getEntryById(uploadId)(state)
  if (upload.id) {
    return upload
  }
}

const isUploadOk = (state: RootState, uploadId: string) => {
  const upload = state.uploads.getIn(['entries', uploadId])
  const extractStatus = _get(upload, 'extractStatus')
  const extractStatusOk = hasOkExtractStatus(extractStatus)
  const uploadManifest = _get(upload, 'manifest')

  if (!uploadId ||
    !uploadManifest ||
    !extractStatusOk
  ) {
    return false
  }

  const files = _get(uploadManifest, 'files')
  return hasMaxFile(files)
}

export const getDeadlineJobsForRoomset = (state: RootState) => (id: string) => {
  const roomset = fromRoomsetSelectors.getEntryById(id)(state)
  if (!roomset) return []
  const jobIds = _map(_get(roomset, 'deadlineJobs'), (_, id) => id)
  const jobs = fromJobsSelectors.getEntriesByIds(jobIds, state)
  return _orderBy(jobs, ['createdAt', 'modifiedAt'])
}

export const getDeadlineJobs = (state: RootState) => {
  // deadline job ids are added to roomset via pubsub
  const currentRoomsetId = getCurrentId(state)
  if (!currentRoomsetId) { return [] }

  const currentRoomset = fromRoomsetSelectors.getEntryById(currentRoomsetId)(state)
  const jobIds = _map(_get(currentRoomset, 'deadlineJobs'), (_, id) => id)
  const jobs = fromJobsSelectors.getEntriesByIds(jobIds, state)

  return _orderBy(jobs, ['createdAt', 'modifiedAt']).filter(job => {
    if (currentRoomset) {
      if (job.uploadId) {
        return job.uploadId === currentRoomset.uploadId
      } else if (currentRoomset.uploadId) {
        const upload = state.uploads.getIn([currentRoomset.uploadId])
        if (upload) {
          return job.createdAt > upload.createdAt
        }
      }
    }

    return true
  })
}

export const canStartDeadlineJob = (state: RootState) => {
  if (!getCurrentId(state)) return false

  // check maxfile upload status
  const uploadId = _get(getEditingRoomset(state), 'uploadId')
  if (!uploadId) return false
  if (!isUploadOk(state, uploadId)) {
    return false
  }

  // check deadline status
  const jobs = getDeadlineJobs(state)
  if (jobs.length < 1) {
    return true
  }

  return hasCompletedAllJobs(jobs) || hasFailedJobs(jobs)
}

export const canStartSetupDeadlineJob = (state: RootState) => (id: string) => {
  const setup = state.roomsets.entries.getIn([id])
  if (!setup || !setup.uploadId) return false

  // check maxfile upload status
  if (!isUploadOk(state, setup.uploadId)) {
    return false
  }

  // deadline job ids are added to roomset via pubsub
  const jobIds = Object.keys(_get(setup, 'deadlineJobs', {}))
  const jobs = fromJobsSelectors.getEntriesByIds(jobIds, state)

  // check deadline status
  if (jobs.length < 1) {
    return true
  }

  const allCompleted = jobs.length && jobs.every(job => job.status === DEADLINE_STATUS.COMPLETED)
  const allFailed = jobs.length && jobs.every(job => job.status === DEADLINE_STATUS.FAILED)
  return allCompleted || allFailed
}

export const selectRoomsets = createSelector(
  (state: RootState) => fromRoomsetSelectors.getActiveEntries(state),
  (roomsetsActive) => {
    return roomsetsActive.filter(doc => ![ROOMSET_TYPES.IMAGE_TEMPLATE, ROOMSET_TYPES.LIGHT_SETUP].includes(doc.type!))
  }
)

export const selectRoomsetsImageTemplate = createSelector(
  (state: RootState) => fromRoomsetSelectors.getActiveEntries(state),
  (roomsetsActive) => {
    return roomsetsActive.filter(doc => doc.type === ROOMSET_TYPES.IMAGE_TEMPLATE)
  }
)

export const selectSetups = (state: RootState) => _groupBy(state.roomsets.entries, 'parentId')
export const selectSetupsCurrentId = (state: RootState) => _groupBy(state.roomsets.entries, 'parentId')[state.roomsetsAdmin.currentId || ''] || {}
export const selectCurrentId = (state: RootState) => state.roomsetsAdmin.currentId
export const selectCurrentRoomset = (state: RootState) => getCurrentRoomset(state)
export const selectUpload = (state: RootState) => getUpload(state)
export const selectJobStatuses = (state: RootState) => getDeadlineJobs(state)
export const selectGetSetupUpload = (state: RootState, uploadId: string) => fromUploadsSelectors.getEntries(state).getIn([uploadId])
export const selectGetDeadlineJobsForRoomset = (state: RootState) => getDeadlineJobsForRoomset(state)
export const selectCanStartSetupDeadlineJob = (state: RootState) => canStartSetupDeadlineJob(state)
export const selectSetupUpload = (state: RootState) => getSetupUpload(state)
export const selectCanStartDeadlineJob = (state: RootState) => canStartDeadlineJob(state)

export const selectEditingRoomset = createSelector(
  (state: RootState) => state.roomsetsAdmin.editingRoomset,
  (roomset) => {
    return SeamlessImmutable.asMutable(roomset) as Roomset
  }
)

export const selectUploadConvertedOrDone = createSelector(
  (state: RootState) => getUpload(state),
  (state: RootState) => getDeadlineJobs(state),
  (upload, jobStatuses) => {
    const completedConvert = jobStatuses.length && jobStatuses.length === jobStatuses.filter(job => job.status === 'Completed').length
    const uploadDone = upload && upload.extractStatus === 'EXTRACT_DONE'
    const uploadConverted = Boolean(uploadDone && completedConvert && _last(jobStatuses).type === 'roomset-post-script')
    return {
      completedConvert,
      uploadDone,
      uploadConverted,
    }
  }
)
