import fetch from '../../../utils/fetch'
import client from '../storage-api-client'

export const update = (payload) => (
  fetch('/api/patterns/update', {
    method: 'POST',
    body: JSON.stringify(payload)
  })
    .then((res) => res.json())
)

export const get = (id) => (
  fetch(`/api/patterns/${id}`)
    .then((res) => res.json())
)

export const remove = (id) => (
  fetch(`/api/patterns/remove/${id}`)
    .then((res) => res.json())
)

export const retry = (id) => (
  fetch(`/api/patterns/retry/${id}`)
    .then((res) => res.json())
)

export const getAll = (ids) => (
  fetch('/api/patterns', {
    method: 'POST',
    body: JSON.stringify(ids)
  })
    .then((res) => res.json())
)

export const getAvailable = (projectId) => (
  fetch(`/api/patterns/getAvailable${projectId ? '/' + projectId : ''}`)
    .then((res) => res.json())
)

export const addToProject = (ids, projectId) => (
  fetch('/api/patterns/addToProject', {
    method: 'POST',
    body: JSON.stringify({
      ids,
      projectId
    })
  })
    .then((res) => res.json())
)

/**
 * Upload to storage api
 * @param  {Array} files           An array of files
 * @param  {Object} eventHandlers  An object with eventHandlers { onUploadProgress, onHashProgress, onRequest }
 * @return {Promise}               A promise which resolves with the manifestKey from the upload
 */
export const upload = (files, eventHandlers) => (
  client.upload(files, eventHandlers)
)

export const convert = (manifestKey, data) => (
  fetch('/api/patterns/uploaded', {
    method: 'POST',
    body: JSON.stringify({
      manifestKey,
      ...data
    })
  })
    .then((res) => res.json())
)
