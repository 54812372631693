import React from 'react'
import cs from 'classnames'

type Props = {
  isOpen: boolean

  /** React element which will be the visible clickable representation for the menu */
  menuTriggerRef: React.RefObject<Element>,
  /** Position in relation to the button for the menu */
  position: 'left' | 'right' | 'bottom' | 'top'
  /** Class for root element */
  className?: {
    /** Root div of the component */
    root?: string
    /** div wrapping the children */
    childrenRoot?: string
  }
  onClose: () => void
  /** The content of the overlay menu */
  children: React.ReactNode
}

type MenuPosition = {
  top: number
  left: number
}

/**
 * This component gives an overlay menu which can be used with any content and any trigger
 * Known issue -> position fixed will not work as intended (relative to viewport) when element is within css-transition
 * Menu does not follow trigger element if used in scroll container
 */
export function OverlayMenu (props: Props) {
  const overlayRef = React.useRef<HTMLDivElement>(null)
  const [menuPosition, setMenuPosition] = React.useState<MenuPosition>({
    top: 0,
    left: 0
  })

  React.useEffect(() => {
    if (props.menuTriggerRef &&
        props.menuTriggerRef.current &&
        overlayRef &&
        overlayRef.current &&
        props.isOpen
    ) {
      setMenuPosition(getPos())
    }
  }, [props.isOpen])

  if (!props.isOpen) return null

  const getPos = (): MenuPosition => {
    let position = props.position
    let offSet = 0

    if (overlayRef.current && props.menuTriggerRef.current) {
      const menuTrigger = props.menuTriggerRef.current.getBoundingClientRect()
      const overlayMenu = overlayRef.current.getBoundingClientRect()

      if (menuTrigger.top + overlayMenu.height > window.innerHeight) {
        if (props.position === 'bottom') {
          position = 'top'
        } else {
          offSet = offSet - overlayMenu.height + menuTrigger.height
        }
      }
      if (menuTrigger.top - overlayMenu.height < 0) {
        position = 'bottom'
      }

      switch (position) {
        case 'bottom':
          return {
            top: menuTrigger.bottom,
            left: menuTrigger.left - (overlayMenu.width - menuTrigger.width)
          }
        case 'left':
          return {
            top: menuTrigger.top + offSet,
            left: menuTrigger.left - overlayMenu.width
          }
        case 'top':
          return {
            top: menuTrigger.top - overlayMenu.height,
            left: menuTrigger.left - (overlayMenu.width - menuTrigger.width)
          }
        default:
          return {
            top: menuTrigger.top + offSet,
            left: menuTrigger.right
          }
      }
    }
    return {
      top: 0,
      left: 0
    }
  }

  React.useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      if (event.target instanceof Node && (
        (props.menuTriggerRef && props.menuTriggerRef.current?.contains(event.target)) ||
        (overlayRef && overlayRef.current?.contains(event.target))
      )) {
        return
      }

      props.onClose()
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  })

  return props.isOpen && (
    <div
      ref={overlayRef}
      style={{
        zIndex: 1,
        visibility: (overlayRef && overlayRef.current ? 'visible' : 'hidden'),
        ...(props.menuTriggerRef ? menuPosition : {}),
      }}
      className={cs(
        props.className?.childrenRoot,
        'fixed bg-white paper-1 p2',
      )}
      data-testid="overlay-menu"
    >
      {props.children}
      <div className='mtn1' />
    </div>
  )
}

type OverlayMenuItemProps = {
  title: string
  shortCode?: string
  style?: React.CSSProperties | undefined
  className?: string
  disabled?: boolean
  handleClick: () => void
  onMouseEnter?: (event: React.MouseEvent) => void
  onMouseLeave?: (event: React.MouseEvent) => void
}

export function OverlayMenuItem (props: OverlayMenuItemProps) {
  return (
    <div
      onClick={props.handleClick}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      style={props.style}
      className={cs(`f7 flex items-center justify-between pointer c-gray-hover ease-in-out pb1 ${props.className}`, [{ disabled: props.disabled }])}
    >
      {!props.shortCode && props.title}
      {props.shortCode && (
        <>
          <span className='pr4'>{props.title}</span>
          <span className='c-gray'>{props.shortCode}</span>
        </>
      )}
    </div>
  )
}
