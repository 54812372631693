import React from 'react'
import InputText from '../../../../common/form/input-text'
import IconArrowDown from '../../../../common/icons/icon-arrow-down'
import IconArrowRight from '../../../../common/icons/icon-arrow-right'
import IconCube from '../../../../common/icons/icon-cube'
import NumericPin from '../../../../common/Numeric-pin'
import { AppearanceGroupClassLabel } from './appearance-group-class-label'
import { AppearanceGroupRow } from './appearance-group-row'
import { AppearanceGroupThumbnail } from './appearance-group-thumbnail'
import { AppearanceGroup, Part } from '../appearance-groups-connect'
import { appearanceGroupStyles } from '../appearance-group-styles'
import cs from 'classnames'
import colors from '../../../../../../css/colors'
import InputNumber from '../../../../common/form/input-number'
import styled from 'styled-components'
import IconPantoneColor from '../../../../common/icons/pantone-color/icon-pantone-color'

type Props = {
  appearanceGroup: AppearanceGroup
  pickerSelection: string[]
  isAppearanceGroupOpen: boolean
  isPartsOpen: boolean
  isCostAndAnnotationsOpen: boolean
  selectFromUuids: (uuid: string[], appendSelection?: boolean) => void
  disableKeyboardListeners: () => void
  enableKeyboardListeners: () => void
  updateAnnotation: (appearanceGroup: AppearanceGroup, annotationText: string) => void
  updateCostEstimation: (id: string, areaCost: number, volumeCost: number) => void
  toggleIsPartsOpen: (appearanceGroupId: string) => void
  toggleIsAppearanceGroupOpen: (appearanceGroupId: string) => void
  toggleIsCostAndAnnotationsOpen: (appearanceGroupId: string) => void
  setMaterial: (partUUID: string) => void
  setColor: (id: string) => void
  setPattern: (id: string) => void
  isDragging: boolean
  handleDragEnd: () => void
  handleDragStart: () => void
  getSelectionMode: any
}

const DragDropWrapper = styled(AppearanceGroupRow)<{isdraging: number, dragover: number}>`
  position: relative;
  &:before {
    display: 'block';
    content: '';
    position: absolute;
    top: 2px;
    right: 2px;
    bottom: 2px;
    left: 2px;
    border: ${({ isdraging }) => isdraging && '2px dashed var(--black25)'};
    border-color: ${({ dragover }) => dragover && colors.black};
    background-color: ${({ isdraging, dragover }) => isdraging && dragover ? colors.white25 : 'transparent'};
  }
`

export function AppearanceGroupCollection (props: Props) {
  const [dragOver, setDragOver] = React.useState(false)

  /**
   * Check how many parts in the appearance group that can be cost estimated
   * A part can be cost estimated if volume or area is not undefined
   */
  function getNumberOfPartsWithCostEstimation (parts: Part[]) {
    let result = 0
    parts.forEach(part => {
      if (part.volume || part.area) {
        result = result + 1
      }
    })
    return result
  }

  async function handleDrop (event: React.SyntheticEvent, appearanceGroup: AppearanceGroup) {
    event.preventDefault()
    if (!props.getSelectionMode) return
    await props.setMaterial(appearanceGroup.materialId)
    if (appearanceGroup.color && appearanceGroup.color.id) {
      await props.setColor(appearanceGroup.color.id)
    }
    if (appearanceGroup.pattern) {
      await props.setPattern(appearanceGroup.pattern.id)
    }
    props.selectFromUuids([])
    props.handleDragEnd()
    setDragOver(false)
  }

  const numberOfPartsWithCostEstimation = getNumberOfPartsWithCostEstimation(props.appearanceGroup.parts)
  const numberOfPartsWithoutCostEstimation = props.appearanceGroup.parts.length - numberOfPartsWithCostEstimation
  return (
    <div key={props.appearanceGroup.id} className={cs('f7')}>
      <DragDropWrapper
        onDrop={(event) => handleDrop(event, props.appearanceGroup)}
        onDragOver={(event) => { event.preventDefault(); setDragOver(true) }}
        onDragLeave={(event) => { event.preventDefault(); setDragOver(false) }}
        onClick={e => {
          const isMultiSelect = (e.ctrlKey || e.shiftKey || e.metaKey)
          if (!props.appearanceGroup.isSelected) {
            props.selectFromUuids(props.appearanceGroup.parts.map(part => part.id), isMultiSelect)
          } else {
            if (props.pickerSelection.length > 1 && !isMultiSelect) {
              props.selectFromUuids(props.appearanceGroup.parts.map(part => part.id))
            } else {
              props.selectFromUuids(props.pickerSelection.filter(pickerId => !props.appearanceGroup.parts.map(part => part.id).includes(pickerId)))
            }
          }
        }}
        isdraging={props.isDragging ? 1 : 0}
        dragover={dragOver ? 1 : 0}
        className={cs(`${props.appearanceGroup.isSelected ? 'bg-primary-lighter' : 'bg-gray-light'}`)}
      >
        <div style={appearanceGroupStyles.arrowContainer} onClick={(e: any) => {
          e.stopPropagation()
          props.toggleIsAppearanceGroupOpen(props.appearanceGroup.id)
        }}>
          {props.isAppearanceGroupOpen ? <IconArrowDown size={11} /> : <IconArrowRight size={11} />}
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1, minWidth: 0 }}>
          <div style={appearanceGroupStyles.materialType}>
            <AppearanceGroupThumbnail styles={appearanceGroupStyles.icon} appearanceGroup={props.appearanceGroup} />
            <span title={props.appearanceGroup.name} className='truncate' style={{ fontSize: 12 }}>{props.appearanceGroup.name}</span>
          </div>
          {props.appearanceGroup.color && !props.appearanceGroup.pattern &&
            <div style={appearanceGroupStyles.materialType} key={props.appearanceGroup.color.id}>
              <div
                style={{
                  ...props.appearanceGroup.color.style,
                  ...appearanceGroupStyles.icon,
                  borderRadius: 2,
                  marginRight: props.appearanceGroup.color.isPrototypeFabricColor ? 4 : 8
                }}
              />
              {props.appearanceGroup.color.isPrototypeFabricColor &&
                  <div title='Non-standard textile color' className='pr1 flex' style={{ paddingLeft: '4px', zIndex: 1 }} >
                    <IconPantoneColor/>
                  </div>
              }
              <span
                title={props.appearanceGroup.color.name || props.appearanceGroup.color.ncsName || props.appearanceGroup.color.pantoneTitle}
                className='truncate' style={{ fontSize: 12 }}
              >
                {props.appearanceGroup.color.name || props.appearanceGroup.color.ncsName || props.appearanceGroup.color.pantoneTitle}
              </span>
            </div>
          }
          {props.appearanceGroup.pattern &&
            <div style={appearanceGroupStyles.materialType} key={props.appearanceGroup.pattern.id}>
              <img
                src={props.appearanceGroup.pattern.thumbnailSrc || ''}
                style={appearanceGroupStyles.icon}
              />
              <span title={props.appearanceGroup.pattern.name} className='truncate' style={{ fontSize: 12 }}>
                {props.appearanceGroup.pattern.name}
              </span>
            </div>
          }
        </div>
        <div className='flex'>
          <div style={{ width: 28, display: 'flex', alignItems: 'center' }}>
            <NumericPin pStyle={{ margin: 0 }} number={props.appearanceGroup.parts.length} title={props.appearanceGroup.parts.length + ' parts in appearance group'} />
          </div>
          <div style={{ width: 76, display: 'flex', justifyContent: 'flex-start' }}>
            <AppearanceGroupClassLabel materialClass={props.appearanceGroup.materialClass} />
          </div>
        </div>
      </DragDropWrapper>
      {props.isAppearanceGroupOpen &&
      <>
        <AppearanceGroupRow
          style={{ paddingLeft: 14 }}
          onClick={() => {
            props.toggleIsCostAndAnnotationsOpen(props.appearanceGroup.id)
          }}
        >
          <p style={appearanceGroupStyles.partsHeader}>Cost and Annotations</p>
          {props.isCostAndAnnotationsOpen ? <IconArrowDown size={9} style={appearanceGroupStyles.arrow} /> : <IconArrowRight size={9} style={appearanceGroupStyles.arrow} />}
        </AppearanceGroupRow>
        {props.isCostAndAnnotationsOpen && <div style={appearanceGroupStyles.materialContainer}>
          <p style={appearanceGroupStyles.materialContainerHeader}>Surface cost</p>
          <div>
            <InputNumber
              onFocus={props.disableKeyboardListeners}
              onBlur={props.enableKeyboardListeners}
              disabled={!props.appearanceGroup.parts.some(part => part.area)}
              value={props.appearanceGroup.areaCost}
              min={0}
              max={Number.MAX_VALUE}
              onChange={value => {
                props.updateCostEstimation(props.appearanceGroup.materialId, value, props.appearanceGroup.volumeCost)
              }}
            /> /m<sup>2</sup>
          </div>
          <p style={appearanceGroupStyles.materialContainerHeader}>Carrier cost</p>
          <div>
            <InputNumber
              onFocus={props.disableKeyboardListeners}
              onBlur={props.enableKeyboardListeners}
              disabled={!props.appearanceGroup.parts.some(part => part.volume)}
              value={props.appearanceGroup.volumeCost}
              min={0}
              max={Number.MAX_VALUE}
              onChange={value => {
                props.updateCostEstimation(props.appearanceGroup.materialId, props.appearanceGroup.areaCost, value)
              }}
            /> /m<sup>3</sup>
          </div>
          <p style={appearanceGroupStyles.materialContainerHeader}>Total cost</p>
          <span style={{ color: colors.errorLightAlt, fontSize: 10 }}>
            {numberOfPartsWithCostEstimation === 0 && 'Geometry cost information is missing'}
          </span>
          <span>
            {numberOfPartsWithCostEstimation > 0 && `${isNaN(props.appearanceGroup.totalCost) ? 0 : props.appearanceGroup.totalCost.toFixed(2)}`}
          </span>
          <span style={{ color: colors.errorLightAlt, fontSize: 10 }}>
            {numberOfPartsWithoutCostEstimation > 0 && numberOfPartsWithCostEstimation !== 0 && `${numberOfPartsWithoutCostEstimation} ${(numberOfPartsWithoutCostEstimation) === 1 ? 'part' : 'parts'} in this group can't be estimated`}
          </span>
          <p style={appearanceGroupStyles.materialContainerHeader}>Annotation</p>
          <InputText
            onFocus={props.disableKeyboardListeners}
            onBlur={props.enableKeyboardListeners}
            value={props.appearanceGroup.annotation.annotationText}
            placeholder='Add annotation...'
            onChange={(value: any) => {
              props.updateAnnotation(props.appearanceGroup, value)
            }}/>
        </div>}
        <div>
          <AppearanceGroupRow
            className={props.appearanceGroup.parts.some((e) => e.isSelected) ? 'bg-primary-lighter' : colors.white}
            style={{ paddingLeft: 14 }}
            onClick={() => {
              props.toggleIsPartsOpen(props.appearanceGroup.id)
            }}
          >
            <p style={appearanceGroupStyles.partsHeader}>Parts</p>
            {props.isPartsOpen ? <IconArrowDown size={9} style={appearanceGroupStyles.arrow} /> : <IconArrowRight size={9} style={appearanceGroupStyles.arrow} />}
          </AppearanceGroupRow>
          {props.isPartsOpen && props.appearanceGroup.parts.map(part => {
            return (
              <AppearanceGroupRow
                draggable
                onDragStart={() => {
                  props.handleDragStart()
                  if (props.pickerSelection.length < 2) {
                    props.selectFromUuids([part.id])
                  }
                }}
                onDragEnd={() => props.handleDragEnd()}
                key={part.id}
                className={part.isSelected ? 'bg-primary-light' : colors.white}
                onClick={e => {
                  const isMultiSelect = (e.ctrlKey || e.shiftKey || e.metaKey)
                  if (!part.isSelected) {
                    props.selectFromUuids([part.id], isMultiSelect)
                  } else {
                    if (props.pickerSelection.length > 1 && !isMultiSelect) {
                      props.selectFromUuids([part.id])
                    } else {
                      props.selectFromUuids(props.pickerSelection.filter(selection => selection !== part.id))
                    }
                  }
                }}
                style={{ borderBottom: 0, borderTop: 0 }}
              >
                <div>
                  <IconCube size={12} style={appearanceGroupStyles.iconCube} />
                </div>
                <span title={part.name} className='truncate' style={{ paddingBottom: 3, fontSize: 11, userSelect: 'none' }}>{part.name}</span>
              </AppearanceGroupRow>
            )
          })}
        </div>
      </>
      }
    </div>
  )
}
