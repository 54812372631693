import _get from 'lodash/get'
import _forEach from 'lodash/forEach'
import _includes from 'lodash/includes'
import _orderBy from 'lodash/orderBy'
import _keyBy from 'lodash/keyBy'
import _filter from 'lodash/filter'

import * as fromProjectsSelectors from '../projects/selectors'

const getLocalState = (state) => state.folders

/* ENTRIES */
const getEntries = (state) =>
  _keyBy(_orderBy(getLocalState(state).entries, ['type', 'createdAt'], ['asc', 'desc']), 'id')
export const getEntriesInProject = (state) =>
  _filter(getEntries(state), entry =>
    entry.projectId === fromProjectsSelectors.getCurrentId(state))

/* CURRENT */
const getCurrentId = (state) => getLocalState(state).currentId
const getCurrentEntry = (state) => _get(getEntries(state), getCurrentId(state), {})

/* COMBINATIONS */
export const getCurrentPatterns = (state) => {
  const projectPatterns = fromProjectsSelectors.getPatterns(state)
  const currFolderPatternIds = getCurrentEntry(state).patterns

  const patterns = []
  _forEach(projectPatterns, pattern => {
    if (currFolderPatternIds && _includes(Object.keys(currFolderPatternIds), pattern.id)) {
      patterns.push(pattern)
    }
  })
  return patterns
}

export const getCurrentCombinations = (state) => {
  const projectCombs = fromProjectsSelectors.getCombinations(state)
  const currFolderCombIds = getCurrentEntry(state).combinations

  const combs = []
  _forEach(projectCombs, (comb) => {
    if (currFolderCombIds && _includes(Object.keys(currFolderCombIds), comb.id)) {
      combs.push(comb)
    }
  })
  return combs
}
