import store from '../../stores'

import * as fromRoomsets from '../../stores/ducks/roomsets'
import { setEditRoomsetId } from '../../stores/ducks/roomsets-admin'

export default function handleRoomsets (msg) {
  store.dispatch(fromRoomsets.receive([msg.doc]))
  if (msg.doc.id === store.getState().roomsetsAdmin.currentId) {
    store.dispatch(setEditRoomsetId(msg.doc.id))
  }
}
