import React from 'react'

import Grid from '../grid/grid'

const PanelGrid = ({
  panelWidth,
  children
}) => (
  <Grid
    columns={[panelWidth, '1fr', panelWidth]}
    rows={['1fr']}
    gridGap={0}
    className='overflow-hidden'
    style={{
      height: '100%',
      width: '100vw',
      gridTemplate: `1fr / ${panelWidth}px 1fr ${panelWidth}px`
    }}
  >
    {children}
  </Grid>
)

export default PanelGrid
