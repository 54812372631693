import React from 'react'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import cs from 'classnames'

import * as fromImagePackage from '../../../../stores/ducks/image-packages'

import * as fromThreeviewerFilesSelectors from '../../../../stores/ducks/threeviewer/files/selectors'
import * as fromImagePackageSelectors from '../../../../stores/ducks/image-packages/selectors'
import * as fromCombinationsSelectors from '../../../../stores/ducks/combinations/selectors'

import { PanelSection, PanelSectionHeader, PanelSectionContent } from '../../../common/panels/panel-section'
import AutoFill from '../../../common/grid/AutoFill'
import { StorageApiThumbnail } from '../../../common/storage-api-image'

const StylesPanel = ({
  selectBatch,
  isSelectDisabled,
  activeBatchId,
  styles,
  isNewImagePackage
}) => (
  <PanelSection key='styles' className='overflow-auto'>
    <PanelSectionHeader noPaddingBottom={!isNewImagePackage} title='Styles' />
    {!isNewImagePackage && <p className="px2 pb2 f6">When changing style, a new image package will be created. To see existing packages, go to the project overview.</p>}
    <PanelSectionContent>
      <AutoFill width={100}>
        {styles.map((item) => {
          return (
            <div
              key={item.id}
              className={cs(
                'pointer',
                'bg-white',
                {
                  'bs-secondary': item.id === activeBatchId,
                  disabled: isSelectDisabled
                }
              )}
            >
              <StorageApiThumbnail
                file={item.thumbnail}
                className='opacity-hover-lighter'
                onClick={() => !isSelectDisabled && selectBatch(item.id)}
              />
              <small className='mb1 f7 bold truncate block center'>
                {item.id}
              </small>
            </div>
          )
        })}
      </AutoFill>
    </PanelSectionContent>
  </PanelSection>
)

const mapStateToProps = createSelector(
  fromCombinationsSelectors.getCurrentEntry,
  (state) => {
    const currentCombination = fromCombinationsSelectors.getCurrentEntry(state)
    const imagePackageId = currentCombination.imagePackageId
    return fromImagePackageSelectors.getStylesById(imagePackageId)(state)
  },
  fromThreeviewerFilesSelectors.getIsSceneLoaded,
  (combination, styles, isSceneLoaded) => {
    const isNewImagePackage = combination.isMaster && !combination.parentId
    return {
      activeBatchId: combination.batchId,
      isSelectDisabled: !isSceneLoaded,
      styles,
      isNewImagePackage
    }
  }
)

const mapDispatchToProps = (dispatch) => ({
  selectBatch: (batchId) => dispatch(fromImagePackage.selectBatch(batchId))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StylesPanel)
