const { selectAnnotation } = require('./controls')
const { svgPlus, svgOpen, svgClosed } = require('./icons')

function SvgElement (activePath, passivePath, callback) {
  let enabled
  const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
  svg.setAttributeNS(null, 'height', 20)
  svg.setAttributeNS(null, 'width', 20)
  svg.style.float = 'right'

  const path = document.createElementNS('http://www.w3.org/2000/svg', 'path')
  path.setAttributeNS(null, 'stroke', 'none')
  path.setAttributeNS(null, 'height', 20)
  path.setAttributeNS(null, 'width', 20)
  path.setAttributeNS(null, 'fill', 'white')
  svg.appendChild(path)

  svg.onclick = (args) => {
    if (enabled) callback()
  }

  this.setEnabled = (enable) => {
    if (!enabled && enable) {
      path.setAttributeNS(null, 'opacity', 1)
      enabled = true
    } else if (enabled && !enable) {
      path.setAttributeNS(null, 'opacity', 0.1)
      enabled = false
    }
  }

  this.setActive = (active) => {
    if (active) {
      path.setAttributeNS(null, 'd', activePath)
      path.setAttributeNS(null, 'fill', '#FCDA32')
    } else {
      path.setAttributeNS(null, 'd', passivePath)
      path.setAttributeNS(null, 'fill', 'white')
    }
  }

  this.svgElement = svg

  this.setEnabled(true)
  this.setActive(false)
}

const AnnotationElement = function (groupId, annotation, manager) {
  const groupIdDiv = document.createElement('div')
  groupIdDiv.className = 'groupId'
  groupIdDiv.style.overflowWrap = 'break-word'
  groupIdDiv.appendChild(document.createTextNode(annotation.annotationText))

  const expandBtn = new SvgElement(
    svgOpen,
    svgClosed,
    () => {
      if (annotation.markers.filter(marker => marker.active).length < 1) {
        annotation.showSingleMarker = false
        expandBtn.setActive(true)
      } else {
        annotation.showSingleMarker = !annotation.showSingleMarker
        expandBtn.setActive(!annotation.showSingleMarker)
      }
      manager.emitter.emitRenderOnNextFrame()
    }
  )
  expandBtn.svgElement.classList.add('absolute', 'bottom-0', 'right-0')
  expandBtn.svgElement.style.cursor = 'pointer'
  expandBtn.svgElement.style.marginRight = '10px'
  expandBtn.svgElement.style.marginBottom = '10px'

  const activateButton = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
  activateButton.setAttribute('width', 20)
  activateButton.setAttribute('height', 20)
  activateButton.setAttribute('viewBox', '0 0 94 94')
  activateButton.setAttribute('size', '80')
  activateButton.innerHTML = '<path fill="currentcolor" d="' + svgPlus + '"></path>'
  activateButton.style.color = 'white'
  activateButton.style.cursor = 'pointer'
  activateButton.style.alignSelf = 'center'
  activateButton.style.display = 'flex'
  activateButton.style.padding = '5px'
  activateButton.title = annotation.annotationText
  activateButton.onclick = () => {
    this.setActive(true)
    this.select()
    // emit also
    selectAnnotation(annotation, manager.annotations, manager.emitter)
    if (manager.lastActiveNode) {
      manager.lastActiveNode.active = true
    }
    manager.emitter.emitRenderOnNextFrame()
  }

  const annotationDiv = document.createElement('div')
  annotationDiv.className = 'annotation'
  annotationDiv.setAttribute('data-id', groupId)

  this.isActive = function () {
    return this.active
  }

  const closeButton = document.createElement('i')
  closeButton.classList.add('scale', 'pointer', 'icon-cancel', 'absolute', 'top-0', 'right-0')
  closeButton.style.marginTop = '10px'
  closeButton.style.marginRight = '6px'
  closeButton.setAttribute('data-id-skip', 'skip-me')
  closeButton.onclick = () => {
    this.setActive(false)
    this.unselect()
    manager.emitter.emitClearSelect()
  }

  this.setActive = function (value) {
    this.active = value
    if (value) {
      clearChildren(annotationDiv)
      annotationDiv.appendChild(groupIdDiv)
      annotationDiv.appendChild(expandBtn.svgElement)
      annotationDiv.appendChild(closeButton)
      annotationDiv.classList.remove('annotation-closed')
      Object.assign(annotationDiv.style, {
        background: 'rgba(0, 0, 0, 0.8)',
        transition: 'background 0.1s ease-out',
        position: 'fixed',
        width: '200px',
        height: '150px',
        padding: '10px 30px 10px 10px',
        color: 'white',
        cursor: 'move',
        'border-radius': '.5em',
        'z-index': 0,
        display: 'block',
        'justify-content': 'initial',
        'align-items': 'initial'
      })
    } else {
      clearChildren(annotationDiv)
      annotationDiv.appendChild(activateButton)
      annotationDiv.classList.add('annotation-closed')
      Object.assign(annotationDiv.style, {
        background: 'rbga(0, 0, 0, 0.02)',
        transition: 'height 0.1s ease-out, width 0.4s ease-in, border-radius 0.3s ease-in',
        'border-radius': '1em',
        width: '22px',
        height: '22px',
        padding: '0px',
        cursor: 'move',
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center'
      })
    }
  }

  function clearChildren (el) {
    while (el.firstChild) el.removeChild(el.firstChild)
  }

  this.getPixelWidth = function () {
    return annotationDiv.clientWidth
  }
  this.getPixelHeight = function () {
    return annotationDiv.clientHeight
  }

  this.setText = function (text) {
    clearChildren(groupIdDiv)
    groupIdDiv.appendChild(document.createTextNode(text))
  }

  this.select = function () {
    annotationDiv.style.background = 'rgba(34, 92, 187, 1)'
    annotationDiv.style.zIndex = '1'
    annotationDiv.classList.add('annotation-selected')
  }

  this.unselect = function () {
    annotationDiv.style.background = 'rgba(0, 0, 0, 0.8)'
    annotationDiv.style.zIndex = '0'
    annotationDiv.classList.remove('annotation-selected')
  }

  this.setVisible = function (value) {
    annotationDiv.style.visibility = value ? 'visible' : 'hidden'
  }

  this.remove = function () {
    annotationDiv.parentElement.removeChild(annotationDiv)
  }

  this.setPosition = function (x, y) {
    annotationDiv.style.left = `${x - annotationDiv.clientWidth / 2 + manager.viewportOffsetLeft}px`
    annotationDiv.style.top = `${y - annotationDiv.clientHeight / 2 + manager.viewportOffsetTop}px`
  }

  this.getPixelPosition = function () {
    return { x: annotationDiv.getBoundingClientRect().x - manager.viewportOffsetLeft, y: annotationDiv.getBoundingClientRect().y - manager.viewportOffsetTop }
  }

  this.updateExpandButtonsState = function () {
    // 1. When there are only one marker, dont display the expand button.
    // 2. When there are more then one marker but there is only one marker visible,
    // then the expand button will be disabled to indicate that
    // 3. Otherwise the expand button is rendered as normal.
    if (annotation.markers.length <= 1) {
      expandBtn.svgElement.style.display = 'none'
    } else if (annotation.markers.length > 1 && annotation.markers.filter(m => m.visible).length <= 1) {
      expandBtn.setEnabled(false)
    } else {
      expandBtn.setEnabled(true)
      expandBtn.svgElement.style.display = 'inline-block'
    }
  }

  this.annotationDiv = annotationDiv
  this.setActive(true)
  this.updateExpandButtonsState()
  this.active = true
}

module.exports = AnnotationElement
