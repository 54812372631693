import React from 'react'
import cs from 'classnames'

import SpriteImage from '../common/sprite-image'
import { StorageApiThumbnail } from '../common/storage-api-image'
import Square from '../common/Square'

import colors from '../../../css/colors'

import _get from 'lodash/get'
import { Combination } from '../../stores/ducks/combinations/Combination'
import { StorageApiFile } from '../../utils/storage'

const bgGrayLight = colors.grayLight
const styles = {
  placeholderTitle: { height: 16, marginBottom: 2 },
  placeholderSubTitle: { height: 16 }
}

type Props = {
  title: string
  secondTitle?: string
  subTitle?: string
  thumbnail: StorageApiFile | string | null
  thumbnailOffset?: { spritesheet: string, x: number, y: number }
  project: any
  onSelect: () => void
  isSelected: boolean
  size?: 'small'
  placeholder?: string
  metadata?: any
  combinationType: Combination['combinationType']
  shared?: boolean
  versionNr?: number
  disabled?: boolean
  uuid?: string
  productName?: string
}

function Design (props: Props) {
  let backgroundColor = 'bg-white'
  if (props.metadata) {
    const saleEndStatus = _get(props.metadata, 'itemSalesEndsStatus')
    if (saleEndStatus === 'all ended') { backgroundColor = 'bg-error-light' }
    if (saleEndStatus === 'partially ended') { backgroundColor = 'bg-warn-lighter' }
  }

  const disabled = props.disabled ?? false
  const cssClasses = disabled ? 'muted bg-white' : cs('pointer opacity-hover-lighter-performant', backgroundColor, {
    'bs-secondary': props.isSelected
  })

  return (
    <div
      onClick={() => {
        if (!disabled) {
          props.onSelect()
        }
      }}
      // NOTE: could use "layer-friendly" classnames
      // https://www.html5rocks.com/en/tutorials/speed/layers/
      // removed paper-1 (box shadow)
      className={cssClasses}
      title={props.title + (props.secondTitle ? ` ${props.secondTitle}` : '') + (props.subTitle ? ` - ${props.subTitle}` : '')}
    >
      {
        !props.placeholder && props.thumbnailOffset ? (
          <SpriteImage
            src={props.thumbnailOffset.spritesheet}
            tileSize={128}
            tilesPerRow={8}
            offsetX={props.thumbnailOffset.x}
            offsetY={props.thumbnailOffset.y}
            spinnerSize='large'
          />
        ) : (
          <StorageApiThumbnail
            src={(typeof props.thumbnail === 'string') ? props.thumbnail : undefined}
            file={typeof props.thumbnail !== 'string' ? props.thumbnail : undefined}
            constrainRatio={[1, 1]}
          />
        )
      }
      {!!props.placeholder && <Square backgroundColor={bgGrayLight} />}

      <div
        data-testid='design-card'
        className={cs('p1', {
          f7: props.size === 'small',
          f6: props.size !== 'small'
        })}
      >
        {props.placeholder
          ? (<div className='bg-gray-light' style={styles.placeholderTitle} />)
          : (<div className='my0 truncate bold'>{props.title}</div>)
        }
        {!!props.secondTitle && !props.placeholder && (
          <div className='my0 line-height truncate'>{props.secondTitle}</div>
        )}
        {props.uuid &&
          <div className='my0 line-height truncate'>{props.uuid}</div>
        }
        {props.placeholder
          ? (<div className='mt1 mr2 bg-gray-light' style={styles.placeholderSubTitle} />)
          : (props.title !== props.subTitle && <div className='mt1 truncate'>{props.subTitle}</div>)
        }
        {props.combinationType === 'variant' && props.project && (
          <div className='truncate'>{props.project.title}</div>
        )}
        {props.productName &&
          <div className='truncate'>{props.productName}</div>
        }
        {props.versionNr && <div className='truncate'>Version {props.versionNr}</div>}
      </div>
    </div>
  )
}

export default Design
