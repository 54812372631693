import React from 'react'

const IconSettings = props => (
  <svg
    width='1em'
    height='1em'
    viewBox='0 0 18 18'
    {...props}
  >
    <g fill={props.color}>
      <path d='M18.0025,10.4997084 L18.0025,7.4997917 L16.3480459,7.4997917 C16.1545513,6.54281827 15.7720619,5.65634289 15.2500764,4.86886476 L16.4260437,3.69439738 L14.3066026,1.57195634 L13.1291353,2.74792367 C12.3431572,2.22743813 11.4566818,1.84494875 10.5012083,1.65295409 L10.5012083,0 L7.5012916,0 L7.5012916,1.65295409 C6.54581818,1.84644871 5.65784285,2.22893809 4.87186468,2.74942363 L3.69589734,1.57495625 L1.57645621,3.6973973 L2.75092359,4.87036472 C2.22893809,5.65634289 1.84644871,6.54431822 1.65445405,7.4997917 L0,7.4997917 L0,10.4997084 L1.65445405,10.4997084 C1.84794867,11.4566818 2.23043805,12.3431572 2.75242355,13.1306353 L1.57645621,14.3081026 L3.69889726,16.4275437 L4.87336464,15.2515764 C5.6593428,15.7735619 6.54581818,16.1545513 7.5027916,16.346546 L7.5027916,18.001 L10.5027083,18.001 L10.5027083,16.346546 C11.4596817,16.1515514 12.3461571,15.770562 13.1321352,15.2485765 L14.3081026,16.4230438 L16.4275437,14.3006028 L15.2530763,13.1276354 C15.7735619,12.3416572 16.1560512,11.4551818 16.3480459,10.4997084 L18.0025,10.4997084 Z M8.99975,11.9996667 C7.3422961,11.9996667 5.99983335,10.655704 5.99983335,8.99975 C5.99983335,7.343796 7.3422961,5.99983335 8.99975,5.99983335 C10.655704,5.99983335 11.9996667,7.343796 11.9996667,8.99975 C11.9996667,10.655704 10.655704,11.9996667 8.99975,11.9996667 Z' />
    </g>
  </svg>
)

IconSettings.defaultProps = {
  color: 'currentcolor'
}

export default IconSettings
