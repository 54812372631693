const THREE = require('three')
const Go3DShaderChunk = require('../shaders/ShaderChunk')

module.exports = `
  #define STANDARD

  #ifdef PHYSICAL
    #define REFLECTIVITY
    #define CLEARCOAT
    #define TRANSPARENCY
  #endif

  uniform vec3 diffuse;
  uniform vec3 emissive;
  uniform float roughness;
  uniform float metalness;
  uniform float opacity;
  uniform float time;

  uniform float colorTextureMix;
  uniform vec3 refractColor;
  uniform vec4 markerPoint;

  varying mat4 vInstanceMatrix;

  #ifdef TRANSPARENCY
    uniform float transparency;
  #endif

  #ifdef REFLECTIVITY
    uniform float reflectivity;
  #endif

  #ifdef CLEARCOAT
    uniform float clearcoat;
    uniform float clearcoatRoughness;
  #endif

  #ifdef USE_SHEEN
    uniform vec3 sheen;
  #endif

  varying vec3 vWorldPosition;
  varying vec3 vViewPosition;

  #ifndef FLAT_SHADED
    varying vec3 vNormal;
    #ifdef USE_TANGENT
      varying vec3 vTangent;
      varying vec3 vBitangent;
    #endif
  #endif

  ${THREE.ShaderChunk.common}
  ${THREE.ShaderChunk.packing}
  ${THREE.ShaderChunk.dithering_pars_fragment}
  ${THREE.ShaderChunk.color_pars_fragment}
  ${THREE.ShaderChunk.uv_pars_fragment}
  ${THREE.ShaderChunk.uv2_pars_fragment}
  ${THREE.ShaderChunk.map_pars_fragment}

  ${Go3DShaderChunk.decal_map_pars_fragment}

  ${THREE.ShaderChunk.alphamap_pars_fragment}
  ${THREE.ShaderChunk.aomap_pars_fragment}
  ${THREE.ShaderChunk.lightmap_pars_fragment}
  ${THREE.ShaderChunk.emissivemap_pars_fragment}
  ${THREE.ShaderChunk.bsdfs}
  ${THREE.ShaderChunk.cube_uv_reflection_fragment}
  ${THREE.ShaderChunk.envmap_common_pars_fragment}
  ${Go3DShaderChunk.envmap_pars_fragment}
  ${Go3DShaderChunk.refractmap_pars_fragment}
  ${THREE.ShaderChunk.fog_pars_fragment}
  ${Go3DShaderChunk.template_ao_pars_fragment}
  ${THREE.ShaderChunk.lights_pars_begin}
  ${Go3DShaderChunk.envmap_physical_pars_fragment}
  ${THREE.ShaderChunk.lights_physical_pars_fragment}
  ${THREE.ShaderChunk.shadowmap_pars_fragment}
  ${THREE.ShaderChunk.bumpmap_pars_fragment}
  ${THREE.ShaderChunk.clearcoat_pars_fragment}
  ${THREE.ShaderChunk.roughnessmap_pars_fragment}
  ${THREE.ShaderChunk.metalnessmap_pars_fragment}
  ${THREE.ShaderChunk.logdepthbuf_pars_fragment}
  ${THREE.ShaderChunk.clipping_planes_pars_fragment}
  ${Go3DShaderChunk.triplanar_map_pars_fragment}
  ${Go3DShaderChunk.normalmap_pars_fragment}
  ${Go3DShaderChunk.model_fading_pars_fragment}

  void main() {

    ${THREE.ShaderChunk.clipping_planes_fragment}
    vec4 diffuseColor = vec4( diffuse, opacity );
    ReflectedLight reflectedLight = ReflectedLight( vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ) );
    vec3 totalEmissiveRadiance = emissive;

    ${Go3DShaderChunk.triplanar_blend_weights_fragment}
    ${THREE.ShaderChunk.logdepthbuf_fragment}
    ${Go3DShaderChunk.map_fragment}
    ${THREE.ShaderChunk.color_fragment}
    ${THREE.ShaderChunk.alphamap_fragment}
    ${THREE.ShaderChunk.alphatest_fragment}
    ${THREE.ShaderChunk.specularmap_fragment}
    ${Go3DShaderChunk.roughnessmap_fragment}
    ${THREE.ShaderChunk.metalnessmap_fragment}
    ${THREE.ShaderChunk.normal_fragment_begin}
    ${Go3DShaderChunk.normal_fragment_maps}
    ${THREE.ShaderChunk.clearcoat_normal_fragment_begin}
    ${THREE.ShaderChunk.clearcoat_normal_fragment_maps}
    ${Go3DShaderChunk.emissivemap_fragment}

    // accumulation
    ${THREE.ShaderChunk.lights_physical_fragment}
    ${THREE.ShaderChunk.lights_fragment_begin}
    ${THREE.ShaderChunk.lights_fragment_maps}
    ${THREE.ShaderChunk.lights_fragment_end}

    // modulation
    ${THREE.ShaderChunk.aomap_fragment}

    ${Go3DShaderChunk.model_fading_fragment}
    ${Go3DShaderChunk.alphamap_fragment}

    ${Go3DShaderChunk.refractmap_fragment}

    vec3 outgoingLight = reflectedLight.directDiffuse + reflectedLight.indirectDiffuse + reflectedLight.directSpecular + reflectedLight.indirectSpecular + totalEmissiveRadiance;

    ${Go3DShaderChunk.transparent_fragment}

    gl_FragColor = vec4( outgoingLight, finalAlpha);
    ${THREE.ShaderChunk.tonemapping_fragment}
    ${THREE.ShaderChunk.encodings_fragment}
    ${THREE.ShaderChunk.fog_fragment}
    ${THREE.ShaderChunk.premultiplied_alpha_fragment}
    ${Go3DShaderChunk.marker_point_fragment}
    ${Go3DShaderChunk.show_diffuse_only_fragment}
    ${THREE.ShaderChunk.dithering_fragment}
  }
`
