import React from 'react'

import InputGroup from '../common/form/input-group'
import Dropdown from '../common/form/input-dropdown'
import Button from '../common/button'
import Checkbox from '../common/form/input-checkbox'
import InputText from '../common/form/input-text'
import Label from '../common/form/label'
import KeyboardListener, { KeyCode, KeyBinding } from '../common/keyboard-listener'

import utils from './utils'

import Client from '@inter-ikea-digital/iig-rpd-dpd-packages-storage-api-client'
const client = Client('/api/storage')

class GeometryUploadView extends React.Component {
  constructor (props) {
    super(props)

    const result = utils.getResult(props.files)

    const fileType = /.*[.](.*)/.exec((result.assemblyOption || '').toLowerCase())
    const isObj = fileType === null || fileType[1] === 'obj'

    this.state = {
      ...result,
      isSoftProduct: isObj,
      isCancelled: false,
      error: undefined,
      uploadProgress: undefined,
      hashProgress: undefined,
      request: undefined,
      overrideModelCenter: false
    }
  }

  handleTitleChange (data) {
    this.setState({ title: data })
  }

  handleAssemblyFileChange (data) {
    this.setState({ assemblyOption: data })
  }

  handleSoftProductChange (data) {
    this.setState({ isSoftProduct: data })
  }

  handleOverrideCenterChange (data) {
    this.setState({ overrideModelCenter: data })
  }

  handleUploadProgress (progress) {
    this.props.onUploadProgress && this.props.onUploadProgress(progress)
  }

  handleHashProgress (progress) {
    this.props.onHashProgress && this.props.onHashProgress(progress)
  }

  handleOnRequest (request) {
    this.props.onRequest && this.props.onRequest(request)
  }

  handleUploading () {
    const { onUploaded, onReset } = this.props
    const { isSoftProduct, title, assemblyOption, supportedFiles, overrideModelCenter } = this.state
    onReset()

    client.upload(supportedFiles, {
      onUploadProgress: this.handleUploadProgress.bind(this),
      onHashProgress: this.handleHashProgress.bind(this),
      onRequest: this.handleOnRequest.bind(this)
    })
      .then(manifestKey => {
        const data = {
          manifestKey,
          model: assemblyOption,
          overrideCenter: overrideModelCenter,
          uploadMetadata: {
            title,
            productType: isSoftProduct ? 'soft' : 'hard',
            splitFaces: true
          }
        }
        onUploaded(data)
      })
      .catch(error => {
        this.setState({ error: error.message })
      })
  }

  errorView () {
    const { error } = this.state
    const { onReset } = this.props

    return (
      <div>
        <h2 className='mt0 f3'>
          Error: Failed to upload files
        </h2>
        <p>Message: {error}</p>
        <div className='flex justify-end items-end mt3'>
          <Button
            btnType='primary'
            className='ml2'
            onClick={() => {
              onReset()
              this.setState({ error: undefined })
            }}
          >
            Ok
          </Button>
        </div>
      </div>
    )
  }

  successfulUploadView () {
    const { isSoftProduct, title, assemblyOption, assemblyFiles, overrideModelCenter } = this.state
    const { onReset } = this.props
    const fileType = /.*[.](.*)/.exec((assemblyOption || '').toLowerCase())
    const productTypeSoft = fileType === null || fileType[1] === 'obj'

    return (
      <div>
        <h2 className='mt0 f3'>
          Upload Geometry
        </h2>

        <InputGroup>
          <Label>
            Title
          </Label>
          <InputText
            className='mt1'
            onChange={this.handleTitleChange.bind(this)}
            value={title}
          />
        </InputGroup>

        {assemblyFiles.length > 1 && (
          <InputGroup>
            <Label>Assembly</Label>
            <Dropdown
              name='assemblyFiles'
              className='width-100 block'
              value={assemblyOption}
              options={assemblyFiles}
              onChange={this.handleAssemblyFileChange.bind(this)}
              optionKey='name'
              optionValue='name'
              optionLabel='name'
            />
          </InputGroup>
        )}

        <InputGroup className='mt2'>
          <Label>
            Conversion settings
          </Label>

          {productTypeSoft && (
            <Checkbox
              name='softProduct'
              checked={isSoftProduct}
              onChange={this.handleSoftProductChange.bind(this)}
              direction='horizontal'
              label='Use UV-mapping'
              className='checkboxNoBold'
            />
          )}

          <Checkbox
            name='overrideCenter'
            checked={overrideModelCenter}
            onChange={this.handleOverrideCenterChange.bind(this)}
            direction='horizontal'
            label='Override Model Center'
            className='checkboxNoBold'
          />
        </InputGroup>

        <div className='flex justify-end items-end mt3'>
          <Button onClick={onReset}>
            Cancel
          </Button>
          <Button
            data-testid='confirm-upload-geometry'
            btnType='primary'
            className='ml2'
            onClick={this.handleUploading.bind(this)}
          >
            Upload
          </Button>
        </div>

        <KeyboardListener
          keyboardEvent='keyup'
          bindings={[
            KeyBinding(KeyCode.enter, this.handleUploading.bind(this))
          ]}
        />

      </div>
    )
  }

  failedUploadView () {
    const {
      msg,
      files,
      supportedFileFormats,
      isInvalidFileError
    } = this.state

    return (
      <div>
        <h2 className='my0 mr3 f3 c-error'>
          {msg}
        </h2>
        <p className='bold mt3 mb0 f7'>Selected files:</p>
        <ul className='f7 overflow-auto' style={{ height: '10vh' }}>
          {files && files.map((file) => (
            <li key={file.name}>{file.name}</li>
          ))}
        </ul>
        {isInvalidFileError &&
          <div className='f7 mt3 p1 bg-secondary-lighter'>
            <i>Supported file formats: </i>
            <div className='mt1'>
              {supportedFileFormats}
            </div>
          </div>
        }
        <div className='mt2 clearfix flex justify-end items-baseline'>
          <Button data-testid='fail-upload-geometry' className='px3' btnType='primary' onClick={this.props.onReset}>
            Close
          </Button>
        </div>
      </div>
    )
  }

  render () {
    if (this.state.error) {
      return this.errorView()
    }

    return this.state.success ? this.successfulUploadView() : this.failedUploadView()
  }
}

export default GeometryUploadView
