import Immutable from 'seamless-immutable'
import { handleActions, createAction } from 'redux-actions'

import * as fromCombinations from '../combinations'

import { dataToMap } from '../../../utils'
import * as api from './api'

const error = createAction('renders/ERROR')
const fetching = createAction('renders/FETCHING')
const confirmReceive = createAction('renders/CONFIRM_RECEIVE')
export const receive = (docs, params = {}) => (dispatch) => dispatch(confirmReceive({ docs, params }))
export const postReceive = createAction('renders/POST_RECEIVE')
export const postRequest = createAction('renders/POST_REQUEST')
const confirmReplaceComments = createAction('renders/CONFIRM_REPLACE_COMMENTS')

export function remove (ids) {
  return (dispatch, getState, { api }) => {
    return api.renders.remove(ids)
      .then((json) => {
        dispatch(receive(json.renders))
        dispatch(fromCombinations.receive(json.combinations))
      })
      .catch((err) => dispatch(error(err)))
  }
}

export function retry (idOrIds) {
  return (dispatch) => {
    return api.retry([].concat(idOrIds))
      .then((payload) => dispatch(receive(payload)))
      .catch((err) => dispatch(error(err)))
  }
}

var initialState = Immutable({
  fetching: false,
  isRendering: false,
  entries: {},
  error: null
})

export default handleActions({
  [fetching]: (state) => state.merge({
    fetching: true
  }),
  [error]: (state, { payload }) => state.merge({
    error: payload,
    fetching: false
  }),
  [postReceive]: (state) => state.merge({
    isRendering: false
  }),
  [postRequest]: (state) => state.merge({
    isRendering: true
  }),
  [confirmReceive]: (state, { payload }) => state.merge({
    entries: dataToMap(payload.docs, payload.params),
    fetching: false
  }, { deep: true }),
  [confirmReplaceComments]: (state, { payload }) => state
    .setIn(['entries', payload.id, 'comments'], payload.comments)
}, initialState)
