import * as THREE from 'three'

// Get a random color
function randomColor () {
  return Math.floor(Math.random() * 16777216)
}

class LightCluster {
  constructor (bounds) {
    this.bounds = bounds
    this.spotLights = []
    this.pointLights = []
  }
}

function subdivideBox (box, levels) {
  if (levels === 0) return [box]

  const stepx = (box.max.x - box.min.x) * (1.0 / levels)
  const stepz = (box.max.z - box.min.z) * (1.0 / levels)

  const divisions = []

  for (let v = 0; v < levels; v++) {
    const minz = box.min.z + v * stepz

    for (let u = 0; u < levels; u++) {
      const minx = box.min.x + u * stepx
      divisions.push(new THREE.Box3(
        new THREE.Vector3(minx, box.min.y, minz),
        new THREE.Vector3(minx + stepx, box.max.y, minz + stepz)
      ))
    }
  }

  return divisions
}

// Adds a wireframe box to the scene.
function addAabbHelper (scene, box) {
  const dim = box.getSize(new THREE.Vector3())
  const geometry = new THREE.BoxGeometry(dim.x, dim.y, dim.z)
  const material = new THREE.MeshBasicMaterial({})
  const cube = new THREE.Mesh(geometry, material)
  box.getCenter(cube.position)
  const WF = new THREE.BoxHelper(cube, randomColor())
  scene.add(WF)
}

function createPointlightTexture (gridCells) {
  const maxLightCount = Math.max(...gridCells.map(c => c.pointLights.length))
  const lightByteCount = 20
  const gridCellCount = gridCells.length
  const gridCellOffset = maxLightCount * lightByteCount + 4

  const bytes = new Uint8Array(gridCellCount * gridCellOffset)
  const intView = new Int32Array(bytes.buffer)

  gridCells.forEach((cell, idx) => {
    const byteOffset = idx * gridCellOffset
    const wordOffset = byteOffset / 4

    // Set light count
    intView[wordOffset] = cell.pointLights.length
    cell.pointLights.forEach((light, lightIdx) => {
      const colorOffset = byteOffset + 4 + (lightIdx * lightByteCount)
      const posOffset = wordOffset + 2 + (lightIdx * lightByteCount / 4)
      // set color and intensity
      bytes[colorOffset + 0] = Math.floor(light.color.r * 255)
      bytes[colorOffset + 1] = Math.floor(light.color.g * 255)
      bytes[colorOffset + 2] = Math.floor(light.color.b * 255)
      bytes[colorOffset + 3] = Math.floor(light.intensity * 255)

      // Set position.
      intView[posOffset + 0] = Math.round(light.position.x * 100)
      intView[posOffset + 1] = Math.round(light.position.y * 100)
      intView[posOffset + 2] = Math.round(light.position.z * 100)
      intView[posOffset + 3] = Math.round(light.distance * 100)
    })
  })

  const texture = new THREE.DataTexture(bytes, gridCellOffset / 4, gridCellCount, THREE.RGBAFormat)
  texture.needsUpdate = true
  return texture
}

function createSpotlightTexture (gridCells) {
  const maxLightCount = Math.max(...gridCells.map(c => c.spotLights.length))
  const lightByteCount = 40
  const gridCellCount = gridCells.length
  const gridCellOffset = maxLightCount * lightByteCount + 4

  const bytes = new Uint8Array(gridCellCount * gridCellOffset)
  const intView = new Int32Array(bytes.buffer)

  gridCells.forEach((cell, idx) => {
    const byteOffset = idx * gridCellOffset
    const wordOffset = byteOffset / 4
    // Set light count
    intView[wordOffset] = cell.spotLights.length
    cell.spotLights.forEach((light, lightIdx) => {
      const colorOffset = byteOffset + 4 + (lightIdx * lightByteCount)
      const posOffset = wordOffset + 2 + (lightIdx * lightByteCount / 4)
      // set color and intensity
      bytes[colorOffset + 0] = Math.floor(light.color.r * 255)
      bytes[colorOffset + 1] = Math.floor(light.color.g * 255)
      bytes[colorOffset + 2] = Math.floor(light.color.b * 255)
      bytes[colorOffset + 3] = Math.floor(light.intensity * 255)

      const dir = new THREE.Vector3()
      dir.copy(light.target.position)
      dir.sub(light.position)
      dir.normalize()

      // Set position.
      intView[posOffset + 0] = Math.round(light.position.x * 100)
      intView[posOffset + 1] = Math.round(light.position.y * 100)
      intView[posOffset + 2] = Math.round(light.position.z * 100)

      // Set direction.
      intView[posOffset + 3] = Math.round(-dir.x * 100)
      intView[posOffset + 4] = Math.round(-dir.y * 100)
      intView[posOffset + 5] = Math.round(-dir.z * 100)

      intView[posOffset + 6] = Math.round(light.distance * 100)
      intView[posOffset + 7] = Math.round(Math.cos(light.angle) * 100)
      intView[posOffset + 8] = Math.round(Math.cos(light.penumbra) * 100)
    })
  })

  const texture = new THREE.DataTexture(bytes, gridCellOffset / 4, gridCellCount, THREE.RGBAFormat)
  texture.needsUpdate = true
  return texture
}

function sortPointLights (scene, pointLights, gridCells, debug) {
  pointLights.forEach((light, idx) => {
    const influence = new THREE.Sphere(light.position, light.distance)
    for (const cell of gridCells) {
      if (influence.intersectsBox(cell.bounds)) {
        cell.pointLights.push(light)
      }
    }
    if (debug) scene.add(new THREE.PointLightHelper(light, 32))
  })
}

// Checks if an aabb intersects with a spotlight.
function boxSpotlightIntersection (box, light) {
  const sphere = box.getBoundingSphere()

  const ldir = new THREE.Vector3()
  ldir.copy(light.target.position)
  ldir.sub(light.position)
  ldir.normalize()

  const bdir = new THREE.Vector3()
  bdir.copy(sphere.center)
  bdir.sub(light.position)
  const bdist = bdir.length()
  bdir.normalize()

  const cosa = Math.cos(ldir.dot(bdir))

  const projDistA = Math.min(cosa * bdist + sphere.radius, light.distance)
  const projDistB = Math.min(cosa * bdist, light.distance)

  if (projDistA < 0) return false

  const projPos = new THREE.Vector3()
  projPos.copy(light.position)
  projPos.addScaledVector(ldir, projDistB)

  const w = Math.tan(light.angle) * projDistA

  if (projPos.distanceTo(sphere.center) > (sphere.radius + w)) return false

  return true
}

function sortSpotLights (scene, spotLights, gridCells, debug) {
  spotLights.forEach((light, idx) => {
    for (const cell of gridCells) {
      if (boxSpotlightIntersection(cell.bounds, light)) {
        cell.spotLights.push(light)
      }
    }

    if (debug) scene.add(new THREE.SpotLightHelper(light))
  })
}

export default class LightGrid {
  constructor (scene, pointLights, spotLights, bounds, divisions, debug = false) {
    // Find bounds of scene.
    const sceneBounds = bounds
    this.gridCells = subdivideBox(sceneBounds, divisions).map(b => new LightCluster(b))

    sortPointLights(scene, pointLights, this.gridCells, debug)
    sortSpotLights(scene, spotLights, this.gridCells, debug)

    if (debug) for (const cell of this.gridCells) addAabbHelper(scene, cell.bounds)

    this.pointLightTexture = createPointlightTexture(this.gridCells)
    this.spotLightTexture = createSpotlightTexture(this.gridCells)

    // Grid data
    const size = sceneBounds.max.sub(sceneBounds.min)
    const cellSize = size.divide(new THREE.Vector3(divisions, 1, divisions))
    const origin = new THREE.Vector3()
    origin.copy(sceneBounds.min)

    this.gridData = {
      gridDim: [divisions, divisions],
      origin: origin,
      cellDim: cellSize,
      gridCellCount: (divisions * divisions)
    }
  }
}
