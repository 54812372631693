import { createSelector } from 'reselect'

import _sortBy from 'lodash/sortBy'
import _get from 'lodash/get'
import _map from 'lodash/map'

// Selectors
import * as fromProjectsSelectors from '../../../../stores/ducks/projects/selectors'
import * as fromThreeviewerSelectors from '../../../../stores/ducks/threeviewer/selectors'
import * as fromSelectionSelectors from '../../../../stores/ducks/selection/selectors'
import * as fromPatternsSelectors from '../../../../stores/ducks/patterns/selectors'
import * as fromMaterialsSelectors from '../../../../stores/ducks/materials/selectors'
import * as fromColorsSelectors from '../../../../stores/ducks/colors/selectors'

import * as fromColorHelpers from '../../../../stores/ducks/colors/helpers'
import { rgbToHsl } from '../../../../stores/ducks/colors/utils'

const getAppliedTypeIds = (fn) => createSelector(
  fromSelectionSelectors.getSelection,
  fromThreeviewerSelectors.getNodeList,
  fromThreeviewerSelectors.getForcedUpdate, // state cant detect changes in threeviewer this deep, so we have to fake an update (just a random number),
  fromPatternsSelectors.getHasPatternChanged, // state cant detect changes in threeviewer this deep, so we have to fake an update (just a random number)
  (selection, nodeList) => {
    const ids = selection.reduce((acc, uuid) => {
      const node = nodeList[uuid]
      if (!node) return acc
      node.traverse((childNode) => {
        Object.assign(acc, fn(childNode) && { [fn(childNode)]: 1 })
      })

      return Object.assign(acc, fn(node) && { [fn(node)]: 1 })
    }, {})

    return ids
  }
)

export const getAppliedMaterialIds = getAppliedTypeIds((n) => _get(n, 'userData.materialId'))
export const getAppliedCarrierIds = getAppliedTypeIds((n) => _get(n, 'userData.carrier_id'))
export const getAppliedPatternIds = getAppliedTypeIds((n) => _get(n, 'userData.patternId'))
const getAppliedMaterialSources = getAppliedTypeIds((n) => _get(n, 'userData.materialSource'))

// NOTE: Have to check color hex-strings because we did not save
// colorId on userData before. We should probably rewrite setColor when
// loading a combination to add the userData to the meshes
export const getAppliedColorIds = createSelector(
  getAppliedTypeIds((n) => _get(n, 'userData.colorId')),
  getAppliedTypeIds((n) => {
    const color = _get(n, 'material.color')
    return color && color.getHexString && color.getHexString()
  }),
  (ids, hexColors) => ({ ...ids, ...hexColors })
)

const getSelectedMaterialIds = createSelector(
  fromSelectionSelectors.getSelection,
  fromThreeviewerSelectors.getNodeList,
  fromThreeviewerSelectors.getForcedUpdate, // state cant detect changes in threeviewer this deep, so we have to fake an update (just a random number)
  (selection, nodeList) => {
    if (!selection.length) return

    return Object.keys(selection.reduce((acc, uuid) => {
      const node = _get(nodeList, uuid)
      node && node.traverse && node.traverse((childNode) => {
        if (childNode.geometry && childNode.visible) {
          Object.assign(acc, {
            [_get(childNode, 'userData.materialId', 'none')]: 1
          })
        }
      })
      return acc
    }, {}))
  }
)

export const getSelectedColors = createSelector(
  fromColorsSelectors.getEntries,
  getAppliedColorIds,
  (colors, anObjectWithSelectedColorKeysAndHexKeys) => {
    const selectedColors = []

    for (const key in colors) {
      const color = colors[key]
      if (anObjectWithSelectedColorKeysAndHexKeys[color.id]) {
        selectedColors.push(color)
      }
    }

    return selectedColors
  }
)

export const getSelectionMode = createSelector(
  fromSelectionSelectors.getSelection,
  fromThreeviewerSelectors.getNodeList,
  (selection, nodeList) => {
    if (!selection.length) return
    let disabled = false
    const modes = selection.reduce((acc, uuid) => {
      const node = nodeList[uuid]
      node && node.visible && node.traverse && node.traverse((childNode) => {
        const modelSource = _get(childNode, 'userData.modelSource')
        const productType = _get(childNode, 'userData.productType')
        const isTemplatePart = _get(childNode, 'userData.isTemplate')
        const isRoomsetPart = _get(childNode, 'userData.modelType') === 'roomset'
        if (!isTemplatePart && !isRoomsetPart) {
          // if rootnode
          if (!childNode.children || childNode.children.length === 0) {
            disabled = disabled || !_get(childNode, 'userData.setMaterial', true)
          }
        }

        /*
        // If materials are disabled we should not be allowed to set a material.
        // Only done for roomsets now, should maybe be done for both
        // 'disableMaterials: true' and 'setMaterials: false' even if not roomset?
        */
        if (isRoomsetPart && _get(childNode, 'userData.disableMaterials', false)) {
          disabled = true
        }

        if (!childNode.visible) return acc
        if (productType && !_get(childNode, 'userData.disableMaterials')) Object.assign(acc, { [`model_${productType}`]: true })
        if (modelSource === 'ugabank') Object.assign(acc, { uga: true })
        if (isTemplatePart) Object.assign(acc, { template: true })
      })

      return acc
    }, {})
    if (disabled) {
      return false
    }
    if (Object.keys(modes).length > 1 && Object.keys(modes).includes('template')) return false
    if (Object.keys(modes).includes('uga')) return false

    return Object.keys(modes).length === 1
      ? Object.keys(modes)[0]
      : true
  }
)

const getCanSetPropertyOnSelectedMaterial = (path) => createSelector(
  getSelectedMaterialIds,
  fromMaterialsSelectors.getJsonEntries,
  getSelectionMode,
  fromSelectionSelectors.getSelection,
  fromThreeviewerSelectors.getNodeList,
  fromThreeviewerSelectors.getForcedUpdate, // state cant detect changes in threeviewer this deep, so we have to fake an update (just a random number)
  fromPatternsSelectors.getHasPatternChanged, // state cant detect changes in threeviewer this deep, so we have to fake an update (just a random number)
  (ids, materialEntries, mode) => {
    return (
      mode &&
      ids &&
      !ids.includes('none') &&
      ids.length === 1 &&
      _get(materialEntries, [ids[0], path])
    )
  }
)

export const getCanSetPatternOnSelectedMaterial = getCanSetPropertyOnSelectedMaterial('decalMaterial')
export const getCanSetColorOnSelectedMaterial = getCanSetPropertyOnSelectedMaterial('canSetColor')
export const getCanSetFabricColorOnFabricMaterial = getCanSetPropertyOnSelectedMaterial('canSetPrototypeFabricColor')

const getSelectedMaterials = createSelector(
  getSelectedMaterialIds,
  fromMaterialsSelectors.getJsonEntries,
  (objectWithMaterialIds, materialEntries) => {
    if (!objectWithMaterialIds || !materialEntries) {
      return []
    }

    const selectedMaterials = []

    objectWithMaterialIds.forEach(id => {
      if (materialEntries[id]) {
        selectedMaterials.push(materialEntries[id])
      }
    })

    return selectedMaterials
  }
)

export const selectedMaterialsHasType = type => createSelector(
  getSelectedMaterials,
  (selectedMaterials) => {
    for (let i = 0; i < selectedMaterials.length; i++) {
      if (selectedMaterials[i].type !== type) {
        return false
      }
    }

    return true
  }
)

export const getMaterialStainStrengthsFromSelection = createSelector(
  fromThreeviewerSelectors.getPickerSelection,
  fromThreeviewerSelectors.getForcedUpdate,
  (selection) => {
    const meshes = Object.keys(selection).map(key => selection[key])

    return meshes.map(mesh => {
      if (mesh.userData.colorTextureMix === undefined) {
        return 1
      }

      return mesh.userData.colorTextureMix
    })
  }
)

export const canShowColorList = createSelector(
  getAppliedMaterialSources,
  (go3dMaterialSources) => {
    const appliedMaterialSources = _map(go3dMaterialSources, (key, val) => val)
    // If we've applied two or more different appearances the color list will not show
    if (appliedMaterialSources.length !== 1) {
      return false
    }

    return appliedMaterialSources[0] === 'dpd' || appliedMaterialSources[0] === 'file'
  }
)

export const getActiveTab = createSelector(
  fromThreeviewerSelectors.getMaterialSource,
  (uiSource) => {
    const activeTab = uiSource
    return activeTab
  }
)

export const canSetPattern = createSelector(
  fromSelectionSelectors.getSelection,
  fromThreeviewerSelectors.getNodeList,
  fromThreeviewerSelectors.getForcedUpdate, // state cant detect changes in threeviewer this deep, so we have to fake an update (just a random number)
  (selection, nodeList) => {
    if (!selection.length) return false
    let showPatterns = true
    selection.forEach((uuid) => {
      const node = _get(nodeList, uuid)
      node && node.traverse && node.traverse((childNode) => {
        // Checks if model is UGA
        const modelSource = childNode.userData.modelSource

        // TODO: Allow setting pattern on custom home objects (walls etc)
        if (modelSource === 'ugabank' || modelSource === 'custom-home') {
          showPatterns = false
        }

        // Checks if model is from modelbank
        if (childNode.isMesh && modelSource === 'modelbank') {
          if (!childNode.material.useTriplanar) {
            showPatterns = true
          }
        }

        // Checks if the mesh has an applied material
        if (childNode.isMesh && childNode.userData.materialId === undefined) {
          showPatterns = false
        }

        // Checks if selected part is template (walls and floors)
        if (childNode.userData.modelType === 'template') {
          showPatterns = false
        }

        if (childNode.userData.setMaterial === false && childNode.userData.combinationType === 'virtual-product') {
          showPatterns = false
        }
      })
    })
    return showPatterns
  }
)

export const selectColorsVisualizer = createSelector(
  fromColorsSelectors.getColors,
  getAppliedColorIds,
  getCanSetFabricColorOnFabricMaterial,
  fromProjectsSelectors.getMarkedColors,
  (colors, appliedColorIds, canSetFabricColor, markedColorIds) => {
    const colorsAsList =
    Object.values(colors)
      .map((color) => {
        const { r, g, b } = color.ncs.color
        return {
          id: color.id,
          name: color.name,
          // NOTE: Have to check color hex-strings because we did not save
          // colorId on userData before. We should probably rewrite setColor when
          // loading a combination to add the userData to the meshes
          selected: appliedColorIds[color.id] || appliedColorIds[color.ncsInThreeColorHexString],
          color: `rgb(${r}, ${g}, ${b})`,
          ncsTitle: color.ncs.name,
          pantoneTitle: color.pantone.name,
          hsl: rgbToHsl({ r, g, b }),
          selectable: color.selectable,
          fy: color.fy,
          isPrototypeFabricColor: color.isPrototypeFabricColor
        }
      })

    let sortedColorsAsList = _sortBy(colorsAsList, ['hsl.h', 'hsl.s', 'hsl.l'])

    if (!canSetFabricColor) {
      sortedColorsAsList = sortedColorsAsList.filter(color => !color.isPrototypeFabricColor)
    }

    // Sorting colors, standards on top then prototype colors
    sortedColorsAsList = sortedColorsAsList.sort((color) => {
      return color.isPrototypeFabricColor ? 1 : -1 // `false` values first, which are the standard colors
    })

    sortedColorsAsList = fromColorHelpers.putColorsOnTop(sortedColorsAsList, [
      'IKEA BASIC WHITE',
      'IKEA GREY 30',
      'IKEA GREY 28',
      'IKEA GREY 23',
      'IKEA GREY 32',
      'IKEA BLACK 1'
    ])

    // Sorting favorites, standards on top then prototype colors
    const sortedMarkedColorIds = fromColorHelpers.sortFavoriteIds(sortedColorsAsList, markedColorIds)

    // Put favorites on top
    sortedColorsAsList = fromColorHelpers.putMarkedColorsOnTop(sortedColorsAsList, sortedMarkedColorIds)

    return {
      colors: sortedColorsAsList.filter((color) => color.selectable),
    }
  }
)
