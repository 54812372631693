import { Vector3, ShaderMaterial, Color, DoubleSide } from 'three'
import vertexShader from './preview.vert.glsl'
import fragmentShader from './preview.frag.glsl'

export default class PreviewMaterial extends ShaderMaterial {
  constructor () {
    super()

    this.side = DoubleSide
    this.transparent = true
    this.uniforms = {
      opacity: { value: 0.3 },
      xa: { value: new Vector3(1.0, 1.0, 1.0) },
      xb: { value: new Vector3(1.0, 1.0, 1.0) },
      ya: { value: new Vector3(1.0, 1.0, 1.0) },
      yb: { value: new Vector3(1.0, 1.0, 1.0) },
      za: { value: new Vector3(1.0, 1.0, 1.0) },
      zb: { value: new Vector3(1.0, 1.0, 1.0) }
    }
    this.vertexShader = vertexShader
    this.fragmentShader = fragmentShader

    this.needsUpdate = true
  }

  groups = [
    new Vector3(1.0, 0.0, 0.0),
    new Vector3(1.0, 1.0, 0.0),
    new Vector3(0.0, 1.0, 0.0),
    new Vector3(0.0, 1.0, 1.0),
    new Vector3(0.0, 0.0, 1.0),
    new Vector3(1.0, 0.0, 1.0),

    new Vector3(1.0, 1.0, 1.0)
  ]

  updateGroups (value: number[]) {
    const groups = this.groups

    this.uniforms.xa.value = groups[value[0]] ?? groups[6]
    this.uniforms.xb.value = groups[value[1]] ?? groups[6]
    this.uniforms.ya.value = groups[value[2]] ?? groups[6]
    this.uniforms.yb.value = groups[value[3]] ?? groups[6]
    this.uniforms.za.value = groups[value[4]] ?? groups[6]
    this.uniforms.zb.value = groups[value[5]] ?? groups[6]

    this.needsUpdate = true
  }
}
