import { EXRLoader } from 'three/examples/jsm/loaders/EXRLoader.js'
const THREE = require('three')

export default function loadEXREnvironment (path, renderer) {
  return new Promise((resolve, reject) => {
    new EXRLoader().setDataType(THREE.FloatType).load(path, texture => {
      const pmremGenerator = new THREE.PMREMGenerator(renderer)
      pmremGenerator.compileEquirectangularShader()
      const exrCubeRenderTarget = pmremGenerator.fromEquirectangular(texture)
      const exrBackground = exrCubeRenderTarget.texture
      resolve({ cubeMap: exrBackground, filtered: exrBackground }) // TODO: We lost the non-filtered cubeMap in the upgrade to R116, see issue #35
    })
  })
}
