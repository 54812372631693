import Immutable from 'seamless-immutable'
import { createAction, handleActions } from 'redux-actions'

import _groupBy from 'lodash/groupBy'
import _omit from 'lodash/omit'
import * as api from './api'

import * as fromJobs from '../jobs'
import * as fromUploads from '../uploads'
import * as fromRoomsets from '../roomsets'
import { Roomset } from '../roomsets/Roomset'
import { AppThunk } from '..'

const setCurrent = createAction<string | null>('roomset-admin/SET_CURRENT')
const setCurrentRoomsetEditData = createAction<any>('roomset-admin/SET_CURRENT_ROOMSET_EDIT_DATA')

export const dispose = createAction('roomset-admin/DISPOSE')
export const setEditRoomsetModalOpen = createAction<boolean>('roomset-admin/SET_EDIT_ROOMSET_MODAL_OPEN')
export const setRemoveRoomsetModalOpen = createAction<boolean>('roomset-admin/SET_REMOVE_ROOMSET_MODAL_OPEN')
export const setEditingRoomsetProperty = createAction<{ key: string, value: any }>('roomsets-admin/SET_EDITING_ROOMSET_PROPERTY')
export const setEditingRoomsetMetadataProperty = createAction<{ key: string, value: any }>('roomsets-admin/SET_EDITING_ROOMSET_METADATA_PROPERTY')
export const setEditingRoomsetMetadataPropertyImageFiles = createAction<{ value: any }>('roomsets-admin/SET_EDITING_ROOMSET_METADATA_PROPERTY_IMAGE')
export const removeEditingRoomsetMetadataPropertyImageFiles = createAction<{ value: any }>('roomsets-admin/REMOVE_EDITING_ROOMSET_METADATA_PROPERTY_IMAGE')

export const setEditRoomsetId = (id: string | null): AppThunk<void> => (dispatch, getState) => {
  dispatch(setCurrent(id))

  if (!id) {
    dispatch(setCurrentRoomsetEditData(initialState.editingRoomset))
  } else {
    const state = getState()
    const roomsets = state.roomsets.entries
    const currentRoomset = roomsets[id]
    dispatch(setCurrentRoomsetEditData(currentRoomset))
    const setups = (_groupBy(roomsets, 'parentId') || {})[id] || []

    let jobIds: string[] = []
    const uploadIds: string[] = []

    ;[currentRoomset, ...setups].forEach(roomset => {
      jobIds = jobIds.concat(Object.keys(roomset.deadlineJobs || {}))
      roomset.uploadId && uploadIds.push(roomset.uploadId)
    })
    if (jobIds.length) dispatch(fromJobs.fetchJobsByIds(jobIds))
    uploadIds.forEach(uploadId => dispatch(fromUploads.get(uploadId)))
  }
}

export const createOrUpdate = (roomset: Roomset, shouldSetCurrent = true): AppThunk<Promise<Roomset>> => async (dispatch) => {
  const result = roomset.id
    ? await dispatch(fromRoomsets.update(_omit(roomset, 'combinationId')))
    : await dispatch(fromRoomsets.create(_omit(roomset, 'combinationId')))
  if (shouldSetCurrent && result.id) {
    dispatch(setCurrent(result.id))
    dispatch(setCurrentRoomsetEditData(result))
  }
  return result
}

export const uploadThumbnail = (files: FileList): AppThunk<Promise<undefined>> => (dispatch) => {
  return dispatch(fromUploads.uploadToStorageApi(files, {}))
    .then((manifest: { files: any[] }) => {
      dispatch(setEditingRoomsetMetadataProperty({
        key: 'thumbnail',
        value: manifest.files[0]
      }))
    })
}

export const uploadImageFiles = (files: FileList): AppThunk<Promise<undefined>> => (dispatch) => {
  return dispatch(fromUploads.uploadToStorageApi(files, {}))
    .then((manifest: { files: any[] }) => {
      dispatch(setEditingRoomsetMetadataPropertyImageFiles({
        value: [manifest.files[0]]
      }))
    })
}

export const removeImageFile = (files: FileList): AppThunk => (dispatch) => {
  return dispatch(removeEditingRoomsetMetadataPropertyImageFiles({
    value: files
  }))
}

export const uploadInfoText = (text: string): AppThunk => (dispatch) => {
  return dispatch(setEditingRoomsetMetadataProperty({
    key: 'infoText',
    value: text
  }))
}

export const addThumbToCam = (files: FileList, camera: any): AppThunk => async (dispatch) => {
  const { resolution, aspectRatio } = await new Promise((resolve, reject) => {
    const reader = new window.FileReader()
    reader.onload = function () {
      const img = new window.Image()
      if (reader.result) {
        img.src = typeof reader.result === 'string' ? reader.result : reader.result.toString()
      }

      img.onerror = (err) => reject(err)
      img.onload = function () {
        const minRes = 1000
        const min = Math.min(img.width, img.height)
        const x = img.width / min
        const y = img.height / min

        resolve({
          resolution: {
            x: Math.ceil(x * minRes),
            y: Math.ceil(y * minRes)
          },
          aspectRatio: { x, y }
        })
      }
    }
    reader.onerror = (err) => reject(err)
    reader.readAsDataURL(files[0])
  })

  const manifest = await dispatch(fromUploads.uploadToStorageApi(files, {}))
  const image = manifest.files[0]

  let vFov = camera.fov
  if (camera.hFov) {
    vFov = 2 * Math.atan(Math.tan(camera.hFov * 0.5) * resolution.y / resolution.x)
  }

  dispatch(setEditingRoomsetProperty({
    key: 'cameras',
    value: {
      [camera.id]: {
        fov: vFov,
        image,
        aspectRatio,
        resolution
      }
    }
  }))
}

export const uploadMaxFile = (files: FileList): AppThunk<Promise<any>> => async (dispatch, getState) => {
  let roomsetId = getState().roomsetsAdmin.currentId
  if (!roomsetId) {
    const roomset = await dispatch(createOrUpdate(getState().roomsetsAdmin.editingRoomset as Roomset))
    roomsetId = roomset.id!
    dispatch(setCurrent(roomsetId))
    dispatch(setCurrentRoomsetEditData(roomset))
  }
  const uploads: { id: string }[] = await dispatch(fromUploads.createUploadsFromFiles(files, {
    roomsetId: roomsetId,
    type: 'roomset-scene',
    isBigFile: true
  }))
  const upload = Object.values(uploads)[0]
  const uploadId = upload.id
  dispatch(setEditingRoomsetProperty({
    key: 'uploadId',
    value: uploadId
  }))
  // start upload stream
  return dispatch(fromUploads._uploadBigFiles(files, uploads, 'roomset-uploads'))
}

export const uploadSetupFile = (files: FileList, parentId: string, type: Roomset['type'], id?: string): AppThunk => async (dispatch) => {
  const title = files[0].name
  const setup = await dispatch(createOrUpdate({ id, title, parentId, type, metadata: {} }, false))

  const uploads: { id: string }[] = await dispatch(fromUploads.createUploadsFromFiles(files, {
    roomsetId: setup.id,
    type: 'roomset-scene',
    isBigFile: true
  }))

  const upload = Object.values(uploads)[0]
  const uploadId = upload.id

  await dispatch(createOrUpdate({ id: setup.id, uploadId }, false))
  return dispatch(fromUploads._uploadBigFiles(files, uploads, 'roomset-uploads'))
}

export const startRoomsetDeadlineJob = (roomsetId: string): AppThunk<Promise<any>> => async (dispatch) => {
  const res = await api.startRoomsetDeadlineJob(roomsetId)
  dispatch(fromRoomsets.clearDeadlineJobs(roomsetId))
  dispatch(fromRoomsets.receive([res]))
}

export const addImageLightSetup = (roomset: Roomset, files: FileList): AppThunk<Promise<undefined>> => (dispatch) => {
  return dispatch(fromUploads.uploadToStorageApi(files, {}))
    .then((manifest: { files: any[] }) => {
      if (roomset?.metadata) roomset.metadata.imageFiles = (roomset.metadata.imageFiles || []).concat([manifest.files[0]])
      dispatch(fromRoomsets.update(_omit(roomset, 'combinationId')))
    })
}

export const deleteImageLightSetup = (roomset: Roomset, removeKey:string): AppThunk => (dispatch) => {
  if (roomset?.metadata) roomset.metadata.imageFiles = roomset.metadata.imageFiles?.filter((o) => o.key !== removeKey)
  return dispatch(fromRoomsets.update(_omit(roomset, 'combinationId')))
}

const initialState = Immutable<{
  currentId: null | string
  editRoomsetModalOpen: boolean
  removeRoomsetModalOpen: boolean
  editingRoomset: Roomset
}>({
  currentId: null,
  editRoomsetModalOpen: false,
  removeRoomsetModalOpen: false,
  editingRoomset: {
    manualAlignment: [null, null, null],
    metadata: {
      tags: [],
      rooms: 1,
      area: 10,
      marketArea: [],
      thumbnail: null,
      isHidden: false,
      isPublished: false,
      imageFiles: [],
      infoText: ''
    }
  }
})

type State = typeof initialState

export default handleActions<State, any>({
  [setCurrent.toString()]: (state, action: { payload: string | null }) => state.merge({
    currentId: action.payload
  }),
  [setCurrentRoomsetEditData.toString()]: (state, action: { payload: Roomset }) => state.merge({
    editingRoomset: action.payload
  }),
  [setEditRoomsetModalOpen.toString()]: (state, action: { payload: boolean }) => state.merge({
    editRoomsetModalOpen: action.payload
  }),
  [setRemoveRoomsetModalOpen.toString()]: (state, action: { payload: boolean }) => state.merge({
    removeRoomsetModalOpen: action.payload
  }),
  [setEditingRoomsetProperty.toString()]: (state, action: { payload: { key: string, value: any }}) => state.merge({
    editingRoomset: { [action.payload.key]: action.payload.value }
  }, { deep: true }),
  [setEditingRoomsetMetadataProperty.toString()]: (state, action: { payload: { key: string, value: any } }) => state.merge({
    editingRoomset: {
      metadata: {
        [action.payload.key]: action.payload.value
      }
    }
  }, { deep: true }),
  [setEditingRoomsetMetadataPropertyImageFiles.toString()]: (state, action: { payload: { value: any } }) =>
    state.updateIn(['editingRoomset', 'metadata', 'imageFiles'], files => {
      if (!files) return action.payload.value
      return files.concat(action.payload.value)
    }
    ),

  [removeEditingRoomsetMetadataPropertyImageFiles.toString()]: (state, action: { payload: { value: any } }) =>
    state.updateIn(['editingRoomset', 'metadata', 'imageFiles'], files => {
      if (!files) return []
      return files.filter((o:any) => o.key !== action.payload.value.key)
    }
    ),

  [dispose.toString()]: (state) => state
    .setIn(['currentId'], null)
    .setIn(['editingRoomset'], initialState.editingRoomset)
}, initialState)
