import React from 'react'
import Button from '../../common/button'

import { connect } from 'react-redux'
import { createSelector } from 'reselect'

import * as fromThreeviewerCamera from '../../../stores/ducks/threeviewer/camera'
import * as fromThreeviewerSelectors from '../../../stores/ducks/threeviewer/selectors'
import * as fromThreeviewerFilesSelectors from '../../../stores/ducks/threeviewer/files/selectors'
import * as fromRoomsetSelectors from '../../../stores/ducks/roomsets/selectors'

const DisablePredefinedCamera = ({
  isPredefinedActive,
  disablePredefinedCamera,
  camera
}) => {
  if (!isPredefinedActive) return null

  return (
    <div className="inline-flex p1">
      <Button btnType="secondary" onClick={disablePredefinedCamera} pad={1}>
        {`Leave ${(camera && camera.name) || 'camera'}`}
      </Button>
    </div>
  )
}

const mapStateToProps = createSelector(
  fromThreeviewerFilesSelectors.getIsSceneLoaded,
  fromThreeviewerSelectors.getActiveCamera,
  fromRoomsetSelectors.getRoomsetCameraList,
  (isSceneLoaded, activeCamera, predefinedCameraList) => ({
    camera: predefinedCameraList[activeCamera.id],
    isPredefinedActive: activeCamera.isPredefined,
    disabled: !isSceneLoaded
  })
)

const mapDispatchToProps = (dispatch) => ({
  disablePredefinedCamera () {
    dispatch(fromThreeviewerCamera.disablePredefinedCamera())
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DisablePredefinedCamera)
