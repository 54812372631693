import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ImmutableObject } from 'seamless-immutable'

import { RiLinksLine as IconConnect, RiAddFill as IconCreate } from 'react-icons/ri'

import * as fromVirtualProducts from '../../../stores/ducks/virtual-products'
import type { RootState } from '../../../stores/ducks'
import type { Item, VpItem } from '../card/Item'
import type { UpdateVirtualProductPayload, VirtualProductProjectListItem } from '../../../stores/ducks/virtual-products/VirtualProduct'
import { FetchStatus } from '../../../stores/ducks/virtual-products'

import Grid from '../../common/grid/grid'
import Info from '../../common/info'
import Modal from '../../common/modal'
import NotificationBubble from '../../common/notification-bubble'

import ActionCard from '../card/ActionCard'
import GeneralContent from '../project-components/GeneralContent'
import DesignSearch from '../../designs'
import ExportModal from '../export/ExportModal'
import { EmptyFilter, EmptySearch, EmptyVirtualProducts } from '../project-components/EmptyStates'

import ConnectVirtualProductModal from './ConnectVirtualProductModal'
import EditVirtualProductModal from './EditVirtualProductModal'
import { parseListItem } from './parse'
import FormRemove from '../../common/form/form-remove'
import CreateVirtualProductModal from './CreateVirtualProductModal'
import type { ContentModals } from '../ProjectContent'
import { AssetType, GlobalProjectModals, ProjectSearchFilters } from '..'

type Props = {
  onSelect: () => void
  selectedIds: string[]
  showToastMessage: (msg: string) => void
  searchFilter: ProjectSearchFilters
  searchString: string
}

export enum VirtualProductsViewModalsEnum {
  EDIT = 'edit',
  DISCONNECT = 'disconnect',
  CONNECT_VP = 'connect-vp',
  CONNECT_VARIANT = 'connect-variant',
  EXPORT = 'export',
  CREATE_VP = 'create-vp'
}

export type VirtualProductsViewModals = null | VirtualProductsViewModalsEnum

function applySearch (
  vp: ImmutableObject<VirtualProductProjectListItem>,
  searchString: Props['searchString'],
  searchFilter: Props['searchFilter']
) {
  const vpStatus = vp.virtualProductDatabaseData.status
  if (searchFilter && searchFilter !== 'local' && vpStatus !== null) {
    if (searchFilter === 'draft' && vpStatus !== 'Draft') return false
    if (searchFilter === 'approved' && vpStatus !== 'Approved') return false
  }
  if (searchString && searchString !== '') {
    const reg = new RegExp(searchString, 'ig')
    return reg.test(vp.virtualProductDatabaseData.name) || reg.test(vp.virtualProductDatabaseData.itemNumber || '')
  }
  return true
}

const VirtualProductsView = (props: Props) => {
  const dispatch = useDispatch()
  const projectVPs = useSelector((state: RootState) => state.virtualProducts.entries)
  const fetchVpsStatus = useSelector((state: RootState) => state.virtualProducts.fetchStatus)
  const projectId = useSelector((state: RootState) => state.projects.currentId as string | null)
  const errorResponseStatus = useSelector((state: RootState) => state.virtualProducts.errorResponseStatus)

  const [searchedItems, setSearchedItems] = React.useState<Item[]>([])
  const [activeItem, setActiveItem] = React.useState<VpItem | null>()
  const [activeModal, setActiveModal] = React.useState<VirtualProductsViewModals>(null)

  React.useEffect(() => {
    if (projectId) dispatch(fromVirtualProducts.getVirtualProductsByProjectId(projectId))
    return () => {
      dispatch(fromVirtualProducts.resetVirtualProductEntries())
    }
  }, [])

  React.useEffect(() => {
    if (projectVPs) {
      setSearchedItems(
        Object.values(projectVPs)
          .filter(vp => applySearch(vp, props.searchString, props.searchFilter))
          .map((item) => parseListItem(item, projectId))
      )
    }
  }, [projectVPs, props.searchFilter, props.searchString, projectId])

  const showEmptyState = FetchStatus.fetched === fetchVpsStatus && Object.keys(projectVPs || {}).length === 0
  const getEmptyStateComponent = () => {
    if (props.searchString && props.searchString !== '' && searchedItems.length < 1) return <EmptySearch />
    if (props.searchFilter && searchedItems.length < 1 && !showEmptyState) return <EmptyFilter />
    if (showEmptyState) {
      return <EmptyVirtualProducts
        onAction={() => setActiveModal(VirtualProductsViewModalsEnum.CONNECT_VP)}
        onSecondAction={() => setActiveModal(VirtualProductsViewModalsEnum.CREATE_VP)}
      />
    }
    return null
  }

  const resetState = () => {
    setActiveModal(null)
    setActiveItem(null)
  }

  if (fetchVpsStatus === FetchStatus.fetching) {
    return <Info icon='block-before icon-spin1 animate-spin my2' />
  }
  if (fetchVpsStatus === FetchStatus.failed) {
    return <span>Sorry something went wrong, try to reload the site</span>
  }

  const handleOnEdit = (id: string, data: any) => {
    const virtualProduct = projectVPs[id]
    if (virtualProduct) {
      const { itemNumber, status, lifecycle, placeholderAttributes } = virtualProduct.virtualProductDatabaseData

      const body: UpdateVirtualProductPayload = {
        name: data.title.trim(),
        placeholderAttributes,
        lifecycle,
        status,
        itemNumber
      }
      dispatch(fromVirtualProducts.updateVirtualProduct(id, body))
    }
  }

  return (
    <>
      <GeneralContent
        className='overflow-auto px1'
        itemsDone={searchedItems}
        emptyStateComponent={getEmptyStateComponent()}
        contentName={AssetType.VIRTUAL_PRODUCT}
        editOnClick={true}
        onClickImage={(item: Item) => {
          setActiveItem(item as VpItem)
          setActiveModal(VirtualProductsViewModalsEnum.EDIT)
        }}
        onSecondaryAction={null}
        onSelect={props.onSelect}
        selectedIds={props.selectedIds}
        onEdit={handleOnEdit}
        onOpenModal={(modal: ContentModals | GlobalProjectModals | VirtualProductsViewModals, item: Item) => {
          // This view (VirtualProductsView) only includes VP Modals
          const vpModals = Object.values(VirtualProductsViewModalsEnum)
          if (modal && vpModals.includes(modal as any)) {
            setActiveItem(item as VpItem)
            setActiveModal(modal as VirtualProductsViewModals)
          }
        }}
        showToastMessage={props.showToastMessage}
        folders={[]} // no folders in VP view
        itemsInProgress={[]} // no items in progress in VP view
        uploadComponent={
          <>
            <ActionCard
              actionTitle='Connect Virtual Product'
              iconComponent={<IconConnect size={72}/>}
              onAction={() => setActiveModal(VirtualProductsViewModalsEnum.CONNECT_VP)}
            />
            <ActionCard
              actionTitle='Create Virtual Product'
              iconComponent={<IconCreate size={72}/>}
              onAction={() => setActiveModal(VirtualProductsViewModalsEnum.CREATE_VP)}
            />
          </>
        }
        onDragStart={() => {}}
        onDragEnd={() => {}}
        isDragging={false}
        textIsEditable={false}
        selectable={false}
      />
      <Modal
        isOpen={activeModal !== VirtualProductsViewModalsEnum.EXPORT && !!activeModal}
        onRequestClose={() => {
          if (activeModal === VirtualProductsViewModalsEnum.CONNECT_VARIANT) {
            setActiveModal(VirtualProductsViewModalsEnum.EDIT)
          } else resetState()
        }}
        shouldCloseOnOverlayClick
        innerStyle={{ maxWidth: 1600 }}
        width={(activeModal === VirtualProductsViewModalsEnum.DISCONNECT || activeModal === VirtualProductsViewModalsEnum.CREATE_VP) ? 600
          : '90vw' }
        height={ (activeModal === VirtualProductsViewModalsEnum.DISCONNECT) ? 'auto'
          : (activeModal === VirtualProductsViewModalsEnum.CREATE_VP) ? '80vh'
            : '90vh' }
      >
        {activeModal === VirtualProductsViewModalsEnum.CONNECT_VP && projectId && (
          <ConnectVirtualProductModal
            projectId={projectId}
            onClose={() => {
              resetState()
            }}
          />
        )}
        {activeModal === VirtualProductsViewModalsEnum.CONNECT_VARIANT && (
          <Grid
            className='absolute top-0 left-0 right-0 bottom-0 m3'
            rows={['2rem', '1fr']}
            gridGap={0}
          >
            <h2 className='mt0 f4'>Connect variant</h2>
            <DesignSearch
              selectOne
              onClose={() => {
                setActiveModal(VirtualProductsViewModalsEnum.EDIT)
              }}
              onConfirm={async (ids: string[]) => {
                if (activeItem?.id && ids?.length) {
                  dispatch(fromVirtualProducts.connectCombination(activeItem.id, ids))
                  setActiveModal(VirtualProductsViewModalsEnum.EDIT)
                }
              }}
              type='connect'
            />
          </Grid>
        )}
        {activeModal === VirtualProductsViewModalsEnum.EDIT && activeItem && (
          <EditVirtualProductModal
            onClose={resetState}
            onConnect={() => {
              setActiveModal(VirtualProductsViewModalsEnum.CONNECT_VARIANT)
            }}
            id={activeItem.id}
          />
        )}
        {activeModal === VirtualProductsViewModalsEnum.CREATE_VP && (
          <CreateVirtualProductModal
            onClose={resetState}
            onConnect={() => {
              setActiveModal(VirtualProductsViewModalsEnum.CONNECT_VARIANT)
            }}
          />
        )}
        {activeModal === VirtualProductsViewModalsEnum.DISCONNECT &&
        <FormRemove
          buttonText='Yes'
          formTitle='Disconnect Virtual Product'
          formDescription={'Are you sure you want to disconnect virtual product?'}
          onCancel={resetState}
          onConfirm={() => {
            if (projectId && activeItem?.id) {
              dispatch(fromVirtualProducts.disconnectVirtualProduct(projectId, activeItem.id))
              resetState()
            }
          }}
        />}
      </Modal>
      {activeModal === VirtualProductsViewModalsEnum.EXPORT && activeItem && <ExportModal
        isOpen={activeModal === VirtualProductsViewModalsEnum.EXPORT}
        onClose={resetState}
        asset={activeItem}
      />}
      <NotificationBubble
        error={errorResponseStatus}
        onClearOrTimeout={() => dispatch(fromVirtualProducts.setErrorResponseStatus(null))}
      />
    </>
  )
}

export default VirtualProductsView
