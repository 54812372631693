import React from 'react'
import colors from '../../../../css/colors'

const IconAlign = props => (
  <svg width='1em' height='1em' viewBox='0 0 25 25' {...props}>
    <defs>
      <path
        d='M12,11 L22,11 L22,13 L12,13 L12,18 L6,12 L12,6 L12,11 Z M2,2 L4.25,2 L4.25,22 L2,22 L2,2 Z'
        fill={props.color}
        fillRule='evenodd'
        strokeWidth='1'
        id='path-1'
      />
    </defs>
    <g id='Icon/Align-alt-2' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <mask id='mask-2' fill={props.color}>
        <use xlinkHref='#path-1' />
      </mask>
      <use fill={colors.grayDark} xlinkHref='#path-1' />
      <g id='Mixin/Fill/Black/Default' mask='url(#mask-2)'>
        <g id='Color/bg/black/085'>
          <g id='Color/bg/white/100' fill={props.color}>
            <rect id='color_bg_white' x='0' y='0' width='24' height='24' />
          </g>
          <g id='Color/bg/black/100' opacity='0.85' fill={props.color}>
            <rect id='color_bg_black' x='0' y='0' width='24' height='24' />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

IconAlign.defaultProps = {
  color: 'currentcolor'
}

export default IconAlign
