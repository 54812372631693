import React from 'react'
import cs from 'classnames'
import PropTypes from 'prop-types'
import { v4 as uuid } from 'uuid'
import autoBind from 'react-autobind'

// JSX
import Grid from '../../common/grid/grid'
import Button from '../../common/button'
import IconActions from './icon-actions'
import Badge from '../badge'

import { MdArrowBack as IconBack } from 'react-icons/md'
import IconDesignView from '../../common/icons/icon-design-view'

// Constants
import { tabs as galleryTabs, modes as overviewModes } from '../constants'
import { PROJECT_TYPES } from '../../../constants'

// CSS
import css from '../index.css'

class TopBar extends React.Component {
  constructor (props) {
    super(props)
    autoBind(this)

    this.state = {
      isDownloading: false
    }
  }

  getIsToolsEnabled () {
    const { selectionMode, hasSelection, tab } = this.props
    if (tab !== galleryTabs.OVERVIEW) { return true }
    if (selectionMode === overviewModes.SELECT_TO_PREVIEW) { return false }

    return hasSelection
  }

  setIsDownloading (value) {
    this.setState({ isDownloading: value })
  }

  setDownloadingDone () {
    this.setIsDownloading(false)
    this._downloadButton._button.blur()
  }

  handleDownloadAll () {
    this.props.onDownloadImages()

    this.setIsDownloading(true)
    setTimeout(
      () => this.setDownloadingDone(),
      4000
    )
  }

  renderImagePackageTopbar () {
    const {
      onBack,
      backToText,
      onOverview,
      onPreview,
      tab
    } = this.props

    const tabs = [
      { key: 'All images', title: 'All images', onClick: onOverview, active: tab === galleryTabs.OVERVIEW },
      { key: 'Preview', title: 'Preview', onClick: onPreview, active: tab === galleryTabs.PREVIEW }
    ]

    if (tab === galleryTabs.PREVIEW) {
      const {
        onRemove,
        isRemoveDisabled,
        onDownloadImages,
        isDownloadDisabled
      } = this.props

      return [
        <div key='Back' className={cs('pointer flex items-center', css.goBack)} onClick={onBack}>
          <IconBack className='mr1' size={24} style={{ marginTop: 2 }} />
          {backToText}
        </div>,
        <div key='Tabs' className='flex'>
          {tabs.map((tab) => (
            <div
              key={uuid()}
              onClick={tab.onClick}
              className={cs('br-2 c-gray pointer mr3 relative nowrap', {
                'c-white-hover': !tab.active,
                'c-white': tab.active
              })}
            >
              {tab.title}
              <Badge
                count={tab.count}
                className='absolute'
                style={{ top: 8, left: 97 }}
              />
            </div>
          ))}
        </div>,
        <div key='empty-1'></div>,
        <IconActions
          className='mr3'
          key='Actions'
          onRemove={onRemove}
          isRemoveDisabled={isRemoveDisabled}
          isDownloadDisabled={isDownloadDisabled}
          noSpreadsheet
          onDownloadImages={onDownloadImages}
          isToolsDisabled={!this.getIsToolsEnabled()}
        />
      ]
    }

    if (tab === galleryTabs.OVERVIEW) {
      const {
        onEditMasterScene,
        hasChildInRenderingState
      } = this.props

      const { isDownloading } = this.state

      return [
        <div key='Back' className={cs('pointer flex items-center', css.goBack)} onClick={onBack}>
          <IconBack className='mr1' size={24} style={{ marginTop: 2 }} />
          {backToText}
        </div>,
        <div key='Tabs' className='flex'>
          {tabs.map((tab) => (
            <div
              key={uuid()}
              onClick={tab.onClick}
              className={cs('br-2 c-gray pointer mr3 relative nowrap', {
                'c-white-hover': !tab.active,
                'c-white': tab.active
              })}
            >
              {tab.title}
              <Badge
                count={tab.count}
                className='absolute'
                style={{ top: 8, left: 97 }}
              />
            </div>
          ))}
        </div>,
        <div key='empty-2'></div>,
        <div key='Buttons' className='flex items-center justify-end mr3'>
          <Button
            className='mr3'
            style={{ width: 155 }}
            btnType='secondary'
            onClick={onEditMasterScene}
          >
            Edit master scene
          </Button>
          <Button
            btnType='primary'
            style={{ width: 175 }}
            className='justify-center'
            ref={node => (this._downloadButton = node)}
            disabled={isDownloading || hasChildInRenderingState}
            onClick={this.handleDownloadAll}
          >
            {isDownloading ? 'Creating ZIP-archive' : 'Download all'}
          </Button>
        </div>
      ]
    }
  }

  renderDefaultTopbar () {
    const {
      onRemove,
      onDownloadSpreadsheet,
      onDownloadImages,
      contentType,
      onBack,
      backToText,
      onVisualize,
      onPreview,
      onComments,
      commentsCount,
      tab,
      isDnpProject,
      isSentToDnp,
      onClickSendToDnp
    } = this.props

    const tabs = [
      { key: 'Preview', title: 'Preview', onClick: onPreview, active: tab === galleryTabs.PREVIEW },
      { key: 'Comments', title: 'Comments', onClick: onComments, active: tab === galleryTabs.COMMENTS, count: commentsCount }
    ]

    const disableDownloads = contentType !== 'render'

    if (tab !== galleryTabs.OVERVIEW) {
      return [
        <div key='Back' className='flex justify-between items-center'>
          <div className={cs('pointer flex items-center', css.goBack)} onClick={onBack}>
            <IconBack className='mr1' size={24} style={{ marginTop: 2 }} />
            {backToText}
          </div>
          <div className='flex mr2'>
            {tabs.map((tab) => (
              <div
                key={uuid()}
                onClick={tab.onClick}
                className={cs('br-2 c-gray pointer mr3 relative nowrap', {
                  'c-white-hover': !tab.active,
                  'c-white': tab.active
                })}
              >
                {tab.title}
                <Badge
                  count={tab.count}
                  className='absolute'
                  style={{ top: 8, left: 97 }}
                />
              </div>
            ))}
          </div>
        </div>,
        <div key='Button' className='flex justify-center'>
          {contentType !== 'pattern' && <Button
            key={'overview-button'}
            className='justify-center width-75'
            btnType='primary'
            icon={<div className='mr2'><IconDesignView /></div>}
            onClick={onVisualize}
          >
            Edit in Visualizer view
          </Button>}
        </div>,
        <div key='Actions' className='flex justify-end mr1'>
          <IconActions
            onRemove={onRemove}
            onDownloadSpreadsheet={onDownloadSpreadsheet}
            onDownloadImages={onDownloadImages}
            isToolsDisabled={!this.getIsToolsEnabled()}
            noSpreadsheet={disableDownloads}
            noDownload={disableDownloads}
            isDnpProject={isDnpProject}
            isSentToDnp={isSentToDnp}
            onClickSendToDnp={onClickSendToDnp}
          />
        </div>
      ]
    }

    return [
      <div key='Back' className='flex justify-between items-center'>
        <div className={cs('pointer flex items-center', css.goBack)} onClick={onBack}>
          <IconBack className='mr1' size={24} style={{ marginTop: 2 }} />
          {backToText}
        </div>
        <div className='flex mr2'>
          {tabs.map((tab) => (
            <div
              key={uuid()}
              onClick={tab.onClick}
              className={cs('br-2 c-gray pointer mr3 relative nowrap', {
                'c-white-hover': !tab.active,
                'c-white': tab.active
              })}
            >
              {tab.title}
              <Badge
                count={tab.count}
                className='absolute'
                style={{ top: 8, left: 97 }}
              />
            </div>
          ))}
        </div>
      </div>,
      <div key='Button' className='flex justify-center'>
        <Button
          key={'overview-button'}
          className='justify-center width-75'
          btnType='primary'
          icon={<div className='mr2'><IconDesignView /></div>}
          onClick={onVisualize}
        >
          Edit in Visualizer
        </Button>
      </div>,
      <div key='Actions' className='flex justify-end mr1'>
        <IconActions
          onRemove={onRemove}
          onDownloadSpreadsheet={onDownloadSpreadsheet}
          noSpreadsheet={disableDownloads}
          noDownload={disableDownloads}
          onDownloadImages={onDownloadImages}
          isToolsDisabled={!this.getIsToolsEnabled()}
        />
      </div>
    ]
  }

  render () {
    const {
      projectType
    } = this.props

    const isImagePackageProject = projectType === PROJECT_TYPES.IMAGE_PACKAGE

    const columns = isImagePackageProject ? ['25%', 'auto', '1fr', '1fr'] : ['1fr', '1fr', '1fr']

    return (
      <Grid
        columns={columns}
        className='c-white px3 items-center'
        style={{ gridArea: 'topbar' }}
      >

        {isImagePackageProject && this.renderImagePackageTopbar()}

        {!isImagePackageProject && this.renderDefaultTopbar()}

      </Grid>
    )
  }
}

TopBar.propTypes = {
  backToText: PropTypes.string,
  onBack: PropTypes.func,
  commentsCount: PropTypes.number,
  onToggleSelectAll: PropTypes.func,
  allSelected: PropTypes.bool,
  tab: PropTypes.string,
  selectionMode: PropTypes.string,
  onToggleSelectionMode: PropTypes.func,
  onDownloadSpreadsheet: PropTypes.func,
  onDownloadImages: PropTypes.func,
  onRemove: PropTypes.func,
  onVisualize: PropTypes.func,
  onOverview: PropTypes.func,
  onPreview: PropTypes.func,
  onComments: PropTypes.func,
  onEditMasterScene: PropTypes.func,
  hasChildInRenderingState: PropTypes.bool,
  isDnpProject: PropTypes.bool,
  isSentToDnp: PropTypes.bool,
  onClickSendToDnp: PropTypes.func
}

export default TopBar
