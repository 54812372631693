import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { RootState } from '../../../../stores/ducks'
import { createSelector } from 'reselect'
import Grid from '../../../common/grid/grid'
import Checkbox from '../../../common/form/input-checkbox'
import cs from 'classnames'
import _get from 'lodash/get'
import IconLandscape from '../../../common/icons/icon-proportions-landscape'
import IconSquare from '../../../common/icons/icon-proportions-square'
import IconPortrait from '../../../common/icons/icon-proportions-portrait'
import colors from '../../../../../css/colors'
import { toggleSafeFrameVisibility, setSafeFrameVisibility, saveSafeFrameVisibility } from '../../../../stores/ducks/threeviewer/ui'
import { setAspectRatio } from '../../../../stores/ducks/threeviewer/camera'
import { getAspectRatio } from '../../../../stores/ducks/threeviewer/selectors'

const nearlyEqual = (a: number, b: number) => {
  return (Math.trunc(a * 1000) / 1000) === Math.trunc(b * 1000) / 1000
}

const mapStateToProps = createSelector(
  getAspectRatio,
  (state: RootState) => _get(state, 'threeviewer.ui.isSafeFrameVisible'),
  (
    aspectRatio,
    isSafeFrameVisible,
  ) => {
    return {
      isSafeFrameVisible,
      aspectRatio,
      defaultRatios: [
        { x: 16, y: 9, title: 'Landscape' },
        { x: 1, y: 1, title: 'Square' },
        { x: 9, y: 16, title: 'Portrait' }
      ].map(({ x, y, title }) => ({
        x,
        y,
        isSelected: nearlyEqual(aspectRatio.x / aspectRatio.y, x / y),
        title,
        ratio: `${x}:${y}`
      }))
    }
  }
)

const mapDispatchToProps = (dispatch: any) => {
  return {
    toggleSafeFrameVisibility: () => dispatch(toggleSafeFrameVisibility()),
    setAspectRatio: ({ x, y }: { x: number; y: number }) => {
      dispatch(setSafeFrameVisibility(true))
      dispatch(saveSafeFrameVisibility(true))
      dispatch(setAspectRatio({ x, y }))
    }
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>
type Props = PropsFromRedux & {
  disabled: boolean
}

function AspectRatioAndSafeFrameControls (props: Props) {
  return (
    <>
      <Checkbox
        data-testid='camera-aspectratio-controls-preview'
        name='Preview proportion in 3D view'
        label='Preview proportion in 3D view'
        checked={props.isSafeFrameVisible}
        disabled={props.disabled}
        onChange={props.toggleSafeFrameVisibility} />
      <Grid
        columns={['auto', 'auto', 'auto']}
        style={{ maxWidth: '15em' }}
        gridGap={'2em'}
      >
        {props.defaultRatios.map((ratio) => (
          <AspectRatioButton
            data-testid={`camera-aspectratio-controls-${ratio.title}`.toLowerCase()}
            key={ratio.title}
            title={ratio.title}
            disabled={props.disabled}
            isSelected={ratio.isSelected}
            onClick={() => !props.disabled && props.setAspectRatio(ratio)} />
        ))}
      </Grid>
    </>
  )
}

type AspectRatioButtonProps = {
  'data-testid'?: string,
  title: string
  disabled: boolean
  isSelected: boolean
  onClick: () => void
}

function AspectRatioButton (props: AspectRatioButtonProps) {
  const Icon = React.useRef<typeof IconLandscape | typeof IconSquare | typeof IconPortrait>(props.title === 'Landscape' ? IconLandscape : (props.title === 'Portrait' ? IconPortrait : IconSquare))
  return (
    <div data-testid={props['data-testid']} className={cs({ 'pointer-disabled': props.disabled })}>
      <Icon.current
        size={'5em'}
        color={props.isSelected ? (props.disabled ? colors.grayLight : colors.secondary) : colors.grayLight}
        onClick={props.onClick}
        className='block mx-auto pointer' />
      <small className='block f8 pt2 center'>{props.title}</small>
    </div>
  )
}

export default connector(AspectRatioAndSafeFrameControls)
