import React, { useEffect, useState } from 'react'
import cs from 'classnames'
import { useDispatch, useSelector } from 'react-redux'

// common
import { StorageApiThumbnail } from '../../../../common/storage-api-image'
import AutoFill from '../../../../common/grid/AutoFill'
import Spinner from '../../../../common/spinner'

// redux
import * as fromMaterials from '../../../../../stores/ducks/materials'
import { getSelectionMode, getAppliedCarrierIds } from '../../../panels/material-and-color/material-and-color-selectors'

// types
import type { MaterialData } from '../../product-concept-analysis/productAnalysisTypes'

const css = require('./../index.css')

function CarrierList ({ search }: { search: string }) {
  const [carrierData, setCarrierData] = useState<MaterialData[]>([])
  const [searchResult, setSearchResult] = useState<MaterialData[]>([])
  const selectionMode = useSelector(getSelectionMode)
  const appliedCarrierIds = useSelector(getAppliedCarrierIds)
  const dispatch = useDispatch()

  /**
   * Fetch material list from xsl
   */
  useEffect(() => {
    async function getCarriers () {
      const response = await fetch('/api/materials/carriers')
      if (response.status === 200) {
        const carrierDataResponse = await response.json()
        const carrierList = carrierDataResponse.filter((data: MaterialData) => data.costVolume.AP !== 0)
        setCarrierData(carrierList)
        filterSelectedCarriers(carrierList)
      } else {
        console.error(response)
      }
    }
    getCarriers()
  }, [])

  /**
   * Search functionality
   */
  useEffect(() => {
    const results = carrierData.filter((data: MaterialData) =>
      data.name.toLowerCase().includes(search.toLowerCase())
    )
    filterSelectedCarriers(results)
  }, [search])

  useEffect(() => {
    filterSelectedCarriers(searchResult)
  }, [appliedCarrierIds])

  /**
   * Filters selected materials in material panel
   */
  function filterSelectedCarriers (carrierArray: MaterialData[]) {
    const carriers = carrierArray?.map((data: MaterialData) => {
      data.selected = !!appliedCarrierIds[data.carrierId]
      return data
    })
    setSearchResult(carriers)
  }

  return (carrierData === null ? <div style={{ display: 'flex', placeItems: 'center', flexDirection: 'column' }}><Spinner /></div>
    : <AutoFill width={80}>
      {searchResult?.map((data: MaterialData) => {
        return (
          <div
            onClick={() => dispatch(fromMaterials.setCarrier(data.carrierId))}
            className={cs('pointer', {
              'muted pointer-disabled': !selectionMode
            })}
            title={data.name}
            key={data.carrierId}
          >
            <div className='relative'>
              <div
                style={{
                  position: 'absolute',
                  padding: 4,
                  right: 0,
                  top: 0
                }}
              >
              </div>
              <StorageApiThumbnail
                src={''}
                className={`${css.hoverInset} ${data.selected ? css.selectedInset : ''}`}
                placeholderSrc={'/img/placeholder-material.jpg'}
              />
              <div
                className='f8 mt1'
                style={{
                  display: '-webkit-box',
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              >
                {data.name.toUpperCase()}
              </div>
            </div>
          </div>
        )
      })}
    </AutoFill>
  )
}

export default CarrierList
