import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { createSelector } from 'reselect'

import * as fromProjects from '../../../../stores/ducks/projects'
import * as fromUploadsSelectors from '../../../../stores/ducks/uploads/selectors'
import * as fromProjectSelectors from '../../../../stores/ducks/projects/selectors'

import { Combination, VisualizedCombination } from '../../../../stores/ducks/combinations/Combination'

import AutoFill from '../../../common/grid/AutoFill'
import Button from '../../../common/button'
import Design from '../../../designs/design'
import Modal from '../../../common/modal'
import InputSearch from '../../../common/form/input-search'
import InputCheckbox from '../../../common/form/input-checkbox'

import GeometryUploadView from '../../../upload-files/geometry-upload-view'
import { UploadComponent } from '../../../upload-files/upload-component'
import ProgressCard from '../../../project/card/ProgressCard'
import { StorageApiFile } from '../../../../utils'
import { connectCombinationsForBatchRender } from '../../../../stores/ducks/combinations'
import { RootState } from '../../../../stores/ducks'
import { difference, orderBy, xor } from 'lodash'
import Grid from '../../../common/grid/grid'
import Scrollable from '../../../common/scrollable'
import SeamlessImmutable from 'seamless-immutable'
import { receive as receiveCombinations } from '../../../../stores/ducks/combinations/actions/json'
import Spinner from '../../../common/spinner'

type Props = {
  closeModal: () => void
}

type ImmutableCombination = SeamlessImmutable.ImmutableObject<Combination> | SeamlessImmutable.ImmutableObject<VisualizedCombination>
export type AddGeometryCombination = ImmutableCombination & {
  thumbnail: StorageApiFile | null,
  _numberOfTagMatches: number
}

function getNumberOfTagMatches (a: ImmutableCombination, b: ImmutableCombination) {
  const aTags: (string[])[] = []
  const bTags: (string[])[] = []
  Object.values(a.models).forEach(m => m.parts && m.parts.forEach(p => p.tags && p.tags.length && aTags.push(Array.from(p.tags))))
  Object.values(b.models).forEach(m => m.parts && m.parts.forEach(p => p.tags && p.tags.length && bTags.push(Array.from(p.tags))))

  let numberOfTagMatches = 0
  aTags.forEach(as => {
    const possibleMatches = as.length
    bTags.forEach(bs => (numberOfTagMatches += possibleMatches - difference(as, bs).length))
  })
  return numberOfTagMatches
}

const selectCombinations = createSelector(
  fromProjectSelectors.getCombinationsWithRender,
  (state: RootState) => state.combinations.entries[state.combinations.currentId || ''],
  (combinations, currentCombination) => {
    const _combinations = Object.values(combinations)
      .filter(c => c && c.models && !c.removedAt)
      .filter(c => ['variant', 'convert'].includes(c.combinationType || ''))
      .filter(c => c.id !== currentCombination?.id)
      .map(c => ({
        ...c,
        _numberOfTagMatches: currentCombination ? getNumberOfTagMatches(currentCombination, c) : 0
      }))
    return orderBy(_combinations, ['_numberOfTagMatches', 'title'], ['desc', 'asc'])
  }
)

const AddGeometryModal = ({ closeModal }: Props) => {
  const dispatch = useDispatch()
  const currentProjectId = useSelector((state: RootState) => state.projects.currentId)
  const connectedBatchGeometryCombinationIds = useSelector((state: RootState) => state.combinations.connectedBatchGeometryCombinationIds)
  const combinations = useSelector(selectCombinations)

  const [searchedItems, setSearchedItems] = useState(combinations)
  const geometriesInProgress = useSelector(fromUploadsSelectors.getCompletedCadsInCurrentProject)
  const projectId = useSelector(fromProjectSelectors.getCurrentId)

  const [searchText, setSearchText] = useState('')
  const [files, setFiles] = useState<any[]>([])
  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const [selectAll, setSelectAll] = useState<boolean | null>(null)

  const [fetching, setFetching] = useState<boolean>(true)

  React.useEffect(() => {
    setSelectedIds(connectedBatchGeometryCombinationIds.asMutable())
  }, [])

  React.useEffect(() => {
    const abortController = new AbortController()
    async function fetchGeometries () {
      try {
        const result = await fetch(`/api/combinations/getGeometriesByProject/${currentProjectId}`, {
          signal: abortController.signal
        })
        const json = await result.json()
        dispatch(receiveCombinations(json))
        setFetching(false)
      } catch (e) {
        if (!abortController.signal.aborted) {
          console.error('Request failed.')
          console.error(e)
          setFetching(false)
        }
      }
    }
    fetchGeometries()
    return () => abortController.abort()
  }, [])

  React.useEffect(() => {
    const lowerCaseSearchText = searchText.toLowerCase()
    const searchedItems = combinations.filter((combination) => {
      return combination.title?.toLowerCase().includes(lowerCaseSearchText)
    })
    if (selectAll) setSelectedIds(searchedItems.map(item => item.id))
    setSearchedItems(searchedItems)
  }, [searchText, combinations])

  React.useEffect(() => {
    if (selectAll !== null) {
      selectAll
        ? setSelectedIds(searchedItems.map(item => item.id))
        : setSelectedIds([])
    }
  }, [selectAll, searchedItems])

  return (
    <>
      <Grid className='height-100' rows={['auto', 'min(50vh, 500px)', 'auto']}>
        <div>
          <h2 className='mt0'>
            Connect geometry
          </h2>
          <div className='flex items-center'>
            <InputSearch
              placeholder='Search...'
              value={searchText}
              onChange={setSearchText}
              onClearSearch={() => setSearchText('')}
              className='mr2'
            />
            <InputCheckbox
              noMargin
              name='select all'
              label='Select All'
              checked={selectAll || false}
              onChange={() => setSelectAll(!selectAll)}
            />
          </div>
        </div>
        <Scrollable innerClassName="bg-gray-light p2 custom-scrollbar">
          {!fetching && (
            <AutoFill width={120}>
              <UploadComponent onUpload={setFiles}/>

              {geometriesInProgress.map((item, index) => (
                <ProgressCard
                  item={item}
                  key={`${item.id}_${index}`}
                  noShadow
                  combinationType='upload'
                />
              ))}
              {searchedItems.map((item, index) => (
                <Design
                  title={item.title}
                  thumbnail={item.thumbnail}
                  project={item.projectId}
                  key={`${item.id}_${index}`}
                  onSelect={() => setSelectedIds(xor(selectedIds, [item.id]))}
                  isSelected={selectedIds.includes(item.id)}
                  combinationType={item.combinationType}
                />
              ))}
            </AutoFill>
          )}
          {fetching && (
            <div className='flex flex-column center mt4'>
              <Spinner />
              <div className='f6 mt1'>Fetching...</div>
            </div>
          )}
        </Scrollable>

        <div className='flex justify-end items-end'>
          <Button onClick={closeModal}>Cancel</Button>
          <Button
            disabled={selectedIds.length === 0}
            btnType='primary'
            className='ml2'
            onClick={() => {
              dispatch(connectCombinationsForBatchRender(selectedIds))
              closeModal()
            }}
          >
            Connect
          </Button>
        </div>
      </Grid>
      <Modal
        isOpen={files.length > 0}
        onRequestClose={() => setFiles([])}
      >
        <GeometryUploadView
          files={files}
          onReset={() => setFiles([])}
          onUploaded={(data: any) => dispatch(fromProjects.receiveCadKey(data, projectId))}
        />
      </Modal>
    </>
  )
}

export default AddGeometryModal
