import Immutable from 'seamless-immutable'
import { handleActions, createAction } from 'redux-actions'
import _uniq from 'lodash/uniq'
import _get from 'lodash/get'
import _find from 'lodash/find'

const create = (action) => createAction(`interface/modals/${action}`)

const openModal = create('OPEN')
export const closeModal = create('CLOSE')
const closeAllModals = create('CLOSE_ALL')
const error = create('ERROR')
export const setTemplateModalProp = create('SET_TEMPLATE_MODAL_PROPS')

export const open = (payload) => (dispatch, getState) => {
  const { type, name, display, data } = payload
  const modals = _get(getState(), 'interface.modals.open', [])
  const modal = _find(modals, { type, name })

  if (modal) {
    dispatch(closeModal({ type, name }))
  }
  dispatch(openModal({ type, name, display, data }))
}

export const close = (payload) => (dispatch) => {
  const { type, name } = payload
  dispatch(closeModal({ type, name }))
}

const initialState = Immutable({
  open: [],
  templateModal: {
    shouldCloseOnEsc: true
  }
})

export default handleActions({

  [openModal]: (state, action) =>
    state.merge({
      open: _uniq(state.open.concat(action.payload))
    }, { deep: true }),

  [closeModal]: (state, action) =>
    state.merge({
      open: state.open.filter((item) =>
        item.name !== action.payload.name &&
        item.type !== action.payload.type
      )
    }),

  [closeAllModals]: (state) =>
    state.merge({
      modals: []
    }, { deep: true }),

  [error]: (state, action) => state.merge({ error: action.payload }),

  [setTemplateModalProp]: (state, { payload }) => state.merge({
    templateModal: { [payload.key]: payload.value }
  }, { deep: true })
}, initialState)
