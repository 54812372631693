// @ts-ignore
import Client from '@inter-ikea-digital/iig-rpd-dpd-packages-storage-api-client'
import { Go3DViewer } from '../../../../../../go3dthree/types/Go3DViewer'
import { SceneGraphNode3d as ISceneGraphNode3d, SceneGraphMesh as ISceneGraphMesh } from '../../../../../../go3dthree/types/SceneGraph'
import type { PostRenderData } from './post-render'
import { StorageApiManifest } from '../../../../utils'
import { lin2srgb } from './utils'
import { getPatternData } from './get-changed-models'
const client = Client('/api/storage')

export async function exportNodes (generatedNodes: Set<ISceneGraphNode3d>, viewer: Go3DViewer) {
  const nodes = getNodesToExport(generatedNodes, viewer)
  const generatedModels: PostRenderData['generatedModelData'] = []
  await Promise.all(nodes.map(async (node) => {
    if (node.userData.isModelGenerated) return

    const exportedGltf = (await viewer.gltfExporter.export([node])) as any
    const file = new File([JSON.stringify(exportedGltf)], 'generated.gltf', { type: 'application/json' })
    const manifestKey = await client.upload([file])
    const res = await fetch(`/api/storage/get/${manifestKey}.json`)
    const manifest = await res.json() as StorageApiManifest

    generatedModels.push({
      id: node.userData.dbModelId,
      manifestKey: manifestKey,
      manifest: manifest,
      metadata: {
        name: node.name,
        generated: true,
        override: node.userData.override
      }
    })
  }))

  return generatedModels
}

function getNodesToExport (generatedNodes: Set<ISceneGraphNode3d>, viewer: Go3DViewer) {
  const material = new viewer.THREE.MeshBasicMaterial()
  const output: any[] = []

  generatedNodes.forEach(node => {
    if (!node.userData.generated || node.userData.ignore) return

    const parent = new viewer.THREE.Scene()
    parent.name = node.name
    parent.userData = {
      dbModelId: node.userData.dbModelId,
      name: node.userData.name,
      nodeId: node.userData.nodeId,
      override: node.userData.override
    }

    node.children.forEach((child: ISceneGraphMesh) => {
      if (child.isMesh) {
        const mesh = viewer.renderScene.meshes[child._instancedMeshId].clone()
        mesh.name = child.userData.nodeId
        mesh.material = material

        if (node.userData.override) {
          mesh.matrixAutoUpdate = false
          mesh.matrix.identity()
        }

        mesh.userData = {
          activeAxis: child.userData.activeAxis,
          bucket: child.userData.bucket,
          nodeId: child.userData.nodeId,
          name: child.userData.name,
          overriddenNodeId: child.userData.overriddenNodeId
        }
        parent.add(mesh)
      }
    })

    output.push(parent)
  })

  return output
}

export function findGeneratedNodesRecursive (node: ISceneGraphNode3d, found = new Set<ISceneGraphNode3d>()) {
  node.children.forEach((child: any) => {
    if (child.children && !child.userData.generated) {
      const _found = findGeneratedNodesRecursive(child, found)
      if (_found.size) {
        _found.forEach(f => found.add(f))
      }
    }

    if (child.userData.generated) {
      found.add(child)
    }
  })

  if (node.parent && node.userData.generated) {
    found.add(node)
  }
  return found
}

export async function exportCustomHome (customHomeNode: ISceneGraphNode3d, viewer: Go3DViewer) {
  const nodes = getCustomHomeNodeToExport(customHomeNode, viewer)
  const exportedGltf = (await viewer.gltfExporter.export(nodes)) as any
  const file = new File([JSON.stringify(exportedGltf)], 'generated.gltf', { type: 'application/json' })
  const manifestKey = await client.upload([file])
  const res = await fetch(`/api/storage/get/${manifestKey}.json`)
  const manifest = await res.json() as StorageApiManifest
  return manifest.files[0]
}

function getCustomHomeNodeToExport (node: ISceneGraphNode3d, viewer: Go3DViewer) {
  const meshes: any[] = []
  node.children.forEach((child) => {
    if (
      !child.isMesh ||
      ('removed' in child.userData) ||
      child.userData.ignore ||
      !child.visible
    ) return

    const userData = child.userData

    const convertColor = lin2srgb(child.material.color)
    const materialColor = `0x${convertColor.getHexString()}`
    userData.color = materialColor

    if (child.userData.patternId) {
      const {
        userDecalRotation,
        decalUVTransform,
        patternFilePath
      } = getPatternData(child)
      userData.patternId = node.userData.patternId
      userData.userDecalRotation = userDecalRotation
      userData.decalUVTransform = decalUVTransform
      userData.patternFilePath = patternFilePath
    }

    const mesh = viewer.renderScene.meshes[child._instancedMeshId].clone()
    mesh.userData = userData
    mesh.name = child.uuid
    const childTransform = new viewer.THREE.Matrix4()
    mesh.getMatrixAt(0, childTransform)
    mesh.matrix = childTransform
    mesh.matrixWorldNeedsUpdate = true
    meshes.push(mesh)
  })
  return meshes
}
