import Immutable from 'seamless-immutable'

import _keyBy from 'lodash/keyBy'
import _omit from 'lodash/omit'
import _isEmpty from 'lodash/isEmpty'

import navigate from '../../../utils/navigate'
import * as api from './api'
import { queryString } from './utils'
import registerServiceWorker from '../../../sw-register'

const RESET_NOTIFICATION = 'users/RESET_NOTIFICATION'
const ERROR = 'users/ERROR'
const RECEIVE_NOTIFICATION = 'users/RECEIVE_NOTIFICATION'
const RECEIVE = 'users/RECEIVE'
const RECEIVE_CURRENT = 'users/RECEIVE_CURRENT'
const DELETE_USERS = 'users/DELETE_USERS'
const CLEAR_USERS = 'users/CLEAR_USERS'
const REPLACE_USERS = 'users/REPLACE_USERS'

export function resetNotification () {
  return {
    type: RESET_NOTIFICATION
  }
}

export function error (err) {
  return {
    type: ERROR,
    data: err
  }
}

export function deleteUsers (userIds) {
  return {
    type: DELETE_USERS,
    data: userIds
  }
}

// Keeps the current user
export function clearUsers () {
  return {
    type: CLEAR_USERS
  }
}

export function replaceUsers (users) {
  return {
    type: REPLACE_USERS,
    data: users
  }
}

export function receiveNotification (notification) {
  return {
    type: RECEIVE_NOTIFICATION,
    data: notification
  }
}

export function receive (users) {
  return {
    type: RECEIVE,
    data: users
  }
}

export function receiveCurrent (user) {
  return {
    type: RECEIVE_CURRENT,
    data: user
  }
}

export function setNewsAsRead (articleId) {
  return (dispatch) => {
    api
      .updateUserNewsStatus(articleId, { read: true })
      .then((result) => {
        if (!_isEmpty(result)) {
          dispatch(receive([result]))
        }
      })
      .catch((err) => dispatch(error(err)))
  }
}

export function optOutNews (articleId) {
  return (dispatch) => {
    api
      .updateUserNewsStatus(articleId, { optOut: true })
      .then((result) => {
        if (!_isEmpty(result)) {
          dispatch(receive([result]))
        }
      })
      .catch((err) => dispatch(error(err)))
  }
}

export function acceptGDPR (userId) {
  return (dispatch, getState) => {
    const data = {
      id: userId || getState().users.currentUserId,
      hasAcceptedGDPR: true
    }

    // Eager update
    dispatch(receive([data]))

    const formData = {
      id: { value: data.id },
      hasAcceptedGDPR: { value: data.hasAcceptedGDPR }
    }
    return api.acceptGDPR(formData)
      .then(() => navigate('/'))
      .catch((err) => dispatch(error(err)))
  }
}

export function getCurrent () {
  return (dispatch) => {
    const token = queryString().token

    return api
      .getCurrent(token)
      .then((json) => {
        registerServiceWorker(json.id)
        dispatch(receiveCurrent(json))
        if (window.location.pathname === '/login') return navigate('/')
        navigate(queryString().ref || window.location.href, false, false)
      })
      .catch(() => {
        const ref =
          window.location.href.match(/\/login/) ||
          window.location.pathname === '/'
            ? ''
            : `/?ref=${window.location.pathname}`
        navigate(`/login${ref}`)
      })
  }
}

export function getActiveUsers () {
  return (dispatch) => {
    api
      .getAllActive()
      .then((json) => dispatch(receive(json)))
      .catch((err) => dispatch(error(err)))
  }
}

export function getUsers () {
  return (dispatch) => {
    api
      .getAll()
      .then((json) => dispatch(receive(json)))
      .catch((err) => dispatch(error(err)))
  }
}

export function getUsersIncludingDeleted () {
  return (dispatch) => {
    api
      .getAllIncludingDeleted()
      .then((json) => dispatch(receive(json)))
      .catch((err) => dispatch(error(err)))
  }
}

export function logout () {
  return (dispatch) => {
    api
      .logout()
      .then(() => {
        window.location = '/login'
      })
      .catch((err) => dispatch(error(err)))
  }
}

export function getUsersByIds (ids) {
  return (dispatch) => {
    api
      .getByIds(ids)
      .then((json) => dispatch(receive(json)))
      .catch((err) => dispatch(error(err)))
  }
}

export function getToken () {
  return () => {
    api.getToken()
  }
}

const initialState = Immutable({
  notification: '',
  error: '',
  currentUserId: null,
  entries: {}
})

export default function usersReducer (state = initialState, action) {
  switch (action.type) {
    case RECEIVE_CURRENT:
      return state.merge(
        {
          currentUserId: action.data.id,
          entries: _keyBy([action.data], 'id')
        },
        { deep: true }
      )

    case RECEIVE_NOTIFICATION:
      return state.merge({ notification: action.data, error: '' })

    case RECEIVE:
      return state.merge(
        {
          entries: _keyBy(action.data, 'id')
        },
        { deep: true }
      )

    case ERROR:
      return state.merge({ error: action.data, notification: '' })

    case RESET_NOTIFICATION:
      return state.merge({ error: '', notification: '' })

    case DELETE_USERS:
      return state.merge({
        entries: _omit(state.entries, action.data)
      })

    case CLEAR_USERS:
      return state.merge({
        entries: { [state.currentUserId]: state.entries[state.currentUserId] }
      })

    case REPLACE_USERS:
      return state.merge({
        entries: { ...state.entries, ..._keyBy(action.data, 'id') }
      })

    default:
      return state
  }
}
