import { SearchParams } from 'elasticsearch'
const ELASTIC_URL_MODELS = '/elastic/models/_search'
const ELASTIC_URL_VIRTUAL_PRODUCTS = '/elastic/virtual-products/_search'

let controller: AbortController
let abortTimer: number
export const searchModels = async (payload: SearchParams) => {
  if (controller && abortTimer) {
    clearTimeout(abortTimer)
    controller.abort()
  }

  controller = new window.AbortController()
  const { signal } = controller
  const _abortTimer = setTimeout(() => {
    controller.abort()
    throw new Error('Request timed out')
  }, 30000)
  abortTimer = _abortTimer

  const res = await window.fetch(ELASTIC_URL_MODELS, {
    signal,
    credentials: 'same-origin',
    redirect: 'manual',
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'POST',
    body: JSON.stringify(payload)
  })

  clearTimeout(abortTimer)

  if (res.status === 200) {
    return res.json()
  } else {
    throw new Error('Something went wrong')
  }
}

export const searchVirtualProducts = async (payload: SearchParams) => {
  if (controller && abortTimer) {
    clearTimeout(abortTimer)
    controller.abort()
  }

  controller = new window.AbortController()
  const { signal } = controller
  const _abortTimer = setTimeout(() => {
    controller.abort()
    throw new Error('Request timed out')
  }, 30000)
  abortTimer = _abortTimer

  const res = await window.fetch(ELASTIC_URL_VIRTUAL_PRODUCTS, {
    signal,
    credentials: 'same-origin',
    redirect: 'manual',
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'POST',
    body: JSON.stringify(payload)
  })

  clearTimeout(abortTimer)

  if (res.status === 200) {
    return res.json()
  } else {
    throw new Error('Something went wrong')
  }
}

export const fetchAggs = async (payload: SearchParams) => {
  const res = await window.fetch(ELASTIC_URL_MODELS, {
    credentials: 'same-origin',
    redirect: 'manual',
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'POST',
    body: JSON.stringify(payload)
  })

  if (res.status === 200) {
    return res.json()
  } else {
    throw new Error('Something went wrong')
  }
}
