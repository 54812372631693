import React from 'react'

export const IconMagnifyingGlass = ({ color, width, height, className }) => {
  return (
    <svg width={width || '1em'} height={height || '1em'} viewBox='0 0 24 24' className={className}>
      <g id='icon-search-plus' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <path
          d='M21.707,20.293 L20.293,21.706 L14.9,16.314 C13.504,17.404 11.799,18 10,18 C7.863,18 5.854,17.168 4.344,15.656 C2.833,14.146 2,12.137 2,10 C2,7.863 2.833,5.854 4.344,4.344 C5.854,2.832 7.863,2 10,2 C12.137,2 14.146,2.832 15.656,4.344 C17.167,5.854 18,7.863 18,10 C18,11.799 17.403,13.504 16.314,14.9 L21.707,20.293 Z M10,16 C11.603,16 13.109,15.376 14.242,14.243 C15.376,13.11 16,11.603 16,10 C16,8.398 15.376,6.891 14.242,5.758 C13.109,4.624 11.603,4 10,4 C8.397,4 6.891,4.624 5.758,5.758 C4.624,6.891 4,8.398 4,10 C4,11.603 4.624,13.11 5.758,14.243 C6.891,15.376 8.397,16 10,16 Z M13.5,9.5 L13.5,10.5 L10.5,10.5 L10.5,13.5 L9.5,13.5 L9.5,10.5 L6.5,10.5 L6.5,9.5 L9.5,9.5 L9.5,6.5 L10.5,6.5 L10.5,9.5 L13.5,9.5 Z'
          id='Combined-Shape'
          fill={color}
        />
      </g>
    </svg>
  )
}
