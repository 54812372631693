import React from 'react'

const IconRoomsets = props => (
  <svg
    width={props.size || props.width}
    height={props.size || props.height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 70 70"
  >
    <path
      d="M51.2 38.5l1.5-1.4-4.5-4.9V19.4h-32v32h32v-9.8h-2v7.8H32.4v-3.7h-2v3.7H18.2V35.1h12.2v4.2h2v-6.2H18.2V21.4h28v5.2h-6v2h6v4.3z"/>
  </svg>
)

IconRoomsets.defaultProps = {
  color: 'currentcolor',
  width: '1em',
  height: '1em',
  size: '1em'
}

export default IconRoomsets
