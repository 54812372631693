import { EventEmitter } from 'events'
import get from 'lodash/get'
/**
 * Forked from:
 * @author zz85 / https://github.com/zz85
 * @author mrdoob / http://mrdoob.com
 * Running this will allow you to drag three.js objects around the screen.
 */

import {
  Intersection,
  Matrix4,
  Object3D,
  PerspectiveCamera,
  Plane,
  Raycaster,
  Vector2,
  Vector3
} from 'three'

type EventType =
  'drag' |
  'dragstart' |
  'dragend' |
  'hoveron' |
  'hoveroff' |
  'move' |
  'select' |
  'pointerdown' |
  'pointerup'

export type DragEvent = {
  object?: Object3D | null
  intersection?: Intersection
  point?: Vector3
  selected?: Object3D | null
  deselected?: Object3D[]
  snappedPoint?: Vector3 | null
  prevHovered?: Object3D | null
  altKey?: boolean
}

export class DragControls extends EventEmitter {
  private plane = new Plane(new Vector3(0, 1, 0), 0)
  private raycaster = new Raycaster()

  private mouse = new Vector2()
  private offset = new Vector3()
  private intersection = new Vector3()
  private worldPosition = new Vector3()
  private inverseMatrix = new Matrix4()
  private intersections: Intersection[] = []
  private transformGroup = false

  selection = new Set<Object3D>()
  active: Object3D | null = null
  private hovered: Object3D | null = null
  private _translationSnap: number | null = null
  public get translationSnap (): number | null {
    return this._translationSnap
  }

  public set translationSnap (value: number | null) {
    this._translationSnap = value
  }

  private objects: Object3D[] = []

  enabledDrag = true
  enabled = true

  private _defaultCursor: string = 'auto'
  private _pointerCursor: string = 'pointer'

  constructor (private camera: PerspectiveCamera, private domElement: any) {
    super()
  }

  addListener (type: EventType, cb: (event: DragEvent) => void) {
    super.addListener(type, cb)
    return this
  }

  removeListener (type: EventType, cb: (event: DragEvent) => void) {
    super.removeListener(type, cb)
    return this
  }

  public set cursor (value: 'moving' | 'move' | 'pointer' | 'auto') {
    if (value === 'auto') {
      this.domElement.style.cursor = this._defaultCursor
    }
    if (value === 'pointer') {
      this.domElement.style.cursor = this._pointerCursor
    }
    if (value === 'move') {
      this.domElement.style.cursor = value
    }
    if (value === 'moving') {
      this.domElement.style.cursor = value
    }
  }

  public set pointerCursor (value: string) {
    this._pointerCursor = value
    this.cursor = 'pointer'
  }

  public set defaultCursor (value: string) {
    this._defaultCursor = value
    this.cursor = 'auto'
  }

  activate () {
    this.domElement.addEventListener('mousemove', this.onDocumentMouseMove, false)
    this.domElement.addEventListener('mousedown', this.onDocumentMouseDown, false)
    this.domElement.addEventListener('mouseup', this.onDocumentMouseCancel, false)
    this.domElement.addEventListener('mouseleave', this.onDocumentMouseCancel, false)
    this.domElement.addEventListener('touchmove', this.onDocumentTouchMove, false)
    this.domElement.addEventListener('touchstart', this.onDocumentTouchStart, false)
    this.domElement.addEventListener('touchend', this.onDocumentMouseCancel, false)
  }

  deactivate () {
    this.domElement.removeEventListener('mousemove', this.onDocumentMouseMove, false)
    this.domElement.removeEventListener('mousedown', this.onDocumentMouseDown, false)
    this.domElement.removeEventListener('mouseup', this.onDocumentMouseCancel, false)
    this.domElement.removeEventListener('mouseleave', this.onDocumentMouseCancel, false)
    this.domElement.removeEventListener('touchmove', this.onDocumentTouchMove, false)
    this.domElement.removeEventListener('touchstart', this.onDocumentTouchStart, false)
    this.domElement.removeEventListener('touchend', this.onDocumentMouseCancel, false)

    this.cursor = 'auto'
  }

  onDocumentMouseMove = (event: MouseEvent) => {
    event.preventDefault()

    var rect = this.domElement.getBoundingClientRect()

    this.mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1
    this.mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1

    this.raycaster.setFromCamera(this.mouse, this.camera)

    if (this.active && this.enabled && this.enabledDrag) {
      this.dragObject(this.active, event)
      return
    }

    this.intersections.length = 0

    this.raycaster.setFromCamera(this.mouse, this.camera)
    this.intersections = this.raycaster.intersectObjects(this.objects, true)

    if (this.intersections.length > 0) {
      this.intersections.sort((a, b) => (a.object.userData.sortOrder || 0) - (b.object.userData.sortOrder || 0))
      var object = this.intersections[0].object

      this.plane.setFromNormalAndCoplanarPoint(this.camera.getWorldDirection(this.plane.normal), this.worldPosition.setFromMatrixPosition(object.matrixWorld))

      if (this.hovered !== object) {
        this.emit('hoveron', { object: object, prevHovered: this.hovered, altKey: event.altKey })
        this.cursor = 'pointer'
        this.hovered = object
      }
    } else if (this.hovered !== null) {
      this.emit('hoveroff', { object: this.hovered, altKey: event.altKey })
      this.cursor = 'auto'
      this.hovered = null
    }

    if (this.raycaster.ray.intersectPlane(this.plane, this.intersection)) {
      let snappedPoint = null
      if (!event.shiftKey && this.translationSnap) {
        snappedPoint = this.intersection.clone()
        snapPointToGrid(snappedPoint, this.translationSnap)
      } else {
        snappedPoint = this.intersection.clone()
        snapPointToGrid(snappedPoint, 0.1)
      }

      this.emit('move', {
        point: this.intersection,
        snappedPoint: snappedPoint
      })
    }
  }

  onDocumentMouseDown = (event: MouseEvent) => {
    if (event.button === 0) {
      event.preventDefault()
      this.onPointerDown(event)
    }
  }

  onDocumentMouseCancel = (event: MouseEvent | TouchEvent) => {
    event.preventDefault()
    if (this.active && this.enabledDrag) {
      this.emit('dragend', { object: this.active })
      this.active = null
    }

    if (event.constructor === TouchEvent) {
      this.cursor = 'auto'
    } else {
      this.cursor = this.hovered ? 'pointer' : 'auto'
    }

    this.emit('pointerup', { object: this.active })
  }

  onDocumentTouchMove = (event: TouchEvent) => {
    event.preventDefault()
    const _event = event.changedTouches[0]

    var rect = this.domElement.getBoundingClientRect()

    this.mouse.x = ((_event.clientX - rect.left) / rect.width) * 2 - 1
    this.mouse.y = -((_event.clientY - rect.top) / rect.height) * 2 + 1

    this.raycaster.setFromCamera(this.mouse, this.camera)

    if (this.active && this.enabled && this.enabledDrag) {
      this.dragObject(this.active, event)
    }
  }

  onDocumentTouchStart = (event: TouchEvent) => {
    event.preventDefault()
    const _event = event.changedTouches[0]

    var rect = this.domElement.getBoundingClientRect()

    this.mouse.x = ((_event.clientX - rect.left) / rect.width) * 2 - 1
    this.mouse.y = -((_event.clientY - rect.top) / rect.height) * 2 + 1

    this.onPointerDown(event)
  }

  private dragObject (object: Object3D, event: TouchEvent | MouseEvent) {
    if (this.raycaster.ray.intersectPlane(this.plane, this.intersection)) {
      const point = this.intersection.sub(this.offset).applyMatrix4(this.inverseMatrix)
      if (this.translationSnap && !event.shiftKey) {
        snapPointToGrid(point, this.translationSnap)
      } else {
        snapPointToGrid(point, 0.1)
      }
      object.position.copy(point)
    }

    this.emit('drag', { object: object })
  }

  private onPointerDown (event: TouchEvent | MouseEvent) {
    this.intersections.length = 0

    this.raycaster.setFromCamera(this.mouse, this.camera)
    this.raycaster.intersectObjects(this.objects, true, this.intersections)

    this.active = null

    if (this.intersections.length > 0) {
      this.intersections.sort((a, b) => (a.object.userData.sortOrder || 0) - (b.object.userData.sortOrder || 0))
      this.active = (this.transformGroup === true) ? this.objects[0] : findRootNode(this.intersections[0].object)

      this.plane.setFromNormalAndCoplanarPoint(this.camera.getWorldDirection(this.plane.normal), this.worldPosition.setFromMatrixPosition(this.active.matrixWorld))

      if (this.raycaster.ray.intersectPlane(this.plane, this.intersection)) {
        this.inverseMatrix.copy(this.active.parent!.matrixWorld).invert()
        this.offset.copy(this.intersection).sub(this.worldPosition.setFromMatrixPosition(this.active.matrixWorld))
      }

      this.domElement.style.cursor = 'move'

      let selected: Object3D | null = null
      let deselected: Object3D[] = []

      if (this.selection.has(this.active)) {
        deselected = [this.active]
      } else {
        selected = this.active
      }

      if (!event.shiftKey) {
        deselected = Array.from(this.selection)
        this.selection.clear()
      }

      if (selected) this.selection.add(selected)

      this.emit('select', { selected, deselected })
      this.emit('dragstart', { object: this.active })
    } else {
      this.emit('select', { deselected: Array.from(this.selection) })
      this.selection.clear()
    }

    if (this.raycaster.ray.intersectPlane(this.plane, this.intersection)) {
      const point = this.intersection
      if (this.translationSnap && !event.shiftKey) {
        snapPointToGrid(point, this.translationSnap)
      } else {
        snapPointToGrid(point, 0.1)
      }

      this.emit('pointerdown', { object: this.active, point, altKey: event.altKey })
    }
  }

  dispose () {
    this.deactivate()
  }

  getObjects () {
    return this.objects
  }

  setObjects (objects: Object3D[]) {
    this.objects = objects
  }
}

function snapPointToGrid (point: Vector3, gridSize: number) {
  point.set(
    Math.round(point.x / gridSize) * gridSize,
    Math.round(point.y / gridSize) * gridSize,
    Math.round(point.z / gridSize) * gridSize
  )
}

function findRootNode (obj: Object3D, objectPath = 'userData.isRootNode') {
  var tmp = obj
  while (tmp.parent && tmp.parent.parent && !get(tmp, objectPath)) {
    tmp = tmp.parent
  }
  return tmp
}
