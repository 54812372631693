import * as THREE from 'three'
import { EventEmitter } from 'events'

const _isUndefined = require('lodash/isUndefined')
const _uniqBy = require('lodash/uniqBy')
const _throttle = require('lodash/throttle')
const _keyBy = require('lodash/keyBy')
const _intersection = require('lodash/intersection')
const _map = require('lodash/map')

const PLANE_OPACITY = 0.5
const FRACTION_OF_SCREEN = 0.15
const UNIT = 1

const AXES = ['x', 'y', 'z']
const POSITIONS = [[1, 1], [1, 0], [1, -1], [0, 1], [0, 0], [0, -1], [-1, 1], [-1, 0], [-1, -1]]

const POINTS = AXES.reduce((memo, axis) => {
  memo[axis] = POSITIONS.reduce((memo, [a, b]) => {
    if (axis === 'x') return memo.concat([[1, a, b], [-1, a, b]])
    if (axis === 'y') return memo.concat([[a, 1, b], [a, -1, b]])
    if (axis === 'z') return memo.concat([[a, b, 1], [a, b, -1]])
  }, [])
  return memo
}, {})

export default class AlignTool extends EventEmitter {
  constructor (app, domElement, options = {}) {
    super()
    this._app = app
    this._domElement = domElement

    this.enabled = false
    this.spacing = 0
    this.positions = POSITIONS

    this.planeOpacity = options.planeOpacity || PLANE_OPACITY
    this.fractionOfScreen = options.fractionOfScreen || FRACTION_OF_SCREEN
    this._raycaster = new THREE.Raycaster()

    this._hasSetPreviewVisibility = false
    this._hasSetOpacity = false

    this._previewMaterial = new THREE.MeshStandardMaterial({
      transparent: true,
      opacity: this.planeOpacity,
      color: new THREE.Color(0, 0, 1),
      side: THREE.FrontSide
    })

    this.previewRenderMaterial = this._app.assetManager.addMaterial(this._previewMaterial, 'alignPreviewMaterial')

    this._axisMaterials = this._getAxisMaterials()

    this.controlPreviews = new THREE.Object3D()
    this.control = new THREE.Object3D()
    this.control.visible = false

    this._alignBox = new THREE.Mesh(
      new THREE.BoxBufferGeometry(1, 1, 1),
      new THREE.MeshBasicMaterial({ color: 0xffffff, transparent: true, opacity: 0 })
    )

    this.control.add(this._alignBox)

    const planes = this._getPlanes()
    this._clickableAlignPoints = []
    planes.forEach((obj) => this._clickableAlignPoints.push(obj.children[0]))
    this.control.add.apply(this.control, planes)

    this._alignPoints = this._getAlignPoints(planes)
  }

  setFractionOfScreen (fractionOfScreen) {
    this.fractionOfScreen = fractionOfScreen
    if (this.enabled && this._alignPointsEnabled) {
      this.updateAlignObjects()
      this.emit('changed')
    }
  }

  setPlaneOpacity (opacity) {
    this.planeOpacity = opacity
    if (this.enabled && this._clickableAlignPoints) {
      this._clickableAlignPoints.forEach((object) => (object.material.opacity = opacity))
    }
    this.emit('changed')
  }

  setSpacing (spacing) {
    this.spacing = spacing
    this.emit('changed')
  }

  setPreviewColor (color) {
    this._previewMaterial.color.set(color)
    this.emit('changed')
  }

  _getAxisMaterials () {
    const materials = {}

    materials.x = new THREE.MeshBasicMaterial({
      color: 0xff0000,
      fog: false,
      opacity: this.planeOpacity,
      transparent: true,
      side: THREE.FrontSide,
      depthWrite: false,
      depthTest: false
    })

    materials.y = materials.x.clone()
    materials.z = materials.x.clone()
    materials.y.color.set(0x00ff00)
    materials.z.color.set(0x0000ff)

    return materials
  }

  _getAlignPoints (planes) {
    return Object.entries(POINTS).reduce((memo, [axis, positions]) => {
      return memo.concat(positions.map((coordinates) => {
        const position = new THREE.Vector3()
        const offset = new THREE.Vector3()

        return {
          coordinates: coordinates,
          axis: axis,
          direction: Math.sign(offset[axis]),
          position: position,
          offset: offset
        }
      }))
    }, [])
      .map((point, index) => {
        const object = planes[index]

        object.children[0].material = this._axisMaterials[point.axis].clone()
        object.children[0].material.opacity = this.planeOpacity

        point.object = object

        return point
      })
      .reduce((memo, next) => Object.assign(memo, { [next.object.uuid]: next }), {})
  }

  _getPlanes () {
    const size = 0.5
    const multiplier = size * 0.5

    const geometry = new THREE.PlaneGeometry(size, size)
    const plane = new THREE.Mesh(geometry, this._axisMaterials.x)

    return Object.entries(POINTS).map(([axis, positions]) => {
      return positions.map(([x, y, z]) => {
        const planeClone = plane.clone()
        const object = new THREE.Object3D()

        object.add(planeClone)
        object.userData.isModelRoot = true
        object.renderOrder = 200

        planeClone.material = this._axisMaterials[axis].clone()

        if (axis === 'x') {
          planeClone.position.y = -multiplier * y
          planeClone.position.x = x * (multiplier * z)
        }

        if (axis === 'y') {
          planeClone.position.x = -multiplier * x
          planeClone.position.y = y * (multiplier * z)
        }

        if (axis === 'z') {
          planeClone.position.y = z * (-multiplier * y)
          planeClone.position.x = -multiplier * x
        }

        if (axis === 'z' && z < 0) object.rotateX(z * Math.PI)
        if (axis === 'x') object.rotateY(x * (Math.PI / 2))
        if (axis === 'y') object.rotateX(-y * (Math.PI / 2))

        return object
      })
    }).reduce((memo, next) => memo.concat(next))
  }

  enable (autoMaster = false) {
    this.enabled = true

    const objects = _uniqBy(_map(this._app.picker.selection, (child) => this._app.viewerUtils.findRootNode(child)), 'uuid')

    if (objects && objects.length > 0) {
      this._objectsToAlign = objects

      this._setupPreviews(objects)

      if (autoMaster) {
        const master = objects[objects.length - 1]
        this._updateAlignPoints(master)
      }

      if (!this._onMouseDown) {
        this._onMouseDown = this.onMouseDown.bind(this)
        this._domElement.addEventListener('mousedown', this._onMouseDown, false)
      }

      if (!this._onMouseMoveListener) {
        this._onMouseMoveListener = _throttle(this.onMouseMove.bind(this), 16, { leading: false, trailing: true })
        this._throttledResetPreviews = _throttle(this._resetPreviews.bind(this), 100, { leading: false, trailing: true })
        this._throttledPreviewAlign = _throttle(this.align.bind(this), 100, { leading: false, trailing: true })
        this._domElement.addEventListener('mousemove', this._onMouseMoveListener, false)
      }

      this.emit('changed')
    }
  }

  disable () {
    this.enabled = false
    this.control.visible = false
    this._disposePreviews()

    this._domElement.removeEventListener('mousemove', this._onMouseMoveListener, false)
    this._domElement.removeEventListener('mousedown', this._onMouseDown, false)
    this._domElement.removeEventListener('mouseup', this._onMouseUp, false)

    delete this._onMouseDown
    delete this._onMouseUp
    delete this._onMouseMoveListener
    delete this._objectsToAlign
    delete this._master
    delete this._masterPreview

    delete this._throttledResetPreviews
    delete this._throttledPreviewAlign

    this.emit('changed')
  }

  _disposePreviews () {
    Object.values(this._previews || {}).forEach((preview) => {
      preview.source.visible = true
      this.controlPreviews.remove(preview.bbHelper)

      if (preview.object.parent) {
        preview.object.parent.remove(preview.object)
      }
    })

    delete this._previews
    delete this._clickablePreviewObjects
    delete this._previewsBySourceUuid
    delete this._previewObjectsToAlign
  }

  dispose () {
    this.disable()
  }

  handleClick (event) {
    const mouseCoordinates = getMouseCoordinates(this._domElement, event.clientX, event.clientY)
    this._raycaster.setFromCamera(mouseCoordinates, this._app.camera)

    const intersectsAlignPoints = this._alignPointsEnabled ? this._raycaster.intersectObjects(this._clickableAlignPoints) : []

    // TODO: @Martin This is a super dumb hack to raycast against invisible meshes.
    const pickerObjects = this._clickablePreviewObjects || []
    const visibleBefore = pickerObjects.map(o => o.visible)
    pickerObjects.forEach(o => { o.visible = true })
    const intersectsObjects = this._raycaster.intersectObjects(pickerObjects)
    pickerObjects.forEach((o, i) => { o.visible = visibleBefore[i] })

    const clickedPreview = this._previews && intersectsAlignPoints.length === 0 && intersectsObjects.length > 0
    const clickedAlignPoint = this._alignPoints && intersectsAlignPoints.length > 0

    if (!clickedAlignPoint && !clickedPreview) {
      this._disposePreviews()
      this._alignPointsEnabled = false
      this.emit('deselect')
    }

    if (clickedAlignPoint) {
      var uuid = this._app.viewerUtils.findRootNode(intersectsAlignPoints[0].object).uuid
      var alignPoint = this._alignPoints[uuid]

      if (alignPoint) {
        const originalPositions = this.align({
          objects: this._objectsToAlign,
          axis: alignPoint.axis,
          direction: alignPoint.direction,
          coordinates: alignPoint.coordinates,
          offset: alignPoint.offset
        })

        this._disposePreviews()
        this.control.visible = false
        this._alignPointsEnabled = false
        this.emit('aligned', this._objectsToAlign, originalPositions)
      }
    }

    if (clickedPreview && !clickedAlignPoint) {
      this._updateAlignPoints(intersectsObjects[0].object)
      this._hidePreviewSources()
    }

    this.emit('changed')
  }

  _hidePreviewSources (object) {
    Object.values(this._previews).forEach((preview) => {
      if (!object || preview.object.uuid !== object.uuid) {
        preview.object.visible = true
        preview.source.visible = false
      }
    })

    if (this._master) {
      this._masterPreview.visible = false
      this._master.visible = true
    }
  }

  _resetPreviews () {
    if (this._previews) {
      Object.values(this._previews).forEach((preview) => {
        preview.object.position.copy(preview.source.position)
        preview.bbHelper.box = this._app.viewerUtils.getBoundingBox([preview.object])
        preview.bbHelper.updateMatrixWorld()
      })

      this._hidePreviewSources()
    }
  }

  onMouseMove (event, optRaycaster) {
    const mouseCoordinates = getMouseCoordinates(this._domElement, event.clientX, event.clientY)
    this._raycaster.setFromCamera(mouseCoordinates, this._app.camera)

    // TODO: @Martin This is a super dumb hack to raycast against invisible meshes.
    const pickerObjects = this._clickablePreviewObjects || []
    const visibleBefore = pickerObjects.map(o => o.visible)
    pickerObjects.forEach(o => { o.visible = true })
    const intersectsObjects = this._raycaster.intersectObjects(pickerObjects)
    pickerObjects.forEach((o, i) => { o.visible = visibleBefore[i] })

    let intersectsAlignPoints = []
    let intersectsAlignBox = false

    if (this._alignPointsEnabled) {
      intersectsAlignPoints = this._raycaster.intersectObjects(this._clickableAlignPoints)
      intersectsAlignBox = this._raycaster.intersectObject(this._alignBox).length > 0
    }

    const hoveredAlignPoint = this._alignPoints && intersectsAlignPoints.length > 0

    const hoveredPreview = !intersectsAlignBox && intersectsObjects.length > 0 &&
      intersectsObjects[0].object.userData && intersectsObjects[0].object.userData.previewRoot

    if (!intersectsAlignBox && !hoveredPreview && !hoveredAlignPoint) {
      this._throttledResetPreviews && this._throttledResetPreviews()
    }

    if (hoveredAlignPoint) {
      this._clickableAlignPoints.forEach((object) => (object.material.opacity = this.planeOpacity))

      const hoveredAlignPoint = intersectsAlignPoints[0].object
      hoveredAlignPoint.material.opacity = 1

      this._hasSetOpacity = true

      var uuid = this._app.viewerUtils.findRootNode(hoveredAlignPoint).uuid
      const alignPoint = this._alignPoints[uuid]

      if (alignPoint && this._throttledPreviewAlign) {
        this._throttledPreviewAlign({
          preview: true,
          axis: alignPoint.axis,
          direction: alignPoint.direction,
          coordinates: alignPoint.coordinates
        })
      }
    } else if (this._hasSetOpacity) {
      this._hasSetOpacity = false
      this._clickableAlignPoints.forEach((object) => (object.material.opacity = this.planeOpacity))
    }

    if (hoveredPreview && !hoveredAlignPoint) {
      hoveredPreview.visible = false

      // TODO: Can we change how we visualise hovering to changing preview material color?
      // This would avoid the issue of tracing against invisible meshes.
      const source = hoveredPreview.userData.master
      if (source) { source.visible = true }

      this._hasSetPreviewVisibility = true
      this._hidePreviewSources(hoveredPreview)
    } else if (this._hasSetPreviewVisibility) {
      this._hasSetPreviewVisibility = false
      this._hidePreviewSources()
    }

    this.emit('changed')
    this.emit('mouseMove')
  }

  onMouseDown (event) {
    var mouseDownArray = getMousePosition(this._domElement, event.clientX, event.clientY)
    var startPos = new THREE.Vector2().fromArray(mouseDownArray)

    this._onMouseUp = eventUp => {
      var mouseUpArray = getMousePosition(this._domElement, eventUp.clientX, eventUp.clientY)
      var endPos = new THREE.Vector2().fromArray(mouseUpArray)
      this._domElement.removeEventListener('mouseup', this._onMouseUp, false)
      if (startPos.distanceTo(endPos) <= 0.002) { this.handleClick(eventUp) }
    }

    this._domElement.addEventListener('mouseup', this._onMouseUp, false)
  }

  align ({
    objects,
    axis,
    direction,
    offset,
    coordinates,
    preview
  }) {
    if (_isUndefined(objects)) {
      objects = preview ? this._previewObjectsToAlign : this._objectsToAlign
    }

    if (!this._master || objects.length < 2) return

    const originalPositions = cloneObjectsPropertyState(objects, 'position')

    const master = preview ? this._masterPreview : this._master
    const masterBB = this._app.viewerUtils.getBoundingBox([master])
    const masterBBCenter = masterBB.getCenter(new THREE.Vector3())

    offset = offset || this._getPointOffset({ axis, bb: masterBB, bbCenter: masterBBCenter, coordinates })

    offset[axis] = 0

    // TODO: This is retarded
    const spacing = parseFloat(this.spacing)

    objects = objects.filter(object => object.uuid !== master.uuid)

    objects.forEach((object, index) => {
      const prevObjects = objects.slice(0, index)
      prevObjects.unshift(master)

      const prevBB = this._app.viewerUtils.getBoundingBox(prevObjects)
      const prevSize = prevBB.getSize(new THREE.Vector3())

      const bb = this._app.viewerUtils.getBoundingBox([object])
      const bbSize = bb.getSize(new THREE.Vector3())
      const bbCenter = bb.getCenter(new THREE.Vector3())

      const newCenter = prevBB.getCenter(new THREE.Vector3())
      newCenter[axis] = newCenter[axis] + direction * (prevSize[axis] / 2 + bbSize[axis] / 2 + spacing)

      // Align to corner
      Object.keys(offset).forEach((offsetAxis) => {
        var offsetDirection = Math.sign(offset[offsetAxis])
        newCenter[offsetAxis] = newCenter[offsetAxis] + offsetDirection * ((prevSize[offsetAxis] / 2) - (bbSize[offsetAxis] / 2))
      })

      object.position.add(newCenter.clone().sub(bbCenter).applyMatrix4(object.parent.matrixWorld))
    })

    if (preview) {
      // TODO: Can we somehow parent this under the preview object?
      Object.values(this._previews).forEach((preview) => {
        preview.bbHelper.box = this._app.viewerUtils.getBoundingBox([preview.object])
        preview.bbHelper.updateMatrixWorld()
      })

      this.emit('preview')
    }

    return originalPositions
  }

  _getHeightOfScreen () {
    if (!this.enabled || !this._master) return
    const distance = this._master.position.distanceTo(this._app.camera.position)

    const vFOV = THREE.Math.degToRad(this._app.camera.fov)
    const height = 2 * Math.tan(vFOV / 2) * distance

    return height
  }

  updateAlignObjects () {
    if (!this._master) return

    const gap = 0.005
    const heightOfScreen = this._getHeightOfScreen()
    const planeScaleFactor = (this.fractionOfScreen * (2 / 3) - gap) / (UNIT / heightOfScreen)
    const boxScaleFactor = this.fractionOfScreen / (UNIT / heightOfScreen)

    this._alignBox.scale.set(boxScaleFactor, boxScaleFactor, boxScaleFactor)

    Object.values(this._alignPoints).forEach((edge, index) => {
      const {
        object,
        position,
        bbCenter
      } = edge

      const offsetFromCenter = position
        .clone()
        .sub(bbCenter)
        .multiplyScalar(boxScaleFactor)

      const offsetFromWorld = offsetFromCenter.clone()
        .add(bbCenter)

      object.position.copy(offsetFromWorld)
      object.scale.set(planeScaleFactor, planeScaleFactor, planeScaleFactor)
    })
  }

  _getPointOffset ({ coordinates, bb, bbCenter }) {
    return this._getPointPosition({ coordinates, bb, bbCenter }).sub(bbCenter)
  }

  _getPointPosition ({ coordinates, bb, bbCenter }) {
    const [x, y, z] = coordinates

    const _x = x === 1 ? bb.max.x : (x === -1 ? bb.min.x : bbCenter.x)
    const _y = y === 1 ? bb.max.y : (y === -1 ? bb.min.y : bbCenter.y)
    const _z = z === 1 ? bb.max.z : (z === -1 ? bb.min.z : bbCenter.z)

    return new THREE.Vector3(_x, _y, _z)
  }

  _updateAlignPoints (object) {
    this._alignPointsEnabled = true
    this._master = object.userData.master
    this._masterPreview = object.userData.previewRoot

    const meshObject = this._app.renderScene.meshes[object.userData.instancedMeshId]
    this._app.picker.selectMany([meshObject])

    this._masterPreview.visible = false
    this._master.visible = true

    this.control.visible = true

    const bb = this._app.viewerUtils.getBoundingBox([this._master])
    const bbCenter = bb.getCenter(this._alignBox.position)

    // this._alignBox.position.copy(bbCenter)

    const box = new THREE.Box3()
    box.setFromCenterAndSize(bbCenter, new THREE.Vector3(1, 1, 1))

    Object.values(this._alignPoints).forEach((point) => {
      point.position.copy(this._getPointPosition({ axis: point.axis, bb: box, bbCenter, coordinates: point.coordinates }))
      point.offset.copy(this._getPointOffset({ axis: point.axis, bb: bb, bbCenter, coordinates: point.coordinates }))
      point.direction = Math.sign(point.offset[point.axis])
      point.bbCenter = bbCenter
      point.object.position.copy(point.position)
      point.object.children[0].material.opacity = this.planeOpacity
    })

    this.updateAlignObjects()
  }

  _setupPreviews (objects) {
    // this._disposePreviews()
    this._previewObjectsToAlign = []
    this._clickablePreviewObjects = []

    this._previews = objects.reduce((memo, source) => {
      const object = source.clone()
      this._app.scene.addModel(object, {})
      object.userData.master = source
      source.visible = false

      object.castShadow = false
      object.receiveShadow = false
      object.traverseMeshes(n => { n.material = this.previewRenderMaterial })

      // TODO: @Anders @Martin Avoid commit.
      this._app.scene.commitChanges()

      const bbHelper = new THREE.Box3Helper(this._app.viewerUtils.getBoundingBox([source]), 0xffff66)
      this.controlPreviews.add(bbHelper)
      // this.controlPreviews.add(object)

      const raycastingMeshes = getRaycastingMeshes(this._app, object, source)
      this._previewObjectsToAlign.push(object)

      this._clickablePreviewObjects.push(...raycastingMeshes)

      return Object.assign(memo, { [object.uuid]: { object, source, bbHelper } })
    }, {})

    this._previewsBySourceUuid = _keyBy(Object.values(this._previews), preview => preview.source.uuid)
  }

  areObjectsSelected (objects) {
    const objectsToAlign = objects.reduce((memo, next) => {
      const rootNode = this._app.viewerUtils.findRootNode(next)
      return Object.assign(memo, { [rootNode.uuid]: rootNode })
    }, {})

    const selectedUuids = _map(this._objectsToAlign, 'uuid')
    const rootNodeUuids = Object.keys(objectsToAlign)

    return _intersection(selectedUuids, rootNodeUuids).length > 0
  }

  getPointForAxis (axis, direction, index) {
    const [a, b] = this.positions[index]

    if (axis === 'x') return [direction, a, b]
    if (axis === 'y') return [a, direction, b]
    if (axis === 'z') return [a, b, direction]
  }
}

function cloneObjectsPropertyState (objects, property) {
  if (!objects[0][property] || !objects[0][property].clone) return {}

  return objects.reduce((acc, obj) => {
    return Object.assign(acc, {
      [obj.uuid]: obj[property].clone()
    })
  }, {})
}

function getMousePosition (dom, x, y) {
  const rect = dom.getBoundingClientRect()
  const coordinates = [(x - rect.left) / rect.width, (y - rect.top) / rect.height]
  return coordinates
}

function getMouseCoordinates (dom, x, y) {
  const coordinates = getMousePosition(dom, x, y)
  const mouseCoordinates = new THREE.Vector2().fromArray(coordinates)
  mouseCoordinates.set((mouseCoordinates.x * 2) - 1, -(mouseCoordinates.y * 2) + 1)

  return mouseCoordinates
}

function getRaycastingMeshes (app, preview, source) {
  const meshes = []

  preview.traverseMeshes(n => {
    // TODO: Review, Fulhack
    const index = n._instancedIndex
    const mesh = app.renderScene.meshes[n._instancedMeshId].userData.utilityMeshes.get(index)

    mesh.userData.master = source
    mesh.userData.previewRoot = preview
    mesh.userData.instancedMeshId = n._instancedMeshId
    meshes.push(mesh)
  })

  return meshes
}
