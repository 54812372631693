import React from 'react'

const IconMultipleImages = props => (
  <svg viewBox='0 0 20 20' width={props.size} height={props.size} {...props}>
    <path
      d='M18,4.01672572 C18.487053,4.05067184 18.7311996,4.13575975 18.9768457,4.2671327 C19.3027716,4.4414398 19.5585602,4.69722837 19.7328673,5.0231543 C19.9071744,5.34908022 20,5.67236646 20,6.5638852 L20,17.4361148 C20,18.3276335 19.9071744,18.6509198 19.7328673,18.9768457 C19.5585602,19.3027716 19.3027716,19.5585602 18.9768457,19.7328673 C18.6509198,19.9071744 18.3276335,20 17.4361148,20 L6.5638852,20 C5.67236646,20 5.34908022,19.9071744 5.0231543,19.7328673 C4.69722837,19.5585602 4.4414398,19.3027716 4.2671327,18.9768457 C4.13575975,18.7311996 4.05067184,18.487053 4.01672572,18 L15.4361148,18 C16.3276335,18 16.6509198,17.9071744 16.9768457,17.7328673 C17.3027716,17.5585602 17.5585602,17.3027716 17.7328673,16.9768457 C17.9071744,16.6509198 18,16.3276335 18,15.4361148 L18,4.01672572 Z M2.5638852,-1.77635684e-15 L14.4361148,-1.56918878e-15 C15.3276335,-1.73295811e-15 15.6509198,0.0928256111 15.9768457,0.267132704 C16.3027716,0.441439796 16.5585602,0.697228371 16.7328673,1.0231543 C16.9071744,1.34908022 17,1.67236646 17,2.5638852 L17,14.4361148 C17,15.3276335 16.9071744,15.6509198 16.7328673,15.9768457 C16.5585602,16.3027716 16.3027716,16.5585602 15.9768457,16.7328673 C15.6509198,16.9071744 15.3276335,17 14.4361148,17 L2.5638852,17 C1.67236646,17 1.34908022,16.9071744 1.0231543,16.7328673 C0.697228371,16.5585602 0.441439796,16.3027716 0.267132704,15.9768457 C0.0928256111,15.6509198 5.43825008e-15,15.3276335 5.32907052e-15,14.4361148 L5.46718256e-15,2.5638852 C5.358003e-15,1.67236646 0.0928256111,1.34908022 0.267132704,1.0231543 C0.441439796,0.697228371 0.697228371,0.441439796 1.0231543,0.267132704 C1.34908022,0.0928256111 1.67236646,-1.6125875e-15 2.5638852,-1.77635684e-15 Z'
      fill={props.color}
    />
  </svg>
)

IconMultipleImages.defaultProps = {
  color: 'currentcolor',
  size: '1em'
}

export default IconMultipleImages
