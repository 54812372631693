
import React from 'react'
import EmptyState from '../../../../common/empty-state/empty-state'
import { IoMdStats as IconProductConcept } from 'react-icons/io'

export default function EmptyStateDemocraticDesign ({ openModal } : { openModal : (() => void) | undefined}) {
  return (
    <EmptyState
      className='mt4'
      icon={<IconProductConcept size={80} />}
      title={'Product Concept Analysis'}
      desc={'...'}
      docLink={'appearances-colors-patterns.md'}
      linkText={'Learn more'}
      onAction={openModal}
      actionTitle={'Load design'}
    />
  )
}
