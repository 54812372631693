import React from 'react'

import Button from '../button'
import KeyboardListener, { KeyCode, KeyBinding } from '../keyboard-listener'

interface Props {
  formTitle: string
  formDescription: React.ReactNode
  noDescMargin?: boolean
  descClassName?: string
  onCancel?: () => void
  buttonText?: string
  onConfirm?: () => void
}

function FormRemove ({
  formTitle,
  noDescMargin,
  descClassName,
  formDescription,
  onCancel,
  onConfirm,
  buttonText
}: Props) {
  return (
    <div
      data-testid='common-remove-form'
    >
      <h2 className='mt0'>
        {formTitle}
      </h2>

      <div className={`${!noDescMargin && 'mb3'} line-height ${descClassName}`}>
        {formDescription}
      </div>

      {(onCancel || onConfirm) &&
          <div className='mt2 clearfix flex justify-end items-baseline'>
            {onCancel && <Button className='mr2' onClick={onCancel} data-testid='remove-form__cancel'>Cancel</Button>}
            {onConfirm && <Button
              data-testid='remove-form-remove'
              btnType='primary'
              className={'px3'}
              onClick={onConfirm}
            >
              {buttonText}
            </Button>}
          </div>
      }

      <KeyboardListener
        bindings={[
          KeyBinding(KeyCode.enter, onConfirm),
          KeyBinding(KeyCode.esc, onCancel)
        ]}
      />
    </div>
  )
}

export default FormRemove
