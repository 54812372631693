import _get from 'lodash/get'
import { updateFloorBoxMaterial } from './update-materials'

export function updateFloorBox ({ floorBox, size, centerPosition }) {
  return (dispatch) => {
    const shouldUpdateScale = (centerPosition && size)

    let earlierScale = null

    if (shouldUpdateScale) {
      earlierScale = floorBox.scale.clone()
      var floorBoxOriginalSizeY = _get(floorBox, 'userData.originalSize.y', 1)

      var scaleFactorY = (1 / floorBoxOriginalSizeY) * 2

      var floorBoxComputedScaleZ = size.z
      var floorBoxComputedScaleX = size.x

      // Postion
      floorBox.position.y = 0
      floorBox.rotation.x = Math.PI / 2
      floorBox.position.x = centerPosition.x

      floorBox.position.z = centerPosition.z - (size.z / 2)

      // Scale
      floorBox.scale.y = (floorBoxComputedScaleZ / 2) * scaleFactorY
      floorBox.scale.x = floorBoxComputedScaleX
    }

    dispatch(updateFloorBoxMaterial(shouldUpdateScale, earlierScale))
  }
}
