import React from 'react'
import cs from 'classnames'
import autoBind from 'react-autobind'

import KeyboardListener, { KeyCode, KeyBinding } from '../../../common/keyboard-listener'

import Grid from '../../../common/grid/grid'
import Pending from '../../../common/pending'
import Square from '../../../common/Square'

import { DEADLINE_STATUS } from '../../../../constants'

function getStrippedText (text) {
  if (text.length > 25) {
    return text.slice(0, 25) + ' [...]'
  } else {
    return text
  }
}

const CARD_SIZE = 195

function VersionCard (props) {
  const rootRef = React.useRef()

  React.useEffect(() => {
    if (props.selected) {
      props.onScrollIntoView(rootRef.current)
    }
  }, [props.selected])

  return (
    <div
      ref={rootRef}
      className={cs('flex my1 pointer paper-2 c-gray', {
        'border bw-4 bc-primary': props.selected,
        'border bw-4 bc-transparent': !props.selected
      })}
      onClick={() => props.onSelectedVersion(props.versionId, props.versionNumber)}
    >
      <div
        className="bg-white"
        style={{ width: CARD_SIZE, height: 'auto' }}
      >
        {props.version.isRender
          ? <Square
            backgroundImage={props.src}
            className='pointer bc-gray-light'
          />
          : <Pending
            backgroundImage={props.version.backgroundImage}
            deadlineStatus={props.version.deadlineStatus}
            renderPercentage={props.version.renderPercentage}
            renderStatus={props.version.renderStatus}
            status={props.version.status}
            disableButtons
          />
        }
      </div>
      <div className='flex flex-column justify-between' style={{ width: 200 }}>
        <div className='ml1 mr1 mb1'>
          <h4 className="mt1 mb1" style={{ wordBreak: 'break-all' }}>{getStrippedText(props.versionTitle)}</h4>
          <h4 className="mt2 mb2">Version {props.versionNumber}</h4>
          {props.version.deadlineStatus === DEADLINE_STATUS.FAILED
            ? <p className='f7 mt2 mb2'>Couldn't create image</p>
            : <p className='f7 mt2 mb2'>{props.version.isRender ? `Created: ${props.createdAt}` : 'Not ready, still in progress'}</p>
          }
        </div>
        {props.isDefault && <div className="bg-secondary"><h4 className="c-white bold center m1" style={{ lineHeight: '1.5' }}>Final <br/> Version</h4></div>}
      </div>
    </div>
  )
}

export default class VersionList extends React.PureComponent {
  constructor (props) {
    super(props)
    autoBind(this, 'handleScrollIntoView')
  }

  handleScrollIntoView (domNode) {
    const scrollY = domNode.offsetTop - (this._scrollRoot.offsetHeight * 0.5)
    this._scrollRoot.scrollTo(0, scrollY)
  }

  render () {
    const {
      onSelectedVersion,
      versions,
      activeId,
      onUpKeyEvent,
      onDownKeyEvent
    } = this.props

    return (
      <Grid
        gridGap={0}
        rows={['auto', '1fr']}
        columns={[400]}
        className='ml1 overflow-hidden c-gray f6'
        style={{ gridArea: 'info' }}
      >
        <div className='ml1 smooth-scroll overflow-y-scroll' ref={node => (this._scrollRoot = node)}>
          {versions.map((version, index) => (
            <VersionCard
              key={version.id}
              versionId={version.id}
              src={version.images.thumbnail}
              versionNumber={versions.length - index}
              createdAt={version.renderedAt}
              selected={version.id === activeId}
              onSelectedVersion={onSelectedVersion}
              versionTitle={version.combinationTitle}
              isDefault={version.isDefault}
              version={version}
              onScrollIntoView={this.handleScrollIntoView}
            />
          ))}
        </div>
        <KeyboardListener
          disabled={versions.length <= 1}
          bindings={[
            KeyBinding(KeyCode.up, onUpKeyEvent),
            KeyBinding(KeyCode.down, onDownKeyEvent)
          ]}
        />
      </Grid>
    )
  }
}
