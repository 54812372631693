import React from 'react'
import ReactDOM from 'react-dom'

export function SubToolbarPortal () {
  return <div id="subToolbarPortal" />
}

type Props = {
  open: boolean
  children: any
  className?: string
}

export default function SubToolbar (props: Props) {
  const domNode = document.getElementById('subToolbarPortal')

  if (!props.open || !domNode) {
    return null
  }

  return ReactDOM.createPortal(
    <div
      style={{
        position: 'fixed',
        top: 64,
        left: 64,
        right: 64,
        height: 64
      }}
      className={props.className || ''}
    >
      {props.children}
    </div>, domNode
  )
}
