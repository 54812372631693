import store from '../../stores'

import * as fromProjects from '../../stores/ducks/projects'
import * as fromUsersSelectors from '../../stores/ducks/users/selectors'

export default function handleProjects (msg) {
  const { doc, unShares = [], status } = msg

  if (unShares.includes(fromUsersSelectors.getCurrentId(store.getState())) || status === 'deleted') {
    return store.dispatch(fromProjects.removed(doc.id))
  }

  store.dispatch(fromProjects.receive([doc]))
}
