import React from 'react'

const IconMaterial = props => (
  <svg
    width={props.width || props.size}
    height={props.height || props.size}
    viewBox='2 2 32 32'
    {...props}
  >
    <g fill={props.color}>
      <path d='M2.57142857,9.00253632 C2.57142857,5.45073358 5.44668423,2.57142857 9.00253632,2.57142857 L26.9974637,2.57142857 C30.5492664,2.57142857 33.4285714,5.44668423 33.4285714,9.00253632 L33.4285714,26.9974637 C33.4285714,30.5492664 30.5533158,33.4285714 26.9974637,33.4285714 L9.00253632,33.4285714 C5.45073358,33.4285714 2.57142857,30.5533158 2.57142857,26.9974637 L2.57142857,9.00253632 Z M5,26.8595402 C5,29.0673552 6.78773063,30.8571429 8.99760263,30.8571429 L17.8571429,30.8571429 L17.8571429,18 L5,18 L5,26.8595402 Z M30.8571429,8.99760263 C30.8571429,6.78978766 29.0694122,5 26.8595402,5 L18,5 L18,17.8571429 L30.8571429,17.8571429 L30.8571429,8.99760263 Z' id='Combined-Shape' />
    </g>
  </svg>
)

IconMaterial.defaultProps = {
  color: 'currentcolor',
  size: '1em'
}

export default IconMaterial
