import React from 'react'
import EmptyState from '../../../common/empty-state/empty-state'
import IconGallery from '../../../common/icons/icon-gallery'
import { ModalButton } from '../../../common/modal'
import DesignLoader from '../../../visualizer/design-loader'
import connectSave from '../../../visualizer/panels/buttons/connect-save'
import CreateRender from '../../../visualizer/panels/buttons/create-render'
import SaveDesign from '../../../visualizer/panels/buttons/save-design'

export const EmptyStateCreateImage = connectSave('render')(() => (
  <ModalButton
    small
    modalContent={(closeModal) => (<CreateRender onClose={closeModal} />)}
    button={(openModal) => (
      <EmptyState
        className='mt4'
        icon={<IconGallery size={80} />}
        title='Create images of your design'
        desc='Create an image with the Create Image button in the lower right corner. '
        docLink='images.md'
        linkText='Learn more'
        onAction={openModal}
        actionTitle='Create Image'
      />
    )}
  />
))

export const EmptyStateCreateImagePackage = connectSave('render')(() => (
  <ModalButton
    small
    modalContent={(closeModal) => (<CreateRender onClose={closeModal} />)}
    button={() => (
      <EmptyState
        className='mt4'
        icon={<IconGallery size={80} />}
        title='No image package created'
        desc='Create an image package with the Create Image button in the lower right corner. '
        docLink='images.md'
      />
    )}
  />
))

export const EmptyStateSaveDesign = connectSave('save')(() => (
  <ModalButton
    small
    modalContent={(closeModal) => (<SaveDesign onClose={closeModal} />)}
    button={(openModal) => (
      <EmptyState
        className='mt4'
        icon={<IconGallery size={80} />}
        title='Create images of your design'
        desc='Save your design to create an image of it. Save Design and Create Image are located in the lower right corner'
        docLink='images.md'
        linkText='Learn more'
        onAction={openModal}
        actionTitle='Save Design'
      />
    )}
  />
))

export const EmptyStateLoadDesign = () => (
  <ModalButton
    modalContent={(closeModal) => (
      <DesignLoader
        onClose={closeModal}
        type='load'
      />
    )}
    button={(openModal) => (
      <EmptyState
        className='mt4'
        icon={<IconGallery size={80} />}
        title='Create images of your design'
        desc='Load a design to create an image of it with the Create Image button in the lower right corner. Adjust the image perspective in the Camera panel. The Create Image button will be enabled once the scene contains a model and the design is saved.'
        docLink='basic-designing.md#dpd_addmodelstoascene'
        linkText='Learn more'
        onAction={openModal}
        actionTitle='Load design'
      />
    )}
  />
)
