const inherits = require('inherits')
const EventEmitter = require('events').EventEmitter

inherits(AnnotationEmitter, EventEmitter)
function AnnotationEmitter () {
  this.emitRenderOnNextFrame = () => {
    this.emit('render')
  }

  this.emitToggleRotate = (value) => {
    this.emit('toggle-rotate', value)
  }

  this.emitSelect = (objects) => {
    this.emit('select', objects)
  }

  this.emitClearSelect = () => {
    this.emit('clear-select')
  }
}

module.exports = AnnotationEmitter
