import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GrTooltip } from 'react-icons/gr'
import styled from 'styled-components'
import cs from 'classnames'

import { MAX_WALL_HEIGHT, MIN_WALL_HEIGHT } from '../../../../../../go3dthree/src/Room/RoomDrawing'

import * as fromStorageUtils from '../../../../utils/storage'
import { RootState } from '../../../../stores/ducks'
import * as customHome from '../../../../stores/ducks/threeviewer/custom-home'

import Button from '../../../common/button'
import { StorageApiThumbnail } from '../../../common/storage-api-image'
import { PanelSection, PanelSectionHeader, PanelSectionContent } from '../../../common/panels/panel-section'
import InputNumber from '../../../common/form/input-number'
import AutoFill from '../../../common/grid/AutoFill'
import { Tooltip, TooltipWrapper } from '../../../common/tooltip'

const StyledTooltip = styled(Tooltip)`
  text-align: left;
  white-space: initial;
`

export default function CustomHomeControls (props: { disabled: boolean; }) {
  const dispatch = useDispatch()

  const roomMode = useSelector((state: RootState) => state.threeviewer.customHome.mode)
  const roomView = useSelector((state: RootState) => state.threeviewer.customHome.view)
  const roomWallHeight = useSelector((state: RootState) => state.threeviewer.customHome.wallHeight)
  const roomWallLength = useSelector((state: RootState) => state.threeviewer.customHome.wallLength)
  const activeAssetId = useSelector((state: RootState) => state.threeviewer.customHome.activeHoleModelId)
  const roomAssets = useSelector(customHome.getRoomHoleModels)

  const setWallHeightDisabled = props.disabled || roomView !== '2D'

  return <>
    <PanelSection>
      <PanelSectionHeader
        title='Custom home'
        color='gray-dark'
        noPaddingBottom
        className='mb1'
      />

      <PanelSectionContent>
        <Button
          pad={1}
          onClick={() => {
            dispatch(customHome.deactivate())
            dispatch(customHome.setScenePanelState('DEFAULT_TEMPLATE'))
          }}
          btnType='primary'
        >
          Save and exit
        </Button>
      </PanelSectionContent>

    </PanelSection>

    <PanelSection>
      <PanelSectionHeader
        title='Tools'
        color='gray-dark'
        noPaddingBottom
        className='mb1'
      />
      <PanelSectionContent>
        <div className='flex'>
          <Button
            onClick={() => dispatch(customHome.setMode('DRAWING'))}
            pad={1}
            className='mr1'
            disabled={roomView !== '2D'}
            btnType={roomMode === 'DRAWING' ? 'secondary' : false}
          >
          Draw
          </Button>
          <Button
            onClick={() => dispatch(customHome.setMode('EDITING'))}
            pad={1}
            disabled={roomView !== '2D'}
            className='mr1'
            btnType={roomMode === 'EDITING' ? 'secondary' : false}
          >
          Edit
          </Button>

          <Button
            onClick={() => dispatch(customHome.clear())}
            pad={1}
          >
          Clear
          </Button>
        </div>

        <div className='flex f7 mt1'>
          <div className='muted'>
            Keyboard shortcuts
          </div>
          <TooltipWrapper location='under'>
            <GrTooltip className='ml1 muted' />
            <StyledTooltip>
              <div style={{ width: 200 }}>
                <p>Hold in SHIFT-key and click to select multiple items.</p>
                <p>Hold in SHIFT-key for more precision while drawing and editing.</p>
                <p>Hold in ALT-key for adding corners to walls or remove them while drawing.</p>
                <p>Use backspace to remove a selection.</p>
              </div>
            </StyledTooltip>
          </TooltipWrapper>
        </div>

      </PanelSectionContent>
    </PanelSection>

    <PanelSection>
      <PanelSectionHeader
        title='Camera perspective'
        color='gray-dark'
        noPaddingBottom
        className='mb1' />
      <PanelSectionContent>
        <div className='flex'>
          <Button
            onClick={() => dispatch(customHome.to2D())}
            className='mr1'
            pad={1}
            btnType={roomView === '2D' ? 'secondary' : false}
          >
            2D
          </Button>

          <Button
            onClick={() => dispatch(customHome.to3D())}
            btnType={roomView === '3D' ? 'secondary' : false}
            pad={1}
          >
            3D
          </Button>
        </div>
      </PanelSectionContent>
    </PanelSection>

    <PanelSection>
      <PanelSectionHeader
        title='Wall height'
        color='gray-dark'
        noPaddingBottom
        className='mb1' />
      <PanelSectionContent>
        <div className='flex'>
          <InputNumber
            disabled={setWallHeightDisabled}
            value={roomWallHeight * 1000}
            onChange={(value) => dispatch(customHome.setWallHeight(value / 1000))}
            min={MIN_WALL_HEIGHT * 1000}
            max={MAX_WALL_HEIGHT * 1000}
          />
          <div className='f8 ml1 flex flex-column justify-center'>
            mm
          </div>
        </div>
      </PanelSectionContent>
    </PanelSection>

    <PanelSection>
      <PanelSectionHeader
        title='Selected wall length'
        color='gray-dark'
        noPaddingBottom
        className='mb1' />
      <PanelSectionContent>
        <div className='flex'>
          <div>{roomWallLength ? (roomWallLength * 1000).toFixed(0) : 0}</div>
          <div className='f8 ml1 flex flex-column justify-center'>
            mm
          </div>
        </div>
        <div className='f7 mt1 c-error'>
          Final wall length is affected by wall thickness.
        </div>
      </PanelSectionContent>
    </PanelSection>

    <PanelSection>
      <PanelSectionHeader
        title='Windows'
        color='gray-dark'
        noPaddingBottom
        className='mb1' />
      <PanelSectionContent>
        <AutoFill width={60}>
          {roomAssets.filter(combination => combination.metadata?.type === 'window').map(combination => (
            <WallAsset
              key={combination.id}
              disabled={roomView === '3D'}
              active={roomMode === 'ADD_HOLE' && activeAssetId === combination.id}
              manifest={combination.render?.manifest}
              title={combination.title}
              onClick={() => dispatch(customHome.setActiveHoleModel(combination))} />
          ))}
        </AutoFill>
      </PanelSectionContent>
    </PanelSection>

    <PanelSection>
      <PanelSectionHeader
        title='Doors'
        color='gray-dark'
        noPaddingBottom
        className='mb1' />
      <PanelSectionContent>
        <AutoFill width={60}>
          {roomAssets.filter(combination => combination.metadata?.type === 'door').map(combination => (
            <WallAsset
              key={combination.id}
              active={roomMode === 'ADD_HOLE' && activeAssetId === combination.id}
              manifest={combination.render?.manifest}
              title={combination.title}
              disabled={roomView === '3D'}
              onClick={() => dispatch(customHome.setActiveHoleModel(combination))} />
          ))}
        </AutoFill>
      </PanelSectionContent>
    </PanelSection>
  </>
}
function WallAsset (props: {
  active: boolean;
  title: string;
  manifest?: any;
  disabled: boolean;
  onClick: () => void;
}) {
  return (
    <div
      onClick={props.onClick}
      className={cs('pointer border bw-2', {
        'br-small bc-secondary': props.active,
        'bc-transparent': !props.active,
        disabled: props.disabled
      })}
    >
      <StorageApiThumbnail
        manifest={props.manifest as fromStorageUtils.StorageApiManifest} />
      <p className='f7 mt1 mb0 mx0 center'>{props.title}</p>
    </div>
  )
}
