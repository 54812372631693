import React, { Component } from 'react'
import autoBind from 'react-autobind'
import cs from 'classnames'

import { FaCloudUploadAlt as IconUpload } from 'react-icons/fa'
import UploadFiles from '../../upload-files/index'
import DragAndDropStyled from '../DragAndDropStyled'

import Button from '../button'

class EmptyStateWithUploadAndCreate extends Component {
  constructor (props) {
    super(props)
    autoBind(this)
  }

  render () {
    const {
      className,
      icon,
      title,
      desc,
      docLink,
      linkText,
      onLoadDefaultModel,
      uploadType,
      onUpload,
      multiple,
      supportedFormats,
      disableCreateButton
    } = this.props
    const linkBase = '/support/document/docs'

    return (
      <div className={cs('c-gray-dark mx2 my2 line-height center flex justify-center', className)}>
        <div style={{ maxWidth: 600 }}>
          <div className='width-100 c-gray mb3 flex justify-center'>{icon}</div>
          <h3 className='f4 regular center line-height'>{title}</h3>
          <p className='mb1 f6'>{desc} <span />
            <a
              className='f6 underline pointer'
              href={`${linkBase}/${docLink}`}
              title={linkText}
              target='_blank'
              rel="noopener noreferrer"
            >
              {linkText}
            </a>
          </p>
          <div className='flex justify-center mt3'>
            <UploadFiles
              onUpload={onUpload}
              multiple={multiple}
              supportedFormats={supportedFormats}
            >
              {({
                isDragging
              }) => (
                <DragAndDropStyled
                  style={{
                    width: 330,
                    height: 180,
                    paddingTop: 5
                  }}
                  isDragging={isDragging}
                >
                  <div style={{ padding: 16, pointerEvents: 'none' }}>
                    <IconUpload size={80} />
                    <p className='h4 m0'>Upload {uploadType}</p>
                    <p className='h6 m0'><i>Drag files / Click</i></p>
                  </div>
                </DragAndDropStyled>
              )}
            </UploadFiles>

            {
              !disableCreateButton &&
              <Button
                className='mr2'
                btnType={'secondary'}
                onClick={onLoadDefaultModel}
              >
                Create New Scene
              </Button>
            }
          </div>
        </div>
      </div>
    )
  }
}

export default EmptyStateWithUploadAndCreate
