import React from 'react'

import _xor from 'lodash/xor'
import type { Subtract } from 'utility-types'

interface HOCMultiSelectProps {
  dispose?: boolean
  selectOne?: boolean
}

export interface InjectedHOCMultiSelectProps {
  selected?: any[]
  onSelect?: (value: any) => void
  onClearSelected?: () => void
}

export const HOCMultiSelect = <P extends InjectedHOCMultiSelectProps>(WrappedComponent: React.ComponentType<P>) => (
  props: Subtract<P, InjectedHOCMultiSelectProps> & HOCMultiSelectProps
) => {
  const [selected, setSelected] = React.useState<any[]>([])

  React.useEffect(() => {
    setSelected([])
  }, [props.dispose])

  function handleToggleSelect (value: any) {
    setSelected(props.selectOne
      ? (selected.includes(value) ? [] : [value])
      : _xor(selected, [value]))
  }

  return (
    <WrappedComponent
      {...props as P}
      selected={selected}
      onSelect={handleToggleSelect}
      onClearSelected={() => setSelected([])}
    />
  )
}
