import { RootState } from '..'
import { createSelector } from 'reselect'

import _isEqual from 'lodash/isEqual'
import _filter from 'lodash/filter'
import _sortBy from 'lodash/sortBy'
import _orderBy from 'lodash/orderBy'

import * as fromUsersSelectors from '../users/selectors'

import { SearchableColor } from '../../../components/project/colors/SearchableColor'

import * as fromColorsSelectors from '../colors/selectors'
import { rgbToHsl } from '../colors/utils'

const getLocalState = (state: RootState) => state.admin

export const getHasUserEditChanges = (state: RootState) => {
  const editedUser: any = getLocalState(state).selectedUser
  if (!editedUser) {
    return false
  }

  const unEditedUser = fromUsersSelectors.getById(editedUser.id)(state)
  return !_isEqual(editedUser, unEditedUser)
}

export const getUserEntries = (state: RootState) => {
  const users = fromUsersSelectors.getEntries(state)
  const showDeleted = getLocalState(state).showDeleted

  return (showDeleted)
    ? _filter(users, (user) => user.removedAt || user.gdprRemovedAt)
    : _filter(users, (user) => !user.removedAt && !user.gdprRemovedAt)
}

export const selectAdminNewColorModalOpen = (state: RootState) => state.admin.newColorModalOpen
export const selectAdminEditColorModalOpen = (state: RootState) => state.admin.editColorModalOpen
export const selectAdminRemoveColorModalOpen = (state: RootState) => state.admin.removeColorModalOpen
export const selectAdminColorSearchString = (state: RootState) => state.admin.colorSearchString
export const selectColorPicker = (state: RootState) => state.admin.colorPicker

export const selectIsCurrentUserAdmin = () => fromUsersSelectors.getHasCurrentScopes(['admin', 'developer'])

export const selectAdminColors = createSelector(
  fromColorsSelectors.getColors,
  (state: RootState) => state.admin.colorSearchString,
  (colors, colorSearchString) => {
    const colorsAsList =
      Object.values(colors)
        .map((col: any) => {
          const { r, g, b } = col.ncs.color
          return {
            id: col.id,
            name: col.name,
            ncsTitle: col.ncs.name,
            pantoneTitle: col.pantone.name,
            color: `rgb(${r}, ${g}, ${b})`,
            pantoneColor: 'rgb(' + col.pantone.color.r + ',' + col.pantone.color.g + ',' + col.pantone.color.b + ')',
            hsl: rgbToHsl({ r, g, b }),
            fy: col.fy,
            selectable: col.selectable,
            isPrototypeFabricColor: col.isPrototypeFabricColor
          }
        })

    const sortedSearch = colorsAsList.filter(color => isColorMatchingSearchString(color, colorSearchString))

    function isColorMatchingSearchString (color: SearchableColor, searchString: string) {
      const name = color.name
      const ncs = color.ncsTitle
      const pantone = color.pantoneTitle
      const rgb = color.color
      searchString = searchString.toUpperCase()

      return (
        (name && name.toUpperCase().includes(searchString)) ||
        (ncs && ncs.toUpperCase().includes(searchString)) ||
        (pantone && pantone.toUpperCase().includes(searchString)) ||
        (rgb && rgb.toUpperCase().includes(searchString))
      )
    }

    function putColorsOnTop (colors: SearchableColor[], names: string[]) {
      for (let i = names.length; i >= 0; i--) {
        const index = colors.map(color => color.name).indexOf(names[i])
        if (index !== -1) {
          const color = colors[index]
          colors.splice(index, 1)
          colors.unshift(color)
        }
      }
      return colors
    }

    let sortedColorsAsList = _sortBy(sortedSearch, ['hsl.h', 'hsl.s', 'hsl.l'])
    sortedColorsAsList = putColorsOnTop(sortedColorsAsList, [
      'IKEA BASIC WHITE',
      'IKEA GREY 30',
      'IKEA GREY 28',
      'IKEA GREY 23',
      'IKEA GREY 32',
      'IKEA BLACK 1'
    ])

    return {
      colors: sortedColorsAsList.filter((color) => color.selectable)
    }
  }
)

export const selectAppearanceUploadStatus = createSelector(
  (state: RootState) => state.admin.adminMaterials,
  (state: RootState) => state.jobs.entries,
  (adminMaterials, jobsUncompleted) => {
    let _adminAppearances = _orderBy(adminMaterials, ['updated'], ['desc'])
    const _jobsUncompleted = _orderBy(Object.values(jobsUncompleted), ['createdAt'], ['desc'])
      .filter((e: any) => e.deadlineStatus !== 'Completed' && e.type === 'bake')

    _jobsUncompleted.forEach((job: any) => {
      _adminAppearances = _adminAppearances.filter((a: any) => a.id !== job.materialId)
    })
    return {
      adminAppearances: _adminAppearances,
      inProgress: _jobsUncompleted
    }
  }
)
