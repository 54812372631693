import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import _intersection from 'lodash/intersection'
import _xor from 'lodash/xor'

import Pill from '../../common/Pill'
import Label from '../../common/form/label'
import InputSearch from '../../common/form/input-search'

import GeneralContent from '../project-components/GeneralContent'
import type { Item } from '../card/Item'
import {
  EmptyScenes,
  EmptyGlobalScenes,
  EmptySearch
} from '../project-components/EmptyStates'
import Scrollable from '../../common/scrollable'

import * as fromSceneSelectors from '../../../stores/ducks/scenes/selectors'
import NestedMultiSelect from './global-scene/NestedMultiSelect'
import { MultiSelectGroup } from './global-scene/MultiSelectGroup'
import { TagKeys as FilterKeys } from '../../../stores/ducks/scenes/Temp'
import * as fromScenes from '../../../stores/ducks/scenes'

import { SCENE_TAGS as allTags } from '../../../constants'
import { AssetType, GlobalProjectModals } from '..'
import { ContentModals } from '../ProjectContent'

import type{ VirtualProductsViewModals } from '../virtual-products/VirtualProductsView'

const filterBaseState = {
  REGION: [] as number[],
  PRIORITIES: [] as number[],
  LIVING_SITUATION: [] as number[],
  SIZE: [] as number[],
  STYLE: [] as number[],
  COST: [] as number[],
  ACTIVITIES: [] as number[],
  ROOMS: [] as number[],
  BEHAVIORS: [] as number[]
}

type Props = {
  contentName: AssetType
  onClickImage: (item: any) => void
  onSecondaryAction: null | ((item: any) => void)
  onSelect: () => void
  onEdit: (id: string, data: any, isBatchRender: boolean, isGlobal: boolean) => void
  onOpenModal: (modal: ContentModals | GlobalProjectModals | VirtualProductsViewModals | null, item: Item) => void
  selectedIds: string[]
  showToastMessage: (msg: string) => void
  removeItem: (payload: Item) => void
  removeItemAsAdmin: ((payload: Item) => void) | undefined
}

const ScenesView = ({
  contentName,
  onClickImage,
  onSecondaryAction,
  onSelect,
  onEdit,
  onOpenModal,
  selectedIds,
  showToastMessage,
  removeItem,
  removeItemAsAdmin
}: Props) => {
  const dispatch = useDispatch()
  const localScenes = useSelector(fromSceneSelectors.getUserScenes) as unknown as Item[]
  const globalScenes = useSelector(fromSceneSelectors.getGlobalScenes) as unknown as Item[]

  const [global, setGlobal] = useState<boolean>(false)
  const [filters, setFilters] = useState(filterBaseState)
  const scenes = global ? globalScenes : localScenes
  const [searchedScenes, setSearchedScenes] = useState(scenes)

  const [searchString, setSearchString] = useState('')

  useEffect(() => {
    if (globalScenes.length < 1) dispatch(fromScenes.fetchGlobalScenes())
  }, [])

  useEffect(() => {
    if (!global) setFilters(filterBaseState)
    searchAndFilter(searchString)
  }, [global])

  useEffect(() => {
    searchAndFilter(searchString)
  }, [filters])

  useEffect(() => {
    searchAndFilter(searchString)
  }, [localScenes, globalScenes])

  const searchAndFilter = (searchString:string) => {
    setSearchString(searchString)
    let scenes = (global ? globalScenes : localScenes)

    if (searchString && searchString !== '') {
      scenes = scenes.filter(item => {
        const reg = new RegExp(searchString, 'ig')
        return reg.test(item.title) || reg.test(item.projectTitle || '')
      })
    }
    Object.keys(filters).forEach((key) => {
      const filterItem = filters[key as FilterKeys]
      scenes = filterItem.length > 0 ? scenes.filter(i => {
        const allSceneTags = (i.tags ?? []).concat(i.commentTags ?? [])
        return _intersection(filterItem, allSceneTags).length > 0
      }) : scenes
    })

    setSearchedScenes(scenes)
  }

  const handleFilterChange = (filterType:FilterKeys, values: number[]) => {
    const updated = { ...filters }
    updated[filterType] = values
    setFilters(updated)
  }

  const getEmptyStateComp = () => {
    if (searchString.length > 0 && searchedScenes.length < 1) return <EmptySearch />
    else if (global) return <EmptyGlobalScenes />
    else return <EmptyScenes />
  }

  const presentKeys = global ? scenes.reduce((accumulator:any, item:any) => accumulator.concat(item.tags), []) : undefined

  return (
    <div className='width-100 height-100 flex'>
      <div style={{ width: '30%', height: '100%' }} className='p1'>
        <Scrollable innerClassName='pr1'>
          <Label>Search</Label>
          <InputSearch
            onChange={searchAndFilter}
            onClearSearch={() => searchAndFilter('')}
            value={searchString}
            placeholder='Search on title or project...'
            disabled={false}
            className='mb1'
          />
          <div className='flex mb2'>
            <Pill
              active={!global}
              onClick={() => global ? setGlobal(false) : setGlobal(true)}
              className='mr1'
              data-testid="my-scenes-button"
            >My scenes</Pill>
            <Pill
              active={global}
              onClick={() => global ? setGlobal(false) : setGlobal(true)}
              data-testid="global-button"
            >Global</Pill>
          </div>
          <div
            style={global ? {} : { pointerEvents: 'none', opacity: '0.5' }}
            data-testid="scenes-filters"
          >
            <div className='flex'>
              <NestedMultiSelect
                label='Regions'
                selectedTags={filters.REGION} tags={allTags.REGION} className='mb1 width-100'
                onChange={(values:number[]) => handleFilterChange('REGION', _xor(filters.REGION, values))}
                presentKeys={presentKeys}
              />
            </div>
            <div className='flex'>
              <NestedMultiSelect
                label='Living situations'
                selectedTags={filters.LIVING_SITUATION} tags={allTags.LIVING_SITUATION} className='mb1 width-100'
                onChange={(values:number[]) => handleFilterChange('LIVING_SITUATION', _xor(filters.LIVING_SITUATION, values))}
                presentKeys={presentKeys}
              />
            </div>
            <div className='flex'>
              <MultiSelectGroup className='width-100' multi={true} label='IKEA long-term priorities' selectedTags={filters.PRIORITIES} options={allTags.PRIORITIES} errorMsg=''
                onChange={(values:number[]) => handleFilterChange('PRIORITIES', values)} />
            </div>
            <div className='flex'>
              <MultiSelectGroup className='width-100' multi={false} label='Home size' selectedTags={filters.SIZE} options={allTags.SIZE} errorMsg=''
                onChange={(values:number[]) => handleFilterChange('SIZE', values)} />
            </div>
            <div className='flex'>
              <MultiSelectGroup className='width-100' multi={true} label='Rooms' selectedTags={filters.ROOMS} options={allTags.ROOMS} errorMsg=''
                onChange={(values:number[]) => handleFilterChange('ROOMS', values)} />
            </div>
            <div className='flex'>
              <MultiSelectGroup className='width-100' multi={false} label='IKEA Style' selectedTags={filters.STYLE} options={allTags.STYLE} errorMsg=''
                onChange={(values:number[]) => handleFilterChange('STYLE', values)} />
            </div>
            <div className='flex'>
              <MultiSelectGroup className='width-100' multi={false} label='Income level' selectedTags={filters.COST} options={allTags.COST} errorMsg=''
                onChange={(values:number[]) => handleFilterChange('COST', values)} />
            </div>
            <div className='flex'>
              <MultiSelectGroup className='width-100' multi={true} label='Activities' selectedTags={filters.ACTIVITIES} options={allTags.ACTIVITIES} errorMsg=''
                onChange={(values:number[]) => handleFilterChange('ACTIVITIES', values)} />
            </div>
            <div className='flex'>
              <MultiSelectGroup disabled className='width-100' multi={true} label='Coming soon - Segments' selectedTags={filters.BEHAVIORS} options={allTags.BEHAVIORS} errorMsg=''
                onChange={(values:number[]) => handleFilterChange('BEHAVIORS', values)} />
            </div>
          </div>
        </Scrollable>
      </div>

      <div style={{ width: '70%', height: '100%' }}>
        <Scrollable>
          {<GeneralContent
            className=''
            itemsDone={searchedScenes}
            emptyStateComponent={searchedScenes.length > 0 ? null : getEmptyStateComp()}
            contentName={contentName}
            editOnClick={false}
            onClickImage={onClickImage}
            onSecondaryAction={onSecondaryAction}
            onSelect={onSelect}
            selectedIds={selectedIds}
            onEdit={onEdit}
            onOpenModal={onOpenModal}
            showToastMessage={showToastMessage}
            removeItem={removeItem}
            removeItemAsAdmin={removeItemAsAdmin}
            folders={[]}
            itemsInProgress={[]}
            uploadComponent={null}
            onDragStart={() => {}}
            onDragEnd={() => {}}
            isDragging={false}
            cardSize='large'
            textIsEditable
            selectable={false}
          />}
        </Scrollable>

      </div>
    </div>
  )
}

export default ScenesView
