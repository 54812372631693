import * as THREE from 'three'
import EventEmitter from 'events'
import { SceneGraphNode3d } from '../../types/SceneGraph'
import ViewerUtils from '../viewer-utils'
import TransformGizmo from './TransformGizmo'
import { approximateQuaternionAverage } from '../util/RotationUtils'

type AssembleMode = 'average' | 'bbCenter' | 'first' | 'last'

/**
 * Tool for assembling a selection of models.
 *
 * Any models can be assembled, however, using this tool will only be sensible for models
 * that have been prepared for assembly. These models have to align perfectly when set to have
 * the same position and rotation.
 *
 * For this to work properly, when uploading models DPD should not override the model centers
 */
export default class AssembleTool extends EventEmitter {
  private viewerUtils: ViewerUtils
  private gizmo: TransformGizmo

  constructor (app: any) {
    super()

    this.viewerUtils = app.viewerUtils
    this.gizmo = app.transformGizmo
  }

  /**
   * Rough check for if selected models can be assembled or not.
   * If models should not be assembled for other reasons, handel outside this tool
   */
  canAssemble () {
    return this.gizmo.objs && this.gizmo.objs.length >= 2
  }

  /**
   * Checks if a parent group should be created for the assembled models
   * If a parent group already exists (and has the correct number of children), do NOT create another parent group
   */
  private shouldGroup (models: SceneGraphNode3d[]) {
    if (models.length < 2) return false

    const parents = new Set<SceneGraphNode3d | undefined>()
    models.forEach(model => parents.add(model.parent))

    // If the models have multiple different parents,
    // group them together to give them a shared parent
    if (parents.size > 1) return true

    // Get the shared parent
    const parent = Array.from(parents)[0]

    // Group children if parent node is not a group or if the parent node has a different number of children
    return !parent?.userData?.isGroup || (parent.children.length !== models.length)
  }

  /**
   * Offsets the assemble position (if necessary) such that none of the models intersect the floor
   */
  private correctAssemblePosition (assemblePosition: THREE.Vector3) {
    const models: SceneGraphNode3d[] = this.gizmo.objs

    // Offset the assemble position such that the models are not interescting the floor
    const yCorrection = models.reduce((currentCorrection, model) => {
      const modelPosition = model.position
      const modelBoundingBox: THREE.Box3 = this.viewerUtils.getObjectBoundingBox(model)
      const yMin = (assemblePosition.y - modelPosition.y) + modelBoundingBox.min.y

      // If the lowest y coordinate is below 0 (below the floor), use in offset calculation
      return yMin < 0 ? Math.max(-yMin, currentCorrection) : currentCorrection
    }, 0.0)

    assemblePosition.y += yCorrection

    return assemblePosition
  }

  /**
   * Calculates the position where the models will be assembled
   * average  => use average model position (calculated using the model position property)
   * bbCenter => use center of the models collective bounding box
   * first    => use position of the first model (using position property)
   * last     => use position of the last model (using position property)
   */
  private getAssemblePosition (mode: AssembleMode) {
    const models: SceneGraphNode3d[] = this.gizmo.objs
    const tempVector = new THREE.Vector3()

    switch (mode) {
      case 'average': {
        return models.reduce((avg, model) => (
          avg.add(tempVector.copy(model.position).divideScalar(models.length))
        ), new THREE.Vector3())
      }
      case 'bbCenter': {
        const collectiveBoundingBox: THREE.Box3 = this.viewerUtils.getBoundingBox(models)
        return collectiveBoundingBox.getCenter(new THREE.Vector3())
      }
      case 'first': {
        return models[0].position.clone()
      }
      case 'last': {
        return models[models.length - 1].position.clone()
      }
    }
  }

  /**
   * Calculates the rotation the assembled models will receive
   * average/bbCenter => use average rotation of models (approximate)
   * first            => use rotation of first model
   * last             => use rotation of last model
   */
  private getAssembleRotation (mode: AssembleMode) {
    const models: SceneGraphNode3d[] = this.gizmo.objs

    switch (mode) {
      case 'average':
      case 'bbCenter': {
        return new THREE.Euler().setFromQuaternion(
          approximateQuaternionAverage(models.map(model => model.quaternion))
        )
      }
      case 'first': {
        return models[0].rotation.clone()
      }
      case 'last': {
        return models[models.length - 1].rotation.clone()
      }
    }
  }

  /**
   * Assembles the selected models.
   * Once assembled, the models will share the same position and rotation.
   * A parent group will also be created, if not already present
   */
  assembleSelectedModels ({ positionMode }: { positionMode: AssembleMode } = { positionMode: 'first' }) {
    if (!this.canAssemble()) return false
    const models: SceneGraphNode3d[] = this.gizmo.objs

    // Set rotation
    const assembleRotation = this.getAssembleRotation(positionMode)
    this.gizmo.setRotationOnSelectedRootModels(
      assembleRotation.x,
      assembleRotation.y,
      assembleRotation.z
    )

    // Set position
    const assemblePosition = this.correctAssemblePosition(
      this.getAssemblePosition(positionMode)
    )
    this.gizmo.setPositionOnSelected(
      assemblePosition.x,
      assemblePosition.y,
      assemblePosition.z
    )

    // Group
    if (this.shouldGroup(models)) {
      this.emit('group', {
        nodeIds: models.map(model => model.uuid)
      })
    }

    return true
  }
}
