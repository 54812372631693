import React from 'react'
import * as ReactSideNav from 'react-sidenav'
import { useSelector } from 'react-redux'
import _startCase from 'lodash/startCase'
import navigate from '../../../utils/navigate'
import colors from '../../../../css/colors'
import * as fromInterfaceSelectors from '../../../stores/ducks/interface/selectors'
import IconGallery from '../../common/icons/icon-gallery'
import IconCube from '../../common/icons/icon-cube'
import IconGroup from '../../common/icons/icon-group'
import IconScene from '../../common/icons/icon-scene'
import IconDesignView from '../../common/icons/icon-design-view'
import { MdLightbulbOutline as IconDNP, MdPalette as IconSurfaces } from 'react-icons/md'
import IconPattern from '../../common/icons/icon-pattern'
import Grid from '../../common/grid/grid'
import Share from './Share'
import styled from 'styled-components'
import type { ProjectTabs } from '..'

const ICON_SIZE = 24

const NewCount = styled.div`
  position: absolute;
  text-align: center;
  font-size: 11px;
  background-color: red;
  align-self: center;
  padding-top: 1px;
  bottom: 8px;
  left: 10px;
  padding-bottom: 2px;
  border-radius: 4px;
  width: 18px;
`

const ItemWrapper = styled.div<{ selected: boolean }>`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  color: ${(props) => props.selected ? colors.white : colors.grayLighter};
  transition: all 0.3s ease 0s;
  padding: 8px 12px;
  background: ${(props) => props.selected ? colors.secondary : ''};
  &:hover {
    background: ${colors.grayBlueLight};
  }
`

const NavContext = (ReactSideNav as unknown as {
  NavContext: React.Context<{
  id: string
  isLeaf: boolean
  level: number
  pathId: string
  selected: boolean
}> }).NavContext

type ItemProps = {
  colorDisabled?: boolean
  color?: any
  count: number
  newImageCount: number
  icon: any
}

function Item (props: ItemProps) {
  const context = React.useContext(NavContext)
  const itemCount = props.count < 1000 ? props.count : '1k+'
  const color = props.colorDisabled ? undefined : props.color

  return (
    <ItemWrapper selected={context.selected}>
      <Grid
        columns={[25, '60%', 'auto']}
        gridGap={6}
        className='width-100 flex items-center'
        style={{
          marginTop: 2,
          marginBottom: 2,
          marginLeft: 6
        }}
      >
        {props.icon}
        {props.newImageCount > 0 && (
          <NewCount>{props.newImageCount}</NewCount>
        )}
        <div style={{ marginLeft: 12 }}>{_startCase(context.id)}</div>
        <div
          style={{
            width: 34,
            height: 21,
            padding: 2,
            backgroundColor: itemCount ? colors.white10 : '',
            color: color ? 'black' : 'white'
          }}
          className='br-small f6 center transition-bg-color'
        >
          {itemCount ? itemCount.toString() : ''}
        </div>
      </Grid>
    </ItemWrapper>
  )
}

type Props = {
  onSelection: (value: ProjectTabs) => void
  showOpenDnp: boolean
  projectId: string
  onOpenDnp: () => void
  patternCount: number
  geometryCount: number
  variantCount: number
  virtualProductCount: number
  imageCount: number
  sceneCount: number
  colorsCount: number,
  virtualProductIsActive: boolean
}

function SideNav (props: Props) {
  const [isShareModalOpen, setIsShareModalOpen] = React.useState(false)

  const newGeometries = useSelector(fromInterfaceSelectors.getNewGeometryIds)
  const newPatterns = useSelector(fromInterfaceSelectors.getNewPatternIds)
  const newVariants = useSelector(fromInterfaceSelectors.getNewVariantIds)
  const newImages = useSelector(fromInterfaceSelectors.getNewImageIds)

  return (
    <ReactSideNav.SideNav
      onSelection={(value: string) => props.onSelection(value as ProjectTabs)}
      defaultSelectedPath='geometry'
    >
      <div className='mb3' />

      <div
        data-testid='visualizer'
        className='flex items-center bg-gray-blue-light-hover c-gray-lighter py1 pointer'
        onClick={() => navigate(`/visualize/${props.projectId}/combination/`)}
        style={{ paddingLeft: 20 }}
      >
        <IconDesignView /><div className='ml2'>Visualizer</div>
      </div>

      {props.showOpenDnp && <div
        className='flex items-center bg-gray-blue-light-hover c-gray-lighter py1 pointer'
        onClick={props.onOpenDnp}
        style={{ paddingLeft: 18 }}
      >
        <IconDNP size={20} /><div className='ml2'>Open DNP</div>
      </div>}

      <Share
        isModalOpen={isShareModalOpen}
        onOpenModal={() => setIsShareModalOpen(true)}
        onCloseModal={() => setIsShareModalOpen(false)}
      />

      <div className='mb3' />

      <ReactSideNav.Nav id='geometry'>
        <Item
          icon={<IconCube width={ICON_SIZE - 2} height={ICON_SIZE - 2} />}
          count={props.geometryCount}
          newImageCount={newGeometries.length}
        />
      </ReactSideNav.Nav>

      <ReactSideNav.Nav id='patterns'>
        <Item
          icon={<IconPattern width={ICON_SIZE - 2} height={ICON_SIZE - 2} />}
          count={props.patternCount}
          newImageCount={newPatterns.length}
        />
      </ReactSideNav.Nav>
      <ReactSideNav.Nav id='variants'>
        <Item
          icon={<IconGroup width={ICON_SIZE} height={ICON_SIZE} />}
          count={props.variantCount}
          newImageCount={newVariants.length}
        />
      </ReactSideNav.Nav>
      <ReactSideNav.Nav id='images'>
        <Item
          icon={<IconGallery width={ICON_SIZE} height={ICON_SIZE} />}
          count={props.imageCount}
          newImageCount={newImages.length}
        />
      </ReactSideNav.Nav>

      <ReactSideNav.Nav id='colors'>
        <Item
          icon={<IconSurfaces size={ICON_SIZE + 3} />}
          count={props.colorsCount}
          newImageCount={0}
        />
      </ReactSideNav.Nav>
      <ReactSideNav.Nav id='scenes'>
        <Item
          icon={<IconScene width={ICON_SIZE + 3} height={ICON_SIZE + 3} />}
          count={props.sceneCount}
          newImageCount={0}
        />
      </ReactSideNav.Nav>

      {/* The virtual product feature is under construction i UPPLYSA. Virtual products from upplysa will replace the creation of virtual products in DPD.
      We might need this code in the future for further implementations and that's why we have not refacorted away the code. */}

      {/* {props.virtualProductIsActive && <ReactSideNav.Nav id='virtualProducts'>
        <Item
          icon={<IconVP size={ICON_SIZE + 3} />}
          count={props.virtualProductCount}
          newImageCount={0}
        />
      </ReactSideNav.Nav>} */}

    </ReactSideNav.SideNav>
  )
}

export default SideNav
