import React, { useState } from 'react'
import InputFile from './form/input-file'
import NotificationBubble from './notification-bubble'

type InputType = '.csv' | '.xlsx' | '.xlsm'

type Props = {
  dataUploadUrl: string
  description: string
  supportedFileFormats: InputType[]
  style?: Object
}

function UploadFileComponent ({ dataUploadUrl, description, supportedFileFormats, style } : Props) {
  const [successMsg, setSuccessMsg] = useState<string | null>(null)
  const [errorMsg, setErrorMsg] = useState<string | null>(null)

  function handleUpload (files: FileList) {
    if (files && files.length) {
      const file = files[0]
      const formData = new FormData()
      formData.append('data', file)
      fetch(dataUploadUrl, {
        method: 'POST',
        body: formData,
      })
        .then(res => {
          const status = res.status
          if (status === 200) setSuccessMsg('File was successfully uploaded')
          else if (status === 422) setErrorMsg('No file was uploaded')
          else setErrorMsg('No file was uploaded, file is formatted wrong')
        })
    }
  }

  const acceptedFileTypesAsString = (supportedFileFormats as string[]).reduce((string1:string, string2:string) => `${string1},${string2}`)
  return (
    <div className='width-100' style={style}>
      <div className='center'>
        <div>{description}</div>
        <div className='f7'>Supported format {supportedFileFormats.map((fileformat:string) => <b key={fileformat}>{`${fileformat} `}</b>)}</div>
        <InputFile accept={acceptedFileTypesAsString} onChange={handleUpload} type='primary' buttonClassName='mx-auto'>Upload</InputFile>
      </div>
      <NotificationBubble
        notification={successMsg}
        error={errorMsg}
        onClearOrTimeout={() => {
          setSuccessMsg(null)
          setErrorMsg(null)
        }}
      />
    </div>
  )
}

export default UploadFileComponent
