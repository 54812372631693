import { Component } from 'react'
import PropTypes from 'prop-types'
import autoBind from 'react-autobind'

class KeyboardListener extends Component {
  constructor (props, context) {
    super(props, context)
    autoBind(this)
  }

  handleKeyEvent (event) {
    const { keyCode } = event
    if (this.props.disabled) return

    this.props.bindings.forEach((binding) => {
      if (binding.keyCode === keyCode || binding.keyCode === KeyCode.any) {
        if (this.props.preventDefault) {
          event.preventDefault()
        }
        binding.callback(event)
      }
    })
  }

  componentDidMount () {
    this.props.eventTarget.addEventListener(
      this.props.keyboardEvent,
      this.handleKeyEvent,
      this.props.useCapture
    )
  }

  componentWillUnmount () {
    this.props.eventTarget.removeEventListener(
      this.props.keyboardEvent,
      this.handleKeyEvent,
      this.props.useCapture
    )
  }

  shouldComponentUpdate () {
    return false
  }

  render () {
    return false
  }
}

KeyboardListener.propTypes = {
  disabled: PropTypes.bool,
  bindings: PropTypes.array.isRequired,
  keyboardEvent: PropTypes.string,
  useCapture: PropTypes.bool,
  preventDefault: PropTypes.bool,
  eventTarget: PropTypes.object
}

KeyboardListener.defaultProps = {
  keyboardEvent: 'keydown',
  useCapture: false,
  preventDefault: true,
  eventTarget: window
}

const KeyCode = {
  enter: 13,
  esc: 27,
  left: 37,
  up: 38,
  right: 39,
  down: 40,
  any: 999
}

function KeyBinding (keyCode, callback) {
  return {
    keyCode,
    callback
  }
}

export default KeyboardListener
export {
  KeyCode,
  KeyBinding
}
