import _sortBy from 'lodash/sortBy'

import { RootState } from '..'

export const getGlobalScenes = (state: RootState) => Object.values(state.scenes.globalEntries)
export const getUserScenes = (state: RootState) => _sortBy(Object.values(state.scenes.entries), ['global'])

export const getError = (state: RootState) => state.scenes.error
export const getTempComments = (state: RootState) => state.scenes.temporary.comments.asMutable({ deep: true })
export const getTempTags = (state: RootState) => state.scenes.temporary.tags.asMutable({ deep: true })
export const getTempTitle = (state: RootState) => state.scenes.temporary.title
