import React from 'react'

const IconRotate = props => (
  <svg width='1em' height='1em' viewBox='0 0 25 24' {...props}>
    <path
      d='M20.533 3.952C18.376 1.525 15.257 0 11.788 0 5.278 0 0 5.373 0 12s5.278 12 11.788 12c6.054 0 11.042-4.646 11.712-10.626h-2.642c-.653 4.503-4.465 7.96-9.07 7.96-5.064 0-9.168-4.18-9.168-9.334 0-5.155 4.104-9.333 9.168-9.333 2.737 0 5.194 1.22 6.874 3.156l-2.629 2.629c-.202.202-.133.384.144.417l7.415.889c.276.033.475-.165.442-.442l-.889-7.415c-.033-.276-.22-.341-.417-.144l-2.195 2.195z'
      fill={props.color}
      fillRule='evenodd'
    />
  </svg>
)

IconRotate.defaultProps = {
  color: 'currentcolor'
}

export default IconRotate
