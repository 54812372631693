import React from 'react'
import styled from 'styled-components'

type IProps = React.HTMLAttributes<HTMLDivElement> & {
  /** grid gap between cells, defaults to 16px */
  gridGap?: number | string
  /** default column width */
  width?: number | string
  /** default row height */
  height?: number | string
  /** column width at minimum screen size 30em */
  xsWidth?: number | string
  /** row height at minimum screen size 30em */
  xsHeight?: number | string
  /** column width at minimum screen size 40em */
  sWidth?: number | string
  /** row height at minimum screen size 40em */
  sHeight?: number | string
  /** column width at minimum screen size 52em */
  mWidth?: number | string
  /** row height at minimum screen size 52em */
  mHeight?: number | string
  /** column width at minimum screen size 64em */
  lWidth?: number | string
  /** row height at minimum screen size 64em */
  lHeight?: number | string
  /** column width at minimum screen size 80em */
  xlWidth?: number | string
  /** row height at minimum screen size 80em */
  xlHeight?: number | string
  /** column width at minimum screen size 120em */
  xxlWidth?: number | string
  /** row height at minimum screen size 120em */
  xxlHeight?: number | string
}

function toCssValue (val: number | string) {
  return isNaN(Number(val)) ? val : `${val}px`
}

function getRepeatFill (value?: number | string) {
  return value
    ? `repeat(auto-fill, minmax(${toCssValue(value)}, 1fr))`
    : 'initial'
}

const mediaSizeDict = {
  xs: '30em',
  s: '40em',
  m: '52em',
  l: '64em',
  xl: '80em',
  xxl: '120em'
}

function getMediaFill (
  mediaSize: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl',
  width?: number | string,
  height?: number | string
) {
  return (width || height)
    ? `
      @media screen and (min-width: ${mediaSizeDict[mediaSize]}) {
        grid-template-columns: ${getRepeatFill(width)};
        grid-template-rows: ${getRepeatFill(height)};
      }
    `
    : ''
}

export default styled.div.withConfig({
  shouldForwardProp: (prop) => ![
    'gridGap',
    'width',
    'height',
    'xsWidth',
    'xsHeight',
    'sWidth',
    'sHeight',
    'mWidth',
    'mHeight',
    'lWidth',
    'lHeight',
    'xlWidth',
    'xlHeight',
    'xxlWidth',
    'xxlHeight'
  ].includes(prop.toString())
})<IProps>`
  display: grid;
  grid-gap: ${(props) => toCssValue(props.gridGap || 16)};

  grid-template-columns: ${(props) => getRepeatFill(props.width)};
  grid-template-rows: ${(props) => getRepeatFill(props.height)};

  ${(props) => getMediaFill('xs', props.xsWidth, props.xsHeight)}
  ${(props) => getMediaFill('s', props.sWidth, props.sHeight)}
  ${(props) => getMediaFill('m', props.mWidth, props.mHeight)}
  ${(props) => getMediaFill('l', props.lWidth, props.lHeight)}
  ${(props) => getMediaFill('xl', props.xlWidth, props.xlHeight)}
  ${(props) => getMediaFill('xxl', props.xxlWidth, props.xxlHeight)}
`
