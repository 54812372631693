import React, { useEffect, useRef, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { createSelector } from 'reselect'

import _map from 'lodash/map'

// Actions
import * as fromMaterials from '../../../../../stores/ducks/materials'
import * as fromProjects from '../../../../../stores/ducks/projects'

// Selectors
import * as fromProjectsSelectors from '../../../../../stores/ducks/projects/selectors'
import * as fromMaterialAndColorSelectors from '../material-and-color-selectors'

import AutoFill from '../../../../common/grid/AutoFill'
import MaterialCard from './material'
import MaterialPreviewModal from '../material-preview-modal'
import { RootState } from '../../../../../stores/ducks'

interface OwnProps {
  materials: any
}

const mapStateToProps = createSelector(
  (state: RootState, ownProps: OwnProps) => ownProps.materials,
  fromMaterialAndColorSelectors.getAppliedMaterialIds,
  fromMaterialAndColorSelectors.getSelectionMode,
  fromProjectsSelectors.getMarkedAppearances,
  fromProjectsSelectors.getCurrentId,
  (materials, appliedMaterialIds, selectionMode, markedAppearances, currentId) => {
    return {
      disabled: !selectionMode,
      materials: _map(materials, (material) => ({
        ...material,
        disabled: !selectionMode,
        selected: !!appliedMaterialIds[material.id],
        previewImg: !material.thumbnailOffset && material.thumbnail && material.thumbnail.endsWith('.png')
          ? `${material.thumbnail.replace(/.png$/, '.jpg')}`
          : material.thumbnail,
        thumbnail: !material.thumbnailOffset && material.thumbnail && material.thumbnail.endsWith('.png')
          ? `${material.thumbnail.replace(/.png$/, '.jpg')}?flatten=%28%29&resize=%28256%2C%20256%29`
          : material.thumbnail,
      })),
      markedAppearances: markedAppearances,
      projectId: currentId
    }
  }
)

const mapDispatchToProps = (dispatch: any) => ({
  apply: (id:string) => {
    dispatch(fromMaterials.setMaterial(id))
  },
  update: (data:any) => {
    dispatch(fromProjects.updateFavorites(data))
  }
})

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>
type Props = PropsFromRedux & {

}

const MaterialList = (props: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedMaterialIndex, setSelectedMaterialIndex] = useState(-1)
  const [appearances, updateMarkedAppearances] = useState<string[]>(props.markedAppearances)

  // To avoid execute props.update on first render
  const firstUpdate = useRef(true)
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }
    const data = {
      id: props.projectId,
      markedAppearances: appearances
    }
    props.update(data)
  }, [appearances])

  const toggleMarkAppearance = (id: string) => {
    if (appearances.includes(id)) {
      updateMarkedAppearances(appearances.filter(i => i !== id))
    } else {
      updateMarkedAppearances([...appearances, id])
    }
  }

  const selectedMaterial = props.materials[selectedMaterialIndex]

  return (
    <>
      <AutoFill width={80}>
        {props.materials.map((material, index) => (
          <MaterialCard
            key={material.id}
            onApply={() => props.apply(material.id)}
            onMaterialPreviewClicked={() => {
              setIsModalOpen(true)
              setSelectedMaterialIndex(index)
            }}
            onFavoriteClick={() => {
              toggleMarkAppearance(material.id)
            }}
            disabled={props.disabled}
            material={material}
            markedAppearances={props.markedAppearances}
          />
        ))}
      </AutoFill>

      {selectedMaterial && <MaterialPreviewModal
        open={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        onNavigation={(indexDelta) => {
          const newIndex = (selectedMaterialIndex + props.materials.length + indexDelta) % props.materials.length
          setSelectedMaterialIndex(newIndex)
        }}
        headerText={selectedMaterial.displayName || selectedMaterial.name}
        cscText={selectedMaterial.colorSpecificationCode || selectedMaterial.color_specification_code}
        idText={selectedMaterial.id}
        material={selectedMaterial}
        previewImg={selectedMaterial && selectedMaterial.previewImg}
      />}
    </>
  )
}

export default connector(MaterialList)
