import React from 'react'
import Circle from '../../../../common/circle'

export const IconVirtualProduct = (props: { className?: string, style?: any }) => {
  return (
    <Circle
      title='Virtual product'
      className={`bg-gray-dark c-white f8 ${props.className || ''}`}
      style={props.style}
      size={20}
    >
      VP
    </Circle>
  )
}
