import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import colors from '../../../css/colors'
import { RootState } from '../../stores/ducks'

import { IoMdArrowDropdown, IoMdArrowDropleft, IoMdArrowDropright, IoMdArrowDropup } from 'react-icons/io'
import { panStart, panEnd } from '../../stores/ducks/threeviewer/imageTemplates'
import { TOP_BAR_HEIGHT } from '../../constants'

const shrinkFactor = 0.875 // 3500 / 4000

const arrowBase = `
  pointer-events: initial;
  cursor: pointer;
  position: absolute;
  width: 64px;
  height: 64px;
  &:hover {
    width: 70px;
    height: 70px;
  }
`

const ArrowDown = styled(IoMdArrowDropdown)`
  transform: translate(-50%, 16px);
  bottom: 0;
  left: 50%;
  ${arrowBase}
`

const ArrowLeft = styled(IoMdArrowDropleft)`
  transform: translate(-16px, -50%);
  top: 50%;
  ${arrowBase}
`

const ArrowRight = styled(IoMdArrowDropright)`
  transform: translate(16px, -50%);
  right: 0;
  top: 50%;
  ${arrowBase}
`

const ArrowUp = styled(IoMdArrowDropup)`
  transform: translate(-50%, -16px);
  left: 50%;
  ${arrowBase}
`

const OffsetViewSafeFrame = styled.div<{ visible: boolean }>`
  display: ${(props) => props.visible ? 'block' : 'none'};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(min(100vw - ${TOP_BAR_HEIGHT * 2}px, 100vh - ${TOP_BAR_HEIGHT}px) * ${shrinkFactor});
  height: calc(min(100vw - ${TOP_BAR_HEIGHT * 2}px, 100vh - ${TOP_BAR_HEIGHT}px) * ${shrinkFactor});
  pointer-events: none;
  border: 1px solid ${colors.secondaryLight};

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 100px;
    height: 1px;
    background: ${colors.secondaryLight};
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 1px;
    height: 100px;
    background: ${colors.secondaryLight};
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const selectVisible = (state: RootState) => !!state.threeviewer.camera.activeCamera.imageTemplate
const selectAutoFitEnabled = (state: RootState) => state.threeviewer.imageTemplates.autoFitEnabled

export default function () {
  const dispatch = useDispatch()
  const visible = useSelector(selectVisible)
  const autoFitEnabled = useSelector(selectAutoFitEnabled)

  return (
    <>
      <OffsetViewSafeFrame
        visible={visible}
      >
        {!autoFitEnabled && (
          <>
            <ArrowDown
              className='c-secondary'
              onMouseDown={() => dispatch(panStart('up'))}
              onMouseUp={() => dispatch(panEnd('up'))}
              onMouseLeave={() => dispatch(panEnd('up'))}

            />
            <ArrowLeft
              className='c-secondary'
              onMouseDown={() => dispatch(panStart('right'))}
              onMouseUp={() => dispatch(panEnd('right'))}
              onMouseLeave={() => dispatch(panEnd('right'))}
            />
            <ArrowRight
              className='c-secondary'
              onMouseDown={() => dispatch(panStart('left'))}
              onMouseUp={() => dispatch(panEnd('left'))}
              onMouseLeave={() => dispatch(panEnd('left'))}
            />
            <ArrowUp
              className='c-secondary'
              onMouseDown={() => dispatch(panStart('down'))}
              onMouseUp={() => dispatch(panEnd('down'))}
              onMouseLeave={() => dispatch(panEnd('down'))}
            />
          </>
        )}
      </OffsetViewSafeFrame>
    </>
  )
}
