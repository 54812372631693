import React from 'react'

import { useSelector, useDispatch } from 'react-redux'
import FormRemove from '../common/form/form-remove'

import Modal from '../common/modal'
import NotificationBubble from '../common/notification-bubble'
import GalleryModal from '../gallery/modal'

import FolderModal from './folders/FolderModal'

import * as fromProjects from '../../stores/ducks/projects'
import * as fromFolders from '../../stores/ducks/folders'
import * as fromPatternsJson from '../../stores/ducks/patterns/json'
import * as fromScenes from '../../stores/ducks/scenes'

import { selectItems as selectItemsSelector } from './selectors/projectSelectors'
import * as fromFoldersSelectors from '../../stores/ducks/folders/selectors'

import _get from 'lodash/get'
import _map from 'lodash/map'

import type { RootState } from '../../stores/ducks'
import type { Item } from './card/Item'
import { GlobalProjectModals, FolderModalType, ProjectTabs, AssetType } from '.'
import { Folder } from '../../stores/ducks/folders/Folder'

type Props = {
  activeModal: GlobalProjectModals | null
  searchedItems: Item[]
  getCurrentContentName: () => AssetType
  selectedIds: string[]
  activeItem: Item | null
  folderIdToRemove: null | string
  selectedTab: ProjectTabs
  projectId: string
  currentFolder: Folder | null
  folderModalType: FolderModalType
  setActiveItem: (item: Item | null) => void
  handleRemoveFolder: (id: string) => void
  setFolderIdToRemove: any
  resetActiveModal: () => void
  resetSelectedIds: () => void
}

function ProjectModals (props: Props) {
  const dispatch = useDispatch()

  const notificationMsg = useSelector((state:RootState) => state.projects.notificationMessage)
  const errorNotificationMsg = useSelector((state:RootState) => state.projects.errorNotificationMessage)
  const sceneError = useSelector((state:RootState) => state.scenes.error)
  const selectItems = useSelector(selectItemsSelector)
  const folders = useSelector(fromFoldersSelectors.getEntriesInProject)

  function getFormTitle () {
    const itemType = props.getCurrentContentName()
    if (props.activeModal === 'removeAsAdmin') return `Remove ${itemType} as admin. This will effect all users!`
    return `Remove ${itemType}`
  }

  function getItemType () {
    switch (props.selectedTab) {
      case 'images': return 'image'
      case 'geometry': return props.selectedIds.length > 1 ? 'geometries' : 'geometry'
      case 'variants': return 'variant'
      case 'patterns': return 'pattern'
      case 'scenes': return 'scene'
      default: return ''
    }
  }

  function getFormDesc () {
    if (props.folderIdToRemove) {
      return 'Are you sure you want to remove this folder?'
    }
    let count = props.selectedIds.length
    if (count === 0) {
      count = props.activeItem ? 1 : count
    }
    const itemType = getItemType()

    return `Are you sure you want to remove ${count === 1 ? `this ${itemType}?` : `${count} ${itemType}s?`}`
  }

  function handleSendtoDnp () {
    const renderIds = props.selectedIds.map((id) => {
      const comb = selectItems.imageCombinations.find((comb) => comb.id === id)
      return _get(comb, 'render.id')
    })
    dispatch(fromProjects.sendToDnp(props.projectId, renderIds))
    props.resetActiveModal()
  }

  function handleAddFolder (title: string, color: any, mode: FolderModalType, selectedIds: null | string[] = null) {
    if (!title) { title = 'Untitled folder' }

    if (mode === 'edit') {
      dispatch(fromFolders.update(
        {
          title: title,
          color: color
        },
        props.currentFolder?.id,
        props.projectId
      ))
      props.resetActiveModal()
      return
    }

    const folderObject: {title: string, color: any, patternIds?: any, combinationIds?: any} = {
      title,
      color
    }
    if (selectedIds) {
      const keyDefinition = props.selectedTab === 'patterns' ? 'patternIds' : 'combinationIds'
      folderObject[keyDefinition] = selectedIds
    }
    dispatch(fromFolders.create(folderObject))
    props.resetSelectedIds()
    props.resetActiveModal()
  }

  function handleRemoveItem () {
    if (props.activeItem && props.selectedTab === ProjectTabs.SCENES) {
      const removeAsAdmin = props.activeModal === 'removeAsAdmin'
      const id = props.activeItem.id
      dispatch(fromScenes.unShareScene({ id, removeAsAdmin }))
      props.resetSelectedIds()
      props.resetActiveModal()
      return
    }

    if (props.folderIdToRemove) {
      props.handleRemoveFolder(props.folderIdToRemove)
      props.setFolderIdToRemove(null)
      props.resetActiveModal()
      return
    }

    _map(props.selectedIds, (id) => {
      if (props.selectedTab === ProjectTabs.PATTERNS) {
        if (folders && folders.length > 0) {
          folders.forEach(folder => {
            if (folder.patterns && id in folder.patterns) {
              dispatch(fromFolders.removeConnectedIds([], id, folder.id))
            }
          })
        }
        dispatch(fromPatternsJson.remove(id))
      } else {
        dispatch(fromProjects.removeCombination(props.projectId, id))
      }
    })
    props.resetSelectedIds()
    props.resetActiveModal()
  }

  let galleryItems = [...selectItems.imagesInProgress, ...props.searchedItems]

  if (props.activeItem?.connectedBatchRenderCombinationIds?.length && props.activeModal === 'gallery') {
    galleryItems = [
      props.activeItem,
      ...(selectItems.combinationsByBatchParentIdInProgress[props.activeItem.id] || []),
      ...(selectItems.combinationsByBatchParentId[props.activeItem.id] || [])
    ]
  }

  return (
    <>
      {props.activeModal === 'gallery' && props.activeItem && (
        <GalleryModal
          rendersInProject={galleryItems}
          renderId={props.activeItem.id}
          onBack={() => props.resetActiveModal() }
          backToText='Return to Project'
        />
      )}
      <Modal
        isOpen={props.activeModal === 'remove' || props.activeModal === 'removeAsAdmin' }
        onRequestClose={() => props.resetActiveModal()}
        width={600}
      >
        <FormRemove
          formTitle={getFormTitle()}
          formDescription={getFormDesc()}
          onConfirm={handleRemoveItem}
          onCancel={() => {
            props.resetActiveModal()
            props.setActiveItem(null)
          }}
          buttonText='Remove'
        />
      </Modal>
      <Modal
        isOpen={props.activeModal === 'sendToDnp'}
        onRequestClose={() => props.resetActiveModal() }
        width={600}
      >
        <FormRemove
          formTitle={'Share to DNP'}
          formDescription={'The image will become available in the connected DNP project. Are you sure you want this? It is not possible to take it back.'}
          onConfirm={handleSendtoDnp}
          onCancel={() => props.resetActiveModal()}
          buttonText='Share'
        />
      </Modal>
      <NotificationBubble
        notification={notificationMsg}
        error={errorNotificationMsg || sceneError}
        onClearOrTimeout={() => {
          dispatch(fromProjects.setNotificationMessage(null))
          dispatch(fromProjects.setErrorNotificationMessage(null))
        }}
      />
      <FolderModal
        isOpen={props.activeModal === 'folder'}
        type={props.folderModalType}
        folders={folders}
        currentFolder={props.currentFolder}
        itemCount={props.selectedIds.length}
        onClose={() => props.resetActiveModal()}
        onConfirm={(title, color, mode) => handleAddFolder(title, color, mode, props.selectedIds)}
      />
    </>
  )
}

export default ProjectModals
