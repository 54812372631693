import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import cs from 'classnames'

import colors from '../../../css/colors'

import ProgressRadial from '../common/progress-radial'

const INITIAL = 'Initial'
const POSTED = 'Posted'
const PENDING = 'Pending'
const ACTIVE = 'Active'
const COMPLETED = 'Completed'
const FAILED = 'Failed'
const SUSPENDED = 'Suspended'
const QUEUED = 'Queued'
const RENDERING = 'Rendering'

const buttonStyle = { width: 35, height: 35 }

const _percentage = (isRender) => {
  return (status, renderPercentage) => {
    if (isRender && renderPercentage) return renderPercentage
    return {
      [INITIAL]: 5,
      [POSTED]: 10,
      [QUEUED]: 15,
      [PENDING]: 17,
      [ACTIVE]: 25,
      [RENDERING]: 25,
      [COMPLETED]: 100
    }[status]
  }
}

const _statusText = (isRender) => {
  return {
    [INITIAL]: 'Job Created',
    [POSTED]: 'Job Posted',
    [QUEUED]: 'Queued',
    [PENDING]: 'Pending',
    [ACTIVE]: isRender ? 'Rendering' : 'Converting',
    [RENDERING]: isRender ? 'Rendering' : 'Converting',
    [COMPLETED]: 'Done',
    [FAILED]: 'Failed',
    [SUSPENDED]: 'Suspended'
  }
}

const _didFail = (status) => (['Failed', 'Suspended'].indexOf(status) !== -1)

class Pending extends Component {
  constructor () {
    super()
    this.state = {}

    this.handleRetry = this.handleRetry.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
  }

  handleRetry () {
    this.props.onRetry(this.props.id)
  }

  handleCancel () {
    this.props.onCancel(this.props.id)
  }

  render () {
    const {
      title,
      model,
      deadlineStatus,
      status,
      renderStatus,
      modifiedAt,
      createdAt,
      renderPercentage
    } = this.props

    const replacedStatus = status || renderStatus
    const statusText = _statusText(!!renderStatus)
    const percentage = _percentage(!!renderStatus)
    const replacedDeadlineStatus = deadlineStatus || status

    return (
      <div className='paper-1 br-2 bg-white px2 py2 relative'>
        {_didFail(replacedDeadlineStatus) && (
          <div className='absolute right-0 left-0 pt1 px1 top-0'>
            <button
              className='pointer right f5 c-error bg-error-hover c-white-hover circle border flex justify-center'
              style={buttonStyle}
              onClick={this.handleCancel}
            >
              <i className='icon-cancel' />
            </button>
            <button
              className='pointer left f5 c-primary bg-primary-hover c-white-hover circle border flex justify-center'
              style={buttonStyle}
              onClick={this.handleRetry}
            >
              <i className='icon-ccw' />
            </button>
          </div>
        )}
        <div className={cs('pt2', {
          'px3 pt2': replacedStatus && !renderStatus,
          'px1 py1 pr2': renderStatus
        })}>
          {_didFail(replacedDeadlineStatus) ? (
            <ProgressRadial
              strokeWidth={10}
              background={colors.errorLightAlt}
              percentage={100}
            >
              <small>{statusText[replacedDeadlineStatus]}</small>
            </ProgressRadial>
          ) : (
            <ProgressRadial
              strokeWidth={10}
              percentage={percentage(replacedDeadlineStatus || (status === INITIAL ? INITIAL : POSTED), renderPercentage)}
            >
              <small>{statusText[replacedDeadlineStatus || (status === INITIAL ? INITIAL : POSTED)]}</small>
            </ProgressRadial>
          )}
        </div>
        <h4 className={cs('f5 mb1 truncate', { hidden: renderStatus })}>{title || model}</h4>
        <time className='muted'><small className={{ fontSize: '65%' }}>Started {moment(modifiedAt || createdAt).fromNow()}</small></time>
      </div>
    )
  }
}

Pending.propTypes = {
  onCancel: PropTypes.func,
  onRetry: PropTypes.func
}

Pending.defaultProps = {
  onCancel () {},
  onRetry () {}
}

export default Pending
