import {
  Vector2,
  MOUSE
} from 'three'
import { EventEmitter } from 'events'

const KEYS = { UP: 38, LEFT: 37, DOWN: 40, RIGHT: 39, SPACE: 32 }

export type OffsetView = {
  zoom: number
  translateX: number
  translateY: number
  verticalOffset: number
  horizontalOffset: number
}

// TODO: Almost all calculations only work if the aspect ratio is 1:1
export default class OffsetViewControls extends EventEmitter {
  private app: any
  private camera: any
  
  view: OffsetView = {
    zoom: 1,
    translateX: 0,
    translateY: 0,
    verticalOffset: 0,
    horizontalOffset: 0,
  }

  private input = {
    keyState: {
      [KEYS.UP]: false,
      [KEYS.RIGHT]: false,
      [KEYS.DOWN]: false,
      [KEYS.LEFT]: false,
    },
    mouseState: {
      [MOUSE.PAN]: false
    },
    deltaZoom: 0,
    mouse: new Vector2(),
    lastMouse: new Vector2(),
    pan: new Vector2(),
  }

  private _manual = false
  private _enabled = false

  constructor (app: any) {
    super()
    this.app = app
    this.camera = app.camera
  }

  enableManual() {
    this._manual = true

    window.addEventListener('keydown', this.onKeyDown)
    window.addEventListener('keyup', this.onKeyUp)
    
    this.app.domElementWrapper.addEventListener('wheel', this.onWheel, true)
    
    this.app.domElementWrapper.addEventListener('mousedown', this.onMouseDown)
    this.app.domElementWrapper.addEventListener('mouseup', this.onMouseUpOrLeave)
    this.app.domElementWrapper.addEventListener('mouseleave', this.onMouseUpOrLeave)
    this.app.domElementWrapper.addEventListener('mousemove', this.onMouseMove)

    this.app.domElementWrapper.addEventListener('touchstart', this.onTouchStart, false)
    this.app.domElementWrapper.addEventListener('touchend', this.onTouchEnd, false)
    this.app.domElementWrapper.addEventListener('touchmove', this.onTouchMove, false)

    this.app.domElementWrapper.addEventListener('contextmenu', this.onContextMenu, false)
  }

  disableManual() {
    this._manual = false

    window.removeEventListener('keydown', this.onKeyDown)
    window.removeEventListener('keyup', this.onKeyUp)
    
    this.app.domElementWrapper.removeEventListener('wheel', this.onWheel)

    this.app.domElementWrapper.removeEventListener('mousedown', this.onMouseDown)
    this.app.domElementWrapper.removeEventListener('mouseup', this.onMouseUpOrLeave)
    this.app.domElementWrapper.removeEventListener('mouseleave', this.onMouseUpOrLeave)
    this.app.domElementWrapper.removeEventListener('mousemove', this.onMouseMove)

    this.app.domElementWrapper.removeEventListener('touchstart', this.onTouchStart, false)
    this.app.domElementWrapper.removeEventListener('touchend', this.onTouchEnd, false)
    this.app.domElementWrapper.removeEventListener('touchmove', this.onTouchMove, false)

    this.app.domElementWrapper.removeEventListener('contextmenu', this.onContextMenu, false)
  }

  clear () {
    const fullWidth = this.app.width
    const fullHeight = this.app.height
    this.camera.setViewOffset(fullWidth, fullHeight, 0, 0, fullWidth, fullHeight)
    this.view.translateX = 0
    this.view.translateY = 0
    this.view.zoom = 1
  }

  private onContextMenu = (event: MouseEvent) => {
    event.preventDefault()
  }


  lastTouch = new Vector2()

  private onTouchStart = (event: TouchEvent) => {
    if (!this._manual) return

    if (event.touches.length === 2) {
      this.input.mouseState[MOUSE.PAN] = true
      this.lastTouch.set(event.touches[0].clientX, event.touches[0].clientY)
    }
  }

  private onTouchMove = (event: TouchEvent) => {
    if (!this._manual) return

    if (event.touches.length === 2) {
      const movementX = event.touches[0].clientX - this.lastTouch.x
      const movementY = event.touches[0].clientY - this.lastTouch.y
      this.input.pan.set(movementX, movementY)
      this.lastTouch.set(event.touches[0].clientX, event.touches[0].clientY)
    }
  }

  private onTouchEnd = (event: TouchEvent) => {
    if (!this._manual) return
    this.input.mouseState[MOUSE.PAN] = false
  }

  private onMouseDown = (event: MouseEvent) => {
    this.input.mouseState = {}
    if (event.button === MOUSE.PAN) {
      this.input.mouseState[MOUSE.PAN] = true
    }
  }

  private onMouseMove = (event: MouseEvent) => {
    if (this.input.mouseState[MOUSE.PAN]) {
      this.input.pan.set(event.movementX, event.movementY)
    }
  }

  private onMouseUpOrLeave = (event: MouseEvent) => {
    this.input.mouseState[MOUSE.PAN] = false
  }

  private onKeyDown = (event: KeyboardEvent) => {
    if (!this._manual) return
    if (Object.values(KEYS).includes(event.keyCode)) {
      event.preventDefault()
      this.input.keyState[event.keyCode] = true
    }
  }

  private onKeyUp = (event: KeyboardEvent) => {
    if (!this._manual) return
    if (Object.values(KEYS).includes(event.keyCode)) {
      event.preventDefault()
      this.input.keyState[event.keyCode] = false
    }
  }

  private onWheel = (event: WheelEvent) => {
    if (!this._manual) return
    this.input.deltaZoom = event.deltaY * 0.05
  }

  currentOffset = new Vector2()
  targetOffset = new Vector2()

  update(delta: number) {
    if (!this.isInputActive()) return
    const horizontal = (this.input.keyState[KEYS.RIGHT] ? 1 : 0) - (this.input.keyState[KEYS.LEFT] ? 1 : 0)
    const vertical = (this.input.keyState[KEYS.DOWN] ? 1 : 0) - (this.input.keyState[KEYS.UP] ? 1 : 0)

    const tx = -this.input.pan.x || (horizontal * 1.05)
    const ty = -this.input.pan.y || (vertical * 1.05)

    if (tx || ty) {
      this.pan(tx / this.view.zoom, ty / this.view.zoom)
    }

    if (this.input.deltaZoom) {
      const zoomSpeed = delta * (this.view.zoom / 2)
      const zoom = this.view.zoom - (this.input.deltaZoom * zoomSpeed)
      this.zoom(zoom)
    }

    this.input.deltaZoom = 0
    this.input.pan.set(0, 0)
  }

  pan (tx: number, ty: number) {
    this.updateViewOffset(tx, ty, this.view.zoom)
  }

  panStart (direction: 'up' | 'down' | 'left' | 'right') {
    if (direction === 'down') this.input.keyState[KEYS.DOWN] = true
    if (direction === 'up') this.input.keyState[KEYS.UP] = true
    if (direction === 'left') this.input.keyState[KEYS.LEFT] = true
    if (direction === 'right') this.input.keyState[KEYS.RIGHT] = true
  }

  panEnd (direction: 'up' | 'down' | 'left' | 'right') {
    if (direction === 'down') this.input.keyState[KEYS.DOWN] = false
    if (direction === 'up') this.input.keyState[KEYS.UP] = false
    if (direction === 'left') this.input.keyState[KEYS.LEFT] = false
    if (direction === 'right') this.input.keyState[KEYS.RIGHT] = false
  }

  zoom(zoom: number, clientX?: number, clientY?: number) {
    const halfWidth = this.app.width * 0.5
    const halfHeight = this.app.height * 0.5

    const tx = clientX ? (clientX - halfWidth) / this.view.zoom : 0
    const ty = clientY ? (clientY - halfHeight) / this.view.zoom : 0

    this.updateViewOffset(tx, ty, zoom)
  }

  updateViewOffset(translateX: number, translateY: number, zoom: number) {
    const screenWidth = this.app.width
    const screenHeight = this.app.height
    if (!this.camera.view) {
      this.camera.setViewOffset(screenWidth, screenHeight, 0, 0, screenWidth, screenHeight)
    }
    const minScreen = Math.min(screenWidth, screenHeight)
    const offsetWidth = screenWidth / zoom
    const offsetHeight = screenHeight / zoom

    const origoX = (screenWidth - offsetWidth) * 0.5
    const origoY = (screenHeight - offsetHeight) * 0.5

    const tx = (this.view.translateX || 0) + translateX
    const ty = (this.view.translateY || 0) + translateY

    this.camera.setViewOffset(screenWidth, screenHeight, origoX + tx, origoY + ty, offsetWidth, offsetHeight)

    this.view.zoom = zoom
    this.view.translateX = tx
    this.view.translateY = ty
    this.view.horizontalOffset = -(tx * zoom / minScreen)
    this.view.verticalOffset = ty * zoom / minScreen

    this.emit('change', this.view)
  }
  
  setOffsetView(horizontalOffset: number, verticalOffset: number, zoom: number) {
    const fullWidth = this.app.width
    const fullHeight = this.app.height
    const offsetWidth = fullWidth / zoom
    const offsetHeight = fullHeight / zoom
    const minScreen = Math.min(fullWidth, fullHeight)
    const origoX = (fullWidth - offsetWidth) * 0.5
    const origoY = (fullHeight - offsetHeight) * 0.5
    const translateX = -(horizontalOffset * minScreen / zoom)
    const translateY = verticalOffset * minScreen / zoom

    this.view.translateX = translateX
    this.view.translateY = translateY
    this.view.horizontalOffset = horizontalOffset
    this.view.verticalOffset = verticalOffset
    this.view.zoom = zoom

    this.camera.setViewOffset(fullWidth, fullHeight, origoX + translateX, origoY + translateY, offsetWidth, offsetHeight)

    this.emit('change', this.view)
  }

  private isInputActive() {
    const isKeysActive = Object.values(this.input.keyState).find(Boolean)
    const isMouseActive = Object.values(this.input.mouseState).find(Boolean)
    return isKeysActive || isMouseActive || this.input.deltaZoom || (this.input.pan.x !== 0 && this.input.pan.y !== 0)
  }
}
