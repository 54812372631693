import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { v4 as uuid } from 'uuid'

import _map from 'lodash/map'

import Grid from '../grid/grid'
import { MdChevronLeft as IconChevronLeft, MdChevronRight as IconChevronRight } from 'react-icons/md'

import KeyboardListener, { KeyCode, KeyBinding } from '../keyboard-listener'

import cs from 'classnames'
import css from './index.css'

/**
 * A carousel to rotate given jsx children
 */

class ImageCarousel extends Component {
  constructor (props) {
    super(props)

    this.state = {
      index: this.props.startAt
    }

    this._scrollNodes = []
    this._navList = React.createRef()
  }

  handleScrollIntoView (domNode) {
    const scrollX = domNode.offsetLeft - (this._navList.current.offsetWidth * 0.5)
    this._navList.current.scrollTo(scrollX, 0)
  }

  handleNavigate (dir) {
    let newIndex = this.state.index
    if (dir === 'left') {
      if (--newIndex < 0) {
        if (this.props.loop) {
          newIndex = Math.max(this.props.children.length - 1, 0)
        }
      }
    } else if (dir === 'right') {
      if (++newIndex >= this.props.children.length) {
        newIndex = 0
      }
    } else if (typeof (dir) === 'number') {
      newIndex = dir
    }

    if (newIndex === this.state.index) {
      // there was no change to the index, nothing needs to be done
      return
    }

    this.props.onChange(newIndex)

    this.setState({
      index: newIndex
    }, () => this.handleScrollIntoView(this._scrollNodes[newIndex]))
  }

  renderDoots (amount) {
    return Array(amount).fill().map((e, n) => {
      const { secondary } = this.props
      return (
        <div
          onClick={() => this.handleNavigate(n)}
          key={n}
          className={cs(css.circle,
            {
              [css.selectedPrimary]: n === this.state.index && !secondary,
              [css.selectedSecondary]: n === this.state.index && secondary
            })}
        />
      )
    })
  }

  renderThumbs (thumbnails) {
    return _map(thumbnails, (thumb, index) => {
      const isActive = index === this.state.index
      const { secondary } = this.props
      return (
        <img
          key={uuid()}
          src={thumb}
          ref={node => (this._scrollNodes[index] = node)}
          className={cs('userselect-none ml1', {
            'pointer opacity-hover-lighter': !isActive,
            'bs-primary': isActive && !secondary,
            'bs-secondary': isActive && secondary
          })}
          style={{
            height: '100%',
            width: 'auto'
          }}
          onClick={() => this.handleNavigate(index)}
        />
      )
    })
  }

  renderLeftNav () {
    return (
      <div
        className='flex width-100 items-center justify-end'
        onClick={() => this.handleNavigate('left')}
        style={{ gridArea: 'left' }}
      >
        <IconChevronLeft
          className={cs(css.leftAnimation, 'pointer mr2')}
          size={this.props.iconSize}
        />
      </div>
    )
  }

  renderRightNav () {
    return (
      <div
        className='flex width-100 items-center justify-start'
        onClick={() => this.handleNavigate('right')}
        style={{ gridArea: 'right' }}
      >
        <IconChevronRight
          className={cs(css.rightAnimation, 'pointer ml2')}
          size={this.props.iconSize}
        />
      </div>
    )
  }

  render () {
    const {
      index
    } = this.state

    const {
      thumbnails,
      children,
      disabled
    } = this.props

    return (
      <Fragment>
        <Grid
          rows={['1fr', '5rem']}
          columns={['auto', '1fr', 'auto']}
          style={{
            gridColumnGap: 0
          }}
          areas={[
            'left image right',
            'nav nav nav'
          ]}
          className='height-100 width-100'
        >

          {!!(children.length > 1) && (this.renderLeftNav())}

          <div className='bg-gray-light' style={{ margin: 'auto' }}>
            {children[index]}
          </div>

          {!!(children.length > 1) && (this.renderRightNav())}
          {((children.length > 1 || thumbnails.length > 1)) &&
            <div
              style={{ gridArea: 'nav' }}
              className={'overflow-hidden'}>
              <div
                ref={this._navList}
                className={cs('smooth-scroll flex flex-row items-center pb1 pt1 height-100 overflow-x-scroll overflow-y-hidden flex', {
                  'justify-center': !thumbnails.length
                })}
              >
                {thumbnails.length
                  ? this.renderThumbs(thumbnails, index)
                  : this.renderDoots(children.length, index)
                }
              </div>
            </div>}

        </Grid>
        <KeyboardListener
          disabled={disabled}
          bindings={[
            KeyBinding(KeyCode.left, () => this.handleNavigate('left')),
            KeyBinding(KeyCode.right, () => this.handleNavigate('right'))
          ]}
        />
      </Fragment>
    )
  }
}

ImageCarousel.propTypes = {
  loop: PropTypes.bool,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  startAt: PropTypes.number,
  thumbnails: PropTypes.array
}

ImageCarousel.defaultProps = {
  loop: true,
  onClick: () => { },
  onChange: () => { },
  startAt: 0,
  thumbnails: [],
  secondary: false,
  iconSize: 36
}

export default ImageCarousel
