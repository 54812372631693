import store from '../../stores'
import * as fromUploads from '../../stores/ducks/uploads'
import * as fromUsersSelectors from '../../stores/ducks/users/selectors'

import show from '../show-notification'

export default function handleUploads (msg) {
  const { doc } = msg
  const state = store.getState()
  const isAdmin = fromUsersSelectors.getHasCurrentScopes('admin')(state)
  const isOwner = fromUsersSelectors.getCurrentId(state) === doc.createdBy

  if (isAdmin || isOwner) {
    store.dispatch(fromUploads.receive([doc]))
  }

  if (isOwner && doc.extractStatus) {
    show({
      title: 'Big file upload',
      body: `File:${doc.filename}\nStatus: ${doc.extractStatus.toLowerCase().replace(/_/g, ' ')}`
    })
  }
}
