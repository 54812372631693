const _ = require('lodash')

module.exports = serializeForm

// form can be of type html element or regular js object
function serializeForm (form, inputs) {
  return inputs.reduce((sum, key) =>
    (_.has(form, key) && `${sum}${(sum.length ? '&' : '')}${key}=${window.encodeURIComponent(form[key].value)}`) || sum
  , '')
}
