import * as api from './api'
import getAnalyseData from '../../../components/visualizer/panels/product-concept-analysis/getAnalyseData'
import * as fromProductConceptAnalysis from '../../../components/visualizer/panels/product-concept-analysis/selectNodesWithChildren'

export function getRenderSpreadsheet (combinationIds) {
  return () => {
    api.renders(combinationIds)
      .catch((err) => console.log(err))
  }
}

export function getUserSpreadsheet () {
  return () => {
    api.users()
      .catch((err) => console.log(err))
  }
}

export function getUserReportsSpreadsheet (json) {
  return () => {
    api.userReports(json)
      .catch((err) => console.log(err))
  }
}

export function getProjectReportsSpreadsheet (json) {
  return () => {
    api.projectReports(json)
      .catch((err) => console.log(err))
  }
}

export function getPartData () {
  return async (_, getState) => {
    const state = getState()
    const carrierSelector = fromProductConceptAnalysis.getAllCarriers(state)
    const appearanceInSceneSelector = fromProductConceptAnalysis.selectAppearancesInScene(state)
    const meshesInSceneSelector = fromProductConceptAnalysis.selectMultipleNodesWithChildren(state)

    const analysationData = await getAnalyseData(carrierSelector, meshesInSceneSelector, appearanceInSceneSelector)
    api.partData(analysationData[0].calculationList)
      .catch((err) => console.log(err))
  }
}
