import React from 'react'
import cs from 'classnames'

import Label from '../../common/form/label'
import InputFile from '../../common/form/input-file'
import TextArea from '../../common/form/textarea'
import { StorageApiThumbnail } from '../../common/storage-api-image'
import { MdDelete as IconTrash } from 'react-icons/md'

import { StorageApiFile } from '../../../utils/storage'

type Props = {
    label: string
    text: string
    images: StorageApiFile[]
    uploadText: (text: string) => void
    uploadImage: (files: FileList) => void
    removeImage: (files: StorageApiFile) => void
    editing: boolean
}

export const AdditionalInformation = (props: Props) => {
  const [infoText, setInfoText] = React.useState<string>(props.text)
  const filesPairLength = props.images.length / 2
  const pairInfoFiles = Array.from({ length: (filesPairLength % 2 ? filesPairLength + 1 : filesPairLength) }, (_, i) => props.images.slice(i * 2, i * 2 + 2))

  return <>
    <Label>
      <span>{props.label}</span>
    </Label>
    <p className='f7 c-gray-accessible'>
          Addtional information. Obs: Will only be visable for admins.
    </p>
    <TextArea
      maxLength={1000}
      defaultValue={(infoText) || ''}
      onChange={(change:any) => {
        setInfoText(change.target.value)
        props.uploadText(change.target.value)
      }}
      style={{ width: '100%', height: '10vh' }}
      resizeable={true}
      disabled={!props.editing}
    />

    <p className='f7 c-gray-accessible'>
          Additional images. Obs: Will only be visable for admins.
    </p>
    <InputFile
      accept='image/*'
      onChange={props.uploadImage}
      buttonClassName='ml-1 mb-1'
      clear={true}
      disabled={!props.editing}
    >
          Upload image
    </InputFile>

    { (pairInfoFiles.length > 0) && <div style={{ width: '100%', height: '50vh', overflowY: 'scroll' }} className='mt1'>
      {
        pairInfoFiles.map((pair, index) => (
          <React.Fragment key={index}>
            {pair[0] && <div style={{ position: 'relative', width: '50%', height: '100%', float: 'left' }}>
              <IconTrash
                size={24}
                className={cs('c-gray-dark mx2', {
                  disabled: false,
                  pointer: true
                }
                )}
                onClick={() => props.removeImage(pair[0])}
                style={{ position: 'absolute', zIndex: 10, right: 0, marginTop: '10px' }}
              />
              <StorageApiThumbnail
                key={Math.random()}
                className='border bc-gray-light'
                file={pair[0]}
                style={{ position: 'absolute', zIndex: 1, maxHeight: '100%', maxWidth: '100%' }}
              />
            </div>}
            {pair[1] && <div style={{ position: 'relative', width: '50%', height: '100%', float: 'right' }}>
              <IconTrash
                size={24}
                className={cs('c-gray-dark mx2', {
                  disabled: false,
                  pointer: true
                }
                )}
                onClick={() => props.removeImage(pair[1])}
                style={{ position: 'absolute', zIndex: 10, right: 0, marginTop: '10px' }}
              />
              <StorageApiThumbnail
                key={Math.random()}
                className='border bc-gray-light'
                file={pair[1]}
                style={{ position: 'absolute', zIndex: 1, maxHeight: '100%', maxWidth: '100%' }}
              />
            </div>}
          </React.Fragment>
        ))
      }
    </div>}
  </>
}
