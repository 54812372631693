import React from 'react'
import cs from 'classnames'

import { v4 as uuid } from 'uuid'
import { RiLinksLine as IconConnect, RiAddFill as IconCreate } from 'react-icons/ri'

import Button from '../button'
import ActionCard from '../../project/card/ActionCard'

type Props = {
  disabled?: boolean
  className?: string
  icon?: any
  title: string
  desc?: string
  docLink?: string
  linkText?: string
  onAction?: () => void
  actionTitle?: string
  buttons?: any[]
  link?: string
  actionDesc?: string
  hasActionCard?: boolean
  onSecondAction?: () => void
  secondAction?: {title?: string, description?: string}
}

const EmptyState = ({
  disabled,
  className,
  icon,
  title,
  desc,
  docLink,
  linkText,
  onAction,
  actionTitle,
  buttons,
  link,
  actionDesc,
  hasActionCard,
  onSecondAction,
  secondAction
}: Props) => {
  const linkBase = link || '/support/document/docs'

  return (
    <div
      className={cs('c-gray-dark mx2 my2 line-height center flex justify-center', className)}
      data-testid='common-empty-state'
    >
      <div style={{ maxWidth: 600 }}>
        <div className='width-100 c-gray mb3 flex justify-center'>{icon}</div>
        <h3 className='f4 regular center line-height'>{title}</h3>
        <p className='mb1 f6'>{desc} <span />
          <a
            className='f6 underline pointer'
            href={`${linkBase}/${docLink}`}
            target='_blank'
            title={linkText}
            rel="noopener noreferrer"
          >
            {linkText}
          </a>
        </p>
        <div className='flex justify-center mt3'>
          {!buttons && actionTitle && onAction && hasActionCard &&
          <ActionCard
            style={{ height: '180px', width: '330px' }}
            onAction={onAction}
            iconComponent={<IconConnect size={72}/>}
            actionTitle={actionTitle}
            actionDesc={actionDesc}/>
          }
          {!buttons && actionTitle && onAction && !hasActionCard &&
            <Button data-testid='load-geometry' disabled={disabled} btnType={'secondary'} onClick={onAction} >
              {actionTitle}
            </Button>
          }
          {onSecondAction && secondAction &&
           <ActionCard
             style={{ height: '180px', width: '330px', marginLeft: '32px' }}
             onAction={onSecondAction}
             iconComponent={<IconCreate size={72}/>}
             actionTitle={secondAction.title}
             actionDesc={secondAction.description}/>
          }
          {buttons && buttons.map((btn) => (
            <Button disabled={disabled} key={uuid()} btnType={'secondary'} onClick={btn.onAction} className='mr2'>
              {btn.title}
            </Button>
          ))}
        </div>
      </div>
    </div>
  )
}

export default EmptyState
