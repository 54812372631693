import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'

// Actions
import * as fromSelection from '../../stores/ducks/selection'
import * as fromProjects from '../../stores/ducks/projects'
import * as fromCombinations from '../../stores/ducks/combinations'
import * as fromPatterns from '../../stores/ducks/patterns/json'
import * as fromDesigns from '../../stores/ducks/designs'
import * as fromAppearanceGroups from '../../stores/ducks/appearance-groups'
import * as fromMaterialSearch from '../../stores/ducks/material-search'
import * as fromMaterials from '../../stores/ducks/materials'
import { getScene } from '../../stores/ducks/scenes'

// Selectors
import * as fromCombinationsSelectors from '../../stores/ducks/combinations/selectors'

// JSX Components : Layout
import Wrapper from '../layout/wrapper'
import Main from '../layout/main'
import PanelGrid from '../common/panels/panel-grid'
import { CreatePatternModal } from './create-pattern-modal'

// JSX Components : Internal
import FileProgress from './file-progress'
import PanelLeft from './panels/panel-left'
import PanelRight from './panels/panel-right'
import Viewer from './viewer'
import Toolbar from './toolbar/toolbar'
import ImagePackageToolbar from './toolbar/toolbar-image-package'
import Header from './Header'
import NotificationBubble from '../common/notification-bubble'
import { PROJECT_TYPES, TOP_BAR_HEIGHT } from '../../constants'

/** To be able to rewrite this component to typescript we need to first
 * rewrite Header(layout), PanelLeft and PanelRight to typescript.
*/
class Visualizer extends PureComponent {
  componentDidMount () {
    document.body.classList.add('hide-scrollbar')
    this.props.setCurrent()
    this.props.fetchPatterns()
    this.props.getMaterialCategoriesList()
    this.props.fetchCarriers()
  }

  componentWillUnmount () {
    document.body.classList.remove('hide-scrollbar')
    this.props.clear()
    this.props.clearAppearanceGroupState()
    this.props.resetResourceFilters({ resource: 'variant' })
    this.props.clearAppearanceCategorieFilters()
    this.props.clearMarkedAppearanceAndColor()
    this.props.clearQueryString()
  }

  render () {
    const {
      goBack,
      onBeforeNavigate,
      imagePackageId,
      projectType,
      isMaster,
      projectId,
      leftPanelIndex,
      rightPanelIndex,
      combinationId
    } = this.props

    return (
      <Wrapper>
        <Header onBeforeNavigate={onBeforeNavigate}>
          {projectType === PROJECT_TYPES.IMAGE_PACKAGE && (
            <ImagePackageToolbar isMaster={isMaster} />
          )}

          {projectType !== PROJECT_TYPES.IMAGE_PACKAGE && (
            <Toolbar />
          )}
        </Header>
        <Main>
          <div className='custom-scrollbar'>
            <PanelGrid panelWidth={TOP_BAR_HEIGHT}>
              <PanelLeft
                defaultIndex={leftPanelIndex}
                onBeforeNavigate={onBeforeNavigate}
                projectType={projectType}
                isMaster={isMaster}
              />
              <Viewer
                projectId={projectId}
                projectType={projectType}
                imagePackageId={imagePackageId}
                combinationId={combinationId}
              />
              <PanelRight
                defaultIndex={rightPanelIndex}
                projectType={projectType}
                isMaster={isMaster}
              />
            </PanelGrid>
          </div>
        </Main>
        <FileProgress
          goBack={goBack}
        />
        <CreatePatternModal />
        <NotificationBubble
          notification={this.props.notificationMessage}
          error={this.props.error}
          onClearOrTimeout={() => {
            this.props.resetNotificationMessage()
          }}
        />
      </Wrapper>
    )
  }
}

const mapStateToProps = createSelector(
  fromCombinationsSelectors.getCurrentEntry,
  state => state.projects.error,
  state => state.projects.notificationMessage,
  (_, ownProps) => ownProps,
  (combination, error, notificationMessage, ownProps) => {
    const isMaster = combination.isMaster
    const isNewImagePackage = isMaster && !combination.parentId
    const isEmptyScene = !ownProps.combinationId
    let leftPanelIndex = null

    if (isEmptyScene) {
      leftPanelIndex = 2
    }

    if (isNewImagePackage) {
      leftPanelIndex = 0
    }

    let rightPanelIndex = null

    if (combination.imageTemplateId) {
      rightPanelIndex = 1
    }

    return {
      leftPanelIndex: leftPanelIndex,
      rightPanelIndex: rightPanelIndex,
      projectType: ownProps.projectType,
      imagePackageId: combination.imagePackageId,
      isMaster: combination.isMaster || false,
      error: error && typeof error === 'object' ? JSON.stringify(error) : error,
      notificationMessage,
      combinationId: ownProps.combinationId
    }
  }
)

const mapDispatchToProps = (dispatch, ownProps) => ({
  setCurrent () {
    if (ownProps.sceneId) {
      dispatch(getScene(ownProps.sceneId, true))
    }
    dispatch(fromProjects.setCurrent(ownProps.projectId))
    // TODO is there any way to figure out if all project data has been fetched already? On reload, combination + render data disappears
    // TODO maybe check for access to project somewhere here?
    dispatch(fromProjects.getProject(ownProps.projectId, { mergeCombinations: true, force: true }))
    dispatch(fromCombinations.setCurrent(ownProps.combinationId))
  },
  clear () {
    dispatch(fromSelection.clear())
  },
  resetNotificationMessage: () => dispatch(fromProjects.setNotificationMessage(null)),
  fetchPatterns: () => dispatch(fromPatterns.getAvailablePatterns()),
  resetResourceFilters: (payload) => dispatch(fromDesigns.resetResourceFilters(payload)),
  clearAppearanceGroupState: () => dispatch(fromAppearanceGroups.clearAppearanceGroupsState()),
  getMaterialCategoriesList: () => dispatch(fromMaterialSearch.getMaterialCategoriesList()),
  clearAppearanceCategorieFilters: () => dispatch(fromMaterialSearch.clearAppearanceCategorieFilters()),
  clearQueryString: () => dispatch(fromMaterialSearch.setQueryString('')),
  clearMarkedAppearanceAndColor () {
    dispatch(fromProjects.clearMarkedAppearances(ownProps.projectId))
    dispatch(fromProjects.clearMarkedColors(ownProps.projectId))
  },
  fetchCarriers: () => dispatch(fromMaterials.fetchCarriers())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Visualizer)
