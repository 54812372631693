import cs from 'classnames'
import _get from 'lodash/get'
import PropTypes from 'prop-types'
import React from 'react'
import colors from '../../../../css/colors'
import { DEADLINE_STATUS } from '../../../constants'

import IconMultipleImages from '../../common/icons/icon-multiple-images'
import KeyboardListener, { KeyBinding, KeyCode } from '../../common/keyboard-listener'
import Pending from '../../common/pending'
import ImageTemplateIcon from '../../common/icons/ImageTemplateIcon'
import css from './index.css'

import { getImageSrc } from '../../../utils/storage'
import { getDeadlineStatus, getRenderPercentage } from '../../../utils/imageTemplateUtils'

const getThumbnail = (render) => getImageSrc(_get(render, 'manifest.files.0', {}), { format: 'jpg', type: 'thumbnail' })

function Thumbnail (props) {
  const thumbnailRef = React.useRef()
  const isBatchRender = (
    _get(props, 'item.connectedBatchRenderCombinationIds.length', 0) > 0 ||
    !!_get(props, 'item.batchParentId')
  )

  React.useEffect(() => {
    if (props.isActive) {
      props.onScrollIntoView(thumbnailRef.current)
    }
  }, [props.isActive])

  return (
    <div
      className='relative ml1 pointer'
      ref={thumbnailRef}
      onClick={props.onClick}
      style={{
        height: '100%',
        width: 'auto',
        boxShadow: props.isActive ? `0 0 0 4px ${colors.primary}` : ''
      }}
    >
      <img
        src={props.src}
        className={cs('userselect-none', css.navThumbnail)}
        style={{
          height: '100%',
          width: 'auto'
        }}
      />
      { props.deadlineStatus !== DEADLINE_STATUS.COMPLETED &&
      <div className='absolute top-0 left-0 right-0 bottom-0 c-black flex justify-center items-center '>
        <Pending
          deadlineStatus={props.deadlineStatus}
          renderStatus={props.deadlineStatus}
          renderPercentage={props.renderPercentage}
          hideTitle
          disableButtons
          smallerIcon
          isVersioned={props.item.isVersioned}
        />
      </div>
      }
      {props.isImagePackageProject && props.item.isVersioned && props.item.isRender &&
          <IconMultipleImages
            size={14}
            color={colors.secondary}
            className='absolute top-0 right-0'
            style={{ margin: '4' }}
          />}

      {props.item.imageTemplateId && props.item.renders && (
        <div className='absolute' style={{ right: 6, bottom: 6 }}>
          <ImageTemplateIcon
            size={20}
            isBatchRender={isBatchRender}
            iconSize={18}
            stackStep={4}
            showStack={props.item.renders.length > 1}
          />
        </div>
      )}
    </div>
  )
}

Thumbnail.propTypes = {
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  src: PropTypes.string
}

export default function Nav (props) {
  const navRef = React.useRef(null)

  function handleScrollIntoView (domNode) {
    const scrollX = domNode.offsetLeft - (navRef.current.offsetWidth * 0.5)
    navRef.current.scrollTo(scrollX, 0)
  }

  return (
    <div
      className={cs('overflow-hidden mt1', { px2: !props.noHorizontalPadding })}
      style={{ gridArea: 'nav' }}
    >
      <div
        ref={navRef}
        className={cs('smooth-scroll nowrap pb1 pr1 pt2 height-100 overflow-x-scroll overflow-y-hidden flex', {
          hidden: props.items.length === 1
        })}
      >
        {props.items.map((item) => (
          <div style={{ height: '100%', width: 'auto' }} className='flex relative' key={item.id}>
            <Thumbnail
              key={item.id}
              src={getThumbnail(item.render)}
              onClick={() => props.onSelect(item.id)}
              isActive={item.id === props.activeItemId}
              onScrollIntoView={handleScrollIntoView}
              item={item}
              isImagePackageProject={props.isImagePackageProject}
              deadlineStatus={item.renders && getDeadlineStatus(item.renders)}
              renderPercentage={item.renders && getRenderPercentage(item.renders)}
            />
          </div>
        ))}
      </div>

      <KeyboardListener
        disabled={props.disabled}
        bindings={[
          KeyBinding(KeyCode.left, props.onPrev),
          KeyBinding(KeyCode.right, props.onNext)
        ]}
      />
    </div>
  )
}

Nav.propTypes = {
  items: PropTypes.array.isRequired,
  activeItemId: PropTypes.string,
  onSelect: PropTypes.func,
  isImagePackageProject: PropTypes.bool
}
