import React, { ComponentPropsWithoutRef } from 'react'
import styled from 'styled-components'

const StyledFieldset = styled.fieldset`
  padding: 0;
  margin: 0;
  border: none;
`

type Props = ComponentPropsWithoutRef<'fieldset'>

const Fieldset = ({ children, ...props }: Props) => {
  return (
    <StyledFieldset
      {...props}
    >
      {children}
    </StyledFieldset>
  )
}

export default Fieldset
