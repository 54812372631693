import React from 'react'
import PropTypes from 'prop-types'

const Grid = ({
  children,
  columns,
  className,
  rows,
  gridGap,
  style,
  areas,
  onClick,
  'data-testid': testid
}) => (
  <div
    data-testid={testid}
    className={className}
    onClick={onClick}
    style={{
      display: 'grid',
      gridGap,
      gridTemplateAreas: areas.map(area => `"${area}"`).join(' '),
      gridTemplateColumns: columns && columns.map((x) => (isNaN(x) ? x : `${x}px`)).join(' '),
      gridTemplateRows: rows && rows.map((x) => (isNaN(x) ? x : `${x}px`)).join(' '),
      ...style
    }}
  >
    {children}
  </div>
)

Grid.propTypes = {
  children: PropTypes.any,
  columns: PropTypes.arrayOf(PropTypes.any),
  rows: PropTypes.arrayOf(PropTypes.any),
  areas: PropTypes.arrayOf(PropTypes.string),
  gridGap: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.object,
  className: PropTypes.string,
  onClick: PropTypes.func,
  'data-testid': PropTypes.string
}

Grid.defaultProps = {
  className: '',
  style: {},
  gridGap: 16,
  areas: [],
}

export default Grid
