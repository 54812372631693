import React from 'react'
import PropTypes from 'prop-types'
import cs from 'classnames'

import { MdDelete as IconTrash, MdFileDownload as IconDownload, MdShare as IconShare } from 'react-icons/md'

import css from '../index.css'

const IconActions =
({
  onRemove,
  onDownloadSpreadsheet,
  onDownloadImages,
  isDnpProject,
  isSentToDnp,
  onClickSendToDnp,
  isToolsDisabled,
  isRemoveDisabled,
  isDownloadDisabled,
  noSpreadsheet,
  noDownload,
  className
}) => (
  <div className={cs(className, 'flex justify-end items-end')}>
    {isDnpProject && !isSentToDnp &&
      <IconShare
        size={24}
        className={cs(css.upAnimation, 'pointer mr2', {
          disabled: isToolsDisabled
        })}
        onClick={onClickSendToDnp}
        style={{ marginLeft: -8 }}
      />
    }

    {isDnpProject && isSentToDnp &&
      <div
        className='mr2 bold disabled'
      >
        Shared to DNP
      </div>
    }

    {!noSpreadsheet &&
      <div
        title="Download spreadsheet"
        onClick={onDownloadSpreadsheet}
        className={cs(css.upAnimation, 'pointer mr2 bold', {
          disabled: isToolsDisabled
        })}
      >
          .xls
      </div>
    }
    <div title="Remove">
      <IconTrash
        size={24}
        className={cs(css.upAnimation, 'pointer mr2', {
          disabled: isRemoveDisabled || isToolsDisabled
        })}
        onClick={onRemove}
      />
    </div>
    {!noDownload &&
    <div title="Download">
      <IconDownload
        size={24}
        className={cs(css.upAnimation, 'pointer', {
          disabled: isDownloadDisabled || isToolsDisabled
        })}
        onClick={onDownloadImages}
      />
    </div>
    }
  </div>
)

IconActions.propTypes = {
  isToolsDisabled: PropTypes.bool,
  onRemove: PropTypes.func,
  onDownloadSpreadsheet: PropTypes.func,
  onDownloadImages: PropTypes.func
}

IconActions.defaultProps = {
  isToolsDisabled: false,
  onRemove () {},
  onDownloadSpreadsheet () {},
  onDownloadImages () {}
}

export default IconActions
