import React from 'react'

import Square from '../../common/Square'
import DragAndDropStyled from '../../common/DragAndDropStyled'
import { FaCloudUploadAlt as IconUpload } from 'react-icons/fa'
import ProgressBar from '../../common/progress-bar'
import CardShell from './CardShell'

type Props = React.HTMLAttributes<HTMLDivElement> & {
  type: string
  isDragging: boolean
  progress?: number
}

const UploadCard = (props: Props) => {
  const showProgress = props.progress && props.progress !== 100

  return (
    <CardShell
      className='c-gray-light-hover-trigger pointer'
      noShadow
      style={props.style}
      onClick={props.onClick}
    >
      <DragAndDropStyled isDragging={props.isDragging}>
        <div style={{ pointerEvents: 'none' }}>
          <Square innerClass='justify-center'>
            <div
              style={{
                alignItems: 'flex-end',
                width: 200,
                height: 200
              }}
            >
              <div
                className='flex width-50'
                style={{
                  margin: 'auto',
                  height: '65%'
                }}
              >
                <IconUpload style={{ alignSelf: 'flex-end' }} size={100} />
              </div>
              <div
                style={{ textAlign: 'center' }}
              >
                <div className='line-height'>
                  <div className='flex-column justify-center'>
                    <div>Upload</div>
                    <div>{props.type}</div>
                  </div>
                  <p className='m0 h6'><i>Drag files / Click</i></p>
                </div>
              </div>
            </div>
          </Square>

          <div className='height-100 flex'/>
          <div className='px1 py1'>
            <div
              className={`f7 c-gray-light-hover-triggee ${props.isDragging ? 'c-gray-light' : 'c-white'}`}
              style={{ paddingBottom: '0.2rem' }}
            >
              <div>.</div>
            </div>

            <div className='f7 c-white'>
              {showProgress && (
                <ProgressBar
                  hideLabel
                  animated
                  striped
                  percentage={props.progress} pad={0}
                />
              )}
              {!showProgress && (
                <div className={`f7 c-gray-light-hover-triggee ${props.isDragging ? 'c-gray-light' : 'c-white'}`}>.</div>
              )}
            </div>
            <div style={{ height: 7 }} />
          </div>
        </div>
      </DragAndDropStyled>
    </CardShell>
  )
}

UploadCard.defaultProps = {
  type: 'Geometry',
  onUploaded: () => ({}),
  style: {}
}

export default UploadCard
