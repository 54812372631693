import * as THREE from 'three'
import _debounce from 'lodash/debounce'
import raf from 'raf'
import './plugins'

import Debug from './debug'
import AnimationManager from './AnimationManager'
import { Histogram } from './histogram'
import GLTFExporter2 from './GLTFExporter'
import PostProcessManager from './PostProcessManager'
import RenderScene from './RenderScene'
import LightGrid from './LightGrid'
import CameraManager from './scenegraph/CameraManager'
import ViewerUtils from './viewer-utils'
import * as ColorUtils from './util/ColorUtils'
import * as annotations from './tools/annotations'
import AlignTool from './tools/AlignTool'
import CloneTool from './tools/CloneTool'

import Picker from './tools/Picker'
import SnappingTool from './tools/SnappingTool'
import TriplanarTool from './tools/TriplanarTool'
import { SplitTool } from './tools/SplitTool/SplitTool'
import TransformGizmo from './tools/TransformGizmo'
import HTCVive from './tools/HTCVive/HTCVive'
import * as SceneGraph from './scenegraph/SceneGraph'
import VariantManager from './scenegraph/VariantManager'
import AssetManager from './AssetManager'
import CanvasImageCapturer from './CanvasImageCapturer'
import ImageTemplateManager from './scenegraph/ImageTemplateManager'
import RoomManager from './Room/RoomManager'
import AssembleTool from './tools/AssembleTool'
import { OrbitControls } from './plugins/OrbitControls'

const SpaceMouse = require('./plugins/SpaceMouse')(THREE)

const app = {}

app.THREE = THREE
app.SceneGraph = SceneGraph
app.TriplanarTool = TriplanarTool
app.CloneTool = CloneTool
app.OrbitControls = OrbitControls
app.LightGrid = LightGrid
app.ColorUtils = ColorUtils
app.TriplanarMaterial = require('./materials/TriplanarMaterial')
app.commands = require('./commands')
app.CameraManager = CameraManager

app.splitTool = new SplitTool(app)

app.createDefaultCanvas = function () {
  const canvas = document.createElement('canvas')
  canvas.setAttribute('tabIndex', -1)
  return canvas
}

app.createDefaultWrapper = function (canvas) {
  const div = document.createElement('div')
  div.appendChild(canvas)
  document.body.appendChild(div)
  document.body.style.margin = 0
  return div
}

app.generateRenderer = function (renderSettings) {
  const renderer = new THREE.WebGLRenderer(renderSettings)
  renderer.shadowMap.enabled = true
  renderer.localClippingEnabled = false
  renderer.shadowMap.type = THREE.PCFSoftShadowMap
  renderer.shadowMap.autoUpdate = false
  renderer.setClearColor(0xffffff, 1.0)
  renderer.gammaFactor = 2.2
  renderer.outputEncoding = THREE.GammaEncoding
  return renderer
}

app.getCanvas = function () {
  app.domElement = app.domElement || app.createDefaultCanvas()
  return app.domElement
}

app.getNonTemplateMeshes = function () {
  const meshes = []
  app.scene.traverse(function (child) {
    if (child.isMesh && child.geometry && !child.userData.isTemplate) {
      meshes.push(child)
    }
  })
  return meshes
}

app.addAnnotationMesh = function (mesh, annotationId, annotationText) {
  if (mesh.isMesh && mesh.geometry && !mesh.userData.isTemplate) {
    annotations.addMarker(mesh, annotationId, app.domElement.parentElement, annotationText)
  }
}
app.updateAnnotation = function (id, annotationText) {
  annotations.updateAnnotationText(id, annotationText)
}

app.init = function (args = {}) {
  const {
    domElement,
    domElementWrapper,
    width,
    height,
    doRenderAlways,
    useOrbitControls
  } = args
  app.domElement = domElement || this.getCanvas()
  app.domElementWrapper = domElementWrapper || app.createDefaultWrapper(app.domElement)
  app.width = width || window.innerWidth
  app.height = height || window.innerHeight
  app.doRenderAlways = (doRenderAlways !== undefined) ? doRenderAlways : true
  app.useOrbitControls = (useOrbitControls !== undefined) ? useOrbitControls : true

  //  Renderer
  var renderSettings = {
    canvas: app.domElement,
    antialias: false,
    gammaOutput: true,
    alpha: true, // We need alpha output since we currently use CSS for a background gradient
    depth: false,
    stencil: false
  }

  app.animationManager = new AnimationManager()
  app.histogram = new Histogram()

  app.renderer = app.generateRenderer(renderSettings)
  app.renderer.setSize(app.width, app.height)
  app.histogram.setup(app.renderer)

  app.renderOnNextFrame = function () {
    app._debouncedResolutionThrottling()
    app.doRenderOnNextFrame = true
  }

  app.assetManager = new AssetManager(app)
  app.viewerUtils = new ViewerUtils(app.assetManager)

  // Camera
  app.camera = new THREE.PerspectiveCamera(37, app.width / app.height, 0.01, 100)
  app.camera.lookAt(new THREE.Vector3())
  app.cameraParent = new THREE.Object3D()
  app.cameraParent.add(app.camera)

  // Scene
  app.renderScene = new RenderScene(app.renderer, app.assetManager, app.camera, app.renderOnNextFrame)
  app.scene = new SceneGraph.SceneGraph(app, app.assetManager, app.renderScene)

  // Picker
  app.picker = new Picker(app.renderer.domElement, app.renderScene.rayCaster, app.scene.objectTracker)

  // Flags
  app.doRenderOnNextFrame = true
  app.isDisposed = false
  app.hasSpaceMouse = false
  app.resetResolution = false

  app.objectTracker = app.scene.objectTracker

  app.renderScene.scene.add(app.cameraParent)
  app.overlayScene = new THREE.Scene()

  // Cube Camera
  app.cubeCamera = app.renderScene.localReflectioCubeCamera
  app.cubeCamera.position.set(0, 0.2, 0)

  // Other
  app.transformGizmo = new TransformGizmo(app, app.camera, app.renderer)
  app.snappingTool = new SnappingTool(app, app.domElement)
  app.alignTool = new AlignTool(app, app.domElement)
  app.cameraManager = new CameraManager(app)
  app.variantManager = new VariantManager(app)
  app.canvasImageCapturer = new CanvasImageCapturer(app)
  app.imageTemplateManager = new ImageTemplateManager(app)
  app.gltfExporter = new GLTFExporter2(app)
  app.assembleTool = new AssembleTool(app)

  app.triplanarTransformGizmo = new TransformGizmo(app, app.camera, app.renderer)
  app.triplanarTransformGizmo.setMode('rotate')
  app.triplanarTransformGizmo.setSpace('local')
  app.triplanarTransformGizmo.control.setRotationSnap(5.0 * 0.0174533)

  // Annotations
  const viewportOffset = app.domElement.getBoundingClientRect()
  annotations.setup(app.renderScene, app.overlayScene, app.picker, window, viewportOffset.left, viewportOffset.top)
  annotations.enabled = false
  app.annotations = annotations

  app.roomManager = new RoomManager(app)

  app.go3dLoader = {
    load: function (filePathsConfigObject) {
      return app.assetManager.loadGo3dformat(filePathsConfigObject)
        .then(intermediateScene => {
          return SceneGraph.convertIntermediateSceneToSceneGraphNode(intermediateScene).scene
        })
    }
  }

  app.go3dLoader2 = {
    load: function (uri, urlPrefixObject) {
      return app.assetManager.loadGo3dformat2(uri, urlPrefixObject)
        .then(intermediateScene => {
          return SceneGraph.convertIntermediateSceneToSceneGraphNode(intermediateScene).scene
        })
    }
  }

  app.materialLoader = {
    loadMaterial: function (materialJson, cb) {
      app.assetManager.loadMaterial(materialJson)
        .then(material => { cb(null, material) })
        .catch(error => { cb(error, null) })
    },
    loadDecal: function (decalJson, cb) {
      app.assetManager.materialLoader.loadDecal(decalJson)
        .then(map => { cb(null, map) })
        .catch(error => { cb(error, null) })
    },
    calculateDecalScale: function (decalJson) {
      return app.assetManager.materialLoader.calculateDecalScale(decalJson)
    },
    addListener: function (type, handler) {
      return app.assetManager.materialLoader.addListener(type, handler)
    }
  }

  app.setEnvMap = function (prefix, postfix) {
    return app.renderScene.setEnvMap(prefix, postfix)
  }

  app.loader = {
    load (model) {
      return SceneGraph.loadModel(app.assetManager, model)
    },
    loadMesh (mesh) {
      return SceneGraph.loadMesh(app.assetManager, mesh)
    },
    addListener (type, handler) {
      return app.assetManager.geometryLoader.addListener(type, handler)
    }
  }

  app.debug = new Debug(app, app.renderScene.scene)
  app.vive = new HTCVive(app)
  app.setupEventHandler()

  app.setSelection = function (newSelection) {
    // NOTE: This function should only be used by external application, not when user selects a pickable object using mouse
    // NOTE: We can't use app.picker.clearSelection since it triggers an event
    for (const uuid in app.picker.selection) {
      const node = app.picker.selection[uuid]
      node.outline = false
    }
    app.picker.selection = {}
    app.picker.brepObjectIsSelected = false
    newSelection.forEach(node => {
      app.picker.selection[node.uuid] = node
      node.outline = true
      app.picker.brepObjectIsSelected = !!node.userData.generated
      if (app.picker.brepObjectIsSelected) {
        node.parent.children.forEach(c => {
          app.renderScene.addLinesFromBrepMesh(c)
        })
      } else {
        app.renderScene.removeNonMeshes()
      }
    })
    app.renderOnNextFrame()
    app.picker.emit('change', Object.values(app.picker.selection))
  }

  // Post processing
  app.postProcessManager = new PostProcessManager(
    app.renderer,
    { overlayScene: app.overlayScene },
    app.debug.recieveStats,
    app.renderOnNextFrame
  )

  app.postProcessManager.enabled = true // temp
  app.outlinePass = app.postProcessManager.outline

  // OrbitControls
  if (app.useOrbitControls) {
    app.cameraManager.useOrbitControls()
  }
  app.resetResolutionThrottling()

  window.addEventListener('resize', app.updateCameraSize, false)

  window.app = app
  window.go3ddebug = function () {
    app.debug.start(app)
  }
}

app.generateAnnotationSVG = function (targetSVGWidth, targetSVGHeight) {
  return annotations.convertToSVG(
    app.renderer.domElement.width,
    app.renderer.domElement.height,
    app.postProcessManager.safeFrame.safeFrameWidth * 2,
    app.postProcessManager.safeFrame.safeFrameHeight * 2,
    targetSVGWidth,
    targetSVGHeight)
}

app.enableAnnotations = function () {
  annotations.setEnabled(true)
  app.picker.disable()
  app.transformGizmo.disable()
  app.triplanarTransformGizmo.disable()
  app.picker.clearSelection()
  app.renderOnNextFrame()
}

app.disableAnnotations = function () {
  annotations.setEnabled(false)
  app.picker.enable()
  app.transformGizmo.enable()
  app.triplanarTransformGizmo.enable()
  const meshes = app.getNonTemplateMeshes()
  meshes.forEach((mesh) => {
    annotations.removeMarker(mesh, app.domElement.parentElement)
  })
  app.picker.clearSelection()
  app.renderOnNextFrame()
}

app.toggleAnnotations = function () {
  const doEnable = !annotations.isEnabled()
  annotations.setEnabled(doEnable)
  if (doEnable) {
    app.picker.disable()
    app.transformGizmo.disable()
  } else {
    app.picker.enable()
    app.transformGizmo.enable()
    const meshes = app.getNonTemplateMeshes()
    meshes.forEach((mesh) => {
      annotations.removeMarker(mesh, app.domElement.parentElement)
    })
  }
  app.picker.clearSelection()
  app.renderOnNextFrame()
}

app.addAxesHelper = function () {
  var axesHelper = new THREE.AxesHelper(200)
  axesHelper.name = 'axesHelper'
  app.overlayScene.add(axesHelper)
}
app.setOutlineColors = function (r, g, b) {
  app.postProcessManager.setOutlineColors(r, g, b)
}

app.setupEventHandler = function () {
  app.picker.on('select', (objects, intersection) => {
    app.renderOnNextFrame()

    if (app.picker.brepObjectIsSelected) {
      objects.forEach(o => {
        o.parent.children.forEach(c => {
          app.renderScene.addLinesFromBrepMesh(c)
        })
      })
    } else {
      app.renderScene.removeNonMeshes()
    }

    objects.forEach(o => {
      o.outline = true
    })
  })

  app.picker.on('deselect', (objects, intersection) => {
    app.renderOnNextFrame()

    objects.forEach(o => {
      o.outline = false
    })

    app.scene.traverse(node => {
      node.transformOutline = false
    })

    if (!app.picker.brepObjectIsSelected) {
      app.renderScene.removeNonMeshes()
    }
  })

  if (app.snappingTool) {
    app.snappingTool.on('mouseMove', app.renderOnNextFrame)
    app.snappingTool.on('snapped', () => { })
  }

  if (app.transformGizmo) {
    app.transformGizmo.control.addEventListener('change', app.renderOnNextFrame, { once: true })

    app.transformGizmo.on('moved', function () {
      app.cameraManager.centerControlsAroundObjects(app.picker.selection)
      app.renderOnNextFrame()
    })

    app.transformGizmo.on('attach', function (objs) {
      app.scene.traverse(node => {
        node.transformOutline = false
      })

      objs.forEach(obj => {
        obj.transformOutline = true
      })
    })
  }

  if (app.triplanarTransformGizmo) {
    app.triplanarTransformGizmo.control.addEventListener('change', app.renderOnNextFrame, { once: true })

    app.triplanarTransformGizmo.on('moved', function () {
      app.renderOnNextFrame()
    })
  }

  app.cameraManager.on('fov-change', (fov) => {
    if (app.transformGizmo) {
      const fovScale = app.cameraManager.calculateFovScale(fov)
      app.transformGizmo.control.setSize(fovScale)
    } else if (app.triplanarTransformGizmo) {
      const fovScale = app.cameraManager.calculateFovScale(fov)
      app.triplanarTransformGizmo.control.setSize(fovScale)
    }

    app.updateCameraSize()
  })

  app.cameraManager.on('change', app.renderOnNextFrame)

  const annotationEmitter = annotations.getEmitter()

  annotationEmitter.on('render', function () {
    app.renderOnNextFrame()
  })

  annotationEmitter.on('toggle-rotate', function (value) {
    app.cameraManager.controls.enableRotate = value
  })

  annotationEmitter.on('select', function (objects) {
    app.cameraManager.centerControlsAroundObjects(objects)
    app.renderOnNextFrame()
    objects.forEach(o => {
      o.outline = true
    })
  })

  annotationEmitter.on('clear-select', function () {
    app.scene.traverse(o => {
      o.outline = false
    })
    app.renderOnNextFrame()
  })
}

app.enableSpaceMouse = function (controls) {
  app.spaceMouse = new SpaceMouse(0, controls)
  app.renderScene.scene.add(app.spaceMouse)
  app.hasSpaceMouse = app.spaceMouse.hasGamePad()
}

app.setCubeCameraPosition = function (newPosition) {
  app.renderScene.setLocalReflectionsCubeCameraPosition(newPosition)
}

app.setAspectRatio = function (aspectRatio) {
  app.postProcessManager.safeFrameRes = aspectRatio
  app.postProcessManager.safeFrame.safeFrameRes = aspectRatio
  app.postProcessManager.aspectRatio = aspectRatio
}

app.updateCameraSize = function updateCameraSize (
  _,
  width = app.domElementWrapper.offsetWidth,
  height = app.domElementWrapper.offsetHeight,
  updateOffsetViewSize = true
) {
  app.width = width
  app.height = height

  app.renderer.setSize(app.width, app.height)
  app.postProcessManager.setSize(app.width, app.height)
  app.cameraManager.updateCameraSize(app.width, app.height, app.postProcessManager.safeFrameHeight)

  if (updateOffsetViewSize && app.camera.view && app.imageTemplateManager.enabled) {
    app.imageTemplateManager.updateOffsetViewSize(app.width, app.height)
  }

  app.renderOnNextFrame()
}

app.addModel = function (model, params) {
  params = params || {}
  app.scene.addModel(model, params)
}

app.removeModel = function (model) {
  app.scene.removeModel(model)
}

app.dispose = function () {
  app.isDisposed = true
  for (var key in app) {
    if (app[key] !== undefined && app[key].dispose && !app[key].isScene) app[key].dispose()
  }

  window.removeEventListener('resize', app.updateCameraSize, false)

  delete app.overlayScene
  delete app.renderer
  delete app.cubeCamera
  delete app.scene
  delete app.renderScene
  delete app.assetManager
  delete app.postProcessManager
  delete app.annotations
  delete app.histogram
  delete window.go3ddebug
  delete window.app
}

app._debouncedResolutionThrottling = _debounce(() => {
  if (app.isDisposed) { return }
  app.resetResolutionThrottling({ shouldRenderNextFrame: false })
  app.doRenderOnNextFrame = true
}, 200, { leading: false, trailing: true })

app.animate = function (cb, time) {
  if (app.isDisposed) return
  cb = cb || function () { }
  time = time || window.performance.now()
  app.assetManager.updateMaterials()
  app.scene.commitChanges()
  app.assetManager.updateMaterials()
  // app.renderScene.updateLocalReflections() // TODO: disabled local reflections when updating to R116, see #36

  const deltaTime = app.postProcessManager.updateFrameRate(time)

  const clampedDeltaTime = Math.min(deltaTime, 0.1)
  app.animationManager.update(clampedDeltaTime)
  app.cameraManager.update(clampedDeltaTime)
  annotations.update(app)
  if (app.imageTemplateManager) {
    app.imageTemplateManager.update(clampedDeltaTime)
  }

  if (app.renderVive) {
    app.vive.render(app.animate)
  } else {
    if (app.doRenderOnNextFrame || app.doRenderAlways || app.animationManager.didAnimate() || app.transformGizmo.didAnimate()) {
      app.doRenderOnNextFrame = false
      app.camera.updateMatrixWorld()

      if (app.hasSpaceMouse) {
        app.spaceMouse.update(app.camera)
      }
      cb(deltaTime)

      if (!app.throttledResolution && !app.resetResolution) {
        app.throttleSettings()
      }

      if (annotations.isEnabled()) {
        annotations.render(app.camera, app.domElement)
        annotations.visibilityNeedsUpdate()
      }

      if (app.postProcessManager.enabled) {
        app.postProcessManager.render(app.renderScene, app.camera)
      } else {
        app.renderer.render(app.renderScene, app.camera)
      }
    } else {
      app.resetResolution = false
    }
    raf(app.animate.bind(this, cb))
  }
}

app.throttleSettings = function () {
  if (app.postProcessManager.frameRate < app.postProcessManager.cutoffFrameRate) {
    app.postProcessManager.ssao.aoClamp = app.postProcessManager.ssao.throttledAoClampModifier * app.postProcessManager.ssao.originalAoClamp
    app.postProcessManager.ssao.lumInfluence = app.postProcessManager.ssao.throttledLumInfluenceModifier * app.postProcessManager.ssao.originalLumInfluence
    app.throttledResolution = true
    app.postProcessManager.resolutionScale = 0.6
  }
}

app.resetResolutionThrottling = function ({ shouldRenderNextFrame } = {}) {
  app.postProcessManager.ssao.aoClamp = app.postProcessManager.ssao.originalAoClamp
  app.postProcessManager.ssao.lumInfluence = app.postProcessManager.ssao.originalLumInfluence
  app.throttledResolution = false
  app.resetResolution = true
  app.postProcessManager.resolutionScale = 1.0
}

export default app
