import React, { MouseEvent } from 'react'

import { StorageApiThumbnail } from '../../common/storage-api-image'
import Button from '../../common/button'
import Label from '../../common/form/label'
import { MdVisibilityOff as IconVisibilityOff } from 'react-icons/md'
import Badge from '../../common/Badge'
import { Roomset } from '../../../stores/ducks/roomsets/Roomset'
import { ImmutableObject } from 'seamless-immutable'
import Grid from '../../common/grid/grid'

type Props = {
  roomset: ImmutableObject<Roomset>
  setups: ImmutableObject<Roomset>[]
  onEdit: () => void
  onDelete: () => void
}

export default function ImageTemplateListItem (props: Props) {
  const thumbnail = props.roomset.metadata?.thumbnail
  const isHidden = props.roomset.metadata?.isHidden
  const isPublished = props.roomset.metadata?.isPublished
  const title = props.roomset.title
  const id = props.roomset.id!
  const converted = props.roomset.combinationId
  const countSetups = props.setups ? props.setups.length : 0

  return (
    <Grid
      className='py2 border-bottom bc-gray-light'
      columns={[150, 'auto']}
    >
      <StorageApiThumbnail
        className='border bc-gray-light pointer'
        file={thumbnail}
        onClick={(event) => {
          event.preventDefault()
          props.onEdit()
        }}
      />

      <div className='flex flex-column justify-between'>
        <div className='f7'>
          <div
            className='mb1 bold pointer flex items-center'
            onClick={(event) => {
              event.preventDefault()
              props.onEdit()
            }}
          >
            {isPublished && (<Badge className='bg-secondary c-white mr1'>Published</Badge>)}
            {!isPublished && (<Badge className='mr1'>Draft</Badge>)}
            <span className='mr1 f6'>{title || 'untitled'}</span>
            <span>{isHidden && (<IconVisibilityOff size='1rem' />)}</span>
          </div>
          <Label subtle>{id}</Label>
          <div className='mb1'>Converted: {converted ? 'yes' : 'no'}</div>
          <div>Light setups: {countSetups}</div>
        </div>
        <div className='flex'>
          <Button
            pad={1}
            className='mr1'
            onClick={(event: MouseEvent) => {
              event.preventDefault()
              props.onEdit()
            }}>
            Edit
          </Button>
          <Button
            pad={1}
            onClick={(event: MouseEvent) => {
              event.preventDefault()
              props.onDelete()
            }}>
            Delete
          </Button>
        </div>
      </div>
    </Grid>
  )
}
