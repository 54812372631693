var THREE = require('three')

/**
 * @author alteredq / http://alteredqualia.com/
 *
 * Full-screen textured quad shader
 */

const CopyShader = {
  uniforms: {
    tDiffuse: { value: null },
    opacity: { value: 1.0 },
    uv_scale: { value: new THREE.Vector2(1.0, 1.0) }
  },

  vertexShader: `
    varying vec2 vUv;

    void main() {
      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }

  `,

  fragmentShader: `
    uniform float opacity;
    uniform sampler2D tDiffuse;
    uniform vec2 uv_scale;
    varying vec2 vUv;

    void main() {
      vec2 uv = vUv * uv_scale;
      vec4 texel = texture2D(tDiffuse, uv);
      gl_FragColor = opacity * texel;
    }
  `
}

class CopyPostProcess {
  constructor () {
    this.copyPass = new THREE.ShaderPass(CopyShader)
    this.enabled = false
  }

  setUVScale (uScale, vScale) {
    this.copyPass.uniforms.uv_scale.value.set(uScale, vScale)
  }

  renderToBuffer (renderer, buffers) {
    this.copyPass.renderToScreen = false
    const input = buffers.input
    const output = buffers.output
    this.copyPass.render(renderer, output, input, 0, false)
    buffers.output = input
    buffers.input = output
  }

  renderToScreen (renderer, buffer) {
    this.copyPass.renderToScren = true
    this.copyPass.render(renderer, null, buffer, 0, false)
  }
}

module.exports = CopyPostProcess
