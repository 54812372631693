import React from 'react'

const IconColorWheel = ({ height, width }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24">
      <path d="M12,12l1.78,11.84A11.79,11.79,0,0,1,1.3,17.37Z" fill="#225cbb"/>
      <path d="M12,12l11.82,2a11.81,11.81,0,0,1-10,9.87Z" fill="#69d15a"/>
      <path d="M12,12,17.54,1.39A11.8,11.8,0,0,1,23.83,14Z" fill="#fcda33"/>
      <path d="M12,12,3.62,3.48A11.78,11.78,0,0,1,17.54,1.39Z" fill="#f29c2a"/>
      <path d="M12,12,1.3,17.37A11.79,11.79,0,0,1,3.62,3.48Z" fill="#c13459"/>
    </svg>
  )
}

export default IconColorWheel
