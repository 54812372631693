import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import cs from 'classnames'

import css from './index.css'
import autoBind from 'react-autobind'
import Label from './label'

import colors from '../../../../css/colors'

class InputCheckbox extends PureComponent {
  constructor (props) {
    super(props)
    autoBind(this, 'handleOnChange')
  }

  handleOnChange (event) {
    if (this.props.stopPropagation) event.stopPropagation()
    this.props.onChange(event.target.checked, this.props.value)
  }

  render () {
    const {
      'data-testid': testid,
      name,
      label,
      noMargin,
      className,
      id,
      value,
      checked,
      inverted,
      disabled
    } = this.props

    const _id = id || name

    return (
      <div
        className={`c-gray-dark ${className} ${css.checkboxNoBold}`}
        onClick={(event) => {
          if (this.props.stopPropagation) event.stopPropagation()
        }}
      >
        <input
          disabled={disabled}
          id={_id}
          key={`input-${_id}`}
          name={name}
          value={value}
          type='checkbox'
          onChange={this.handleOnChange}
          className={css.inputCheckbox}
          checked={checked}
          data-testid={testid || 'inputCheckBox'}
        />
        <Label
          noMargin={noMargin}
          key={`label-${_id}`}
          htmlFor={_id}
          className={{
            [`${css.labelNormal}`]: !inverted,
            [`${css.labelInverted}`]: inverted,
            disabled: disabled,
            'flex items-center': true,
            nowrap: this.props.noWrap
          }}
        >
          <span
            className={cs(
              {
                [css.checkbox]: !disabled,
                'bc-gray-dark': !inverted,
                [css.checkboxInverted]: inverted,
                disabled: disabled,
                [`${css.disabledCheckbox} bg-gray-light`]: disabled && !inverted,
                'bg-secondary': checked && !inverted,
                'bg-primary': checked && inverted,
                'bg-white': !checked && !inverted,
                'br-small': true
              }
            )}
            style={inverted && { boxShadow: `inset 0px 0px 0px 3px ${colors.grayDark}` }}
          />
          <span>{label}</span>
        </Label>
      </div>
    )
  }
}

InputCheckbox.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  handleOnChange: PropTypes.func,
  name: PropTypes.string,
  selected: PropTypes.bool,
  noWrap: PropTypes.bool,
  stopPropagation: PropTypes.bool
}

InputCheckbox.defaultProps = {
  className: '',
  noWrap: true,
  label: null
}

export default InputCheckbox
