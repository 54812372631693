import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import _omit from 'lodash/omit'

import Modal from '../../common/modal'
import Tabs from '../../common/tabs/tabs'
import TabGroup from '../../common/tabs/tab-group'

import * as fromRoomsetsAdmin from '../../../stores/ducks/roomsets-admin'
import TabLightSetups from './TabLightSetups'
import TabImageTemplate from './TabImageTemplate'
import Label from '../../common/form/label'
import Button from '../../common/button'

import * as fromAdminRoomsetsSelectors from '../../../stores/ducks/roomsets-admin/selectors'

function formatDate (date: string) {
  return new Date(date).toISOString().replace('T', ' ').substr(0, 16)
}

type Props = {
  onRequestClose: () => void
  isOpen: boolean
}

export type Errors = {
  title?: boolean
  maxFile?: boolean
  thumbnail?: boolean
  defaultCameraId?: boolean
  defaultTargetId?: boolean
  defaultDummyId?: boolean
  manualAlignmentX?: boolean
  manualAlignmentY?: boolean
  manualAlignmentZ?: boolean
  category?: boolean
}

function AddEditRoomsetModal (props: Props) {
  const dispatch = useDispatch()
  const setups = useSelector(fromAdminRoomsetsSelectors.selectSetupsCurrentId)
  const currentId = useSelector(fromAdminRoomsetsSelectors.selectCurrentId)
  const editingRoomset = useSelector(fromAdminRoomsetsSelectors.selectEditingRoomset)
  const upload = useSelector(fromAdminRoomsetsSelectors.selectUpload)
  const jobStatuses = useSelector(fromAdminRoomsetsSelectors.selectJobStatuses)

  const [errors, setErrors] = React.useState<Errors>({})

  React.useEffect(() => {
    return () => {
      setErrors({})
      props.onRequestClose()
      dispatch(fromRoomsetsAdmin.dispose())
    }
  }, [])

  const validate = (noErrorsCallback: () => void) => {
    const completedConvert = jobStatuses.length && jobStatuses.length === jobStatuses.filter(job => job.status === 'Completed').length
    const uploadDone = upload && upload.extractStatus === 'EXTRACT_DONE'
    const uploadConverted = uploadDone && completedConvert

    const errors: Errors = {}

    if (uploadConverted) {
      if (Object.keys(editingRoomset.cameras || {}).length > 1 && !editingRoomset.defaultCameraId) {
        errors.defaultCameraId = true
      }

      if (Object.keys(editingRoomset.targets || {}).length > 1 && !editingRoomset.defaultTargetId) {
        errors.defaultTargetId = true
      }

      if (Object.keys(editingRoomset.dummies || {}).length > 1 && !editingRoomset.defaultDummyId) {
        errors.defaultDummyId = true
      }

      if (Object.keys(editingRoomset.dummies || {}).length === 0) {
        if (editingRoomset.manualAlignment![0] === null) errors.manualAlignmentX = true
        if (editingRoomset.manualAlignment![1] === null) errors.manualAlignmentY = true
        if (editingRoomset.manualAlignment![2] === null) errors.manualAlignmentZ = true
      }
    }

    if (!editingRoomset.title || !editingRoomset.title.length) errors.title = true
    if (!(editingRoomset.uploadId || editingRoomset.combinationId)) errors.maxFile = true
    if (!editingRoomset?.metadata?.thumbnail) errors.thumbnail = true
    if (!editingRoomset?.metadata?.category) errors.category = true

    setErrors(errors)
    if (!Object.keys(errors).length) noErrorsCallback()
  }

  const handleClose = () => {
    setErrors({})
    dispatch(fromRoomsetsAdmin.dispose())
    props.onRequestClose()
  }

  const countSetups = Object.keys(setups || {}).length

  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={handleClose}
      innerStyle={{ width: '80vw', maxWidth: 1000 }}
    >
      <div>
        <h2 className='mt0'>
          {currentId ? 'Edit' : 'Add new'} image template
        </h2>
      </div>

      <Tabs>
        {(active: number, onToggleActive: () => void, onToggleDefault: () => void) => (
          <>
            <TabGroup
              tabs={[
                {
                  id: 0,
                  label: 'IMAGE TEMPLATE'
                },
                {
                  id: 1,
                  label: `LIGHT SETUPS (${countSetups})`
                }
              ]}
              onToggleActive={onToggleActive}
              onToggleDefault={onToggleDefault}
              active={active}
            />
            <div
              style={{ height: '60vh' }}
              className='py2 overflow-x-hidden overflow-y-scroll custom-scrollbar'
            >
              <TabImageTemplate
                active={active === 0}
                errors={errors}
                omitErrors={(props: any) => setErrors(_omit(errors, props))}
                validate={validate}
              />
              <TabLightSetups
                active={active === 1}
                setups={setups}
              />
            </div>
          </>
        )}
      </Tabs>

      <div className='flex mt3'>
        {editingRoomset.createdAt && (
          <div className='mr2'>
            <Label>Created</Label>
            <div className='f7 select-enable'>{formatDate(editingRoomset.createdAt)}</div>
          </div>
        )}
        {editingRoomset.modifiedAt && (
          <div className='mr2'>
            <Label>Modified</Label>
            <div className='f7 select-enable'>{formatDate(editingRoomset.modifiedAt)}</div>
          </div>
        )}
        <div className='mr2'>
          <Label>Combination id</Label>
          <div className='f7 italic select-enable'>{editingRoomset.combinationId || 'not yet converted'}</div>
        </div>
        <div className='mr2'>
          <Label>Roomset id</Label>
          <div className='f7 italic select-enable'>{currentId || 'not yet saved'}</div>
        </div>
      </div>

      <div className='flex justify-between items-end mt2'>
        <div className='flex'>
          <Button
            title={editingRoomset.metadata?.isHidden ? 'This template is hidden in the Product Picture panel. Press to show.' : 'This template is visible in the Product Picture panel. Press to hide.'}
            disabled={!currentId}
            className='mr2'
            onClick={() => {
              validate(() => {
                const isHidden = editingRoomset?.metadata?.isHidden ?? false
                dispatch(fromRoomsetsAdmin.createOrUpdate({
                  id: editingRoomset.id,
                  metadata: {
                    ...(editingRoomset.metadata || {}),
                    isHidden: !isHidden
                  }
                }))
              })
            }}>
            {editingRoomset.metadata?.isHidden ? 'Show' : 'Hide'}
          </Button>

          <Button
            title={editingRoomset.metadata?.isPublished ? 'This template is visible to all users. Press to set as draft.' : 'This template is only visible to admins. Press to publish.'}
            disabled={!currentId}
            onClick={() => {
              validate(() => {
                const isPublished = editingRoomset?.metadata?.isPublished ?? false
                dispatch(fromRoomsetsAdmin.createOrUpdate({
                  id: editingRoomset.id,
                  metadata: {
                    ...(editingRoomset.metadata || {}),
                    isPublished: !isPublished
                  }
                }))
              })
            }}
          >
            {editingRoomset.metadata?.isPublished ? <span>Set as draft</span> : <span>Publish</span>}
          </Button>
        </div>

        <div className='flex'>
          <Button onClick={handleClose}>
            Cancel
          </Button>

          <Button
            className='ml2'
            btnType='primary'
            disabled={Object.keys(errors).length !== 0}
            onClick={() => {
              validate(() => {
                dispatch(fromRoomsetsAdmin.createOrUpdate(editingRoomset))
              })
            }}
          >
            {currentId ? 'Update' : 'Create'}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default AddEditRoomsetModal
