import React from 'react'
import PropTypes from 'prop-types'
import autoBind from 'react-autobind'
import cs from 'classnames'

import IconCross from './icons/icon-cross'

const fadeTransition = 3000
const styles = {
  fadeout: {
    opacity: 0,
    transition: `all ${fadeTransition}ms ease-in-out`
  },

  bubble: {
    width: 600,
    borderRadius: 2,
    right: 0,
    left: 0,
    bottom: '16px',
    margin: 'auto'
  }
}

/**
 * This needs a relative parent to work.
 */
class NotificationBubble extends React.Component {
  constructor (props, ctx) {
    super(props, ctx)
    autoBind(this)

    this.state = {
      shouldFade: false,
      fadeTimer: null,
      resetTimer: null,

      notification: '',
      error: ''
    }
  }

  _startFadeOut () {
    this.setState({ shouldFade: true })
  }

  _resetBubble () {
    this.setState({
      shouldFade: false,
      notification: '',
      error: ''
    })

    this.props.onClearOrTimeout()
  }

  componentDidUpdate (prevProps) {
    const props = this.props
    const state = this.state

    const shouldStartTimers =
      (prevProps.notification !== props.notification && props.notification) ||
      (prevProps.error !== props.error && props.error)

    if (shouldStartTimers) {
      clearTimeout(state.fadeTimer)
      clearTimeout(state.resetTimer)
      this.setState((prevState, props) => ({
        shouldFade: false,
        fadeTimer: setTimeout(this._startFadeOut, props.notifyTimer),
        // add fadeTransition time for css fade out effect
        resetTimer: setTimeout(this._resetBubble, props.notifyTimer + fadeTransition),

        notification: props.notification || '',
        error: props.error || ''
      }))
    }
  }

  componentWillUnmount () {
    this.state.fadeTimer && clearTimeout(this.state.fadeTimer)
    this.state.resetTimer && clearTimeout(this.state.resetTimer)
  }

  render () {
    const props = this.props
    const state = this.state

    if (!state.notification && !state.error) {
      return null
    }

    const message = state.error ? state.error : state.notification
    const contentStyle = {
      ...styles.bubble,
      ...(state.shouldFade ? styles.fadeout : {})
    }
    return (
      <div
        data-testid='notification-bubble'
        style={contentStyle}
        className={cs(
          `absolute relative br-2 z4 py2 px2 c-white f6 center ${props.className}`,
          {
            'bg-error-light': props.error
          },
          {
            'bg-success-dark': !props.error
          }
        )}
      >
        <IconCross
          size={'1em'}
          className='scale pointer z1 ml2 top-0 right-0 c-white right'
          onClick={() => this._resetBubble()}
        />
        <div>
          {message}
        </div>
      </div>
    )
  }
}

NotificationBubble.propTypes = {
  notifyTimer: PropTypes.number,
  className: PropTypes.string,

  // Use individual notification bubbles for showing error and notification
  notification: PropTypes.string,
  error: PropTypes.string,
  onClearOrTimeout: PropTypes.func
}

NotificationBubble.defaultProps = {
  notifyTimer: 3000,
  className: '',

  notification: '',
  error: '',
  onClearOrTimeout: x => x
}

export default NotificationBubble
