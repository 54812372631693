import React from 'react'

import Modal from '../../common/modal'

import FormRemove from '../../common/form/form-remove'

const RemoveRoomsetModal = props => (
  <Modal
    isOpen={props.isOpen}
    onRequestClose={props.onClose}
    width={600}
  >
    <FormRemove
      formTitle='Delete roomset'
      formDescription='Are you sure you want to delete this Roomset/Home?'
      onConfirm={props.onRemove}
      onCancel={props.onClose}
      buttonText='Delete'
    />
  </Modal>
)

export default RemoveRoomsetModal
