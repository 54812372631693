import _forEach from 'lodash/forEach'
const noop = () => { }

export default function createSetPatternCommand ({
  nodes,
  newNodeValues,
  oldNodeValues,
  afterExecute = noop,
  afterUndo = noop
}) {
  const setRecursePattern = (nodes, nodeValues) => {
    _forEach(nodes, (node) => {
      const nodeUuid = node.uuid
      const nodeValue = nodeValues[nodeUuid]
      if (!nodeValue) {
        return node.children.length && setRecursePattern(node.children)
      }

      node.userData.patternId = nodeValue.patternId

      node.material.decalMap = nodeValue.decalMap
      node.material.decalUVTransform = nodeValue.decalUVTransform
      node.material.userDecalTranslation = nodeValue.userDecalTranslation

      node.material.transformDecal(
        undefined,
        nodeValue.decalScale,
        nodeValue.decalRotation,
        nodeValue.userDecalRotation
      )
    })
  }

  return {
    undo () {
      setRecursePattern(nodes, oldNodeValues)
      afterUndo()
    },
    execute () {
      setRecursePattern(nodes, newNodeValues)
      afterExecute()
    }
  }
}
