import React from 'react'

const Icon3dMode = props => (
  <svg width={props.size} height={props.size} viewBox="0 0 24 24" {...props}>
    <path
      d="M2.9307396,21.4647832 L14.2616391,21.4647832 L19.692649,15.8694723 L14.9285759,15.8694723 L14.9285759,15.5982298 C14.0912033,16.4967763 12.8931093,17.0593533 11.5626212,17.0593533 C10.3993056,17.0593533 9.33720426,16.6292675 8.52893938,15.9205686 L2.9307396,21.4647832 Z M2.53927879,21.0963627 L8.14635499,15.5433573 C7.42027232,14.7384388 6.97875142,13.6753975 6.97875142,12.5100101 C6.97875142,11.1721873 7.56059716,9.96923823 8.48683903,9.13682399 L8.44274717,9.13682399 L8.44274717,3.9561859 L2.53927879,9.81670899 L2.53927879,21.0963627 Z M20.4607212,15.0778056 L20.4607212,3.53521684 L9.23441384,3.53521684 L9.23441384,8.59033349 C9.91677234,8.19024748 10.7126826,7.96066696 11.5626212,7.96066696 C14.0942225,7.96066696 16.1464909,9.99747726 16.1464909,12.5100101 C16.1464909,13.462708 15.8514236,14.3470084 15.3470404,15.0778056 L20.4607212,15.0778056 Z M20.8579438,15.4403783 L20.8579438,15.8694723 L20.4414498,15.8694723 L14.4909391,22 L2,22 L2,9.5950556 L8.64338347,3 L21,3 L21,15.2940246 L20.8579438,15.4403783 Z M11.5626212,16.5241364 C13.796387,16.5241364 15.6072121,14.7269509 15.6072121,12.5100101 C15.6072121,10.2930694 13.796387,8.4958838 11.5626212,8.4958838 C9.32885526,8.4958838 7.51803021,10.2930694 7.51803021,12.5100101 C7.51803021,14.7269509 9.32885526,16.5241364 11.5626212,16.5241364 Z"
      fill={props.color}
    />
  </svg>
)

Icon3dMode.defaultProps = {
  color: 'currentcolor',
  size: '1em'
}

export default Icon3dMode
