import React from 'react'

import IconRemoveSwatch from '../../../common/icons/icon-remove-swatch'
import IconPlus from '../../../common/icons/icon-plus'
import Grid from '../../../common/grid/grid'
import styled from 'styled-components'
import Trigger from '../../../common/modal-redux-trigger'

import { PanelSectionHeader } from '../../../common/panels/panel-section'
import Swatch from './swatch'
import ProgressRadial from '../../../common/progress-radial'

const progressSteps = {
  Initial: 0,
  Queued: 1,
  Rendering: 2,
  Completed: 3,
  Failed: 3
}

const getProgressPercentage = (jobs) => {
  return (jobs.reduce((acc, job) => {
    const step = progressSteps[job.deadlineStatus || job.status] || 0
    return acc + step
  }, 0) / (jobs.length * 3) * 100)
}

const Pattern = styled(Swatch)`
  background: ${props => `url(${props.thumbnailSrc})`};
  background-size: cover;
  background-position: center center;
  cursor: pointer;
`

class PatternList extends React.Component {
  componentWillUnmount () {
    this.props.closeModal({ type: 'patterns', name: 'create' })
  }

  render () {
    const {
      patterns,
      incompleteJobs,
      onRemove,
      onApply
    } = this.props

    const rows = ['auto', 64, 64, 'auto', '1fr']

    return (
      <Grid
        className='py2 bc-gray-light border-right overflow-hidden'
        rows={rows}
        gridGap={0}
      >
        <div className='flex justify-center pb2'>
          <PanelSectionHeader noPadding title='Pattern' />
        </div>

        <div className='flex justify-center' style={{ paddingBottom: 0 }}>
          <div
            className='pointer'
            title='Remove pattern'
            onClick={onRemove}
          >
            <IconRemoveSwatch
              className='circle bg-gray-light-hover'
              strokeWidth={0.23}
              width={50}
              height={50}
            />
          </div>
        </div>

        <Trigger
          type={'patterns'}
          name={'create'}
        >
          {({ onTrigger }) => (
            <div
              className='flex justify-center items-center border mx2 bg-gray-light-hover pointer'
              style={{
                width: 46,
                height: 46,
                borderRadius: '50%'
              }}
              onClick={onTrigger}
            >
              <IconPlus size={24} />
            </div>
          )}
        </Trigger>

        <div className='px2 overflow-auto'>
          <Grid columns={['1fr']}>
            {incompleteJobs.map((jobs) => (
              <div key={jobs[0].id}>
                <ProgressRadial
                  hideLabel
                  strokeWidth={14}
                  percentage={getProgressPercentage(jobs)}
                />
              </div>
            ))}
            {patterns.map((pattern) => (
              <Pattern
                key={pattern.id}
                onClick={() => onApply(pattern.id)}
                {...pattern}
              />
            ))}
          </Grid>
        </div>
      </Grid>
    )
  }
}

export default PatternList
