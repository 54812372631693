import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import * as fromColors from '../../../stores/ducks/colors'
import * as fromProjects from '../../../stores/ducks/projects'
import * as fromProjectSelectors from '../../../stores/ducks/projects/selectors'
import * as fromColorsSelectors from '../../../stores/ducks/colors/selectors'

import ColorCard from '../../common/color-card'
import ColorModal from './ColorModal'
import type { SearchableColor } from './SearchableColor'
import AutoFill from '../../common/grid/AutoFill'
import Pill from '../../common/Pill'
import { StarSelectIcon, Wrapper } from '../../visualizer/panels/material-and-color/Color'

function ProjectColorsView () {
  const dispatch = useDispatch()
  const { colors } = useSelector(fromColorsSelectors.selectProjectColors)
  const searchFilter = useSelector(fromColorsSelectors.selectSearchFilter)
  const colorsPage = useSelector(fromColorsSelectors.selectColorsPage)
  const markedColorIds = useSelector(fromProjectSelectors.getMarkedColors)
  const projectId = useSelector(fromProjectSelectors.getCurrentId)
  const viewColorModalOpen = useSelector(fromColorsSelectors.selectViewColorModalOpen)
  const [selectedColor, setSelectedColor] = React.useState<SearchableColor | null>(null)
  const [selected, setSelected] = useState(false)
  const [markedColors, updateMarkedColors] = React.useState<string[]>(markedColorIds)
  const [colorsArray, setColorsArray] = useState<SearchableColor[] | undefined>(undefined)
  const [pages, setPages] = useState<Number>(1)

  const ITEMS_PER_PAGE = window.innerWidth > 1920 ? 160 : 120

  // To avoid execute props.update on first render
  const firstUpdate = useRef(true)
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }
    const data = {
      id: projectId,
      markedColors: markedColors
    }

    dispatch(fromProjects.updateFavorites(data))
  }, [markedColors])

  useEffect(() => {
    dispatch(fromColors.setSearchFilter('standard'))
  }, [])

  useEffect(() => {
    const page = pages < colorsPage ? 1 : colorsPage
    setPages(Math.ceil(colors.length / ITEMS_PER_PAGE))
    pagination(page, colors, ITEMS_PER_PAGE)
  }, [colors, colorsPage, searchFilter])

  function pagination (page:number, items:any, imagesPerPage:number) {
    dispatch(fromColors.setPage(page))
    setColorsArray(items.slice((page - 1) * imagesPerPage, (page * imagesPerPage)))
  }

  const toggleMarkColors = (id: string) => {
    if (markedColors.includes(id)) {
      updateMarkedColors(markedColors.filter(i => i !== id))
    } else {
      updateMarkedColors([...markedColors, id])
    }
  }

  return (
    <div data-testid='color-container' className='overflow-y-auto pl1'>
      <AutoFill
        width={185}
      >
        {colorsArray && colorsArray.map(color => (
          <Wrapper key={color.id} data-testid='color-wrapper' >
            <ColorCard
              {...color}
              onClick={() => {
                setSelectedColor(color)
                dispatch(fromColors.setViewColorModalOpen(true))
              }}
              isPrototypeFabricColor={color.isPrototypeFabricColor}
            />
            <StarSelectIcon
              onClick={() => {
                setSelected(!selected)
                toggleMarkColors(color.id)
              }}
              selected={!!markedColorIds.includes(color.id)} />
          </Wrapper>
        ))}
      </AutoFill>

      {pages > 1 && (
        <div className="width-100 flex flex-wrap justify-center p2">
          {[...Array(pages)].map((unused, i) =>
            <Pill style={{ margin: '2px' }} key={i} active={colorsPage === i + 1} onClick={() => { dispatch(fromColors.setPage(i + 1)) }}>{i + 1}</Pill>
          )}
        </div>
      )}

      {selectedColor && (
        <ColorModal
          isOpen={viewColorModalOpen}
          onRequestClose={() => dispatch(fromColors.setViewColorModalOpen(false))}
          selectedColor={selectedColor}
          width={600}
        />
      )}
    </div>
  )
}

export default ProjectColorsView
