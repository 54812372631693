import paramify from 'paramify'
import _get from 'lodash/get'

export const match = (pathname) => {
  const _match = paramify(window.location.pathname)
  const didMatch = _match(pathname)
  if (_match.params) { match.params = _match.params }
  match.getParam = (param) => _get(match, ['params', param])
  return didMatch
}
