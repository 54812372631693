import { combineReducers } from 'redux'

import settings from './settings'
import vive from './vive'
import ui, { dispose as disposeUI } from './ui'
import viewer, { disposeAll as disposeViewer } from './viewer'
import files, { dispose as disposeFiles } from './files'
import { dispose as disposeMaterials } from '../materials'
import camera, { dispose as disposeCamera } from './camera'
import imageTemplates, { dispose as disposeImageTemplates } from './imageTemplates'
import { disposeLights } from '../templates/lights'
import triplanar, { dispose as disposeTriplanarTool } from './triplanar'
import customHome, { dispose as disposeCustomHome } from './custom-home'

export const dispose = () => (dispatch) => {
  dispatch(disposeUI())
  dispatch(disposeViewer())
  dispatch(disposeFiles())
  dispatch(disposeMaterials())
  dispatch(disposeCamera())
  dispatch(disposeImageTemplates())
  disposeLights()
  dispatch(disposeTriplanarTool())
  dispose(disposeCustomHome())
}

export default combineReducers({
  files,
  settings,
  ui,
  camera,
  viewer,
  vive,
  triplanar,
  imageTemplates,
  customHome
})
