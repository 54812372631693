import { EventEmitter } from 'events'
import { omit } from 'lodash'

const WILDCARD = '*'

export type StateData = { [key: string]: any }

type Handler = {
  from: string | string[]
  to: string
  condition: (data0: StateData, data1: StateData) => boolean
  enter?: (nextState: string, nextData: StateData, currentState: string, currentData: StateData) => void
  leave?: (nextState: string, nextData: StateData, currentState: string, currentData: StateData) => void
  tick?: (data: StateData) => void
  // eslint-disable-next-line no-use-before-define
  substate?: FiniteStateMachine
}

class FiniteStateMachine extends EventEmitter {
  isSubstate = false

  _state: string
  _stateData: StateData
  _state0: string
  _stateData0: StateData
  _handlers: Map<string, { next: Omit<Handler, 'from'>[], leave?: Map<string, Handler['leave']>, tick?: Handler['tick'], substate?: FiniteStateMachine }>
  changed = false

  constructor ({
    initialState,
    initialStateData,
    handlers
  }: {
    initialState: string
    initialStateData: StateData
    handlers: Handler[]
  }) {
    super()

    this._state = initialState
    this._stateData = initialStateData
    this._state0 = initialState
    this._stateData0 = initialStateData
    this._handlers = mapHandlers(handlers)
  }

  get state () {
    return this._state
  }

  get stateData () {
    return this._stateData
  }

  set state (_) {
    throw new Error('Action not allowed')
  }

  set stateData (_) {
    throw new Error('Action not allowed')
  }

  get substate () {
    const handler = this._handlers.get(this._state)
    if (handler?.substate) {
      return handler.substate.state
    }
    return null
  }

  get substateFsm () {
    const handler = this._handlers.get(this._state)
    return handler?.substate
  }

  reset () {
    this._state = this._state0
    this._stateData = this._stateData0

    this._handlers.forEach(h => {
      if (h.substate) h.substate.reset()
    })
  }

  change (incomingData: { [key: string]: any }, cb = () => {}) {
    const currState = this._state
    const currStateData = this._stateData
    const currHandler = this._handlers.get(this._state)

    const nextStateData = { ...currStateData, ...incomingData }

    let changed = false
    for (const key in incomingData) {
      if (typeof currStateData[key] === 'undefined' || currStateData[key] !== incomingData[key]) {
        changed = true
        break
      }
    }

    this._stateData = nextStateData

    let currSubstate = null
    const currSubstateFsm = this.substateFsm

    if (currSubstateFsm) {
      currSubstate = currSubstateFsm.state
      currSubstateFsm.change(incomingData)
    }

    let nextState = null
    let nextHandler = null

    const nextHandlers = currHandler?.next ?? []

    for (const next of nextHandlers) {
      if (next.condition(nextStateData, currStateData)) {
        nextHandler = next
        nextState = next.to
        break
      }
    }

    // no state change
    if (!nextState || nextState === currState) {
      currHandler?.tick && currHandler.tick(nextStateData)
    } else if (nextHandler) {
      // state was changed
      nextHandler.enter && nextHandler.enter(nextState, nextStateData, currState, currStateData)
      nextHandler.tick && nextHandler.tick(nextStateData)

      if (currHandler?.leave && currHandler.leave.has(nextState)) {
        const leave = currHandler.leave.get(nextState)
        if (leave) leave(nextState, nextStateData, currState, currStateData)
      }

      if (currSubstateFsm && currSubstate) {
        const substateHandler = currSubstateFsm._handlers.get(currSubstate)
        if (substateHandler && substateHandler.leave && substateHandler.leave.has(nextState)) {
          const leave = substateHandler.leave.get(nextState)
          if (leave) leave(nextState, nextStateData, currState, currStateData)
        }
      }

      this._state = nextState
    }

    this.changed = changed || !!(this.substateFsm && this.substateFsm.state !== currSubstate)

    if (this.changed && !this.isSubstate) {
      // TODO: Only collects data from substates one level deep.
      this.emit('change', {
        from: currState,
        to: nextState || currState,
        fromSubstate: currSubstate,
        toSubstate: this.substateFsm && this.substateFsm.state,
        stateData: nextStateData,
        prevStateData: currStateData
      })
    }

    return this._state
  }
}

export function mapHandlers (handlers: Handler[]) {
  // validate
  handlers.forEach(h => {
    if (!h.condition) {
      throw new Error('All handlers must provide a condition function.')
    }

    if (!h.to) {
      throw new Error('All handlers must provide a to state.')
    }

    if (!h.from) {
      throw new Error('All handlers must provide a from state.')
    }
  })

  // collect all states
  const allStates: string[] = []
  handlers.forEach(h => {
    ;(typeof h.from === 'string' ? [h.from] : h.from)
      .concat(h.to)
      .forEach(s => {
        if (s !== WILDCARD && !allStates.includes(s)) {
          allStates.push(s)
        }
      })
  })

  handlers = handlers.map(h => {
    h.from = h.from === WILDCARD
      ? allStates.filter(s => s !== h.to)
      : h.from
    return h
  })

  // collect fns
  const fnDict = handlers.reduce((acc: Map<string, {
    tick?: Handler['tick']
    substate?: FiniteStateMachine
    leave?: Map<string, Handler['leave']>
  }>, h) => {
    if (!acc.has(h.to)) {
      acc.set(h.to, {})
    }

    const obj = acc.get(h.to)

    if (h.tick) {
      obj!.tick = h.tick
    }

    if (h.substate) {
      obj!.substate = h.substate
      obj!.substate.isSubstate = true
    }

    if (h.leave) {
      if (!obj!.leave) {
        obj!.leave = new Map<string, Handler['leave']>()
      }
      ;(typeof h.from === 'string' ? [h.from] : h.from)
        .forEach(f => obj!.leave!.set(f, h.leave))
    }

    return acc.set(h.to, obj!)
  }, new Map())

  // construct handlers dict
  const dict = handlers.reduce((acc: Map<string, {
    next: Omit<Handler, 'from'>[],
    leave?: Map<string, Handler['leave']>,
    tick?: Handler['tick'],
    substate?: FiniteStateMachine
  }>, h) => {
    if (!h.from) return acc
    ;(typeof h.from === 'string' ? [h.from] : h.from).forEach(f => {
      if (!acc.has(f)) {
        acc.set(f, { next: [] })
      }

      const obj = acc.get(f)
      const fns = fnDict.get(f)

      if (fns && fns.leave) {
        obj!.leave = fns.leave
      }

      if (fns && fns.tick) {
        obj!.tick = fns.tick
      }

      if (fns && fns.substate) {
        obj!.substate = fns.substate
      }

      obj!.next = [...obj!.next, omit(h, 'from')]

      acc.set(f, obj!)
    })
    return acc
  }, new Map())

  allStates.forEach((state: string) => {
    if (!dict.has(state)) {
      throw new Error(`Missing handler for state: ${state}`)
    }
  })

  return dict
}

export default FiniteStateMachine
