import React from 'react'
import { useSelector } from 'react-redux'
import _isNil from 'lodash/isNil'
import zIndexHandler from '../../enhancers/zindex'

import IconCamera from '../../common/icons/icon-camera'
import IconLayers from '../../common/icons/icon-layers'
import IconCube from '../../common/icons/icon-cube'
import IconMaterialGroup from '../../common/icons/icon-material-group'
import IconScene from '../../common/icons/icon-scene'
import IconUndo from '../../common/icons/icon-undo'
import IconRedo from '../../common/icons/icon-redo'
import IconRenders from '../../common/icons/icon-renders'
import { IoMdStats as IconProductConcept } from 'react-icons/io'
import PanelContainer from '../../common/panels/panel-container'
import PanelButton from '../../common/panels/panel-button'

// Panels
import CameraPanel from './camera/CameraPanel'
import ScenePanel from './scene/ScenePanel'
import AppearanceGroupsPanel from './appearance-groups/appearance-groups-panel'
import DemocraticDesignPanel from './product-concept-analysis/ProductConceptAnalysisPanel'
import GeometryPanel from './geometry/geometry-panel'
import RendersPanel from './renders/renders-panel'
import StylesPanel from './styles/styles-panel'
import VrPanel from './vr/vr-panel'

// Buttons
import WrapperUndoRedo from './buttons/wrapper-undo-redo'

// Selectors
import * as fromThreeviewerSelectors from '../../../stores/ducks/threeviewer/selectors'
import { getIsFeatureActive } from '../../../stores/ducks/threeviewer/selectors'

// Constants
import { PROJECT_TYPES, FEATURES } from '../../../constants'

const tabs = [
  {
    icon: (<IconScene width={28} height={28} />),
    title: 'Scene',
    content: <ScenePanel />,
    shouldDisplay: ({ projectType }) => projectType === PROJECT_TYPES.DEFAULT
  },
  {
    icon: (<IconCamera width={28} height={28} />),
    title: 'Camera',
    content: <CameraPanel />,
    shouldDisplay: ({ projectType }) => projectType === PROJECT_TYPES.DEFAULT
  },
  {
    icon: (<IconCube width={28} height={28} />),
    title: 'Geometry',
    keepMounted: true,
    grid: true,
    content: <GeometryPanel />,
    shouldDisplay: ({ projectType }) => projectType === PROJECT_TYPES.DEFAULT
  },
  {
    icon: (<IconMaterialGroup width={28} height={28} />),
    title: 'Appearance Groups',
    grid: true,
    content: <AppearanceGroupsPanel />,
    shouldDisplay: ({ projectType }) => projectType === PROJECT_TYPES.DEFAULT
  },
  {
    icon: (<IconLayers size={28} />),
    title: 'Styles',
    grid: true,
    content: <StylesPanel />,
    shouldDisplay: ({ projectType, isMaster }) => isMaster && projectType !== PROJECT_TYPES.DEFAULT
  },
  {
    icon: (<IconRenders width={28} height={28} />),
    title: 'Images',
    grid: true,
    content: <RendersPanel />,
    shouldDisplay: () => true
  },
  {
    icon: (<IconCamera width={28} height={28} />),
    title: 'VR',
    content: <VrPanel />,
    shouldDisplay: ({ projectType, vrEnabled }) => vrEnabled && projectType === PROJECT_TYPES.DEFAULT
  },
  {
    icon: (<IconProductConcept size='28' />),
    title: 'Product Concept Analysis',
    grid: true,
    content: <DemocraticDesignPanel />,
    shouldDisplay: ({ projectType, PCAActive }) => projectType === PROJECT_TYPES.DEFAULT && PCAActive
  }
]

const buttons = [
  <WrapperUndoRedo key='undo-redo'>
    {({
      undo,
      redo,
      canUndo,
      canRedo
    }) => ([
      <PanelButton
        key='undo'
        inverted
        onClick={undo}
        disabled={!canUndo}
        icon={(<IconUndo width={26} height={26} />)}
        title='Undo'
      />,
      <PanelButton
        key='redo'
        inverted
        onClick={redo}
        disabled={!canRedo}
        icon={(<IconRedo width={26} height={26} />)}
        title='Redo'
      />
    ])}
  </WrapperUndoRedo>
]

const LeftPanel = ({
  isTop, // from zIndexHandler
  setAsTop, // from zIndexHandler
  defaultIndex,
  onBeforeNavigate,
  isMaster,
  projectType
}) => {
  const vrEnabled = useSelector(fromThreeviewerSelectors.getIsViveAvailable)
  const defaultIndexOverride = useSelector(state => _isNil(state.threeviewer.ui.leftPanelIndex)
    ? defaultIndex
    : state.threeviewer.ui.leftPanelIndex
  )
  const PCAActive = useSelector(state => getIsFeatureActive(state)(FEATURES.PRODUCT_CONCEPT_ANALYSIS, true))

  const getTabs = tabs.filter(tab => tab.shouldDisplay({ projectType, isMaster, vrEnabled, PCAActive }))

  return <PanelContainer
    defaultWidth={344}
    defaultIndex={defaultIndexOverride}
    minWidth={300}
    maxWidth={500}
    className={isTop ? 'z3' : 'z2'}
    position='left'
    onClick={setAsTop}
    tabs={getTabs}
    onBeforeNavigate={onBeforeNavigate}
  >
    {buttons}
  </PanelContainer>
}

export default zIndexHandler(LeftPanel)
