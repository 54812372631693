import { v4 as uuid } from 'uuid'

import _forEach from 'lodash/forEach'
import _get from 'lodash/get'

import * as fromJson from './index'
import * as fromThreeviewerCamera from '../threeviewer/camera'
import * as fromCombinations from '../combinations'
import { fetchForVisualize } from '../combinations/actions/json'
import * as fromMaterials from '../materials'

import * as fromThreeviewerSelectors from '../threeviewer/selectors'

import ROOMSET_INTERACTIONS from './interactions'
import { DEFAULT_ROOMSET_ENVMAP } from '../../../constants'

export const dispose = () => (dispatch, getState) => {
  const state = getState()
  const viewer = fromThreeviewerSelectors.getViewer(state)
  const nodeList = fromThreeviewerSelectors.getNodeList(state)

  _forEach(nodeList, (node) => {
    if (_get(node, 'userData.modelType') === 'roomset') {
      viewer.removeModel(node)
    }
  })

  dispatch(fromThreeviewerCamera.useDefaultCameraAndControls())
}

export const load = (id, { cameraSettings, setCamera, modifications }) => async (dispatch, getState) => {
  try {
    const instanceId = uuid()
    const state = getState()
    const viewer = fromThreeviewerSelectors.getViewer(state)
    const settings = state.threeviewer.settings
    const THREE = viewer.THREE

    viewer.postProcessManager.lut.setLutTable = new THREE.TextureLoader().load('/img/lut/roomset_lut.png')
    viewer.postProcessManager.lut.enabled = true
    viewer.renderer.toneMappingExposure = 1.4

    await dispatch(fromMaterials.loadEnvMap(DEFAULT_ROOMSET_ENVMAP, '.exr'))

    const roomset = await dispatch(fromJson.fetchIfNeeded(id))
    const combination = await dispatch(fetchForVisualize(roomset.combinationId))

    const modifiedParts = new Map()
    Object.values(modifications || {}).forEach(mod => {
      mod.parts.forEach(part => {
        modifiedParts.set(part.partId, part)
      })
    })

    // Needed for navigation.
    // Fix since we have not marked floor models that will not change in 3D Studio Max.
    combination.nodes.forEach(nodeData => {
      const isStaticFloor = (
        _get(nodeData, 'modifications.metadata.type') !== 'floor' &&
        _get(nodeData, 'modifications.metadata.originalName', '').toLowerCase().includes('floor') &&
        _get(nodeData, 'modifications.metadata.static')
      ) || _get(nodeData, 'modifications.metadata.type') === 'static_floor'

      if (isStaticFloor) {
        nodeData.modifications.metadata.static = false
        nodeData.modifications.metadata.type = 'static_floor'
      }

      nodeData.modifications.parts.forEach(part => {
        if (modifiedParts.has(part.partId)) {
          Object.assign(part, modifiedParts.get(part.partId))
        }
      })
    })

    if (setCamera) {
      combination.cameraSettings = {
        ...cameraSettings,
        isPredefined: true
      }
    }

    viewer.cameraManager.change({
      behavior: viewer.CameraManager.BEHAVIORS.ROOMSETS
    })

    await dispatch(fromCombinations.loadCombination({
      instanceId,
      isComplementary: false,
      combination: combination,
      interactions: ROOMSET_INTERACTIONS
    }))

    const intensity = _get(settings, 'roomsetEnvMap.intensity', 1)
    dispatch(fromMaterials.setEnvMapSettings({ intensity: intensity }))

    const placeCoordinate = getPlaceCoordinate(viewer)
    placeCoordinate.z -= 1
    dispatch(fromJson.setPlacementCoordinate(placeCoordinate.toArray()))

    const bb = getBoundingBoxForRoomset(viewer)

    if (bb) {
      viewer.cameraManager.setCameraClampingObjectFromBB(bb, true)

      if (setCamera && !cameraSettings) {
        dispatch(fromThreeviewerCamera.activateFloorplanView(false))
        return {
          moveCamera: false,
          instanceId,
          placeCoordinate
        }
      }
    }

    return { instanceId, placeCoordinate }
  } catch (err) {
    console.error(err)
    throw err
  }
}

const getPlaceCoordinate = (viewer) => {
  const objects = []
  let coord = new viewer.THREE.Vector3()
  viewer.scene.traverse((node) => {
    if (_get(node, 'userData.metadata.type') !== 'floor') return
    objects.push(node)
  })

  if (objects.length) {
    const bb = viewer.viewerUtils.getBoundingBox(objects)
    coord = bb.getCenter(new viewer.THREE.Vector3())
    coord.y = bb.max.y
  }
  return coord
}

const getBoundingBoxForRoomset = (viewer) => {
  const objects = []
  viewer.scene.children.forEach((node) => {
    if (_get(node, 'userData.modelType') !== 'roomset') return

    if (node.userData.isModelRoot) {
      objects.push(node)
    } else {
      node.children.forEach((childNode) => {
        if (childNode.userData.isModelRoot) {
          objects.push(childNode)
        }
      })
    }
  })
  if (objects.length) {
    return viewer.viewerUtils.getBoundingBox(objects)
  }
  return false
}
