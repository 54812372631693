const whiteList = [
  'name',
  'parent',
  'children',
  'position',
  'scale',
  'visible',
  'castShadow',
  'receiveShadow',
  'userData',
  'geometry',
  'material'
]

function drawProperty (target, key, folder, onChange = x => x) {
  if (whiteList.indexOf(key) === -1) {
    return
  }

  const value = target[key]

  const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  const logSelection = {
    select: function () {
      console.log(value)
    }
  }

  const logObject = (o) => {
    return {
      select: function () {
        console.log(o)
      }
    }
  }

  // Draw the ones that works great without any additional settings
  if (
    (value !== undefined && value !== null) &&
    (key === 'name' ||
    key === 'visible' ||
    key === 'castShadow' ||
    key === 'receiveShadow')
  ) {
    folder.add(target, key).listen().onChange(onChange)
  }

  // Console.log actions
  if (key === 'parent' || key === 'children') {
    folder.add(logSelection, 'select').name(`Log: ${capitalize(key)} (${value.type})`)
  }

  if (value && key === 'position') {
    folder.add(value, 'x').name('Position X').step(0.1).listen().onChange(onChange)
    folder.add(value, 'y').name('Position Y').step(0.1).listen().onChange(onChange)
    folder.add(value, 'z').name('Position Z').step(0.1).listen().onChange(onChange)
  }

  if (value && key === 'scale') {
    folder.add(value, 'x').name('Scale X').step(0.01).listen().onChange(onChange)
    folder.add(value, 'y').name('Scale Y').step(0.01).listen().onChange(onChange)
    folder.add(value, 'z').name('Scale Z').step(0.01).listen().onChange(onChange)
  }

  if (key === 'userData') {
    const userDataFolder = folder.addFolder('userData')
    Object.keys(value).forEach((k) => {
      if (value[k] === null || value[k] === undefined || value[k] instanceof Object) {
        userDataFolder.add(logObject(value), 'select').name(k)
        return
      }
      userDataFolder.add(value, k)
    })
  }

  if (key === 'geometry') {
    const userDataFolder = folder.addFolder(`geometry (${value.type})`)
    Object.keys(value).forEach((k) => {
      if (value[k] === null || value[k] === undefined || value[k] instanceof Object) {
        if (!k) {
          return
        }
        userDataFolder.add(logObject(value[k]), 'select').name(`Log: ${k}`).onChange(onChange)
        return
      }
      userDataFolder.add(value, k).onChange(onChange)
    })
  }

  if (key === 'material') {
    const materialFolder = folder.addFolder(`material (${value.type})`)

    Object.keys(value).forEach((k) => {
      if (value[k] === null || value[k] === undefined || value[k] instanceof Object) {
        if (!k) {
          return
        }
        materialFolder.add(logObject(value[k]), 'select').name(`Log: ${k}`).onChange(onChange)
        return
      }
      materialFolder.add(value, k).onChange(onChange)
    })
  }
}

module.exports = drawProperty
