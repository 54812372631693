import _get from 'lodash/get'
import _last from 'lodash/last'
import { ImmutableObject } from 'seamless-immutable'
import { Render } from '../stores/ducks/renders/Render'
import { getImageSrc } from './storage'

function getFile (render: Render | ImmutableObject<Render>) {
  if (!_get(render, 'manifest.files.length', false)) return null
  return render.manifest!.files.find(f => f.name.match(/\.(png|jpe?g)/))
}

export default function getImageDownloadFile (render: Render | ImmutableObject<Render>, title?: string) {
  const file = getFile(render)
  if (!file) return null
  const src = getImageSrc(file, {})
  const ext = _last(src.split('.'))
  const createdAt = render.createdAt.split('T')[0].split(' ').join('_')
  const downloadTitle = title || `image_${createdAt}`
  return {
    key: file.key,
    src: src,
    name: `${downloadTitle}.${ext}`
  }
}
