var _ = require('lodash')
var MODE = 'specialSnapping'
let THREE
let utils

module.exports = {
  addSpecialSnappingHandler (params) {
    THREE = this.app.THREE
    utils = this.app.viewerUtils
    var specialSnappingEvent = params.event || this.TriggerClicked
    var sumVec = null

    this.on(this.PadTouched, () => {
      sumVec = new THREE.Vector2()
    })

    this.on(this.PadUntouched, () => {
      if (this.mode && this.mode !== MODE) return

      if (sumVec.length() < 0.9) {
        sumVec = null
        return
      }

      // rotate around Y
      if (Math.abs(sumVec.x) > Math.abs(sumVec.y)) {
        // positive rotation
        if (sumVec.x > 0) {
          this._rotateSnappingCloneAroundAxis(Math.PI / 2, 'rotateY')
        } else {
          this._rotateSnappingCloneAroundAxis(-Math.PI / 2, 'rotateY')
        }
      } else {
        // positive rotation around z
        if (sumVec.y > 0) {
          // this._rotateSnappingCloneAroundAxis(Math.PI / 2, 'rotateZ')
        } else {
          // this._rotateSnappingCloneAroundAxis(-Math.PI / 2, 'rotateZ')
        }
      }

      sumVec = null
    })

    this.on(this.PadDragged, (dx, dy) => {
      sumVec.add(new THREE.Vector2(dx, dy))
    })

    this.on(specialSnappingEvent, () => {
      var objectToMove = _.get(this, 'snappingInfo.objectToMove')

      var vrSnapTargets = _(this.app)
        .chain()
        .get('objectTracker.interactions.vrSnapTargets', {})
        .map((val, key) => { return val })
        .value()

      if (!this.snappingInfo) {
        this.startSnapping()
        objectToMove = _.get(this, 'snappingInfo.objectToMove')

        if (objectToMove) {
          this.snappingInfo.snappingClone = this.snappingInfo.objectToMove.clone()
          this.snappingInfo.snappingCloneParent = new THREE.Object3D()
          this.snappingInfo.savedMaterials = {}

          var diagonal = this.snappingInfo.BBLS.min.distanceTo(this.snappingInfo.BBLS.max)

          this.snappingInfo.snappingCloneParent.scale.multiplyScalar(0.1 / diagonal)

          objectToMove.traverse((child) => {
            if (child.material) {
              this.snappingInfo.savedMaterials[child.uuid] = child.material.clone()
              child.material = child.material.clone()
              child.material.transparent = true
              child.material.opacity = 0.5
              child.material.color.set(0x009900)
            }
          })

          vrSnapTargets.forEach((target) => {
            target.traverse((child) => {
              if (_.get(child, 'material.uniforms.visible')) {
                child.material.uniforms.visible.value = 1
              }
            })
          })

          this._update.snapping = () => {
            var snappingClone = this.snappingInfo.snappingClone
            var snappingCloneParent = this.snappingInfo.snappingCloneParent
            var newOrientation = this.snappingPos()

            if (newOrientation) {
              if (snappingClone.parent !== this.app.scene) {
                snappingCloneParent.remove(snappingClone)
                this.remove(snappingCloneParent)
                this.app.scene.add(snappingClone)
                snappingClone.position.copy(newOrientation.position)
              }

              snappingClone.position.lerp(newOrientation.position, 0.2)
              snappingClone.rotation.copy(newOrientation.rotation)
            } else if (snappingClone.parent === this.app.scene) {
              this.app.scene.remove(snappingClone)
              this.add(snappingCloneParent)
              snappingCloneParent.add(snappingClone)
              snappingClone.setRotationFromQuaternion(new THREE.Quaternion())
              utils.centerObject(snappingClone)
              snappingCloneParent.position.set(0, 0, -0.07)
            } else if (!snappingClone.parent) {
              this.app.scene.add(snappingClone)
              utils.centerObject(snappingClone)
              this.app.scene.remove(snappingClone)
              this.add(snappingCloneParent)
              snappingCloneParent.add(snappingClone)
              snappingCloneParent.position.set(0, 0, -0.07)
            }

            if (snappingClone.parent === snappingCloneParent) {
              var objectToMoveQ = objectToMove.getWorldQuaternion()
              var snapParentQ = snappingCloneParent.parent.getWorldQuaternion()
              var Q = snapParentQ.conjugate()
              Q.multiply(objectToMoveQ)
              snappingCloneParent.setRotationFromQuaternion(Q)
            }
          }
        }
      } else {
        var newOrientation = this.snappingPos()
        if (newOrientation) {
          objectToMove.position.copy(this.snappingInfo.snappingClone.getWorldPosition())
          objectToMove.rotation.copy(newOrientation.rotation)
          objectToMove.traverse((child) => {
            var oldMaterial = _.get(this, ['snappingInfo', 'savedMaterials', child.uuid], false)
            if (oldMaterial) {
              child.material = oldMaterial
            }
          })

          // Deactivate
          delete this._update.snapping
          this.app.scene.remove(this.snappingInfo.snappingClone)
          this.snappingInfo.snappingCloneParent.remove(this.snappingInfo.snappingClone)
          this.remove(this.snappingInfo.snappingCloneParent)
          this.snappingInfo = null
          this.mode = null

          vrSnapTargets.forEach((target) => {
            target.traverse((child) => {
              if (_.get(child, 'material.uniforms.visible')) {
                child.material.uniforms.visible.value = 0
              }
            })
          })
        }
      }
    })
  },

  snappingPos () {
    var raycaster = this.getRaycaster()

    var vrSnapTargets = _(this.app)
      .chain()
      .get('objectTracker.interactions.vrSnapTargets', {})
      .map((val, key) => { return val })
      .value()

    var objectToMove = _.get(this, 'snappingInfo.objectToMove', false)

    var intersections = raycaster.intersectObjects(vrSnapTargets)
    if (intersections.length > 0 && objectToMove) {
      var intersection = intersections[0]
      var intersectionObject = intersection.object

      var point = intersection.point

      var intersectionNormalLS = intersection.face.normal.clone()
      var intersectionNormalWS = utils.normalLocalToWorld(intersectionNormalLS, intersectionObject)

      var BBLS = this.snappingInfo.BBLS

      var sizeLS = BBLS.getSize(new THREE.Vector3())

      var centerLS = BBLS.getCenter(new THREE.Vector3())
      var centerWS = centerLS.clone()
      objectToMove.parent.localToWorld(centerWS)

      var plane = new THREE.Plane(intersectionNormalWS, -point.distanceTo(new THREE.Vector3()))
      var sortedBBPoints = utils.getBBPoints(BBLS).sort((a, b) => {
        var distanceA = plane.distanceToPoint(objectToMove.parent.localToWorld(a.clone()))
        var distanceB = plane.distanceToPoint(objectToMove.parent.localToWorld(b.clone()))
        return distanceA - distanceB
      })

      var p1 = sortedBBPoints[0]
      var p2 = sortedBBPoints[1]
      var p3 = sortedBBPoints[2]

      // Get normal of closest bounding box plane
      var objNormalLS = new THREE.Vector3().subVectors(p3, p1).cross(new THREE.Vector3().subVectors(p2, p1)).normalize()

      var Q = new THREE.Quaternion()

      var objDirLS = _(objNormalLS).pick('x', 'y', 'z').toPairs().maxBy((val) => Math.abs(val[1]))
      var v1 = new THREE.Vector3()
      v1[objDirLS[0]] = (sizeLS[objDirLS[0]] / 2) * Math.sign(objDirLS[1])

      var bbEdgePointLS = centerLS.clone().add(v1)

      var { rotation, position } = utils.rotateAroundPivot(Q, this.app.scene, objectToMove, centerWS)
      var bbEdgePointWS = bbEdgePointLS.clone()
      objectToMove.parent.localToWorld(bbEdgePointWS)

      var translationWS = new THREE.Vector3().subVectors(point, bbEdgePointWS)
      position.add(objectToMove.parent.worldToLocal(translationWS.clone()))

      return { rotation, position }
    }
  },

  _rotateSnappingCloneAroundAxis (radians, axisFunction) {
    if (!this.snappingInfo || !this.snappingInfo.snappingClone) {
      return
    }

    var objectToMove = _.get(this, 'snappingInfo.objectToMove')
    utils.rotateObjectAroundAxis(objectToMove, radians, axisFunction)
    this.snappingInfo.BBLS = utils.getWorldBoundFromMeshBoundingBoxes(objectToMove)
  },

  startSnapping () {
    if (this.mode && this.mode !== MODE) {
      return
    }

    var vrSnapSources = _(this.app)
      .chain()
      .get('objectTracker.interactions.vrSnapSources', {})
      .map((val, key) => val)
      .filter('visible')
      .value()

    var raycaster = this.getRaycaster()
    var intersects = raycaster.intersectObjects(vrSnapSources)

    if (intersects.length > 0) {
      var rootNode = utils.findRootNode(intersects[0].object)
      if (!rootNode.userData.vrNoSourceSnap) {
        this.mode = MODE

        this.snappingInfo = {
          objectToMove: rootNode,
          BBLS: utils.getWorldBoundFromMeshBoundingBoxes(rootNode)
        }
      }
    }
  }
}
