import React from 'react'
import path from 'path'
import _map from 'lodash/map'
import { hasMaxFile } from '../../../stores/ducks/roomsets-admin/utils'

export const Files = ({
  files,
  uploadFile = 'none'
}) => {
  const hasValidMaxFile = hasMaxFile(files)
  const renderMaxFile = hasValidMaxFile ? (
    <div><b>.max file</b>: {path.basename(hasValidMaxFile.path)}</div>
  ) : (
    <div className='c-error bold'>Upload must contain one .max file!</div>
  )

  return (
    <div className='line-height'>
      <div>
        <b>Uploaded file</b>: {uploadFile}
      </div>
      {!!files && (renderMaxFile)}

      {!!files && (
        <div>
          <b>{files.length} files:</b>
          <div className='truncate' title={_map(files, (file) => path.basename(file.path))}>
            {_map(files, (file) => path.basename(file.path)).join(', ')}
          </div>
        </div>
      )}
    </div>
  )
}
