var THREE = require('three')

/**
 * @author Jam3 / https://github.com/Jam3/threejs-post-process-example
 * @author mattdesl / https://github.com/mattdesl/glsl-lut
 *
 */

const lutShader = {

  uniforms: {
    tDiffuse: { type: 't', value: null },
    tLookup: { type: 't', value: null },
    uvScale: { value: 1.0 }
  },

  vertexShader: `
    varying vec2 vUv;
    uniform float uvScale;

    void main() {
      vUv = uv * uvScale;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
  `,

  fragmentShader: `
    precision mediump float;
    #define LUT_FLIP_Y
    varying vec2 vUv;
    uniform sampler2D tDiffuse;
    uniform sampler2D tLookup;

    vec4 lut(in vec4 textureColor, in sampler2D lookupTable) {
      #ifndef LUT_NO_CLAMP
      textureColor = clamp(textureColor, 0.0, 1.0);
      #endif

      mediump float blueColor = textureColor.b * 63.0;

      mediump vec2 quad1;
      quad1.y = floor(floor(blueColor) / 8.0);
      quad1.x = floor(blueColor) - (quad1.y * 8.0);

      mediump vec2 quad2;
      quad2.y = floor(ceil(blueColor) / 8.0);
      quad2.x = ceil(blueColor) - (quad2.y * 8.0);

      highp vec2 texPos1;
      texPos1.x = (quad1.x * 0.125) + 0.5/512.0 + ((0.125 - 1.0/512.0) * textureColor.r);
      texPos1.y = (quad1.y * 0.125) + 0.5/512.0 + ((0.125 - 1.0/512.0) * textureColor.g);

      #ifdef LUT_FLIP_Y
      texPos1.y = 1.0-texPos1.y;
      #endif

      highp vec2 texPos2;
      texPos2.x = (quad2.x * 0.125) + 0.5/512.0 + ((0.125 - 1.0/512.0) * textureColor.r);
      texPos2.y = (quad2.y * 0.125) + 0.5/512.0 + ((0.125 - 1.0/512.0) * textureColor.g);

      #ifdef LUT_FLIP_Y
      texPos2.y = 1.0-texPos2.y;
      #endif

      lowp vec4 newColor1 = texture2D(lookupTable, texPos1);
      lowp vec4 newColor2 = texture2D(lookupTable, texPos2);

      lowp vec4 newColor = mix(newColor1, newColor2, fract(blueColor));
      return newColor;
    }

    void main () {
      gl_FragColor = texture2D(tDiffuse, vUv);
      gl_FragColor.rgb = lut(gl_FragColor, tLookup).rgb;
    }
  `
}

class LUTPostProcess {
  constructor () {
    this.pass = new THREE.ShaderPass(lutShader)
    this.enabled = false
    this.validLutTable = false
  }

  set setLutTable (value) {
    if (value) {
      value.generateMipmaps = false
      value.minFilter = THREE.LinearFilter
      this.pass.uniforms.tLookup.value = value
      this.validLutTable = true
    } else {
      this.validLutTable = false
    }
  }

  setUVScale (uvScale) {
    this.pass.uniforms.uvScale.value = uvScale
  }

  render (renderer, buffers) {
    if (!this.validLutTable) {
      return
    }
    var input = buffers.input
    var output = buffers.output
    this.pass.render(renderer, output, input, 0, false)

    // Swap
    buffers.input = output
    buffers.output = input
  }
}

module.exports = LUTPostProcess
