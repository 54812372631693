import React from 'react'
import * as SideNav from 'react-sidenav'

import Grid from '../common/grid/grid'
import colors from '../../../css/colors'
import styled from 'styled-components'

const ItemWrapper = styled.div<{ selected: boolean }>`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  color: ${colors.grayDark};
  transition: all 0.3s ease 0s;
  padding: 8px 12px;
  background: ${(props) => props.selected ? colors.grayLighter : ''};
  &:hover {
    background: ${colors.white55};
  }
`

const Text = styled.div`
  margin-left: 12px;
`

const NavContext = (SideNav as unknown as {
  NavContext: React.Context<{
  id: string
  isLeaf: boolean
  level: number
  pathId: string
  selected: boolean
}> }).NavContext

type NavItemProps = {
  text: string
  icon: any
}

function Item (props: NavItemProps) {
  const context = React.useContext(NavContext)
  return (
    <ItemWrapper selected={context.selected}>
      <Grid
        columns={[25, '60%']}
        gridGap={6}
        className='width-100 flex items-center'
        style={{
          marginTop: 2,
          marginBottom: 2,
          marginLeft: 6
        }}
      >
        {props.icon}
        <Text>
          {props.text}
        </Text>
      </Grid>
    </ItemWrapper>
  )
}

type Props = {
  onSelection: (id: string) => void
  defaultSelectedPath: string
  navItems: { id: string, icon: any, text: string }[]
}

function SideNavLeft (props: Props) {
  return (
    <div style={{ width: 250 }} className='bg-gray-light'>
      <SideNav.SideNav
        defaultSelectedPath={props.defaultSelectedPath}
        onSelection={props.onSelection}
      >
        <div className='p2 pt3 bold f6'>Admin settings</div>

        {props.navItems.map((navItem) => (
          <SideNav.Nav id={navItem.id} key={navItem.id}>
            <Item
              icon={navItem.icon}
              text={navItem.text}
            />
          </SideNav.Nav>
        ))}

      </SideNav.SideNav>
    </div>
  )
}

export default SideNavLeft
