import React, { Component } from 'react'
import PropTypes from 'prop-types'
import autoBind from 'react-autobind'

import navigate from '../../utils/navigate'

// JSX Components : Common
import Modal from './modal'
import FormRemove from './form/form-remove'

const noop = () => {}

class Confirm extends Component {
  constructor (props) {
    super(props)

    this.state = { isOpen: false, confirmCallback: noop }

    autoBind(this)
    if (props.onPopstate) {
      window.removeEventListener('popstate', navigate.onPopstate)
      window.addEventListener('popstate', this.handlePopstate, false)
    }
  }

  componentWillUnmount () {
    if (this.props.onPopstate) {
      window.addEventListener('popstate', navigate.onPopstate, false)
      window.removeEventListener('popstate', this.handlePopstate)
    }
  }

  handlePopstate (event) {
    event.preventDefault()
    window.history.go(1)

    this.setState({
      isOpen: true,
      confirmCallback: this.props.onPopstate
    })
  }

  handleOpen (confirmCallback) {
    this.setState({
      isOpen: true,
      confirmCallback
    })
  }

  handleConfirm () {
    this.setState({ isOpen: false }, this.state.confirmCallback)
  }

  handleCancel () {
    this.setState({
      isOpen: false,
      confirmCallback: noop
    })
  }

  render () {
    const { children, title, buttonText, description } = this.props

    return (
      <div>
        {children({ onOpen: this.handleOpen })}
        <Modal
          isOpen={this.state.isOpen}
          onRequestClose={this.handleCancel}
          width={600}
        >
          <FormRemove
            formTitle={title}
            formDescription={description}
            onConfirm={this.handleConfirm}
            onCancel={this.handleCancel}
            buttonText={buttonText}
          />
        </Modal>
      </div>
    )
  }
}

Confirm.propTypes = {
  children: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  description: PropTypes.string,
  onPopstate: PropTypes.func
}

export default Confirm
