import * as THREE from 'three'
var _get = require('lodash/get')

export default class ViewerUtils {
  constructor (assetManager) {
    this.assetManager = assetManager
  }

  // Transforms a normal vector from local to world space
  normalLocalToWorld (normal, obj) {
    var normalMatrix = new THREE.Matrix3().getNormalMatrix(obj.matrixWorld)
    return normal.clone().applyMatrix3(normalMatrix).normalize()
  }

  rotateAroundPivot (Q, scene, source, pivot) {
    // Create a pivot point object with sourcepoint as position
    var pivotObj = new THREE.Object3D()
    var representationObj = new THREE.Object3D()
    scene.add(representationObj)
    representationObj.position.copy(source.position)
    representationObj.rotation.copy(source.rotation)
    representationObj.scale.copy(source.scale)

    scene.add(pivotObj)
    pivotObj.position.copy(pivot)

    // Set the root node as a child on the pivot point
    THREE.SceneUtils.attach(representationObj, representationObj.parent, pivotObj)
    var representationObjPos = representationObj.position.clone()
    representationObj.position.set(new THREE.Vector3())

    // Set the second rotation to be the rotation of the first premultiplied with the second rotation
    pivotObj.setRotationFromQuaternion(Q)

    representationObj.position.copy(representationObjPos)

    // Set the scene as parent to the root node again
    THREE.SceneUtils.detach(representationObj, representationObj.parent, pivotObj.parent)

    var rotation = representationObj.rotation.clone()
    var position = representationObj.position.clone()

    pivotObj.parent.remove(pivotObj)
    representationObj.parent.remove(representationObj)

    return {
      position,
      rotation
    }
  }

  /**
   * Rotates the object around the pivot by first translating the object to the
   * pivot, then rotating, and finally moving the object back to its original position
   * @param {THREE.Object3D} object
   * @param {THREE.Vector3} pivot
   * @param {THREE.Quaternion} quaternion
   */
  rotateAroundPivotSimple (object, pivot, quaternion) {
    // TODO @Martin: Can we avoid these allocations?
    const translateToPivot = new THREE.Matrix4().makeTranslation(
      -pivot.x,
      -pivot.y,
      -pivot.z
    )

    const translateBackFromPivot = new THREE.Matrix4().makeTranslation(
      pivot.x,
      pivot.y,
      pivot.z
    )
    // .conjugate modifies the quaternion which triggers the onChange callback for objects in the scenegraph.
    // Therefore, we need to copy the quaternion as to not modify the transform of any object.
    const conjugate = new THREE.Quaternion().copy(object.quaternion).conjugate()

    const rotate = new THREE.Matrix4().makeRotationFromQuaternion(quaternion.multiply(conjugate))
    const rotation = translateBackFromPivot.multiply(rotate).multiply(translateToPivot)

    object.applyMatrix4(rotation)
  }

  findRootNode (obj, objectPath = 'userData.isModelRoot') {
    var tmp = obj

    while (tmp.parent && tmp.parent.parent && !_get(tmp, objectPath)) {
      tmp = tmp.parent
    }

    return tmp
  }

  /**
   * This method is slow since it loops through all vertices of the geometry in the provided objects.
   * This is used to get a projected Box2 get the precise screen bounding rect of the objects.
   * Use with caution.
   * @param {Array.<*>} objects
   * @param {number} screenWidth
   * @param {number} screenHeight
   * @param {*} camera
   * @return {THREE.Box2} Projected Box2
   */
  getProjectedBox2SLOW (objects, screenWidth, screenHeight, camera) {
    const scope = this
    let minX = null
    let minY = null
    let maxX = null
    let maxY = null

    const tmp = new THREE.Vector3()

    objects.forEach(obj => obj.traverse(child => {
      if (!child.geometry) return

      const geometry = scope.assetManager.geometries.get(child.geometry.uuid)
      const positions = geometry.attributes.position.array
      const l = positions.length

      for (let i = 0; i < l; i += 3) {
        const x = positions[i]
        const y = positions[i + 1]
        const z = positions[i + 2]
        tmp.set(x, y, z).applyMatrix4(child.matrixWorld).project(camera)
        if (minX === null) {
          minX = tmp.x
          minY = tmp.y
          maxX = tmp.x
          maxY = tmp.y
        } else {
          minX = Math.min(tmp.x, minX)
          minY = Math.max(tmp.y, minY)
          maxX = Math.max(tmp.x, maxX)
          maxY = Math.min(tmp.y, maxY)
        }
      }
    }))

    minX = minX * screenWidth * 0.5 + screenWidth * 0.5
    minY = -(minY * screenHeight * 0.5) + screenHeight * 0.5
    maxX = maxX * screenWidth * 0.5 + screenWidth * 0.5
    maxY = -(maxY * screenHeight * 0.5) + screenHeight * 0.5

    return new THREE.Box2(new THREE.Vector2(minX, minY), new THREE.Vector2(maxX, maxY))
  }

  roundDecimalVEC3 (n, _precision = 8) {
    var factor = Math.pow(10, _precision)
    n.x = (Math.round(n.x * factor) / factor)
    n.y = (Math.round(n.y * factor) / factor)
    n.z = (Math.round(n.z * factor) / factor)
    return n
  }

  precisionCorrectedBoundingBox (box) {
    // Rounding is necessary due minor variation in vertex coords
    // This variation occur likely due to double precision representations in THREE, while GLTF stores float
    box.min = box.min ? this.roundDecimalVEC3(box.min) : box.min
    box.max = box.max ? this.roundDecimalVEC3(box.max) : box.max
    return box
  }

  calcLocalBoundingBox (object, useBoundingBox = false, onlyUseRootScale = true) {
    const scope = this
    // TODO: This function is slow since it accesses vertices of the geometry
    //       The result is cached (so not so slow) but it is not ideal. This solution breaks future lodding/streaming
    const box = new THREE.Box3()
    const tempBox = new THREE.Box3()
    function recursiveExpand (node, parentMatrix, first = false) {
      let matrix = parentMatrix
      if (!first && node._matrix !== undefined) {
        matrix = new THREE.Matrix4()
        matrix.multiplyMatrices(parentMatrix, node.matrix)
      }
      if (node.geometry) {
        if (useBoundingBox) {
          tempBox.copy(node.geometry.boundingBox)
          tempBox.applyMatrix4(matrix)
          box.union(tempBox)
        } else {
          scope.assetManager.expandBoxByTransformedGeometrySLOW(box, node.geometry.uuid, matrix)
        }
      }
      node.children.forEach(function (child) {
        recursiveExpand(child, matrix)
      })
    }
    let m
    if (onlyUseRootScale) {
      m = new THREE.Matrix4().makeScale(object.scale.x, object.scale.y, object.scale.z)
    } else {
      m = object.matrix
    }
    recursiveExpand(object, m, true)

    return this.precisionCorrectedBoundingBox(box)
  }

  alternativeCalcLocalBoundingBox (obj) {
    var BBLS = new THREE.Box3()
    obj.traverse((child) => {
      if (child.geometry) {
        var bbMin = child.geometry.boundingBox.min.clone()
        var bbMax = child.geometry.boundingBox.max.clone()

        var a = child
        bbMin.applyMatrix4(a.matrix)
        bbMax.applyMatrix4(a.matrix)
        while (a !== obj) {
          a = a.parent
          bbMin.applyMatrix4(a.matrix)
          bbMax.applyMatrix4(a.matrix)
        }
        BBLS.expandByPoint(bbMin)
        BBLS.expandByPoint(bbMax)
      }
    })

    return this.precisionCorrectedBoundingBox(BBLS)
  }

  getObjectBoundingBox (object, localBBKey = 'localBoundingBox') {
    if (!object[localBBKey]) {
      object[localBBKey] = this.calcLocalBoundingBox(object)
    }
    var worldBB = object[localBBKey].clone()
    worldBB.applyMatrix4(object.matrixWorld)
    return worldBB
  }

  getWorldBoundFromMeshBoundingBoxes (object) {
    return this.calcLocalBoundingBox(object, true, false)
  }

  getBoundingBox (objects, params = {}) {
    var boundingBox = new THREE.Box3()
    for (var i in objects) {
      if (params.filterProperty && !objects[i][params.filterProperty]) {
        continue
      }
      boundingBox.union(this.getObjectBoundingBox(objects[i]))
    }

    return boundingBox
  }

  rotateObjectAroundAxis (object, radians, axisFunction) {
    var localBB = this.getWorldBoundFromMeshBoundingBoxes(object)
    var destination = localBB.getCenter(new THREE.Vector3())
    object[axisFunction](radians)
    this.centerObject(object)
    object.position.add(destination)
  }

  centerObject (snappingClone) {
    var cloneBB = this.getWorldBoundFromMeshBoundingBoxes(snappingClone)
    var cloneCenterLS = cloneBB.getCenter(new THREE.Vector3())
    var translationLS = new THREE.Vector3().subVectors(new THREE.Vector3(0, 0, 0), cloneCenterLS)
    snappingClone.position.add(translationLS)
  }

  getBBPoints (bb) {
    return [
      new THREE.Vector3().set(bb.min.x, bb.min.y, bb.min.z),
      new THREE.Vector3().set(bb.min.x, bb.min.y, bb.max.z),
      new THREE.Vector3().set(bb.min.x, bb.max.y, bb.min.z),
      new THREE.Vector3().set(bb.min.x, bb.max.y, bb.max.z),
      new THREE.Vector3().set(bb.max.x, bb.min.y, bb.min.z),
      new THREE.Vector3().set(bb.max.x, bb.min.y, bb.max.z),
      new THREE.Vector3().set(bb.max.x, bb.max.y, bb.min.z),
      new THREE.Vector3().set(bb.max.x, bb.max.y, bb.max.z)
    ]
  }

  getSelectedObjectsFromMarquee (pos0, pos1, domElement, objects, camera) {
    const rect = domElement.getBoundingClientRect()

    const coords0 = new THREE.Vector2().set((pos0.x - rect.left) / rect.width, 1 - (pos0.y - rect.top) / rect.height)
    const coords1 = new THREE.Vector2().set((pos1.x - rect.left) / rect.width, 1 - (pos1.y - rect.top) / rect.height)
    const marqueeBB = new THREE.Box2().setFromPoints([coords0, coords1])

    const bbs = objects.map((obj) => {
      return { obj, bb: this.getObjectBoundingBox(obj) }
    })

    return bbs.map((obj) => {
      const bb = obj.bb

      // Get all points of bb since we need to create a clip space bounding box
      // (min and max in world space doesn't necessarily correlate with min max in clip space)
      const points = this.getBBPoints(bb)

      // Project all points to clip space
      const screenPoints = points.map((point) => {
        point.project(camera)

        return new THREE.Vector2().set(0.5 + point.x / 2, 0.5 + point.y / 2)
      })

      // Define a new Box2 from the clip space points
      const screenBB = new THREE.Box2().setFromPoints(screenPoints)

      return { bb: screenBB, obj: obj.obj }
    }).filter((obj) => {
      return marqueeBB.containsBox(obj.bb) || marqueeBB.intersectsBox(obj.bb) || obj.bb.containsBox(marqueeBB)
    }).map((obj) => { return obj.obj })
  }

  stackObjects (node, otherObjects, nodeBBox) {
    otherObjects.forEach((object) => {
      const objectBB = this.getBoundingBox([object])

      if (nodeBBox.intersectsBox(objectBB)) {
        node.position.x += (objectBB.max.x - objectBB.min.x) + 0.1

        const newBBox = this.getBoundingBox([node])
        this.stackObjects(node, otherObjects, newBBox)
      }
    })

    return node.position.x
  }

  getIntersectsWithFloor (camera) {
    const origin = camera.position
    const direction = new THREE.Vector3()
    camera.getWorldDirection(direction)

    if (direction.y === 0) {
      return null // no intersection
    }

    const t = -origin.y / direction.y
    if (t < 0) {
      return null // behind camera
    }

    return origin.clone().add(direction.clone().multiplyScalar(t))
  }

  getObjectPlacement (picker, camera, maxDistance = 25) {
    const point = new THREE.Vector2(0.5, 0.5)
    const rayCast = picker.getIntersects(point)

    if (rayCast != null && rayCast.distance < maxDistance) {
      rayCast.point.y = 0
      return rayCast.point
    }

    const intersectsWithFloor = this.getIntersectsWithFloor(camera)
    if (intersectsWithFloor) {
      return intersectsWithFloor
    }

    // If the camera happens to be pointed upwards or parallell to the floor plane
    // we will place it 8m ahead in the direction but down on the floor, so the object
    // wont get lost in the infinite beyond.
    const direction = new THREE.Vector3()
    camera.getWorldDirection(direction)
    const placementPos = new THREE.Vector3().addVectors(camera.position, direction.multiplyScalar(8))
    placementPos.y = 0
    return placementPos
  }

  getSceneBoundingBox (scene, filter) {
    const objects = []
    scene.traverse((node) => {
      if (!filter || filter(node)) {
        objects.push(node)
      }
    })
    return this.getBoundingBox(objects)
  }

  debugPosition (name, point) {
    this.viewer.overlayScene.remove(this[name])
    this[name] = new THREE.Mesh(new THREE.SphereBufferGeometry(0.01, 20, 20), new THREE.MeshBasicMaterial({ color: 0x00ff00, wireframe: true }))
    this[name].position.copy(point)
    this[name].needsUpdate = true
    this[name].updateMatrixWorld()
    this.viewer.overlayScene.add(this[name])
  }
}
