import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import cs from 'classnames'
import css from './index.css'
import InputRadio from './input-radio'

class InputRadioGroup extends PureComponent {
  constructor (props, ctx) {
    super(props, ctx)

    this.state = {
      selected: (props.options || []).reduce((acc, option) => {
        return option && option.selected ? option.value : acc
      }, null)
    }
  }

  handleChange (value) {
    // This appeared to fire two times every time a value changed for some reason,
    // I added a check to on call onChange once
    if (value !== this.state.selected) {
      this.setState({ selected: value })
      this.props.onChange(value)
    }
  }

  render () {
    const {
      name,
      direction,
      options,
      className,
      disabled,
    } = this.props

    return (
      <div className={cs(className, {
        [css.radioButtonsInline]: direction === 'horizontal',
        // if you want the old bolded font on vertical layout just don't set any direction
        [css.radioButtonNoBold]: direction === 'vertical'
      })}
      data-testid={this.props['data-testid']}
      >
        {options.map((option) => (
          <InputRadio
            disabled={disabled || option.disabled}
            name={name}
            label={option.label}
            value={option.value}
            key={option.value}
            onClick={() => this.handleChange(option.value)}
            selected={this.state.selected === option.value}
          />
        ))}
      </div>
    )
  }
}

InputRadioGroup.propTypes = {
  disabled: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func
}

InputRadioGroup.defaultProps = {
  disabled: false,
  className: '',
  onChange () {},
  options: []
}

export default InputRadioGroup
