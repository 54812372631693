import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import autoBind from 'react-autobind'
import IconDuplicate from '../../common/icons/icon-duplicate'
import ToolbarButton from './toolbar-button'
import * as fromThreeviewerFilesSelectors from '../../../stores/ducks/threeviewer/files/selectors'
import * as fromVisualizerSelectors from '../../visualizer/visualizer-selectors'
import * as fromCloneSelectors from '../../../stores/ducks/clone/selectors'
import * as fromThreeviewerSelectors from '../../../stores/ducks/threeviewer/selectors'
import * as fromClone from '../../../stores/ducks/clone'

const mapStateToProps = createSelector(
  fromVisualizerSelectors.getHasValidSelection,
  fromCloneSelectors.getCanDuplicate,
  fromThreeviewerFilesSelectors.getIsSceneLoaded,
  fromThreeviewerSelectors.getKeyBoardBindingsEnabled,
  (_, ownProps) => ownProps,
  (hasValidSelection, canDuplicate, isSceneLoaded, enabled, ownProps) => {
    return {
      disabled:
        ownProps.disabled || !hasValidSelection || !isSceneLoaded || !enabled,
      canClone: hasValidSelection,
      canDuplicate
    }
  }
)

const mapDispatchToProps = dispatch => ({
  clone: () => dispatch(fromClone.clone()),
  duplicate: () => dispatch(fromClone.duplicate()),
  dispose: () => dispatch(fromClone.dispose())
})

const propTypes = {
  dispose: PropTypes.func,
  duplicate: PropTypes.func,
  clone: PropTypes.func,
  disabled: PropTypes.bool,
  canClone: PropTypes.bool,
  canDuplicate: PropTypes.bool
}

class DuplicateButton extends React.PureComponent {
  constructor (props) {
    super(props)
    autoBind(this, 'handleKeyDown', 'handleClick')

    window.addEventListener('keydown', this.handleKeyDown)
  }

  componentWillUnmount () {
    this.props.dispose()
    window.removeEventListener('keydown', this.handleKeyDown)
  }

  handleKeyDown (event) {
    if (
      !this.props.disabled &&
      (event.ctrlKey || event.metaKey || event.shiftKey) &&
      event.keyCode === 68
    ) {
      event.preventDefault()
      if (this.props.canDuplicate) {
        this.props.duplicate()
      } else if (this.props.canClone) {
        this.props.clone()
      }
    }
  }

  handleClick () {
    if (this.props.canDuplicate) {
      this.props.duplicate()
    } else if (this.props.canClone) {
      this.props.clone()
    }
  }

  render () {
    return (
      <ToolbarButton
        disabled={this.props.disabled}
        onClick={this.handleClick}
        icon={<IconDuplicate width={20} height={20} />}
        title="Duplicate"
      />
    )
  }
}

DuplicateButton.propTypes = propTypes

export default connect(mapStateToProps, mapDispatchToProps)(DuplicateButton)
