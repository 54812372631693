export const createSetColorCommand = ({
  newValue,
  oldValuesMap,
  afterUndo,
  afterExecute,
  setMaterialColor,
  uuids,
  nodeList
}) => ({
  undo () {
    uuids.forEach((uuid) => {
      const oldValue = oldValuesMap[uuid]

      if (oldValue) {
        const isDefaultMaterialColor = !oldValue.colorJson

        setMaterialColor(
          nodeList[uuid],
          oldValue.colorJson,
          isDefaultMaterialColor
        )
      }
    })

    afterUndo()
  },

  execute () {
    uuids.forEach((uuid) => {
      setMaterialColor(nodeList[uuid], newValue)
    })

    afterExecute()
  }
})
