import React from 'react'
import { FaAngleDown, FaAngleUp, FaFilter } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import * as fromSelection from '../../../../stores/ducks/selection'
import * as fromThreeviewerSelectors from '../../../../stores/ducks/threeviewer/selectors'
import * as fromThreeviewerUi from '../../../../stores/ducks/threeviewer/ui'
import * as fromTree from '../../../../stores/ducks/tree'
import * as fromTreeSelectors from '../../../../stores/ducks/tree/selectors'

import type { IModelSource } from '../../../../stores/ducks/tree/TreeNode'

import Button from '../../../common/button'
import ButtonIcon from '../../../common/button-icon'
import EmptyState from '../../../common/empty-state/empty-state'
import Checkbox from '../../../common/form/input-checkbox'
import Grid from '../../../common/grid/grid'
import IconCube from '../../../common/icons/icon-cube'
import { OverlayMenu } from '../../../common/OverlayMenu'
import { PanelSectionHeader } from '../../../common/panels/panel-section'

import { LoadDesignModalButton } from './load-design/LoadDesignModalButton'
import { DebouncedInputSearch } from './debounced-search-input/DebouncedInputSearch'

import { getFilteredNodes } from './utils/helpers'

import GeometryTree from './components/geometry-tree'

const SelectLink = styled.div<{ disabled: boolean }>`
  cursor: pointer;
  margin-right: 16px;
  opacity: ${(props) => props.disabled ? 0.5 : 1};
  pointer-events: ${(props) => props.disabled ? 'none' : 'auto'};
  &:hover {
    text-decoration: ${(props) => props.disabled ? 'none' : 'underline'};
  }
`

type ModelTypes = {
  [key in IModelSource]: string
}

// TODO might be able to use MODEL_RESOURCE_TYPE in constants, but it doesn't match perfectly
// NOTE: VPs are missing from this list. Is that a problem?
const modelTypes: ModelTypes = {
  uploadedGeometry: 'Uploaded geometry',
  modelbank: 'Model bank',
  ugabank: 'Propping bank',
  'non-cad': 'Non-CAD'
}

type Props = {
  active: boolean // true if panel is visible, false if not. Used to refresh react-virtualized list
}

/**
 * Resonsible for rendering the entire geometry panel
 * Renders the search bar, the filter menu modal, and the actual geometry tree
 */
const GeometryPanel = ({ active }: Props) => {
  // Reference to the model filter menu trigger (i.e, the button that opens the modal)
  const filterMenuTriggerRef = React.useRef<HTMLDivElement>(null)
  const [isFilterMenuOpen, setIsFilterMenuOpen] = React.useState(false)

  const [searchTerm, setSearchTerm] = React.useState('')
  const [selectedFilters, setSelectedFilters] = React.useState<IModelSource[]>([])

  const flatNodes = useSelector(fromTreeSelectors.getFlatNodes)
  const hasNodes = useSelector(fromTreeSelectors.getHasNodes)

  const isKeyboardBindingEnabled = useSelector(fromThreeviewerSelectors.getKeyBoardBindingsEnabled)
  const disabled = useSelector(fromTreeSelectors.getIsDisabled)
  const isolationEnabled = useSelector(fromTreeSelectors.getIsIsolationEnabled)

  const dispatch = useDispatch()

  /**
   * All visible nodes after a model filter and/or search term has been applied
   */
  const filteredNodesUuids = React.useMemo(
    () => getFilteredNodes(flatNodes, selectedFilters, searchTerm),
    [flatNodes, selectedFilters, searchTerm]
  )

  /**
   * Hook for registering key event listeners
   */
  React.useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (!isKeyboardBindingEnabled || disabled) return

      /**
       * Functionality for handling isolation (Key: I)
       */
      if (event.code === 'KeyI' && isolationEnabled) {
        dispatch(fromSelection.toggleIsolation())
      }

      /**
       * Functionality for handling the hide node hotkey (Key: H).
       */
      if (event.code === 'KeyH' && !event.altKey) {
        dispatch(fromSelection.handleHotkeyHide())
      }

      /**
       * Functionality for handling the show all nodes hotkey (Key: ALT+H).
       */
      if (event.code === 'KeyH' && event.altKey) {
        dispatch(fromSelection.handleHotkeyShow())
      }
    }

    window.addEventListener('keyup', handleKeyPress)
    return () => {
      window.removeEventListener('keyup', handleKeyPress)
    }
  }, [isKeyboardBindingEnabled, isolationEnabled, disabled])

  /**
   * The geometry remains mounted, even when it's not visible. This facilitates many aspects
   * of the geometry tree state management, and ensures that keyboard shortcuts keep working.
   *
   * When the user leaves the visualizer, the panle is finally unmounted and all redux data is cleared
   * The tree is rebuilt the next time the visualizer is opened.
   */
  React.useEffect(() => {
    return () => {
      dispatch(fromTree.clear())
    }
  }, [])

  /**
   * Hook that ensures that all filtered nodes are visible in the tree (i.e, expanded)
   */
  React.useEffect(() => {
    if (searchTerm.length > 0) {
      dispatch(fromTree.openGroup(filteredNodesUuids))
    }
  }, [filteredNodesUuids])

  /**
   * Toggles a single filter. Used by the model filter modal
   */
  const toggleFilter = (filter: IModelSource) => {
    if (selectedFilters.includes(filter)) {
      setSelectedFilters(selectedFilters.filter(selectedFilter => selectedFilter !== filter))
    } else {
      setSelectedFilters(selectedFilters.concat([filter]))
    }
  }

  /**
   * Function which picks nodes from filteredNodes that matches matches the search term
   * Nodes included due to the logic in getFilteredNodes are excluded
   *
   * This is done due to the fact that only nodes matching the search term should, for example, get
   * a material set. If used on filteredNodes it will set material on parent nodes affecting
   * all siblings and not just the child
   */
  const getFilteredNodesExact = () => {
    return filteredNodesUuids.filter(uuid => {
      const flatNode = flatNodes[uuid]
      return flatNode
        ? flatNode.name.toLowerCase().includes(searchTerm.toLowerCase())
        : false
    })
  }

  const toggleKeyboardBindings = (active: boolean) => dispatch(fromThreeviewerUi.toggleKeyBoardBindings(active))

  return (
    <Grid
      className='height-100'
      rows={['auto', '1fr']}
      gridGap={0}
    >
      <div className='pt2'>
        <PanelSectionHeader title='Geometry in scene' includeTestId={active} />
        {hasNodes && (
          <>
            <div className='px2 mb2'>
              <LoadDesignModalButton
                toggleKeyboardBindings={toggleKeyboardBindings}
              >
                {(openModal: any) => (
                  <Button
                    className={'width-100 justify-center'}
                    onClick={(params: any) => {
                      toggleKeyboardBindings(false)
                      openModal(params)
                    }}
                    disabled={disabled}
                    pad={1}
                    data-testid="load-geometry"
                  >
                    Load geometry
                  </Button>
                )}
              </LoadDesignModalButton>
            </div>
            <div className='pl2 mb1 flex'>
              <DebouncedInputSearch
                onFocus={() => toggleKeyboardBindings(false)}
                onBlur={() => toggleKeyboardBindings(true)}
                placeHolder="Filter..."
                onChange={setSearchTerm}
                debounce={500}
              />
              <div
                style={{
                  alignItems: 'center' // for some reason basscss flex-center doesnt work
                }}
                className={'px2 flex pointer height-100'}
                onClick={() => {
                  setIsFilterMenuOpen(!isFilterMenuOpen)
                }}
                ref={filterMenuTriggerRef}
                data-testid="filter-menu-icon"
              >
                <FaFilter
                  size={18}
                  className='opacity-hover-lighter-performant-alt'
                />
              </div>
              { isFilterMenuOpen && (
                <OverlayMenu
                  isOpen={true} // Always set to open since the component is unmounted if isFilterMenuOpen === false
                  position="right"
                  menuTriggerRef={filterMenuTriggerRef}
                  onClose={() => setIsFilterMenuOpen(false)}
                >
                  { (Object.entries(modelTypes) as [IModelSource, string][]).map(([filter, name]) => (
                    <Checkbox
                      key={filter}
                      id={filter}
                      label={name}
                      className='flex items-center'
                      checked={selectedFilters.includes(filter)}
                      onChange={() => toggleFilter(filter)}
                    />
                  ))}
                </OverlayMenu>
              )}
            </div>
            <div className='ml2 flex f7' style={{ justifyContent: 'space-between', alignItems: 'center' }}>
              <div className='flex'>
                <SelectLink
                  disabled={ !searchTerm.length && !selectedFilters.length }
                  onClick={() => dispatch(fromTree.selectFromUuids(getFilteredNodesExact()))}
                  data-testid='selectFilteredSelectLink'
                >
                  Select filtered
                </SelectLink>
                <SelectLink
                  disabled={ !!searchTerm.length || !!selectedFilters.length }
                  onClick={() => dispatch(fromTree.selectFromUuids(Object.keys(flatNodes)))}
                  data-testid='selectAllSelectLink'
                >
                  Select all
                </SelectLink>
              </div>
              <div className='flex'>
                <ButtonIcon
                  disabled={false}
                  noPointer={false}
                  title="Collapse all"
                  btnType='transparent'
                  className='opacity-hover-lighter-performant-alt py1'
                  padding={false}
                  onClick={() => dispatch(fromTree.closeAllGroups())}
                  icon={
                    <FaAngleUp
                      size={18}
                      className='opacity-hover-lighter-performant-alt'
                    />
                  }
                />
                <ButtonIcon
                  disabled={false}
                  title="Expand all"
                  noPointer={false}
                  btnType='transparent'
                  className='opacity-hover-lighter-performant-alt py1'
                  padding={false}
                  onClick={() => dispatch(fromTree.openAllGroups())}
                  icon={
                    <FaAngleDown
                      size={18}
                      className='opacity-hover-lighter-performant-alt'
                    />
                  }
                />
              </div>
            </div>
          </>)}
      </div>

      { /* If there are nodes in the tree, render the geometry tree component */ }
      {hasNodes && (
        <GeometryTree
          filteredNodesUuids={filteredNodesUuids}
          active={active}
        />
      )}

      { /* Otherwise, render the load design button */ }
      {!hasNodes && (
        <LoadDesignModalButton
          toggleKeyboardBindings={toggleKeyboardBindings}
        >
          {(openModal: any) => (
            <EmptyState
              className='mt4'
              disabled={disabled}
              icon={<IconCube size={80} />}
              title='Add geometry to your scene'
              desc='Load geometry and designs into the scene with the Load design button.'
              docLink='basic-designing.md#dpd_addmodelstoascene'
              linkText='Learn more'
              actionTitle='Load geometry'
              onAction={() => {
                toggleKeyboardBindings(false)
                openModal()
              }}
            />
          )}
        </LoadDesignModalButton>
      )}
    </Grid>
  )
}

export default GeometryPanel
