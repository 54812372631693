import Immutable, { ImmutableObject } from 'seamless-immutable'
import { createAction, handleActions } from 'redux-actions'

import * as fromUtils from '../../../utils'

import * as fromReplaceModelActions from './actions/replace-model'
import * as fromPostRenderActions from './actions/post-render'
import * as fromAddRemoveModelActions from './actions/add-remove-models'
import * as fromLoadCombinationActions from './actions/load-combination'
import * as fromJsonActions from './actions/json'
import * as fromImagePackageActions from './actions/image-package'
import error from './actions/error'
import { Combination, VisualizedCombination } from './Combination'
import { AppThunk } from '..'
import { groupBy } from 'lodash'
import { Render } from '../renders/Render'
import getImageDownloadFile from '../../../utils/getImageDownloadFile'

const confirmReceive = fromJsonActions.confirmReceive

export const receive = fromJsonActions.receive
export const remove = fromJsonActions.remove
export const update = fromJsonActions.update
export const setCurrent = fromJsonActions.setCurrent

export const replaceModel = fromReplaceModelActions.replaceModel

export const postRender = fromPostRenderActions.postRender
export const removeModel = fromAddRemoveModelActions.removeModel
export const fetchAndLoadCombination = fromLoadCombinationActions.fetchAndLoadCombination
export const loadCombination = fromLoadCombinationActions.loadCombination

export const receiveCostEstimation = createAction('combinations/RECEIVE_COST_ESTIMATION')
export const setAnnotation = createAction('combinations/SET_ANNOTATION')

export const createImagePackageMaster = fromImagePackageActions.createImagePackageMaster
export const setImagePackageFinalVersion = fromImagePackageActions.setImagePackageFinalVersion
export const connectCombinationsForBatchRender = createAction<string[]>('combinations/connectCombinationsForBatchRender')
export const deleteByIdConnectGeometries = createAction<string>('combinations/DELETE_CONNECTED_GEOMETRIES')

export function updateBatchRenderTitle ({ data, id }: { data: Partial<Combination>, id: string }): AppThunk {
  return (dispatch, _, { api }) => {
    return api.combinations.updateBatchRenderTitle({ data, id })
      .then(() => {})
      .catch((err) => dispatch(error(err)))
  }
}

export function downloadRenderBatch (id: string): AppThunk {
  return (dispatch, getState, { api }) => {
    const state = getState()
    const roomsets = state.roomsets.entries
    const rendersGroupedByCombinationId = groupBy((state.renders.entries as unknown as ImmutableObject<{ [id: string]: Render }>), 'combinationId')
    const combination = state.combinations.entries[id]
    const connectedCombinations: ImmutableObject<Combination | VisualizedCombination>[] = []
    Object.values(state.combinations.entries).forEach(combination => {
      if (combination.batchParentId === id) connectedCombinations.push(combination)
    })

    const files: { key: string, src: string, name: string, path?: string }[] = []
    const title = combination.batchRenderTitle || 'Image_package'

    ;[combination, ...connectedCombinations].forEach(combination => {
      const renders = rendersGroupedByCombinationId[combination.id]
      renders.forEach((render) => {
        const file = getImageDownloadFile(render, combination.title)
        if (!file) return
        if (combination.imageTemplateId) {
          const setupId = render.setupId

          // If we have a cameraType we add the name to make sure the name is unique
          if (render.cameraType) {
            // Add cameraType before the files extension
            const splitName = file.name.split('.')
            // Check if there acctually was something resembling an extension
            if (splitName.length > 1) {
              const name = splitName.slice(0, -1).join('.')
              const extension = splitName[splitName.length - 1]
              file.name = `${name}_${render.cameraType}.${extension}`
            } else {
              // If there is no file extension we append the cameraType directly at the end
              const name = splitName[0]
              file.name = `${name}_${render.cameraType}`
            }
          }

          files.push({
            ...file,
            path: [
              title,
              setupId ? (roomsets[setupId]?.title || setupId) : 'default light setup'
            ].join('/')
          })
        } else {
          files.push(file)
        }
      })
    })

    api.downloadZip.storage(files, title)
  }
}

const initialState = Immutable<{
  entries: { [key: string]: Combination | VisualizedCombination }
  currentId: string | null
  error: string | null
  connectedBatchGeometryCombinationIds: string[]
}>({
  entries: {},
  currentId: null,
  error: null,
  connectedBatchGeometryCombinationIds: []
})

type State = typeof initialState

export default handleActions<State, any>({
  [connectCombinationsForBatchRender.toString()]: (state, { payload }) => state.merge({ connectedBatchGeometryCombinationIds: payload }),
  [deleteByIdConnectGeometries.toString()]: (state, { payload }) => state.merge({
    connectedBatchGeometryCombinationIds: state.connectedBatchGeometryCombinationIds.filter(id => id !== payload)
  }),
  [receiveCostEstimation.toString()]: (state, { payload }) => state.setIn(
    ['entries', state.currentId, 'costEstimations', payload.id],
    {
      areaCost: payload.areaCost,
      volumeCost: payload.volumeCost
    }
  ),
  [setAnnotation.toString()]: (state, { payload }) => {
    return state.setIn(
      ['entries', state.currentId, 'annotations', payload.id],
      {
        ...payload
      }
    )
  },
  [error.toString()]: (state, { payload }) => state.merge({ error: payload }),
  [remove.toString()]: (state, { payload }) => state.merge({
    entries: typeof payload === 'string'
      ? state.entries.without((entry) => entry.id === payload)
      : state.entries.without(payload)
  }),
  [confirmReceive.toString()]: (state, { payload }) => state.merge({
    entries: fromUtils.dataToMap(payload.data, payload.params)
  }, { deep: true }),
  [setCurrent.toString()]: (state, { payload }) => state.merge({
    currentId: payload
  })
}, initialState)
