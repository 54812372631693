import React from 'react'
import cs from 'classnames'

import ZIndexHandler from '../enhancers/zindex'
import useOutsideClick from '../../hooks/useOutsideClick'
// @ts-ignore
import css from './menu.css'

type MenuItemProps = {
  onClick: () => void
  icon?: any
  iconInJSX?: any
  text: string
  className: string
  disabled: boolean
}

function MenuItem (props: MenuItemProps) {
  return (
    <a
      onClick={() => {
        !props.disabled && props.onClick()
      }}
      className={cs(`${props.className} f6 block px1 py1 bg-primary-hover`, {
        'bg-gray-light-hover c-gray default-cursor': props.disabled,
        pointer: !props.disabled
      })}
    >
      {props.icon && (<i className={cs(`${props.icon} mr1`)} />)}
      {props.iconInJSX && <div className={cs('inline mr1')}>{props.iconInJSX}</div>}
      <div className={cs('inline')}>{props.text}</div>
    </a>
  )
}

MenuItem.defaultProps = {
  className: '',
  icon: false
}

type Props = {
  isTop: boolean // From ZIndexHandler
  setAsTop: () => void // From ZIndexHandler
  items: { icon?: any; iconInJSX?: any; text: string; shouldntClose: boolean; onClick: () => void }[]
  position: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight'
  listClassName: string
  listStyle: React.CSSProperties
  buttonClassName: string
  className: string
  style: React.CSSProperties
  disabled: boolean
  iconClass: string
  isOpen: boolean
}

function Menu (props: Props) {
  if (!props.items) return null

  const [isOpen, setIsOpen] = React.useState(false)
  const el = React.useRef(null)

  useOutsideClick(el, () => setIsOpen(false))

  return (
    <div
      ref={el}
      className={cs(props.className, 'relative', {
        z1: !props.isTop,
        z2: props.isTop
      })}
      onClick={props.setAsTop}
      style={props.style}
      data-testid='common-menu'
    >
      <div
        onClick={() => setIsOpen(!isOpen)}
        className={`${props.buttonClassName} height-100 flex items-center pointer`}
      >
        <i className={props.iconClass} />
      </div>

      <div
        className={cs(
          'absolute c-gray-dark bg-gray-light',
          css[props.position],
          props.listClassName,
          { hidden: !isOpen }
        )}
        style={props.listStyle}
      >
        {props.items.filter(Boolean).map((item, index) => (
          <MenuItem
            {...item}
            disabled={props.disabled}
            key={index}
            onClick={() => {
              !item.shouldntClose && setIsOpen(false)
              item.onClick()
            }}
          />
        ))}
      </div>
    </div>
  )
}

Menu.defaultProps = {
  listStyle: {},
  className: '',
  style: {},
  iconClass: 'icon-ellipsis-vert'
}

export default ZIndexHandler(Menu)
