import { v4 as uuid } from 'uuid'

import { PRODUCT_TYPES, COMBINATION_TYPES, FEATURES } from '../../../../constants'

import _get from 'lodash/get'
import _set from 'lodash/set'
import _isEmpty from 'lodash/isEmpty'
import _omit from 'lodash/omit'

import * as fromThreeviewerSelectors from '../../threeviewer/selectors'

import * as fromSelection from '../../selection'
import * as fromTemplates from '../../templates'
import * as fromMaterials from '../../materials'
import * as fromColors from '../../colors'
import * as fromPatternsJson from '../../patterns/json'
import * as fromPatternsTextures from '../../patterns/textures'
import * as fromDefaultTemplate from '../../templates/default-template'
import * as fromThreeviewerUI from '../../threeviewer/ui'
import * as fromThreeviewerFiles from '../../threeviewer/files'
import * as fromThreeviewerCamera from '../../threeviewer/camera'
import * as fromVirtualProducts from '../../virtual-products'
import * as fromThreeviewerSplit from '../../threeviewer/split'
import { loadSavedImageTemplateCameraSettings } from '../../threeviewer/imageTemplates'

import * as fromPlaceActions from './place'
import * as fromAddRemoveModelsActions from './add-remove-models'
import * as fromJsonActions from './json'
import { getEntries as getPatternEntries } from '../../patterns/selectors'

import DEFAULT_INTERACTIONS, { Interaction } from '../interactions'
import { AppThunk } from '../..'
import { VisualizedCombination, Part, GroupRecursive, CombinationModel } from '../Combination'
import type { SceneGraphMesh as ISceneGraphMesh, SceneGraphNode as ISceneGraphNode, SceneGraphNode3d as ISceneGraphNode3d } from '../../../../../../go3dthree/types/SceneGraph'
import { connectCombinationsForBatchRender } from '..'
import * as customHome from '../../threeviewer/custom-home'
import { getNodeList } from '../../threeviewer/selectors'

function getFixedPartId (partId = '') {
  // TODO: this function is solely used for old lagacy uga converts where the partid and assignment ids does not match
  // Once we start converting ugas via assetdb we should scrap this.
  const id = ~partId.indexOf('node_') ? partId : `node_${partId}_1_${partId.replace('part', '')}`
  return id.replace(/ /g, '_')
}

export const fetchAndLoadCombinations = (ids: string[]): AppThunk => {
  return async (dispatch, getState) => {
    const state = getState()
    if (state.designs.activeTab === 'virtualProduct') {
      await Promise.all(ids.map(async (id: string) => {
        await dispatch(fromVirtualProducts.getVirtualProductById(id))
      }))
    }
    const result = await dispatch(fromJsonActions.fetchManyForVisualize(ids))
    dispatch(fromMaterials.receiveJson(result.materials))
    dispatch(fromPatternsJson.receive(result.patterns))

    dispatch(fromThreeviewerFiles.loadFile({
      type: 'model',
      uris: result.modelFiles
    }))

    for (const combination of result.docs) {
      const instanceId = uuid()
      await dispatch(loadCombination({
        instanceId,
        combination,
        isComplementary: true,
        interactions: DEFAULT_INTERACTIONS
      }))

      dispatch(fromThreeviewerFiles.loaded('combination', instanceId))
    }
  }
}

export const fetchAndLoadCombination = (id: string, isComplementary = false): AppThunk => {
  return async (dispatch) => {
    const instanceId = uuid()

    if (!isComplementary) {
      dispatch(fromThreeviewerFiles.dispose())
    }

    dispatch(fromThreeviewerFiles.load('combination', instanceId))

    const combination = await dispatch(
      fromJsonActions.fetchForVisualize(
        id,
        { initialLoad: !isComplementary }
      )
    )

    if (!combination) {
      return null
    }

    await Promise.all([
      dispatch(loadCombination({
        instanceId,
        combination,
        isComplementary,
        interactions: DEFAULT_INTERACTIONS
      })),
      !isComplementary && dispatch(loadTemplate(combination))
    ].filter(Boolean))

    // InstanceID is used by replace roomset part to determine where to place combination model
    dispatch(fromThreeviewerFiles.loaded('combination', instanceId))
    return { combination, instanceId }
  }
}

export const loadCombination = ({
  instanceId,
  combination,
  isComplementary,
  interactions
}: {
  instanceId: string
  combination: VisualizedCombination
  isComplementary: boolean
  interactions: { [id: string]: Interaction }
}): AppThunk => async (dispatch, getState) => {
  const state = getState()
  const viewer = state.threeviewer.viewer

  if (!viewer) return
  const { THREE } = viewer

  dispatch(fromThreeviewerFiles.loadCombinations([combination.id]))

  dispatch(fromThreeviewerUI.combinationModelAdded())
  combination.materials && dispatch(fromMaterials.receiveJson(combination.materials))
  combination.patterns && dispatch(fromPatternsJson.receive(combination.patterns))
  if (combination.cameraSettings && _get(combination, 'cameraSettings.transform') && !isComplementary) {
    const { cameraSettings } = combination

    dispatch(fromThreeviewerCamera.setCameraSettings({
      settings: {
        transform: cameraSettings.transform,
        near: _get(cameraSettings, 'near', 0.01),
        fov: cameraSettings.fov,
        aspectRatio: cameraSettings.aspectRatio || _get(cameraSettings, 'resolution', { x: 1, y: 1 }),
        target: cameraSettings.target,
        name: cameraSettings.name || cameraSettings.id
      },
      cameraId: cameraSettings.id || 1,
      isPredefined: cameraSettings.isPredefined
    }))
  }

  const files = Array.from(new Set(combination.nodes.map((nodeData: any) => nodeData.file).filter(Boolean)))
  dispatch(fromThreeviewerFiles.loadFile({
    id: combination.id,
    name: combination.title || combination.articlenr || combination.id,
    type: 'model',
    uris: files
  }))

  const nodeDict = new Map<string, ISceneGraphNode3d | null>()
  const materialModifications: { [materialId: string]: ISceneGraphMesh[] } = {}
  const patternModifications: { [patternId: string]: ISceneGraphMesh[] } = {}
  const colorModifications: { [colorId: string]: ISceneGraphMesh[] } = {}
  const stainModifications: [any, ISceneGraphMesh][] = []
  const carrierModifications: any[] = []
  const visibilityModifications: any[] = []
  const splitParts: { [uuid: string]: {[id: string]:Part}} = {}

  const virtualProductChildren = new Set<string>()

  await Promise.all(combination.nodes.map(async (modelData) => {
    if (combination.customHomeSchema && modelData.source === 'roomAsset') {
      return
    }

    if (modelData.children) {
      const scene = new viewer.SceneGraph.SceneGraphNode3d() as ISceneGraphNode3d

      const interactionType = modelData.interactionType || 'default'

      scene.userData = {
        ...scene.userData,
        ...interactions.userData,
        instanceId,
        params: (interactions[interactionType] || interactions.default).params,
        combinationType: combination.combinationType,
        interactionType: interactionType,
        isCombination: true,
        isGroup: true,
        isVirtualProductRoot: modelData.isVirtualProductRoot,
        virtualProductId: modelData.virtualProductId,
        name: modelData.name || modelData.id,
        virtualProductTransform: modelData.virtualProductTransform
      }
      if (modelData.virtualProductId) {
        modelData.children.forEach(uuid => virtualProductChildren.add(uuid))
      }
      return nodeDict.set(modelData.id, scene)
    }

    let result

    try {
      result = await viewer.loader.load({
        url: modelData.file,
        modelId: modelData.modelId,
        useModelMaterial: modelData.useModelMaterial || modelData.uvMapped
      })
    } catch (err: any) {
      const error = err.target
        ? { type: 'progress', status: err.target.status, message: err.target.statusText }
        : { message: err.message }

      dispatch(fromThreeviewerFiles.fileError({
        file: modelData.file as string,
        error
      }))

      console.error(err)
      return nodeDict.set(modelData.id, null)
    }

    const scene = result.scene.clone()
    const modifications = modelData.modifications
    const transform = modelData.modifications!.transform!
    const matrix = new viewer.THREE.Matrix4()
    matrix.set(
      transform[0], transform[3], transform[6], transform[9],
      transform[1], transform[4], transform[7], transform[10],
      transform[2], transform[5], transform[8], transform[11],
      0, 0, 0, 1
    )
    // Save the original rotation of each VP-model
    scene.userData.originalVPRotation = new viewer.THREE.Matrix4().extractRotation(matrix).elements

    if (modifications.virtualProductId) {
      const virtualProductRoot = combination.nodes.find(node => node.modifications.isVirtualProductRoot && node.modifications.virtualProductId === modifications.virtualProductId)

      if (virtualProductRoot?.modifications.virtualProductTransform) {
        const virtualProductCenterArray = virtualProductRoot?.modifications.virtualProductTransform
        const virtualProductCenter = new viewer.THREE.Matrix4().fromArray(virtualProductCenterArray)
        matrix.copy(new viewer.THREE.Matrix4().copy(matrix).premultiply(virtualProductCenter))
      }
    }
    scene.setLocalMatrix(matrix)

    if ('visible' in modifications && !modifications.visible) {
      visibilityModifications.push(scene)
    }

    // Set userData for models
    const isStatic = _get(modifications, 'metadata.static', false)

    let interactionType: string = 'default'

    if (isStatic) {
      interactionType = 'static'
    } else {
      interactionType = (_get(modifications, 'metadata.type') || 'default')
    }

    const interaction = interactions[interactionType] || interactions.default

    const isRoomsetModel = _get(interaction, 'userData.modelType') === 'roomset'
    // const setMaterial = _get(modelData, 'modelInteractions.setMaterial', false)
    let setMaterial = _get(modelData, 'modelInteractions.setMaterial', false)

    // It should also be possible to set materials for non-static roomset models
    setMaterial = setMaterial || (isRoomsetModel && !isStatic)

    if (
      virtualProductChildren.has(modifications.uuid) ||
      combination.virtualProductId ||
      modifications.virtualProductId ||
      interaction.userData.disableMaterials
    ) setMaterial = false

    scene.userData = {
      ...scene.userData,
      ...interaction.userData,
      isModelGenerated: modifications!.generated,
      override: modifications.override,
      generated: modifications.generated,
      instanceId,
      interactionType,
      combinationType: combination.combinationType,
      params: interactions[interactionType].params,
      dbModelId: modelData.modelId,
      modelId: modelData.id,
      changed: !isRoomsetModel,
      isModelRoot: true,
      setMaterial: setMaterial,
      static: isStatic,
      modelSource: modelData.source,
      metadata: modifications.metadata,
      name: modifications.name || modelData.title || '',
      hasDefaultTransform: (new THREE.Matrix4()).equals(matrix),
      productType: _get(modelData, 'productType', PRODUCT_TYPES.hard),
      splitFaces: _get(modelData, 'splitFaces'),
      article: _get(modelData, 'articlenr') && ({
        articlenr: _get(modelData, 'articlenr'),
        pqpm: _get(modelData, 'pqpm', 1000)
      }),
      virtualProductId: combination.virtualProductId || modifications.virtualProductId
    }
    const partsByNodeId: { [id: string]: Part } = {}
    const partsByOriginalNodeId: { [id: string]: Part } = {}
    ;(modifications.parts || []).forEach((part: Part) => {
      const partId = modifications.generated ? part.partId : getFixedPartId(part.partId)
      partsByNodeId[partId] = part

      // Store the part together with the original part/node id.
      // This is used as a backup lookup for roomsets where node id formats has changed
      partsByOriginalNodeId[part.partId] = part
    })

    scene.traverse((child: ISceneGraphMesh) => {
      if (child.isMesh) {
        child.castShadow = true
        child.receiveShadow = true

        child.userData = {
          setMaterial: setMaterial, // perhaps userdata.interactions ?
          productType: scene.userData.productType,
          splitFaces: scene.userData.splitFaces,
          ...(child.userData || {}),
          ...(interaction.userData || {})
        }

        child.userData.combinationType = combination.combinationType
        child.userData.modelType = scene.userData.modelType
        child.userData.uvFlipped = scene.userData.uvFlipped
        child.userData.modelSource = scene.userData.modelSource
        child.userData.isTemplate = scene.userData.isTemplate
        child.userData.dbModelId = scene.userData.dbModelId

        if (child.material && modelData.uvMapped) {
          child.material.useTriplanar = false
        }
      }

      let part = partsByNodeId[child.userData.nodeId]

      if (!part && isRoomsetModel) part = partsByOriginalNodeId[child.userData.nodeId]

      if (!part) return
      addPart(child, part, modifications)

      if (part.type) {
        child.userData.type = part.type
        splitParts[child.uuid] = partsByNodeId
      }
    })

    return nodeDict.set(modelData.id, scene)
  }))

  function addPart (node: ISceneGraphMesh, part: Part, modifications?: CombinationModel) {
    _set(node, 'userData.tags', part.tags)

    if (part.name) {
      _set(node, 'userData.name', part.name)
    }

    if (modifications && modifications.generated) {
      node.userData.generated = modifications.generated
    }

    if (part.splitData) {
      node.userData.splitData = part.splitData
    }

    if (part.mapRotation && !_get(node, 'userData.mapRotation')) {
      _set(node, 'userData.mapRotation', part.mapRotation)
    }

    if (part.triplanarTranslation && !_get(node, 'userData.triplanarTranslation')) {
      _set(node, 'userData.triplanarTranslation', new THREE.Vector3().fromArray(part.triplanarTranslation))
    }

    if (part.triplanarOrientation && !_get(node, 'userData.triplanarOrientation')) {
      _set(node, 'userData.triplanarOrientation', new THREE.Vector3().fromArray(part.triplanarOrientation))
    }

    if (part.decalUVTransform) {
      _set(node, 'userData.decalUVTransform', part.decalUVTransform)
      _set(node, 'userData.userDecalRotation', part.userDecalRotation)
    }

    if (typeof part.uvMapRotation !== 'undefined') {
      _set(node, 'userData.uvMapRotation', part.uvMapRotation)
    }

    if (part.mapOffset) {
      _set(node, 'userData.mapOffset', new THREE.Vector2().fromArray(part.mapOffset))
    }

    if (typeof part.useTriplanar !== 'undefined') {
      _set(node, 'userData.useTriplanar', part.useTriplanar)
    }

    // Collect modifications
    if (part.colorTextureMix) {
      stainModifications.push([part.colorTextureMix, node])
    }
    if (part.carrier_id) {
      carrierModifications.push([part.carrier_id, node])
      carrierModifications.push([part.carrierName, node])
    }
    if (part.materialId && part.type !== 'splitmesh') {
      materialModifications[part.materialId] = (materialModifications[part.materialId] || []).concat(node)
    }
    if (part.patternId) {
      patternModifications[part.patternId] = (patternModifications[part.patternId] || []).concat(node)
    }
    if (part.colorId) {
      colorModifications[part.colorId] = (colorModifications[part.colorId] || []).concat(node)
    }
    if ('visible' in part && !part.visible) {
      visibilityModifications.push(node)
    }
  }

  // Add
  const addToTree: ISceneGraphNode3d[] = []
  const doNotAddToTree: ISceneGraphNode3d[] = []
  function addRecursive (group: GroupRecursive, parentNode: null | ISceneGraphNode = null) {
    const node = nodeDict.get(group.id)
    if (node) {
      if (!parentNode) {
        if (
          group.isVirtualProductRoot ||
          combination.combinationType === 'virtual-product'
        ) {
          node.userData.isVirtualProductRoot = true
          node.userData.virtualProductId = group.virtualProductId || combination.virtualProductId
        }
        if (node.userData.override) {
          // We need to get rid of the top node for nodes that will be
          // inserted into the tree to override a split mesh so that
          // the tree in the geometry panel wont have an extra level.
          // > parent
          //   > mesh override (split)
          //     - mesh side 1
          //     - mesh side 2
          //     - mesh side 3
          const overriddenRootNode = nodeDict.get(node.userData.override.combinationModelInternalId)
          const overrideNode = node.children[0]
          if (overriddenRootNode && overrideNode) {
            overrideNode.userData = _omit(node.userData, 'isModelRoot')
            overriddenRootNode.traverse((child: ISceneGraphNode3d) => {
              if (child.userData.nodeId === node.userData.override.nodeId) {
                if (overrideNode) {
                  child.visible = false
                  child.userData.visible = false
                  child.userData.overriddenBy = overrideNode.uuid
                  child.userData.ignore = true
                  child.parent.add(overrideNode)
                }
              }
            })
          }
        } else {
          const interaction = _get(interactions, [node.userData.interactionType])
          if (interaction && interaction.userData.addToTree) {
            addToTree.push(node)
          } else {
            doNotAddToTree.push(node)
          }

          if (combination.combinationType === COMBINATION_TYPES.variant) {
            node.userData.name = combination.title
          }
        }
      } else {
        parentNode.add(node)
      }
      if (group.children) {
        group.children.forEach((child: any) => addRecursive(child, node))
      }
    }
  }

  combination.groups.forEach((group) => addRecursive(group))
  if (addToTree.length) {
    dispatch(fromAddRemoveModelsActions.addModels(addToTree, isComplementary))
  }
  if (doNotAddToTree.length) {
    doNotAddToTree.forEach(node => viewer.addModel(node, node.userData.params))
  }

  /* Temporary fix to filter out Virtual Products from placement.
   This is necessary as the place-function offsets the bounding box of the Virtual Product Root and
   the transforms from UPPLYSA.
   TODO: Fix place-function to compensate offset for Virtual Products. */
  let isVirtualProduct
  Object.values(combination.models).forEach((model) => {
    if (model.virtualProductId || model.isVirtualProductRoot) {
      isVirtualProduct = true
    }
  })
  if (isComplementary || combination.combinationType === 'convert') {
    if (!isVirtualProduct) {
      dispatch(fromPlaceActions.place(instanceId, isComplementary, combination.combinationType))
    }
  }

  // update positions after placing an object
  viewer.transformGizmo.holeSnapHelper.updateHoleMarkers()
  const splitPromises: Promise<string | undefined>[] = []
  const splitNodes: string[] = []
  addToTree.forEach(node => {
    node.traverse((n: ISceneGraphMesh) => {
      // Checks if there are any nodes that should be split but aren't and splits them
      if (n.userData.type === 'splitmesh') {
        splitPromises.push(dispatch(fromThreeviewerSplit.splitFace(n.uuid)))
        splitNodes.push(n.uuid)
      }
    })
  })
  const ids = await Promise.all(splitPromises)

  // Matches new splits with correct parts in order to set materials etc.
  ids.forEach((newUUID, index) => {
    const oldUUID = splitNodes[index]
    if (!newUUID || !oldUUID) return
    const node = (getNodeList(getState())[newUUID || ''] || Object.values(viewer.picker.selection)[0]) as ISceneGraphNode3d | undefined
    if (!node) return
    const partsByNodeId = splitParts[oldUUID]
    node.traverse((child: ISceneGraphMesh) => {
      // As nodeIds are "fixed" we must match using these "fixed" id:s
      const nodeId = getFixedPartId(child.userData.nodeId)
      const part = partsByNodeId[nodeId]
      if (!part) return
      addPart(child, part)
    })
  })

  // Retrieves recently added model and sends the model to the camera as thw new target for orbitControls.
  // This event is required to make sure that the camera will focus on the most recently added object,
  if (addToTree.length) {
    dispatch(fromThreeviewerCamera.setCameraTarget(addToTree[0]))
  }

  // Modify
  const materialIds = Object.keys(materialModifications)
  await dispatch(fromMaterials.prefetchPreloadMaterials(materialIds))
  await Promise.all(Object.entries(materialModifications).map(([materialId, nodes]) => {
    return dispatch(fromMaterials.setMaterial(materialId, nodes, false))
  }))

  dispatch(applyModifications({
    patterns: patternModifications,
    colors: colorModifications,
    stains: stainModifications,
    hidden: visibilityModifications,
    carriers: carrierModifications
  }))

  if (
    fromThreeviewerSelectors.getIsFeatureActive(state)(FEATURES.IMAGE_TEMPLATES) &&
    combination.imageTemplate &&
    !isComplementary
  ) {
    dispatch(loadSavedImageTemplateCameraSettings(
      combination.imageTemplate,
      combination,
      false
    ))
  }

  if (!isComplementary && combination.connectedBatchGeometryCombinationIds) {
    const existingCombinationIds = combination.connectedBatchGeometryCombinationIds.filter(id => {
      return !!state.combinations.entries[id]
    })
    dispatch(connectCombinationsForBatchRender(existingCombinationIds))
  }
  dispatch(fromDefaultTemplate.adjustWallInScene())
  files.forEach((file) => dispatch(fromThreeviewerFiles.loaded('model', file)))

  if (combination.customHomeSchema) {
    dispatch(customHome.loadSchema(combination.customHomeSchema))
  }

  dispatch(fromThreeviewerFiles.confirmCombinationsLoaded([combination.id]))

  viewer.scene.traverse((node: ISceneGraphNode3d) => {
    if (node.userData.isVirtualProductRoot) {
      const bbCenter = new viewer.THREE.Vector3()
      const bb = viewer.viewerUtils.getBoundingBox(node.children)
      bb.getCenter(bbCenter)
      // node.localBoundingBox?.getCenter(bbCenter)
      const virtualProductId = node.userData.virtualProductId
      const virtualProductCombinationNode = combination.nodes.find(combinationNode => combinationNode.isVirtualProductRoot &&
        combinationNode.virtualProductId === virtualProductId)
      if (virtualProductCombinationNode?.virtualProductTransform) {
        const transform = virtualProductCombinationNode?.virtualProductTransform
        const virtualProductTransform = new viewer.THREE.Matrix4().fromArray(transform)
        virtualProductTransform.invert()
        bbCenter.applyMatrix4(virtualProductTransform)
      }
      node.userData.originalBBCenter = bbCenter
    }
  })
  return nodeDict
}

function loadTemplate (combination: VisualizedCombination): AppThunk {
  return async (dispatch) => {
    // Load template!
    const _roomsetId = combination.roomsetId
    const _templateId = _roomsetId || combination.templateId || 'default'
    const modifications = _isEmpty(combination.roomsetModels)
      ? combination.templateModels
      : combination.roomsetModels

    if (_templateId && _templateId !== 'custom-home') {
      return dispatch(fromTemplates.load(_templateId, { modifications }))
    }
  }
}

function applyModifications (modifications: {
  colors: { [colorId: string]: any[] }
  hidden: any[]
  stains: [any, ISceneGraphMesh][]
  carriers: [any, ISceneGraphMesh][]
  patterns: { [patternId: string]: ISceneGraphMesh[] }
}): AppThunk {
  return async (dispatch, getState) => {
    // Apply visibility modifications
    dispatch(fromSelection.applyHiddenModification(modifications.hidden.map(n => n.uuid)))

    Object.entries(modifications.colors).forEach(([colorId, parts]) => {
      dispatch(fromColors.setColor({
        id: colorId,
        parts: parts,
        addToUndoHistory: false
      }))
    })

    modifications.carriers.forEach(([carrier, node]) => {
      if (carrier) {
        node.userData.carrier_id = carrier
        node.userData.carrierName = carrier
      }
    })

    modifications.stains.forEach(([colorTextureMix, node]) => {
      if (node.material && node.material.useColorTextureMix) {
        node.userData.colorTextureMix = colorTextureMix
        node.material.colorTextureMix = colorTextureMix
        node.material.needsUpdate = true
      }
    })

    const state = getState()
    const patternEntries = getPatternEntries(state)

    const patternsToFetch: string[] = []
    Object.keys(modifications.patterns).forEach(patternId => {
      if (!patternEntries[patternId]) {
        patternsToFetch.push(patternId)
      }
    })

    if (patternsToFetch.length) {
      await dispatch(fromPatternsJson.getPatterns(patternsToFetch))
    }

    Object.entries(modifications.patterns).forEach(([patternId, nodes]) => {
      nodes.forEach(node => {
        dispatch(fromPatternsTextures.setPatternOnParts({
          patternId,
          parts: [node],
          decalUVTransform: node.userData.decalUVTransform,
          userDecalRotation: node.userData.userDecalRotation
        }))
      })
    })
  }
}
