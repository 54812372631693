import React, { useEffect, useState } from 'react'

import UploadFiles from '../../upload-files'
import UploadCard from '../../project/card/UploadCard'
import AppearanceModal from './appearance-modal'

interface Props {
  supportedFormatMsg: (msg: string) => void
}

export default (props: Props) => {
  const [files, setFiles] = useState<File[] | null>(null)
  const supportedFormats = ['.tar', '.gz', '.zip']

  useEffect(() => {
    if (files && files.length === 0) {
      props.supportedFormatMsg(`Supported formats are ${supportedFormats.join(' ')}`)
    }
  }, [files])

  return (
    <div>
      <UploadFiles
        onUpload={(files: File[]) => setFiles(files)}
        multiple
        supportedFormats={supportedFormats}
      >
        {({ isDragging }: { isDragging: boolean }) => (
          <UploadCard
            type='Appearance'
            isDragging={isDragging}
          />
        )}
      </UploadFiles>
      <AppearanceModal
        isOpen={Boolean(files && files.length > 0)}
        onClose={() => setFiles(null)}
        files={files}
      />
    </div>
  )
}
