import React from 'react'
import { AutoSizer } from 'react-virtualized'
import createTable from './table'

export default function ProjectsTable ({ numberOfProjects, numberOfPPMProjects, searchedProjects }) {
  const ppmPercentage = ((numberOfPPMProjects / numberOfProjects) * 100).toFixed(2)
  return (
    <AutoSizer >
      {({ width, height }) => {
        const _width = width - 20
        const textStyleBold = { width: _width, fontWeight: 'bold' }
        return (
          <>
            {createTable({
              width: _width,
              height: (2 * height / 3),
              data: searchedProjects,
              cols: [{ label: 'Title', key: 'title' }, { label: 'Owner', key: 'owner' }, { label: 'Members', key: 'members', width: 20 }, { label: 'Renders', key: 'renders', width: 20 }],
              headerTitle: 'Projects',
              noDataText: 'No Projects'
            })}

            {numberOfProjects && <div style={textStyleBold} className="pt2">{`Total amount of projects: ${numberOfProjects}`}</div>}
            {numberOfPPMProjects && <div style={textStyleBold} className="pt2">{`PPM projects: ${numberOfPPMProjects}`} ({ppmPercentage}%)</div>}
          </>
        )
      }}

    </AutoSizer>)
}
