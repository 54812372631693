import _get from 'lodash/get'

// selectors
import * as fromThreeviewerSelectors from '../../threeviewer/selectors'
import * as fromCombinationSelectors from '../../combinations/selectors'
import * as fromProjectSelectors from '../../projects/selectors'
import * as fromRoomsetSelectors from '../../roomsets/selectors'

// actions
import * as fromRenders from '../../renders'
import * as fromJsonActions from '../../combinations/actions/json'
import * as fromFolders from '../../folders'
import * as fromFoldersApi from '../../folders/api'

import error from './error'
import { getChangedModels, findVisibleNodesRecursive } from './get-changed-models'
import * as api from '../api'

import fetch from '../../../../utils/fetch'
// @ts-ignore
import Client from '@inter-ikea-digital/iig-rpd-dpd-packages-storage-api-client'

import {
  getTransform
} from './utils'

import {
  RENDER_PRESETS, RENDER_RESOLUTIONS
} from '../../../../constants'

import { v4 as uuid } from 'uuid'
import { AppThunk } from '../..'
import { VisualizedCombination, CombinationModel, CreateCombination, Combination, Part } from '../Combination'
import { Go3DViewer } from '../../../../../../go3dthree/types/Go3DViewer'
import { SceneGraphMesh, SceneGraphNode3d as ISceneGraphNode3d, SceneGraphNode3d } from '../../../../../../go3dthree/types/SceneGraph'
import SeamlessImmutable, { ImmutableObject } from 'seamless-immutable'
import { CreateRender, Render } from '../../renders/Render'
import { DEFAULT_TEMPLATE_ID } from '../../templates'
import { findGeneratedNodesRecursive, exportNodes, exportCustomHome } from './get-generated-nodes'
import { StorageApiManifest } from '../../../../utils/storage'

type Vec2 = { x: number, y: number }
type Preset = {
  id: string
  aspectRatio: { [id: string]: Vec2 }
  resolutions: { default: Vec2 }
  // eslint-disable-next-line camelcase
  vrscenes: { default: string, no_env: string, easyHome: string }
}

export type PostRenderData = {
  combination: CreateCombination
  render: CreateRender
  models: { [id: string]: CombinationModel }
  generatedModelData: { id: string, manifest: StorageApiManifest, manifestKey: string, metadata: { [key: string]: any } }[]
}

enum RenderType {
  ImageTemplate,
  Variant,
  Roomset,
  CustomHome,
  Default
}

const client = Client('/api/storage')

const getRenderResolution = (preset: Preset, aspectRatio: Vec2) => {
  const { x, y } = aspectRatio
  const proportion = x === y ? 'square' : (x < y ? 'portrait' : 'landscape')

  if (_get(preset, ['resolutions', proportion])) {
    return _get(preset, ['resolutions', proportion])
  }

  const resolution = preset.resolutions.default
  const size = Math.max(resolution.x, resolution.y)

  if (x === y) {
    return {
      x: size,
      y: size
    }
  } else if (x < y) {
    return {
      x: size * (x / y),
      y: size
    }
  } else {
    return {
      x: size,
      y: size * (y / x)
    }
  }
}

function getCostEstimations (
  viewer: Go3DViewer,
  sourceCombination?: SeamlessImmutable.ImmutableObject<VisualizedCombination> | SeamlessImmutable.ImmutableObject<Combination>,
) {
  const costEstimations: { [key: string]: any } = {}
  let totalCost = 0

  if (sourceCombination && sourceCombination.costEstimations) {
    for (const key in sourceCombination.costEstimations) {
      costEstimations[key] = {
        areaCost: sourceCombination.costEstimations[key].areaCost,
        volumeCost: sourceCombination.costEstimations[key].volumeCost
      }
    }
  }

  viewer.scene.traverse((object: SceneGraphMesh) => {
    if (object.isMesh) {
      const materialId = object.material.materialId
      Object.keys(costEstimations).forEach(key => {
        if (key === materialId) {
          const volumeCost = _get(object, ['userData', 'volume'], 0) * _get(costEstimations, [key, 'volumeCost'], 0)
          const areaCost = _get(object, ['userData', 'area'], 0) * _get(costEstimations, [key, 'areaCost'], 0)
          totalCost += volumeCost + areaCost
        }
      })
    }
  })

  costEstimations.totalCost = Number(totalCost.toFixed(2))

  return costEstimations as CreateCombination['costEstimations']
}

export async function uploadFiles (files: File[]) {
  const manifestKey = await client.upload(files)
  const res = await fetch(`/api/storage/get/${manifestKey}.json`)
  return { manifest: await res.json(), manifestKey }
}

export function postRender ({
  cameraSettings,
  combinationId,
  combinationType,
  dontAddParent = false,
  imagePackageSettings,
  projectId,
  renderSettings,
  title
}: {
  cameraSettings?: Partial<Render['cameraSettings']>
  combinationId?: string
  combinationType: CreateCombination['combinationType']
  dontAddParent?: boolean,
  imagePackageSettings?: {
    batchId: string
    doBatchRender: boolean
    imagePackageId: string
    projectType: string
  },
  projectId?: string,
  renderSettings?: {
    ignoreAnnotations: boolean
    preset?: Preset
    useEnvironment: boolean
  },
  title: string
}): AppThunk<Promise<any>> {
  return async (dispatch, getState) => {
    const state = getState()
    const viewer = state.threeviewer.viewer
    if (!viewer) return
    const entries = fromCombinationSelectors.getEntries(state)
    const cameraState = fromThreeviewerSelectors.getCameraState(state)
    const _combinationId = combinationId || state.combinations.currentId
    const _projectId = projectId || (state.projects.currentId ?? null)
    const connectedBatchGeometryCombinationIds = state.combinations.connectedBatchGeometryCombinationIds.asMutable() as string[]
    const templateId: null | string = state.templates.activeId
    const activeCamera = state.threeviewer.camera.activeCamera
    const activeRoomset = fromRoomsetSelectors.getCurrentEntry(state)
    const sourceCombination = state.combinations.entries.getIn([_combinationId || '']) as ImmutableObject<VisualizedCombination>
    const renderPreset = renderSettings?.preset
    const useEnvironment = renderSettings?.useEnvironment
    const imageTemplate = activeCamera.imageTemplate

    Object.values(sourceCombination.models).filter(model => {
      return model.isVirtualProductRoot
    })

    // Reasonable fallbacks
    let resolution = RENDER_PRESETS['1'].resolutions.default
    let template = RENDER_PRESETS['1'].vrscenes.default

    if (renderPreset) {
      resolution = getRenderResolution(renderPreset, viewer.postProcessManager.aspectRatio)
      if (!useEnvironment) template = renderPreset.vrscenes.no_env
      if (!template) template = renderPreset.vrscenes.default
    }

    const combinationData: CreateCombination = {
      title: title || sourceCombination.title,
      combinationType,
      costEstimations: getCostEstimations(viewer, sourceCombination),
      projectId: _projectId,
      annotations: sourceCombination?.annotations,
      roomsetId: (activeRoomset && activeRoomset.id) ?? null,
      templateId
    }

    // We need to sanity check near variable as it is sometimes set to 0
    // Should probably be done earlier so the state can't be wrong?
    let near = _get(cameraState, 'near', viewer.camera.near)
    near = near === 0 ? viewer.camera.near : near

    const renderData: CreateRender = {
      cameraSettings: {
        resolution,
        near: near,
        transform: getTransform(_get(cameraSettings, 'matrixWorld', viewer.camera.matrixWorld)),
        fov: viewer.THREE.MathUtils.degToRad(_get(cameraSettings, 'fov', viewer.cameraManager.desiredFov))
      },
      extension: _get(renderPreset, 'extension', _get(imagePackageSettings, 'extension', 'png')),
      renderQuality: renderPreset && renderPreset.id,
      renderEnvironment: useEnvironment,
      dynamicEnvironment: templateId === DEFAULT_TEMPLATE_ID && useEnvironment,
      template: template,
      overrides: []
    }

    let renderType = RenderType.Default

    if (imageTemplate && activeCamera.id) {
      renderType = RenderType.ImageTemplate
    } else if (combinationType === 'variant') {
      renderType = RenderType.Variant
    } else if (viewer.roomManager.hasWallNodesInDrawing()) {
      renderType = RenderType.CustomHome
    } else if (activeRoomset && combinationData.roomsetId) {
      renderType = RenderType.Roomset
    }

    if (renderType !== RenderType.Roomset) {
      combinationData.roomsetId = null
      renderData.roomsetId = null
    }

    if (viewer.annotations.isEnabled() && !renderSettings?.ignoreAnnotations) {
      renderData.annotationSvg = viewer.generateAnnotationSVG(resolution.x, resolution.y)
    }

    const generatedNodes = findGeneratedNodesRecursive(viewer.scene as unknown as ISceneGraphNode3d)
    const generatedModels = dispatch(getChangedModels(generatedNodes))
    const generatedModelFileData = await exportNodes(generatedNodes, viewer)

    let isolatedModels: { [id: string]: CombinationModel } = {}
    viewer.scene.children.forEach((child: SceneGraphNode3d) => {
      if (child.userData.isVirtualProductRoot) {
        // Get the current bounding box center of the entire VP
        const bb = viewer.viewerUtils.getBoundingBox(child.children)
        const bbCenter = new viewer.THREE.Vector3()
        bb.getCenter(bbCenter)

        // Get the difference between an objects original rotation (from UPPLYSA) and the current one.
        // This should be equal to the total VP rotation
        const dummy = child.children[0]
        const dummyRotation = new viewer.THREE.Matrix4().extractRotation(dummy.matrixWorld)
        const originalRotationInverse = new viewer.THREE.Matrix4().fromArray(dummy.userData.originalVPRotation).invert()
        dummyRotation.multiply(originalRotationInverse)

        const originalBBCenter = new viewer.THREE.Vector3().copy(child.userData.originalBBCenter)
        const bbCenterRotation = new viewer.THREE.Matrix4().copy(dummyRotation)

        // Calculate the movement of the VP center point in relation to the new BB-center
        const vpOffset = new viewer.THREE.Vector3().copy(originalBBCenter.clone().negate())
        vpOffset.applyMatrix4(bbCenterRotation)
        vpOffset.add(bbCenter)

        // construct the virtualProductTransform
        const virtualProductRotation = new viewer.THREE.Matrix4().copy(bbCenterRotation)
        const virtualProductTranslation = new viewer.THREE.Matrix4().setPosition(vpOffset)

        // Apply rotation then translation
        const virtualProductTransform = new viewer.THREE.Matrix4()
        virtualProductTransform.premultiply(virtualProductRotation)
        virtualProductTransform.premultiply(virtualProductTranslation)

        child.userData.virtualProductTransform = virtualProductTransform.elements
      }
    })

    if (renderType === RenderType.Variant) {
      const visibleNodes = findVisibleNodesRecursive(viewer.scene)
      combinationData.templateId = 'default'

      if (visibleNodes.size) {
        const _uuid = uuid()
        const children: string[] = []
        isolatedModels = dispatch(getChangedModels(visibleNodes))
        viewer.scene.children.forEach((child: any) => {
          if (isolatedModels[child.uuid]) children.push(child.uuid)
        })
        const group: CombinationModel = {
          name: title,
          id: _uuid,
          uuid: _uuid,
          isGroup: true,
          children: children
        }
        isolatedModels[_uuid] = group
      }
    } else if (renderType === RenderType.ImageTemplate) {
      combinationData.imageTemplateId = imageTemplate!.id

      // Pass along if design renders should also be created
      combinationData.designRender = state.threeviewer.imageTemplates.designRenderEnabled

      combinationData.title = (
        title ||
        sourceCombination.originalTitle ||
        sourceCombination.title ||
        _get(sourceCombination, 'nodes.0.title')
      )
      renderData.dynamicEnvironment = false
      renderData.overrides = renderPreset ? [renderPreset.vrscenes.default] : []
      renderData.template = imageTemplate!.vrscene!

      isolatedModels = dispatch(getChangedModels(findVisibleNodesRecursive(viewer.scene)))

      const camera = imageTemplate!.cameras![activeCamera.id!]
      const { x, y } = camera.aspectRatio
      const res = renderPreset ? renderPreset.resolutions.default : RENDER_RESOLUTIONS.square
      const size = Math.max(res.x, res.y)
      const minScreen = Math.min(viewer.camera.view!.fullWidth, viewer.camera.view!.fullHeight)
      const aspect = resolution.x / resolution.y
      const { translateY, translateX, zoom } = viewer.imageTemplateManager.offsetView

      renderData.cameraSettings = {
        ...camera,
        targetPlacement: viewer.imageTemplateManager.targetPlacement,
        targetPosition: viewer.imageTemplateManager.targetPosition.toArray(),
        contentRatioToFullSize: viewer.imageTemplateManager.productRatioToFullSize,
        near: near,
        transform: getTransform(_get(cameraSettings, 'matrixWorld', viewer.camera.matrixWorld)),
        zoom_factor: zoom,
        horizontal_offset: -(translateX * zoom / minScreen * aspect),
        vertical_offset: translateY * zoom / minScreen * aspect,
        resolution: {
          x: Math.round(x <= y ? size : (x / y) * size),
          y: Math.round(y <= x ? size : (y / x) * size)
        }
      }
    } else if (renderType === RenderType.Roomset) {
      renderData.template = activeRoomset!.vrscene!
      renderData.overrides = renderPreset ? [renderPreset.vrscenes.default] : []
      renderData.roomsetId = combinationData.roomsetId

      if (activeCamera.isPredefined && typeof activeCamera.id === 'string') {
        const predefinedCamera = fromRoomsetSelectors.getCamera(combinationData.roomsetId!)(activeCamera.id)(state)
        const { x, y } = predefinedCamera.aspectRatio
        const renderCamRes = renderData.cameraSettings.resolution
        const renderCamMaxRes = Math.max(renderCamRes.x, renderCamRes.y)
        // Overwrite with cameraSettings from predefined camera
        // But only the aspect. resolutions are still based of cameraSettings
        renderData.cameraSettings = {
          ...predefinedCamera,
          resolution: {
            x: Math.floor(x >= y ? renderCamMaxRes : (x / y) * renderCamMaxRes),
            y: Math.floor(y >= x ? renderCamMaxRes : (y / x) * renderCamMaxRes)
          },
          isPredefined: true
        }
      }
    }

    // Custom home renders overwrites other type of renders.
    if (renderType === RenderType.CustomHome && renderSettings?.preset) {
      const changes = dispatch(getChangedModels(new Set([viewer.roomManager.roomObject]))) as { [key: string]: CombinationModel }
      const customHomeAppearanceAssignments: { [id: number]: Part } = {}
      Object.values(changes).forEach((model) => model.parts?.forEach((p) => p.kvadratMeshId && (customHomeAppearanceAssignments[p.kvadratMeshId] = p)))
      const file = await exportCustomHome(viewer.roomManager.roomObject, viewer)
      combinationData.gltf = file
      combinationData.customHomeAppearanceAssignments = customHomeAppearanceAssignments
      combinationData.customHomeSchema = viewer.roomManager.schema

      // Custom Home renders uses the generated gltf file as the template (see core/deadline-helpers/render).
      // The gltf file is then converted to a vrscene in vrscene_builder so we can use it as a template.
      // For light, camera, and other render settings, we want to use the easyHome vrscene, so we send it in as an override.
      renderData.overrides.push(renderSettings.preset.vrscenes.easyHome)
    }

    // generate realtime placeholder image
    const file = viewer.canvasImageCapturer.generateFilefromCanvas('canvas-image.jpeg')
    renderData.manifest = (await uploadFiles([file])).manifest

    if (activeCamera.imageTemplate && connectedBatchGeometryCombinationIds.length > 0) {
      combinationData.title = (
        sourceCombination.originalTitle ||
        sourceCombination.title ||
        _get(sourceCombination, 'nodes.0.title') ||
        title
      )
      combinationData.originalTitle = sourceCombination.originalTitle || combinationData.title
      combinationData.batchRenderTitle = title
      combinationData.connectedBatchGeometryCombinationIds = connectedBatchGeometryCombinationIds
    }

    const postRenderData: PostRenderData = {
      combination: combinationData,
      render: renderData,
      generatedModelData: generatedModelFileData,
      models: {
        ...(Object.keys(isolatedModels).length ? isolatedModels : dispatch(getChangedModels())),
        ...generatedModels
      }
    }

    dispatch(fromRenders.postRequest())

    if (imagePackageSettings) {
      // Setting parentId to null will create a parent combination,
      // otherwise it is considered a render of a parent combination
      const parentId = dontAddParent ? null : (_get(entries, [_combinationId || '', 'parentId']) || combinationId)

      return dispatch(createImagePackage({
        ...postRenderData,
        templateId,
        projectId,
        combination: {
          ...postRenderData.combination,
          parentId,
          sourceCombinationId: combinationId,
          title: title || sourceCombination.title,
        },
        batchId: imagePackageSettings.batchId,
        projectType: imagePackageSettings.projectType,
        doBatchRender: imagePackageSettings.doBatchRender,
        imagePackageId: imagePackageSettings.imagePackageId,
        parentId: _get(entries, [_combinationId || '', 'parentId']) || _combinationId
      }))
    }
    return dispatch(create(postRenderData))
  }
}

function createImagePackage (payload: any): AppThunk<Promise<any>> {
  return (dispatch) => {
    return api.createImagePackage(payload)
      .then((json: any) => {
        if (payload.doBatchRender) {
          dispatch(fromJsonActions.receive(json))
        } else {
          dispatch(fromJsonActions.receive(json.parentCombination))
          dispatch(fromJsonActions.receive(json.combination))
        }
        return new Promise((resolve) => {
          setTimeout(() => {
            dispatch(fromRenders.postReceive())
            resolve(json)
          }, 2000)
        })
      })
      .catch((err: Error) => dispatch(error(err)))
  }
}

function create (payload: PostRenderData): AppThunk<Promise<any>> {
  return (dispatch, getState) => {
    return api.create(payload)
      .then(async (json: VisualizedCombination) => {
        const state = getState()

        if (json.imageTemplateId) {
          const folderId = 'template-images_' + fromProjectSelectors.getCurrentId(state)
          const folder = await fromFoldersApi.get(folderId)
          if (folder) {
            dispatch(fromFolders.addConnectedIds([json.id], [], folder.id))
          } else {
            dispatch(fromFolders.create({
              id: folderId,
              title: 'Template images',
              locked: true,
              type: 'template-images',
              color: {
                hsl: '0,0%,90%'
              },
              combinationIds: [json.id]
            }))
          }
        }

        // small timeout to disable create/save design buttons to prevent spamming
        // of create render/combination :)
        setTimeout(() => { dispatch(fromRenders.postReceive()) }, 2000)
      })
      .catch((err: Error) => dispatch(error(err)))
  }
}
