import React from 'react'
import Circle from '../circle'
import PropTypes from 'prop-types'
import cs from 'classnames'

type Props = {
  onClick: (event: MouseEvent) => void
  isActive: boolean
  count: number
  className: string
  size: number
  style: React.CSSProperties
}

const CommentCounter = ({
  onClick,
  isActive,
  count,
  className,
  size,
  style
}:Props) => (
  <Circle
    // @ts-ignore
    onClick={onClick}
    size={size}
    className={cs(
      'f7',
      'paper-1',
      'bg-secondary-hover c-white-hover',
      'pointer',
      className,
      {
        'bg-secondary c-white': isActive,
        'bg-secondary-lighter c-gray-dark': !isActive
      }
    )}
    style={style}
  >
    {count}
  </Circle>
)

CommentCounter.defaultProps = {
  className: '',
  style: {},
  size: 24
}

CommentCounter.propTypes = {
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
  count: PropTypes.number,
  className: PropTypes.string,
  size: PropTypes.number,
  style: PropTypes.object
}

export default CommentCounter
