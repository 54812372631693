import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cs from 'classnames'

import colors from '../../../css/colors'

import ProgressRadial from './progress-radial'
import Square from './Square'
import IconMultipleImages from './icons/icon-multiple-images'

const INITIAL = 'Initial'
const POSTED = 'Posted'
const PENDING = 'Pending'
const ACTIVE = 'Active'
const COMPLETED = 'Completed'
const FAILED = 'Failed'
const SUSPENDED = 'Suspended'
const QUEUED = 'Queued'
const RENDERING = 'Rendering'

const styles = {
  button: { width: 25, height: 25 },
  image: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: '90%'
  },
  pendingOverlay: { backgroundColor: 'rgba(255,255,255, 0.75)' }
}

const _percentage = (isRender) => {
  return (status, renderPercentage) => {
    if (isRender && renderPercentage) return renderPercentage
    return {
      [INITIAL]: 5,
      [POSTED]: 10,
      [QUEUED]: 15,
      [PENDING]: 17,
      [ACTIVE]: 25,
      [RENDERING]: 25,
      [COMPLETED]: 100
    }[status]
  }
}

const _statusText = (isRender, multipleRenders = false) => {
  return {
    [INITIAL]: isRender ? (multipleRenders ? 'Creating images' : 'Creating image') : 'Job Created',
    [POSTED]: isRender ? (multipleRenders ? 'Creating images' : 'Creating image') : 'Job Posted',
    [QUEUED]: isRender ? (multipleRenders ? 'Creating images' : 'Creating image') : 'Queued',
    [PENDING]: isRender ? (multipleRenders ? 'Creating images' : 'Creating image') : 'Pending',
    [ACTIVE]: isRender ? (multipleRenders ? 'Creating images' : 'Creating image') : 'Converting',
    [RENDERING]: isRender ? (multipleRenders ? 'Creating images' : 'Creating image') : 'Converting',
    [COMPLETED]: 'Done',
    [FAILED]: 'Failed',
    [SUSPENDED]: 'Suspended'
  }
}

const _didFail = (status) => (['Failed', 'Suspended'].indexOf(status) !== -1)

const PendingInner = ({
  backgroundImage,
  disableButtons,
  hideTitle,
  percentage,
  replacedDeadlineStatus,
  renderPercentage,
  status,
  statusText,
  onCancel,
  onGoToFailedRender
}) => {
  return ([
    _didFail(replacedDeadlineStatus) && !disableButtons && (
      <div key='icons'>
        {backgroundImage && <button
          className='pointer absolute top-0 left-0 m1 f5 c-error bg-error-hover c-white-hover circle border flex justify-center'
          style={styles.button}
          onClick={onCancel}
        >
          <i className='icon-cancel' />
        </button>}
        <button
          className='pointer absolute bottom-0 right-0 m1 f5 c-secondary bg-secondary-hover c-white-hover circle border flex justify-center'
          style={styles.button}
          onClick={onGoToFailedRender}
        >
          <i className='icon-cube' />
        </button>
      </div>
    ),
    <div key='radials' className='height-50'>
      {_didFail(replacedDeadlineStatus) ? (
        <ProgressRadial
          hideLabel
          strokeWidth={10}
          background={colors.errorLightAlt}
          percentage={95}
        />
      ) : (
        <ProgressRadial
          hideLabel
          strokeWidth={10}
          background={colors.success}
          percentage={percentage(replacedDeadlineStatus || (status === INITIAL ? INITIAL : POSTED), renderPercentage)}
        />
      )}
    </div>,
    !hideTitle && <small key='status' className='mx1 mt1 f8 center'>{statusText[replacedDeadlineStatus || (status === INITIAL ? INITIAL : POSTED)]}</small>
  ])
}

class Pending extends Component {
  constructor () {
    super()

    this.handleCancel = this.handleCancel.bind(this)
  }

  handleCancel (e) {
    e.stopPropagation()
    this.props.onCancel(this.props.id)
  }

  render () {
    const {
      active,
      backgroundImage,
      disableButtons,
      deadlineStatus,
      multipleRenders,
      hideTitle,
      renderStatus,
      renderPercentage,
      status,
      onClick,
      onGoToFailedRender,
      smallerIcon,
      isVersioned
    } = this.props

    const statusText = _statusText(!!renderStatus, multipleRenders)
    const percentage = _percentage(!!renderStatus)
    const replacedDeadlineStatus = deadlineStatus || status

    if (isVersioned) {
      return (
        <Square
          onClick={backgroundImage && onClick}
          backgroundImage={backgroundImage}
          className={cs('relative', {
            pointer: backgroundImage
          })}
          hoverable={backgroundImage}
          pad={0}
          innerStyle={styles.image}
        >
          <div
            className={cs('absolute width-100 height-100 flex flex-column justify-center', {
              'bs-secondary': active
            })}
            style={styles.pendingOverlay}
          >
            <PendingInner
              status={status}
              renderPercentage={renderPercentage}
              statusText={statusText}
              percentage={percentage}
              replacedDeadlineStatus={replacedDeadlineStatus}
              disableButtons={disableButtons}
              onCancel={this.handleCancel}
              onGoToFailedRender={onGoToFailedRender}
              hideTitle={hideTitle}
              backgroundImage={backgroundImage}
            />
            <IconMultipleImages
              size={smallerIcon ? 14 : 18}
              color={colors.secondary}
              className='absolute top-0 right-0 m1'
              style={{ margin: smallerIcon && '4' }}
            />
          </div>
        </Square>
      )
    }

    return (

      <Square
        backgroundImage={backgroundImage}
        className='relative'
        innerClass={cs('flex flex-column justify-center', {
          'bs-secondary': active
        })}
      >
        <PendingInner
          status={status}
          renderPercentage={renderPercentage}
          statusText={statusText}
          percentage={percentage}
          replacedDeadlineStatus={replacedDeadlineStatus}
          disableButtons={disableButtons}
          onCancel={this.handleCancel}
          onGoToFailedRender={onGoToFailedRender}
          hideTitle={hideTitle}
          backgroundImage={backgroundImage}
        />
      </Square>
    )
  }
}

Pending.propTypes = {
  active: PropTypes.bool,
  backgroundImage: PropTypes.string,
  disableButtons: PropTypes.bool,
  deadlineStatus: PropTypes.string,
  hideTitle: PropTypes.bool,
  multipleRenders: PropTypes.bool,
  renderStatus: PropTypes.string,
  renderPercentage: PropTypes.number,
  status: PropTypes.string,
  onCancel: PropTypes.func,
  onClick: PropTypes.func,
  onGoToFailedRender: PropTypes.func,
  smallerIcon: PropTypes.bool,
  isVersioned: PropTypes.bool
}

Pending.defaultProps = {
  onCancel () { }
}

export default Pending
