import React from 'react'

const IconPositionNew = props => (
  <svg width={props.width || props.size} height={props.height || props.size} viewBox="2 2 20 20" {...props}>
    <path
      d="M11.6737284,2.16276082 C11.848226,1.94447641 12.1258123,1.94702011 12.2982764,2.16276082 L12.2982764,2.16276082 L16.1146078,6.93672641 C16.2891054,7.15501082 16.2016945,7.32735953 15.9239119,7.32735953 L15.9239119,7.32735953 L13.0957339,7.327 L13.0957339,12.913 L16.6657339,12.913 L16.6661422,10.0860591 C16.6661422,9.8082765 16.8384909,9.72086561 17.0567753,9.89536322 L21.8307409,13.7116946 C22.0464816,13.8841587 22.0490253,14.161745 21.8307409,14.3362426 L17.0567753,18.1525739 C16.8410346,18.3250381 16.6661422,18.2452977 16.6661422,17.9618781 L16.6657339,15.134 L12.4217339,15.134 L10.0567339,18.511 L12.1579469,20.7632911 C12.3473941,20.9664484 12.2809604,21.1479182 12.0023102,21.1691684 L5.90812104,21.633917 C5.63271798,21.6549195 5.44154429,21.4536406 5.48218031,21.1771515 L6.370906,15.1302267 C6.41106848,14.8569596 6.59335956,14.7960018 6.78665125,15.0032817 L8.50873393,16.85 L10.9195471,13.4085517 C10.9459937,13.370782 10.9765892,13.3376848 11.0102454,13.3094601 C10.9268007,13.2216459 10.8757826,13.1002584 10.8757826,12.9701722 L10.8757339,7.327 L8.04809295,7.32735953 C7.79301532,7.32735953 7.70291778,7.18569668 7.81317543,7.00008913 L7.85739707,6.93672641 Z" id="Combined-Shape"
      fill={props.color}
    ></path>
  </svg>
)

IconPositionNew.defaultProps = {
  size: '1em',
  color: 'currentcolor'
}

export default IconPositionNew
