import css from './index.css'
import colors from '../../../../../css/colors'
import styled from 'styled-components'

const Swatch = styled.div.attrs(() => ({
  className: `bc-gray-dark ${css.hoverInset}`
}))`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: ${props => props.outline ? `1px solid ${colors.todo_ddd}` : 'initial'};
  box-shadow: ${props => props.selected ? `inset 0 0 0 2px ${colors.grayDark}, inset 0 0 0 5px ${colors.white}` : ''};
`

export default Swatch
