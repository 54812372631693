import { Mesh, BufferGeometry, Float32BufferAttribute, Vector3, Quaternion, BufferAttribute } from 'three'
import { SceneGraphMesh as ISceneGraphMesh } from '../../../types/SceneGraph'
import { BufferGeometryUtils } from 'three/examples/jsm/utils/BufferGeometryUtils'
import type { Go3DViewer } from '../../../types/Go3DViewer'
import PreviewMaterial from './PreviewMaterial/PreviewMaterial'

export enum Split { xa, xb, ya, yb, za, zb }
export type IVec3 = [number, number, number]
export type IVec2 = [number, number]

interface Bucket {
  position: number[]
  normal: number[]
  uv: number[]
  index: number[]
  axises: number[]
  indexTable: Map<number, number>
  indexIterator: number
}

function getDominantFacingDirectionOfSurfaceNormal (value: IVec3) {
  const x = Math.abs(value[0])
  const y = Math.abs(value[1])
  const z = Math.abs(value[2])
  const max = Math.max(x, y, z)

  if (max === x) {
    const _x = Math.sign(value[0])
    if (_x === 1) { return 0 }
    if (_x === -1) { return 1 }
  }

  if (max === y) {
    const _y = Math.sign(value[1])
    if (_y === 1) { return 2 }
    if (_y === -1) { return 3 }
  }

  if (max === z) {
    const _z = Math.sign(value[2])
    if (_z === 1) { return 4 }
    if (_z === -1) { return 5 }
  }

  return 6
}

export class SplitTool {
  private addedPreview = false
  private previewMaterial = new PreviewMaterial()
  previewMesh = new Mesh()
  private previewGeometryId: string | null = null
  private previewPosition = new Vector3()
  private previewRotation = new Quaternion()
  private previewScale = new Vector3()

  constructor (private app: Go3DViewer) {
    this.app = app
  }

  public dispose () {
    if (this.previewMesh.parent) this.previewMesh.parent.remove(this.previewMesh)
    this.addedPreview = false
    this.previewGeometryId = null
  }

  public mergeMeshes (meshes: ISceneGraphMesh[]) {
    const geometries = meshes.map(mesh => {
      return this.app.assetManager.geometries.get(mesh.geometry.uuid)
    })
    const geometry = BufferGeometryUtils.mergeBufferGeometries(geometries)
    return new Mesh(geometry, meshes[0].material)
  }

  public stopPreview (sourceMesh?: ISceneGraphMesh) {
    sourceMesh = sourceMesh ?? Object.values(this.app.picker.selection)[0] as ISceneGraphMesh | undefined
    if (sourceMesh) {
      sourceMesh.visible = sourceMesh.userData.visible ?? true
    }
    this.previewMesh.visible = false
    this.app.renderOnNextFrame()
  }

  public preview (splitGroups: number[], sourceMesh?: ISceneGraphMesh) {
    if (!this.addedPreview) {
      this.addedPreview = true
      this.app.overlayScene.add(this.previewMesh)
    }
    sourceMesh = sourceMesh ?? Object.values(this.app.picker.selection)[0] as ISceneGraphMesh | undefined
    if (!sourceMesh) return

    if (this.previewGeometryId !== sourceMesh.geometry.uuid) {
      const geometry = this.app.assetManager.geometries.get(sourceMesh.geometry.uuid)
      this.previewMesh.geometry = geometry
      this.previewMesh.material = this.previewMaterial
      sourceMesh.matrixWorld.decompose(this.previewPosition, this.previewRotation, this.previewScale)
      this.previewMesh.quaternion.copy(this.previewRotation)
      this.previewMesh.scale.copy(this.previewScale)
      this.previewMesh.position.copy(this.previewPosition)
    }

    sourceMesh.visible = false
    this.previewMesh.visible = true
    this.previewMaterial.updateGroups(splitGroups)
    this.app.renderOnNextFrame()
  }

  public split (sourceMesh: ISceneGraphMesh, splitGroups: number[], data?: any) {
    const meshes = this.splitMesh(sourceMesh, data)
    const models = meshes.map(mesh => this.app.loader.loadMesh(mesh))
    return models
  }

  // Create brep meshes using prim data from converter
  public splitMesh (mesh: ISceneGraphMesh, data?: any) {
    const meshes: Mesh[] = []
    const dataJson = data

    for (let i = 0; i < dataJson.prims_size; i++) {
      const len = dataJson[`prim_${i}_coords_indices`].length
      const newPosition: number[] = []
      const newNormal: number[] = []
      const indexMap = new Map<string, number>()
      const indices: Array<number> = []
      let iter = 0
      for (let k = 0; k < len; k++) {
        const coordindex = dataJson[`prim_${i}_coords_indices`][k]
        const normalindex = dataJson[`prim_${i}_normals_indices`][k]

        const currentIndex = [coordindex, normalindex].toString()
        const existingIndex = indexMap.has(currentIndex)
        let sharedIndex = 0
        if (existingIndex) {
          sharedIndex = indexMap.get(currentIndex)!
        } else {
          sharedIndex = iter
          indexMap.set(currentIndex, sharedIndex)
          newPosition.push(...dataJson[`prim_${i}_coords`][coordindex])
          newNormal.push(...dataJson[`prim_${i}_normals`][normalindex])
          iter++
        }
        indices.push(sharedIndex)
      }

      const geometry = new BufferGeometry()
      geometry.setAttribute('position', new Float32BufferAttribute(newPosition, 3))
      geometry.setAttribute('normal', new Float32BufferAttribute(newNormal, 3))
      geometry.setIndex(new BufferAttribute(new Uint32Array(indices), 1))

      const newMesh = new Mesh(geometry)
      if (dataJson[`prim_${i}_name`]) {
        newMesh.name = `brep_${mesh.name}_${dataJson[`prim_${i}_name`]}`
      }
      meshes.push(newMesh)
    }

    return meshes
  }

  // THREE winding order CW
  // TO REMOVE when brep functionality is fully working
  private splitMeshOld (mesh: ISceneGraphMesh, splitGroups: number[]) {
    const geometry = this.app.assetManager.geometries.get(mesh.geometry.uuid)
    const normal = geometry.attributes.normal.array as number[]
    const position = geometry.attributes.position.array as number[]
    const index = geometry.index.array as number[]
    const uv = geometry.attributes.uv?.array as number[]

    const len = index.length
    const buckets: Bucket[] = []
    splitGroups.forEach((number) => {
      buckets[number] = {
        position: [],
        normal: [],
        uv: [],
        index: [],
        axises: [],
        indexTable: new Map<number, number>(),
        indexIterator: 0
      }
    })

    for (let i = 0; i < len; i += 3) {
      const v0 = readVertex(index[i], position, normal, uv)
      const v1 = readVertex(index[i + 1], position, normal, uv)
      const v2 = readVertex(index[i + 2], position, normal, uv)

      const u = sub(v1[1], v0[1])
      const v = sub(v2[1], v0[1])
      const surfaceNormal = cross(u, v)
      const dominantFacingDirection = getDominantFacingDirectionOfSurfaceNormal(surfaceNormal)
      const bucketKey = splitGroups[dominantFacingDirection]

      if (buckets[bucketKey]) {
        const bucket = buckets[bucketKey]
        const indexTable = bucket.indexTable
        bucket.axises.push(dominantFacingDirection)

        ;([v0, v1, v2]).forEach(([i, pos, nor, uv]) => {
          if (!indexTable.has(i)) {
            bucket.position.push(...pos)
            bucket.normal.push(...nor)
            if (uv) bucket.uv.push(...uv)
            indexTable.set(i, bucket.indexIterator++)
          }
          bucket.index.push(indexTable.get(i)!)
        })
      }
    }

    const meshes: Mesh[] = []

    Object.entries(buckets).forEach(([bucketKey, bucket]) => {
      const geometry = new BufferGeometry()
      geometry.setAttribute('position', new Float32BufferAttribute(bucket.position, 3))
      geometry.setAttribute('normal', new Float32BufferAttribute(bucket.normal, 3))
      if (bucket.uv.length) geometry.setAttribute('uv', new Float32BufferAttribute(bucket.uv, 2))
      geometry.setIndex(new BufferAttribute(new Uint32Array(bucket.index), 1))

      const mesh = new Mesh(geometry)
      const axises = new Set(bucket.axises)
      mesh.userData.splitData = {
        activeAxises: {
          x: axises.has(Split.xa) || axises.has(Split.xb),
          y: axises.has(Split.ya) || axises.has(Split.yb),
          z: axises.has(Split.za) || axises.has(Split.zb)
        },
        bucket: bucketKey
      }
      meshes.push(mesh)
    })

    return meshes
  }
}

function sub (p0: IVec3, p1: IVec3): IVec3 {
  return [
    p0[0] - p1[0],
    p0[1] - p1[1],
    p0[2] - p1[2]
  ]
}
function cross (a: IVec3, b: IVec3): IVec3 {
  return [
    (a[1] * b[2]) - (a[2] * b[1]),
    (a[2] * b[0]) - (a[0] * b[2]),
    (a[0] * b[1]) - (a[1] * b[0])
  ]
}

function readVec3 (buffer: number[], offset: number) {
  const o = offset * 3
  return [buffer[o], buffer[o + 1], buffer[o + 2]] as IVec3
}

function readVec2 (buffer: number[], offset: number) {
  const o = offset * 2
  return [buffer[o], buffer[o + 1]] as IVec2
}

function readVertex (index: number, position: number[], normal: number[], uv: number[] = []) {
  const p = readVec3(position, index)
  const n = readVec3(normal, index)
  return [index, p, n, uv.length ? readVec2(uv, index) : [0, 0]] as [number, IVec3, IVec3, IVec2]
}
