import React from 'react'

// common
import Grid from '../../../common/grid/grid'

// components
import Search from './carrier-search/Search'

function CarrierPanel () {
  const columns = ['1fr']
  return (
    <Grid
      className='overflow-hidden height-100'
      rows={['1fr']}
      gridGap={0}
      columns={columns}
    >
      <Search />
    </Grid>
  )
}

export default CarrierPanel
