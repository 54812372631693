const assemblyFileTypes = ['catproduct', 'sldasm']
const partFileTypes = ['sat', 'catpart', 'igs', 'iges', 'jt', 'sldprt', 'obj', 'rvt', '3ds', 'dae', 'step', 'stp', 'glb', 'gltf']
const accompanyingFileTypes = ['bin', 'png', 'jpg']

function isAssemblyFile (fileName) {
  return assemblyFileTypes.filter((fileType) => {
    return fileName.match(new RegExp('.' + fileType + '$', 'i'))
  }).length > 0
}

function isPartFile (fileName) {
  return partFileTypes.filter((fileType) => {
    return fileName.match(new RegExp('.' + fileType + '$', 'i'))
  }).length > 0
}

function isAccompanyingFile (fileName) {
  return accompanyingFileTypes.filter((fileType) => {
    return fileName.match(new RegExp('.' + fileType + '$', 'i'))
  }).length > 0
}

function getAssemblyFiles (files) {
  return files.filter((file) => isAssemblyFile(file.name))
    .sort((a, b) => b.size - a.size)
}

function getPartFiles (files) {
  return files.filter((file) => isPartFile(file.name))
    .sort((a, b) => b.size - a.size)
}

function getAccompanyingFiles (files) {
  return files.filter((file) => isAccompanyingFile(file.name))
    .sort((a, b) => b.size - a.size)
}

function getError (assemblyFiles, partFiles, accompanyingFiles) {
  const error = {}

  if (!assemblyFiles.length && !partFiles.length) {
    error.msg = 'Selected file(s) are not supported'
    error.isInvalidFileError = true
  } else if (assemblyFiles.length > 0 && !partFiles.length && !accompanyingFiles.length) {
    error.msg = 'Please upload required parts with the assembly'
  } else if (
    !assemblyFiles.length && partFiles.length > 1
  ) {
    error.msg = 'Please upload parts separately or pair with an assembly'
  }

  return error
}

const acceptedFileTypes = assemblyFileTypes.concat(partFileTypes, accompanyingFileTypes).sort()

const isFileSupported = (file) => {
  var parts = file.name.split('.')
  return acceptedFileTypes
    .map(x => x.toLowerCase())
    .includes(parts[parts.length - 1].toLowerCase())
}

function getResult (files) {
  const assemblyFiles = getAssemblyFiles(files)
  const partFiles = getPartFiles(files)
  const accompanyingFiles = getAccompanyingFiles(files)
  const fail = {
    success: false,
    files,
    supportedFileFormats: acceptedFileTypes.map(fileType => `.${fileType.toLowerCase()}`).join(', ')
  }

  if (Object.keys(getError(assemblyFiles, partFiles, accompanyingFiles)).length !== 0) {
    return { ...fail, ...getError(assemblyFiles, partFiles, accompanyingFiles) }
  }

  return {
    success: true,
    assemblyFiles,
    assemblyOption: assemblyFiles.length ? assemblyFiles[0].name : partFiles[0].name,
    title: assemblyFiles.length ? assemblyFiles[0].name : partFiles[0].name,
    supportedFiles: files.filter(file => isFileSupported(file))
  }
}

module.exports = {
  acceptedFileTypes,
  getResult,
}
