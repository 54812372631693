import store from '../../stores'

import * as fromFolders from '../../stores/ducks/folders'

export default function handleFolders (msg) {
  const {
    doc,
    status,
    removedCombinations
  } = msg

  if (status === 'deleted') {
    return store.dispatch(fromFolders.removed(doc.id))
  }

  if (removedCombinations) {
    store.dispatch(fromFolders.removedCombinations({
      folderId: doc.id,
      combinationIds: removedCombinations
    }))
  }

  store.dispatch(fromFolders.receive([doc]))
}
