import * as THREE from 'three'
import { EventEmitter } from 'events'

export default class Picker extends EventEmitter {
  constructor (domElement, rayCaster, objectTracker) {
    super()
    this.rayCaster = rayCaster
    this.objectTracker = objectTracker
    this.onDownPosition = new THREE.Vector2()
    this.onUpPosition = new THREE.Vector2()
    this.onMovePosition = new THREE.Vector2()

    this.domElement = domElement || null
    this.enabled = true
    this.isDragging = false
    this._lastTimeStamp = 0
    this.selection = {}
    this.keyDown = null
    this.overrideHandler = null
    this.brepObjectIsSelected = false

    this._boundOnMouseDown = this.onMouseDown.bind(this)
    this._boundOnTouchStart = this.onTouchStart.bind(this)
    this._boundOnDoubleClick = this.onDoubleClick.bind(this)
    this._boundOnMouseMove = this.onMouseMove.bind(this)
    this._setKeycode = this.setKeycode.bind(this)
    this._resetKeyCode = this.resetKeyCode.bind(this)

    this.domElement.addEventListener('mousedown', this._boundOnMouseDown, false)
    this.domElement.addEventListener('touchstart', this._boundOnTouchStart, false)
    this.domElement.addEventListener('dblclick', this._boundOnDoubleClick, false)
    this.domElement.addEventListener('mousemove', this._boundOnMouseMove, false)
    window.addEventListener('keydown', this._setKeycode, false)
    window.addEventListener('keyup', this._resetKeyCode, false)
  }

  dispose () {
    this.clearSelection()

    this.domElement.removeEventListener('mousedown', this._boundOnMouseDown)
    this.domElement.removeEventListener('touchstart', this._boundOnTouchStart)
    this.domElement.removeEventListener('dblclick', this._boundOnDoubleClick)
    this.domElement.removeEventListener('mousemove',this._boundOnMouseMove)
    window.removeEventListener('keydown', this._setKeycode)
    window.removeEventListener('keyup', this._resetKeyCode)

    this.removeAllListeners()

    delete this.rayCaster
    delete this.objectTracker
    delete this.domElement
  }

  setKeycode (e) {
    this.keyDown = e.code

    // Note: The event has been already set as consumed
    // in KeyboardListener.handleKeyEvent
    if (this.keyDown === 'Escape') {
      if (Object.keys(this.selection).length >= 1) {
        this.clearSelection()
      }
    }
  }

  resetKeyCode () {
    this.keyDown = null
  }

  disable () {
    this.enabled = false
    this.clearSelection()
  }

  enable () {
    this.enabled = true
  }

  clearSelection (emitSelect = true, reselection = false) {
    this.brepObjectIsSelected = false
    if (emitSelect) {
      this.emit('deselect', Object.values(this.selection), reselection)
    }
    this.selection = {}
    this.emit('change', Object.values(this.selection))
  }

  // Set an override event handler that hijacks the picker events.
  setOverrideHandler (handler) {
    this.overrideHandler = handler
  }

  clearOverrideHandler () {
    this.overrideHandler = null
  }

  handleClick (event) {
    if (!this.enabled || this.onDownPosition.distanceTo(this.onUpPosition) > 0.002) return

    event.preventDefault()

    const intersects = this.getIntersects(this.onUpPosition, mesh => this.objectTracker.interactions.picker[mesh.sceneGraphID])

    if (this.overrideHandler) {
      const allowSelection = this.overrideHandler(intersects)
      if (!allowSelection) return
    }

    var modifierKey = (event.shiftKey || event.metaKey || event.ctrlKey)

    var objects = []
    let object = false

    if (intersects) {
      object = intersects.object
    }

    const codes = [
      'KeyP',
      'KeyT'
    ]

    if (codes.includes(this.keyDown)) {
      // KeyT is for the manual hole-snapping
      if (this.keyDown === 'KeyT') {
        this.emit('select-' + this.keyDown, this.rayCaster.rayCaster.ray, event.ctrlKey)
      } else {
        this.emit('select-' + this.keyDown, object)
      }
      return
    }

    // check if left click
    if (event.which === 1) {
      // is clicked part already selected
      if (this.isSelected(object.uuid)) {
        // deselect if only one is selected
        if (Object.keys(this.selection).length === 1) {
          if (event.type === 'dblclick') {
            this.emit('dblclick', Object.values(this.selection), intersects)
          } else {
            this.deselect(object.uuid)
          }
        } else {
          // if shift is pressed, deselect clicked part
          if (modifierKey) {
            this.deselect(object.uuid)
          } else {
            this.select(object, modifierKey)
            objects.push(object)
            if (event.type === 'dblclick') {
              this.emit('dblclick', [], intersects)
            }
          }
        }
      } else {
        if (this.disableMulti) {
          this.selection = {}
        }
        this.select(object, modifierKey)
        objects.push(object)
        if (event.type === 'dblclick') {
          this.emit('dblclick', Object.values(this.selection), intersects)
        }
      }
    }

    if (event.which === 1 && modifierKey) {
      objects.push(...Object.values(this.selection))
    }

    // only emit select event if there is a selection and something has changed
    if (Object.keys(this.selection).length && objects.length > 0) {
      this.emit('select', Object.values(this.selection), intersects)
    }

    this.emit('change', Object.values(this.selection))
  }

  select (object, modifierKey) {
    if (!object) {
      this.clearSelection()
    } else {
      if (!modifierKey) {
        this.clearSelection(true, true)
      }
      this.selection[object.uuid] = object
      if (object.userData.generated) {
        this.brepObjectIsSelected = true
      }
    }
  }

  selectMany (objects, emitSelect = true, keepSelection = false) {
    if (!keepSelection) this.clearSelection(emitSelect)

    objects.forEach(object => {
      this.selection[object.uuid] = object
    })

    if (emitSelect) {
      this.emit('select', Object.values(this.selection))
    }
    this.emit('change', Object.values(this.selection))
  }

  deselect (uuid) {
    if (uuid) {
      this.brepObjectIsSelected = false
      this.emit('deselect', [this.selection[uuid]])
      this.emit('change', Object.values(this.selection))
      delete this.selection[uuid]
    }
  }

  deselectMeshes (sceneGraphObjects) {
    const nodes = []
    sceneGraphObjects.map((obj) => {
      if (obj.children.length !== 0) {
        obj.traverse(node => {
          if (node.isMesh && this.selection[node.uuid]) {
            nodes.push(this.selection[node.uuid])
          }
        })
      } else {
        nodes.push(obj)
      }
    })
    this.emit('deselect', nodes)
    this.emit('change', Object.values(this.selection))
  }

  isSelected (uuid) {
    return !!this.selection[uuid]
  }

  getMousePosition (dom, x, y) {
    if (!dom) return [0, 0]
    var rect = dom.getBoundingClientRect()
    var coordinates = [(x - rect.left) / rect.width, (y - rect.top) / rect.height]

    return coordinates
  }

  // TODO @Martin getIntersects and getSnappingIntersects can be unified.
  getIntersects (point, filter, meshIds) {
    const hit = this.rayCaster.findIntersections(point, filter, meshIds)

    // Convert SceneGraph ids to object references
    if (hit) hit.object = this.objectTracker.interactions.picker[hit.object]

    return hit
  }

  getSnappingIntersects (point, filter, meshIds) {
    const hit = this.rayCaster.findIntersections(point, false, meshIds)

    // Convert SceneGraph ids to object references
    if (hit) hit.object = this.objectTracker.interactions.snapTargets[hit.object]

    return hit
  }

  trace (origin, direction, filter) {
    const hit = this.rayCaster.trace(origin, direction, filter)

    // Convert SceneGraph ids to object references
    if (hit) hit.object = this.objectTracker.interactions.snapTargets[hit.object]

    return hit
  }

  onDoubleClick (event) {
    this.handleClick(event)
  }

  onMouseDown (event) {
    event.preventDefault()
    if (!this.domElement) return

    var array = this.getMousePosition(this.domElement, event.clientX, event.clientY)

    this.isDragging = false
    this.onDownPosition.fromArray(array)

    var _onMouseMove = function (event) {
      this.isDragging = true
      this.domElement.removeEventListener('mousemove', _onMouseMove, true)
    }.bind(this)

    var _onMouseUp = function (event) {
      var array = this.getMousePosition(this.domElement, event.clientX, event.clientY)
      this.onUpPosition.fromArray(array)
      this.handleClick(event)
      this.domElement.removeEventListener('mouseup', _onMouseUp, true)
      this.domElement.removeEventListener('mousemove', _onMouseMove, true)
    }.bind(this)

    this.domElement.addEventListener('mousemove', _onMouseMove, true)
    this.domElement.addEventListener('mouseup', _onMouseUp, true)
  }

  onMouseMove (event) {
    event.preventDefault()
    if(!this.domElement) return

    var array = this.getMousePosition(this.domElement, event.clientX, event.clientY)
    this.onMovePosition.fromArray(array)
  }

  onTouchStart (event) {
    var touch = event.changedTouches[0]

    var array = this.getMousePosition(this.domElement, touch.clientX, touch.clientY)
    this.onDownPosition.fromArray(array)
    this.isDragging = false

    var _onTouchMove = function (event) {
      this.isDragging = true
      this.domElement.removeEventListener('touchmove', _onTouchMove, true)
    }.bind(this)

    var _onTouchEnd = function (event) {
      var touch = event.changedTouches[0]
      var array = this.getMousePosition(this.domElement, touch.clientX, touch.clientY)
      this.onUpPosition.fromArray(array)
      this.handleClick(event)
      this.domElement.removeEventListener('touchend', _onTouchEnd, true)
    }.bind(this)

    this.domElement.addEventListener('touchend', _onTouchEnd, true)
    this.domElement.addEventListener('touchmove', _onTouchMove, true)
  }
}
