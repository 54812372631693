import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// lodash
import _keys from 'lodash/keys'
import _pickBy from 'lodash/pickBy'
import _xor from 'lodash/xor'
import _includes from 'lodash/includes'

// Common
import { PROJECT_TYPES } from '../../constants'
import Grid from '../common/grid/grid'
import Info from '../common/info'
import Modal from '../common/modal'

// Components
import {
  EmptyFolder, EmptyGeometry, EmptyImages, EmptyPatterns, EmptySearch,
  EmptySentAssets, EmptyTemplateImagesFolder, EmptyVariants
} from './project-components/EmptyStates'
import UploadGeometry from './card/UploadGeometry'
import UploadPattern from './card/UploadPattern'
import SaveSceneForm from './scenes/save-scene/SaveSceneForm'
import type { Item } from './card/Item'
import ProjectColorsView from './colors'
import GeneralContent from './project-components/GeneralContent'
import ScenesView from './scenes/ScenesView'
import VirtualProductsView, { VirtualProductsViewModals } from './virtual-products/VirtualProductsView'

// Modals
import ShareSceneGloballyModal from './scenes/global-scene/ShareSceneGloballyModal'
import ShowGlobalSceneModal from './scenes/global-scene/ShowGlobalSceneModal'
import ShareToUserModal from './share/ShareToUserModal'
import ShareExternalModal from './share/ShareExternalModal'
import ExportModal from './export/ExportModal'
import MaterialPreviewModal from '../visualizer/panels/material-and-color/material-preview-modal'

import navigate from '../../utils/navigate'

// selectors
import * as fromProjectsSelectors from '../../stores/ducks/projects/selectors'
import { isFetchingSelector } from './selectors/projectSelectors'

// actions
import * as fromScenes from '../../stores/ducks/scenes'
import * as fromProjects from '../../stores/ducks/projects'
import * as fromCombinations from '../../stores/ducks/combinations'
import * as fromPatternsJson from '../../stores/ducks/patterns/json'

import { AssetType, GlobalProjectModals, ProjectSearchFilters, ProjectTabs } from '.'
import { Folder } from '../../stores/ducks/folders/Folder'

type Props = {
  folders: any
  searchedItems: Item[]
  searchFilter: ProjectSearchFilters
  currentFolder: null | Folder
  selectedTab: ProjectTabs
  selectedIds: string[]
  virtualProductIsActive: boolean
  searchString: string
  getItemsInProgress: any
  getIsCurrentPageEmpty: () => boolean | null
  handleUploaded: (data: any) => void
  getDoneAssets: (type: AssetType) => Item[]
  getCurrentContentName: () => AssetType

  isDragging: boolean
  setIsDragging: (dragging: boolean) => void
  setSelectedIds: (ids: string[]) => void

  projectId: string
  handlePreview: (combination: Item) => void

  setActiveModalAndItem: ((modal: (GlobalProjectModals | null), item: Item) => void)

  activeItem: Item | null
  setActiveItem: (item: Item | null) => void
}

export enum ContentModals {
  PATTERN = 'pattern',
  SCENE = 'scene',
  SEND_ASSET = 'sendAsset',
  SHARE_EXTERNAL = 'shareExternal',
  EXPORT = 'export',
  GLOBAL_SCENE = 'globalScene',
  SHOW_GLOBAL_SCENE = 'showGlobalScene'
}

function Content (props: Props) {
  const [activeModal, setActiveModal] = useState<ContentModals | null>(null)
  const [previewPattern, setPreviewPattern] = useState<null | { id: string, title: string, imgOriginal: string }>(null)

  const project = useSelector(fromProjectsSelectors.getCurrentEntry)
  const isFetching = useSelector(isFetchingSelector)

  const dispatch = useDispatch()

  const isRegularContent = ![ProjectTabs.COLORS, ProjectTabs.SCENES, ProjectTabs.VIRTUAL_PRODUCTS].includes(props.selectedTab)

  const setNotificationMessage = () => (x:any) => dispatch(fromProjects.notify(x))

  function getEmptyStateComponent () {
    const { currentFolder, selectedTab, searchString, searchFilter } = props

    if (searchString && searchString !== '') {
      return <EmptySearch />
    }

    if (selectedTab === ProjectTabs.IMAGES) {
      if (searchFilter === 'sent') {
        return <EmptySentAssets assetType={AssetType.IMAGES} />
      }
      if (currentFolder && !props.getDoneAssets(AssetType.IMAGES).length) {
        return currentFolder.type === 'template-images'
          ? <EmptyTemplateImagesFolder />
          : <EmptyFolder />
      }
      return <EmptyImages />
    }

    if (selectedTab === ProjectTabs.GEOMETRY) {
      if (searchFilter === 'sent') {
        return <EmptySentAssets assetType={AssetType.GEOMETRY} />
      }
      if (currentFolder && !props.getDoneAssets(AssetType.GEOMETRY).length) {
        return currentFolder.type === 'template-images'
          ? <EmptyTemplateImagesFolder />
          : <EmptyFolder />
      }
      return <EmptyGeometry onUploaded={props.handleUploaded} />
    }

    if (selectedTab === ProjectTabs.VARIANTS) {
      if (searchFilter === 'sent') {
        return <EmptySentAssets assetType={AssetType.VARIANTS} />
      }
      if (currentFolder && !props.getDoneAssets(AssetType.VARIANTS).length) {
        return currentFolder.type === 'template-images'
          ? <EmptyTemplateImagesFolder />
          : <EmptyFolder />
      }
      return <EmptyVariants />
    }

    if (selectedTab === ProjectTabs.PATTERNS) {
      if (searchFilter === 'sent') {
        return <EmptySentAssets assetType={AssetType.PATTERNS} />
      }
      if (currentFolder && !props.getDoneAssets(AssetType.PATTERNS).length) {
        return currentFolder.type === 'template-images'
          ? <EmptyTemplateImagesFolder />
          : <EmptyFolder />
      }
      return <EmptyPatterns />
    }

    return <div />
  }

  function getUploadComponent () {
    if (props.selectedTab === ProjectTabs.GEOMETRY) {
      return <UploadGeometry onUploaded={handleUploaded} key='upload-geometry' />
    }

    if (props.selectedTab === ProjectTabs.PATTERNS) {
      return <UploadPattern key='upload-pattern' />
    }

    return null
  }

  function handleOpenPatternModal (pattern: { id: any; title: any; imgOriginal: any }) {
    setActiveModal(ContentModals.PATTERN)
    setPreviewPattern({
      id: pattern.id,
      title: pattern.title,
      imgOriginal: pattern.imgOriginal
    })
  }

  function getPrimaryCardAction () {
    switch (props.selectedTab) {
      case ProjectTabs.PATTERNS: return handleOpenPatternModal
      case ProjectTabs.IMAGES: return props.handlePreview
      case ProjectTabs.SCENES: return handleSceneClick
      default: return handleVisualize
    }
  }

  function getSecondaryCardAction () {
    switch (props.selectedTab) {
      case ProjectTabs.IMAGES: return handleVisualize
      case ProjectTabs.SCENES: return handleVisualizeScene
      default: return null
    }
  }

  function handleCardSelected (id?: string) {
    if (!id) return
    props.setSelectedIds(_xor([id], props.selectedIds))
  }

  function handleEdit (id: string, data: any, isBatchRender: boolean, isGlobal: boolean) {
    if (props.selectedTab === ProjectTabs.PATTERNS) return dispatch(fromPatternsJson.update({ ...data, id }))
    if (props.selectedTab === ProjectTabs.SCENES) return dispatch(fromScenes.updateScene({ id, title: data.title, global: isGlobal }))
    if (props.selectedTab === ProjectTabs.IMAGES && isBatchRender) return dispatch(fromCombinations.updateBatchRenderTitle({ data, id }))
    return dispatch(fromCombinations.update(id, data))
  }

  function handleUploaded (data: any) {
    dispatch(fromProjects.receiveCadKey(data, props.projectId))
  }

  function handleCardDragStart (_: any, draggedId: string) {
    props.setIsDragging(true)

    if (!_includes(props.selectedIds, draggedId)) props.setSelectedIds([draggedId])
  }

  function handleNavigatePatternModal (patterns: Item[], delta: string) {
    const index = _keys(_pickBy(patterns, { id: previewPattern?.id }))[0]

    let newIndex = parseInt(index) + parseInt(delta)
    if (newIndex > (patterns.length - 1)) {
      newIndex = 0
    } else if (newIndex < 0) {
      newIndex = (patterns.length - 1)
    }
    const newPattern = patterns[newIndex]

    setPreviewPattern({
      id: newPattern.id,
      title: newPattern.title,
      imgOriginal: newPattern.imgOriginal || ''
    })
  }

  function resetModalDefault () {
    setActiveModal(null)
    props.setActiveItem(null)
  }

  function handleVisualizeScene (scene: Item) {
    navigate(`/visualize/${props.projectId}/combination/${scene.combinationId}?sceneId=${scene.id}`)
  }

  function handleVisualize (comb: Item) {
    const id = comb.id
    let projectTypeUrlParam = 'combination'
    if (project.projectType && project.projectType !== PROJECT_TYPES.DEFAULT) {
      projectTypeUrlParam = project.projectType
    }
    navigate(`/visualize/${props.projectId}/${projectTypeUrlParam}/${id}`)
  }

  function getItemType () {
    switch (props.selectedTab) {
      case ProjectTabs.IMAGES: return 'image'
      case ProjectTabs.GEOMETRY: return props.selectedIds.length > 1 ? 'geometries' : 'geometry'
      case ProjectTabs.VARIANTS: return 'variant'
      case ProjectTabs.PATTERNS: return 'pattern'
      case ProjectTabs.SCENES: return 'scene'
      default: return ''
    }
  }

  function handleSceneClick (scene: Item) {
    if (scene.global) return handleOpenGlobalSceneInfo(scene)
    return handleVisualizeScene(scene)
  }

  function handleOpenGlobalSceneInfo (scene: Item | null) {
    if (!scene) return
    dispatch(fromScenes.setTempSelectedCombination({ combination: scene }))
    setActiveModal(ContentModals.SHOW_GLOBAL_SCENE)
    props.setActiveItem(scene)
  }

  function setModalState (modal: ContentModals | GlobalProjectModals | VirtualProductsViewModals, item: Item) {
    // No VirtualProductsViewModals for ProjectContentView
    const ContentModalsArray = Object.values(ContentModals)
    const GlobalProjectModalsArray = Object.values(GlobalProjectModals)

    const modalsThatRequireActiveItem = [
      ContentModals.SCENE,
      ContentModals.EXPORT,
      ContentModals.GLOBAL_SCENE,
      ContentModals.SHOW_GLOBAL_SCENE,
      ContentModals.SEND_ASSET,
      ContentModals.SHARE_EXTERNAL
    ]
    if (modal && ContentModalsArray?.includes(modal as any)) {
      if (modalsThatRequireActiveItem.includes(modal as any)) props.setActiveItem(item)
      setActiveModal(modal as ContentModals)
      return
    }
    if (modal && GlobalProjectModalsArray?.includes(modal as any)) props.setActiveModalAndItem(modal as GlobalProjectModals, item)
  }

  return (
    <>
      {isFetching && <Info icon='block-before icon-spin1 animate-spin my2' />}
      {!isFetching && (
        <div className='height-100'>
          <Grid
            rows={['1fr', 'auto']}
            className='px2 height-100'
            gridGap={0}
          >
            {isRegularContent && (
              <GeneralContent
                className='overflow-auto px1'
                folders={props.folders}
                itemsDone={props.searchedItems}
                itemsInProgress={props.searchFilter !== 'sent' ? props.getItemsInProgress() : []}
                emptyStateComponent={props.getIsCurrentPageEmpty() && getEmptyStateComponent()}
                uploadComponent={!props.currentFolder && getUploadComponent()}
                contentName={props.getCurrentContentName()}
                editOnClick={[ProjectTabs.GEOMETRY, ProjectTabs.VARIANTS, ProjectTabs.SCENES].includes(props.selectedTab)}
                onClickImage={getPrimaryCardAction()}
                onSecondaryAction={getSecondaryCardAction()}
                onSelect={handleCardSelected}
                selectedIds={props.selectedIds}
                onEdit={handleEdit}
                onDragStart={handleCardDragStart}
                onDragEnd={() => props.setIsDragging(false)}
                isDragging={props.isDragging}
                onOpenModal={setModalState}
                showToastMessage={setNotificationMessage}
              />
            )}
            {props.virtualProductIsActive && props.selectedTab === ProjectTabs.VIRTUAL_PRODUCTS && (
              <VirtualProductsView
                showToastMessage={setNotificationMessage}
                searchFilter={props.searchFilter}
                searchString={props.searchString}
                onSelect={handleCardSelected}
                selectedIds={props.selectedIds}
              />
            )}
            {props.selectedTab === ProjectTabs.COLORS && (
              <ProjectColorsView />
            )}
            {props.selectedTab === ProjectTabs.SCENES && (
              <ScenesView
                contentName={props.getCurrentContentName()}
                onClickImage={getPrimaryCardAction()}
                onSecondaryAction={getSecondaryCardAction()}
                onSelect={handleCardSelected}
                selectedIds={props.selectedIds}
                onEdit={handleEdit}
                onOpenModal={setModalState}
                removeItem={(item: Item) => props.setActiveModalAndItem(GlobalProjectModals.REMOVE, item)}
                removeItemAsAdmin={(item: Item) => props.setActiveModalAndItem(GlobalProjectModals.REMOVE_AS_ADMIN, item)}
                showToastMessage={setNotificationMessage}
              />
            )}
          </Grid>
        </div>
      )}
      <MaterialPreviewModal
        open={activeModal === ContentModals.PATTERN}
        onRequestClose={() => {
          setActiveModal(null)
          setPreviewPattern(null)
        }}
        onNavigation={(delta: string) => handleNavigatePatternModal(props.searchedItems, delta)}
        headerText={previewPattern && previewPattern.title}
        src={previewPattern && previewPattern.imgOriginal}
      />
      <Modal
        isOpen={activeModal === ContentModals.SCENE}
        onRequestClose={() => setActiveModal(null)}
        shouldCloseOnOverlayClick
        width={600}
      >
        <SaveSceneForm
          onSubmit={(title) => {
            if (title.length > 0 && props.activeItem) {
              dispatch(fromScenes.shareScene({ id: props.activeItem.id, title }))
              setActiveModal(null)
            }
          }}
          onCancel={() => setActiveModal(null)}
        />
      </Modal>
      <ShareSceneGloballyModal
        isOpen={activeModal === ContentModals.GLOBAL_SCENE}
        onRequestClose={resetModalDefault}
        scene={props.activeItem}
      />
      <ShowGlobalSceneModal
        isOpen={activeModal === ContentModals.SHOW_GLOBAL_SCENE}
        onRequestClose={resetModalDefault}
        scene={props.activeItem}
        onOpenVisualizer={(scene: Item) => {
          handleVisualizeScene(scene)
          resetModalDefault()
        }}
      />
      <ShareToUserModal
        isModalOpen={activeModal === ContentModals.SEND_ASSET}
        handleCloseModal={resetModalDefault}
        modalType='ASSET'
        asset={props.activeItem}
        assetType={getItemType()}
      />
      <ShareExternalModal
        isOpen={activeModal === ContentModals.SHARE_EXTERNAL}
        onClose={resetModalDefault}
        asset={props.activeItem}
      />
      {props.activeItem !== null && <ExportModal
        isOpen={activeModal === ContentModals.EXPORT}
        onClose={resetModalDefault}
        asset={props.activeItem}
      />}
    </>
  )
}

export default Content
