const THREE = require('three')
const convertToSVG = require('./convert')
const AnnotationEmitter = require('./AnnotationEmitter')
const AnnotationElement = require('./AnnotationElement')
const LineDrawer = require('./LineDrawer').default
const render = require('./render')
const { findGroup, findMarker } = require('./utils')
const { setAnnotationPosition, AnnotationControls } = require('./controls')

let manager, needsVisibilityUpdate

function AnnotationMananger (renderScene, viewportOffsetLeft, viewportOffsetTop) {
  this.renderScene = renderScene
  this.viewportOffsetLeft = viewportOffsetLeft
  this.viewportOffsetTop = viewportOffsetTop

  this.annotations = []
  this.markers = []
  this.enabled = false
  this.canvasHeight = undefined
  this.canvasWidth = undefined
  this.lastActiveNode = undefined
  this.lineDrawer = undefined
  this.emitter = undefined
  this.controls = undefined
  this.disabledColor = new THREE.Color(1, 0, 0)
  this.lockedColor = new THREE.Color(0, 1, 0)
  this.whiteColor = new THREE.Color(1, 1, 1)
  this.blackColor = new THREE.Color(0, 0, 0)
  this.frame = -1
}

const Annotation = function (markers, groupId, annotationText) {
  this.lockedMarker = undefined
  this.selected = false
  this.groupId = groupId
  this.annotationText = annotationText
  this.markers = markers
  this.active = true
  this.showSingleMarker = true
  this.locked = false
  this.positionX = 0 // From last update OR set by user if locked
  this.positionY = 0
  this.anchorX = 0 // From last update, where is the grouping circle? Can be at a marker if single marker mode or single visible marker in annotation
  this.anchorY = 0

  // break out colors
  this.color = new THREE.Color(0, 0, 0)
  this.selectedColor = new THREE.Color(0.13, 0.36, 0.73)
  this.element = new AnnotationElement(groupId, this, manager)
  this.closestMarkerPosition = undefined

  this.updateAnnotationText = function (annotationText) {
    this.annotationText = annotationText
    this.element.setText(annotationText)
  }
}

const Marker = function (mesh, parentAnnotation) {
  this.mesh = mesh
  this.active = true
  this.visible = true
  this.clickable = false
  this.position3d = new THREE.Vector3() // Position to use when locked
  this.locked = false
  this.positionX = 0 // From last update
  this.positionY = 0
  this.parentAnnotation = parentAnnotation
}

function updateVisibility (idBuffer) {
  for (let i = 0; i < manager.markers.length; i++) {
    const marker = manager.markers[i]
    if (marker) {
      const visible = idBuffer[i * 4] === 255
      manager.markers[i].visible = visible
    }
  }

  manager.annotations.forEach((a) => { a.element.updateExpandButtonsState() })
}

function dispose () {
  if (manager.lineDrawer) {
    manager.lineDrawer.dispose()
    manager.lineDrawer = undefined
  }
  if (manager.controls) {
    manager.controls.dispose()
    manager.controls = undefined
  }
  manager.annotations.forEach((annotation) => {
    manager.annotations.splice(manager.annotations.indexOf(annotation), 1)
    annotation.element.remove()
  })
  manager.markers = []
  manager.annotations = []
  manager.enabled = false
}

function removeMarker (mesh, domParent) {
  const id = mesh._instancedMeshId
  if (id === undefined) return

  const marker = manager.markers[id]
  if (marker === undefined || marker === null) return

  const annotation = marker.parentAnnotation

  if (annotation.markers.length === 1) {
    manager.annotations.splice(manager.annotations.indexOf(annotation), 1)
    domParent.removeChild(annotation.element.annotationDiv)
  } else {
    annotation.markers.splice(annotation.markers.indexOf(marker), 1)
  }
  manager.markers[id] = null
}

function updateAnnotationText(groupId, annotationText) {
  let targetAnnotation = findGroup(groupId, manager.annotations)
  if(targetAnnotation) {
    targetAnnotation.updateAnnotationText(annotationText)
  }
}

function addMarker (mesh, groupId, domParent, annotationText) {
  if (groupId === undefined) return

  let marker = findMarker(mesh, manager.markers)
  if (marker && marker.parentAnnotation.groupId === groupId) return

  let targetAnnotation = findGroup(groupId, manager.annotations)

  // No target annotation and marker alone in current annotation, update current annotation
  if (targetAnnotation === undefined && marker && marker.parentAnnotation.markers.length === 1) {
    marker.parentAnnotation.updateGroupId(groupId)
    return
  }

  if (targetAnnotation === undefined) {
    targetAnnotation = new Annotation([], groupId, annotationText)
    manager.annotations.push(targetAnnotation)
    domParent.appendChild(targetAnnotation.element.annotationDiv)
  }

  if (marker) {
    // We are moving an existing marker to another annotation
    const oldAnnotation = marker.parentAnnotation
    oldAnnotation.markers.splice(oldAnnotation.markers.indexOf(marker), 1)

    // Remove old annotations if this marker was the only one
    if (oldAnnotation.markers.length === 0) {
      domParent.removeChild(oldAnnotation.element.annotationDiv)
      manager.annotations.splice(manager.annotations.indexOf(oldAnnotation), 1)
    }
  } else {
    marker = new Marker(mesh, targetAnnotation)
    manager.markers[mesh._instancedMeshId] = marker
  }
  targetAnnotation.markers.push(marker)
  marker.parentAnnotation = targetAnnotation
}

function setup (renderScene, overlayScene, picker, domElement, canvasOffsetLeft, canvasOffsetTop) {
  manager = new AnnotationMananger(renderScene, canvasOffsetLeft, canvasOffsetTop)
  manager.emitter = new AnnotationEmitter()
  manager.controls = new AnnotationControls(picker, domElement, manager)
  manager.overlayScene = overlayScene
  needsVisibilityUpdate = false
}

function setEnabled (enable) {
  manager.enabled = enable
  if (enable) {
    manager.controls.enable()
    manager.frame = -1
    manager.lineDrawer = new LineDrawer(manager.overlayScene)
  } else {
    manager.controls.dispose()
    manager.lineDrawer && manager.lineDrawer.dispose()
    manager.lineDrawer = undefined
  }
  manager.annotations.forEach(annotation => {
    annotation.element.setVisible(enable)
  })
}

function isEnabled () {
  return manager.enabled
}

function setAnnotationActive (groupId, active) {
  const group = findGroup(groupId, manager.annotations)
  if (group === undefined) return
  group.active = active
}

function setMarkerActiveFromMesh (mesh, active) {
  const marker = findMarker(mesh, manager.markers)
  if (marker === undefined) return
  marker.active = active
}

function setAnnotationSingleMarker (groupId, singleMarker) {
  const group = findGroup(groupId, manager.annotations)
  if (group === undefined) return
  group.showSingleMarker = singleMarker
}

function setMarkerPositionFromMesh (mesh, position3d, locked = true) {
  const marker = findMarker(mesh, manager.markers)
  if (marker === undefined) return
  marker.locked = locked
  marker.position3d = position3d.clone()
}

function visibilityNeedsUpdate () {
  needsVisibilityUpdate = true
}

function getEmitter () {
  return manager.emitter
}

function update (app) {
  if (isEnabled()) {
    // spreading out the work of updating visibility for annotations over several frames
    // render twice on enable is a quick fix where the shader does not render properly the first frame
    if (manager.frame === -1 || manager.frame === 0) {
      app.histogram.render(app.renderer, app.renderScene.scene, app.camera, { idSizeDivideFactor: 2 })
    } else if (manager.frame === 10) {
      app.histogram.lastResult = app.histogram.readback(app.renderer)
    } else if (manager.frame === 20) {
      updateVisibility(app.histogram.lastResult)
      app.renderOnNextFrame()
    } else if (manager.frame >= 30) {
      if (needsVisibilityUpdate) {
        manager.frame = 0
      }
      return
    }
    manager.frame++
  }
}

module.exports = {
  update,
  visibilityNeedsUpdate,
  convertToSVG: (width, height, safeFrameWidth, safeFrameHeight, targetHeight, targetWidth) => convertToSVG(manager.annotations, width, height, safeFrameWidth, safeFrameHeight, targetHeight, targetWidth),
  setAnnotationPosition: (groupId, x, y, locked) => setAnnotationPosition(manager.annotations, groupId, x, y, locked),
  render: (camera, canvas) => render(camera, canvas, manager),
  getEmitter,
  setup,
  addMarker,
  updateVisibility,
  removeMarker,
  dispose,
  setEnabled,
  isEnabled,
  setAnnotationActive,
  setMarkerActiveFromMesh,
  setMarkerPositionFromMesh,
  setAnnotationSingleMarker,
  updateAnnotationText
}
