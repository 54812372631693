import { createSelector } from 'reselect'

import _get from 'lodash/get'

import { RootState } from '..'

const getLocalState = (state: RootState) => state.designs

export const getAvailableAggs = createSelector(
  getLocalState,
  (localState) => {
    const { activeTab } = localState
    return _get(localState, [activeTab, 'availableAggs'], {})
  }
)

export const getFilterValues = createSelector(
  getLocalState,
  (localState) => {
    const { activeTab } = localState
    const specificFilters = _get(localState, [activeTab, 'filters'], {})
    const { requireThumbnail, idsOmittedFromSearch } = localState.filters
    return {
      ...specificFilters,
      requireThumbnail,
      idsOmittedFromSearch
    }
  }
)
