import { createSelector } from 'reselect'
import Immutable from 'seamless-immutable'

import _get from 'lodash/get'
import _uniqWith from 'lodash/uniqWith'

import * as fromThreeviewerSelectors from '../../stores/ducks/threeviewer/selectors'
import * as fromSelectionSelectors from '../../stores/ducks/selection/selectors'
import * as fromPatternsSelectors from '../../stores/ducks/patterns/selectors'
import { approxEqualityQuaternion, approxEqualityVector3 } from '../../../../go3dthree/src/util/ComparisonUtils'

export const evaluateSelection = (filter, mode = 'every') => createSelector(
  fromSelectionSelectors.getSelection,
  fromThreeviewerSelectors.getNodeList,
  (selection, nodeList) => {
    const evaluate = uuid => {
      const node = nodeList[uuid]
      if (!node) return false
      return filter(node)
    }

    return selection.length > 0 &&
      (mode === 'every' ? selection.every(evaluate) : selection.some(evaluate))
  }
)

export const getHasValidSelection = evaluateSelection((node) => {
  const isVisible = node.visible
  const isTemplate = _get(node, ['userData', 'isTemplate'])
  const isRoomsetModel = _get(node, ['userData', 'modelType']) === 'roomset'
  return isVisible && !isTemplate && !isRoomsetModel
})

export const getHasValidTriplanarSelection = createSelector(
  fromSelectionSelectors.getSelection,
  fromThreeviewerSelectors.getNodeList,
  fromThreeviewerSelectors.getForcedUpdate, // to listen on changes in threeviewer
  (selectedUuids, nodeList) => {
    const selectedMeshes = []

    selectedUuids.forEach(uuid => {
      const node = nodeList[uuid]
      node && node.traverse && node.traverse(child => child.isMesh && selectedMeshes.push(child))
    })

    if (selectedMeshes.length !== 1) {
      return false
    }

    const selectedMesh = selectedMeshes[0]
    const isTemplate = _get(selectedMesh, ['userData', 'isTemplate'])
    const isVisible = _get(selectedMesh, ['visible'])
    const hasTriplanarMaterial = _get(selectedMesh, 'material.isTriplanarMaterial')
    const usesTriplanarMaterial = _get(selectedMesh, 'material.useTriplanar')
    const canSetAppearance = _get(selectedMesh, 'userData.setMaterial', true)

    return (
      hasTriplanarMaterial &&
      usesTriplanarMaterial &&
      isVisible &&
      !isTemplate &&
      canSetAppearance
    )
  }
)

export const getHasValidAlignSelection = createSelector(
  fromThreeviewerSelectors.getViewer,
  fromSelectionSelectors.getSelection,
  fromThreeviewerSelectors.getNodeList,
  (viewer, selection, nodeList) => {
    let isSelectionInvalid = false
    const rootNodes = new Set()
    selection.forEach(uuid => {
      const node = nodeList[uuid]
      if (!node) return
      if (!isSelectionInvalid) {
        if (!node.visible) isSelectionInvalid = true
        if (_get(node, ['userData', 'isTemplate'])) isSelectionInvalid = true
        if (_get(node, ['userData', 'modelType']) === 'roomset') isSelectionInvalid = true
      }
      const rootNode = viewer.viewerUtils.findRootNode(node)
      rootNode && rootNodes.add(rootNode)
    })
    return rootNodes.size > 1 && !isSelectionInvalid
  }
)

/**
 * Checks if a list of models are already assembled by comparing positions and orientations.
 * If the models share the same position and orientation, they are considered assembled.
 */
const modelsAreAssembled = (models) => {
  if (models.length <= 1) return true // One or zero models are always considered assembled.

  return (
    (_uniqWith(models, (m1, m2) => approxEqualityVector3(m1.position, m2.position)).length === 1) &&
    (_uniqWith(models, (m1, m2) => approxEqualityQuaternion(m1.quaternion, m2.quaternion)).length === 1)
  )
}

/**
 * Returns true if the current selection is generally valid (getHasValidSelection)
 * and if the current selection is not already assembled.
 */
export const getHasValidAssembleSelection = createSelector(
  getHasValidSelection,
  fromSelectionSelectors.getSelection,
  fromThreeviewerSelectors.getNodeList,
  (hasValidSelection, selection, nodeList) => {
    // If empty or invalid selection, assembly cannot be performed.
    if (!selection.length || !hasValidSelection) {
      return false
    }

    // Filter out all nodes that have a parent in the selection list.
    // If a group is selected, along with one if its children, we can ignore
    // the child, since it is included in the parent group.
    selection = Immutable.asMutable(selection)
      .filter(uuid => !selection.includes(nodeList[uuid].parent.uuid))

    const firstNode = nodeList[selection[0]]

    // If a single non-group is selected, the selection cannot be assembled.
    if (
      selection.length === 1 &&
      !firstNode.userData.isGroup
    ) {
      return false
    }

    // If only a single group node is selected, check if
    // the children of the group are alread assembled.
    // If true, the selection is already assembled.
    if (
      selection.length === 1 &&
      firstNode.userData.isGroup &&
      modelsAreAssembled(firstNode.children)
    ) {
      return false
    }

    const parent = firstNode.parent

    // If multiple nodes are selected, check if they have the same parent group
    // and if the children of the parent group are assembled. If true,
    // the selection is already assembled.
    if (
      parent.userData.isGroup &&
      parent.children.length === selection.length &&
      parent.children.every(child => selection.includes(child.uuid)) &&
      modelsAreAssembled(parent.children)
    ) {
      return false
    }

    // If all previous checks failed, the selection can be assembled.
    return true
  }
)

export const getHasPattern = createSelector(
  fromSelectionSelectors.getSelection,
  fromThreeviewerSelectors.getNodeList,
  fromPatternsSelectors.getHasPatternChanged,
  (selection, nodeList) => {
    const selectedMeshes = []
    selection.forEach(uuid => {
      const node = nodeList[uuid]
      if (node) {
        node.traverse(child => child.isMesh && selectedMeshes.push(child))
      }
    })

    return selectedMeshes.length > 0 && selectedMeshes.every(mesh => {
      return mesh.userData.patternId
    })
  }
)

export const getDecalMaterialList = createSelector(
  fromSelectionSelectors.getSelection,
  fromThreeviewerSelectors.getNodeList,
  fromPatternsSelectors.getHasPatternChanged,
  (selection, nodeList) => {
    const selectedMeshes = []
    selection.forEach(uuid => {
      const node = nodeList[uuid]
      if (node) {
        node.traverse(child => child.isMesh && selectedMeshes.push(child.uuid))
      }
    })
    return selectedMeshes.filter((uuid) => {
      return _get(nodeList, [uuid, 'material', 'isTriplanarMaterial'], false)
    })
  }
)
