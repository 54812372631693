var THREE = require('three')
var inherits = require('inherits')
var EventEmitter = require('events').EventEmitter
var _omit = require('lodash/omit')

function loadModelFile (loader, json) {
  return loader.load(json.url).then(model => ({ scene: model, json }))
}

inherits(Loader, EventEmitter)

function Loader (materialLoader, loader) {
  this.materialLoader = materialLoader
  this.loader = loader
}

Loader.prototype.load = function ({ filePaths, config }) {
  return Promise.resolve()
    .then(() => this.transform(filePaths, config))
    .then((json) => this.loadJson(json))
    .then((models) => Promise.all(models.map(this.loadModel.bind(this))))
}

Loader.prototype.loadJson = function (json) {
  return Promise.all(json.reduce((acc, model) => {
    return (('shouldLoad' in model) ? model.shouldLoad : true)
      ? acc.concat(loadModelFile(this.loader, model))
      : acc
  }, []))
}

Loader.prototype.transform = function (filePaths, config) {
  var models = filePaths
    .filter((file) => (/\.(obj|dae)$/i.test(file)))
    .map((url) => {
      var maps = [
        { type: 'map', fileName: 'VRayDiffuseFilterMap' },
        { type: 'roughnessMap', fileName: 'VRayMtlReflectGlossinessBake' },
        // { type: 'normalMap', fileName: 'VRayNormalsMap' },
        { type: 'lightMap', fileName: 'VRayRawGlobalIlluminationMap' }, // VRayRawTotalLightingMap //VRayRawGlobalIlluminationMap
        { type: 'metalnessMap', fileName: 'Metalness' }
      ].reduce((acc, map) => {
        const fileName = `${url.replace(/\..*/, '')}${map.fileName}.jpg`
        if (filePaths.indexOf(fileName) >= 0) {
          return Object.assign(acc, { [map.type]: fileName })
        }
        return acc
      }, {})

      var model = {
        url: url,
        scale: 1,
        material: {
          maps: maps,
          side: 'FrontSide',
          color: '0xffffff',
          envMapIntensity: 0.2,
          metalness: 0
        },
        receiveShadow: true
      }

      if (/snap_area/.test(model.url)) {
        model.material.color = '0xff0000'
        // model.material.transparent = true
        // model.material.opacity = 0
        model.scale = 0.01
        model.userData = {
          vrNoSourceSnap: true,
          noSourceSnap: true
        }
        model.interactions = {
          vrSnapTargets: { recursive: true },
          snapTargets: { recursive: true }
        }
        model.isGridMaterial = true
        model.gridRepeat = {
          x: 0.2471827993171315 * 25,
          y: 0.9689688662293267 * 25
        }
      }

      if (/snap_area_2/.test(model.url)) {
        model.scale = 1
        model.interactions = model.interactions || {}
        model.interactions.floors = { recursive: true }
        model.gridRepeat = {
          x: 0.4050035343661544 * 25,
          y: 0.9143151191744142 * 25
        }
      }

      if (/skrivbord/i.test(model.url)) {
        model.interactions = {
          vrSnapSources: { recursive: true }
        }
      }

      if (/pano/i.test(model.url)) {
        model.scale = 10
      }

      if (/walls|Plane|window/i.test(model.url)) {
        model.castShadow = true
      }

      if (/dae/i.test(model.url)) {
        model.decay = 2
        model.distance = 2
        model.intensity = 5
        model.shadowMapSize = 1024
        model.castShadow = true
      }

      if (config) {
        const mapTypes = [
          'map',
          'roughnessMap',
          'normalMap',
          'lightMap',
          'metalnessMap'
        ]

        const splitUrl = model.url.split('/')
        const modelUrl = splitUrl[splitUrl.length - 1]
        const baseUrl = model.url.replace(modelUrl, '')

        if (config[modelUrl]) {
          const modelConfig = config[modelUrl]

          Object.keys(modelConfig).forEach((key) => {
            if (mapTypes.indexOf(key) >= 0) {
              if (modelConfig[key]) {
                // Override path
                model.material.maps[key] = `${baseUrl}${modelConfig[key]}`
              } else {
                // Remove map
                delete model.material.maps[key]
              }
            }
          })

          model.material = Object.assign(
            model.material,
            _omit(modelConfig, mapTypes)
          )

          if (modelConfig.color) {
            const [r, g, b] = modelConfig.color.map((val) => ((1 / 255) * val))
            model.material.color = new THREE.Color(r, g, b)
          }
        }
      }

      return model
    })

  return models
}

Loader.prototype.loadModel = function (model) {
  var json = model.json
  var scene = model.scene

  scene.scale.multiplyScalar(json.scale)
  scene.visible = json.hasOwnProperty('visible') ? json.visible : true

  var maps = json.material.maps
  return Promise.all(Object.keys(maps).map((mapType) => {
    return this.materialLoader.loadTexture(maps[mapType]).then(map => ({ type: mapType, map }))
  }))
    .then((textures) => {
      scene.traverse((child) => {
        child.receiveShadow = json.receiveShadow || false
        child.castShadow = json.castShadow || false

        if (!scene.visible) {
          child.visible = false
        }

        if (json.userData) {
          child.userData = json.userData
        }

        if (child.isLight) {
          if (child.isDirectionalLight) {
            child.castShadow = json.hasOwnProperty('castShadow') ? json.castShadow : false
            var mapSize = parseInt(json.shadowMapSize) || 512
            child.shadow.mapSize.set(mapSize, mapSize)
            child.target = child.parent.children[0]
          }

          if (child.isSpotLight) {
            child.castShadow = false
            // While not valid in THREE, we don't have to add to the scene here. It will be fixed in conversion
            child.target.position.copy(child.position)
            child.target.translateY(-1)
            child.distance = 10
            child.decay = 2
            child.penumbra = 0.25
          }
        }

        if (json.isGridMaterial && child.material) {
          child.material = new THREE.ShaderMaterial({
            uniforms: {
              repeat: { value: new THREE.Vector2(json.gridRepeat.x, json.gridRepeat.y) },
              visible: { value: 0 }
            },
            vertexShader: `
            varying vec2 vUv;
            void main() {
              vUv = uv;
              gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
            }
          `,
            fragmentShader: `
            uniform vec2 repeat;
            uniform float visible;
            varying vec2 vUv;
            void main()
            {
              vec2 suv = repeat * vUv;

              float lw = 0.9;

              float xl = smoothstep(lw, 1.0, (suv.x - floor(suv.x))) + smoothstep(lw, 1.0, 1.0 - (suv.x - floor(suv.x)));
              float yl = smoothstep(lw, 1.0, (suv.y - floor(suv.y))) + smoothstep(lw, 1.0, 1.0 - (suv.y - floor(suv.y)));

              vec3 color = vec3(clamp(xl + yl, 0.0, 1.0));
              gl_FragColor = vec4(color, color.x * visible);
            }
          `
          })

          child.material.transparent = true
        } else if (child.material) {
          var material = child.material

          const jsonMaterial = json.material

          textures.forEach((tex) => {
            if (!tex) return
            material[tex.type] = tex.map
          })

          material.transparent = 'transparent' in jsonMaterial ? jsonMaterial.transparent : false
          material.opacity = 'opacity' in jsonMaterial ? jsonMaterial.opacity : 1
          material.side = THREE[json.material.side] || THREE.FrontSide

          material.envMapIntensity = json.envMapIntensity || 0
          material.metalness = json.metalness || 0

          if (material.metalnessMap) {
            material.envMapIntensity = 1
            material.metalness = 1
          }
          material.lightMapIntensity = 1.2

          material = Object.assign(material, _omit(jsonMaterial || {}, 'maps', 'color'))

          if (jsonMaterial.color && jsonMaterial.color.isColor) {
            material.color.set(json.material.color)
          } else {
            material.color.setHex(json.material.color || '0xffffff')
          }

          material.needsUpdate = true
        }
      })
    })
    .then(() => model)
}

module.exports = Loader
