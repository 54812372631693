import React from 'react'

import connectVRMode from './vr-mode-connect'

import {
  PanelSection,
  PanelSectionHeader,
  PanelSectionContent
} from '../../../../components/common/panels/panel-section'

// JSX Components
import Button from '../../../common/button'

const VRModePanelSection = connectVRMode(({
  toggleVive,
  isEnabled
}) => (
  <PanelSection>
    <PanelSectionHeader title='VR' />
    <PanelSectionContent>
      <Button
        onClick={() => isEnabled && toggleVive()}
        disabled={!isEnabled}
        pad={1}
      >
        Start VR
      </Button>
    </PanelSectionContent>
  </PanelSection>
))

const VrPanel = () => {
  return (<VRModePanelSection key='vr' />)
}

export default VrPanel
