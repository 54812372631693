import React from 'react'
import PropTypes from 'prop-types'
import { v4 as uuid } from 'uuid'
import autoBind from 'react-autobind'

import css from './input-dropdown.css'

class Dropdown extends React.Component {
  constructor (props) {
    super(props)
    autoBind(this)
  }

  handleChange (event) {
    event.persist()
    this.props.onChange(event.target.value)
  }

  render () {
    if (!this.props.options || !this.props.options.length) return null
    const id = this.props.id || 'field-' + uuid()

    const disabled = this.props.disabled
    const optKey = this.props.optionKey
    const optValue = this.props.optionValue
    const optLabel = this.props.optionLabel

    const value = (this.props.value && this.props.value.length > 0)
      ? this.props.value
      : this.props.nullOption
        ? this.props.nullOption.value
        : this.props.options[0][optValue]

    return (
      <div className={disabled
        ? `${css.wrapper} icon-down-open c-gray`
        : `${css.wrapper} icon-down-open`
      }
      data-testid={this.props['data-testid']}
      >
        <select
          className={disabled
            ? `${this.props.className}
            ${css.root}
            pl1
            pr3
            bg-gray-light
            bc-secondary-focus
            bc-gray-light
            initial-cursor
            c-gray`
            : `${this.props.className}
            ${css.root}
            pointer
            pl1
            pr3
            bg-white
            bc-secondary-focus
            bc-gray-light`
          }
          disabled={disabled}
          id={id}
          onChange={this.handleChange}
          value={value}
          data-testid={this.props.testid || 'dropDown'}
        >
          {(this.props.nullOption ? [this.props.nullOption] : []).concat(this.props.options).map((item, index) => {
            return <option key={item[optKey] + '-' + index} value={item[optValue]}>{item[optLabel]}</option>
          })}
        </select>
      </div>
    )
  }
}

Dropdown.propTypes = {
  id: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  nullOption: PropTypes.shape({
    key: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired,
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
  }),
  optionKey: PropTypes.string,
  optionValue: PropTypes.string,
  optionLabel: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func
}

Dropdown.defaultProps = {
  id: '',
  value: '',
  optionValue: 'value',
  optionLabel: 'label',
  optionKey: 'key',
  options: [],
  className: '',
  onChange: function () { }
}

export default Dropdown
