import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import autoBind from 'react-autobind'
import { createSelector } from 'reselect'
import cs from 'classnames'
import { Column, Table, AutoSizer, SortDirection, SortIndicator } from 'react-virtualized'

import _get from 'lodash/get'

// Actions
import * as fromUsers from '../../../stores/ducks/users'
import * as fromAdmin from '../../../stores/ducks/admin'
import * as fromSpreadsheet from '../../../stores/ducks/spreadsheet'

// Selectors
import * as fromAdminSelectors from '../../../stores/ducks/admin/selectors'
import * as fromUserSelectors from '../../../stores/ducks/users/selectors'

import Grid from '../../common/grid/grid'
import InputSearch from '../../common/form/input-search'
import EditableText from '../../../components/common/form/editable-text'
import RemoveUserModal from './remove-user-modal'
import DiscardChangesModal from './discard-user-changes-modal'
import NotificationBubble from '../../common/notification-bubble'

import css from './index.css'

const Link = (props) => (
  <div
    {...props}
    tabIndex={props.disabled ? -1 : 0}
    className={`pointer underline truncate ${css.focusableElement} ${props.disabled ? 'c-black-25' : 'c-secondary'}`}
    onClick={(e) => {
      if (props.disabled) return

      props.onClick(e)
    }}
    onKeyPress={(e) => {
      if (props.disabled) return

      // enter key
      if (e.charCode === 13) {
        props.onClick(e)
      }
    }}
  >
    {props.children}
  </div>
)

Link.defaultProps = { disabled: false }

class AdminUsers extends React.Component {
  constructor (props, context) {
    super(props, context)

    const sortBy = 'email'
    const sortDirection = SortDirection.ASC

    this.sortedUsers = []

    this.state = {
      sortBy,
      sortDirection,
      email: '',
      changePasswordUserId: '',
      disableSearchInput: false
    }

    autoBind(this)
  }

  componentDidMount () {
    this.props.getUsersIncludingDeleted()
  }

  componentWillUnmount () {
    this.props.setSelectedUser(null)
    this.props.clearUsers()
    this.props.getActiveUsers()
    this.props.setUserSearchString('')
  }

  _isSelected (rowIndex) {
    return this.sortedUsers[rowIndex].id === _get(this.props.selectedUser, 'id', '')
  }

  _isRemoved (rowIndex) {
    return !!this.sortedUsers[rowIndex].removedAt
  }

  _isGdprRemoved (rowIndex) {
    return !!this.sortedUsers[rowIndex].gdprRemovedAt
  }

  _headerRenderer ({ label, dataKey, sortBy, sortDirection }) {
    return (
      <div className='c-black-25'>
        {label}
        {sortBy === dataKey && <SortIndicator sortDirection={sortDirection} />}
      </div>
    )
  }

  _rowClassName ({ index }) {
    if (index < 0) {
      return ''
    }

    const isRemoved = this._isRemoved(index)
    const isSelected = this._isSelected(index)
    return cs(
      css.row,
      {
        [css.rowSelected]: isSelected,
        'c-black-25': this.props.activeSelection && !isSelected && !isRemoved,
        'c-error': isRemoved,
        'bg-black-10': index % 2 === 0
      }
    )
  }

  _renderEditableField ({ rowIndex }, property) {
    const user = this.sortedUsers[rowIndex]
    const isSelected = this._isSelected(rowIndex)
    const isRemoved = this._isRemoved(rowIndex)

    return (
      isSelected ? (
        <EditableText
          onClick={(e) => e.stopPropagation()}
          defaultValue={this.props.selectedUser[property] || property}
          editable={!isRemoved && isSelected}
          onChange={(change) => this.props.setUserProperties({ [property]: change })}
          singleClickEdit
          className={'f5 col-12 truncate'}
          tabIndex={0}
        >
          {(value, props) => (
            <div {...props}>{value}</div>
          )}
        </EditableText>
      ) : (
        <div key={user.id} className='truncate'>
          {user[property] || property}
        </div>
      )
    )
  }

  _toggleDeletedUsers (value) {
    this.props.setSelectedUser(null)
    this.props.setShowDeleted(value)
  }

  _sortList ({ sortBy, sortDirection }) {
    const { users } = this.props

    const sortedList = [...users]

    if (sortBy) {
      sortedList.sort(function (a, b) {
        // sort by email
        var nameA = a[sortBy].toUpperCase() // ignore upper and lowercase
        var nameB = b[sortBy].toUpperCase() // ignore upper and lowercase
        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }
        // names must be equal
        return 0
      })

      if (sortDirection === SortDirection.DESC) {
        sortedList.reverse()
      }
    }
    return sortedList
  }

  _sort ({ sortBy, sortDirection }) {
    this.setState({ sortBy, sortDirection })
  }

  setSearchString (event) {
    this.props.setUserSearchString(event)
  }

  downloadSpreadsheet () {
    this.props.downloadUserSpreadsheet({})
  }

  render () {
    const {
      sortBy,
      sortDirection
    } = this.state

    const props = this.props
    this.sortedUsers = this._sortList({ sortBy, sortDirection })

    return (
      <div>
        <NotificationBubble
          className={'mtn2'}
          notification={props.notification}
          error={props.error}
          onClearOrTimeout={props.resetNotification}
        />

        <Grid
          columns={['1fr', 'auto', 'auto', 'auto']}
          gridGap={24}
          style={{ alignItems: 'center' }}
        >
          <InputSearch
            placeholder='Search...'
            onChange={this.setSearchString}
            disabled={this.props.selectedUser !== null}
            onClearSearch={() => this.setSearchString('')}
            value={props.userSearchString}
          />

          <div
            onClick={this.downloadSpreadsheet}
            className={cs(css.upAnimation, 'pointer mr2 bold')}
          >
            .xls
          </div>
        </Grid>
        <AutoSizer>
          {({ width, height }) => (
            <Table
              disableHeader={false}
              rowClassName={this._rowClassName}
              rowGetter={({ index }) => this.sortedUsers[index]}
              headerHeight={40}
              rowHeight={30}
              rowCount={props.users.length}
              width={width}
              height={height}
              sort={this._sort}
              sortBy={sortBy}
              sortDirection={sortDirection}
              gridClassName='border-left border-right bc-black-55'
            >

              <Column
                width={400}
                label='Email'
                dataKey='email'
                className='border-right bc-black-55 height-100 flex items-center'
                flexGrow={2}
                headerRenderer={this._headerRenderer}
              />
              <Column
                width={200}
                label='First name'
                dataKey='firstName'
                className='height-100 flex items-center'
                flexGrow={2}
                headerRenderer={this._headerRenderer}
                disableSort
              />
              <Column
                width={200}
                label='Last name'
                dataKey='lastName'
                className='border-right bc-black-55 height-100 flex items-center'
                flexGrow={2}
                headerRenderer={this._headerRenderer}
                disableSort
              />
            </Table>
          )}

        </AutoSizer>
        <RemoveUserModal
          width={600}
          selectedUser={props.selectedUser}
          isOpen={props.removeUserModalOpen}
          onRequestClose={() => {
            props.setRemoveUserModalOpen(false)
            props.setSelectedUser(null)
          }}
          onCancel={() => {
            props.setRemoveUserModalOpen(false)
            props.setSelectedUser(null)
          }}
          onConfirm={() => {
            props.setRemoveUserModalOpen(false)
            props.setSelectedUser(null)
          }}

          formTitle={'Remove user'}
          formDescription={`Are you sure you want to remove the user with email: ${props.selectedUser && props.selectedUser.email}?`}
          buttonText={'Yes, remove!'}
        />

        <RemoveUserModal
          width={600}
          selectedUser={props.selectedUser}
          isOpen={props.gdprRemoveUserModalOpen}
          onRequestClose={() => {
            props.setGdprRemoveUserModalOpen(false)
            props.setSelectedUser(null)
          }}
          onCancel={() => {
            props.setGdprRemoveUserModalOpen(false)
            props.setSelectedUser(null)
          }}
          onConfirm={() => {
            props.setGdprRemoveUserModalOpen(false)
            props.setSelectedUser(null)
          }}

          formTitle={'Permanently remove user data'}
          formDescription={(
            <span>
              <span>Are you sure you want to delete all user data for the user with email: {props.selectedUser && props.selectedUser.email}?</span>
              <br />
              <br />
              Deleting this data is <b>permanent</b>. Restoring this user will <b>NOT</b> be possible.
            </span>
          )}
          buttonText={'Yes, I understand. Remove user data.'}
        />

        <DiscardChangesModal
          isOpen={props.discardChangesModalOpen}
          onRequestClose={() => props.setDiscardUserChangesModalOpen(false)}
          width={600}
          onCancel={() => {
            props.setDiscardUserChangesModalOpen(false)
          }}
          onConfirm={() => {
            props.setDiscardUserChangesModalOpen(false)
            props.setSelectedUser(null)
          }}
          selectedUserEmail={
            // get original email
            props.selectedUser && props.users.find(user => user.id === props.selectedUser.id).email
          }
        />
      </div>
    )
  }
}

const mapStateToProps = createSelector(
  (_, ownProps) => ownProps.searchString,
  (state) => state.users.error,
  (state) => state.users.notification,
  fromAdminSelectors.getUserEntries,
  fromUserSelectors.getCurrentUserId,
  fromAdminSelectors.getHasUserEditChanges,
  (state) => state.admin,
  (ownProps, error, notification, users, currentUserId, hasUserEditChanges, admin) => {
    return {
      error,
      notification,
      users: Object.values(users).filter(item => {
        if (
          (item.firstName && item.firstName.toUpperCase().includes(admin.userSearchString.toUpperCase())) ||
          (item.lastName && item.lastName.toUpperCase().includes(admin.userSearchString.toUpperCase())) ||
          (item.email && item.email.toUpperCase().includes(admin.userSearchString.toUpperCase()))
        ) {
          return item
        }
      }
      ),
      currentUserId,
      hasUserEditChanges,
      activeSelection: admin.selectedUser && admin.selectedUser.id,
      selectedUser: admin.selectedUser,
      newUserModalOpen: admin.newUserModalOpen,
      newResetPasswordModalOpen: admin.resetPasswordModalOpen,
      discardChangesModalOpen: admin.discardUserChangesModalOpen,
      removeUserModalOpen: admin.removeUserModalOpen,
      gdprRemoveUserModalOpen: admin.gdprRemoveUserModalOpen,
      showDeleted: admin.showDeleted,
      userSearchString: admin.userSearchString
    }
  }
)

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getUsers: fromUsers.getUsers,
    getActiveUsers: fromUsers.getActiveUsers,
    getUsersIncludingDeleted: fromUsers.getUsersIncludingDeleted,
    clearUsers: fromUsers.clearUsers,
    resetNotification: fromUsers.resetNotification,
    setSelectedUser: fromAdmin.setSelectedUser,
    setShowDeleted: fromAdmin.setShowDeleted,
    setUserProperties: fromAdmin.setUserProperties,
    setNewUserModalOpen: fromAdmin.setNewUserModalOpen,
    setResetPasswordModalOpen: fromAdmin.setResetPasswordModalOpen,
    setDiscardUserChangesModalOpen: fromAdmin.setDiscardUserChangesModalOpen,
    setRemoveUserModalOpen: fromAdmin.setRemoveUserModalOpen,
    setGdprRemoveUserModalOpen: fromAdmin.setGdprRemoveUserModalOpen,
    setUserSearchString: fromAdmin.setUserSearchString,
    downloadUserSpreadsheet: fromSpreadsheet.getUserSpreadsheet
  }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminUsers)
