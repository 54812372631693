import React from 'react'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import { PROJECT_TYPES } from '../../../../constants'
import * as fromProjectsSelectors from '../../../../stores/ducks/projects/selectors'
import DefaultRendersPanel from './default'
import ImagePackagesRendersPanel from './image-packages'

function RendersPanel (props) {
  if (props.projectType === PROJECT_TYPES.IMAGE_PACKAGE) {
    return <ImagePackagesRendersPanel />
  }

  return <DefaultRendersPanel />
}

const mapStateToProps = createSelector(
  fromProjectsSelectors.getCurrentEntry,
  (project) => {
    const projectType = project.projectType
    return {
      projectType: projectType || PROJECT_TYPES.DEFAULT
    }
  }
)

export default connect(
  mapStateToProps
)(RendersPanel)
