import React, { useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import * as fromThreeviewerSelectors from '../../../stores/ducks/threeviewer/selectors'
import InputNumericRange from '../../common/form/input-numeric-range'
import { RootState } from '../../../stores/ducks'
import Button from '../../common/button'
import { MdRotate90DegreesCcw } from 'react-icons/md'
import colors from '../../../../css/colors'
import { initControls, dispose, setTranslation, rotateMap90DegreesOnAxis, saveTranslation, addSavedTranslationToUndoRedoHistory } from '../../../stores/ducks/threeviewer/triplanar'
import { InputNumber } from '../../common/form/input-number-alt'
import { SceneGraphMesh as ISceneGraphMesh } from '../../../../../go3dthree/types/SceneGraph'

type Axis = 'x' | 'y' | 'z'

const MIN = -1
const MAX = 1
const STEP = 0.001

const mapStateToProps = (state: RootState) => {
  const viewer = state.threeviewer.viewer
  let activeAxises = { x: true, y: true, z: true }
  if (viewer) {
    const selectedMesh = Object.values(viewer.picker.selection)[0] as ISceneGraphMesh
    if (selectedMesh && selectedMesh.userData.splitData?.activeAxises) {
      activeAxises = selectedMesh.userData.splitData.activeAxises
    }
  }

  return {
    activeAxises,
    triplanarTranslation: state.threeviewer.triplanar.translation,
    triplanarMapRotation: state.threeviewer.triplanar.mapRotation,
    viewer: fromThreeviewerSelectors.getViewer(state)
  }
}

const connector = connect(mapStateToProps, {
  setTranslation: setTranslation,
  rotateMap90DegreesOnAxis: rotateMap90DegreesOnAxis,
  initControls: initControls,
  saveTranslation: saveTranslation,
  addSavedTranslationToUndoRedoHistory: addSavedTranslationToUndoRedoHistory,
  dispose: dispose
})
type PropsFromRedux = ConnectedProps<typeof connector>
type Props = PropsFromRedux & {
  disabled: boolean
}

function TriplanarSettings (props: Props) {
  React.useEffect(() => {
    props.initControls()
    return () => {
      props.dispose()
    }
  }, [])

  const handleTranslation = (value: number, axis: Axis) => {
    props.setTranslation(value, axis)
  }

  return (
    <div className='flex justify-around'>
      <div className='flex'>
        <TranslationInput
          axis='x'
          disabled={props.disabled || !props.activeAxises.x}
          value={props.triplanarTranslation.x ?? 0}
          onBlur={() => props.addSavedTranslationToUndoRedoHistory()}
          onFocus={() => props.saveTranslation()}
          onChange={(value) => handleTranslation(value, 'x')}
        />
        <TranslationInput
          axis='y'
          disabled={props.disabled || !props.activeAxises.y}
          value={props.triplanarTranslation.y ?? 0}
          onBlur={() => props.addSavedTranslationToUndoRedoHistory()}
          onFocus={() => props.saveTranslation()}
          onChange={(value) => handleTranslation(value, 'y')}
        />
        <TranslationInput
          axis='z'
          disabled={props.disabled || !props.activeAxises.z}
          value={props.triplanarTranslation.z ?? 0}
          onBlur={() => props.addSavedTranslationToUndoRedoHistory()}
          onFocus={() => props.saveTranslation()}
          onChange={(value) => handleTranslation(value, 'z')}
        />
      </div>

      <div className='flex ml2'>
        <MapRotationInput
          axis='x'
          onClick={() => props.rotateMap90DegreesOnAxis('x')}
          disabled={props.disabled || !props.activeAxises.x}
          value={props.triplanarMapRotation.x}
        />
        <MapRotationInput
          axis='y'
          onClick={() => props.rotateMap90DegreesOnAxis('y')}
          disabled={props.disabled || !props.activeAxises.y}
          value={props.triplanarMapRotation.y}
        />
        <MapRotationInput
          axis='z'
          onClick={() => props.rotateMap90DegreesOnAxis('z')}
          disabled={props.disabled || !props.activeAxises.z}
          value={props.triplanarMapRotation.z}
        />
      </div>
    </div>
  )
}

function MapRotationInput (props: {
  axis: 'x' | 'y' | 'z'
  value: number
  disabled: boolean
  onClick: () => void
}) {
  return (
    <div className='c-gray f8 mr1'>
      <div className='flex items-center mb1'>
        Map rotation {props.axis}
      </div>
      <div className='flex items-center' title="Rotate map 90 degrees on x axis">
        <Button
          disabled={props.disabled}
          onClick={() => props.onClick()}
          pad={false}
          size='small'
          inverted
          className='mr1'
          style={{ borderColor: props.axis === 'x' ? colors.error : (props.axis === 'y' ? colors.success : colors.secondary) }}
          icon={<MdRotate90DegreesCcw height={15} width={15} style={{ margin: '-5px 0' }} />}
        />
        {props.value}°
      </div>
    </div>
  )
}

function TranslationInput (props: {
  axis: 'x' | 'y' | 'z'
  value: number
  disabled: boolean
  onFocus: () => void
  onBlur: () => void
  onChange: (value: number) => void
}) {
  const [inputIsEmpty, setInputIsEmpty] = useState<boolean>(false)

  return (
    <div className="flex flex-column" style={{ width: '200px' }}>
      <div className='flex justify-between items-center px1 mb1'>
        <div className='c-gray f8'>Move {props.axis}</div>
        <InputNumber
          className='bc-transparent br-small f8 bg-white-10 c-gray'
          style={{ height: '2em', width: '6.5em' }}
          value={inputIsEmpty ? '' : props.value}
          min={MIN}
          max={MAX}
          step={STEP}
          onFocus={() => props.onFocus()}
          onBlur={() => {
            if (inputIsEmpty) {
              setInputIsEmpty(false)
              props.onChange(0)
            }
            props.onBlur()
          }}
          onChange={(value: number | '') => {
            if (value === '') {
              setInputIsEmpty(true)
            } else {
              setInputIsEmpty(false)
              props.onChange(value)
            }
          }
          }
        />
      </div>
      <InputNumericRange
        disabled={props.disabled}
        min={MIN}
        max={MAX}
        step={STEP}
        value={props.value}
        onMouseDown={() => props.onFocus()}
        onMouseUp={() => props.onBlur()}
        onChange={(value) => props.onChange(Number(value))}
      />
    </div>
  )
}

export default connector(TriplanarSettings)
