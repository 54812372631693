import React from 'react'

const IconSurface = props => (
  <svg width={props.size} height={props.size} viewBox='0 0 24 24' {...props}>
    <path
      fill={props.color}
      d='M12.7692308,20.0408654 L20.4615385,15.8461538 L20.4615385,8.20192308 L12.7692308,11.0024038 L12.7692308,20.0408654 Z M12,9.64423077 L20.3894231,6.59134615 L12,3.53846154 L3.61057692,6.59134615 L12,9.64423077 Z M22,6.61538462 L22,15.8461538 C22,16.126604 21.9278853,16.387018 21.7836538,16.6274038 C21.6394224,16.8677897 21.4431102,17.0560891 21.1947115,17.1923077 L12.7331731,21.8076923 C12.508813,21.9358981 12.2644244,22 12,22 C11.7355756,22 11.491187,21.9358981 11.2668269,21.8076923 L2.80528846,17.1923077 C2.55688978,17.0560891 2.36057764,16.8677897 2.21634615,16.6274038 C2.07211466,16.387018 2,16.126604 2,15.8461538 L2,6.61538462 C2,6.29487019 2.09214651,6.00240517 2.27644231,5.73798077 C2.4607381,5.47355637 2.70512668,5.28525697 3.00961538,5.17307692 L11.4711538,2.09615385 C11.6474368,2.03205096 11.8237171,2 12,2 C12.1762829,2 12.3525632,2.03205096 12.5288462,2.09615385 L20.9903846,5.17307692 C21.2948733,5.28525697 21.5392619,5.47355637 21.7235577,5.73798077 C21.9078535,6.00240517 22,6.29487019 22,6.61538462 Z'
    />
  </svg>
)

IconSurface.defaultProps = {
  color: 'currentcolor',
  size: '1em'
}

export default IconSurface
