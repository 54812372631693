import React from 'react'

const IconFloorplanMode = props => (
  <svg width={props.size} height={props.size} viewBox="0 0 24 24" {...props}>
    <path
      d="M23.4,12.2288739 C22.5811626,13.7259107 21.007216,14.7391304 19.2,14.7391304 C16.5490332,14.7391304 14.4,12.5589518 14.4,9.86956522 C14.4,8.03615764 15.3987452,6.43940021 16.8743957,5.60869565 L0.6,5.60869565 L0.6,18.3913043 L23.4,18.3913043 L23.4,12.2288739 Z M23.4,7.5102565 L23.4,5.60869565 L21.5256043,5.60869565 C22.312305,6.05156191 22.9634604,6.71215439 23.4,7.5102565 Z M0,5 L24,5 L24,19 L0,19 L0,5 Z M19.2,14.1304348 C21.5195959,14.1304348 23.4,12.2227785 23.4,9.86956522 C23.4,7.51635194 21.5195959,5.60869565 19.2,5.60869565 C16.8804041,5.60869565 15,7.51635194 15,9.86956522 C15,12.2227785 16.8804041,14.1304348 19.2,14.1304348 Z"
      fill={props.color}
    />
  </svg>
)

IconFloorplanMode.defaultProps = {
  color: 'currentcolor',
  size: '1em'
}

export default IconFloorplanMode
