import store from '../../stores'

import * as fromNews from '../../stores/ducks/news'

export default function handleNews (msg) {
  if (msg.remove) {
    store.dispatch(fromNews.removeArticle(msg.doc.id))
  } else {
    store.dispatch(fromNews.receiveArticle(msg.doc))
  }
}
