import { EventEmitter } from 'events'
import { srgb2lin } from './util/ColorUtils'
import * as THREE from 'three'
import { DDSLoader } from 'three/examples/jsm/loaders/DDSLoader.js'
import { BasisTextureLoader } from 'three/examples/jsm/loaders/BasisTextureLoader.js'
var _ = require('lodash')

const _isUndefined = require('lodash/isUndefined')
const _forEach = require('lodash/forEach')

var PatchedTextureLoader = require('./plugins/PatchedTextureLoader')(THREE)

var TriplanarMaterial = require('./materials/TriplanarMaterial')

const getMaterialType = (materialJson) => {
  if (materialJson.materialType) return materialJson.materialType
  if (materialJson.decalMaterial) return 'decalMaterial'
  return 'triplanarMaterial'
}

export default class MaterialLoader extends EventEmitter {
  constructor (renderer) {
    super()
    this.renderer = renderer
    this.textures = {}

    this.texLoader = new PatchedTextureLoader()
    this.ddsLoader = new DDSLoader()
    this.basisloader = new BasisTextureLoader()
    this.basisloader.setTranscoderPath('/go3d-shared/basis/')
    this.basisloader.detectSupport(renderer)
  }

  loadTexture (url, onProgress) {
    if (!(url in this.textures)) {
      this.textures[url] = new Promise((resolve, reject) => {
        this.emit('onStartLoad', url)

        let loader
        if (hasFileEnding(url, 'basis')) {
          loader = this.basisloader
        } else if (hasFileEnding(url, 'dds') && this.supportsDxt()) {
          loader = this.ddsLoader
        } else {
          loader = this.texLoader
        }

        loader.load(
          url,
          map => {
            this.emit('loaded', url)
            resolve(map)
          },
          onProgress || this.emit.bind(this, 'onProgress'),
          err => {
            this.emit('onError', err)
            reject(err)
          }
        )
      })
    }
    return this.textures[url]
  }

  loadColor (color, key, material) {
    var rgbColor = new THREE.Color().setHex(color)

    switch (key) {
      case 'metalnessMap':
        return { metalness: rgbColor.b * _.get(material, 'metalness', 1) }
      case 'roughnessMap':
        return { roughness: rgbColor.g * _.get(material, 'roughness', 1) }
      case 'map':
        rgbColor = srgb2lin(rgbColor)
        return { color: rgbColor }
      case 'refractMap':
        rgbColor = srgb2lin(rgbColor)
        return { refractColor: rgbColor }
      case 'alphaMap':
        return { color: rgbColor } // TODO: Change this
    }
  }

  supportsDxt () {
    var supportsDxt = false
    if (this.renderer.extensions.get('WEBGL_compressed_texture_s3tc') !== null) {
      supportsDxt = true
    }
    return supportsDxt
  }

  async loadMaterial (materialJson, cb) {
    const textures = Object.values(materialJson.textures)
      .map(texture => ({
        type: texture.type,
        uri: texture.uri,
        color: texture.color
      }))

    const texUrls = textures
      .filter(texture => !texture.color)
      .reduce((acc, val) => {
        acc[val.type] = val.uri
        return acc
      }, {})

    const texColors = textures
      .filter(texture => texture.color)
      .reduce((acc, val) => {
        acc[val.type] = val.color
        return acc
      }, {})

    var repeat = materialJson.repeat

    if (!repeat && materialJson.offsetRepeat) {
      repeat = { x: materialJson.offsetRepeat.z, y: materialJson.offsetRepeat.w }
    } else if (!repeat) {
      repeat = { x: 1, y: 1 }
    }

    const maps = await this.loadMaps(texUrls)

    if ('map' in maps) {
      maps.map.encoding = THREE.sRGBEncoding
    }

    _forEach(maps, function (value, key) {
      if (value) {
        value.wrapS = THREE.RepeatWrapping
        value.wrapT = THREE.RepeatWrapping
        if (value.repeat) {
          value.repeat.set(repeat.x || 1, repeat.y || 1)
        }
      }
    })

    let material = _(materialJson).pick([
      'metalness',
      'roughness',
      'bumpMapScale',
      'transparent',
      'opacity'
    ]).extend(maps).value()

    // For each color converted map, add a map to the material with the specified color
    _forEach(texColors, (value, key) => {
      material = _.assign(material, this.loadColor(value, key, material))
    })

    if (!material.color) {
      material.color = _parseColor(materialJson.color)
    }
    if (!material.opacity) {
      material.opacity = 1.0
    }

    if (materialJson.type) {
      material.classification = materialJson.type
    }

    var normalScale = _parseFloat(materialJson.normalScale, 1)

    _setDecalProps(material, materialJson)
    material.useTriplanar = _isUndefined(materialJson.useTriplanar) ? true : materialJson.useTriplanar
    material.useLocalEnvMap = _isUndefined(materialJson.useLocalEnvMap) ? false : materialJson.useLocalEnvMap
    material.mapRepeat = new THREE.Vector2(_parseFloat(materialJson.repeat.x, 1), _parseFloat(materialJson.repeat.y, 1))
    material.mapOffset = _isUndefined(materialJson.offset) ? new THREE.Vector2(0.0, 0.0) : new THREE.Vector2(_parseFloat(materialJson.offset.x, 0), _parseFloat(materialJson.offset.y, 0))

    material.normalScale = new THREE.Vector2(normalScale, normalScale)

    return this.createMaterialFromJSON(material, getMaterialType(materialJson))
  }

  createMaterialFromJSON (base, materialType) {
    var triplanar = new TriplanarMaterial(base)
    triplanar = this.setTransparency(triplanar, base)
    triplanar.useColorTextureMix = base.canSetColor
    return triplanar
  }

  setTransparency (material, base) {
    material.useAlphaMap = false
    material.transparent = material.transparent ? material.transparent : false

    // refract
    var transparentKeyWords = ['glass'] // Materials of these types also gets refraction.
    var transparentMaterial = (_.includes(transparentKeyWords, base.classification))

    if (material.uniforms.refractMap.value || material.uniforms.refractColor.value || transparentMaterial) {
      material.opacity = 0.99
      material.transparent = true
      material.useRefraction = true
      material.useRefractMap = (material.uniforms.refractMap.value)
      material.refractColor = transparentMaterial ? material.color : material.refractColor
      material.transparentMaterial = transparentMaterial
    }

    // alpha
    if (material.uniforms.alphaMap.value || material.uniforms.alphaColor.value) {
      material.opacity = 0.99
      material.useAlphaMap = true
      material.transparent = true
    }

    return material
  }

  async loadMaps (urls) {
    const loadedMaps = {}
    Object.keys(urls).forEach(key => {
      loadedMaps[key] = this.loadTexture(urls[key])
    })

    await Promise.all(Object.keys(loadedMaps).map(async key => {
      loadedMaps[key] = await loadedMaps[key]
    }))

    return loadedMaps
  }

  calculateDecalScale (decalJson) {
    const dimensions = decalJson.dimensions
    return new THREE.Vector2(_parseFloat(1 / dimensions.width, 1), _parseFloat(1 / dimensions.height, 1))
  }

  async loadDecal (decalJson, cb) {
    const map = await this.loadTexture(decalJson.uri)

    map.encoding = THREE.sRGBEncoding
    map.wrapS = THREE.RepeatWrapping
    map.wrapT = THREE.RepeatWrapping

    return map
  }
}

var hasFileEnding = (file, fileEnding) => {
  return file ? file.split('.').pop() === fileEnding : false
}

function _parseFloat (float, defaultValue) {
  var _parsed = Number.parseFloat(float)
  return Number.isNaN(_parsed) ? defaultValue : _parsed
}

function _parseNumber (float, defaultValue) {
  var _parsed = Number(float)
  return Number.isNaN(_parsed) ? defaultValue : _parsed
}

function _parseColor (hexcolor) {
  // Sane persons stores their colors in sRGB while ThreeJS expects linear
  return srgb2lin(new THREE.Color(_parseNumber(hexcolor, 0xffffff)))
}

function _setDecalProps (material, materialJson) {
  var repeat = materialJson.repeat || {}
  var offsetRepeat = materialJson.offsetRepeat || {}
  var decalOffset = materialJson.decalOffset || {}
  var decalScale = materialJson.decalScale || {}

  material.offsetRepeat = new THREE.Vector4(
    _parseFloat(offsetRepeat.x, 0),
    _parseFloat(offsetRepeat.y, 0),
    _parseFloat(offsetRepeat.z, repeat.x || 1),
    _parseFloat(offsetRepeat.w, repeat.x || 1)
  )

  material.decalOffset = new THREE.Vector2(
    _parseFloat(decalOffset.x, 0),
    _parseFloat(decalOffset.y, 0)
  )
  material.decalScale = new THREE.Vector2(
    _parseFloat(decalScale.x, 0),
    _parseFloat(decalScale.y, 0)
  )
  material.decalRotation = materialJson.decalRotation || 0
}
