import type { MaterialData, ProductAnalyze, MissingData, CostObject, PartIdWithCost } from './productAnalysisTypes'
import type { NodesWithChildren } from './selectNodesWithChildren'
import type { SceneGraphNode3d as ISceneGraphNode3d, SceneGraphMesh as ISceneGraphMesh } from '../../../../../../go3dthree/types/SceneGraph'
import _get from 'lodash/get'

type ChildDataAnalysis = {
  topNodeThatIsPart: PartCostNode | undefined
  weight: number
  appearanceCost: CostObject | undefined
  materialCost: CostObject | undefined
  co2: number
  area: number
  volume: number
  name: string
  carrierName: string
  appearanceName: string
  appearanceId: string
  parentName: string
  parentNamePtc: string | undefined
  boundingBox: any
}

type PartCost = {
  costPrice: number
  currency: 'eur' | 'cny'
  id: string
  partNumber: number
  salesPrice: number
}

type PartCostNode = {
  node: ISceneGraphNode3d,
  partNumber: number
}

type PartCostIdCount = { partNumber: number, count: number }

const getPartCost = async (id : number, currencyRate : number) => {
  const res = await fetch(`/api/part-cost/${id}`)
  if (res.status === 200) {
    const data : PartCost = await res.json()
    switch (data.currency) {
      case 'eur': return (data.costPrice ? data.costPrice * 1 : 0)
      case 'cny': return (data.costPrice ? data.costPrice * currencyRate : 0)
      default: return 0
    }
  }
  return null
}

const getCurrencyRate = async () => {
  const res = await fetch('/api/part-cost/currency')
  if (res.status === 200) {
    const rate = await res.json()
    return rate
  } else {
    return new Error('Could not fetch currency rate')
  }
}

const getAnalysationForNode = async (currencyRate: number, materialData: MaterialData[], childNode: ISceneGraphNode3d) => {
  const mesh = childNode as ISceneGraphMesh
  const boundingBox = childNode.localBoundingBox
  const area = childNode.userData.area * 100 || mesh.calculateArea() // m2 to d2
  const volume = childNode.userData.volume * 1000 || mesh.calculateVolume() // m3 to d3
  const appearanceName = childNode.userData.materialName
  const appearanceId = childNode.userData.materialId
  const appearanceData = materialData.find((material: MaterialData) => material.carrierId === appearanceId)
  const carrierName = childNode?.userData?.carrier_id?.toLowerCase().trim()
  const carrierData = materialData.find((material: MaterialData) => material.carrierId === carrierName)
  const parentName = childNode.parent.name
  const parentNamePtc = childNode.parent.userData.ptc_wm_name

  const childData : ChildDataAnalysis = {
    topNodeThatIsPart: undefined,
    weight: 0,
    appearanceCost: undefined,
    materialCost: undefined,
    co2: 0,
    area,
    volume,
    name: childNode.userData.name,
    carrierName,
    appearanceName,
    appearanceId,
    parentName,
    parentNamePtc,
    boundingBox
  }

  // Check five levels up for part cost id (ptc_vm_part_number)
  const possiblePartNumbers = [
    { node: childNode.parent?.parent?.parent?.parent?.parent, partNumber: childNode.parent?.parent?.parent?.parent?.parent?.userData?.ptc_wm_part_number },
    { node: childNode.parent?.parent?.parent?.parent, partNumber: childNode.parent?.parent?.parent?.parent?.userData?.ptc_wm_part_number },
    { node: childNode.parent?.parent?.parent, partNumber: childNode.parent?.parent?.parent?.userData?.ptc_wm_part_number },
    { node: childNode.parent?.parent, partNumber: childNode.parent?.parent?.userData?.ptc_wm_part_number },
    { node: childNode.parent, partNumber: childNode.parent?.userData?.ptc_wm_part_number },
    { node: childNode, partNumber: childNode.userData?.ptc_wm_part_number }
  ].filter(t => t.partNumber && t.partNumber !== '<Not Specified>')

  if (possiblePartNumbers.length > 0) {
    childData.topNodeThatIsPart = possiblePartNumbers[0]
  }

  if (area && appearanceData?.costArea) {
    childData.weight = childData.weight + area * (appearanceData?.simulationThickness ?? 0) * (appearanceData?.density ?? 0)
    childData.appearanceCost = childData.topNodeThatIsPart ? undefined : {
      AP: area * (appearanceData?.costArea?.AP ?? 0),
      EU: area * (appearanceData?.costArea?.EU ?? 0)
    }
  }

  if (volume && carrierData) {
    childData.materialCost = childData.topNodeThatIsPart ? undefined : {
      AP: volume * (carrierData?.costVolume?.AP ?? 0),
      EU: volume * (carrierData?.costVolume?.EU ?? 0),
    }
    const materialWeight = volume * carrierData?.density
    childData.weight = childData.weight + materialWeight
    childData.co2 = carrierData?.carbonFootprint * materialWeight
  }

  return childData
}

export default async function getAnalyseData (materialData: MaterialData[], nodesWithChildren: NodesWithChildren[], allAppearancesInScene: any) {
  let currencyRate : number
  try {
    const currencyData = await getCurrencyRate()
    if (currencyData.currency === undefined) return []
    else {
      currencyRate = currencyData.currency
    }
  } catch {
    return []
  }

  const topNodePromises = nodesWithChildren.map(async ({ nodeKey, children, name }: NodesWithChildren) => {
    // values per product/model
    let materialCost = {
      AP: 0,
      EU: 0
    }
    let appearanceCost = {
      AP: 0,
      EU: 0
    }
    const missingData: MissingData[] = []
    let isMissingData = false
    let weight = 0
    let co2 = 0
    const partsThatHaveApperanceOrMaterialSet = {
      partsWithApperanceOrMaterial: 0,
      onePartWithMaterial: false,
      onePartWithAppearane: false
    }
    let childrenWithAreaOrVolume = 0
    const topNodePartsWithCost : PartCostNode[] = []

    const getAnalysationForNodeCurr = getAnalysationForNode.bind(null, currencyRate, materialData)

    const childAnalysationsPromises = children.map((childNode: ISceneGraphNode3d) => getAnalysationForNodeCurr(childNode))
    const calculationList: any = []
    const childAnalysations = await Promise.all(childAnalysationsPromises)

    // sum up all child analysations
    childAnalysations.forEach((childNode:ChildDataAnalysis) => {
      // Calculates and summarizes the value of X/Y/Z min and max values.
      const xValues = [childNode.boundingBox.min.x * 1000, childNode.boundingBox.max.x * 1000]
      const yValues = [childNode.boundingBox.min.y * 1000, childNode.boundingBox.max.y * 1000]
      const zValues = [childNode.boundingBox.min.z * 1000, childNode.boundingBox.max.z * 1000]

      const bbXdim = Math.max(...xValues) - Math.min(...xValues)
      const bbYdim = Math.max(...yValues) - Math.min(...yValues)
      const bbZdim = Math.max(...zValues) - Math.min(...zValues)

      // Calculates the area by picking the two biggest bounding box values and then mulityply them.
      const allBoundingBoxesDim = [bbXdim, bbYdim, bbZdim]
      allBoundingBoxesDim.sort((a: number, b: number) => {
        return b - a
      })
      const boundingBoxCalcArea = allBoundingBoxesDim[0] * allBoundingBoxesDim[1] / 10000

      // Checks for the most accurate appearance name that will be exported to the xls.
      const metadata = allAppearancesInScene[childNode.appearanceName] || allAppearancesInScene[childNode.appearanceId]
      let appearanceName =
      _get(metadata, 'metadata.tag_items.Designation') ||
      _get(metadata, 'metadata.displayName') ||
      _get(metadata, 'metadata.description') ||
      _get(metadata, 'metadata.name') ||
      _get(metadata, 'name')
      // Get's biggest bounding box length
      const boundingBoxCalcLength = allBoundingBoxesDim[0]

      if (Array.isArray(appearanceName)) {
        appearanceName = appearanceName[0]
      }

      weight = weight + childNode.weight

      const boundingBox = {
        min: {
          x: childNode.boundingBox.min.x * 1000,
          y: childNode.boundingBox.min.y * 1000,
          z: childNode.boundingBox.min.z * 1000
        },
        max: {
          x: childNode.boundingBox.max.x * 1000,
          y: childNode.boundingBox.max.y * 1000,
          z: childNode.boundingBox.max.z * 1000
        }
      }

      const calObj = {
        area: childNode.area,
        volume: childNode.volume,
        name: childNode.name,
        parent: childNode.parentNamePtc ? childNode.parentNamePtc : childNode.parentName,
        boundingBox,
        bbXdim,
        bbYdim,
        bbZdim,
        boundingBoxCalcArea,
        boundingBoxCalcLength,
        appearanceName: appearanceName,
        carrierName: childNode.carrierName
      }
      calculationList.push(calObj)
      // if node is a sub-part of Part then it should not be included with the calc
      if (childNode.topNodeThatIsPart) {
        // topNodePartsWithCost should only include one of each top node that is a Part
        if (!topNodePartsWithCost.some(topNodeWithPart => topNodeWithPart.node === childNode.topNodeThatIsPart?.node)) {
          topNodePartsWithCost.push(childNode.topNodeThatIsPart)
        }
      } else {
        appearanceCost = {
          AP: appearanceCost.AP + (childNode.appearanceCost?.AP ?? 0),
          EU: appearanceCost.EU + (childNode.appearanceCost?.EU ?? 0)
        }
        materialCost = {
          AP: materialCost.AP + (childNode.materialCost?.AP ?? 0),
          EU: materialCost.EU + (childNode.materialCost?.EU ?? 0)
        }
      }

      co2 = co2 + childNode.co2
      const modalName = childNode.parentName ? childNode.parentName + ' ' + childNode.name : childNode.name
      const isPart = !!childNode.topNodeThatIsPart

      // building up missing information list for missing info modal
      missingData.push({
        name: childNode.name,
        missingAppearanceCost: {
          AP: !!childNode.appearanceCost?.AP || isPart,
          EU: !!childNode.appearanceCost?.EU || isPart
        },
        missingCarrierCost: {
          AP: !!childNode.materialCost?.AP || isPart,
          EU: !!childNode.materialCost?.EU || isPart
        },
        carrierName: childNode.carrierName,
        appearanceName: childNode.appearanceName,
        appearanceId: childNode.appearanceId,
        modalName,
        partNumber: childNode.topNodeThatIsPart?.partNumber || null
      })
      if (childNode.area || childNode.volume) {
        childrenWithAreaOrVolume = childrenWithAreaOrVolume + 1
      }
      isMissingData = isMissingData || (isPart ? false : (!childNode.appearanceCost?.AP || !childNode.appearanceCost?.EU || !childNode.materialCost?.AP || !childNode.materialCost?.EU))
      // used for knowing when to show icons
      if (!!childNode.appearanceId || !!childNode.carrierName) {
        partsThatHaveApperanceOrMaterialSet.partsWithApperanceOrMaterial = partsThatHaveApperanceOrMaterialSet.partsWithApperanceOrMaterial + 1
        partsThatHaveApperanceOrMaterialSet.onePartWithAppearane = partsThatHaveApperanceOrMaterialSet.onePartWithAppearane || !!childNode.appearanceId
        partsThatHaveApperanceOrMaterialSet.onePartWithMaterial = partsThatHaveApperanceOrMaterialSet.onePartWithMaterial || !!childNode.carrierName
      }
    })

    const allPartsHaveMaterialOrApperanceSet = partsThatHaveApperanceOrMaterialSet.partsWithApperanceOrMaterial >= childrenWithAreaOrVolume
    const showMaterialIcon = allPartsHaveMaterialOrApperanceSet && partsThatHaveApperanceOrMaterialSet.onePartWithMaterial
    const showAppearnaceIcon = allPartsHaveMaterialOrApperanceSet && partsThatHaveApperanceOrMaterialSet.onePartWithAppearane

    const reducedTopNodePartsWithCost : PartCostIdCount[] = topNodePartsWithCost.reduce((acc : PartCostIdCount[], item : PartCostNode) => {
      const partNodeToUpdate = acc.find(partNode => partNode.partNumber === item.partNumber)
      if (partNodeToUpdate) {
        const newArray = acc
        const index = newArray.indexOf(partNodeToUpdate)
        newArray[index] = { partNumber: partNodeToUpdate.partNumber, count: partNodeToUpdate.count + 1 }
        return newArray
      }
      return acc.concat([{ partNumber: item.partNumber, count: 1 }])
    }, [])

    const allPartsCostInfo : PartIdWithCost[] = await Promise.all(reducedTopNodePartsWithCost.map(async ({ partNumber, count }: PartCostIdCount) => {
      const partCostForNode = await getPartCost(partNumber, currencyRate)
      return {
        partNumber,
        cost: partCostForNode !== null ? parseFloat((partCostForNode * count).toFixed(2)) : partCostForNode
      }
    }))
    const partsCost = allPartsCostInfo.reduce((sum : number, itemCost : PartIdWithCost) => sum + (itemCost.cost === null ? 0 : itemCost.cost), 0)

    return {
      name,
      nodeKey,
      children,
      materialCost,
      appearanceCost,
      partsCost,
      weight,
      co2,
      missingData,
      isMissingData,
      showMaterialIcon,
      showAppearnaceIcon,
      allPartsCostInfo,
      calculationList
    }
  })

  const productAnalysations : ProductAnalyze[] = await Promise.all(topNodePromises)
  return productAnalysations
}
