import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import _startCase from 'lodash/startCase'

import LayoutWithSideNav from '../layout/layout-with-sidenav'

import SideNavLeft from './sidenav'

import AdminSurfaces from './admin-appearances/index'
import AdminRoomsets from './admin-roomsets/index'
import AdminReports from './admin-reports/index'
import AdminColors from './admin-colors/index'
import AdminCommunicate from './admin-communicate'
import AdminImageTemplates from './admin-image-templates'
import AdminPlmUpload from './admin-plm-upload'
import AdminMaterials from './admin-materials'
import AdminPartCost from './admin-part-cost'
import AdminSupport from './admin-support'
import * as fromUsersSelectors from '../../stores/ducks/users/selectors'
import store from '../../stores'

import {
  MdPalette as IconSurfaces,
  MdAssessment as IconReports,
  MdNotifications as IconCommunicate,
  MdCenterFocusStrong as IconImageTemplate,
  MdFileUpload as IconPlmUpload,
  MdTexture as IconMaterial,
  MdComputer as IconSupport
} from 'react-icons/md'

import IconUsers from '../common/icons/icon-user'
import IconRoomsets from '../common/icons/icon-roomsets'
import IconAppearances from '../common/icons/icon-material'

import { FEATURES } from '../../constants'
import { getSettings } from '../../stores/ducks/threeviewer/settings'
import { selectIsFeatureActive } from '../../stores/ducks/users/selectors'
import AdminUsers from './admin-users'

const MATERIALS = 'materials'
const PART_COST = 'partCost'

const navItems = [
  {
    id: 'users',
    icon: <div style={{ marginLeft: 4 }}><IconUsers size={20} /></div>,
    text: 'Users'
  },
  {
    id: 'surfaces',
    icon: <div style={{ marginLeft: 4 }}><IconAppearances size={20} /></div>,
    text: 'Appearances'
  },
  {
    id: 'roomsets',
    icon: <div style={{ marginLeft: -4, height: 24 }}><div style={{ marginTop: -6 }}><IconRoomsets size={36} /></div></div>,
    text: 'Roomsets'
  },
  {
    id: 'reports',
    icon: <IconReports size={24} />,
    text: 'Reports'
  },
  {
    id: 'colors',
    icon: <IconSurfaces size={24} />,
    text: 'Colors'
  },
  {
    id: 'communicate',
    icon: <IconCommunicate size={24} />,
    text: 'Communicate'
  },
  {
    id: 'image-templates',
    feature: FEATURES.IMAGE_TEMPLATES,
    icon: <IconImageTemplate size={24} />,
    text: 'Image templates'
  },
  {
    id: 'plm-upload',
    icon: <IconPlmUpload size={24} />,
    text: 'PLM upload'
  },
  {
    id: MATERIALS,
    icon: <IconMaterial size={24} />,
    text: 'Materials'
  },
  {
    id: PART_COST,
    icon: <IconPlmUpload size={24} />,
    text: 'Part cost upload'
  },
  {
    id: 'support-tools',
    icon: <IconSupport size={24} />,
    text: 'Support tools',
    developerOnly: true
  }
]

const AdminDashboard = () => {
  const dispatch = useDispatch()
  const { isFeatureActive } = useSelector(selectIsFeatureActive)
  const state = store.getState()
  const isDeveloper = fromUsersSelectors.getHasCurrentScopes('developer')(state)

  const [currentTab, setCurrentTab] = useState('users')

  useEffect(() => {
    dispatch(getSettings())
  }, [])

  const getCurrentTabIcon = () => {
    const size = 24
    switch (currentTab) {
      case 'users':
        return <IconUsers size={size} />

      case 'surfaces':
        return <IconAppearances size={size} />

      case 'roomsets':
        return <IconRoomsets size={40} />

      case 'reports':
        return <IconReports size={size} />

      case 'colors':
        return <IconSurfaces size={size} />

      case 'communicate':
        return <IconCommunicate size={size} />

      case 'image-templates':
        return <IconImageTemplate size={24} />

      case 'plm-upload':
        return <IconPlmUpload size={24} />

      case MATERIALS:
        return <IconMaterial size={24} />

      case PART_COST:
        return <IconPlmUpload size={24} />

      case 'support-tools':
        return <IconSupport size={24} />

      default:
        return null
    }
  }

  const filterNavigation = () => {
    return (
      navItems.filter(i => {
        if (i.developerOnly) return isDeveloper
        return i.feature ? isFeatureActive(i.feature) : true
      })
    )
  }

  const getCurrentTabName = () => {
    if (currentTab === 'surfaces') {
      return 'Appearances'
    }
    if (currentTab === 'plm-upload') {
      return 'PLM upload'
    }
    if (currentTab === PART_COST) {
      return 'Part cost upload'
    }
    return _startCase(currentTab)
  }

  const renderCurrentContent = () => {
    switch (currentTab) {
      case 'users':
        return <AdminUsers />

      case 'surfaces':
        return <AdminSurfaces />

      case 'roomsets':
        return <AdminRoomsets />

      case 'reports':
        return <AdminReports />

      case 'colors':
        return <AdminColors />

      case 'communicate':
        return <AdminCommunicate />

      case 'image-templates':
        return <AdminImageTemplates />

      case 'plm-upload':
        return <AdminPlmUpload/>

      case MATERIALS:
        return <AdminMaterials/>

      case PART_COST:
        return <AdminPartCost/>

      case 'support-tools':
        return <AdminSupport/>

      default:
        return null
    }
  }

  return (
    <LayoutWithSideNav
      breadcrumbs={[
        { id: 0, title: 'Dashboard', route: '/' },
        { id: 1, title: 'Admin' }
      ]}
      currentTabName={getCurrentTabName()}
      currentTabIcon={getCurrentTabIcon()}
      sideNavComponent={
        <SideNavLeft
          navItems={filterNavigation()}
          onSelection={(path) => setCurrentTab(path)}
          defaultSelectedPath='users'
        />
      }
      toolsComponent={null}
    >
      {renderCurrentContent()}
    </LayoutWithSideNav>
  )
}

export default AdminDashboard
