import fetch from '../../../utils/fetch'

const assertError = (err: string, msg: {msg: string}) => {
  console.error(msg)
  console.error(err)
  throw err
}

export const getAllExportsInProject = (projectId: string) => {
  return fetch(`/api/export/view/byProject/${projectId}`)
    .then((res) => res.json())
    .catch((err) => assertError(err, { msg: 'Not able to get all external views by projectId' }))
}

export const startExportOfVariant = (combinationId: string, title: string) => {
  return fetch('/api/export/view', {
    method: 'PUT',
    body: JSON.stringify(Object.assign({}, { combinationId, title }))
  })
    .then((res) => res.json())
    .catch((err) => assertError(err, { msg: 'Not able to create external view' }))
}

export const getExportById = (id: string) => {
  return fetch(`/api/export/view/${id}`)
    .then((res) => res.json())
    .catch((err) => assertError(err, { msg: 'Not able to get external view by id' }))
}

export const getExportByCombinationId = (combinationId: string) => {
  return fetch(`/api/export/view/byCombination/${combinationId}`)
    .then((res) => res.json())
    .catch((err) => assertError(err, { msg: 'Not able to get external view by combinationId' }))
}
