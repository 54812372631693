import _get from 'lodash/get'

import store from '../../stores'

import * as fromUsersSelectors from '../../stores/ducks/users/selectors'
import * as fromLikes from '../../stores/ducks/likes'

export default function handleLikes (msg) {
  if (_get(msg, 'doc.userId') === fromUsersSelectors.getCurrentId(store.getState())) return
  store.dispatch(fromLikes.receive([msg.doc]))
}
