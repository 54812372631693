import React, { useState } from 'react'
import styled from 'styled-components'

import { MdReport as ReportIcon } from 'react-icons/md'

import Button from '../../../common/button'
import KeyboardListener, { KeyCode, KeyBinding } from '../../../common/keyboard-listener'
import Grid from '../../../common/grid/grid'

type Props = {
  title: string
  onSubmit?: () => void
  onUpdate?: () => void
  onCancel: () => void
  onOpenVisualizer?: () => void
  onEditClick?: () => void
  componentGeneral: React.ReactNode
  componentAnnotation: React.ReactNode
  errorMsg?: string
  readOnly?: boolean
  hasEditAccess?: boolean
  disabledSave?: boolean
  email?: string
}

enum TABS {
  GENERAL,
  ANNOTATION
}

type TabProps = {
  tab: TABS.GENERAL | TABS.ANNOTATION,
  selectedTab: TABS.GENERAL | TABS.ANNOTATION
}

const StyledTab = styled.div<TabProps>`
  width: 50%;
  text-align: center;
  background-color: ${(props) =>
    props.selectedTab === props.tab ? 'var(--secondary)' : 'white'};
  display: flex;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
`

const Tabs = ({
  title,
  onSubmit,
  onUpdate,
  onCancel,
  onOpenVisualizer,
  onEditClick,
  componentGeneral,
  componentAnnotation,
  errorMsg,
  readOnly,
  hasEditAccess,
  disabledSave,
  email
}: Props) => {
  const [selectedTab, setSelectedTab] = useState<
    TABS.GENERAL | TABS.ANNOTATION
  >(TABS.GENERAL)

  const handleEnter = () => {
    if (selectedTab === TABS.ANNOTATION) return
    if (readOnly && onOpenVisualizer && !onSubmit) return onOpenVisualizer()
    if (!readOnly && onUpdate) return disabledSave ? onCancel() : onUpdate()
    if (onSubmit) return onSubmit()
  }

  return (
    <>
      <Grid rows={['56px', '1fr', '56px']} gridGap={0} className='width-100 height-100'>
        <div className="flex justify-between items-baseline">
          <h2 className='mt0'>{title}</h2>
          {email && (
            <p className="f6 bold m0 mr3">
              Shared by <span className="regular inline">{email}</span>
            </p>
          )}
        </div>
        <div
          className='bg-gray-light overflow-hidden'
        >
          <div
            className='bg-white rounded flex width-100'
            style={{
              height: '8%',
              border: '2px solid',
              borderColor: 'var(--secondary)'
            }}
          >
            <StyledTab
              selectedTab={selectedTab}
              tab={TABS.GENERAL}
              onClick={() => setSelectedTab(TABS.GENERAL)}
            >
              <span>
                GENERAL{' '}
                {errorMsg && errorMsg.length > 0 && (
                  <span className='c-error'>{<ReportIcon size={24} />}</span>
                )}
              </span>
            </StyledTab>
            <StyledTab
              selectedTab={selectedTab}
              tab={TABS.ANNOTATION}
              onClick={() => setSelectedTab(TABS.ANNOTATION)}
            >
              <span>ANNOTATED IMAGE</span>
            </StyledTab>
          </div>
          <div style={{ height: '90%' }}>
            {selectedTab === TABS.GENERAL && componentGeneral}
            {selectedTab === TABS.ANNOTATION && componentAnnotation}
          </div>
        </div>

        <div className={`flex items-end ${readOnly ? 'justify-between' : 'justify-end'}`}>
          {onSubmit && (
            <>
              <Button onClick={onCancel} style={{ marginTop: 'auto' }}>
                Cancel
              </Button>
              <Button
                btnType='primary'
                className='ml2'
                style={{ marginTop: 'auto' }}
                onClick={onSubmit}
                disabled={disabledSave}
              >
                Save as global
              </Button>
            </>
          )}
          {!onSubmit &&
          readOnly &&
            onOpenVisualizer && (
            <Button
              btnType='primary'
              style={{ marginTop: 'auto' }}
              onClick={onOpenVisualizer}
              className='self-start'
            >
                Open scene in Visualizer
            </Button>
          )}
          {onEditClick &&
          hasEditAccess &&
            readOnly && (
            <Button btnType='primary'
              style={{ marginTop: 'auto' }}
              onClick={onEditClick}
              className='self-start'>
                Edit
            </Button>
          )}
          {onEditClick &&
            !readOnly && (
            <div className='flex'>
              <Button onClick={onCancel} style={{ marginTop: 'auto' }}>
                Cancel
              </Button>
              <Button
                btnType='primary'
                className='ml2'
                style={{ marginTop: 'auto' }}
                onClick={onUpdate}
                disabled={disabledSave}
              >
                Save changes
              </Button>
            </div>
          )}
        </div>
      </Grid>
      <KeyboardListener bindings={[KeyBinding(KeyCode.enter, handleEnter)]} />
    </>
  )
}

export default Tabs
