
function findGroup (groupId, annotations) {
  for (let i = 0; i < annotations.length; i++) {
    const annotation = annotations[i]
    if (annotation.groupId === groupId) return annotation
  }
  return undefined
}

function findMarker (mesh, markers) {
  let marker
  if (mesh._instancedMeshId !== undefined) {
    const id = mesh._instancedMeshId
    marker = markers[id]
    return marker
  }
  return undefined
}

module.exports = {
  findGroup,
  findMarker
}
