const THREE = require('three')

const markerPosition = new THREE.Vector3()
const centerPosition = new THREE.Vector3()
const vertPosition = new THREE.Vector3()

function render (camera, canvas, manager) {
  if (!manager.annotations || !manager.markers || !manager.lineDrawer) {
    return
  }

  let numAutoLayoutAnnotations = 0

  camera.updateMatrixWorld()
  // First update marker 2d positions so they are updated
  for (let i = 0; i < manager.markers.length; i++) {
    const marker = manager.markers[i]
    if (!marker) continue
    if (marker.locked) {
      markerPosition.set(marker.position3d.x, marker.position3d.y, marker.position3d.z)
    } else {
      // Avoid deleted meshes
      if (!('_instancedMeshId' in marker.mesh)) continue
      marker.mesh.geometry.boundingBox.getCenter(centerPosition)
      const renderMesh = manager.renderScene.meshes[marker.mesh._instancedMeshId]
      const positionBuffer = renderMesh.geometry.attributes.position.array
      let closestX, closestY, closestZ
      const closestSquaredDistance = Number.MAX_VALUE

      // check for closest vertex position
      for (let i = 0; i < positionBuffer.length; i += 3) {
        vertPosition.set(positionBuffer[i], positionBuffer[i + 1], positionBuffer[i + 2])

        // cheaper check than square root
        const dist = vertPosition.distanceToSquared(centerPosition)
        if (dist < closestSquaredDistance) {
          closestX = positionBuffer[i]
          closestY = positionBuffer[i + 1]
          closestZ = positionBuffer[i + 2]
        }
      }
      markerPosition.set(closestX, closestY, closestZ)
      let instMatrix = new THREE.Matrix4()
      renderMesh.getMatrixAt(marker.mesh._instancedIndex, instMatrix)
      
      markerPosition.applyMatrix4(instMatrix)
    }

    markerPosition.project(camera)
    marker.positionX = markerPosition.x
    marker.positionY = markerPosition.y
  }
  manager.lineDrawer.beginFrame(camera)

  // Find midpoint of all visible markers for every annotation (in 2D)
  // Note that also inactive markers are considered in this phase
  // Find total number of annotations and set visible on the domElement to hide inactive annotation boxes
  for (let i = 0; i < manager.annotations.length; i++) {
    const annotation = manager.annotations[i]

    let meanX = 0
    let meanY = 0
    let numVisible = 0
    let numActiveAndVisible = 0

    for (let j = 0; j < annotation.markers.length; j++) {
      const marker = annotation.markers[j]
      if (!marker.visible) continue

      if (marker.active) {
        meanX += marker.positionX
        meanY += marker.positionY
        numActiveAndVisible++
      }

      numVisible++
    }
    if (numVisible !== 0) {
      if (annotation.element.active) {
        annotation.meanX = meanX / numActiveAndVisible
        annotation.meanY = meanY / numActiveAndVisible
        if (annotation.locked) {
          annotation.assigned = true
          drawAnnotation(annotation, annotation.positionX, annotation.positionY)
        } else {
          annotation.assigned = false
          numAutoLayoutAnnotations++
        }
      }
      annotation.active = true
      annotation.element.setVisible(true)
    } else {
      annotation.active = false
      annotation.element.setVisible(false)
    }
  }

  for (let i = 0; i < numAutoLayoutAnnotations; i++) {
    const annotationBoxX = (i & 1) ? -0.5 : 0.5
    let annotationBoxY = 0

    if (numAutoLayoutAnnotations > 2) {
      annotationBoxY = -0.5 + Math.floor(i / 2) / (Math.floor((numAutoLayoutAnnotations + 1) / 2) - 1)
    }

    // Find an unassigned annotation that is closest to the annotation box (at x,y)
    let smallestDist2, smallestIndex
    for (let j = 0; j < manager.annotations.length; j++) {
      const annotation = manager.annotations[j]
      if (annotation.active && !annotation.assigned) {
        const dx = annotation.meanX - annotationBoxX
        const dy = annotation.meanY - annotationBoxY
        const dist2 = dx * dx + dy * dy
        if (smallestDist2 === undefined || dist2 < smallestDist2) {
          smallestIndex = j
          smallestDist2 = dist2
        }
      }
    }

    const annotation = manager.annotations[smallestIndex]

    // Remember the layouted position so we can use it for picking
    annotation.positionX = annotationBoxX
    annotation.positionY = annotationBoxY

    // Lock annotation after first placement.
    annotation.locked = true

    drawAnnotation(annotation, annotationBoxX, annotationBoxY)
  }

  manager.lineDrawer.finishFrame()

  function drawAnnotation (annotation, annotationBoxX, annotationBoxY) {
    annotation.assigned = true
    annotation.closestMarkerPosition = undefined

    const element = annotation.element.annotationDiv
    const width = parseInt(canvas.style.width, 10)
    const height = parseInt(canvas.style.height, 10)
    manager.canvasWidth = width
    manager.canvasHeight = height
    const aspect = height / width

    const ax = (0.5 + annotationBoxX * 0.5) * width
    const ay = (0.5 - annotationBoxY * 0.5) * height
    annotation.element.setPosition(ax, ay)

    const radiusInnerCricle = 0.01
    const radiusOuterCricle = 0.02
    const lineThickness = 0.003

    if (annotation.showSingleMarker) {
      // Find the visible/active marker in the chosen annotation that is closest to the annotation box
      let smallestDist2
      let smallestX
      let smallestY
      let closestMarker

      for (let j = 0; j < annotation.markers.length; j++) {
        annotation.markers[j].clickable = false
      }

      if (annotation.lockedMarker && annotation.lockedMarker.active) {
        smallestX = annotation.lockedMarker.positionX
        smallestY = annotation.lockedMarker.positionY
        closestMarker = annotation.lockedMarker
      } else {
        for (let j = 0; j < annotation.markers.length; j++) {
          const marker = annotation.markers[j]
          if (!marker.visible || !marker.active) continue

          const dx = marker.positionX - annotationBoxX
          const dy = marker.positionY - annotationBoxY
          const dist2 = dx * dx + dy * dy

          if (smallestDist2 === undefined || dist2 < smallestDist2) {
            smallestX = marker.positionX
            smallestY = marker.positionY
            smallestDist2 = dist2
            closestMarker = marker
          }
        }
      }
      // no render if no active marker is visible. this will cause the annotation to still be visbile even without lines.
      // another solution would be to turn visiblility off if annotation does not have any visable active markers,
      // but then we have to handle disabled annotations diffrently, since otherwise it would not be possible to enable an annotation
      // with all markers disabled
      if (!closestMarker) {
        return
      }

      const lineDx = smallestX - annotationBoxX
      const lineDy = smallestY - annotationBoxY

      let offsetX = 0
      let offsetY = 0

      if (Math.abs(lineDx) > Math.abs(lineDy)) {
        offsetX = lineDx > 0 ? -element.clientWidth / width : element.clientWidth / width
      } else {
        offsetY = lineDy > 0 ? -element.clientHeight / height : element.clientHeight / height
      }

      const annotationAttachX = annotationBoxX - offsetX
      const annotationAttachY = annotationBoxY - offsetY

      annotation.annotationAttachX = annotationAttachX
      annotation.annotationAttachY = annotationAttachY

      annotation.anchorX = smallestX
      annotation.anchorY = smallestY

      if (annotation.element.isActive()) {
        closestMarker.clickable = true
      }

      let singleMarkerColor
      if (annotation.selected) {
        if (closestMarker.locked) {
          singleMarkerColor = manager.lockedColor
        } else {
          singleMarkerColor = annotation.selectedColor
        }
      } else {
        singleMarkerColor = annotation.color
      }

      annotation.closestMarkerPosition = { x: smallestX, y: smallestY }

      const r1 = closestMarker.hover ? radiusInnerCricle * 1.3 : radiusInnerCricle
      const r2 = closestMarker.hover ? radiusOuterCricle * 1.3 : radiusOuterCricle

      manager.lineDrawer.drawLineWithCircle(smallestX, smallestY, annotationAttachX, annotationAttachY, aspect, singleMarkerColor, manager.whiteColor, r2, r1, lineThickness)
    } else {
      for (let j = 0; j < annotation.markers.length; j++) {
        annotation.markers[j].clickable = true
      }

      let anchorX = (annotation.meanX + annotationBoxX) * 0.5
      let anchorY = (annotation.meanY + annotationBoxY) * 0.5
      const lineDx = anchorX - annotationBoxX
      const lineDy = anchorY - annotationBoxY

      let offsetX = 0
      let offsetY = 0

      if (Math.abs(lineDx) > Math.abs(lineDy)) {
        offsetX = lineDx > 0 ? -element.clientWidth / width : element.clientWidth / width
      } else {
        offsetY = lineDy > 0 ? -element.clientHeight / height : element.clientHeight / height
      }

      const annotationAttachX = annotationBoxX - offsetX
      const annotationAttachY = annotationBoxY - offsetY

      annotation.annotationAttachX = annotationAttachX
      annotation.annotationAttachY = annotationAttachY

      anchorX = (annotation.meanX + annotationAttachX) * 0.5
      anchorY = (annotation.meanY + annotationAttachY) * 0.5

      annotation.anchorX = anchorX
      annotation.anchorY = anchorY

      const lineColor = annotation.selected ? annotation.selectedColor : annotation.color
      manager.lineDrawer.drawLine(anchorX, anchorY, annotationAttachX, annotationAttachY, aspect, lineColor, lineThickness)

      for (let k = 0; k < annotation.markers.length; k++) {
        const marker = annotation.markers[k]
        if (!marker.visible) continue

        let markerColor
        if (annotation.selected) {
          if (marker.locked) {
            markerColor = manager.lockedColor
          } else {
            markerColor = annotation.selectedColor
          }
        } else {
          markerColor = annotation.color
        }

        const r1 = marker.hover ? radiusInnerCricle * 1.3 : radiusInnerCricle
        const r2 = marker.hover ? radiusOuterCricle * 1.3 : radiusOuterCricle

        if (marker.active) {
          manager.lineDrawer.drawLineWithCircle(marker.positionX, marker.positionY, anchorX, anchorY, aspect, markerColor, manager.whiteColor, r2, r1, lineThickness)
        } else if (annotation.selected) {
          manager.lineDrawer.drawCircle(marker.positionX, marker.positionY, aspect, r2, r1, manager.disabledColor, manager.whiteColor)
        }
      }
    }
  }
}

module.exports = render
