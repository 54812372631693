import React from 'react'

const IconMove = props => (
  <svg width={props.width || props.size} height={props.height || props.size} viewBox="2 2 20 20" {...props}>
    <path
      d="M16,18 L12,22 L8,18 L16,18 Z M6.06950502,8.03551403 L5.92988576,16.0342956 L2.00030461,11.9650952 L6.06950502,8.03551403 Z M18,8 L22,12 L18,16 L18,8 Z M12,9 C13.6568542,9 15,10.3431458 15,12 C15,13.6568542 13.6568542,15 12,15 C10.3431458,15 9,13.6568542 9,12 C9,10.3431458 10.3431458,9 12,9 Z M12,2 L16,6 L8,6 L12,2 Z"
      fill={props.color}
    />
  </svg>
)

IconMove.defaultProps = {
  size: '1em',
  color: 'currentcolor'
}

export default IconMove
