import React from 'react'
import styled from 'styled-components'

import Spinner from '../../../../common/spinner'
import Button from '../../../../common/button'

export const CenteredDiv = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`
type PropTypes = {
  abort: () => void
}

const LargeCenteredLoader = ({ abort } : PropTypes) => <CenteredDiv><Spinner spinnerstyle={{ fontSize: '7rem' }}/>
  <div className='pt1'>Fetching from PLM,</div>
  <div>please be patient</div>
  <div
    className='flex pt2'
    style={{
      justifyContent: 'center'
    }}>
    <Button
      onClick={abort}
    >Cancel</Button>
  </div>
</CenteredDiv>

export default LargeCenteredLoader
