import Immutable from 'seamless-immutable'
import { createAction, handleActions } from 'redux-actions'

export const toggleIsAppearanceGroupOpen = createAction<string>('appearance-groups/TOGGLE_IS_APPEARANCE_GROUP_OPEN')
export const toggleIsNoAppearanceSetOpen = createAction('appearance-groups/TOGGLE_IS_NO_APPEARANCE_SET_OPEN')
export const toggleIsPartsGroupOpen = createAction<string>('appearance-groups/TOGGLE_IS_PARTS_GROUP_OPEN')
export const toggleIsCostAndAnnotationsOpen = createAction<string>('appearance-groups/TOGGLE_IS_COST_AND_ANNOTATIONS_OPEN')
export const clearAppearanceGroupsState = createAction('appearance-groups/CLEAR_APPEARANCE_GROUPS_STATE')

const initialState = Immutable<{
  isAppearanceGroupOpen: string[]
  isNoAppearanceSetOpen: boolean
  isPartsGroupOpen: string[]
  isCostAndAnnotationsOpen: string[]
}>({
  isAppearanceGroupOpen: [],
  isNoAppearanceSetOpen: false,
  isPartsGroupOpen: [],
  isCostAndAnnotationsOpen: []
})

type State = typeof initialState

export default handleActions<State, string>({
  [toggleIsAppearanceGroupOpen.toString()]: (state, action) => {
    if (state.isAppearanceGroupOpen.includes(action.payload)) {
      return state.merge({
        isAppearanceGroupOpen: state.isAppearanceGroupOpen.filter(appearanceGroup => appearanceGroup !== action.payload)
      })
    }
    return state.merge({
      isAppearanceGroupOpen: state.isAppearanceGroupOpen.concat(action.payload)
    })
  },
  [toggleIsNoAppearanceSetOpen.toString()]: (state) => state.merge({
    isNoAppearanceSetOpen: !state.isNoAppearanceSetOpen
  }),
  [toggleIsPartsGroupOpen.toString()]: (state, action) => {
    if (state.isPartsGroupOpen.includes(action.payload)) {
      return state.merge({
        isPartsGroupOpen: state.isPartsGroupOpen.filter(appearanceGroup => appearanceGroup !== action.payload)
      })
    }
    return state.merge({
      isPartsGroupOpen: state.isPartsGroupOpen.concat(action.payload)
    })
  },
  [toggleIsCostAndAnnotationsOpen.toString()]: (state, action) => {
    if (state.isCostAndAnnotationsOpen.includes(action.payload)) {
      return state.merge({
        isCostAndAnnotationsOpen: state.isCostAndAnnotationsOpen.filter(appearanceGroup => appearanceGroup !== action.payload)
      })
    }
    return state.merge({
      isCostAndAnnotationsOpen: state.isCostAndAnnotationsOpen.concat(action.payload)
    })
  },
  [clearAppearanceGroupsState.toString()]: (state) => state.merge({
    isAppearanceGroupOpen: [],
    isNoAppearanceSetOpen: false,
    isPartsGroupOpen: []
  })
}, initialState)
