import React from 'react'
import ReactDOM from 'react-dom'
import { IconContext } from 'react-icons'
import { Provider } from 'react-redux'
import paramify from 'paramify'

import store from '../stores'
import * as fromUserSelectors from '../stores/ducks/users/selectors'
import navigate from '../utils/navigate'
import { PROJECT_TYPES } from '../constants'

// JSX Components
import Confirm from './common/confirm'

// JSX Components : Pages
import Dashboard from './dashboard/index'
import Project from './project/index'
import ImagePackageProject from './image-package-project/index'
import Login from './login/index'
import GalleryContainer from './gallery/index'
import Visualizer from './visualizer/index'

import AdminDashboard from './admin/index'

import GDPR from './gdpr/index'

import Designs from './designs/index'
import MaterialSearch from './visualizer/panels/material-and-color/material-search/search'
import DesignLoader from './visualizer/design-loader'
import RedirectToNewUrl from './redirect/RedirectToNewUrl'

import * as fromProjects from '../stores/ducks/projects'

export default {
  fromRoute () {
    const match = paramify(window.location.pathname)
    const currentUser = fromUserSelectors.getCurrent(store.getState())

    const domain = window.location.hostname
    if (domain === 'nbi-dpd.com' || domain === 'www.nbi-dpd.com') {
      return (
        <RedirectToNewUrl isProduction/>
      )
    }
    if (domain === 'staging.nbi-dpd.com' || domain === 'www.staging.nbi-dpd.com') {
      return (
        <RedirectToNewUrl />
      )
    }

    const isAdmin = () => {
      return currentUser.scopes && (currentUser.scopes.includes('admin') || currentUser.scopes.includes('developer'))
    }

    if (currentUser && !currentUser.hasAcceptedGDPR && !match('/password/reset/')) {
      // Renamed GDPR to Privacy Statement on public places for user
      if (!match('/privacy-statement')) {
        navigate('/privacy-statement')
      }
      return <GDPR />
    }

    if (match('/review-privacy-statement')) {
      return <GDPR showConfirmButton={false} />
    }

    if (process.env.NODE_ENV === 'development' || !process.env.NODE_ENV) {
      if (match('/designs/:projectId') || match('/designs/*')) {
        store.dispatch(fromProjects.setCurrent(match.params.projectId))

        return (
          <div style={{ height: '100vh', width: '100%' }}>
            <Designs />
          </div>
        )
      }

      if (match('/material-search/*')) return (<MaterialSearch />)
      if (match('/model-search/*')) return (<DesignLoader />)
    }

    if (match('/gallery/:parentCombinationId')) {
      document.title = 'DPD | Gallery'

      const query = new window.URLSearchParams(window.location.search)
      const parentCombinationId = match.params.parentCombinationId
      const projectId = query.get('project')
      const renderId = query.get('render')

      return (
        <GalleryContainer
          projectId={projectId}
          parentCombinationId={parentCombinationId}
          renderId={renderId}
          onBack={() => navigate(projectId ? `/project/${projectId}/show` : '/')}
          backToText={projectId ? 'Go back to project' : 'Go to dashboard'}
        />
      )
    }

    if (match('/superuser')) {
      document.title = 'DPD | Admin'
      if (isAdmin()) {
        return (<AdminDashboard />)
      }

      navigate('/')
    }

    if (match('/project/:id/show')) {
      // document title is set in component based on current project title
      return (
        <Project
          projectId={match.params.id}
        />
      )
    }

    if (match('/image-package-project/:id/show')) {
      // document title is set in component based on current project title
      return (
        <ImagePackageProject
          className='project'
          projectId={match.params.id}
          title='Project'
        />
      )
    }

    if (match('/visualize/:id/*')) {
      document.title = 'DPD | Visualizer view'

      const searchParams = new URLSearchParams(window.location.search)

      const isImagePackage = match(`/visualize/:id/${PROJECT_TYPES.IMAGE_PACKAGE}/:combinationId`)
      const projectId = match.params.id

      let combinationId = null

      if (isImagePackage) {
        combinationId = match.params.combinationId
      } else {
        combinationId = match('/visualize/:id/combination/:combinationId') ? match.params.combinationId : null
      }

      const sceneId = searchParams.get('sceneId') || null

      const goBack = () => isImagePackage ? navigate(`/image-package-project/${projectId}/show`) : navigate(`/project/${projectId}/show`)

      return (
        <Confirm
          onPopstate={goBack}
          title='Leave'
          description='All changes since your last created image or saved design will be lost.'
          buttonText='Yes, I want to leave'
        >
          {({ onOpen }) => (
            <Visualizer
              key={combinationId}
              goBack={goBack}
              onBeforeNavigate={onOpen}
              sceneId={sceneId}
              combinationId={combinationId}
              projectId={projectId}
              isImagePackage={isImagePackage}
              projectType={isImagePackage ? PROJECT_TYPES.IMAGE_PACKAGE : PROJECT_TYPES.DEFAULT}
            />
          )}
        </Confirm>
      )
    }

    if (match('/login')) {
      document.title = 'DPD | Login'
      return <div className='wrapper wrapper-login'><Login/></div>
    }

    document.title = 'DPD | Dashboard'

    return (
      <Dashboard
        breadcrumbs={[{ id: 0, title: 'Dashboard' }]}
      />
    )
  },

  render (el, store) {
    ReactDOM.render((
      <IconContext.Provider value={{ style: { verticalAlign: 'middle' } }}>
        <Provider store={store}>{this.fromRoute()}</Provider>
      </IconContext.Provider>
    ), el)
  }
}
