import React from 'react'
import styled from 'styled-components'

interface Props {
  isProduction: boolean
}

export default function RedirectToNewUrl ({ isProduction }: Props) {
  return (
    <Background>
      <Container>
        <h1>DPD has moved! </h1>
        <p>Please use
          {isProduction ? <a href='https://dpd.inter.ikea.net'> https://dpd.inter.ikea.net </a>
            : <a href='https://ppe-dpd.inter.ikeadt.net'> https://ppe-dpd.inter.ikeadt.net </a>}
           from now on. </p>
        <p className='mb1'>Don't forget to update your DPD browser bookmarks to </p>
        <p className='mt0'> {isProduction ? 'https://dpd.inter.ikea.net' : 'https://ppe-dpd.inter.ikeadt.net'}</p>
        <p>Thanks!</p>
      </Container>
    </Background>
  )
}

const Background = styled.div`
    background-image: url('/img/background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    `

const Container = styled.div`
    transform: translateY(-10%);
    max-width: 620px;
    width: 90%;
    background-color: var(--todo_f5f5f5);
    box-shadow: 0 0 40px 0 rgba(0,0,0,0.18);
    border-radius: 5px;
    padding: 1rem;
    margin: 0 auto;
    text-align: center;
`
