import React from 'react'
import { FaPencilAlt as IconPencil, FaLock as IconLock, FaEllipsisV } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { MdTexture as IconCarrier, MdLockOpen as IconUnLocked } from 'react-icons/md'
import styled from 'styled-components'
import cs from 'classnames'

import colors from '../../../../../../css/colors'

import * as fromThreeviewerUi from '../../../../../stores/ducks/threeviewer/ui'
import * as fromSelectionSelectors from '../../../../../stores/ducks/selection/selectors'
import * as fromTreeSelectors from '../../../../../stores/ducks/tree/selectors'
import * as fromTree from '../../../../../stores/ducks/tree'
import * as fromSelection from '../../../../../stores/ducks/selection'
import { RootState } from '../../../../../stores/ducks/'

import type { ITreeNode, ITreeNodeMetaData } from '../../../../../stores/ducks/tree/TreeNode'

import ButtonIcon from '../../../../common/button-icon'
import EditableText from '../../../../common/form/editable-text'
import IconArrowDown from '../../../../common/icons/icon-arrow-down'
import IconArrowRight from '../../../../common/icons/icon-arrow-right'
import IconFolder from '../../../../common/icons/icon-folder'
import IconFolderOpen from '../../../../common/icons/icon-folder-open'
import IconGroup from '../../../../common/icons/icon-group'
import IconMaterial from '../../../../common/icons/icon-material'
import IconModel from '../../../../common/icons/icon-model'
import IconSurface from '../../../../common/icons/icon-surface'

import { useMemoizedSelectorFactory } from '../../../../../hooks/useMemoizedSelectorFactory'
import { IconVirtualProduct } from '../virtual-products/IconVirtualProduct'
import { shouldSelectMany } from '../utils/helpers'

/**
 * Styled div with before element to add a border inside the droppable element
 * This prevents element to resize when adding a border during a drag event
 */
const DragDropWrapper = styled.div<{droppable: boolean, dragover: boolean, dragging: boolean}>`
  position: relative;
  cursor: ${({ dragging }) => dragging ? 'grabbing' : 'pointer'};
  &:before {
    display: ${({ droppable: dropable, dragover }) => dropable || dragover ? 'block' : 'none'};
    content: '';
    position: absolute;
    top: 2px;
    right: 2px;
    bottom: 2px;
    left: 2px;
    border: 2px dashed var(--black25);
    border-color: ${({ dragover }) => dragover && colors.black};
    background-color: ${({ droppable: dropable, dragover }) => dropable && dragover ? colors.white25 : 'transparent'};
  }
`

// Various styles
const styles: { [key: string]: React.CSSProperties } = {
  dataName: { marginTop: 3 },
  iconArrow: { marginBottom: -2 },
  iconModel: { marginTop: 3 },
  iconGroup: { marginTop: 3 },
  iconMesh: { marginLeft: 5, marginTop: 5, marginBottom: 5 },
  iconPencilEditableText: { display: 'none' },
  iconEye: { marginTop: 8, marginLeft: 2 },
  iconFolder: { marginTop: 10 },
  iconVirtualProduct: { marginLeft: 5, marginRight: 3 }
}

type NodeProps = {
  uuid: string,
  index: number, // The index of the current node in the flattened node list
  node: ITreeNode,
  metaData: ITreeNodeMetaData,
  filteredNodesUuids: string[] | null,
  toggleBocModal: (open: boolean) => void,
  toggleUnlockVirtualProductModalOpen: (open: boolean, uuid?: string) => void
  handleActionOverlayShouldOpen: (index: number, nodeRef: React.MutableRefObject<any>) => void,
  handleActionOverlayShouldClose: () => void
}

/**
 * Responsible for rendering a single node in the geometry tree.
 * This component is not recursive, i.e it will not render it's children.
 *
 * NOTE: This component is aggressively memoized to avoid re-doing expensive calculations and
 * rerendering unless absolutely necessary. Thanks to react-virtualized, only a handful of
 * nodes are mounted to the DOM at any given moment, which avoids problems with excessive memory usage.
 */
export const GeometryNode = React.memo((props: NodeProps) => {
  // Ref for the action overlay trigger button
  const actionOverlayTriggerRef = React.useRef<HTMLDivElement>(null)

  const [editing, setEditing] = React.useState<boolean>(false)
  const [isDraggingOver, setIsDraggingOver] = React.useState<boolean>(false)

  const flatNode = useSelector((state: RootState) => fromTreeSelectors.getFlatNode(state, props.uuid))
  const hasNodes =
    Object.values(props.node.nodes).length > 0 &&
    props.node.metaData?.type !== 'virtual-product' // Virtual products never show child nodes

  const isIsolationActive = useSelector(fromSelectionSelectors.getIsIsolationActive)
  const disabled = useSelector(fromTreeSelectors.getIsDisabled)
  const isDragging = useSelector(fromTreeSelectors.getIsDragging)

  const isDraggable =
    ['model', 'group', 'virtual-product'].includes(flatNode?.type || '')

  const actionsEnabled =
    ['model', 'variant', 'group', 'virtual-product'].includes(props.metaData.type) ||
    (['mesh', 'part', 'splitMesh'].includes(props.metaData.type) && props.metaData.canHide)

  // Memoized selector factories are used to create unique selectors for each node (based on uuid)
  // and memoize using React.memo, to ensure that the selector cache is preserved between renders
  const isHidden = useMemoizedSelectorFactory(fromTreeSelectors.createIsHiddenSelector, props.uuid)
  const childrenSelected = useMemoizedSelectorFactory(fromTreeSelectors.createIsChildrenSelectedSelector, props.uuid)
  const childrenHidden = useMemoizedSelectorFactory(fromTreeSelectors.createIsChildrenHiddenSelector, props.uuid)
  const ancestorSelected = useMemoizedSelectorFactory(fromTreeSelectors.createIsAncestorSelectedSelector, props.uuid)
  const ancestorHidden = useMemoizedSelectorFactory(fromTreeSelectors.createIsAncestorHiddenSelector, props.uuid)

  const isDroppable = useMemoizedSelectorFactory(fromTreeSelectors.createIsDroppableSelector, props.uuid)
  const opened = useMemoizedSelectorFactory(fromTreeSelectors.createIsOpenedSelector, props.uuid)
  const selected = useMemoizedSelectorFactory(fromTreeSelectors.createIsSelectedSelector, props.uuid)

  const showMaterialIcon = useMemoizedSelectorFactory(fromTreeSelectors.createShouldShowMaterialIconSelector, props.uuid)
  const showCarrierIcon = useMemoizedSelectorFactory(fromTreeSelectors.createShouldShowCarrierIconSelector, props.uuid)
  const showFolderIcon = !['model', 'mesh', 'variant', 'splitMesh', 'virtual-product'].includes(props.metaData.type)

  const dispatch = useDispatch()

  // The icon for this node type
  const nodeTypeIcon = React.useMemo(() => {
    switch (props.metaData.type) {
      case 'model': return <IconModel size={18} style={styles.iconModel} data-testid="icon-model" />
      case 'mesh': return <IconSurface size={18} style={styles.iconMesh} data-testid="icon-mesh" />
      case 'splitMesh': return <IconUnLocked size={18} style={styles.iconMesh} data-testid="icon-splitMesh" />
      case 'variant': return <IconGroup size={18} style={styles.iconGroup} data-testid="icon-variant" />
      case 'virtual-product': return <IconVirtualProduct style={styles.iconVirtualProduct} data-testid="icon-vp" />
      default: return null
    }
  }, [props.metaData.type])

  const handleSelect = React.useCallback((event: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
    dispatch(fromTree.select(props.uuid, shouldSelectMany(event)))
  }, [props.uuid])

  const handleEditing = (editing: boolean) => {
    setEditing(editing)
    dispatch(fromThreeviewerUi.toggleKeyBoardBindings(!editing))
  }

  const isHideDisabled = (): boolean => {
    return (
      (isHidden && ancestorHidden) ||
      (isIsolationActive && (isHidden || childrenHidden))
    )
  }

  const handleOverlayActionClick = (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
    if (!disabled && !selected) handleSelect(event)
    props.handleActionOverlayShouldOpen(props.index, actionOverlayTriggerRef)
  }

  return (
    <DragDropWrapper
      droppable={isDroppable}
      dragover={isDraggingOver}
      dragging={isDragging}
      draggable={isDraggable}
      onDragStart={event => {
        dispatch(fromTree.updateIsDragging(true))
        if (!selected) handleSelect(event) // Select node if not already selected
      }}
      onDrop={event => {
        event.preventDefault()
        setIsDraggingOver(false)
        dispatch(fromTree.updateIsDragging(false))
        if (isDroppable) {
          dispatch(fromTree.dropSelection(props.uuid, shouldSelectMany(event)))
        }
      }}
      onDragOver={event => {
        // onDragEnter doesnt seem to work with onDragLeave, both fire at the same time
        event.preventDefault()
        if (isDroppable) {
          setIsDraggingOver(true)
        }
      }}
      onDragEnd={() => dispatch(fromTree.updateIsDragging(false))}
      onDragLeave={() => setIsDraggingOver(false)}
    >
      <div
        data-testid='geometry-node'
        className={cs(
          `noselect flex items-center border-bottom bc-black-10 geometry-node-level-${flatNode?.ancestors.length ?? 0}`,
          {
            'pointer-disabled': disabled,
            pointer: !disabled,
          }
        )}
        style={{
          paddingLeft: flatNode?.ancestors.length * 20,
          background: selected
            ? colors.primary
            : (childrenSelected || ancestorSelected)
              ? colors.primaryLighter
              : `rgba(230, 230, 230, ${1 - (flatNode?.ancestors.length * 0.5)})`
        }}
      >
        {hasNodes && (
          <ButtonIcon
            disabled={false}
            noPointer={false}
            btnType='transparent'
            className='opacity-hover-lighter-performant-alt p1'
            padding={false}
            onClick={() => !disabled && dispatch(fromTree.toggleOpen(props.uuid))}
            icon={
              opened
                ? <IconArrowDown size={12} style={styles.iconArrow} data-testid="icon-arrow-down" />
                : <IconArrowRight size={12} style={styles.iconArrow} data-testid="icon-arrow-right" />
            }
          />
        )}
        <div
          className={cs('flex flex-auto items-center', {
            muted: disabled || (isHidden || ancestorHidden)
          })}
          onClick={(event) => !disabled && handleSelect(event)}
          onDoubleClick={(event) => {
            if (disabled) return
            event.preventDefault()
            dispatch(fromTree.selectFocus(props.uuid))
          }}
        >
          <div className='pr1'>
            {nodeTypeIcon}
            {showFolderIcon && (opened
              ? <IconFolderOpen size={20} style={styles.iconFolder} data-testid="icon-folder-open" />
              : <IconFolder size={20} style={styles.iconFolder} data-testid="icon-folder" />
            )}
          </div>
          {showMaterialIcon && <div className='pr1'>
            <IconMaterial size={16} style={styles.iconModel} data-testid="icon-material" />
          </div>}
          {showCarrierIcon && <div className='pr1'>
            <IconCarrier size='20' data-testid="icon-carrier" />
          </div>}

          {!editing && (
            <div
              className='f7 truncate'
              style={styles.dataName}
              title={props.metaData.name}
              data-testid="node-name"
            >{props.metaData.name}</div>
          )}

          {editing && (
            <EditableText
              editing
              onClick={(event) => event.stopPropagation()}
              defaultValue={props.metaData.name}
              editable={props.metaData.type === 'group'}
              onChange={(name) => {
                handleEditing(false)
                // Updates the actual scene graph
                dispatch(fromTree.updateMetadata(props.uuid, { name }))
              }}
              className='truncate f7'
              iconStyle={styles.iconPencilEditableText}
            >
              {(value, props) => <div {...props}>{value}</div>}
            </EditableText>
          )}
        </div>

        <div className={cs('flex items-center', { muted: disabled })}>
          {props.metaData.canEdit && (
            <ButtonIcon
              disabled={false}
              noPointer={false}
              btnType='transparent'
              className='opacity-hover-lighter-performant-alt p1'
              padding={false}
              onClick={() => {
                if (disabled) return
                handleEditing(!editing)
              }}
              icon={
                <IconPencil
                  size={12}
                  className='opacity-hover-lighter-performant-alt'
                  data-testid="icon-pencil"
                />
              }
            />
          )}

          {/* Unlock Virtual Product from UPPLYSA will be inactive during the beta period.
          Re add the following onClick action to active unlocking:
          !disabled && props.toggleUnlockVirtualProductModalOpen(true, props.uuid)
          */}

          {props.metaData.locked && (
            <ButtonIcon
              disabled={disabled}
              noPointer={false}
              title="Unlock virtual product"
              btnType='transparent'
              className='opacity-hover-lighter-performant-alt p1'
              padding={false}
              onClick={() => {}}
              icon={<IconLock size={12} data-testid="icon-lock" />}
            />
          )}
          {actionsEnabled && (<>
            <div
              ref={actionOverlayTriggerRef}
            >
              <ButtonIcon
                btnType='transparent'
                className='opacity-hover-lighter-performant-alt py1'
                onClick={handleOverlayActionClick}
                icon={
                  <FaEllipsisV
                    size={12}
                    className='opacity-hover-lighter-performant-alt'
                    data-testid="icon-ellipsis-v"
                  />
                }
              />
            </div>
          </>)}
          {props.metaData.canHide && (
            <ButtonIcon
              disabled={isHideDisabled()}
              noPointer={false}
              title="Hide/Show node"
              btnType='transparent'
              className={cs(
                'opacity-hover-lighter-performant-alt p1',
                { 'bg-error-striped': !(isHidden || ancestorHidden) && childrenHidden })
              }
              padding={false}
              onClick={() => !disabled && dispatch(fromSelection.toggleVisibility(props.uuid))}
              icon={
                (isHidden || ancestorHidden)
                  ? <i style={{ color: isIsolationActive ? 'lightgray' : 'black' }} className='icon-eye-off' data-testid="icon-eye-off" />
                  : <i style={{ color: isIsolationActive && (isHidden || childrenHidden) ? 'lightgray' : 'black' }} className='icon-eye' data-testid="icon-eye" />
              }
            />
          )}
        </div>
      </div>
    </DragDropWrapper>
  )
})
