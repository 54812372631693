/* eslint-disable */
/**
 * @author mrdoob / http://mrdoob.com/
 */

const URL = window.URL

module.exports = function (THREE) {
  // Reverted to r83
  class PatchedImageLoader extends THREE.ImageLoader {
    load (url, onLoad, onProgress, onError) {
      var scope = this

      var image = document.createElementNS('http://www.w3.org/1999/xhtml', 'img')
      image.onload = function () {
        image.onload = null

        URL.revokeObjectURL(image.src)

        if (onLoad) onLoad(image)

        scope.manager.itemEnd(url)
      }
      image.onerror = onError

      if (url.indexOf('data:') === 0) {
        image.src = url
      } else if (this.crossOrigin !== undefined) {
        // crossOrigin doesn't work with URL.createObjectURL()?

        image.crossOrigin = this.crossOrigin
        image.src = url
      } else {
        var loader = new THREE.FileLoader()
        loader.setPath(this.path)
        loader.setResponseType('blob')
        loader.setWithCredentials(this.withCredentials)
        loader.load(url, function (blob) {
          image.src = URL.createObjectURL(blob)
        }, onProgress, onError)
      }

      scope.manager.itemStart(url)

      return image
    }
  }

  class PatchedTextureLoader extends THREE.TextureLoader {
    load (url, onLoad, onProgress, onError) {
      var texture = new THREE.Texture()

      // From r84 but using pathed PatchedImageLoader
      var loader = new PatchedImageLoader(this.manager)
      // From r84 but using pathed PatchedImageLoader
      loader.setCrossOrigin(this.crossOrigin)
      loader.setPath(this.path)
      loader.load(url, function (image) {
        // JPEGs can't have an alpha channel, so memory can be saved by storing them as RGB.
        var isJPEG = url.search(/\.(jpg|jpeg)$/) > 0 || url.search(/^data:image\/jpeg/) === 0

        texture.format = isJPEG ? THREE.RGBFormat : THREE.RGBAFormat
        texture.image = image
        texture.needsUpdate = true

        if (onLoad !== undefined) {
          onLoad(texture)
        }
      }, onProgress, onError)

      return texture
    }
  }

  return PatchedTextureLoader
}
