import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'

// Lodash
import _capitalize from 'lodash/capitalize'

// Redux
import { RootState } from '../../../../stores/ducks'
import { DEFAULT_TEMPLATE_ID, load } from '../../../../stores/ducks/templates'
import * as fromThreeviewerUI from '../../../../stores/ducks/threeviewer/ui'
import * as fromTemplatesSelectors from '../../../../stores/ducks/templates/selectors'
import * as fromRoomsetsSelectors from '../../../../stores/ducks/roomsets/selectors'
import * as fromThreeviewerFilesSelectors from '../../../../stores/ducks/threeviewer/files/selectors'
import * as fromInterfaceSelectors from '../../../../stores/ducks/interface/selectors'
import { LOCKED_MODES } from '../../../../stores/ducks/threeviewer/camera'
import { getCameraMode, getIsFeatureActive } from '../../../../stores/ducks/threeviewer/selectors'

// Utils
import * as fromStorageUtils from '../../../../utils/storage'

// Components
import DefaultScene from './default-scene'
import Button from '../../../common/button'
import StorageApiImage from '../../../common/storage-api-image'
import { ModalButton } from '../../../common/modal'
import { PanelSection, PanelSectionHeader, PanelSectionContent } from '../../../common/panels/panel-section'
import TemplateLoader from '../../../template-loader'
import * as customHome from '../../../../stores/ducks/threeviewer/custom-home'
import CustomHomeControls from './CustomHomeControls'
import { FEATURES } from '../../../../constants'

const getIsDisabled = createSelector(
  (_: RootState, isDisabled: boolean) => isDisabled,
  fromThreeviewerFilesSelectors.getIsDefaultTemplateLoading,
  fromThreeviewerFilesSelectors.getIsSceneLoaded,
  getCameraMode,
  (isDisabled, isDefaultTemplateLoading, isSceneLoaded, cameraMode) => {
    return isDisabled ||
      isDefaultTemplateLoading ||
      !isSceneLoaded ||
      cameraMode === LOCKED_MODES.OFFSET_VIEW_CAMERA
  }
)

const getLook = createSelector(
  fromTemplatesSelectors.getActiveTemplateId,
  fromRoomsetsSelectors.getCurrentEntry,
  fromInterfaceSelectors.getTemplateModalProp,
  (
    activeTemplateId,
    currentRoomset,
  ) => {
    const look = {
      title: 'n/a',
      thumbnail: '/img/placeholder-project.jpg',
      id: ''
    }

    if (activeTemplateId && !currentRoomset) {
      look.id = activeTemplateId
      look.title = _capitalize(activeTemplateId)
      look.thumbnail = '/img/template-default.jpg'
    }

    if (currentRoomset && currentRoomset.id && currentRoomset.metadata) {
      look.id = currentRoomset.id
      look.title = currentRoomset.title ? currentRoomset.title : ''
      look.thumbnail = currentRoomset.metadata.thumbnail ? fromStorageUtils.getImageSrc(currentRoomset.metadata.thumbnail, {
        type: 'thumbnail',
        format: 'jpg'
      }) : '/img/placeholder-project.jpg'
    }

    return look
  }
)

const getShouldTemplateModalCloseOnEsc = createSelector(
  fromInterfaceSelectors.getTemplateModalProp,
  (templateModal) => {
    return templateModal.shouldCloseOnEsc
  }
)

function ScenePanel (props: { isDisabled: boolean }) {
  const dispatch = useDispatch()

  const disabled = useSelector((state: RootState) => getIsDisabled(state, props.isDisabled))
  const look = useSelector(getLook)
  const shouldTemplateModalCloseOnEsc = useSelector(getShouldTemplateModalCloseOnEsc)
  const isCustomHomeFeatureActive = useSelector((state) => getIsFeatureActive(state)(FEATURES.CUSTOM_HOME, true))

  const scenePanelState = useSelector((state: RootState) => state.threeviewer.customHome.scenePanelState)

  React.useEffect(() => {
    if (scenePanelState === 'DEFAULT_TEMPLATE' && ![DEFAULT_TEMPLATE_ID, customHome.CUSTOM_HOME_TEMPLATE_ID].includes(look.id)) {
      dispatch(customHome.setScenePanelState('HOME'))
    }
  }, [look.id, scenePanelState])

  React.useEffect(() => {
    return () => {
      dispatch(customHome.dispose())
    }
  }, [])

  return (
    <div className='height-100 overflow-auto'>
      <PanelSectionHeader title='Scene' className='pt2 px2' />

      {scenePanelState !== 'CUSTOM_HOME' && (
        <PanelSection fullHeight>
          <PanelSectionContent>
            <StorageApiImage
              type='thumbnail'
              src={look.thumbnail}
              className='userselect-none bg-gray-light'
              style={{
                objectFit: 'scale-down',
                objectPosition: 'center',
                height: 180
              }}
            />
            <p><b>Active scene: </b> {look.title}</p>
          </PanelSectionContent>
        </PanelSection>
      )}

      <PanelSection fullHeight>
        <PanelSectionContent>
          <ModalButton
            disabled={disabled || scenePanelState === 'CUSTOM_HOME'}
            fullSize
            modalProps={{ shouldCloseOnEsc: shouldTemplateModalCloseOnEsc }}
            modalContent={(closeModal: () => void) => (
              <TemplateLoader
                onClose={() => {
                  dispatch(fromThreeviewerUI.toggleKeyBoardBindings(true))
                  closeModal()
                }}
              />
            )}
            button={(openModal: any) => (
              <div className='flex mb1'>
                <Button
                  onClick={() => {
                    dispatch(load(DEFAULT_TEMPLATE_ID))
                    dispatch(customHome.setScenePanelState('DEFAULT_TEMPLATE'))
                  }}
                  pad={1}
                  className='mr1'
                  disabled={disabled || look.id === DEFAULT_TEMPLATE_ID || scenePanelState === 'CUSTOM_HOME'}
                >
                  Load Default
                </Button>
                <Button
                  onClick={(params: any) => {
                    dispatch(fromThreeviewerUI.toggleKeyBoardBindings(false))
                    openModal(params)
                  }}
                  disabled={disabled || scenePanelState === 'CUSTOM_HOME'}
                  pad={1}
                >
                  Load Home
                </Button>
              </div>
            )}
            onModalClose={() => { dispatch(fromThreeviewerUI.toggleKeyBoardBindings(true)) }}
          />

          {isCustomHomeFeatureActive && (
            <Button
              onClick={() => {
                dispatch(customHome.activate())
                dispatch(customHome.setScenePanelState('CUSTOM_HOME'))
              }}
              disabled={disabled || scenePanelState === 'CUSTOM_HOME'}
              pad={1}
            >
              Draw custom home
            </Button>
          )}
        </PanelSectionContent>
      </PanelSection>

      {scenePanelState === 'DEFAULT_TEMPLATE' && look.id === DEFAULT_TEMPLATE_ID && (
        <PanelSection key='defaultScene'>
          <PanelSectionHeader
            title='Walls and floor'
            color='gray-dark'
            noPaddingBottom
            className='my1'
          />
          <PanelSectionContent>
            <DefaultScene />
          </PanelSectionContent>
        </PanelSection>
      )}

      {scenePanelState === 'CUSTOM_HOME' && isCustomHomeFeatureActive && (
        <CustomHomeControls
          disabled={disabled}
        />
      )}
    </div>
  )
}

export default ScenePanel
