import React, { useEffect, useState } from 'react'
import UploadFileComponent from '../../common/UploadFileComponent'
import InputNumber from '../../common/form/input-number'
import Button from '../../common/button'
import NotificationBubble from '../../common/notification-bubble'

export default () => {
  const [currency, setCurrency] = useState<number | undefined>(undefined)
  const [storedCurrency, setStoredCurrency] = useState<number | undefined>(undefined)
  const [responseStatus, setResponseStatus] = useState<'success' | 'error' | null >(null)

  const getStoredCurrency = async () => {
    const res = await fetch('/api/part-cost/currency')
    const currencyData = await res.json()
    setStoredCurrency(currencyData.currency)
    setCurrency(currencyData.currency)
  }

  useEffect(() => {
    getStoredCurrency()
  }, [])

  const saveNewCurrency = async () => {
    const res = await fetch('/api/part-cost/currency', {
      method: 'POST',
      body: JSON.stringify({
        currency,
        from: 'CNY',
        to: 'EUR'
      })
    })
    if (res.status === 200) setResponseStatus('success')
  }

  return (<div>
    <UploadFileComponent
      dataUploadUrl={'api/spreadsheet/part-cost/replace'}
      description='Upload a csv file that contains the price data for IKEA PARTS'
      supportedFileFormats={['.xlsx', '.xlsm']}
    />
    <div className='center'>
      <div className='pt3 pb1'>Add IKEA exchange rate - CNY to EURO</div>
      <InputNumber
        value={currency}
        style={{ fontSize: '1rem', height: '2rem', display: 'inline' }}
        className='mr1'
        onChange={setCurrency}
        step={0.01}
        min={0.0001}
        max={1000} />
      <Button
        pad={2}
        disabled={(storedCurrency === currency)}
        inverted={false}
        btnType={'primary'}
        onClick={saveNewCurrency}
        style={{ display: 'inline' }}
      >
      Update currency
      </Button>
      <NotificationBubble
        notification={responseStatus === 'success' ? 'File was successfully uploaded' : null}
        error={responseStatus === 'error' ? 'No file was uploaded' : null}
        onClearOrTimeout={() => setResponseStatus(null)}
      />
    </div>
  </div>)
}
