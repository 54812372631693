import React from 'react'
import cs from 'classnames'
import useOutsideClick from '../../hooks/useOutsideClick'
import _map from 'lodash/map'

import ZIndexHandler from '../enhancers/zindex'
import { MdMoreVert } from 'react-icons/md'
// @ts-ignore
import css from '../common/menu.css'

export type MenuItemType = {
  icon?: any;
  iconInJSX?: any;
  text: string;
  shouldntClose: boolean;
  onClick: () => void
  subMenuItems?: MenuItemType[]
  disabled: boolean
}

function MenuItem (props: MenuItemType) {
  return (
    <a
      onClick={() => { !props.disabled && props.onClick() }}
      className={cs('f6 block px1 py1 bg-primary-hover', {
        'bg-gray-light-hover c-gray default-cursor width-100': props.disabled,
        pointer: !props.disabled
      })}
    >
      {props.icon && <div className='inline mr1'>{props.icon}</div>}
      <div className='inline'>{props.text}</div>
    </a>
  )
}

type MenuItemWithSubsProps = {
  item: MenuItemType
  disabled: boolean
  onClick: () => void
  onClose: () => void
}

function MenuItemWithSubs (props: MenuItemWithSubsProps) {
  const [hover, setHover] = React.useState(false)

  return (
    <div
      className='relative'
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className=''>
        <MenuItem
          {...props.item}
          onClick={props.onClick}
          disabled={props.disabled}
        />
      </div>

      <div
        className={cs(
          'absolute c-gray-dark bg-gray-light top-0 width-100 brtr-2 brbr-2',
          { hidden: !hover }
        )}
        style={{ marginLeft: 180 }}
      >
        {_map((props.item.subMenuItems || []), (subItem, index) => (
          <MenuItem
            {...subItem}
            key={index}
            disabled={props.disabled}
            onClick={() => {
              subItem.onClick()
              props.onClose()
            }}
          />
        ))}
      </div>
    </div>
  )
}

MenuItemWithSubs.defaultProps = {
  text: '',
  icon: false,
  onClick: () => {},
  onClose: () => {},
  disabled: false
}

type Props = React.HTMLAttributes<HTMLDivElement> & {
  isTop: boolean
  setAsTop: () => void
  items: MenuItemType[]
  position?: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight'
  button?: any
  disabled: boolean
}

function MenuWithSubMenus (props: Props) {
  const [isOpen, setIsOpen] = React.useState(false)
  const el = React.useRef(null)
  useOutsideClick(el, () => setIsOpen(false))

  return (
    <div
      ref={el}
      className={cs('relative', {
        z1: !props.isTop, z2: props.isTop
      }, props.className)}
      onClick={props.setAsTop}
    >
      <div
        onClick={() => setIsOpen(!isOpen)}
        className='pointer'
      >
        {props.button}
      </div>

      <div
        data-testid='menu-with-submenu'
        style={{ width: 180 }}
        className={cs(
          'absolute c-gray-dark bg-gray-light width-100 brtl-2 brbl-2 mt1',
          css[props.position || 'bottomLeft'],
          { hidden: !isOpen }
        )}
      >
        {props.items.filter(i => i).map((item, index) => (
          <MenuItemWithSubs
            item={item}
            disabled={props.disabled || item.disabled}
            key={index}
            onClose={() => setIsOpen(false)}
            onClick={() => {
              if (!item.subMenuItems) {
                setIsOpen(false)
                item.onClick && item.onClick()
              }
            }}
          />
        ))}
      </div>
    </div>
  )
}

MenuWithSubMenus.defaultProps = {
  items: [],
  button: <MdMoreVert size={24} />
}

export default ZIndexHandler(MenuWithSubMenus)
