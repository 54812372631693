import { createSelector } from 'reselect'

import { RootState } from '../../../../stores/ducks'
import { getNodeList, getForcedUpdate } from '../../../../stores/ducks/threeviewer/selectors'
import type { SceneGraphNode3d as ISceneGraphNode3d } from '../../../../../../go3dthree/types/SceneGraph'
import * as fromMaterialsSelectors from '../../../../stores/ducks/materials/selectors'

export type NodesWithChildren = {
  nodeKey: string
  children: ISceneGraphNode3d[],
  name: string
}

const ignoreNode = (node: ISceneGraphNode3d) => (
  !node.geometry ||
  !node.material ||
  node.userData.isTemplate
)

const getChildNodes = (node:ISceneGraphNode3d) => {
  if (!node) return []
  if (node.children.length > 0) {
    let children = [node]
    node.children.forEach((childNode:ISceneGraphNode3d) => {
      children = children.concat(getChildNodes(childNode))
    })
    return children
  } else return [node]
}

/**
 * This function updates a child node's carrier_id property
 * (to it's parent's) if they fulfills this:
 *  - carrier_id is not defined
 *  - parent node have carrier_id
 * @param nodes
 * @returns updatedNodes
 */
const forceMaterialUpdateOnChildren = (nodes:ISceneGraphNode3d[]) => {
  const updatedNodes = nodes
  updatedNodes.forEach((node:ISceneGraphNode3d, index:number) => {
    if (!node?.userData.carrier_id) {
      const parentCarrierId = node.parent?.userData?.carrier_id?.toLowerCase()
      node.userData.carrier_id = parentCarrierId
      updatedNodes[index] = node
    }
  })
  return updatedNodes
}

export const selectNodesWithChildren = createSelector(
  (state: RootState) => state.tree.nodes,
  getNodeList,
  getForcedUpdate, // to listen on changes in threeviewer
  (nodes, nodeList) => {
    const cadData:NodesWithChildren[] = []
    // for each top node - add their children
    Object.keys(nodes).forEach((node:string) => {
      let allChildren = getChildNodes(nodeList[node]).filter((node:ISceneGraphNode3d) => !ignoreNode(node))
      allChildren = forceMaterialUpdateOnChildren(allChildren)
      cadData.push({
        name: nodes[node].metaData.name,
        nodeKey: node,
        children: allChildren
      })
    })
    return cadData
  }
)

/**
 * This function returns all meshes included in the redux selection state.
 */
export const selectMultipleNodesWithChildren = createSelector(
  (state: RootState) => state.selection.selection,
  (state: RootState) => state.tree.nodes,
  getNodeList,
  getForcedUpdate, // to listen on changes in threeviewer
  (selection, nodes, nodeList) => {
    const cadData:NodesWithChildren[] = []
    let allMeshes:any = []
    const nodeKeys:any = []

    selection.forEach((node:string) => {
      let allChildren = getChildNodes(nodeList[node]).filter((node:ISceneGraphNode3d) => !ignoreNode(node))
      allChildren = forceMaterialUpdateOnChildren(allChildren)
      allMeshes = allMeshes.concat(allChildren)
      nodeKeys.push(node)
    })
    cadData.push({
      name: 'Part data',
      nodeKey: nodeKeys,
      children: allMeshes
    })
    return cadData
  }
)

/**
 * This function selects all the appearances in the scene en returns them as materials
 */
export const selectAppearancesInScene = createSelector(
  fromMaterialsSelectors.getJsonEntries,
  (materials) => {
    return materials
  }
)

/**
 * This function selects all the materials from the materials xls file and returns them as carriers.
 */
export const getAllCarriers = createSelector(
  (state: RootState) => state.materials.carriers,
  (carriers) => {
    return carriers
  }
)
