import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import _get from 'lodash/get'
import fetch from '../../../../utils/fetch'
import { createSelector } from 'reselect'
import { loadSavedImageTemplateCameraSettings } from '../../../../stores/ducks/threeviewer/imageTemplates'
import { getCurrentEntry as getCurrentCombination } from '../../../../stores/ducks/combinations/selectors'
import Grid from '../../../common/grid/grid'
import { StorageApiThumbnail } from '../../../common/storage-api-image'
import type { RootState } from '../../../../stores/ducks'
import type { Roomset } from '../../../../stores/ducks/roomsets/Roomset'
import Immutable, { ImmutableObject } from 'seamless-immutable'

const selectTemplates = createSelector(
  (state: RootState) => state.roomsets.entries,
  (roomsets) => {
    return Object.values(roomsets).filter(roomset => roomset.type === 'image-template')
  }
)

const selectCombination = createSelector(
  getCurrentCombination,
  (combination) => {
    let _combination = null
    if (combination && combination.imageTemplateId) {
      _combination = { ...combination }
      _combination.file = _get(combination, 'render.manifest.files.0')
    }
    return _combination
  }
)

export default function UseImageTemplate () {
  const dispatch = useDispatch()
  const templates = useSelector(selectTemplates)
  const combination = useSelector(selectCombination)
  const [usedTemplate, setUsedTemplate] = React.useState<ImmutableObject<Roomset> | null>(null)

  const onSelectUsed = React.useCallback(() => dispatch(loadSavedImageTemplateCameraSettings(usedTemplate as Roomset, combination)), [dispatch, usedTemplate, combination])

  React.useEffect(() => {
    const abortController = new AbortController()
    const fetchData = async () => {
      if (combination && combination.imageTemplateId) {
        let template = templates.find((t: any) => t.id === combination.imageTemplateId)
        // Template might have been removed, is set as draft or is hidden.
        try {
          if (!template) {
            const result = await fetch(`/api/roomsets/${combination.imageTemplateId}`, {
              signal: abortController.signal
            })
            const json: Roomset = await result.json()
            template = Immutable(json)
          }
          template && setUsedTemplate(template)
        } catch (e) {
          if (!abortController.signal.aborted) {
            console.error('Request failed.')
          }
        }
      }
    }
    fetchData()
    return () => {
      abortController.abort()
    }
  }, [templates, combination])

  return React.useMemo(() => (
    <>
      {(combination && usedTemplate) && (
        <>
          <Grid
            columns={[48, 'auto']}
            className='pointer bg-gray-light-hover'
            onClick={onSelectUsed}
          >
            <StorageApiThumbnail className='border bc-gray-light bg-white' file={combination.file} />
            <div className='flex items-center'>
              Use saved setting
            </div>
          </Grid>
        </>
      )}
      {!combination && (
        <div className='muted'>
          No template images have been created yet.
        </div>
      )}
    </>
  ), [
    dispatch,
    templates,
    combination,
    usedTemplate,
    onSelectUsed,
  ])
}
