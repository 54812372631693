import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import moment from 'moment'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _omit from 'lodash/omit'
import _sortBy from 'lodash/sortBy'

import { MdVisibility as IconVisibility, MdVisibilityOff as IconVisibilityOff } from 'react-icons/md'

import Modal from '../../common/modal'
import Label from '../../common/form/label'
import Button from '../../common/button'
import InputFile from '../../common/form/input-file'
import InputText from '../../common/form/input-text'
import InputSelect from '../../common/form/input-select'

import { StorageApiThumbnail } from '../../common/storage-api-image'
import InputNumber from '../../common/form/input-number'

import AutoFill from '../../common/grid/AutoFill'

import UploadProgress from './upload-progress'
import DeadlineProgress from './deadline-progress'
import { Files } from './files'

import * as fromRoomsetsAdmin from '../../../stores/ducks/roomsets-admin'
import * as fromRoomsetAdminSelectors from '../../../stores/ducks/roomsets-admin/selectors'
import {
  mapToKeysValueLabel,
  ROOMSET_TAGS_FULL,
  GROUPED_ROOMSET_COUNTRIES,
  ROOMSET_COUNTRIES
} from '../../../constants'
import { RoomsetMetadata } from '../../../stores/ducks/roomsets/Roomset'

const ROOMSET_TAGS = mapToKeysValueLabel(ROOMSET_TAGS_FULL)

const formatGroupLabel = (data: any) => (
  <div className='flex justify-between pb1 border-bottom'>
    <span className='c-gray-dark'>{data.label}</span>
    <span className='c-gray-dark label-gray-light'>{data.options.length}</span>
  </div>
)

type AddEditRoomsetModalProps = {
  isOpen: boolean
  onRequestClose: () => void
}

interface IRoomsetCountries {
  [key: string]: string
}

const AddEditRoomsetModal = (props: AddEditRoomsetModalProps) => {
  const dispatch = useDispatch()
  const currentId = useSelector(fromRoomsetAdminSelectors.selectCurrentId)
  const editingRoomset = useSelector(fromRoomsetAdminSelectors.selectEditingRoomset)
  const currentRoomset = useSelector(fromRoomsetAdminSelectors.selectCurrentRoomset)
  const upload = useSelector(fromRoomsetAdminSelectors.selectUpload)
  const jobStatuses = useSelector(fromRoomsetAdminSelectors.selectJobStatuses)
  const canStartDeadlineJob = useSelector(fromRoomsetAdminSelectors.selectCanStartDeadlineJob)

  const [errors, setErrors] = useState<any>({})
  const [disableStartDeadlineJobButton, setDisableStartDeadlineJobButton] = useState<boolean>(false)
  const [indicatedErrors, setIndicatedErrors] = useState<boolean>(false)

  const roomsetCountries: IRoomsetCountries = ROOMSET_COUNTRIES

  const handleStartConvert = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    setDisableStartDeadlineJobButton(true)
    setTimeout(() => setDisableStartDeadlineJobButton(false), 4000)
    dispatch(fromRoomsetsAdmin.startRoomsetDeadlineJob(currentId!))
  }

  const metadata: RoomsetMetadata = _get(editingRoomset, 'metadata', {})
  const {
    tags,
    area,
    rooms,
    thumbnail,
    marketArea,
    isHidden
  } = metadata

  const handleCreateRoomset = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    const errors: any = {}

    if (!marketArea?.length) errors.marketArea = true
    if (!editingRoomset.title || !editingRoomset.title.length) errors.title = true
    if (!(editingRoomset.uploadId || editingRoomset.combinationId)) errors.maxFile = true
    if (!thumbnail) errors.floorplan = true

    setIndicatedErrors(true)
    setErrors(errors)
  }

  const handleHideShowRoomset = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    dispatch(fromRoomsetsAdmin.setEditingRoomsetMetadataProperty({
      key: 'isHidden',
      value: !isHidden
    }))
  }

  const handleUploadMaxFile = (files: FileList) => {
    if (files && files.length) {
      setErrors(_omit(errors, 'maxFile'))
      dispatch(fromRoomsetsAdmin.uploadMaxFile(files))
    }
  }

  const handleUploadFloorplan = (files: FileList) => {
    if (files && files.length) {
      setErrors(_omit(errors, 'floorplan'))
      dispatch(fromRoomsetsAdmin.uploadThumbnail(files))
    }
  }

  const handleMarketAreaChange = (countries: any) => {
    if (countries && countries.length) {
      setErrors(_omit(errors, 'marketArea'))
      dispatch(fromRoomsetsAdmin.setEditingRoomsetMetadataProperty({
        key: 'marketArea',
        value: _map(countries, 'value')
      }))
    }
  }

  const handleTitleChange = (title: string) => {
    if (title && title.trim && title.trim().length) {
      setErrors(_omit(errors, 'title'))
      dispatch(fromRoomsetsAdmin.setEditingRoomsetProperty({
        key: 'title',
        value: title
      }))
    }
  }

  const handleClose = () => {
    setIndicatedErrors(false)
    setErrors({})
    props.onRequestClose()
  }

  useEffect(() => {
    if (indicatedErrors) {
      if (Object.keys(errors).length === 0) {
        dispatch(fromRoomsetsAdmin.createOrUpdate(editingRoomset))
      }
    }
  }, [errors])

  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={handleClose}
      width={'80vw'}
    >
      <div>
        <h2 className='mt0'>
          {currentId ? 'Edit' : 'Add new'} roomset
        </h2>
      </div>

      <div
        style={{ height: '70vh' }}
        className='py2 overflow-x-hidden overflow-y-scroll custom-scrollbar'
      >
        <div className='mb2 width-50'>
          <Label className='flex justify-between'>
            <span>Roomset title</span>
            {errors.title && (<span className='c-error regular'>Required</span>)}
          </Label>
          <InputText
            defaultValue={editingRoomset.title}
            onChange={handleTitleChange}
          />
        </div>

        <div className='mb2 flex'>
          <div className='mr2'>
            <Label>Rooms:</Label>
            <InputNumber
              value={rooms || 1}
              min={1}
              max={22}
              onChange={(rooms) => dispatch(fromRoomsetsAdmin.setEditingRoomsetMetadataProperty({
                key: 'rooms',
                value: rooms
              }))}
            />
          </div>
          <div className='mr2'>
            <Label>Area (m²):</Label>
            <InputNumber
              value={area || 10}
              min={10}
              max={250}
              onChange={(area) => dispatch(fromRoomsetsAdmin.setEditingRoomsetMetadataProperty({
                key: 'area',
                value: area
              }))}
            />
          </div>
        </div>

        <div className='mb2 width-50'>
          <Label>Tags:</Label>
          <InputSelect
            closeMenuOnSelect={false}
            isMulti
            options={ROOMSET_TAGS}
            defaultValue={_map(tags, (tag) => ({ value: tag, label: tag }))}
            onChange={(tags: any) => {
              dispatch(fromRoomsetsAdmin.setEditingRoomsetMetadataProperty({
                key: 'tags',
                value: _map(tags, 'value')
              }))
            }}
          />
        </div>

        <div className='mb2 width-50'>
          <Label className='flex justify-between'>
            <span>Market area (one or multiple countries):</span>
            {errors.marketArea && (<span className='c-error regular'>Required</span>)}
          </Label>
          <InputSelect
            closeMenuOnSelect={false}
            isMulti
            options={GROUPED_ROOMSET_COUNTRIES}
            formatGroupLabel={formatGroupLabel}
            defaultValue={_map(marketArea, (value) => ({ value, label: roomsetCountries[value] }))}
            onChange={handleMarketAreaChange}
          />
        </div>

        <div className='mb2'>
          <AutoFill width={180}>
            {_map(_sortBy(editingRoomset.cameras, ['name']), (camera, idx) => {
              return (
                <div key={camera.id}>
                  <div className='mr4'>
                    <Label className='truncate'>{camera.name || idx}</Label>
                    <div className=''>
                      <StorageApiThumbnail
                        className='border bc-gray-light'
                        file={_get(camera, 'image')}
                      />
                    </div>

                    <InputFile
                      accept='image/*'
                      onChange={(e: any) => dispatch(fromRoomsetsAdmin.addThumbToCam(e, camera))}
                    >
                      Upload thumbnail
                    </InputFile>
                  </div>
                </div>
              )
            })}
          </AutoFill>
        </div>

        <div className='mb2 flex'>
          <div className='mr4'>
            <Label className='flex justify-between'>
              <span>Floor plan</span>
              {errors.floorplan && (<span className='c-error regular'>Required</span>)}
            </Label>
            <div style={{ width: 158 }}>
              <StorageApiThumbnail
                constrainRatio={[1, 1]}
                className='border bc-gray-light'
                file={thumbnail}
              />
            </div>

            <InputFile
              accept='image/*'
              onChange={handleUploadFloorplan}
            >
              Upload floor plan
            </InputFile>
          </div>

          <div className='flex flex-auto flex-column justify-between'>
            <div className='f7'>
              <Label className='flex justify-between'>
                <span>.max file upload</span>
                {errors.maxFile && (<span className='c-error regular'>Required</span>)}
              </Label>

              <UploadProgress
                uploadProgress={upload}
              />
              <Files
                uploadFile={_get(upload, 'filename')}
                files={_get(upload, 'manifest.files')}
              />
            </div>

            <div>
              <div className='f7'>Supported formats <b>.max .zip .tar.gz</b>. Zip must contain one .max file.</div>
              <InputFile
                accept='.max,application/gzip,application/zip'
                onChange={handleUploadMaxFile}
              >
                Upload .max file
              </InputFile>

            </div>
          </div>
        </div>

        <div className='mb2'>
          <div>
            <Label>Conversion progress</Label>
            <Label subtle>A conversion typically takes a couple of hours (depending on the size and complexity of the scene). Some data here is listed for the dpd pipeline team.</Label>
            <DeadlineProgress
              jobStatuses={jobStatuses}
            />
          </div>
        </div>
      </div>

      <div className='flex justify-end items-end mt3'>
        {editingRoomset.createdAt && (
          <div className='mr2'>
            <Label>Created</Label>
            <div className='f7 select-enable'>{`${moment(editingRoomset.createdAt).format('YYYY-MM-DD HH:mm')}`}</div>
          </div>
        )}
        {editingRoomset.modifiedAt && (
          <div className='mr2'>
            <Label>Modified</Label>
            <div className='f7 select-enable'>{`${moment(editingRoomset.modifiedAt).format('YYYY-MM-DD HH:mm')}`}</div>
          </div>
        )}
        <div className='mr2'>
          <Label>Combination id</Label>
          <div className='f7 italic select-enable'>{currentRoomset && currentRoomset.combinationId ? currentRoomset.combinationId : 'not yet converted'}</div>
        </div>
        <div className='mr2'>
          <Label>Roomset id</Label>
          <div className='f7 italic select-enable'>{currentId || 'not yet saved'}</div>
        </div>
        <Button
          title={isHidden ? 'This roomset is hidden in the Load Home view. Press to show.' : 'This roomset is visible in the Load Home view. Press to hide.'}
          className='mr3'
          btnType={isHidden ? false : 'primary'}
          onClick={handleHideShowRoomset}>
          {isHidden ? <IconVisibilityOff size={'1rem'} /> : <IconVisibility size={'1rem'} />}
        </Button>
        <Button
          className='mr3'
          btnType='primary'
          disabled={Object.keys(errors).length !== 0}
          onClick={handleCreateRoomset}>
          {currentId ? 'Update roomset' : 'Create roomset'}
        </Button>
        <Button
          disabled={!canStartDeadlineJob || !currentId || disableStartDeadlineJobButton}
          btnType='primary'
          onClick={handleStartConvert}>
          Start roomset convert
        </Button>
      </div>
    </Modal>
  )
}

export default AddEditRoomsetModal
