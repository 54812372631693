import React from 'react'

const IconCross = props => (
  <svg
    {...props}
    width={props.size}
    height={props.size}
    viewBox='0 0 18 18'
    className={props.className}
  >
    <path
      fill={props.color}
      d='M17.749 1.707L16.334.293 9.042 7.586 1.749.293.334 1.707 7.627 9 .334 16.293l1.415 1.414 7.293-7.293 7.292 7.293 1.415-1.414L10.456 9z'
    />
  </svg>
)

IconCross.defaultProps = {
  color: 'currentcolor',
  width: '1em',
  height: '1em',
  className: ''
}

export default IconCross
