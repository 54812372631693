import Actions from './actions'

import * as THREE from 'three'
import dat from 'dat.gui'
import { SceneGraphDirectionalLight, SceneGraphNode3d } from '../scenegraph/SceneGraph'
const DebugWindow = require('./debug-window')
const drawProperty = require('./property-drawer')

export default class Debug {
  constructor (app, scene) {
    this.stats = { render: {}, postProcess: {}, overlay: {} }
    this.app = app
    this.scene = scene
    this.debugWindow = new DebugWindow(this.app, this.scene, this.stats, () => {
      this.debugWindow.unmount()
      this.stop()
    })
    this.isRunning = false

    this.start = this.start.bind(this)
    this.stop = this.stop.bind(this)
    this.recieveStats = this.recieveStats.bind(this)
    this.update = this.update.bind(this)

    this.shadows = true
    this.lightsWithShadows = []
  }

  start () {
    if (this.isRunning) {
      this.gui.destroy()
      this.debugWindow.unmount()
    }
    this.isRunning = true
    this.debugWindow.mount()
    const gui = new dat.GUI()
    gui.width = 450
    this.gui = gui

    if (document.getElementsByClassName('dg ac')[0]) {
      document.getElementsByClassName('dg ac')[0].style = 'z-index: 100000;'
    }

    this.tick = setInterval(() => { this.debugWindow.update() }, 500)

    this.renderingFolder = gui.addFolder('Rendering')
    this.renderingFolder.add(this.app, 'doRenderAlways').listen().name('Always Render').onChange(this.update)
    this.renderingFolder.add(this.app.postProcessManager, 'forceResolutionScaling').listen().name('Force Resolution Scaling').onChange(() => this.updateForceScaling())

    const shadowMapFolder = gui.addFolder('Shadow Map')
    const shadowMapDict = {
      [THREE.BasicShadowMap]: 'BasicShadowMap',
      [THREE.PCFShadowMap]: 'PCFShadowMap',
      [THREE.PCFSoftShadowMap]: 'PCFSoftShadowMap'
    }
    const reversedShadowMapDict = {
      BasicShadowMap: THREE.BasicShadowMap,
      PCFShadowMap: THREE.PCFShadowMap,
      PCFSoftShadowMap: THREE.PCFSoftShadowMap
    }
    const shadowMap = { type: shadowMapDict[this.app.renderer.shadowMap.type] }
    shadowMapFolder.add(shadowMap, 'type')
      .options(Object.values(shadowMapDict))
      .name('ShadowMap Type')
      .onChange((value) => {
        this.app.renderer.shadowMap.type = reversedShadowMapDict[value]
        this.update()
      })

    const postProcessFolder = gui.addFolder('Post Processing')

    postProcessFolder.add(this.app.postProcessManager, 'sceneRender').listen().name('Scene Render').onChange(this.update)
    postProcessFolder.add(this.app.postProcessManager, 'overlayEnabled').listen().name('Overlay').onChange(this.update)
    postProcessFolder.add(this.app.postProcessManager.outline, 'enabled').listen().name('Outline').onChange(this.update)
    postProcessFolder.add(this.app.postProcessManager.safeFrame, 'enabled').listen().name('Safe Frame').onChange(this.update)
    postProcessFolder.add(this.app.postProcessManager, 'graphicsType', [0, 1, 2]).listen().onChange(this.update)
    postProcessFolder.add(this.app.postProcessManager.ssao, 'enabled', 0, 1).listen().name('SSAO').onChange(this.update)
    postProcessFolder.add(this.app.postProcessManager.ssao, 'kernelRadius', 0.01, 2).listen().onChange(this.update)
    postProcessFolder.add(this.app.postProcessManager.ssao, 'aoLift', 0.0, 1.0).listen().onChange(this.update)
    postProcessFolder.add(this.app.postProcessManager.lut, 'enabled', 0, 1).listen().name('Lut').onChange(this.update)
    postProcessFolder.add(this.app.postProcessManager.fxaa, 'enabled', 0, 1).listen().name('FXAA').onChange(this.update)
    postProcessFolder.add(this.app.postProcessManager.copy, 'enabled', 0, 1).listen().name('Copy').onChange(this.update)
    postProcessFolder.add(this.app.postProcessManager.ssao, 'onlySSAO', 0, 1).listen().onChange(this.update)
    postProcessFolder.add(this.app.postProcessManager, 'alchemyAO', 0, 1).listen().onChange(this.update)

    const sceneFolder = gui.addFolder('Scene')
    sceneFolder.add(this, 'shadows').onChange((value) => this.setShadows(value))

    var lightCounter = 0
    this.app.scene.traverse((child) => {
      if (child.isLight) {
        var lightFolder = sceneFolder.addFolder(`light ${lightCounter} (${child.type})`)
        lightCounter++

        lightFolder.add(child, 'uuid').listen()
        lightFolder.add(child, 'name').listen()
        lightFolder.add(child, 'visible').listen().onChange(this.update)
        lightFolder.add(child, 'intensity', 0.0, 2.0).listen().onChange(this.update)

        if (child.angle) {
          lightFolder.add(child, 'angle', 0.0, 2.0).listen().onChange(this.update)
        }
        if (child.decay) {
          lightFolder.add(child, 'decay', 0.0, 2.0).listen().onChange(this.update)
        }
        if (child.distance) {
          lightFolder.add(child, 'distance', 0.0, 100.0).listen().onChange(this.update)
        }
        if (child.castShadow) {
          lightFolder.add(child, 'castShadow').listen().onChange(this.update)
        }

        if (child.shadow) {
          const shadowProps = { ...child.shadow }
          const shadowFolder = lightFolder.addFolder('Shadow')

          Object.entries(shadowProps).forEach(([key, value]) => {
            if (key !== 'camera') {
              shadowFolder.add(shadowProps, key).onChange(() => {
                child.shadow = { ...shadowProps }
                child._updateRenderAsset()
                this.update()
              })
            }
          })

          const cameraFolder = shadowFolder.addFolder('Camera')
          Object.entries(shadowProps.camera).forEach(([key]) => {
            cameraFolder.add(shadowProps.camera, key).onChange(() => {
              child.shadow = { ...shadowProps }
              child._updateRenderAsset()
              this.update()
            })
          })
        }

        if (child.receiveShadow) {
          lightFolder.add(child, 'receiveShadow').listen().onChange(this.update)
        }

        const positionFolder = lightFolder.addFolder('Position')
        positionFolder.add(child.position, 'x', -100, 100, 0.1).name('x').onChange((value) => {
          child.position.x = value
          child._updateRenderAsset()
          this.update()
        })
        positionFolder.add(child.position, 'y', -100, 100, 0.1).name('y').onChange((value) => {
          child.position.y = value
          child._updateRenderAsset()
          this.update()
        })
        positionFolder.add(child.position, 'z', -100, 100, 0.1).name('z').onChange((value) => {
          child.position.z = value
          child._updateRenderAsset()
          this.update()
        })

        if (!child.target && child instanceof SceneGraphDirectionalLight) {
          const target = new SceneGraphNode3d()
          this.app.scene.add(target)
          child.target = target
        }

        if (child.target) {
          const targetFolder = lightFolder.addFolder('Target position')
          targetFolder.add(child.target.position, 'x', -100, 100, 0.1).name('x').onChange((value) => {
            child.target.position.y = value
            child.target._updateRenderAsset()
            child._updateRenderAsset()
            this.update()
          })
          targetFolder.add(child.target.position, 'y', -100, 100, 0.1).name('y').onChange((value) => {
            child.target.position.y = value
            child.target._updateRenderAsset()
            child._updateRenderAsset()
            this.update()
          })
          targetFolder.add(child.target.position, 'z', -100, 100, 0.1).name('z').onChange((value) => {
            child.target.position.z = value
            child.target._updateRenderAsset()
            child._updateRenderAsset()
            this.update()
          })
        }
      }
    })

    const firstSelection = this.app.picker.selection[Object.keys(this.app.picker.selection)[0]]

    if (firstSelection instanceof THREE.Object3D) {
      const selectedFolder = gui.addFolder(`Selected Object (${firstSelection.type})`)

      Object.keys(firstSelection).forEach((k) => {
        drawProperty(firstSelection, k, selectedFolder, this.update)
      })
    }

    sceneFolder.add(this.app.renderScene, 'printStats').name('Print Scene Statistics (console)')

    const actions = new Actions(this.app)
    const actionFolder = gui.addFolder('Debug Actions')
    actionFolder.add(actions, 'wireframe').name('Wireframe')
    actionFolder.add(actions, 'showCubeCamera').name('Show Cube Camera').listen().onChange(this.update)
    actionFolder.add(actions, 'showControlsTarget').name('Show Controls Target').listen().onChange(this.update)
    actionFolder.add(actions, 'showObjectNormals').name('Show Object Normals')
    actionFolder.add(actions, 'showOSBoundingBoxes').name('Object Space Bounding Boxes')
    actionFolder.add(actions, 'showWSBoundingBoxes').name('World Space Bounding Boxes')
    actionFolder.add(actions, 'showOSBoundingSpheres').name('Show Bounding Spheres')
    actionFolder.add(actions, 'showShadowCameras').name('Show Shadow Cameras')
    actionFolder.add(actions, 'showVertexNormals').name('Show Vertex Normals')
    actionFolder.add(actions, 'showLights').name('Show Lights')
    actionFolder.add(actions.mock.update, 'geometry', ['keep', 'cubes', 'spheres']).name('Geometries').listen().onChange(actions.mockUpdate.bind(actions))
    actionFolder.add(actions.mock.update, 'material', ['keep', 'white triplanar']).name('Materials').listen().onChange(actions.mockUpdate.bind(actions))
    actionFolder.add(actions.mock.update, 'simplifyStart', 1, 1000).name('Spheres Simplification Start').listen().onChange(actions.mockUpdate.bind(actions))
    actionFolder.add(actions.mock.update, 'simplifyPercentage', 0, 1).name('Spheres Simplification Keep Percentage').listen().onChange(actions.mockUpdate.bind(actions))
    actionFolder.add(actions, 'reset').name('Reset')
    this.update()
  }

  stop () {
    this.isRunning = false
    if (this.gui) {
      this.gui.destroy()
    }
    window.clearInterval(this.tick)
  }

  recieveStats (renderer, type, drawCalls, triangleCount, lineCount, pointCount) {
    if (type === 'memory') {
      this.stats.geometryCount = renderer.info.memory.geometries
      this.stats.textureCount = renderer.info.memory.textures
      this.stats.programCount = renderer.info.programs.length
      return
    }

    let targetStats

    if (type === 'render') {
      targetStats = this.stats.render
      // Update these here so we only do it once per frame
    } else if (type === 'overlay') {
      targetStats = this.stats.overlay
    } else if (type === 'postprocess') {
      targetStats = this.stats.postProcess
    }

    if (targetStats) {
      targetStats.drawCalls = drawCalls
      targetStats.triangleCount = triangleCount
      targetStats.lineCount = lineCount
      targetStats.pointCount = pointCount
    }
  }

  setShadows (value) {
    if (!value) {
      this.app.scene.traverse((child) => {
        if (child.isLight && child.castShadow) {
          this.lightsWithShadows.push(child)
          child.castShadow = false
        }
      })
    } else if (value) {
      this.lightsWithShadows.forEach((light) => {
        light.castShadow = value
      })
      this.lightsWithShadows.length = 0
    }

    this.lightsWithShadows.forEach((light) => {
      light.castShadow = value
    })

    this.app.renderOnNextFrame()
  }

  setSsaoEnabled (value) {
    this.app.postProcessManager.ssao.enabled = value
    this.update()
  }

  setFxaaEnabled (value) {
    this.app.postProcessManager.fxaa.enabled = value
    this.update()
  }

  updateForceScaling () {
    if (this.app.postProcessManager.forceResolutionScaling) {
      this.forcedScaleController = this.renderingFolder
        .add(this.app.postProcessManager, 'forceResolutionScalingValue', 0, 1)
        .listen()
        .name('Forced Resolution Scale')
        .onChange(this.update)
    } else {
      this.renderingFolder.remove(this.forcedScaleController)
    }
    this.update()
  }

  update () {
    this.app.renderer.shadowMap.needsUpdate = true
    this.app.renderOnNextFrame()
    this.debugWindow.update()
  }

  dispose () {
    this.isDisposed = true
    this.debugWindow.unmount()
    this.stop()
  }

  getSelectedThreeMaterial () {
    const mesh = Object.values(this.app.picker.selection)[0]
    const material = mesh.material
    return this.app.assetManager.getThreeMaterial(material._renderMaterialId)
  }
}
