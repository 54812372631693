module.exports = function (THREE) {
  const SpaceMouse = function (id, orbit) {
    THREE.Object3D.call(this)
    var axes = [0, 0, 0, 0, 0, 0]
    var orbitControls = orbit

    var spaceMouseRotateSpeed = 6.0
    var spaceMouseDollyScale = 0.98
    var panSpeed = 50

    var panXAxis = 0
    var panYAxis = 2
    var dollyAxis = 1
    var rotateUpAxis = 3
    var rotateLeftAxis = 5

    this.gamePad = findGamepad(id)

    this.hasGamePad = function () {
      if (this.gamePad) {
        return true
      } else {
        return false
      }
    }

    this.update = function (camera) {
      this.gamePad = findGamepad(id)

      // If any axis has changed since last update, update list of axes
      if (axes[panXAxis] !== this.gamePad.axes[panXAxis] ||
        axes[dollyAxis] !== this.gamePad.axes[dollyAxis] ||
        axes[panYAxis] !== this.gamePad.axes[panYAxis] ||
        axes[rotateUpAxis] !== this.gamePad.axes[rotateUpAxis] ||
        axes[rotateLeftAxis] !== this.gamePad.axes[rotateLeftAxis]) {
        axes = this.gamePad.axes.slice(0, this.gamePad.axes.length)
      }

      if (axes[panXAxis] !== 0 || axes[panYAxis] !== 0) {
        var deltax = axes[panXAxis]
        var deltay = axes[panYAxis]
        orbitControls.panWrap(-deltax * panSpeed, -deltay * panSpeed)
      }
      if (axes[dollyAxis] > 0) {
        orbitControls.dollyInWrap(spaceMouseDollyScale)
      } else if (axes[dollyAxis] < 0) {
        orbitControls.dollyOutWrap(spaceMouseDollyScale)
      }

      var element = orbitControls.domElement === document ? orbitControls.domElement.body : orbitControls.domElement
      if (axes[rotateUpAxis] !== 0 || axes[rotateLeftAxis] !== 0) {
        orbitControls.rotateLeftWrap(2 * Math.PI * axes[rotateLeftAxis] / element.clientHeight * spaceMouseRotateSpeed)
        orbitControls.rotateUpWrap(2 * Math.PI * -axes[rotateUpAxis] / element.clientHeight * spaceMouseRotateSpeed)
      }
      orbitControls.update()
    }

    function findGamepad (id) {
      var gamepads = navigator.getGamepads()
      for (var i = 0, j = 0; i < 4; i++) {
        var gamepad = gamepads[i]
        if (gamepad && (gamepad.id.includes('SpaceMouse Wireless'))) {
          if (j === id) {
            return gamepad
          }
          j++
        }
      }
    }
  }

  SpaceMouse.prototype = Object.create(THREE.Object3D.prototype)
  SpaceMouse.prototype.constructor = SpaceMouse

  return SpaceMouse
}
