import React from 'react'
import autoBind from 'react-autobind'
import PropTypes from 'prop-types'

import { match } from '../../../utils/match-pathname'
import navigate from '../../../utils/navigate'

import colors from '../../../../css/colors'
import Grid from '../../common/grid/grid'
import AutoFill from '../../common/grid/AutoFill'
import Image from '../../common/image'
import IconMultipleImages from '../../common/icons/icon-multiple-images'

import TopBar from '../topbar'
import { modes } from '../constants'
import { PROJECT_TYPES } from '../../../constants'

import _get from 'lodash/get'
import _filter from 'lodash/filter'
import _xor from 'lodash/xor'
import Pending from '../../common/pending'

class Overview extends React.Component {
  constructor (props) {
    super(props)

    autoBind(this)

    this.state = {
      mode: modes.SELECT,
      selectedIds: []
    }
  }

  getIsAllSelected () {
    const { selectedIds } = this.state
    const { renders } = this.props
    if (selectedIds <= 0) {
      return false
    }

    return selectedIds.length === renders.length
  }

  getHasChildInRenderingState () {
    return this.props.renders.filter(render => !render.isRender).length > 0
  }

  handleToggleSelectionMode () {
    if (this.state.mode === modes.SELECT) {
      this.setState({
        mode: modes.SELECT_TO_PREVIEW,
        selectedIds: []
      })
    } else {
      this.setState({
        mode: modes.SELECT
      })
    }
  }

  handleToggleSelectAll () {
    if (this.getIsAllSelected()) {
      this.handleDeselectAll()
    } else {
      this.handleSelectAll()
    }
  }

  handleSelectAll () {
    this.setState({
      selectedIds: this.props.renders.map((render) => render.id)
    })
  }

  handleDeselectAll () {
    this.setState({
      selectedIds: []
    })
  }

  handleSelectImage (id) {
    if (!id) return
    if (this.props.projectType === PROJECT_TYPES.IMAGE_PACKAGE || this.state.mode === modes.SELECT_TO_PREVIEW) {
      this.props.onPreviewById(id)
    } else if (this.state.mode === modes.SELECT) {
      this.setState({
        selectedIds: _xor([id], this.state.selectedIds)
      })
    }
  }

  handleRemove (ids) {
    this.props.onRemove(ids)
    this.setState({
      selectedIds: []
    })
  }

  handleDownloadSpreadsheet () {
    const { selectedIds } = this.state
    const { renders } = this.props

    const selectedCombinationIds = selectedIds.map((id) => {
      return _get(renders.find((render) => render.id === id), 'combinationId')
    }).filter(Boolean)

    this.props.onDownloadSpreadsheet(selectedCombinationIds)
  }

  handleDownloadImages () {
    if (this.state.selectedIds.length === 1) {
      const selectedRender = this.props.renders.find((render) => {
        return render.id === this.state.selectedIds[0]
      })

      const link = document.createElement('a')
      link.href = selectedRender.images.original
      link.download = `${this.props.designCardTitle}_${selectedRender.id}.png`
      link.click()
      return
    }

    // otherwise zip the multiple files
    if (this.props.projectType === PROJECT_TYPES.IMAGE_PACKAGE) {
      this.props.onDownloadImagePackage(this.props.renders, this.props.designCardTitle)
    } else {
      const files = this.state.selectedIds.map((id) => {
        const render = this.props.renders.find((render) => render.id === id)
        const file = _get(render, 'manifest.files', []).filter((file) => file.name.match(/\.png/))[0]
        let downloadTitle = `${this.props.designCardTitle}_${render.id}.png`
        downloadTitle = downloadTitle.split(' ').join('-')
        return {
          key: file.key,
          name: downloadTitle
        }
      }).filter(Boolean)

      if (files.length) {
        this.props.onDownloadImages(files, this.props.designCardTitle)
      }
    }
  }

  handleVisualizeRender (combinationId) {
    let projectTypeUrlParam = 'combination'
    if (this.props.projectType && this.props.projectType !== PROJECT_TYPES.DEFAULT) {
      projectTypeUrlParam = this.props.projectType
    }
    navigate(`/visualize/${this.props.projectId}/${projectTypeUrlParam}/${combinationId}`)
  }

  handleVisualizeMasterScene () {
    if (match(`/visualize/${this.props.currentProjectId}/${this.props.projectType}/${this.props.combinationMasterId}`)) {
      return this.props.onBack()
    }
    navigate(`/visualize/${this.props.currentProjectId}/${this.props.projectType}/${this.props.combinationMasterId}`)
  }

  shouldRenderImage (render) {
    return !(this.props.projectType !== PROJECT_TYPES.IMAGE_PACKAGE && !render.rendered)
  }

  render () {
    const {
      renders,
      projectType,
      onBack,
      backToText,
      commentsCount,
      onPreview,
      onOverview,
      tab,
      onComments
    } = this.props

    const {
      mode,
      selectedIds
    } = this.state

    return (
      <Grid
        rows={[64, '1fr']}
        className='overflow-hidden height-100 pb4 custom-scrollbar-inverted'
      >
        <div className='self-center'>
          <TopBar
            projectType={projectType}
            onPreview={onPreview}
            onOverview={onOverview}
            onComments={onComments}
            commentsCount={commentsCount}
            tab={tab}
            selectionMode={mode}
            onToggleSelectionMode={this.handleToggleSelectionMode}
            hasSelection={!!selectedIds.length}
            allSelected={this.getIsAllSelected()}
            onToggleSelectAll={this.handleToggleSelectAll}
            onRemove={() => this.handleRemove(selectedIds)}
            onDownloadSpreadsheet={this.handleDownloadSpreadsheet}
            onDownloadImages={this.handleDownloadImages}
            onEditMasterScene={this.handleVisualizeMasterScene}
            onBack={onBack}
            backToText={backToText}
            hasChildInRenderingState={this.getHasChildInRenderingState()}
          />
        </div>

        <AutoFill
          className='content-start px4 pt3 overflow-overlay'
          width={168}
          gridGap={24}
        >
          {renders.map((render) => (this.shouldRenderImage(render) &&
            <div key={render.id} className='relative'>
              {render.isRender &&
                <Image
                  src={render.images.medium}
                  constrainRatio={[1, 1]}
                  className='pointer opacity-hover-lighter'
                  onClick={() => this.handleSelectImage(render.id)}
                  style={{
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    boxShadow: this.state.selectedIds.includes(render.id) ? `0 0 0 4px ${colors.primary}` : ''
                  }}
                />
              }
              {this.props.projectType === PROJECT_TYPES.IMAGE_PACKAGE && !render.isRender &&
                <Pending
                  onClick={() => this.handleSelectImage(_get(render, 'latestCompletedRender.id'))}
                  deadlineStatus={render.deadlineStatus}
                  renderStatus={render.renderStatus}
                  renderPercentage={render.renderPercentage}
                  status={render.status}
                  backgroundImage={_get(render, 'latestCompletedRender.imageSrc')}
                  multipleRenders={_filter(render.versions || [], version => !version.rendered).length > 1}
                  disableButtons
                  isVersioned={render.isVersioned}
                />
              }
              {render.isVersioned && render.isRender &&
                <IconMultipleImages
                  size={18}
                  color={colors.secondary}
                  className='absolute top-0 right-0 m1'
                />
              }
              {projectType === PROJECT_TYPES.IMAGE_PACKAGE &&
                <p className='c-gray center f8 c-white m1' style={{ wordBreak: 'break-all' }}>
                  {render.combinationTitle}
                </p>
              }
            </div>
          ))}
        </AutoFill>
      </Grid>
    )
  }
}

Overview.propTypes = {
  renders: PropTypes.array.isRequired,
  onRemove: PropTypes.func,
  onBack: PropTypes.func,
  onPreview: PropTypes.func,
  onDownloadImages: PropTypes.func,
  onDownloadSpreadsheet: PropTypes.func,
  backToText: PropTypes.string,
  combinationMasterId: PropTypes.string,
  currentProjectId: PropTypes.string
}

export default Overview
