import Loader from './Loader'
import MaterialLoader from './MaterialLoader'
import { RenderMaterial, RenderMaterialTriplanar } from './RenderMaterial'
import loadEXREnvironment from './plugins/EXREnvironment/EXREnvironmentLoader'
const THREE = require('three')

const TriplanarMaterial = require('./materials/TriplanarMaterial')
const Go3dFormatLoader = require('./tools/Go3dFormatLoader')
const Go3dFormatLoader2 = require('./tools/Go3dFormatLoader2')(THREE)

function buildMaterialHash (material) {
  let key = material.materialId + '_'
  key += material.color.getHexString()
  key += material.envMapIntensity.toFixed(4)
  if (material.side === THREE.FrontSide) {
    key += 'f'
  } else if (material.side === THREE.BackSide) {
    key += 'b'
  } else if (material.side === THREE.DoubleSide) {
    key += 'd'
  }
  if (material instanceof RenderMaterialTriplanar) {
    key += material.mapRotationX.toFixed(2)
    key += material.mapRotationY.toFixed(2)
    key += material.mapRotationZ.toFixed(2)
    key += material.uvMapRotation.toFixed(2)
    key += material.mapRepeat.x.toFixed(2)
    key += material.mapRepeat.y.toFixed(2)
    key += material.mapOffset.x.toFixed(2)
    key += material.mapOffset.y.toFixed(2)
    key += material.useTriplanar ? 'y' : 'n'
    key += material.showDiffuseOnly ? 'y' : 'n'
    key += material.useLocalEnvMap ? 'y' : 'n'
    key += material.useModelFading ? 'y' : 'n'
    key += material.startFading.toFixed(2)
    key += material.endFading.toFixed(2)
    key += material.origoFading.x.toFixed(2)
    key += material.origoFading.y.toFixed(2)
    key += material.origoFading.z.toFixed(2)
    key += material.wallX0.toFixed(4)
    key += material.wallX1.toFixed(4)
    key += material.opacity.toFixed(4)
    key += material.markerPoint.x.toFixed(4)
    key += material.markerPoint.y.toFixed(4)
    key += material.markerPoint.z.toFixed(4)
    key += material.markerPoint.w.toFixed(4)

    key += material.useColorTextureMix ? 'y' : 'n'
    key += material.colorTextureMix.toFixed(2)
    key += material.meterScale.toFixed(4)

    key += material.triplanarOrientation.x.toFixed(2)
    key += material.triplanarOrientation.y.toFixed(2)
    key += material.triplanarOrientation.z.toFixed(2)
    key += material.triplanarTranslation.x.toFixed(4)
    key += material.triplanarTranslation.y.toFixed(4)
    key += material.triplanarTranslation.z.toFixed(4)

    const decalMap = material.decalMap
    if (decalMap !== null) {
      const decalTransform = material.decalUVTransform
      for (let i = 0; i < 16; i++) {
        key += decalTransform.elements[i].toFixed(8)
      }
      key += material.decalMap.id + '_'
    } else {
      key += '_'
    }
    if (material.flipY) {
      key += 'f'
    } else {
      key += '_'
    }
  }
  return key
}

function applyMaterialProperties (threeMat, renderMat, envMap, envMapLocal) {
  // Common properties
  threeMat.color = renderMat.color // Don't use .copy() since it avoids the setter logic.
  threeMat.side = renderMat.side
  threeMat.envMap = envMap
  if (threeMat instanceof TriplanarMaterial && renderMat.useLocalEnvMap) {
    if (envMapLocal !== null) {
      threeMat.envMap = envMapLocal
    }
  }
  threeMat.envMapIntensity = renderMat.envMapIntensity

  // Type-specific properties
  if (threeMat.type === 'TriplanarMaterial') {
    threeMat.triplanarOrientation = renderMat.triplanarOrientation
    threeMat.triplanarTranslation = renderMat.triplanarTranslation
    threeMat.mapRotationX = renderMat.mapRotationX
    threeMat.mapRotationY = renderMat.mapRotationY
    threeMat.mapRotationZ = renderMat.mapRotationZ
    threeMat.uvMapRotation = renderMat.uvMapRotation
    threeMat.markerPoint = renderMat.markerPoint
    threeMat.mapRepeat = renderMat.mapRepeat.clone().multiplyScalar(renderMat.meterScale) // mapRepeat setter expects a vector2 as argument.
    threeMat.mapOffset = renderMat.useTriplanar ? renderMat.mapOffset.clone().multiplyScalar(1.0 / renderMat.meterScale) : renderMat.mapOffset.clone()
    threeMat.useTriplanar = renderMat.useTriplanar
    threeMat.showDiffuseOnly = renderMat.showDiffuseOnly
    threeMat.useLocalEnvMap = renderMat.useLocalEnvMap
    threeMat.useModelFading = renderMat.useModelFading
    threeMat.startFading = renderMat.startFading
    threeMat.endFading = renderMat.endFading
    threeMat.origoFading = renderMat.origoFading.clone()
    threeMat.wallX0 = renderMat.wallX0
    threeMat.wallX1 = renderMat.wallX1
    threeMat.opacity = renderMat.opacity
    threeMat.useColorTextureMix = renderMat.useColorTextureMix
    threeMat.colorTextureMix = renderMat.colorTextureMix
    threeMat.decalMap = renderMat.decalMap
    threeMat.decalUVTransform = renderMat.decalUVTransform
    threeMat.meterScale = renderMat.meterScale
  }

  if (threeMat.map) {
    threeMat.map.flipY = renderMat.flipY
  }
  if (threeMat.roughnessMap) {
    threeMat.roughnessMap.flipY = renderMat.flipY
  }
  if (threeMat.metalnessMap) {
    threeMat.metalnessMap.flipY = renderMat.flipY
  }
  if (threeMat.refractMap) {
    threeMat.refractMap.flipY = renderMat.flipY
  }
  if (threeMat.alphaMap) {
    threeMat.alphaMap.flipY = renderMat.flipY
  }
}

/*
  RenderResourceManager manages bank materials/models that can be used in multiple scenes
*/
export default class AssetManager {
  constructor (app) {
    const debug = false
    if (debug) {
      this.materialProxyHandler = {
        get: function (obj, prop) {
          if (prop in obj) {
            return obj[prop]
          } else if (prop === 'then') {
            return this
          } else if (prop === 'userData') {
            return obj[prop]
          } else {
            console.trace('Tried to get', prop, 'on', obj)
          }
        },
        set: function (obj, prop, value) {
          if (prop in obj) {
            obj[prop] = value
            return true
          } else {
            console.trace('Tried to set', prop, 'on', obj, 'with value', value)
            return false
          }
        }
      }
    }

    this.app = app
    this.envMaps = new Map()
    this.envMap = null
    this.envMapFiltered = null
    this.envMapLocalFiltered = null
    this.clock = new THREE.Clock()

    this.renderOnNextFrameFunction = app.renderOnNextFrame
    this.renderer = app.renderer
    this.materials = new Map()
    this.materialIndex = new Map()
    this.materialCache = new Map()
    this.geometries = new Map()
    this.meshMaterials = new Map()
    this.materialMeshes = new Map()

    this.materialMap = new Map()

    this.materialLoader = new MaterialLoader(this.renderer)
    this.geometryLoader = new Loader()
    this.geometryLoader.addListener('onError', console.error)
    this.go3dLoader2 = new Go3dFormatLoader2(this.materialLoader, this.geometryLoader)
    this.go3dLoader = new Go3dFormatLoader(this.materialLoader, this.geometryLoader)

    this.dirtyMaterials = new Set()

    // NOTE: Must be created after this.dirtyMaterials
    this.defaultMaterial = this.addMaterial(new THREE.MeshStandardMaterial({ color: 0xFFFFFF }), '__default_material')

    this.unitCubeGeometry = new THREE.BoxBufferGeometry()
    this.unitCubeGeometryId = this.addGeometry(this.unitCubeGeometry)

    // Statistics
    this.numUsedVariantsDirty = true
  }

  markAsDirty (material) {
    this.dirtyMaterials.add(material)
  }

  updateMaterials () {
    const elapsedTime = this.clock.getElapsedTime()
    this.materials.forEach(m => {
      if (m.type === 'TriplanarMaterial' && m.uniforms) {
        m.uniforms.time = { value: elapsedTime }
      }
    })

    if (this.dirtyMaterials.size === 0) return
    this.renderOnNextFrameFunction()
    this.dirtyMaterials.forEach(m => {
      this.updateMaterial(m, m._hash, m.color)
    })

    this.dirtyMaterials.clear()
  }

  addGeometry (geometry) {
    geometry.computeBoundingBox()
    geometry.computeBoundingSphere()
    const uid = THREE.Math.generateUUID()
    this.geometries.set(uid, geometry)
    return uid
  }

  getThreeMaterial (renderMaterialId) {
    return this.materials.get(this.materialMap.get(renderMaterialId))
  }

  // Creates and populates a RenderMaterial based on the underlying three material
  createRenderMaterial (material, mid) {
    let renderMat
    if (material.type === 'TriplanarMaterial') {
      renderMat = new RenderMaterialTriplanar(mid, this, undefined)

      renderMat.decalMap = material.decalMap
      renderMat.decalScale = material.decalScale || new THREE.Vector2(1, 1)
      renderMat.decalRotation = material.decalRotation
      renderMat.flipY = material.flipY
      delete material.decalScale
      delete material.decalOffset
      delete material.decalRotation
      renderMat.meterScale = material.meterScale

      renderMat.mapRotationX = material.mapRotationX
      renderMat.mapRotationY = material.mapRotationY
      renderMat.mapRotationZ = material.mapRotationZ
      renderMat.markerPoint = material.markerPoint
      renderMat.mapRepeat.copy(material.mapRepeat)
      renderMat.mapOffset.copy(material.mapOffset)
      renderMat.useTriplanar = material.useTriplanar
      renderMat.showDiffuseOnly = material.showDiffuseOnly
      renderMat.useLocalEnvMap = material.useLocalEnvMap
      renderMat.useModelFading = material.useModelFading
      renderMat.startFading = material.startFading
      renderMat.endFading = material.endFading
      renderMat.opacity = material.opacity
      renderMat.origoFading = material.origoFading
      renderMat.wallX0 = material.wallX0
      renderMat.wallX1 = material.wallX1
    } else {
      renderMat = new RenderMaterial(mid, this, undefined)
    }
    renderMat.name = material.name
    renderMat.color.copy(material.color)
    renderMat.side = material.side
    if (material.envMapIntensity) {
      renderMat.envMapIntensity = material.envMapIntensity
    }

    if (this.materialProxyHandler !== undefined) {
      renderMat = new Proxy(renderMat, this.materialProxyHandler)
    }
    return renderMat
  }

  addMaterial (material, materialId = undefined) {
    material.name = materialId
    material.envMap = this.envMapFiltered
    // If no material id is provided use a uuid.
    const mid = materialId || THREE.Math.generateUUID()

    const renderMaterial = this.createRenderMaterial(material, mid, this, undefined)
    const hash = buildMaterialHash(renderMaterial)
    renderMaterial._hash = hash

    if (this.materialIndex.has(hash)) {
      const index = this.materialIndex.get(hash)
      index.count++
      this.materialMap.set(renderMaterial._renderMaterialId, index.uid)
    } else {
      const uid = THREE.Math.generateUUID()
      this.materials.set(uid, material)
      this.materialIndex.set(hash, { uid: uid, count: 1 })
      this.materialMap.set(renderMaterial._renderMaterialId, uid)
    }
    return renderMaterial
  }

  applyUpdatedMaterialToMeshes (renderMaterial, material) {
    const oldMeshes = this.materialMeshes.get(renderMaterial)
    if (!oldMeshes) return
    oldMeshes.forEach(mesh => {
      mesh.material = material
    })
    this.numUsedVariantsDirty = true
  }

  updateMaterialReuse (renderMaterial, oldHash, newHash) {
    if (newHash === oldHash) return // Is this even necessary?! @Anders: Seems smart even if not strictly needed

    const renderMaterialId = renderMaterial._renderMaterialId
    // If we find a matching material, return that one.
    const oldIndex = this.materialIndex.get(oldHash)
    const newIndex = this.materialIndex.get(newHash)
    let freeIndex
    let result
    let updateThreeMat

    oldIndex.count--
    if (oldIndex.count === 0) {
      this.materialIndex.delete(oldHash)
      // Don't recycle the material just yet in case we need it
      freeIndex = oldIndex
    }

    if (newIndex !== undefined) {
      // We found a three-material with matching hash
      newIndex.count++
      this.applyUpdatedMaterialToMeshes(renderMaterialId, this.materials.get(newIndex.uid))
      result = newIndex.uid
      // No need to update the thee material, we already had the right hash
    } else if (freeIndex !== undefined) {
      // We did not have a match but we can reuse the old material since we were the only user
      freeIndex.count = 1
      this.materialIndex.set(newHash, freeIndex)
      result = freeIndex.uid
      updateThreeMat = this.materials.get(freeIndex.uid)
      freeIndex = undefined
    } else {
      // We need a new clone
      const newMat = this.materials.get(oldIndex.uid).clone()
      const newUid = THREE.Math.generateUUID()
      this.materials.set(newUid, newMat)
      this.materialIndex.set(newHash, { uid: newUid, count: 1 })
      this.applyUpdatedMaterialToMeshes(renderMaterialId, newMat)
      result = newUid
      updateThreeMat = newMat
    }

    if (updateThreeMat !== undefined) {
      applyMaterialProperties(updateThreeMat, renderMaterial, this.envMapFiltered, this.envMapLocalFiltered)
    }

    if (freeIndex !== undefined) {
      // The free index was not claimed, make sure the three material is recycled
      this.materials.delete(freeIndex.uid)
    }

    this.materialMap.set(renderMaterial._renderMaterialId, result)
    renderMaterial._hash = newHash
  }

  updateMaterial (renderMaterial, oldHash, color) {
    const newHash = buildMaterialHash(renderMaterial)
    this.updateMaterialReuse(renderMaterial, oldHash, newHash)
  }

  registerMaterialClone (oldId, newId, hash) {
    const matId = this.materialMap.get(oldId)
    this.materialIndex.get(hash).count++
    this.materialMap.set(newId, matId)
  }

  registerMeshMaterials (material, mesh) {
    this.numUsedVariantsDirty = true
    this.meshMaterials.set(mesh, material)

    const addMesh = mat => {
      const set = this.materialMeshes.get(material) || new Set()
      set.add(mesh)
      this.materialMeshes.set(material, set)
    }

    if (Array.isArray(material)) {
      material.forEach(m => addMesh(m))
    } else {
      addMesh(material)
    }
  }

  loadEnvMap (prefix, postfix) {
    const scope = this

    const key = prefix + '_' + postfix
    if (this.envMaps.has(key)) return this.envMaps.get(key) // Could be a promise

    if (postfix === '.exr') {
      const promise = loadEXREnvironment(prefix + postfix, this.renderer).then(envs => {
        this.envMap = envs.cubeMap
        this.envMapFiltered = envs.filtered
        return envs
      })
      this.envMaps.set(key, promise)
      return promise
    }

    const promise = new Promise((resolve, reject) => {
      function genCubeUrls (prefix, postfix) {
        return [
          prefix + 'px' + postfix, prefix + 'nx' + postfix,
          prefix + 'py' + postfix, prefix + 'ny' + postfix,
          prefix + 'pz' + postfix, prefix + 'nz' + postfix
        ]
      }
      const urls = genCubeUrls(prefix, postfix)

      if (postfix === '.hdr') {
        const hdrCubeTexLoader = new THREE.HDRCubeTextureLoader()
        hdrCubeTexLoader.load(THREE.UnsignedByteType, urls, resolve)
      } else {
        const cubeTexLoader = new THREE.CubeTextureLoader()
        cubeTexLoader.load(urls, resolve)
      }
    }).then((cubeMap) => {
      cubeMap.encoding = THREE.GammaEncoding

      const pmremGenerator = new THREE.PMREMGenerator(cubeMap)
      pmremGenerator.update(scope.renderer)

      const pmremCubeUVPacker = new THREE.PMREMCubeUVPacker(pmremGenerator.cubeLods)
      pmremCubeUVPacker.update(scope.renderer)

      const filterTarget = pmremCubeUVPacker.CubeUVRenderTarget

      pmremCubeUVPacker.CubeUVRenderTarget = null
      pmremGenerator.dispose()
      pmremCubeUVPacker.dispose()

      // We load it correctly, but we also track it here in .envMap
      // That means that we can only have one env map for now
      scope.envMap = cubeMap
      scope.envMapFiltered = filterTarget.texture

      return { cubeMap: cubeMap, filtered: filterTarget.texture }
    })

    this.envMaps.set(key, promise)
    return promise
  }

  setEnvMap (envMap, localEnvMap, localPosition) {
    if (this.envMapFiltered !== null && this.envMapFiltered !== envMap) this.envMapFiltered.dispose()
    if (this.envMapLocalFiltered !== null && this.envMapLocalFiltered !== localEnvMap) this.envMapLocalFiltered.dispose()

    this.envMapFiltered = envMap
    this.envMapLocalFiltered = localEnvMap

    this.materials.forEach((threeMaterial, key, map) => {
      if (localEnvMap !== null && (threeMaterial instanceof TriplanarMaterial && threeMaterial.useLocalEnvMap)) {
        threeMaterial.envMap = localEnvMap
        threeMaterial.wCubeCameraPosition = localPosition
      } else {
        threeMaterial.envMap = envMap
      }
      threeMaterial.needsUpdate = true
    })
  }

  unregisterMeshMaterials (mesh) {
    this.numUsedVariantsDirty = true
    const renderMaterial = this.meshMaterials.get(mesh)
    if (Array.isArray(renderMaterial)) {
      renderMaterial.forEach(rmat => {
        const set = this.materialMeshes.get(rmat)
        if (set) set.delete(mesh)
      })
    } else {
      const set = this.materialMeshes.get(renderMaterial)
      if (set) set.delete(mesh)
    }
  }

  getStats () {
    if (this.numUsedVariantsDirty) {
      // We update this stat lazily such that it doesn't affect performance while navigating
      const usedMaterials = new Set()
      this.materialMeshes.forEach(s => {
        s.forEach(mesh => {
          usedMaterials.add(mesh.material.uuid)
        })
      })
      this.numUsedVariants = usedMaterials.size
      this.numUsedVariantsDirty = false
    }
    return {
      inScene: this.numUsedVariants,
      variants: this.materialIndex.size,
      materials: this.materialCache.size
    }
  }

  expandBoxByTransformedGeometrySLOW (box, geometryId, matrix) {
    const geometry = this.geometries.get(geometryId)
    // this needs to match what box.expandByObject expects. Otherwise KABOOM
    const fakeMeshNode = {
      updateWorldMatrix: function () {
      },
      geometry: geometry,
      matrixWorld: matrix,
      children: []
    }

    box.expandByObject(fakeMeshNode)
  }

  updateThreeModelToIntermediateFormat (threeModel, contentBaseName) {
    const self = this
    const geometryUuidToAssetId = new Map()
    const materialUuidToAssetId = new Map()

    // NOTE: Geometries and materials are not disposed. Ownership is assumed to be taken over by AssetManager

    threeModel.traverse(function (o) {
      o.updateMatrix()
      if (o.geometry === undefined) return

      const key = o.geometry.uuid
      let id
      if (!geometryUuidToAssetId.has(key)) {
        o.geometry.name = contentBaseName + ((o.name !== undefined && o.name !== 'undefined') ? '_' + o.name : '')
        id = self.addGeometry(o.geometry)
        geometryUuidToAssetId.set(key, id)
      } else {
        id = geometryUuidToAssetId.get(key)
      }
      o.geometry = { id: id, boundingBox: o.geometry.boundingBox }

      if (o.material === undefined || o.material === null) {
        o.material = self.defaultMaterial
      } else {
        const materialAdder = (material) => {
          const key = material.uuid
          if (materialUuidToAssetId.has(key)) {
            return materialUuidToAssetId.get(key)
          }
          material.name = contentBaseName + (material.name !== undefined ? material.name : '_')
          const id = self.addMaterial(material)
          materialUuidToAssetId.set(key, id)
          return id
        }

        // NOTE: Here we do not support instancing between different gltf-files (not of material of geometries)
        if (Array.isArray(o.material)) {
          for (let i = 0; i < o.material.length; ++i) {
            const id = materialAdder(o.material[i])
            o.material[i] = id
          }
        } else {
          const id = materialAdder(o.material)
          o.material = id
        }
      }
    })

    threeModel.updateMatrixWorld()
    threeModel.contentBox = new THREE.Box3().setFromObject(threeModel)
  }

  loadGo3dformat (filePathsConfigObject) {
    const scope = this
    // NOTE: No caching is happening at all for these scenes
    return new Promise((resolve, reject) => {
      // go3dLoader loads a three-scene with custom materials and lights
      resolve(this.go3dLoader.load(filePathsConfigObject))
    }).then(model => {
      // Here all materials and geometries are added to the asset manager
      scope.updateThreeModelToIntermediateFormat(model)
      return model
    })
  }

  loadGo3dformat2 (uri, urlPrefixObject) {
    const scope = this
    // NOTE: No caching is happening at all for these scenes
    return this.go3dLoader2.load(uri, urlPrefixObject)
      .then(model => {
      // Here all materials and geometries are added to the asset manager
        scope.updateThreeModelToIntermediateFormat(model)
        return model
      })
  }

  loadModel (model) {
    const loaderFunction = url => {
      switch (url) {
        case '__test_plane':
          return Promise.resolve({
            scene: new THREE.Mesh(new THREE.PlaneBufferGeometry(20, 7, 1, 1))
          })
        case '__test_sphere':
          return Promise.resolve({
            scene: new THREE.Mesh(new THREE.SphereBufferGeometry(2, 25, 25))
          })
        case '__test_box':
          return Promise.resolve({
            scene: new THREE.Mesh(new THREE.BoxBufferGeometry(1, 1, 1))
          })
        case '__test_torus':
          return Promise.resolve({
            scene: new THREE.Mesh(new THREE.TorusKnotBufferGeometry(1 / 5, 0.4 / 5, 512, 64))
          })
        default:
          return this.geometryLoader.load(url, model.useModelMaterial)
      }
    }

    const cacheKey = model.url

    if (this.geometries.has(cacheKey)) {
      // NOTE: The thing we return can either be a model or a promise if it currently loading
      return Promise.resolve(this.geometries.get(cacheKey))
    } else {
      const p = loaderFunction(cacheKey).then(
        metaScene => {
          const model = metaScene.scene
          this.updateThreeModelToIntermediateFormat(model, cacheKey)
          this.geometries.set(cacheKey, model)
          return model
        },
        error => {
          // Clear the result from the cache on failure.
          if (this.geometries.has(cacheKey)) this.geometries.delete(cacheKey)
          throw error
        }
      )
      this.geometries.set(cacheKey, p)
      return p
    }
  }

  // Returns a RenderMaterial
  // Feel free to call multiple times. Actual material is cached and the returned material is just an accessor
  loadMaterial (materialJson) {
    const materialType = materialJson.materialType
    const materialId = materialJson.test ? '__test_' + materialType : materialJson.id
    // Remove this redundant promise.
    return Promise.resolve().then(() => {
      if (this.materialCache.has(materialId)) {
        // Read from cache
        return Promise.resolve(this.materialCache.get(materialId).clone())
      } else {
        // Actually load and mark in cache that load is in progress
        const p = new Promise(async (resolve, reject) => {
          if (materialJson.test) {
            let outMaterial
            if (materialType === 'triplanarMaterial') {
              outMaterial = new TriplanarMaterial()
            }
            this.materialCache.set(materialId, outMaterial)
            resolve(outMaterial)
          } else {
            try {
              const material = await this.materialLoader.loadMaterial(materialJson)
              this.materialCache.set(materialId, material)
              resolve(material.clone())
            } catch (error) {
              console.error(error)
              reject(error)
            }
          }
        })
        this.materialCache.set(materialId, p)
        return p
      }
    }).then(material => {
      return this.addMaterial(material, materialId)
    })
  }

  /*
  * For draco decompression to work, the draco library needs to be hosted along with go3d.
  * The library files can be found at https://github.com/mrdoob/three.js/tree/master/examples/js/libs/draco.
  * Hosting the library files will be the responsibility of the application using go3d for now.
  * For example, to enable draco for DPD, put the library files in /static/draco/, and provide "/draco/"
  * as the path to this function.
  */
  enableDracoDecoding (dracoPath) {
    this.geometryLoader.setDracoPath(dracoPath)
  }
}
