import 'basscss'
import '../css/style.css'

import navigate from './utils/navigate'

import * as actions from './stores/ducks/users'

import store from './stores'

import './changes'
import './visibility-register'

store.dispatch(actions.getCurrent())
  .then(() => store.dispatch(actions.getActiveUsers()))
  .then(() => {
    let components = require('./components').default

    const rootEl = document.getElementById('root')

    components.render(rootEl, store)

    navigate.emitter.on('navigate', () => {
      components.render(rootEl, store)
    })

    if (module.hot) {
      module.hot.accept('./components/index', () => {
        components = require('./components/index').default
        navigate.emitter.emit('navigate')
      })
    }
  })
