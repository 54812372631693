import _get from 'lodash/get'
import _orderBy from 'lodash/orderBy'
import _groupBy from 'lodash/groupBy'
import moment from 'moment'
import React from 'react'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import { DEADLINE_STATUS, PROJECT_TYPES } from '../../../../constants'
import * as fromCombinationsSelectors from '../../../../stores/ducks/combinations/selectors'
import * as fromProjectsSelectors from '../../../../stores/ducks/projects/selectors'
import * as fromRenders from '../../../../stores/ducks/renders'
import * as fromThreeviewerSelectors from '../../../../stores/ducks/threeviewer/selectors'
import * as fromThreeviewer from '../../../../stores/ducks/threeviewer/viewer'
import { getImageSrc } from '../../../../utils/storage'
import AutoFill from '../../../common/grid/AutoFill'
import { PanelSection, PanelSectionContent, PanelSectionHeader } from '../../../common/panels/panel-section'
import Pending from '../../../common/pending'
import { StorageApiThumbnail } from '../../../common/storage-api-image'
import GalleryModal from '../../../gallery/modal'
import ImageTemplateIcon from '../../../common/icons/ImageTemplateIcon'
import { EmptyStateLoadDesign, EmptyStateSaveDesign, EmptyStateCreateImage } from './empty-states'
import Pill from '../../../common/Pill'

const Render = props => {
  const isBatchRender = !!(props.combination.connectedBatchRenderCombinationIds || []).length
  return (
    <div
      data-testid='render'
      className='opacity-hover-lighter pointer'
      onClick={props.onPreview}
    >
      <div className='relative border bc-gray-light' data-testid={props.testid || 'renderedImage'} >
        {(props.render.rendered && props.render.deadlineStatus === DEADLINE_STATUS.COMPLETED) ? (
          <StorageApiThumbnail file={props.thumbnail} />
        ) : (
          <Pending
            {...props.combination}
            disableButtons
            deadlineStatus={props.deadlineStatus}
            renderStatus={props.render.renderStatus}
            renderPercentage={props.render.renderPercentage}
            status={props.render.status}
            hideTitle
            onCancel={props.cancel}
            backgroundImage={getImageSrc(props.thumbnail, { format: 'jpg', type: 'thumbnail' })}
          />
        )}
        {props.combination.imageTemplateId && (
          <div className='absolute' style={{ bottom: 6, right: 6 }}>
            <ImageTemplateIcon
              isBatchRender={isBatchRender}
              size={20}
              iconSize={18}
              stackStep={4}
              showStack={props.renders.length > 1 || isBatchRender}
            />
          </div>
        )}
      </div>

      <time className='mt1 f7 c-gray-accessible truncate block' dateTime={props.timestamp}>
        {props.renderedAt}
      </time>
    </div>
  )
}

function RendersPanel (props) {
  const imagesPerPage = 48
  const pages = Math.ceil(props.imageCombinations.filter(item => !item.batchParentId).length / imagesPerPage)

  const [imagesPage, setImagesPage] = React.useState(1)
  const [imagesArray, setImageArray] = React.useState()
  const [active, setActive] = React.useState({ id: null, renders: null })

  React.useEffect(() => {
    pagination(imagesPage, props.imageCombinations.filter(item => !item.batchParentId), imagesPerPage)
  }, [props.imageCombinations, imagesPage])

  function pagination (page, images, imagesPerPage) {
    setImagesPage(page)
    setImageArray(images.slice((page - 1) * imagesPerPage, (page * imagesPerPage)))
  }
  function openPreview (activeId) {
    if (!activeId) return
    const activeCombination = props.imageCombinations.find(c => c.id === activeId)
    if (!activeCombination) return
    if (activeCombination.connectedBatchRenderCombinationIds && activeCombination.connectedBatchRenderCombinationIds.length) {
      const connectedBatchCombinations = props.imageCombinations.filter(item => item.batchParentId === activeId)
      setActive({ id: activeId, renders: [activeCombination, ...connectedBatchCombinations] })
    } else {
      setActive({ id: activeId, renders: props.imageCombinations.filter(item => !item.batchParentId) })
    }
    props.disableKeyboardListeners()
  }

  function closePreview () {
    setActive({ id: null, renders: null })
    props.enableKeyboardListeners()
  }

  return (
    <PanelSection className='overflow-auto'>
      <PanelSectionHeader title='Images' />
      {!props.imageCombinations.length && !props.hasModel && (
        <EmptyStateLoadDesign />
      )}
      {!props.imageCombinations.length && props.hasModel && !props.currentCombinationId && (
        <EmptyStateSaveDesign />
      )}
      {(!props.imageCombinations.length && props.hasModel && props.currentCombinationId) && (
        <EmptyStateCreateImage />
      )}
      <PanelSectionContent>
        <AutoFill width={80}>
          {imagesArray && imagesArray.map((item) =>
            <Render
              key={item.id}
              combination={item}
              render={item.render}
              renderedAt={item.renderedAt}
              timestamp={item.timestamp}
              thumbnail={item.thumbnail}
              renders={item.renders}
              onPreview={() => openPreview(item.id)}
              onCancel={props.cancel}
              backgroundImage={getImageSrc(props.thumbnail, { format: 'jpg', type: 'thumbnail' })}
              testid={item.title}
            />
          )}
        </AutoFill>
      </PanelSectionContent>
      {pages > 1 &&
        <div className="flex flex-wrap justify-center p2">
          {[...Array(pages)].map((unused, i) =>
            <Pill style={{ margin: '2px' }} key={i} active={imagesPage === i + 1} onClick={() => { setImagesPage(i + 1) }}>{i + 1}</Pill>
          )}
        </div>}
      {active.id && (
        <GalleryModal
          backToText='Return to Visualizer View'
          onBack={closePreview}
          renderId={active.id}
          rendersInProject={active.renders}
          designCardTitle={props.designCardTitle}
        />
      )}
    </PanelSection>
  )
}

const mapStateToProps = createSelector(
  fromProjectsSelectors.getCombinations,
  (state) => state.renders.entries,
  fromThreeviewerSelectors.getHasCombinationModel,
  fromCombinationsSelectors.getCurrentId,
  (allCombinations, renders, hasModel, currentCombinationId) => {
    const rendersByCombinationId = _groupBy(renders, 'combinationId')

    const imageCombinations = []

    Object.values(allCombinations).forEach(combination => {
      if (combination.sentAssetId) return
      if (combination.removedAt) return
      if (combination.combinationType !== 'render') return
      const _renders = rendersByCombinationId[combination.id]
      if (!_renders || !_renders.length) return

      let render = _renders[0]
      if (_renders.length > 1) {
        const defaultRender = _renders.find(r => r.id === combination.defaultRenderId)
        if (defaultRender) render = defaultRender
      }
      const timestamp = Date.parse(render.renderedAt || render.createdAt)
      imageCombinations.push({
        ...combination,
        renders: _renders,
        timestamp,
        renderedAt: moment(timestamp).fromNow(),
        thumbnail: _get(render, 'manifest.files.0'),
        render: render
      })
    })

    const currentCombination = imageCombinations.find(c => c.id === currentCombinationId)

    return {
      imageCombinations: _orderBy(imageCombinations, ['createdAt'], ['desc']),
      projectType: PROJECT_TYPES.DEFAULT,
      hasModel,
      currentCombinationId,
      currentCombination
    }
  }
)

const mapDispatchToProps = (dispatch) => ({
  remove: (id) => dispatch(fromRenders.remove(id)),
  enableKeyboardListeners: () => dispatch(fromThreeviewer.enableKeyboardListeners()),
  disableKeyboardListeners: () => dispatch(fromThreeviewer.disableKeyboardListeners())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RendersPanel)
