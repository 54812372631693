import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import cs from 'classnames'

import * as fromProjects from '../../stores/ducks/projects'
import { getToken } from '../../stores/ducks/users'

import navigate from '../../utils/navigate'

import Modal from '../common/modal'
import Tabs from '../common/tabs/tabs'
import TabGroup from '../common/tabs/tab-group'

import StandardLayout from '../layout/standard-layout'

import ProjectList from './project-list'
import Button from '../common/button'
import Spinner from '../common/spinner'

import NewProjectForm from './new-project-form'

import EmptyState from '../common/empty-state/empty-state'
import { MdCreateNewFolder as IconNewProject } from 'react-icons/md'
import { useDashboardSelectors } from '../../stores/ducks/dashboard'

const EmptyStateData = {
  icon: <IconNewProject size={80} />,
  title: 'Collaborate in design projects',
  desc:
    'Create a project and share it, or see projects that have been shared with you in the Shared With Me tab. Projects contain designs based on 3D-models, and images of these designs.',
  docLink: 'projects.md',
  linkText: 'Read more about projects in the help section.',
  actionTitle: 'New project'
}

type Props = React.HTMLAttributes<HTMLElement> & {
  breadcrumbs: { id: number, title: string }[]
}

const Dashboard = ({ breadcrumbs }: Props) => {
  const dispatch = useDispatch()

  const {
    currentUserId,
    myProjects,
    sharedProjects,
    dashboardStatus,
    projectsTabs
  } = useDashboardSelectors()

  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    dispatch(fromProjects.getDashboard())
    if (window.location.hostname.startsWith('local')) {
      dispatch(getToken())
    }
  }, [])

  const handleCreate = (data: any) => {
    handleCloseModal()
    dispatch(fromProjects.create(data))
  }

  const handleRemove = (projectId: string) => {
    dispatch(fromProjects.remove(projectId))
  }

  const handleUnfollow = (projectId: string) => {
    dispatch(fromProjects.toggleShare(projectId, currentUserId))
  }

  const handleNavigate = (projectId: string, projectType: string) => {
    if (projectType === 'image-package') {
      navigate(`/image-package-project/${projectId}/show`)
    } else {
      navigate(`/project/${projectId}/show`)
    }
  }

  const handleEdit = (data: any) => {
    dispatch(fromProjects.update(data))
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  return (
    <StandardLayout
      breadcrumbs={breadcrumbs}
      subheaderContent={
        <div className="flex justify-end width-100">
          <Button data-testid="new-project-button" pad={1} btnType="primary" onClick={handleOpenModal}>
            New project
          </Button>
        </div>
      }
    >
      <Tabs className="width-100">
        {(active: string | number,
          onToggleActive: (id: string | number) => void,
          onToggleDefault: () => void
        ) => [
          <TabGroup
            key="tab-group"
            tabs={projectsTabs}
            onToggleActive={onToggleActive}
            onToggleDefault={onToggleDefault}
            active={active}
          />,
          <div key="tab-content" className="pt4">
            {dashboardStatus === 'FETCHING' ? (
              <div className="flex justify-center items-center width-100" >
                <Spinner />
              </div>
            ) : (<>
              <div className={cs({ hidden: active !== 0 })}>
                {myProjects.length > 0 && (
                  <ProjectList
                    ids={myProjects}
                    onEdit={handleEdit}
                    onOpen={handleNavigate}
                    onRemove={handleRemove}
                  />
                )}

                {myProjects.length === 0 && (
                  <EmptyState
                    className="mt4"
                    icon={EmptyStateData.icon}
                    title={EmptyStateData.title}
                    desc={EmptyStateData.desc}
                    docLink={EmptyStateData.docLink}
                    linkText={EmptyStateData.linkText}
                    onAction={handleOpenModal}
                    actionTitle={EmptyStateData.actionTitle}
                  />
                )}
              </div>

              <div className={cs({ hidden: active !== 1 })}>
                <ProjectList
                  shared
                  ids={sharedProjects}
                  onOpen={handleNavigate}
                  onRemove={handleUnfollow}
                />
              </div>
            </>)}
          </div>
        ]}
      </Tabs>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleCloseModal}
        shouldCloseOnOverlayClick
        width={600}
      >
        <NewProjectForm
          onSubmit={handleCreate}
          onCancel={handleCloseModal}
        />
      </Modal>
    </StandardLayout>
  )
}

export default Dashboard
