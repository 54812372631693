import Immutable from 'seamless-immutable'
import { createAction, handleActions } from 'redux-actions'
import * as fromThreeviewer from '../ui'
import _get from 'lodash/get'
import { Dispatch } from 'redux'

export const STATUS = {
  PENDING: 'PENDING',
  LOADING: 'LOADING',
  LOADED: 'LOADED',
  FAILED: 'FAILED',
  FINISHED: 'FINISHED'
}

const FINALIZE_TIMEOUT = 500

export type FileProgress = {
  finalize: boolean
  progress: number
  file: string
  type: string
}

export type Files = {
  id?: string
  name?: string
  uris: string[]
  type: string
}

export type FileEvent = {
  file: string
  type: string
}

export type FileError = {
  file: string
  error: any
}

export const dispose = createAction('threeviewer/files/DISPOSE')
export const disposeLoaded = createAction('threeviewer/files/DISPOSE_LOADED')
export const loadCombinations = createAction<string[]>('threeviewer/files/LOAD_COMBINATION')
export const confirmCombinationsLoaded = createAction<string[]>('threeviewer/files/COMBINATIONS_LOADED')

export const loadFile = createAction<Files>('threeviewer/files/LOAD_FILE')
export const fileError = createAction<FileError>('threeviewer/files/FAILED')

const confirmFileProgress = createAction<FileProgress>('threeviewer/files/PROGRESS')

const confirmLoad = createAction<FileEvent>('threeviewer/files/LOAD')
const confirmLoaded = createAction<FileEvent>('threeviewer/files/LOADED')
const finalize = createAction<{ file?: string }>('threeviewer/files/FINALIZE')

export const fileProgress = (payload: FileProgress) => (dispatch: Dispatch<any>) => {
  dispatch(confirmFileProgress(payload))
  if (_get(payload, 'finalize', true) && payload.progress === 100) {
    setTimeout(() => dispatch(finalize({ file: payload.file })), FINALIZE_TIMEOUT)
  }
}

export const loaded = (type: string, file: string) => (dispatch: Dispatch<any>) => {
  dispatch(confirmLoaded({ type, file }))
  dispatch(fromThreeviewer.forceUpdate())
  setTimeout(() => dispatch(finalize({ file })), FINALIZE_TIMEOUT)
}

export const load = (type: string, file: string) => (dispatch: Dispatch<any>) => dispatch(confirmLoad({ file, type }))

const initialState = Immutable<{
  progressError: boolean
  entries: { [file: string]: {
    status: string
    progress: number
    type: string
  }}
  combinationsInProgress: string[]
}>({
  progressError: false,
  entries: {},
  combinationsInProgress: []
})

type State = typeof initialState

export default handleActions<State, any>({
  [confirmLoad.toString()]: (state, { payload }: { payload: FileEvent }) => {
    return state
      .setIn(['entries', payload.file, 'status'], STATUS.PENDING)
      .setIn(['entries', payload.file, 'progress'], 0)
      .setIn(['entries', payload.file, 'type'], _get(state, ['entries', payload.file, 'type'], payload.type))
  },
  [loadFile.toString()]: (state, { payload }: { payload: Files }) => {
    const files = payload.uris.reduce((acc, file) => ({
      ...acc,
      [file]: {
        status: STATUS.PENDING,
        progress: 0,
        type: payload.type
      }
    }), {})

    return state.merge({ entries: state.entries.merge(files) }, { deep: true })
  },
  [confirmFileProgress.toString()]: (state, { payload }: { payload: FileProgress }) => {
    const lastStatus = _get(state, ['entries', payload.file as string, 'status'])
    let status: string = STATUS.LOADING

    if (payload.progress === 100) {
      status = lastStatus === STATUS.FAILED ? STATUS.FAILED : STATUS.LOADED
    }

    return state
      .setIn(['entries', payload.file, 'progress'], payload.progress)
      .setIn(['entries', payload.file, 'type'], _get(state, ['entries', payload.file as string, 'type'], payload.type))
      .setIn(['entries', payload.file, 'status'], status)
  },
  [confirmLoaded.toString()]: (state, { payload }: { payload: FileEvent }) => {
    const lastStatus = _get(state, ['entries', payload.file, 'status'])
    return state
      .setIn(['entries', payload.file, 'progress'], 100)
      .setIn(['entries', payload.file, 'type'], _get(state, ['entries', payload.file, 'type'], payload.type))
      .setIn(['entries', payload.file, 'status'], lastStatus === STATUS.FAILED ? STATUS.FAILED : STATUS.LOADED)
  },
  [fileError.toString()]: (state, { payload }: { payload: FileError }) => {
    return state
      .setIn(['progressError'], true)
      .setIn(['entries', payload.file, 'progress'], 100)
      .setIn(['entries', payload.file, 'error'], payload.error)
      .setIn(['entries', payload.file, 'status'], STATUS.FAILED)
  },
  [finalize.toString()]: (state, { payload }: { payload: { file: string }}) => {
    const lastStatus = _get(state, ['entries', payload.file, 'status'])
    return state
      .setIn(['entries', payload.file], _get(state, ['entries', payload.file], Immutable({})).merge({
        status: lastStatus === STATUS.FAILED ? STATUS.FAILED : STATUS.FINISHED
      }))
  },
  [loadCombinations.toString()]: (state, { payload }: { payload: string[] }) => {
    const combinationsInProgress = new Set(Immutable.asMutable<string>(state.combinationsInProgress))
    payload.forEach(combinationsInProgress.add, combinationsInProgress)
    return state.merge({ combinationsInProgress: Array.from(combinationsInProgress) })
  },
  [confirmCombinationsLoaded.toString()]: (state, { payload }: { payload: string[] }) => {
    const combinationsInProgress = new Set(Immutable.asMutable<string>(state.combinationsInProgress))
    payload.forEach(combinationsInProgress.delete, combinationsInProgress)
    return state.merge({ combinationsInProgress: Array.from(combinationsInProgress) })
  },
  [disposeLoaded.toString()]: state => state, // NOTE: This does nothing for some reason
  [dispose.toString()]: () => initialState
}, initialState)
