import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import IconMember from '../../common/icons/icon-user'

import * as fromProjects from '../../../stores/ducks/projects'
import * as fromProjectsSelectors from '../../../stores/ducks/projects/selectors'

import ShareToUserModal from '../share/ShareToUserModal'

type Props = {
  onCloseModal: () => void
  onOpenModal: () => void
  isModalOpen: boolean
}

export default function Share (props: Props) {
  const dispatch = useDispatch()
  const project = useSelector(fromProjectsSelectors.getCurrentEntry)

  return (
    <div className='width-100'>
      <div className='flex flex-wrap width-100'>
        <div data-testid='project-members-navItem' className='width-100' onClick={props.onOpenModal}>
          <div
            className='flex items-center bg-gray-blue-light-hover c-gray-lighter py1 pointer'
            style={{ paddingLeft: 20 }}
          >
            <IconMember /><div className='ml2'>Members</div>
          </div>
        </div>
        <ShareToUserModal
          isModalOpen={props.isModalOpen}
          handleCloseModal={props.onCloseModal}
          ownerOfObject={project.createdBy}
          handleShareWithUser={(userId: string) => dispatch(fromProjects.toggleShare(project.id, userId))}
          modalType='PROJECT'
        />
      </div>
    </div>
  )
}
