
module.exports = function (annotations, canvasWidth, canvasHeight, safeFrameWidth, safeFrameHeight, targetWidth, targetHeight) {
  const namespaceURI = 'http://www.w3.org/2000/svg'

  const annotationColor = '#000000'
  const annotationDataList = getAnnotationDataList(annotations, canvasWidth, canvasHeight)
  const svg = document.createElementNS(namespaceURI, 'svg')
  svg.version = '1.2'
  svg.setAttribute('width', targetWidth)
  svg.setAttribute('height', targetHeight)

  // in pixels
  const deltaX = ((canvasWidth - safeFrameWidth) / 2) * (targetWidth / safeFrameWidth)
  const deltaY = ((canvasHeight - safeFrameHeight) / 2) * (targetHeight / safeFrameHeight)

  // modifiedRatio
  const scaleX = canvasWidth / safeFrameWidth
  const scaleY = canvasHeight / safeFrameHeight

  const scaledWidth = targetWidth * scaleX
  const scaledHeight = targetHeight * scaleY
  const elementScaleFactor = Math.max(((scaledWidth + scaledHeight) / 2) / ((canvasWidth + canvasHeight) / 2), 1)

  annotationDataList.forEach(a => {
    const line = document.createElementNS(namespaceURI, 'line')
    line.setAttribute('x1', a.annotationAttachX * scaledWidth - deltaX)
    line.setAttribute('y1', a.annotationAttachY * scaledHeight - deltaY)
    line.setAttribute('x2', a.anchorPosition.x * scaledWidth - deltaX)
    line.setAttribute('y2', a.anchorPosition.y * scaledHeight - deltaY)
    line.setAttribute('stroke', annotationColor)
    line.setAttribute('stroke-width', 5 * elementScaleFactor)
    svg.appendChild(line)

    a.markers.forEach(m => {
      const line = document.createElementNS(namespaceURI, 'line')
      line.setAttribute('x1', m.x * scaledWidth - deltaX)
      line.setAttribute('y1', m.y * scaledHeight - deltaY)
      line.setAttribute('x2', a.anchorPosition.x * scaledWidth - deltaX)
      line.setAttribute('y2', a.anchorPosition.y * scaledHeight - deltaY)
      line.setAttribute('stroke', annotationColor)
      line.setAttribute('stroke-width', 5 * elementScaleFactor)
      svg.appendChild(line)

      const circle = document.createElementNS(namespaceURI, 'circle')
      circle.setAttribute('cx', m.x * scaledWidth - deltaX)
      circle.setAttribute('cy', m.y * scaledHeight - deltaY)
      circle.setAttribute('r', 10 * elementScaleFactor)
      circle.setAttribute('fill', annotationColor)
      svg.appendChild(circle)

      const innerCircle = document.createElementNS(namespaceURI, 'circle')
      innerCircle.setAttribute('cx', m.x * scaledWidth - deltaX)
      innerCircle.setAttribute('cy', m.y * scaledHeight - deltaY)
      innerCircle.setAttribute('r', 5 * elementScaleFactor)
      innerCircle.setAttribute('fill', '#fff')
      svg.appendChild(innerCircle)
    })
  })

  // show text boxes on top
  annotationDataList.forEach(a => {
    const rect = document.createElementNS(namespaceURI, 'rect')
    rect.setAttribute('x', a.box.position.x * scaledWidth - deltaX)
    rect.setAttribute('y', a.box.position.y * scaledHeight - deltaY)
    rect.setAttribute('rx', a.box.width * scaledWidth / 20)
    rect.setAttribute('ry', a.box.width * scaledWidth / 20)
    rect.setAttribute('width', a.box.width * scaledWidth)
    rect.setAttribute('height', a.box.height * scaledHeight)
    rect.setAttribute('fill', annotationColor)
    rect.setAttribute('overflow-wrap', 'break-word')
    rect.setAttribute('opacity', 0.8)
    svg.appendChild(rect)

    // draw text last to make sure it's visible
    var offsetX = 15 * elementScaleFactor
    var offsetY = 30 * elementScaleFactor
    addTextToSvg(a.box.text, {
      x: a.box.position.x * scaledWidth - deltaX + offsetX,
      y: a.box.position.y * scaledHeight - deltaY + offsetY
    }, svg, elementScaleFactor)
  })

  return new window.XMLSerializer().serializeToString(svg)
}

function addTextToSvg (text, position, svg, textScaleFactor) {
  const maxCharsPerRow = 18
  const ySpacing = 20 * textScaleFactor
  const namespaceURI = 'http://www.w3.org/2000/svg'
  const words = text.split(' ')

  let rowWord = ''
  let totalYSpacing = 0
  var rows = []
  const fontSize = 12

  words.forEach((word, index) => {
    const wordLen = word.length + 1

    if (wordLen + rowWord.length > maxCharsPerRow) {
      var row = document.createElementNS(namespaceURI, 'text')
      row.setAttribute('x', position.x)
      row.setAttribute('y', position.y + totalYSpacing)
      row.setAttribute('fill', '#fff')
      row.style.fontSize = `${fontSize * textScaleFactor}px`
      row.style.fontFamily = 'Verdana'
      row.textContent = rowWord
      rows.push(row)
      totalYSpacing += ySpacing
      rowWord = word + ' '
    } else {
      rowWord += word + ' '
    }
    if (index === words.length - 1) {
      const row = document.createElementNS(namespaceURI, 'text')
      row.setAttribute('x', position.x)
      row.setAttribute('y', position.y + totalYSpacing)
      row.setAttribute('fill', '#fff')
      row.style.fontSize = `${fontSize * textScaleFactor}px`
      row.style.fontFamily = 'Verdana'
      row.textContent = rowWord
      rows.push(row)
    }
  })

  rows.forEach((row) => {
    svg.appendChild(row)
  })
}

function AnnotationData () {
  this.position = { x: undefined, y: undefined }
  this.anchorPosition = { x: undefined, y: undefined }
  this.box = {
    text: undefined,
    position: { x: undefined, y: undefined },
    width: undefined,
    height: undefined
  }
  this.markers = []
}

function getAnnotationDataList (annotations, width, height) {
  const annotationDataList = []

  function ndc (value) {
    return value * 0.5 + 0.5
  }

  annotations.forEach(annotation => {
    if (!annotation.active || !annotation.element.isActive()) {
      return
    }

    const annotationData = new AnnotationData()
    annotationData.position.x = ndc(annotation.positionX)
    annotationData.position.y = ndc(-annotation.positionY)
    annotationData.annotationAttachX = ndc(annotation.annotationAttachX)
    annotationData.annotationAttachY = ndc(-annotation.annotationAttachY)

    annotationData.anchorPosition.x = ndc(annotation.anchorX)
    annotationData.anchorPosition.y = ndc(-annotation.anchorY)
    annotationData.box.text = annotation.annotationText
    annotationData.box.position.x = annotation.element.getPixelPosition().x / width
    annotationData.box.position.y = annotation.element.getPixelPosition().y / height
    annotationData.box.width = annotation.element.getPixelWidth() / width
    annotationData.box.height = annotation.element.getPixelHeight() / height

    if (annotation.closestMarkerPosition) {
      annotationData.markers.push({
        x: ndc(annotation.closestMarkerPosition.x),
        y: ndc(-annotation.closestMarkerPosition.y)
      })
    } else {
      annotationData.markers = annotation.markers
        .filter(marker => marker && marker.visible && marker.active)
        .map(marker => ({
          x: ndc(marker.positionX),
          y: ndc(-marker.positionY)
        }))
    }
    annotationDataList.push(annotationData)
  })

  return annotationDataList
}
