import React, { PureComponent } from 'react'

import InputRange from 'react-input-range'

import css from './index.css'

const inputRangeClassNames = {
  activeTrack: `${css.inputRangeTrack} ${css.inputRangeTrackActive}`,
  disabledInputRange: `${css.inputRange} ${css.inputRangeDisabled}`,
  inputRange: `${css.inputRange}`,
  labelContainer: `${css.inputRangeLabelContainer}`,
  maxLabel: `${css.inputRangeLabel} ${css.inputRangeLabelMax}`,
  minLabel: `${css.inputRangeLabel} ${css.inputRangeLabelMin}`,
  slider: `${css.inputRangeSlider}`,
  sliderContainer: `${css.inputRangeSliderContainer}`,
  track: `${css.inputRangeTrack} ${css.inputRangeTrackBackground}`,
  valueLabel: `${css.inputRangeLabel} ${css.inputRangeLabelValue}`
}

class InputRangeDouble extends PureComponent {
  render () {
    return (
      <InputRange
        classNames={inputRangeClassNames}
        {...this.props}
      />
    )
  }
}

export default InputRangeDouble
