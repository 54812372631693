const THREE = require('three')

function DebugWindow (app, scene, stats, onClose = x => x) {
  this.mounted = false
  this.app = app
  this.scene = scene
  this.stats = stats
  this.width = app.width
  this.height = app.height
  this.styles = document.createElement('style')
  this.element = document.createElement('div')
  this.closeButton = document.createElement('button')
  this.closeButton.innerHTML = 'Stop debugging'
  this.styles.innerHTML = `
    .debug-window {
      position: absolute;
      left: 10px;
      bottom: 10px;
      width: 245px;
      color: white;
      background-color: #000;
      font-size: 12px;
      padding: 8px;
      font: 11px 'Lucida Grande',sans-serif;
      z-index: 100000;
    }

    .debug-window > * {
      margin-bottom: 4px;
    }

    .debug-window-section:not(:last-child) {
      margin-bottom: 12px;
    }
  `
  this.element.className = 'debug-window'

  this.closeButton.onclick = () => {
    onClose()
  }

  // this.unmount = this.unmount.bind(this)
  this.mount = this.mount.bind(this)
  this.update = this.update.bind(this)
}

DebugWindow.prototype.mount = function () {
  if (!this.mounted) {
    document.body.appendChild(this.styles)
    document.body.appendChild(this.element)
  }
  this.mounted = true
}

DebugWindow.prototype.unmount = function () {
  if (this.mounted) {
    document.body.removeChild(this.styles)
    document.body.removeChild(this.element)
  }
}

DebugWindow.prototype.update = function () {
  let numberOfMeshes = 0
  let numberOfLights = 0

  this.app.renderScene.scene.traverse((node) => {
    if (node instanceof THREE.Mesh) { numberOfMeshes++ }
    if (node.isLight) { numberOfLights++ }
  })

  const scaledRes = this.app.postProcessManager.scalingActive()
    ? `${this.app.postProcessManager.getScaledWidth()} x ${this.app.postProcessManager.getScaledHeight()}`
    : 'Inactive'

  const materialStats = this.app.assetManager.getStats()

  this.element.innerHTML = `
    <div class="debug-window-section">
      <div><strong>WebGL Render Stats:</strong></div>
      <div>Scene Resolution: <strong>${this.app.postProcessManager.width} x ${this.app.postProcessManager.height}</strong></div>
      <div>Scaled Resolution: <strong>${scaledRes}</strong></div>
      <div>Frame rate (fps): <strong>${this.app.postProcessManager.frameRate.toFixed(2)}</strong></div>
      <div>Frame time (ms): <strong>${this.app.postProcessManager.frameMs.toFixed(2)}</strong></div>
    </div>
    <div class="debug-window-section">
      <div><strong>Materials:</strong></div>
      <div>Total Material Count: <strong>${materialStats.materials}</strong></div>
      <div>Total Material Variants Count: <strong>${materialStats.variants}</strong></div>
      <div>Active Material Variants: <strong>${materialStats.inScene}</strong></div>
      <div>Program Count: <strong>${this.stats.programCount}</strong></div>
      <div>Texture Count: <strong>${this.stats.textureCount}</strong></div>
    </div>
    <div class="debug-window-section">
      <div><strong>Render (Call/Tri/Line/Point):</strong></div>
      <div>Main: <strong>${this.stats.render.drawCalls} / ${this.stats.render.triangleCount} / ${this.stats.render.lineCount} / ${this.stats.render.pointCount}</strong></div>
      <div>Overlay: <strong>${this.stats.overlay.drawCalls} / ${this.stats.overlay.triangleCount} / ${this.stats.overlay.lineCount} / ${this.stats.overlay.pointCount}</strong></div>
      <div>PostProcess: <strong>${this.stats.postProcess.drawCalls} / ${this.stats.postProcess.triangleCount} / ${this.stats.postProcess.lineCount} / ${this.stats.postProcess.pointCount}</strong></div>
    </div>
    <div class="debug-window-section">
      <div><strong>Scene information:</strong></div>
      <div>Number of meshes in the scene: <strong>${numberOfMeshes}</strong></div>
      <div>Number of lights in the scene: <strong>${numberOfLights}</strong></div>
      <div>Geometry Count: <strong>${this.stats.geometryCount}</strong></div>
    </div>
  `

  this.element.appendChild(this.closeButton)
}

module.exports = DebugWindow
