import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import * as fromVisualizerSelectors from '../visualizer-selectors'
import * as fromThreeviewerSelectors from '../../../stores/ducks/threeviewer/selectors'
import * as fromThreeviewerFilesSelectors from '../../../stores/ducks/threeviewer/files/selectors'

import ToolbarButton from './toolbar-button'
import PatternSettings from './subtoolbar-pattern'
import SubToolbar from './subtoolbar'

import { PROJECT_TYPES } from '../../../constants'

import KeyboardListener, {
  KeyCode,
  KeyBinding
} from '../../common/keyboard-listener'

import IconPattern from '../../common/icons/icon-pattern'

const propTypes = {
  isMaster: PropTypes.bool,
  hasValidPatternSelection: PropTypes.bool,
  keyBoardBindingsEnabled: PropTypes.bool,
  isSceneLoaded: PropTypes.bool
}

const mapStateToProps = (state) => {
  return {
    hasValidPatternSelection: fromVisualizerSelectors.getHasPattern(state),
    keyBoardBindingsEnabled: fromThreeviewerSelectors.getKeyBoardBindingsEnabled(state),
    isSceneLoaded: fromThreeviewerFilesSelectors.getIsSceneLoaded(state)
  }
}

function ToolbarImagePackage (props) {
  const [open, setOpen] = React.useState(false)

  React.useEffect(() => {
    if (!props.isMaster) {
      setOpen(true)
    }
  }, [])

  return (
    <React.Fragment>
      <KeyboardListener
        disabled={!props.keyBoardBindingsEnabled}
        bindings={[KeyBinding(KeyCode.esc, () => setOpen(false))]}
      />
      <div
        className="flex justify-center items-center height-100"
      >
        <ToolbarButton
          title='Pattern Adjustments'
          icon={<IconPattern width={20} height={20} />}
          active={true}
          disabled={
            !props.isSceneLoaded ||
            !props.hasValidPatternSelection
          }
          onClick={() => {
            setOpen(!open)
          }}
        />

      </div>
      <SubToolbar open={open} className="flex justify-around p1 c-white bg-black-70 userselect-text">
        <PatternSettings projectType={PROJECT_TYPES.IMAGE_PACKAGE} />
      </SubToolbar>
    </React.Fragment>
  )
}

ToolbarImagePackage.propTypes = propTypes
export default connect(mapStateToProps)(ToolbarImagePackage)
