import React, { Component } from 'react'
import PropTypes from 'prop-types'

import autoBind from 'react-autobind'

import Form from './form'
import InputGroup from './input-group'
import InputText from './input-text'
import Label from './label'
import Button from '../button'

import KeyboardListener, { KeyBinding, KeyCode } from '../keyboard-listener'

class FormEdit extends Component {
  constructor (props, context) {
    super(props, context)
    this.state = {}
    autoBind(this)
  }

  handleConfirm (event) {
    event.preventDefault()
    const title = this._title.root.value.trim()
    const updateObject = { title }
    this.props.onSubmit(updateObject)
  }

  focusFirstInput () {
    this._title.root.focus()
  }

  render () {
    const {
      formTitle,
      title,
      onCancel
    } = this.props

    return (
      <div>
        <h2 className='mt0'>
          {formTitle}
        </h2>
        <Form>
          <InputGroup>
            <Label>
              Title
            </Label>
            <InputText
              ref={(node) => (this._title = node)}
              defaultValue={title}
              placeholder='Title'
            />
          </InputGroup>

          <div className='mt2 clearfix flex justify-end items-baseline'>
            <Button className='mr2' onClick={onCancel}>Cancel</Button>
            <Button btnType='primary' onClick={this.handleConfirm}>
              Save
            </Button>
          </div>

          <KeyboardListener
            bindings={[
              KeyBinding(KeyCode.enter, this.handleConfirm)
            ]}
          />
        </Form>
      </div>
    )
  }
}

FormEdit.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string
}

FormEdit.defaultProps = {}

export default FormEdit
