import React from 'react'
import styled from 'styled-components'

type Props = React.HTMLAttributes<HTMLElement> & {
  src: string
  constrainRatio?: [number, number]
}

const ImageConstrainedRatio = styled.div<Props & { constrainRatio: [number, number] }>`
  width: 100%;
  background-image: ${(props) => `url(${props.src})`};
  background-size: cover;
  background-position: center center;
  padding-bottom: ${(props) => (props.constrainRatio[0] / props.constrainRatio[1] || 1) * 100}%;
`

const ImageResponsive = styled.img.attrs(props => ({
  className: `img-responsive ${props.className}`
}))``

function Image (props: Props) {
  if (props.constrainRatio) {
    return (
      <ImageConstrainedRatio
        onClick={props.onClick}
        src={props.src}
        style={props.style}
        className={props.className}
        constrainRatio={props.constrainRatio}
      />
    )
  }

  return (
    <ImageResponsive
      onClick={props.onClick}
      src={props.src}
      style={props.style}
      className={props.className}
      data-testid='img-preview'
    />
  )
}

export default Image
