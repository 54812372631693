import React from 'react'
import PropTypes from 'prop-types'

import colors from '../../../css/colors'

const clamp = (val, min, max) => Math.min(Math.max(min, val), max)
const getDashoffset = (percentage, circumference) => ((clamp(percentage, 0, 100) * 0.01) - 1) * circumference

const ProgressRadial = ({
  percentage,
  background,
  strokeWidth,
  hideLabel,
  animate,
  className,
  children
}) => {
  const size = 100
  const center = size * 0.5
  const radius = center - (strokeWidth * 0.55)
  const circumference = Math.PI * (radius * 2)

  return (
    <div
      className={`relative height-100 ${className}`}
      data-testid='progress-radial'
    >
      <svg width='100%' height='100%' className={animate ? 'animate-spin' : ''} viewBox={`0 0 ${size} ${size}`}>
        <circle
          style={{ transition: 'stroke-dashoffset 50ms linear' }}
          cx={center}
          cy={center}
          r={radius}
          stroke={colors.todo_f7f7f7}
          strokeWidth={strokeWidth - 1}
          fill='none'
        />
        <circle
          style={{ transition: 'stroke-dashoffset 50ms linear' }}
          cx={center}
          cy={center}
          r={radius}
          stroke={background}
          strokeDasharray={circumference}
          strokeDashoffset={-getDashoffset(percentage, circumference)}
          strokeWidth={strokeWidth}
          transform={`rotate(-90 ${center} ${center})`}
          fill='none'
        />
      </svg>

      {!hideLabel && (
        <div className='absolute center top-50 left-50' style={{
          transform: 'translate(-50%, -55%)'
        }}>
          {children || `${percentage.toFixed()}%`}
        </div>
      )}
    </div>
  )
}

ProgressRadial.propTypes = {
  percentage: PropTypes.number,
  background: PropTypes.string,
  strokeWidth: PropTypes.number,
  animate: PropTypes.bool,
  hideLabel: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.any
}

ProgressRadial.defaultProps = {
  animate: false,
  percentage: 100,
  hideLabel: false,
  background: colors.success,
  strokeWidth: 20,
  className: ''
}

export default ProgressRadial
