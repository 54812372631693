import store from '../../stores'

import * as fromUsers from '../../stores/ducks/users'
import * as fromUsersSelectors from '../../stores/ducks/users/selectors'

export default function handleUsers (msg) {
  const user = fromUsersSelectors.getById(msg.doc.id)(store.getState())

  if (!user) {
    return store.dispatch(fromUsers.getUsersByIds([msg.doc]))
  }

  store.dispatch(fromUsers.receive([msg.doc]))
}
