import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import type { ImmutableArray } from 'seamless-immutable'
import _xor from 'lodash/xor'

import * as fromVirtualProducts from '../../../stores/ducks/virtual-products'
import { useDebounce } from '../../../hooks/useDebounce'
import { RootState } from '../../../stores/ducks'
import { FetchStatus } from '../../../stores/ducks/virtual-products'

import AutoFill from '../../common/grid/AutoFill'
import ConfirmSelection from '../../designs/confirm-selection'
import EmptyState from '../../common/empty-state/empty-state'
import Design from '../../designs/design'
import Grid from '../../common/grid/grid'
import IconCube from '../../common/icons/icon-cube'
import Info from '../../common/info'
import InputSearch from '../../common/form/input-search'
import Label from '../../common/form/label'
import LoadMore from '../../visualizer/panels/material-and-color/material-search/load-more'

import { parseConnectVirtualProductItem } from './parse'
import type { VirtualProductItem } from './parse'

function InitialLoaderComponent () {
  return (
    <div className='height-50 flex justify-center' >
      <Info icon='block-before icon-spin1 animate-spin my2' />
    </div>
  )
}
interface OwnProps {
  projectId: string
  onClose: () => void
}

function ConnectVirtualProductModal (props: OwnProps) {
  const dispatch = useDispatch()
  const searchResult = useSelector((state: RootState) => state.virtualProducts.searchResult)
  const searchStatus = useSelector((state: RootState) => state.virtualProducts.searchStatus)

  const [queryString, setQueryString] = React.useState<string>('')
  const [selected, setSelected] = React.useState<string[]>([])
  const [parsedData, setParsedData] = React.useState<null | ImmutableArray<VirtualProductItem>>(null)

  const [page, setPage] = React.useState(1)
  const [isMounted, setIsMounted] = React.useState(false)
  const debouncedQueryString = useDebounce(queryString, 300)

  function runSearch () {
    dispatch(fromVirtualProducts.getSearchResults(debouncedQueryString, page, props.projectId))
  }

  React.useEffect(() => {
    setIsMounted(true)
    runSearch()
  }, [])

  React.useEffect(() => {
    if (isMounted) runSearch()
  }, [debouncedQueryString, isMounted])

  React.useEffect(() => {
    if (searchResult && searchResult.length) {
      setParsedData(searchResult.map(parseConnectVirtualProductItem))
    }
  }, [searchResult])

  const showEmptyState = searchStatus !== FetchStatus.fetching && (searchResult || []).length === 0

  const fetching = (searchStatus === FetchStatus.fetching)

  const selectionCount = Object.keys(selected || []).length

  return (
    <Grid
      className='height-100 width-100'
      areas={[
        'empty tabs',
        'filters result'
      ]}
      rows={['auto', '1fr']}
      columns={['1fr', '4fr']}
      gridGap={0}
    >
      <div style={{ gridArea: 'empty' }} />
      <div
        className='pr2 overflow-y-auto'
        style={{ gridArea: 'filters' }}
      >
        <Label>Search</Label>
        <InputSearch
          placeholder='Search...'
          onChange={(value: string) => setQueryString(value.trim())}
          onClearSearch={() => setQueryString('')}
        />
      </div>

      <div style={{ gridArea: 'tabs' }} className='py2' />

      <Grid
        style={{ gridArea: 'result' }}
        rows={['1fr', 41]}
      >
        <div className='relative overflow-hidden bg-gray-light height-100'>
          {((searchStatus === FetchStatus.idle) || fetching) && <InitialLoaderComponent />}

          {!fetching && (searchResult || []).length > 0 && (
            <LoadMore
              className='overflow-x-hidden overflow-y-auto custom-scrollbar absolute top-0 right-0 bottom-0 left-0 p2 height-100'
              onLoadMore={() => setPage(page + 1)}
            >
              <AutoFill width={120} >
                {parsedData && parsedData.map((item, index) => (
                  <Design
                    {...item}
                    project={''}
                    size={'small'}
                    key={`${item.id}_${index}`}
                    onSelect={() => setSelected(_xor(selected, [item.id]))}
                    isSelected={selected.includes(item.id)}
                    shared={false}
                  />
                ))}
              </AutoFill>
            </LoadMore>
          )}

          {showEmptyState && (
            <EmptyState
              className='mt4 mt4'
              title='No virtual products found'
              icon={<IconCube size={80} />}
              desc='Try changing the search query.'
            />
          )}

        </div>

        <div className='flex justify-end'>
          <ConfirmSelection
            onClose={props.onClose}
            onConfirm={() => {
              dispatch(fromVirtualProducts.connectVirtualProduct(props.projectId, selected))
              props.onClose()
            }}
            type='connect'
            selected={selected}
          >
            {selectionCount > 0 &&
          <p className='pr2'>
            <span>{selectionCount}</span> selected
          </p>
            }
          </ConfirmSelection>
        </div>
      </Grid>
    </ Grid>
  )
}

export default ConnectVirtualProductModal
