import React from 'react'

// common
import { PanelSectionHeader } from '../../../../common/panels/panel-section'
import InputSearch from '../../../../common/form/input-search'
import Label from '../../../../common/form/label'

// internal
import CarrierList from './CarrierList'

function Search () {
  const [searchString, setSearchString] = React.useState<string>('')

  return (
    <div className='pt2 flex flex-column'>
      <PanelSectionHeader
        title='Materials'
      />
      <div className='relative px2 flex flex-column height-100'>
        <Label style={{ marginBottom: 4, marginTop: 12 }}>Search</Label>
        <InputSearch
          onChange={setSearchString}
          value={searchString}
          onClearSearch={() => { setSearchString('') }}
          placeholder='Search...'
          style={{ marginBottom: 20 }}
        />
        <CarrierList search={searchString} />
      </div>
    </div>
  )
}

export default Search
