import { SceneGraphMesh, SceneGraphMeshGeometry, SceneGraphNode3d } from '../scenegraph/SceneGraph'
import * as THREE from 'three'
import { EventEmitter } from 'events'
import { BufferGeometryUtils } from 'three/examples/jsm/utils/BufferGeometryUtils'
import { quaternionFromVec3, vec3FromQuaternion } from '../math'
import _isFinite from 'lodash/isFinite'
import type { SceneGraphMesh as TypeSceneGraphMesh, SceneGraphNode3d as TypeSceneGraphNode3d } from '../../types/SceneGraph'

function isValidNumber (value: number) {
  return _isFinite(Number(value))
}

function isValidVector3 (vector: THREE.Vector3 | { x: number, y: number, z: number }) {
  return vector && isValidNumber(vector.x) && isValidNumber(vector.y) && isValidNumber(vector.z)
}

export default class TriplanarTool extends EventEmitter {
  private _app: any
  enabled = false
  source: null | TypeSceneGraphMesh = null
  _orientationDummy: null | SceneGraphNode3d = null
  _widget: null | THREE.Object3D = null
  _widgetArrowBox: null | THREE.Mesh = null

  _centerLocal = new THREE.Vector3()
  _centerWorld = new THREE.Vector3()
  _invSourceRotation = new THREE.Quaternion(0, 0, 0)

  constructor (app: any) {
    super()
    this._app = app
    this.enabled = false

    this._app.triplanarTransformGizmo.on('moved', this.setTriplanarOrientationFromDummy)
    this._app.triplanarTransformGizmo.disable()
  }

  enable () {
    if (!this.enabled) {
      this.enabled = true
      this._app.picker.disableMulti = true
      this._app.triplanarTransformGizmo.enable()

      if (this.source) {
        this._initTriplanar(this.source)

        const rootNode = this._app.viewerUtils.findRootNode(this.source)

        rootNode.traverse((node: TypeSceneGraphNode3d) => {
          if (
            node.material &&
            node.material.useTriplanar &&
            node.userData.materialId === this.source!.userData.materialId
          ) {
            node.material.showDiffuseOnly = true
          }
        })
      }
    }
  }

  disable () {
    if (this.enabled) {
      this.enabled = false
      this._widget && (this._widget.visible = false)
      this._app.picker.disableMulti = false
      this._app.triplanarTransformGizmo.disable()
      this._app.picker.clearOverrideHandler()
      this._app.picker.selection = {}
      this.source && (this.source.outline = false)
      this.source = null

      this._app.scene.traverse((node: TypeSceneGraphNode3d) => {
        if (node.material && node.material.useTriplanar) {
          node.material.showDiffuseOnly = false
        }
      })
    }
  }

  dispose () {
    if (this._widget && this._widget.parent) this._widget.parent.remove(this._widget)
    this._widget = null
    this._widgetArrowBox = null
    this._orientationDummy = null
    this.source && (this.source.outline = false)
    this.source = null

    this._app.triplanarTransformGizmo.removeListener('moved', this.setTriplanarOrientationFromDummy)
  }

  select (source: TypeSceneGraphMesh) {
    if (!source) return

    if (this.source && this.source !== source) {
      this.source.outline = false
    }

    if (this.enabled) {
      this._initTriplanar(source)
    } else {
      this.source = source
    }
  }

  setOrientation (orientation: THREE.Vector3, node: TypeSceneGraphMesh) {
    if (!node || !orientation || !isValidVector3(orientation)) return
    const materialClone = node.material.clone()
    materialClone.triplanarOrientation = orientation
    materialClone.triplanarOrientation = orientation
    node.material = materialClone
    if (this.enabled) {
      this._initTriplanar(node)
    }
  }

  setTriplanarOrientationFromDummy = () => {
    if (!this.enabled) return
    if (this._orientationDummy && this.source) {
      const source = this.source
      const rotation = new THREE.Quaternion().setFromEuler(this._orientationDummy.rotation)
      const rotationDelta = this._invSourceRotation.clone().multiply(rotation)
      const triplanarOrientation = vec3FromQuaternion(rotationDelta)
      if (this._widget) this._widget.rotation.copy(this._orientationDummy.rotation)
      const materialClone = source.material.clone()
      materialClone.triplanarOrientation = triplanarOrientation
      materialClone.triplanarOrientation = triplanarOrientation
      source.material = materialClone
    }
  }

  getTranslation () {
    const source = this.source
    if (source) {
      return source.material.triplanarTranslation
    }
  }

  setTranslationOnAxis (value: number, axis: 'x' | 'y' | 'z') {
    const source = this.source
    if (source && isValidNumber(value)) {
      const materialClone = source.material.clone()
      const translation = materialClone.triplanarTranslation.clone()
      if (axis === 'x') translation.setX(value)
      if (axis === 'y') translation.setY(value)
      if (axis === 'z') translation.setZ(value)
      materialClone.triplanarTranslation = translation
      source.material = materialClone
    }
  }

  setTriplanarTranslation (value: THREE.Vector3, node: TypeSceneGraphMesh) {
    if (isValidVector3(value)) {
      const materialClone = node.material.clone()
      const translation = materialClone.triplanarTranslation.clone()
      translation.set(value.x, value.y, value.z)
      materialClone.triplanarTranslation = translation
      materialClone.triplanarTranslation = translation
      node.material = materialClone
    }
  }

  getMapRotation () {
    const source = this.source
    if (source) {
      return new THREE.Vector3(
        source.material.mapRotationX,
        source.material.mapRotationY,
        source.material.mapRotationZ
      )
    }
    return new THREE.Vector3()
  }

  setMapRotation (value: { x: number, y: number, z: number }, node: TypeSceneGraphMesh) {
    if (!isValidVector3(value)) return
    const materialClone = node.material.clone()
    materialClone.setMapRotationXYZ(value.x, value.y, value.z)
    node.material = materialClone
    node.userData.mapRotation = [value.x, value.y, value.z]

    if (this._widgetArrowBox && this._widgetArrowBox.parent) {
      this._widgetArrowBox.parent.remove(this._widgetArrowBox)
    }

    if (this._widget) {
      this._widgetArrowBox = createArrowBox(this.getMapRotation())
      this._widget.add(this._widgetArrowBox)
    }
  }

  rotateMap90DegreesOnAxis (axis: 'x' | 'y' | 'z') {
    if (!this.source || !this.source.material) return

    const rotateDeg = 90
    const materialClone = this.source.material.clone()

    if (axis === 'x') {
      materialClone.mapRotationX = rotate(this.source.material.mapRotationX, rotateDeg)
    }
    if (axis === 'y') {
      materialClone.mapRotationY = rotate(this.source.material.mapRotationY, rotateDeg)
    }
    if (axis === 'z') {
      materialClone.mapRotationZ = rotate(this.source.material.mapRotationZ, rotateDeg)
    }

    this.source.material = materialClone

    this.source.userData.mapRotation = [
      this.source.material.mapRotationX,
      this.source.material.mapRotationY,
      this.source.material.mapRotationZ
    ]

    if (this._widgetArrowBox && this._widgetArrowBox.parent) {
      this._widgetArrowBox.parent.remove(this._widgetArrowBox)
    }

    if (this._widget) {
      this._widgetArrowBox = createArrowBox(this.getMapRotation())
      this._widget.add(this._widgetArrowBox)
    }
  }

  updateWidget () {
    if (this.enabled && this._widget && this.source) {
      const eyeDistance = this._centerWorld.distanceTo(this._app.camera.position)
      const size = 0.15
      this._widget.scale.set(1, 1, 1).multiplyScalar(eyeDistance * size / 7)
      this._widget.position.copy(this._centerWorld)
    }
  }

  _initTriplanar (source: TypeSceneGraphMesh) {
    this.source = source

    if (!this.source || !this.source.material.isTriplanarMaterial) {
      console.warn('Material is not \'TriplanarMaterial\'.')
      return this.disable()
    }

    if (!this._orientationDummy) {
      this._orientationDummy = createDummyBox(this._app)
    }

    if (!this._widget) {
      const mapRotation = this.getMapRotation()
      const bufferBox = createColoredAxisBox()
      const arrowBox = createArrowBox(mapRotation)
      this._widgetArrowBox = arrowBox
      this._widget = new THREE.Object3D()
      this._widget.add(bufferBox)
      this._widget.add(arrowBox)
      this._app.overlayScene.add(this._widget)
    }

    this.source.outline = true

    const bbox = this.source.geometry.boundingBox
    bbox.getCenter(this._centerLocal)
    this._centerWorld = this._centerLocal.clone().applyMatrix4(this.source.matrixWorld)

    this.source.matrixWorld.decompose(
      this._orientationDummy.position,
      this._orientationDummy.quaternion,
      this._orientationDummy.scale
    )

    this._orientationDummy.position.copy(this._centerWorld)
    this._widget!.position.copy(this._centerWorld)

    this._invSourceRotation.copy(this._orientationDummy.quaternion.clone().invert())
    if (this.source.userData.triplanarOrientation) {
      const rotation = this._orientationDummy.quaternion.clone().multiply(quaternionFromVec3(this.source.userData.triplanarOrientation))
      this._orientationDummy.quaternion.copy(rotation)
      this._widget!.quaternion.copy(rotation)
    } else {
      const rotation = new THREE.Quaternion()
      this._orientationDummy.quaternion.copy(rotation)
      this._widget!.quaternion.copy(rotation)
    }

    this._app.triplanarTransformGizmo.attach([this._orientationDummy], {
      isTriplanarMaterial: true,
      triplanarOrientation: this._orientationDummy.quaternion
    })
    this._widget!.visible = true
    this.updateWidget()
  }
}

function rotate (value: number, deg: number) {
  return (value + deg) % 360
}

function createColoredAxisBox () {
  const geometry = new THREE.BoxBufferGeometry(1.98, 1.98, 1.98)
  const colors = [
    // red
    1, 0, 0,
    1, 0, 0,
    1, 0, 0,
    1, 0, 0,

    1, 0, 0,
    1, 0, 0,
    1, 0, 0,
    1, 0, 0,

    // green
    0, 1, 0,
    0, 1, 0,
    0, 1, 0,
    0, 1, 0,

    0, 1, 0,
    0, 1, 0,
    0, 1, 0,
    0, 1, 0,

    // blue
    0, 0, 1,
    0, 0, 1,
    0, 0, 1,
    0, 0, 1,

    0, 0, 1,
    0, 0, 1,
    0, 0, 1,
    0, 0, 1
  ]
  geometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3))
  const material = new THREE.MeshBasicMaterial({
    vertexColors: true,
    opacity: 0.3,
    transparent: true,
    depthTest: false,
    depthWrite: false
  })

  return new THREE.Mesh(
    geometry,
    material
  )
}

function createArrowBox (mapRotation: THREE.Vector3) {
  const points = [
    0.3, 0.5, 0,
    0, 0.8, 0,
    -0.3, 0.5, 0,

    -0.07, 0.5, 0,
    -0.07, -0.8, 0,
    0.07, -0.8, 0,

    -0.07, 0.5, 0,
    0.07, -0.8, 0,
    0.07, 0.5, 0
  ]
  const arrow = new THREE.BufferGeometry()
  arrow.setAttribute('position', new THREE.Float32BufferAttribute(points, 3))

  const x1 = arrow.clone()
  const x2 = arrow.clone()
  x1.rotateY(Math.PI / 2)
  x1.translate(1, 0, 0)
  x2.rotateY(Math.PI / -2)
  x2.translate(-1, 0, 0)
  x1.rotateX(THREE.MathUtils.DEG2RAD * mapRotation.x)
  x2.rotateX(THREE.MathUtils.DEG2RAD * mapRotation.x)

  const y1 = arrow.clone()
  const y2 = arrow.clone()
  y1.rotateX(Math.PI / -2)
  y1.translate(0, 1, 0)
  y2.rotateX(Math.PI / 2)
  y2.rotateY(Math.PI)
  y2.translate(0, -1, 0)
  y1.rotateY(THREE.MathUtils.DEG2RAD * mapRotation.y)
  y2.rotateY(THREE.MathUtils.DEG2RAD * mapRotation.y)

  const z1 = arrow.clone()
  const z2 = arrow.clone()
  z1.translate(0, 0, 1)
  z2.rotateY(Math.PI)
  z2.translate(0, 0, -1)
  z1.rotateZ(THREE.MathUtils.DEG2RAD * mapRotation.z)
  z2.rotateZ(THREE.MathUtils.DEG2RAD * mapRotation.z)

  const material = new THREE.MeshBasicMaterial({ color: 0xffffff })
  return new THREE.Mesh(BufferGeometryUtils.mergeBufferGeometries([x1, x2, z1, z2, y1, y2]), material)
}

function createDummyBox (app: any) {
  const geometry = new SceneGraphMeshGeometry(app.assetManager.unitCubeGeometryId, app.assetManager.unitCubeGeometry.boundingBox)
  const box = new SceneGraphMesh()
  box.geometry = geometry
  return box
}
