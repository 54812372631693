import Immutable from 'seamless-immutable'
import { createAction, handleActions } from 'redux-actions'

import _get from 'lodash/get'
import _filter from 'lodash/filter'

import {
  getViewer,
  getViveState,
  getIsViveActive
} from './selectors'

import * as fromMaterials from '../materials'
import * as fromColors from '../colors'
import * as fromTemplates from '../templates'

export const setAsAvailable = createAction('threeviewer/vive/AVAILABLE')
const confirmDispose = createAction('threeviewer/vive/DISPOSE')

const deactivate = createAction('threeviewer/vive/DEACTIVATE')
const activate = createAction('threeviewer/vive/ACTIVATE')
const setCamera = createAction('threeviewer/vive/SET_CAMERA')

// const dispose = () => (dispatch, getState) => {
//   const { vive } = getViewer(getState())

//   vive && vive.dispose && vive.dispose()
//   dispatch(confirmDispose())
// }

// const exitVR = () => (dispatch, getState) => {
//   const state = getState()
//   const isActive = getIsViveActive(state)
//   const { vive } = getViewer(state)

//   if (!isActive) return
//   vive.exitVR().then(() => dispatch(dispose()))
// }

export const loadMaterialsForVR = () => (dispatch, getState) => {
  return Promise.all([
    dispatch(fromMaterials.getMaterialsJSON({ canSetInVR: true })),
    dispatch(fromColors.getColors())
  ])
    .then(() => {
      const state = getState()
      const { materialsJson } = state.materials
      const { colors } = state.colors

      const colorsForVR = _filter(colors, (json) => _get(json, 'canSetInVR') && _get(json, 'ncs.color'))
        .map((json) => {
          const { r, g, b } = _get(json, 'ncs.color')
          return { ...json, color: `rgb(${r},${g},${b})` }
        })

      const materialsForVR = _filter(materialsJson, (json) => json.canSetInVR)

      return Promise.all(materialsForVR.map((json) => dispatch(fromMaterials.loadMaterial(json, json.id))))
        .then((materials) => {
          const colorMaterial = materials
            .find((material) => _get(materialsJson, `${material.materialId}.defaultVRColorMaterial`))

          colorsForVR.forEach((color) => {
            const clonedMaterial = colorMaterial.clone()

            clonedMaterial.color.copy(color.color)

            materials.push(Object.assign(clonedMaterial, {
              materialId: colorMaterial.materialId,
              materialName: colorMaterial.materialName,
              name: colorMaterial.name,
              colorId: color.id,
              colorName: color.name
            }))
          })

          return materials.sort((a, b) => {
            return _get(materialsJson, `${a.materialId}.type`) > _get(materialsJson, `${b.materialId}.type`)
          })
        })
    })
}

export const toggleVive = () => (dispatch, getState) => {
  const state = getState()
  const viewer = getViewer(state)
  const isActive = getIsViveActive(state)

  if (isActive) {
    dispatch(deactivate())
    viewer.renderVive = false
    viewer.renderer.setClearColor(0xffffff, 0)
    return
  }

  viewer.vive.startVR()
    .then(() => dispatch(fromTemplates.getCurrentOrigo()))
    .then((currentOrigo) => {
      dispatch(activate())
      viewer.renderVive = true

      // To prevent further translation when toggling
      if (!getViveState(getState()).isCameraSet) {
        viewer.cameraParent.translateX(currentOrigo.x)
        viewer.cameraParent.translateZ(currentOrigo.z)

        dispatch(setCamera())
      }

      viewer.renderer.setClearColor(0xf4f4f4, 1)

      window.addEventListener('keydown', ({ keyCode }) => {
        switch (keyCode) {
          case 39:
          case 68:
            // right
            viewer.cameraParent.translateX(0.1)
            break
          case 65:
          case 37:
            // lefts
            viewer.cameraParent.translateX(-0.1)
            break
          case 87:
          case 38:
            viewer.cameraParent.translateZ(0.1)
            // up
            break
          case 83:
          case 40:
            viewer.cameraParent.translateZ(-0.1)
            // down
            break
        }
      })
    })
    .catch((err) => console.error(err))
}

const initialState = Immutable({
  isActive: false,
  isAvailable: false,
  isCameraSet: false
})

export default handleActions({
  [setAsAvailable]: (state) => state.merge({ isAvailable: true }),
  [deactivate]: (state) => state.merge({ isActive: false }),
  [activate]: (state) => state.merge({ isActive: true }),
  [setCamera]: (state) => state.merge({ isCameraSet: true }),
  [confirmDispose]: () => initialState
}, initialState)
