import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createSelector } from 'reselect'

import _get from 'lodash/get'
import _filter from 'lodash/filter'

import * as fromUploadsSelectors from '../../stores/ducks/uploads/selectors'
import * as fromUsersSelectors from '../../stores/ducks/users/selectors'
import * as fromProjectsSelectors from '../../stores/ducks/projects/selectors'
import * as fromUploads from '../../stores/ducks/uploads'

import Pending from './pending'
import EmptyState from '../common/empty-state/empty-state'

import IconRectangles from '../common/icons/icon-rectangles'

const templateEmptyStateData = {
  icon: <IconRectangles size={80} />,
  title: 'Create a set of product images',
  desc: 'Click "CREATE IMAGE PACKAGE" above to start applying patterns in a new package. Patterns can easily be uploaded and adjusted. Each package holds multiple images, and it is recommended to create one package per pattern.'
}

const ConversionList = ({
  conversions,
  rootClassName,
  itemClassName,
  retryConvert,
  emptyProject,
  remove
}) => {
  if (conversions.length === 0 && emptyProject) {
    return ([
      <EmptyState
        key='template'
        className='mt4'
        icon={templateEmptyStateData.icon}
        title={templateEmptyStateData.title}
        desc={templateEmptyStateData.desc}
      />
    ])
  }

  return (
    <div className={rootClassName}>
      {(conversions).map((conversion) => (
        <div key={conversion.id} className={itemClassName}>
          <Pending
            {...conversion}
            title={_get(conversion, 'uploadMetadata.title')}
            onRetry={() => retryConvert(conversion.id)}
            onCancel={() => remove(conversion.id)}
          />
        </div>
      ))}
    </div>
  )
}

const mapStateToProps = createSelector(
  fromUploadsSelectors.getEntries,
  fromProjectsSelectors.getCurrentId,
  fromUsersSelectors.getCurrentId,
  (uploads, currentProjectId) => {
    if (!uploads) { return null }
    return {
      conversions: _filter(uploads, (upload) => {
        return upload &&
          upload.type === 'cad' &&
          upload.projectId === currentProjectId &&
          !upload.removedAt &&
          !upload.processed &&
          upload.shouldProcess
      }).sort((a, b) => (+b.createdAt - +a.createdAt))
    }
  }
)

const mapDispatchToProps = (dispatch) => bindActionCreators({
  retryConvert: fromUploads.retryConvert,
  remove: fromUploads.remove
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConversionList)
