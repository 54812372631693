const WALL_OVERLAP = 0.01

export function updateWall ({
  wall,
  floorBox,
  centerPosition,
  size,
  viewerUtils
}) {
  return () => {
    const shouldUpdateScale = (centerPosition && size)

    let earlierScale = null
    if (shouldUpdateScale) {
      var wallBB = viewerUtils.getWorldBoundFromMeshBoundingBoxes(wall)
      var floorBB = viewerUtils.getWorldBoundFromMeshBoundingBoxes(floorBox)

      earlierScale = wall.scale.clone()

      wall.position.y = 0

      if (wall.wallType === 'originalWall') {
        var wallComputedScaleX = (size.x)
        wall.position.x = floorBox.position.x
        wall.position.z = wall.position.z + (floorBB.min.z - wallBB.max.z)
        wall.scale.set(wallComputedScaleX, 1, 1)
      } else if (wall.wallType === 'relativeWall') {
        var wallComputedScaleZ = size.z + WALL_OVERLAP
        wall.position.x = wall.position.x + (floorBB.max.x - wallBB.min.x)
        wall.position.z = floorBox.position.z + ((floorBB.max.z - floorBB.min.z) / 2) - (WALL_OVERLAP / 2)
        wall.scale.set(wallComputedScaleZ, 1, 1)
      }

      wall.userData.snappingType = 'EXACT'
      wall.userData.noSourceSnap = true
    }

    wall.traverse((child) => {
      if (child.material) {
        const { material } = child

        if (shouldUpdateScale) {
          const mapRepeat = material.mapRepeat.clone()

          mapRepeat.divide(earlierScale) // divide with scale before setting scale to get "default" mapRepeat
          mapRepeat.multiply(wall.scale)

          child.userData.mapRepeat = mapRepeat
          material.mapRepeat.copy(mapRepeat)
        } else if (child.userData.mapRepeat) {
          material.mapRepeat.copy(child.userData.mapRepeat)
        }

        if (material.useModelFading) { material.useModelFading = false }

        material.wallX0 = wall.position.x - 0.5 * wall.scale.x
        material.wallX1 = wall.position.x + 0.5 * wall.scale.x
      }
    })
  }
}
