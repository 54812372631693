import * as THREE from 'three'

type Axis = 'x' | 'y' | 'z'
const axes: Axis[] = ['x', 'y', 'z']

export const wrapPIEuler = (euler: THREE.Euler) => {
  axes.forEach(axis => {
    let value = euler[axis]
    if (value > Math.PI) {
      value -= 2 * Math.PI
    } else if (value < -Math.PI) {
      value += 2 * Math.PI
    }
    euler[axis] = value
  })
  return euler
}

export const differenceEuler = (euler1: THREE.Euler, euler2: THREE.Euler) => {
  return axes.reduce((acc, axis) => Math.abs(euler1[axis] - euler2[axis]) + acc, 0)
}

export const continuousEuler = (previous: THREE.Euler, current: THREE.Euler) => {
  // Find equivalent euler angles
  const alternatives = [
    wrapPIEuler(new THREE.Euler(
      current.x - Math.PI,
      -Math.PI - current.y,
      current.z - Math.PI
    )),
    wrapPIEuler(new THREE.Euler(
      current.x + Math.PI,
      Math.PI - current.y,
      current.z + Math.PI
    ))
  ]

  // Find the euler angle that is closest to the previous one
  const { closest } = alternatives.reduce(({ closest, diff }, alternative) => {
    const currentDiff = differenceEuler(previous, alternative)
    return diff > currentDiff
      ? { closest: alternative, diff: currentDiff }
      : { closest, diff }
  }, { closest: current, diff: differenceEuler(previous, current) })

  return closest
}

/**
 * A rough average of a set of quaternions. This works best for quaternions that already have fairly
 * similar orientations. Unfortunately, averaging quaternions is not a trivial problem. However, this implementation
 * should be sufficient for many purposes.
 *
 * Based on this comment: https://math.stackexchange.com/a/3435296
 */
export const approximateQuaternionAverage = (quaternions: THREE.Quaternion[]) => {
  if (!quaternions.length) return new THREE.Quaternion()
  if (quaternions.length === 1) return quaternions[0]

  const average = new THREE.Quaternion(0.0, 0.0, 0.0, 0.0)
  quaternions.forEach((quaternion, i) => {
    let weight = 1.0

    // Correction for double cover problem
    if (i > 0 && quaternion.dot(quaternions[0]) < 0.0) {
      weight = -1
    }

    average.x += weight * quaternion.x
    average.y += weight * quaternion.y
    average.z += weight * quaternion.z
    average.w += weight * quaternion.w
  })

  return average.normalize()
}
