import React from 'react'

const IconGallery = props => (
  <svg viewBox='0 0 20 20' width={props.size} height={props.size} {...props}>
    <path
      d="M6.7 6.7c0 .6-.2 1-.6 1.4-.4.4-.9.6-1.4.6s-1-.2-1.4-.6c-.4-.4-.6-.9-.6-1.4s.2-1 .6-1.4.9-.6 1.4-.6 1 .2 1.4.6c.4.3.6.8.6 1.4zm10.6 4v4.7H2.7v-2L6 10l1.7 1.7L13 6.3l4.3 4.4zm1-7.4H1.7c-.1 0-.2 0-.2.1-.1.1-.2.2-.2.3v12.7c0 .1 0 .2.1.2.1.1.1.1.2.1h16.7c.1 0 .2 0 .2-.1.1-.1.1-.1.1-.2V3.7c0-.1 0-.2-.1-.2 0-.1-.1-.2-.2-.2zm1.7.4v12.7c0 .5-.2.9-.5 1.2-.3.3-.7.5-1.2.5H1.7c-.5 0-.9-.2-1.2-.5-.3-.4-.5-.8-.5-1.3V3.7c0-.5.2-.9.5-1.2.3-.3.7-.5 1.2-.5h16.7c.5 0 .9.2 1.2.5.2.3.4.7.4 1.2z"
      fill={props.color}
    />
  </svg>
)

IconGallery.defaultProps = {
  color: 'currentcolor',
  size: '1rem'
}

export default IconGallery
