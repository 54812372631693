import React from 'react'
import styled from 'styled-components'
import IconUsers from '../../common/icons/icon-user'
import IconFolder from '../../common/icons/icon-folder'

const MenuContainer = styled.div`
background-color: var(--grayLightAlt);
height: 50px;
display: flex;
flex-direction: row; 
border-style: solid;
border-width: thin; 
border-color: var(--grayLightAlt);
`
const MenuItem = styled.div`
background-color: ${props => props.selected ? 'var(--grayLighter)' : ''};
 width: 100px;
 display: flex;
 justify-content: center;
 flex-direction: column;
 cursor: pointer;
 &:hover {
  background-color: ${props => !props.selected ? 'var(--grayLight)' : ''};
}
`

function Menu ({ usersSelected, projectsSelected, setMenuItem }) {
  return <MenuContainer >
    <MenuItem selected={usersSelected}
      onClick={() => setMenuItem('users')}
    >
      <div style={{ margin: '0 auto' }}><IconUsers size={20} /></div>
      <div style={{ margin: '0 auto' }}> Users</div>
    </MenuItem>

    <MenuItem selected={projectsSelected}
      onClick={() => setMenuItem('projects')}
    >
      <div style={{ margin: '0 auto' }}><IconFolder size={20} /></div>
      <div style={{ margin: '0 auto' }}>Projects</div>
    </MenuItem>
  </MenuContainer>
}

export default Menu
