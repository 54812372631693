import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import InputGroup from '../common/form/input-group'
import Button from '../common/button'
import InputText from '../common/form/input-text'
import Label from '../common/form/label'
import KeyboardListener, { KeyCode, KeyBinding } from '../common/keyboard-listener'

import { convert, cancel } from '../../stores/ducks/patterns/convert'

class PatternUploadView extends React.Component {
  constructor (props) {
    super(props)
    const { files } = props
    const file = files[0]
    this.isFileSupported = this.isFileSupported.bind(this)

    const success = this.isFileSupported(file)

    this.state = {
      title: file.name,
      files: files,
      success
    }
  }

  isFileSupported (file) {
    var parts = file.name.split('.')
    return this.props.supportedFormats
      .map(x => x.toLowerCase())
      .includes('.' + parts[parts.length - 1].toLowerCase())
  }

  handleTitleChange (data) {
    this.setState({ title: data })
  }

  handleUploading () {
    const { files, title } = this.state
    this.props.onReset()
    this.props.convert(files, { title })
  }

  render () {
    const { onReset } = this.props
    const { title, success } = this.state

    if (!success) {
      return (
        <div>
          <h2 className='my0 f3 c-error'>
            Unsupported file format
          </h2>
          <div className='py2'>Supported file formats are: <b>.pdf, .tiff, .tif, .jpg, .jpeg, .png, .eps, .svg, .psd</b>.</div>
          <div className='mt2 clearfix flex justify-end items-baseline'>
            <Button className='px3' btnType='primary' onClick={this.props.onReset}>
              Close
            </Button>
          </div>
        </div>
      )
    }

    return (
      <div>
        <h2>New pattern</h2>
        <div className='mb2'>To create a pattern with transparency you need to upload a .psd, .pdf or .png file.</div>
        <InputGroup>
          <Label>
            Title
          </Label>
          <InputText
            className='mt1'
            onChange={this.handleTitleChange.bind(this)}
            value={title}
          />
        </InputGroup>

        <div className='flex justify-end items-end mt3'>
          <Button
            onClick={onReset}
          >
            Cancel
          </Button>
          <Button
            data-testid='confirm-upload-pattern'
            btnType='primary'
            className='ml2'
            onClick={this.handleUploading.bind(this)}
          >
            Upload
          </Button>
        </div>
        <KeyboardListener
          keyboardEvent='keyup'
          bindings={[
            KeyBinding(KeyCode.enter, this.handleUploading.bind(this))
          ]}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    modals: state.interface.modals
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    convert: (files, data) => convert(files, data),
    cancel
  }, dispatch)
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PatternUploadView)
