export type UserData = {
  disableMaterials?: boolean
  addToTree?: boolean
  isCombination?: boolean
  modelType?: 'combination' | 'roomset' | 'template'
}

export type Interaction = {
  addProductType?: boolean
  userData: UserData,
  params: {
    addToPicker?: boolean
    addToNodeList?: boolean
    addToTransformGizmo?: boolean
    addToTriplanarTool?: boolean
    addToGeometryMap?: boolean
    interactions?: {
      wall?: boolean | { recursive: boolean }
      combinations?: boolean | { recursive: boolean }
      canSetMaterial?: boolean | { recursive: boolean }
      snapTargets?: boolean | { recursive: boolean }
      vrSnapTargets?: boolean | { recursive: boolean }
      vrSnapSources?: boolean | { recursive: boolean }
      cloneTool?: boolean | { recursive: boolean }
    }
  }
}

const defaultInteraction: Interaction = {
  addProductType: true,
  userData: {
    addToTree: true,
    isCombination: true,
    modelType: 'combination'
  },
  params: {
    addToPicker: true,
    addToNodeList: true,
    addToTransformGizmo: true,
    addToTriplanarTool: true,
    addToGeometryMap: true,
    interactions: {
      wall: { recursive: true },
      combinations: { recursive: true },
      canSetMaterial: { recursive: true },
      snapTargets: { recursive: true },
      vrSnapTargets: { recursive: true },
      vrSnapSources: { recursive: true },
      cloneTool: { recursive: true }
    }
  }
}

const staticInteraction: Interaction = {
  userData: {
    disableMaterials: true,
    modelType: 'combination'
  },
  params: {}
}

export default {
  default: defaultInteraction,
  static: staticInteraction
}
