import { createSelector } from 'reselect'

import * as fromUsersSelectors from '../../../stores/ducks/users/selectors'
import * as fromProjectsSelectors from '../../../stores/ducks/projects/selectors'
import type { User } from '../../../stores/ducks/users/User'

export const selectProjectMembers = createSelector(
  fromProjectsSelectors.getCurrentEntry,
  fromUsersSelectors.getEntries,
  (project, users) => {
    const sharedWith = { ...(project.sharedWith || {}), [project.createdBy]: true }
    const usersSharedWith: User[] = []
    Object.entries(sharedWith).forEach(([userId, shared]) => {
      if (shared && users[userId] && userId !== project.createdBy) {
        usersSharedWith.push(users[userId])
      }
    })

    return [
      users[project.createdBy],
      ...usersSharedWith
    ].filter(Boolean)
  }
)
