import { EventEmitter } from 'events'
import { approxEquality, arrayContentEqual } from '../util/ComparisonUtils'
import { continuousEuler } from '../util/RotationUtils'
import AutoGroupManager from './AutoGroupManager'
import _difference from 'lodash/difference'

var THREE = require('three')
var inherits = require('inherits')
var objectTracker = require('./../scenegraph/ObjectTracker')
var { SceneGraphMesh } = require('../scenegraph/SceneGraph')
var SnapHelper = require('../plugins/TransformController/SnapHelper').default

inherits(TransformGizmo, EventEmitter)

function TransformGizmo (viewer, camera, renderer) {
  this.viewer = viewer

  this.dummyMesh = undefined
  this.prevDummyMatrix = undefined
  this.objs = []

  this.autoGroupManager = new AutoGroupManager(viewer)
  this.holeSnapHelper = new SnapHelper(viewer.overlayScene)
  this.control = new THREE.TransformControls(viewer, camera, renderer.domElement, this.holeSnapHelper)

  this._boundChange = this._change.bind(this)
  this._boundHandleMouseDown = this._handleMouseDown.bind(this)
  this._boundHandleMouseUp = this._handleMouseUp.bind(this)

  this.control.addEventListener('objectChange', this._boundChange)
  this.control.addEventListener('mouseDown', this._boundHandleMouseDown)
  this.control.addEventListener('mouseUp', this._boundHandleMouseUp)

  this.enabled = true
  this.autoGroupingActive = false

  this._trackedRotations = new Map()
}

TransformGizmo.prototype.SPACES = {
  LOCAL: 'local',
  WORLD: 'world'
}

const MODES = {
  SNAPPING: 'snapping',
  ROTATE: 'rotate',
  TRANSLATE: 'translate',
  SCALE: 'scale',
  ROTATE_Y: 'rotateY'
}

TransformGizmo.prototype.MODES = MODES

TransformGizmo.prototype.attach = function (objs, params = {}) {
  if (!this.enabled) return
  params = params || {}

  if (params.attachToRoot) {
    objs = Object.values(
      objs.reduce((memo, next) => {
        var _root = this.viewer.viewerUtils.findRootNode(next)
        return Object.assign(memo, {
          [_root.uuid]: _root
        })
      }, {})
    )
  }

  if (!params.isTriplanarMaterial) {
    if (!objectTracker.typeContainsObjects(objs, 'transformGizmo')) return
  }

  if (!params.noEmit) this.emit('attach', objs)
  this.resetDummyMesh(objs, params.triplanarOrientation)
  this.control.attach(this.dummyMesh, objs)
  this.params = params
}

TransformGizmo.prototype.attachToGroup = function (objs, params) {
  const groups = new Set()
  const nonGroupNodes = []
  params = params || {}

  function findNonGroupNodes (node, callback) {
    if (!node.userData.isGroup) {
      callback(node)
      return
    }
    node.children.forEach(n => {
      return findNonGroupNodes(n, callback)
    })
  }

  if (params.attachToRoot) {
    objs = Object.values(
      objs.reduce((memo, next) => {
        var _root = this.viewer.viewerUtils.findRootNode(next)
        return Object.assign(memo, {
          [_root.uuid]: _root
        })
      }, {})
    )
  }

  objs.forEach(obj => {
    let current = obj.parent
    let topGroup = null
    while (current.parent !== null) {
      if (current.userData.isGroup) {
        topGroup = current
      }
      current = current.parent
    }
    if (topGroup) {
      groups.add(topGroup)
    } else {
      nonGroupNodes.push(obj)
    }
  })

  if (groups.size > 0) {
    const newObjs = [...groups].reduce((acc, curr) => {
      const children = []
      findNonGroupNodes(curr, node => children.push(node))
      return [...acc, ...children]
    }, [])
    objs = [...new Set(newObjs), ...nonGroupNodes]
  }

  params.attachToRoot = false
  this.attach(objs, params)
}

TransformGizmo.prototype.selectionUpdate = function () {
  if (!this.objs) {
    return
  }
  this.emit('moveDone', null)
}

TransformGizmo.prototype.setAxisPositionOnSelected = function (axis, amount) {
  if (!this.objs || !['x', 'y', 'z'].includes(axis) || isNaN(amount)) {
    return
  }

  for (let i = 0; i < this.objs.length; i++) {
    const o = this.objs[i]
    o.position[axis] = amount
  }

  this.reattachObjects()
  this.emit('moved', null)
  this.emit('moveDone', null)
}

TransformGizmo.prototype.setPositionOnSelected = function (posX, posY, posZ) {
  if (!this.objs) {
    return
  }

  const prevPositions = {}
  for (let i = 0; i < this.objs.length; i++) {
    const o = this.objs[i]
    prevPositions[o.uuid] = o.position.clone()

    const newX = isNaN(posX) ? o.position.x : posX
    const newY = isNaN(posY) ? o.position.y : posY
    const newZ = isNaN(posZ) ? o.position.z : posZ
    o.position.set(newX, newY, newZ)
  }

  this.reattachObjects()
  this.emit('offset-position', { prevPositions })
  this.emit('moved', null)
  this.emit('moveDone', null)
}

TransformGizmo.prototype.offsetAxisPositionOnSelected = function (axis, amount) {
  if (!this.objs || amount === 0 || !['x', 'y', 'z'].includes(axis) || isNaN(amount)) {
    return
  }

  const prevPositions = {}

  for (let i = 0; i < this.objs.length; i++) {
    const o = this.objs[i]
    prevPositions[o.uuid] = o.position.clone()

    if (axis === 'x') {
      o.translateX(amount)
    } else if (axis === 'y') {
      o.translateY(amount)
    } else if (axis === 'z') {
      o.translateZ(amount)
    }
  }

  this.reattachObjects()
  this.emit('offset-position', { prevPositions })
  this.emit('moved', null)
  this.emit('moveDone', null)
}

TransformGizmo.prototype.offsetPositionOnSelected = function (x, y, z) {
  if (isNaN(x) || isNaN(y) || isNaN(z)) {
    return
  }

  const prevPositions = {}

  for (let i = 0; i < this.objs.length; i++) {
    const o = this.objs[i]
    prevPositions[o.uuid] = o.position.clone()

    o.translateX(x)
    o.translateY(y)
    o.translateZ(z)
  }

  this.reattachObjects()
  this.emit('offset-position', { prevPositions })
  this.emit('moved', null)
  this.emit('moveDone', null)
}

TransformGizmo.prototype._getPreviousState = function () {
  const prevRotations = {}
  const prevPositions = {}
  const prevTrackedRotations = this._cloneTrackedRotations(this._trackedRotations)

  for (let i = 0; i < this.objs.length; i++) {
    const o = this.objs[i]
    prevPositions[o.uuid] = o.position.clone()
    prevRotations[o.uuid] = o.rotation.clone()
  }

  return {
    prevRotations,
    prevPositions,
    prevTrackedRotations
  }
}

TransformGizmo.prototype.setRotationOnSelected = function (rotX, rotY, rotZ) {
  if (!this.objs) return

  const previousState = this._getPreviousState()

  const newX = isNaN(rotX) ? this.dummyMesh.rotation.x : THREE.MathUtils.degToRad(rotX)
  const newY = isNaN(rotY) ? this.dummyMesh.rotation.y : THREE.MathUtils.degToRad(rotY)
  const newZ = isNaN(rotZ) ? this.dummyMesh.rotation.z : THREE.MathUtils.degToRad(rotZ)

  this.dummyMesh.rotation.set(newX, newY, newZ)
  this._change({ target: null, type: 'objectChange' })

  this.emit('offset-rotation', previousState)
  this.emit('moved', null)
  this.emit('moveDone', null)
}

TransformGizmo.prototype.setRotationOnSelectedRootModels = function (rotX, rotY, rotZ) {
  if (!this.objs) return

  const previousState = this._getPreviousState()

  this.objs.forEach(obj => obj.rotation.set(rotX, rotY, rotZ))

  this._invalidateTrackedRotations()

  this.emit('offset-rotation', previousState)
  this.emit('moved', null)
  this.emit('moveDone', null)
}

TransformGizmo.prototype.offsetAxisRotationOnSelected = function (axis, amount) {
  if (!this.objs || amount === 0 || !['x', 'y', 'z'].includes(axis)) {
    return
  }

  const previousState = this._getPreviousState()

  const amountInRadians = THREE.MathUtils.degToRad(amount)
  if (axis === 'x') {
    this.dummyMesh.rotateX(amountInRadians)
  } else if (axis === 'y') {
    this.dummyMesh.rotateY(amountInRadians)
  } else if (axis === 'z') {
    this.dummyMesh.rotateZ(amountInRadians)
  }
  this._change({ target: null, type: 'objectChange' })

  this.emit('offset-rotation', previousState)
  this.emit('moved', null)
  this.emit('moveDone', null)
}

TransformGizmo.prototype._cloneTrackedRotations = function (rotations) {
  const clonedRotations = new Map()
  rotations.forEach((group, uuid) => {
    clonedRotations.set(uuid, {
      uuids: [...group.uuids],
      rotation: group.rotation.clone()
    })
  })
  return clonedRotations
}

TransformGizmo.prototype._trackRotations = function () {
  if (this.objs.length < 2) return
  const rotations = this._trackedRotations

  const rotationGroup = {
    uuids: this.objs.map(obj => obj.uuid),
    rotation: this.dummyMesh.rotation.clone()
  }

  for (let i = 0; i < this.objs.length; i++) {
    const obj = this.objs[i]

    // If there already is an entry for the current object, check if this entry contains the same
    // uuids as the current rotation. If not, clear the entries for these uuids
    if (rotations.has(obj.uuid) && !arrayContentEqual(rotations.get(obj.uuid).uuids, rotationGroup.uuids)) {
      rotations.get(obj.uuid)
        .uuids
        .forEach(uuid => rotations.delete(uuid))
    }

    // Update the rotations map
    rotations.set(obj.uuid, rotationGroup)
  }
}

TransformGizmo.prototype._restoreRotations = function () {
  if (this.objs.length < 2) return false

  const uuid = this.objs[0].uuid // Any uuid can be used as key
  if (!this._trackedRotations.has(uuid)) return false

  const rotationGroup = this._trackedRotations.get(uuid)
  if (!arrayContentEqual(this.objs.map(obj => obj.uuid), rotationGroup.uuids)) return false

  this.dummyMesh.rotation.copy(rotationGroup.rotation)
  return true
}

TransformGizmo.prototype._invalidateTrackedRotations = function () {
  if (!this.objs) return
  const rotations = this._trackedRotations

  // For each object A that has a tracked rotation
  this.objs.forEach(obj => {
    if (rotations.has(obj.uuid)) {
      // Get the uuids of the other objects in the group
      rotations.get(obj.uuid)
        .uuids
        .forEach(uuid => {
          const otherUuids = rotations.get(uuid).uuids
          if (otherUuids) {
            // And remove the object A uuid from their respective groups
            rotations.get(uuid).uuids = _difference(otherUuids, [uuid])
          }
        })
    }
  })

  // Remove the groups
  this.objs.forEach(obj => {
    rotations.delete(obj.uuid)
  })
}

TransformGizmo.prototype.resetDummyMesh = function (objs, triplanarOrientation) {
  var dummyMesh = new SceneGraphMesh()
  var center = new THREE.Vector3()

  this.control.getSelectedObjectsBB(objs)
    .getCenter(center)

  this.dummyMesh = dummyMesh
  this.objs = objs

  dummyMesh.position.copy(center)

  if (triplanarOrientation) {
    dummyMesh.quaternion.copy(triplanarOrientation)
  } else if (objs.length) {
    if (!this._restoreRotations()) {
      const rotation = objs[0].rotation
      const isRotationsEqual = objs.every(obj => (
        approxEquality(rotation.x, obj.rotation.x) &&
        approxEquality(rotation.y, obj.rotation.y) &&
        approxEquality(rotation.z, obj.rotation.z)
      ))

      if (isRotationsEqual) dummyMesh.rotation.copy(rotation)
    }
  }

  this.prevDummyMatrix = dummyMesh.matrix.clone()
}

TransformGizmo.prototype.getObjects = function () {
  return this.objs
}

TransformGizmo.prototype.getTrackedRotations = function () {
  return this._trackedRotations
}

TransformGizmo.prototype.setTrackedRotations = function (trackedRotations) {
  this._trackedRotations = trackedRotations
}

TransformGizmo.prototype.detach = function () {
  this.control.detach()
}

TransformGizmo.prototype.enable = function () {
  this.enabled = true
}

TransformGizmo.prototype.disable = function () {
  this.enabled = false
  this.detach()
}

TransformGizmo.prototype.setSnapToModelsActive = function (value) {
  this.control.alignSnappingEnabled = value
}

TransformGizmo.prototype.setSnapToHolesActive = function (value) {
  if (value) {
    this.control.enableHoleSnapping()
  } else {
    this.control.disableHoleSnapping()
  }
}

TransformGizmo.prototype.setAutoGroupingActive = function (value) {
  this.autoGroupingActive = value
}

TransformGizmo.prototype.cameraSwitched = function (newCamera, renderer) {
  var newControl = new THREE.TransformControls(newCamera, renderer.domElement)
  // keep settings from old TransformControl
  newControl.attach(this.control.object)
  newControl.setSpace(this.control.space)
  newControl.setMode(this.control.getMode())
  newControl.setRotationSnap(this.control.rotationSnap)
  newControl.setTranslationSnap(this.control.translationSnap)
  newControl.setSize(this.control.size)

  this.control.parent.add(newControl)
  this.disposeControl()

  this.control = newControl
}

TransformGizmo.prototype.reattachObjects = function () {
  this.attach(this.objs, this.params)
}

TransformGizmo.prototype.setSpace = function (space) {
  this.control.setSpace(space)
}

TransformGizmo.prototype._handleMouseDown = function (event) {
  if (!this.enabled) return
  this.emit('mouseDown', event)
}

TransformGizmo.prototype._handleMouseUp = function (event) {
  if (!this.enabled) return
  if (this.autoGroupingActive && this.getMode() === MODES.SNAPPING && event.target.wasMoved) {
    event.autoGroupingData = this.autoGroupManager.getAutoSnappingData(event)
  }
  this.emit('moveDone', null)
  this.emit('mouseUp', event)
  this.emit('snapStart')
  event.autoGroupingData = null
}

TransformGizmo.prototype.addSnappableModel = function (model) {
  this.holeSnapHelper.addSnappableModel(model, this.control.holeSnappingEnabled && this.control.mode === 'snapping')
}

TransformGizmo.prototype.removeSnappableModel = function (model) {
  this.holeSnapHelper.removeSnappableModel(model)
}

TransformGizmo.prototype._change = function (event) {
  if (!this.enabled) return

  // skip if no change
  if (this.dummyMesh.matrix.equals(this.prevDummyMatrix)) {
    return
  }

  // calc "diff" between prev and current
  const deltaMatrix = new THREE.Matrix4()
  deltaMatrix.copy(this.prevDummyMatrix).invert()
  deltaMatrix.premultiply(this.dummyMesh.matrix)

  const position = new THREE.Vector3()
  const rotation = new THREE.Quaternion()
  const scale = new THREE.Vector3()

  this.objs.forEach(obj => {
    const previousEuler = obj.rotation.clone()
    const newMatrix = obj.matrix.clone()

    // apply obj matrix
    newMatrix.premultiply(deltaMatrix)
    newMatrix.decompose(position, rotation, scale)

    // update
    obj.rotation.setFromQuaternion(rotation)
    obj.position.copy(position)
    obj.scale.copy(scale)

    // correct rotation
    obj.rotation.copy(
      continuousEuler(previousEuler, obj.rotation)
    )
  })

  this.prevDummyMatrix = this.dummyMesh.matrix.clone()

  if (event.target !== null) {
    const { prevIntersection, wasMoved } = event.target

    if (this.autoGroupingActive && prevIntersection && this.getMode() === MODES.SNAPPING && wasMoved) {
      const data = this.autoGroupManager.getAutoSnappingData(event)
      this.autoGroupManager.enabled = data.action !== 'NO_OP'
      this.autoGroupManager.updateMarkerPoint(event)
    }
  }
  this.holeSnapHelper.updateHoleMarkers()

  this._trackRotations()

  this.emit('moved', event)
}

TransformGizmo.prototype.updateHoleMarkers = function () {
  this.holeSnapHelper.updateHoleMarkers()
}

TransformGizmo.prototype.setMode = function (mode) {
  if (!Object.values(this.MODES).includes(mode)) {
    return console.warn(`${mode} is not implemented.`)
  }

  if (mode === 'snapping') {
    if (this.control.holeSnappingEnabled) {
      this.holeSnapHelper.enableHoleMarkers()
    }
  } else {
    this.holeSnapHelper.disableHoleMarkers()
  }

  this.control.setMode(mode)
}

TransformGizmo.prototype.getMode = function () {
  return this.control.getMode()
}

TransformGizmo.prototype.didAnimate = function () {
  return this.autoGroupingActive && this.autoGroupManager.enabled
}

TransformGizmo.prototype.disposeControl = function () {
  this.control.removeEventListener('objectChange', this._boundChange)
  this.control.removeEventListener('mouseDown', this._boundHandleMouseDown)
  this.control.removeEventListener('mouseUp', this._boundHandleMouseUp)

  this.removeAllListeners()

  this.control.traverse((child) => {
    if (child.dispose) {
      child.dispose()
    }

    if (child.geometry) {
      child.geometry.dispose()
      child.geometry = undefined
    }

    if (child.material) {
      Object.keys(child.material)
        .filter((key) => /map/i.test(key))
        .forEach((key) => {
          const texture = child.material[key]
          if (texture && texture.dispose) texture.dispose()
          child.material[key] = undefined
        })

      child.material.dispose()
      child.material = undefined
    }
  })

  this.control.dispose()
  this.control.detach()

  if (this.control.parent) {
    this.control.parent.remove(this.control)
  }

  delete this._listeners
}

TransformGizmo.prototype.dispose = TransformGizmo.prototype.disposeControl

export default TransformGizmo
