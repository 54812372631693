import { createSelector } from 'reselect'
import { connect } from 'react-redux'

import _flow from 'lodash/fp/flow'
import _map from 'lodash/fp/map'
import _filter from 'lodash/fp/filter'
import _get from 'lodash/get'
import _pick from 'lodash/fp/pick'
import _orderBy from 'lodash/orderBy'
import _groupBy from 'lodash/groupBy'
import _forEach from 'lodash/forEach'
import _every from 'lodash/every'
import __map from 'lodash/map'

import * as storageUtils from '../../../../utils/storage'

// Actions
import * as fromPatternTextures from '../../../../stores/ducks/patterns/textures'
import * as fromInterfaceModals from '../../../../stores/ducks/interface/modals'

// Selectors
import * as fromMaterialAndColorSelectors from './material-and-color-selectors'
import * as fromPatternSelectors from '../../../../stores/ducks/patterns/selectors'
import * as fromProjectsSelectors from '../../../../stores/ducks/projects/selectors'
import * as fromJobsSelectors from '../../../../stores/ducks/jobs/selectors'

const mapStateToProps = createSelector(
  fromPatternSelectors.getEntries,
  fromMaterialAndColorSelectors.getAppliedPatternIds,
  fromProjectsSelectors.getCurrentId,
  fromMaterialAndColorSelectors.getCanSetPatternOnSelectedMaterial,
  fromJobsSelectors.getEntries,
  (state) => state.users.currentUserId,
  (
    entries,
    appliedPatternIds,
    currentProjectId,
    canSetPattern,
    jobs,
    currentUserId
  ) => {
    const patterns = _flow(
      _filter((entry) => !entry.removedAt),
      _filter((entry) => _get(entry, 'manifest.files.0', false)),
      _filter((entry) => entry.patternFilePath),
      _filter((entry) => {
        const inCurrentProject = (entry.projects || []).includes(currentProjectId)
        return inCurrentProject || (entry.sentAssetId && entry.createdBy === currentUserId)
      }),
      _map((entry) => {
        return ({
          ...entry,
          // NOTE: Could be changed later. Should disable/enable base
          // applied materials which allow decals.
          disabled: !canSetPattern,
          selected: appliedPatternIds[entry.id],
          thumbnailSrc: storageUtils
            .getImageSrc(_get(entry, 'manifest.files.0'), {
              resize: [100, 100],
              format: 'jpg'
            })
        })
      }),
      _map(_pick([
        'title',
        'id',
        'selected',
        'thumbnailSrc',
        'createdAt',
        'disabled',
        'jobs'
      ]))
    )(_orderBy(entries, ['createdAt'], ['desc']))
    const patternIds = __map(patterns, 'id')

    const jobsGroupedBySourceId = _groupBy(jobs, 'sourceId')

    var incompleteJobs = []
    _forEach(jobsGroupedBySourceId, (jobGroup, id) => {
      // note: edge case filter where pattern is completed but not all job data has been received yet
      // fixes so pattern doesn't show up as done and rendering
      if (patternIds.includes(id)) {
        return
      }

      if (!_every(jobGroup, (job) => ((job.status === 'Completed' && job.percentage === 100) || job.shouldProcess === false))) {
        incompleteJobs.push(jobGroup)
      }
    })

    return {
      incompleteJobs,
      patterns
    }
  }
)

const mapDispatchToProps = (dispatch) => ({
  onApply: (id) => dispatch(fromPatternTextures.setPattern(id)),
  onRemove: () => dispatch(fromPatternTextures.removePattern()),
  closeModal: (data) => dispatch(fromInterfaceModals.closeModal(data))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)
