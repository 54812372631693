import React, { useLayoutEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import _filter from 'lodash/filter'
import _get from 'lodash/get'
import _orderBy from 'lodash/orderBy'

import { SCENE_TAGS as allTags } from '../../../../constants'

import { RootState } from '../../../../stores/ducks'
import { Render } from '../../../../stores/ducks/renders/Render'
import { getTempComments } from '../../../../stores/ducks/scenes/selectors'
import type { Comment, Tags } from '../../../../stores/ducks/scenes/Temp'
import { getImageSrc } from '../../../../utils/storage'

import CommentImage from '../../../common/comments/comment-image'
import Grid from '../../../common/grid/grid'
import { Item } from '../../card/Item'
import Comments from '../comments/comment-container'

const orderAndFilterCommentsIndex = (comments:Comment[]) => _orderBy(_filter(comments, 'position'), 'index', ['asc'])

const selectCommentsIndex = createSelector(
  getTempComments,
  (state: RootState) => state.gallery.temporaryAnchor,
  (comments, temporaryAnchor) => {
    const output: any[] = orderAndFilterCommentsIndex(comments)
    if (temporaryAnchor) {
      for (const comment of comments) {
        if (comment.hasTempAnchor) {
          const index = output.indexOf(comment)
          // In editing mode, the comment's anchor is replaced with a temporary anchor
          output[index] = temporaryAnchor
          return output
        }
      }

      output.push(temporaryAnchor)
    }
    return output
  }
)
const getOriginal = (render: Render) => getImageSrc(_get(render, 'manifest.files.0', {}), { format: 'jpg', type: 'large' })

export const Annotation = ({ readOnly, combination, fetchedComments } : {readOnly: boolean, combination:Item, fetchedComments?:Comment[]}) => {
  if (!combination.render) return <div>Something went wrong, please report this issue using the report button</div>
  const tempComments = useSelector(getTempComments)
  const [comments, setComments] = useState(tempComments)

  const positionedComments = readOnly ? () => orderAndFilterCommentsIndex(fetchedComments ?? []) : (state: RootState) => selectCommentsIndex(state)

  // use layout to remove the flickering of anchors in comment-image, when toggle edit mode
  useLayoutEffect(() => {
    if (readOnly && fetchedComments) return setComments(fetchedComments)
    setComments(tempComments)

    return () => setComments(tempComments)
  }, [readOnly, fetchedComments, tempComments])

  return (
    <Grid
      gridGap={0}
      columns={['60%', '40%']}
      rows={['100%']}
      className='height-100 bg-white'
    >
      <CommentImage
        id={combination.id}
        src={getOriginal(combination.render)}
        size={_get(combination, 'render.cameraSettings.resolution', { x: 0, y: 0 })}
        commentSelector={positionedComments}
        bgColor={'bg-gray-light'}
        disableAnnotation={readOnly}
      />
      <div className='height-100 bg-white'>
        <Comments tags={allTags as Tags} addComments={!readOnly} comments={comments} removeEnabled={!readOnly}/>
      </div>
    </Grid>
  )
}
