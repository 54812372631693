import React from 'react'

const iconAngleWide = props => (
  <svg viewBox='0 0 24 24' width={props.size} height={props.size} {...props}>
    <path
      d="M19.7236376,13.2052149 C16.9689965,11.39828 14.2301699,10.5 11.5,10.5 C8.96455915,10.5 6.42165248,11.2747103 3.86554704,12.8324405 L12,20.2136547 L19.7236376,13.2052149 Z M20.4885979,12.5110889 L23.6640063,9.62971875 L24.3359937,10.3702813 L12,21.5639796 L2.99307709,13.3910849 L2.85624311,13.4808649 L2.50932756,12.9521299 L-0.33599374,10.3702813 L0.33599374,9.62971875 L3.09505205,12.1332922 C5.88456217,10.3808043 8.68830902,9.5 11.5,9.5 C14.4358125,9.5 17.3629641,10.4602866 20.2742913,12.3704772 L20.4885979,12.5110889 Z"
      fill={props.color}
    />
  </svg>
)

iconAngleWide.defaultProps = {
  color: 'currentcolor',
  size: '1em'
}

export default iconAngleWide
