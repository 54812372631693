export const getUpplysaLinks = () => {
  let links = { upplysaURL: 'https://dev.upplysa.inter.ikeadt.net', redirectURL: 'https://dev-dpd.inter.ikeadt.net/' }

  if (window.location.origin.startsWith('https://dpd.inter.ikea.net')) {
    links = { upplysaURL: 'https://pilot.upplysa.inter.ikea.net', redirectURL: 'https://dpd.inter.ikea.net/' }
  } else if (window.location.origin.includes('ppe-dpd')) {
    links = { upplysaURL: 'https://test.upplysa.inter.ikeadt.net', redirectURL: 'https://ppe-dpd.inter.ikeadt.net/' }
  }

  return links
}
