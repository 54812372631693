import { createAction, handleActions } from 'redux-actions'

import colors from '../../../../css/colors'

import * as fromThreeviewerSelectors from './selectors'
import * as fromThreeviewerCamera from './camera'

import {
  setupCloneTool,
  setupPostProcess,
  setupSnapping,
  setupTransformTool,
  setupPicker,
  setupFileLoaders,
  setupVive,
  setupSelectSimilarMeshes,
  setupSelectParentObjects,
  setupAlignTool,
  setupAssembleTool
} from './viewer-setup'
import { setup as setupImageTemplates } from './imageTemplates'
import type { Go3DViewer } from '../../../../../go3dthree/types/Go3DViewer'
import { AppThunk } from '..'
import { clear as clearTree } from '../tree'
import { setup as setupTriplanarTool } from './triplanar'
import { setupCustomHome } from './custom-home'

type Listener = { key: string, func: () => void }

const dispose = createAction('threeviewer/viewer/DISPOSE')
export const receive = createAction('threeviewer/viewer/RECEIVE')

export const disableKeyboardListeners = (): AppThunk => () => {
  var listeners = []
    // @ts-ignore
    .concat(setupSelectSimilarMeshes.listeners || [])
    // @ts-ignore
    .concat(setupSelectParentObjects.listeners || [])

  listeners.forEach((obj: Listener) => {
    window.removeEventListener(obj.key, obj.func)
  })
}

export const enableKeyboardListeners = (): AppThunk => () => {
  var listeners = []
    // @ts-ignore
    .concat(setupSelectSimilarMeshes.listeners || [])
    // @ts-ignore
    .concat(setupSelectParentObjects.listeners || [])

  listeners.forEach((obj: Listener) => {
    window.addEventListener(obj.key, obj.func)
  })
}

export const disposeAll = (): AppThunk => (dispatch) => {
  var listeners = []
    // @ts-ignore
    .concat(setupSelectSimilarMeshes.listeners || [])
    // @ts-ignore
    .concat(setupSelectParentObjects.listeners || [])

  listeners.forEach((obj: Listener) => {
    window.removeEventListener(obj.key, obj.func)
  })

  // @ts-ignore
  setupSelectSimilarMeshes.listeners = []
  // @ts-ignore
  setupSelectParentObjects.listeners = []

  dispatch(dispose())
}

export const setup = (): AppThunk => (dispatch: any, getState) => {
  const state = getState()
  const viewer = fromThreeviewerSelectors.getViewer(state)
  const settings = fromThreeviewerSelectors.getSettings(state)

  // @ts-ignore
  window.setupLowRes = () => {
    viewer.scene.traverse((child: any) => {
      if (child.isLight) {
        if (child.castShadow) {
          child.castShadow = false
        } else {
          child.visible = false
        }
      }
    })
  }

  fromThreeviewerCamera.setupCamera(viewer, settings.cameraSettings, dispatch)
  fromThreeviewerCamera.setupControls(viewer, settings, dispatch)
  dispatch(clearTree())
  setupCloneTool(viewer)
  setupPostProcess(dispatch, viewer, settings)
  setupSnapping(viewer, dispatch)
  setupTransformTool(viewer, viewer.transformGizmo, dispatch)
  setupTransformTool(viewer, viewer.triplanarTransformGizmo, dispatch)
  setupPicker(viewer, getState, dispatch)
  setupFileLoaders(viewer, dispatch)
  dispatch(setupTriplanarTool(viewer))
  setupVive(getState, dispatch)
  setupSelectSimilarMeshes(viewer, getState, dispatch)
  setupSelectParentObjects(viewer, getState, dispatch)
  setupAlignTool(viewer, dispatch)
  setupAssembleTool(viewer, dispatch)
  dispatch(setupImageTemplates())
  dispatch(setupCustomHome())

  const { r, g, b } = new viewer.THREE.Color(colors.primary)
  viewer.setOutlineColors(r, g, b)
  viewer.renderer.setClearColor(0xffffff, 0)
  viewer.assetManager.enableDracoDecoding('/go3d-shared/draco/')
  return Promise.resolve()
}

const initialState: Go3DViewer | null = null

export default handleActions<Go3DViewer | null, any>({
  [dispose.toString()]: () => initialState,
  [receive.toString()]: (_, action: { payload: Go3DViewer }) => action.payload
}, initialState)
