import _get from 'lodash/get'

import store from '../stores'

import * as fromUsersSelectors from '../stores/ducks/users/selectors'

export const isAccessibleToUser = (doc, sharedWith) => {
  const userId = fromUsersSelectors.getCurrentId(store.getState())

  return doc.createdBy === userId ||
    _get(sharedWith, userId)
}
