import React, { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import _get from 'lodash/get'

import fetch from '../../../utils/fetch'
import { EXTRACT_STATUSES } from '../../../constants'

import * as fromUploads from '../../../stores/ducks/uploads'
import * as fromAdmin from '../../../stores/ducks/admin'
import * as fromMaterialSearchSelectors from '../../../stores/ducks/material-search/selectors'
import * as fromUploadsSelectors from '../../../stores/ducks/uploads/selectors'

import Modal from '../../common/modal'
import Grid from '../../common/grid/grid'
import Button from '../../common/button'
import Label from '../../common/form/label'
import UploadProgress from '../admin-roomsets/upload-progress'

import InputFile from '../../common/form/input-file'
import InputText from '../../common/form/input-text'
import InputGroup from '../../common/form/input-group'
import InputRadioGroup from '../../common/form/input-radio-group'
import InputCheckbox from '../../common/form/input-checkbox'
import InputSelect from '../../common/form/input-select'
import SeamlessImmutable from 'seamless-immutable'

import styled from 'styled-components'

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const appearanceTypes = [
  { value: 'foil', label: 'Foil (not wood)' },
  { value: 'plastic', label: 'Plastic' },
  { value: 'coating', label: 'Coating' },
  { value: 'lacquer', label: 'Pigment lacquer' },
  { value: 'wood', label: 'Wood expression' },
  { value: 'metal', label: 'Metal or glass' },
  { value: 'transparent', label: 'Transparent' },
  { value: 'fabric', label: 'Fabric' },
  { value: 'pile', label: 'Pile' }
]

type Option = {
  value: string,
  label: string
}

type State = {
  id: string | null,
  name: string,
  type: string,
  enableForTemplateScene: boolean,
  enableUserToSetColor: boolean,
  appearanceType: string,
  isHidden: boolean,
  isPublished: boolean,
  createAppearanceError: string,
  selectedCategories: Option[],
  newCategory: string,
  appearanceCategories: Option[],
  inputValueCategory: string
}

type UploadState = {
  uploadId: string | null,
  fileList: File[] | null
}

// categories is not part of the default state as they are the same for both add and edit modal
const defaultState: Omit<State, 'appearanceCategories'> = {
  id: null,
  name: '',
  type: 'foil',
  enableForTemplateScene: false,
  enableUserToSetColor: true,
  isHidden: false,
  isPublished: false,
  appearanceType: 'ncs',
  createAppearanceError: '',
  selectedCategories: [],
  newCategory: '',
  inputValueCategory: ''
}

const defaultUploadState: UploadState = {
  fileList: null,
  uploadId: null
}

type Props = {
  key?: string
  data?: {
    id: string
    name: string
    type: string,
    isHidden: boolean,
    isPublished: true,
    modelMaterial: boolean,
    canSetColor: boolean,
    colorVariant: string,
    categories: string[]
  }
  files: File[] | null
  isOpen: boolean
  onClose: () => void
}

function AppearanceModal (props: Props) {
  const dispatch = useDispatch()
  const categories = useSelector(fromMaterialSearchSelectors.getCategories)
  const uploads = useSelector(fromUploadsSelectors.getEntries)

  // TODO: This should be split into multiple useState hooks
  const [state, setState] = React.useState<State>(
    {
      ...defaultState,
      // this needs to be mutable otherwise React-select (in InputSelect) will throw error
      appearanceCategories: SeamlessImmutable.asMutable(categories.map((category) => {
        return {
          value: category,
          label: category
        }
      }))
    }
  )

  const [uploadState, setUploadState] = React.useState<UploadState>(
    { ...defaultUploadState }
  )

  const scrollRef = useRef<HTMLInputElement>(null)

  const scrollToBottom = () => {
    const divHeight = scrollRef?.current?.scrollHeight
    const clientHeight = scrollRef?.current?.clientHeight
    const scrollTo = (divHeight && clientHeight) ? (divHeight - clientHeight) : 0
    if (scrollRef.current) scrollRef.current.scrollTo({ top: scrollTo, behavior: 'smooth' })
  }

  React.useEffect(() => {
    // For some reasons categories can be empty and this component is rendered before categories are set
    setState({
      ...state,
      // this needs to be mutable otherwise React-select (in InputSelect) will throw error
      appearanceCategories: SeamlessImmutable.asMutable(categories.map((category) => {
        return {
          value: category,
          label: category
        }
      }))
    })
  }, [categories])

  React.useEffect(() => {
    // only update state if this is an existing appearance. Otherwise it should use the default state
    if (props.data) {
      setState({
        ...state,
        id: props.data.id,
        name: props.data.name,
        type: props.data.type,
        isHidden: props.data.isHidden,
        isPublished: props.data.isPublished,
        enableForTemplateScene: !props.data.modelMaterial,
        enableUserToSetColor: props.data.canSetColor,
        appearanceType: props.data.colorVariant,
        selectedCategories: props.data.categories?.map(category => {
          return { value: category, label: category }
        })
      })
    }
  }, [props.data])

  React.useEffect(() => {
    if (props.files) {
      uploadMaterial(props.files)
    }
  }, [props.files])

  function createAppearance () {
    const isFabric = state.type === 'fabric' || state.type === 'pile'
    const hasCategoryFabric = state.selectedCategories.some((data) => data.value === 'Fabric')

    const newMaterial = {
      canSetColor: state.enableUserToSetColor || (state.type === 'fabric'),
      canSetPrototypeFabricColor: hasCategoryFabric,
      canSetInVR: true,
      categories: state.enableForTemplateScene ? [] : state.selectedCategories.map((data) =>
        data.value
      ),
      colorVariant: state.appearanceType,
      decalMaterial: isFabric,
      displayName: state.name,
      isHidden: true,
      isPublished: false,
      materialType: isFabric ? 'decalMaterial' : 'triplanarMaterial',
      // metalness and roughness are always equal to 1,
      // since they will be multiplied with corresponding textures
      metalness: state.type === 'metal' ? 1 : 0,
      modelMaterial: !state.enableForTemplateScene,
      name: state.name,
      normalScale: '1.0',
      productType: isFabric ? 'soft' : '',
      repeat: { x: 1, y: 1 },
      roughness: 1,
      source: 'file',
      templateMaterial: state.enableForTemplateScene,
      textures: {},
      thumbnail: '',
      type: state.type
    }

    fetch('/api/materials/create', {
      method: 'POST',
      body: JSON.stringify(newMaterial)
    })
      .then(response => response.json())
      .then(response => {
        dispatch(fromAdmin.getAdminMaterials())
        if (!response.id) {
          return Promise.reject(new Error('failed to create material'))
        }
        return response.id
      })
      .then((materialId) => {
        fetch('/api/materialUploads/create', {
          method: 'POST',
          body: JSON.stringify({
            bigFileUploadId: uploadState.uploadId,
            materialId,
          })
        })
      })
      .catch((err) => {
        console.error(err)
      })
    handleClose()
  }

  function isUploadOk (upload: any) {
    const extractStatus = _get(upload, 'extractStatus')
    const extractStatusOk = ![EXTRACT_STATUSES.EXTRACTING, EXTRACT_STATUSES.EXTRACT_FAILED].includes(extractStatus)
    const uploadManifest = _get(upload, 'manifest')
    if (!uploadManifest || !extractStatusOk) {
      return false
    }
    return true
  }

  function handleClose () {
    setState({
      ...state,
      ...defaultState,
    })
    setUploadState({
      ...defaultUploadState
    })
    props.onClose()
  }

  function handleEdit () {
    const isFabric = state.type === 'fabric' || state.type === 'pile'
    const hasCategoryFabric = state.selectedCategories.some((data) => data.value === 'Fabric')

    const editAppearance = {
      id: state.id,
      categories: state.enableForTemplateScene ? [] : state.selectedCategories.map((data) =>
        data.value
      ),
      canSetColor: state.enableUserToSetColor || isFabric,
      canSetPrototypeFabricColor: hasCategoryFabric,
      colorVariant: state.appearanceType,
      decalMaterial: isFabric,
      displayName: state.name,
      materialType: isFabric ? 'decalMaterial' : 'triplanarMaterial',
      metalness: state.type === 'metal' ? 1 : 0,
      modelMaterial: !state.enableForTemplateScene,
      name: state.name,
      productType: isFabric ? 'soft' : '',
      templateMaterial: state.enableForTemplateScene,
      type: state.type
    }
    dispatch(fromAdmin.updateAdminMaterial(editAppearance))
  }

  function getLabel (value: string) {
    const appearanceType = appearanceTypes.find(appearanceType => appearanceType.value === value)
    return appearanceType!.label
  }

  function setNewCategory (newCategory: string) {
    setState({ ...state, appearanceCategories: [...state.appearanceCategories, { value: newCategory, label: newCategory }] })
  }

  async function uploadMaterial (files: File[]) {
    if (files && files.length > 0) {
      const dbUploads = await dispatch(fromUploads.createUploadsFromFiles(
        files,
        { type: 'vray-materials', isBigFile: true }
      )) as any
      const upload = dbUploads[Object.keys(dbUploads)[0]]
      dispatch(fromUploads._uploadBigFiles(
        files,
        dbUploads,
        'vray-materials'
      ))
      setUploadState({
        uploadId: upload.id,
        fileList: files,
      })
    }
  }

  const upload = uploadState.uploadId ? uploads[uploadState.uploadId] : undefined
  const canCreateAppearance =
    (uploadState.uploadId && upload && isUploadOk(upload))

  return (
    <Modal
      key='add-edit-admin-appearance'
      isOpen={props.isOpen}
      onRequestClose={handleClose}
      width={600}
    >
      {state.id && <h2 className='mt0'>Edit appearance</h2>}
      {!state.id && <h2 className='mt0'>New appearance</h2>}
      <Grid
        rows={['1fr', 64]}
        gridGap={0}
        style={{ height: '60vh' }}
      >
        <div ref={scrollRef} className='overflow-y-overlay flex flex-column pr2'>
          {!state.id &&
            <InputGroup className='mb2'>
              <Label>VRAY material upload (.tar.gz or .zip)</Label>

              <ul className='mt0'>
                <li>One vrscene file <span>named material.vrscene</span></li>
                <li>One thumbnail image file <span>named thumbnail.png</span></li>
                <li>All texture image files referenced in the vrscene.</li>
              </ul>

              <div className='flex flex-column'>
                <InputFile
                  value={uploadState.fileList}
                  handleFilelist
                  accept={'application/gzip,application/zip'}
                  onChange={(files: FileList) => {
                    uploadMaterial(Object.values(files))
                  }}
                >
                  Upload
                </InputFile>
                <UploadProgress uploadProgress={upload} />
              </div>
            </InputGroup>
          }
          <InputGroup className='mb2'>
            <Label>Name</Label>
            <InputText
              onChange={(value: string) => { setState({ ...state, name: value }) }}
              placeholder='ex. IKEA BIRCH RAW PLYWOOD'
              value={state.name}
            />
          </InputGroup>

          <div onClick={scrollToBottom}>
            <InputGroup className='mb2'>
              <Label>Appearance type</Label>
              <InputSelect
                options={appearanceTypes}
                defaultValue={{
                  value: state.type ? state.type : 'foil',
                  label: state.type ? getLabel(state.type) : 'Foil (not wood)'
                }}
                onChange={(option: Option) => {
                  setState({ ...state, type: option.value })
                }}
              />
            </InputGroup>
          </div>
          {!state.enableForTemplateScene &&
            <div onClick={scrollToBottom}>
              <InputGroup className='mb2 width-100'>
                <Label>Categories</Label>
                <InputSelect
                  isMulti
                  onInputChange={(inputValue: string) => setState({ ...state, inputValueCategory: inputValue.charAt(0).toUpperCase() + inputValue.slice(1) })}
                  isDisabled={state.newCategory.length > 0}
                  options={state.appearanceCategories}
                  defaultValue={props.data && props.data.categories && props.data.categories.map((category) => {
                    return {
                      value: category.charAt(0).toUpperCase() + category.slice(1),
                      label: category.charAt(0).toUpperCase() + category.slice(1)
                    }
                  })}
                  noOptionsMessage={() => {
                    return (
                      <p
                        onClick={() => setNewCategory(state.inputValueCategory)}
                        className='c-gray-dark-hover pointer'
                      >
                        Add category: <span className='underline c-gray-dark-hover pointer'>{state.inputValueCategory}</span>
                      </p>
                    )
                  }}
                  onChange={(option: Option[]) => {
                    setState({ ...state, selectedCategories: option || [] })
                  }}
                />
              </InputGroup>
            </div>
          }

          {!((state.type === 'fabric') || (state.type === 'pile')) &&
            <InputGroup className='mb1'>
              <Label>Geometry type</Label>
              <InputRadioGroup
                direction='vertical'
                onChange={(value: string) => {
                  setState({
                    ...state,
                    enableForTemplateScene: value === 'enable-for-template-scene'
                  })
                }}
                options={[
                  {
                    name: 'template',
                    label: 'Walls & floor',
                    value: 'enable-for-template-scene',
                    selected: state.enableForTemplateScene
                  },
                  {
                    name: 'models',
                    label: 'Models',
                    value: 'enable-for-models',
                    selected: !state.enableForTemplateScene
                  }
                ]}
              />
            </InputGroup>
          }

          {!((state.type === 'fabric') || (state.type === 'pile')) &&
            <InputGroup className='mb2'>
              <Label>Colorable</Label>
              <InputCheckbox
                name='enable-user-to-set-color'
                label='Colorable: possible to set color'
                value='enable-user-to-set-color'
                checked={state.enableUserToSetColor}
                stopPropagation
                onChange={(checked: boolean) => {
                  setState({
                    ...state,
                    enableUserToSetColor: checked
                  })
                }}
              />
            </InputGroup>
          }
        </div>

        {!state.id &&
          <div className='flex flex-column justify-center items-center mt2 width-100'>
            <Button
              btnType='primary'
              disabled={!canCreateAppearance}
              onClick={() => {
                createAppearance()
              }}
            >
              Create appearance
            </Button>
            <div className='f7 italic c-gray-accessible mt1'>Create appearance will be enabled after upload.</div>
          </div>
        }

        {state.id &&
          <ButtonWrapper>
            <div className='flex flex-row'>
              <Button
                title={state.isHidden ? 'Show inside appearance panel in visualizer' : 'Hide from appearance panel in visualizer'}
                onClick={() => {
                  dispatch(fromAdmin.updateAdminMaterial({
                    id: state.id,
                    isHidden: !state.isHidden
                  }))
                  setState({
                    ...state,
                    isHidden: !state.isHidden
                  })
                }}
              >
                {state.isHidden ? 'Show' : 'Hide'}
              </Button>
              {!state.isHidden &&
                <Button
                  className={'ml1'}
                  title={state.isPublished ? 'Show only for admins' : 'Show for everyone'}
                  onClick={() => {
                    dispatch(fromAdmin.updateAdminMaterial({
                      id: state.id,
                      isPublished: !state.isPublished
                    }))
                    setState({
                      ...state,
                      isPublished: !state.isPublished
                    })
                  }}
                >
                  {state.isPublished ? 'Set as draft' : 'Publish'}
                </Button>
              }
            </div>
            <Button
              btnType='primary'
              onClick={() => {
                handleEdit()
                handleClose()
              }}
            >
              Edit appearance
            </Button>
          </ButtonWrapper>
        }
      </Grid>
    </Modal>
  )
}

export default AppearanceModal
