import * as THREE from 'three'
import { Vector3, Quaternion } from 'three'

export function lerp (a: number, b: number, alpha: number) {
  return a + (b - a) * alpha
}

export function vec2lerp (v1: THREE.Vector2, v2: THREE.Vector2, alpha: number) {
  return new THREE.Vector2(lerp(v1.x, v2.x, alpha), lerp(v1.y, v2.y, alpha))
}

export function vec3lerp (v1: THREE.Vector3, v2: THREE.Vector3, alpha: number) {
  return new THREE.Vector3(
    lerp(v1.x, v2.x, alpha),
    lerp(v1.y, v2.y, alpha),
    lerp(v1.z, v2.z, alpha)
  )
}

export function mat4lerp (m1: THREE.Matrix4, m2: THREE.Matrix4, alpha: number) {
  const result = new THREE.Matrix4()

  result.set(
    lerp(m1.elements[0], m2.elements[0], alpha),
    lerp(m1.elements[1], m2.elements[1], alpha),
    lerp(m1.elements[2], m2.elements[2], alpha),
    lerp(m1.elements[3], m2.elements[3], alpha),
    lerp(m1.elements[4], m2.elements[4], alpha),
    lerp(m1.elements[5], m2.elements[5], alpha),
    lerp(m1.elements[6], m2.elements[6], alpha),
    lerp(m1.elements[7], m2.elements[7], alpha),
    lerp(m1.elements[8], m2.elements[8], alpha),
    lerp(m1.elements[9], m2.elements[9], alpha),
    lerp(m1.elements[10], m2.elements[10], alpha),
    lerp(m1.elements[11], m2.elements[11], alpha),
    lerp(m1.elements[12], m2.elements[12], alpha),
    lerp(m1.elements[13], m2.elements[13], alpha),
    lerp(m1.elements[14], m2.elements[14], alpha),
    lerp(m1.elements[15], m2.elements[15], alpha)
  )

  return result
}

export function positionDifferenceBetweenMatrix4s (
  left: THREE.Matrix4,
  right: THREE.Matrix4
) {
  const t = new THREE.Vector3()
  const t2 = new THREE.Vector3()
  const n = new THREE.Quaternion()
  const nv = new THREE.Vector3()
  left.decompose(t, n, nv)
  right.decompose(t2, n, nv)
  return t.sub(t2)
}

export function rotationDifferenceBetweenMatrix4s (
  left: THREE.Matrix4,
  right: THREE.Matrix4
) {
  const r = new THREE.Quaternion()
  const r2 = new THREE.Quaternion()
  const n = new THREE.Vector3()
  left.decompose(n, r, n)
  right.decompose(n, r2, n)

  return r2.invert().multiply(r)
}

export function clamp (value: number, min: number, max: number) {
  return Math.min(Math.max(value, min), max)
}

export function rotationAlongAxis (axis: THREE.Vector3, angle: number) {
  const hsin = Math.sin(angle / 2)
  const hcos = Math.cos(angle / 2)
  return new THREE.Quaternion(
    axis.x * hsin,
    axis.y * hsin,
    axis.z * hsin,
    hcos
  )
}

export function rotationFromLookingAt (
  source: THREE.Vector3,
  dest: THREE.Vector3
) {
  return new THREE.Quaternion().setFromRotationMatrix(
    new THREE.Matrix4().lookAt(source, dest, new THREE.Vector3(0, 1, 0))
  )
}

export function rotationFromYawPitchRoll (
  yaw: number,
  pitch: number,
  roll: number
) {
  const hsinX = Math.sin(pitch / 2)
  const hcosX = Math.cos(pitch / 2)
  const hsinY = Math.sin(yaw / 2)
  const hcosY = Math.cos(yaw / 2)
  const hsinZ = Math.sin(roll / 2)
  const hcosZ = Math.cos(roll / 2)

  return new THREE.Quaternion(
    hsinX * hcosY * hcosZ - hcosX * hsinY * hsinZ,
    hcosX * hsinY * hcosZ + hsinX * hcosY * hsinZ,
    hcosX * hcosY * hsinZ - hsinX * hsinY * hcosZ,
    hcosX * hcosY * hcosZ + hsinX * hsinY * hsinZ
  )
}

export function yawFromRotation (quaternion: THREE.Quaternion) {
  return Math.atan2(
    2 * quaternion.y * quaternion.w - 2 * quaternion.x * quaternion.z,
    1 - 2 * quaternion.y * quaternion.y - 2 * quaternion.z * quaternion.z
  )
}

export function pitchFromRotation (quaternion: THREE.Quaternion) {
  return Math.atan2(
    2 * quaternion.x * quaternion.w - 2 * quaternion.y * quaternion.z,
    1 - 2 * quaternion.x * quaternion.x - 2 * quaternion.z * quaternion.z
  )
}

export function rollFromRotation (quaternion: THREE.Quaternion) {
  return Math.asin(
    2 * quaternion.x * quaternion.y + 2 * quaternion.z * quaternion.w
  )
}

export function positionFromRotatingAroundPoint (
  point: THREE.Vector3,
  origin: THREE.Vector3,
  axis: THREE.Vector3,
  angle: number
) {
  const distance = new THREE.Vector3().copy(origin).sub(point)
  const q = new THREE.Quaternion().setFromAxisAngle(axis, angle)
  return distance.applyQuaternion(q).add(point)
}

export function projectVectorOntoVector (
  from: THREE.Vector3,
  onto: THREE.Vector3
) {
  const a = new THREE.Vector3().copy(from)
  const b = new THREE.Vector3().copy(onto)
  const dot = a.dot(b)
  return b.sub(a.multiplyScalar(dot)).normalize()
}

/**
 * Takes an arbitary position and moves it so that it is aligned to the surface of
 * the provided sphere arguments
 */
export function positionFromProjectingOntoSphere (
  currentPosition: THREE.Vector3,
  sphereCenter: THREE.Vector3,
  radius: number
) {
  const result = new THREE.Vector3()
  const direction = new THREE.Vector3()
    .copy(currentPosition)
    .sub(sphereCenter)
    .normalize()

  const mx = new THREE.Matrix4().lookAt(
    direction,
    new THREE.Vector3(0, 0, 0),
    new THREE.Vector3(0, 1, 0)
  )
  const qt = new THREE.Quaternion().setFromRotationMatrix(mx)

  result.applyQuaternion(qt)

  result.copy(sphereCenter)
  result.add(direction.multiplyScalar(radius))

  return result
}

export function quaternionFromVec3 (vec: Vector3) {
  const euler = new THREE.Euler().setFromVector3(vec, 'XYZ')
  return new THREE.Quaternion().setFromEuler(euler)
}

export function vec3FromQuaternion (quaternion: Quaternion) {
  const euler = new THREE.Euler().setFromQuaternion(quaternion, 'XYZ')
  return new THREE.Vector3(euler.x, euler.y, euler.z)
}
