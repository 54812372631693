import { connect } from 'react-redux'
import { createSelector } from 'reselect'

import * as fromVive from '../../../../stores/ducks/threeviewer/vive'
import * as fromThreeviewerFilesSelectors from '../../../../stores/ducks/threeviewer/files/selectors'
import * as fromThreeviewerSelectors from '../../../../stores/ducks/threeviewer/selectors'

const mapStateToProps = createSelector(
  fromThreeviewerSelectors.getIsViveAvailable,
  fromThreeviewerFilesSelectors.getIsSceneLoaded,
  (isEnabled, isSceneLoaded) => ({
    disabled: !isSceneLoaded,
    isEnabled
  })
)

const mapDispatchToProps = (dispatch) => ({
  toggleVive: () => dispatch(fromVive.toggleVive())
})

export default connect(mapStateToProps, mapDispatchToProps)
