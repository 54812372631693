import { AppThunk } from '..'

import Immutable from 'seamless-immutable'
import { createAction, handleActions } from 'redux-actions'

import _keyBy from 'lodash/keyBy'
import _filter from 'lodash/filter'
import { ExternalView } from './ExternalView'

const assertError = (msg: string) => (err: Error) => {
  console.error(msg)
  console.error(err)
  throw err
}

const receive = createAction('external/RECEIVE')
export const receiveUpdate = createAction('external/RECEIVE_UPDATE')
const setAsCurrent = createAction('external/SET_AS_CURRENT')
const confirmFeature = createAction('external/CONFIRM_FEATURE')

export const createExternalViewForVariant = (combinationId: string, title: string) : AppThunk => (dispatch, getState, { api }) => {
  return api.external.createExternalViewForVariant(combinationId, title)
    .then((payload: any) => {
      dispatch(receive(payload))
      return payload
    })
    .catch(assertError('Unable to create external view'))
}

const featureIsEnabled : AppThunk<Promise<boolean>> = async (dispatch, getState, { api }) => {
  const state = getState()
  if (state.external.featureEnabled !== null) return state.external.featureEnabled

  // check if user is admin - enable immediately
  const userEntries = state.users.entries as {[id: string]: any}
  const currentUserId = state.users.currentUserId || ''
  if (userEntries[currentUserId].scopes.includes('admin')) {
    dispatch(confirmFeature(true))
    return true
  }

  // check if feature is globally enabled
  const { features } = await api.threeviewer.getSettings()
  if (features && features['share-external-view']) {
    dispatch(confirmFeature(true))
    return true
  }

  dispatch(confirmFeature(false))
  return false
}

export const getExternalViews = (projectId: string) : AppThunk => async (dispatch, getState, { api }) => {
  if (!await dispatch(featureIsEnabled)) return

  return api.external.getAllInProject(projectId)
    .then((payload: any) => {
      payload = _filter(payload, externalView => !externalView.removedAt)
      dispatch(receive(payload))
    })
    .catch(assertError('Unable to get all external views by projectId'))
}

export const setAsCurrentExternalView = (id: string) : AppThunk => (dispatch, getState, { api }) => {
  return api.external.getExternalViewById(id)
    .then((payload) => dispatch(setAsCurrent(payload)))
    .catch(assertError('Unable to get external view by id'))
}

export const recreateArJobForExternalView = (externalViewId: string): AppThunk => (dispatch, getState, { api }) => {
  return api.external.recreateArJobForExternalView(externalViewId)
    .then((payload: any) => dispatch(receive(payload)))
    .catch(assertError('Unable to retrigger AR pipe for specified external view'))
}

const initialState = Immutable<{
  entries: {[id: string]: ExternalView},
  current: null | ExternalView,
  featureEnabled: boolean | null
}>({
  entries: {},
  current: null,
  featureEnabled: null
})

type State = typeof initialState

export default handleActions<State, any>({
  [receive.toString()]: (state, { payload }) => state.merge({
    entries: _keyBy([].concat(payload), 'id')
  }, { deep: true }),
  [receiveUpdate.toString()]: (state, { payload }) => state.setIn(['entries', payload.id], payload),
  [setAsCurrent.toString()]: (state, { payload }) => state.merge({
    current: payload
  }),
  [confirmFeature.toString()]: (state, { payload }) => state.merge({
    featureEnabled: payload
  })
}, initialState)
