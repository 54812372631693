import Immutable from 'seamless-immutable'
import { createAction, handleActions } from 'redux-actions'
import * as queryBuilder from './query-builder'
import * as fromMaterialSearchSelectors from './selectors'
import * as fromUsersSelectors from '../users/selectors'
import * as fromProjectsSelectors from '../projects/selectors'

import _get from 'lodash/get'
import _map from 'lodash/map'
import { AppThunk } from '..'

export const incrementPage = createAction('material-search/INCREMENT_PAGE')
const setIsSearching = createAction<boolean>('material-search/SET_IS_SEARCHING')
export const setQueryString = createAction<string>('material-search/SET_QUERY_STRING')
const setSearchResults = createAction<any[]>('material-search/SET_SEARCH_RESULTS')
export const setShouldPerformFirstDefaultSearch = createAction<boolean>('material-search/SET_SHOULD_PERFORM_DEFAULT_SEARCH')
const setError = createAction<Error | null>('material-search/SET_ERROR')
export const setOnlyStandardAppearances = createAction<boolean>('material-search/SET_SEARCH_ALL')
export const toggleMaterialCategories = createAction<string>('material-search/TOGGLE_MATERIAL_CATEGORIES')
export const toggleMaterialClass = createAction<string>('material-search/TOGGLE_MATERIAL_CLASS')
export const getMaterialCategories = createAction<any[]>('material-search/GET_CATEGORIES')
export const clearAppearanceCategorieFilters = createAction('material-search/CLEAR_APPEARANCE_CATEGORIE_FILTERS')
export const toggleIsUncategorizedSelected = createAction('material-search/TOGGGLE_IS_UNCATEGORIZED_SELECTED')

export const getMaterialCategoriesList = (): AppThunk => {
  return (dispatch, getState, { api }) => {
    const state = getState()
    const isAdmin = fromUsersSelectors.getHasCurrentScopes('admin')(state)
    const query = queryBuilder.categoryQuery(isAdmin)

    api.materialSearch.searchMaterials(query).then((data: any) => {
      const dpdCategories = data.aggregations.agg01.categories.buckets.map((bucket: any) => bucket.key)
      const ikeaCategories = data.aggregations.agg02.categories.buckets.map((bucket: any) => bucket.key)
      const allCats = ikeaCategories.concat(dpdCategories.filter((item: any) => ikeaCategories.indexOf(item) < 0))
      const sortCats = allCats.sort()
      dispatch(getMaterialCategories(sortCats))
    })
  }
}

export const performSearch = (): AppThunk => {
  return (dispatch, getState, { api }) => {
    const state = getState()
    const filters = fromMaterialSearchSelectors.getFilters(state)
    const {
      error,
      page,
      queryString
    } = state.materialSearch

    const options = { pageSize: 40, page }
    const isAdmin = fromUsersSelectors.getHasCurrentScopes('admin')(state)
    const markedAppearances = fromProjectsSelectors.getMarkedAppearances(state)

    let query
    if (!queryString) {
      query = queryBuilder.buildDefaultQuery(filters, options, isAdmin, markedAppearances)
    } else {
      query = queryBuilder.buildQuery(queryString, filters, options, isAdmin, markedAppearances)
    }

    dispatch(setIsSearching(true))
    if (error) {
      dispatch(setError(null))
    }
    api.materialSearch.searchMaterials(query)
      .then((data) => {
        const sanitizedMaterials = _map(_get(data, 'hits.hits'), '_source')
        dispatch(setSearchResults(sanitizedMaterials))
        dispatch(setIsSearching(false))
      })
      .catch((err) => {
        if (err.name === 'AbortError') {
          return // this is ok, caught to avoid spamming log
        }
        dispatch(setError(err.message))
        dispatch(setIsSearching(false))
      })
  }
}

const initialState = Immutable<{
  page: number
  queryString: string
  isSearching: boolean
  shouldPerformFirstDefaultSearch: boolean
  searchResults: any[]
  onlyStandardAppearances: boolean
  error: null | Error
  materialCategories: string[]
  materialClass: string[]
  allMaterialCategories: string[],
  isUncategorizedSelected: boolean,
}>({
  page: 1,
  queryString: '',
  isSearching: false,
  shouldPerformFirstDefaultSearch: true,
  searchResults: [],
  onlyStandardAppearances: true,
  error: null,
  materialCategories: [],
  materialClass: [],
  allMaterialCategories: [],
  isUncategorizedSelected: false
})

type State = typeof initialState

export default handleActions<State, any>({
  [incrementPage.toString()]: (state) => state.merge({
    page: state.page + 1
  }),
  [setIsSearching.toString()]: (state, { payload }) => state.merge({
    isSearching: payload
  }),
  [setQueryString.toString()]: (state, { payload }) => state.merge({
    page: 1,
    queryString: payload
  }),
  [setSearchResults.toString()]: (state, { payload }) => state.merge({
    searchResults: payload
  }),
  [setShouldPerformFirstDefaultSearch.toString()]: (state, { payload }) => state.merge({
    shouldPerformFirstDefaultSearch: payload
  }),
  [setOnlyStandardAppearances.toString()]: (state, { payload }) => state.setIn(['onlyStandardAppearances'], payload),
  [setError.toString()]: (state, { payload }) => state.merge({
    error: payload
  }),
  [toggleMaterialCategories.toString()]: (state, { payload }) => {
    if (state.materialCategories.includes(payload)) {
      return (
        state.merge({
          materialCategories: state.materialCategories.filter(category => category !== payload)
        })
      )
    }
    return state.merge({
      materialCategories: state.materialCategories.concat(payload)
    })
  },
  [toggleMaterialClass.toString()]: (state, { payload }) => {
    if (state.materialClass.includes(payload)) {
      return (
        state.merge({
          materialClass: state.materialClass.filter(mClass => mClass !== payload)
        })
      )
    }
    return state.merge({
      materialClass: state.materialClass.concat(payload)
    })
  },
  [getMaterialCategories.toString()]: (state, { payload }) => state.merge({
    allMaterialCategories: payload
  }),
  [toggleIsUncategorizedSelected.toString()]: (state) => state.merge({
    isUncategorizedSelected: !state.isUncategorizedSelected
  }),
  [clearAppearanceCategorieFilters.toString()]: (state) => state.merge({
    materialClass: [],
    materialCategories: []
  })
}, initialState)
