import _map from 'lodash/map'
import _flatten from 'lodash/flatten'

export const getMaterialValuesFromNodeGroups = (nodeGroups) => {
  return _flatten(_map(nodeGroups, 'meshes')).reduce((acc, node) => {
    return acc.concat({
      node,
      material: Array.isArray(node.material) ? node.material.slice(0) : node.material.clone(),
      userData: Object.assign({}, node.userData)
    })
  }, [])
}

export const getNodesToSetMaterialOn = (nodes) => {
  return nodes.reduce((acc, node) => {
    if (node.geometry) {
      return acc.concat({ node, meshes: [node] })
    }

    if (!node.traverse) return acc

    const childNodes = []

    node.traverse((child) => {
      if (child.geometry) childNodes.push(child)
    })

    return acc.concat({ node, meshes: childNodes })
  }, [])
}

export var ColorTextureMixVal = function (value) {
  /*
  Stains are not 100% opaque when applied to 100%
  Therefore we need to set strenght ot 90% and lie the user.
  */
  this.input = value

  this.values = {
    1.0: 0.9
  }

  const key = Object.keys(this.values).find(key => this.values[key] === this.input)
  this.displayValue = key ? parseFloat(key) : this.input
  this.value = this.input in this.values ? this.values[this.input] : this.input
}
