import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { createSelector } from 'reselect'
import _isEmpty from 'lodash/isEmpty'
import { RootState } from '../../../../stores/ducks'
import {
  PanelSection,
  PanelSectionHeader,
  PanelSectionContent
} from '../../../common/panels/panel-section'
import * as fromRoomsetSelectors from '../../../../stores/ducks/roomsets/selectors'
import { LOCKED_MODES } from '../../../../stores/ducks/threeviewer/camera'

import PredefinedCameraList from './PredefinedCameraList'
import CameraFovControls from './CameraFovControls'
import SavedCameraList from './SavedCameraList'
import AspectRatioAndSafeFrameControls from './AspectRatioAndSafeFrameControls'
import CameraViewControls from './CameraViewControls'

const mapStateToProps = createSelector(
  (state: RootState) => state.threeviewer.camera.mode,
  fromRoomsetSelectors.getRoomsetCameraList,
  (cameraMode, roomsetCameraList) => {
    return {
      cameraMode,
      roomsetCameraList
    }
  }
)

const connector = connect(mapStateToProps)
type PropsFromRedux = ConnectedProps<typeof connector>
type Props = PropsFromRedux & {}

function CameraPanel (props: Props) {
  return (
    <div data-testid='camera-panel' className='height-100 overflow-auto'>
      <PanelSection>
        <PanelSectionHeader title='Camera perspective' />
        <PanelSectionContent>
          <CameraViewControls />
        </PanelSectionContent>
      </PanelSection>

      <PanelSection>
        <PanelSectionHeader title='Field of view' color='gray-dark' />
        <PanelSectionContent>
          <CameraFovControls disabled={!!props.cameraMode} />
        </PanelSectionContent>
      </PanelSection>

      <PanelSection>
        <PanelSectionHeader title='Image proportions' color='gray-dark' />

        <PanelSectionContent>
          <AspectRatioAndSafeFrameControls disabled={!!props.cameraMode} />
        </PanelSectionContent>
      </PanelSection>

      <PanelSection>
        <PanelSectionHeader title='Saved camera perspectives' color='gray-dark' />
        <PanelSectionContent>
          <SavedCameraList data-testid='camera-panel-saved-perspectives' disabled={props.cameraMode === LOCKED_MODES.OFFSET_VIEW_CAMERA} />
        </PanelSectionContent>
      </PanelSection>

      {!_isEmpty(props.roomsetCameraList) && (
        <PanelSection>
          <PanelSectionHeader title='Predefined camera perspectives' color='gray-dark' />
          <PanelSectionContent>
            <PredefinedCameraList disabled={props.cameraMode === LOCKED_MODES.OFFSET_VIEW_CAMERA} />
          </PanelSectionContent>
        </PanelSection>
      )}
    </div>
  )
}

export default connector(CameraPanel)
