import Immutable from 'seamless-immutable'
import {
  handleActions,
  createAction
} from 'redux-actions'
import _isEmpty from 'lodash/isEmpty'
import _keyBy from 'lodash/keyBy'
import _map from 'lodash/map'
import _last from 'lodash/last'

import {
  dataToMap
} from '../../../utils'

import * as api from './api'

import storageApiClient from '../storage-api-client'

const tus = require('tus-js-client')

const error = createAction('uploads/ERROR')
const confirmReceive = createAction('uploads/CONFIRM_RECEIVE')

export const confirmRemove = createAction('upload/CONFIRM_REMOVE')

export const receive = (data, params = {}) => (dispatch) => {
  dispatch(confirmReceive(dataToMap(data, params)))
}

//    3.2 Side Effect Actions
export function get (id) {
  return (dispatch) => {
    return api.get(id)
      .then((data) => dispatch(receive(data)))
      .catch((err) => dispatch(error(err)))
  }
}

export function remove (id) {
  return (dispatch) => {
    return api.remove(id)
      .then((data) => dispatch(receive(data)))
      .catch((err) => dispatch(error(err)))
  }
}

export function retryConvert (id) {
  return (dispatch) => {
    return api.retryConvert(id)
      .then((data) => dispatch(receive(data)))
      .catch((err) => dispatch(error(err)))
  }
}

export const uploadToStorageApi = (files, payload, jobs = []) => (dispatch, _, { api }) => {
  if (_isEmpty(files)) return Promise.resolve()

  return dispatch(createUploadsFromFiles(files, payload, jobs))
    .then((uploadsByFilename) => {
      return storageApiClient
        .upload(files, {
          onUploadProgress (progress) {
            const upload = uploadsByFilename[progress.file]
            if (upload) {
              dispatch(receive({ id: upload.id, progress }))
            }
          }
        })
    })
    .then((manifestKey) => api.storage.getManifest(manifestKey))
    .catch(console.error)
}

// See big file uploader service
const isSupportedArchive = (mimetype) => {
  return RegExp(/gzip/).test(mimetype) || RegExp(/zip/).test(mimetype)
}

/**
 * Creates upload entries in database.
 *
 * @param {*} files list of files to upload
 * @param {*} payload files metadata, added to each file
 * @returns db upload data object, keyed by filename
 */
export const createUploadsFromFiles = (files, payload) => (dispatch, _, { api }) => {
  return api.uploads.create(_map(files, file => {
    const extraData = {}
    if (isSupportedArchive(file.type)) {
      extraData.extractStatus = 'WAITING_TO_PROCESS'
    }
    return {
      ...payload,
      ...extraData,
      filename: file.name
    }
  }))
    .then((json) => [].concat(json))
    .then((uploads) => {
      dispatch(receive(uploads))

      return _keyBy(uploads, 'filename')
    })
}

/**
 * Uploads files to sharedfs/../DPD/storage-big-files/[dev_env]/[subdirectory]/uploadId
 * Uploads are temporarily stored on sharedfs/../DPD/storage-big-files/[dev_env]/[subdirectory]/tusd-uploads
 * Uploadid is required and generated by sending your files to createUploadsFromFiles
 *
 * @param {*} files
 * @param {*} uploadsByFilename generate with createUploadsFromFiles
 * @param {*} subdirectory
 * @returns file manifests with upoadId and complete path locations
 */
export const _uploadBigFiles = (files, uploadsByFilename, subdirectory) =>
  (dispatch) => {
    const handleProgressEvent = (event, file) => {
      if (event.lengthComputable) {
        dispatch(receive({
          id: file.id,
          progress: { uploadedBytes: event.loaded, totalBytes: event.total }
        }))
      }
    }

    const uploads = _map(files, file => {
      return new Promise((resolve, reject) => {
        const upload = new tus.Upload(file, {
          endpoint: '/api/big-file-uploader/upload/files/',
          retryDelays: [0, 3000, 5000, 10000, 20000],
          metadata: {
            filename: file.name,
            filetype: file.type,
            subdirectory
          },
          onError: (err) => {
            dispatch(receive({
              id: uploadsByFilename[file.name].id,
              error: err
            }))
            reject(err)
          },
          onProgress: (loaded, total) => {
            handleProgressEvent({
              total, loaded, lengthComputable: true
            }, uploadsByFilename[file.name])
          },
          onSuccess: () => {
            resolve({ tempId: _last(upload.url.split('/')).split('+')[0], upload: uploadsByFilename[file.name] })
          }
        })
        upload.start()
      })
    })

    return Promise.all(uploads).then(tempUploads => {
      return _map(tempUploads, ({ tempId, upload }) => {
        return window.fetch('/api/big-file-uploader/finalize', {
          method: 'POST',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            uploadId: upload.id,
            extractStatus: upload.extractStatus,
            subdirectory,
            tempId
          })
        }).then(response => response.json())
      })
    })
  }

const initialState = Immutable({
  error: null,
  entries: {}
})

export default handleActions({
  [error]: (state, { payload }) => state.merge({
    error: payload
  }),
  [confirmRemove]: (state, { payload }) => state.merge({
    entries: state.entries.without(payload)
  }),
  [confirmReceive]: (state, { payload }) => state.merge({
    entries: payload
  }, { deep: true })
}, initialState)
