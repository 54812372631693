import * as THREE from 'three'
import { EventEmitter } from 'events'
import _ from 'lodash'
import AnimationManager, { Animation } from '../AnimationManager'
import FirstPersonControls from '../plugins/FirstPersonControls'
import FirstPersonControlsWidget from '../plugins/FirstPersonControls/Widget'
import FiniteStateMachine, { StateData } from '../FiniteStateMachine'
import { Go3DViewer } from '../../types/Go3DViewer'
import ViewerUtils from '../viewer-utils'
import Picker from '../tools/Picker'
import type { SceneGraphMesh, SceneGraphNode3d } from '../../types/SceneGraph'
import { OrbitControls } from '../plugins/OrbitControls'

export type Matrix3x4 = [
  number, number, number, number,
  number, number, number, number,
  number, number, number, number
]

const EPSILON = 0.002
const ROOMSET_NAVIGATION_FOV = 50

const STANDARD_VIEWS = {
  TOP: 'TOP',
  BOTTOM: 'BOTTOM',
  FRONT: 'FRONT',
  BACK: 'BACK',
  LEFT: 'LEFT',
  RIGHT: 'RIGHT'
}

const BEHAVIORS = {
  DEFAULT: 'DEFAULT',
  ROOMSETS: 'ROOMSETS',
  IMAGE_PACKAGE: 'IMAGE_PACKAGE'
}

const LOCKED_MODES = {
  FLOORPLAN: 'FLOORPLAN',
  PREDEFINED: 'PREDEFINED',
  OFFSET_VIEW_CAMERA: 'OFFSET_VIEW_CAMERA'
}

const STATES = {
  DEFAULT: 'DEFAULT',
  ROOMSETS: 'ROOMSETS',
  IMAGE_PACKAGE: 'IMAGE_PACKAGE'
}

const SUBSTATES = {
  FIRST_PERSON: 'FIRST_PERSON',
  FLOORPLAN: 'FLOORPLAN',
  PREDEFINED: 'PREDEFINED',
  ORBIT_FREE: 'ORBIT_FREE',
  ORBIT_SELECTED: 'ORBIT_SELECTED',
  OFFSET_VIEW_CAMERA: 'OFFSET_VIEW_CAMERA',
  DRAWING: 'DRAWING'
}

export default class CameraManager extends EventEmitter {
  private _app: Go3DViewer
  private _domElement: HTMLElement | null
  private _animationManager: AnimationManager
  private _utils: ViewerUtils
  private _picker: Picker & { selection: { [key: string]: any } }
  private _lockEnabledState = false
  private _firstPersonControlsWidget: FirstPersonControlsWidget
  private _sceneBB: null | THREE.Box3 = null
  private _cameraClampingBox: null | THREE.Box3 = null
  private _orbitControls: OrbitControls
  private _firstPersonControls: FirstPersonControls
  private _listeners: { target: any, type: string, handler: any, params?: { [key: string]: any } }[] = []
  private _throttledCheckIfOutsideBB: any
  private _fsm: FiniteStateMachine

  private _boundingObjects: {
    sceneBox: null | THREE.Box3
    expandedSceneBox: null | THREE.Box3
    cameraClampingBox: null | THREE.Box3
    cameraClampingBox0: null | THREE.Box3
    cameraClampingSphere: null | THREE.Sphere
    cameraClampingObject: null | THREE.Box3 | THREE.Sphere
  } = {
    sceneBox: null,
    expandedSceneBox: null,
    cameraClampingBox: null,
    cameraClampingBox0: null,
    cameraClampingSphere: null,
    cameraClampingObject: null
  }

  private _state: {
    savedControlsState: {
      enabled?: boolean
      enableMouse?: boolean
      enableKeys?: boolean
    }
    animations: {
      [name: string]: number | number[] | null
    },
    useSnappingCamera: boolean
    cameraDesiredFov: number
    direction: THREE.Vector3
    isAnimatingNear?: boolean
    isAnimatingFov?: boolean
  } = {
    savedControlsState: {},
    animations: {},
    useSnappingCamera: false,
    cameraDesiredFov: 0,
    direction: new THREE.Vector3()
  }

  camera: THREE.PerspectiveCamera
  cameraParent: any | null
  throttledFov: null | any = null
  controls: OrbitControls | FirstPersonControls | null = null
  cameraFOV0: number

  static BEHAVIORS: typeof BEHAVIORS
  static LOCKED_MODES: typeof LOCKED_MODES
  static STATES: typeof STATES
  static SUBSTATES: typeof SUBSTATES
  static ROOMSET_NAVIGATION_FOV: typeof ROOMSET_NAVIGATION_FOV

  constructor (app: Go3DViewer) {
    super()

    this._app = app
    this._domElement = app.domElement
    this._animationManager = app.animationManager
    this._utils = app.viewerUtils
    this._picker = app.picker
    this.camera = app.camera

    this._firstPersonControlsWidget = new FirstPersonControlsWidget(app)
    this._firstPersonControlsWidget.rejectFromHitFilter = (mesh: SceneGraphMesh) => {
      return _.get(app.objectTracker, `interactions.nodeList.${mesh.sceneGraphID}.userData.isCombination`)
    }
    this._app.firstPersonControlsWidget = this._firstPersonControlsWidget

    this.cameraFOV0 = this.camera.fov

    // TODO: We need to aggregate all states instead of having different truths throughout the app
    this._state = {
      savedControlsState: {},
      animations: {},
      useSnappingCamera: false,
      cameraDesiredFov: this.camera.fov,
      direction: new THREE.Vector3()
    }

    this._orbitControls = new OrbitControls(this.camera, this._domElement)
    this._firstPersonControls = new FirstPersonControls(this.camera, this._domElement)
    this._firstPersonControls.start()

    this._listeners = [
      { target: window, type: 'click', handler: this._handleClick.bind(this) },
      { target: window, type: 'mousedown', handler: this._handleMouseDown.bind(this), params: { useCapture: true } },
      { target: this._orbitControls, type: 'change', handler: this._handleOrbitControlsChange.bind(this) },
      { target: this._firstPersonControls, type: 'change', handler: this._handleFirstPersonControlsChange.bind(this) },
      { target: this._picker, type: 'select', handler: this._handlePickerSelect.bind(this) },
      { target: this._picker, type: 'deselect', handler: this._handlePickerDeselect.bind(this) },
      { target: this._picker, type: 'dblclick', handler: this._handlePickerDblClick.bind(this) }
    ]

    CameraManager.addListeners(this._listeners)

    this._throttledCheckIfOutsideBB = _.throttle(() => this.checkIfOutsideBB(), 50, { trailing: true })

    this._fsm = new FiniteStateMachine({
      initialState: STATES.DEFAULT,
      initialStateData: {
        behavior: BEHAVIORS.DEFAULT
      },
      handlers: [
        {
          from: '*',
          to: STATES.DEFAULT,
          condition: (data) => data.behavior === BEHAVIORS.DEFAULT,
          enter: (_, stateData) => {
            this.useOrbitControls(stateData.target)
            this.resetOrbitControls()
            this._firstPersonControlsWidget.enabled = false
            this._fsm.reset()
          },
          substate: new FiniteStateMachine({
            initialState: SUBSTATES.ORBIT_FREE,
            initialStateData: {
              lockedMode: null
            },
            handlers: [
              {
                from: '*',
                to: SUBSTATES.OFFSET_VIEW_CAMERA,
                condition: (data) => data.lockedMode === LOCKED_MODES.OFFSET_VIEW_CAMERA,
                enter: () => {
                  this.disable()
                },
                leave: () => {
                  this.enable()
                }
              },
              {
                from: '*',
                to: SUBSTATES.ORBIT_FREE,
                condition: (data) => !data.lockedMode,
                enter: (_, stateData) => {
                  const bb = this.getBoundingBoxForDefaultScene()
                  const size = new THREE.Vector3()
                  bb.getSize(size)
                  this.setCameraClampingObjectFromBB(bb.expandByVector(size.multiplyScalar(5)))

                  const { combinationsSelected } = stateData
                  let { target } = stateData

                  if (!target && combinationsSelected) {
                    const bb = this._app.viewerUtils.getBoundingBox(Object.values(this._app.picker.selection))
                    target = bb.getCenter(new THREE.Vector3())
                  }
                  this.useOrbitControls(target)
                }
              },
              {
                from: '*',
                to: SUBSTATES.FLOORPLAN,
                condition: (data) => data.lockedMode === LOCKED_MODES.FLOORPLAN,
                enter: () => {
                  this.enterFloorplan()
                }
              },
              {
                from: '*',
                to: SUBSTATES.DRAWING,
                condition: (data) => data.drawing,
                enter: () => {
                  const target = this._orbitControls.target
                  this.controls = this._orbitControls
                  this.camera.fov = this.cameraFOV0
                  this.desiredFov = this.camera.getEffectiveFOV()
                  this.camera.position.set(target.x, 30, target.z)
                  const lookAt = new THREE.Vector3(target.x, 0, target.z)
                  this.camera.lookAt(lookAt)
                  this.camera.updateMatrixWorld(true)
                  this.camera.updateProjectionMatrix()
                  this.controls.target.copy(lookAt)
                  this.controls.enableRotate = false
                  this._app.renderOnNextFrame()
                },
                leave: () => {
                  const target = this._orbitControls.target
                  this._boundingObjects.cameraClampingSphere = new THREE.Sphere(target, this.camera.far * 0.95)
                  this._boundingObjects.cameraClampingObject = this._boundingObjects.cameraClampingSphere
                  this.controls!.enableRotate = true
                }
              }
            ]
          })
        },
        {
          from: '*',
          to: STATES.ROOMSETS,
          condition: (data) => data.behavior === BEHAVIORS.ROOMSETS,
          substate: new FiniteStateMachine({
            initialState: SUBSTATES.ORBIT_FREE,
            initialStateData: {
              isInsideRoomset: false,
              combinationsSelected: false,
              lockedMode: null
            },
            handlers: [
              {
                from: '*',
                to: SUBSTATES.PREDEFINED,
                condition: (data) => data.lockedMode === LOCKED_MODES.PREDEFINED,
                enter: () => {
                  this.resetCameraClampingBox()
                  this._app.picker.clearSelection()
                  this.resetFirstPersonControls()
                  this._firstPersonControlsWidget.enabled = false
                  this.disable()
                },
                leave: (state, data) => {
                  if (state === SUBSTATES.FLOORPLAN) {
                    this.near = 0.01
                    return
                  }

                  this.enable()
                  this.resetFirstPersonControls()

                  if (state === SUBSTATES.OFFSET_VIEW_CAMERA) {
                    this.near = 0.01
                  } else {
                    const dir = this.camera.getWorldDirection(new THREE.Vector3()).normalize()
                    const endPos = this.camera.position.clone().add(dir.multiplyScalar(this.camera.near))
                    this.leavePredefinedCameraAnimation(endPos, 0.01, ROOMSET_NAVIGATION_FOV, data.throttledFovCb)
                  }
                }
              },
              {
                from: '*',
                to: SUBSTATES.ORBIT_FREE,
                condition: (data) => (
                  !data.isInsideRoomset &&
                  !data.lockedMode &&
                  !data.combinationsSelected
                ),
                enter: () => {
                  this._firstPersonControlsWidget.enabled = false
                  this.useOrbitControls(null, true)
                }
              },
              {
                from: [SUBSTATES.FIRST_PERSON, SUBSTATES.ORBIT_FREE],
                to: SUBSTATES.ORBIT_SELECTED,
                condition: (data) => (
                  data.combinationsSelected &&
                  !data.lockedMode
                ),
                enter: (_, stateData) => {
                  this._firstPersonControlsWidget.enabled = false
                  this.useOrbitControls()
                  this.centerControlsAroundObjects(stateData.selectedCombinations)
                }
              },
              {
                from: [SUBSTATES.FLOORPLAN, SUBSTATES.PREDEFINED],
                to: SUBSTATES.ORBIT_SELECTED,
                condition: (data) => (
                  data.combinationsSelected &&
                  !data.lockedMode
                ),
                enter: () => {
                  this._firstPersonControlsWidget.enabled = false
                  this.centerControlsAroundObjects(this._app.picker.selection)
                  this.useOrbitControls()
                }
              },
              {
                from: [SUBSTATES.PREDEFINED, SUBSTATES.FLOORPLAN],
                to: SUBSTATES.FIRST_PERSON,
                condition: (data) => (
                  data.isInsideRoomset &&
                  !data.lockedMode &&
                  !data.combinationsSelected
                ),
                enter: () => {
                  this.resetFirstPersonControls()
                  this.useFirstPersonControls()
                  this._firstPersonControls.keepCameraY = true
                  this._firstPersonControlsWidget.enabled = true
                }
              },
              {
                from: [SUBSTATES.ORBIT_FREE, SUBSTATES.ORBIT_SELECTED],
                to: SUBSTATES.FIRST_PERSON,
                condition: (data) => (
                  data.isInsideRoomset &&
                  !data.lockedMode &&
                  !data.combinationsSelected
                ),
                enter: () => {
                  this.resetFirstPersonControls()
                  this.useFirstPersonControls()
                  this._firstPersonControls.keepCameraY = true
                  this._firstPersonControlsWidget.enabled = true
                }
              },
              {
                from: '*',
                to: SUBSTATES.FLOORPLAN,
                condition: (data) => data.lockedMode === LOCKED_MODES.FLOORPLAN,
                enter: () => {
                  this.enterFloorplan()
                },
                leave: () => {
                  this.resetCameraClampingBox()
                }
              },
              {
                from: '*',
                to: SUBSTATES.OFFSET_VIEW_CAMERA,
                condition: (data) => data.lockedMode === LOCKED_MODES.OFFSET_VIEW_CAMERA,
                enter: () => {
                  this.disable()
                },
                leave: () => {
                  this.enable()
                }
              }
            ]
          })
        },
        {
          from: '*',
          to: STATES.IMAGE_PACKAGE,
          condition: (data) => data.behavior === BEHAVIORS.IMAGE_PACKAGE,
          enter: () => {
            this.disable()
            this.lockEnabledState = true
          },
          leave: () => {
            this.lockEnabledState = false
          }
        }
      ]
    })

    this._fsm.on('change', (data) => {
      this.emit('stateChange', data)
    })
  }

  public change (incomingData: StateData, cb = () => {}) {
    this._fsm.change(incomingData)
    cb()
  }

  set lockEnabledState (val: boolean) {
    this._lockEnabledState = val
  }

  get lockEnabledState () {
    return this._lockEnabledState
  }

  public disable (inputType?: string) {
    if (this._lockEnabledState) return

    if (!inputType && this.controls?.enabled) {
      this._state.savedControlsState.enabled = this.controls.enabled
      this.controls.enabled = false
    }

    if (inputType === 'mouse' && this.controls?.enableMouse) {
      this._state.savedControlsState.enableMouse = this.controls.enableMouse
      this.controls.enableMouse = false
    }

    if (inputType === 'keys' && this.controls?.enableKeys) {
      this._state.savedControlsState.enableKeys = this.controls.enableKeys
      this.controls.enableKeys = false
    }
  }

  public enable (inputType?: string | null, force = false) {
    if (this._lockEnabledState || !this.controls) return

    if (!inputType) {
      this.controls.enabled = force || _.get(this._state.savedControlsState, 'enabled', true)
    }

    if (inputType === 'mouse') {
      this.controls.enableMouse = force || _.get(this._state.savedControlsState, 'enableMouse', true)
    }

    if (inputType === 'keys') {
      this.controls.enableKeys = force || _.get(this._state.savedControlsState, 'enableKeys', true)
    }
  }

  get state () { return this._fsm.state }

  get substate () { return this._fsm.substate }

  get stateData () { return this._fsm.stateData }

  set useSnappingCamera (val: boolean) { this._state.useSnappingCamera = val }
  get useSnappingCamera () { return this._state.useSnappingCamera }

  get desiredFov () { return this._state.cameraDesiredFov }

  set desiredFov (fov) {
    this._state.cameraDesiredFov = fov
    this.emit('fov-change', fov)
  }

  get near () { return this.camera.near }

  set near (near) {
    this.camera.near = near
  }

  // Event handlers
  private _handleMouseDown (event: MouseEvent & { target: null | HTMLElement }) {
    if (event.target?.contains(this._domElement)) {
      this.enable('keys')
    } else {
      this.disable('keys')
    }
  }

  private _handleClick (event: MouseEvent & { target: null | HTMLElement }) {
    if (event.target?.contains(this._domElement)) {
      this._domElement && this._domElement.focus()
    }
  }

  private _handleControlsChange () {
    if (this._fsm.state === STATES.ROOMSETS) {
      this._throttledCheckIfOutsideBB()
    }

    if (this._fsm.substate === SUBSTATES.DRAWING) {
      this.camera.position.y = THREE.MathUtils.clamp(this.camera.position.y, 1, this.camera.far * 0.9)
    } else if (this._boundingObjects.cameraClampingObject) {
      this._boundingObjects.cameraClampingObject.clampPoint(this.camera.position, this.camera.position)
    }

    this.emit('change')
  }

  private _handleFirstPersonControlsChange () {
    if (this.controls && this.controls.isFirstPersonControls) {
      this._handleControlsChange()
    }
  }

  private _handleOrbitControlsChange () {
    if (this.controls && this.controls.isOrbitControls) {
      this._handleControlsChange()
    }
  }

  private _handlePickerDblClick (objects: SceneGraphNode3d[]) {
    if (!this.controls?.enabled) return

    if (this._fsm.substate === SUBSTATES.FLOORPLAN) {
      return this.camera2DZoomToObjects(objects)
    }

    const hitCombination = objects.find(obj => obj.userData.modelType === 'combination')

    const shouldFitToScreen = (
      this._fsm.state === STATES.DEFAULT ||
      (
        this._fsm.state === STATES.ROOMSETS &&
        hitCombination
      )
    )

    if (shouldFitToScreen) {
      this.animateFitObjectsInScreen(objects, (target) => {
        if (target) {
          this.useOrbitControls(target)
          this.centerControlsAroundObjects(objects)
          this.enable('mouse')
        }
      })
    } else {
      this.centerControlsAroundObjects(objects)
    }
  }

  private _handlePickerSelect (objects: SceneGraphNode3d[]) {
    if (!this.controls?.enabled) return
    if (!objects.length) return

    const hitCombination = objects.find(obj => obj.userData.modelType === 'combination')
    if (this._fsm.state === STATES.ROOMSETS) {
      if (hitCombination && this._fsm.substate === SUBSTATES.FIRST_PERSON) {
        this.change({
          combinationsSelected: true,
          selectedCombinations: objects
        })
      }

      if (this._boundingObjects.expandedSceneBox && CameraManager.getIsPointInsideBB(this.camera.position, this._boundingObjects.expandedSceneBox)) {
        this.change({
          isInsideRoomset: true
        })
      }
    }

    this.centerControlsAroundObjects(objects)
  }

  private _handlePickerDeselect () {
    if (!this.controls?.enabled) return

    if (this._fsm.state === STATES.ROOMSETS && !this._fsm.stateData.lockedMode) {
      this.change({
        isInsideRoomset: this._boundingObjects.expandedSceneBox
          ? CameraManager.getIsPointInsideBB(this.camera.position, this._boundingObjects.expandedSceneBox)
          : this._fsm.stateData.isInsideRoomset,
        combinationsSelected: false
      })
    }
  }

  private checkIfOutsideBB (force = false) {
    const canChangeControls = (
      this._fsm.state === STATES.ROOMSETS &&
      this._fsm.substate !== SUBSTATES.FLOORPLAN &&
      !this._fsm.stateData.combinationsSelected
    )

    if (canChangeControls || force) {
      this.change({
        isInsideRoomset: this._boundingObjects.expandedSceneBox
          ? CameraManager.getIsPointInsideBB(this.camera.position, this._boundingObjects.expandedSceneBox)
          : this._fsm.stateData.isInsideRoomset
      })
    }
  }

  // dispose and resets
  public dispose () {
    CameraManager.removeListeners(this._listeners)

    this._firstPersonControls.dispose()
    this._orbitControls.dispose()

    this._state.savedControlsState = {}
    this._domElement = null
    this.controls = null
    this.cameraParent = null
  }

  private resetOrbitControlsSelection () {
    if (this._orbitControls) {
      this._orbitControls.selected = false
    }
  }

  private resetFirstPersonControls () {
    this._firstPersonControls.resetYawAndPitch()
    this._firstPersonControls.lockedRotationMode = false
    this._firstPersonControlsWidget.enabled = true
  }

  private resetOrbitControls () {
    this._orbitControls.reset()
  }

  // camera methods
  public updateCameraSize (width: number, height: number, safeFrameHeight: number) {
    // NOTE: Monkey path!
    // In some cases we might call updateCameraSize after CameraManager has been disposed.
    // If no camera exist on the object, we get a nasty error. Will need to rewrite CameraManager to TS to
    // make it easier to pick up on these kind of nasty things.
    const safeFrameFov = this._state.cameraDesiredFov * Math.PI / 180
    const aspect = width / height

    this.camera.aspect = aspect

    if (!safeFrameHeight) {
      this.camera.fov = safeFrameFov * (180 / Math.PI)
    } else {
      this.camera.fov = 2 * (180 / Math.PI) * Math.atan(((height / 2) * Math.tan(safeFrameFov / 2)) / safeFrameHeight)
    }

    this.camera.updateProjectionMatrix()
  }

  public setCameraClampingObjectFromBB (bb: THREE.Box3, expandBySize = false) {
    if (bb && bb.isBox3) {
      this._boundingObjects.sceneBox = bb.clone()
      this._boundingObjects.expandedSceneBox = bb.clone().expandByVector(new THREE.Vector3(4, 2, 4))
      this._boundingObjects.cameraClampingBox = bb.clone()
      if (expandBySize) {
        this._boundingObjects.cameraClampingBox.expandByVector(bb.getSize(new THREE.Vector3()))
      }

      const center = this._boundingObjects.cameraClampingBox.getCenter(new THREE.Vector3())
      this._boundingObjects.cameraClampingSphere = new THREE.Sphere(
        center,
        center.distanceTo(this._boundingObjects.cameraClampingBox.max)
      )
      this._boundingObjects.cameraClampingBox0 = this._boundingObjects.cameraClampingBox.clone()
      this._boundingObjects.cameraClampingObject = this._boundingObjects.cameraClampingSphere
    } else {
      this._boundingObjects.cameraClampingBox = null
      this._boundingObjects.cameraClampingBox0 = null
    }
  }

  private resetCameraClampingBox () {
    if (this._boundingObjects.cameraClampingBox && this._boundingObjects.cameraClampingBox0) {
      this._boundingObjects.cameraClampingBox = this._boundingObjects.cameraClampingBox0.clone()
      this._boundingObjects.cameraClampingObject = this._boundingObjects.cameraClampingSphere
    }
  }

  public getCameraSettings () {
    const camera = this.camera
    const cameraTransform = CameraManager.getTransform(camera.matrixWorld)
    const cameraFov = THREE.MathUtils.degToRad(camera.fov)

    return {
      fov: cameraFov,
      transform: cameraTransform,
      target: this.controls?.target,
      near: camera.near,
      far: camera.far
    }
  }

  public setCameraSettings ({
    transform,
    fov,
    target,
    near,
    far
  }: {
    transform: Matrix3x4
    fov: number
    target: THREE.Vector3
    near: number
    far: number
  }, cb = (settings: null | { fov: number, transform: number[], target: THREE.Vector3, near: number, far: number }) => { }) {
    if (transform) {
      if (!_.every(transform, (x) => !isNaN(x))) {
        return console.warn('Invalid transform.', transform)
      }

      const matrix = CameraManager.fromAffinityMatrixToMatrix4(transform)

      if (this.controls && this.controls.isOrbitControls) {
        if (!target) {
          const targetWS = this._orbitControls.target.clone()
          const targetLS = this.camera.worldToLocal(targetWS.clone())

          this.camera.position.setFromMatrixPosition(matrix)
          this.camera.rotation.setFromRotationMatrix(matrix)
          this.camera.updateMatrixWorld(true)

          const targetWS2 = this.camera.localToWorld(targetLS.clone())
          this._orbitControls.target.copy(targetWS2)
        } else {
          this.camera.position.setFromMatrixPosition(matrix)
          this.camera.rotation.setFromRotationMatrix(matrix)
          this.camera.updateMatrixWorld(true)
          this._orbitControls.target.copy(target)
        }
      }

      if (this.controls && this.controls.isFirstPersonControls) {
        this._firstPersonControls.stop()
        this._firstPersonControls.setCameraState(matrix)
        this._firstPersonControls.start()
      }
    }

    this.camera.fov = THREE.MathUtils.radToDeg(fov)
    this.near = near
    if (far) {
      this.camera.far = far
    }

    this.desiredFov = this.camera.getEffectiveFOV()
    this.camera.updateProjectionMatrix()

    cb(this.getCameraSettings())
  }

  private setCameraView (cameraView: string, bb: THREE.Box3) {
    const camera = this.camera
    const offset = 1.25
    const center = bb.getCenter(new THREE.Vector3())
    const size = bb.getSize(new THREE.Vector3())

    const position = center.clone()
    const direction = new THREE.Vector3()

    let screenX = 0
    let screenY = 0
    let screenZ = 0

    // X Axis
    if ([STANDARD_VIEWS.LEFT, STANDARD_VIEWS.RIGHT].includes(cameraView)) {
      direction.x = cameraView === STANDARD_VIEWS.LEFT ? 1 : -1
      screenX = size.z
      screenY = size.y
      screenZ = size.x
    }

    // Y Axis
    if ([STANDARD_VIEWS.TOP, STANDARD_VIEWS.BOTTOM].includes(cameraView)) {
      direction.y = cameraView === STANDARD_VIEWS.TOP ? 1 : -1
      screenX = size.x
      screenY = size.z
      screenZ = size.y
    }

    // Z Axis
    if ([STANDARD_VIEWS.FRONT, STANDARD_VIEWS.BACK].includes(cameraView)) {
      direction.z = cameraView === STANDARD_VIEWS.FRONT ? 1 : -1
      screenX = size.x
      screenY = size.y
      screenZ = size.z
    }

    const { dimension, fov } = CameraManager.getOptimalFovAndDimension(
      screenX,
      screenY,
      camera.aspect,
      camera.fov
    )
    const distance = CameraManager.getAdjacent(dimension * 0.5, fov * 0.5)

    position.add(direction.clone().multiplyScalar(offset * (distance + screenZ * 0.5)))
    camera.position.copy(position)
    camera.lookAt(center)
    camera.far = camera.far + distance
    camera.updateMatrixWorld(true)
    camera.updateProjectionMatrix()

    this._state.direction.copy(direction)
  }

  // controls methods
  public useFirstPersonControls () {
    if (this.controls) {
      this._orbitControls.enabled = false
      if (this.controls.isFirstPersonControls) return
    }

    this.controls = this._firstPersonControls
    this._firstPersonControlsWidget.enabled = true

    this.controls.start()
  }

  public updateOrbitControls () {
    this._orbitControls.update()
  }

  public useOrbitControls (target?: THREE.Vector3 | null, fakeCenterSelection = false) {
    if (this.controls) {
      this._firstPersonControls.enabled = false
      this._firstPersonControlsWidget.enabled = false
    }

    if (this.controls && !this.controls.isOrbitControls) {
      this._orbitControls.mouseButtons = {
        LEFT: THREE.MOUSE.ROTATE,
        RIGHT: THREE.MOUSE.PAN,
        MIDDLE: THREE.MOUSE.DOLLY
      }

      this._orbitControls.enabled = true
      this._orbitControls.enableRotate = true

      if (fakeCenterSelection) {
        this._orbitControls.selectedObjectBB = this._boundingObjects.sceneBox
        this._orbitControls.selected = true
      }
    }

    var selectedCombinationModels = Object.keys(this._picker.selection).reduce((filtered: { [key: string]: SceneGraphNode3d }, key) => {
      if (this._picker.selection[key].userData === 'combination') filtered[key] = this._picker.selection[key]
      return filtered
    }, {})

    if (target) {
      this._orbitControls.target.copy(target)
    } else if (this.camera && Object.keys(selectedCombinationModels).length === 0 && this._sceneBB !== null) {
      var dir = this.camera.getWorldDirection(new THREE.Vector3()).normalize()
      var targetPos = new THREE.Vector3()
      if (this._boundingObjects.sceneBox) {
        targetPos.addVectors(this.camera.position, (dir.multiplyScalar(this._boundingObjects.sceneBox.max.length())))
      }
      this._orbitControls.target.copy(targetPos)
    }
    this.controls = this._orbitControls
  }

  public centerControlsAroundObjects (objects: { [id: string]: SceneGraphNode3d } | SceneGraphNode3d[]) {
    if (!_.isEmpty(objects)) {
      const bb = this._utils.getBoundingBox(Object.values(objects))
      this._orbitControls.selectedObjectBB = bb
      this._orbitControls.selected = true
    } else {
      this._orbitControls.selectedObjectBB = null
      this._orbitControls.selected = false
    }
  }

  // controls and camera
  public update (deltaTime: number) {
    if (this.controls && this.controls.isFirstPersonControls) {
      // TODO: Handle enabled state with FSM. This is messy.
      if (this._fsm.substate !== SUBSTATES.PREDEFINED) {
        this._firstPersonControls.update(deltaTime)
      }
    }
  }

  public enterFloorplan () {
    this.enable(null, true)
    this.resetFirstPersonControls()
    this.useFirstPersonControls()

    const bb = this._fsm.state === STATES.ROOMSETS
      ? this.getBoundingBoxForRoomsetScene()
      : this.getBoundingBoxForDefaultScene()

    this.setCameraView(STANDARD_VIEWS.TOP, bb)
    this.setCameraClampingObjectFromBB(bb, true)
    this._firstPersonControlsWidget.enabled = false

    this._firstPersonControls.keepCameraY = false
    this._firstPersonControls.lockedRotationMode = true
    this._firstPersonControls.resetYawAndPitch()

    this._boundingObjects.cameraClampingBox = this._boundingObjects.cameraClampingBox?.clone() ?? new THREE.Box3()
    this._boundingObjects.cameraClampingBox.max.y = this.camera.position.y * 2
    this._boundingObjects.cameraClampingBox.min.y = bb.max.y * 2
    this._boundingObjects.cameraClampingObject = this._cameraClampingBox
  }

  // animations
  private _removeAnimations () {
    Object.entries(this._state.animations).forEach(([key, idOrIds]) => {
      if (idOrIds !== null) {
        ;(typeof idOrIds === 'object' ? idOrIds : [idOrIds])
          .forEach(id => this._animationManager.removeAnimation(id))
        this._state.animations[key] = null
      }
    })
  }

  public cameraLookAt (point: THREE.Vector3) {
    this._orbitControls.target.copy(point)
    if (this.camera) this.camera.lookAt(point)
  }

  public moveToPoint (point: THREE.Vector3, cb = () => { }, params: { delay?: number, rotation?: THREE.Euler, onUpdate?: () => {} } = {}) {
    if (!point || !point.isVector3) return

    const delay = params.delay ?? 0.3
    const onUpdate = params.onUpdate ?? (() => {})

    this._removeAnimations()
    const animation = new Animation(this.camera.position)
    this._state.animations.move = animation.id
    if (params.rotation) {
      this.camera.rotation.copy(params.rotation)
    }
    this._animationManager
      .addAnimation(animation)
      .delay(delay)
      .lerpTo(point, 1.5)
      .onUpdate(onUpdate)
      .endIf((current: THREE.Vector3, target: THREE.Vector3) => current.distanceTo(target) < 0.02)
      .onEnd(() => {
        this.checkIfOutsideBB(true)
        this._state.animations.move = null
        this._handleControlsChange()
        cb()
      })
  }

  private camera2DZoomToObjects (objects: SceneGraphNode3d[], cb = (position: THREE.Vector3) => {}) {
    //  This method presumes that the camera is only rotated on one axis.
    //  i.e. one of the standard views (top, left, right, bottom, front, back) are activated.
    if (!this.controls?.enabled) return
    if (!objects || Object.values(objects).length === 0) return

    const offset = 2
    const bb = this._utils.getBoundingBox(Object.values(objects))
    const center = bb.getCenter(new THREE.Vector3())
    const size = bb.getSize(new THREE.Vector3())

    let screenX = 0
    let screenY = 0
    let screenZ = 0

    const direction = this._state.direction.clone()

    if (direction.x !== 0) {
      screenX = size.z
      screenY = size.y
      screenZ = size.x
    }

    if (direction.y !== 0) {
      screenX = size.x
      screenY = size.z
      screenZ = size.y
    }

    if (direction.z !== 0) {
      screenX = size.x
      screenY = size.y
      screenZ = size.z
    }

    const { dimension, fov } = CameraManager.getOptimalFovAndDimension(screenX, screenY, this.camera.aspect, this.camera.fov)
    const distance = CameraManager.getAdjacent(dimension * 0.5, fov * 0.5)
    const targetPosition = center.clone().add(direction.multiplyScalar(offset * (distance + screenZ * 0.5)))

    this._removeAnimations()
    const animation = new Animation(this.camera.position)
    this._state.animations.zoom2d = animation.id

    this._animationManager
      .addAnimation(animation)
      .lerpTo(targetPosition, 0.7)
      .endIf((current: THREE.Vector3, target: THREE.Vector3) => current.distanceTo(target) < 0.02)
      .onEnd(() => {
        this._state.animations.zoom2d = null
        this._firstPersonControls.resetYawAndPitch()
        this._handleControlsChange()
        cb(this.camera.position)
      })
  }

  public async animateFitObjectsInScreen (objects: SceneGraphNode3d[], cb = (target: THREE.Vector3) => {}) {
    if (!this.controls?.enabled) return
    if (!objects || objects.length === 0) return

    const bb = this._utils.getBoundingBox(objects)
    const center = bb.getCenter(new THREE.Vector3())

    const startRotation = this.camera.rotation.clone()
    this.camera.lookAt(center)
    const endRotation = this.camera.rotation.clone()
    this.camera.rotation.copy(startRotation)

    const qStart = new THREE.Quaternion().setFromEuler(startRotation)
    const qEnd = new THREE.Quaternion().setFromEuler(endRotation)

    const targetPosition = this.getFitBBInScreenPosition(this.camera.position.clone(), bb)

    this._removeAnimations()
    const rotationAnimation = new Animation(qStart)
    const positionAnimation = new Animation(this.camera.position)
    this._state.animations.fit = [rotationAnimation.id, positionAnimation.id]

    const rotationPromise = new Promise((resolve) => {
      return this._animationManager
        .addAnimation(rotationAnimation)
        .lerpTo(qEnd, 0.5)
        .onUpdate((current: THREE.Quaternion) => this.camera.rotation.setFromQuaternion(current))
        .endIf((current: THREE.Quaternion, target: THREE.Quaternion) => Math.abs(current.angleTo(target)) < EPSILON)
        .onEnd(resolve)
    })

    const positionPromise = new Promise((resolve) => {
      return this._animationManager
        .addAnimation(positionAnimation)
        .delay(0.3)
        .lerpTo(targetPosition, 0.7)
        .endIf((current: THREE.Vector3, target: THREE.Vector3) => current.distanceTo(target) < 0.02)
        .onEnd(resolve)
    })

    await Promise.all([rotationPromise, positionPromise])

    this._state.animations.fit = null
    this._firstPersonControls.resetYawAndPitch()
    this._handleControlsChange()
    cb(center)
  }

  private animateCameraHeight (y: number, cb = () => { }) {
    if (
      !this.controls?.enabled ||
      !this.controls?.isFirstPersonControls ||
      !this.controls?.keepCameraY ||
      this.camera.position.y === y ||
      isNaN(y)
    ) {
      return
    }

    const targetPosition = this.camera.position.clone().setY(y)

    this._removeAnimations()
    const animation = new Animation(this.camera.position)
    this._state.animations.height = animation.id

    this._animationManager
      .addAnimation(animation)
      .lerpTo(targetPosition, 1)
      .endIf((current: THREE.Vector3, target: THREE.Vector3) => current.distanceTo(target) < 0.02)
      .onEnd(() => {
        this._state.animations.height = null
      })
  }

  private animateCameraFov (fov: number, onUpdate: (fov: number) => {}) {
    if (this._state.isAnimatingFov) return

    this._state.isAnimatingFov = true
    const fovAnimation = new Animation(this.camera.fov)

    return new Promise<void>((resolve) => {
      return this._animationManager
        .addAnimation(fovAnimation)
        .lerpTo(fov, 1)
        .onUpdate((current: number) => {
          this.camera.fov = current
          this.camera.updateProjectionMatrix()
          onUpdate(current)
        })
        .endIf((current: number, target: number) => Math.abs(current - target) < EPSILON)
        .onEnd(() => {
          onUpdate(fov)
          this._state.isAnimatingFov = false
          this.camera.fov = fov
          this.camera.updateProjectionMatrix()
          this.desiredFov = this.camera.getEffectiveFOV()
          resolve()
        })
    })
  }

  private animateCameraNear (near: number) {
    if (this._state.isAnimatingNear) return

    this._state.isAnimatingNear = true

    const nearAnimation = new Animation(this.camera.near)

    return new Promise<void>((resolve) => {
      return this._animationManager
        .addAnimation(nearAnimation)
        .lerpTo(near, 1)
        .onUpdate((current: number) => {
          this.near = current
          this.camera.updateProjectionMatrix()
        })
        .endIf((current: number, target: number) => Math.abs(current - target) < EPSILON)
        .onEnd(() => {
          this._state.isAnimatingNear = false
          this.near = near
          resolve()
        })
    })
  }

  private leavePredefinedCameraAnimation (finalPosition: THREE.Vector3, finalNear: number, finalFov: number, onUpdate: (fov: number) => {}) {
    const animation = new Animation(0)
    const lerpFactor = 3
    return new Promise<void>((resolve) => {
      return this._animationManager
        .addAnimation(animation)
        .lerpTo(100, lerpFactor)
        .onUpdate((current: number, target: number, interpolationFactor: number) => {
          const fovVector = new THREE.Vector2(this.camera.fov, 0)
          const targetFovVector = new THREE.Vector2(finalFov, 0)
          this.camera.fov = fovVector.lerp(targetFovVector, interpolationFactor).x

          const nearVector = new THREE.Vector2(this.camera.near, 0)
          const targetNearVector = new THREE.Vector2(finalNear, 0)
          this.near = nearVector.lerp(targetNearVector, interpolationFactor).x
          this.camera.updateProjectionMatrix()

          this.camera.position.copy(this.camera.position.clone().lerp(finalPosition, interpolationFactor))

          onUpdate && onUpdate(this.camera.fov)
          this.camera.updateProjectionMatrix()
        })
        .endIf((current: number, target: number) => current >= 99)
        .onEnd(() => {
          this.camera.position.copy(finalPosition)
          this.near = finalNear
          resolve()
        })
    })
  }

  // utils
  public calculateFovScale (fov: number) { return fov / this.cameraFOV0 }

  public getIsInsideRoomset () {
    if (this._boundingObjects.expandedSceneBox) {
      return CameraManager.getIsPointInsideBB(this.camera.position, this._boundingObjects.expandedSceneBox)
    }
    return false
  }

  private getBoundingBoxForDefaultScene () {
    return this._app.viewerUtils.getSceneBoundingBox(this._app.scene, (node: SceneGraphMesh) => {
      return (
        node.geometry &&
        node.userData && node.userData.materialId !== 'DEFAULT_VRAYPLANE'
      )
    })
  }

  private getBoundingBoxForRoomsetScene () {
    const objects: SceneGraphNode3d[] = []
    this._app.scene.children.forEach((node: SceneGraphNode3d) => {
      if (!node.userData || node.userData.modelType !== 'roomset') return

      if (node.userData.isModelRoot) {
        objects.push(node)
      } else {
        node.children.forEach((childNode) => {
          if (childNode.userData.isModelRoot) {
            objects.push(childNode)
          }
        })
      }
    })
    return this._app.viewerUtils.getBoundingBox(objects)
  }

  public getFitBBInScreenPosition (position: THREE.Vector3, bb: THREE.Box3) {
    if (!bb.isBox3) return

    const center = bb.getCenter(new THREE.Vector3())

    const direction = position.sub(center).normalize()

    // TODO: For a more correct result we need to calculate this dimension
    // based on which angle the camera looks at the object bb
    const maxDimension = bb.min.distanceTo(bb.max)

    const fov = Math.min(this.camera.fov, this.camera.fov * this.camera.aspect)
    const adjacent = CameraManager.getAdjacent(maxDimension * 0.5, fov * 0.5)
    const distance = adjacent + maxDimension * 0.5
    return center.clone().add(direction.multiplyScalar(distance))
  }

  public fitBBInScreen (bb: THREE.Box3) {
    if (!this.controls?.enabled) return
    if (!bb.isBox3) return

    const center = bb.getCenter(new THREE.Vector3())

    const startRotation = this.camera.rotation.clone()
    this.camera.lookAt(center)
    this.camera.rotation.copy(startRotation)

    const targetPosition = this.getFitBBInScreenPosition(this.camera.position.clone(), bb)

    if (targetPosition) this.camera.position.copy(targetPosition)
    this.camera.lookAt(center)
    this._orbitControls.target.copy(center)
    this._firstPersonControls.resetYawAndPitch()

    this._handleControlsChange()
  }

  private static getIsPointInsideBB (point: THREE.Vector3, bb: THREE.Box3) {
    if (!bb) return true
    return bb.containsPoint(point)
  }

  private static addListeners (listeners: { target: any, type: string, handler: any, params?: { [key: string]: any } }[]) {
    listeners.forEach(({ target, type, handler, params }) => {
      if (target.addEventListener) target.addEventListener(type, handler, params)
      if (target.on) target.on(type, handler)
    })
  }

  private static removeListeners (listeners: { target: any, type: string, handler: any, params?: { [key: string]: any } }[]) {
    listeners.forEach(({ target, type, handler, params }) => {
      if (target.removeEventListener) target.removeEventListener(type, handler, params)
      if (target.removeListener) target.removeListener(type, handler)
    })
  }

  private static getAdjacent (size: number, halfFovInDeg: number) {
    // adjacent equals opposite over tan of angle
    return size / Math.tan(halfFovInDeg * THREE.MathUtils.DEG2RAD)
  }

  private static getOptimalFovAndDimension (screenX: number, screenY: number, aspect: number, vFov: number) {
    if (screenX / screenY > aspect) {
      return {
        fov: Math.atan(Math.tan(vFov * 0.5 * THREE.MathUtils.DEG2RAD) * aspect) * 2 * THREE.MathUtils.RAD2DEG,
        dimension: screenX
      }
    }
    return {
      fov: vFov,
      dimension: screenY
    }
  }

  public static getTransform (mat: THREE.Matrix4, posMultiple = 1) {
    const scaleAndRotation = new THREE.Matrix3().setFromMatrix4(mat).toArray()

    const pos = new THREE.Vector3()
      .setFromMatrixPosition(mat)
      .multiplyScalar(posMultiple)
      .toArray()

    return scaleAndRotation.concat(pos)
  }

  public static moveAffinityMatrixForwardMultipliedByNear (transform: Matrix3x4, near: number) {
    // 6, 7, 8 = forward
    // 9, 10, 11 = position
    const t = [...transform]
    t[9] += (-t[6] * near)
    t[10] += (-t[7] * near)
    t[11] += (-t[8] * near)
    return t
  }

  public static getPredefinedCameraLeaveSettings (transform: Matrix3x4, near: number) {
    return {
      near: 0.01,
      far: 100,
      fov: THREE.MathUtils.degToRad(ROOMSET_NAVIGATION_FOV),
      transform: CameraManager.moveAffinityMatrixForwardMultipliedByNear(transform, near)
    }
  }

  private static fromAffinityMatrixToMatrix4 (transform: Matrix3x4) {
    const matrix = new THREE.Matrix4()

    matrix.set(
      transform[0], transform[3], transform[6], transform[9],
      transform[1], transform[4], transform[7], transform[10],
      transform[2], transform[5], transform[8], transform[11],
      0, 0, 0, 1
    )

    return matrix
  }
}

CameraManager.BEHAVIORS = BEHAVIORS
CameraManager.LOCKED_MODES = LOCKED_MODES
CameraManager.STATES = STATES
CameraManager.SUBSTATES = SUBSTATES
CameraManager.ROOMSET_NAVIGATION_FOV = ROOMSET_NAVIGATION_FOV
