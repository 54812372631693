import { createSelector } from 'reselect'

import _get from 'lodash/get'
import { RootState } from '../../../stores/ducks'

export const reportStatus = createSelector(
  (state: RootState) => _get(state, 'reports.activeUsers', undefined),
  (state: RootState) => _get(state, 'reports.nonActiveUsers', undefined),
  (state: RootState) => _get(state, 'reports.projects', undefined),
  (activeUsers, nonActiveUsers, projects) => {
    return {
      activeUsers: activeUsers,
      nonActiveUsers: nonActiveUsers,
      projects: projects
    }
  }
)
