const _get = require('lodash/get')

const cloneObjectsPropertyState = (objects, path) => {
  if (!_get(objects, `0.${path}.clone`)) {
    throw new Error(`No method clone on object.${path}`)
  }

  return objects.reduce((acc, object) => {
    return Object.assign(acc, {
      [object.uuid]: _get(object, path).clone()
    })
  }, {})
}

module.exports = {
  cloneObjectsPropertyState
}
