import { Vector2, Vector3, MeshBasicMaterial, Object3D, Mesh, Box3, MathUtils } from 'three'
import { Go3DViewer } from '../../types/Go3DViewer'
import { Hole, HoleDescription, Holes } from './WallHoles'
import { WallNodes, WallNode } from './WallNodes'
import { Walls, Wall } from './Walls'
import { getSignedArea, createCustomMesh, FLOOR_MATERIAL, setObjectOutlineError } from './helpers'
import { Kvadrat, MeshType, SchemaV2 } from './kvadrat/kvadrat-generator'

export type ISchemaDPDEnhanced = SchemaV2 & {
  holes: { [wallId: string]: HoleDescription[] }
}

const debugMaterial = new MeshBasicMaterial({ color: 'magenta' })

export const MIN_WALL_HEIGHT = 2.5
export const MAX_WALL_HEIGHT = 6

export class RoomDrawing {
  wallNodes = new WallNodes()
  debug = false
  private walls = new Walls()
  private wallHoles = new Holes()

  // Used to draw floor
  private kvadrat = new Kvadrat()

  public objects: Object3D[] = []
  public isDrawnCCW = false

  private layer0 = new Object3D()
  private layer1 = new Object3D()
  private layer2 = new Object3D()
  private layer3 = new Object3D()
  private layer4 = new Object3D()

  private _bb = new Box3()
  private _center = new Vector3()

  private floor: Mesh | null = null
  private lastClosingWall: Wall | null = null

  private dragPosition = new Vector2()
  private lastWallPosition = new Vector2()

  constructor (private app: Go3DViewer) {
    this.app.overlayScene.add(this.layer0)
    this.app.overlayScene.add(this.layer1)
    this.app.overlayScene.add(this.layer2)
    this.app.overlayScene.add(this.layer3)
    this.app.overlayScene.add(this.layer4)
  }

  private _wallHeight = 2.5
  public get wallHeight () {
    return this._wallHeight
  }

  public set wallHeight (value: number) {
    this._wallHeight = MathUtils.clamp(value, MIN_WALL_HEIGHT, MAX_WALL_HEIGHT)
    this.walls.updateWallHeight(this._wallHeight)
  }

  set visible (value: boolean) {
    this.layer0.visible = value
    this.layer1.visible = value
    this.layer2.visible = value
    this.layer3.visible = value
  }

  get holeList () {
    return this.wallHoles.list
  }

  get bb () {
    if (this.floor) {
      this.floor.geometry.computeBoundingBox()
      this._bb.copy(this.floor.geometry.boundingBox!).applyMatrix4(this.floor.matrixWorld)
    }
    return this._bb
  }

  get center () {
    if (this.floor) {
      this.bb.getCenter(this._center)
    } else {
      this._center.set(0, 0, 0)
    }
    return this._center
  }

  clear () {
    if (this.floor && this.floor.parent) this.floor.parent.remove(this.floor)

    this.objects.forEach(o => o.parent?.remove(o))
    this.objects = []

    this.lastClosingWall = null
    this.floor = null
    this.walls.clear()
    this.wallNodes.clear()
    this.wallHoles.clear()
  }

  addWallNode (point: Vector2) {
    const corner = this.wallNodes.add(point)
    corner.set(point.x, point.y)
    this.addObject(corner.object, this.layer2)

    const count = this.wallNodes.size()

    if (count > 1) {
      const wall = this.walls.add(count - 2, count - 1, this.wallHeight)
      if (wall) {
        wall.set(this.wallNodes.at(wall.from), this.wallNodes.at(wall.to))
        this.addObject(wall.object, this.layer1)
      }
    }

    if (count > 2) {
      if (this.lastClosingWall) {
        this.lastClosingWall.from = count - 1
        this.lastClosingWall.to = 0
        this.lastClosingWall.set(this.wallNodes.at(this.lastClosingWall.from), this.wallNodes.at(this.lastClosingWall.to))
      } else {
        const wall = this.walls.add(count - 1, 0, this.wallHeight)
        if (wall) {
          wall.set(this.wallNodes.at(wall.from), this.wallNodes.at(wall.to))
          this.addObject(wall.object, this.layer1)
          this.lastClosingWall = wall
        }
      }

      this.updateFloor()
    }

    this.app.renderOnNextFrame()
  }

  addHoleToWall (object: Object3D, point: Vector3, description: HoleDescription) {
    let changed = false
    const wall = this.walls.objectToWall(object)
    if (!wall) return

    if ((description.type === 'window' || description.type === 'door')) {
      const offsetOnWall = wall.offsetOnWall(point)
      const hole = wall.addHoleFromDescription(description, offsetOnWall)
      if (hole) {
        changed = true
        this.wallHoles.register(hole)
        this.addObject(hole.object, this.layer3)
      } else {
        setObjectOutlineError(wall.object, true)
      }
    }
    return changed
  }

  removeSelected (selected: Set<Object3D>) {
    let changedWalls = false

    selected.forEach(object => {
      const hole = this.wallHoles.objectToHole(object)
      if (hole) {
        this.removeHole(hole)
      }

      const wallNode = this.wallNodes.objectToWallNode(object)
      if (wallNode) {
        changedWalls = true
        this.removeWallNode(wallNode)
      }

      const wall = this.walls.objectToWall(object)
      if (wall) {
        const wallNodeFrom = this.wallNodes.indexToWallNode(wall.from)
        if (wallNodeFrom) {
          changedWalls = true
          this.removeWallNode(wallNodeFrom)
        }
      }
    })

    if (changedWalls) {
      this.walls.update(this.wallNodes)
      this.updateFloor()
    }

    this.app.renderOnNextFrame()
  }

  debugSelect (object: Object3D) {
    if (!this.debug) return

    const corner = this.wallNodes.objectToWallNode(object)
    const wall = this.walls.objectToWall(object)
    const hole = this.wallHoles.objectToHole(object)

    if (corner) {
      console.log('c', corner.index)
      console.log(corner)
    }
    if (wall) {
      console.log(wall.id, wall.from, wall.to)
      console.log(wall)
    }
    if (hole) {
      console.log('h', hole.modelId, hole.wallId)
      console.log(hole)
    }
  }

  clickedDrawing (object: Object3D, point: Vector3) {
    const wallNode = this.wallNodes.objectToWallNode(object)
    const wall = this.walls.objectToWall(object)

    let changed = false

    if (wallNode) {
      changed = true
      this.removeWallNode(wallNode)
    }

    if (wall) {
      changed = true
      const pointOnWall = wall.pointOnWall(point)

      const wallNode = this.wallNodes.insert(wall.from, pointOnWall, this.walls)
      const to = (wallNode.index + 1) === this.wallNodes.size() ? 0 : wallNode.index + 1

      const newWall = this.walls.split(
        wall,
        pointOnWall,
        wallNode.index,
        to
      )

      this.addObject(wallNode.object, this.layer2)
      this.addObject(newWall.object, this.layer1)
    }

    if (changed) {
      this.walls.update(this.wallNodes)
      this.updateFloor()
    }
  }

  private debugObject (object: any) {
    if (object) {
      object.children.forEach((c: any) => {
        c.material = debugMaterial
      })
    }
  }

  startDragObject (object: Object3D) {
    const wall = this.walls.objectToWall(object)
    if (wall) {
      this.lastWallPosition.set(object.position.x, object.position.z)
    }

    const hole = this.wallHoles.objectToHole(object)
    if (hole) {
      if (hole.wallId) {
        hole.setLastValidPositionX(hole.position.x)
        hole.setLastValidWallId(hole.wallId)
        if (hole.object.parent) hole.object.parent.remove(hole.object)
        this.layer4.add(hole.object)
      } else {
        this.removeHole(hole)
      }
    }
  }

  dragObject (object: Object3D, dragEnd: boolean) {
    let changed = false

    const wall = this.walls.objectToWall(object)
    const wallNode = this.wallNodes.objectToWallNode(object)
    const hole = this.wallHoles.objectToHole(object)

    this.dragPosition.set(object.position.x, object.position.z)
    const position = this.dragPosition

    if (wallNode) {
      changed = true
      const point = this.wallNodes.at(wallNode.index)
      point.set(position.x, position.y)
      wallNode.set(position.x, position.y)
      this.walls.update(this.wallNodes)
    }

    if (wall) {
      changed = true

      const tx = position.x - this.lastWallPosition.x
      const ty = position.y - this.lastWallPosition.y

      const points = [wall.from, wall.to]

      points.forEach(index => {
        const point = this.wallNodes.at(index)
        point.set(point.x + tx, point.y + ty)
        const wallNode = this.wallNodes.indexToWallNode(index)
        if (wallNode) wallNode.set(point.x, point.y)
      })

      this.walls.update(this.wallNodes)
      this.lastWallPosition.copy(position)
    }

    if (hole) {
      const closestWall = this.walls.findClosestWallToPoint(object.position)
      if (dragEnd) {
        if (hole.object.parent) hole.object.parent.remove(hole.object)
        this.layer3.add(hole.object)
      }
      this.walls.moveHole(hole, object.position, hole.wallId, closestWall?.id ?? null, dragEnd)
    }

    if (changed) {
      this.updateFloor()
      this.app.renderOnNextFrame()
    }
  }

  loadSchema (schema: ISchemaDPDEnhanced) {
    this.clear()

    schema.wall_nodes.forEach(v => {
      const vec = new Vector2(v.x, v.z)
      const corner = this.wallNodes.add(vec)
      corner.set(vec.x, vec.y)
      this.addObject(corner.object, this.layer2)
    })

    schema.walls.forEach(v => {
      const wall = this.walls.add(v.from, v.to, this.wallHeight)
      wall.id = v.id
      wall.set(this.wallNodes.at(v.from), this.wallNodes.at(v.to))

      this.addObject(wall.object, this.layer1)

      if (wall.to === 0) {
        this.lastClosingWall = wall
      }
    })

    Object.entries(schema.holes).forEach(([wallId, holes]) => {
      const wall = this.walls.byId(wallId)

      if (wall) {
        wall.holes.clear()

        holes.forEach(description => {
          const hole = wall.addHoleFromSchema(description)
          this.wallHoles.register(hole)
          this.addObject(hole.object, this.layer3)
        })
      }
    })

    this.updateFloor()
  }

  getSchema (): ISchemaDPDEnhanced {
    this.isDrawnCCW = getSignedArea(this.wallNodes.values()) < 0

    const floorNodes = Object.keys(this.wallNodes.values()).map(k => Number(k))

    const holes: ISchemaDPDEnhanced['holes'] = {}

    const walls = this.walls.values().map((wall) => {
      const sortedHoles = Array.from(wall.holes)
        .sort((a, b) => (a.position.x - b.position.x))
        .filter(hole => hole.visible)

      holes[wall.id] = sortedHoles.map((hole) => hole.description)

      return {
        id: wall.id,
        from: wall.from,
        to: wall.to,
        thickness: wall.thickness,
        base: wall.base,
        height: wall.height,
        holes: sortedHoles.map(hole => ({ vertices: hole.vertices }))
      }
    })

    return {
      wall_nodes: this.wallNodes.toSchemaObjects(),
      walls: walls,
      floors: [
        {
          id: 'floor_1',
          nodes: this.isDrawnCCW ? [...floorNodes].reverse() : floorNodes
        }
      ],
      materials: {},
      material_assignments: [],
      holes: holes
    }
  }

  getObjectType (object: Object3D) {
    return object.userData.roomEditorObjectType
  }

  private addObject (object: Object3D, layer: Object3D) {
    this.objects.push(object)
    layer.add(object)
  }

  private removeObject (object: Object3D) {
    this.objects = this.objects.filter(o => o !== object)
    if (object.parent) object.parent.remove(object)
  }

  private removeHole (hole: Hole) {
    this.wallHoles.deregister(hole)
    this.removeObject(hole.object)
    if (hole.wallId) {
      const wall = this.walls.byId(hole.wallId)
      if (wall) wall.removeHole(hole)
    }
  }

  private removeWallNode (wallNode: WallNode) {
    const removedWall = this.wallNodes.remove(wallNode, this.walls)
    if (removedWall) {
      this.removeObject(removedWall.object)
      removedWall.holes.forEach((hole) => this.removeHole(hole))
    }
    this.removeObject(wallNode.object)
  }

  private updateFloor () {
    if (this.floor) this.layer0.remove(this.floor)

    if (this.wallNodes.size() < 2) {
      this.floor = null
      return
    }

    const points = this.wallNodes.values()
    const isDrawnCCW = getSignedArea(points) < 0

    const wallNodes = this.wallNodes.values().map(p => {
      return {
        x: p.x,
        y: 0,
        z: p.y
      }
    })
    const floorNodes = Object.keys(wallNodes).map(k => Number(k))

    const meshes = this.kvadrat.update({
      wall_nodes: wallNodes,
      walls: [],
      floors: [
        {
          id: 'floor_2',
          nodes: isDrawnCCW ? [...floorNodes].reverse() : floorNodes
        }
      ],
      materials: {},
      material_assignments: []
    })

    let floor = null

    meshes.forEach(kvadratMesh => {
      if (kvadratMesh.type === MeshType.FLOOR) {
        const mesh = createCustomMesh(
          kvadratMesh.geometry.positions,
          kvadratMesh.geometry.indices,
          kvadratMesh.geometry.normals,
          kvadratMesh.geometry.uv0,
          kvadratMesh.geometry.uv1
        )

        mesh.position.set(kvadratMesh.position.x, kvadratMesh.position.y, kvadratMesh.position.z)
        mesh.receiveShadow = true
        mesh.castShadow = true
        mesh.material = FLOOR_MATERIAL

        floor = mesh
      }
    })

    if (floor) {
      this.floor = floor
      this.layer0.add(this.floor)
    }
  }

  public getWallLengthFromNode (object: Object3D): number | null {
    const wall = this.walls.objectToWall(object)
    if (!wall) {
      return null
    }
    return wall.length
  }
}
