import _get from 'lodash/get'

const getTemplateModelSettings = ({
  id,
  type,
  modifications,
  template
}) => {
  const model = _get(template, type)
  return {
    modifications: _get(modifications, [id], {}),
    model: model ? model.merge({ id, userData: { name: id } }, { deep: true }) : {}
  }
}

export default getTemplateModelSettings
