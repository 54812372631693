import _curry from 'lodash/curry'
import _get from 'lodash/get'
import { getCurrentEntry as getCurrentProject } from '../projects/selectors'
import { getCurrent as getCurrentUser } from '../users/selectors'
import { findVisibleNodesRecursive } from '../combinations/actions/get-changed-models'

const getLocalState = (state) => state.threeviewer

// Return default values instead of new instances every time
// This avoids unnecessary re-renders of react components
const defaultViewer = {}
const defaultNodeList = {}
const defaultSelection = {}

// Settings
export const getSettings = (state) => state.threeviewer.settings

// Viewer
export const getViewer = (state) => state.threeviewer.viewer || defaultViewer

export const getNodeList = (state) => (
  _get(getViewer(state), 'objectTracker.interactions.nodeList', defaultNodeList)
)
export const getPickerSelection = (state) => _get(getViewer(state), 'picker.selection', defaultSelection)

// Vive
export const getViveState = (state) => getLocalState(state).vive
export const getIsViveAvailable = (state) => getViveState(state).isAvailable
export const getIsViveActive = (state) => getViveState(state).isActive

// UI
export const getAnnotationsActive = (state) => getUIState(state).annotationsActive
export const getComponents = (state) => _get(getUIState(state), 'scene.defaultScene.components')
export const getComponentVisibility = _curry((id, state) => _get(getComponents(state), [id, 'active']))
export const getUIState = (state) => getLocalState(state).ui
export const getForcedUpdate = (state) => getUIState(state).forcedUpdate
export const getForcedUpdateTree = (state) => getUIState(state).forcedUpdateTree
export const getHasCombinationModel = (state) => _get(getUIState(state), 'scene.hasCombinationModel')
export const getMaterialSource = (state) => _get(getUIState(state), 'materialSource')
export const getKeyBoardBindingsEnabled = (state) => _get(getUIState(state), 'keyBoardBindingsEnabled')
export const getMoveGizmoSettings = (state) => getUIState(state).moveGizmoSettings
export const getTransformGizmo = (state) => getViewer(state).transformGizmo
export const getAssembleTool = (state) => getViewer(state).assembleTool
export const getToolbarPositionFields = (state) => getUIState(state).toolbarPositionFields
export const getToolbarRotationFields = (state) => getUIState(state).toolbarRotationFields

//  Camera
export const getCameraState = (state) => _get(getLocalState(state), 'camera')
export const getAspectRatio = (state) => _get(getCameraState(state), 'aspectRatio')
export const getActiveCamera = (state) => _get(getCameraState(state), 'activeCamera')
export const getActiveCameraId = (state) => _get(getActiveCamera(state), 'id', null)
export const getCameraMode = (state) => _get(getCameraState(state), 'mode')

export const getIsFeatureActive = (state) => (feature, allowForAdmin) => {
  const activeInProject = _get(getCurrentProject(state), ['features', feature], false)
  const globallyActive = _get(getSettings(state), ['features', feature], false)
  const isUserAdmin = _get(getCurrentUser(state), 'scopes', []).includes('admin')
  if (allowForAdmin && isUserAdmin) {
    return globallyActive || isUserAdmin
  }
  return globallyActive || activeInProject
}

// Make sure so that only a single rootModel is selected
export const getDesignRenderActive = (state) => () => {
  const viewer = getViewer(state)

  const isolatedModels = findVisibleNodesRecursive(viewer.scene)
  return isolatedModels.size === 1
}
