const THREE = require('three')
/**
 * Modified version of the following three.js example:
 * @author Mugen87 / https://github.com/Mugen87
 *
 * References:
 * http://john-chapman-graphics.blogspot.com/2013/01/ssao-tutorial.html
 * https://learnopengl.com/Advanced-Lighting/SSAO
 * https://github.com/McNopper/OpenGL/blob/master/Example28/shader/ssao.frag.glsl
 */

const SSAOShader = {

  defines: {
    PERSPECTIVE_CAMERA: 1,
    KERNEL_SIZE: 32
  },

  uniforms: {

    tDiffuse: { value: null },
    tNormal: { value: null },
    tDepth: { value: null },
    kernel: { value: null },
    cameraNear: { value: null },
    cameraFar: { value: null },
    resolution: { value: new THREE.Vector2() },
    cameraProjectionMatrix: { value: new THREE.Matrix4() },
    cameraInverseProjectionMatrix: { value: new THREE.Matrix4() },
    kernelRadius: { value: 0.25 },
    uvScale: { value: 1.0 }
  },

  vertexShader: `

    varying vec2 vUv;

    void main() {

      vUv = uv;

      gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );

    }`,

  fragmentShader: `

    uniform sampler2D tDiffuse;
    uniform sampler2D tNormal;
    uniform sampler2D tDepth;

    uniform vec3 kernel[ KERNEL_SIZE ];
    uniform float kernelRadius;

    uniform vec2 resolution;

    uniform float cameraNear;
    uniform float cameraFar;
    uniform mat4 cameraProjectionMatrix;
    uniform mat4 cameraInverseProjectionMatrix;

    varying vec2 vUv;
    uniform float uvScale;

    #include <packing>

    float getDepth( const in vec2 screenPosition ) {

      return texture2D( tDepth, screenPosition ).x;

    }

    float getViewZ( const in float depth ) {

      #if PERSPECTIVE_CAMERA == 1

        return perspectiveDepthToViewZ( depth, cameraNear, cameraFar );

      #else

        return orthographicDepthToViewZ( depth, cameraNear, cameraFar );

      #endif

    }

    vec3 getViewPosition( const in vec2 screenPosition, const in float depth, const in float viewZ ) {

      float clipW = cameraProjectionMatrix[2][3] * viewZ + cameraProjectionMatrix[3][3];

      vec4 clipPosition = vec4( ( vec3( screenPosition, depth ) - 0.5 ) * 2.0, 1.0 );

      clipPosition *= clipW; // unprojection.

      return ( cameraInverseProjectionMatrix * clipPosition ).xyz;

    }

    float rng_state = 21317.1283892346023540;
    #define TAU 6.28318530718

    float rand() {
        float v = fract(sin(rng_state) * 43758.5453123);
        rng_state = rng_state + v;
        return v;
    }

    vec3 uniform_hemi() {
        float u1 = rand();
        float r = sqrt(u1);
        float theta = rand() * TAU;
        float x = cos(theta) * r;
        float y = sin(theta) * r;
        float z = 1.0 - u1;
        return vec3(x, y, z);
    }

    vec3 getViewNormal( const in vec3 vsPos ) {
      return normalize(cross(
        dFdx(vsPos),
        dFdy(vsPos)
      ));
    }

    void main() {
      vec2 uv = vUv * uvScale;
      rng_state = rng_state * uv.x * uv.y + uv.x * rng_state - uv.y * rng_state;
      float depth = getDepth( uv );
      float viewZ = getViewZ( depth );

      vec3 viewPosition = getViewPosition( uv, depth, viewZ );
      vec3 viewNormal = getViewNormal( viewPosition );

      // compute matrix used to reorient a kernel vector
      vec3 random = uniform_hemi();
      vec3 tangent = normalize( random - viewNormal * dot( random, viewNormal ) );
      vec3 bitangent = cross( viewNormal, tangent );
      mat3 kernelMatrix = mat3( tangent, bitangent, viewNormal );

      float occlusion = 0.0;

      for ( int i = 0; i < KERNEL_SIZE; i ++ ) {

        vec3 sampleVector = kernelMatrix * kernel[ i ]; // reorient sample vector in view space
        vec3 samplePoint = viewPosition + ( sampleVector * kernelRadius * uvScale ); // calculate sample point

        vec4 samplePointNDC = cameraProjectionMatrix * vec4( samplePoint, 1.0 ); // project point and calculate NDC
        samplePointNDC /= samplePointNDC.w;

        vec2 samplePointUv = samplePointNDC.xy * 0.5 + 0.5;
        float realDepth = getDepth(samplePointUv);
        vec3 realPoint = getViewPosition(samplePointUv, realDepth, getViewZ(realDepth));

        if (realPoint.z > samplePoint.z) {

          // Don't consider points which are outside the kernel, to avoid the 'halo' effect
          float viewDistance = distance(realPoint, samplePoint);
          float rangeCutoff = step(viewDistance, kernelRadius);

          // Use projected area to estimate the decrease in irradiance due to the occlusion
          vec3 l = normalize(samplePoint - viewPosition);
          float occlusionImpact = max(0.0, dot(l, viewNormal));

          occlusion += occlusionImpact * rangeCutoff;
          
        }
      }

      // For surfaces orthagonal to the view direction there is little screen space information and we can get over-occlusion
      // artifacts. This edge smoothing tries to alleviate some of that by decreasting the occlusion impact of those cases.
      vec3 V = -normalize(viewPosition);
      float edgeAmount = 1.0 - dot(viewNormal, V);
      float edgeSmoothing = step(edgeAmount, 0.98); // is this an edge worth smoothing
      edgeSmoothing = clamp(edgeSmoothing, 0.25, 1.0); // however, don't over-brighten edges instead!

      occlusion = clamp( occlusion / float( KERNEL_SIZE ), 0.0, 1.0 ) * edgeSmoothing;

      const float contrast = 1.0;
      float occlusionFactor = pow(1.0 - occlusion, contrast);

      vec4 color = texture2D( tDiffuse, uv);
      gl_FragColor = vec4(vec3(occlusionFactor),  color.a);
    }
  `

}

const SSAOBlurShader = {

  uniforms: {

    tDiffuse: { value: null },
    tAO: { value: null },
    resolution: { value: new THREE.Vector2() },
    onlyAO: { value: false },
    uvScale: { value: 1.0 },
    lift: { value: 0.4 }

  },

  vertexShader: `

    varying vec2 vUv;

    void main() {

      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );

    }

  `,

  fragmentShader: `

    uniform sampler2D tDiffuse;
    uniform sampler2D tAO;
    uniform float uvScale;

    uniform vec2 resolution;
    uniform bool onlyAO;
    uniform float lift;

    varying vec2 vUv;

    void main() {
      vec2 uv = vUv * uvScale;
      vec2 texelSize = ( 1.0 / resolution );
      float result = 0.0;

      result += 0.25 * texture2D( tAO, uv + vec2(-0.5, -0.5) * texelSize ).r;
      result += 0.25 * texture2D( tAO, uv + vec2(-0.5, +0.5) * texelSize ).r;
      result += 0.25 * texture2D( tAO, uv + vec2(+0.5, -0.5) * texelSize ).r;
      result += 0.25 * texture2D( tAO, uv + vec2(+0.5, +0.5) * texelSize ).r;

      float occlusion = result * (1.0 - lift) + lift;
      vec4 color = texture2D( tDiffuse, uv);
      if (onlyAO) {
        gl_FragColor = vec4(vec3(occlusion), color.a);
      } else {
        gl_FragColor = vec4(color.rgb * occlusion, color.a);
      }
    }
  `
}

function makeKernel (size) {
  if (size === 32) {
    // We already have a nice looking kernel for 32 samples, why create a new one?
    return [
      0.025200383878447037, -0.040387572784427124, 0.07733784616961509,
      0.04056331930790754, 0.06446536129849244, 0.04337267530173941,
      -0.00292465766737533, 0.09120871495170833, 0.023068027783509044,
      -0.008136130652512584, 0.04931915199085124, 0.08475593738942412,
      0.04971450894033449, 0.0843902960989675, 0.029957255288986233,
      -0.008343121388842176, -0.0700582326261952, 0.0811621681762434,
      -0.02093864434493587, 0.03986815066970486, 0.11623583438292415,
      0.09048385675541998, -0.07795456256484726, 0.04336276140491919,
      -0.06294394970403608, -0.10935584703930308, 0.054357890649422876,
      -0.06510778887854553, -0.11834500021828813, 0.0646173459687476,
      0.04654000877789359, -0.04278870151612909, 0.16661843329547615,
      -0.1487412488282788, 0.03263245731064838, 0.09397014349050031,
      0.08666980356029517, -0.1012323794245733, 0.1481750815475159,
      -0.15923465375159437, 0.05409495162001688, 0.1347406365011984,
      0.06517477817369408, 0.08645373718982553, 0.22921207983036043,
      0.08808722923727301, -0.11923586951319372, 0.2239461338850749,
      -0.09030926139014031, 0.18827435901564255, 0.19083693244435773,
      0.017674754238034832, 0.2572451210547826, 0.16618832548940016,
      -0.3267704328323181, -0.044793610287637835, 0.10203407044384318,
      0.29804639041243686, 0.16960112197215912, 0.13547261235580796,
      -0.05329514772980242, 0.2186089372980804, 0.3394401686767889,
      0.3341650445186504, 0.2847427834658208, 0.0923030373292084,
      -0.2190285680519703, -0.14286494916189987, 0.395232407346366,
      0.4338401785279832, -0.20553289732532334, 0.15700339739010458,
      0.0628818519392535, 0.4198745570345658, 0.30893243967293516,
      0.09345409057015469, 0.45836255740267906, 0.3123005862789195,
      0.043031876030927736, 0.6345513648292112, 0.11139533911307196,
      -0.3424876758252459, -0.015435096429527697, 0.5820451143897195,
      0.032223365901938396, 0.3148762620001931, 0.6620948571564174,
      -0.11826055849834072, 0.7149826304639623, 0.2133133287404196,
      0.2636212110512239, 0.2728697682544413, 0.7294538641236444,
      -0.5325779334969711, -0.21532942615183595, 0.5952790029902687
    ]
  } else {
    const kernel = []

    for (var i = 0; i < size; i++) {
      const sample = new THREE.Vector3()
      const u1 = Math.random()
      const r = Math.sqrt(u1)
      const theta = Math.random() * 6.28318530718
      sample.x = Math.cos(theta) * r
      sample.y = Math.sin(theta) * r
      sample.z = 1.0 - u1

      let scale = i / size
      scale = THREE.Math.lerp(0.1, 1, scale * scale)
      sample.multiplyScalar(scale)

      kernel.push(sample)
    }

    return kernel
  }
}

export default class SSAOPostProcess {
  constructor (renderer, width, height) {
    this.ssaoPass = new THREE.ShaderPass(SSAOShader)
    this.blurPass = new THREE.ShaderPass(SSAOBlurShader)

    this.enabled = false

    this.width = width
    this.height = height

    this.ssaoPass.material.extensions.derivatives = true
    if (!renderer.capabilities.isWebGL2) {
      this.derivatives = renderer.extensions.get('OES_standard_derivatives')
      renderer.getContext().hint(this.derivatives.FRAGMENT_SHADER_DERIVATIVE_HINT_OES, renderer.getContext().NICEST)
    }
    this.ssaoPass.uniforms.resolution.value.set(this.width, this.height)

    const kernelSize = SSAOShader.defines.KERNEL_SIZE
    const kernel = makeKernel(kernelSize)
    this.ssaoPass.uniforms.kernel.value = kernel

    this.originalLumInfluence = 0.8
    this.originalAoClamp = 0.6

    this.throttledAoClampModifier = 1.1
    this.throttledLumInfluenceModifier = 1.1

    this.needsUpdate = false

    this.aoBuffer = new THREE.WebGLRenderTarget(this.width, this.height, { depthBuffer: false, format: THREE.RGBA })
    this.blurPass.uniforms.tAO.value = this.aoBuffer.texture
    this.blurPass.uniforms.resolution.value.set(this.width, this.height)
  }

  render (renderer, buffers, camera) {
    var input = buffers.input
    var output = buffers.output

    this.ssaoPass.uniforms.tDepth.value = input.depthTexture
    this.ssaoPass.uniforms.cameraNear.value = camera.near
    this.ssaoPass.uniforms.cameraFar.value = camera.far
    this.ssaoPass.uniforms.cameraProjectionMatrix.value.copy(camera.projectionMatrix)
    this.ssaoPass.uniforms.cameraInverseProjectionMatrix.value.copy(camera.projectionMatrix).invert()

    // ShaderPass.render will pick up previous frame from input and blend in one pass
    this.aoBuffer.viewport.copy(input.viewport)
    this.ssaoPass.render(renderer, this.aoBuffer, input, 0.0, false)

    this.blurPass.uniforms.tAO.value = this.aoBuffer.texture
    this.blurPass.render(renderer, output, input, 0.0, false)

    // We only rendererd once we we need to swap buffers
    buffers.input = output
    buffers.output = input
  }

  setSize (width, height) {
    this.width = width
    this.height = height
    // this.ssaoPass.uniforms.size.value.set(width, height)
    this.blurPass.uniforms.resolution.value.set(width, height)
    this.aoBuffer.setSize(width, height)
  }

  setUVScale (uvScale) {
    this.ssaoPass.uniforms.uvScale.value = uvScale
    this.blurPass.uniforms.uvScale.value = uvScale
  }

  get throttledAoClamp () {
    return this.throttledAoClampModifier
  }

  set throttledAoClamp (value) {
    this.throttledAoClampModifier = value
  }

  get throttledLumInfluence () {
    return this.throttledLumInfluenceModifier
  }

  set throttledLumInfluence (value) {
    this.throttledLumInfluenceModifier = value
  }

  get onlySSAO () {
    return this.blurPass.uniforms.onlyAO.value
  }

  set onlySSAO (value) {
    this.blurPass.uniforms.onlyAO.value = value
  }

  get aoLift () {
    return this.blurPass.uniforms.lift.value
  }

  set aoLift (value) {
    this.blurPass.uniforms.lift.value = value
  }

  get kernelRadius () {
    return this.ssaoPass.uniforms.kernelRadius.value
  }

  set kernelRadius (v) {
    this.ssaoPass.uniforms.kernelRadius.value = v
  }
}
