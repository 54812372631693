import fetch from '../../utils/fetch'

import * as colors from '../ducks/colors/api'
import * as combinations from '../ducks/combinations/api'
import * as files from './files'
import * as likes from '../ducks/likes/api'
import * as materials from '../ducks/materials/api'
import * as patterns from '../ducks/patterns/api'
import * as projects from '../ducks/projects/api'
import * as renders from '../ducks/renders/api'
import * as threeviewer from '../ducks/threeviewer/api'
import * as uploads from '../ducks/uploads/api'
import * as users from '../ducks/users/api'
import * as roomsets from '../ducks/roomsets/api'
import * as roomsetsAdmin from '../ducks/roomsets-admin/api'
import * as reports from '../ducks/reports/api'
import * as comments from '../ducks/comments/api'
import * as spreadsheet from './spreadsheet'
import * as downloadZip from './download-zip'
import * as imagePackages from '../ducks/image-packages/api'
import * as jobs from '../ducks/jobs/api'
import * as folders from '../ducks/folders/api'
import * as materialSearch from '../ducks/material-search/api'
import * as designs from '../ducks/designs/api'
import * as news from '../ducks/news/api'
import * as external from '../ducks/external/api'
import * as exportDownload from '../ducks/export/api'

const storage = {
  getManifest: (manifestKey: string) => fetch(`/api/storage/get/${manifestKey}.json`).then(res => res.json())
}

const mergedApi = {
  colors,
  combinations,
  designs,
  files,
  likes,
  materialSearch,
  materials,
  patterns,
  projects,
  renders,
  threeviewer,
  uploads,
  users,
  roomsets,
  roomsetsAdmin,
  reports,
  comments,
  spreadsheet,
  downloadZip,
  imagePackages,
  jobs,
  folders,
  storage,
  news,
  external,
  exportDownload
}

export default mergedApi

export type DpdApi = typeof mergedApi
