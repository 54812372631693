import React from 'react'

const IconLandscapeProportions = props => (
  <svg width={props.size} height={props.size} viewBox="0 0 24 24" {...props}>
    <path d="M0.9,4.9 L0.9,19.1 L23.1,19.1 L23.1,4.9 L0.9,4.9 Z M0.6,4 L23.4,4 C23.7313708,4 24,4.26862915 24,4.6 L24,19.4 C24,19.7313708 23.7313708,20 23.4,20 L0.6,20 C0.26862915,20 1.48387116e-15,19.7313708 1.44328993e-15,19.4 L0,4.6 C-4.05812251e-17,4.26862915 0.26862915,4 0.6,4 Z" id="landscape"
      fill={props.color}
    />
  </svg>
)

IconLandscapeProportions.defaultProps = {
  color: 'currentcolor',
  size: '1em'
}

export default IconLandscapeProportions
