import * as THREE from 'three'
import { ColorWrapper, Vector2Wrapper, Vector3Wrapper, Vector4Wrapper } from './ThreeWrappers'

/*
  Render material is the interface to manipulate a material bank material
  The material bank material itself is readonly and only extra attributes can be changed
  It has exactly what it needs and nothing else

  Each of the Rapid Images material types will have a class here

  You are free to share RenderMaterials on many meshes BUT if you do so their parameters will be tied together.
  Cloning or even loading multiple times is fine though and will not incur rendering overhead.

  NOTE: If you want to add a property here you need to change in AssetManager.js.
  Search for an existing property to see where you need to update.
*/

export class RenderMaterial {
  constructor (materialId, assetManager, hash) {
    this.uuid = THREE.Math.generateUUID()
    this._renderMaterialId = THREE.Math.generateUUID()
    this.materialId = materialId
    this.markAsDirty = () => {
      // TODO: @Performance Keep a dirty bit somewhere so we don't have to go to assetManager more than once per 'transaction'
      this._assetManager.markAsDirty(this)
    }

    this._color = new ColorWrapper(this.markAsDirty, 1, 1, 1)
    this._envMapIntensity = 1.0
    this._assetManager = assetManager
    this._side = THREE.FrontSide
    this._hash = hash
  }

  get envMapIntensity () { return this._envMapIntensity }
  get color () { return this._color }
  get side () { return this._side }

  set side (value) {
    this._side = value
    this.markAsDirty()
  }

  set envMapIntensity (value) {
    this._envMapIntensity = value
    this.markAsDirty()
  }

  get isTriplanarMaterial () { return false }

  clone () {
    const newObject = new this.constructor(this.materialId, this._assetManager, this._hash)
    this.copy(newObject)

    this._assetManager.registerMaterialClone(
      this._renderMaterialId,
      newObject._renderMaterialId,
      this._hash
    )

    if (this.userData !== undefined) {
      newObject.userData = JSON.parse(JSON.stringify(this.userData))
    }

    if (this._assetManager.materialProxyHandler === undefined) {
      return newObject
    } else {
      return new Proxy(newObject, this._assetManager.materialProxyHandler)
    }
  }

  copy (target) {
    target.color.copy(this._color)
    target.envMapIntensity = this._envMapIntensity
    target.side = this._side
    target.name = this.name
  }
}

export class RenderMaterialTriplanar extends RenderMaterial {
  constructor (materialId, assetManager, hash) {
    super(materialId, assetManager, hash)

    // Triplanar properties
    this._useTriplanar = true // Use triplanar or use UV-mapping?
    this._showDiffuseOnly = false
    this._useLocalEnvMap = false

    this._triplanarOrientation = new Vector3Wrapper(this.markAsDirty, 0, 0, 0)
    this._triplanarTranslation = new Vector3Wrapper(this.markAsDirty, 0, 0, 0)
    this._markerPoint = new Vector4Wrapper(this.markAsDirty, 0, 0, 0, 0)
    this._mapRotation = new Vector3Wrapper(this.markAsDirty, 0, 0, 0)
    this._uvMapRotation = 0.0
    this._mapRepeat = new Vector2Wrapper(this.markAsDirty, 1, 1)
    this._mapOffset = new Vector2Wrapper(this.markAsDirty, 0, 0)
    this._opacity = 1.0
    this._origoFading = new Vector3Wrapper(this.markAsDirty, 0, 0, 0)
    this._useModelFading = false
    this._startFading = 1.0
    this._endFading = 1.0
    this._wallX0 = 0.0
    this._wallX1 = 1.0
    this._useColorTextureMix = null
    this._colorTextureMix = 1.0
    this._meterScale = 1.0

    // Decal properties
    this._decalMap = null
    this._decalUVTransform = new THREE.Matrix4()
    this._mirrorRotation = false
    this._flipY = false

    // Decal properties - Internal
    this.userDecalRotation = undefined
    this._uvSpaceTranslate = new THREE.Vector3()
    this.renderDecalUVTransform = new THREE.Matrix4()
    this._decalScale = new THREE.Vector2(0, 0)
    this._decalRotation = 0
    this._decalTranslation = new THREE.Vector3(0, 0, 0)
    this._decalOffset = new THREE.Vector2(0, 0)
    this._decalDimensions = new THREE.Vector2(1, 1)
  }

  copy (target) {
    super.copy(target)

    // Copy triplanar properties
    target._showDiffuseOnly = this._showDiffuseOnly
    target._useTriplanar = this._useTriplanar
    target._mapRotation.copy(this._mapRotation)
    target._uvMapRotation = this._uvMapRotation
    target._triplanarOrientation.copy(this._triplanarOrientation)
    target._triplanarTranslation.copy(this._triplanarTranslation)
    target._mapRepeat.copy(this._mapRepeat)
    target._mapOffset.copy(this._mapOffset)
    target._opacity = this._opacity
    target._origoFading.copy(this._origoFading)
    target._startFading = this._startFading
    target._endFading = this._endFading
    target._wallX0 = this._wallX0
    target._wallX1 = this._wallX1
    target._useModelFading = this._useModelFading
    target._useLocalEnvMap = this._useLocalEnvMap
    target._useColorTextureMix = this._useColorTextureMix
    target._colorTextureMix = this._colorTextureMix
    target._meterScale = this._meterScale
    target._decalDimensions.copy(this._decalDimensions)

    // Copy decal properties
    // NOTE: Since the parameters are coupled and all visible I'm not using setters/getters here to avoid order problems
    target._decalMap = this._decalMap
    target._decalUVTransform.copy(this._decalUVTransform)
    target._mirrorRotation = this._mirrorRotation
    target._uvSpaceTranslate.copy(this._uvSpaceTranslate)
    target.renderDecalUVTransform.copy(this.renderDecalUVTransform)
    target._decalScale.copy(this._decalScale)
    target._decalTranslation.copy(this._decalTranslation)
    target._decalRotation = this._decalRotation
    if (this.userDecalRotation !== undefined) {
      target.userDecalRotation = this.userDecalRotation
    }
    target.markAsDirty()
  }

  // Triplanar methods ////////////////////////////////////

  get isTriplanarMaterial () { return true }

  get mapRepeat () { return this._mapRepeat }

  get mapOffset () { return this._mapOffset }
  set mapOffset (v) {
    if (v !== this._mapOffset) {
      this._mapOffset = v
      this.markAsDirty()
    }
  }

  get decalDimensions () { return this._decalDimensions }
  set decalDimensions (v) {
    if (v) {
      this._decalDimensions = new THREE.Vector2(v.width, v.height, 0)
    }
  }

  get markerPoint () { return this._markerPoint }
  set markerPoint (v) {
    if (v !== this._markerPoint) {
      this._markerPoint = v
      this.markAsDirty()
    }
  }

  get showDiffuseOnly () { return this._showDiffuseOnly }
  set showDiffuseOnly (v) {
    if (v !== this._showDiffuseOnly) {
      this._showDiffuseOnly = v
      this.markAsDirty()
    }
  }

  get useTriplanar () { return this._useTriplanar }
  set useTriplanar (v) {
    if (v !== this._useTriplanar) {
      this._useTriplanar = v
      this.markAsDirty()
    }
  }

  get useLocalEnvMap () { return this._useLocalEnvMap }
  set useLocalEnvMap (v) {
    if (v !== this._useLocalEnvMap) {
      this._useLocalEnvMap = v
      this.markAsDirty()
    }
  }

  get useModelFading () { return this._useModelFading }
  set useModelFading (v) {
    if (v !== this._useModelFading) {
      this._useModelFading = v
      this.markAsDirty()
    }
  }

  get startFading () { return this._startFading }
  set startFading (v) {
    if (v !== this._startFading) {
      this._startFading = v
      this.markAsDirty()
    }
  }

  get endFading () { return this._endFading }
  set endFading (v) {
    if (v !== this._endFading) {
      this._endFading = v
      this.markAsDirty()
    }
  }

  get triplanarTranslation () {
    return this._triplanarTranslation
  }

  set triplanarTranslation (vec3) {
    const t = this._triplanarTranslation
    if (vec3.x !== t.x || vec3.y !== t.y || vec3.z !== t.z) {
      this._triplanarTranslation.set(vec3.x, vec3.y, vec3.z)
      this.markAsDirty()
    }
  }

  get triplanarOrientation () {
    return this._triplanarOrientation
  }

  set triplanarOrientation (vec3) {
    const t = this._triplanarOrientation
    if (vec3.x !== t.x || vec3.y !== t.y || vec3.z !== t.z) {
      this._triplanarOrientation.set(vec3.x, vec3.y, vec3.z)
      this.markAsDirty()
    }
  }

  get origoFading () { return this._origoFading }

  set origoFading (v) {
    if (v !== this._origoFading) {
      this._origoFading = v
      this.markAsDirty()
    }
  }

  get wallX0 () { return this._wallX0 }
  set wallX0 (v) {
    if (v !== this._wallX0) {
      this._wallX0 = v
      this.markAsDirty()
    }
  }

  get wallX1 () { return this._wallX1 }
  set wallX1 (v) {
    if (v !== this._wallX1) {
      this._wallX1 = v
      this.markAsDirty()
    }
  }

  get mapRotationX () { return this._mapRotation.x }
  get mapRotationY () { return this._mapRotation.y }
  get mapRotationZ () { return this._mapRotation.z }

  set mapRotationX (v) { this._mapRotation.x = v }
  set mapRotationY (v) { this._mapRotation.y = v }
  set mapRotationZ (v) { this._mapRotation.z = v }

  setMapRotationXYZ (x, y, z) {
    this._mapRotation.set(x, y, z)
    this.markAsDirty()
  }

  get uvMapRotation () { return this._uvMapRotation }

  set uvMapRotation (v) {
    this._uvMapRotation = v
    this.markAsDirty()
  }

  get opacity () { return this._opacity }
  set opacity (v) {
    if (v !== this._opacity) {
      this._opacity = v
      this.markAsDirty()
    }
  }

  get useColorTextureMix () { return this._useColorTextureMix }
  set useColorTextureMix (v) {
    if (v !== this._useColorTextureMix) {
      this._useColorTextureMix = v
      this.markAsDirty()
    }
  }

  get colorTextureMix () { return this._colorTextureMix }
  set colorTextureMix (v) {
    if (v !== this._colorTextureMix) {
      this._colorTextureMix = v
      this.markAsDirty()
    }
  }

  setDefaultMapRotationFromBoundingBox (geometryBoundingBox) {
    const localBBox = geometryBoundingBox
    const x = Math.abs(localBBox.max.x - localBBox.min.x)
    const y = Math.abs(localBBox.max.y - localBBox.min.y)
    const z = Math.abs(localBBox.max.z - localBBox.min.z)
    this._mapRotation.set(x > z ? 90 : 0, z > y ? 90 : 0, x > y ? 90 : 0)
  }

  get meterScale () { return this._meterScale }
  set meterScale (v) {
    if (v !== this._meterScale) {
      this._meterScale = v
      this.markAsDirty()
    }
  }

  // Decal methods //////////////////////////////////////////////////////

  get decalMap () { return this._decalMap }
  get flipY () { return this._flipY }
  get decalUVTransform () { return this._decalUVTransform }
  get mirrorRotation () { return this._mirrorRotation }

  set flipY (v) {
    if (v !== this._flipY) {
      this._flipY = v
      this.markAsDirty()
    }
  }

  set mirrorRotation (v) {
    if (v !== this._mirrorRotation) {
      this._mirrorRotation = v
      this.markAsDirty()
    }
  }

  set decalUVTransform (value) {
    var textureScale = new THREE.Matrix4().makeScale(this._decalDimensions.x / 1000, this._decalDimensions.y / 1000, 1)
    var value_ = textureScale.clone().multiply(value)
    var t = new THREE.Vector3()
    var q = new THREE.Quaternion()
    var s = new THREE.Vector3()
    value_.decompose(t, q, s)

    s.multiply(new THREE.Vector3(1000 / this._decalDimensions.x, 1000 / this._decalDimensions.y, 1))

    this._decalScale = new THREE.Vector2(s.x, s.y)
    this._uvSpaceTranslate = t

    this._decalUVTransform.copy(value)
    this.markAsDirty()
  }

  getDecalTranslationFromTransform () {
    return new THREE.Vector3().setFromMatrixPosition(this._decalUVTransform)
  }

  getDecalRotationFromTransform () {
    var t = new THREE.Vector3()
    var q = new THREE.Quaternion()
    var s = new THREE.Vector3()
    this.decalUVTransform.decompose(t, q, s)

    var v1 = new THREE.Vector3(1, 0, 0)
    v1.applyQuaternion(q)
    var v2 = new THREE.Vector2(v1.x, v1.y)
    var rotation = v2.angle()
    return rotation
  }

  removeDecal () {
    this.transformDecal(
      new THREE.Vector3(),
      new THREE.Vector2(1, 1),
      0,
      0
    )
    this._decalMap = new THREE.Texture()
    this.markAsDirty()
  }

  transformDecal (
    deltaTranslation = new THREE.Vector3(),
    scale = new THREE.Vector2(1, 1),
    rotation = 0,
    userDecalRotation
  ) {
    const _oldRotation = this.decalRotation

    const rotationChanged = rotation !== _oldRotation
    const scaleChanged = !scale.equals(this.decalScale)

    const _changed = rotationChanged || scaleChanged
    const _realTranslate = _changed ? new THREE.Vector3() : deltaTranslation

    const newT = new THREE.Vector3(_realTranslate.x, _realTranslate.y, 0)
    newT.applyAxisAngle(new THREE.Vector3(0, 0, 1), rotation)
    newT.add(this._uvSpaceTranslate)

    const _translate = new THREE.Matrix4().makeTranslation(newT.x, newT.y, 0)
    const _scale = new THREE.Matrix4().makeScale(scale.x * this._decalDimensions.x / 1000, scale.y * this._decalDimensions.y / 1000, 1)
    const _rotation = new THREE.Matrix4().makeRotationZ(rotation)
    const flipRender = this.mirrorRotation ? -1 : 1
    const _renderRotation = new THREE.Matrix4().makeRotationZ(flipRender * rotation)
    var textureScale = new THREE.Matrix4().makeScale(1000 / this._decalDimensions.x, 1000 / this._decalDimensions.y, 1)

    const m = new THREE.Matrix4()
    const renderMatrix = new THREE.Matrix4()

    // Order of operations is important, do NOT change
    // We want to keep translation independent of rotation and scale
    // i.e. down is always down independent of rotation,
    // amount of translation is always the same independent of scale.
    // Additionally, to support non-square decals, the scale
    // information of the pattern has to be applied after the UI transform.
    m.multiply(textureScale)
    m.multiply(_translate)
    m.multiply(_rotation)
    m.multiply(_scale)

    renderMatrix.multiply(textureScale)
    renderMatrix.multiply(_translate)
    renderMatrix.multiply(_renderRotation)
    renderMatrix.multiply(_scale)

    this._decalRotation = rotation

    if (userDecalRotation || userDecalRotation === 0) {
      this.userDecalRotation = userDecalRotation
    }

    this._decalTranslation = deltaTranslation.clone()

    this.decalUVTransform = m // NOTE: Triggers complicated setter
    this.renderDecalUVTransform = renderMatrix
  }

  set decalTranslation (value) {
    this.transformDecal(new THREE.Vector2(value.x, value.y), this._decalScale, this._decalRotation)
  }

  get decalTranslation () {
    return this._decalTranslation
  }

  set decalScale (value) {
    this.transformDecal(this._decalTranslation, new THREE.Vector2(value.x, value.y), this._decalRotation)
  }

  get decalScale () {
    return this._decalScale || new THREE.Vector2(1, 1)
  }

  set decalRotation (value) {
    this.transformDecal(this._decalTranslation, this._decalScale, value)
  }

  get decalRotation () {
    return this._decalRotation || 0
  }

  set decalMap (v) {
    if (v !== this._decalMap) {
      this._decalMap = v
      this.markAsDirty()
    }
  }
}
