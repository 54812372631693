import React, { Component } from 'react'
import PropTypes from 'prop-types'

import _omit from 'lodash/omit'

import css from './index.css'

class InputEmail extends Component {
  get root () {
    return this._root
  }

  render () {
    const props = this.props

    return (
      <input
        {..._omit(props, 'fullWidth')}
        type='email'
        ref={(node) => (this._root = node)}
        className={`${css.inputEmail} ${props.className}} ${props.fullWidth ? 'block width-100' : ''}`}
      />
    )
  }
}

InputEmail.propTypes = {
  className: PropTypes.string,
  fullWidth: PropTypes.bool
}

InputEmail.defaultProps = {
  placeholder: 'E-mail address',
  className: '',
  fullWidth: true
}

export default InputEmail
