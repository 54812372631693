import { StorageApiFile } from '../../../utils'

export const CATEGORIES = [
  'Floor bound',
  'Wall bound',
  'Ceiling Bound',
  'Table bound',
  'Straight on'
]

export type RoomsetMetadata = {
  isHidden?: boolean
  isPublished?: boolean
  rooms?: number
  area?: number,
  marketArea?: string[]
  tags?: string[]
  category?: 'Floor bound' | 'Wall bound' | 'Ceiling Bound' | 'Table bound' | 'Straight on'
  thumbnail?: StorageApiFile | null,
  imageFiles?: StorageApiFile[] | null
  infoText?: string
}

export interface Roomset {
  deadlineJobs?: { [id: string]: { type: string } }
  deadlineStatus?: 'Failed' | 'Completed'
  id?: string
  parentId?: string
  combinationId?: string
  title?: string | null
  uploadId?: string | null
  vrscene?: string
  type?: 'light-setup' | 'image-template'
  resolution?: { x: number; y: number }
  aspectRatio?: { x: number; y: number }
  manualAlignment?: [number | null, number | null, number | null]
  cameras?: { [id: string]: any }
  perspType?: string,
  dummies?: { [id: string]: any }
  targets?: { [id: string]: any }
  defaultCameraId?: string
  defaultTargetId?: string
  defaultDummyId?: string
  createdAt?: string
  modifiedAt?: string
  removedAt?: string
  presets?: {}
  metadata?: RoomsetMetadata
}

export interface FilteredRoomset {
  deadlineJobs: { [id: string]: { type: string } }
  deadlineStatus: 'Failed' | 'Completed'
  id: string
  combinationId: string
  title: string | null
  uploadId: string | null
  vrscene: string
  cameras: { [id: string]: any }[]
  createdAt: string
  createdBy: string
  modifiedAt: string
  metadata: Pick<Required<RoomsetMetadata>, 'area' | 'rooms'| 'tags' | 'marketArea' | 'thumbnail'>
}
