const THREE = require('three')

/*
  Wrappers for various Three.js classes that provide onChange callbacks.
  The wrappers behave just like the underlying Three.js types, but call onChange
  when they are modified.

  THREE.Quaternion and THREE.Euler already had this feature but our version makes
  sure that no event is emitted when they are set to the same value they already have.
*/

export class ColorWrapper extends THREE.Color {
  constructor (onChange, r, g, b) {
    super(r, g, b)
    this.__onChange = onChange
  }

  get r () { return this._r }
  get g () { return this._g }
  get b () { return this._b }

  set r (v) { setterHelper(this, '_r', v, this.__onChange) }
  set g (v) { setterHelper(this, '_g', v, this.__onChange) }
  set b (v) { setterHelper(this, '_b', v, this.__onChange) }

  clone () { return new THREE.Color(this.r, this.g, this.b) }
}

export class Vector2Wrapper extends THREE.Vector2 {
  constructor (onChange, x, y) {
    super(x, y)
    this.__onChange = onChange
  }

  get x () { return this._x }
  get y () { return this._y }

  set x (v) { setterHelper(this, '_x', v, this.__onChange) }
  set y (v) { setterHelper(this, '_y', v, this.__onChange) }

  clone () { return new THREE.Vector2(this.x, this.y) }
}

export class Vector3Wrapper extends THREE.Vector3 {
  constructor (onChange, x, y, z) {
    super(x, y, z)

    this.__onChange = onChange
  }

  get x () { return this._x }
  get y () { return this._y }
  get z () { return this._z }

  set x (v) { setterHelper(this, '_x', v, this.__onChange) }
  set y (v) { setterHelper(this, '_y', v, this.__onChange) }
  set z (v) { setterHelper(this, '_z', v, this.__onChange) }

  clone () { return new THREE.Vector3(this.x, this.y, this.z) }
}

export class Vector4Wrapper extends THREE.Vector4 {
  constructor (onChange, x, y, z, w) {
    super(x, y, z, w)
    this.__onChange = onChange
  }

  get x () { return this._x }
  get y () { return this._y }
  get z () { return this._z }
  get w () { return this._w }

  set x (v) { setterHelper(this, '_x', v, this.__onChange) }
  set y (v) { setterHelper(this, '_y', v, this.__onChange) }
  set z (v) { setterHelper(this, '_z', v, this.__onChange) }
  set w (v) { setterHelper(this, '_w', v, this.__onChange) }

  clone () { return new THREE.Vector4(this.x, this.y, this.z, this.w) }
}

export class QuaternionWrapper extends THREE.Quaternion {
  constructor (onChange, x, y, z, w) {
    super(x, y, z, w)
    this.__onChange = onChange
  }

  get _x () { return this.__x }
  get _y () { return this.__y }
  get _z () { return this.__z }
  get _w () { return this.__w }

  set _x (v) { setterHelper(this, '__x', v, this.__onChange) }
  set _y (v) { setterHelper(this, '__y', v, this.__onChange) }
  set _z (v) { setterHelper(this, '__z', v, this.__onChange) }
  set _w (v) { setterHelper(this, '__w', v, this.__onChange) }

  clone () { return new THREE.Quaternion(this.__x, this.__y, this.__z, this.__w) }

  copy (quaternion) {
    if (quaternion.x !== this.x || quaternion.y !== this.y || quaternion.z !== this.z || quaternion.w !== this.w) {
      this.__x = quaternion.x
      this.__y = quaternion.y
      this.__z = quaternion.z
      this.__w = quaternion.w
      if (this.__onChange) this.__onChange()
    }
    return this
  }

  set (x, y, z, w) {
    if (x !== this.x || y !== this.y || z !== this.z || w !== this.w) {
      this.__x = x
      this.__y = y
      this.__z = z
      this.__w = w

      if (this.__onChange) this.__onChange()
    }
    return this
  }
}

export class EulerWrapper extends THREE.Euler {
  constructor (onChange, x, y, z, order) {
    super(x, y, z, order)
    this.__onChange = onChange
  }

  get _x () { return this.__x }
  get _y () { return this.__y }
  get _z () { return this.__z }
  get _order () { return this.__order }

  set _x (v) { setterHelper(this, '__x', v, this.__onChange) }
  set _y (v) { setterHelper(this, '__y', v, this.__onChange) }
  set _z (v) { setterHelper(this, '__z', v, this.__onChange) }
  set _order (v) { setterHelper(this, '__order', v, this.__onChange) }

  clone () { return new THREE.Euler(this.__x, this.__y, this.__z, this.__order) }

  copy (euler) {
    if (euler.x !== this.x || euler.y !== this.y || euler.z !== this.z || euler.order !== this.order) {
      this.__x = euler.x
      this.__y = euler.y
      this.__z = euler.z
      if (this.__onChange) this.__onChange()
    }
    return this
  }

  set (x, y, z, order = this.__order) {
    if (x !== this.x || y !== this.y || z !== this.z || order !== this.order) {
      this.__x = x
      this.__y = y
      this.__z = z
      this._order = order

      if (this.__onChange) this.__onChange()
    }
    return this
  }
}

function setterHelper (object, key, value, onChange) {
  if (value !== object[key]) {
    object[key] = value
    if (onChange) onChange()
  }
}
