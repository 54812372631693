var THREE = require('three')

/*
  Histogram Rendering
  ===================
  Our goal is the find out what meshes are visible. Some are invisible due to being outside of the camera frustum. Some are hidden behind other objects.
  Testing against camera frustum is easy on the CPU but it is the hidden behind other objects that motivates the GPU solution we have here.
  We don't render gizmos. Transparant surfaces could give surprising results.

  This is used for the annoations (tools/annotations) but since it only renders and compacts the generic ID used in the render scene it is kept outside of annotations.
  It could be reused for other things as well.

  Pass 1 - ID-Pass
  ----------------
  First all surfaces are rendered using an ID-shader that writes the mesh ID into a render target.
  The ID used is the index of the mesh in the render scene (renderSceneMeshID).
  Since we support WebGL1 we use a 8-bit render target and pack the index into RGB using the function setHex.

  Z-buffering ensures that only the surface closest to the camera is being rendered.

  The vertex shader (idVert) and the fragment shader (idFrag) are quite small.
  It transforms the primitives and writes the ID to the framebuffer but does nothing else.

  Pass 2 - Compaction Pass
  ------------------------
  Instead of downloading the full image (say a million pixels) we do a compaction pass first.
  While taking some time on the GPU this makes it faster on the CPU side. It would be interesting to measure this!

  The idea behind the compaction is a bit weird.
  We setup a framebuffer that has one pixel for every ID we are interested in. Our IDs start at 0 so that makes sense.
  To support say 900 IDs the target framebuffer will be a 32*32 framebuffer that can hold 1024 elements.
  We then use the 900 first pixels to store a 0 for if not visible or a 1 if visible.

  In order to fill the ID-buffer we render one point per pixel on the screen.
  The vertex shader look in the full ID-framebuffer at the pixel that it has been assigned.
  It then move the vertex such that if its pixel is 99 it will render into pixel 99 of the target, hence tagging index 99 as being used.

  We store our mesh in this.compactionMesh. In order to avoid having a very large mesh, that we also need to resize when we get more pixels, we use instancing to amplify 8096 vertices.
  If we want 10000 vertices we render two instances of 8096 vertices. There will be too many vertices being used but we will see that it is OK.

  Since we don't have access to the instance id (gl_InstanceID) in WebGL we manually make an instanced buffer ("instanceBaseId") with one integer per instance.
  This gives us 8096*8069 possible pixels. This could cause problems if we used annotations at very high resolutions. If we need to increase these, take care to not confuse the two different
  sources of the number 8096. Only modify the instanceBase and the instanceCount.

  Readback
  --------
  The data from the compact render target can then be read using this.readback.
  We get a texture that is far smaller than the full convas due to compaction.
  In it we can check if a certain ID is visible or not.

  This is not a nice thing to do since it is a blocking call, making the CPU wait until the GPU has finished rendered everything.
  Ideally you want the CPU to go on and generate new work for the GPU while the GPU is working.

  Read more here:
  https://developer.mozilla.org/en-US/docs/Web/API/WebGL_API/WebGL_best_practices#avoid_blocking_api_calls_in_production_e.g._geterror_getparameter

  Relevant copy-past:

    Certain WebGL entry points cause synchronous stalls on the calling thread.
    Even basic requests can take as long as 1ms, but they can take even longer if they need to wait for all graphics work to be completed (with an effect similar to glFinish() in native OpenGL).
    In production code, avoid such entry points, especially on the browser main thread where they can cause the entire page to jank (often including scrolling or even the whole browser).

  ...

  readPixels() to the CPU (i.e. without an UNPACK buffer bound): finish + round-trip.

  Optimization
  ------------
  If the histogram becomes more used the following things could be done to speed it up:
  * Measure if compaction is good or bad. Maybe javascript can loop through the readback image and update a bit per ID or something.
    * Try both and compare performance
  * Make sure the readback is not blocking the GPU. See above under 'Readback'.
    * At least fix it in WebGL2.
*/
const Histogram = function () {
  this.idMaterial = undefined
  this.idTarget = undefined
  this.compactionMaterial = undefined
  this.compactionScene = undefined
  this.compactionTarget = undefined
  this.compactionMesh = undefined
  this.compactionReadback = undefined

  var isDisposed = false

  this.dispose = function () {
    if (isDisposed) return
    isDisposed = true

    for (var key in this) {
      if (this[key] !== undefined && this[key].dispose && !this[key].isScene) {
        this[key].dispose()
      }
    }
  }

  this.setup = function (renderer) {
    var idVert =
`#define SHADER_NAME vertMaterial\n
precision highp float;\n
uniform mat4 viewMatrix;\n
uniform mat4 modelMatrix;\n
uniform mat4 projectionMatrix;\n
attribute mat4 instanceMatrix;\n
attribute vec3 position;\n
void main() {\n
  vec3 positionEye = ( viewMatrix * modelMatrix * instanceMatrix * vec4( position, 1.0 ) ).xyz;\n
  gl_Position = projectionMatrix * vec4( positionEye, 1.0 );\n
}\n`

    var idFrag =
`#define SHADER_NAME fragMaterial\n
#extension GL_OES_standard_derivatives : enable\n
precision highp float;\n
uniform vec3 idColor;\n
void main() {\n
  gl_FragColor = vec4( idColor, 1.0 );\n\
}\n`

    this.idMaterial = new THREE.RawShaderMaterial({
      vertexShader: idVert,
      fragmentShader: idFrag,
      uniforms: {
        idColor: {
          value: new THREE.Color()
        }
      }
    })

    this.idMaterial.side = THREE.DoubleSide // ? Changing breaks overrideMaterial as a viable way to do id-material... must change materials on objects depending on sideness

    var compactVert =
`#define SHADER_NAME vertMaterial\n
precision highp float;\n
uniform float id_target_width, id_target_height;
uniform float compaction_target_width, histogram_target_height;
uniform sampler2D id_target_sampler;
\n
attribute vec3 position;\n
attribute float instanceBaseId;\n
\n
void main() {\n
  gl_PointSize = 1.0;\n

  float id_buffer_pixel = floor(instanceBaseId + position.x);

  // Choose the point in the id target that this point correspond to (point N maps to pixel N)
  float id_x = (  mod(id_buffer_pixel,  id_target_width)+0.5)/id_target_width;
  float id_y = (floor(id_buffer_pixel / id_target_width)+0.5)/id_target_height;

  vec3 color_at_pixel = texture2D(id_target_sampler, vec2(id_x, id_y)).rgb;

  // TODO: Test this code with higher IDs. Should be robust to 2^24
  vec3 ci = floor(color_at_pixel * 256.0);
  float id_at_pixel = floor(ci.b + ci.g*256.0 + ci.r*256.0*256.0);

  // Now position our point in the histogram-target so it renders in the right texel/cell
  // Coordinates in NDC space are from -1 to 1
  float histogram_x = (  mod(id_at_pixel,  compaction_target_width)+0.5)/compaction_target_width*2.0-1.0;
  float histogram_y = (floor(id_at_pixel / compaction_target_width)+0.5)/histogram_target_height*2.0-1.0;

  gl_Position = vec4(histogram_x, histogram_y, 0, 1);
}\n`

    var compactFrag =
`#define SHADER_NAME fragMaterial\n
#extension GL_OES_standard_derivatives : enable\n
precision highp float;\n
void main() {\n
  gl_FragColor = vec4( 1,1,1,1 );\n\
}\n`

    this.compactionMaterial = new THREE.RawShaderMaterial({
      vertexShader: compactVert,
      fragmentShader: compactFrag,
      uniforms: {
        id_target_width: { type: 'f', value: 1.0 },
        id_target_height: { type: 'f', value: 1.0 },
        id_target_sampler: { value: null },
        compaction_target_width: { type: 'f', value: 1.0 },
        histogram_target_height: { type: 'f', value: 1.0 }
      }
    })

    this.compactionMesh = new THREE.Points(generateCustomGeom(), this.compactionMaterial)
    this.compactionMesh.frustumCulled = false
    this.compactionScene = new THREE.Scene()
    this.compactionScene.add(this.compactionMesh)
  }

  function generateCustomGeom () {
    var id = []
    for (let i = 0; i < 8192; i++) {
      id.push(i, i, i)
    }

    var instanceBase = []
    for (let i = 0; i < 8192; i++) {
      instanceBase.push(i * 8192)
    }

    var geometry = new THREE.InstancedBufferGeometry()
    geometry.instanceCount = 8192
    // TODO: We must have some position to be able to generate bounding geometry. Can we turn that off?
    //       If so rename position to id and make it a single float
    geometry.setAttribute('position', new THREE.Float32BufferAttribute(id, 3))
    geometry.setAttribute('instanceBaseId', new THREE.InstancedBufferAttribute(new Float32Array(instanceBase), 1))

    return geometry
  }

  this.render = function (renderer, scene, camera, { idSizeDivideFactor = 1 }) {
    var oldOverride = scene.overrideMaterial
    scene.overrideMaterial = this.idMaterial

    var gl = renderer.getContext()
    var u = this.idMaterial.uniforms
    var materialProperties = renderer.properties.get(this.idMaterial)
    var p = materialProperties.program
    var uv = u.idColor.value
    var idMaterial = this.idMaterial

    function onBeforeRender (renderer, scene, camera, geometry, material, group) {
      if (material !== idMaterial) return
      var id = this.renderSceneMeshID
      // TODO: It seems as if we don't get a proper p for the first frame. I guess annotations won't work during that frame
      // Might be just for first object for first frame (even though we precompiled the shader)
      if (p) {
        gl.useProgram(p.program)
        p.getUniforms().setValue(gl, 'idColor', uv.setHex(id))
      }
    }

    // We should be able to use an array since we have indiecs
    var maxHistogramId = 0

    scene.traverse(function (mesh) {
      if (!mesh.isMesh) return
      mesh.onBeforeRender = onBeforeRender
      // TODO: Once we honor userData.histogramId in child, turn those without to non-visible? Or at least GUI elements?
      const id = mesh.renderSceneMeshID
      if (id > maxHistogramId) {
        maxHistogramId = id
      }
    })

    // If we are using index 0,1,2,3 then we need room for 4 ids, ie maxHistogramId+1
    maxHistogramId++

    var idSize = renderer.getSize()
    idSize.width = idSize.width / idSizeDivideFactor
    idSize.height = idSize.height / idSizeDivideFactor

    var recreateIdTarget = false

    if (this.idTarget === undefined) {
      recreateIdTarget = true
    } else if (this.idTarget.width !== idSize.width || this.idTarget.height !== idSize.height) {
      recreateIdTarget = true
      // TODO: Can we resize?
      this.idTarget.dispose() // ?
    }
    if (recreateIdTarget) {
      this.idTarget = new THREE.WebGLRenderTarget(idSize.width, idSize.height, { generateMipmaps: false, depthBuffer: true, stencilBuffer: false, minFilter: THREE.NearestFilter, magFilter: THREE.NearestFilter })
    }

    const clearColor = renderer.getClearColor()
    const oldClearAlpha = renderer.getClearAlpha()
    const oldClearR = clearColor.r
    const oldClearG = clearColor.g
    const oldClearB = clearColor.b

    // Pixels that are have no surface rendered to them will get color (1,1,1) which maps to very high ID number
    // This makes ID 0 safe to use in the ID-buffer
    clearColor.r = clearColor.g = clearColor.b = 1

    renderer.setRenderTarget(this.idTarget)
    renderer.clear()
    renderer.render(scene, camera)

    // Restore onBeforeRender and scene.overrideMaterial
    scene.traverse(function (mesh) {
      if (!mesh.isMesh) return
      mesh.onBeforeRender = function () {}
    })
    scene.overrideMaterial = oldOverride

    var recreateHistogramTarget = false
    if (this.compactionTarget === undefined) {
      // Histogram target not created yet. It has been postponed to
      recreateHistogramTarget = true
    } else if (this.compactionTarget.width * this.compactionTarget.height < maxHistogramId) {
      // Histogram target can not hold all the indices we need
      recreateHistogramTarget = true
      // TODO: Can we resize targets or dispose of them better?
      this.compactionTarget.dispose()
    }

    if (recreateHistogramTarget) {
      let side = Math.ceil(Math.sqrt(maxHistogramId))
      if (side < 4) {
        // We make sure the render target is at least 4x4 pixels large
        // This also handles the case when there are no IDs in use before the compactionTarget is created the first time
        side = 4
      } else {
        // Round up to closest power-of-two size
        side = Math.pow(2, Math.ceil(Math.log2(side)))
      }
      this.compactionTarget = new THREE.WebGLRenderTarget(side, side, { generateMipmaps: false, depthBuffer: false, stencilBuffer: false, minFilter: THREE.NearestFilter, magFilter: THREE.NearestFilter })
      this.compactionReadback = new Uint8Array(side * side * 4)
    }

    // Make compact histogram
    this.compactionMaterial.uniforms.id_target_width.value = this.idTarget.width
    this.compactionMaterial.uniforms.id_target_height.value = this.idTarget.height
    this.compactionMaterial.uniforms.id_target_sampler.value = this.idTarget.texture
    this.compactionMaterial.uniforms.compaction_target_width.value = this.compactionTarget.width
    this.compactionMaterial.uniforms.histogram_target_height.value = this.compactionTarget.height
    this.compactionMaterial.uniformsNeedUpdate = true
    this.compactionMesh.geometry.instanceCount = Math.ceil(this.idTarget.width * this.idTarget.height / 8096.0)

    // Set all entries in the histogram to zero
    clearColor.r = clearColor.g = clearColor.b = 0

    // TODO: Camera is ignored in shader but might be used for culling. Make a proper camera? Disable culling?
    renderer.setRenderTarget(this.compactionTarget)
    renderer.clear()
    renderer.render(this.compactionScene, camera)

    clearColor.r = oldClearR
    clearColor.g = oldClearG
    clearColor.b = oldClearB

    renderer.setClearColor(clearColor)
    renderer.setClearAlpha(oldClearAlpha)
  }

  this.readback = function (renderer) {
    renderer.readRenderTargetPixels(this.compactionTarget, 0, 0, this.compactionTarget.width, this.compactionTarget.height, this.compactionReadback)
    return this.compactionReadback
  }
}

module.exports = {
  Histogram
}
