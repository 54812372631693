import * as THREE from 'three'
import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter.js'
import type { Go3DViewer } from '../types/Go3DViewer'

export default class GLTFExporter2 {
  private gltfExporter = new GLTFExporter()
  private scene = new THREE.Scene()
  constructor (app: Go3DViewer) {
    return this
  }

  setScene (geoArray:any[]) {
    this.scene.clear()
    geoArray.forEach((value) => {
      value.material = new THREE.MeshStandardMaterial({ side: THREE.BackSide })
      this.scene.add(value)
    })
  }

  async export (geoArray:any[]) {
    const options = {
      onlyVisible: false
    }
    this.setScene(geoArray)
    return new Promise((resolve, reject) => {
      this.gltfExporter.parse(this.scene, (gltf) => {
        resolve(gltf)
      }, options)
    })
  }
}
