import Immutable from 'seamless-immutable'
import { getLocalState } from './selectors'
import * as fromThreeviewerUI from '../threeviewer/ui'

export const UNDO = 'undoRedo/UNDO'
export const REDO = 'undoRedo/REDO'
export const DISPOSE = 'undoRedo/DISPOSE'
export const ADD = 'undoRedo/ADD'

const ADD_COMMAND = 'undoRedo/ADD_COMMAND'
const MAX_HISTORY = 40

export function undo () {
  return (dispatch, getState) => {
    const { undos } = getLocalState(getState())

    let command

    if (undos.length) {
      command = undos[0]
    }

    if (command !== undefined && command.undo) {
      command.undo()
      dispatch({ type: UNDO })
    }
    dispatch(fromThreeviewerUI.forceUpdate())
  }
}

export function redo () {
  return (dispatch, getState) => {
    const { redos } = getLocalState(getState())

    let command

    if (redos.length) {
      command = redos[0]
    }

    if (command !== undefined && command.execute) {
      command.execute()
      dispatch({ type: REDO })
    }
    dispatch(fromThreeviewerUI.forceUpdate())
  }
}

export function addCommand (command, clearRedos = true) {
  return { type: ADD_COMMAND, command, clearRedos }
}

export function dispose () {
  return {
    type: DISPOSE
  }
}

// 4 Reducer
var initialState = Immutable({
  redos: [],
  undos: []
})

export default function reducer (state = initialState, action) {
  switch (action.type) {
    case UNDO:
      return state.merge({
        undos: state.undos.length === 1 ? [] : state.undos.slice(1),
        redos: [state.undos[0]].concat(state.redos)
      })

    case REDO:
      return state.merge({
        undos: state.redos.length > 0 ? [state.redos[0]].concat(state.undos) : state.undos,
        redos: state.redos.length === 1 ? [] : state.redos.slice(1)
      })

    case ADD_COMMAND:
      return state.merge({
        undos: [action.command].concat(state.undos).slice(0, MAX_HISTORY),
        redos: action.clearRedos ? [] : state.redos
      })

    case DISPOSE:
      return initialState

    default:
      return state
  }
}
