import React from 'react'
import colors from '../../../../../css/colors'

type AppearanceGroupStyles = 'materialColor' | 'costTotalStyle' | 'materialType' | 'arrow' | 'arrowContainer' | 'materialContainer' | 'materialContainerHeader' | 'partsHeader' | 'noMateriaTypebox' | 'iconCube' | 'icon' | 'row' | 'crossIconLeft' | 'crossIconRight'
export const appearanceGroupStyles: {
  [key in AppearanceGroupStyles]: React.CSSProperties
} = {
  materialColor: {
    width: 12,
    height: 1,
    margin: '0 8px'
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    userSelect: 'none'
  },
  costTotalStyle: {
    height: 50,
    background: colors.grayLight,
    color: colors.black
  },
  materialType: {
    display: 'flex',
    alignItems: 'center',
    padding: 4,
    flex: 1,
    minWidth: 0
  },
  arrowContainer: {
    padding: '7px 7px 7px 4px',
    display: 'flex',
    alignItems: 'center'
  },
  arrow: {
    marginLeft: 7
  },
  materialContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    paddingLeft: 14,
    paddingRight: 14,
    paddingBottom: 14
  },
  materialContainerHeader: {
    fontWeight: 'bold',
    marginBottom: 4
  },
  partsHeader: {
    fontWeight: 'bold',
    marginBottom: 4,
    marginTop: 2,
    marginRight: 8
  },
  noMateriaTypebox: {
    width: 18,
    height: 18,
    marginRight: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.white,
    overflow: 'hidden',
    borderRadius: 2
  },
  iconCube: {
    marginRight: 6,
    marginLeft: 10
  },
  icon: {
    marginRight: 8,
    width: 18,
    height: 18,
    userSelect: 'none'
  },
  crossIconLeft: {
    backgroundColor: colors.errorLightAlt,
    width: 1,
    height: 30,
    transform: 'rotate(46deg)',
    marginTop: 1
  },
  crossIconRight: {
    backgroundColor: colors.errorLightAlt,
    width: 1,
    height: 30,
    transform: 'rotate(-46deg)',
    marginTop: 1
  }
}
