import React, { useEffect, useRef } from 'react'
import { MdSearch as IconSearch } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { AutoSizer, List, ListRowProps } from 'react-virtualized'
import * as fromColors from '../../../../stores/ducks/colors'
import * as fromProjects from '../../../../stores/ducks/projects'
import * as fromProjectsSelectors from '../../../../stores/ducks/projects/selectors'
import { setKeyBoardBindings } from '../../../../stores/ducks/threeviewer/ui'
import { disableKeyboardListeners, enableKeyboardListeners } from '../../../../stores/ducks/threeviewer/viewer'
import { PanelSectionHeader } from '../../../common/panels/panel-section'
import ColorStyled from './Color'
import ColorSearchModal from './color-search-modal'
import * as fromMaterialAndColorSelectors from './material-and-color-selectors'

// The height of a single row in a react-virtualized list has to be set explicitly
const NODE_HEIGHT = 60

const ColorList = () => {
  const dispatch = useDispatch()
  const { colors } = useSelector(fromMaterialAndColorSelectors.selectColorsVisualizer)
  const markedColorIds = useSelector(fromProjectsSelectors.getMarkedColors)
  const [colorSearchModalOpen, setColorSearchModalOpen] = React.useState(false)
  const [markedColors, updateMarkedColors] = React.useState<string[]>(markedColorIds)
  const projectId = useSelector(fromProjectsSelectors.getCurrentId)
  const [scrollToIndex, setScrollToIndex] = React.useState<number |undefined>(undefined)

  const enableKeyBoardBindings = () => {
    dispatch(setKeyBoardBindings(true))
    dispatch(enableKeyboardListeners())
  }

  const disableKeyBoardBindings = () => {
    dispatch(setKeyBoardBindings(false))
    dispatch(disableKeyboardListeners())
  }

  // To avoid execute update on first render
  const firstUpdate = useRef(true)
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }
    if (projectId) {
      const data = {
        id: projectId,
        markedColors: markedColors
      }
      dispatch(fromProjects.updateFavorites(data))
    }
  }, [markedColors])

  const toggleMarkColors = (id: string) => {
    if (markedColors.includes(id)) {
      updateMarkedColors(markedColors.filter(i => i !== id))
    } else {
      updateMarkedColors([...markedColors, id])
    }
  }

  const rowRenderer = ({ key, index, style }: ListRowProps) => {
    const color = colors[index]

    return (
      <div
        key={key}
        style={style}
      >
        <ColorStyled
          markedColorIds={markedColorIds}
          color={color}
          onApply={() => onApply(color.id)}
          onFavoriteClick={() => toggleMarkColors(color.id)}
        />
      </div>
    )
  }

  const onApply = (id:string) => {
    dispatch(fromColors.setColor({ id }))
  }

  return (
    <div className='py2 bc-gray-light border-right overflow-hidden'>
      <div className='flex justify-center pb2'>
        <PanelSectionHeader noPadding title='Color'/>
      </div>
      <div className='flex justify-center pb2'>
        <div
          className='flex justify-center items-center pointer bg-gray-light-hover border'
          data-testid = 'color-modal-open'
          style={{ borderRadius: '100%', height: 46, width: 46, marginTop: 2 }}
          title='Search color'
          onClick={() => {
            setColorSearchModalOpen(true)
            disableKeyBoardBindings()
          }}
        >
          <IconSearch size={24} />
        </div>
      </div>
      <div style={{ height: '80vh' }} >
        <div
          className='px2 overflow-auto height-100'
          data-testid='color-list'
        >
          <AutoSizer>
            {({ height }) => {
              return <List
                style={{
                  outline: 'none'
                }}
                width={60}
                height={height}
                rowCount={colors.length}
                rowRenderer={rowRenderer}
                rowHeight={NODE_HEIGHT}
                scrollToIndex={scrollToIndex}
              />
            }}
          </AutoSizer>
        </div>
      </div>
      <ColorSearchModal
        isOpen={colorSearchModalOpen}
        markedColorIds={markedColorIds}
        colors={colors}
        onRequestClose={() => {
          setColorSearchModalOpen(false)
          enableKeyBoardBindings()
        }}
        onApply={(id: string) => onApply(id)}
        onSelectColor={(id) => {
          const index = colors.map((color: any) => color.id).indexOf(id)
          setScrollToIndex(index)
        }}
        onFavoriteClick={(id: string) => toggleMarkColors(id)}
      />
    </div>
  )
}

export default React.memo(ColorList)
