import _get from 'lodash/get'
import React from 'react'
import { useSelector } from 'react-redux'
import { AssetType, GlobalProjectModals } from '..'
import type { Folder } from '../../../stores/ducks/folders/Folder'
import { RootState } from '../../../stores/ducks'
import { getCurrentUserId, getHasCurrentScopes } from '../../../stores/ducks/users/selectors'
import AutoFill from '../../common/grid/AutoFill'
import Pill from '../../common/Pill'
import Card from '../card/Card'
import type { Item } from '../card/Item'
import ProgressCard from '../card/ProgressCard'
import { ContentModals } from '../ProjectContent'
import { VirtualProductsViewModals } from '../virtual-products/VirtualProductsView'

type Props = {
  emptyStateComponent: any
  uploadComponent: any
  className: string
  itemsDone: Item[]
  itemsInProgress: Item[]
  contentName: AssetType
  selectedIds: string[]
  isDragging: boolean
  folders: Folder[]
  editOnClick: boolean
  showToastMessage: (msg: string) => void
  onClickImage?: (item: any) => void
  onSecondaryAction: null | ((item: any) => void)
  onSelect: () => void
  onEdit: (id: string, data: any, isBatchRender: boolean, isGlobal: boolean) => void
  onDragStart: (event: React.DragEvent<HTMLDivElement>, id: string) => void
  onDragEnd: (event: React.DragEvent<HTMLDivElement>) => void
  onOpenModal: (modal: ContentModals | GlobalProjectModals | VirtualProductsViewModals | null, payload: Item) => void
  removeItem?: (payload: Item) => void
  removeItemAsAdmin?: (payload: Item) => void
  cardSize?: 'medium' | 'large'
  textIsEditable?: boolean
  selectable?: boolean
}

const Content = (props: Props) => {
  if (props.emptyStateComponent) return props.emptyStateComponent

  const assetType = props.contentName === 'pattern' ? 'patterns' : 'combinations'

  const sceneTabActive = (props.contentName === 'scene')
  const ITEMS_PER_PAGE = 48
  const imagesPerPage = props.uploadComponent === null ? ITEMS_PER_PAGE : ITEMS_PER_PAGE - 1

  const pages = Math.ceil(props.itemsDone.length / imagesPerPage)
  const [imagesPage, setImagesPage] = React.useState(1)
  const [imagesArray, setImageArray] = React.useState<Item[] | undefined>(undefined)
  const userId = useSelector(getCurrentUserId)
  const userIsAdmin = useSelector((state: RootState) => getHasCurrentScopes('admin')(state))

  React.useEffect(() => {
    const page = Math.ceil(props.itemsDone.length / imagesPerPage) < imagesPage ? 1 : imagesPage
    pagination(page, props.itemsDone, imagesPerPage)
  }, [props.itemsDone, imagesPage])

  function pagination (page:number, items:any, imagesPerPage:number) {
    setImagesPage(page)
    setImageArray(items.slice((page - 1) * imagesPerPage, (page * imagesPerPage)))
  }

  const getAssociatedFolderColors = (id: string) => {
    return props.folders
      .filter(folder => Object.keys(_get(folder, assetType, {})).includes(id))
      .map(folder => folder.color.hsl)
  }

  const isBatchRender = (item:Item) => {
    if (item.imageTemplateId && item.connectedBatchRenderCombinationIds) return true
    return false
  }

  const isGlobal = (item:Item) => (
    item.global ?? false
  )

  const hasGlobalEditAccess = (item:Item) => (
    userIsAdmin || item.createdBy === userId
  )

  return (
    <div className={props.className}>
      <AutoFill
        className='pb3'
        width={170}
        xxlWidth={224}
        style={{ marginTop: '4px' }}
      >
        {props.uploadComponent}
        {props.itemsInProgress.map(item => {
          return (
            <ProgressCard
              item={item}
              key={`progress-${item.id}`}
              onSecondaryAction={() => props.onSecondaryAction && props.onSecondaryAction(item)}
              combinationType={item.combinationType}
              onClickImage={() => {
                if (item.combinationType === 'variant' || item.combinationType === 'render') {
                  props.onClickImage && props.onClickImage(item)
                }
              }}
            />
          )
        })}

        {imagesArray !== undefined && imagesArray.map(item => (
          <Card
            key={`done-${item.id}`}
            item={item}
            onSelect={props.onSelect}
            selected={props.selectedIds.includes(item.id)}
            onEdit={(data: { title: string }) => props.onEdit(item.id, data, isBatchRender(item), isGlobal(item))}
            onSecondaryAction={() => props.onSecondaryAction && props.onSecondaryAction(item)}
            onClickImage={() => props.onClickImage && props.onClickImage(item)}
            folderColors={getAssociatedFolderColors(item.id)}
            onDragStart={(event: React.DragEvent<HTMLDivElement>) => props.onDragStart(event, item.id)}
            onDragEnd={props.onDragEnd}
            isDragging={props.isDragging}
            defaultCursor={props.onClickImage ? 'pointer' : 'default'}
            editOnClick={props.editOnClick}
            contentName={props.contentName}
            subtitle={sceneTabActive ? item.projectTitle : item.subtitle}
            manifest ={sceneTabActive ? (item.render && item.render.manifest) : item.manifest}
            onOpenModal={(modal: ContentModals | GlobalProjectModals | VirtualProductsViewModals) => props.onOpenModal(modal, item)}
            removeItem={() => props.removeItem ? props.removeItem(item) : undefined}
            removeItemAsAdmin={() => props.removeItemAsAdmin ? props.removeItemAsAdmin(item) : undefined}
            showToastMessage={props.showToastMessage}
            textIsEditable={hasGlobalEditAccess(item)}
            selectable={props.selectable}
            exportEnabled={!!item.combinationId}
          />
        ))}
      </AutoFill>
      {pages > 1 && (
        <div className="width-100 flex flex-wrap justify-center p2">
          {[...Array(pages)].map((unused, i) =>
            <Pill style={{ margin: '2px' }} key={i} active={imagesPage === i + 1} onClick={() => { setImagesPage(i + 1) }}>{i + 1}</Pill>
          )}
        </div>
      )}
    </div>
  )
}

export default Content
