import { Object3D, Vector3 } from 'three'
import type { WallId } from './Walls'
import { createDoor, createWindow } from './helpers'

const windowObject = createWindow()
const doorObject = createDoor()

export type HoleDescription = {
  wallId?: WallId
  index?: number
  modelId: string
  size: { x: number, y: number }
  position: { x: number, y: number }
  type: string
}

let id = 0

export class Hole {
  modelId: string
  id: number
  wallId: WallId | null

  object: Object3D
  size: { x: number, y: number }
  position: { x: number, y: number }
  type: string
  rotation = 0

  private lastValidPositionX: number | null = null
  private lastValidWallId: string | null = null
  private _position3 = new Vector3()

  constructor (description: HoleDescription, wallId: WallId) {
    this.wallId = wallId
    this.id = id++
    this.modelId = description.modelId
    this.position = description.position
    this.size = description.size
    this.type = description.type
    this.object = description.type === 'window' ? windowObject.clone() : doorObject.clone()
    this.object.scale.set(this.size.x, this.object.scale.y, this.object.scale.z)
  }

  get visible () {
    return this.object.visible
  }

  set visible (value: boolean) {
    this.object.visible = value
  }

  setLastValidPositionX (x: number) {
    this.lastValidPositionX = x
  }

  getLastValidPositionX () {
    return this.lastValidPositionX
  }

  getLastValidWallId () {
    return this.lastValidWallId
  }

  setLastValidWallId (wallId: string) {
    this.lastValidWallId = wallId
  }

  get description (): HoleDescription {
    return {
      wallId: this.wallId ?? undefined,
      modelId: this.modelId,
      size: this.size,
      position: { x: this.position.x, y: this.position.y },
      type: this.type
    }
  }

  get vertices () {
    const x0 = this.position.x + this.size.y * 0.01
    const y0 = this.position.y + this.size.y * 0.01
    const x1 = this.position.x + this.size.x * 0.99
    const y1 = this.position.y + this.size.y * 0.99

    return [
      {
        x: x0,
        y: y1
      },
      {
        x: x1,
        y: y1
      },
      {
        x: x1,
        y: y0
      },
      {
        x: x0,
        y: y0
      }
    ]
  }

  get position3 () {
    return this._position3.copy(this.object.position).setY(this.position.y + this.size.y * 0.5)
  }

  set (x: number, position3: Vector3, rotation: number) {
    this.object.position.copy(position3)
    this.object.rotation.z = rotation
    this.position.x = x
    this.rotation = rotation
  }
}

export class Holes {
  private _objectToHole = new Map<Object3D, Hole>();
  register (hole: Hole) {
    this._objectToHole.set(hole.object, hole)
  }

  deregister (hole: Hole) {
    this._objectToHole.delete(hole.object)
  }

  clear () {
    this._objectToHole.clear()
  }

  objectToHole (node: Object3D) {
    return this._objectToHole.get(node)
  }

  get list () {
    const holes: Hole[] = []
    this._objectToHole.forEach((hole) => holes.push(hole))
    return holes
  }
}
