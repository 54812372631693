import React, { useState } from 'react'
import { connect, ConnectedProps, useSelector } from 'react-redux'
import { createSelector } from 'reselect'

import styled from 'styled-components'
import _debounce from 'lodash/debounce'

import { HOCMultiSelect, InjectedHOCMultiSelectProps } from '../common/multi-select'
import Grid from '../common/grid/grid'
import TabGroup from '../common/tabs/tab-group'
import LoadMore from '../visualizer/panels/material-and-color/material-search/load-more'

import InputSearch from '../common/form/input-search'
import InputCheckbox from '../common/form/input-checkbox'
import ConfirmSelection from './confirm-selection'
import SearchFilters from './search-filters'
import FetchDataSearchFilters from './search-filters-fetch-data'
import SearchResults from './search-results'

import GeometryUploadView from '../upload-files/geometry-upload-view'
import UploadFiles from '../upload-files/index'
import uploadUtils from '../upload-files/utils'
import DragAndDropStyled from '../common/DragAndDropStyled'
import { FaCloudUploadAlt as IconUpload } from 'react-icons/fa'

import EmptyState from '../common/empty-state/empty-state'
import EmptyStateUpload from '../common/empty-state/with-upload'
import { EmptyVariants } from '../project/project-components/EmptyStates'
import IconCube from '../common/icons/icon-cube'
import Info from '../common/info'
import Label from '../common/form/label'

import * as fromDesigns from '../../stores/ducks/designs'
import * as fromProjects from '../../stores/ducks/projects'
import * as fromProjectSelectors from '../../stores/ducks/projects/selectors'
import * as fromUploadsSelectors from '../../stores/ducks/uploads/selectors'
import * as fromCombinationsSelectors from '../../stores/ducks/combinations/selectors'
import * as fromVirtualProduct from '../../stores/ducks/virtual-products'
import { getIsFeatureActive } from '../../stores/ducks/threeviewer/selectors'

import { MODEL_RESOURCE_TYPES } from '../../constants.js'
import { RootState } from '../../stores/ducks'
import { getUpplysaLinks } from '../../utils/upplysa'

const InfoText = styled.div`
  font-size: 10px;
  color: red;
`

const {
  RESOURCE_DPD,
  RESOURCE_VARIANT,
  RESOURCE_MODEL_BANK,
  RESOURCE_PROPPING_BANK,
  RESOURCE_NON_CAD,
  RESOURCE_VIRTUAL_PRODUCTS
} = MODEL_RESOURCE_TYPES

export type TabTypes = 'upload' | 'variant' | 'modelbank' | 'ugabank' | 'non-cad' | 'virtualProduct'

function InitialLoaderComponent () {
  return (
    <div className='height-50 flex justify-center' >
      <Info icon='block-before icon-spin1 animate-spin my2' />
    </div>
  )
}

type UploadComponentProps = {
  onUpload: (files: any[]) => void
}
function UploadComponent (props: UploadComponentProps) {
  return (
    <UploadFiles
      onUpload={props.onUpload}
      supportedFormats={uploadUtils.acceptedFileTypes.map(t => `.${t}`)}
      multiple
    >
      {({ isDragging } : { isDragging: boolean }) => (
        <DragAndDropStyled
          style={{
            width: '100%',
            height: '100%',
            textAlign: 'center',
            paddingTop: '20%',
            paddingLeft: 10,
            paddingRight: 10
          }}
          isDragging={isDragging}
        >
          <div className='pointer-disabled' >
            <IconUpload size={60} />
            <p className='h5 m0'>Upload Geometry</p>
            <p className='h6 m0'><i>Drag files / Click</i></p>
          </div>
        </DragAndDropStyled>
      )}
    </UploadFiles>
  )
}

function ModelbankAgenda () {
  return (
    <div className='flex f6'>
      <div
        className='py1 px3 bg-error-light mr2'
        title='This item is no longer available in stores.'
      >
        Not in sale
      </div>
      <div
        className='py1 px3 bg-warn-lighter'
        title='This item is no longer available in some countries.'
      >
        Soon out of range
      </div>
    </div>
  )
}

export type DesignTypes = 'load' | 'connect'| 'replace'
interface OwnProps extends InjectedHOCMultiSelectProps {
  onClose: () => void
  onConfirm?: (ids: string[]) => void
  type: DesignTypes
}

const mapStateToProps = createSelector(
  (state: RootState) => state.designs.activeTab as TabTypes | null,
  (state: RootState) => state.designs.searchResults,
  (state: RootState) => state.designs.sizes,
  (state: RootState) => state.designs.error,
  (state: RootState) => state.designs.isSearching,
  (state: RootState) => state.designs.queryString,
  (state: RootState) => state.designs.shouldPerformFirstDefaultSearch,
  fromProjectSelectors.getCurrentId,
  (_: RootState, ownProps: OwnProps) => ownProps,
  fromUploadsSelectors.getCompletedCadsInCurrentProject,
  (state: RootState) => getIsFeatureActive(state),
  (state: RootState) => state.virtualProducts,
  (
    activeTab,
    searchResults,
    sizes,
    error,
    isSearching,
    queryString,
    shouldPerformFirstDefaultSearch,
    projectId,
    ownProps,
    uploadsInProgress,
    getIsFeatureActive,
    virtualProducts,
  ) => {
    let tabs = [
      {
        id: RESOURCE_DPD,
        label: 'UPLOADED GEOMETRY'
      },
      {
        id: RESOURCE_VARIANT,
        label: 'VARIANTS'
      },
      {
        id: RESOURCE_MODEL_BANK,
        label: 'IKEA MODEL BANK'
      },
      {
        id: RESOURCE_PROPPING_BANK,
        label: 'IKEA PROPPING BANK'
      },
      {
        id: RESOURCE_NON_CAD,
        label: 'IKEA NON-CAD'
      },
      {
        id: RESOURCE_VIRTUAL_PRODUCTS,
        label: 'VIRTUAL PRODUCTS (BETA)'
      }
    ]

    if (ownProps.type === 'connect') {
      tabs = []
    }

    let filteredVirtualProducts
    if (activeTab === 'virtualProduct') {
      if (queryString !== null) {
        filteredVirtualProducts = virtualProducts.filteredVirtualProductsUpplysa.filter((data: any) => {
          return data.title.toUpperCase().includes(queryString.toUpperCase()) || data.productName?.toUpperCase().includes(queryString.toUpperCase())
        })
      } else {
        filteredVirtualProducts = virtualProducts.filteredVirtualProductsUpplysa
      }
    }

    return {
      activeTab,
      error,
      isSearching,
      queryString,
      projectId: projectId as string | null,
      searchResults: activeTab === 'virtualProduct' ? filteredVirtualProducts : Array.from(searchResults) as any[],
      sizes,
      shouldPerformFirstDefaultSearch,
      uploadsInProgress,
      tabs: tabs,
      type: ownProps.type,
      filteredVirtualProductsUpplysa: filteredVirtualProducts,
      virtualProducts: virtualProducts
    }
  }
)

const mapDispatchToProps = (dispatch: any) => {
  const debouncedPerformSearch = _debounce(() => dispatch(fromDesigns.performSearch()), 250)
  return {
    fetchAggs: () => {
      dispatch(fromDesigns.fetchAggs())
    },
    fetchAggsVariants: () => {
      dispatch(fromDesigns.fetchAggsVariants())
    },
    fetchAggsUploads: () => {
      dispatch(fromDesigns.fetchAggsUploads())
    },
    performDefaultSearch: () => {
      dispatch(fromDesigns.performSearch())
      dispatch(fromDesigns.setShouldPerformFirstDefaultSearch(false))
    },
    performSearch: () => {
      dispatch(fromDesigns.performSearch())
    },
    setQueryString: (value: string) => {
      dispatch(fromDesigns.setQueryString(value))
      debouncedPerformSearch()
    },
    setActiveTab: (value: string | number) => {
      dispatch(fromDesigns.setActiveTab(value))
      dispatch(fromDesigns.setQueryString(''))
      dispatch(fromDesigns.clearSearchResults())
      dispatch(fromDesigns.performSearch())
    },
    loadMore: () => {
      dispatch(fromDesigns.incrementPage())
      dispatch(fromDesigns.performSearch())
    },
    receiveCadKey: (data: any, id: string) => {
      dispatch(fromProjects.receiveCadKey(data, id))
    },
    setIsModalOpen: (value: boolean) => {
      dispatch(fromDesigns.setIsModalOpen(value))
    },
    fetchVpFromUpplysa: () => {
      dispatch(fromVirtualProduct.getVirtualProductFromUpplysa())
    }
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>
type Props = PropsFromRedux & OwnProps

function Designs (props: Props) {
  const [files, setFiles] = useState<any[]>([])
  const [lastLengthOfInProgress, setLastLengthOfInProgress] = useState(0)
  const [timerId, setTimerId] = useState<number | null>(null)
  const [uploadsInProgressState, setUploadsInProgressState] = useState<any[]>([])
  const [defaultTab, setDefaultTab] = useState<TabTypes>('upload')
  const [saveWarningOpen, setSaveWarningOpen] = useState(false)

  const showReapplySettings = useSelector(
    // Has to be cast to any because typescript does not understand that the selector takes 3 arguments
    state => (fromCombinationsSelectors.getShowReapplyAppearancesAndMaterialsSettings as any)(
      state, props.type, (props.activeTab ?? defaultTab)
    )
  )

  // Only used when in replace mode
  // Gives user an option to enable/disable the re-apply appearances/materials feature
  const [shouldReapply, setShouldReapply] = useState({
    appearances: true,
    materials: true,
    usingMetadata: true,
    usingBoundingBoxes: false // NOTE: Experimental
  })

  React.useEffect(() => {
    if (props.shouldPerformFirstDefaultSearch) {
      props.performDefaultSearch()
      props.fetchAggs()
    }

    props.fetchAggsUploads()
    props.fetchAggsVariants()
    props.setIsModalOpen(true)
    const defaultTab = props.type === 'connect' ? 'variant' : 'upload'
    setDefaultTab(defaultTab)
    props.setActiveTab(defaultTab)

    return () => {
      if (timerId) clearTimeout(timerId)
      props.setIsModalOpen(false)
    }
  }, [])

  React.useEffect(() => {
    props.activeTab === 'virtualProduct' && props.fetchVpFromUpplysa()
    props.onClearSelected && props.onClearSelected()
    setFiles([])
  }, [props.activeTab])

  React.useEffect(() => {
    if (props.uploadsInProgress.length < lastLengthOfInProgress) {
      if (timerId) clearTimeout(timerId)
      setTimerId(setTimeout(() => {
        if (props.activeTab === 'upload') {
          props.performSearch()
          setUploadsInProgressState(props.uploadsInProgress)
          setTimerId(null)
        }
      }, 4500))
    } else if (!timerId) {
      setUploadsInProgressState(props.uploadsInProgress)
    }

    if (props.uploadsInProgress.length !== lastLengthOfInProgress) {
      setLastLengthOfInProgress(props.uploadsInProgress.length)
    }
  }, [props.uploadsInProgress])

  const handleUploaded = (data: any) => {
    if (props.projectId) props.receiveCadKey(data, props.projectId)
  }

  function handleUpplysaSSO () {
    const links = getUpplysaLinks()
    window.location.replace(`${links.upplysaURL}/auth/sso/login-sso?post_auth_uri=${links.redirectURL}`)
  }

  const SaveWarning = () => {
    return (
      <div className="flex flex-column items-center mt4 height-100">
        <h3 style={{
          fontWeight: 'normal'
        }}>You are about to leave DPD, any unsaved work will be lost.</h3>
        <h3 style={{
          fontWeight: 'normal'
        }}>Would you like to proceed?</h3>
        <button className='styled-button' onClick={() => { handleUpplysaSSO() }}>Continue to authentication</button>
        <button className='styled-button' onClick={() => { setSaveWarningOpen(!saveWarningOpen) }} >Cancel</button>
      </div>
    )
  }

  const selectionCount = Object.keys(props.selected || []).length

  const isUploading = props.uploadsInProgress.length > 0
  const searchResultsCount = props.searchResults.length
  const showInitialLoader = props.isSearching && !searchResultsCount
  const showEmptyState = !showInitialLoader && !searchResultsCount && !props.queryString
  const showSearchResults = (searchResultsCount || (isUploading && props.activeTab === RESOURCE_DPD)) &&
    !files.length &&
    !showInitialLoader

  return (
    <Grid
      className='height-100 absolute top-0 bottom-0 left-0 right-0 pt3'
      areas={[
        'empty tabs',
        'filters result'
      ]}
      rows={['auto', '1fr']}
      columns={['1fr', '4fr']}
      gridGap={0}
    >
      <div style={{ gridArea: 'empty' }} />
      <div
        className='pr2 overflow-y-auto'
        style={{ gridArea: 'filters' }}
      >
        <Label>
          Search
        </Label>
        <InputSearch
          placeholder='Search...'
          onChange={props.setQueryString}
          value={props.queryString}
          onClearSearch={() => props.setQueryString('')}
        />
        <div className='pt2'>
          {props.activeTab === 'virtualProduct' ? <FetchDataSearchFilters/>
            : <SearchFilters activeTab={props.activeTab || defaultTab} />}
        </div>

        { showReapplySettings && (<>
          <div className='pt2'>
            <Label>
              Attempt to re-apply
            </Label>
            <InputCheckbox
              name='enable-reapply-appearances'
              label='Appearances'
              value='enable-reapply-appearances'
              checked={shouldReapply.appearances}
              stopPropagation
              noWrap={false}
              onChange={(checked: boolean) => {
                setShouldReapply({ ...shouldReapply, appearances: checked })
              }}
            />
            <InputCheckbox
              name='enable-reapply-materials'
              label='Materials'
              value='enable-reapply-materials'
              checked={shouldReapply.materials}
              stopPropagation
              noWrap={false}
              onChange={(checked: boolean) => {
                setShouldReapply({ ...shouldReapply, materials: checked })
              }}
            />
          </div>
          <div className='pt2'>
            <Label
              subtle={!shouldReapply.appearances && !shouldReapply.materials}
            >
              Re-apply using
            </Label>
            <InputCheckbox
              name='enable-reapply-using-node-names'
              label='Part names'
              value='enable-reapply-using-node-names'
              checked={shouldReapply.usingMetadata}
              stopPropagation
              noWrap={false}
              disabled={!shouldReapply.appearances && !shouldReapply.materials}
              onChange={(checked: boolean) => {
                setShouldReapply({ ...shouldReapply, usingMetadata: checked })
              }}
            />
            <InputCheckbox
              name='enable-reapply-using-bounding-boxes'
              label='Part shapes/locations'
              value='enable-reapply-using-bounding-boxes'
              checked={shouldReapply.usingBoundingBoxes}
              stopPropagation
              noWrap={false}
              disabled={!shouldReapply.appearances && !shouldReapply.materials}
              onChange={(checked: boolean) => {
                setShouldReapply({ ...shouldReapply, usingBoundingBoxes: checked })
              }}
            />
            { (shouldReapply.usingBoundingBoxes && (shouldReapply.appearances || shouldReapply.materials)) && (
              <InfoText>
                Warning: Re-applying using shapes/locations is still experimental.
              </InfoText>
            )}
          </div>
        </>)}

      </div>
      <TabGroup
        style={{ gridArea: 'tabs' }}
        tabs={props.tabs}
        onToggleActive={props.setActiveTab}
        active={props.activeTab || defaultTab}
      />
      <Grid
        style={{ gridArea: 'result' }}
        rows={['1fr', 41]}
      >
        <div className='relative overflow-hidden bg-gray-light height-100'>
          {showInitialLoader && <InitialLoaderComponent />}

          {showSearchResults &&
            <LoadMore
              className='overflow-x-hidden overflow-y-auto custom-scrollbar absolute top-0 right-0 bottom-0 left-0 p2 height-100'
              onLoadMore={() => props.loadMore()}
            >
              <SearchResults
                selected={props.selected || []}
                onSelect={props.onSelect}
                hits={props.searchResults}
                queryString={props.queryString}
                resource={props.activeTab}
                projectId={props.projectId}
                itemsInProgress={uploadsInProgressState}
                uploadItem={props.activeTab === RESOURCE_DPD &&
                  <UploadComponent onUpload={setFiles}/>
                }
                type={props.type}
              />
            </LoadMore>
          }

          {!showInitialLoader && !showSearchResults && (
            <div className='overflow-auto custom-scrollbar absolute top-0 right-0 bottom-0 left-0 height-100'>
              {files.length > 0 &&
                <div className='flex justify-center items-center height-100 overflow-auto'>
                  <div style={{ width: 400, maxWidth: '75%' }}>
                    <GeometryUploadView
                      files={files}
                      onReset={() => setFiles([])}
                      onUploaded={handleUploaded}
                    />
                  </div>
                </div>
              }

              {!searchResultsCount && props.queryString &&
                <EmptyState
                  className='mt4'
                  title='No items found'
                  desc={`No search results for '${props.queryString}'`}
                />
              }

              {showEmptyState && props.activeTab === RESOURCE_DPD && !files.length && !isUploading &&
                <EmptyStateUpload
                  icon={<IconCube size={80} />}
                  className='mt4'
                  title='Upload geometry'
                  desc='All uploaded geometry will appear here and will be available in the design view.'
                  docLink='geometry.md'
                  linkText='Learn more'
                  onUpload={setFiles}
                  disableCreateButton
                  supportedFormats={uploadUtils.acceptedFileTypes.map(t => `.${t}`)}
                  multiple
                />
              }

              {showEmptyState && props.activeTab === RESOURCE_VARIANT &&
                <EmptyVariants />
              }

              {showEmptyState && props.activeTab === RESOURCE_NON_CAD &&
                <EmptyState
                  className='mt4'
                  title='No Non-CAD found'
                />
              }
              {props.virtualProducts.fetchingVirtualProduct && props.activeTab === RESOURCE_VIRTUAL_PRODUCTS &&
                <InitialLoaderComponent />
              }

              {props.virtualProducts.manifestResponseCode !== 401 && showEmptyState && props.activeTab === RESOURCE_VIRTUAL_PRODUCTS && !props.virtualProducts.fetchingVirtualProduct && (
                <EmptyState
                  className='mt4'
                  title='No virtual products found'
                />
              )}

              {/* We need to catch the 401 error responded from the frontend here */}
              {props.virtualProducts.manifestResponseCode === 401 && showEmptyState && props.activeTab === RESOURCE_VIRTUAL_PRODUCTS && !props.virtualProducts.fetchingVirtualProduct && (
                !saveWarningOpen
                  ? <EmptyState
                    className='mt4'
                    title='In order to view Virtual Products you will need to authenticate to UPPLYSA.'
                    actionTitle='Authenticate to UPPLYSA'
                    onAction={() => {
                      setSaveWarningOpen(!saveWarningOpen)
                    }}
                  />
                  : <SaveWarning />
              )}
            </div>
          )}
        </div>
        <div className='flex items-end justify-between'>
          <div>
            {props.activeTab === 'modelbank' && <ModelbankAgenda />}
          </div>

          <div className='flex items-center'>
            <ConfirmSelection
              onClose={props.onClose}
              onConfirm={props.onConfirm}
              type={props.type}
              selected={props.selected || []}
              shouldReapply={shouldReapply}
            >
              {selectionCount > 0 && !!props.tabs.length &&
                <p className='pr2'>
                  <span className='c-secondary'>{selectionCount}</span> selected
                </p>
              }
            </ConfirmSelection>
          </div>
        </div>
      </Grid>
    </Grid>
  )
}

export default connector(HOCMultiSelect(Designs))
