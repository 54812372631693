import React, { Component } from 'react'
import { connect } from 'react-redux'

import Label from '../../common/form/label'
import InputCheckbox from '../../common/form/input-checkbox'

import * as fromThreeViewerUi from '../../../stores/ducks/threeviewer/ui'
import * as fromThreeviewerSelectors from '../../../stores/ducks/threeviewer/selectors'

class MoveSettings extends Component {
  createCheckbox (title, checked, callback) {
    return (
      <div className="pr3 flex flex-column">
        <div className="flex items-center mb1 height-100">
          <Label small inverted style={{ padding: '10px' }}>
            <div className="f6">{title}</div>
          </Label>
          <InputCheckbox
            name={title}
            value={title}
            checked={checked}
            onChange={callback}
          >
          </InputCheckbox>
        </div>
      </div>
    )
  }

  render () {
    const { snapToModels, snapToHoles, autoGroup } = this.props.moveGizmoSettings
    return (
      <div className="flex justify-around">
        { this.createCheckbox('Enable model snapping', snapToModels, this.props.toggleSnapToModels) }
        { this.createCheckbox('Enable hole snapping', snapToHoles, this.props.toggleSnapToHoles) }
        { this.createCheckbox('Enable auto grouping', autoGroup, this.props.toggleAutoGroup) }
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  toggleSnapToModels: () => dispatch(fromThreeViewerUi.toggleSnapToModels()),
  toggleSnapToHoles: () => dispatch(fromThreeViewerUi.toggleSnapToHoles()),
  toggleAutoGroup: () => dispatch(fromThreeViewerUi.toggleAutoGroup())
})

const mapStateToProps = (state) => ({
  moveGizmoSettings: fromThreeviewerSelectors.getMoveGizmoSettings(state)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MoveSettings)
