import React from 'react'
import styled from 'styled-components'
import InputSearch from '../../common/form/input-search'
import Grid from '../../common/grid/grid'

const ExcelDiv = styled.div`
  font-weight: bold;
  text-align: center;
  transition: transform 100ms;
  cursor: pointer;
  &:hover {
    transform: translateY(-2px);
  }
`

export default function Filters ({ width, searchString, onSearchChange, onSearchClear, onDownloadXls }) {
  return (
    <Grid
      columns={['4fr', '1fr']}
      gridGap={24}
      style={{ alignItems: 'center', width: width }}
    >
      <InputSearch
        placeholder='Search...'
        onChange={onSearchChange}
        onClearSearch={onSearchClear}
        value={searchString}

      />
      <ExcelDiv
        onClick={onDownloadXls}
      >
            .xls
      </ExcelDiv>
    </Grid>
  )
}
