import React from 'react'

const IconFolder = props => (
  <svg width={props.size} height={props.size} viewBox='0 0 16 16' {...props}>
    <path
      fill={props.color}
      d='M16,4 L15,4 L15,12 L16,12 L16,15 L13,15 L13,14 L3,14 L3,15 L0,15 L0,12 L1,12 L1,4 L0,4 L0,1 L3,1 L3,2 L13,2 L13,1 L16,1 L16,4 Z M14,2 L14,3 L15,3 L15,2 L14,2 Z M1,2 L1,3 L2,3 L2,2 L1,2 Z M2,14 L2,13 L1,13 L1,14 L2,14 Z M13,13 L13,12 L14,12 L14,4 L13,4 L13,3 L3,3 L3,4 L2,4 L2,12 L3,12 L3,13 L13,13 Z M15,14 L15,13 L14,13 L14,14 L15,14 Z M10,6 L13,6 L13,12 L6,12 L6,10 L3,10 L3,4 L10,4 L10,6 Z M4,9 L9,9 L9,5 L4,5 L4,9 Z M12,11 L12,7 L10,7 L10,10 L7,10 L7,11 L12,11 Z'
    />
  </svg>
)

IconFolder.defaultProps = {
  color: 'currentcolor',
  size: '1em'
}

export default IconFolder
