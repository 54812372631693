import Immutable from 'seamless-immutable'
import { createAction, handleActions } from 'redux-actions'
import _get from 'lodash/get'
import { AppThunk } from '..'
import { addCommand } from '../undo-redo'
import TriplanarTool from '../../../../../go3dthree/src/tools/TriplanarTool'
import type { Go3DViewer } from '../../../../../go3dthree/types/Go3DViewer'

type Vector3 = { x: number, y: number, z: number }
type Axis = 'x' | 'y' | 'z'

export const dispose = createAction('threeviewer/triplanar/DISPOSE')
const confirmSetTranslation = createAction<Vector3>('threeviewer/triplanar/SET_TRANSLATION')
const confirmSetMapRotation = createAction<Vector3>('threeviewer/triplanar/SET_MAP_ROTATION')
const confirmSaveTranslation = createAction<Vector3>('threeviewer/triplanar/SAVE_TRANSLATION')
const confirmSaveOrientation = createAction<Vector3>('threeviewer/triplanar/SAVE_ORIENTATION')

export function setup (viewer: Go3DViewer): AppThunk {
  return (dispatch: any, getState) => {
    viewer.triplanarTool = new TriplanarTool(viewer)
    viewer.triplanarTool.disable()

    if (viewer.triplanarTransformGizmo) {
      // @ts-ignore - triplanarGizmo extends EventEmitter with inherits()
      viewer.triplanarTransformGizmo.on('mouseDown', () => {
        if (!viewer.triplanarTool || !viewer.triplanarTool.enabled) return
        const orientation = _get(viewer.triplanarTool, 'source.userData.triplanarOrientation')
        if (orientation) dispatch(confirmSaveOrientation(orientation))
      })

      // @ts-ignore - triplanarGizmo extends EventEmitter with inherits()
      viewer.triplanarTransformGizmo.on('mouseUp', () => {
        if (!viewer.triplanarTool || !viewer.triplanarTool.enabled) return
        const oldOrientation = getState().threeviewer.triplanar.savedOrientation
        const newOrientation = _get(viewer.triplanarTool, 'source.userData.triplanarOrientation')
        const sourceNode = viewer.triplanarTool.source
        if (sourceNode && oldOrientation && newOrientation) {
          const command = {
            // @ts-ignore - Immutable.ImmutableObject<Vector3> works like Vector3
            undo: () => viewer.triplanarTool.setOrientation(oldOrientation, sourceNode),
            execute: () => viewer.triplanarTool.setOrientation(newOrientation, sourceNode)
          }
          dispatch(addCommand(command))
        }
      })
    }
  }
}

export function initControls (): AppThunk {
  return (dispatch: any, getState) => {
    const viewer = getState().threeviewer.viewer
    if (viewer && viewer.triplanarTool) {
      const triplanarTool = viewer.triplanarTool
      const translation = triplanarTool.getTranslation()
      const mapRotation = triplanarTool.getMapRotation()
      if (translation) {
        dispatch(confirmSetTranslation(translation))
      }
      if (mapRotation) {
        dispatch(confirmSetMapRotation(mapRotation))
      }
    }
  }
}

export function setTranslation (value: number, axis: Axis): AppThunk {
  return (dispatch, getState) => {
    const viewer = getState().threeviewer.viewer
    if (viewer && viewer.triplanarTool) {
      const triplanarTool = viewer.triplanarTool
      triplanarTool.setTranslationOnAxis(value, axis)
      const translation = triplanarTool.getTranslation()
      if (translation) dispatch(confirmSetTranslation(translation))
    }
  }
}

export function saveTranslation (): AppThunk {
  return (dispatch, getState) => {
    const viewer = getState().threeviewer.viewer
    if (viewer && viewer.triplanarTool) {
      const translation = viewer.triplanarTool.getTranslation()
      if (translation) dispatch(confirmSaveTranslation(translation))
    }
  }
}

export function addSavedTranslationToUndoRedoHistory (): AppThunk {
  return (dispatch: any, getState) => {
    const state = getState()
    const viewer = state.threeviewer.viewer
    const oldTranslation = state.threeviewer.triplanar.savedTranslation
    if (viewer && viewer.triplanarTool && oldTranslation) {
      const triplanarTool = viewer.triplanarTool
      const sourceNode = triplanarTool.source
      const newTranslation = triplanarTool.getTranslation()

      if (!sourceNode) return

      const command = {
        undo: () => {
          // @ts-ignore - Immutable.ImmutableObject<Vector3> works like Vector3
          viewer.triplanarTool.setTriplanarTranslation(oldTranslation, sourceNode)
          dispatch(confirmSetTranslation(oldTranslation))
        },
        execute: () => {
          if (newTranslation) {
            viewer.triplanarTool.setTriplanarTranslation(newTranslation, sourceNode)
            dispatch(confirmSetTranslation(newTranslation))
          }
        }
      }
      dispatch(addCommand(command))
    }
  }
}

export function rotateMap90DegreesOnAxis (axis: Axis): AppThunk {
  return (dispatch, getState) => {
    const viewer = getState().threeviewer.viewer
    if (viewer && viewer.triplanarTool) {
      const triplanarTool = viewer.triplanarTool
      const oldMapRotation = triplanarTool.getMapRotation()
      const sourceNode = triplanarTool.source
      triplanarTool.rotateMap90DegreesOnAxis(axis)
      const newMapRotation = triplanarTool.getMapRotation()
      if (newMapRotation) dispatch(confirmSetMapRotation(newMapRotation))

      const command: any = {
        undo: () => {
          sourceNode && viewer.triplanarTool.setMapRotation(oldMapRotation, sourceNode)
          dispatch(confirmSetMapRotation(oldMapRotation))
        },
        execute: () => {
          sourceNode && viewer.triplanarTool.setMapRotation(newMapRotation, sourceNode)
          dispatch(confirmSetMapRotation(newMapRotation))
        }
      }
      // @ts-ignore
      dispatch(addCommand(command))
    }
  }
}

const initialState = Immutable<{
  translation: Vector3
  mapRotation: Vector3
  savedTranslation: null | Vector3
  savedOrientation: null | Vector3
}>({
  translation: { x: 0, y: 0, z: 0 },
  mapRotation: { x: 0, y: 0, z: 0 },
  savedTranslation: null,
  savedOrientation: null
})

type State = typeof initialState

export default handleActions<State, any>({
  [confirmSetTranslation.toString()]: (state, { payload }) => state.setIn(['translation'], { x: payload.x, y: payload.y, z: payload.z }),
  [confirmSetMapRotation.toString()]: (state, { payload }) => state.setIn(['mapRotation'], { x: payload.x, y: payload.y, z: payload.z }),
  [confirmSaveTranslation.toString()]: (state, { payload }) => state.setIn(['savedTranslation'], { x: payload.x, y: payload.y, z: payload.z }),
  [confirmSaveOrientation.toString()]: (state, { payload }) => state.setIn(['savedOrientation'], { x: payload.x, y: payload.y, z: payload.z }),
  [dispose.toString()]: () => initialState
}, initialState)
