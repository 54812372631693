import React from 'react'

import Modal from '../../common/modal'
import FormRemove from '../../common/form/form-remove'

class RemoveUserModal extends React.PureComponent {
  render () {
    const props = this.props

    return (
      <Modal
        isOpen={props.isOpen}
        onRequestClose={props.onRequestClose}
        shouldCloseOnOverlayClick
        width={props.width}
      >
        <FormRemove
          formTitle={props.formTitle}
          formDescription={props.formDescription}
          onCancel={props.onCancel}
          onConfirm={props.onConfirm}
          buttonText={props.buttonText}
        />
      </Modal>
    )
  }
}

export default RemoveUserModal
