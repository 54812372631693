const THREE = require('three')
const utils = require('../utils.js')

const vertexShader = require('./shader.vert')
const fragmentShader = require('./shader.frag')

const oAssign = Object.assign
const oKeys = Object.keys

class TriplanarMaterial extends THREE.ShaderMaterial {
  constructor (params = {}, lightGrid = null) {
    super()
    this.type = 'TriplanarMaterial'
    this.defines = {}
    this.extensions.derivatives = true
    this.showDiffuseOnly = params.hasOwnProperty('showDiffuseOnly') ? params.showDiffuseOnly : false
    this.useTriplanar = params.hasOwnProperty('useTriplanar') ? params.useTriplanar : true
    this.useLocalEnvMap = params.hasOwnProperty('useLocalEnvMap') ? params.useLocalEnvMap : false
    this.useModelFading = params.hasOwnProperty('useModelFading') ? params.useModelFading : false
    this.useAlphaMap = params.hasOwnProperty('useAlphaMap') ? params.useAlphaMap : false
    this.useRefractMap = params.hasOwnProperty('useRefractMap') ? params.useRefractMap : false
    this.useRefraction = params.hasOwnProperty('useRefraction') ? params.useRefraction : false

    this.useColorTextureMix = null
    this.lights = true
    this.transparent = false

    this.map = new THREE.Texture()

    this._mapRepeat = new THREE.Vector2(1.0, 1.0)
    this._mapOffset = new THREE.Vector2(0.0, 0.0)
    this._uvMapRotation = 0.0
    this._meterScale = 1.0

    this._triplanarTranslation = new THREE.Vector3()
    this._triplanarOrientation = new THREE.Vector3()

    this.setLightCullingDefines(lightGrid)

    // Mutate uniforms
    oAssign(this.uniforms, oKeys(THREE.UniformsLib).reduce((acc, key) => oAssign(acc, THREE.UniformsLib[key]), {}))

    const defaultUniforms = {
      alphaMap: null,
      refractMap: null,
      refractColor: null,
      alphaColor: null,
      colorTextureMix: 0.0,
      useColorTextureMix: null,
      color: new THREE.Color(0xffffff),
      roughness: 1.0,
      metalness: 0.0,
      emissive: new THREE.Color(0x000000),
      emissiveIntensity: 1.0,
      envMapIntensity: 1.0,
      refractionRatio: 0.98,
      mapRotationX: 0.0,
      mapRotationY: 0.0,
      mapRotationZ: 0.0,
      origoFading: new THREE.Vector3(0.0, 0.0, 0.0),
      triplanarTransform: new THREE.Matrix4(),
      endFading: 1.0,
      startFading: 1.0,
      uvTransform: new THREE.Matrix3().identity(),
      normalScale: new THREE.Vector2(1, 1),
      wallX0: -1.0,
      wallX1: 1.0,
      localEnvMapRadius: 40.0,
      time: 0,
      markerPoint: new THREE.Vector4(0, 0, 0, 0),
      wCubeCameraPosition: new THREE.Vector3(0, 0, 0),
      decalUVTransform: new THREE.Matrix4().identity(),
      decalMap: new THREE.Texture(),
      meterScale: 1.0
    }
    this.setValues(oAssign({}, defaultUniforms, params))
    this._updateShaders()
  }

  setLightCullingDefines (lightGrid) {
    if (lightGrid == null) {
      this.defines.NUM_TILED_POINTLIGHTS = '0'
      this.defines.NUM_TILED_SPOTLIGHTS = '0'
      this.useTiledLighting = false
    } else {
      this.defines.NUM_TILED_POINTLIGHTS = String(lightGrid.pointLightTexture.image.width)
      this.defines.NUM_TILED_SPOTLIGHTS = String(lightGrid.spotLightTexture.image.width)
      this.useTiledLighting = true

      const ligthCullingUniforms = {
        pointLightMap: lightGrid.pointLightTexture,
        spotLightMap: lightGrid.spotLightTexture,
        tileLayout: lightGrid.gridData
      }
      this.setValues(ligthCullingUniforms)
    }
  }

  setMapRotationXYZ (x, y, z) {
    this.mapRotationX = x
    this.mapRotationY = y
    this.mapRotationZ = z
  }

  // Get set Tiled Shading
  get useTiledLighting () { return this._useTiledLighting }
  set useTiledLighting (value) {
    if (value) {
      this.defines.TILED_LIGHTING = ''
    } else {
      delete this.defines.TILED_LIGHTING
    }
    this._useTiledLighting = value
    this._updateShaders()
  }

  get showDiffuseOnly () { return this._showDiffuseOnly }
  set showDiffuseOnly (value) {
    if (value) {
      this.defines.SHOW_DIFFUSE_ONLY = ''
    } else {
      delete this.defines.SHOW_DIFFUSE_ONLY
    }
    this._showDiffuseOnly = value
    this._updateShaders()
  }

  // Get set Triplanar
  get useTriplanar () { return this._useTriplanar }
  set useTriplanar (value) {
    if (value) {
      this.defines.USE_TRIPLANAR = ''
    } else {
      delete this.defines.USE_TRIPLANAR
    }
    this._useTriplanar = value
    this._updateShaders()
  }

  get useLocalEnvMap () { return this._useLocalEnvMap }
  set useLocalEnvMap (value) {
    if (value) {
      this.defines.LOCAL_ENVMAP_REFLECTION = ''
    } else {
      delete this.defines.LOCAL_ENVMAP_REFLECTION
    }
    this._useLocalEnvMap = value
    this._updateShaders()
  }

  set mapRotationX (value) {
    this._mapRotationX = value
    this.uniforms.mapRotationX = { value: getRotationMatrix(value) }
  }

  get mapRotationX () { return this._mapRotationX }

  set mapRotationY (value) {
    this._mapRotationY = value
    this.uniforms.mapRotationY = { value: getRotationMatrix(value) }
  }

  get mapRotationY () { return this._mapRotationY }

  set mapRotationZ (value) {
    this._mapRotationZ = value
    this.uniforms.mapRotationZ = { value: getRotationMatrix(value) }
  }

  get mapRotationZ () { return this._mapRotationZ }

  set mapRepeat (value) {
    this._mapRepeat = value
    this._updateUvTransform()
  }

  // this mapRepeat is only valid until you also store rotation in the uvTransform
  get mapRepeat () { return this._mapRepeat }

  set mapOffset (value) {
    this._mapOffset = value
    this._updateUvTransform()
  }

  get mapOffset () { return this._mapOffset }

  set uvMapRotation (value) {
    this._uvMapRotation = value
    this._updateUvTransform()
  }

  get uvMapRotation () { return this._uvMapRotation }

  set color (value) {
    this.uniforms.diffuse = { value: parseColor(value) }
    if (this.transparentMaterial) {
      this.refractColor = value
    }
  }

  get color () { return this.uniforms.diffuse.value }

  set emissive (value) { this.uniforms.emissive = { value: parseColor(value) } }
  get emissive () { return this.uniforms.emissive }

  // refract and alpha:
  get useAlphaMap () { return this._useAlphaMap }
  get useRefractMap () { return this._useRefractMap }
  get useRefraction () { return this._useRefraction }
  get useColorTextureMix () { return this._useColorTextureMix }
  get colorTextureMix () { return this._colorTextureMix }

  get refractColor () {
    return this.uniforms.refractColor.value
  }

  set useColorTextureMix (value) {
    if (value) {
      this.defines.USE_COLOR_TEXTURE_MIX_MODE = true
    } else {
      delete this.defines.USE_COLOR_TEXTURE_MIX_MODE
    }

    this._useColorTextureMix = value
    this._updateShaders()
  }

  set markerPoint (point) {
    var newPoint = point.clone()
    this.uniforms.markerPoint.value = newPoint
    this._markerPoint = newPoint
  }

  set refractColor (v) {
    if (!this.uniforms.refractColor.value) {
      this.uniforms.refractColor.value = new THREE.Color()
    }
    this.refractColor.copy(v)
  }

  set useAlphaMap (value) {
    if (value) {
      this.defines.USE_ALPHAMAP = ''
    } else {
      delete this.defines.USE_ALPHAMAP
    }

    this._useAlphaMap = value
    this._updateShaders()
  }

  set useRefractMap (value) {
    if (value) {
      this.defines.USE_REFRACTMAP = ''
    } else {
      delete this.defines.USE_REFRACTMAP
    }

    this._useRefractMap = value
    this._updateShaders()
  }

  set useRefraction (value) {
    if (value) {
      this.defines.USE_REFRACTION = ''
    } else {
      delete this.defines.USE_REFRACTION
    }
    this._useRefraction = value
    this._updateShaders()
  }

  // Get set Floor Fading
  get useModelFading () { return this._useModelFading }
  set useModelFading (value) {
    if (value) {
      this.defines.USE_MODEL_FADING = ''
    } else {
      delete this.defines.USE_MODEL_FADING
    }

    this._useModelFading = value
    this._updateShaders()
  }

  get startFading () { return this.uniforms.startFading.value }
  set startFading (value) {
    if (
      this.uniforms.hasOwnProperty('endFading') &&
      Number.isFinite(this.uniforms.endFading.value) &&
      value > this.uniforms.endFading.value
    ) {
      this.uniforms.endFading = { value }
    }
    this.uniforms.startFading = { value }
  }

  get endFading () { return this.uniforms.endFading.value }
  set endFading (value) {
    if (
      this.uniforms.hasOwnProperty('startFading') &&
      Number.isFinite(this.uniforms.startFading.value) &&
      value < this.uniforms.startFading.value
    ) {
      this.uniforms.startFading = { value }
    }
    this.uniforms.endFading = { value }
  }

  get origoFading () { return this.uniforms.origoFading.value }
  set origoFading (value) { this.uniforms.origoFading = { value } }

  set triplanarTranslation (vec3) {
    this._triplanarTranslation = vec3
    const translationMatrix = new THREE.Matrix4().makeTranslation(vec3.x, vec3.y, vec3.z)
    const orientationMatrix = new THREE.Matrix4().extractRotation(this.uniforms.triplanarTransform.value)
    const transform = orientationMatrix.copy(orientationMatrix).invert().multiply(translationMatrix)
    this.uniforms.triplanarTransform = { value: transform.copy(transform).invert() }
  }

  set triplanarOrientation (vec3) {
    this._triplanarOrientation = vec3
    const translationMatrix = new THREE.Matrix4().makeTranslation(this._triplanarTranslation.x, this._triplanarTranslation.y, this._triplanarTranslation.z)
    const orientationMatrix = new THREE.Matrix4().makeRotationFromEuler(new THREE.Euler().setFromVector3(vec3))
    const transform = orientationMatrix.multiply(translationMatrix)
    this.uniforms.triplanarTransform = { value: transform.copy(transform).invert() }
  }

  get wCubeCameraPosition () { return this.uniforms.localEnvMapRadius.value }
  set wCubeCameraPosition (value) { this.uniforms.localEnvMapRadius = value }

  get wallX0 () { return this.uniforms.wallX0.value }
  set wallX0 (value) { this.uniforms.wallX0 = { value } }

  get wallX1 () { return this.uniforms.wallX1.value }
  set wallX1 (value) { this.uniforms.wallX1 = { value } }

  get opacity () { return this._opacity }
  set opacity (value) {
    this._opacity = value
    if (this.uniforms) this.uniforms.opacity = { value }
    this.transparent = value < 1.0 || this._useModelFading
  }

  get decalMap () {
    return this.uniforms.decalMap ? this.uniforms.decalMap.value : null
  }

  set decalMap (value) {
    if (value === this.uniforms.decalMap) return
    if (value) {
      this.defines.USE_DECALMAP = ''
      this.uniforms.decalMap = { value: value }
    } else {
      delete this.defines.USE_DECALMAP
    }

    this._updateShaders()
  }

  get decalUVTransform () {
    return utils.uvMat3ToUvMat4(this.uniforms.decalUVTransform.value)
  }

  set decalUVTransform (mat4) {
    this.uniforms.decalUVTransform = { value: utils.uvMat4ToUvMat3(mat4) }
  }

  get meterScale () {
    return this._meterScale
  }

  set meterScale (val) {
    this._meterScale = val
  }

  // Overwrites ShaderMaterial.setValues
  setValues (values) {
    for (var key in values) {
      this[key] = values[key]
    }
  }

  setDefaultMapRotationFromMesh (mesh) {
    mesh.geometry.computeBoundingBox()

    const localBBox = mesh.geometry.boundingBox

    const x = Math.abs(localBBox.max.x - localBBox.min.x)
    const y = Math.abs(localBBox.max.y - localBBox.min.y)
    const z = Math.abs(localBBox.max.z - localBBox.min.z)

    this.mapRotationY = x > z ? 90 : 0
    this.mapRotationX = z > y ? 90 : 0
    this.mapRotationZ = x > y ? 90 : 0
  }

  clone () {
    var values = oKeys(this.uniforms).reduce((acc, key) => {
      // returns a string with path to  texture map if the uniform exists, otherwise returns null.
      return oAssign(acc, { [key]: !this.uniforms[key] ? null : this.uniforms[key].value })
    }, {})

    // GOTCHA: complex types NEED to be cloned below manually, otherwise they will be SHARED and you will suffer
    // TLDR: .clone() ALL vectors, matrices and objects of any kind.
    const clone = new this.constructor(oAssign({}, values, {
      color: this.color.clone(),
      origoFading: this.origoFading.clone(),
      normalScale: this.normalScale ? this.normalScale.clone() : new THREE.Vector2(1, 1),
      wCubeCameraPosition: this.wCubeCameraPosition.clone(),
      useAlphaMap: this.useAlphaMap,
      useRefractMap: this.useRefractMap,
      useRefraction: this.useRefraction,
      useColorTextureMix: this.useColorTextureMix,
      colorTextureMix: this.colorTextureMix,
      uvTransform: this.uvTransform.clone(),
      transparentMaterial: this.transparentMaterial,
      _mapRepeat: this._mapRepeat.clone(),
      _mapOffset: this._mapOffset.clone(),
      _uvMapRotation: this._uvMapRotation,
      decalMap: this.decalMap,
      decalUVTransform: this.decalUVTransform.clone(),
      triplanarTransform: this.triplanarTransform.clone(),
      _meterScale: this._meterScale
    }))

    // The uniform value is a mat2 and the api value is a float
    clone.setMapRotationXYZ(
      this.mapRotationX,
      this.mapRotationY,
      this.mapRotationZ
    )

    clone.name = this.name

    return clone
  }

  _updateShaders () {
    this.vertexShader = `
        ${printDefines(this.defines)}
        ${vertexShader}
      `
    this.fragmentShader = `
        ${printDefines(this.defines)}
        ${fragmentShader}
      `
    this.needsUpdate = true
  }

  _updateUvTransform () {
    this.uniforms.uvTransform.value.setUvTransform(this._mapOffset.x, this._mapOffset.y, this._mapRepeat.x, this._mapRepeat.y, this._uvMapRotation, 0.0, 0.0)
  }
}

function printDefines (defines) {
  return oKeys(defines).reduce((acc, key) => (`
    #define ${key} ${defines[key]}
    ${acc}
  `), '')
}

function getRotationMatrix (deg) {
  const rad = deg * Math.PI / 180
  const mat = new THREE.Matrix3()
  mat.setUvTransform(0, 0, 1, 1, rad, 0, 0)
  return mat
}

function parseColor (value) {
  return value.isColor ? value : new THREE.Color(value)
}

var uniforms = [
  'time',
  'markerPoint',
  'alphaMap',
  'refractMap',
  'refractColor',
  'colorTextureMix',
  'alphaColor',
  'metalness',
  'roughness',
  'normalScale',
  'emissiveIntensity',
  'refractionRatio',
  'map',
  'normalMap',
  'metalnessMap',
  'roughnessMap',
  'bumpMapScale',
  'envMapIntensity',
  'specularMap',
  'envMap',
  'uvTransform',
  'pointLightMap',
  'spotLightMap',
  'tileLayout',
  'localEnvMapRadius',
  'wCubeCameraPosition',
  'triplanarTransform'
]

uniforms.forEach((uniformKey) => {
  Object.defineProperty(TriplanarMaterial.prototype, uniformKey, {
    get () {
      return this.uniforms[uniformKey] && this.uniforms[uniformKey].value
    },

    set (value) {
      this.uniforms[uniformKey] = { value }
    }
  })
})

module.exports = TriplanarMaterial
