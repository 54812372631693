
const THREE = require('three')
const { findGroup, findMarker } = require('./utils')

function setMarkerActive (marker, active) {
  if (marker === undefined) return
  if (!active) {
    marker.locked = false
  }
  marker.active = active
}

function setAnnotationPosition (annotations, groupId, x, y, locked = true) {
  const group = findGroup(groupId, annotations)
  if (group === undefined) return
  group.locked = locked
  group.positionX = Math.min(Math.max(x, -1), 1)
  group.positionY = Math.min(Math.max(y, -1), 1)
}

function setMarkerPosition (marker, position3d, locked = true) {
  if (marker === undefined) return
  marker.locked = locked
  marker.position3d.copy(position3d)
}

function selectAnnotation (annotation, annotations, emitter) {
  emitter.emitClearSelect()
  clearAnnotationSelection(annotations)

  annotation.element.select()
  emitter.emitSelect(annotation.markers.map(marker => marker.mesh))
  annotation.selected = true
}

function clearAnnotationSelection (annotations) {
  annotations.forEach(annotation => {
    if (annotation.selected) {
      annotation.selected = false
      annotation.element.unselect()
    }
  })
}

function AnnotationControls (picker, domElement, manager) {
  const MOUSE_LEFT = 0

  let targetMarker
  let targetAnnotation

  let markerDidMove
  let shouldClearSelection

  let targetAnnotationOffsetX
  let targetAnnotationOffsetY

  let lastTargetMarker

  this.enable = () => {
    domElement.addEventListener('mousedown', handleMouseDown)
    domElement.addEventListener('mousemove', handleMouseMove)
    domElement.addEventListener('mouseup', handleMouseUp)
  }

  this.dispose = () => {
    domElement.removeEventListener('mousedown', handleMouseDown)
    domElement.removeEventListener('mousemove', handleMouseMove)
    domElement.removeEventListener('mouseup', handleMouseUp)
  }

  function getClosestMarker (markers, clientX, clientY) {
    const screenX = ((clientX - manager.viewportOffsetLeft) / manager.canvasWidth * 2) - 1
    const screenY = 1 - ((clientY - manager.viewportOffsetLeft) / manager.canvasHeight * 2)
    const minDistance = 0.025
    let closestDistance
    let closestMarker

    for (let i = 0; i < markers.length; i++) {
      const marker = markers[i]
      if (marker && marker.parentAnnotation.active && marker.clickable) {
        const distance2D = Math.sqrt(Math.pow(marker.positionX - screenX, 2) + Math.pow(marker.positionY - screenY, 2))
        if (distance2D < minDistance && (closestDistance === undefined || distance2D < closestDistance)) {
          closestDistance = distance2D
          closestMarker = marker
        }
      }
    }
    return closestMarker
  }

  function handleMouseDown (event) {
    if (event.button !== MOUSE_LEFT) return

    function getGroupIdDiv (element) {
      function recurseSearchGroupdId (e) {
        if (!e || e === document.body || e.getAttribute('data-id-skip')) {
          return undefined
        }

        if (e.getAttribute('data-id')) {
          return e
        }

        return recurseSearchGroupdId(e.parentElement)
      }

      return recurseSearchGroupdId(element)
    }

    const element = getGroupIdDiv(event.target)
    if (element) {
      const id = element.getAttribute('data-id')
      const matches = manager.annotations.filter(annotation => id === annotation.groupId)
      if (matches.length > 0) {
        // calculate offset
        const elementBoundingClientRect = element.getBoundingClientRect()
        targetAnnotationOffsetX = elementBoundingClientRect.width / 2 - event.clientX + elementBoundingClientRect.left
        targetAnnotationOffsetY = elementBoundingClientRect.height / 2 - event.clientY + elementBoundingClientRect.top

        // select
        targetAnnotation = matches[0]
        if (!targetAnnotation.selected && targetAnnotation.element.active) {
          manager.emitter.emitClearSelect()
          selectAnnotation(targetAnnotation, manager.annotations, manager.emitter)
        }
        manager.emitter.emitRenderOnNextFrame()
      }
    } else {
      targetMarker = getClosestMarker(manager.markers, event.clientX, event.clientY)
      if (targetMarker) {
        manager.emitter.emitToggleRotate(false)
      }

      shouldClearSelection = event.target.tagName === 'CANVAS'
    }
  }

  function getIntersectingObjects (event, markers) {
    const mouse = new THREE.Vector2(
      (event.clientX - manager.viewportOffsetLeft) / manager.canvasWidth,
      (event.clientY - manager.viewportOffsetTop) / manager.canvasHeight
    )

    const meshes = markers
      .filter(marker => marker && marker.active)
      .reduce((map, marker) => {
        map[marker.mesh.uuid] = true
        return map
      }, {})

    return picker.getIntersects(mouse, mesh => meshes[mesh.sceneGraphID])
  }

  function handleMouseMove (event) {
    if (targetAnnotation && targetAnnotation.element.active) {
      const screenX = ((event.clientX - manager.viewportOffsetLeft + targetAnnotationOffsetX) / manager.canvasWidth) * 2 - 1
      const screenY = 1 - ((event.clientY - manager.viewportOffsetTop + targetAnnotationOffsetY) / manager.canvasHeight) * 2
      setAnnotationPosition(manager.annotations, targetAnnotation.groupId, screenX, screenY)
      manager.emitter.emitRenderOnNextFrame()
    }
    if (targetMarker) {
      const intersects = getIntersectingObjects(event, targetMarker.parentAnnotation.markers)
      markerDidMove = true
      if (intersects) {
        // update targetMarker
        const newTargetMarker = findMarker(intersects.object, manager.markers)

        if (newTargetMarker !== targetMarker) {
          targetMarker.locked = false
          targetMarker = newTargetMarker
        }

        selectAnnotation(targetMarker.parentAnnotation, manager.annotations, manager.emitter)
        targetMarker.parentAnnotation.lockedMarker = targetMarker

        setMarkerPosition(targetMarker, intersects.point)
        manager.emitter.emitRenderOnNextFrame()
      }
    } else {
      // when nothing is targeted
      const marker = getClosestMarker(manager.markers, event.clientX, event.clientY)
      manager.markers.forEach((marker) => { if (marker) { marker.hover = false } })
      if (marker) {
        marker.hover = true
        if (lastTargetMarker !== marker) {
          manager.emitter.emitRenderOnNextFrame()
          lastTargetMarker = marker
        }
      }
      shouldClearSelection = false
    }
  }

  function handleMouseUp (event) {
    // ignore other clicks
    if (event.button !== MOUSE_LEFT) return

    // if the annotation is selected and marker locked, unlock
    // if the annotation is selected and marker unlocked, disable
    // if the annotation is no selected, select
    if (targetMarker && !markerDidMove) {
      const annotation = targetMarker.parentAnnotation

      if (annotation.selected) {
        if (targetMarker.locked) {
          targetMarker.locked = false
          // reset lockedMarker if marker was disabled
          if (annotation.lockedMarker === targetMarker) {
            annotation.lockedMarker = undefined
          }
        } else {
          setMarkerActive(targetMarker, !targetMarker.active)
          const nrActiveMarkers = annotation.markers.filter(marker => marker.active && marker.visible)
          // disable annotation if no active markers are left
          if (nrActiveMarkers < 1 || annotation.showSingleMarker) {
            // targetMarker.locked = false
            manager.lastActiveNode = targetMarker
            annotation.element.unselect()
            annotation.element.setActive(false)
            manager.emitter.emitClearSelect()
          }
        }
      } else {
        selectAnnotation(targetMarker.parentAnnotation, manager.annotations, manager.emitter)
      }
    }

    // reset
    if (targetMarker || targetAnnotation) {
      targetMarker = undefined
      targetAnnotation = undefined
      markerDidMove = false
      manager.emitter.emitToggleRotate(true)
      manager.emitter.emitRenderOnNextFrame()
    } else if (shouldClearSelection) {
      manager.emitter.emitClearSelect()
      clearAnnotationSelection(manager.annotations)
    }
  }
}

module.exports = {
  AnnotationControls,
  setAnnotationPosition,
  selectAnnotation
}
