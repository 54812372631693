import React from 'react'
import cs from 'classnames'

type Props = React.HTMLAttributes<HTMLButtonElement> & {
  btnType?: 'primary'| 'secondary'| 'inverted'| 'transparent'
  disabled?: boolean
  icon?: React.ReactNode
  noPointer?: boolean
  padding?: boolean
  shadow?: boolean
  text?: string
  textClassName?: string
}

const ButtonIcon = ({
  btnType = 'primary',
  className,
  disabled,
  icon,
  noPointer,
  padding,
  shadow,
  text,
  textClassName,
  ...props
}: Props) => (
  <button
    {...props}
    className={cs(
      'flex flex-column justify-content items-center bold uppercase br-big',
      {
        'bg-primary bg-primary-light-hover c-black': btnType === 'primary',
        'bg-black bg-black-hover c-primary': btnType === 'inverted',
        'c-black': btnType === 'transparent',
        disabled: disabled,
        'drop-shadow': shadow,
        p2: padding,
        pointer: !noPointer
      },
      className
    )}
  >
    {icon &&
      <span>
        {icon}
      </span>
    }
    <span className={cs(
      { 'f5 mt3': text && !textClassName },
      textClassName
    )}>
      {text}
    </span>
  </button>
)

export default ButtonIcon
