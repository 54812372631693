import React from 'react'

const IconCreateImage = props => (
  <svg width='1em' height='1em' viewBox='0 0 20 20' {...props}>
    <path
      d='M9 3v14H1c-.667-.797-1-3.11-1-6.94C0 6.232.333 3.879 1 3h8zm0 2H2.778C2.259 5.627 2 7.308 2 10.043c0 2.735.26 4.388.778 4.957H9V5zm1-4.2c0-.442.458-.8 1.022-.8.565 0 1.022.358 1.022.8v18.4c0 .442-.457.8-1.022.8-.564 0-1.022-.358-1.022-.8V.8zM14 5a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm4 0a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm1 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm0 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm-1 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm-4 0a1 1 0 1 1 0-2 1 1 0 0 1 0 2z'
      fill={props.color}
    />
  </svg>
)

IconCreateImage.defaultProps = {
  color: 'currentcolor'
}

export default IconCreateImage
