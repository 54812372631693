import React from 'react'
import ModalPortal from '../common/modal-portal'

import GalleryContainer from './index'

const GalleryModal = ({
  projectId = '',
  backToText = '',
  parentCombinationId = '',
  designCardTitle = '',
  rendersInProject,
  renderId,
  onBack
}) => (
  <ModalPortal>
    <GalleryContainer
      projectId={projectId}
      parentCombinationId={parentCombinationId}
      rendersInProject={rendersInProject}
      designCardTitle={designCardTitle}
      renderId={renderId}
      onBack={onBack}
      backToText={backToText}
    />
  </ModalPortal>
)

export default GalleryModal
