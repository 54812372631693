import React from 'react'

const iconAngleNarrow = props => (
  <svg viewBox='0 0 24 24' width={props.size} height={props.size} {...props}>
    <path
      d="M15.9097769,10.6245572 C14.5791098,9.37324607 13.2774228,8.76133036 12,8.76133036 C10.7233778,8.76133036 9.42252172,9.37247933 8.09272486,10.6222054 L12.0001846,19.7598925 L15.9097769,10.6245572 Z M16.3262952,9.65130129 L17.5451466,6.80327637 L18.4644938,7.19672363 L17.098023,10.3896829 L17.2071045,10.4982151 L16.9341837,10.7725172 L11.9998154,22.3023918 L7.07211517,10.778848 L6.79289547,10.4982151 L6.90458899,10.3870841 L5.5402693,7.19659014 L6.4597307,6.80340986 L7.6764937,9.64883897 C9.09350758,8.39815472 10.53599,7.76133036 12,7.76133036 C13.4649705,7.76133036 14.9083851,8.39899057 16.3262952,9.65130129 Z"
      fill={props.color}
    />
  </svg>
)

iconAngleNarrow.defaultProps = {
  color: 'currentcolor',
  size: '1em'
}

export default iconAngleNarrow
