import React from 'react'
import Modal from '../../../common/modal'
import HeaderText from '../../../common/text/header-text'
import BlockMeasurer from '../../../common/layout/block-measurer'
import Label from '../../../common/text/label'
import IconArrowLeft from '../../../common/icons/icon-arrow-left'
import IconArrowRight from '../../../common/icons/icon-arrow-right'
import IconColorWheel from '../../../common/icons/icon-color-wheel'
import { TryMultipleImages } from './try-multiple-images'
import KeyboardListener, { KeyCode, KeyBinding } from '../../../common/keyboard-listener.jsx'
import type { Material } from '../../../../stores/ducks/materials/Material'
import _padStart from 'lodash/padStart'

type PreviewProps = {
  open: Boolean
  onRequestClose: () => void
  onNavigation: (data: any) => void
  material?: Material
  headerText: string | null
  cscText?: string
  idText?: string
  previewImg?: string
  src?: string | null
}

const MaterialPreviewModal = ({ open, onRequestClose, onNavigation, material, headerText, cscText, idText, previewImg, src }: PreviewProps) => {
  return (
    <Modal
      width={1200}
      height='90vh'
      noPadding
      isOpen={open}
      onRequestClose={() => {
        if (onRequestClose) {
          onRequestClose()
        }
      }}
    >
      <BlockMeasurer>
        {({ measuredWidth, measuredHeight }: any) => (
          <div
            className='p3'
            style={{
              height: '100%',
              overflow: 'auto',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between'
            }}
          >
            {!material &&
              <HeaderText>
                {headerText}
              </HeaderText>
            }
            {material &&
              <div className='flex flex-column h4'>
                <span title={'Appearance name'}><b>Name: </b>{headerText}</span>
                <span title={'Color Specification Code'}><b>CSC: </b>{cscText || 'No value'}</span>
                <span title={'Appearance ID'}><b>MAT ID: </b>{material.source === 'materialbank' ? _padStart(idText, 6, '0') : 'No value' }</span>
              </div>
            }
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                userSelect: 'none'
              }}
            >
              <div onClick={() => onNavigation(-1)} className='pointer'>
                <IconArrowLeft width={40} height={40} />
              </div>

              <div
                style={{
                  width: measuredWidth - 100,
                  // todo: temp removed
                  // maxWidth: 700,
                  height: measuredHeight - 200,
                  // todo: temp removed
                  // maxHeight: 700,
                  textAlign: 'center'
                }}
                className={`flex justify-center items-center ${material ? '' : 'p3'}`}
              >
                {/*
                  21/11/2018
                  This solution is a kind of workaround because it is so immensely
                  tedious to add material thumbnails to our storage and then hook it up
                  to the material table in the database. The workaround will go and check
                  if there are high resolution thumbnails on shared fs, and if there is
                  show them, otherwise it will fallback to the old materials thumbnail
                  which is 500x500px in size. In case none of the mentioned images exist
                  it will fallback on a placeholder image. Be aware that this TryMultipleImage
                  component is not meant to be reused. The reason why the file name is joined is
                  because our shared fs file solution does not support spaces.
                */}
                {material && material.name && previewImg && <TryMultipleImages
                  key={material.name}
                  srcs={[
                    `/api/modelbank/highres-thumbs/${(material.name).toLowerCase().split(' ').join('')}.png`,
                    `/api/modelbank/highres-thumbs/${(material.name).toLowerCase().split(' ').join('')}.jpg`,
                    previewImg || '/img/placeholder-material.jpg'
                  ]}
                  fallback='/img/placeholder-material.jpg'
                  alignToHeight={Math.max(measuredWidth - 100, 700) > Math.max(measuredHeight - 200, 700)}
                />}

                {!material && src && <img
                  src={src}
                  style={{ maxWidth: '100%', maxHeight: '100%' }}
                />}
              </div>

              <div onClick={() => onNavigation(1)} className='pointer'>
                <IconArrowRight width={40} height={40} />
              </div>
            </div>

            <div style={{ flex: '0 0 20px', textAlign: 'center' }}>
              {material && material.canSetColor && material.type === 'wood' && (
                <div style={{
                  display: 'flex',
                  justifyContent: 'center'
                }}>
                  <IconColorWheel width={18} height={18} />

                  <div style={{ flex: '0 0 10px' }} />

                  {<Label>This wood appearance is stainable</Label>}
                </div>
              )}
            </div>
          </div>
        )}
      </BlockMeasurer>
      <KeyboardListener
        disabled={!open}
        bindings={[
          KeyBinding(KeyCode.left, () => onNavigation(-1)),
          KeyBinding(KeyCode.right, () => onNavigation(1))
        ]}
      />
    </Modal>
  )
}

export default MaterialPreviewModal
