
export const actionSanitizer = (action) => {
  try {
    JSON.stringify(action)
    return action
  } catch (e) {
    return { type: action.type }
  }
}

export const stateSanitizer = (state) => {
  return Object.assign({}, state, {
    threeviewer: Object.assign({}, state.threeviewer, {
      viewer: {},
      template: {}
    }),
    patterns: Object.assign({}, state.patterns, {
      textures: Object.assign({}, state.patterns.textures, {
        entries: {}
      })
    }),
    templates: {}, // This crashes the devtool plugin otherwise.
    materials: Object.assign({}, state.materials, {
      localEnvMap: {},
      materials: {},
      envMap: {}
    })
  })
}
