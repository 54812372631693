
import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import _keyBy from 'lodash/keyBy'

import { MdDelete as IconTrash } from 'react-icons/md'
import Button from '../../../common/button'
import Modal from '../../../common/modal'
import { StorageApiThumbnail } from '../../../common/storage-api-image'
import { getCombinationsWithRender } from '../../../../stores/ducks/projects/selectors'
import { deleteByIdConnectGeometries } from '../../../../stores/ducks/combinations'
import type { RootState } from '../../../../stores/ducks'

import AddGeometryModal, { AddGeometryCombination } from './AddGeometryModal'
import Scrollable from '../../../common/scrollable'

const ConnectGeometry = () => {
  const dispatch = useDispatch()
  const [addGeometryModalOpen, setAddGeometryModalOpen] = useState<boolean>(false)
  const loadedGeometries = useSelector((state: RootState) => {
    const combinationsWithRenders = _keyBy(getCombinationsWithRender(state), 'id')
    return state.combinations.connectedBatchGeometryCombinationIds.map((id) => {
      return combinationsWithRenders[id]
    }).filter(Boolean) as unknown as AddGeometryCombination[]
  })

  const deleteItem = (id:string) => dispatch(deleteByIdConnectGeometries(id))
  return (
    <>
      {!!loadedGeometries.length && (
        <div style={{ height: 200 }}>
          <Scrollable innerClassName="custom-scrollbar">
            {loadedGeometries.map((item) => (
              <div className='flex bg-gray-light p1 mb1 br-small' key={item.id}>
                <div style={{ width: 50, height: 50 }}>
                  <StorageApiThumbnail
                    src={(typeof item.thumbnail === 'string') ? item.thumbnail : undefined}
                    file={typeof item.thumbnail !== 'string' ? item.thumbnail : undefined}
                    constrainRatio={[1, 1]}
                  />
                </div>
                <div className='break-word px1' style={{ width: 'calc(100% - 50px - 20px)' }}>
                  {item.title}
                </div>
                <IconTrash size='20' className='pointer' onClick={() => deleteItem(item.id)}/>
              </div>
            ))}
          </Scrollable>
        </div>
      )}
      {!loadedGeometries.length && (
        <div className='muted'>
        No geometries have been connected yet.
        </div>
      )}
      <Button
        onClick={() => setAddGeometryModalOpen(true)}
        className='mt2'
      >
        {loadedGeometries.length ? 'Edit connected geometries' : 'Connect geometry'}
      </Button>
      <Modal
        shouldCloseOnOverlayClick
        isOpen={addGeometryModalOpen}
        onRequestClose={() => setAddGeometryModalOpen(false)}
        width={1000}
      >
        <AddGeometryModal closeModal={() => setAddGeometryModalOpen(false)} />
      </Modal>
    </>
  )
}

export default ConnectGeometry
