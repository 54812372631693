import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import cs from 'classnames'

import KeyboardListener, { KeyBinding, KeyCode } from '../keyboard-listener'

const clamp = (val, min, max) => Math.min(Math.max(val, min), max)

function InputNumber (props) {
  const [value, setValue] = React.useState(props.value || '')

  React.useEffect(() => {
    setValue(props.value)
  }, [props.value])

  const inputRef = React.useRef()

  function _handleEnterKey () {
    if (inputRef.current) {
      inputRef.current.blur()
    }
  }

  function _handleBlur () {
    const clamped = clamp(value, props.min, props.max)
    setValue(clamped)
    props.onChange(clamped)
    props.onBlur && props.onBlur()
  }

  return (
    <Fragment>
      <input
        data-testid={props['data-testid'] || 'input-number'}
        ref={inputRef}
        type='number'
        className={cs(
          'bc-transparent',
          'br-small',
          'f8',
          props.className,
          {
            disabled: props.disabled,
            'bg-black-10 c-gray-darker': !props.inverted,
            'bg-white-10 c-gray': props.inverted
          }
        )}
        style={props.style}
        min={props.min}
        max={props.max}
        step={props.step}
        value={value}
        onFocus={props.onFocus}
        onKeyUp={(event) => (event.keyCode === 40 || event.keyCode === 38) && props.onChange(event.target.value)}
        onChange={(event) => setValue(event.target.value)}
        onBlur={() => _handleBlur()}
      />
      <KeyboardListener
        bindings={[
          KeyBinding(KeyCode.enter, _handleEnterKey),
          KeyBinding(KeyCode.esc, _handleBlur)
        ]}
      />
    </Fragment>
  )
}

InputNumber.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.number,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  style: PropTypes.object
}

InputNumber.defaultProps = {
  step: 1,
  className: ''
}

export default InputNumber
