import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import _get from 'lodash/get'

import Grid from '../../../common/grid/grid'

import PatternList from './pattern-list'
import ColorList from './color-list'
import MaterialSearch from './material-search/search'

import connectPatternList from './pattern-list-connect'

// Actions
import * as fromMaterials from '../../../../stores/ducks/materials'
import * as fromPatterns from '../../../../stores/ducks/patterns/json'
import * as fromColors from '../../../../stores/ducks/colors'

// Selectors
import * as fromMaterialAndColorSelectors from './material-and-color-selectors'
import { StainStrengthList } from './stains-strength-list'
import { setMaterialStainStrength } from '../../../../stores/ducks/threeviewer/ui'

const ConnectedPatternList = connectPatternList(PatternList)

class MaterialPanel extends PureComponent {
  componentDidMount () {
    this.props.fetchColors()
    this.props.fetchMaterials()
    this.props.fetchPatterns()
  }

  componentDidUpdate () {
    if (this.props.showStainStrengthSelectionList !== this.props.colorStrengthOpen) {
      this.props.setColorStrengthOpen(this.props.showStainStrengthSelectionList)
    }
  }

  render () {
    const {
      projectType,
      selectedColors,
      setPattern,
      showColorList,
      showStainStrengthSelectionList
    } = this.props

    const showPatterns = setPattern

    let stainColor = { r: 255, g: 255, b: 255 }

    if (showStainStrengthSelectionList) {
      stainColor = _get(selectedColors, '[0].ncs.color', { r: 255, g: 255, b: 255 })
    }

    // Column should be adjusted depending on how many lists that should show up
    // on the left. For each list prepend a fixed amount to the column array, this
    // will make it so that the layout works correctly with any setup of the lists.
    const columns = ['1fr']
    if (showPatterns) {
      columns.unshift(80)
    }
    if (showStainStrengthSelectionList) {
      columns.unshift(80)
    }
    if (showColorList) {
      columns.unshift(80)
    }

    return (
      <Grid
        className='overflow-hidden height-100'
        rows={['1fr']}
        gridGap={0}
        columns={columns}
      >
        {showPatterns && <ConnectedPatternList projectType={projectType} />}
        {showStainStrengthSelectionList &&
          <StainStrengthList
            values={[0.90, 0.75, 0.5, 0.2]}
            color={stainColor}
            selectedStrengths={this.props.materialStainStrengths}
            onStainStrengthSelected={(strength) => {
              this.props.setMaterialStainStrength(strength)
            }}
          />
        }
        {showColorList && <ColorList />}
        <MaterialSearch projectType={projectType} />
      </Grid>
    )
  }
}

const mapStateToProps = createSelector(
  fromMaterialAndColorSelectors.getActiveTab,
  fromMaterialAndColorSelectors.getCanSetColorOnSelectedMaterial,
  fromMaterialAndColorSelectors.canShowColorList,
  fromMaterialAndColorSelectors.getSelectedColors,
  fromMaterialAndColorSelectors.getMaterialStainStrengthsFromSelection,
  fromMaterialAndColorSelectors.selectedMaterialsHasType('wood'),
  fromMaterialAndColorSelectors.canSetPattern,
  (state) => state.colors.colorStrengthOpen,
  (
    activeTab,
    canSetColor,
    canShowColorList,
    selectedColors,
    materialStainStrengths,
    isSelectedMaterialsWood,
    setPattern,
    colorStrengthOpen
  ) => {
    const showColorList = activeTab === 'dpd' && canShowColorList && canSetColor
    const showStainStrengthSelectionList = showColorList && isSelectedMaterialsWood && selectedColors.length === 1
    return {
      selectedColors,
      materialStainStrengths,
      setPattern,
      colorStrengthOpen,
      showColorList,
      showStainStrengthSelectionList
    }
  })

const mapDispatchToProps = (dispatch) => ({
  fetchMaterials: () => dispatch(fromMaterials.getMaterialsJSON({ initialFetch: true })),
  fetchColors: () => dispatch(fromColors.getColors()),
  fetchPatterns: () => dispatch(fromPatterns.getPatternsForProject()),
  setMaterialStainStrength: (value) => dispatch(setMaterialStainStrength(value)),
  setColorStrengthOpen: (value) => dispatch(fromColors.setColorStrengthOpen(value))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MaterialPanel)
