import React, { PureComponent } from 'react'
// @ts-ignore
import autoBind from 'react-autobind'

import { connect, ConnectedProps } from 'react-redux'
import { createSelector } from 'reselect'

import _filter from 'lodash/filter'
import _find from 'lodash/find'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _map from 'lodash/map'
import _sortBy from 'lodash/sortBy'

import Label from '../common/form/label'
import InputCheckbox from '../common/form/input-checkbox'
import InputText from '../common/form/input-text'
import InputRadioGroup from '../common/form/input-radio-group'
import InputSelect from '../common/form/input-select'

import * as fromDesigns from '../../stores/ducks/designs'

import * as fromDesignsSelectors from '../../stores/ducks/designs/selectors'
import * as fromProjectsSelectors from '../../stores/ducks/projects/selectors'
import * as fromUsersSelectors from '../../stores/ducks/users/selectors'

import { isSubsetOfHfb } from '../../utils/business-logic'
import { RootState } from '../../stores/ducks/'
import { TabTypes } from './index'

import type { Filter, Option } from './search-filters-fetch-data'

const createSelectionElement = (
  { filter, handleTextChange, handleRadioChange, handleInputSelectChange, applyFilter }:
  {
    filter: Filter,
    handleTextChange: (val: string, filter: Filter) => void,
    handleRadioChange: (val: string, filter: Filter) => void,
    handleInputSelectChange: any,
    applyFilter: any
  }
) => {
  const elements = {
    text: (
      <InputText
        onChange={(val: string) => handleTextChange(val, filter)}
        defaultValue={filter.value}
        placeholder={filter.value || filter.placeholder}
      />
    ),
    radio: (
      <InputRadioGroup
        options={filter.value}
        direction={filter.direction}
        onChange={(val: string) => handleRadioChange(val, filter)}
        disabled={filter.disabled}
      />
    ),
    inputSelect: (
      <InputSelect
        data-testid={filter.label}
        options={filter.options}
        value={filter.value}
        defaultValue={filter.defaultValue}
        onChange={(option: Option) => handleInputSelectChange(option, filter)}
        isDisabled={filter.disabled}
      />
    ),
    dropdown: (
      <InputSelect
        isDisabled={filter.disabled}
        options={filter.options}
        defaultValue={filter.defaultValue}
        onChange={(option: Option) => applyFilter({
          filterProjectId: option.value,
          filterProjectLabel: option.label
        })
        }
      />
    )
  }
  return (
    <div
      data-testid={`filter-${filter.label.toLowerCase().replace(/\s+/g, '-')}`}
      key={filter.key} className='flex flex-column flex-auto'>
      <Label>{filter.label}</Label>
      {elements[filter.type] || <div>input type not implemented</div>}
    </div>
  )
}

function getInputFilterValue (type: Filter['type'], value: string, filter: Filter) {
  if (type === 'radio') {
    const val = filter.value.find((option: Option) => (option.value === value ? option : false))
    return _get(val, 'value', null)
  }

  return {
    ...filter,
    value: value ? value.trim().toLowerCase() : ''
  }
}

const defaultValue = { label: 'All', value: null }
function createDropdownOptionsFromAggs (agg: any, showKey = true) {
  return [defaultValue].concat(
    _sortBy(
      _map(agg, (aggData) => {
        return {
          label: `${showKey ? `${aggData.key} ` : ''}${aggData.name}`,
          value: aggData.key
        }
      }),
      'value')
  )
}

function createDropdownOptionsFromAggsVariants (agg: any) {
  return [defaultValue].concat(
    _sortBy(
      _map(agg, (aggData) => {
        return {
          label: aggData.name,
          value: aggData.key
        }
      }),
      'value')
  )
}

type OwnProps = {
  activeTab: TabTypes
}

const mapStateToProps = createSelector(
  fromProjectsSelectors.getCurrentId,
  fromUsersSelectors.getCurrentId,
  fromDesignsSelectors.getAvailableAggs,
  fromDesignsSelectors.getFilterValues,
  (state: RootState) => state.designs.showSizes,
  (projectId, userId, availableAggs, filterValues, showSizes) => {
    return {
      availableAggs,
      projectId,
      userId,
      filterValues,
      showSizes
    }
  }
)

const mapDispatchToProps = (dispatch: any, ownProps: OwnProps) => {
  return {
    applyFilter: (filters: { [key: string]: any }) => {
      dispatch(fromDesigns.setResourceFilters({
        resource: ownProps.activeTab,
        filters
      }))
      dispatch(fromDesigns.performSearch())
    },
    setShowSizes: (showSizes: boolean) => {
      dispatch(fromDesigns.setShowSizes(showSizes))
      if (showSizes) {
        dispatch(fromDesigns.performSearch())
      }
    }
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>
type Props = PropsFromRedux & OwnProps

type State = {
  productArea: any
  productRangeArea: any
  [key: string]: any
}

class SearchFilters extends PureComponent<Props, State> {
  handleTextChange: (value: string, filter: Filter) => void
  handleRadioChange: (value: string, filter: Filter) => void

  constructor (props: Props) {
    super(props)
    autoBind(this)

    this.handleTextChange = this.handleInputChange.bind(this, 'text')
    this.handleRadioChange = this.handleInputChange.bind(this, 'radio')

    this.state = {
      productArea: undefined,
      productRangeArea: undefined
    }
  }

  handleInputChange (type: Filter['type'], value: string, filter: Filter) {
    const filterValue = getInputFilterValue(type, value, filter)

    this.setState({
      [filter.key]: !_isEmpty(filterValue) && filterValue
    }, () => {
      this.handleApplyFilter(this.state)
    })
  }

  handleInputSelectChange (option: { value: any }, filter: Filter) {
    // Exception to handle businessArea and related productRangeArea and productArea
    if (filter.key === 'businessArea') {
      const stateChange: { [key: string]: any } = {}
      stateChange.businessArea = option.value

      if (this.state.productArea && !isSubsetOfHfb(this.state.productArea, option.value)) {
        stateChange.productArea = null
      }

      if (this.state.productRangeArea && !isSubsetOfHfb(this.state.productRangeArea, option.value)) {
        stateChange.productRangeArea = null
      }

      return this.setState(stateChange, () => {
        this.handleApplyFilter(this.state)
      })
    }

    // Exception to handle productRangeArea and related productArea
    if (filter.key === 'productRangeArea') {
      const stateChange: { [key: string]: any } = {}
      stateChange.productRangeArea = option.value

      if (this.state.productRangeArea && !isSubsetOfHfb(this.state.productRangeArea, option.value)) {
        stateChange.productArea = null
      }

      return this.setState(stateChange, () => {
        this.handleApplyFilter(this.state)
      })
    }

    this.setState({
      [filter.key]: option.value
    }, () => {
      this.handleApplyFilter(this.state)
    })
  }

  handleApplyFilter (filters: any) {
    this.props.applyFilter(filters)
  }

  private getFilterComponentsData () {
    let filterComponentsData: any[] = []

    if (this.props.activeTab === 'upload') {
      const {
        globalUploads
      } = this.props.filterValues

      const {
        selectableProject
      } = this.props.availableAggs

      filterComponentsData = [
        {
          label: 'Uploads',
          key: 'globalUploads',
          type: 'radio',
          direction: 'vertical',
          value: [
            { label: 'In this project', value: 'local', selected: globalUploads === 'local' },
            { label: 'All uploaded by me', value: 'global', selected: globalUploads === 'global' },
            { label: 'All sent to me', value: 'sent', selected: globalUploads === 'sent' }
          ]
        }
      ]

      if (globalUploads === 'global') {
        filterComponentsData.push(
          {
            label: 'From project',
            key: 'projectId',
            type: 'dropdown',
            options: createDropdownOptionsFromAggsVariants(selectableProject),
            defaultValue: {
              value: this.props.filterValues.filterProjectId, label: this.props.filterValues.filterProjectLabel
            }
          }
        )
      }
    }

    if (this.props.activeTab === 'variant') {
      const {
        globalVariants
      } = this.props.filterValues

      const {
        selectableProject
      } = this.props.availableAggs

      filterComponentsData = [
        {
          label: 'Variants',
          key: 'globalVariants',
          type: 'radio',
          direction: 'vertical',
          value: [
            { label: 'In this project', value: 'local', selected: globalVariants === 'local' },
            { label: 'All created by me', value: 'global', selected: globalVariants === 'global' },
            { label: 'All sent to me', value: 'sent', selected: globalVariants === 'sent' }
          ]
        }
      ]
      if (globalVariants === 'global') {
        filterComponentsData.push(
          {
            label: 'From project',
            key: 'projectId',
            type: 'dropdown',
            options: createDropdownOptionsFromAggsVariants(selectableProject),
            defaultValue: {
              value: this.props.filterValues.filterProjectId, label: this.props.filterValues.filterProjectLabel
            }
          }
        )
      }
    }

    if (this.props.activeTab === 'modelbank') {
      const {
        businessArea,
        productRangeArea,
        productArea,
        endSalesDateStatus,
        stylegroup
      } = this.props.filterValues

      const {
        selectableHfb,
        selectablePra,
        selectablePa,
        selectableStylegroups
      } = this.props.availableAggs

      // handle selectable pra and pa options
      let filteredSelectablePra
      let filteredSelectablePa
      if (businessArea) {
        filteredSelectablePra = _filter(selectablePra, (pra) => isSubsetOfHfb(pra.key, businessArea))
        filteredSelectablePa = _filter(selectablePa, (pa) => isSubsetOfHfb(pa.key, businessArea))
      }
      if (productRangeArea) {
        filteredSelectablePa = _filter(selectablePa, (pa) => isSubsetOfHfb(pa.key, productRangeArea))
      }

      const hfbValue = _find(selectableHfb, _hfb => _hfb.key === businessArea)
      const praValue = _find(selectablePra, _pra => _pra.key === productRangeArea)
      const paValue = _find(selectablePa, _pa => _pa.key === productArea)

      filterComponentsData = [
        {
          label: 'HFB',
          key: 'businessArea',
          type: 'inputSelect',
          value: !businessArea ? defaultValue : {
            label: `${businessArea} ${_get(hfbValue, 'name', '')}`,
            value: businessArea
          },
          defaultValue,
          options: createDropdownOptionsFromAggs(selectableHfb)
        },
        {
          label: 'Product range area',
          key: 'productRangeArea',
          type: 'inputSelect',
          value: !productRangeArea ? defaultValue : {
            label: `${productRangeArea} ${_get(praValue, 'name', '')}`,
            value: productRangeArea
          },
          defaultValue,
          options: createDropdownOptionsFromAggs(filteredSelectablePra || selectablePra)
        },
        {
          label: 'Product area',
          key: 'productArea',
          type: 'inputSelect',
          value: !productArea ? defaultValue : {
            label: `${productArea} ${_get(paValue, 'name', '')}`,
            value: productArea
          },
          defaultValue,
          options: createDropdownOptionsFromAggs(filteredSelectablePa || selectablePa)
        },
        {
          label: 'End sales date',
          key: 'endSalesDateStatus',
          type: 'radio',
          direction: 'vertical',
          value: [
            { label: 'Show all', value: 'show all', selected: endSalesDateStatus === 'show all' },
            { label: 'Hide ended', value: 'hide ended', selected: endSalesDateStatus === 'hide ended' }
          ]
        },
        {
          label: 'Stylegroup',
          key: 'stylegroup',
          type: 'inputSelect',
          value: !stylegroup ? defaultValue : {
            label: `${stylegroup}`,
            value: stylegroup
          },
          defaultValue,
          options: createDropdownOptionsFromAggs(selectableStylegroups, false)
        }
      ]
    }
    return filterComponentsData
  }

  render () {
    const filterComponentsData = this.getFilterComponentsData()

    return (
      <div>
        <div>
          {_map(filterComponentsData, (filter) => (
            <div key={filter.key} className='mb2 flex flex-auto width-100'>
              {createSelectionElement({
                filter,
                handleTextChange: this.handleTextChange,
                handleRadioChange: this.handleRadioChange,
                handleInputSelectChange: this.handleInputSelectChange,
                applyFilter: this.handleApplyFilter
              })}
            </div>
          ))}
          {this.props.activeTab === 'modelbank' &&
            <div data-testid="filter-display-options" className='flex flex-column flex-auto'>
              <Label>Display options</Label>
              <InputCheckbox
                name='showSizes'
                key='showSizes'
                label='Show sizes'
                checked={this.props.showSizes}
                onChange={() => {
                  this.props.setShowSizes(!this.props.showSizes)
                }}
              />
            </div>
          }
        </div>
      </div>
    )
  }
}

export default connector(SearchFilters)
