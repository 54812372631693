import React from 'react'

const IconPortraitProportions = props => (
  <svg height={props.size} viewBox="0 0 16 24" {...props}>
    <path d="M-3.1,4.9 L-3.1,19.1 L19.1,19.1 L19.1,4.9 L-3.1,4.9 Z M-3.4,4 L19.4,4 C19.7313708,4 20,4.26862915 20,4.6 L20,19.4 C20,19.7313708 19.7313708,20 19.4,20 L-3.4,20 C-3.73137085,20 -4,19.7313708 -4,19.4 L-4,4.6 C-4,4.26862915 -3.73137085,4 -3.4,4 Z" id="portrait" transform="translate(8.000000, 12.000000) rotate(90.000000) translate(-8.000000, -12.000000) "
      fill={props.color}
    />
  </svg>
)

IconPortraitProportions.defaultProps = {
  color: 'currentcolor',
  size: '1em'
}

export default IconPortraitProportions
