import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as fromInterfaceModals from '../../stores/ducks/interface/modals'
import * as fromRoomsets from '../../stores/ducks/roomsets'
import { FilteredRoomset } from '../../stores/ducks/roomsets/Roomset'
import * as fromRoomsetsSelectors from '../../stores/ducks/roomsets/selectors'
import * as fromTemplates from '../../stores/ducks/templates'
import * as fromThreeviewerUI from '../../stores/ducks/threeviewer/ui'
import AutoFill from '../common/grid/AutoFill'
import KeyboardListener, { KeyBinding, KeyCode } from '../common/keyboard-listener'
import CamerasView from './cameras'
import Item from './list-item'
interface Props {
  onClose: () => void
}

const List = ({ onClose }: Props) => {
  const dispatch = useDispatch()
  const roomsets = useSelector(fromRoomsetsSelectors.getFilteredEntries)
  const currentRoomsetId = useSelector(fromRoomsetsSelectors.getCurrentId)

  const [selectedRoomset, setSelectedRoomset] = useState<FilteredRoomset | null>(null)
  const [isCamerasOpen, setIsCamerasOpen] = useState<boolean>(false)

  useEffect(() => {
    dispatch(fromRoomsets.fetchIfNeeded())
  }, [])

  const handleLoad = (
    id: string,
    cameraSettings = null
  ) => {
    dispatch(fromTemplates.load(id, { cameraSettings }))
    onClose()
    dispatch(fromThreeviewerUI.setActiveLeftPanelIndex(1))
  }

  const onOpenCameras = (id: string) => {
    const currentRoomset = roomsets.find(roomset => roomset.id === id)
    if (!currentRoomset) return
    setSelectedRoomset(currentRoomset)
    setIsCamerasOpen(true)

    dispatch(fromInterfaceModals.setTemplateModalProp({ key: 'shouldCloseOnEsc', value: false }))
  }

  const onCloseCameras = () => {
    setIsCamerasOpen(false)
    dispatch(fromInterfaceModals.setTemplateModalProp({ key: 'shouldCloseOnEsc', value: true }))
    setSelectedRoomset(null)
  }

  const onLoadCamera = React.useCallback((cameraSettings: any) => {
    if (selectedRoomset) handleLoad(selectedRoomset.id, cameraSettings)
  }, [selectedRoomset])

  return (
    <>
      <div className='width-100 height-100 bg-gray-light'>
        {!roomsets.length
          ? <div className='height-100 flex items-center justify-center'>
              There is/are no home/s matching the filter/s you selected.
          </div>
          : <div className='height-100 p2 overflow-x-hidden overflow-y-auto custom-scrollbar'>
            <AutoFill width={320}>
              {roomsets.map((item, index) => (
                <Item
                  key={index + item.id}
                  onLoad={handleLoad}
                  onOpenCameras={() => onOpenCameras(item.id)}
                  currentRoomsetId={currentRoomsetId}
                  roomset={item}
                />
              ))}
            </AutoFill>
          </div>
        }
      </div>
      {isCamerasOpen && selectedRoomset && (
        <div className='absolute top-0 right-0 bottom-0 left-0 bg-white p3'>
          <CamerasView
            onCloseCameras={onCloseCameras}
            onLoad={onLoadCamera}
            selectedRoomset={selectedRoomset}
          />
        </div>
      )}
      <KeyboardListener
        bindings={[
          KeyBinding(KeyCode.esc, onCloseCameras)
        ]}
      />
    </>
  )
}

export default React.memo(List)
