import React from 'react'

import { MdNotifications, MdNotificationsNone } from 'react-icons/md'
import IconCross from '../common/icons/icon-cross'

import * as fromNewsSelectors from '../../stores/ducks/news/selectors'
import * as fromUsersSelectors from '../../stores/ducks/users/selectors'

import { createSelector } from 'reselect'
import * as fromUsers from '../../stores/ducks/users'
import * as fromNews from '../../stores/ducks/news'
import { connect } from 'react-redux'

import PropTypes from 'prop-types'
import PreviewPostModal from '../admin/admin-communicate/preview-post-modal'
import moment from 'moment'
import NavigatePostsModal from '../admin/admin-communicate/navigate-posts-modal'

function NewsNotification (props) {
  const [showLatestNotice, setShowLatestNotice] = React.useState(false)
  const [showNoticeList, setShowNoticeList] = React.useState(false)
  const [noticeToShow, setNoticeToShow] = React.useState()
  React.useEffect(() => {
    props.fetchNews()
  }, [])
  if (!props.article) {
    return <></>
  }
  return (
    <>
      <div className="relative">
        {props.read ? (
          <MdNotificationsNone
            className='pointer'
            onClick={() => {
              setNoticeToShow(props.article)
              setShowLatestNotice(true)
            }}
            size={'2rem'}
            color={'white'}
          />
        ) : (
          <MdNotifications
            className='pointer'
            size={'2rem'}
            color={'white'}
            onClick={() => {
              setNoticeToShow(props.article)
              setShowLatestNotice(true)
            }}
          />
        )}

        {!props.optOut && !props.read && (
          <div
            className="z4 absolute flex flex-column items-center"
            style={{ left: '-134px' }}
          >
            <IconCross
              size={'1em'}
              className="scale pointer absolute z1 mx2 my2 top-0 right-0"
              onClick={() => props.optOutNews(props.article.id)}
            />
            <div
              style={{
                borderLeft: '5px solid transparent',
                borderRight: '5px solid transparent',
                borderBottom: '5px solid rgb(229, 229, 229)'
              }}
            />{' '}
            {/* Creates an arrow above the box */}
            <div
              className="p2 bg-gray-light pointer"
              style={{ borderRadius: '5px', width: '300px' }}
              onClick={() => setShowLatestNotice(true)}
            >
              <div>
                <h4 className="mt0 pr3 truncate" style={{ marginBottom: '0.3rem' }}>
                  {props.article.popupTitle}
                </h4>
                <time className={'mb1 block truncate f7 c-gray-accessible'}>Published: {moment(props.article.publishedAt).calendar()}</time>

                <p className="mt0 f6" style={{ wordBreak: 'break-word' }}>
                  {props.article.popupBody}
                </p>
              </div>
            </div>
          </div>
        )}
      </div>

      <PreviewPostModal
        isOpen={showLatestNotice}
        onRequestClose={() => {
          setShowLatestNotice(false)
          props.setNewsAsRead(props.article.id)
        }}
        onRequestShowNavigatePostsModal={() => {
          setShowLatestNotice(false)
          setShowNoticeList(true)
        }}
        article={noticeToShow || props.article}
      />
      <NavigatePostsModal
        isOpen={showNoticeList}
        onRequestClose={() => {
          setShowNoticeList(false)
        }}
        onRequestShowNotice={(post) => {
          setNoticeToShow(post)
          setShowNoticeList(false)
          setShowLatestNotice(true)
        }}
        articles={props.articles}
      />
    </>
  )
}

NewsNotification.propTypes = {
  article: PropTypes.object
}

const mapStateToProps = createSelector(
  fromNewsSelectors.getLatestPublishedArticle,
  fromUsersSelectors.getCurrent,
  fromNewsSelectors.getLatestPublishedArticles,
  (article, currentUser, articles) => {
    return {
      article,
      articles,
      read: !article
        ? false
        : !!(currentUser.news &&
          currentUser.news[article.id] &&
          currentUser.news[article.id].read),
      optOut: !article
        ? false
        : !!(currentUser.news &&
          currentUser.news[article.id] &&
          currentUser.news[article.id].optOut)
    }
  }
)

const mapDispatchToProps = (dispatch) => ({
  fetchNews: () => dispatch(fromNews.fetchAllPublished()),
  optOutNews: articleId => {
    dispatch(fromUsers.optOutNews(articleId))
  },
  setNewsAsRead: articleId => {
    dispatch(fromUsers.setNewsAsRead(articleId))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(NewsNotification)
