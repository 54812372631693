import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { reportStatus } from './adminReportsUseSelector'
import _filter from 'lodash/filter'

import * as fromReports from '../../../stores/ducks/reports'
import Grid from '../../common/grid/grid'

import * as fromSpreadsheet from '../../../stores/ducks/spreadsheet'
import Menu from './menu'
import Filters from './filters'
import UsersTable from './usersTable'
import ProjectsTable from './projectsTable'

function AdminReports () {
  const dispatch = useDispatch()
  const { activeUsers, projects, nonActiveUsers } = useSelector(reportStatus)

  const [searchString, setSearchString] = useState('')
  const [searchedActiveUsers, setSearchedActiveUsers] = useState(activeUsers)
  const [searchedProjects, setSearchedProjects] = useState(projects)
  const [selectedMenuItem, setSelectedMenuItem] = useState('users')

  const usersSelected = selectedMenuItem === 'users'
  const projectsSelected = selectedMenuItem === 'projects'

  useEffect(() => {
    dispatch(fromReports.getUserData())
    dispatch(fromReports.getProjectData())
  }, [])

  useEffect(() => {
    setSearchedActiveUsers(activeUsers)
    setSearchedProjects(projects)
  }, [activeUsers, projects])

  useEffect(() => {
    if (usersSelected) {
      if (searchString.length > 0) setSearchedActiveUsers(filterSearch(activeUsers))
      else setSearchedActiveUsers(activeUsers)
      setSearchedProjects(projects)
    }
    if (projectsSelected) {
      if (searchString.length > 0) setSearchedProjects(filterSearch(projects))
      else setSearchedProjects(projects)
      setSearchedActiveUsers(activeUsers)
    }
  }, [searchString])

  const filterSearch = (data) => {
    return Object.values(data).filter(item => {
      if (
        (item.email && item.email.toUpperCase().includes(searchString.toUpperCase())) ||
        (item.title && item.title.toUpperCase().includes(searchString.toUpperCase()))
      ) {
        return item
      }
    })
  }
  return (
    <Grid rows={['auto', 'auto', '1fr']}>
      <Menu
        usersSelected={usersSelected}
        projectsSelected={projectsSelected}
        setMenuItem={(menuItem) => {
          setSelectedMenuItem(menuItem)
          setSearchString('')
        }}
      />
      <Filters
        width={''}
        searchString={searchString}
        onSearchChange={(s) => setSearchString(s)}
        onSearchClear={() => setSearchString('')}
        onDownloadXls={() => usersSelected ? dispatch(fromSpreadsheet.getUserReportsSpreadsheet(searchedActiveUsers))
          : dispatch(fromSpreadsheet.getProjectReportsSpreadsheet(searchedProjects))
        }
      />
      <div style={{ overflowY: 'auto', overFlowX: 'auto' }}>
        { usersSelected
          ? <UsersTable
            numberOfActiveUsers={activeUsers ? activeUsers.length : undefined}
            searchedActiveUsers={searchedActiveUsers}
            nonActiveUsers={nonActiveUsers}
          />
          : <ProjectsTable
            numberOfProjects={projects ? projects.length : undefined}
            numberOfPPMProjects={projects ? _filter(projects, { PPM: true }).length : undefined}
            searchedProjects={searchedProjects}
          /> }
      </div>
    </Grid>)
}

export default AdminReports
