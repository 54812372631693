import React from 'react'

const IconTrash = props => (
  <svg viewBox='0 0 18 20' width='1em' height='1em' {...props}>
    <path
      d='M10 16.583V7.417a.373.373 0 0 0-.14-.3A.542.542 0 0 0 9.5 7h-1a.542.542 0 0 0-.36.117.373.373 0 0 0-.14.3v9.166c0 .122.047.222.14.3A.542.542 0 0 0 8.5 17h1c.146 0 .266-.04.36-.117a.373.373 0 0 0 .14-.3zm-4 0V7.417a.373.373 0 0 0-.14-.3A.542.542 0 0 0 5.5 7h-1a.542.542 0 0 0-.36.117.373.373 0 0 0-.14.3v9.166c0 .122.047.222.14.3A.542.542 0 0 0 4.5 17h1c.146 0 .266-.04.36-.117a.373.373 0 0 0 .14-.3zm8 0V7.417a.373.373 0 0 0-.14-.3A.542.542 0 0 0 13.5 7h-1a.542.542 0 0 0-.36.117.373.373 0 0 0-.14.3v9.166c0 .122.047.222.14.3a.542.542 0 0 0 .36.117h1c.146 0 .266-.04.36-.117a.373.373 0 0 0 .14-.3zM6.136 3.333h5.728L11.25 1.81a.36.36 0 0 0-.217-.143H6.98a.36.36 0 0 0-.217.143l-.627 1.523zm11.749 1.55A.394.394 0 0 1 17.59 5H16v12.344s.163 1.343-.237 1.868c-.4.525-.882.788-1.445.788H3.682c-.563 0-1.044-.254-1.445-.762-.4-.508-.237-1.842-.237-1.842V5H.41a.394.394 0 0 1-.295-.117.41.41 0 0 1-.115-.3V3.75a.41.41 0 0 1 .115-.3.394.394 0 0 1 .294-.117h3.95l.895-2.174c.128-.321.358-.595.69-.82C6.278.113 6.615 0 6.955 0h4.091c.341 0 .678.113 1.01.339.333.225.563.499.69.82l.896 2.174h3.95C17.71 3.333 18 4 18 4s-.038.805-.115.883z'
      fill={props.color}
    />
  </svg>
)

IconTrash.defaultProps = {
  color: 'currentcolor'
}

export default IconTrash
