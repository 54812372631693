import cs from 'classnames'
import React, { MouseEvent } from 'react'

import Button from '../../common/button'
import TextArea from '../../common/form/textarea'

import useOutsideClick from '../../../hooks/useOutsideClick'
import type { Tags, Comment } from '../../../stores/ducks/scenes/Temp'
import KeyboardListener, { KeyCode, KeyBinding } from '../../common/keyboard-listener'

import { TagInputs } from '../../../components/project/scenes/comments/comment-tags'

type Props = {
  isReply?: boolean
  isUpdate?: boolean
  oldComment?: string
  expanded?: boolean
  onSubmit: (value: string, tags: any[]) => void
  onCancel: () => void
  onExpand: () => void
  lightBorder: boolean
  tagsActive: boolean
  tags?: Tags
  noOutSideClick?: boolean
  commentToUpdate?: Comment
}
function AddOrUpdateComment (props: Props) {
  const [expanded, setExpanded] = React.useState(false)
  const [comment, setComment] = React.useState(props.oldComment || '')
  const [tags, setTags] = React.useState([])
  const domNodeRef = React.useRef<HTMLDivElement | null>(null)
  const scrollTo = React.useRef<HTMLDivElement | null>(null)

  React.useEffect(() => {
    if (props.expanded !== undefined) {
      setExpanded(props.expanded)
    }
  }, [props.expanded])

  React.useEffect(() => {
    if (props.isReply && expanded && !expanded) {
      window.requestAnimationFrame(() => {
        // Non-standard. https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoViewIfNeeded
        // @ts-ignore
        scrollTo.current && scrollTo.current.scrollIntoViewIfNeeded()
      })
    }
  })

  useOutsideClick(domNodeRef, React.useCallback(() => {
    if (!props.noOutSideClick) setExpanded(false)
  }, []))

  function onCancel () {
    setExpanded(false)
    props.onCancel()
  }

  function onExpand () {
    setExpanded(true)
    props.onExpand()
  }

  function handleSubmit (event: Event | MouseEvent) {
    if (!comment) return event.preventDefault()
    props.onSubmit(comment, tags)
    setTags([])
    setComment('')
    onCancel()
  }

  const handleChange = (event:React.ChangeEvent<HTMLTextAreaElement>) => {
    if (!event.target) return
    setComment(event.target.value.trim())
  }

  const handleEnter = (event: Event) => {
    if (comment) handleSubmit(event)
  }

  return (
    <div
      ref={domNodeRef}
      className={cs('br-2 pt1 relative', {
        pb1: props.isReply,
        mt2: !props.isReply
      })}
    >
      {props.isUpdate && <p className='bold'>Edit</p>}
      {!expanded && (
        <div
          className={cs({
            'flex justify-end c-primary-hover pointer': props.isReply,
            'bg-white py2 px1 br-2 border': !props.isReply,
            'bc-gray-light': props.lightBorder
          })}
          onClick={onExpand}
        >
          {props.isReply ? 'Reply' : 'Add comment...'}
        </div>
      )}
      <div
        ref={scrollTo}
        className='pointer-disabled absolute height-100 top-0'
      />
      {expanded && (
        <>
          <TextArea
            autoFocus={!props.isUpdate}
            onChange={handleChange}
            onKeyDown={(event: KeyboardEvent) => {
              if (event.key === 'Enter') {
                handleSubmit(event)
              }
              if (event.key === 'Escape') {
                onCancel()
              }
            }}
            defaultValue={props.oldComment}
            className={cs({
              m0: props.isReply
            })}
          />
          {props.tagsActive && props.tags &&
          <TagInputs setTags={setTags} tags={props.tags} selectedTags={props.commentToUpdate ? props.commentToUpdate.tags : undefined}/>
          }
          <div className='flex justify-end' >
            <Button
              size='small'
              className='my1'
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              disabled={!comment}
              size='small'
              className='my1 ml-auto'
              btnType='primary'
              onClick={handleSubmit}
            >
              {props.isReply ? 'Reply' : props.isUpdate ? 'Update' : 'Comment'}
            </Button>
          </div>
          <KeyboardListener
            bindings={[
              KeyBinding(KeyCode.enter, handleEnter)
            ]}
          />
        </>
      )}
    </div>
  )
}

export default AddOrUpdateComment
