import cs from 'classnames'
import _filter from 'lodash/filter'
import _fpFilter from 'lodash/fp/filter'
import _fpFlow from 'lodash/fp/flow'
import _fpGroupBy from 'lodash/fp/groupBy'
import _fpMap from 'lodash/fp/map'
import _fpReject from 'lodash/fp/reject'
import _get from 'lodash/get'
import _orderBy from 'lodash/orderBy'
import _reduce from 'lodash/reduce'
import moment from 'moment'
import React from 'react'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import colors from '../../../../../css/colors'
import { DEADLINE_STATUS, PROJECT_TYPES } from '../../../../constants'

import * as fromCombinationsSelectors from '../../../../stores/ducks/combinations/selectors'
import * as fromProjectsSelectors from '../../../../stores/ducks/projects/selectors'
import * as fromRenders from '../../../../stores/ducks/renders'
// Selectors
import * as fromRenderSelectors from '../../../../stores/ducks/renders/selectors'
import * as fromThreeviewerSelectors from '../../../../stores/ducks/threeviewer/selectors'
// Actions
import * as fromThreeviewer from '../../../../stores/ducks/threeviewer/viewer'
import { getImageSrc } from '../../../../utils/storage'
import AutoFill from '../../../common/grid/AutoFill'
import IconMultipleImages from '../../../common/icons/icon-multiple-images'
import { PanelSection, PanelSectionContent, PanelSectionHeader } from '../../../common/panels/panel-section'
import Pending from '../../../common/pending'
import { StorageApiThumbnail } from '../../../common/storage-api-image'
import GalleryModal from '../../../gallery/modal'
import { EmptyStateLoadDesign, EmptyStateSaveDesign, EmptyStateCreateImagePackage } from './empty-states'

const Render = props => (
  <div
    className={cs({
      'opacity-hover-lighter': props.isClickable,
      'bs-secondary': props.active,
      pointer: props.isClickable
    })}
    onClick={props.onPreview}
  >
    <div className='relative border bc-gray-light'>
      {(props.rendered && props.deadlineStatus === DEADLINE_STATUS.COMPLETED) ? (
        <StorageApiThumbnail file={props.thumbnail} />
      ) : (
        <Pending
          {...props.combination}
          disableButtons
          deadlineStatus={props.deadlineStatus}
          renderStatus={props.renderStatus}
          renderPercentage={props.renderPercentage}
          status={props.status}
          hideTitle
          onCancel={props.cancel}
          backgroundImage={props.backgroundImage || getImageSrc(props.thumbnail, { format: 'jpg', type: 'thumbnail' })}
          isVersioned={props.isVersioned}
        />
      )}
      {props.isVersioned && !!props.rendered && props.deadlineStatus !== DEADLINE_STATUS.FAILED &&
        <IconMultipleImages
          size={18}
          color={colors.secondary}
          className='absolute top-0 right-0 m1'
        />
      }
    </div>

    <p className='mt1 f7 c-gray-accessible px1 block center' style={{ wordBreak: 'break-all' }}>
      {props.title}
    </p>
  </div>
)

function RendersPanel (props) {
  const [activeId, setActiveId] = React.useState(null)

  function getActiveIndex (renders) {
    return renders.findIndex(render => (_get(render, 'combination.originalCombinationId') === _get(props.currentCombination, 'originalCombinationId')) && !_get(props.currentCombination, 'isMaster'))
  }

  function openPreview (activeId) {
    if (!activeId) return
    setActiveId(activeId)
    props.disableKeyboardListeners()
  }

  function closePreview () {
    setActiveId(null)
    props.enableKeyboardListeners()
  }

  const allRenders = _orderBy(props.renders, 'timestamp', ['desc'])

  return (
    <PanelSection className='overflow-auto'>
      <PanelSectionHeader title='Images' />
      {!props.renders.length && !props.hasModel && (
        <EmptyStateLoadDesign />
      )}
      {!props.renders.length && props.hasModel && !props.currentCombinationId && (
        <EmptyStateSaveDesign />
      )}
      {(!props.renders.length && props.hasModel && props.currentCombinationId) && (
        <EmptyStateCreateImagePackage />
      )}
      <PanelSectionContent>
        <AutoFill width={80}>
          {props.renders.map((render, index) =>
            <Render
              key={render.id}
              combination={render.combination}
              thumbnail={render.thumbnail}
              renderedAt={render.renderedAt}
              timestamp={render.timestamp}
              deadlineStatus={render.deadlineStatus}
              rendered={render.rendered}
              renderStatus={render.renderStatus}
              renderPercentage={render.renderPercentage}
              renders={render.renders}
              status={render.status}
              title={render.title}
              projectType={props.projectType}
              onPreview={() => openPreview(render.id)}
              active={getActiveIndex(props.renders) === index}
              isVersioned={render.isVersioned}
              onCancel={props.cancel}
              backgroundImage={_get(render, 'latestCompletedRender.imageSrc')}
              isClickable
            />
          )}
        </AutoFill>
      </PanelSectionContent>

      {activeId && (
        <GalleryModal
          backToText='Return to Visualizer View'
          onBack={closePreview}
          renderId={activeId}
          rendersInProject={allRenders}
          parentCombinationId={props.parentCombinationId}
          designCardTitle={props.designCardTitle}
        />
      )}
    </PanelSection>
  )
}

const getThumbnail = (render) => getImageSrc(_get(render, 'manifest.files.0', {}), { format: 'jpg', type: 'thumbnail' })

const mapStateToProps = createSelector(
  fromCombinationsSelectors.getCurrentEntry,
  fromRenderSelectors.getEntries,
  fromThreeviewerSelectors.getHasCombinationModel,
  fromCombinationsSelectors.getCurrentId,
  fromCombinationsSelectors.getEntries,
  fromProjectsSelectors.getCurrentEntry,
  (
    currentCombination,
    renders,
    hasModel,
    currentCombinationId,
    combinations,
    project
  ) => {
    const projectType = project.projectType

    const parentId = currentCombination.parentId || currentCombination.id
    const designCardTitle = _get(combinations, [parentId, 'title'])
    const orderBy = projectType === PROJECT_TYPES.IMAGE_PACKAGE
      ? 'combination.title'
      : 'timestamp'

    const _renders = _fpFlow(
      _fpFilter(Boolean),
      _fpReject('removedAt'),
      _fpFilter(render => render.parentCombinationId === parentId),
      _fpReject(render => _get(combinations, [render.combinationId, 'isMaster'])),
      _fpReject(render => render.combinationId === parentId && !currentCombination.parentId),
      _fpMap(render => {
        const timestamp = Date.parse(render.renderedAt || render.createdAt)

        return render.merge({
          combination: combinations[render.combinationId],
          render,
          timestamp,
          renderedAt: moment(timestamp).fromNow(),
          thumbnail: _get(render, 'manifest.files.0'),
          imageSrc: getThumbnail(render)
        })
      }),
      _fpGroupBy('combination.originalCombinationId'),
      (groupedByOriginalId) => _reduce(groupedByOriginalId, (acc, children, originalCombinationId) => {
        if (!originalCombinationId || originalCombinationId === 'undefined') {
          return acc.concat(children)
        }

        const parentVersions = _get(combinations, [parentId, 'versions'], {})
        const versions = _orderBy(children, 'timestamp', ['desc'])

        const savedFinalVersionId = parentVersions[originalCombinationId]
        let finalVersion = children.find(child => child.combination.id === savedFinalVersionId)
        if (!finalVersion) {
          finalVersion = versions[0]
        }

        return acc.concat(finalVersion.merge({
          title: _get(finalVersion, 'combination.title'),
          versions,
          latestCompletedRender: _filter(versions || [], version => version.rendered)[0],
          isVersioned: versions.length > 1
        }))
      }, []),
      (items) => _orderBy(items, orderBy, ['desc'])
    )(renders)
    return {
      renders: _renders,
      parentCombinationId: parentId,
      projectType,
      hasModel,
      currentCombinationId,
      currentCombination: combinations[currentCombinationId],
      designCardTitle
    }
  }
)

const mapDispatchToProps = (dispatch) => ({
  remove: (id) => dispatch(fromRenders.remove(id)),
  enableKeyboardListeners: () => dispatch(fromThreeviewer.enableKeyboardListeners()),
  disableKeyboardListeners: () => dispatch(fromThreeviewer.disableKeyboardListeners())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RendersPanel)
