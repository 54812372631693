import { createSelector } from 'reselect'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _has from 'lodash/has'
import _compact from 'lodash/compact'
import _filter from 'lodash/filter'
import _keyBy from 'lodash/keyBy'
import _includes from 'lodash/includes'

// Lodash
import _groupBy from 'lodash/groupBy'

import * as fromRendersSelectors from '../renders/selectors'
import * as fromPatternsSelectors from '../patterns/selectors'
import type { RootState } from '..'
import type { StorageApiFile } from '../../../utils/storage'
import type { Render } from '../renders/Render'
import type { ImmutableObject } from 'seamless-immutable'
import type { Combination, VisualizedCombination } from '../combinations/Combination'
import { Project } from './Project'

const getLocalState = (state: RootState) => state.projects
export const selectProjectById = (id: string) => (state: RootState) => _get(getLocalState(state), `entries.${id}`, false) as ImmutableObject<Project> | boolean
export const getCurrentId = (state: RootState) => getLocalState(state).currentId as string | null
export const getEntries = (state: RootState) => getLocalState(state).entries as ImmutableObject<{ [id: string]: Project }>
export const getCurrentEntry = (state: RootState) => _get(getEntries(state), getCurrentId(state) || '', {}) as ImmutableObject<Project & { virtualProductCount?: number }>
export const getCurrentProjectType = (state: RootState) => getCurrentEntry(state).projectType
export const getStatus = (state: RootState) => _get(getLocalState(state), 'status')

export const getMarkedAppearances = createSelector(
  getCurrentEntry,
  (currentEntry) => {
    return currentEntry.markedAppearances?.asMutable() || []
  }
)

export const getMarkedColors = createSelector(
  getCurrentEntry,
  (currentEntry) => {
    return currentEntry.markedColors?.asMutable() || []
  }
)

export const getEntriesByIds = createSelector(
  getEntries,
  (_: RootState, ownProps: { ids: string[] }) => ownProps.ids,
  (entries, ids) => _compact(ids.map((id) => _get(entries, id)))
)

const getProjectCameraList = createSelector(
  getEntries,
  getCurrentEntry,
  (entries, current) => {
    return _get(entries, [current.id, 'cameraList'], {})
  }
)

export const getCameraList = createSelector(
  getProjectCameraList,
  getCurrentEntry,
  (cameraList, currentEntry) => {
    const cameraTransform = _get(currentEntry, 'cameraSettings.transform')
    const cameraFov = _get(currentEntry, 'cameraSettings.fov')
    const aspectRatio = _get(currentEntry, 'cameraSettings.aspectRatio', _get(currentEntry, 'cameraSettings.resolution', { x: 1, y: 1 }))

    const renderSetting = cameraTransform && cameraFov ? {
      renderSetting: {
        fov: cameraFov,
        aspectRatio: aspectRatio,
        title: 'Rendered Setting',
        transform: cameraTransform,
        locked: true
      }
    } : {}

    return Object.assign({}, cameraList, renderSetting)
  }
)

export const getCombinations = createSelector(
  (state: RootState): string | null => state.projects.currentId,
  (state: RootState) => state.combinations.entries,
  (state: RootState): string | null => state.users.currentUserId,
  (projectId, combinations, currentUserId) => {
    return Object.values(combinations).filter(combination => {
      return (
        combination &&
        !combination.removedAt &&
        (combination.projectId === projectId || (
          combination.sentAssetId && combination.createdBy === currentUserId
        ))
      )
    })
  }
)

export const getPatterns = createSelector(
  (state: RootState) => state.projects.currentId,
  (state: RootState) => fromPatternsSelectors.getEntries(state),
  (state: RootState) => state.users.currentUserId,
  (projectId, patterns, currentUserId) => {
    return _filter(patterns, (pattern) => (
      pattern &&
      !pattern.removedAt &&
      (_includes(pattern.projects, projectId) || (pattern.sentAssetId && pattern.createdBy === currentUserId))
    ))
  }
)

export const getRenders = createSelector(
  (state: RootState) => state.projects.currentId,
  (state: RootState) => getCombinations(state),
  (state: RootState) => fromRendersSelectors.getEntries(state),
  (projectId, combinations, renders) => {
    const renderCombinations = _filter(combinations, { combinationType: 'render' })
    const rendersByCombinationId = _keyBy(_filter(renders, r => r && !r.removedAt), 'combinationId')
    const projectRenders = _map(renderCombinations, (combination) => rendersByCombinationId[combination.id])

    return _filter(projectRenders)
  }
)

export const isDnpProject = createSelector(
  getCurrentEntry,
  (currentEntry) => {
    return currentEntry.apiUserData && _has(currentEntry, ['apiUserData', 'dnp'])
  }
)

type CombinationWithRender = (ImmutableObject<Combination> | ImmutableObject<VisualizedCombination>) & {
  renders: ImmutableObject<Render>[]
  render: ImmutableObject<Render>
  manifest: ImmutableObject<Render>['manifest']
  thumbnail: ImmutableObject<StorageApiFile> | null
}

export const getCombinationsWithRender = createSelector(
  (state: RootState) => state.combinations.entries,
  (state: RootState) => state.renders.entries as unknown as { [id: string]: ImmutableObject<Render> },
  (state: RootState) => state.users.currentUserId,
  (state: RootState) => state.projects.currentId,
  (combinations, renders, userId, projectId) => {
    const rendersByCombinationId = _groupBy(renders, 'combinationId')

    const _combinations = Object.values(combinations)
      .filter(c => !c.removedAt)
      .filter(c => c.projectId === projectId || (c.sentAssetId && c.createdBy === userId))
      .map(combination => {
        const renders = rendersByCombinationId[combination.id]
        if (!renders) return

        let defaultRender = renders[0]
        if (renders.length > 1) {
          defaultRender = renders.find(r => r.id === combination.defaultRenderId) ?? defaultRender
        }

        return {
          ...combination,
          renders: renders,
          manifest: defaultRender.manifest,
          render: defaultRender,
          thumbnail: defaultRender.manifest?.files?.length ? defaultRender.manifest.files[0] : null
        }
      }) as CombinationWithRender[]

    return _combinations
  }
)

export const getDashboardStatus = (state: RootState) => state.projects.dashboardStatus
