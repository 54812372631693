import React from 'react'

type Props = React.HTMLAttributes<HTMLDivElement> & {
  innerClass?: string
  noShadow?: boolean
  draggable?: boolean
  tooltip?: string
  onDragStart?: (event: React.DragEvent<HTMLDivElement>) => void
  onDragEnd?: (event: React.DragEvent<HTMLDivElement>) => void
}

export default function CardShell (props: Props) {
  return (
    <div
      data-testid='card-shell'
      onClick={props.onClick}
      className={props.className}
      draggable={props.draggable || false}
      onDragStart={props.onDragStart}
      onDragEnd={props.onDragEnd}
      title={props.tooltip}
    >
      <div
        className={`${props.noShadow ? '' : 'paper-1'} br-small ${props.innerClass}`}
        style={props.style}
      >
        {props.children}
      </div>
    </div>
  )
}
