import React from 'react'
import PropTypes from 'prop-types'
import cs from 'classnames'

import css from './panel-button.css'

const classNames = {
  base: 'relative px1 center pointer flex flex-column items-center justify-center ease-in-out',
  inactive: 'border-top',
  disabled: 'pointer-disabled',
  default: {
    enabled: 'c-gray-dark fill-gray-dark',
    disabled: 'c-black-25 fill-black-25',
    inactive: `bg-gray-lighter bg-white-hover bc-black-10 ${css.inactive}`,
    active: {
      base: 'z1 relative bg-white bc-primary',
      left: `border-left ${css.leftActive}`,
      right: `border-right ${css.rightActive}`
    }
  },
  inverted: {
    base: 'bc-white-25',
    active: 'bg-gray c-primary fill-primary',
    disabled: 'c-white-25 fill-white-25',
    enabled: 'c-white fill-white',
    inactive: 'bg-gray-dark bg-black-70-hover'
  }
}

const getClassName = ({ position, active, inverted, disabled }) => {
  if (inverted) {
    return cs(classNames.base, classNames.inverted.base, {
      [`${classNames.inactive} ${classNames.inverted.inactive}`]: !active,
      [classNames.inverted.active]: active,
      [classNames.inverted.enabled]: !disabled,
      [`${classNames.disabled} ${classNames.inverted.disabled}`]: disabled
    })
  }

  return cs(classNames.base, classNames.default.base, {
    [`${classNames.inactive} ${classNames.default.inactive}`]: !active,
    [cs(classNames.default.active.base, classNames.default.active[position])]: active,
    [classNames.default.enabled]: !disabled,
    [`${classNames.disabled} ${classNames.default.disabled}`]: disabled
  })
}

const styles = {
  title: { marginTop: 5 }
}

function noop () {}

const PanelButton = ({
  icon,
  title,
  active,
  position,
  onClick,
  height,
  disabled,
  inverted
}) => (
  <div
    data-testid={title}
    disabled
    title={title}
    onClick={disabled ? noop : onClick}
    style={{ height }}
    className={getClassName({ position, active, inverted, disabled })}
  >
    {icon}
    <div className='f8' style={styles.title}>
      {title}
    </div>
  </div>
)

PanelButton.defaultProps = {
  height: 88
}

PanelButton.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.element,
  width: PropTypes.number,
  height: PropTypes.number,
  active: PropTypes.bool,
  position: PropTypes.oneOf(['left', 'right']),
  onClick: PropTypes.func
}

export default PanelButton
