import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import _map from 'lodash/map'

import Modal from '../../common/modal'
import ModalContent from '../../common/form/form-remove'
import Button from '../../common/button'
import { getExportByCombinationId } from '../../../stores/ducks/export/selectors'
import { startExportOfVariant } from '../../../stores/ducks/export'
import type { Item, VpItem } from '../card/Item'
import type { RootState } from '../../../stores/ducks'
import Spinner from '../../common/spinner'
import InputSelect from '../../common/form/input-select'
import { ExportData } from '../../../stores/ducks/export/ExportData'

type Props = {
  asset: Item | VpItem
  isOpen: boolean
  onClose: () => void
}

export const CenteredDiv = styled.div`
    top: 50%;
    left: 50%;
    margin: auto;
`

const fileTypeLabels = [
  /* { value: 'usdz', label: '.usdz', key: 1 }, */
  { value: 'gltf', label: '.gltf', key: 2 },
  { value: 'fbx', label: '.fbx', key: 3 },
  { value: 'zip', label: '.zip', key: 1 },
]

type DownloadFileTypes = 'gltf' | 'usdz' | 'fbx' | 'zip'

export default function ShareExternalModal (props: Props) {
  if (!props.isOpen) return null
  const id = props.asset.combinationId ? props.asset.combinationId : props.asset.id

  const dispatch = useDispatch()
  const [selectedFileTypes, setSelectedFileTypes] = useState<DownloadFileTypes[]>(['gltf'])
  const combExport:ExportData = useSelector((state: RootState) => getExportByCombinationId(state, id))
  const [loading, setLoading] = useState(false)

  const finished = !!combExport?.exportGltfModel
  const failed = combExport?.failed

  useEffect(() => {
    if (!combExport) setLoading(false)
    else if (combExport && !combExport.exportGltfModel) setLoading(true)
    else if (combExport && combExport.exportGltfModel) setLoading(false)
  }, [combExport])

  const startExport = async (combinationId: string, title: string) => {
    dispatch(startExportOfVariant(combinationId, title))
  }

  const handleStartProcess = async () => {
    if (!props.asset) return

    setLoading(true)
    await startExport(id, props.asset.title)
  }

  const handleDownloadExport = () => {
    selectedFileTypes.forEach((fileType: DownloadFileTypes) => downloadFile(fileType))
  }

  const downloadFile = (fileType : DownloadFileTypes) => {
    const basePath = `/api/export/view/${combExport?.id}/assets/`
    const link = document.createElement('a')
    let href = ''
    switch (fileType) {
      case 'gltf':
        href = `${basePath}${combExport?.exportGltfModel}`
        break
      case 'usdz':
        href = `${basePath}${combExport?.exportUsdzModel}`
        break
      case 'zip':
        href = `${basePath}${combExport?.exportUsdPackage}`
        break
      case 'fbx':
        href = `${basePath}${combExport?.exportFbxModel}`
        break
    }
    link.href = href
    link.download = `${combExport?.title}.${fileType}`
    link.click()
  }

  const handleFileTypeChange = (inputFileTypes:never) => setSelectedFileTypes(_map(inputFileTypes, 'value'))

  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.onClose}
      shouldCloseOnOverlayClick
      width={600}
    >
      <ModalContent
        formTitle='Export model'
        noDescMargin
        formDescription={(
          <>
            <div className=''>
              <div className='pb2'>
              Export and download variant model externally. The model export takes about one <b>minute</b>. The resulting model can be downloaded in either <b>gltf</b> or <b>fbx</b>.
              </div>
              {finished && <div className='pb2 c-success'>
              Export finished
              </div>}
              {!combExport && <div className='flex justify-center'>
                <Button btnType='primary' onClick={handleStartProcess} disabled={loading}>
                  Start export
                </Button>
              </div>}
              {failed
                ? <></>
                : !finished && loading && (
                  <>
                    <div className='mt2 flex'>
                      <CenteredDiv>
                        <Spinner spinnerstyle={{ fontSize: '8rem' }}/>
                      </CenteredDiv>
                    </div>
                    <p className='center italic f7'>This modal can be closed and reopened later to download the model.</p>
                  </>
                )}
            </div>
            {finished && !loading &&
                <div className='mt3 flex justify-between'>
                  <div
                    style={{ width: '80%' }}
                    data-testid="export-select"
                  >
                    <InputSelect
                      className='flex'
                      isMulti
                      options={fileTypeLabels}
                      defaultValue={fileTypeLabels[1]}
                      onChange={handleFileTypeChange}
                    />
                  </div>
                  <Button
                    className='flex'
                    btnType='secondary'
                    onClick={handleDownloadExport}
                    data-testid="download-export-button"
                  > Download
                  </Button>
                </div>
            }
            {failed && <p className='c-error'>Sorry, something went wrong with the export. Try to create a new variant and make a new export. If this does not work, use the report incident button in the top menu.</p>}
          </>
        )}
      />
    </Modal>
  )
}
