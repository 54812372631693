import { SearchableColor } from '../../../components/project/colors/SearchableColor'

export function sortFavoriteIds (colors: SearchableColor[], ids: string[]) {
  const favoriteColors = []
  for (let i = ids.length; i >= 0; i--) {
    const index = colors.map(color => color.id).indexOf(ids[i])
    if (index !== -1) {
      const color = colors[index]
      favoriteColors.push(color)
    }
  }
  favoriteColors.sort((color: any) => {
    return color.isPrototypeFabricColor ? 1 : -1 // `false` values first == standard colors
  })

  return favoriteColors.map((color: any) => color.id)
}

export function putMarkedColorsOnTop (colors: SearchableColor[], ids: string[]) {
  for (let i = ids.length; i >= 0; i--) {
    const index = colors.map(color => color.id).indexOf(ids[i])
    if (index !== -1) {
      const color = colors[index]
      colors.splice(index, 1)
      colors.unshift(color)
    }
  }

  return colors
}

export function putColorsOnTop (colors: SearchableColor[], names: string[]) {
  for (let i = names.length; i >= 0; i--) {
    const index = colors.map(color => color.name).indexOf(names[i])
    if (index !== -1) {
      const color = colors[index]
      colors.splice(index, 1)
      colors.unshift(color)
    }
  }
  return colors
}

export function isColorMatchingSearchString (color: SearchableColor, searchString: string) {
  const { name, ncsTitle, pantoneTitle } = color
  const rgb = color.color
  searchString = searchString.toUpperCase()

  return (
    (name && name.toUpperCase().includes(searchString)) ||
    (ncsTitle && ncsTitle.toUpperCase().includes(searchString)) ||
    (pantoneTitle && pantoneTitle.toUpperCase().includes(searchString)) ||
    (rgb && rgb.toUpperCase().includes(searchString))
  )
}
