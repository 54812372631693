import React from 'react'

import Grid from '../common/grid/grid'

const Wrapper = ({ subheader = false, children }) => (
  <Grid
    rows={subheader ? [64, 64, '1fr'] : [64, '1fr']}
    gridGap={0}
    style={{ height: '100vh' }}
  >
    {children}
  </Grid>
)

export default Wrapper
