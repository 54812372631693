import Immutable, { ImmutableObject, ImmutableArray } from 'seamless-immutable'
import _orderBy from 'lodash/orderBy'
import _get from 'lodash/get'
import { createSelector } from 'reselect'
import { createAction, handleActions } from 'redux-actions'

import type { SceneGraphNode3d } from '../../../../../go3dthree/types/SceneGraph'
import type { Command } from '../undo-redo/Command'
import CameraManager from '../../../../../go3dthree/src/scenegraph/CameraManager'
import type { ISchemaDPDEnhanced } from '../../../../../go3dthree/src/Room/RoomDrawing'
import type { Mode } from '../../../../../go3dthree/src/Room/RoomManager'
import type { Combination as TypeCombination, VisualizedCombination } from '../combinations/Combination'

import { DEFAULT_ROOMSET_ENVMAP } from '../../../constants'

import { AppThunk, RootState } from '..'
import { fetchRoomHoleModels } from '../combinations/actions/json'
import { loadCombination } from '../combinations/actions/load-combination'
import { Interaction } from '../combinations/interactions'
import { toggleKeyBoardBindings } from './ui'
import * as fromMaterials from '../materials'
import { setColor } from '../colors'
import { setPatternOnParts } from '../patterns/textures'
import * as undoRedo from '../undo-redo'
import { getNodeList } from './selectors'
import { setCurrent as setCurrentRoomset } from '../roomsets'
import { collectLights } from '../templates/lights'
import { setActive as setActiveTemplateId } from '../templates'
import { adjustWallInScene, load as loadDefaultTemplate, dispose as disposeDefaultScene } from '../templates/default-template'
import { setCameraMode } from './camera'

export const confirmDispose = createAction('threeviewer/room/DISPOSE')

type ScenePanelState = 'DEFAULT_TEMPLATE' | 'CUSTOM_HOME' | 'HOME'

const confirmMode = createAction<Mode>('threeviewer/room/SET_MODE')
const confirmView = createAction<'2D' | '3D' | null>('threeviewer/room/SET_VIEW')
const confirmWallHeight = createAction<number>('threeviewer/room/SET_WALL_HEIGHT')
const confirmWallLength = createAction<number | null>('threeviewer/room/SET_WALL_LENGTH')
const confirmActiveHoleModelId = createAction<null | string>('threeviewer/room/SET_ACTIVE_ASSET_ID')
export const setScenePanelState = createAction<ScenePanelState>('threeviewer/room/SET_UI_STATE')

export const CUSTOM_HOME_TEMPLATE_ID = 'custom-home'

const ROOM_MESH_TYPE_TO_MATERIAL_ID = {
  default: 'concrete-painted',
  wall: 'concrete-painted',
  floor: 'whiteashfloor',
  ceiling: 'concrete-painted',
  skirting: 'concrete-painted',
  wallTop: '71206cd3-d51a-4246-82ff-c3fd21b5422d'
}

const ROOM_ASSET_INTERACTIONS: {
  default: Interaction
} = {
  default: {
    userData: {
      isCombination: true,
      modelType: 'combination'
    },
    params: {
      addToNodeList: true,
      addToPicker: false,
      interactions: {
        snapTargets: { recursive: true }
      }
    }
  }
}

export const getRoomHoleModels = createSelector(
  (state: RootState) => state.combinations.entries,
  (entries) => {
    const assets = Object.values(entries)
      .filter(combination => combination)
      .filter(combination => combination.source === 'roomAsset') as unknown as ImmutableArray<VisualizedCombination>
    return _orderBy(assets, 'title')
  }
)

export function dispose (): AppThunk {
  return (dispatch, getState) => {
    const viewer = getState().threeviewer.viewer
    if (!viewer) return
    // @ts-ignore
    dispatch(undoRedo.dispose())
    dispatch(deactivate())
    dispatch(confirmDispose())
  }
}

export function clear (): AppThunk {
  return (dispatch, getState) => {
    const viewer = getState().threeviewer.viewer
    if (!viewer) return
    viewer.roomManager.clear()
    dispatch(confirmMode(viewer.roomManager.mode))
    dispatch(confirmView(viewer.roomManager.view))
    dispatch(confirmActiveHoleModelId(viewer.roomManager.activeHoleModelId))
    dispatch(confirmMode(viewer.roomManager.mode))
  }
}

export function addHoleModel (combination: ImmutableObject<VisualizedCombination> | null): AppThunk<Promise<string | undefined>> {
  return async (dispatch, getState) => {
    if (!combination) return

    const viewer = getState().threeviewer.viewer
    if (!viewer) return

    if (viewer.roomManager.holeModels.has(combination.id)) return

    const loaded = await dispatch(loadCombination({
      instanceId: combination.id,
      combination: combination as unknown as VisualizedCombination,
      isComplementary: true,
      interactions: ROOM_ASSET_INTERACTIONS
    })) as unknown as Map<string, SceneGraphNode3d>

    const scene = loaded.values().next().value
    scene.visible = false
    scene.parent.removeChild(scene)

    // TODO: Temporary hack to move traditional windows a bit forward
    const offsetZ = (combination.metadata?.style === 'traditional' && combination.metadata?.type === 'window')
      ? -0.045
      : 0

    viewer.roomManager.registerHoleModel({
      scene: scene,
      id: combination.id,
      type: combination.metadata?.type ?? 'window',
      offset: {
        x: 0,
        y: 0,
        z: combination.metadata?.offset ?? offsetZ
      }
    })

    return combination.id
  }
}

export function setActiveHoleModel (combination: ImmutableObject<VisualizedCombination> | null): AppThunk {
  return async (dispatch, getState) => {
    const viewer = getState().threeviewer.viewer
    if (!viewer) return

    if (combination === null) {
      viewer.roomManager.setActiveHoleModel(null)
      dispatch(confirmActiveHoleModelId(viewer.roomManager.activeHoleModelId))
      return
    }
    await dispatch(addHoleModel(combination))
    viewer.roomManager.mode = 'ADD_HOLE'
    viewer.roomManager.setActiveHoleModel(combination.id)
    dispatch(confirmActiveHoleModelId(viewer.roomManager.activeHoleModelId))
    dispatch(confirmMode(viewer.roomManager.mode))
  }
}

export function setMode (mode: Mode): AppThunk {
  return (dispatch, getState) => {
    const viewer = getState().threeviewer.viewer
    if (!viewer) return
    viewer.roomManager.mode = mode
    dispatch(confirmMode(mode))
  }
}

export function setupCustomHome (): AppThunk {
  return (dispatch, getState) => {
    const state = getState()
    const viewer = state.threeviewer.viewer
    if (!viewer) return

    viewer.roomManager.addListener('change', (change: {old: ISchemaDPDEnhanced, new: ISchemaDPDEnhanced }) => {
      const command: Command = {
        undo () {
          viewer.roomManager.loadSchema(change.old)
          dispatch(confirmMode(viewer.roomManager.mode))
          dispatch(confirmView(viewer.roomManager.view))
          viewer.renderOnNextFrame()
        },
        execute () {
          viewer.roomManager.loadSchema(change.new)
          dispatch(confirmMode(viewer.roomManager.mode))
          dispatch(confirmView(viewer.roomManager.view))
          viewer.renderOnNextFrame()
        }
      }

      // @ts-ignore
      dispatch(undoRedo.addCommand(command))
    })

    viewer.roomManager.addListener('changeWallLength', (wallLength: number | null) => {
      dispatch(confirmWallLength(wallLength))
    })
  }
}

function prepareSceneForCustomHome (): AppThunk {
  return async (dispatch, getState) => {
    const state = getState()
    const viewer = state.threeviewer.viewer
    if (!viewer) return

    viewer.cameraManager.change({ lockedMode: null })
    viewer.cameraManager.change({ behavior: CameraManager.BEHAVIORS.DEFAULT })
    dispatch(setCameraMode(null))

    if (state.roomsets.currentId) {
      const nodeList = getNodeList(state) as { [id: string]: SceneGraphNode3d }
      Object.values(nodeList).forEach((node) => {
        if (node && node.parent === viewer.scene && node.userData.modelType === 'roomset') {
          viewer.removeModel(node)
        }
      })
      dispatch(setCurrentRoomset(null))
    }

    viewer.scene.children.forEach((child) => {
      if (child.userData.modelType === 'template') {
        viewer.removeModel(child)
      }
    })

    await dispatch(loadDefaultTemplate(getState().threeviewer.settings.template, {}))
    dispatch(setActiveTemplateId(CUSTOM_HOME_TEMPLATE_ID))

    viewer.postProcessManager.lut.setLutTable = new viewer.THREE.TextureLoader().load('/img/lut/roomset_lut.png')
    viewer.postProcessManager.lut.enabled = true
    viewer.renderer.toneMappingExposure = 1.0

    await dispatch(fromMaterials.loadEnvMap(DEFAULT_ROOMSET_ENVMAP, '.exr'))
    const intensity = _get(state.threeviewer.settings, 'roomsetEnvMap.intensity', 1)
    dispatch(fromMaterials.setEnvMapSettings({ intensity: intensity }))

    viewer.scene.children.forEach((child) => {
      if (child.userData.defaultSceneLight) {
        viewer.scene.remove(child)
      }
    })

    dispatch(adjustWallInScene(true))
    dispatch(disposeDefaultScene())
    dispatch(collectLights())
  }
}

export function activate (): AppThunk {
  return (dispatch, getState) => {
    const state = getState()
    const viewer = state.threeviewer.viewer
    if (!viewer) return

    dispatch(prepareSceneForCustomHome())

    viewer.picker.disable()
    viewer.roomManager.activate((node: any) => !node.isLight && node.name !== 'floor')

    // @ts-ignore
    dispatch(undoRedo.dispose())

    dispatch(confirmWallHeight(viewer.roomManager.wallHeight))
    dispatch(confirmMode(viewer.roomManager.mode))
    dispatch(confirmView(viewer.roomManager.view))

    dispatch(fetchRoomHoleModels())
    dispatch(toggleKeyBoardBindings(false))
  }
}

export function deactivate (): AppThunk {
  return async (dispatch, getState) => {
    const viewer = getState().threeviewer.viewer
    if (!viewer) return
    if (!viewer.roomManager.enabled) return

    viewer.picker.enable()
    viewer.roomManager.to3D()
    viewer.roomManager.deactivate()

    dispatch(applyAppearances())
    dispatch(confirmMode(viewer.roomManager.mode))
    dispatch(confirmView(viewer.roomManager.view))
  }
}

export function to2D (): AppThunk {
  return (dispatch, getState) => {
    const viewer = getState().threeviewer.viewer
    if (!viewer) return
    viewer.roomManager.to2D()
    dispatch(confirmMode(viewer.roomManager.mode))
    dispatch(confirmView(viewer.roomManager.view))
  }
}

export function to3D (): AppThunk {
  return async (dispatch, getState) => {
    const viewer = getState().threeviewer.viewer
    if (!viewer) return
    // @ts-ignore
    dispatch(undoRedo.dispose())
    viewer.roomManager.to3D()
    const schema = viewer.roomManager.schema

    if (schema) dispatch(applyAppearances())

    dispatch(confirmMode(viewer.roomManager.mode))
    dispatch(confirmView(viewer.roomManager.view))
  }
}

export function setWallHeight (height: number): AppThunk {
  return (dispatch, getState) => {
    const viewer = getState().threeviewer.viewer
    if (!viewer) return
    viewer.roomManager.wallHeight = height
    dispatch(confirmWallHeight(viewer.roomManager.wallHeight))
  }
}

export function loadSchema (schema: TypeCombination['customHomeSchema']): AppThunk {
  return async (dispatch, getState) => {
    const state = getState()
    const viewer = state.threeviewer.viewer
    if (!viewer || !schema) return

    dispatch(prepareSceneForCustomHome())
    await dispatch(fetchRoomHoleModels())

    const holeModelCombinations = getRoomHoleModels(getState())

    if (schema.holes) {
      const modelIds = new Set<string>()
      Object.values(schema.holes).forEach(wallHoles => {
        wallHoles.forEach(hole => {
          modelIds.add(hole.modelId)
        })
      })

      const existingHoleModelCombinations: ImmutableObject<VisualizedCombination>[] = []
      modelIds.forEach(id => {
        const holeModelCombination = holeModelCombinations.find(m => m.id === id)
        if (holeModelCombination) existingHoleModelCombinations.push(holeModelCombination)
      })

      await Promise.all(existingHoleModelCombinations.map(c => {
        return dispatch(addHoleModel(c))
      }))
    }

    viewer.roomManager.loadSchema(schema)
    viewer.roomManager.to3D()
    dispatch(confirmWallHeight(viewer.roomManager.wallHeight))

    const combination = state.combinations.entries.getIn([state.combinations.currentId || ''])
    const customHomeAppearanceAssignments = combination ? combination.customHomeAppearanceAssignments : undefined
    dispatch(applyAppearances(customHomeAppearanceAssignments))

    viewer.renderOnNextFrame()
    viewer.roomManager.deactivate()
  }
}

function applyAppearances (appearanceAssignments?: ImmutableObject<TypeCombination['customHomeAppearanceAssignments']>): AppThunk {
  return async (dispatch, getState) => {
    const viewer = getState().threeviewer.viewer
    if (!viewer) return

    if (viewer.roomManager.roomObject && viewer.roomManager.hasWallNodesInDrawing()) {
      const partsByMaterialId: { [id: string]: any[] } = {}
      const partsByColorId: { [id: string]: any[] } = {}
      const partsByPatternId: { [id: string]: any[] } = {}

      viewer.roomManager.roomObject.children.forEach((child) => {
        const assignments = appearanceAssignments ? appearanceAssignments.getIn([child.userData.kvadratMeshId]) : null
        let materialId = _get(ROOM_MESH_TYPE_TO_MATERIAL_ID, child.userData.roomMeshType, 'plaster_wall')

        if (assignments) {
          materialId = assignments.materialId ?? materialId
          if (assignments.colorId) {
            partsByColorId[assignments.colorId] = (partsByColorId[assignments.colorId] || []).concat(child)
          }
          if (assignments.patternId) {
            partsByPatternId[assignments.patternId] = (partsByPatternId[assignments.patternId] || []).concat(child)
          }
        }

        partsByMaterialId[materialId] = (partsByMaterialId[materialId] || []).concat(child)
      })

      const materialIds = Object.keys(partsByMaterialId)
      await dispatch(fromMaterials.prefetchPreloadMaterials(materialIds))
      await Promise.all(Object.entries(partsByMaterialId).map(([materialId, nodes]) => {
        return dispatch(fromMaterials.setMaterial(materialId, nodes, false))
      }))

      Object.entries(partsByColorId).forEach(([colorId, parts]) => {
        dispatch(setColor({
          id: colorId,
          parts: parts,
          addToUndoHistory: false
        }))
      })

      Object.entries(partsByPatternId).forEach(([patternId, nodes]) => {
        dispatch(setPatternOnParts({
          patternId,
          parts: nodes,
          userDecalRotation: 0
        }))
      })
    }
  }
}

const initialState = Immutable<{
  mode: Mode
  view: '2D' | '3D' | null
  wallHeight: number
  wallLength: number | null
  activeHoleModelId: string | null
  scenePanelState: ScenePanelState
}>({
  scenePanelState: 'DEFAULT_TEMPLATE',
  mode: null,
  view: null,
  wallHeight: 2.5,
  wallLength: null,
  activeHoleModelId: null,
})

type State = typeof initialState

export default handleActions<State, any>({
  [setScenePanelState.toString()]: (state, action) => state.merge({ scenePanelState: action.payload }),
  [confirmActiveHoleModelId.toString()]: (state, action) => state.merge({ activeHoleModelId: action.payload }),
  [confirmMode.toString()]: (state, action) => state.merge({ mode: action.payload }),
  [confirmView.toString()]: (state, action) => state.merge({ view: action.payload }),
  [confirmWallHeight.toString()]: (state, action) => state.merge({ wallHeight: action.payload }),
  [confirmWallLength.toString()]: (state, action) => state.merge({ wallLength: action.payload }),
  [confirmDispose.toString()]: () => initialState
}, initialState)
