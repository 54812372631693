import React from 'react'

import _sample from 'lodash/sample'
import _map from 'lodash/map'

import Modal from '../../common/modal'
import FormRemove from '../../common/form/form-remove'
import InputGroup from '../../common/form/input-group'
import Label from '../../common/form/label'
import InputText from '../../common/form/input-text'

import { IKEA_COLORS, DEFAULT_CLUSTER_COLOR } from './color-constants'
import { Folder } from '../../../stores/ducks/folders/Folder'

type Color = {
  hsl: string
  name: string
}

type Props = {
  itemCount: number
  isOpen: boolean
  onClose: () => void
  onConfirm: (folderName: string, folderColor: Color, type: 'edit' | 'create') => void
  type: 'edit' | 'create'
  currentFolder: Folder | null
  folderColor?: Color
  folders: Folder[]
}

function FolderModal (props: Props) {
  if (!props.isOpen) return null

  const [folderName, setFolderName] = React.useState('')
  const [folderColor, setFolderColor] = React.useState<Color>(getUniqueFolderColor())

  function getUniqueFolderColor () {
    const usedFolderColorHsls = _map(props.folders, folder => folder.color && folder.color.hsl)

    const possibleColors = IKEA_COLORS.filter(
      color => usedFolderColorHsls && !usedFolderColorHsls.includes(color.hsl)
    )

    return _sample(possibleColors) || DEFAULT_CLUSTER_COLOR
  }

  React.useEffect(() => {
    if (!props.isOpen) return
    if (props.currentFolder && props.type === 'edit') {
      setFolderName(props.currentFolder.title)
      setFolderColor(props.currentFolder.color)
    } else {
      setFolderName('')
      setFolderColor(getUniqueFolderColor())
    }
  }, [props.type, props.currentFolder, props.isOpen])

  return (
    <Modal
      key='modal-add-folder'
      isOpen={props.isOpen}
      onRequestClose={props.onClose}
      width={600}
    >
      <FormRemove
        formTitle={props.type === 'edit' ? 'Edit folder' : 'Create folder'}
        descClassName='mb2'
        formDescription={[
          <InputGroup key='input-name'>
            <Label className='flex justify-between'>
              <span>Name</span>
            </Label>
            <InputText
              focus
              onChange={(value: string) => setFolderName(value)}
              value={folderName}
              placeholder='Untitled folder'
            />
          </InputGroup>,
          <div key='items-count-label' className='f6 py1'>{props.itemCount} items selected</div>,
          <div className='flex items-center justify-between pt3' key='color'>
            <div className='flex items-center'>
              <div
                className='circle'
                style={{ backgroundColor: `hsl(${folderColor.hsl})`, width: '2.25rem', height: '2.25rem' }}
              />
              <div data-testid='folder-color' className='f7 ml1'>Color: {folderColor.name}</div>
            </div>
            <div
              data-testid='folder-randomize-color'
              className='f7 bold pointer underline-hover'
              onClick={() => setFolderColor(getUniqueFolderColor())}
            >
              Randomize color
            </div>
          </div>
        ]}
        onConfirm={() => props.onConfirm(folderName, folderColor, props.type)}
        onCancel={props.onClose}
        buttonText={props.type === 'edit' ? 'Save' : 'Create'}
      />
    </Modal>
  )
}

FolderModal.defaultProps = {
  type: 'create'
}

export default FolderModal
