require('whatwg-fetch')

export default function wrapper (url: string, options?: any): Promise<Response> {
  options = options || {}
  options.credentials = 'same-origin'
  options.redirect = 'manual'
  return window.fetch(url, options).then(checkStatus)
}

function checkStatus (res: Response): Promise<Response> {
  return res.status === 200 ? Promise.resolve(res) : res.text().then((text) => Promise.reject(text))
}
