import React from 'react'

export default function Badge (props: React.HTMLAttributes<HTMLElement>) {
  return (
    <div
      className={`border br-2 f7 regular ${props.className}`}
      style={{ padding: '2px 4px', ...props.style }}
    >
      {props.children}
    </div>
  )
}
