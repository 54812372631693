const { cloneObjectsPropertyState } = require('./utils')
const noop = () => {}

const createSetRositionCommand = ({
  objects,
  newValuesMap,
  optionalOldValuesMap,
  afterExecute = noop,
  afterUndo = noop
}) => {
  let oldValuesMap

  if (objects.length && Object.keys(newValuesMap).length) {
    oldValuesMap = cloneObjectsPropertyState(objects, 'rotation')
  }

  if (Object.keys(optionalOldValuesMap).length) {
    oldValuesMap = optionalOldValuesMap
  }

  return {
    undo () {
      objects.forEach((object) => {
        object.rotation.copy(oldValuesMap[object.uuid])
      })

      afterUndo()
    },

    execute () {
      objects.forEach((object) => {
        object.rotation.copy(newValuesMap[object.uuid])
      })

      afterExecute()
    }
  }
}

module.exports = createSetRositionCommand
