import React from 'react'

const Spinner = (props) => (
  <div {...props} data-testid='common-spinner'>
    <div className='flex-none'>
      <i className={`block-before icon-spin1 animate-spin ${props.spinnersize || 'f1'}`} style={props.spinnerstyle}/>
    </div>
  </div>
)

export default Spinner
