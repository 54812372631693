import React from 'react'

import { FaCloudUploadAlt as IconUpload } from 'react-icons/fa'
import UploadFiles from './index'
import uploadUtils from './utils'
import DragAndDropStyled from '../common/DragAndDropStyled'

export const UploadComponent = ({ onUpload }:{onUpload:any}) => {
  return (
    <UploadFiles
      onUpload={onUpload}
      supportedFormats={uploadUtils.acceptedFileTypes.map(t => `.${t}`)}
      multiple
    >
      {({
        isDragging
      }:{isDragging:boolean}) => (
        <DragAndDropStyled
          style={{
            width: '100%',
            height: '100%',
            textAlign: 'center',
            paddingTop: '20%',
            paddingLeft: 10,
            paddingRight: 10
          }}
          isDragging={isDragging}
        >
          <div className='pointer-disabled' >
            <IconUpload size={60} />
            <p className='h5 m0'>Upload Geometry</p>
            <p className='h6 m0'><i>Drag files / Click</i></p>
          </div>
        </DragAndDropStyled>
      )}
    </UploadFiles>
  )
}
