import styled from 'styled-components'

interface Props {
  size?: number
}
const Circle = styled.div<Props>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => `${props.size || 24}px`};
  height: ${(props) => `${props.size || 24}px`};
  border-radius: 999px;
`
export default Circle
