import fetch from '../../../utils/fetch'
import client from '../storage-api-client'

export const upload = (files, onUploadProgress) => (
  client.upload(files, { onUploadProgress })
)

export const getAll = (params = {}) => (
  fetch('/api/materials', {
    method: 'POST',
    body: JSON.stringify(params)
  })
    .then((res) => res.json())
)

export const get = (id) => (
  fetch(`/api/materials/${id}`)
    .then((res) => res.json())
)

export const getManifest = (storageKey) => (
  fetch(`/api/storage/get/${storageKey}`)
    .then((res) => res.json())
)

export const save = (payload) => (
  fetch('/api/materials/create', {
    method: 'POST',
    body: JSON.stringify(payload)
  })
    .then((res) => res.json())
)

export const getAllCarriers = () => (
  fetch('/api/materials/carriers', {
    method: 'GET',
  })
    .then((res) => res.json())
)
