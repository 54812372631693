import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import autoBind from 'react-autobind'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as modalsActions from '../../stores/ducks/interface/modals'

class ModalReduxTrigger extends PureComponent {
  constructor (props) {
    super(props)
    autoBind(this)
  }

  handleTrigger (event) {
    event.stopPropagation()
    event.preventDefault()
    const { type, name, data } = this.props
    this.props.modals.open({ type, name, data })
  }

  render () {
    const { children } = this.props
    return children({ onTrigger: this.handleTrigger })
  }
}

ModalReduxTrigger.proptypes = {
  name: PropTypes.string.isRequired,
  data: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ])
}

ModalReduxTrigger.defaultProps = {
  data: {}
}

const mapStateToProps = () => ({})
const mapDispatchToProps = (dispatch) => ({ modals: bindActionCreators(modalsActions, dispatch) })

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalReduxTrigger)
