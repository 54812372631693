import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import autoBind from 'react-autobind'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createSelector } from 'reselect'

import IconCross from './icons/icon-cross'

import colors from '../../../css/colors'

import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import cs from 'classnames'

import * as modalsActions from '../../stores/ducks/interface/modals'

const style = {
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.6)',
    zIndex: 500
  },
  modal: {
    width: 500,
    padding: '2.5rem 3rem',
    boxShadow: '0 0 50px rgba(0,0,0,0.15)',
    borderRadius: 2
  }
}

const NestedModal = ({ children, isOpen, onClose, display }) => {
  if (!isOpen) return <div />

  return (
    <div
      className={cs(
        'absolute z1 top-0 right-0 left-0 bottom-0 flex justify-center items-center',
        { 'display-none': !display }
      )}
      style={style.overlay}
    >
      <div
        className='bg-white relative'
        style={style.modal}
      >
        <IconCross
          color={colors.black}
          size={'1em'}
          className='scale pointer absolute z1 mx2 my2 top-0 right-0'
          onClick={onClose}
        />
        {children}
      </div>
    </div>
  )
}

class ModalRedux extends PureComponent {
  constructor (props) {
    super(props)

    const {
      name,
      type,
      data,
      display
    } = props

    this.settings = {
      name,
      type,
      data
    }

    this.state = {
      display
    }

    autoBind(this)
  }

  open () { this.props.modals.open(this.settings) }
  close () { this.props.modals.close(this.settings) }
  show () { this.setState({ display: true }) }
  hide () { this.setState({ display: false }) }

  render () {
    const { children, isOpen, data } = this.props
    const { display } = this.state

    return (
      <NestedModal isOpen={isOpen} onClose={this.close} display={display}>
        {children({
          onClose: this.close,
          onShow: this.show,
          onHide: this.hide,
          data
        })}
      </NestedModal>
    )
  }
}

ModalRedux.proptypes = {
  display: PropTypes.bool
}

ModalRedux.defaultProps = {
  display: true
}

export default connect(
  createSelector(
    (state, props) => _get(state.interface, 'modals.open', []).find((modal) => (modal.name === props.name && modal.type === props.type)),
    (modal) => ({ isOpen: !_isEmpty(modal), ...modal })
  ),
  (dispatch) => ({ modals: bindActionCreators(modalsActions, dispatch) })
)(ModalRedux)
