import _get from 'lodash/get'

export function hideNodes (nodeList, uuids) {
  uuids.forEach((uuid) => {
    const node = nodeList[uuid]

    if (node && !node.userData.ignore) {
      node.visible = false

      if (node.geometry) {
        node.userData.changed = true
        node.userData.visible = false
      }

      node.traverse((child) => {
        if (child.geometry && !child.userData.ignore) {
          child.visible = false
          child.userData.changed = true
          child.userData.visible = false
        }
      })
    }
  })
}

export function showNodes (nodeList, uuids) {
  uuids.forEach((uuid) => {
    const node = nodeList[uuid]

    if (node && !node.userData.ignore) {
      node.visible = true

      if (node.geometry) {
        node.userData.changed = true
        node.userData.visible = true
      }
    }
  })
}

export function findChildUUIDs (nodeList, uuids) {
  function flattenChildNodes (nodes) {
    return nodes.reduce((acc, node) => {
      return node.children.length
        ? acc.concat(flattenChildNodes(node.children).concat(node.uuid))
        : acc.concat(node.uuid)
    }, [])
  }

  return uuids.reduce((acc, uuid) => {
    var node = nodeList[uuid]

    if (!node) return acc

    return node.children.length
      ? acc.concat(flattenChildNodes(node.children).concat(uuid))
      : acc.concat(uuid)
  }, [])
}

export function findOwnParentUUIDs (nodeList, uuids) {
  function findOwnParents (node) {
    const uuids = []

    while (node.children.length === 1 && node.parent) {
      uuids.push(node.uuid)
      node = node.parent
    }

    return uuids
  }

  return uuids.reduce((acc, uuid) => {
    const parentNode = _get(nodeList, [uuid, 'parent'])
    return acc.concat(parentNode && findOwnParents(parentNode))
  }, uuids)
}

export function getCombinationNodes (nodes) {
  return nodes.filter((node) => {
    const isTemplateNode = node.userData.isTemplate || node.userData.isRoomset
    const isVisible =
      typeof node.userData.visible === 'undefined' || node.userData.visible
    return !isTemplateNode && isVisible
  })
}

export function fitObjectsInScreen (viewer, nodes) {
  viewer.cameraManager.animateFitObjectsInScreen(nodes, (target) => {
    if (target) {
      viewer.cameraManager.useOrbitControls(target)
      viewer.cameraManager.centerControlsAroundObjects(nodes)
    }
  })
}
