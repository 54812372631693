import React from 'react'

interface Props {
  size?: number
  fill?: string
}

const IconPantoneColor = ({ size = 16, fill = 'none' }: Props) => {
  return (
    <svg width={size} height={size} fill={fill} version="1.1" viewBox="0 0 19.5 19.5" xmlns="http://www.w3.org/2000/svg" >
      <g transform="translate(-8,-8)" clipPath="url(#clip0_163_799)" stroke="#333" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
        <path d="m16.75 10.75v14c0 0.5304-0.2107 1.0391-0.5858 1.4142s-0.8838 0.5858-1.4142 0.5858h-4c-0.5304 0-1.0391-0.2107-1.4142-0.5858-0.37507-0.3751-0.58578-0.8838-0.58578-1.4142v-14c0-0.5304 0.21071-1.0391 0.58578-1.4142 0.37508-0.37507 0.88382-0.58578 1.4142-0.58578h4c0.5304 0 1.0391 0.21071 1.4142 0.58578 0.3751 0.37508 0.5858 0.88382 0.5858 1.4142z"/>
        <path d="m24.1 17.09-1.7 1.66-5.65 5.66v-11.32l1.66-1.66c0.3747-0.3725 0.8816-0.5816 1.41-0.5816s1.0353 0.2091 1.41 0.5816l2.83 2.83c0.1886 0.1831 0.3393 0.4015 0.4434 0.6429s0.1595 0.5009 0.1633 0.7638c0.0037 0.2628-0.0445 0.5238-0.1417 0.768s-0.2416 0.4669-0.425 0.6553z"/>
        <path d="m26.75 20.75v4c0 0.5304-0.2107 1.0391-0.5858 1.4142s-0.8838 0.5858-1.4142 0.5858h-10c0.5304 0 1.0391-0.2107 1.4142-0.5858s0.5858-0.8838 0.5858-1.4142v-0.34l5.65-5.66h2.35c0.5304 0 1.0391 0.2107 1.4142 0.5858s0.5858 0.8838 0.5858 1.4142z"/>
        <path d="m12.75 22.75v0.01"/>
      </g>
      <defs>
        <clipPath id="clip0_163_799">
          <rect transform="translate(8,8)" width="19.5" height="19.5" fill="#fff"/>
        </clipPath>
      </defs>
    </svg>
  )
}

export default IconPantoneColor
