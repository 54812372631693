import React, { PureComponent } from 'react'
import { createSelector } from 'reselect'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import autoBind from 'react-autobind'
import cs from 'classnames'

import _filter from 'lodash/filter'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _omitBy from 'lodash/omitBy'

import * as fromProjects from '../../stores/ducks/projects'

import navigate from '../../utils/navigate'

import CreateImagePackage from './create'
import CombinationList from './combination-list'
import ConversionList from './conversion-list'

import Modal from '../common/modal'
import Info from '../common/info'
import FormEdit from '../common/form/form-edit'
import Button from '../common/button'

import StandardLayout from '../layout/standard-layout'

import * as fromUsersSelectors from '../../stores/ducks/users/selectors'
import * as fromProjectsSelectors from '../../stores/ducks/projects/selectors'
import * as fromImagePackagesSelectors from '../../stores/ducks/image-packages/selectors'

class ImagePackageProject extends PureComponent {
  constructor (...args) {
    super(...args)

    this.state = {
      isModalOpen: false
    }
    autoBind(this)
  }

  componentDidMount () {
    this.props.setCurrent(this.props.projectId)
    this.props.getProject(this.props.projectId, { mergeCombinations: true, force: true })
  }

  handleOpenModal () {
    this.setState({ isModalOpen: true })
  }

  handleCloseModal () {
    this.setState({ isModalOpen: false })
  }

  handleAfterModalOpen () {
    this._editForm && this._editForm.focusFirstInput()
  }

  handleUploaded (data) {
    this.props.receiveCadKey(data, this.props.projectId)
  }

  handleEdit (data) {
    const _data = _omitBy(data, _isEmpty)

    if (_isEmpty(_data)) return

    this.props.update({
      ..._data,
      id: this.props.projectId
    })

    this.handleCloseModal()
  }

  handleNavigateDashboard () {
    navigate('/dashboard')
  }

  onLoadDefaultModel () {
    navigate(`/visualize/${this.props.projectId}/combination/`)
  }

  onLoadImagePackage (payload) {
    const { masterCombinationId } = payload
    navigate(`/visualize/${this.props.projectId}/image-package/${masterCombinationId}`)
  }

  render () {
    const {
      projectTitle,
      projectDescription,
      imagePackage,
      noProjectFound,
      emptyProject,
      isFetching
    } = this.props

    document.title = `DPD | ${projectTitle || ''}`
    const breadcrumbs = [{ id: 0, title: projectTitle }]

    if (noProjectFound || isFetching) {
      return (
        <StandardLayout breadcrumbs={breadcrumbs}>
          <div className='flex justify-center mt4'>
            <Info
              icon={cs({
                'icon-lock': noProjectFound,
                'block-before icon-spin1 animate-spin my2': isFetching
              })}
              text={isFetching
                ? 'Fetching project...'
                : 'The project does not exist, or you lack sufficent rights to access it.'
              }
            >
              {noProjectFound && (
                <div className='flex justify-center'>
                  <Button btnType='primary' onClick={this.handleNavigateDashboard}>
                    Go to Dashboard
                  </Button>
                </div>
              )}
            </Info>
          </div>
        </StandardLayout>
      )
    }

    return (
      <StandardLayout
        breadcrumbs={breadcrumbs}
      >
        <div className='xxl-col-8 p2 mx-auto'>

          <div className='p3 mb3 bg-error c-white'>
            This module will be discontinued in future releases. Please contact <a className='c-white c-white-hover underline' href='https://prototypeshop-ikea.creatics.se' target='_blank'>Virtual Prototype Shop</a> to receive assets for image package creation in default DPD projects.
          </div>

          <CreateImagePackage
            onLoadImagePackage={this.onLoadImagePackage}
            imagePackage={imagePackage}
          />

          <ConversionList
            rootClassName='mb3 mt4 clearfix mxn1'
            itemClassName='col col-6 sm-col-4 lg-col-3 xl-col-2 pb2 px1'
            emptyProject={emptyProject}
            onUploaded={this.handleUploaded}
            onLoadDefaultModel={this.onLoadDefaultModel}
          />

          <CombinationList
            rootClassName='clearfix mxn1'
            itemClassName='col col-12 py2 px1 mb3'
          />
        </div>

        <Modal
          isOpen={this.state.isModalOpen}
          onAfterOpen={this.handleAfterModalOpen}
          onRequestClose={this.handleCloseModal}
          width={600}
        >
          <FormEdit
            ref={(node) => (this._editForm = node)}
            formTitle={'Edit project'}
            title={projectTitle}
            description={projectDescription}
            onSubmit={this.handleEdit}
            onCancel={this.handleCloseModal}
          />
        </Modal>
      </StandardLayout>
    )
  }
}

const mapStateToProps = createSelector(
  fromUsersSelectors.getCurrentId,
  fromProjectsSelectors.getCurrentEntry,
  fromProjectsSelectors.getStatus,
  fromProjectsSelectors.getCombinations,
  fromImagePackagesSelectors.getEntries,
  (currentUserId, project, status, combinations, imagePackages) => {
    const isProjectAccessibleToUser = (
      _get(project, ['sharedWith', currentUserId]) ||
      _get(project, 'createdBy') === currentUserId
    )

    return {
      noProjectFound: (!isProjectAccessibleToUser || _isEmpty(project)) && (
        status === fromProjects.statuses.FAILED ||
        status === fromProjects.statuses.FETCHED
      ),
      projectTitle: project.title,
      imagePackage: _filter(imagePackages, ip => ip && ip.batches && !ip.removedAt)[0],
      projectDescription: project.description,
      isFetching: status === fromProjects.statuses.FETCHING && _isEmpty(project),
      emptyProject: combinations.length === 0
    }
  }
)

const mapDispatchToProps = (dispatch) => bindActionCreators({
  setCurrent: fromProjects.setCurrent,
  getProject: fromProjects.getProject,
  receiveCadKey: fromProjects.receiveCadKey,
  update: fromProjects.update
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImagePackageProject)
