import React from 'react'
import { AiFillStar as IconStar, AiOutlineStar as IconOutlineStar } from 'react-icons/ai'
import styled from 'styled-components'
import IconPantoneColor from '../../../common/icons/pantone-color/icon-pantone-color'
import { SearchableColor } from '../../../project/colors/SearchableColor'
import Swatch from './swatch'

type SearchableColorProps = SearchableColor & {
    onClick: () => void
}

const Color = styled(Swatch).attrs<SearchableColorProps>((props) => ({
  title: `${props.name || 'N/A'} - ${props.ncsTitle || 'N/A'} - ${props.pantoneTitle || 'N/A'}`
}))<SearchableColorProps>`
    background: ${props => props.color};
    &:hover {
      cursor: pointer;
    };
  `

const PantoneColor = styled(Color).attrs<SearchableColorProps>((props) => ({
  title: `${props.pantoneTitle || 'N/A'}`
}))``

type Props = {
  onApply: () => void
  color: any
  onFavoriteClick: () => void
  markedColorIds: string[]
}

export default function ColorStyled ({ onApply, color, onFavoriteClick, markedColorIds }: Props) {
  const [selected, setSelected] = React.useState(false)
  return (
    <Wrapper>
      { color.isPrototypeFabricColor
        ? <>
          <PantoneColor
            {...color}
            data-testid='color'
            onClick={onApply}
          />
          <div className='absolute' style={{ bottom: 0 }} title="Non-standard textile color">
            <IconPantoneColor fill="white" size={14}/>
          </div>
        </>
        : <Color
          {...color}
          data-testid='color'
          onClick={onApply}/>
      }
      <StarSelectIcon
        inColorList
        selected={!!markedColorIds.includes(color.id)}
        onClick={() => {
          setSelected(!selected)
          onFavoriteClick()
        }}
      />
    </Wrapper>
  )
}

const IconWrapper = styled.div<{
  selected: boolean
  inColorList: boolean
  }>`
    position: absolute; 
    top: ${props => props.inColorList ? '0px' : '1px'}; 
    left: ${props => props.inColorList ? '0px' : '1px'}; 
    background-color: var(--black25);
    border-radius: ${props => props.inColorList ? '9px' : '2px'};
    opacity: ${props => props.selected ? '1' : '0'};
    transition: opacity 100ms ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      cursor: pointer;
    }
`

export const Wrapper = styled.div`
  position: relative;
    &:hover ${IconWrapper}{
      opacity: 1;
    }
  `
interface StarProps {
  selected: boolean
  onClick: () => void
  inColorList?: boolean
}

export const StarSelectIcon = ({ selected, onClick, inColorList = false }: StarProps) => {
  return (
    <IconWrapper
      data-testid='favorite-colors'
      selected={selected}
      onClick={onClick}
      inColorList={inColorList}>

      {selected ? <IconStar size={18} style={{ color: 'var(--primary)' }} title='Remove favorite'/> : <IconOutlineStar size={18} style={{ color: 'var(--primary)' }} title='Add favorite'/>}
    </IconWrapper>
  )
}
