import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../common/button'
import AddEditModal from './add-edit-modal'
import ImageTemplateListItem from './ImageTemplateListItem'
import RemoveModal from './remove-modal'
import * as fromRoomsets from '../../../stores/ducks/roomsets'
import * as fromRoomsetsAdmin from '../../../stores/ducks/roomsets-admin'
import Grid from '../../common/grid/grid'
import InputSearch from '../../common/form/input-search'
import Scrollable from '../../common/scrollable'
import * as fromAdminRoomsetsSelectors from '../../../stores/ducks/roomsets-admin/selectors'

enum RoomsetModals {
  EDIT = 'edit-roomset',
  REMOVE = 'remove-roomset'
}

function AdminImageTemplates () {
  const dispatch = useDispatch()
  const imageTemplates = useSelector(fromAdminRoomsetsSelectors.selectRoomsetsImageTemplate)
  const setups = useSelector(fromAdminRoomsetsSelectors.selectSetups)
  const currentId = useSelector(fromAdminRoomsetsSelectors.selectCurrentId)
  const [activeModal, setActiveModal] = useState<RoomsetModals | null>(null)

  const [searchString, setSearchString] = React.useState('')
  const [filteredList, setFilteredList] = React.useState(imageTemplates)

  React.useEffect(() => {
    dispatch(fromRoomsets.fetchAll())
  }, [])

  React.useEffect(() => {
    const filtered = imageTemplates.filter(roomset =>
      roomset.title?.toLowerCase().includes(searchString.toLowerCase())
    )
    setFilteredList(filtered)
  }, [searchString, imageTemplates])

  return (
    <Grid rows={['auto', '1fr']}>
      <Grid
        gridGap={24}
        columns={['1fr', 'auto']}
        style={{ alignItems: 'center' }}
      >
        <InputSearch
          placeholder='Search...'
          value={searchString}
          onChange={(text: string) => setSearchString(text)}
          onClearSearch={() => setSearchString('')}
        />
        <Button
          pad={1}
          btnType='primary'
          onClick={() => setActiveModal(RoomsetModals.EDIT)}
        >
          Add new image template
        </Button>
      </Grid>

      <AddEditModal
        isOpen={activeModal === RoomsetModals.EDIT}
        onRequestClose={() => {
          setActiveModal(null)
          dispatch(fromRoomsetsAdmin.setEditRoomsetId(null))
        }}
      />

      <Scrollable>
        {filteredList.map(roomset => (
          <ImageTemplateListItem
            key={roomset.id}
            roomset={roomset}
            setups={setups[roomset.id!]}
            onEdit={() => {
              dispatch(fromRoomsetsAdmin.setEditRoomsetId(roomset.id!))
              setActiveModal(RoomsetModals.EDIT)
            }}
            onDelete={() => {
              dispatch(fromRoomsetsAdmin.setEditRoomsetId(roomset.id!))
              setActiveModal(RoomsetModals.REMOVE)
            }}
          />
        ))}
      </Scrollable>

      <RemoveModal
        isOpen={activeModal === RoomsetModals.REMOVE}
        onClose={() => {
          dispatch(fromRoomsetsAdmin.setEditRoomsetId(null))
          setActiveModal(null)
        }}
        onRemove={() => {
          dispatch(fromRoomsets.remove(currentId!))
          setActiveModal(null)
          dispatch(fromRoomsetsAdmin.setEditRoomsetId(null))
        }}
      />
    </Grid>
  )
}

export default AdminImageTemplates
