import { ImmutableArray } from 'seamless-immutable'

import _get from 'lodash/get'
import _hasIn from 'lodash/hasIn'
import _intersection from 'lodash/intersection'

import { SceneGraphNode3d as ISceneGraphNode3d, SceneGraphMesh as ISceneGraphMesh } from '../../../../../go3dthree/types/SceneGraph'
import { ITreeNode, ITreeNodes, FlattenedNodes, FlattenedNode } from './TreeNode'

export const overlaps = (array1: any[], array2: any[]) => {
  return array1.some(element => array2.includes(element))
}

export function findLowestGroup (obj: ISceneGraphNode3d) {
  let tmp = obj
  let lowestGroup = null

  while (tmp.parent && tmp.parent.parent) {
    tmp = tmp.parent
    if (_get(tmp, 'userData.isGroup')) {
      lowestGroup = tmp
    }
  }

  return lowestGroup
}

export function findLowestCommonAncestor (nodes: Set<ISceneGraphNode3d | ISceneGraphMesh>): ISceneGraphNode3d | ISceneGraphMesh | null {
  const allAncestors: (ISceneGraphNode3d | ISceneGraphMesh)[][] = []
  const nodesWithAncestorsCount: [(ISceneGraphNode3d | ISceneGraphMesh), number][] = []

  nodes.forEach(node => {
    if (node.parent.parent === null || node.parent.userData.isGroup) {
      const ancestors: (ISceneGraphNode3d | ISceneGraphMesh)[] = []
      let countAncestors = 0
      node.traverseAncestors((ancestor: ISceneGraphNode3d | ISceneGraphMesh) => {
        if (ancestor !== node) {
          countAncestors++
          ancestors.push(ancestor)
        }
      })
      nodesWithAncestorsCount.push([node, countAncestors])
      allAncestors.push(ancestors)
    }
  })

  const commonAncestors = _intersection(...allAncestors)

  const findCommon = (node: ISceneGraphNode3d | ISceneGraphMesh) => {
    let tmp = node
    let found: ISceneGraphNode3d | ISceneGraphMesh | null = null

    while (tmp.parent) {
      tmp = tmp.parent
      if (commonAncestors.includes(tmp)) {
        found = tmp
        break
      }
    }

    return found
  }

  let lca: ISceneGraphNode3d | ISceneGraphMesh | null = null
  let leastCountAncestors: number | null = null

  nodesWithAncestorsCount.forEach(([node, countAncestors]) => {
    if (leastCountAncestors === null || countAncestors < leastCountAncestors) {
      lca = findCommon(node)
      if (lca) leastCountAncestors = countAncestors
    }
  })

  return lca
}

export function buildNodeTree (node: ISceneGraphNode3d) {
  let tree = toEntry(node)
  let children = node.children
  let canHide = true

  if (children.length && children[0].name === 'scene_root') {
    const topNode = node.children[0] as ISceneGraphNode3d
    canHide = canHide ? !(node.userData.modelSource === 'ugabank') : canHide
    tree = toEntry(topNode, canHide)
    children = topNode.children
  }

  children.forEach((child: ISceneGraphNode3d) => {
    canHide = canHide ? !(node.userData.modelSource === 'ugabank') : canHide
    addEntry(child, tree.nodes, canHide)
  })

  function isCadFeature (object: ISceneGraphNode3d) {
    return _hasIn(object, 'userData.feature')
  }

  function addEntry (object: ISceneGraphNode3d, nodes: ITreeNode['nodes'], canHide = true) {
    const ignore = isCadFeature(object) || object.userData.ignore
    if (ignore) return

    canHide = canHide ? !(node.userData.modelSource === 'ugabank') : canHide
    var newEntry = toEntry(object, canHide)
    nodes[object.uuid] = newEntry

    object.children.forEach((child: ISceneGraphNode3d) => {
      if (child.name || child.userData.name) {
        canHide = canHide ? !(node.userData.modelSource === 'ugabank') : canHide
        addEntry(child, newEntry.nodes, canHide)
      }
    })
  }

  function getNodeName (node: ISceneGraphNode3d) {
    let name = ''
    if (_get(node, 'parent.parent', null) === null || (node.children && node.children.length === 0)) {
      name = node.userData.name
    } else {
      name = node.userData.ptc_wm_name || node.userData.name || node.name
    }
    return name
  }

  function toEntry (node: ISceneGraphNode3d, canHide = true) {
    const isModelRoot = _get(node, 'userData.isModelRoot', false)
    const isGroup = _get(node, 'userData.isGroup', false)
    let type: ITreeNode['metaData']['type'] = 'part'
    if (isGroup) type = 'group'
    if (isModelRoot) type = 'model'
    if (node.isMesh) type = 'mesh'
    if (node.userData.override) type = 'splitMesh'
    if (node.userData.isGroupRoot && node.userData.combinationType === 'variant') type = 'variant'
    // TODO: find out how to find rootGroup of a variant.
    if (node.userData.isVirtualProductRoot) type = 'virtual-product'

    const name = getNodeName(node)
    const canEdit = type === 'group'
    canHide = isModelRoot ? true : canHide

    let modelSource = node.userData.modelSource as undefined | string
    if (!modelSource || !['modelbank', 'ugabank', 'non-cad'].includes(modelSource)) {
      modelSource = 'uploadedGeometry'
    }

    const canSplit = getCanSplit(type, modelSource, node)

    return {
      uuid: node.uuid,
      modelSource,
      nodes: {},
      leafNodeCount: getLeafNodeCount(node),
      parent: _get(node, 'parent.uuid'),
      metaData: {
        canSplit,
        canRemove: (['group', 'variant', 'virtual-product'].includes(type) || isModelRoot) && _get(node, 'userData.canRemove', true), // can only remove model roots and groups
        canHide,
        canEdit,
        name: name,
        isLeaf: node.isMesh,
        type,
        generated: node.userData.generated ?? false,
        locked: type === 'virtual-product',
      }
    } as ITreeNode
  }

  return tree
}

function getCanSplit (type: string, modelSource: string, node: ISceneGraphNode3d) {
  return (
    ['mesh', 'splitMesh'].includes(type) &&
    modelSource === 'uploadedGeometry' &&
    node.userData.productType === 'hard'
  )
}

export function transformForTree (node: ISceneGraphNode3d) {
  return {
    [node.uuid]: buildNodeTree(node)
  }
}

function getLeafNodeCount (object: ISceneGraphNode3d) {
  let count = 0

  object.traverse((child: ISceneGraphMesh) => {
    if (child.isMesh) { count++ }
  })

  return count
}

export function findPath (uuid: string, nodes: { [key: string]: any }): string[] {
  const _find = (uuid: string, nodes: { [key: string]: any }, path: string[] = []) => {
    if (uuid in nodes) {
      return [...path, uuid]
    }

    for (const key in nodes) {
      const node = nodes[key]
      if (uuid in node.nodes) {
        return [...path, node.uuid, uuid]
      }
      const found = _find(uuid, node.nodes, [...path, node.uuid]) as string[] | null
      if (found) return found
    }
    return null
  }

  const path = _find(uuid, nodes)

  return (path || []).reduce((acc: string[], uuid: string, index: number) => {
    if (index === 0) return [uuid]
    return [...acc, 'nodes', uuid]
  }, [])
}

export const findNodeUuidToScrollTo = (node: FlattenedNode, flatNodes: FlattenedNodes, opened: ImmutableArray<string>) => {
  let parent = flatNodes[node.parent]
  if (!parent) return null
  while (
    flatNodes[parent.parent] &&
    (!opened.includes(parent.parent) || flatNodes[parent.parent].leafNodeCount === 1)
  ) {
    parent = flatNodes[parent.parent]
  }
  return parent.uuid
}

// Required to find variant root
// viewerUtils.findRootNode returns the node one step below the variant group root node,
// causing issues when replacing models with variants, and vice versa.
// Using this function resolves the issue.
export const getTrueRoot = (node: ISceneGraphNode3d): ISceneGraphNode3d => {
  while (
    node.userData.combinationType === 'variant' &&
      node.parent &&
      node.parent?.userData?.combinationType === 'variant' &&
      node.parent?.userData?.isGroup
  ) {
    node = node.parent
  }

  return node
}

export const getTrueRoots = (nodes: ISceneGraphNode3d[]) => {
  const roots = nodes.map(node => {
    return getTrueRoot(node)
  })

  // Exclude nodes that already have parents in list
  return roots.filter(
    root => !roots.includes(root.parent)
  )
}

// Tools for flattening the tree

/**
 * Recursively flattens the node children and places them in a list
 * NOTE: Could be optimized by flattening breadth first and just copying child uuids up
 *
 * @param nodes The nodes to flatten all childrens for
 */
function flattenChildren (nodes: ITreeNodes): string[] {
  return Object.values(nodes).reduce((acc: string[], node: ITreeNode) => {
    return [...acc, node.uuid, ...flattenChildren(node.nodes)]
  }, [])
}

/**
 * Recursively create a flat list of all nodes in the tree hierarchy. Each node contains a list of ancestors and children.
 *
 * This is primarily used to get the ancestors and/or children for a specific node without having to parse the tree state.
 *
 * @param nodes Nodes in tree hierarchy from the tree state.
 * @param sceneNodes All nodes in the scene on a flat hierarchy
 * @param ancestors Array with id of ancestors for the node
 */
export function flattenNodes (nodes: ITreeNodes, sceneNodes: { [key: string]: { userData: { materialId?: string, setMaterial?: boolean, visible?: boolean, carrier_id?: string }} }, ancestors: string[] = []): FlattenedNodes {
  return Object.values(nodes).reduce((acc, node: ITreeNode) => {
    const sceneNode = sceneNodes[node.uuid]
    if (!sceneNode) return acc
    return {
      ...acc,
      [node.uuid]: {
        uuid: node.uuid,
        name: node.metaData.name,
        modelSource: node.modelSource,
        canSetMaterial: Boolean(sceneNode.userData.setMaterial),
        hasMaterial: Boolean(sceneNode.userData.materialId),
        hasCarrier: Boolean(sceneNode.userData.carrier_id),
        type: node.metaData.type,
        leafNodeCount: node.leafNodeCount,
        parent: node.parent,
        hidden: sceneNode.userData?.visible,
        ancestors,
        children: flattenChildren(node.nodes),
        canRemove: node.metaData.canRemove
      },
      ...flattenNodes(node.nodes ?? {}, sceneNodes, [...ancestors, node.uuid])
    }
  }, {})
}

/**
 * Show material icon if the leaf has an assigned material
 * If the node is not a leaf show the icon if all child leafs have assigned materials
 *
 * @param uuid A node
 * @param flatNodes All flattened nodes
 */
export function shouldShowMaterialIcon (uuid: string, flatNodes: FlattenedNodes): boolean {
  const flatNode = flatNodes[uuid]
  if (!flatNode) return false

  if (!flatNode.children.length) {
    return flatNode.hasMaterial
  } else {
    return flatNode.children.every(
      uuid => flatNodes[uuid].children.length > 0 || flatNodes[uuid].hasMaterial
    )
  }
}

/**
 * Check if any leaf is missing a carrier assignment
 * If the child is not a leaf, aka has children call this function again
 *
 * @param children List of UUIDs for children
 * @param flatNodes All flattened nodes
 */
function hasAllLeafsCarriers (children: string[], flatNodes: FlattenedNodes): boolean {
  return children.every(uuid => {
    const childNode = flatNodes[uuid]
    return childNode.children.length > 0 ||
      (childNode.hasCarrier || flatNodes[childNode.parent]?.hasCarrier)
  })
}

/**
 * Show carrier icon if the leaf has an assigned carrier
 * If the node is not a leaf show the icon if all child leafs have assigned carriers
 *
 * @param uuid A node
 * @param isPcaActive If PCA is active
 * @param flatNodes All flattened nodes
 */
export function shouldShowCarrierIcon (uuid: string, isPcaActive: boolean, flatNodes: FlattenedNodes): boolean {
  if (!isPcaActive) return false

  const flatNode = flatNodes[uuid]
  if (!flatNode) return false

  if (!flatNode.children.length) {
    return flatNode.hasCarrier || flatNodes[flatNode.parent]?.hasCarrier
  } else {
    return hasAllLeafsCarriers(flatNode.children, flatNodes)
  }
}
