import Immutable from 'seamless-immutable'
import { createAction, handleActions } from 'redux-actions'
import { AppThunk } from '..'
import * as api from './api'

const receive = createAction('threeviewer/settings/RECEIVE')

type TemplateModel = {
  material: {
    id: string
  }
  modelInteractions: {
    addToNodeList: boolean
    addToPicker: boolean
    interactions: {
      [interaction: string]: any
    }
  }
  type: string
  url: string
  userData: {
    [property: string]: any
  }
}

export type Settings = {
  bloom?: {
    radius: number
    strength: number
    threshold: number
  }
  cameraSettings?: {
    position: [number, number, number]
    targetPosition: [number, number, number]
  }
  envMap?: { intensity: number }
  features?: { 'product-picture-templates': boolean, [feature: string]: boolean }
  lights?: {
    [name: string]: {
      color: number
      intensity: number
      position: [number, number, number]
      targetPosition: [number, number, number]
    }
  }
  postProcess?: {
    bloom: boolean
    fxAA: boolean
    outline: boolean
    overlay: boolean
    ssao: boolean
  }
  roomsetEnvMap?: { intensity: number }
  shadowMap?: {
    bias: number
    camera: {
      bottom: number
      far: number
      left: number
      right: number
      top: number
    }
    mapSize: {
      height: number
      width: number
    }
  }
  ssao?: {
    aoClamp: boolean
    lumInfluence: boolean
  }
  template?: {
    cubeCameraPosition: [number, number, number]
    displaySRGB: number
    floor: TemplateModel
    floorBox: TemplateModel
    lutPath: string
    originalBB: {
      max: { x: number, z: number }
      min: { x: number, z: number }
    }
    skirting: TemplateModel
    wall: TemplateModel
  }
  toneMapping?: {
    toneMapping: boolean
    toneMappingExposure: boolean
  }
  vray?: { version: string }
}

export const getSettings = (): AppThunk => (dispatch) => {
  return api.getSettings()
    .then((json: Settings) => {
      dispatch(receive(json))
      return json
    })
}

const initialState = Immutable<Settings>({})

type State = typeof initialState

export default handleActions<State, any>({
  [receive.toString()]: (state, action) => state.merge(action.payload)
}, initialState)
