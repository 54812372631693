import fetch from '../../../utils/fetch'
import * as serialize from '../../../utils/serialize-form'

export const acceptGDPR = formData => {
  return fetch('/api/users/update', {
    method: 'POST',
    body: serialize(formData, ['hasAcceptedGDPR', 'id'])
  }).then(res => res.text())
}

export const getCurrent = token =>
  fetch('/api/users/current' + (token ? `/?token=${token}` : '')).then(res =>
    res.json()
  )

export const logout = () =>
  fetch('/api/users/logout', { method: 'POST' }).then(res => res.text())

export const getByIds = ids =>
  fetch('/api/users/getByIds', {
    method: 'POST',
    body: JSON.stringify({ ids })
  }).then(res => res.json())

export const getAllActive = () =>
  fetch('/api/users/allActive').then(res => res.json())

// Used in admin view, but permission not required
export const getAll = () => fetch('/api/users/all').then(res => res.json())

export const getAllIncludingDeleted = () =>
  fetch('/api/users/allIncludingDeleted').then(res => res.json())

export const updateUserNewsStatus = (id, { read, optOut }) =>
  fetch('/api/users/current/news', {
    method: 'POST',
    body: JSON.stringify({
      newsId: id,
      read,
      optOut
    })
  }).then(res => res.json())

export const getToken = () => {
  fetch('/api/users/get-token', {
    method: 'GET',
  }).then(res => res)
}
