import fetch from '../../../utils/fetch'

export const getMaterials = () => (
  fetch('/api/materials/admin-created')
    .then((res) => res.json())
)

export const deleteMaterial = (id) => (
  fetch('/api/materials/admin-created/delete', {
    method: 'POST',
    body: JSON.stringify(id)
  })
    .then((res) => res.text())
)

export const updateMaterial = (payload) => (
  fetch('/api/materials/admin-created/update', {
    method: 'POST',
    body: JSON.stringify(payload)
  })
    .then((res) => res.text())
)

export const getMaterialJobs = () => (
  fetch('/api/materials/admin-created/jobs')
    .then((res) => res.json())
)
