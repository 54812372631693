import React, { useState } from 'react'
import cs from 'classnames'

import _map from 'lodash/map'
import _forEach from 'lodash/forEach'
import _includes from 'lodash/includes'

import IconFolders from '../../common/icons/icon-folder'
import { MdDelete as IconRemove, MdPlaylistAdd as IconAddToList, MdLock as IconLocked } from 'react-icons/md'
import type { Folder as _Folder } from '../../../stores/ducks/folders/Folder'

type NewFolderButtonProps = {
  onAdd: () => void
  onDrop: () => void
  isDragging: boolean
}

const NewFolderButton = (props: NewFolderButtonProps) => {
  const [isDraggedOver, setIsDraggedOver] = useState(false)
  const [isHovered, setIsHovered] = useState(false)

  return (
    <div
      className={cs('mr2 my2 p1 pb2 br-small pointer border-dashed bc-gray bw-2',
        {
          'bg-white': !isDraggedOver && !isHovered,
          'border-dashed': props.isDragging,
          'border-dashed bc-black bg-gray-light': isDraggedOver,
          'bc-black bg-gray-light': isHovered
        }
      )}
      onClick={props.onAdd}
      onDrop={(event) => { event.preventDefault(); setIsDraggedOver(false); props.onDrop() }}
      onDragOver={(event) => { event.preventDefault(); setIsDraggedOver(true) }}
      onDragLeave={(event) => { event.preventDefault(); setIsDraggedOver(false) }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      data-testid='new-folder-button'
    >
      <div style={{ pointerEvents: 'none' }} className='flex items-center'>
        <IconAddToList size={24} />
        <div className='ml1 bold f7'>New folder</div>
      </div>
      <div style={{ pointerEvents: 'none' }} className='italic f7 ml3'>Drag items / Click</div>
    </div>
  )
}

const getBackgroundColor = (isDragging: boolean, isDraggedOver: boolean, isHovered: boolean, color: string) => {
  if (isDraggedOver) {
    return `hsla(${color})`
  } else if (isDragging) {
    return `hsla(${color + ',0.5'})`
  } else if (isHovered) {
    return `hsla(${color + ',0.8'})`
  } else {
    return `hsl(${color})`
  }
}

type FolderProps = {
  id: string
  showRemoveVersion: boolean
  title: string
  selected: boolean
  itemCount: number
  color: string
  onClick: () => void
  onRemove: () => void
  onDrop: (id: string, showRemoveVersion: boolean) => void
  isDragging: boolean
  locked: boolean
}

const Folder = (props: FolderProps) => {
  const [isDraggedOver, setIsDraggedOver] = useState(false)
  const [isHovered, setIsHovered] = useState(false)

  return (
    <div
      data-testid='folder-item'
      className={cs('p1 mb1 flex items-center justify-between',
        'br-small pointer bw-2 opacity-hover-trigger', {
          'bc-secondary': props.selected,
          'bc-transparent': !props.selected,
          'border-dashed bc-black': !props.locked && isDraggedOver && props.isDragging,
          'border-dashed bc-gray-accessible': !props.locked && props.isDragging,
          border: !isDraggedOver || !props.isDragging
        })}
      style={{
        backgroundColor: getBackgroundColor(props.isDragging, isDraggedOver, isHovered, props.color)
      }}
      onClick={props.onClick}
      onDrop={(event) => {
        event.preventDefault()
        setIsDraggedOver(false)
        !props.locked && props.onDrop(props.id, props.showRemoveVersion)
      }}
      onDragOver={(event) => { event.preventDefault(); setIsDraggedOver(true) }}
      onDragLeave={(event) => { event.preventDefault(); setIsDraggedOver(false) }}
      onMouseEnter={() => { setIsHovered(true) }}
      onMouseLeave={() => { setIsHovered(false) }}
    >
      <div style={{ pointerEvents: 'none' }}>
        <div style={{ width: 128 }} className='truncate'>
          {props.locked && (<IconLocked style={{ marginLeft: -3, marginBottom: 4, marginRight: 4 }} />)}{props.title}
        </div>
        {props.locked && (
          <div className='f7 c-accessible mt1'>{props.itemCount} items</div>
        )}

        {!props.locked && (
          <>
            {(!props.showRemoveVersion || !props.isDragging) && <div className='f7 c-accessible mt1'>{props.itemCount} items</div>}
            {(props.showRemoveVersion && props.isDragging) && (
              <div className='f7 c-accessible mt1 bold flex justify-center items-center'>
                <IconRemove />
                <div className='ml1'>Remove items</div>
              </div>
            )}
          </>
        )}
      </div>
      {!props.locked && (
        <div
          className='opacity-hover-triggee'
          data-testid='icon-remove-folder'
          onClick={(event) => { event.stopPropagation(); props.onRemove() }}
        >
          <IconRemove size={24} />
        </div>
      )}
    </div>
  )
}

const getShowRemoveVersion = (selectedIds: string[], folder: _Folder) => {
  let showRemoveVersion = true

  _forEach(selectedIds, cardId => {
    if (!_includes(Object.keys(folder.combinations), cardId) && !_includes(Object.keys(folder.patterns), cardId)) {
      showRemoveVersion = false
    }
  })

  return showRemoveVersion
}

type FolderListProps = {
  folders: _Folder[]
  selectedId: string
  selectedIds: string[]
  onAdd: () => void
  onOpen: (folder: _Folder) => void
  onRemove: (id: string) => void
  onDrop: (id: string, showRemoveVersion: boolean) => void
  onDropNewFolder: () => void
  isDragging: boolean
}

const FolderList = (props: FolderListProps) => {
  return (
    <div className='pl2 pt3 border-left bc-gray-light height-100 width-100 flex flex-column'>
      <div className='flex mb1'>
        <IconFolders size={24} />
        <p className='inline my0 ml1'>Folders</p>
      </div>

      <NewFolderButton
        onAdd={props.onAdd}
        onDrop={props.onDropNewFolder}
        isDragging={props.isDragging}
      />

      <div className='overflow-y-overlay pr2' style={{ flexGrow: 1 }} data-testid='folder-list'>
        {_map(props.folders, folder => (
          <Folder
            key={folder.id}
            id={folder.id}
            title={folder.title}
            color={folder.color && folder.color.hsl}
            locked={folder.locked || false}
            onClick={() => props.onOpen(folder)}
            onRemove={() => props.onRemove(folder.id)}
            onDrop={props.onDrop}
            isDragging={props.isDragging}
            selected={props.selectedId === folder.id}
            showRemoveVersion={getShowRemoveVersion(props.selectedIds, folder)}
            itemCount={Object.keys(folder.combinations).length + Object.keys(folder.patterns || {}).length}
          />
        ))}
      </div>
    </div>
  )
}

export default FolderList
