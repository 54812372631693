import React from 'react'

import IconArrow from './icon-arrow-up'

const IconArrowDown = props => (
  <IconArrow
    transform='scale(1, -1)'
    {...props}
  />
)

export default IconArrowDown
