import React from 'react'

function useImageLoaded (
  src: string
) {
  const [loaded, setLoaded] = React.useState(false)
  React.useEffect(() => {
    const image = new window.Image()
    image.src = src
    const onLoad = () => setLoaded(true)
    image.addEventListener('load', onLoad)
    return () => {
      image.removeEventListener('load', onLoad)
    }
  }, [])
  return loaded
}

export default useImageLoaded
