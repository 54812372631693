import _get from 'lodash/get'

import store from '../../stores'

import * as fromProjects from '../../stores/ducks/projects'
import * as fromUploads from '../../stores/ducks/uploads'

import { isAccessibleToUser } from '../utils'

import show from '../show-notification'

export default function handleConverts (msg) {
  const { projectId, doc, project, status } = msg

  if (status === 'deleted') {
    store.dispatch(fromUploads.confirmRemove(doc))
    return
  }

  store.dispatch(fromProjects.receive([project]))
  store.dispatch(fromUploads.receive([doc]))

  if (
    !msg.uniqueDevice ||
    !/(Completed|Failed)/.test(doc.deadlineStatus) ||
    !isAccessibleToUser(doc, project.sharedWith)
  ) {
    return
  }

  let level = ''
  let statusMsg = ''

  if (doc.deadlineStatus === 'Completed') {
    statusMsg = 'was successfully converted'
    level = 'success'
  }

  if (doc.deadlineStatus === 'Failed') {
    statusMsg = 'has failed'
    level = 'error'
  }

  show({
    title: `Model convert for project ${_get(msg, 'project.title', '')}`,
    body: `${msg.model} submitted by ${_get(msg, 'user.email', '')} ${statusMsg}.`,
    navigateTo: projectId && `/project/${projectId}/show`,
    level: level
  })
}
