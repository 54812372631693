import fetch from '../../../utils/fetch'

const assertError = (msg: string) => (err: Error) => {
  console.error(msg)
  console.error(err)
  throw err
}

export const remove = (ids: string[]) => (
  fetch('/api/renders/remove', {
    method: 'POST',
    body: JSON.stringify({ ids })
  })
    .then((res) => res.json())
    .catch(assertError(`unable to remove renders ${ids}`))
)

export const retry = (ids: string[]) => (
  fetch('/api/renders/retry', {
    method: 'POST',
    body: JSON.stringify({ ids })
  })
    .then((res) => (res.json()))
    .catch(assertError(`unable to retry renders ${ids.join(', ')}`))
)

export const getByCombinationId = (combinationId: string) => (
  fetch(`/api/renders/getByCombinationId/${combinationId}`)
    .then((res) => (res.json()))
    .catch(assertError(`unable to get renders for combination ${combinationId}`))
)

export const getByProject = (projectId: string) => (
  fetch(`/api/renders/getByProject/${projectId}`)
    .then((res) => res.json())
    .catch(assertError(`unable to fetch renders for project ${projectId}`))
)

// TODO when backend is done
export const shareImage = (payload:any) => (
  { code: 200, comment: 'shareImage', id: payload.id }
)
