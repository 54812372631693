import React from 'react'
import { createSelector } from 'reselect'
import { connect } from 'react-redux'

import _map from 'lodash/map'
import _get from 'lodash/get'
import _groupBy from 'lodash/groupBy'
import _sortBy from 'lodash/sortBy'
import _omit from 'lodash/omit'
import _uniqWith from 'lodash/uniqWith'

import * as fromCombinationsSelectors from '../../stores/ducks/combinations/selectors'
import * as fromThreeviewerFilesSelectors from '../../stores/ducks/threeviewer/files/selectors'
import * as fromThreeviewerFiles from '../../stores/ducks/threeviewer/files'

import ProgressBar from '../common/progress-bar'
import ErrorInfo from './error-info'

const { STATUS } = fromThreeviewerFiles

const TIMEOUT = 10000

const styles = {
  wrapper: {
    width: 400,
    top: '45%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }
}

const LoadingProgress = ({
  title,
  percentage,
  hasErrors
}) => (
  <div>
    <h4 className='h5 mt2 mb1'>Loading {title}</h4>
    <div className='mb1 clearfix'>
      <label className='truncate col col-4 truncate' title={title}>
        Progress
      </label>
      <div className='col col-8' title={`${percentage}%`}>
        <ProgressBar
          pad={0}
          percentage={percentage}
          type={hasErrors ? 'error' : 'warning'}
        />
      </div>
    </div>
  </div>
)

function FileProgress (props) {
  const [canExit, setCanExit] = React.useState(false)
  const [_timeout, _setTimeout] = React.useState(null)

  // Remember loading status of all processes. This avoids flickering
  const [fullLoadingProgress, setFullLoadingProgress] = React.useState([])

  React.useEffect(() => {
    // Merge the current loading progress with the stored one, and sort by type
    setFullLoadingProgress(_sortBy(
      _uniqWith([...props.loadingProgressByType, ...fullLoadingProgress], (a, b) => a.type === b.type),
      ['type']
    ))
  }, [props.loadingProgressByType])

  React.useEffect(() => {
    if (props.isLoading) {
      _setTimeout(setTimeout(() => {
        if (props.hasErrors) {
          setCanExit(true)
        }
      }, TIMEOUT))
    } else {
      setFullLoadingProgress([])
    }
  }, [props.isLoading])

  React.useEffect(() => {
    return () => {
      props.dispose()
      _timeout && clearTimeout(_timeout)
    }
  }, [])

  if (!props.hasProgressErrors && !props.isLoading) return null

  return (
    <div
      className='absolute p2 f6 bg-white'
      style={styles.wrapper}
    >
      {canExit && (
        <i
          className='scale pointer icon-cancel absolute z1 px1 py1 top-0 right-0'
          onClick={() => setCanExit(false)}
        />
      )}
      {props.hasErrors
        ? (
          <ErrorInfo
            sceneError={props.sceneError}
            errorFileCount={props.errorFileCount}
            combinationId={props.combinationId}
            goBack={props.goBack}
            continue={() => {
              props.dispose() // Dispose files to clear errors
            }}
          />
        ) : <>
          <h2 className='mt0'>Loading assets</h2>

          <div className='mt2' />
          <ProgressBar
            pad={0}
            animated
            hideLabel
            percentage={100}
            type={props.hasErrors ? 'error' : 'info'}
          />
          {_map(fullLoadingProgress, (item) => (
            <LoadingProgress
              key={item.type}
              {...item}
            />
          ))}
        </>
      }
    </div>
  )
}

const getTitleForType = (type) => _get({
  roomset: 'Look',
  model: 'Models',
  material: 'Textures',
  envmap: 'Environment',
  combination: 'Combinations',
  'default-template': 'Default Template'
}, type, type)

const getLoadingProgressByType = createSelector(
  fromThreeviewerFilesSelectors.getEntries,
  (entries) => {
    let errorFileCount = 0
    const loadingProgressByType = Object.entries(_groupBy(entries, 'type'))
      .filter(([type]) => type !== 'combination')
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .filter(([_, files]) => !files.every(f => f.status === STATUS.FINISHED))
      .map(([type, files]) => {
        const count = files.length
        const progress = files.reduce((sum, file) => (sum + file.progress), 0)
        errorFileCount += files.reduce((sum, file) => (sum + (_get(file, 'error.message') ? 1 : 0)), 0)
        const total = Math.floor(progress / count)

        const errors = _omit(_groupBy(files, file => _get(file, 'error.message')), undefined)

        return {
          type,
          title: getTitleForType(type),
          percentage: total,
          errors,
          count,
          hasErrors: !!Object.keys(errors).length
        }
      })

    return {
      loadingProgressByType,
      errorFileCount
    }
  }
)

const mapStateToProps = createSelector(
  getLoadingProgressByType,
  state => state.scenes.error,
  fromThreeviewerFilesSelectors.getIsLoading,
  fromCombinationsSelectors.getCurrentId,
  (progressByType, sceneError, isLoading, combinationId) => {
    const { loadingProgressByType, errorFileCount } = progressByType
    const hasProgressErrors = loadingProgressByType.some(t => t.hasErrors)

    return {
      combinationId,
      sceneError,
      hasProgressErrors,
      hasErrors: sceneError || hasProgressErrors,
      isLoading: isLoading,
      loadingProgressByType,
      errorFileCount
    }
  }
)

const mapDispatchToProps = (dispatch) => ({
  dispose: () => dispatch(fromThreeviewerFiles.dispose()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FileProgress)
