import React from 'react'
import _map from 'lodash/map'
import _filter from 'lodash/filter'
import _upperFirst from 'lodash/upperFirst'

import colors from '../../../../css/colors'
import ProgressBar from '../../../components/common/progress-bar'

const getStatusColor = (done, failed) => {
  if (done) {
    return colors.secondary
  }
  if (failed) {
    return colors.error
  }
  return colors.primary
}

class DeadlineProgress extends React.PureComponent {
  renderMultiJobProgress (job) {
    if (!job.deadlineJobs) return
    const total = _map(job.deadlineJobs).length
    const completed = _filter(job.deadlineJobs, { status: 'Completed' }).length
    const failed = _filter(job.deadlineJobs, { status: 'Failed' }).length
    const percentage = Math.trunc(completed / total * 100)

    return (
      <div className='ml3 flex flex-auto line-height'>
        <div className='mr1 width-50'>
          <ProgressBar percentage={percentage} pad={0} />
        </div>
        <div className='mr1'>{`${completed} / ${total} completed`}</div>
        {!!failed && (<div className='mr1 bold c-error'>{`${failed} failed`}</div>)}
      </div>
    )
  }

  render () {
    const {
      jobStatuses
    } = this.props

    const shouldRenderJobs = jobStatuses && jobStatuses.length
    if (!shouldRenderJobs) {
      return (
        <div className='f7'>No conversion started yet</div>
      )
    }

    const completedJobs = _filter(jobStatuses, (job) => job.status === 'Completed')
    const failedJobs = _filter(jobStatuses, (job) => job.status === 'Failed').length > 0
    const overallPercentage = Math.min(100, completedJobs.length * 25)
    const overallStatusColor = getStatusColor(overallPercentage >= 100, failedJobs)

    return (
      <div>
        <div className={'f7 flex flex-column flex-auto mb1'}>
          {_map(jobStatuses, (job) => (
            <div key={job.id}>
              <div className='flex justify-between line-height'>
                <div>
                  <span className='mr1'>{`${job.type}`}</span>
                  <span className='c-gray'>{`${job.id}`}</span>
                </div>
                <div className='italic'>{_upperFirst(job.status.toLowerCase())}</div>
              </div>
              {this.renderMultiJobProgress(job)}
            </div>
          ))}
        </div>

        <div className='flex f7 line-height'>
          <div className='mr1 bold'>Overall status:</div>
          <div className='mr1 width-50'>
            <ProgressBar
              pad={0}
              percentage={overallPercentage}
              background={overallStatusColor}
            />
          </div>
          {!!failedJobs && (<div className='bold c-error'>Failed</div>)}
        </div>
      </div>
    )
  }
}

export default DeadlineProgress
