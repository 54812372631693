import Immutable from 'seamless-immutable'
import { handleActions, createAction } from 'redux-actions'

import _get from 'lodash/get'

export const resetGeometries = createAction('project-view/reset-geometries')
export const resetPatterns = createAction('project-view/reset-patterns')
export const resetVariants = createAction('project-view/reset-variants')
export const resetImages = createAction('project-view/reset-images')

const receiveNewGeometry = createAction('project-view/receiveNewGeometry')
const receiveNewPattern = createAction('project-view/receiveNewPattern')
const receiveNewVariant = createAction('project-view/receiveNewVariant')
const receiveNewImage = createAction('project-view/receiveNewImage')

export const receiveCombination = (payload) => (dispatch) => {
  const { id, type, projectId } = payload
  switch (type) {
    case 'convert':
      return dispatch(receiveNewGeometry({ projectId, id, finished: true }))
    case 'variant':
      return dispatch(receiveNewVariant({ projectId, id, finished: false }))
    case 'render':
      return dispatch(receiveNewImage({ projectId, id, finished: false }))
  }
}

export const setFinishedCombination = (payload) => (dispatch, getState) => {
  const { type, id, projectId } = payload
  const localState = _get(getState(), ['interface', 'projectView', 'newItems', projectId])

  if (!localState) return

  if (type === 'variant' && localState.variants && localState.variants[id]) {
    dispatch(receiveNewVariant({ projectId, id, finished: true }))
  }

  if (type === 'render' && localState.images && localState.images[id]) {
    dispatch(receiveNewImage({ projectId, id, finished: true }))
  }
}

export const receivePattern = (payload) => (dispatch) => {
  dispatch(receiveNewPattern({ projectId: payload.projectId, id: payload.id, finished: true }))
}

const initialState = Immutable({
  newItems: {}
})

export default handleActions({
  [resetGeometries]: (state, { payload }) => state.setIn(
    ['newItems', payload, 'geometries'],
    {}
  ),
  [resetPatterns]: (state, { payload }) => state.setIn(
    ['newItems', payload, 'patterns'],
    {}
  ),
  [resetVariants]: (state, { payload }) => state.setIn(
    ['newItems', payload, 'variants'],
    {}
  ),
  [resetImages]: (state, { payload }) => state.setIn(
    ['newItems', payload, 'images'],
    {}
  ),

  [receiveNewGeometry]: (state, { payload }) => state.setIn(
    ['newItems', payload.projectId, 'geometries', payload.id],
    {
      finished: payload.finished
    }
  ),
  [receiveNewPattern]: (state, { payload }) => state.setIn(
    ['newItems', payload.projectId, 'patterns', payload.id],
    {
      finished: payload.finished
    }
  ),
  [receiveNewVariant]: (state, { payload }) => state.setIn(
    ['newItems', payload.projectId, 'variants', payload.id],
    {
      finished: payload.finished
    }
  ),
  [receiveNewImage]: (state, { payload }) => state.setIn(
    ['newItems', payload.projectId, 'images', payload.id],
    {
      finished: payload.finished
    }
  )
}, initialState)
