import React from 'react'

const IconFolder = props => (
  <svg width={props.size} height={props.size} viewBox='0 0 20 20' {...props}>
    <path
      fill={props.color}
      d='M13.9285714,6.23214286 L13.9285714,12.125 C13.9285714,12.6383954 13.7444215,13.0792392 13.3761161,13.4475446 C13.0078107,13.8158501 12.5669669,14 12.0535714,14 L1.875,14 C1.36160458,14 0.92076077,13.8158501 0.552455357,13.4475446 C0.184149944,13.0792392 0,12.6383954 0,12.125 L0,4.08928571 C0,3.57589029 0.184149944,3.13504648 0.552455357,2.76674107 C0.92076077,2.39843566 1.36160458,2.21428571 1.875,2.21428571 L4.55357143,2.21428571 C5.06696685,2.21428571 5.50781066,2.39843566 5.87611607,2.76674107 C6.24442148,3.13504648 6.42857143,3.57589029 6.42857143,4.08928571 L6.42857143,4.35714286 L12.0535714,4.35714286 C12.5669669,4.35714286 13.0078107,4.5412928 13.3761161,4.90959821 C13.7444215,5.27790363 13.9285714,5.71874743 13.9285714,6.23214286 Z'
    />
  </svg>
)

IconFolder.defaultProps = {
  color: 'currentcolor',
  size: '1em'
}

export default IconFolder
