import { createSelector } from 'reselect'

import _get from 'lodash/get'

export const getLocalState = (state) => _get(state, 'templates')
export const getActiveTemplateId = (state) => _get(getLocalState(state), 'activeId', null)

// Default Template
export const getDefaultTemplate = (state) => _get(getLocalState(state), 'defaultTemplate')

export const getDefaultTemplateActive = createSelector(
  getActiveTemplateId,
  (activeTemplateId) => {
    return activeTemplateId === 'default'
  }
)
