import React from 'react'
import { useDispatch } from 'react-redux'
import { unlockVirtualProduct } from '../../../../../stores/ducks/tree'
import FormRemove from '../../../../common/form/form-remove'

import Modal from '../../../../common/modal'

type Props = {
  uuid: string | null
  isOpen: boolean
  onClose: () => void
}

export default function UnlockVirtualProductModal (props: Props) {
  const dispatch = useDispatch()
  if (props.uuid === null) {
    return null
  }
  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.onClose}
      shouldCloseOnOverlayClick={true}
      width={600}
    >
      <FormRemove
        formTitle='Unlock virtual product'
        formDescription='This action will decouple the virtual product from any future updates. Unlock if you want to make experimental changes to the virtual product.'
        onConfirm={() => {
          dispatch(unlockVirtualProduct(props.uuid!))
          props.onClose()
        }}
        onCancel={props.onClose}
        buttonText='Unlock'
      />
    </Modal>
  )
}
