export default {
  default: {
    userData: {
      modelType: 'roomset',
      noSourceSnap: true,
      snappingType: 'EXACT'
    },
    params: {
      addToNodeList: true,
      addToPicker: false,
      interactions: {
        snapTargets: { recursive: true }
      }
    }
  },
  static: {
    userData: {
      modelType: 'roomset',
      noSourceSnap: true,
      snappingType: 'EXACT'
    },
    params: {
      addToNodeList: true,
      addToPicker: false,
      interactions: {
        snapTargets: { recursive: true }
      }
    }
  },
  floor: {
    userData: {
      disableMaterials: false,
      modelType: 'roomset',
      noSourceSnap: true,
      snappingType: 'EXACT'
    },
    params: {
      addToNodeList: true,
      addToPicker: true,
      interactions: {
        navigationArea: { recursive: true },
        floor: { recursive: true },
        snapTargets: { recursive: true }
      }
    }
  },
  static_floor: {
    userData: {
      disableMaterials: true,
      modelType: 'roomset',
      noSourceSnap: true,
      snappingType: 'EXACT'
    },
    params: {
      addToNodeList: true,
      addToPicker: true,
      interactions: {
        navigationArea: { recursive: true },
        floor: { recursive: true },
        snapTargets: { recursive: true }
      }
    }
  },
  wall: {
    userData: {
      modelType: 'roomset',
      noSourceSnap: true,
      snappingType: 'EXACT'
    },
    params: {
      addToNodeList: true,
      addToPicker: true,
      interactions: {
        snapTargets: { recursive: true }
      }
    }
  },
  ceiling: {
    userData: {
      disableMaterials: true,
      modelType: 'roomset',
      noSourceSnap: true,
      snappingType: 'EXACT'
    },
    params: {
      addToNodeList: true,
      addToPicker: true,
      interactions: {
        snapTargets: { recursive: true }
      }
    }
  }
}
