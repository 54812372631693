import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ImmutableObject } from 'seamless-immutable'
import _isEmpty from 'lodash/isEmpty'
import _isEqual from 'lodash/isEqual'
import _omit from 'lodash/omit'
import _omitBy from 'lodash/omitBy'

import { RootState } from '../../../../stores/ducks'
import { getIsCommenting } from '../../../../stores/ducks/gallery/selectors'
import * as fromScenes from '../../../../stores/ducks/scenes'
import type { GlobalScene } from '../../../../stores/ducks/scenes/Scene'
import * as fromSceneSelectors from '../../../../stores/ducks/scenes/selectors'
import type { Comment, TagKeys, TempTags } from '../../../../stores/ducks/scenes/Temp'
import * as fromUsersSelectors from '../../../../stores/ducks/users/selectors'

import FormRemove from '../../../common/form/form-remove'
import Modal from '../../../common/modal'
import type { Item } from './../../card/Item'
import { Annotation } from './Annotation'
import { General } from './General'
import Tabs from './Tabs'

type Props = {
  isOpen: boolean
  onRequestClose: () => void
  onOpenVisualizer: (scene: Item) => void
  scene?: any
}

const ShowGlobalSceneModal = ({ isOpen, onRequestClose, onOpenVisualizer, scene }: Props) => {
  if (!isOpen) return null

  const dispatch = useDispatch()
  const tempTags = useSelector(fromSceneSelectors.getTempTags)
  const tempTitle = useSelector(fromSceneSelectors.getTempTitle)
  const tempComments = useSelector(fromSceneSelectors.getTempComments)
  const isCommenting = useSelector(getIsCommenting) as boolean
  const userId = useSelector(fromUsersSelectors.getCurrentUserId)
  const { email } = useSelector(fromUsersSelectors.getById(scene.createdBy))
  const userIsAdmin = useSelector((state: RootState) => fromUsersSelectors.getHasCurrentScopes('admin')(state))
  const globalScene: ImmutableObject<GlobalScene> = useSelector((state: RootState) => state.scenes.globalEntries[scene.id])

  const [comments, setComments] = useState<Comment[]>()
  const [readOnly, setReadOnly] = useState<boolean>(true)
  const [isUpdated, setIsUpdated] = useState<boolean>(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [editConfirmationModalOpen, setEditConfirmationModalOpen] = useState(false)
  const [saveConfirmationModalOpen, setSaveConfirmationModalOpen] = useState(false)
  const [localTitle, setLocalTitle] = useState<string | null>(null)

  useEffect(() => {
    async function fetchComments () {
      const apiUrl = '/api/scenes/comments'
      const result = await fetch(`${apiUrl}/${scene.id}`)
      const json = await result.json()
      setComments(json.comments)
    }
    fetchComments()
    setIsUpdated(false)
  }, [globalScene])

  const onCloseEdit = () => {
    dispatch(fromScenes.setTempTitle({ title: null }))
    dispatch(fromScenes.clearTempTags())
    dispatch(fromScenes.clearTempComments())
    setEditConfirmationModalOpen(false)
    setReadOnly(true)
    setErrorMsg('')
  }

  const checkRequiredInput = (tags: TempTags) => {
    const requiredKeys = ['REGION', 'PRIORITIES', 'LIVING_SITUATION', 'ACTIVITIES', 'ROOMS']
    const notOk = requiredKeys.filter((key:string) => tags[key as TagKeys] === undefined || tags[key as TagKeys].length < 1)
    return notOk.length === 0
  }

  const preUpdate = () => {
    if ((tempTitle === null || tempTitle.length > 0) && checkRequiredInput(tempTags)) {
      setSaveConfirmationModalOpen(true)
      return setErrorMsg('')
    }
    setErrorMsg('All required fields are not set')
  }

  const updateChanges = () => {
    const tagIds = getTempTagIds()
    const title = tempTitle || globalScene.title
    const commentsOmitStatus = tempComments.map((comment) => _omit(comment, 'hasTempAnchor'))

    dispatch(fromScenes.updateScene({ id: scene.id, title, tags: tagIds, comments: commentsOmitStatus, global: true }))
    onCloseEdit()
    setSaveConfirmationModalOpen(false)
    setIsUpdated(true)
  }

  const initialValue = {
    ROOMS: [],
    REGION: [],
    LIVING_SITUATION: [],
    SIZE: [],
    STYLE: [],
    COST: [],
    PRIORITIES: [],
    ACTIVITIES: [],
    BEHAVIORS: []
  }

  useEffect(() => {
    // Convert numbers[] into temporary tags with key {key: [], key: []...}
    if (!readOnly) {
      const tagsWithKey = globalScene.tags.reduce((accumulator: any, sceneTag: number) => {
        if (sceneTag >= 200 && sceneTag <= 210) {
          accumulator.ROOMS.push(sceneTag)
        }
        if (sceneTag >= 301 && sceneTag <= 334) {
          accumulator.REGION.push(sceneTag)
        }
        if (sceneTag >= 401 && sceneTag <= 409) {
          accumulator.LIVING_SITUATION.push(sceneTag)
        }
        if (sceneTag >= 9 && sceneTag <= 12) {
          accumulator.SIZE.push(sceneTag)
        }
        if (sceneTag >= 50 && sceneTag <= 51) {
          accumulator.STYLE.push(sceneTag)
        }
        if (sceneTag >= 47 && sceneTag <= 49) {
          accumulator.COST.push(sceneTag)
        }
        if (sceneTag >= 60 && sceneTag <= 62) {
          accumulator.PRIORITIES.push(sceneTag)
        }
        if (sceneTag >= 101 && sceneTag <= 112) {
          accumulator.ACTIVITIES.push(sceneTag)
        }
        if (sceneTag >= 113 && sceneTag <= 117) {
          accumulator.BEHAVIORS.push(sceneTag)
        }

        return accumulator
      }, initialValue)

      dispatch(fromScenes.setTempTagsGroup(_omitBy(tagsWithKey, _isEmpty)))
      dispatch(fromScenes.setTempTitle({ title: globalScene.title }))
      dispatch(fromScenes.setTempComments(comments))
    }
  }, [readOnly])

  const getTempTagIds = () => {
    const reducerConcatArrays = (accumulator:any[], currentValue:any) => accumulator.concat(currentValue)
    return Object.values(tempTags).reduce(reducerConcatArrays, [])
  }

  const isUnChanged = () => {
    const initialState = {
      title: globalScene.title,
      comments: comments,
      tags: globalScene.tags.asMutable().sort()
    }

    const tempState = {
      title: localTitle || tempTitle,
      comments: tempComments,
      tags: getTempTagIds().sort()
    }

    return _isEqual(initialState, tempState)
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => {
          if (readOnly) return onRequestClose()
          if (!readOnly && isUnChanged()) {
            onRequestClose()
            onCloseEdit()
            return
          }
          setEditConfirmationModalOpen(true)
        }
        }
        shouldCloseOnOverlayClick
        width={'80vw'}
        height={'80vh'}
      >
        <Tabs
          title={readOnly ? 'Global scene information' : 'Edit global scene information'}
          onCancel={() => {
            if (isUnChanged()) return onCloseEdit()
            setEditConfirmationModalOpen(true)
          }}
          email={email}
          onOpenVisualizer={() => onOpenVisualizer(scene)}
          onEditClick={() => setReadOnly(false)}
          onUpdate={() => preUpdate()}
          readOnly={readOnly}
          errorMsg={errorMsg}
          hasEditAccess={userIsAdmin || globalScene.createdBy === userId}
          disabledSave={isCommenting || isUnChanged()}
          componentGeneral={(
            <General
              errorMsg={errorMsg}
              selectedTags={readOnly ? globalScene.tags.asMutable() : getTempTagIds()}
              title={tempTitle || globalScene.title}
              readOnly={readOnly}
              isUpdated={isUpdated}
              passLocalTitle={(title) => setLocalTitle(title)}
            />
          )}
          componentAnnotation={<Annotation readOnly={readOnly} combination={scene} fetchedComments={comments}/>}
        />
      </Modal>
      <Modal
        isOpen={editConfirmationModalOpen}
        onRequestClose={() => {}}
        shouldCloseOnOverlayClick={false}
        width={600}
      >
        <FormRemove
          formTitle={'Leave edit global scene'}
          formDescription={'No changes will be saved.'}
          onConfirm={onCloseEdit}
          onCancel={() => setEditConfirmationModalOpen(false)}
          buttonText='Leave'
        />
      </Modal>
      <Modal
        isOpen={saveConfirmationModalOpen}
        onRequestClose={() => {}}
        shouldCloseOnOverlayClick={false}
        width={600}
      >
        <FormRemove
          formTitle={'Save global scene. This will effect all users'}
          formDescription={'Are you sure you want to save changes to this scene?'}
          onConfirm={updateChanges}
          onCancel={() => setSaveConfirmationModalOpen(false)}
          buttonText='Save'
        />
      </Modal>
    </>
  )
}

export default ShowGlobalSceneModal
