import React from 'react'

const Label = ({ children }) => {
  return (
    <div
      style={{ display: 'inline-block' }}
      className='f6'
    >
      {children}
    </div>
  )
}

export default Label
