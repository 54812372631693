import _get from 'lodash/get'
import _set from 'lodash/set'
import _isUndefined from 'lodash/isUndefined'

import { SearchParams } from 'elasticsearch'
import { MODEL_RESOURCE_TYPES } from '../../../constants'
import type { Status } from '../virtual-products/VirtualProduct'

const {
  RESOURCE_DPD,
  RESOURCE_VARIANT,
  RESOURCE_MODEL_BANK,
  RESOURCE_PROPPING_BANK,
  RESOURCE_NON_CAD,
  RESOURCE_VIRTUAL_PRODUCTS
} = MODEL_RESOURCE_TYPES

const AGGS_SIZE = 10000
const DEFAULT_PAGE_SIZE = 40
const NUMBERS_ONLY = /^[0-9]*$/

type AVAILABLE_SCOPE = 'sent' | 'local' | 'global'

type Filters = {
  activeTab: string
  userId: string
  projectId: string
  globalUploads?: AVAILABLE_SCOPE
  globalVariants?: AVAILABLE_SCOPE
  filterProjectId?: null | string
  idsOmittedFromSearch: never[]
  requireThumbnail: boolean
  // modelbank
  endSalesDateStatus?: 'hide ended' | 'show all'
  businessArea?: null | string
  productArea?: null | string
  productRangeArea?: null | string
  stylegroup?: null | string
  // virtualProduct
  status?: Status
}

export const buildDefaultQuery = (
  filters: Filters,
  options = {
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE
  }
) => {
  const { activeTab } = filters
  const { page, pageSize } = options
  const query: SearchParams = {}

  if (activeTab !== RESOURCE_VIRTUAL_PRODUCTS) {
    _set(query, ['sort'], [
      { 'title.keyword': { order: 'asc' } }
    ])
  }
  if ([RESOURCE_DPD, RESOURCE_VARIANT].includes(activeTab)) {
    _set(query, 'sort', [{ createdAt: { order: 'desc' } }])
  }

  // filters
  const { mustNotTerms, terms } = buildFilters(filters)
  _set(query, ['query', 'bool', 'filter'], terms)
  mustNotTerms && _set(query, ['query', 'bool', 'must_not'], mustNotTerms)

  // pagination
  const size = page * pageSize
  // Note: for future optimization, set from = page and size = pageSize. This requires redux support however...
  _set(query, ['from'], 0)
  _set(query, ['size'], size)

  return query
}

export const buildQuery = (
  queryString: string,
  filters: Filters,
  options = {
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE
  }
) => {
  const { page, pageSize } = options
  const { activeTab } = filters

  const query = {}

  const should = []
  if ([RESOURCE_PROPPING_BANK, RESOURCE_MODEL_BANK].includes(activeTab) && queryString.match(NUMBERS_ONLY)) {
    // article number
    should.push({ match: { 'articlenr.trimzero': { query: queryString } } })
  }

  if ([RESOURCE_DPD, RESOURCE_VARIANT, RESOURCE_MODEL_BANK, RESOURCE_PROPPING_BANK, RESOURCE_NON_CAD].includes(activeTab)) {
    // title matching (same as metadata.name for modelbank models but indexed for more searchability)
    should.push({
      bool: {
        should: [
          { match: { title: { query: queryString } } },
          { match_phrase: { title: { query: queryString, slop: 1, boost: 2 } } },
          { match: { 'title.edgengrams': { query: queryString, operator: 'and', boost: 0.5 } } },
          { match: { 'metadata.tags.keyword': { query: queryString } } }
        ]
      }
    })
  }

  if (RESOURCE_MODEL_BANK === activeTab) {
    // product type, is multiword
    // query: multiword
    should.push({
      match_phrase: {
        'metadata.producttype_name': {
          query: queryString,
          boost: 2,
          slop: 2
        }
      }
    })

    should.push({
      multi_match: {
        query: queryString,
        type: 'cross_fields',
        operator: 'and',
        fields: ['title^2', 'metadata.producttype_name']
      }
    })
  }

  if (activeTab === RESOURCE_VIRTUAL_PRODUCTS) {
    should.push({
      bool: {
        should: [
          {
            wildcard: {
              name: {
                value: `*${queryString}*`
              }
            }
          },
          { match_phrase: { name: { query: queryString } } }
        ]
      }
    })
  }

  if (should.length) {
    // TODO QUERY is this necessary?
    _set(query, ['query', 'bool', 'should'], should)
    _set(query, ['query', 'bool', 'minimum_should_match'], 1)
  }

  // sort
  if ([RESOURCE_DPD, RESOURCE_VARIANT].includes(activeTab)) {
    _set(query, 'sort', ['_score', 'createdAt'])
  }

  // filters
  const { mustNotTerms, terms } = buildFilters(filters)
  _set(query, ['query', 'bool', 'filter'], terms)
  mustNotTerms && _set(query, ['query', 'bool', 'must_not'], mustNotTerms)

  // pagination
  const size = page * pageSize
  // Note: for future optimization, set from = page and size = pageSize. This requires redux support however...
  _set(query, ['from'], 0)
  _set(query, ['size'], size)

  if (_get(query, ['query', 'bool', 'should', 'length'])) {
    _set(query, ['min_score'], 0.1)
  }

  return query
}

export const buildFilters = (filters: Partial<Filters>) => {
  const { activeTab, userId } = filters

  const mustNotTerms: any = []
  let terms: any = []

  if (activeTab === RESOURCE_DPD) {
    mustNotTerms.push({ term: { 'project.projectType': 'image-package' } })
    terms = [
      { term: { removed: false } },
      { term: { combinationType: 'convert' } }
    ]

    if (filters.globalUploads === 'sent') {
      terms.push({ term: { createdBy: userId } })
      terms.push({ term: { sentAsset: true } })
    }

    if (filters.globalUploads === 'local') {
      terms.push({ term: { projectRemoved: false } })
      terms.push({ term: { projectId: filters.projectId } })
      mustNotTerms.push({ term: { sentAsset: true } })
    }

    if (filters.globalUploads === 'global') {
      terms.push({ term: { projectRemoved: false } })
      filters.filterProjectId !== null && terms.push({ term: { projectId: filters.filterProjectId } })
      mustNotTerms.push({ term: { sentAsset: true } })
      terms.push({ term: { createdBy: userId } })
    }

    if (_isUndefined(filters.globalUploads)) {
      terms.push({ term: { projectRemoved: false } })
      mustNotTerms.push({ term: { sentAsset: true } })
      terms.push({ term: { createdBy: userId } })
    }
  } else if (activeTab === RESOURCE_VARIANT) {
    mustNotTerms.push({ term: { 'project.projectType': 'image-package' } })

    terms = [
      { term: { removed: false } },
      { term: { combinationType: RESOURCE_VARIANT } }
    ]

    if (filters.globalVariants === 'sent') {
      terms.push({ term: { createdBy: userId } })
      terms.push({ term: { sentAsset: true } })
    }

    if (filters.globalVariants === 'local') {
      terms.push({ term: { projectRemoved: false } })
      terms.push({ term: { projectId: filters.projectId } })
      mustNotTerms.push({ term: { sentAsset: true } })
    }

    if (filters.globalVariants === 'global') {
      terms.push({ term: { projectRemoved: false } })
      filters.filterProjectId !== null && terms.push({ term: { projectId: filters.filterProjectId } })
      mustNotTerms.push({ term: { sentAsset: true } })
      terms.push({ term: { createdBy: userId } })
    }

    if (_isUndefined(filters.globalVariants)) {
      terms.push({ term: { projectRemoved: false } })
      mustNotTerms.push({ term: { sentAsset: true } })
      terms.push({ term: { createdBy: userId } })
    }
  } else if (activeTab === RESOURCE_MODEL_BANK) {
    terms = [
      { term: { type: RESOURCE_MODEL_BANK } },
      { term: { active: true } }
    ]

    const {
      businessArea,
      productArea,
      productRangeArea,
      stylegroup,
      endSalesDateStatus
    } = filters

    if (businessArea) {
      terms.push({ term: { 'metadata.hfb': businessArea } })
    }

    if (productArea) {
      terms.push({ term: { 'metadata.pa': productArea } })
    }

    if (productRangeArea) {
      terms.push({ term: { 'metadata.pra': productRangeArea } })
    }

    if (stylegroup) {
      terms.push({ term: { 'metadata.stylegroup': stylegroup } })
    }

    if (endSalesDateStatus && endSalesDateStatus === 'hide ended') {
      mustNotTerms.push({ term: { 'metadata.itemSalesEndsStatus': 'all ended' } })
    }
  } else if (activeTab === RESOURCE_PROPPING_BANK) {
    // default to search for standard materials
    terms = [
      { term: { type: RESOURCE_PROPPING_BANK } }
    ]

    mustNotTerms.push({ term: { active: false } })
  } else if (activeTab === RESOURCE_NON_CAD) {
    terms = [
      { term: { type: RESOURCE_NON_CAD } },
      { term: { projectId: 'NULL' } }
    ]

    mustNotTerms.push({ term: { active: false } })
  } else if (activeTab === RESOURCE_VIRTUAL_PRODUCTS && filters.status) {
    terms = [
      { term: { 'metaData.status': filters.status } },
    ]
    mustNotTerms.push({ term: { active: false } })
  }

  if (filters.requireThumbnail) {
    terms.push({ term: { hasThumbnail: true } })
  }

  if (filters.idsOmittedFromSearch && _get(filters, 'idsOmittedFromSearch.length')) {
    mustNotTerms.push({ ids: { values: filters.idsOmittedFromSearch } })
    mustNotTerms.push({ bool: { should: filters.idsOmittedFromSearch.map((id: any) => ({ match: { sourceId: id } })) } })
  }

  const _filters: {terms: any[], mustNotTerms?: any[]} = { terms }
  if (mustNotTerms.length) {
    _filters.mustNotTerms = mustNotTerms
  }

  return _filters
}

export const queryAggregations: any = {
  modelbank: {
    hfb: {
      terms: {
        size: AGGS_SIZE,
        field: 'metadata.hfb'
      },
      aggs: {
        name: {
          terms: {
            field: 'metadata.hfb_name.keyword'
          }
        }
      }
    },
    pra: {
      terms: {
        size: AGGS_SIZE,
        field: 'metadata.pra'
      },
      aggs: {
        name: {
          terms: {
            field: 'metadata.pra_name.keyword'
          }
        }
      }
    },
    pa: {
      terms: {
        size: AGGS_SIZE,
        field: 'metadata.pa'
      },
      aggs: {
        name: {
          terms: {
            field: 'metadata.pa_name.keyword'
          }
        }
      }
    },
    stylegroup: {
      terms: {
        size: AGGS_SIZE,
        field: 'metadata.stylegroup'
      }
    }
  },
  upload: {
    projectId: {
      terms: {
        size: AGGS_SIZE,
        field: 'projectId'
      },
      aggs: {
        name: {
          terms: {
            field: 'project.title.keyword'
          }
        }
      }
    }
  },
  variant: {
    projectId: {
      terms: {
        size: AGGS_SIZE,
        field: 'projectId'
      },
      aggs: {
        name: {
          terms: {
            field: 'project.title.keyword'
          }
        }
      }
    }
  }
}
