import React from 'react'

import { EXTRACT_STATUSES } from '../../../constants'
import _upperFirst from 'lodash/upperFirst'
import colors from '../../../../css/colors'
import ProgressBar from '../../common/progress-bar'

const getStatusColor = (percentage, extractStatus, error) => {
  if (error || extractStatus === EXTRACT_STATUSES.EXTRACT_FAILED) {
    return colors.error
  }
  if (extractStatus === EXTRACT_STATUSES.EXTRACTING) {
    return colors.primaryLight
  }
  if (extractStatus === EXTRACT_STATUSES.EXTRACT_DONE || (!extractStatus && percentage >= 100)) {
    return colors.secondary
  }
  return colors.primary
}

class UploadProgress extends React.PureComponent {
  render () {
    if (!this.props.uploadProgress) {
      return null
    }

    const {
      filename,
      extractStatus,
      progress,
      error
    } = this.props.uploadProgress

    let percentage = 0

    if (!progress) {
      percentage = 100
    }

    if (progress) {
      const { uploadedBytes, totalBytes } = progress
      percentage = Math.trunc(uploadedBytes / totalBytes * 100)
    }

    let _extractStatus = 'Unknown status'
    if (extractStatus) {
      _extractStatus = _upperFirst(extractStatus.toLowerCase().replace(/_/g, ' '))
    }

    return (
      <div
        className='flex items-center flex-auto my1'
        style={this.props.style}
      >
        <div className='mr1 width-60 line-height'>
          <ProgressBar
            percentage={percentage}
            pad={0}
            background={getStatusColor(percentage, extractStatus, error)}
          />
        </div>

        <div className='mr1 width-20 truncate f7 italic' title={filename}>
          {filename}
        </div>

        <div className='truncate f7 italic'>
          {_extractStatus}
        </div>
      </div>
    )
  }
}

export default UploadProgress
