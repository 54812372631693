import React, { PureComponent } from 'react'

import _debounce from 'lodash/debounce'

class LoadMore extends PureComponent {
  constructor (props, ctx) {
    super(props, ctx)
    this.handleScroll = _debounce(this.handleScroll.bind(this), 200)
  }

  handleScroll () {
    const el = this._root

    if ((Math.ceil(el.scrollTop) + el.clientHeight + 100) >= el.scrollHeight) {
      this.props.onLoadMore()
    }
  }

  render () {
    const { children, style, className } = this.props

    return (
      <div
        ref={(node) => (this._root = node)}
        style={style}
        className={className}
        onScroll={this.handleScroll}
      >
        {children}
      </div>
    )
  }
}

export default LoadMore
