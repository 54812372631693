import Immutable from 'seamless-immutable'
import { createAction, handleActions } from 'redux-actions'
import _isEmpty from 'lodash/isEmpty'

import { getConvertState } from './selectors'
import { addEntities } from './json'

export const STATUS = {
  PENDING: 'PENDING',
  UPLOADING: 'UPLOADING',
  UPLOADED: 'UPLOADED',
  CONVERTING: 'CONVERTING'
}

const shouldNotConvert = (state) => {
  const { status } = getConvertState(state)
  return [STATUS.CONVERTING, STATUS.UPLOADING].includes(status)
}

const shouldAbortRequest = (state) => {
  return getConvertState(state).isCancelled
}

const reset = createAction('patterns/convert/RESET')
const uploading = createAction('patterns/convert/UPLOADING')
const uploaded = createAction('patterns/convert/UPLOADED')
const converting = createAction('patterns/convert/CONVERTING')
const converted = createAction('patterns/convert/CONVERTED')
const cancelled = createAction('patterns/convert/CANCELLED')
const uploadProgress = createAction('patterns/convert/UPLOAD_PROGRESS')
const hashProgress = createAction('patterns/convert/HASH_PROGRESS')
const error = createAction('patterns/convert/ERROR')

let _request = null

export const cancel = () => (dispatch) => {
  _request && _request.abort()
  _request = null
  dispatch(cancelled())
}

export const convert = (files, data) => (dispatch, getState, { api }) => {
  const state = getState()

  if (shouldNotConvert(state)) return

  dispatch(uploading())

  if (_isEmpty(files) || _isEmpty(data)) return dispatch(error('Nothing to convert'))

  const projectId = state.projects.currentId

  api.patterns.upload(files, {
    onUploadProgress: (progress) => dispatch(uploadProgress(progress)),
    onHashProgress: (progress) => dispatch(hashProgress(progress)),
    onRequest (request) {
      if (shouldAbortRequest(getState())) return dispatch(cancel())
      _request = request
    }
  })
    .then((originalManifestKey) => {
      dispatch(uploaded())
      dispatch(converting())

      _request = null

      return api.patterns.convert(
        originalManifestKey,
        {
          ...data,
          projectId
        }
      )
    })
    .then((json) => {
      dispatch(converted())
      dispatch(addEntities(json))
    })
    .catch((err) => {
      _request = null
      dispatch(error(err))
    })
}

const initialState = Immutable({
  status: STATUS.PENDING,
  hashProgress: {},
  uploadProgress: {},
  error: null
})

export default handleActions({
  [cancelled]: () => initialState.merge({ status: STATUS.PENDING }),
  [uploading]: (state) => state.merge({ status: STATUS.UPLOADING }),
  [uploaded]: (state) => state.merge({ status: STATUS.UPLOADED }),
  [converting]: (state) => state.merge({ status: STATUS.CONVERTING }),
  [converted]: (state) => state.merge({ status: STATUS.PENDING }),
  [error]: (state, action) => state.merge({ status: STATUS.PENDING, error: action.payload }),
  [uploadProgress]: (state, action) => state.merge({ uploadProgress: action.payload }),
  [hashProgress]: (state, action) => state.merge({ hashProgress: action.payload }),
  [reset]: () => initialState
}, initialState)
