import { createStore, applyMiddleware, compose } from 'redux'

// middlewares
import thunkMiddleware from 'redux-thunk'
import errorLoggerMiddleware from './middlewares/error-logger'

import api from './api'

import rootReducer from './ducks'

import { actionSanitizer, stateSanitizer } from './devtools-sanitizers'

const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
const composeEnhancers = process.env.NODE_ENV === 'development' && devToolsExtension
  ? devToolsExtension({ actionSanitizer, stateSanitizer })
  : compose

var store = createStore(rootReducer, composeEnhancers(
  applyMiddleware(
    thunkMiddleware.withExtraArgument({ api }),
    errorLoggerMiddleware
  )
))

export default store
