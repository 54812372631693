import { EventEmitter } from 'events'
import * as THREE from 'three'
import * as _ from 'lodash'

export default class SnappingTool extends EventEmitter {
  constructor (viewer, domElement, params = { gridSnap: 0.1 }) {
    super()
    this.viewer = viewer
    this.domElement = domElement
    this.utils = viewer.viewerUtils

    this.enabled = false
    this.gridSnap = params.gridSnap
    this.transparentMaterial = this.viewer.assetManager.addMaterial(new THREE.MeshStandardMaterial({
      transparent: true,
      opacity: 0.3,
      color: new THREE.Color(0.0, 0.0, 1.0),
      side: THREE.FrontSide
    }), 'snappingPreviewMaterial')
  }

  getRayCastedMousePosition (clientX, clientY) {
    var mouse = new THREE.Vector2()
    var point = new THREE.Vector2()
    point.fromArray(this.viewer.picker.getMousePosition(this.domElement, clientX, clientY))
    mouse.set((point.x * 2) - 1, -(point.y * 2) + 1)

    return mouse
  }

  onClick (e, point) {
    const intersects = this.viewer.picker.getSnappingIntersects(point)

    if (this.sourceIntersection && intersects && this.previewMesh) {
      // 7. Copy positions from the preview mesh onto the original mesh
      const parent = this.utils.findRootNode(this.sourceIntersection.object)
      parent.position.copy(this.previewMesh.position)
      parent.rotation.copy(this.previewMesh.rotation)

      this.deselect()

      this.emit('snapped', { object: parent, rotation: parent.rotation.clone(), position: parent.position.clone() })
      // TODO Move this outside, should not be here
    } else if (intersects && !this.sourceIntersection) {
      if (_.get(intersects, 'object.userData.noSourceSnap', false)) {
        return e
      }

      this.sourceIntersection = intersects
      this.sourceIntersectionRoot = this.utils.findRootNode(this.sourceIntersection.object)

      const snapTargetIdMap = getAllChildMeshes(this.sourceIntersectionRoot).reduce((acc, val) => {
        acc[val.uuid] = true
        return acc
      }, {})

      this.pickerFilter = mesh => !snapTargetIdMap[mesh.sceneGraphID]

      this.emit(
        'snappedStart',
        {
          object: this.sourceIntersectionRoot,
          rotation: this.sourceIntersectionRoot.rotation.clone(),
          position: this.sourceIntersectionRoot.position.clone()
        }
      )
    } else {
      this.deselect()
    }
  }

  onMouseMove (e) {
    if (!this.sourceIntersection) return

    const point = new THREE.Vector2().fromArray(this.viewer.picker.getMousePosition(this.domElement, e.clientX, e.clientY))
    const intersection = this.viewer.picker.getSnappingIntersects(point, this.pickerFilter)

    // When there are no targets to snap againts, remove the preview and return
    if (intersection === null) {
      if (this.previewMesh) {
        this.previewMesh.visible = false
      }
      // TODO: Should we emit a mouseMove event here?
      return
    }

    /*
      How this snapping implementation works:

      1. Creates a copy of the the object that the user wants to snap. This copy will be
      used to indicate where the snapping will occur if the user would like to snap at any
      given moment. This copy of the mesh is refered to as the preview mesh from here on.

      2. Moves the preview mesh so that it is aligned with the object it was created from.
      (same position and rotation)

      3. Rotate the preview mesh in place around its bounding box center so that it
      is aligend with the target mesh the user wants to snap to. The strategy here is to
      keep the original rotation as much as possible.

      4. Once rotated in place, move the preview mesh to the target so that the center
      of its bounding box is at the target position.

      5. Offset the preview mesh by half of its mesh distance in the reverse direction
      of the normal from the target face. Now the preview mesh will be located at its final
      location

      6. Do some simple tests to see if the preview mesh is below the floor, if it is move it up
      so that the mesh is aligned to the floor

      7. (in click handler) copy the position and rotation from the preview mesh to the real mesh
    */

    // 1. Setup the preview mesh
    if (!this.previewMesh) {
      this.previewMesh = this.sourceIntersectionRoot.clone()
      this.previewMesh.outline = false

      this.previewMesh.traverse((child) => {
        child.castShadow = false
        child.receiveShadow = false

        if (child.material) {
          child.material = this.transparentMaterial
        }
      })

      this.viewer.scene.addModel(this.previewMesh, {})
    } else {
      this.previewMesh.visible = true
    }

    const snapTarget = intersection.object
    const hitNormal = intersection.face.normal

    const hitNormalWorld = hitNormal.clone()
      .applyMatrix3(new THREE.Matrix3().getNormalMatrix(snapTarget.matrixWorld))
      .normalize()
    const hitNormalWorldReverse = hitNormalWorld.clone().multiplyScalar(-1)

    // 2. Reset the previews mesh position and rotation, this step is important. Because
    // all calculations from here will assume that the previewMesh is aligned with the
    // object it was created from.
    this.previewMesh.position.copy(this.sourceIntersectionRoot.position)
    this.previewMesh.rotation.copy(this.sourceIntersectionRoot.rotation)

    // 3. Lets start by rotating the preview mesh so that its local up vector is up.
    // Note: This cannot be done by just reseting the rotation since then we wouldnt be able
    // to find which side is most closest aligned to the target area.
    // let previewMeshBoundingBox = new THREE.Box3().setFromObject(sourceIntersectionRoot)
    let previewMeshBoundingBox = this.utils.getObjectBoundingBox(this.previewMesh)
    const previewMeshBoundingBoxBoxCenter = previewMeshBoundingBox.getCenter(new THREE.Vector3())

    // let localUp = this.previewMesh.up.clone().applyQuaternion(this.previewMesh.quaternion)
    const localUp = new THREE.Vector3(0, 1, 0).applyQuaternion(this.previewMesh.quaternion)
    const down = localUp.clone().multiplyScalar(-1)

    // Next. Lets find out the closest side of the mesh and the target surface.
    // We do this by transforming the hit normal to the object space of the preview mesh.
    // Then we snap the normal to the closest axis and transform back to world space.
    var closestAxis = getClosestAxis(this.previewMesh, hitNormalWorld)

    // Again, Rotate the preview mesh around its bounding box center to account for meshes
    // that dont have their pivot in the center.
    rotateAroundPivot(
      this.previewMesh,
      previewMeshBoundingBoxBoxCenter,
      new THREE.Quaternion().setFromUnitVectors(closestAxis, hitNormalWorld)
    )

    // Lets calculate the distance to the bounding box from its center in the direction.
    // This will be needed to offset the mesh from the target position later.
    // To calculate the distance, we put the meshs local bounding box temporarly in the scene,
    // rotate it to match the previous calculated rotation. then cast a ray from its center onto it
    // in the direction of the face normal.
    const previewMeshLocalBoundingBox = calculateLocalGeometryBoundingBox(this.previewMesh)
    const distanceToBounds = raycastInBox(previewMeshLocalBoundingBox, hitNormalWorld, this.previewMesh.quaternion)

    // 4 & 5. Let now move the mesh into position. We start from the point where the raycast hit.
    const targetPosition = new THREE.Vector3()
      .copy(intersection.point)
      // Then subtract the center of the non local bounding box from its position. This will move
      // the vector so that the center of the bounding box of the snap target will be
      // at the raycast hit vector.
      .sub(previewMeshBoundingBox.getCenter(new THREE.Vector3()).sub(this.previewMesh.position))
      // Next, offset the vector by half of the bounding box size, but only in the opposite
      // direction of the normal of the face that the raycast hit. This new vector will now move
      // the mesh perfectly onto the the target.
      .sub(hitNormalWorldReverse.multiplyScalar(distanceToBounds))

    this.previewMesh.position.copy(targetPosition)

    // 6. Now lets find if the mesh is intersecting a mesh below it, we are going to use
    // a naive approach and only cast one ray from its center downwards.
    // previewMeshBoundingBox = new THREE.Box3().setFromObject(this.previewMesh)
    previewMeshBoundingBox = this.utils.getObjectBoundingBox(this.previewMesh)

    const origin = previewMeshBoundingBox.getCenter(new THREE.Vector3())
    const hit = this.viewer.picker.trace(origin, down, this.pickerFilter)
    const distanceToBottom = raycastInBox(previewMeshBoundingBox, localUp, this.previewMesh.quaternion)

    if (hit && hit.distance < distanceToBottom) {
      const offsetPos = origin.sub(this.previewMesh.position)
      this.previewMesh.position.y = hit.point.y + distanceToBottom - offsetPos.y
    }

    this.emit('mouseMove')
  }

  enable () {
    this.enabled = true

    if (!this._clickStart) {
      this._clickStart = this.clickStart.bind(this)
      this.domElement.addEventListener('mousedown', this._clickStart, false)
      this.domElement.addEventListener('touchstart', this._clickStart, false)
    }

    if (!this._clickEnd) {
      this._clickEnd = this.clickEnd.bind(this)
      this.domElement.addEventListener('mouseup', this._clickEnd, false)
      this.domElement.addEventListener('touchend', this._clickEnd, false)
    }

    if (!this._onMouseMoveListener) {
      this._onMouseMoveListener = _.throttle(this.onMouseMove.bind(this), 16)
      this.domElement.addEventListener('mousemove', this._onMouseMoveListener, false)
    }
  }

  disable () {
    this.enabled = false
    this.deselect()

    if (this._onMouseMoveListener) {
      this.domElement.removeEventListener('mousemove', this._onMouseMoveListener)
      this._onMouseMoveListener = undefined
    }

    if (this._clickStart) {
      this.domElement.removeEventListener('mousedown', this._clickStart)
      this.domElement.removeEventListener('touchstart', this._clickStart)
      this._clickStart = undefined
    }

    if (this._clickEnd) {
      this.domElement.removeEventListener('mouseup', this._clickEnd)
      this.domElement.removeEventListener('touchend', this._clickEnd)
      this._clickEnd = undefined
    }
  }

  deselect () {
    this.sourceIntersection = undefined
    this.sourceIntersectionRoot = undefined
    this.pickerFilter = undefined

    if (this.previewMesh) {
      this.viewer.scene.removeModel(this.previewMesh)
      this.previewMesh = undefined
    }
  }

  clickStart (e) {
    this.mouseStartPos = new THREE.Vector2()
    this.mouseStartPos.fromArray(this.viewer.picker.getMousePosition(this.domElement, e.clientX, e.clientY))
    this.emit('mouseDown')
  }

  clickEnd (e) {
    var mouseEndPos = new THREE.Vector2()
    mouseEndPos.fromArray(this.viewer.picker.getMousePosition(this.domElement, e.clientX, e.clientY))
    if (this.mouseStartPos.distanceTo(mouseEndPos) <= 0.002) {
      this.onClick(e, mouseEndPos)
    }
  }

  dispose () {
    this.enabled = false
    this.deselect()

    this.domElement.removeEventListener('mousemove', this._onMouseMoveListener)
    this.domElement.removeEventListener('mousedown', this._clickStart)
    this.domElement.removeEventListener('touchstart', this._clickStart)
    this.domElement.removeEventListener('mouseup', this._clickEnd)
    this.domElement.removeEventListener('touchend', this._clickEnd)
    this.removeAllListeners()

    delete this._onClickListener
    delete this._onMouseMoveListener
    delete this._clickStart
    delete this._clickEnd
    delete this.sourceIntersection
    delete this.domElement
  }
}

export function getClosestAxis (mesh, normal) {
  const worldToPreviewSpace = mesh.quaternion.clone().invert()
  const v = normal.clone().applyQuaternion(worldToPreviewSpace)

  const ax = Math.abs(v.x)
  const ay = Math.abs(v.y)
  const az = Math.abs(v.z)
  const index = (ax >= ay && ax >= az ? 0 : (ay >= az ? 1 : 2))

  for (let i = 0; i < 3; i++) {
    v.setComponent(i, i === index ? Math.sign(v.getComponent(i)) : 0)
  }
  v.applyQuaternion(mesh.quaternion)
  return v
}

/**
 * Rotates the object around the pivot by first translating the object to the
 * pivot, then rotating, and finally moving the object back to its original position
 * @param {THREE.Object3D} object
 * @param {THREE.Vector3} pivot
 * @param {THREE.Quaternion} quaternion
 */
export function rotateAroundPivot (object, pivot, quaternion) {
  const translateToPivot = new THREE.Matrix4().makeTranslation(
    -pivot.x,
    -pivot.y,
    -pivot.z
  )
  const translateBackFromPivot = new THREE.Matrix4().makeTranslation(
    pivot.x,
    pivot.y,
    pivot.z
  )

  const rotate = new THREE.Matrix4().makeRotationFromQuaternion(quaternion)
  const rotation = translateBackFromPivot.multiply(rotate).multiply(translateToPivot)

  object.applyMatrix4(rotation)
}

/**
 * Given a bounding box and an optional rotation, calculates the distance
 * from the center to an edge of the box in the provided direction.
 * @param {THREE.Scene} scene
 * @param {THREE.Box3} boundingBox
 * @param {THREE.Vector3} direction
 * @param {THREE.Quaternion} [quaternion]
 * @returns {number}
 */
export function raycastInBox (boundingBox, direction, quaternion) {
  const quat = quaternion.clone().invert()
  const dir = direction.clone().applyQuaternion(quat)
  const distanceOrFalse = distanceToBox(boundingBox.getCenter(new THREE.Vector3()), dir, boundingBox.min, boundingBox.max)

  return distanceOrFalse ? Math.abs(distanceOrFalse) : 0
}

/**
 * Get the objects local bounding box
 * @param {THREE.Object3D} object
 * @return {THREE.Box3}
 */
export function calculateLocalGeometryBoundingBox (object, offset) {
  let minX = Number.MAX_SAFE_INTEGER
  let minY = Number.MAX_SAFE_INTEGER
  let minZ = Number.MAX_SAFE_INTEGER
  let maxX = Number.MIN_SAFE_INTEGER
  let maxY = Number.MIN_SAFE_INTEGER
  let maxZ = Number.MIN_SAFE_INTEGER

  object.traverseMeshes(o => {
    const bb = o.geometry.boundingBox
    minX = Math.min(bb.min.x, minX)
    minY = Math.min(bb.min.y, minY)
    minZ = Math.min(bb.min.z, minZ)
    maxX = Math.max(bb.max.x, maxX)
    maxY = Math.max(bb.max.y, maxY)
    maxZ = Math.max(bb.max.z, maxZ)
  })

  return new THREE.Box3(
    new THREE.Vector3(minX, minY, minZ),
    new THREE.Vector3(maxX, maxY, maxZ)
  )
}
/**
 * @param  {THREE.Object3D} object
 * @returns {THREE.Mesh[]}
 */
function getAllChildMeshes (object) {
  const meshes = []
  object.traverseMeshes(o => { meshes.push(o) })
  return meshes
}

// Ray-Box intersection test. Returns false or distance to intersection along ray.
const distanceToBox = (() => {
  const tmin = new THREE.Vector3()
  const tmax = new THREE.Vector3()
  const fmin = new THREE.Vector3()
  const fmax = new THREE.Vector3()

  return function (origin, dir, min, max) {
    tmin.copy(min).sub(origin).divide(dir)
    tmax.copy(max).sub(origin).divide(dir)

    fmin.copy(tmin).min(tmax)
    fmax.copy(tmin).max(tmax)

    if (fmin.x > fmax.y || fmin.y > fmax.x) return false

    fmin.x = Math.max(fmin.x, fmin.y)
    fmax.x = Math.min(fmax.x, fmax.y)

    if (fmin.x > fmax.z || fmin.z > fmax.x) return false

    return Math.max(fmin.x, fmin.z)
  }
})()
