import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import cs from 'classnames'
import _last from 'lodash/last'
import _map from 'lodash/map'
import { MdDelete as IconTrash, MdFileDownload as IconDownload, MdShare as IconShare } from 'react-icons/md'
import getImageDownloadFile from '../../utils/getImageDownloadFile'
import api from '../../stores/api'
import Checkbox from '../common/form/input-checkbox'
import InputSearch from '../common/form/input-search'
import MenuWithSubMenus from './MenuWithSubMenus'
import Pill from '../common/Pill'
import { PROJECT_TABS } from '../../constants'
import type { Item } from './card/Item'

import * as fromFolders from '../../stores/ducks/folders'
import * as fromColors from '../../stores/ducks/colors'
import * as fromColorsSelectors from '../../stores/ducks/colors/selectors'
import type { ProjectSearchFilters } from '.'

type Props = React.HTMLAttributes<HTMLElement> & {
  selectedTab: string
  selectedIds: string[]
  allSelected: boolean
  disableSelectAll: boolean
  imageCombinations: any[]
  showSendToDnp: boolean
  searchString: string
  disableSearch?: boolean
  searchFilter: ProjectSearchFilters
  currentFolder?: any
  folders: any[]
  onToggleSelectAll: () => void
  onSearchChange: (value: string) => void
  onClearSearch: () => void
  onAddCombsToFolder: (id: string) => void
  onSearchFilterChange: (filter: NonNullable<ProjectSearchFilters>) => void
  onOpenModal: (modal: 'remove' | 'sendToDnp' | 'createFolder') => void
  getSelectedCombination: (id: string) => Item | undefined
  resetSelectedIds: () => void
}

const Tools = (props: Props) => {
  const dispatch = useDispatch()
  const searchFilter = useSelector(fromColorsSelectors.selectSearchFilter)
  const hasSelection = !!props.selectedIds.length
  const downloadEnabled = props.selectedTab === PROJECT_TABS.images
  const onDownload = () => downloadEnabled && handleDownload(props.selectedIds, props.imageCombinations)

  function removeSelectionFromCurrentFolder () {
    const folderId = props.currentFolder && props.currentFolder.id

    if (props.selectedTab === 'patterns') {
      dispatch(fromFolders.removeConnectedIds([], props.selectedIds, folderId))
    } else {
      dispatch(fromFolders.removeConnectedIds(props.selectedIds, [], folderId))
    }
    props.resetSelectedIds()
  }

  if (props.selectedTab === PROJECT_TABS.colors) {
    return (
      <div className='flex justify-between width-100 items-center'>
        <InputSearch
          onChange={props.onSearchChange}
          onClearSearch={props.onClearSearch}
          value={props.searchString}
          placeholder='Search...'
        />
        <div className='flex items-center mx2' data-testid="colors-filters">
          <div title='IKEA Standard colors'>
            <Pill
              className='mr1'
              active={searchFilter === 'standard'}
              onClick={() => {
                dispatch(fromColors.setSearchFilter('standard'))
                dispatch(fromColors.setPage(1))
              }}>Standard</Pill>
          </div>
          <div title='Non-standard textile colors'>
            <Pill
              className='mr1'
              active={searchFilter === 'fabric'}
              onClick={() => {
                dispatch(fromColors.setSearchFilter('fabric'))
                dispatch(fromColors.setPage(1))
              }}>Non-standard textile</Pill>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='flex justify-between width-100'>
      <div className={cs('flex width-100 items-center', {
        mr3: props.selectedTab !== PROJECT_TABS.virtualProducts,
        mr1: props.selectedTab === PROJECT_TABS.virtualProducts
      })}>
        <InputSearch
          onChange={props.onSearchChange}
          onClearSearch={props.onClearSearch}
          value={props.searchString}
          placeholder='Search...'
          disabled={props.disableSearch}
        />

        {props.selectedTab !== PROJECT_TABS.virtualProducts && (
          <div className='flex items-center ml2'>
            <Pill
              className='mr1'
              active={props.searchFilter === 'local'}
              onClick={() => props.onSearchFilterChange('local')}
            >This project</Pill>
            <Pill
              active={props.searchFilter === 'sent'}
              onClick={() => props.onSearchFilterChange('sent')}
            >Sent to me</Pill>
          </div>
        )}
        {props.selectedTab === PROJECT_TABS.virtualProducts && (
          <div className='flex items-center ml2'>
            <Pill
              className='mr1'
              active={props.searchFilter === 'draft'}
              onClick={() => props.onSearchFilterChange('draft')}
            >Draft</Pill>
            <Pill
              active={props.searchFilter === 'approved'}
              onClick={() => props.onSearchFilterChange('approved')}
            >Approved</Pill>
          </div>
        )}
      </div>
      {props.selectedTab !== PROJECT_TABS.virtualProducts && (
        <div className={cs(props.className, 'flex justify-end items-center')}>
          <Checkbox
            noMargin
            name='select all'
            label={'Select All'}
            className='flex items-center'
            checked={props.allSelected}
            disabled={props.disableSelectAll}
            onChange={props.onToggleSelectAll}
          />
          {props.showSendToDnp &&
          <IconShare
            size={24}
            className={cs('c-gray-dark ml2', {
              disabled: !hasSelection,
              pointer: hasSelection
            })}
            onClick={() => props.onOpenModal('sendToDnp')}
          />
          }
          {downloadEnabled &&
        <div title="Download">
          <IconDownload
            size={24}
            className={cs('c-gray-dark ml2', {
              disabled: !hasSelection || !downloadEnabled,
              pointer: hasSelection
            })}
            onClick={onDownload}
          />
        </div>
          }
          <div
            title="Remove"
            data-testid="remove-button"
          >
            <IconTrash
              size={24}
              className={cs('c-gray-dark ml2', {
                disabled: !hasSelection,
                pointer: hasSelection
              })}
              onClick={() => props.onOpenModal('remove')}
            />
          </div>
          <MenuWithSubMenus
          // MenuWithSubMenus is a composed component with zIndexEnhancer which messes with TS.
          // TODO: Implement zIndexEnhancer as hook instead.
          // @ts-ignore
            items={[
              props.showSendToDnp && {
                onClick: () => props.onOpenModal('sendToDnp'),
                text: 'Share to DNP',
                iconInJSX: <IconShare />
              },
              {
                text: 'Add to folder...',
                subMenuItems: [
                  {
                    text: '+ New folder',
                    onClick: () => props.onOpenModal('createFolder')
                  },
                  ..._map(props.folders, folder => ({
                    text: folder.title,
                    onClick: () => props.onAddCombsToFolder(folder.id)
                  }))
                ]
              },
              props.currentFolder && {
                text: 'Remove from folder',
                onClick: removeSelectionFromCurrentFolder
              },
              props.selectedTab === PROJECT_TABS.images && {
                icon: <IconDownload />,
                text: 'Download image' + (props.selectedIds.length > 1 ? 's' : ''),
                onClick: onDownload
              },
              props.selectedTab === PROJECT_TABS.images && {
                icon: <IconDownload />,
                text: 'Download .xls',
                onClick: () => downloadSpreadsheet(props.selectedIds)
              },
              {
                icon: <IconTrash />,
                text: 'Remove',
                onClick: () => props.onOpenModal('remove')
              }
            ].filter(Boolean)}
            disabled={!hasSelection}
            className='ml1'
          />
        </div>
      )}

    </div>
  )
}

function downloadSpreadsheet (ids: string[]) {
  api.spreadsheet.renders(ids)
}

function handleDownload (selectedIds: string[], imageCombinations: any[]) {
  const files: any[] = []
  const downloadTitles: { [key: string]: number } = {}

  selectedIds.forEach(id => {
    const combination = imageCombinations.find(c => c.id === id)
    if (combination && combination.render) {
      const file = getImageDownloadFile(combination.render, combination.title)
      if (!file) return
      if (file.name in downloadTitles) {
        downloadTitles[file.name] += 1
        const ext = _last(file.name.split('.'))
        file.name = `${file.name.split('.')[0]}_${downloadTitles[file.name]}.${ext}`
      } else {
        downloadTitles[file.name] = 0
      }
      files.push(file)
    }
  })

  if (files.length === 1) {
    const file = files[0]
    const link = document.createElement('a')
    link.href = file.src
    link.download = file.name
    link.click()
    return
  }

  if (files.length) {
    api.downloadZip.storage(files, '')
  }
}

export default Tools
