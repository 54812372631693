import { Vector2, GridHelper, Object3D, Vector4, Vector3 } from 'three'
import { Go3DViewer } from '../../types/Go3DViewer'
import TriplanarMaterial from '../materials/TriplanarMaterial'
import { SceneGraphNode3d } from '../scenegraph/SceneGraph'
import { DragControls, DragEvent } from './DragControls'
import { setObjectHover, setObjectOutline, createDrawingPointer, createCustomMesh, setObjectOutlineError, flipBufferGeometryNormals } from './helpers'
import type { SceneGraphNode3d as TypeSceneGraphNode3d } from '../../types/SceneGraph'

import penCursor from './img/pen-cursor.png'
import penCursorPlus from './img/pen-cursor-plus.png'
import penCursorMinus from './img/pen-cursor-minus.png'
import { ISchemaDPDEnhanced, RoomDrawing } from './RoomDrawing'
import { Kvadrat, MeshType } from './kvadrat/kvadrat-generator'
import { EventEmitter } from 'events'
import CameraManager from '../scenegraph/CameraManager'

export type Mode = null | 'DRAWING' | 'EDITING' | 'ADD_HOLE'

type HoleModel = {
  id: string,
  type: string,
  scene: SceneGraphNode3d,
  size: { x: number, y: number },
  offset: { x: number, y: number, z: number }
}

const MESH_TYPES = {
  [MeshType.WALL]: 'wall',
  [MeshType.FLOOR]: 'floor',
  [MeshType.CEILING]: 'ceiling',
  [MeshType.SKIRTING]: 'skirting'
}

const DEFAULT_HOME_MATERIAL = new TriplanarMaterial({ color: 'gray' })

class RoomManager extends EventEmitter {
  private drawing: RoomDrawing
  private _mode: Mode = null
  enabled = false
  private verticalOffset = 0.05
  private holeModelClones: SceneGraphNode3d[] = []
  private controls: DragControls
  private kvadrat = new Kvadrat()

  holeModels = new Map<string, HoleModel>()
  activeHoleModelId: null | string = null
  view: '2D' | '3D' | null = null

  schema: ISchemaDPDEnhanced | null = null

  roomObject = new SceneGraphNode3d() as TypeSceneGraphNode3d

  private drawingPointer = createDrawingPointer()
  private grid: GridHelper
  private listenersActive = false
  private clickTimeout: null | number = null

  public get wallHeight () {
    return this.drawing.wallHeight
  }

  public set wallHeight (value: number) {
    this.drawing.wallHeight = value
  }

  private lastSelected: Object3D | null = null

  constructor (private app: Go3DViewer) {
    super()
    this.drawing = new RoomDrawing(app)
    this.controls = new DragControls(app.camera, app.domElementWrapper)

    const grid = new GridHelper(100, 100)
    // @ts-ignore
    grid.material.opacity = 0.4
    // @ts-ignore
    grid.material.transparent = true
    app.renderScene.scene.add(grid)
    grid.visible = false
    this.grid = grid

    this.app.overlayScene.add(this.drawingPointer)
    this.drawingPointer.visible = false
  }

  public get mode (): Mode {
    return this._mode
  }

  public set mode (value: Mode) {
    this.drawingPointer.visible = false
    this.controls.enabledDrag = false
    this.controls.active = null
    this.controls.defaultCursor = 'auto'

    if (this.mode === 'EDITING' && value !== 'EDITING' && this.lastSelected) {
      setObjectOutline(this.lastSelected, false)
      this.lastSelected = null
    }

    if (value === 'EDITING') {
      this.controls.setObjects(this.drawing.objects)
      this.controls.enabledDrag = true
    }

    if (value === 'DRAWING') {
      this.controls.defaultCursor = `url(${penCursor}) -25 20, auto`
    }

    if (value === 'ADD_HOLE') {}

    this._mode = value
    this.app.renderOnNextFrame()
  }

  emitWallLengthChange (wallLength: number | null) {
    this.emit('changeWallLength', wallLength)
  }

  emitSchemaChange () {
    const oldSchema = this.schema
    const newSchema = this.drawing.getSchema()

    if (oldSchema) {
      this.emit('change', {
        old: oldSchema,
        new: newSchema
      })
    }
  }

  private visibilityBackup = new Map<SceneGraphNode3d, boolean>()

  activate (shouldHide = (node: TypeSceneGraphNode3d) => true) {
    if (this.enabled) return

    this.controls.translationSnap = 0.5
    this.app.postProcessManager.safeFrame.enabled = false
    this.grid.visible = true
    this.enabled = true

    this.to2D()

    // Hide scenegraph nodes
    this.app.scene.children.forEach((node: TypeSceneGraphNode3d) => {
      if (shouldHide(node)) {
        this.visibilityBackup.set(node, node.visible)
        node.visible = false
      }
    })
  }

  deactivate () {
    if (!this.enabled) return

    this.view = null
    this.mode = null

    this.grid.visible = false
    this.enabled = false

    this.app.cameraManager.change({ drawing: false })
    this.app.postProcessManager.safeFrame.enabled = true

    this.visibilityBackup.forEach((visible, node) => (node.visible = visible))
    this.visibilityBackup.clear()
  }

  trash () {
    if (this.roomObject.parent) { this.app.removeModel(this.roomObject) }
    this.holeModelClones.forEach(clone => this.app.removeModel(clone))
    this.holeModelClones = []
    this.drawing.clear()
    this.controls.setObjects([])
    this.activeHoleModelId = null
    this.app.renderOnNextFrame()
  }

  clear () {
    if (this.clickTimeout !== null) window.clearTimeout(this.clickTimeout)
    this.trash()
    this.to2D()
    this.mode = 'DRAWING'
  }

  to2D () {
    if (this.view === '2D') return

    if (this.drawing.wallNodes.size() === 0) {
      this.mode = 'DRAWING'
    } else {
      this.mode = 'EDITING'
    }

    this.activateDrawingControls()

    if (this.roomObject) this.roomObject.visible = false

    this.holeModelClones.forEach(clone => this.app.removeModel(clone))
    this.holeModelClones = []

    this.view = '2D'
    this.drawing.visible = true

    if (this.app.cameraManager.controls?.target) {
      this.app.cameraManager.controls.target.copy(this.drawing.center)
    }
    this.app.cameraManager.change({ lockedMode: null })
    this.app.cameraManager.change({ behavior: CameraManager.BEHAVIORS.DEFAULT })
    this.app.cameraManager.change({ drawing: true })
  }

  hasWallNodesInDrawing () {
    return this.drawing.wallNodes.size()
  }

  to3D () {
    if (this.view === '3D') return

    this.deactivateDrawingControls()

    this.view = '3D'
    this.mode = null
    this.activeHoleModelId = null
    this.drawing.visible = false
    this.app.cameraManager.change({ drawing: false })

    if (!this.hasWallNodesInDrawing()) return
    const schema = this.drawing.getSchema()
    this.schema = schema

    const meshes = this.kvadrat.update(schema)

    if (this.roomObject.parent) { this.app.removeModel(this.roomObject) }

    const roomObject = new SceneGraphNode3d() as TypeSceneGraphNode3d

    // Raise room by 5cm so floor isn't fighting with default scene floor
    roomObject.position.setY(roomObject.position.y + this.verticalOffset)

    meshes.forEach(kvadratMesh => {
      const mesh = createCustomMesh(
        kvadratMesh.geometry.positions,
        kvadratMesh.geometry.indices,
        kvadratMesh.geometry.normals,
        kvadratMesh.geometry.uv0,
        kvadratMesh.geometry.uv1
      )

      mesh.position.set(kvadratMesh.position.x, kvadratMesh.position.y, kvadratMesh.position.z)
      mesh.receiveShadow = true
      mesh.castShadow = true
      mesh.material = DEFAULT_HOME_MATERIAL

      if (kvadratMesh.type === MeshType.FLOOR) {
        const ceilingMesh = createCustomMesh(
          kvadratMesh.geometry.positions,
          kvadratMesh.geometry.indices,
          kvadratMesh.geometry.normals,
          kvadratMesh.geometry.uv0,
          kvadratMesh.geometry.uv1
        )
        flipBufferGeometryNormals(ceilingMesh.geometry)

        const ceiling = this.app.loader.loadMesh(ceilingMesh).scene
        ceiling.position.y = this.wallHeight - this.verticalOffset

        ceiling.userData = {
          ...ceiling.userData,
          kvadratMeshId: kvadratMesh.stable_id + 1,
          setMaterial: true,
          modelType: 'combination',
          isTemplate: false,
          isCombination: true,
          roomMeshType: MESH_TYPES[MeshType.CEILING]
        }

        roomObject.add(ceiling)
      }

      const scene = this.app.loader.loadMesh(mesh).scene

      scene.userData = {
        ...scene.userData,
        kvadratMeshId: kvadratMesh.stable_id,

        // NOTE: These parameters are needed to enable setting appearances on the models
        setMaterial: true,
        modelType: 'combination',
        // We have OK floor materials in the template materials list.
        isTemplate: kvadratMesh.type === MeshType.FLOOR,
        // For all the other models, we use materials from the materialbank or dpd standard materials
        isCombination: kvadratMesh.type !== MeshType.FLOOR,
        roomMeshType: (mesh.position.y > this.verticalOffset && kvadratMesh.type === MeshType.WALL)
          ? 'wallTop'
          : MESH_TYPES[kvadratMesh.type],
        productType: kvadratMesh.type !== MeshType.FLOOR ? 'hard' : null
      }
      roomObject.add(scene)
    })

    this.drawing.holeList.forEach((hole) => {
      const model = this.holeModels.get(hole.modelId || '')
      if (!model || !hole.visible) return
      const clone = this.cloneHoleModel(model.scene)
      clone.visible = true
      clone.rotation.y = hole.rotation + (this.drawing.isDrawnCCW ? 0 : Math.PI)
      const mat = clone.matrix
      const rotatedOffset = new Vector4(
        model.offset.x,
        model.offset.y + this.verticalOffset,
        model.offset.z,
        0
      )
      rotatedOffset.applyMatrix4(mat)
      clone.position.addVectors(hole.position3, new Vector3(rotatedOffset.x, rotatedOffset.y, rotatedOffset.z))
      this.holeModelClones.push(clone)
      this.app.addModel(clone, { addToNodeList: true })
    })

    this.app.addModel(roomObject, {
      addToNodeList: true,
      addToPicker: { recursive: true },
      interactions: {
        snapTargets: { recursive: true }
      }
    })

    this.roomObject = roomObject
  }

  private cloneHoleModel (node: SceneGraphNode3d) {
    const clone = node.clone()
    clone.userData = { ...node.userData, isModelRoot: true }
    clone.traverse((child: any) => {
      if (child.material) {
        const materialId = child.material.materialId
        child.material = child.material.clone()
        child.material.materialId = materialId
      }
    })
    return clone
  }

  registerHoleModel (holeModel: Omit<HoleModel, 'size'>) {
    const bb = this.app.viewerUtils.getBoundingBox([holeModel.scene])

    this.holeModels.set(holeModel.id, {
      id: holeModel.id,
      scene: holeModel.scene,
      type: holeModel.type,
      offset: holeModel.offset,
      size: { x: bb.max.x - bb.min.x, y: bb.max.y - bb.min.y }
    })
  }

  setActiveHoleModel (id: string | null) {
    this.activeHoleModelId = id
  }

  getSchema () {
    return this.drawing.getSchema()
  }

  loadSchema (schema: ISchemaDPDEnhanced) {
    this.drawing.loadSchema(schema)
    this.controls.setObjects(this.drawing.objects)
    this.wallHeight = schema.walls[0]?.height ?? this.wallHeight
    this.schema = schema
    if (!this.enabled) {
      this.drawing.visible = false
    } else {
      this.mode = 'EDITING'
    }
  }

  private onKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Backspace') {
      this.schema = this.drawing.getSchema()

      this.drawing.removeSelected(this.controls.selection)
      this.controls.setObjects(this.drawing.objects)

      this.emitSchemaChange()
    }
  }

  private onPointerUp = (event: DragEvent) => {
    const object = event.object
    if (this.mode === 'ADD_HOLE' && object) {
      if (this.clickTimeout !== null) window.clearTimeout(this.clickTimeout)
      this.clickTimeout = window.setTimeout(() => {
        const changedOutline = setObjectOutlineError(object, false)
        if (changedOutline) this.app.renderOnNextFrame()
      }, 50)
    }
  }

  private onPointerDown = (event: DragEvent) => {
    const object = event.object
    const point = event.point

    if (!point) return
    point.setY(0)

    if (point && this.mode === 'DRAWING') {
      this.schema = this.drawing.getSchema()

      if (object && event.altKey) {
        this.drawing.clickedDrawing(
          object,
          point
        )
      } else {
        this.drawing.addWallNode(new Vector2(point.x, point.z))
      }
      this.controls.setObjects(this.drawing.objects)
      this.emitSchemaChange()
    }

    if (!object) return

    const holeModel = this.holeModels.get(this.activeHoleModelId || '')

    if (this.mode === 'ADD_HOLE' && holeModel) {
      const oldSchema = this.drawing.getSchema()

      const changed = this.drawing.addHoleToWall(
        object,
        point,
        {
          modelId: holeModel.id,
          type: holeModel.type,
          size: holeModel.size,
          position: { x: 0, y: holeModel.type === 'window' ? 0.8 : 0 }
        }
      )

      if (changed) {
        this.schema = oldSchema
        this.emitSchemaChange()
      }
    }

    this.app.renderOnNextFrame()
    setObjectHover(object, false)
  }

  private onSelect = (event: DragEvent) => {
    let changed = false

    if (this.mode === 'EDITING') {
      const selected = event.selected
      if (event.selected) this.lastSelected = event.selected
      const deselected = event.deselected

      if (selected) {
        this.drawing.debugSelect(selected)
        if (selected.userData.roomEditorObjectType === 'wall') {
          const wallLength = this.drawing.getWallLengthFromNode(selected)
          this.emitWallLengthChange(wallLength)
        }
        if (setObjectOutline(selected, true)) {
          changed = true
        }
      }

      if (deselected) {
        deselected.forEach(object => {
          if (setObjectOutline(object, false)) {
            changed = true
          }
        })
        if (deselected.length === 1 &&
            deselected[0].userData.roomEditorObjectType === 'wall' &&
            selected?.userData.roomEditorObjectType !== 'wall') {
          this.emitWallLengthChange(0)
        }
      }
    }

    if (changed) {
      this.app.renderOnNextFrame()
    }
  }

  private onHoverOn = (event: DragEvent) => {
    const object = event.object
    if (!object) return

    const changedHoverOn = setObjectHover(object, true)
    const changedHoverOff = event.prevHovered && setObjectHover(event.prevHovered, false)

    if (object && (changedHoverOn || changedHoverOff)) {
      this.controls.pointerCursor = 'pointer'
      this.drawingPointer.visible = false

      if (this.mode === 'DRAWING') {
        const objectType = this.drawing.getObjectType(object)

        if (objectType === 'wall' && event.altKey) {
          this.controls.pointerCursor = `url(${penCursorPlus}) -25 20, auto`
          this.drawingPointer.visible = true
          if (event.point) this.drawingPointer.position.copy(event.point)
        }
        if (objectType === 'wallNode' && event.altKey) {
          this.controls.pointerCursor = `url(${penCursorMinus}) -25 20, auto`
        }
      }

      this.app.renderOnNextFrame()
    }
  }

  private onHoverOff = (event: DragEvent) => {
    if (!event.object) return

    this.controls.pointerCursor = 'pointer'

    const changed = setObjectHover(event.object, false)

    if (changed) {
      this.app.renderOnNextFrame()
    }
  }

  private onDragStart = (event: DragEvent) => {
    if (this.mode !== 'EDITING' || !event.object) return
    this.schema = this.drawing.getSchema()
    this.app.cameraManager.disable('mouse')
    this.drawing.startDragObject(event.object)
  }

  private onDrag = (event: DragEvent) => {
    if (this.mode !== 'EDITING' || !event.object) return
    this.app.renderOnNextFrame()
    this.drawing.dragObject(event.object, false)
  }

  private onDragEnd = (event: DragEvent) => {
    if (this.mode !== 'EDITING') return
    this.app.cameraManager.enable('mouse')

    if (event.object) {
      this.drawing.dragObject(event.object, true)
      this.emitSchemaChange()
      this.app.renderOnNextFrame()
    }
  }

  private onPointerMove = (event: DragEvent) => {
    if (this.mode === 'DRAWING') {
      const point = event.snappedPoint || event.point
      if (point) this.drawingPointer.position.copy(point)
      this.app.renderOnNextFrame()
    }
  }

  private onMouseEnter = (event: MouseEvent) => {
    if (!this.drawingPointer.visible && this.mode === 'DRAWING') {
      this.drawingPointer.visible = true
      this.app.renderOnNextFrame()
    }
  }

  private onMouseLeave = (event: MouseEvent) => {
    if (this.drawingPointer.visible) {
      this.drawingPointer.visible = false
      this.app.renderOnNextFrame()
    }
  }

  deactivateDrawingControls () {
    if (!this.listenersActive) return
    if (this.clickTimeout !== null) window.clearTimeout(this.clickTimeout)

    document.body.removeEventListener('keydown', this.onKeyDown)

    this.controls.removeListener('dragend', this.onDragEnd)
    this.controls.removeListener('dragstart', this.onDragStart)
    this.controls.removeListener('drag', this.onDrag)
    this.controls.removeListener('hoveron', this.onHoverOn)
    this.controls.removeListener('hoveroff', this.onHoverOff)
    this.controls.removeListener('select', this.onSelect)
    this.controls.removeListener('pointerdown', this.onPointerDown)
    this.controls.removeListener('pointerup', this.onPointerUp)
    this.controls.removeListener('move', this.onPointerMove)

    this.app.domElementWrapper.removeEventListener('mouseenter', this.onMouseEnter)
    this.app.domElementWrapper.removeEventListener('mouseleave', this.onMouseLeave)

    this.controls.deactivate()

    this.listenersActive = false
  }

  activateDrawingControls () {
    if (this.listenersActive) return
    this.controls.activate()

    if (this.clickTimeout !== null) window.clearTimeout(this.clickTimeout)
    document.body.addEventListener('keydown', this.onKeyDown, false)

    this.controls.addListener('dragend', this.onDragEnd)
    this.controls.addListener('dragstart', this.onDragStart)
    this.controls.addListener('drag', this.onDrag)
    this.controls.addListener('hoveron', this.onHoverOn)
    this.controls.addListener('hoveroff', this.onHoverOff)
    this.controls.addListener('select', this.onSelect)
    this.controls.addListener('pointerdown', this.onPointerDown)
    this.controls.addListener('pointerup', this.onPointerUp)
    this.controls.addListener('move', this.onPointerMove)

    this.app.domElementWrapper.addEventListener('mouseenter', this.onMouseEnter)
    this.app.domElementWrapper.addEventListener('mouseleave', this.onMouseLeave)

    this.listenersActive = true
  }
}

export default RoomManager
