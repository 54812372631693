import React from 'react'

const IconLeave = props => (
  <svg
    viewBox="0 0 100 100"
    width={ props.width || props.size }
    height={ props.height || props.size }
    fill={props.color}
  >
    <path d="M5273.1 2400.1v-2c0-2.8-5-4-9.7-4s-9.7 1.3-9.7 4v2c0 1.8.7 3.6 2 4.9l5 4.9c.3.3.4.6.4 1v6.4c0 .4.2.7.6.8l2.9.9c.5.1 1-.2 1-.8v-7.2c0-.4.2-.7.4-1l5.1-5c1.3-1.3 2-3.1 2-4.9zm-9.7-.1c-4.8 0-7.4-1.3-7.5-1.8.1-.5 2.7-1.8 7.5-1.8s7.3 1.3 7.5 1.8c-.2.5-2.7 1.8-7.5 1.8z"/>
    <path d="M5268.4 2410.3c-.6 0-1 .4-1 1s.4 1 1 1h4.3c.6 0 1-.4 1-1s-.4-1-1-1h-4.3zM5272.7 2413.7h-4.3c-.6 0-1 .4-1 1s.4 1 1 1h4.3c.6 0 1-.4 1-1s-.4-1-1-1zM5272.7 2417h-4.3c-.6 0-1 .4-1 1s.4 1 1 1h4.3c.6 0 1-.4 1-1 0-.5-.4-1-1-1z"/>
    <g>
      <path d="M82.1 45.5H27.5c-2.5 0-4.5 2-4.5 4.5s2 4.5 4.5 4.5h54.6L71 65.7c-1.8 1.8-1.8 4.6 0 6.4 1.8 1.8 4.6 1.8 6.4 0l18.9-18.9c.8-.8 1.3-2 1.3-3.2s-.5-2.3-1.3-3.2L77.3 28c-.9-.9-2-1.3-3.2-1.3s-2.3.4-3.1 1.3c-1.8 1.8-1.8 4.6 0 6.4l11.1 11.1z"/>
      <path d="M7 90.8h45.9c2.5 0 4.5-2 4.5-4.5V68.8c0-2.5-2-4.5-4.5-4.5s-4.5 2-4.5 4.5v13H11.5V18.2h36.9v13c0 2.5 2 4.5 4.5 4.5s4.5-2 4.5-4.5V13.7c0-2.5-2-4.5-4.5-4.5H7c-2.5 0-4.5 2-4.5 4.5v72.5c0 2.5 2 4.6 4.5 4.6z"/>
    </g>
  </svg>
)

IconLeave.defaultProps = {
  color: 'currentcolor',
  size: '1em'
}

export default IconLeave
