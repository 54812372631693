import _get from 'lodash/get'

const getLocalState = (state) => _get(state, 'interface')
export const getTemplateModalProp = (state) => _get(getLocalState(state), 'modals.templateModal', {})

function getFinishedIds (state, type) {
  const localState = getLocalState(state)
  const object = _get(localState, type)
  if (!object) {
    return []
  }

  const arr = Object.entries(object)
  return arr.filter(item => item[1].finished).map(item => item[0])
}

export const getNewGeometryIds = (state) => {
  if (state.projects.currentId === null) {
    return []
  }
  return getFinishedIds(state, `projectView.newItems.${state.projects.currentId}.geometries`)
}

export const getNewPatternIds = (state) => {
  if (state.projects.currentId === null) {
    return []
  }
  return getFinishedIds(state, `projectView.newItems.${state.projects.currentId}.patterns`)
}

export const getNewVariantIds = (state) => {
  if (state.projects.currentId === null) {
    return []
  }
  return getFinishedIds(state, `projectView.newItems.${state.projects.currentId}.variants`)
}

export const getNewImageIds = (state) => {
  if (state.projects.currentId === null) {
    return []
  }
  return getFinishedIds(state, `projectView.newItems.${state.projects.currentId}.images`)
}

export const getNewCombinationsIds = (state) => {
  return [
    ...getNewGeometryIds(state),
    ...getNewVariantIds(state),
    ...getNewImageIds(state)
  ]
}
