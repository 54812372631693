import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { v4 as uuid } from 'uuid'
import _orderBy from 'lodash/orderBy'

import AddComment from '../../../common/comments/add-or-update'
import CommentCounter from '../../../common/comments/comment-counter'
import Grid from '../../../common/grid/grid'

import * as fromGallery from '../../../../stores/ducks/gallery'
import * as fromGallerySelectors from '../../../../stores/ducks/gallery/selectors'
import * as fromScenes from '../../../../stores/ducks/scenes'
import type { Comment, Tags } from '../../../../stores/ducks/scenes/Temp'
import { placeTemporaryAnchor } from '../../../../stores/ducks/gallery'

import CommentComponent from './comment'

type ScrollRef = {
  ref: any;
};

const HiddenScrollDiv = styled.div<ScrollRef>`
overflow-y: scroll;
&::-webkit-scrollbar {
  display: none;
}
`

type Props = {
  comments: Comment[]
  removeEnabled: boolean
  addComments: boolean
  tags: Tags
}

function CommentsContainer (props:Props) {
  const rootRef = React.useRef()
  const dispatch = useDispatch()

  const orderComments = _orderBy(props.comments, 'index', ['asc'])
  const haveTemporaryAnchor = useSelector<null | object>(fromGallerySelectors.getTemporaryAnchor)
  const selectedId = useSelector<string>(fromGallerySelectors.getSelectedCommentId)
  const position = useSelector<null | object>(fromGallerySelectors.getPosition)
  const [expandAddComment, setExpandAddComment] = useState<boolean>(!!haveTemporaryAnchor)
  const [commentToUpdate, setCommentToUpdate] = useState<Comment | null>(null)
  const [commentIndex, setIndexOfComment] = useState<null | number>(null)

  const focusComment = (payload:boolean) => dispatch(fromGallery.focusComment(payload))
  const onToggleSelect = (id:string) => dispatch(fromGallery.toggleSelectPlacedComment(id))
  const onClearTemporaryAnchor = () => dispatch(fromGallery.clearTemporaryAnchor())

  useEffect(() => setExpandAddComment(!!haveTemporaryAnchor), [haveTemporaryAnchor])
  useEffect(() => { return () => handleCollapseComment() }, [])
  // To activate isEditing mode when adding a comment through adding an anchor
  useEffect(() => { if (expandAddComment) handleExpandComment() }, [expandAddComment])

  useEffect(() => {
    if (!commentToUpdate) return
    let status = false
    if (commentToUpdate.position) {
      dispatch(placeTemporaryAnchor(commentToUpdate.position))
      status = true
    }
    dispatch(fromScenes.statusTempComment({ id: commentIndex, hasTempAnchor: status }))

    return () => {
      dispatch(fromScenes.statusTempComment({ id: commentIndex, hasTempAnchor: false }))
      setIndexOfComment(null)
    }
  }, [commentToUpdate])

  function handleExpandComment () {
    focusComment(true)
    setExpandAddComment(true)
  }

  function handleCollapseComment () {
    focusComment(false)
    onClearTemporaryAnchor()
    setExpandAddComment(false)
    setCommentToUpdate(null)
  }

  const newAnchorIndex:number = props.comments.filter((c:Comment) => c.position).length + 1

  return (
    <Grid
      gridGap={0}
      rows={['auto', '1fr']}
      style={{ height: '100%' }}
    >
      {!expandAddComment && <HiddenScrollDiv
        ref={rootRef}
      >
        {orderComments.length > 0 ? orderComments.map((comment:Comment) => (
          <CommentComponent
            key={comment.id}
            comment={comment}
            isSelected={selectedId === comment.id}
            onRemove={() => dispatch(fromScenes.removeTempComment(comment.id))}
            onEdit={() => {
              setIndexOfComment(props.comments.indexOf(comment))
              setCommentToUpdate(comment)
              handleExpandComment()
            }}
            onToggleSelect={() => onToggleSelect(comment.id)}
            removeEnabled={props.removeEnabled}
            addComments={props.addComments}
          />
        )) : <div className='f6 text-center p2 italic'>{`No annotations ${props.addComments ? 'yet, add a annotation by clicking on the image' : 'added by the user who shared this scene'}`}</div>}
      </HiddenScrollDiv>}
      <div style={{ gridRowEnd: 'none' }} className='overflow-x-hidden overflow-y-auto'>
        <div className='mr1 ml1'>
          {(!!position && selectedId === 'temporary') && (
            <div className='f7 flex items-center pt2 mbn1'>
              {`${commentToUpdate ? 'Edit' : 'Write a'} comment for`}
              <CommentCounter
                className='ml1'
                size={16}
                style={{ fontSize: 8 }}
                isActive
                count={(commentToUpdate && commentToUpdate.position) ? commentToUpdate.index : newAnchorIndex}
                onClick={() => {}}
              />
            </div>
          )}
        </div>
        {props.addComments && !commentToUpdate && <div className='mr1 ml1 mb1'>
          {<AddComment
            expanded={expandAddComment}
            onExpand={() => handleExpandComment()}
            onCancel={() => handleCollapseComment()}
            onSubmit={(comment, tags) => {
              const index = position ? newAnchorIndex : null
              dispatch(fromScenes.addTempComment({
                comment: comment,
                position: position,
                id: uuid().toString(),
                index: index,
                tags: tags
              }))
            }}
            lightBorder
            tagsActive
            tags={props.tags}
            noOutSideClick
          />}
        </div>}
        {commentToUpdate && <div className='mr1 ml1 mb1'>
          {<AddComment
            expanded={expandAddComment}
            onExpand={() => handleExpandComment()}
            onCancel={() => handleCollapseComment()}
            onSubmit={(comment, tags) => {
              const index = (commentToUpdate.position && commentToUpdate.index) || newAnchorIndex

              const body = {
                ...commentToUpdate,
                comment: comment,
                position: position,
                id: commentToUpdate.id,
                index: index,
                tags: tags
              }
              dispatch(fromScenes.updateTempComment({ body, id: commentIndex }))
              handleCollapseComment()
            }}
            oldComment={commentToUpdate && commentToUpdate.comment}
            lightBorder
            tagsActive
            tags={props.tags}
            noOutSideClick
            commentToUpdate={commentToUpdate}
            isUpdate
          />}
        </div>}
      </div>
    </Grid>
  )
}

export default CommentsContainer
