/* eslint-disable */
module.exports = function(THREE){
/**
 * @author alteredq / http://alteredqualia.com/
 */

THREE.ShaderPass = function ( shader, textureID ) {

	this.textureID = ( textureID !== undefined ) ? textureID : "tDiffuse";

	if ( shader instanceof THREE.ShaderMaterial ) {

		this.uniforms = shader.uniforms;

		this.material = shader;

	} else if ( shader ) {

		this.uniforms = THREE.UniformsUtils.clone( shader.uniforms );

		this.material = new THREE.ShaderMaterial( {

			defines: shader.defines || {},
			uniforms: this.uniforms,
			vertexShader: shader.vertexShader,
			fragmentShader: shader.fragmentShader

		} );

	}

	this.camera = new THREE.OrthographicCamera( - 1, 1, 1, - 1, 0, 1 );
	this.scene = new THREE.Scene();

	this.quad = new THREE.Mesh( new THREE.PlaneBufferGeometry( 2, 2 ), null );
	this.scene.add( this.quad );

};

THREE.ShaderPass.prototype = {
	constructor: THREE.ShaderPass,
		render: function( renderer, writeBuffer, readBuffer, delta, maskActive ) {
			if ( this.uniforms[ this.textureID ] ) {
				this.uniforms[ this.textureID ].value = readBuffer.texture;
			}

			this.quad.material = this.material;
			if ( this.renderToScreen ) {
				renderer.render( this.scene, this.camera );
			} else{
        renderer.setRenderTarget(writeBuffer)
        if(this.clear) { renderer.clear() }
				renderer.render( this.scene, this.camera );
			}
		},
		dispose: function() {
		}
	}
}

