import React from 'react'
import PropTypes from 'prop-types'
import cs from 'classnames'

export const PanelSectionHeader = ({
  title,
  noPadding,
  noPaddingBottom,
  className,
  color,
  includeTestId = true // Fix for IKEA TTS QuickAccess
}) => (
  <div
    className={cs({
      px2: !noPadding,
      pb2: !noPadding && !noPaddingBottom
    }, className)}>
    <h3
      data-testid={includeTestId ? `section-header-${title.toLowerCase()}` : undefined}
      className={`f7 m0 c-${color}`}>{title}
    </h3>
  </div>
)

PanelSectionHeader.defaultProps = {
  color: 'secondary',
  noPadding: false,
  noPaddingBottom: false,
  className: ''
}

PanelSectionHeader.propTypes = {
  title: PropTypes.string,
  noPadding: PropTypes.bool,
  noPaddingBottom: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.string
}

export const PanelSectionContent = ({
  fullWidth,
  children
}) => (
  <div className={cs('f6', {
    px2: !fullWidth
  })}>
    {children}
  </div>
)

PanelSectionContent.propTypes = {
  fullWidth: PropTypes.bool,
  children: PropTypes.any
}

PanelSectionContent.defaultProps = {
  fullHeight: false
}

export const PanelSection = ({
  children,
  fullHeight,
  className
}) => (
  <div className={cs(className, {
    py2: !fullHeight
  })}>
    {children}
  </div>
)

PanelSection.propTypes = {
  fullWidth: PropTypes.bool,
  children: PropTypes.any,
  className: PropTypes.string
}

PanelSection.defaultProps = {
  fullHeight: false,
  className: ''
}
