import React from 'react'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import _pick from 'lodash/pick'
import { MdDelete as IconTrash } from 'react-icons/md'
import cs from 'classnames'

import * as fromRenders from '../../../stores/ducks/renders'
import * as fromUploads from '../../../stores/ducks/uploads'
import * as fromProjects from '../../../stores/ducks/projects'
import * as fromJobs from '../../../stores/ducks/jobs'

import colors from '../../../../css/colors'
import CardShell from './CardShell'
import IconDesignView from '../../common/icons/icon-design-view'
import IconImageTemplate from '../../common/icons/ImageTemplateIcon'
import ProgressRadial from '../../common/progress-radial'
import { StorageApiThumbnail } from '../../common/storage-api-image'
import Square from '../../common/Square'
import type { Status } from '../../../stores/ducks/renders/Render'
import type { Item } from './Item'

import { getDeadlineStatus, getRenderPercentage } from '../../../utils/imageTemplateUtils'

const BUTTON_SIZE = { width: 32, height: 32 }

const INITIAL = 'Initial'
const POSTED = 'Posted'
const PENDING = 'Pending'
const ACTIVE = 'Active'
const COMPLETED = 'Completed'
const QUEUED = 'Queued'
const RENDERING = 'Rendering'
const FAILED = 'Failed'
const SUSPENDED = 'Suspended'

type Props = {
  item: Item
  combinationType: Item['combinationType']
  onClickImage?: () => void
  onSecondaryAction?: () => void
  noShadow?: boolean
  className?: string
}

export default function ProgressCard (props: Props) {
  const dispatch = useDispatch()
  const [hover, setHover] = React.useState(false)

  function handleRetry () {
    if (props.combinationType === 'render' || props.combinationType === 'variant') {
      const renderId = props.item.render?.id
      if (props.item.batchRenders && props.item.batchRenders.length > 0) {
        const failedRenders = props.item.batchRenders.filter(item => item.deadlineStatus !== COMPLETED)
        dispatch(fromRenders.retry(failedRenders.map(item => item.id)))
      } else if (props.item.renders && props.item.renders.length > 1) {
        dispatch(fromRenders.retry(props.item.renders.map(render => render.id)))
      } else {
        dispatch(fromRenders.retry(renderId))
      }
    } else if (props.combinationType === 'upload') {
      dispatch(fromUploads.retryConvert(props.item.id))
    }
  }

  function handleCancel () {
    if (props.item.type === 'bake') {
      dispatch(fromJobs.removeJobsById(props.item.id))
    } else {
      if (props.combinationType === 'upload') dispatch(fromUploads.remove(props.item.id))
      if (['variant', 'render'].includes(props.combinationType || '')) dispatch(fromProjects.removeCombination(props.item.projectId, props.item.id))
    }
  }

  const {
    deadlineStatus,
    status,
    renderPercentage
  } = getRenderStatus(props.item)

  const replacedDeadlineStatus = (deadlineStatus || status) as unknown as Status
  const percentage = getPercentage(
    props.combinationType,
    replacedDeadlineStatus || (status === INITIAL ? INITIAL : POSTED),
    renderPercentage
  )
  const hasFailed = getDidFail(replacedDeadlineStatus)
  const isBatchRender = !!((props.item && props.item.connectedBatchRenderCombinationIds) || []).length

  return (
    <CardShell
      noShadow={props.noShadow}
      className={props.className}
      tooltip={props.item.title || 'Rendering'}
    >
      <div
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        className={cs('bg-white relative border-bottom bc-gray-light',
          { pointer: ['render', 'variant'].includes(props.combinationType || '') }
        )}
      >
        <div
          className='relative'
          onClick={props?.onClickImage}
          data-testid="card-shell-preview"
        >
          {props.item.render && (
            <StorageApiThumbnail
              manifest={props.item.manifest}
              style={{ borderRadius: '2px 2px 0 0' }}
              className='opacity-hover-lighter'
            />
          )}

          {!props.item.render && (<Square />)}

          {hasFailed && (
            <div className='pt1 px1 absolute right-0 top-0 z1'>
              <button
                className='ml1 pointer right f5 c-gray-dark bc-gray bc-gray-dark-hover bg-white bg-gray-light-hover border br-2 flex justify-center items-center'
                style={BUTTON_SIZE}
                onClick={event => {
                  handleCancel()
                  event.stopPropagation()
                }}
              >
                <IconTrash />
              </button>
              {props.item.type !== 'bake' &&
                <button
                  className='pointer left f5 c-gray-dark bc-gray bc-gray-dark-hover bg-white bg-gray-light-hover border br-2 flex justify-center items-center'
                  style={BUTTON_SIZE}
                  onClick={event => { event.stopPropagation(); handleRetry() }}
                >
                  <i className='icon-ccw' />
                </button>
              }
            </div>
          )}

          <div className='absolute top-0 bottom-0 right-0 left-0 px3'>
            {hasFailed && (
              <ProgressRadial
                strokeWidth={8}
                background={colors.errorLightAlt}
                percentage={95}
                hideLabel
              />
            )}

            {!hasFailed && (
              <ProgressRadial
                hideLabel
                strokeWidth={8}
                percentage={percentage}
              />
            )}
          </div>

          {(['variant'].includes(props.combinationType || '') && hover && !hasFailed) &&
            <>
              <div className='bg-primary-transparent absolute top-0 bottom-0 right-0 left-0 width-100 height-100 flex justify-center items-center'>
                <div className='flex justify-center items-center'>
                  <IconDesignView size={24} /><div className='ml2'>Visualize</div>
                </div>
              </div>
            </>
          }

          {(['render'].includes(props.combinationType || '') && hover && !hasFailed && props.item.type !== 'bake') && (
            <div
              onClick={(event) => { event.stopPropagation(); if (props.onSecondaryAction) props.onSecondaryAction() }}
              className='absolute right-0 top-0 bg-primary bg-primary-light-hover br-small m1'
              style={{ height: 40, width: 40 }}
            >
              <div className='m1 flex justify-center items-center'>
                <IconDesignView size={24} />
              </div>
            </div>
          )}

          {props.item.imageTemplateId && props.item.renders && props.item.combinationType !== 'convert' && (
            <div className='absolute' style={{ bottom: 10, right: 10 }}>
              <IconImageTemplate
                size={26}
                isBatchRender={isBatchRender}
                iconSize={24}
                stackStep={6}
                showStack={props.item.renders.length > 1 || isBatchRender}
              />
            </div>
          )}
        </div>
      </div>
      <div className='bg-white relative'>
        <div className='px1 py1'>
          <div
            className={'width-100 block py0 px0 f7 bold truncate'}
            style={{ paddingBottom: '0.2rem' }}
          >
            {getText(props.item, isBatchRender)}
          </div>

          <time className='c-gray-accessible f7 truncate'>
            <div className='truncate'>Started {moment(props.item.modifiedAt || props.item.createdAt).fromNow()}</div>
          </time>
        </div>
      </div>
      <div style={{ height: 7 }} />
    </CardShell>
  )
}

function getText (item: Props['item'], isBatchRender:boolean) {
  // images (renders) and variant have title on top level of job per default
  // patterns have title on top level (added in selector)

  if (isBatchRender) return item.batchRenderTitle || (item?.renders && item?.renders[0].title) || item.title

  if (item.title) {
    return item.title
  }
  // geometry have title in uploadedMetaData object per default
  if (item.uploadMetadata && item.uploadMetadata.title) {
    return item.uploadMetadata.title
  }
  return item.status === 'Failed' ? 'Failed' : 'In progress'
}

function getRenderStatus (item: Props['item']) {
  if (item.imageTemplateId && item.batchRenders) {
    return {
      deadlineStatus: getDeadlineStatus(item.batchRenders),
      renderPercentage: getRenderPercentage(item.batchRenders),
      status: item.render?.status,
      renderStatus: item.render?.status
    }
  }
  if (item.imageTemplateId && item.renders) {
    return {
      deadlineStatus: getDeadlineStatus(item.renders),
      renderPercentage: getRenderPercentage(item.renders),
      status: item.render?.status,
      renderStatus: item.render?.status
    }
  }
  if (item.combinationType !== 'upload' && item.render) {
    return _pick(item.render, ['deadlineStatus', 'status', 'renderStatus', 'renderPercentage'])
  }
  return _pick(item, ['deadlineStatus', 'status', 'renderStatus', 'renderPercentage'])
}

function getDidFail (deadlineStatus: string) {
  return [FAILED, SUSPENDED].includes(deadlineStatus)
}

function getPercentage (combinationType: Item['combinationType'], status: Status, renderPercentage?: number) {
  if (combinationType === 'render' && renderPercentage) return renderPercentage

  return {
    [INITIAL]: 5,
    [POSTED]: 10,
    [QUEUED]: 15,
    [PENDING]: 17,
    [ACTIVE]: 25,
    [RENDERING]: 25,
    [COMPLETED]: 100,
    [FAILED]: 75,
    [SUSPENDED]: 75
  }[status]
}
