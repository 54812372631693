import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ImmutableObject } from 'seamless-immutable'
import useStorageApiUpload from '../../../hooks/useStorageApiUpload'
import { RootState } from '../../../stores/ducks'
import { setErrorResponseStatus, uploadVirtualProductAttachments } from '../../../stores/ducks/virtual-products'
import { VirtualProductProjectListItem } from '../../../stores/ducks/virtual-products/VirtualProduct'
import UploadFiles from '../../upload-files'
import UploadCard from '../card/UploadCard'

interface Props {
  id: string
  onUploading: (status: boolean) => void
}

export default function UploadImage ({ id, onUploading }: Props) {
  const dispatch = useDispatch()
  const errorResponseStatus = useSelector((state: RootState) => state.virtualProducts.errorResponseStatus)
  const virtualProduct: ImmutableObject<VirtualProductProjectListItem> = useSelector((state: RootState) => state.virtualProducts.entries[id])
  const [{ manifest, error: uploadError }, uploadFiles] = useStorageApiUpload()

  useEffect(() => {
    if (virtualProduct.attachments || errorResponseStatus) {
      onUploading(false)
    }
  }, [virtualProduct.attachments, errorResponseStatus])

  useEffect(() => {
    if (uploadError) {
      onUploading(false)
      dispatch(setErrorResponseStatus('Action: upload attachment failed, we are sorry. Please try again'))
    }
  }, [uploadError])

  useEffect(() => {
    if (!manifest || !id) return
    dispatch(uploadVirtualProductAttachments(id, manifest))
  }, [manifest])

  return (
    <UploadFiles
      onUpload={(files: File[]) => {
        // Placeholder visible
        onUploading(true)
        uploadFiles(files)
      }}
      supportedFormats={['image/*']}
    >
      {({ isDragging }: { isDragging: boolean }) => (
        <UploadCard
          type='Images'
          isDragging={isDragging}
        />
      )}
    </UploadFiles>
  )
}
