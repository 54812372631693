import React from 'react'
import _includes from 'lodash/includes'
import styled from 'styled-components'

import InputGroup from '../../../common/form/input-group'
import Label from '../../../common/form/label'
import InputSelect from '../../../common/form/input-select'
import type { LabelArray, TagLabel } from '../../../../stores/ducks/scenes/Temp'

const Group = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const formatGroupLabel = (data:TagLabel) => (
  <Group>
    <span>{data.label}</span>
  </Group>
)

type MultiSelectGroupProps = {
  readOnly?: boolean
  disabled?: boolean
  className?: string
  label: string
  options: LabelArray
  onChange: (option: number[]) => void
  errorMsg?: string
  multi?: boolean
  isGroup?: boolean
  selectedTags: number[]
  style?: Object
}

export const MultiSelectGroup = ({ className, label, options, onChange, errorMsg, multi = true, isGroup = false, selectedTags, readOnly, style, disabled = false } : MultiSelectGroupProps) => {
  const filterSelectedValues = (allValues:any) => allValues.filter((v:any) => _includes(selectedTags, v.key))
  const _selectedTags = isGroup ? options.reduce((acc:any, val:any) => acc.concat(val.options), []) : options
  const selectedValues = filterSelectedValues(_selectedTags)

  function isLabelArray (values: LabelArray | TagLabel): values is LabelArray {
    return Array.isArray(values)
  }
  function isTagLabel (values: LabelArray | TagLabel): values is TagLabel {
    return (values as TagLabel).key !== undefined
  }

  return (
    <div className={className} style={disabled ? { pointerEvents: 'none', opacity: '0.6' } : {} }>
      <InputGroup >
        <Label className='flex justify-between'>
          <span>{label}</span>
          {errorMsg && selectedValues.length < 1 && <span className='c-error regular'>{'Required'}</span>}
        </Label>
        <InputSelect
          value={selectedValues}
          isMulti={multi ?? true}
          options={options}
          onChange={(values:LabelArray | TagLabel) => {
            if (!values) return onChange([])
            if (isLabelArray(values)) return onChange(values.map((v:TagLabel) => v.key))
            if (isTagLabel(values)) return onChange([values.key])
          }}
          isDisabled={readOnly || disabled}
          isClearable={!readOnly}
          placeholder={readOnly ? 'No tags in this category' : 'Select tag'}
          components={readOnly && { DropdownIndicator: () => null, IndicatorSeparator: () => null, MultiValueRemove: () => null, ClearIndicator: () => null }}
          style={style}
          formatGroupLabel={isGroup ? formatGroupLabel : undefined}
        />
      </InputGroup>
    </div>
  )
}
