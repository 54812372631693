import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import type { LabelArray, Tags } from '../../../../stores/ducks/scenes/Temp'
import InputGroup from '../../../common/form/input-group'
import InputSelect from '../../../common/form/input-select'
import Label from '../../../common/form/label'

const Tag = styled.div`
border-color: var(--grayAlt);
background-color: var(--grayAlt);
color: white;
display: flex;
margin: 2px;
`

function TagsComp ({ tags }: { tags: LabelArray[] }) {
  const tagClasses = 'border rounded f7 x pl1 pr1'
  return <div className='flex flex-wrap flex-end' style={{ justifyContent: 'flex-end' }}>
    {tags && tags.map((tag:any, i:number) => {
      if (!tag) return
      return <Tag className={tagClasses} key={i}>{tag.label}</Tag>
    })}
  </div>
}

export default TagsComp

type TagInputsProps = {
  setTags: React.Dispatch<React.SetStateAction<never[]>>,
  tags: Tags,
  selectedTags?: LabelArray[]
}

enum TagTypes {
  ROOMS,
  ACTIVITIES,
  PRIORITIES
}

export function TagInputs ({ setTags, tags, selectedTags } : TagInputsProps) {
  const [localTags, setLocalTags] = useState({
    ROOMS: [],
    ACTIVITIES: [],
    PRIORITIES: []
  })

  const handleSetTags = () => {
    setTags((localTags.ROOMS ?? []).concat(localTags.ACTIVITIES).concat(localTags.PRIORITIES))
  }

  const handleChange = (tagType: TagTypes, changedTags: never[]) => {
    const updatedState = localTags
    switch (tagType) {
      case TagTypes.ROOMS:
        updatedState.ROOMS = changedTags ?? []
        break
      case TagTypes.ACTIVITIES:
        updatedState.ACTIVITIES = changedTags ?? []
        break
      case TagTypes.PRIORITIES:
        updatedState.PRIORITIES = changedTags ?? []
        break
    }
    setLocalTags(updatedState)
    handleSetTags()
  }

  const initialValue = {
    ROOMS: [],
    PRIORITIES: [],
    ACTIVITIES: [],
  }

  useEffect(() => {
    if (!selectedTags) return
    const tagsWithKey = selectedTags.reduce((accumulator: any, tag: any) => {
      if (tag.key >= 200 && tag.key <= 210) {
        accumulator.ROOMS.push(tag)
      }
      if (tag.key >= 60 && tag.key <= 61) {
        accumulator.PRIORITIES.push(tag)
      }
      if (tag.key >= 101 && tag.key <= 112) {
        accumulator.ACTIVITIES.push(tag)
      }
      return accumulator
    }, initialValue)
    setLocalTags(tagsWithKey)
  }, [selectedTags])

  return <>
    <InputGroup className='width-100 mr2'>
      <Label className='flex justify-between'>
        <span>Room</span>
      </Label>
      <InputSelect
        value={localTags.ROOMS}
        isMulti
        options={tags.ROOMS}
        onChange={(c:never) => handleChange(TagTypes.ROOMS, c)}
        isClearable={true}
      />
    </InputGroup>
    <InputGroup className='width-100 mr2'>
      <Label className='flex justify-between'>
        <span>Activity</span>
      </Label>
      <InputSelect
        value={localTags.ACTIVITIES}
        isMulti
        options={tags.ACTIVITIES}
        onChange={(c:never) => handleChange(TagTypes.ACTIVITIES, c)}
      />
    </InputGroup>
    <InputGroup className='width-100 mr2'>
      <Label className='flex justify-between'>
        <span>IKEA long-term priorities</span>
      </Label>
      <InputSelect
        value={localTags.PRIORITIES}
        isMulti
        options={tags.PRIORITIES}
        onChange={(c:never) => handleChange(TagTypes.PRIORITIES, c)}
      />
    </InputGroup>
  </>
}
