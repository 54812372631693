const noop = () => {}

export const createSetMaterialCommand = ({
  newValues,
  oldValues,
  setMaterial,
  afterUndo = noop,
  afterExecute = noop
}) => ({
  undo () {
    oldValues.forEach(setMaterial)
    afterUndo()
  },

  execute () {
    newValues.forEach(setMaterial)
    afterExecute()
  }
})
