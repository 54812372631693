import fetch from '../../../utils/fetch'

const assertError = (reason) => (err) => {
  console.error(reason)
  console.error(err)
  throw err
}

export const create = (data) => (
  fetch('/api/comments/create', {
    method: 'PUT',
    body: JSON.stringify({ data })
  })
    .then((res) => res.json())
    .catch(assertError({ msg: 'Unable to save comment', data }))
)

export const update = (data) => (
  fetch('/api/comments/update', {
    method: 'POST',
    body: JSON.stringify({ data })
  })
    .then((res) => res.json())
    .catch(assertError({ msg: 'Unable to update comment', data }))
)

export const remove = (data) => (
  fetch('/api/comments/remove', {
    method: 'POST',
    body: JSON.stringify({ data })
  })
    .then((res) => res.json())
    .catch(assertError({ msg: 'Unable to remove comment', data }))
)

export const getAllComments = (projectId) => (
  fetch(`/api/comments/getByProject/${projectId}`)
    .then((res) => (res.json()))
    .catch(assertError(`unable to get comments for project ${projectId}`))
)

export const get = (id) => (
  fetch(`/api/comments/getFor/${id}`)
    .then((res) => res.json())
    .catch(assertError({ msg: 'Unable to fetch comments', id }))
)
