import React, { useState } from 'react'
import { MdCenterFocusStrong as IconImageTemplate } from 'react-icons/md'
import { AssetType } from '..'
import EmptyState from '../../common/empty-state/empty-state'
import EmptyStateUpload from '../../common/empty-state/with-upload'
import IconCube from '../../common/icons/icon-cube'
import IconFolders from '../../common/icons/icon-folder'
import IconGallery from '../../common/icons/icon-gallery'
import IconGroup from '../../common/icons/icon-group'
import IconPattern from '../../common/icons/icon-pattern'
import IconScenes from '../../common/icons/icon-scene'
import Modal from '../../common/modal'
import GeometryUploadView from '../../upload-files/geometry-upload-view'
import PatternUploadView from '../../upload-files/pattern-upload-view'
import uploadUtils from '../../upload-files/utils'

export const EmptySentAssets = (props: { assetType: AssetType }) => {
  let icon = <IconGroup size={80} />
  if (props.assetType === 'image') icon = <IconGallery size={80} />
  if (props.assetType === 'geometry') icon = <IconCube size={80} />
  if (props.assetType === 'pattern') icon = <IconPattern size={80} />

  const desc = `When somebody sends you ${props.assetType === 'image' ? 'an' : 'a'} ${props.assetType}, it will appear here.`
  const title = `No ${props.assetType === 'geometry' ? 'geometries' : props.assetType + 's'} sent to you`

  return (
    <EmptyState
      className='mt4'
      icon={icon}
      title={title}
      desc={desc}
      docLink='send-assets.md'
      linkText='Learn more'
    />
  )
}

export const EmptyImages = () => (
  <EmptyState
    className='mt4'
    icon={<IconGallery size={80} />}
    title='Create Images'
    desc='Images of your designs can be created from the visualizer view.'
    docLink='images.md'
    linkText='Learn more'
  />
)

export const EmptyGeometry = (props: { onUploaded: (data: any) => void }) => {
  const [files, setFiles] = useState<File[]>([])
  const reset = () => setFiles([])

  return (
    <div>
      <EmptyStateUpload
        className='mt4'
        icon={<IconCube size={80} />}
        title='Upload Geometry'
        desc='Once the geometry is uploaded, you can use it in your designs in the visualizer view.'
        docLink='getting-started.md#dpd_2ndashuploadamodel'
        linkText='Learn more'
        uploadType='Geometry'
        onUpload={(files: File[]) => setFiles(files)}
        disableCreateButton
        supportedFormats={uploadUtils.acceptedFileTypes.map(t => `.${t}`)}
        multiple
      />
      <Modal
        isOpen={files.length > 0}
        onRequestClose={reset}
      >
        <GeometryUploadView
          files={files}
          onReset={reset}
          onUploaded={props.onUploaded}
        />
      </Modal>
    </div>
  )
}

export const EmptyVariants = () => (
  <EmptyState
    className='mt4'
    icon={<IconGroup size={80} />}
    title='Create variants'
    desc='Variants can be any combination of geometry and materials, making it easier to reuse designs.'
    docLink='variants.md'
    linkText='Learn more'
  />
)

export const EmptyScenes = () => (
  <EmptyState
    className='mt4'
    icon={<IconScenes size={80} />}
    title='Shared scenes'
    desc='To create a scene go to the images tab to the left and save an image as a scene.'
    docLink='scenes.md'
    linkText='Learn more'
  />
)

export const EmptyGlobalScenes = () => (
  <EmptyState
    className='mt4'
    icon={<IconScenes size={80} />}
    title='Global scenes'
    desc='To create a global scene go to the menu on a scene and click on "Save as global"'
    docLink='scenes.md'
    linkText='Learn more'
  />
)

export const EmptyFolder = () => (
  <EmptyState
    className='mt4'
    icon={<IconFolders size={80} />}
    title='Organize your project with folders'
    desc='Organize items into folders by selecting and dragging them, the dotted menu or the New folder button.'
    docLink='folders.md'
    linkText='Read more about folders in the help section.'
  />
)

export const EmptyTemplateImagesFolder = () => (
  <EmptyState
    className='mt4'
    icon={<IconImageTemplate size={80} />}
    title='Template images'
    desc='Template images can be created in the Visualizer.'
  />
)

export const EmptySearch = () => (
  <EmptyState
    className='mt4'
    title='No items found'
    desc='Try changing the search query.'
  />
)

export const EmptyFilter = () => (
  <EmptyState
    className='mt4'
    title='No items found'
    desc='Try changing the search filter.'
  />
)

export const EmptyPatterns = () => {
  const [files, setFiles] = useState<File[]>([])
  const supportedFormats = [
    '.pdf',
    '.tiff',
    '.tif',
    '.jpg',
    '.jpeg',
    '.png',
    '.eps',
    '.svg',
    '.psd'
  ]

  return (
    <div className='overflow-y-auto'>
      <EmptyStateUpload
        className='mt4'
        icon={<IconPattern size={80} />}
        title='Upload patterns'
        desc='Patterns are images that can be applied to soft geometry.'
        docLink='appearances-colors-patterns.md#dpd_uploadpatterns'
        linkText='Learn more'
        uploadType='Pattern'
        onUpload={(files: File[]) => setFiles(files)}
        multiple={false}
        supportedFormats={supportedFormats}
        disableCreateButton
      />
      <Modal
        isOpen={files.length > 0}
        onRequestClose={() => setFiles([])}
      >
        <PatternUploadView
          files={files}
          supportedFormats={supportedFormats}
          onReset={() => setFiles([])}
        />
      </Modal>
    </div>
  )
}

export const EmptyVirtualProducts = (props: { onAction: () => void, onSecondAction: () => void }) => {
  return (
    <EmptyState
      className='mt4'
      icon={<IconCube size={80} />}
      title='Connect Virtual Product'
      desc='Virtual Products '
      docLink=''
      linkText='Learn more'
      actionTitle='Connect Virtual Product'
      onAction={props.onAction}
      hasActionCard
      onSecondAction={props.onSecondAction}
      secondAction={{ title: 'Create Virtual Product' }}
    />
  )
}
