import React from 'react'

const IconRenders = props => (
  <svg width='1em' height='1em' viewBox='0 0 20 16' {...props}>
    <path
      d='M6.667 4.667c0 .555-.195 1.027-.584 1.416a1.929 1.929 0 0 1-1.416.584 1.929 1.929 0 0 1-1.417-.584 1.929 1.929 0 0 1-.583-1.416c0-.556.194-1.028.583-1.417a1.929 1.929 0 0 1 1.417-.583c.555 0 1.027.194 1.416.583.39.389.584.861.584 1.417zm10.666 4v4.666H2.667v-2L6 8l1.667 1.667L13 4.333l4.333 4.334zm1-7.334H1.667a.32.32 0 0 0-.235.1.32.32 0 0 0-.099.234v12.666c0 .09.033.169.1.235a.32.32 0 0 0 .234.099h16.666a.32.32 0 0 0 .235-.1.32.32 0 0 0 .099-.234V1.667a.32.32 0 0 0-.1-.235.32.32 0 0 0-.234-.099zM20 1.667v12.666c0 .459-.163.851-.49 1.177-.326.327-.718.49-1.177.49H1.667c-.459 0-.851-.163-1.177-.49A1.605 1.605 0 0 1 0 14.333V1.667C0 1.208.163.816.49.49.816.163 1.208 0 1.667 0h16.666c.459 0 .851.163 1.177.49.327.326.49.718.49 1.177z'
      fillRule='nonzero'
      fill={props.color}
    />
  </svg>
)

IconRenders.defaultProps = {
  color: 'currentcolor'
}

export default IconRenders
