import * as THREE from 'three'
import { Dispatch } from 'redux'
import _difference from 'lodash/difference'
import _clamp from 'lodash/clamp'

import type {
  SceneGraphNode3d as ISceneGraphNode3d,
  SceneGraphMesh as ISceneGraphMesh
} from '../../../../../../go3dthree/types/SceneGraph'

import * as fromMaterials from '../../materials'
import * as fromThreeViewerUI from '../../threeviewer/ui'
import { getMinimalProportion, getVolume } from './utils'

const tempVector1 = new THREE.Vector3()
const tempVector2 = new THREE.Vector3()

type Match = {
  originalMesh: ISceneGraphMesh
  replacementMesh: ISceneGraphMesh
  score: number
}

type PossibleMatches = Map<ISceneGraphMesh, Match>

// Ensures that there's at least two PID-levels, i.e two levels in the folder structure (TODO: verify this works for CADs with collapsed nodeIds)
const CAD_NODE_ID_REGEX = /node_(.*PID[0-9]+){2,}/

// Checks if a mesh name contains a version name, i.e "mesh0-2" or "mesh0_2", where "2" would be the version.
const CAD_VERSION_REGEX = /^(.+)((-|_)[0-9]+)$/

const DISALLOWED_COMBINATION_TYPES = [
  'virtual-product'
] as const

const ALLOWED_MODEL_SOURCES = [
  undefined, // undefined is allowed, since uploaded geometries often do not have this value set
  'uploaded-geometry'
] as const

// The model tabs (geometry load modal) that should display settings for reapplying appearances/materials
export const ALLOWED_MODEL_TABS = [
  'upload',
  'variant'
] as const

// These constants are not very scientific, the values are just set to what seems to produce the best results...

// The minimum score required to match two bounding boxes
const BOUNDING_BOX_MATCH_THRESHOLD = 0.12

// How strongly similarities in proportions between bounding boxes influences the score
const BOUNDING_BOX_PROPORTION_SCORE_MULTIPLIER = 0.12

// How strongly the distance between bounding boxes affects the score
const DISTANCE_SCALE_MULTIPLIER = 4.0

// Controls the exponent of the distance weight, influencing how much the distance between meshes affects the score
const DISTANCE_WEIGHT_POW = 1.3

// If the confidence in a single bounding box match is above this value
const BOUNDING_BOX_SINGLETON_MATCH_THRESHOLD = 0.75

/**
 * Determines if appearances/materials can be replaced
 **/
export const canReapplyAppearanceAndMaterial = (rootNode: ISceneGraphNode3d) => {
  return (
    !DISALLOWED_COMBINATION_TYPES.includes(rootNode.userData.combinationType) && // Disallow certain combination types
    ALLOWED_MODEL_SOURCES.includes(rootNode.userData.modelSource) // Allow certain model types
  )
}

/**
 * Checks if two meshes share at least one tag
 */
const matchOnTags = (mesh1: ISceneGraphMesh, mesh2: ISceneGraphMesh) => {
  const tags1: string[] | undefined = mesh1.userData.tag || mesh1.userData.tags
  const tags2: string[] | undefined = mesh2.userData.tag || mesh2.userData.tags
  return (
    (tags1 && tags2) &&
    tags1.some((tag: string) => tags2.includes(tag))
  )
}

/**
 * Checks if two meshes have the same name (optionally excluding possible version numbers)
 * If mesh.name exists, this will be used. If not, the fallback of mesh.userData.name will be used.
 * If no name is found for any of the meshes, the function will return false
 *
 * If ignoreVersion is set to true, possible version numbers will be removed from the name before comparison.
 * I.e, "mesh1-1" becomes "mesh1" and "mesh3-145" becomes "mesh3"
 */
const matchOnName = (mesh1: ISceneGraphMesh, mesh2: ISceneGraphMesh, ignoreVersion: boolean = false) => {
  let name1: string | undefined = mesh1.name || mesh1.userData.name
  let name2: string | undefined = mesh2.name || mesh2.userData.name

  if (!name1 || !name2) return false

  // Will remove version numbers appended to names, i.e "mesh1-1" will become "mesh1"
  // This is useful when matching meshes that are the structurally the same, but have changed slightly

  // TODO: maybe only do this IF there's significant overlap to avoid unintended side effects
  if (ignoreVersion) {
    const removeVersionNumber = (name: string) => {
      return CAD_VERSION_REGEX.test(name)
        // If the test goes through, we know for sure that match will not return null
        ? (name.match(CAD_VERSION_REGEX) as RegExpMatchArray)[1]
        : name
    }

    name1 = removeVersionNumber(name1)
    name2 = removeVersionNumber(name2)
  }

  return name1 === name2
}

/**
 * Compares two CAD meshes (mesh nodeIDs should match the CAD_NODE_ID_REGEX) and returns true if they match
 * Will compare names of the meshes, as well as the immediate parents. If any of the meshes lack a parent, false will be returned
 *
 * Comparing the name of two meshes along with the parent names should be sufficient for identifying exact mesh matches.
 */
const matchCadMesh = (mesh1: ISceneGraphMesh, mesh2: ISceneGraphMesh) => {
  if (!mesh1.parent || !mesh2.parent) return false

  return (
    matchOnName(mesh1, mesh2, true) &&
    matchOnName(mesh1.parent, mesh2.parent, true)
  )
}

/**
 * Determines if two meshes match based on metadata.
 * Two meshes will match if they
 *   1. Share at least one tag
 *   2. Have the same nodeId
 *   3. Share names and parent names (CAD meshes)
 */
const isMetadataMatch = (mesh1: ISceneGraphMesh, mesh2: ISceneGraphMesh) => {
  // Check if models have matching tags
  if (matchOnTags(mesh1, mesh2)) return true

  const nodeId1: string | undefined = mesh1.userData.nodeId
  const nodeId2: string | undefined = mesh2.userData.nodeId
  if (nodeId1 && nodeId2) {
    // Check if full match
    if (nodeId1 === nodeId2) return true

    // Check if nodeId matches structure of CAD model
    if (
      CAD_NODE_ID_REGEX.test(nodeId1) &&
      CAD_NODE_ID_REGEX.test(nodeId2)
    ) {
      return matchCadMesh(mesh1, mesh2)
    }
  }

  return false
}

/**
 * Finds all mesh matches that can be identified based on metadata.
 * A mesh from the originalMeshes array can match against multiple meshes from the replacementMeshes array.
 *
 * The unmatched original meshes and replacement meshes will be returned, along with all matches
 */
const findMetadataMatches = (originalMeshes: ISceneGraphMesh[], replacementMeshes: ISceneGraphMesh[]) => {
  const matches: Match[] = []

  // NOTE: This step has O(n^2) complexity
  const unmatchedOriginalMeshes = originalMeshes.filter(mesh => {
    const matchedReplacementMeshes: ISceneGraphMesh[] = []
    for (let i = 0; i < replacementMeshes.length; i++) {
      const other = replacementMeshes[i]

      if (isMetadataMatch(mesh, other)) {
        matches.push({
          originalMesh: mesh,
          replacementMesh: other,
          score: 1.0 // A match based on metadata is always deemed to be a 100% match
        })
        matchedReplacementMeshes.push(other)

        break
      }
    }

    // Remove matched meshes to avoid matching the same mesh twice
    replacementMeshes = _difference(replacementMeshes, matchedReplacementMeshes)

    // Return true if no match was found (i.e keep the mesh for further processing)
    return !matchedReplacementMeshes.length
  })

  return {
    unmatchedOriginalMeshes,
    unmatchedReplacementMeshes: replacementMeshes,
    matches
  }
}

/**
 * Calculates the matching score of two bounding boxes.
 * The score is determined based on the overlap of the bounding boxes and their relative sizes.
 */
const calculateBoundingBoxMatchScore = (
  originalMesh: ISceneGraphMesh,
  replacementMesh: ISceneGraphMesh,
  distanceScale: number,
) => {
  const originalBB: THREE.Box3 = originalMesh.geometry.boundingBox.clone()
  const replacementBB: THREE.Box3 = replacementMesh.geometry.boundingBox.clone()

  originalBB.applyMatrix4(originalMesh.matrixWorld)
  replacementBB.applyMatrix4(replacementMesh.matrixWorld)

  // The intersection of the two boxes, i.e how much overlap exists
  // We first verify that the boxes actually intersect each other. If not, Box3.intersect will return
  // an "empty" box with max=(-Infinity, -Infinity, -Infinity) and min=(Infinity, Infinity, Infinity)
  // which will mess up the following calculations.
  const intersectionBB = originalBB.intersectsBox(replacementBB)
    ? originalBB.clone().intersect(replacementBB)
    : new THREE.Box3(new THREE.Vector3(0, 0, 0), new THREE.Vector3(0, 0, 0))

  // Volumes
  const replacementVolume = getVolume(replacementBB)
  const intersectionVolume = getVolume(intersectionBB)

  // Proportions
  const originalDimensions = originalBB.getSize(tempVector1)
  const replacementDimensions = replacementBB.getSize(tempVector2)

  const proportions = {
    width: getMinimalProportion(originalDimensions.x, replacementDimensions.x),
    height: getMinimalProportion(originalDimensions.y, replacementDimensions.y),
    depth: getMinimalProportion(originalDimensions.z, replacementDimensions.z)
  }

  // Indicates how similar the bounding boxes are in dimensions
  const proportionScore = proportions.width * proportions.height * proportions.depth

  const distance = originalBB.getCenter(tempVector1).distanceTo(replacementBB.getCenter(tempVector2))

  // The proportion of the original bounding box that is inside the replacement bounding box
  const amountInside = intersectionVolume / replacementVolume

  const similarityScore = BOUNDING_BOX_PROPORTION_SCORE_MULTIPLIER * proportionScore / (distanceScale * Math.pow(distance, DISTANCE_WEIGHT_POW))
  const overlapScore = amountInside * proportionScore

  return _clamp(similarityScore + overlapScore, 0.0, 1.0)
}

/**
 * Finds all mesh matches that can be identified based on similar bounding boxes.
 * For each replacement mesh, the bounding box will be compared to the bounding box of each original mesh.
 * The best match will be returned (if the score is greater than the BOUNDING_BOX_MATCH_THRESHOLD).
 *
 * If the bounding boxes of the two models are passed, and correctOffset is set to true, any possible model offset
 * from the local origin will be corrected. This may help matching models where the model is not centered around (0, 0, 0)
 *
 * All unmatched original meshes and replacement meshes will also be returned.
 */
const findBoundingBoxMatches = (
  originalMeshes: ISceneGraphMesh[],
  replacementMeshes: ISceneGraphMesh[],
  originalModelBoundingBox?: THREE.Box3,
  replacementModelBoundingBox?: THREE.Box3
) => {
  const allPossibleMatches: PossibleMatches = new Map()

  // The distance scale depends on the size of the models, and is used to determine
  // how close or far away two meshes seem to be (relative to the rest of the model)
  let distanceScale = 1.0
  if (originalModelBoundingBox && replacementModelBoundingBox) {
    const originalModelDimensions = originalModelBoundingBox.getSize(tempVector1)
    const replacementModelDimensions = replacementModelBoundingBox.getSize(tempVector2)

    const averageDimensionOriginal = (
      originalModelDimensions.x + originalModelDimensions.y + originalModelDimensions.z
    ) / 3.0

    const averageDimensionReplacement = (
      replacementModelDimensions.x + replacementModelDimensions.y + replacementModelDimensions.z
    ) / 3.0

    distanceScale = DISTANCE_SCALE_MULTIPLIER / Math.min(averageDimensionOriginal, averageDimensionReplacement)
  }

  // NOTE: O(n^2) complexity once again.

  // Iterate over all mesh combinations and determine the scores
  originalMeshes.forEach((originalMesh: ISceneGraphMesh) => {
    // Temporary array for holding possible matches for the current originalMesh,
    // and the best possible match for the current originalMesh.
    // These temporary variables are used to optionally discard certain matches.
    // For example, if a sufficiently good match is found with a particular replacementMesh,
    // other possible matches will be discarded.
    const possibleMatches: Match[] = []
    let bestPossibleMatch: Match | undefined // Undefined if no match is found

    replacementMeshes.forEach((replacementMesh: ISceneGraphMesh) => {
      // Add a new array entry and calculate the score of the current bounding boxes
      const score = calculateBoundingBoxMatchScore(
        originalMesh,
        replacementMesh,
        distanceScale
      )

      const possibleMatch = { originalMesh, replacementMesh, score }

      // Add possible matches to the array of possible matches if
      // 1. there's currently no possible match for this mesh
      // 2. or if the current possible match has a higher score than the stored value for this mesh
      if (
        !allPossibleMatches.has(replacementMesh) ||
        score > (allPossibleMatches.get(replacementMesh) as Match).score
      ) {
        possibleMatches.push(possibleMatch)

        // Keep track of the best possible match
        if (
          !bestPossibleMatch ||
          possibleMatch.score > bestPossibleMatch.score
        ) {
          bestPossibleMatch = possibleMatch
        }
      }
    })

    // Skip the next step if no match is found
    if (!bestPossibleMatch) return

    // If the score of the best match exceeds this threshold, ONLY add this match to the map of all possible matches
    // All other matches will be discarded.
    if (bestPossibleMatch.score > BOUNDING_BOX_SINGLETON_MATCH_THRESHOLD) {
      allPossibleMatches.set(bestPossibleMatch.replacementMesh, bestPossibleMatch)
    } else {
      possibleMatches.forEach(possibleMatch => {
        allPossibleMatches.set(possibleMatch.replacementMesh, possibleMatch)
      })
    }
  })

  // Find actual matches
  const matches: Match[] = []
  const unmatchedOriginalMeshes: ISceneGraphMesh[] = [...originalMeshes] // Add all original meshes at first, to delete matches later
  const unmatchedReplacementMeshes: ISceneGraphMesh[] = [] // Unmatched meshes will be added to this array

  // Iterate over all possible matches and keep those over the threshold. Also track the unmatched meshes
  allPossibleMatches.forEach((bestMatch, replacementMesh) => {
    // If the best match has a score exceeding BOUNDING_BOX_MATCH_THRESHOLD, keep the match
    if (bestMatch.score > BOUNDING_BOX_MATCH_THRESHOLD) {
      matches.push(bestMatch)

      // Remove the original mesh from the unmatched array
      unmatchedOriginalMeshes.splice(unmatchedOriginalMeshes.indexOf(bestMatch.originalMesh))
    } else {
      // If no match for this replacement mesh, add to unmatched array
      unmatchedReplacementMeshes.push(replacementMesh)
    }
  })

  return {
    unmatchedOriginalMeshes,
    unmatchedReplacementMeshes,
    matches
  }
}

/**
 * Replaces the appearances on the replacement meshes if they have a match among the original meshes.
 * The appearance will be transferred from mesh1 to mesh2 if the meshes match according to the isMatch function (see above).
 * A mesh can match multiple meshes.
 */
export const reapplyAppearancesAndMaterials = (
  original: ISceneGraphNode3d,
  replacement: ISceneGraphNode3d,
  reapplyAppearanches: boolean = true,
  reapplyMaterials: boolean = true,
  reapplyUsingMetadata: boolean = true,
  reapplyUsingBoundingBoxes: boolean = false
) => {
  return (dispatch: Dispatch<any>) => {
    if (
      (!reapplyAppearanches && !reapplyMaterials) ||
      (!reapplyUsingMetadata && !reapplyUsingBoundingBoxes) ||
      (!canReapplyAppearanceAndMaterial(original) || !canReapplyAppearanceAndMaterial(replacement))
    ) return

    // Creates a flat list of leaf nodes. Only nodes that can have a material will be included
    const getFilteredLeaves = (node: ISceneGraphNode3d, requireSetAppearance = false, leaves: ISceneGraphMesh[] = []) => {
      if (
        (!node.children || !node.children.length) && // Only add leaf nodes
        (node.userData.setMaterial && (node.userData.materialId || !requireSetAppearance)) // Only add nodes that can have and/or currently have an appearance set
      ) {
        leaves.push(node as ISceneGraphMesh)
      }

      node.children.forEach(child => getFilteredLeaves(child, requireSetAppearance, leaves))
      return leaves
    }

    const originalMeshes = getFilteredLeaves(original, true)
    const replacementMeshes = getFilteredLeaves(replacement)

    const matches: Match[] = []
    let unmatchedOriginalMeshes: ISceneGraphMesh[] = originalMeshes
    let unmatchedReplacementMeshes: ISceneGraphMesh[] = replacementMeshes

    // Find matches using node IDs, tags and names
    if (reapplyUsingMetadata) {
      const metadataMatches = findMetadataMatches(
        originalMeshes,
        replacementMeshes
      )

      matches.push(...metadataMatches.matches)
      unmatchedOriginalMeshes = metadataMatches.unmatchedOriginalMeshes
      unmatchedReplacementMeshes = metadataMatches.unmatchedReplacementMeshes
    }

    // Find matches using bounding box overlap and similarities
    if (reapplyUsingBoundingBoxes) {
      const boundingBoxMatches = findBoundingBoxMatches(
        unmatchedOriginalMeshes,
        unmatchedReplacementMeshes,
        original.localBoundingBox,
        replacement.localBoundingBox
      )

      matches.push(...boundingBoxMatches.matches)
      unmatchedOriginalMeshes = boundingBoxMatches.unmatchedOriginalMeshes
      unmatchedReplacementMeshes = boundingBoxMatches.unmatchedReplacementMeshes
    }

    // Roughly indicates how many appearances/materials were transferred in proportion to how many could have been transferred
    // 1.0 means all were transferred, or that the replacementModel is satiated.
    // 0.0 means no were transferred.
    const coverage = (!originalMeshes.length || !replacementMeshes.length)
      ? 0.0
      : _clamp(matches.length / Math.min(originalMeshes.length, replacementMeshes.length), 0.0, 1.0)

    // Indicates how confident the algorithm was in its decisions
    let confidence = 0.0

    // For each match, replace the appearance and calculate the overall confidence score
    matches.forEach(({ originalMesh, replacementMesh, score }) => {
      // Assign appearance (and color, and pattern, if exists)
      // (material refers to apperances in this context. DPD naming conventions are confusing)
      if (
        originalMesh.userData.materialId &&
        replacementMesh.userData.setMaterial &&
        reapplyAppearanches
      ) {
        dispatch(fromMaterials.transferMaterial(
          originalMesh, replacementMesh
        ))
      }

      // Assign material (carriers refers to materials. Again, confusing naming conventions)
      const carrierId = originalMesh.userData.carrier_id
      if (
        carrierId &&
        reapplyMaterials
      ) {
        dispatch(fromMaterials.setCarrier(
          carrierId,
          { [replacementMesh.uuid]: replacementMesh } // setCarrier expects this format
        ))
      }

      confidence += score / matches.length
    })

    dispatch(fromThreeViewerUI.forceUpdate())
    dispatch(fromThreeViewerUI.forceUpdateTree())

    return {
      numberOfMatches: matches.length,
      coverage, // How many appearances/materials were re-applied divided by the number of meshes evaluated
      confidence, // The average score of all matches, indicating how reliable the matches were
    }
  }
}
