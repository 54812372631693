import React from 'react'
import { FieldWrapper, inputTextColor } from '.'
import Button from '../common/button'
import Email from '../common/form/input-email'
import Password from '../common/form/input-password'

const LoginFieldset = () => (
  <div>
    <FieldWrapper>
      <Email
        required
        name="email"
        placeholder="E-mail"
        style={{ color: inputTextColor }}
      />
      <Password
        required
        placeholder="Password"
        name="password"
        style={{ color: inputTextColor }}
      />
    </FieldWrapper>
    <div className="flex justify-center">
      <Button btnType="primary">Login</Button>
    </div>
  </div>
)

export default LoginFieldset
