import React, { Component } from 'react'
import PropTypes from 'prop-types'

class Tabs extends Component {
  constructor (props) {
    super(props)

    this.state = { active: props.active }
    this.toggleActiveTab = this.toggleActiveTab.bind(this)
    this.toggleDefaultTab = this.toggleDefaultTab.bind(this)
  }

  toggleActiveTab (activeTabId) {
    if (this.props.setToDefault) {
      this.setState({
        active: this.props.default
      })
    }

    if (activeTabId !== this.state.active) {
      this.setState({
        active: activeTabId
      })
    }
  }

  toggleDefaultTab () {
    this.setState({
      active: this.props.default
    })
  }

  render () {
    return (
      <div
        className={`width-100 ${this.props.className}`}
        style={this.props.style}
      >
        {this.props.children(
          this.state.active,
          this.toggleActiveTab,
          this.toggleDefaultTab
        )}
      </div>
    )
  }
}

Tabs.propTypes = {
  active: PropTypes.number,
  default: PropTypes.number
}

Tabs.defaultProps = {
  className: '',
  style: {},
  active: 0,
  default: 0
}

export default Tabs
