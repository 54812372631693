import React from 'react'

const IconReplace = props => (
  <svg width='1em' height='1em' viewBox='0 0 20 18' {...props}>
    <path
      d='M3.171 14.863a9.001 9.001 0 0 0 15.77-4.832h-2.016A7.002 7.002 0 0 1 4.59 13.443l1.65-1.65c.196-.196.128-.38-.145-.41l-5.364-.596c-.276-.03-.474.172-.444.444l.596 5.365c.03.276.218.336.409.145l1.878-1.878zm13.513-11.89A9 9 0 0 0 1 9.028h2V9a7 7 0 0 1 12.267-4.61l-1.913 1.913c-.196.195-.128.379.145.41l5.365.595c.276.03.474-.171.444-.444l-.596-5.365c-.03-.275-.218-.336-.409-.145l-1.619 1.619z'
      fill={props.color}
    />
  </svg>
)

IconReplace.defaultProps = {
  color: 'currentcolor'
}

export default IconReplace
