import React from 'react'

import Modal from '../../common/modal'
import FormRemove from '../../common/form/form-remove'

type Props = {
  isOpen: boolean
  onRequestClose: () => void
  width: number
  formTitle: string
  formDescription: string
  onCancel: () => void
  onConfirm: () => void
  buttonText: string
  selectedColor: string
}

const RemoveColorModal = (props: Props) => {
  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      shouldCloseOnOverlayClick
      width={props.width}
    >
      <FormRemove
        formTitle={props.formTitle}
        formDescription={props.formDescription}
        onCancel={props.onCancel}
        onConfirm={props.onConfirm}
        buttonText={props.buttonText}
      />
    </Modal>
  )
}

export default RemoveColorModal
