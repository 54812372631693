import * as fromThreeviewerSelectors from '../../threeviewer/selectors'
import _get from 'lodash/get'

export function place (
  instanceId,
  isComplementary,
  combinationType
) {
  return (dispatch, getState) => {
    const state = getState()
    const viewer = fromThreeviewerSelectors.getViewer(state)
    const activeTemplateId = _get(state, 'templates.activeId', '')
    const activeRoomset = _get(state, ['roomsets', 'entries', activeTemplateId])

    let origoTranslation = new viewer.THREE.Vector3()

    if (isComplementary) {
      origoTranslation = viewer.viewerUtils.getObjectPlacement(viewer.picker, viewer.camera)
      origoTranslation.y = 0
    }

    // If we are opening a convert from Project view we should
    // use the default template origin coords for placement.
    if (!isComplementary && combinationType === 'convert') {
      origoTranslation.fromArray(_get(state, `templates.templates.json.${activeTemplateId}.origo`, [0, 0, 0]))

      if (activeRoomset) {
        origoTranslation.fromArray(_get(state, ['roomsets', 'placementCoordinate'], [0, 0, 0]))
      }
    }

    placeCombination({
      viewer,
      instanceId,
      isComplementary,
      inRoomset: !!activeRoomset,
      origoTranslation
    })
  }
}

export function placeCombination ({
  viewer,
  instanceId,
  isComplementary,
  origoTranslation,
  inRoomset = false,
  origoChanged = false
}) {
  const rootModelsToPlace = []
  viewer.scene.children.forEach(node => {
    if (
      node.userData.instanceId === instanceId &&
      node.userData.isCombination
    ) {
      node.traverse(child => {
        if (child.userData.isModelRoot && !rootModelsToPlace.includes(child)) rootModelsToPlace.push(child)
      })
    }
  })

  const bb = viewer.viewerUtils.getBoundingBox(rootModelsToPlace)
  const center = new viewer.THREE.Vector3()
  const size = new viewer.THREE.Vector3()
  bb.getCenter(center)
  bb.getSize(size)

  const halfSizeX = size.x * 0.5
  const halfSizeY = size.y * 0.5
  const halfSizeZ = size.z * 0.5
  const offset = 0.1

  rootModelsToPlace.forEach(node => {
    if (origoChanged) {
      return node.position.add(origoTranslation)
    }

    if (
      (isComplementary || node.userData.hasDefaultTransform) &&
      node.userData.modelSource !== 'imagepackage'
    ) {
      node.position.sub(center)
      node.position.add(origoTranslation)

      // Move object towards camera in x/z-axis to avoid objects being placed in roomset walls
      if (inRoomset || viewer.roomManager.roomObject) {
        node.position.x = viewer.camera.position.x > origoTranslation.x
          ? node.position.x + halfSizeX + offset
          : node.position.x - halfSizeX - offset
        node.position.z = viewer.camera.position.z > origoTranslation.z
          ? node.position.z + halfSizeZ + offset
          : node.position.z - halfSizeZ - offset
      }

      // Place object on (or above) floor
      node.position.y = node.position.y + halfSizeY
    }
  })
}
