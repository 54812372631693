import React from 'react'

import Grid from '../common/grid/grid'

const Main = ({ children }) => (
  <Grid
    className='relative overflow-auto'
    rows={['1fr', 'auto']}
    gridGap={0}
  >
    <div className='absolute flex flex-column justify-between width-100 height-100'>
      {children}
    </div>
  </Grid>
)

Main.defaultProps = {}

export default Main
