import React, { PureComponent } from 'react'
import autoBind from 'react-autobind'

import _get from 'lodash/get'
import _find from 'lodash/find'
import _findIndex from 'lodash/findIndex'
import _filter from 'lodash/filter'
import _orderBy from 'lodash/orderBy'

import Modal from '../common/modal'
import FormRemove from '../common/form/form-remove'
import Button from '../common/button'

import Preview from './preview'
import Overview from './overview'

import { tabs } from './constants'
import { PROJECT_TYPES } from '../../constants'
import getImageDownloadFile from '../../utils/getImageDownloadFile'

class Gallery extends PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      hasError: false,
      activeId: props.activeId,
      tab: tabs.PREVIEW,
      selectedIds: [],
      isRemoveModalOpen: false,
      isSendToDnpModalOpen: false
    }

    autoBind(this)
  }

  componentDidCatch (error) {
    console.error(error)
    this.setState({ hasError: true })
  }

  handleShowPreviewById (id) {
    this.setState({
      activeId: id,
      tab: tabs.PREVIEW
    })
  }

  handleShowPreview () {
    this.setState({
      tab: tabs.PREVIEW
    })
  }

  handleShowOverview () {
    this.setState({
      tab: tabs.OVERVIEW
    })
  }

  handleShowComments () {
    this.setState({
      tab: tabs.COMMENTS
    })
  }

  getCommentCount () {
    const activeRender = this.props.renders.find((render) => {
      return render.id === this.state.activeId
    }) || this.props.renders[0]
    return activeRender.commentsCount
  }

  handleOpenModal (ids) {
    this.setState(() => ({
      isRemoveModalOpen: true,
      selectedIds: [].concat(ids)
    }))
  }

  handleCloseModal (ids) {
    this.setState({
      isRemoveModalOpen: false
    }, () => {
      const hasDeletedRenders = Array.isArray(ids)
      if (this.props.projectType === PROJECT_TYPES.IMAGE_PACKAGE && hasDeletedRenders) {
        const renders = this.props.renders
        const activeRender = _find(renders, { id: this.state.activeId })

        // find next active render
        const versions = renders.filter((render) => render.originalCombinationId === activeRender.originalCombinationId)
        const versionsWithoutActive = _filter(versions, (version) => version.combinationId !== activeRender.combinationId)
        const orderedVersionsWithoutActive = _orderBy(versionsWithoutActive, ['timestamp'], ['desc'])
        const nextVersion = orderedVersionsWithoutActive[0]
        const nextRender = _find(renders, { combinationId: nextVersion.combinationId })

        if (activeRender.isDefault) {
          this.props.setImagePackageFinalVersion(nextRender.combinationId)
        }
        this.handleSelectVersionInPreview(nextRender.id)
      }
    })
  }

  handleOpenSendToDnpModal () {
    this.setState(() => ({ isSendToDnpModalOpen: true }))
  }

  handleCloseSendToDnpModal () {
    this.setState({ isSendToDnpModalOpen: false })
  }

  handleSendtoDnp () {
    const { activeId } = this.state
    const { currentProjectId, onSendToDnp } = this.props

    onSendToDnp(currentProjectId, [activeId])
    this.handleCloseSendToDnpModal()
  }

  handleRemoveRenders () {
    const ids = this.state.selectedIds
    const activeId = this.state.activeId
    const numberOfRenders = this.props.renders.length

    if (!PROJECT_TYPES.IMAGE_PACKAGE && ids.includes(activeId)) {
      this.setState(() => ({
        activeId: this.props.renders[0].id
      }))
    }

    this.props.onRemove(ids)
    this.handleCloseModal(ids)

    if (numberOfRenders === ids.length) {
      this.props.onBack()
    }
  }

  handleSelectVersionInPreview (id) {
    this.setState({
      activeId: id
    })
  }

  handleSelectInNav (id) {
    this.setState({
      activeId: id
    })
  }

  getActiveNavIndex () {
    const { renders, finalRenders, projectType } = this.props
    const activeRender = _find(renders, { id: this.state.activeId })

    if (projectType === PROJECT_TYPES.DEFAULT || activeRender.isDefault) {
      return _findIndex(finalRenders, { id: this.state.activeId })
    }

    return _findIndex(finalRenders, { originalCombinationId: activeRender.originalCombinationId })
  }

  getActiveRender () {
    return this.props.renders.find((render) => {
      return render.id === this.state.activeId
    }) || this.props.renders[0]
  }

  getActiveRenderVersions () {
    const { renders } = this.props
    const activeRender = this.getActiveRender()
    const originalCombinationId = activeRender.originalCombinationId
    const versions = renders.filter((render) => render.originalCombinationId === originalCombinationId)
    return versions
  }

  handlePrev () {
    const { finalRenders } = this.props
    const activeIndex = this.getActiveNavIndex()

    const nextIndex = activeIndex === 0
      ? finalRenders.length - 1
      : activeIndex - 1

    this.setState({
      activeId: _get(finalRenders, [nextIndex, 'id'])
    })
  }

  handleNext () {
    const { finalRenders } = this.props
    const activeIndex = this.getActiveNavIndex()

    const nextIndex = activeIndex === finalRenders.length - 1
      ? 0
      : activeIndex + 1

    this.setState({
      activeId: _get(finalRenders, [nextIndex, 'id'])
    })
  }

  handleUpKeyEvent () {
    const versions = this.getActiveRenderVersions()
    const activeVersionIndex = _findIndex(versions, (version) => (version.id === this.state.activeId))

    const nextIndex = activeVersionIndex === 0
      ? versions.length - 1
      : activeVersionIndex - 1

    this.setState({
      activeId: _get(versions, [nextIndex, 'id'])
    })
  }

  handleDownKeyEvent () {
    const versions = this.getActiveRenderVersions()
    const activeVersionIndex = _findIndex(versions, (version) => (version.id === this.state.activeId))

    const nextIndex = activeVersionIndex === versions.length - 1
      ? 0
      : activeVersionIndex + 1

    this.setState({
      activeId: _get(versions, [nextIndex, 'id'])
    })
  }

  handleDownloadImagePackage (renders) {
    const files = []
    renders.forEach((render) => {
      const file = getImageDownloadFile(render, `${this.props.designCardTitle}_${render.combinationTitle}`)
      if (file) files.push(file)
    })

    if (files.length) {
      this.props.onDownloadImages(files, this.props.designCardTitle)
    }
  }

  render () {
    const {
      backToText,
      projectId,
      renders,
      finalRenders,
      currentProjectId,
      projectType,
      designCardTitle,
      onDownloadSpreadsheet,
      onDownloadImages,
      onBack,
      combinationMasterId,
      isCompleteBatchRendered
    } = this.props

    const {
      hasError,
      tab,
      selectedIds,
      isRemoveModalOpen,
      isSendToDnpModalOpen
    } = this.state

    if (hasError) {
      return (
        <div
          className='bg-gray-dark c-white flex-column flex justify-center items-center'
          style={{ height: '100vh' }}
        >
          <div className='f4'>
            Something went wrong :(
          </div>

          <Button
            onClick={onBack}
            btnType='primary'
            className='mt3'
          >
            Go back
          </Button>
        </div>
      )
    }

    return ([
      <div
        key='content'
        className='bg-gray-dark'
        style={{ height: '100vh' }}
      >
        {tab === tabs.OVERVIEW && (
          <Overview
            key='overview'
            projectType={projectType}
            onPreview={this.handleShowPreview}
            onPreviewById={this.handleShowPreviewById}
            onOverview={this.handleShowOverview}
            onComments={this.handleShowComments}
            tab={this.state.tab}
            commentsCount={this.getCommentCount()}
            projectId={projectId}
            designCardTitle={designCardTitle}
            renders={projectType === PROJECT_TYPES.IMAGE_PACKAGE ? finalRenders : renders}
            onRemove={this.handleOpenModal}
            onDownloadSpreadsheet={() => onDownloadSpreadsheet([this.state.activeId])}
            onDownloadImages={onDownloadImages}
            onDownloadImagePackage={this.handleDownloadImagePackage}
            onBack={onBack}
            backToText={backToText}
            currentProjectId={currentProjectId}
            combinationMasterId={combinationMasterId}
          />
        )}

        {tab !== tabs.OVERVIEW && (
          <Preview
            key='preview'
            projectType={projectType}
            onPreview={this.handleShowPreview}
            onOverview={this.handleShowOverview}
            onComments={this.handleShowComments}
            tab={this.state.tab}
            currentProjectId={currentProjectId}
            designCardTitle={designCardTitle}
            renders={renders}
            finalRenders={finalRenders}
            projectId={projectId}
            activeId={this.state.activeId ? this.state.activeId : this.props.activeId}
            onSelectedVersion={this.handleSelectVersionInPreview}
            onSelectInNav={this.handleSelectInNav}
            onNext={this.handleNext}
            onPrev={this.handlePrev}
            onUpKeyEvent={this.handleUpKeyEvent}
            onDownKeyEvent={this.handleDownKeyEvent}
            onRemove={this.handleOpenModal}
            onDownloadSpreadsheet={onDownloadSpreadsheet}
            onDownloadImages={onDownloadImages}
            onDownloadImagePackage={this.handleDownloadImagePackage}
            onBack={onBack}
            backToText={backToText}
            render={this.getActiveRender()}
            onClickSendToDnp={this.handleOpenSendToDnpModal}
            isCompleteBatchRendered={isCompleteBatchRendered}
          />
        )}
      </div>,
      <Modal
        key='modal'
        isOpen={isRemoveModalOpen}
        onRequestClose={this.handleCloseModal}
        width={600}
      >
        <FormRemove
          formTitle='Remove image'
          formDescription={`Are you sure you want to remove ${selectedIds.length === 1 ? 'this image?' : `${selectedIds.length} images?`}`}
          onConfirm={this.handleRemoveRenders}
          onCancel={this.handleCloseModal}
          buttonText='Remove'
        />
      </Modal>,
      <Modal
        key='modal-send-to-dnp'
        isOpen={isSendToDnpModalOpen}
        onRequestClose={this.handleCloseSendToDnpModal}
        width={600}
      >
        <FormRemove
          formTitle={'Share to DNP'}
          formDescription={'The image will become available in the connected DNP project. Are you sure you want this? It is not possible to take it back.'}
          onConfirm={this.handleSendtoDnp}
          onCancel={this.handleCloseSendToDnpModal}
          buttonText='Share'
        />
      </Modal>
    ])
  }
}

export default Gallery
