import React, { useState } from 'react'

// A form with a single input field. Can show a value (props.value) that can be updated externally (example: position from redux state)
// external updates only apply when the field is not focused
export function FormSingleInputField (props: { readOnly: boolean, value: string, borderColor: string, testid?:string, fieldName: string, onSubmitCallback: (arg: number)=>void }) {
  const [fieldValue, setFieldValue] = useState('-')

  const [allowExternalChange, setAllowExternalChange] = useState(true)
  if (allowExternalChange && props.value !== fieldValue) { // only change the field value externally if allowed and the update does not match the current value
    setFieldValue(props.value)
  }

  return <>
    <div className='m1'>{props.fieldName}</div>
    <form onSubmit={(event) => {
      event.preventDefault()
      setFieldValue(props.value)
      props.onSubmitCallback(+fieldValue)
    }}>

      {inputField({
        name: props.fieldName,
        readOnly: props.readOnly,
        testid: props.testid,
        borderColor: props.borderColor,
        value: fieldValue,
        setter: setFieldValue,
        width: '4em',
        onFocus: () => { setAllowExternalChange(false) },
        onBlur: () => { setAllowExternalChange(true) }
      })}
    </form>
  </>
}

// A form with three input fields, Can show values (props.value) that can be updated externally (example: position from redux state)
// external updates only apply when the none of the three fields are focused
export function FormThreeInputFields (props: { readOnly: boolean, propX: string, propY: string, propZ: string, onSubmitCallback: (argX: number, argY: number, argz: number) => void }) {
  const [x, setX] = useState('-')
  const [y, setY] = useState('-')
  const [z, setZ] = useState('-')

  const [allowExternalChange, setAllowExternalChange] = useState(true)
  if (allowExternalChange) { // only change the field value externally if allowed and the update does not match the current value
    if (props.propX !== x) {
      setX(props.propX)
    }
    if (props.propY !== y) {
      setY(props.propY)
    }
    if (props.propZ !== z) {
      setZ(props.propZ)
    }
  }

  return <>
    <form
      // When any of the input fields are focused, don't update the field values unless the user types in changes
      onFocus={() => {
        setAllowExternalChange(false)
      }}

      // Only set allow external change to true when none of the three fields are focused
      onBlur={(event) => {
        const currTarget = event.currentTarget
        const currTargetParent = currTarget.parentNode
        if (currTargetParent === null) return
        setTimeout(function () {
          if (!currTargetParent.contains(document.activeElement)) {
            setAllowExternalChange(true)
          }
        }, 0)
      }}

      onSubmit={(event) => {
        event.preventDefault()
        props.onSubmitCallback(+x, +y, +z)
        if (isNaN(+x)) {
          setX(props.propX)
        }
        if (isNaN(+y)) {
          setY(props.propY)
        }
        if (isNaN(+z)) {
          setZ(props.propZ)
        }
      }}>

      <div className='flex items-center c-gray f8'>

        <input type="submit" hidden={true}></input>

        <div className='m1'>{'x'}</div>
        { inputField({
          name: 'x',
          testid: 'inputFieldX',
          readOnly: props.readOnly,
          borderColor: '#ff0000',
          value: x,
          setter: setX,
          width: '5em'
        }) }

        <div className='m1'>{'y'}</div>
        { inputField({
          name: 'y',
          testid: 'inputFieldY',
          readOnly: props.readOnly,
          borderColor: '#00ff00',
          value: y,
          setter: setY,
          width: '5em'
        }) }

        <div className='m1'>{'z'}</div>
        { inputField({
          name: 'z',
          testid: 'inputFieldZ',
          readOnly: props.readOnly,
          borderColor: '#0000ff',
          value: z,
          setter: setZ,
          width: '5em'
        }) }

      </div>
    </form>

  </>
}

function inputField (props: {name: string, testid?:string, readOnly: boolean, value: string, borderColor: string, width: string, setter: (v: string) => void, onFocus?: () => void | undefined, onBlur?: () => void | undefined}) {
  return <>
    <input
      readOnly={props.readOnly}
      type="text"
      data-testid={props.testid || 'inputField' }
      className='bc-transparent br-small f8 bg-white-10 c-gray'
      style={{ height: '2em', width: props.width, borderColor: props.borderColor }}
      value={props.value}
      onChange={ (event) => { props.setter(event.target.value) } }
      onFocus={ () => { if (props.onFocus) { props.onFocus() } } }
      onBlur={ () => { if (props.onBlur) { props.onBlur() } } }
    />
  </>
}
