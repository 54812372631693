import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createSelector } from 'reselect'
import autoBind from 'react-autobind'

import _find from 'lodash/find'
import _get from 'lodash/get'

// JSX Components : Common
import Form from '../../../common/form/form'
import Label from '../../../common/form/label'
import InputText from '../../../common/form/input-text'
import Checkbox from '../../../common/form/input-checkbox'
import Button from '../../../common/button'
import KeyboardListener, { KeyCode, KeyBinding } from '../../../common/keyboard-listener'

import * as fromThreeviewerUI from '../../../../stores/ducks/threeviewer/ui'
import * as fromCombinations from '../../../../stores/ducks/combinations'

// Selectors
import * as fromProjectsSelectors from '../../../../stores/ducks/projects/selectors'
import * as fromCombinationsSelectors from '../../../../stores/ducks/combinations/selectors'

class CreateImagePackage extends PureComponent {
  constructor (props) {
    super(props)
    autoBind(this)
    this.state = {
      addBatchToParent: true
    }
  }

  toggleAddBatchToParent () {
    this.setState(prevState => ({
      addBatchToParent: !prevState.addBatchToParent
    }))
  }

  handleConfirmSettings (event) {
    event.preventDefault()
    const title = _get(this, '_title.root.value').trim()
    this.handleCreateImagePackage(title || 'New Image Package')
  }

  handleCreateImagePackage (title) {
    this.props.openLeftPanelTab(this.props.isMaster ? 1 : 0)
    this.props.onClose()

    const {
      combination,
      combinationId,
      isNewImagePackage,
      isMaster,
      projectId,
      projectType,
      batchId,
      imagePackageId
    } = this.props

    const imagePackageSettings = {
      doBatchRender: isMaster,
      projectType,
      batchId,
      imagePackageId,
      extension: 'jpg'
    }

    if (isNewImagePackage || !this.state.addBatchToParent) {
      const masterCombinationId = isNewImagePackage ? combinationId : combination.originalCombinationId
      this.props.createImagePackageMaster(projectId, { masterCombinationId, title })
        .then((id) => {
          return this.props.postRender({
            combinationId: id,
            projectId,
            imagePackageSettings
          })
        })
        .then((combinations) => {
          const combination = _find(combinations, { isMaster: true })
          const url = `/visualize/${projectId}/${combination.projectType}/${combination.id}`
          window.history.pushState(null, null, url)
          this.props.setCurrentCombination(combination.id)
        })
    } else {
      this.props.postRender({
        combinationId,
        projectId,
        imagePackageSettings
      })
        .then((result) => {
          const combination = result.combination || _find(result, { isMaster: true })
          const url = `/visualize/${projectId}/${combination.projectType}/${combination.id}`
          window.history.pushState(null, null, url)
          this.props.setCurrentCombination(combination.id)
        })
    }
  }

  render () {
    const {
      onClose,
      isMaster,
      isNewImagePackage,
      isEditMaster,
      parentCombinationTitle
    } = this.props

    const {
      addBatchToParent
    } = this.state

    const CREATE_BATCH = {
      headline: 'Create Image Package',
      createButton: 'Create Images'
    }

    const CREATE_NEW_IMAGE_PACKAGE = {
      headline: 'New Image Package',
      createButton: CREATE_BATCH.headline
    }

    const CREATE_VERSION = {
      headline: 'Create New Image Version',
      createButton: 'Create Version'
    }

    let headline = CREATE_NEW_IMAGE_PACKAGE.headline
    let createButton = CREATE_NEW_IMAGE_PACKAGE.createButton

    if (!isNewImagePackage && isMaster) {
      headline = CREATE_BATCH.headline
      createButton = addBatchToParent ? CREATE_BATCH.createButton : CREATE_NEW_IMAGE_PACKAGE.createButton
    } else if (!isNewImagePackage && !isMaster) {
      headline = CREATE_VERSION.headline
      createButton = CREATE_VERSION.createButton
    }

    return (
      <div className='line-height'>
        <Form>
          <h2 className='mt0'>
            {headline}
          </h2>
          {isEditMaster &&
            <Checkbox
              name='create new image package'
              label={'Create new image package'}
              className='mt3 mr2 c-black'
              checked={!addBatchToParent}
              onChange={this.toggleAddBatchToParent}
            />
          }
          {(isNewImagePackage || isEditMaster) &&
            <div>
              <Label className='mt3'>
                  Package title
              </Label>
              <InputText
                ref={(node) => (this._title = node)}
                disabled={isNewImagePackage ? !addBatchToParent : addBatchToParent}
                focus
                focusOnUpdate
                placeholder={parentCombinationTitle}
              />
            </div>
          }
          {!isMaster && <div className='mt3 mb2'>
            Watch the creation progress and final image in the <b>Images</b> panel to the left.
          </div>}
          {isMaster && <div className='mt3 mb2'>
            <div className='mb2'>
              The creation process will take some time and you can watch the progress in the <b>Images</b> panel to the left.
            </div>
            <div className='mb2'>
              If you want to edit the pattern details of a specific image, create a new version of that image from the gallery.
            </div>
            <div className='mb2'>
              If you change the style, a new image package will be created to keep track of its images.
            </div>
          </div>}
          <div className='mt2 clearfix flex justify-end items-baseline'>
            <Button type='button' className='mr3' onClick={onClose}>Cancel</Button>
            <Button btnType='primary' onClick={(isNewImagePackage || !addBatchToParent) ? this.handleConfirmSettings : this.handleCreateImagePackage}>
              {createButton}
            </Button>
          </div>
        </Form>
        <KeyboardListener
          bindings={[
            KeyBinding(KeyCode.enter, (isNewImagePackage || !addBatchToParent) ? this.handleConfirmSettings : this.handleCreateImagePackage)
          ]}
        />
      </div>
    )
  }
}

const mapStateToProps = createSelector(
  fromProjectsSelectors.getCurrentId,
  fromCombinationsSelectors.getCurrentEntry,
  (state) => {
    const currentCombination = fromCombinationsSelectors.getCurrentEntry(state)
    const parentId = currentCombination.parentId
    return fromCombinationsSelectors.selectCombinationById(parentId)(state)
  },
  (projectId, combination, parentCombination) => ({
    projectId,
    projectType: combination.projectType,
    combination,
    parentCombinationTitle: parentCombination.title || '',
    isNewImagePackage: !combination.parentId && combination.isMaster,
    isEditMaster: combination.parentId && combination.isMaster,
    hasParent: !!combination.parentId,
    isMaster: combination.isMaster,
    combinationId: combination.id,
    batchId: combination.batchId,
    imagePackageId: combination.imagePackageId
  })
)

const mapDispatchToProps = (dispatch) => {
  return {
    ...bindActionCreators({
      postRender: fromCombinations.postRender,
      createImagePackageMaster: fromCombinations.createImagePackageMaster,
      setCurrentCombination: fromCombinations.setCurrent
    }, dispatch),
    openLeftPanelTab: (index) => dispatch(fromThreeviewerUI.setActiveLeftPanelIndex(index))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateImagePackage)
