import React from 'react'
import styled from 'styled-components'

type Props = React.HTMLAttributes<HTMLDivElement> & {
  active: boolean
}

const Pill = styled.div.attrs<Props>((props) => ({
  className: `${props.className || ''} f7 nowrap`
}))<Props>`
  cursor: pointer;
  background-color: ${(props) => props.active ? 'var(--secondary)' : 'var(--grayLight)'};
  color: ${(props) => props.active ? 'var(--white)' : 'inherit'};
  border-radius: 26px;
  padding: 0.25rem 0.5rem;
`

export default Pill

export const PillLarge = styled(Pill)<Props>`
  border-radius: 8px;
  padding: 0.6rem 1rem;
  &:hover {
    background-color: ${(props) => props.active ? 'var(--todo_0051BA)' : 'var(--grayLightAlt)'}
  }
`
