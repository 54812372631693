import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { createSelector } from 'reselect'
import moment from 'moment'
import { ImmutableObject } from 'seamless-immutable'

import _pick from 'lodash/pick'
import keyBy from 'lodash/keyBy'

import colors from '../../../../css/colors'

import { FaPencilAlt as Pencil } from 'react-icons/fa'
import IconDesignView from '../../common/icons/icon-design-view'
import IconRenders from '../../common/icons/icon-renders'
import IconImageTemplate from '../../common/icons/ImageTemplateIcon'
import {
  MdLanguage as IconGlobal,
  MdFileDownload as IconExport,
  MdErrorOutline as IconError
} from 'react-icons/md'

import EditableText from '../../common/form/editable-text'
import InputCheckbox from '../../common/form/input-checkbox'
import ProgressRadial from '../../common/progress-radial'
import { StorageApiThumbnail } from '../../common/storage-api-image'
import Menu from '../../common/menu'
import IconScene from '../../common/icons/icon-scene'
import Avatar from '../../common/avatar'
import Spinner from '../../common/spinner'

import CardShell from './CardShell'
import type { Item } from './Item'
import { AssetType, GlobalProjectModals } from '..'

import type { Render } from '../../../stores/ducks/renders/Render'
import type { RootState } from '../../../stores/ducks'

import { recreateArJobForExternalView } from '../../../stores/ducks/external'
import { downloadRenderBatch } from '../../../stores/ducks/combinations'
import { getExternalViewByCombinationId } from '../../../stores/ducks/external/selectors'
import { getHasCurrentScopes } from '../../../stores/ducks/users/selectors'
import { getCombinationsWithRender } from '../../../stores/ducks/projects/selectors'
import { getExportByCombinationId } from '../../../stores/ducks/export/selectors'
import { ContentModals } from '../ProjectContent'
import { VirtualProductsViewModalsEnum } from '../virtual-products/VirtualProductsView'

import type { VirtualProductsViewModals } from '../virtual-products/VirtualProductsView'

function Time (props: {
  createdAt: CardProps['item']['createdAt'],
  updated: CardProps['item']['updated'],
}) {
  return (
    <time className='c-gray-accessible f7 truncate'>
      <div className='truncate'>{(props.createdAt && moment(props.createdAt).fromNow()) ||
        (props.updated && moment(props.updated).fromNow())}
      </div>
    </time>
  )
}

function CardCheckbox (props: {
  onChange: (id: string) => void
  itemId: string
  selected: boolean
}) {
  return (
    <div
      // prevent drag events on checkbox
      draggable
      onDragStart={event => {
        event.preventDefault()
        event.stopPropagation()
      }}
    >
      <InputCheckbox
        name={props.itemId}
        className='p1 absolute left-0 top-0'
        checked={props.selected}
        stopPropagation
        onChange={() => props.onChange(props.itemId)}
      />
    </div>
  )
}

const FolderColorDiv = ({ colors }: { colors: string[] }) => (
  <div
    className='flex width-100 b0'
    style={{
      height: 7,
      bottom: 0
    }}
  >
    {colors.map((color, index) => (
      <div
        key={color + index}
        style={{
          backgroundColor: `hsl(${color})`,
          width: `${100 / colors.length}%`
        }}
      />
    ))}
  </div>
)

type CardProps = React.HTMLAttributes<HTMLDivElement> & {
  item: Item
  defaultCursor: string
  contentName: AssetType
  selected: boolean
  isDragging: boolean
  subtitle?: string
  folderColors: string[]
  manifest?: Render['manifest']
  editOnClick: boolean
  onOpenModal: (modal: ContentModals | GlobalProjectModals | VirtualProductsViewModals | null) => void
  showToastMessage: (msg: string) => void
  onSecondaryAction: () => void
  onClickImage: () => void
  onDragStart: (event: React.DragEvent<HTMLDivElement>) => void
  onDragEnd: (event: React.DragEvent<HTMLDivElement>) => void
  onSelect: () => void
  onEdit: (data: { title: string }) => void
  removeItem?: () => void
  removeItemAsAdmin?: () => void
  textIsEditable?: boolean
  selectable?: boolean
  className?: string
  exportEnabled?: boolean
}

const getIsAllRendersDone = createSelector(
  (_: RootState, item: Item) => item,
  getCombinationsWithRender,
  (item, combinationsWithRender) => {
    if (
      item.contentType === 'pattern' ||
      ['convert', 'variant'].includes(item.combinationType || '') ||
      item.savedAsScene
    ) return item.render && item.render.rendered

    if (!item.render || !item.render.rendered) return false

    const combinationsById = keyBy(combinationsWithRender, 'id')
    let batchRenders: ImmutableObject<Render>[] = []
      ;(item.connectedBatchRenderCombinationIds || []).forEach((id) => {
      const batchRenderCombination = combinationsById[id]
      if (batchRenderCombination) {
        batchRenders = [...batchRenders, ...batchRenderCombination.renders]
      }
    })
    const allRenders = [...(item.renders || []), ...batchRenders]
    return allRenders.length > 0 && allRenders.every(render => render.renderedAt)
  }
)

const Card = (props: CardProps) => {
  const dispatch = useDispatch()

  const externalView = useSelector((state: RootState) => getExternalViewByCombinationId(state, props.item.id))
  const externalViewsEnabled = useSelector((state: RootState) => state.external.featureEnabled)
  const userIsAdmin = useSelector((state: RootState) => getHasCurrentScopes('admin')(state))
  const isAllRendersDone = useSelector((state: RootState) => getIsAllRendersDone(state, props.item))

  const userSentBy = useSelector((state: RootState) => props.item.sentBy ? state.users.entries.getIn([props.item.sentBy]) : null)
  const id = props.item.combinationId ? props.item.combinationId : props.item.id

  const exported = useSelector((state: RootState) => getExportByCombinationId(state, id))

  const [hover, setHover] = React.useState(false)

  const geometryTabActive = (props.contentName === AssetType.GEOMETRY)
  const patternTabActive = (props.contentName === AssetType.PATTERNS)
  const variantTabActive = (props.contentName === AssetType.VARIANTS)
  const imageTabActive = (props.contentName === AssetType.IMAGES)
  const sceneTabActive = (props.contentName === AssetType.SCENE)
  const virtualProductTabActive = (props.contentName === AssetType.VIRTUAL_PRODUCT)

  const openVisualizer = variantTabActive
  const shareAsset = geometryTabActive || patternTabActive || variantTabActive || imageTabActive

  const renderPercentage = (props.item.render && props.item.render.percentage) || 0
  const rendered = props.item.render && props.item.render.rendered
  const { deadlineStatus, status } = _pick(props.item.render, ['deadlineStatus', 'status'])
  const didFail = ['Failed', 'Suspended'].includes((deadlineStatus || '') || (status || ''))
  const defaultTitle = props.item.displayName || `Untitled ${props.contentName}`

  const handleRemoveItem = () => {
    if (props.removeItem) return props.removeItem()
  }

  const menuItems = [
    openVisualizer && { onClick: () => props.onClickImage(), text: 'Open in visualizer' },
    shareAsset && { onClick: () => props.onOpenModal(ContentModals.SEND_ASSET), text: 'Send to ...' },
    imageTabActive && !props.item.savedAsScene && { onClick: () => props.onOpenModal(ContentModals.SCENE), text: 'Save as scene' },
    sceneTabActive && !props.item.global && { onClick: () => props.onOpenModal(ContentModals.GLOBAL_SCENE), text: 'Save as global' },
    sceneTabActive && props.removeItem && !props.item.global && { onClick: handleRemoveItem, text: 'Remove' },
    sceneTabActive && props.item.global && userIsAdmin && { onClick: props.removeItemAsAdmin, text: 'Remove as admin' },
    props.item.connectedBatchRenderCombinationIds && {
      onClick: () => dispatch(downloadRenderBatch(props.item.batchParentId || props.item.id)),
      text: 'Download zip'
    },
    (variantTabActive && externalViewsEnabled) && {
      onClick: () => props.onOpenModal(ContentModals.SHARE_EXTERNAL),
      text: (externalView && 'Get external AR link') || 'Create external AR link'
    },
    (variantTabActive && externalViewsEnabled && userIsAdmin && externalView) && {
      onClick: () => dispatch(recreateArJobForExternalView(externalView.id)),
      text: 'Reprocess AR model'
    },
    (variantTabActive && externalViewsEnabled) && {
      onClick: () => props.onOpenModal(ContentModals.EXPORT),
      text: (externalView && 'Download export') || 'Export'
    },
    virtualProductTabActive && {
      onClick: () => props.onOpenModal(VirtualProductsViewModalsEnum.DISCONNECT),
      text: 'Disconnect'
    },
    virtualProductTabActive && {
      onClick: () => props.onOpenModal(VirtualProductsViewModalsEnum.EDIT),
      text: 'Edit'
    },
    virtualProductTabActive && props.exportEnabled && {
      onClick: () => props.onOpenModal(VirtualProductsViewModalsEnum.EXPORT),
      text: 'Export'
    }
  ].filter(Boolean)

  const showMenu = menuItems.length > 0 && (isAllRendersDone || patternTabActive || sceneTabActive || virtualProductTabActive)
  const isBatchRender = !!((props.item && props.item.connectedBatchRenderCombinationIds) || []).length
  const title = isBatchRender ? (props.item.batchRenderTitle || props.item.title) : (props.item.title || defaultTitle)

  return (
    <>
      <CardShell
        onDragStart={props.onDragStart}
        onDragEnd={props.onDragEnd}
        draggable
        className={props.className}
        innerClass='bg-white'
        style={{
          boxShadow: props.selected ? `0 0 0 4px ${colors.secondary}` : ''
        }}
        tooltip={title || defaultTitle}
      >
        <div
          className={`relative ${props.isDragging ? 'grabbable' : props.defaultCursor} border-bottom bc-gray-light`}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <div onClick={props.onClickImage}>
            <StorageApiThumbnail
              src={props.item.thumbnail}
              manifest={props.manifest}
              style={{ borderRadius: '2px 2px 0 0' }}
              className='opacity-hover-lighter'
            />
            {props.item.render && (!rendered || didFail) && (
              <div
                className='p1 absolute right-0 bottom-0'
                style={{ width: 75, height: 75 }}
              >
                <ProgressRadial
                  strokeWidth={8}
                  background={didFail ? colors.errorLightAlt : colors.success}
                  percentage={didFail ? 95 : renderPercentage}
                >
                  <IconRenders />
                </ProgressRadial>
              </div>
            )}

            {props.item.isNew && !exported &&
              <div
                className='p1 absolute bg-primary f7 br-2'
                style={{ left: 8, bottom: 8 }}
                data-testid="is-new-icon"
              >
                New
              </div>
            }

            {(!props.item.isNew && userSentBy) && (
              <div
                className='absolute f7 br-2'
                style={{
                  right: (imageTabActive && !!props.item.imageTemplateId && !!props.item.renders) ? 42 : 8,
                  bottom: 8
                }}
              >
                <Avatar user={userSentBy} size={30} withTooltip tooltipLocation='over' />
              </div>
            )}

            {imageTabActive && props.item.savedAsScene &&
              <div
                className='flex justify-center p1 absolute bg-gray-light f7 br-2'
                style={{ left: 8, bottom: 8 }}
                title='Saved as Scene'
              >
                <IconScene
                  size={24}
                />
              </div>
            }

            {imageTabActive && !!props.item.imageTemplateId && !!props.item.renders && props.item.combinationType !== 'convert' && (
              <div
                className='absolute'
                style={{
                  left: (props.item.render && !rendered) ? 40 : 'initial',
                  right: (props.item.render && !rendered) ? 'initial' : 8,
                  bottom: 8
                }}
                title='Image from Image template'
              >
                <IconImageTemplate
                  size={26}
                  isBatchRender={isBatchRender}
                  iconSize={24}
                  stackStep={6}
                  showStack={props.item.renders.length > 1 || isBatchRender}
                />
              </div>
            )}

            {sceneTabActive && props.item.global && (
              <div
                className='flex justify-center p1 absolute bg-gray-light f7 br-2'
                style={{ left: 8, bottom: 8 }}
                title='Saved as global'
              >
                <IconGlobal
                  size={24}
                />
              </div>
            )}

            {(hover || props.selected) && !props.editOnClick && props.selectable && (
              <CardCheckbox
                onChange={props.onSelect}
                itemId={props.item.id}
                selected={props.selected}
              />
            )}

            {(props.editOnClick && hover) && (
              <>
                <div className='bg-primary-transparent absolute relative top-0 bottom-0 right-0 left-0 width-100 height-100 flex justify-center items-center'>
                  {([AssetType.APPERANCE, AssetType.VIRTUAL_PRODUCT].includes(props.contentName)) &&
                    <div className='flex justify-center items-center'>
                      <Pencil size={24} /><div className='ml2'>Edit</div>
                    </div>
                  }
                  {(![AssetType.APPERANCE, AssetType.VIRTUAL_PRODUCT].includes(props.contentName)) &&
                    <div className='flex justify-center items-center'>
                      <IconDesignView size={24} /><div className='ml2'>Visualize</div>
                    </div>
                  }
                  {(hover || props.selected) && props.selectable &&
                    <CardCheckbox
                      onChange={props.onSelect}
                      itemId={props.item.id}
                      selected={props.selected}
                    />
                  }
                </div>
              </>
            )}

            {(hover && !props.editOnClick && !patternTabActive) && (
              <div
                onClick={(event) => {
                  event.stopPropagation()
                  props.onSecondaryAction!()
                }}
                className='absolute right-0 top-0 bg-primary bg-primary-light-hover br-small m1'
                style={{ height: 40, width: 40 }}
              >
                <div className='m1 flex justify-center items-center'>
                  <IconDesignView size={24} />
                </div>
              </div>
            )}

            {(variantTabActive || virtualProductTabActive) && exported &&
            <div
              onClick={(event) => {
                event.stopPropagation()
                props.onOpenModal(ContentModals.EXPORT)
              }}>
              <div
                className='flex justify-center p1 absolute bg-gray-light f7 br-2'
                style={{ left: 8, bottom: 8 }}
                data-testid="export-finished"
              >
                <IconExport
                  className={exported.exportGltfModel ? 'c-gray-dark' : 'c-gray'}
                  size={20}
                />
              </div>
              {exported && !exported.exportGltfModel && !exported.failed && <div
                className='flex justify-center p1 absolute f7 br-2'
                style={{ left: '18px', bottom: 4 }}
              >
                <Spinner spinnerstyle={{ fontSize: '0.7rem' }}/>
              </div>}
              {exported && exported.failed && <div
                className='flex justify-center p1 absolute f7 br-2'
                style={{ left: '18px', bottom: 4 }}
              >
                <IconError
                  className={`c-error ${exported.exportGltfModel ? 'c-gray-dark' : 'c-gray'}`}
                  size={15}
                />
              </div>}
            </div>
            }
          </div>
        </div>

        <div
          className='relative px1 py1 width-100'
        >
          <div className=''>
            <EditableText
              defaultValue={title}
              onChange={(title) => props.onEdit({ title })}
              editable={props.textIsEditable}
              singleClickEdit
              className='width-100 block py0 px0 f7 bold truncate'
              iconSize={12}
            >
              {(value, props) => (
                <div {...props}>{value}</div>
              )}
            </EditableText>

            {props.item.currentVersion && (
              <div className='flex justify-between pr2'>
                <Time createdAt={props.item.createdAt} updated={props.item.updated} />
                <div className='m0 f7 c-gray-accessible truncate' style={{ paddingBottom: '0.2rem' }}>
                  ver. {props.item.currentVersion}
                </div>
              </div>
            )}

            {!props.item.currentVersion && (
              <Time createdAt={props.item.createdAt} updated={props.item.updated} />
            )}

          </div>

          {showMenu &&
            <Menu
              // @ts-ignore
              className='absolute right-0 top-0 bottom-0'
              listClassName='brtl-2'
              buttonClassName='brbr-2 bg-white bg-gray-lighter-hover'
              position='topLeft'
              items={menuItems}
            />
          }

        </div>
        <FolderColorDiv colors={props.folderColors} />
      </CardShell>
    </>
  )
}
Card.defaultProps = {
  defaultCursor: 'pointer',
  selectable: true,
  textIsEditable: false
}

export default Card
