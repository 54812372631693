import fetch from '../../utils/fetch'

const assertError = (msg) => (err) => {
  console.error(msg)
  console.error(err)
  throw err
}

export const storage = (files, name) => {
  return (
    fetch('/api/download-zip/prepare', {
      method: 'POST',
      body: JSON.stringify({ files, name })
    })
      .then(res => res.json())
      .then(({ downloadId }) => {
        window.location = `/api/download-zip/download/${downloadId}`
      })
      .catch(assertError('unable to download files'))
  )
}
