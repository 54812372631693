import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import cs from 'classnames'

import * as fromAdmin from '../../../stores/ducks/admin'
import * as fromAdminSelectors from '../../../stores/ducks/admin/selectors'
import * as fromMaterialSearch from '../../../stores/ducks/material-search'

import _xor from 'lodash/xor'

import AppearanceModal from './appearance-modal'
import Modal from '../../common/modal'
import FormRemove from '../../common/form/form-remove'

import Content from '../../project/project-components/GeneralContent'
import Spinner from '../../common/spinner'
import UploadAppearance from './UploadAppearance'
import { MdDelete as IconTrash } from 'react-icons/md'
import Button from '../../common/button'
import Grid from '../../common/grid/grid'
import InputSearch from '../../common/form/input-search'
import InputCheckbox from '../../common/form/input-checkbox'
import NotificationBubble from '../../common/notification-bubble'

const AdminAppearances = () => {
  const [isAppearanceModalOpen, setIsAppearanceModalOpen] = useState(false)
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false)
  const [selectedAppearence, setSelectedAppearence] = useState(undefined)

  const [appearancesSearchString, setAppearancesSearchString] = useState('')
  const [appearancesSearchResults, setAppearancesSearchResults] = useState([])

  const [allSelected, setAllSelected] = useState(false)
  const [selectIds, setSelectIds] = useState([])
  const [hasSelection, setHasSelection] = useState(false)
  const [errorMsg, setErrorMsg] = useState(null)

  const dispatch = useDispatch()
  const { adminAppearances, inProgress } = useSelector(fromAdminSelectors.selectAppearanceUploadStatus)

  const renderingAppearances = useSelector(state => state.admin.adminMaterialsInProgress)
  const adminLoader = useSelector(state => state.admin.adminLoader)

  const handleSearch = (value) => {
    setAppearancesSearchString(value)
  }

  useEffect(() => {
    dispatch(fromAdmin.getAdminMaterials())
    dispatch(fromMaterialSearch.getMaterialCategoriesList())
  }, [])

  useEffect(() => {
    const checkIfCompleted = (Object.values(renderingAppearances).map(m => m.status))
    if (checkIfCompleted.includes('Completed')) {
      dispatch(fromAdmin.getAdminMaterials())
    }
  }, [renderingAppearances])

  useEffect(() => {
    const searchResults = adminAppearances.filter(appearance => {
      return (
        searchNameIndex(appearance.name, appearancesSearchString) ||
        searchCategoryIndex(appearance.categories, appearancesSearchString)
      )
    }
    )
    setAppearancesSearchResults(searchResults)
  }, [appearancesSearchString, adminAppearances])

  function searchNameIndex (name, searchString) {
    return name.toUpperCase().includes(searchString.toUpperCase())
  }

  function searchCategoryIndex (categoires, searchString) {
    const categoiresToUpperCase = categoires.map(category => {
      return category.toUpperCase()
    })

    for (var i = 0; i < categoiresToUpperCase.length; i++) {
      if (categoiresToUpperCase[i].includes(searchString.toUpperCase())) return true
    }
    return false
  }

  useEffect(() => {
    if (selectIds.length > 0) {
      setHasSelection(true)
    } else {
      setHasSelection(false)
    }
  }, [selectIds])

  function handleSelectAll () {
    setSelectIds(adminAppearances.map(render => render.id))
    setAllSelected(true)
  }

  function handleDeselectAll () {
    setSelectIds([])
    setAllSelected(false)
  }

  function onToggleSelectAll () {
    if (allSelected) {
      handleDeselectAll()
    } else {
      handleSelectAll()
    }
  }

  function handleCardSelected (id) {
    if (!id) return
    setSelectIds(_xor([id], selectIds))
  }

  function removeSelected () {
    selectIds.forEach(id => dispatch(fromAdmin.deleteAdminMaterial(id)))
    setIsRemoveModalOpen(false)
    setSelectIds([])
  }

  function getRemoveFormDesc () {
    return `Are you sure you want to remove ${selectIds.length === 1
      ? 'this appearance?'
      : `${selectIds.length} appearances?`}`
  }

  function appearenceData (input) {
    setIsAppearanceModalOpen(true)
    setSelectedAppearence(input)
  }

  function editAppearence (data, { title }) {
    const updateAppearanceObject = {
      id: data,
      name: title,
      displayName: title
    }
    dispatch(fromAdmin.updateAdminMaterial(updateAppearanceObject))
  }

  function closeAppearanceModal () {
    setSelectedAppearence(undefined)
    setIsAppearanceModalOpen(false)
  }
  return (
    <div style={{ height: 'calc(100vh - 270px)' }}>
      <Grid
        columns={['1fr', 'auto']}
        gridGap={24}
        style={{ alignItems: 'center' }}
        className='mb2'
      >
        <InputSearch
          placeholder='Search...'
          value={appearancesSearchString}
          onChange={handleSearch}
          onClearSearch={() => setAppearancesSearchString('')}
        />
        <div className={cs('flex justify-end items-center')}>
          <InputCheckbox
            noMargin
            name='select all'
            label='Select All'
            className='flex items-center'
            checked={allSelected}
            onChange={onToggleSelectAll}
          />
          <IconTrash
            size={24}
            className={cs('c-gray-dark mx2', {
              disabled: !hasSelection,
              pointer: hasSelection
            }
            )}
            onClick={() => setIsRemoveModalOpen(true)}
          />
          <Button
            btnType='primary'
            onClick={() => setIsAppearanceModalOpen(true)}
          >
              Upload appearance
          </Button>
        </div>
      </Grid>
      <div className='height-100 overflow-auto'>
        <Content
          className='pb3'
          folders={[]}
          itemsDone={appearancesSearchResults}
          itemsInProgress={inProgress}
          emptyStateComponent={null}
          uploadComponent={<UploadAppearance supportedFormatMsg={(msg) => setErrorMsg(msg)}/>}
          contentName='appearance'
          editOnClick={true}
          onClickImage={appearenceData}
          onSecondaryAction={undefined}
          onSelect={handleCardSelected}
          selectedIds={selectIds}
          onEdit={editAppearence}
          onDragStart={() => {}}
          onDragEnd={() => {}}
          isDragging={false}
        />
        {adminLoader && <div style={{ textAlign: 'center' }}><Spinner /></div>}
      </div>
      <AppearanceModal
        isOpen={isAppearanceModalOpen}
        onClose={closeAppearanceModal}
        data={selectedAppearence}
      />
      <Modal
        key='modal-remove'
        isOpen={isRemoveModalOpen}
        onRequestClose={() => setIsRemoveModalOpen(false)}
        width={600}
      >
        <FormRemove
          formTitle='Remove appearances'
          formDescription={getRemoveFormDesc()}
          onConfirm={removeSelected}
          onCancel={() => setIsRemoveModalOpen(false)}
          buttonText='Remove'
        />
      </Modal>
      <NotificationBubble
        error={errorMsg}
        onClearOrTimeout={() => {
          setErrorMsg(null)
        }}
      />
    </div>
  )
}

export default AdminAppearances
