const THREE = require('three')

const SSAOShader = {
  uniforms: {
    tDiffuse: { value: null },
    tDepth: { value: null },
    size: { value: new THREE.Vector2(512, 512) },
    cameraNear: { value: 1 },
    cameraFar: { value: 100 },
    onlyAO: { value: 0 },
    aoClamp: { value: 0.5 },
    lumInfluence: { value: 0.5 },
    radius: { value: 1 },
    strength: { value: 1 },
    projectionFactor: { value: 1.0 },
    uvScale: { value: 1.0 },
    inverseProjection: { value: new THREE.Matrix4() },
    seed: { value: 1.0 },
    alpha: { value: 1.0 }
  },

  vertexShader: `
    varying vec2 vUv;

    void main() {
      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
    }
  `,

  fragmentShader: `
    uniform float cameraNear;
    uniform float cameraFar;
    uniform bool onlyAO;        // use only ambient occlusion pass?
    uniform vec2 size;          // texture width, height
    uniform float aoClamp;      // depth clamp - reduces haloing at screen edges
    uniform float lumInfluence; // how much luminance affects occlusion
    uniform sampler2D tDiffuse;
    uniform highp sampler2D tDepth;
    uniform mat4 inverseProjection;

    varying vec2 vUv;

    //#define PI 3.14159265
    #define DL 2.399963229728653  // PI * ( 3.0 - sqrt( 5.0 ) )
    #define EULER 2.718281828459045

    // user variables

    const int samples = 8;
    uniform float radius;
    uniform float strength;
    uniform float projectionFactor;

    uniform float uvScale;

    uniform float seed;
    uniform float alpha;

    // RGBA depth

    #include <packing>

    vec3 get_viewspace_pos(vec2 uv) {
      float fragCoordZ = texture2D(tDepth, uv).x;
      vec4 clip = vec4(uv * 2.0 - 1.0, fragCoordZ, 1.0);
      vec4 view = inverseProjection * clip;
      return view.xyz / view.w;

      //float fragCoordZ = texture2D(tDepth, uv).x;
      //float z = perspectiveDepthToViewZ( fragCoordZ, cameraNear, cameraFar );
      //float x = fragCoordZ * (1.0 - projectionMatrix[0][2] - 2.0 * uv.x) / projectionMatrix[0][0];
      //float y = fragCoordZ * (1.0 + projectionMatrix[1][2] + 2.0 * uv.y) / projectionMatrix[1][1];
      //return vec3(x, y, z);
    }

    vec3 approximateNormal(sampler2D depthSampler, vec2 uv) {
      float dx = 1.0 / size.x;
      float dy = 1.0 / size.y;
      vec3 center = get_viewspace_pos(uv);
      vec3 right = get_viewspace_pos(vec2(uv.x + dx, uv.y));
      vec3 up = get_viewspace_pos(vec2(uv.x, uv.y + dy));
      right -= center;
      up -= center;
      return normalize(cross(right, up));
    }

    float rng_state = 0.0;
    #define TAU 6.28318530718

    float rand(){
        float v = fract(sin(rng_state) * 43758.5453123);
        rng_state = rng_state + v;
        return v;
    }

    vec2 uniform_disc() {
        float r = sqrt(rand());
        float theta = rand() * TAU;

        return vec2(cos(theta), sin(theta)) * r;
    }

    vec3 alchemyAO(vec2 uv) {
      float beta = 0.01;

      float sum = 0.0;

      vec3 p = get_viewspace_pos(uv);
      vec3 n = approximateNormal(tDepth, uv);

      float r = radius * projectionFactor / -p.z;

      for(int i = 0; i < samples; i++) {
        // sample point on disk
        vec2 s = uniform_disc() * r;
        // get view space position
        vec3 sp = get_viewspace_pos(uv + s);
        vec3 v = (sp - p);

        float vdn = dot(v, n);
        float vdv = dot(v, v);

        // reject samples outside hemisphere
        if(vdn < 0.0) continue;

        sum += max(0.0, (vdn + p.z * beta) / (vdv + 0.001));
      }
      float obscurance = (2.0 * strength / float(samples)) * sum;
      return vec3(max(0.0, 1.0 - obscurance));
      //return vec3(viewZToOrthographicDepth(p.z, cameraNear, cameraFar));
      //return vec3(0.5) + n * 0.5;
    }

    void main() {
      vec2 uv = vUv * uvScale;
      rng_state = seed * uv.x * uv.y * 3.71235247232 + uv.x + uv.y + seed;

      //vec4 color = texture2D( tDiffuse, uv );
      vec3 final = alchemyAO(uv);

      gl_FragColor = vec4( final, alpha );

    }
`
}

const BlurShader = {

  uniforms: {
    tDiffuse: { value: null },
    tDepth: { value: null },
    size: { value: new THREE.Vector2(512, 512) },
    uvScale: { value: 1.0 },
    step: { value: new THREE.Vector2(0, 0) }
  },

  vertexShader: `
    varying vec2 vUv;

    void main() {
      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
    }
  `,

  fragmentShader: `
    uniform sampler2D tDiffuse;
    uniform highp sampler2D tDepth;
    uniform vec2 size;
    uniform vec2 step;

    varying vec2 vUv;

    uniform float uvScale;

    vec3 blur(vec2 uv) {
      vec3 blur = vec3(0.0);
      blur += texture2D( tDiffuse, uv ).xyz;
      float depth = texture2D( tDepth, uv ).r;

      for(int i = 1; i <= 5; i++) {
        vec2 s = float(i) * step;
        blur += texture2D( tDiffuse, uv + s).xyz;
        blur += texture2D( tDiffuse, uv - s).xyz;
      }
      return blur / 11.0;
    }

    void main() {
      vec2 uv = vUv * uvScale;
      gl_FragColor = vec4(blur(uv), 1.0);
    }
`
}

const ComposeShader = {

  uniforms: {
    tDiffuse: { value: null },
    tAo: { value: null },
    uvScale: { value: 1.0 },
    onlyAO: { value: 0 }
  },

  vertexShader: `
    varying vec2 vUv;

    void main() {
      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
    }
  `,

  fragmentShader: `
    uniform sampler2D tDiffuse;
    uniform sampler2D tAo;
    uniform bool onlyAO;

    varying vec2 vUv;
    uniform float uvScale;

    void main() {
      vec2 uv = vUv * uvScale;

      vec4 ao = texture2D(tAo, vUv);
      vec4 color = texture2D(tDiffuse, uv);
      vec4 final = color * ao;
      if(onlyAO) {
        final = ao;
      }

      gl_FragColor = vec4(final.rgb, color.a);
    }
`
}

export default class AlchemyAOPostProcess {
  constructor (renderer, width, height) {
    this.ssaoPass = new THREE.ShaderPass(SSAOShader)
    this.blurPass = new THREE.ShaderPass(BlurShader)
    this.composePass = new THREE.ShaderPass(ComposeShader)

    this.ssaoPass.material.extensions.derivatives = true
    this.enabled = false
    if (!renderer.capabilities.isWebGL2) {
      this.derivatives = renderer.extensions.get('OES_standard_derivatives')
      renderer.getContext().hint(this.derivatives.FRAGMENT_SHADER_DERIVATIVE_HINT_OES, renderer.getContext().NICEST)
    }

    this.aoBuffer = null
    this.accumulate = true

    this.width = width
    this.height = height
    this.blur = false
    this.iteration = 1
    this.ssaoPass.uniforms.tDepth.value = this.depthTexture
    this.ssaoPass.uniforms.size.value.set(width, height)
    this.ssaoPass.uniforms.onlyAO.value = false
    this.ssaoPass.uniforms.aoClamp.value = 0.6
    this.ssaoPass.uniforms.lumInfluence.value = 0.8
    this.ssaoPass.material.transparent = true

    this.originalLumInfluence = 0.8
    this.originalAoClamp = 0.6

    this.throttledAoClampModifier = 1.1
    this.throttledLumInfluenceModifier = 1.1

    this.needsUpdate = false
  }

  render (renderer, buffers, camera) {
    var input = buffers.input
    var output = buffers.output

    if (!this.aoBuffer) {
      // /, type: THREE.FloatType
      this.aoBuffer = new THREE.WebGLRenderTarget(this.width, this.height, { depthBuffer: false, format: THREE.RGBA })
    }
    // First create ssao
    this.ssaoPass.uniforms.tDepth.value = input.depthTexture
    this.blurPass.uniforms.tDepth.value = input.depthTexture
    this.ssaoPass.uniforms.cameraNear.value = camera.near
    this.ssaoPass.uniforms.cameraFar.value = camera.far

    this.ssaoPass.uniforms.inverseProjection.value.copy(camera.projectionMatrix).invert()

    // TODO: Review how this factor should be calculated.
    const pf = new THREE.Vector4(1, 0, -1, 1).applyMatrix4(camera.projectionMatrix).x
    this.ssaoPass.uniforms.projectionFactor.value = pf

    if (this._dirty) {
      renderer.setRenderTarget(this.aoBuffer)
      renderer.clear()
      this.iteration = 1
      this._dirty = false
    }

    let aoBuffer = this.aoBuffer
    if (this.accumulate) {
      const gl = renderer.getContext()
      // update the seed to have temporally different sampling.
      this.ssaoPass.uniforms.seed.value += 0.1
      aoBuffer = this.aoBuffer
      gl.enable(gl.BLEND)
      gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA)
      this.ssaoPass.uniforms.alpha.value = 1.0 / this.iteration
      this.iteration++
    }

    this.ssaoPass.render(renderer, aoBuffer, input, 0.0, false)

    if (this.blur) {
      this.blurPass.uniforms.step.value.set(1.0 / this.width, 0.0)
      this.blurPass.render(renderer, output, this.aoBuffer, 0.0, false)
      this.blurPass.uniforms.step.value.set(0.0, 1.0 / this.height)
      this.blurPass.render(renderer, this.aoBuffer, output, 0.0, false)
    }

    this.composePass.uniforms.tAo.value = aoBuffer.texture
    this.composePass.render(renderer, output, input, 0.0, false)

    buffers.input = output
    buffers.output = input
  }

  resetAccumulation () {
    this._dirty = true
  }

  setSize (width, height) {
    this.ssaoPass.uniforms.size.value.set(width, height)
    if (this.aoBuffer) {
      this.aoBuffer.setSize(width, height)
    }
  }

  setUVScale (uvScale) {
    this.ssaoPass.uniforms.uvScale.value = uvScale
    this.composePass.uniforms.uvScale.value = uvScale
  }

  get throttledAoClamp () {
    return this.throttledAoClampModifier
  }

  set throttledAoClamp (value) {
    this.throttledAoClampModifier = value
  }

  get throttledLumInfluence () {
    return this.throttledLumInfluenceModifier
  }

  set throttledLumInfluence (value) {
    this.throttledLumInfluenceModifier = value
  }

  get onlySSAO () {
    return this.ssaoPass.uniforms.onlyAO.value
  }

  set onlySSAO (value) {
    this.composePass.uniforms.onlyAO = { value }
  }

  get aoClamp () {
    return this.ssaoPass.material.uniforms.aoClamp.value
  }

  set aoClamp (value) {
    this.ssaoPass.material.uniforms.aoClamp = { value }
  }

  get lumInfluence () {
    return this.ssaoPass.material.uniforms.lumInfluence.value
  }

  set lumInfluence (value) {
    this.ssaoPass.material.uniforms.lumInfluence = { value }
  }
}
