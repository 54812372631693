import { Object3D, Vector2 } from 'three'
import { mod, createWallNode } from './helpers'
import type { Walls, Wall } from './Walls'

const wallNodeObject = createWallNode()

export class WallNode {
  index: number;
  object = wallNodeObject.clone();

  constructor (index: number) {
    this.index = index
  }

  set (x: number, y: number) {
    this.object.position.set(x, 0, y)
  }
}

export class WallNodes {
  points: Vector2[] = [];
  wallNodes: WallNode[] = [];
  private _pointToObject = new Map<Vector2, Object3D>();
  private _objectToPoint = new Map<Object3D, Vector2>();
  private _objectToWallNode = new Map<Object3D, WallNode>();
  private _indexToWallNode = new Map<number, WallNode>();
  private _pointToWallNodes = new Map<Vector2, WallNode>();

  indexToWallNode (index: number) {
    return this._indexToWallNode.get(index)
  }

  objectToWallNode (object: Object3D) {
    return this._objectToWallNode.get(object)
  }

  register (point: Vector2, wallNode: WallNode, index: number) {
    this._pointToObject.set(point, wallNode.object)
    this._objectToPoint.set(wallNode.object, point)
    this._objectToWallNode.set(wallNode.object, wallNode)
    this._indexToWallNode.set(index, wallNode)
    this._pointToWallNodes.set(point, wallNode)
    this.wallNodes.push(wallNode)
  }

  deregister (wallNode: WallNode) {
    const point = this._objectToPoint.get(wallNode.object)

    this._objectToPoint.delete(wallNode.object)
    this._objectToWallNode.delete(wallNode.object)

    if (!point) { return }

    const removedAtIndex = this.points.indexOf(point)

    this._indexToWallNode.forEach(wallNode => {
      if (wallNode.index > removedAtIndex) {
        wallNode.index = wallNode.index - 1
      }
    })

    this._indexToWallNode.delete(removedAtIndex)
    this._pointToWallNodes.delete(point)
    this._pointToObject.delete(point)

    this.points = this.points.filter(p => p !== point)
    this.wallNodes = this.wallNodes.filter(c => c !== wallNode)
  }

  add (point: Vector2) {
    this.points.push(point)
    const index = this.points.length - 1
    const wallNode = new WallNode(index)
    this.register(point, wallNode, index)
    return wallNode
  }

  insert (from: number, point: Vector2, walls: Walls) {
    const points = [...this.points]
    points.splice(from + 1, 0, point)

    this._indexToWallNode.clear()

    this.wallNodes.forEach((wallNode) => {
      const oldIndex = wallNode.index
      const point = this.points[oldIndex]
      const newIndex = points.indexOf(point)
      wallNode.index = newIndex
      this._indexToWallNode.set(newIndex, wallNode)
    })

    const index = points.indexOf(point)
    const prev = Math.max(index - 1, 0)

    walls.sort()

    walls.values().forEach((wall) => {
      const from1 = points.indexOf(this.at(wall.from))
      const to1 = points.indexOf(this.at(wall.to))

      walls.move(
        wall,
        [wall.from, wall.to],
        [from1, (wall.from === prev) ? index : to1]
      )
    })

    const wallNode = new WallNode(index)
    wallNode.set(point.x, point.y)
    this.register(point, wallNode, index)

    this.points = points

    return wallNode
  }

  remove (wallNode: WallNode, walls: Walls) {
    const index = wallNode.index
    const points = [...this.points]
    points.splice(index, 1)

    let removedWall

    walls.sort()

    walls.values().forEach((wall) => {
      const from1 = points.indexOf(this.at(wall.from))
      let to1 = points.indexOf(this.at(wall.to))

      if (from1 === -1) {
        removedWall = wall
        walls.deregister(wall)
        return
      }

      if (to1 === -1) {
        to1 = mod(Math.max(wall.to, from1 + 1), points.length)
      }

      walls.move(
        wall,
        [wall.from, wall.to],
        [from1, to1]
      )
    })

    this.wallNodes = this.wallNodes.filter(c => c !== wallNode)

    this._indexToWallNode.clear()

    this.wallNodes.forEach((wallNode) => {
      const oldIndex = wallNode.index
      const point = this.points[oldIndex]
      const newIndex = points.indexOf(point)
      wallNode.index = newIndex
      this._indexToWallNode.set(newIndex, wallNode)
    })

    const point = this._objectToPoint.get(wallNode.object)

    this._objectToPoint.delete(wallNode.object)
    this._objectToWallNode.delete(wallNode.object)

    if (point) {
      this._pointToWallNodes.delete(point)
      this._pointToObject.delete(point)
    }

    this.wallNodes = this.wallNodes.filter(c => c !== wallNode)
    this.points = points

    return removedWall as undefined | Wall
  }

  values () {
    return this.points
  }

  at (index: number) {
    return this.points[index]
  }

  size () {
    return this.points.length
  }

  clear () {
    this.points = []
    this._pointToObject.clear()
    this._objectToPoint.clear()
    this._objectToWallNode.clear()
    this._indexToWallNode.clear()
    this._pointToWallNodes.clear()
  }

  toSchemaObjects () {
    return this.points.map((point) => ({
      x: point.x,
      y: 0,
      z: point.y
    }))
  }
}
