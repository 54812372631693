const noop = () => {}

const createSnappingCommand = ({
  object,
  newValue,
  oldValue,
  afterExecute = noop,
  afterUndo = noop
}) => ({
  undo () {
    object.rotation.copy(oldValue.rotation)
    object.position.copy(oldValue.position)

    afterUndo()
  },

  execute () {
    object.rotation.copy(newValue.rotation)
    object.position.copy(newValue.position)

    afterExecute()
  }
})

module.exports = createSnappingCommand
