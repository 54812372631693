import React from 'react'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'

import moment from 'moment'

import _findIndex from 'lodash/findIndex'
import _orderBy from 'lodash/orderBy'
import _isUndefined from 'lodash/isUndefined'

import AddComment from '../../common/comments/add-or-update'
import Comment from './comment'
import CommentCounter from '../../common/comments/comment-counter'

import * as fromComments from '../../../stores/ducks/comments'
import * as fromLikes from '../../../stores/ducks/likes'
import * as fromGallery from '../../../stores/ducks/gallery'

import * as fromGallerySelectors from '../../../stores/ducks/gallery/selectors'
import * as fromCommentsSelectors from '../../../stores/ducks/comments/selectors'
import * as fromLikesSelectors from '../../../stores/ducks/likes/selectors'
import * as fromUsersSelectors from '../../../stores/ducks/users/selectors'

function CommentsContainer (props) {
  const rootRef = React.useRef()
  const commentsLength = React.useRef(props.comments.length)

  React.useEffect(() => {
    props.focusComment(props.expandAddComment)
  }, [props.expandAddComment])

  React.useEffect(() => {
    if (props.isFetched && commentsLength.current < props.comments.length) {
      window.requestAnimationFrame(() => {
        rootRef.current.scrollTo(0, rootRef.current.scrollHeight)
      })
    }
    commentsLength.current = props.comments.length
  }, [props.isFetched, props.comments.length])

  function handleAdd (comment) {
    props.createComment({
      comment,
      parentEntityId: props.id,
      parentEntityType: 'renders',
      position: props.position
    })
      .then(() => props.onClearTemporaryAnchor())
  }

  function handleRemove (id) {
    const removeData = {
      id: id,
      parentEntityId: props.activeRenderId
    }
    props.removeComment(removeData)
  }

  function handleExpandComment () {
    props.focusComment(true)
  }

  function handleCollapseComment () {
    props.focusComment(false)
    props.onClearTemporaryAnchor()
  }

  function handleLike (id) {
    const comment = props.flatComments.find((comment) => comment.id === id)

    if (comment) {
      const like = {
        parentEntityId: id,
        parentEntityType: 'comments',
        rootEntityId: comment.parentEntityId,
        rootEntityType: comment.parentEntityType
      }

      props.likeComment(like)
    }
  }

  return (
    <>
      <div
        className='overflow-y-scroll pr1'
        ref={rootRef}
      >

        {props.comments.map((comment) => (
          <Comment
            {...comment}
            key={comment.id}
            onCommentFocus={props.focusComment}
            isSelected={props.selectedId === comment.id}
            activeRenderId={props.id}
            currentUserId={props.currentUserId}
            onAdd={props.createComment}
            onEdit={props.editComment}
            onRemove={handleRemove}
            onToggleSelect={() => props.onToggleSelect(comment.id)}
            onLike={handleLike}
          />
        ))}
      </div>
      <div className='mr2 ml1'>
        {(props.position && props.selectedId === 'temporary') && (
          <div className='f7 flex items-center pt2 mbn1'>
            Write a comment for
            <CommentCounter
              className='ml1'
              size={16}
              style={{ fontSize: 8 }}
              isActive
              count={props.nextPlacedCommentIndex}
            />
          </div>
        )}
        <AddComment
          expanded={props.expandAddComment}
          onExpand={() => handleExpandComment()}
          onCancel={() => handleCollapseComment()}
          onSubmit={handleAdd}
          lightBorder={false}
        />
      </div>
    </>
  )
}

const getComments = (state, comments, likes, parentId) => {
  const _comments = comments
    .filter((comment) => parentId ? comment.parentId === parentId : !comment.parentId)
    .map((comment) => {
      const commentLikes = (likes || []).filter((like) => like.parentEntityId === comment.id)
      return comment.merge({
        likedByCurrentUser: !!commentLikes.find(like => like.userId === fromUsersSelectors.getCurrentUserId(state)),
        author: fromUsersSelectors.getById(comment.userId)(state),
        timestamp: Date.parse(comment.createdAt),
        createdAt: moment(comment.createdAt).fromNow(),
        likes: commentLikes,
        replies: comment.parentId ? [] : getComments(state, comments, likes, comment.id)
      })
    })

  return _orderBy(_comments, 'timestamp', ['asc'])
}

const mapStateToProps = (state, ownProps) => createSelector(
  fromCommentsSelectors.getEntriesByParentEntityId(ownProps.id),
  fromCommentsSelectors.getStatus,
  fromLikesSelectors.getEntriesByRootEntityId(ownProps.id),
  fromGallerySelectors.getTemporaryAnchor,
  fromGallerySelectors.getSelectedCommentId,
  fromGallerySelectors.getPosition,
  (comments, commentStatus, likes, temporaryAnchor, selectedCommentId, position) => {
    const _comments = getComments(state, comments, (likes || []).filter((like) => !like.removedAt))
    const placedComments = _comments.filter((comment) => comment.position)
    return {
      isFetched: commentStatus === fromComments.status.FETCHED,
      expandAddComment: !!temporaryAnchor,
      selectedId: selectedCommentId,
      position: position,
      flatComments: comments,
      nextPlacedCommentIndex: placedComments.length + 1,
      comments: _comments.map((comment) => {
        const index = _findIndex(placedComments, (c) => c.id === comment.id)
        return _isUndefined(index) ? comment : comment.merge({ index: index + 1 })
      }),
      currentUserId: fromUsersSelectors.getCurrentUserId(state)
    }
  }
)(state)

const mapDispatchToProps = (dispatch) => ({
  focusComment: (payload) => dispatch(fromGallery.focusComment(payload)),
  createComment: (payload) => dispatch(fromComments.create(payload)),
  editComment: (payload) => dispatch(fromComments.update(payload)),
  removeComment: (payload) => dispatch(fromComments.remove(payload)),
  likeComment: (payload) => dispatch(fromLikes.toggleLike(payload)),
  onToggleSelect: (id) => dispatch(fromGallery.toggleSelectPlacedComment(id)),
  onSelectCommentAnchor: (id) => dispatch(fromGallery.selectCommentAnchor(id)),
  onClearTemporaryAnchor: () => dispatch(fromGallery.clearTemporaryAnchor())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommentsContainer)
