import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import _find from 'lodash/find'
import _map from 'lodash/map'

import * as fromCommunicate from '../../../stores/ducks/news'

import colors from '../../../../css/colors'

import Button from '../../common/button'
import AddEditPostModal from './add-edit-post-modal'
import PostList from './post-list'
import EmptyState from '../../common/empty-state/empty-state'
import PreviewPostModal from './preview-post-modal'
import RemovePostModal from './remove-post-modal'
import { MdNotifications as IconCommunicate } from 'react-icons/md'

import * as fromAdminSelectors from '../../../stores/ducks/admin/selectors'
import * as fromNewsSelectors from '../../../stores/ducks/news/selectors'

interface AdminArticle {
  title?: string,
  popupTitle?: string,
  popupBody?: string,
  content?: any,
  removedContent?: any,
  publish?: boolean,
  remove?: boolean
}

const AdminCommunicate = () => {
  const adminArticles = useSelector(fromNewsSelectors.getAdminArticlesByUpdate)
  const isCurrentUserAdmin = useSelector(fromAdminSelectors.selectIsCurrentUserAdmin)
  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(fromCommunicate.fetchAll())
  }, [])

  const [isAddEditModalOpen, setIsAddEditModalOpen] = React.useState(false)
  const [isPreviewOpen, setIsPreviewOpen] = React.useState(false)
  const [isConfirmRemoveOpen, setIsConfirmRemoveOpen] = React.useState(false)
  const [currentPostId, setCurrentPostId] = React.useState('')

  const _handleCloseAddEdit = () => {
    setIsAddEditModalOpen(false)
    setCurrentPostId('')
  }

  const _handleClosePreview = () => {
    setIsPreviewOpen(false)
    setCurrentPostId('')
  }

  const _handleCloseRemove = () => {
    setIsConfirmRemoveOpen(false)
    setCurrentPostId('')
  }

  return (
    <div>
      <div className='flex justify-end'>
        <Button
          btnType='primary'
          className='flex-none'
          onClick={() => setIsAddEditModalOpen(true)}
        >
          Write new
        </Button>
      </div>

      {Object.entries(adminArticles).length === 0 && (
        <EmptyState
          className='mt4'
          title='Create a post to get started'
          icon={<IconCommunicate size={48} color={colors.grayDark} />}
          desc='There are currently no posts to list. Click "Write new" to get started.'
        />
      )}

      {Object.entries(adminArticles).length >= 1 && (
        <PostList
          posts={_map(adminArticles, (article) => article)}
          onDelete={(postId: string) => {
            setCurrentPostId(postId)
            setIsConfirmRemoveOpen(true)
          }}
          onEdit={(postId: string) => {
            setCurrentPostId(postId)
            setIsAddEditModalOpen(true)
          }}
          onPreview={(postId: string) => {
            setIsPreviewOpen(true)
            setCurrentPostId(postId)
          }}
          onPublish={(postId: string) => dispatch(fromCommunicate.updateAdminArticle(postId, { publish: true } as any))}
        />
      )}
      {isAddEditModalOpen && (
        <AddEditPostModal
          currentPost={_find(adminArticles, ['id', currentPostId])}
          isOpen={isAddEditModalOpen}
          onRequestClose={_handleCloseAddEdit}
          onCreate={(value: any) => {
            dispatch(fromCommunicate.createAdminArticle(value))
            _handleCloseAddEdit()
          }}
          onUpdate={({
            title,
            popupTitle,
            popupBody,
            content,
            removedContent,
            publish,
          }: AdminArticle) => {
            dispatch(fromCommunicate.updateAdminArticle(currentPostId, {
              title,
              popupTitle,
              popupBody,
              publish,
            } as any))
            content.forEach((item: any) => {
              if (item.id.match(/^new/)) {
                dispatch(fromCommunicate.addAdminContent(currentPostId, item.type, item.body))
              }
              dispatch(fromCommunicate.updateAdminContent(currentPostId, item.id, { body: item.body }))
            })
            removedContent.forEach((id: string) => {
              dispatch(fromCommunicate.removeAdminContent(currentPostId, id))
            })
            _handleCloseAddEdit()
          }}
          onUploadContentImage={(value: string) => dispatch(fromCommunicate.uploadImageContent(value))}
        />
      )}

      {isPreviewOpen && (
        <PreviewPostModal
          isOpen={isPreviewOpen}
          isCurrentUserAdmin={isCurrentUserAdmin}
          article={_find(adminArticles, ['id', currentPostId])}
          onRequestClose={_handleClosePreview}
          onPublish={(postId: string) => {
            dispatch(fromCommunicate.updateAdminArticle(postId, { publish: true } as any))
            _handleClosePreview()
          }}
        />
      )}

      {isConfirmRemoveOpen && (
        <RemovePostModal
          isOpen={isConfirmRemoveOpen}
          title={_find(adminArticles, ['id', currentPostId]).title}
          onRequestClose={_handleCloseRemove}
          onRemove={() => {
            dispatch(fromCommunicate.updateAdminArticle(currentPostId, { remove: true } as any))
            _handleCloseRemove()
          }}
        />
      )}
    </div>
  )
}

export default AdminCommunicate
