var THREE = require('three')

const SafeFrameShader = {
  uniforms: {
    tDiffuseBackground: { value: null },
    uScreenSize: { value: new THREE.Vector2(0, 0) },
    uGuardIntensity: { value: 0.5 },
    safeFrameWidth: { value: 50 },
    safeFrameHeight: { value: 50 },
    uSafeFrameColor: { value: new THREE.Color(1, 1, 0) }
  },

  vertexShader: `
    varying vec2 vUv;

    void main() {
      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
  `,

  // TODO: We could probably speed this one up by feeding in safeFrameWidth/safeFrameHeight in uv-coordinates (and omit screenSize)
  fragmentShader: `
    varying vec2 vUv;
    uniform sampler2D tDiffuseBackground;
    uniform vec2 uScreenSize;
    uniform float uGuardIntensity;
    uniform vec3 uSafeFrameColor;
    uniform float safeFrameWidth;
    uniform float safeFrameHeight;

    void main() {
      vec2 screenCoord = vUv * uScreenSize;
      vec2 mid = vec2(0.5) * uScreenSize;
      vec4 bgcolor = texture2D(tDiffuseBackground, vUv);

      bool inX = screenCoord.x >= (mid.x - safeFrameWidth) && screenCoord.x <= (mid.x + safeFrameWidth);
      bool inY = screenCoord.y >= (mid.y - safeFrameHeight) && screenCoord.y <= (mid.y + safeFrameHeight);

      vec4 maskColor = (inX && inY) ? vec4(1.0) : vec4(1.0 - uGuardIntensity);
      vec4 outcolor = vec4(bgcolor.rgb * maskColor.rgb, bgcolor.a * maskColor.a);

      if (abs(abs(screenCoord.x - mid.x) - safeFrameWidth) <= 0.5 && abs(screenCoord.x) < uScreenSize.x - 1.0 && abs(screenCoord.x) > 1.0) {
        outcolor = vec4(uSafeFrameColor, 1.0);
      }
      if (abs(abs(screenCoord.y - mid.y) - safeFrameHeight) <= 0.5 && abs(screenCoord.y) < uScreenSize.y - 1.0 && abs(screenCoord.y) > 1.0) {
        outcolor = vec4(uSafeFrameColor, 1.0);
      }

      gl_FragColor = outcolor;
    }
  `
}

class SafeFramePostProcess {
  constructor (width, height, resolutions) {
    this.safeFramePass = new THREE.ShaderPass(SafeFrameShader, 'tDiffuseBackground')
    this.safeFramePass.uniforms.uGuardIntensity.value = 0.6
    this.safeFramePass.uniforms.uScreenSize.value.set(width, height)
    this.resolutions = resolutions
    this.safeFrameRes = 0 // Will call recalculate
    this.enabled = false
  }

  set safeFrameRes (value) {
    this.safeFrameResValue = value // TODO: Clone value?
    this.recalculate()
  }

  setSize (width, height) {
    var screenSize = this.safeFramePass.uniforms.uScreenSize.value

    if (width === screenSize.x && height === screenSize.y) {
      return
    }

    screenSize.set(width, height)
    this.recalculate()
  }

  get safeFrameHeight () {
    return this.safeFramePass.uniforms.safeFrameHeight.value
  }

  get safeFrameWidth () {
    return this.safeFramePass.uniforms.safeFrameWidth.value
  }

  recalculate () {
    var screenSize = this.safeFramePass.uniforms.uScreenSize.value
    const value = this.safeFrameResValue
    let _value = value.x && value.y ? value : this.resolutions[value]
    if (!_value || _value.y === 0) {
      _value = { x: 1, y: 1 }
    }
    const ratio = _value.x / _value.y

    let w
    let h

    if (ratio > 1) {
      if (screenSize.y <= screenSize.x / ratio) {
        w = (screenSize.y / 2) * ratio
        h = (screenSize.y / 2)
      } else {
        w = (screenSize.x / 2)
        h = (screenSize.x / 2) / ratio
      }
    } else if (ratio < 1) {
      if (screenSize.x <= screenSize.y * ratio) {
        w = (screenSize.x / 2)
        h = (screenSize.x / 2) / ratio
      } else {
        w = (screenSize.y / 2) * ratio
        h = (screenSize.y / 2)
      }
    } else {
      if (screenSize.y > screenSize.x) {
        w = (screenSize.x / 2)
        h = (screenSize.x / 2)
      } else {
        w = (screenSize.y / 2)
        h = (screenSize.y / 2)
      }
    }

    this.safeFramePass.uniforms.safeFrameWidth.value = w
    this.safeFramePass.uniforms.safeFrameHeight.value = h
  }

  render (renderer, buffers) {
    var output = buffers.output
    var input = buffers.input

    this.safeFramePass.render(renderer, output, input, 0, false)

    buffers.input = output
    buffers.output = input
  }
}

module.exports = SafeFramePostProcess
