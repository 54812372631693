import _get from 'lodash/get'

import store from '../../stores'

import * as fromProjects from '../../stores/ducks/projects'
import * as fromCombinations from '../../stores/ducks/combinations'
import * as fromRenders from '../../stores/ducks/renders'
import * as fromProjectView from '../../stores/ducks/interface/project-view'

import show from '../show-notification'

import { isAccessibleToUser } from '../utils'

export default function handleRenders (msg) {
  const {
    doc,
    combinationTitle,
    noStatusChange,
    combinations = [],
    project = {}
  } = msg

  const {
    renderStatus,
    deadlineStatus,
    processed
  } = doc

  if (combinations && processed && project?.id) {
    combinations.forEach(combination => {
      store.dispatch(fromProjectView.setFinishedCombination({ projectId: project.id, type: combination.combinationType, id: combination.id }))
    })
  }

  store.dispatch(fromRenders.receive([doc]))
  store.dispatch(fromCombinations.receive(combinations))
  store.dispatch(fromProjects.receive([project]))

  const isConversionThumbnail = /thumbnail/.test(msg.doc.template)

  if (
    !msg.uniqueDevice ||
    noStatusChange ||
    isConversionThumbnail ||
    !isAccessibleToUser(doc, project && project.sharedWith)
  ) {
    return
  }

  let level = 'success'
  let statusMsg = ''

  if (renderStatus === 'Rendered') {
    statusMsg = 'was successfully rendered'
  }

  if (['Initial', 'Queued'].includes(renderStatus)) {
    statusMsg = deadlineStatus === 'Active'
      ? 'is being rendered'
      : 'was queued to render'
  }

  if ([renderStatus, deadlineStatus].includes('Failed')) {
    statusMsg = 'failed render'
    level = 'error'
  }

  const userEmail = _get(msg, 'user.email', 'dpd user')

  const shouldNotify = combinations.filter(c => !c.sentAssetId).length > 0

  if (shouldNotify) {
    show({
      title: `Render in project ${project.title}`,
      body: `Design ${combinationTitle} ${statusMsg} by ${userEmail}`,
      icon: msg.thumbnail,
      tag: msg.tag,
      level: level,
      navigateTo: doc.rendered
        ? `/gallery/${doc.parentCombinationId}?render=${doc.id}${project.id ? `&project=${project.id}` : ''}`
        : (project.id ? `/project/${project.id}/show` : '/')
    })
  }
}
