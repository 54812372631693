import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Modal from '../../common/modal'
import ModalContent from '../../common/form/form-remove'
import Button from '../../common/button'
import { getExternalViewByCombinationId } from '../../../stores/ducks/external/selectors'
import { createExternalViewForVariant } from '../../../stores/ducks/external'
import type { Item } from '../card/Item'
import type { RootState } from '../../../stores/ducks'
import { notify } from '../../../stores/ducks/projects'

type Props = {
  asset: Item | null
  isOpen: boolean
  onClose: () => void
}

export default function ShareExternalModal (props: Props) {
  if (!props.isOpen) return null

  const urlText = React.useRef<HTMLDivElement | null>(null)

  const dispatch = useDispatch()

  const externalView = useSelector((state: RootState) => props.asset ? getExternalViewByCombinationId(state, props.asset.id) : null)
  const urlToExternalViewer = externalView ? `https://${window.location.hostname}/external?id=${externalView.id}` : ''

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(urlToExternalViewer)
    dispatch(notify('Copied share link to clipboard.'))
  }

  const handleStartProcess = async () => {
    if (!props.asset) return
    await dispatch(createExternalViewForVariant(props.asset.id, props.asset.title))
    dispatch(notify(`Started sharing ${props.asset.title}.`))
  }

  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.onClose}
      shouldCloseOnOverlayClick
      width={600}
    >
      <ModalContent
        formTitle='Share variant by link'
        noDescMargin
        onConfirm={props.onClose}
        buttonText='Done'
        formDescription={(
          <>
            {externalView && (
              <div>
                {!externalView.glbModel && (
                  <div className='pb2'>
                    Until the geometry processing is complete, the link will lead to an empty page.
                  </div>
                )}
                <div className='flex width-100'>
                  <div
                    data-testid='share-ar-url'
                    className='width-100 bg-gray-light br-2 mr1 p1 nowrap overflow-x-overlay'
                    ref={urlText}
                  >
                    {urlToExternalViewer}
                  </div>
                  <Button onClick={handleCopyToClipboard}>
                      Copy link
                  </Button>
                </div>
              </div>
            )}

            {!externalView && (
              <div>
                <div className='pb2'>
                  Share the variant with others via a public link. Note that the conversion takes 10-20 minutes.
                </div>
                <div className='flex justify-center'>
                  <Button
                    btnType='primary'
                    onClick={handleStartProcess}
                    data-testid="share-ar-button"
                  >
                      Get share link
                  </Button>
                </div>
              </div>
            )}
          </>
        )}
      />
    </Modal>
  )
}
