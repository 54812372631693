import React from 'react'

import Modal from '../../common/modal'

import FormRemove from '../../common/form/form-remove'

const RemovePostModal = props => (
  <Modal
    isOpen={props.isOpen}
    onRequestClose={props.onRequestClose}
    width={600}
  >
    <FormRemove
      formTitle='Delete post'
      formDescription={`Are you sure you want to delete "${props.title}"?`}
      onConfirm={props.onRemove}
      onCancel={props.onRequestClose}
      buttonText='Delete'
    />
  </Modal>
)

export default RemovePostModal
