import _get from 'lodash/get'
import _curry from 'lodash/curry'

const getLocalState = (state) => _get(state, 'imagePackages')
export const getEntries = (state) => _get(getLocalState(state), 'entries', {})
const getEntryById = _curry((id, state) => _get(getEntries(state), id, {}))
const getCurrentId = state => _get(getLocalState(state), 'currentId', null)
export const getCurrentEntry = (state) => getEntryById(getCurrentId(state), state)
export const getStylesById = _curry((id, state) => _get(getEntryById(id)(state), 'styles', []))
export const getSelectedBatchById = _curry((packageId, batchId, state) => _get(getEntryById(packageId)(state), ['batches', batchId], {}))
