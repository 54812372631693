import fetch from '../../../utils/fetch'

export const getByIds = (ids) => (
  fetch('/api/jobs/getByIds', {
    method: 'POST',
    body: JSON.stringify({ ids })
  })
    .then((res) => res.json())
)

export const removeById = (id) => (
  fetch('/api/jobs/remove', {
    method: 'POST',
    body: JSON.stringify({ id })
  })
    .then((res) => res.json())
)
