import React, { Component } from 'react'
import { connect } from 'react-redux'

import cs from 'classnames'
import autoBind from 'react-autobind'
import navigate from '../../../utils/navigate'

import _isEmpty from 'lodash/isEmpty'
import _get from 'lodash/get'
import _find from 'lodash/find'

import { match } from '../../../utils/match-pathname'
import Grid from '../../common/grid/grid'
import { MdChevronLeft as IconChevronLeft, MdChevronRight as IconChevronRight } from 'react-icons/md'

import Preview from './preview'
import PreviewImagePackage from './image-package'

import TopBar from '../topbar'
import Nav from './nav'

import * as fromGallerySelectors from '../../../stores/ducks/gallery/selectors'
import * as fromUsersSelectors from '../../../stores/ducks/users/selectors'
import * as fromCommentsSelectors from '../../../stores/ducks/comments/selectors'
import * as fromProjectsSelectors from '../../../stores/ducks/projects/selectors'

import {
  PROJECT_TYPES,
  DEADLINE_STATUS,
  ROOMSET_RENDER_PRESETS,
  RENDER_PRESETS
} from '../../../constants'

import css from '../index.css'
import getImageDownloadFile from '../../../utils/getImageDownloadFile'

class PreviewContainer extends Component {
  constructor (props) {
    super(props)
    autoBind(this)

    this.state = {}
  }

  handleVisualize () {
    const projectType = this.props.projectType || PROJECT_TYPES.DEFAULT
    const combinationId = this.props.render.combinationId || this.props.render.id
    const urlType = (projectType === PROJECT_TYPES.DEFAULT) ? 'combination' : projectType

    if (match(`/visualize/${this.props.currentProjectId}/${urlType}/${combinationId}`)) {
      return this.props.onBack()
    }
    navigate(`/visualize/${this.props.currentProjectId}/${urlType}/${combinationId}`)
  }

  handleDownload () {
    const activeRender = this.props.render

    let file = null

    if (this.props.projectType === PROJECT_TYPES.IMAGE_PACKAGE) {
      const { designCardTitle } = this.props
      const versionNumber = this.state.selectedVersionNumber || this.getActiveRenderVersions().length
      const combinationTitle = _get(activeRender, ['combinationTitle'])
      const title = combinationTitle
        ? `${designCardTitle}_${combinationTitle}_${versionNumber}`
        : `${designCardTitle}_${activeRender.createdAt.split('T')[0]}`
      file = getImageDownloadFile(activeRender, title)
    } else {
      // Note: This is because the render data is constructed differently depending on
      // if the gallery is opened from the Project or the Visualize view.
      file = activeRender.render
        ? getImageDownloadFile(activeRender.render, activeRender.title)
        : getImageDownloadFile(activeRender, activeRender.title)
    }

    if (file) {
      const link = document.createElement('a')
      link.href = file.src
      link.download = file.name
      link.click()
    }
  }

  handleSelectedVersion (versionId, versionNumber) {
    this.setState({ selectedVersionNumber: versionNumber })
    this.props.onSelectedVersion(versionId)
  }

  getActiveRenderVersions () {
    const renders = this.props.renders
    const activeRender = this.props.render
    const originalCombinationId = activeRender.originalCombinationId
    const versions = renders.filter((render) => render.originalCombinationId === originalCombinationId)
    return versions
  }

  getActiveNavId () {
    const { projectType, finalRenders } = this.props
    const activeRender = this.props.render

    if (projectType === PROJECT_TYPES.DEFAULT || activeRender.isDefault) {
      return activeRender.id
    }
    const finalRender = _find(finalRenders, { originalCombinationId: activeRender.originalCombinationId })
    return _get(finalRender, 'id')
  }

  isRemoveDisabled (activeRender) {
    return (!activeRender.rendered && activeRender.deadlineStatus !== DEADLINE_STATUS.FAILED) ||
    (activeRender.isDefault && activeRender.rendered) ||
    (activeRender.deadlineStatus === DEADLINE_STATUS.FAILED && activeRender.versions.length === 1)
  }

  render () {
    const {
      backToText,
      renders,
      finalRenders,
      projectType,
      onSelectInNav,
      navDisabled,
      onNext,
      onPrev,
      onUpKeyEvent,
      onDownKeyEvent,
      onDownloadSpreadsheet,
      onRemove,
      tab,
      onPreview,
      onOverview,
      onComments,
      onBack,
      author,
      size,
      resolution,
      quality,
      render,
      comments,
      isDnpProject,
      onClickSendToDnp
    } = this.props
    if (_isEmpty(renders)) return null

    const activeRender = render
    const activeNavId = this.getActiveNavId()
    if (!activeRender) return null
    const isImagePackageProject = projectType === PROJECT_TYPES.IMAGE_PACKAGE
    let versions = []
    if (isImagePackageProject) {
      versions = this.getActiveRenderVersions()
    }

    return (
      <Grid
        rows={[64, 'auto', 108]}
        columns={[64, '1fr', 'auto', 64]}
        areas={[
          'topbar topbar topbar topbar',
          'prev image info next',
          'nav nav nav nav'
        ]}
        className='height-100 overflow-hidden custom-scrollbar-inverted'
        style={{
          gridRowGap: 16,
          gridColumnGap: 0
        }}
      >
        <TopBar
          projectType={projectType}
          onPreview={onPreview}
          onOverview={onOverview}
          onComments={onComments}
          commentsCount={comments.length}
          contentType={activeRender.contentType || activeRender.combinationType || (activeRender.combination && activeRender.combination.combinationType)}
          tab={tab}
          onRemove={() => onRemove(activeRender.render.id)}
          isDownloadDisabled={isImagePackageProject && !activeRender.isRender}
          isRemoveDisabled={isImagePackageProject && activeRender && this.isRemoveDisabled(activeRender)}
          onDownloadSpreadsheet={() => onDownloadSpreadsheet([activeRender.combinationId || activeRender.id])}
          onDownloadImages={this.handleDownload}
          onVisualize={this.handleVisualize}
          onBack={onBack}
          backToText={backToText}
          isDnpProject={isDnpProject}
          isSentToDnp={!!_get(activeRender, 'render.sentToDnp')}
          onClickSendToDnp={onClickSendToDnp}
        />

        <div
          key='prev'
          style={{ gridArea: 'prev' }}
          onClick={onPrev}
          className={cs(css.leftAnimation, 'flex items-center', {
            pointer: finalRenders.length > 1,
            'invisible events-disable': finalRenders.length === 1
          })}
        >
          <IconChevronLeft className='c-white ml2 mr1' size={36} />
        </div>

        {isImagePackageProject && (
          <PreviewImagePackage
            {...activeRender}
            versions={versions}
            onSelectedVersion={this.handleSelectedVersion}
            onVisualize={this.handleVisualize}
            onUpKeyEvent={onUpKeyEvent}
            onDownKeyEvent={onDownKeyEvent}
          />
        )}

        {!isImagePackageProject &&
          <Preview
            combination={activeRender}
            render={activeRender.render || render}
            tab={tab}
            author={author}
            size={size}
            resolution={resolution}
            quality={quality}
            id={activeRender.render.id}
            isCompleteBatchRendered={this.props.isCompleteBatchRendered}
          />
        }

        <div
          key='next'
          style={{ gridArea: 'next' }}
          onClick={onNext}
          className={cs(css.rightAnimation, 'flex justify-end items-center', {
            pointer: finalRenders.length > 1,
            'invisible events-disable': finalRenders.length === 1
          })}
        >
          <IconChevronRight className='c-white mr2 ml1' size={36} />
        </div>

        <Nav
          disabled={navDisabled}
          items={finalRenders}
          onSelect={onSelectInNav}
          activeItemId={activeNavId}
          onNext={onNext}
          onPrev={onPrev}
          isImagePackageProject={isImagePackageProject}
        />
      </Grid>
    )
  }
}

const getAuthor = (user) => {
  if (_isEmpty(user)) return { name: '', email: '' }

  return {
    name: `${user.firstName || ''} ${user.lastName || ''}`.trim(),
    email: user.email
  }
}

const getRenderResolution = ({ x, y }) => {
  return `${x} x ${y} pixels`
}

const getRenderQualityTitle = (renderQuality) => {
  // Does not take in to account custom presets on roomset doc.
  const roomsetPreset = _get(ROOMSET_RENDER_PRESETS, [renderQuality])
  const defaultPreset = _get(RENDER_PRESETS, [renderQuality])
  return _get(roomsetPreset || defaultPreset, 'title', '')
}

const mapStateToProps = (state, ownProps) => {
  const isCommenting = fromGallerySelectors.getIsCommenting(state)
  const { render } = ownProps
  const combination = render
  const user = fromUsersSelectors.getById(render.createdBy)(state)
  const size = _get(render, 'render.cameraSettings.resolution', { x: 0, y: 0 })
  const renderQuality = _get(render, 'render.renderQuality')

  return {
    navDisabled: isCommenting,
    author: getAuthor(user),
    size,
    isDnpProject: fromProjectsSelectors.isDnpProject(state),
    resolution: getRenderResolution(size),
    quality: getRenderQualityTitle(renderQuality),
    comments: fromCommentsSelectors.getEntriesByParentEntityId(combination.render.id, state)
  }
}

export default connect(
  mapStateToProps,
  () => ({})
)(PreviewContainer)
