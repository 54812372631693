import { createAction } from 'redux-actions'

import _get from 'lodash/get'
import _omit from 'lodash/omit'

import navigate from '../../../../utils/navigate'

import * as api from '../api'
import * as fromImagePackages from '../../image-packages'
import * as fromProjectsSelectors from '../../projects/selectors'

import error from './error'
import type { AppThunk } from '../..'
import type { VisualizedCombination, Combination } from '../Combination'
import { Pattern } from '../../patterns/Pattern'

export const confirmReceive = createAction('combinations/CONFIRM_RECEIVE')
export const remove = createAction('combinations/REMOVE')
export const setCurrent = createAction('combinations/SET_CURRENT')

export function receive (data: (Combination | VisualizedCombination)[], params = {}): AppThunk {
  return (dispatch) => dispatch(confirmReceive({ data, params }))
}

export function update (id: string, data: Partial<Combination>): AppThunk {
  return (dispatch) => {
    return api.update({ id, data })
      .then((json) => dispatch(receive(json)))
      .catch((err) => dispatch(error(err)))
  }
}

export function fetchManyForVisualize (ids: string[]): AppThunk<Promise<{
  docs: VisualizedCombination[];
  modelFiles: string[];
  materials: any;
  patterns: Pattern[];
}>> {
  return (dispatch) => {
    return api.visualizeMany(ids)
      .then((result) => {
        dispatch(receive(result.docs))
        return result
      })
  }
}

export function fetchRoomHoleModels (): AppThunk {
  return (dispatch) => {
    return api
      .fetchRoomHoleModels()
      .then((result) => dispatch(receive(result.docs)))
  }
}

export function fetchForVisualize (id: string, params = { initialLoad: false }): AppThunk<Promise<null | VisualizedCombination>> {
  return (dispatch, getState) => {
    return api.visualize(id)
      .then((combination) => {
        const notAllowedToView = (params.initialLoad && !combination)

        if (notAllowedToView) {
          const currentProjectId = fromProjectsSelectors.getCurrentId(getState())
          // TODO:
          // No combination found, should show info to user instead of just navigating to dashboard.
          // Could be that the user is not authorized or that the combination was removed.
          navigate(`/project/${currentProjectId}/show`)
          return null
        }

        if (_get(combination, 'imagePackage')) {
          dispatch(fromImagePackages.receive([combination.imagePackage]))
          dispatch(fromImagePackages.setCurrent(combination.imagePackage.id))
        }

        dispatch(receive([_omit(combination, 'imagePackage')]))
        return combination
      })
  }
}
