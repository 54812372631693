import React from 'react'

const IconDuplicate = props => (
  <svg width='1em' height='1em' viewBox='0 0 22 22' {...props}>
    <g fill={props.color} fillRule='evenodd'>
      <path
        d='M8.511 0H19.6A2.4 2.4 0 0 1 22 2.4v12.311a2.4 2.4 0 0 1-2.4 2.4H8.511a2.4 2.4 0 0 1-2.4-2.4V2.4a2.4 2.4 0 0 1 2.4-2.4zm.045 2.444v12.223h11V2.444h-11z'
        opacity={0.5}
      />
      <path
        d='M2.4 4.889h11.089a2.4 2.4 0 0 1 2.4 2.4V19.6a2.4 2.4 0 0 1-2.4 2.4H2.4A2.4 2.4 0 0 1 0 19.6V7.289a2.4 2.4 0 0 1 2.4-2.4zm.044 2.444v12.223h11V7.333h-11z'
      />
    </g>
  </svg>
)

IconDuplicate.defaultProps = {
  color: 'currentcolor'
}

export default IconDuplicate
