const noop = () => {}

const createPatternTransformCommand = function ({
  decalMaterialsMap,
  newValuesMap,
  oldValuesMap,
  afterExecute = noop,
  afterUndo = noop
}) {
  return {
    undo () {
      Object.keys(oldValuesMap).forEach((key) => {
        if (key === 'userDecalRotation') {
          Object.keys(decalMaterialsMap).forEach((key) => {
            decalMaterialsMap[key].userDecalRotation = oldValuesMap.userDecalRotation
          })
        } else if (key === 'userDecalTranslation') {
          Object.keys(decalMaterialsMap).forEach((key) => {
            decalMaterialsMap[key].userDecalTranslation = oldValuesMap.userDecalTranslation
          })
        } else {
          decalMaterialsMap[key].decalUVTransform = oldValuesMap[key]
        }
      })

      afterUndo()
    },
    execute () {
      Object.keys(oldValuesMap).forEach((key) => {
        if (key === 'userDecalRotation') {
          Object.keys(decalMaterialsMap).forEach((key) => {
            decalMaterialsMap[key].userDecalRotation = newValuesMap.userDecalRotation
          })
        } else if (key === 'userDecalTranslation') {
          Object.keys(decalMaterialsMap).forEach((key) => {
            decalMaterialsMap[key].userDecalTranslation = newValuesMap.userDecalTranslation
          })
        } else {
          decalMaterialsMap[key].decalUVTransform = newValuesMap[key]
        }
      })

      afterExecute()
    }
  }
}

module.exports = createPatternTransformCommand
