import React from 'react'
import Counter from './comment-counter'

type CommentAnchorProps = {
  count: number
  isActive: boolean
  posX: number
  posY: number
  onClick: (event: MouseEvent) => void
}

const CommentAnchor = (props: CommentAnchorProps) => {
  const onClick = React.useCallback((event: MouseEvent) => {
    event.stopPropagation()
    props.onClick(event)
  }, [])
  return (
    <Counter
      count={props.count}
      isActive={props.isActive}
      className='absolute'
      onClick={onClick}
      style={{
        transform: 'translate(-50%,-50%)',
        top: props.posY,
        left: props.posX
      }} />
  )
}

export default CommentAnchor
