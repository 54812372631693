import React from 'react'

const IconArrowUp = props => (
  <svg width={props.size} height={props.size} viewBox='0 0 10 6' {...props}>
    <path
      d='M8.498 6L10 4.614 5 0 0 4.614 1.502 6 5 2.772z'
      fill={props.color}
    />
  </svg>
)

IconArrowUp.defaultProps = {
  color: 'currentcolor',
  size: '1em'
}

export default IconArrowUp
