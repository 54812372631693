import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import * as fromColors from '../../../stores/ducks/colors'

import colors from '../../../../css/colors'
// @ts-ignore
import css from './index.css'

import Modal from '../../common/modal'
import InputGroup from '../../common/form/input-group'
import Label from '../../common/form/label'
import InputText from '../../common/form/input-text'
import Button from '../../common/button'
import Dropdown from '../../common/form/input-dropdown'
import ColorPicker from './color-picker'

import * as fromAdminSelectors from '../../../stores/ducks/admin/selectors'
import * as fromColorsSelectors from '../../../stores/ducks/colors/selectors'

const styles = {
  errorMessage: {
    color: colors.error
  }
}

type Props = {
  isOpen: boolean
  onRequestClose: () => void
  width: number
}

const AddColorModal = (props: Props) => {
  const colorPicker = useSelector(fromAdminSelectors.selectColorPicker)
  const collisionError = useSelector(fromColorsSelectors.selectCollisionError)
  const dispatch = useDispatch()

  const [name, setName] = useState('')
  const [ncsTitle, setNcsTitle] = useState('')
  const [pantoneTitle, setPantoneTitle] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [ncsErrorMessage, setNcsErrorMessage] = useState('')
  const [pantoneErrorMessage, setPantoneErrorMessage] = useState('')
  const [fy, setFy] = useState('')

  const handleChangeOnName = (name: string) => {
    setName(name)
    if (collisionError.name) {
      dispatch(fromColors.resetCollisionProblem('name'))
    }
  }

  const handleChangeOnNcsTitle = (ncsTitle: string) => {
    setNcsTitle(ncsTitle)
    setErrorMessage('')
    setNcsErrorMessage('')
    if (collisionError.ncs) {
      dispatch(fromColors.resetCollisionProblem('ncs'))
    }
  }

  const handleChangeOnPantoneTitle = (pantoneTitle: string) => {
    setPantoneTitle(pantoneTitle)
    setErrorMessage('')
    setPantoneErrorMessage('')
    if (collisionError.pantone) {
      dispatch(fromColors.resetCollisionProblem('pantone'))
    }
  }

  const handleChangeOnFy = (fy: string) => {
    setFy(fy)
  }

  const validation = () => {
    let validationOK = true
    if (
      name === '' &&
      ncsTitle === '' &&
      pantoneTitle === ''
    ) {
      setErrorMessage('Please fill in either NCS title or PANTONE title.')
      validationOK = false
    } else {
      setErrorMessage('')
    }

    if ((ncsTitle) !== '') {
      const matchNcsTitle = /^(NCS)(\s)(S)(\s)(\d{4})(-)(\w{1,4}$)/
      var matchNcs = matchNcsTitle.exec(ncsTitle.toUpperCase())
      if (!matchNcs) {
        setNcsErrorMessage('Not a valid NCS title.')
        validationOK = false
      } else {
        setNcsErrorMessage('')
      }
    }

    if ((pantoneTitle) !== '') {
      const matchPantoneTitle = /^(PANTONE)(\s)(\d{2})(-)(\d{4})(\s)(TCX)$/
      var matchPantone = matchPantoneTitle.exec(pantoneTitle.toUpperCase())
      if (!matchPantone) {
        setPantoneErrorMessage('Not a valid PANTONE title.')
        validationOK = false
      } else {
        setPantoneErrorMessage('')
      }
    }

    return validationOK
  }

  const handleBlurOnNcsTitle = (event: React.FocusEvent<HTMLInputElement>) => {
    var ncsTitle = event.target.value
    setNcsTitle(ncsTitle.toUpperCase())
  }

  const handleBlurOnPantoneTitle = (event: React.FocusEvent<HTMLInputElement>) => {
    var pantoneTitle = event.target.value
    setPantoneTitle(pantoneTitle)
  }

  const handleAddColor = () => {
    const validationOK = validation()
    if (!validationOK) return

    const matchColors = /rgb\(\s*(\d*)\s*,\s*(\d*)\s*,\s*(\d*)\s*\)/
    const match = matchColors.exec(colorPicker || 'rgb(0, 0, 0)')

    const newColor = {
      fy: fy || 'pre21',
      name: name,
      ncs: {
        active: true,
        color: {
          b: match![3],
          g: match![2],
          r: match![1]
        },
        name: ncsTitle.toUpperCase()
      },
      pantone: {
        color: {
          b: match![3],
          g: match![2],
          r: match![1]
        },
        name: pantoneTitle.toUpperCase()
      },
      selectable: true
    }

    dispatch(fromColors.addColorsAdmin(newColor))
  }

  useEffect(() => {
    validation()
  }, [ncsTitle, pantoneTitle])

  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      width={props.width}
    >
      <h2 className='mt0'>Add new color</h2>
      <InputGroup>
        <div className='flex justify-between'>
          <div
            style={{ backgroundColor: colorPicker }}
            className={css.preview}
          />
          <div className='width-60'>
            <Label><span>Title</span></Label>
            <InputText
              spellCheck={false}
              placeholder={'IKEA COLOR no 00 / NCS S 0000-A00A / PANTONE 00-0000 TCX'}
              name={name}
              value={name}
              onChange={handleChangeOnName}
            />
            <Label subtle style={{ height: '1rem', marginTop: 2 }}>
              {collisionError.name ? <span style={styles.errorMessage} className='italic f7'>This title already exists</span> : <span />}
            </Label>
            <Label><span>NCS title</span></Label>
            <InputText
              spellCheck={false}
              placeholder={'NCS S 0000-A00A'}
              name={ncsTitle}
              value={ncsTitle}
              onChange={handleChangeOnNcsTitle}
              onBlur={handleBlurOnNcsTitle}
              textTransform={'uppercase'}
            />
            <Label subtle style={{ height: '1rem', marginTop: 2 }}>
              <span style={styles.errorMessage} className='italic f7'>{errorMessage}{ncsErrorMessage}</span>
              {collisionError.ncs ? <span style={styles.errorMessage} className='italic f7'>This NCS already exists</span> : <span />}
            </Label>
            <Label><span>PANTONE title</span></Label>
            <InputText
              spellCheck={false}
              placeholder={'PANTONE 00-0000 TCX'}
              name={pantoneTitle}
              value={pantoneTitle}
              onChange={handleChangeOnPantoneTitle}
              onBlur={handleBlurOnPantoneTitle}
              textTransform={'uppercase'}
            />
            <Label subtle style={{ height: '1rem', marginTop: 2 }}>
              <span style={styles.errorMessage} className='italic f7'>{errorMessage}{pantoneErrorMessage}</span>
              {collisionError.pantone ? <span style={styles.errorMessage} className='italic f7'>This PANTONE already exists</span> : <span />}
            </Label>
            <div className='width-40'>
              <Label><span>RGB value</span></Label>
              <ColorPicker color={colorPicker}/>
            </div>
            <Label subtle style={{ height: '1rem', marginTop: 2 }}>
              {collisionError.rgb ? <span style={styles.errorMessage} className='italic f7'>This RGB value already exists</span> : <span />}
            </Label>
            <Label><span>FY</span></Label>
            <div className='width-20'>
              <Dropdown
                className='width-100'
                value={fy}
                onChange={handleChangeOnFy}
                // default input value to db is 'pre21'
                options={[
                  { value: 'pre21', label: 'pre21' },
                  { value: '20', label: '20' },
                  { value: '21', label: '21' },
                  { value: '22', label: '22' },
                  { value: '23', label: '23' },
                  { value: '24', label: '24' },
                  { value: '25', label: '25' },
                  { value: '26', label: '26' },
                  { value: '27', label: '27' },
                  { value: '28', label: '28' },
                  { value: '29', label: '29' },
                  { value: '30', label: '30' }
                ]}
              />
            </div>
          </div>
        </div>
      </InputGroup>
      <Label style={{ height: '1rem', marginTop: 2 }} />
      <div className='flex justify-end'>
        <Button onClick={props.onRequestClose}>Cancel</Button>
        <Button
          btnType='primary'
          className='ml1'
          onClick={handleAddColor}
        >Add</Button>
      </div>
    </Modal>
  )
}

export default AddColorModal
