import React, { PureComponent } from 'react'
import autoBind from 'react-autobind'

import _get from 'lodash/get'

import IconPattern from '../common/icons/icon-pattern'

import CardWithIcon from './card-with-icon'
import Button from '../common/button'

class CreateImagePackage extends PureComponent {
  constructor (...args) {
    super(...args)
    autoBind(this)
  }

  handleLoadImagePackage () {
    const { imagePackage } = this.props

    const batch = imagePackage.defaultBatchId
      ? _get(imagePackage, ['batches', imagePackage.defaultBatchId])
      : Object.values(imagePackage.batches)[0]

    const masterCombinationId = batch.masterCombinationId
    this.props.onLoadImagePackage({
      masterCombinationId
    })
  }

  render () {
    return (
      <div className='col-12 mx-auto'>
        <div className='col-6 mt0 mb3'>
          <CardWithIcon
            icon={<IconPattern size={80} />}
          >
            <p className='mb2'>Click here to create a new image package for bedsets.</p>
            <Button
              onClick={this.handleLoadImagePackage}
              btnType='primary'
              inverted
              uppercase
            >
              Create Image Package
            </Button>
          </CardWithIcon>
        </div>
      </div>
    )
  }
}

export default CreateImagePackage
