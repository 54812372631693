import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../common/button'
import AddEditRoomsetModal from './add-edit-roomset-modal'
import RoomsetsList from './roomsets-list'
import RemoveRoomsetModal from './remove-roomset-modal'
import * as fromRoomsets from '../../../stores/ducks/roomsets'
import * as fromRoomsetsAdmin from '../../../stores/ducks/roomsets-admin'
import * as fromRoomsetsAdminSelector from '../../../stores/ducks/roomsets-admin/selectors'

enum RoomsetModals {
  EDIT = 'edit-roomset',
  REMOVE = 'remove-roomset'
}

const AdminRoomsets = () => {
  const dispatch = useDispatch()
  const roomsets = useSelector(fromRoomsetsAdminSelector.selectRoomsets)
  const currentId = useSelector(fromRoomsetsAdminSelector.selectCurrentId)

  const [activeModal, setActiveModal] = useState<RoomsetModals | null>(null)

  useEffect(() => {
    dispatch(fromRoomsets.fetchAll())
  }, [])

  return (
    <div>
      <div className='flex items-center justify-end'>
        <a href='/guides/roomset.html' target='_blank' className='mr2'>
          Roomset guidelines
        </a>
        <Button
          btnType='primary'
          className='height-100'
          onClick={() => setActiveModal(RoomsetModals.EDIT)}
        >
          Add new roomset
        </Button>
      </div>

      <AddEditRoomsetModal
        isOpen={activeModal === RoomsetModals.EDIT}
        onRequestClose={() => {
          setActiveModal(null)
          dispatch(fromRoomsetsAdmin.setEditRoomsetId(null))
        }}
      />

      <RoomsetsList
        roomsets={roomsets}
        onEdit={(id: string) => {
          dispatch(fromRoomsetsAdmin.setEditRoomsetId(id))
          setActiveModal(RoomsetModals.EDIT)
        }}
        onDelete={(id: string) => {
          dispatch(fromRoomsetsAdmin.setEditRoomsetId(id))
          setActiveModal(RoomsetModals.REMOVE)
        }}
      />

      <RemoveRoomsetModal
        isOpen={activeModal === RoomsetModals.REMOVE}
        onClose={() => {
          dispatch(fromRoomsetsAdmin.setEditRoomsetId(null))
          setActiveModal(RoomsetModals.REMOVE)
        }}
        onRemove={() => {
          dispatch(fromRoomsets.remove(currentId!))
          setActiveModal(null)
          dispatch(fromRoomsetsAdmin.setEditRoomsetId(null))
        }}
      />
    </div>
  )
}

export default AdminRoomsets
