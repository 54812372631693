import React from 'react'

type Props = React.HTMLAttributes<HTMLDivElement> & {
  isDragging: boolean
}

export default function DragAndDropStyled ({ children, isDragging, style }: Props) {
  return (
    <div
      className={`border-dashed bw-2 pointer ${isDragging ? 'bg-gray-light bc-black c-black' : 'bg-white'} bg-gray-light-hover c-black-hover c-gray bc-black-hover`}
      style={style}
    >
      { children }
    </div>
  )
}
