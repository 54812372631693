import fetch from '../../../utils/fetch'

const assertError = (err: string, msg: {msg: string}) => {
  console.error(msg)
  console.error(err)
  throw err
}

export const getAllInProject = (projectId: string) => {
  return fetch(`/api/external/view/byProject/${projectId}`)
    .then((res) => res.json())
    .catch((err) => assertError(err, { msg: 'Not able to get all external views by projectId' }))
}

export const createExternalViewForVariant = (combinationId: string, title: string) => {
  return fetch('/api/external/view', {
    method: 'PUT',
    body: JSON.stringify(Object.assign({}, { combinationId, title }))
  })
    .then((res) => res.json())
    .catch((err) => assertError(err, { msg: 'Not able to create external view' }))
}

export const getExternalViewById = (id: string) => {
  return fetch(`/api/external/view/${id}`)
    .then((res) => res.json())
    .catch((err) => assertError(err, { msg: 'Not able to get external view by id' }))
}

export const getExternalViewByCombinationId = (combinationId: string) => {
  return fetch(`/api/external/view/byCombination/${combinationId}`)
    .then((res) => res.json())
    .catch((err) => assertError(err, { msg: 'Not able to get external view by combinationId' }))
}

export const recreateArJobForExternalView = (externalViewId: string) => {
  return fetch(`/api/external/view/${externalViewId}/ar-job`, {
    method: 'POST'
  })
    .then((res) => res.json())
    .catch((err) => assertError(err, { msg: 'Not able to retrigger AR job for specified view' }))
}
