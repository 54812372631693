import fetch from '../../../utils/fetch'
import { Roomset } from './Roomset'

export const get = async (id: string) => {
  const response = await fetch(`/api/roomsets/${id}`)
  return (await response.json()) as Roomset
}

export const getAll = async () => {
  const response = await fetch('/api/roomsets')
  return (await response.json()) as Roomset[]
}

export const getByIds = async (ids: string[]) => {
  const response = await fetch('/api/roomsets/getByIds', {
    method: 'post',
    body: JSON.stringify({ ids })
  })
  return (await response.json()) as Roomset[]
}

export const create = async (payload: Roomset) => {
  const response = await fetch('/api/roomsets/create', {
    method: 'post',
    body: JSON.stringify(payload)
  })
  return (await response.json()) as Roomset
}

export const update = async (payload: Roomset) => {
  const response = await fetch('/api/roomsets/update', {
    method: 'post',
    body: JSON.stringify(payload)
  })
  return (await response.json()) as Roomset
}

export const remove = async (id: string) => {
  const response = await fetch(`/api/roomsets/remove/${id}`)
  return (await response.json()) as Roomset
}

export const getImageTemplatesAndSetups = async () => {
  const result = await fetch('/api/roomsets/image-templates')
  const json: Roomset[] = await result.json()
  return json
}
