import cs from 'classnames'
import React from 'react'
import { connect } from 'react-redux'
import { STATUS, cancel, convert } from '../../stores/ducks/patterns/convert'
import Button from '../common/button'
import InputText from '../common/form/input-text'
import Label from '../common/form/label'
import KeyboardListener, { KeyBinding, KeyCode } from '../common/keyboard-listener'
import ProgressBar from '../common/progress-bar'
import ModalRedux from '../common/modal-redux'

function CreatePatternForm (props) {
  const [title, setTitle] = React.useState('')
  const [files, setFiles] = React.useState(null)

  const fileInputRef = React.useRef()

  React.useEffect(() => {
    if (props.status === STATUS.UPLOADED) {
      props.onClose()
    }

    if (props.status === STATUS.PENDING && !files && fileInputRef) {
      handleTriggerFileInput()
    }
  }, [props.status])

  function getFileName (file) { if (file && file.name) { return file.name.split('.')[0] } }

  const supportedFormats = [
    '.pdf',
    '.tiff',
    '.tif',
    '.jpg',
    '.jpeg',
    '.png',
    '.eps',
    '.svg',
    '.psd'
  ].join(',')

  function handleSubmit (event) {
    event.preventDefault()
    props.convert(files, {
      title
    })
    props.onClose()
  }

  function handleFileChange (event) {
    props.onShow()
    event.persist()
    const { files } = event.target
    setFiles(files)
    setTitle(getFileName(files[0]))
  }

  function handleCancel () {
    if (props.status === STATUS.UPLOADING) props.cancel()
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.value = ''
    }
    props.onClose()
  }

  function renderProgress () {
    return (
      <div>
        <span>Uploading {props.uploadProgress.file ? <i>({props.uploadProgress.file})</i> : 'files'} {props.uploadProgress.uploadedMB} / {props.uploadProgress.totalMB}</span>
        <ProgressBar percentage={Math.floor(props.uploadProgress.percentage || props.hashProgress.percentage)} />
        <Button
          className='inline-block px2 mt2'
          onClick={handleCancel}
        >
          Cancel
        </Button>
      </div>
    )
  }

  function renderFileInput () {
    return (
      <input
        className='hidden'
        accept={supportedFormats}
        onChange={handleFileChange}
        ref={fileInputRef}
        type='file'
      />
    )
  }

  function handleTriggerFileInput () {
    handleHideModal()
    fileInputRef.current.click()
  }

  function handleHideModal () {
    props.onHide()
  }

  if (!files) return renderFileInput()
  if (props.error) return (<div>{props.error.message}</div>)
  if (props.status === STATUS.UPLOADING) return renderProgress()

  return (
    <div>
      <h2>New pattern</h2>

      <div className='mb2'>To create a pattern with transparency you need to upload a .psd, .pdf or .png file.</div>

      <div>
        <Label>Title:</Label>
        <InputText
          autoFocus
          defaultValue={title}
          onChange={setTitle}
          placeholder='Title'
        />
      </div>
      <div className={cs('flex mt3 justify-end')}>
        <div className='flex justify-end items-end col-4'>
          <Button onClick={handleCancel}>
              Cancel
          </Button>
          <Button btnType='primary' className='ml2' onClick={handleSubmit}>
              Save
          </Button>
        </div>
      </div>
      <KeyboardListener
        bindings={[
          KeyBinding(KeyCode.enter, handleSubmit)
        ]}
      />
    </div>
  )
}

const mapStateToProps = (state) => {
  const convert = state.patterns.convert
  return {
    status: convert.status,
    hashProgress: convert.hashProgress,
    uploadProgress: convert.uploadProgress,
    error: convert.error,
    modals: state.interface.modals
  }
}

const mapDispatchToProps = {
  convert,
  cancel
}

const ConnectedCreatePatternForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreatePatternForm)

export const CreatePatternModal = () => (
  <ModalRedux
    type={'patterns'}
    name={'create'}
    display={false}
  >
    {({ onClose, onShow, onHide }) => (
      <ConnectedCreatePatternForm
        onClose={onClose}
        onShow={onShow}
        onHide={onHide}
      />
    )}
  </ModalRedux>
)
