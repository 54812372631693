import Immutable from 'seamless-immutable'
import { createAction, handleActions } from 'redux-actions'
import _map from 'lodash/map'
import _get from 'lodash/get'

import _isUndefined from 'lodash/isUndefined'

import * as fromDefaultTemplate from '../templates/default-template'
import * as fromThreeviewerSelectors from './selectors'
import * as fromUndoRedo from '../undo-redo'
import { getMaterialById } from '../materials'

import * as threeviewerUICommands from '../threeviewer/commands'

import * as materialUtils from '../materials/utils'
import * as materialCommands from '../materials/commands'
import * as fromCombinationSelectors from '../combinations/selectors'
import * as fromPatterns from '../patterns/selectors'
import * as fromColorsSelectors from '../colors/selectors'
import { setAnnotation } from '../combinations'

export const setUseEnvironment = createAction('threeviewer/ui/SET_USE_ENVIRONMENT')
export const setRenderPreset = createAction('threeviewer/ui/SET_RENDER_PRESET')
export const saveSafeFrameVisibility = createAction('threeviewer/ui/SAVE_SAFEFRAME_VISIBILITY')
export const setActiveLeftPanelIndex = createAction('threeviewer/ui/SET_ACTIVE_LEFT_PANEL_TAB')
export const combinationModelAdded = createAction('threeviewer/ui/scene/ADDED_COMBINATION_MODEL')
export const setMaterialSource = createAction('threeviewer/ui/materials/SET_MATERIAL_SOURCE')
const setIsAnnotationsActive = createAction('threeviewer/ui/SET_IS_ANNOTATIONS_ACTIVE')
const setToolbarPositionFields = createAction('threeviewer/ui/SET_POSITION_PANEL_VALUES')
const setToolbarRotationFields = createAction('threeviewer/ui/SET_ROTATION_PANEL_VALUES')

export const setSnapToModelsActive = createAction('threeviewer/ui/SET_SNAP_TO_MODELS_ACTIVE')
export const setSnapToHolesActive = createAction('threeviewer/ui/SET_SNAP_TO_HOLES_ACTIVE')
export const setAutoGroupActive = createAction('threeviewer/ui/SET_AUTO_GROUP_ACTIVE')

const setDefaultSceneComponentActive = createAction('threeviewer/ui/scene/setDefaultSceneComponentActive')
export const setKeyBoardBindings = createAction('threeviewer/ui/keyBoardBindings/SET_VALUE')
const confirmSetSafeframeVisibility = createAction('threeviewer/ui/CONFIRM_SET_SAFEFRAME_VISIBILITY')

export const toggleSafeFrameVisibility = () => (dispatch, getState) => {
  const viewer = fromThreeviewerSelectors.getViewer(getState())
  viewer.postProcessManager.safeFrame.enabled = !viewer.postProcessManager
    .safeFrame.enabled
  viewer.renderOnNextFrame()
  dispatch(saveSafeFrameVisibility(viewer.postProcessManager.safeFrame.enabled))
  dispatch(
    confirmSetSafeframeVisibility(viewer.postProcessManager.safeFrame.enabled)
  )
}

export const setSafeFrameVisibility = isVisible => (dispatch, getState) => {
  const viewer = fromThreeviewerSelectors.getViewer(getState())
  viewer.postProcessManager.safeFrame.enabled = isVisible
  viewer.renderOnNextFrame()
  dispatch(
    confirmSetSafeframeVisibility(viewer.postProcessManager.safeFrame.enabled)
  )
}

export const resetSafeFrameVisibility = () => (dispatch, getState) => {
  const state = getState()
  const viewer = fromThreeviewerSelectors.getViewer(state)
  const ui = fromThreeviewerSelectors.getUIState(state)
  viewer.postProcessManager.safeFrame.enabled = ui.savedIsSafeFrameVisible
  viewer.renderOnNextFrame()
  dispatch(
    confirmSetSafeframeVisibility(viewer.postProcessManager.safeFrame.enabled)
  )
}

export const toggleKeyBoardBindings = value => (dispatch, getState) => {
  const state = getState()
  if (_isUndefined(value)) {
    value = !fromThreeviewerSelectors.getKeyBoardBindingsEnabled(state)
  }
  dispatch(setKeyBoardBindings(value))
}

export const dispose = createAction('threeviewer/ui/DISPOSE')
export const forceUpdate = createAction('threeviewer/ui/FORCE_UPDATE')
export const forceUpdateTree = createAction('threeviewer/ui/FORCE_UPDATE_TREE')

// TODO: Should this be implemented at all?
// const startAccumulation = () => (dispatch, getState) => {
//   const viewer = fromThreeviewerSelectors.getViewer(getState())
//   viewer.postProcessManager.graphicsType =
//     viewer.postProcessManager.graphicsTypes.ACCUMULATION
// }

export const setDefaultSceneComponentVisibility = (id, visibility) => (
  dispatch
) => {
  dispatch(setVisibleModel(id, visibility))
  dispatch(setDefaultSceneComponentActive({ key: id, value: visibility }))

  if (id === 'wallLeft' && !visibility) {
    dispatch(setVisibleModel('skirtingLeft', visibility))
    dispatch(
      setDefaultSceneComponentActive({ key: 'skirtingLeft', value: visibility })
    )
  }
  if (id === 'wallRight' && !visibility) {
    dispatch(setVisibleModel('skirtingRight', visibility))
    dispatch(
      setDefaultSceneComponentActive({
        key: 'skirtingRight',
        value: visibility
      })
    )
  }
}

export const toggleTemplateModelVisibility = id => (dispatch, getState) => {
  const state = getState()
  let visibility = fromThreeviewerSelectors.getComponentVisibility(id, state)
  const undoVisibility = fromThreeviewerSelectors.getComponents(state)
  if (_isUndefined(visibility)) {
    visibility = true
  }
  const command = threeviewerUICommands.createSetDefaultSceneComponentVisibilityCommand(
    {
      id,
      visibility,
      undoVisibility,
      setVisibility (id, visibility) {
        dispatch(setDefaultSceneComponentVisibility(id, visibility))
      },
      afterExecute: () => {
        dispatch(fromDefaultTemplate.adjustWallInScene())
      },
      afterUndo: () => {
        dispatch(fromDefaultTemplate.adjustWallInScene())
      }
    }
  )

  dispatch(fromUndoRedo.addCommand(command))
  command.execute()
}

// This function assumes that there is only one selected material. This was
// written when the ui for setting the stain would only appear with one selected mesh.
export const setMaterialStainStrength = strength => (dispatch, getState) => {
  const state = getState()
  const viewer = fromThreeviewerSelectors.getViewer(state)
  const { picker } = viewer
  const nodeGroups = materialUtils.getNodesToSetMaterialOn(
    _map(picker.selection)
  )
  const oldValues = materialUtils.getMaterialValuesFromNodeGroups(nodeGroups)

  const selection = viewer.picker.selection
  for (const key in selection) {
    const selectedMesh = selection[key]
    const mixValueObj = new materialUtils.ColorTextureMixVal(strength)

    selectedMesh.userData.colorTextureMix = mixValueObj.value
    selectedMesh.traverseMeshes(n => {
      if (n.material !== undefined) {
        n.material.colorTextureMix = mixValueObj.value
        n.userData.colorTextureMix = mixValueObj.value
      }
    })
    selectedMesh.material.needsUpdate = true
  }

  const newValues = materialUtils.getMaterialValuesFromNodeGroups(nodeGroups)
  const command = materialCommands.createSetMaterialCommand({
    newValues,
    oldValues,
    setMaterial (value) {
      value.node.material.colorTextureMix = value.userData.colorTextureMix
      value.node.userData.colorTextureMix = value.userData.colorTextureMix
    },
    afterUndo () {
      dispatch(forceUpdate())
    },
    afterExecute () {
      dispatch(forceUpdate())
    }
  })

  dispatch(fromUndoRedo.addCommand(command))
  command.execute()
  dispatch(forceUpdate())
  viewer.renderOnNextFrame()
}

const setVisibleModel = (modelName, visibility) => (
  dispatch,
  getState
) => {
  const viewer = fromThreeviewerSelectors.getViewer(getState())

  var model = viewer.objectTracker.getObject('canSetVisible', modelName)

  if (!visibility) {
    model.traverse(child => {
      if (viewer.picker.selection[child.uuid]) {
        viewer.picker.deselect(child.uuid)
      }
    })
  }
  if (model) {
    model.visible = visibility
    model.userData.visible = visibility
    model.userData.changed = true
    model.traverse(grandChild => (grandChild.visible = visibility))
  }
}

export const incrementCameraPosition = () => (dispatch, getState) => {
  const viewer = fromThreeviewerSelectors.getViewer(getState())
  viewer.variantManager.incrementCameraPosition()
}

export const decrementCameraPosition = () => (dispatch, getState) => {
  const viewer = fromThreeviewerSelectors.getViewer(getState())
  viewer.variantManager.decrementCameraPosition()
}

export const updateToolbarPositionFields = (position) => (dispatch /* , getState */) => {
  dispatch(setToolbarPositionFields(position))
}

export const updateToolbarRotationFields = (rotation) => (dispatch /* , getState */) => {
  dispatch(setToolbarRotationFields(rotation))
}

export const toggleSnapToModels = () => (dispatch, getState) => {
  const viewer = fromThreeviewerSelectors.getViewer(getState())
  const { snapToModels } = fromThreeviewerSelectors.getMoveGizmoSettings(getState())

  dispatch(setSnapToModelsActive(!snapToModels))
  viewer.transformGizmo.setSnapToModelsActive(!snapToModels)
}

export const toggleSnapToHoles = () => (dispatch, getState) => {
  const viewer = fromThreeviewerSelectors.getViewer(getState())
  const { snapToHoles } = fromThreeviewerSelectors.getMoveGizmoSettings(getState())

  dispatch(setSnapToHolesActive(!snapToHoles))
  viewer.transformGizmo.setSnapToHolesActive(!snapToHoles)
}

export const toggleAutoGroup = () => (dispatch, getState) => {
  const viewer = fromThreeviewerSelectors.getViewer(getState())
  const { autoGroup } = fromThreeviewerSelectors.getMoveGizmoSettings(getState())

  dispatch(setAutoGroupActive(!autoGroup))
  viewer.transformGizmo.setAutoGroupingActive(!autoGroup)
}

export function updateAnnotation (materialGroup, annotationText) {
  return async (dispatch, getState) => {
    const viewer = fromThreeviewerSelectors.getViewer(getState())
    const annotationId = materialGroup.id
    viewer.updateAnnotation(annotationId, annotationText)
    dispatch(setAnnotation({ id: annotationId, annotationText, materialId: materialGroup.materialId, colorId: materialGroup.color?.colorId, patternId: materialGroup.pattern?.patternId }))
  }
}

export function activateAnnotations () {
  return async (dispatch, getState) => {
    const viewer = fromThreeviewerSelectors.getViewer(getState())
    const combination = fromCombinationSelectors.getCurrentEntry(getState())
    const patterns = fromPatterns.getEntries(getState())
    const colors = fromColorsSelectors.getEntries(getState())
    const meshes = viewer.getNonTemplateMeshes()
    const materialIdDic = {}
    for (let i = 0; i < meshes.length; i++) {
      const materialId = meshes[i].material.materialId
      if (!materialId) continue
      if (!materialIdDic[materialId]) {
        materialIdDic[materialId] = { materialId, meshes: [] }
      }
      materialIdDic[materialId].meshes.push(meshes[i])
    }
    const materialObjList = Object.values(materialIdDic)
    const promises = materialObjList.map(materialObj =>
      dispatch(getMaterialById(materialObj.materialId))
    )

    const materialJsons = await Promise.all(promises)

    for (let i = 0; i < materialObjList.length; i++) {
      // the material json is undefined for the material with "construction tape".
      // For now I'm going to guard against this case to avoid a crash when these materials are present.
      // I dont know the underlaying reason why its undefined, if this is resolved in the future
      // feel free to remove this guard.
      if (!materialJsons[i]) {
        continue
      }
      const materialObj = materialObjList[i]

      const materialName =
        _get(materialJsons[i], 'metadata.tag_items.Designation'[0]) ||
        _get(materialJsons[i], 'metadata.description') ||
        _get(materialJsons[i], 'metadata.name') ||
        _get(materialJsons[i], 'displayName') ||
        _get(materialJsons[i], 'name')

      materialObj.meshes.forEach(mesh => {
        const annotationId = `${mesh.userData.materialId}${mesh.userData.colorId || ''}${mesh.userData.patternId || ''}`
        let annotationText
        if (combination.annotations && combination.annotations[annotationId]) {
          annotationText = combination.annotations[annotationId].annotationText
        } else {
          const color = colors[mesh.userData.colorId]
          let colorName
          if (color) {
            colorName = color.name ? color.name : color.ncs && color.ncs.name
          }
          const patternName = patterns[mesh.userData.patternId] && patterns[mesh.userData.patternId].title
          annotationText = materialName
          if (colorName) {
            annotationText = `${annotationText}, ${colorName}`
          }
          if (patternName) {
            annotationText = `${annotationText}, ${patternName}`
          }
        }
        if (mesh.userData.materialId) { // Don't add annotation if mesh don't have an assigned material
          viewer.addAnnotationMesh(mesh, annotationId, annotationText)
        }
      })
    }
    viewer.enableAnnotations()
    dispatch(setIsAnnotationsActive(true))
  }
}

export function deactivateAnnotations () {
  return (dispatch, getState) => {
    const viewer = fromThreeviewerSelectors.getViewer(getState())
    viewer.disableAnnotations()
    dispatch(setIsAnnotationsActive(false))
  }
}

const initialState = Immutable({
  moveGizmoSettings: {
    snapToModels: true,
    snapToHoles: false,
    autoGroup: false
  },
  toolbarPositionFields: {
    x: 0,
    y: 0,
    z: 0
  },
  toolbarRotationFields: {
    x: 0,
    y: 0,
    z: 0
  },
  leftPanelIndex: null,
  rightPanelIndex: null,
  forcedUpdate: 0,
  forcedUpdateTree: 0,
  isSafeFrameVisible: true,
  savedIsSafeFrameVisible: true,
  annotationsActive: false,
  render: {
    quality: '1',
    useEnvironment: true
  },
  scene: {
    hasCombinationModel: false,
    defaultScene: {
      components: {
        wallLeft: {
          active: undefined
        },
        skirtingLeft: {
          active: undefined
        },
        wallRight: {
          active: undefined
        },
        skirtingRight: {
          active: undefined
        },
        floorBox: {
          active: undefined
        }
      }
    }
  },
  leftToolPanelIndex: -1,
  materialSource: 'dpd',
  keyBoardBindingsEnabled: true
})

export default handleActions(
  {
    [setToolbarPositionFields]: (state, action) => state.setIn(['toolbarPositionFields'], action.payload),
    [setToolbarRotationFields]: (state, action) => state.setIn(['toolbarRotationFields'], action.payload),
    [setSnapToModelsActive]: (state, action) => state.setIn(['moveGizmoSettings', 'snapToModels'], action.payload),
    [setSnapToHolesActive]: (state, action) => state.setIn(['moveGizmoSettings', 'snapToHoles'], action.payload),
    [setAutoGroupActive]: (state, action) => state.setIn(['moveGizmoSettings', 'autoGroup'], action.payload),
    [setIsAnnotationsActive]: (state, action) => state.setIn(['annotationsActive'], action.payload),
    [saveSafeFrameVisibility]: (state, action) => state.setIn(['savedIsSafeFrameVisible'], action.payload),
    [confirmSetSafeframeVisibility]: (state, action) => state.setIn(['isSafeFrameVisible'], action.payload),
    [setDefaultSceneComponentActive]: (state, { payload }) => state.setIn(['scene', 'defaultScene', 'components', payload.key, 'active'], payload.value),
    [combinationModelAdded]: (state) => state.setIn(['scene', 'hasCombinationModel'], true),
    [setMaterialSource]: (state, action) => state.setIn(['materialSource'], action.payload),
    [setRenderPreset]: (state, action) => state.setIn(['render', 'quality'], action.payload),
    [setUseEnvironment]: (state, action) => state.setIn(['render', 'useEnvironment'], action.payload),
    [forceUpdate]: (state) => state.setIn(['forcedUpdate'], Math.random()),
    [forceUpdateTree]: (state) => state.setIn(['forcedUpdateTree'], Math.random()),
    [setKeyBoardBindings]: (state, action) => state.setIn(['keyBoardBindingsEnabled'], action.payload),
    [setActiveLeftPanelIndex]: (state, { payload }) => state.merge({ leftPanelIndex: payload }),
    [dispose]: () => initialState
  },
  initialState
)
