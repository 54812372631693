import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import _get from 'lodash/get'
import _filter from 'lodash/filter'
import _sortBy from 'lodash/sortBy'
import _capitalize from 'lodash/capitalize'

import fetch from '../../../utils/fetch'

import Modal from '../../common/modal'
import Avatar from '../../common/avatar'
import InputText from '../../common/form/input-text'
import Button from '../../common/button'
import IconPlus from '../../common/icons/icon-plus'

import * as fromUsersSelectors from '../../../stores/ducks/users/selectors'

import { selectProjectMembers } from './selectProjectMembers'
import SharedWith from './SharedWith'
import { notify, setErrorNotificationMessage } from '../../../stores/ducks/projects'
import type { User } from '../../../stores/ducks/users/User'
import type { Item } from '../card/Item'

const titleClasses = 'f3 mt0 '
const subtitleClasses = 'f5 mt3 mb1 '
const explanationClasses = 'f7 mb2 '
const noResultClasses = 'px2 py2 muted f6 '
const filteredUsersWrapperStyle = { height: '20vh', overflowY: 'scroll' } as React.CSSProperties

const getApiUrl = (type: string) => {
  switch (type) {
    case 'combination': return '/api/send-assets/combinations'
    case 'variant': return '/api/send-assets/combinations'
    case 'image': return '/api/send-assets/combinations'
    case 'pattern': return '/api/send-assets/patterns'
    default: return '/api/send-assets/combinations'
  }
}

interface UsersObject {
  [id: string]: User
}

type Props = {
  isModalOpen: boolean
  handleCloseModal: () => void
  ownerOfObject?: string
  handleShareWithUser?: (id: string) => void
  modalType: 'PROJECT' | 'ASSET'
  asset?: Item | null
  assetType?: string
}

const _getUserName = (user:User) => {
  return user.firstName
    ? `${user.firstName} ${user.lastName || ''}`
    : user.email
}

function getShareableUsers (users: UsersObject, sharedWith: User[], createdBy: string, userSentBy:User, currentUserId?: string) {
  const shareableUsers: { [id: string]: User & { fullName: string } } = {}

  Object.keys(users).forEach((id) => {
    if (currentUserId !== id && userSentBy?.id !== id && !sharedWith.find(u => u.id === id) && id !== createdBy) {
      const user = users[id]
      shareableUsers[id] = {
        ...user,
        fullName: user.firstName ? `${user.firstName} ${user.lastName || ''}` : ''
      }
    }
  })

  return _sortUsers(shareableUsers)
}

const _sortUsers = (users: UsersObject) => _sortBy(users, [(user) => _getUserName(user).toLowerCase()])
const _filterProps = ['firstName', 'lastName', 'email', 'fullName']
const _makeMatchPropWithQuery = (query: string, user: any) => (prop: string) => ((user[prop] || '').toLowerCase().indexOf(query) !== -1)
const _filterUsers = (query: string, user: any) => (query ? _filterProps.some(_makeMatchPropWithQuery(query, user)) : true)

const ShareToUserModal = (props: Props) => {
  if (!props.isModalOpen) return null

  const dispatch = useDispatch()

  const [usersSharedWith, setUsersSharedWith] = useState([] as User[])
  const projectMembers = useSelector(selectProjectMembers)

  const [query, setQuery] = useState('')
  const [textInput, setTextInput] = useState('')
  const userId = useSelector(fromUsersSelectors.getCurrentId)
  const allUsers = useSelector(fromUsersSelectors.getEntries)
  const userSentBy = props?.asset?.sentBy ? allUsers[props.asset?.sentBy] : undefined

  const [isVerifyModalOpen, setIsVerifyModalOpen] = useState(false)
  const [selectedUser, setSelectedUser] = useState<User | null>(null)

  const [sharedWith, setSharedWith] = useState<User[]>([])
  const sortedShareableWith = getShareableUsers(allUsers, sharedWith, userId, userSentBy, props.ownerOfObject)
  const filteredUsers = _filter(sortedShareableWith, (user) => _filterUsers(query, user))

  useEffect(() => {
    const abortController = new AbortController()
    async function fetchUsers () {
      if (props.asset?.id !== undefined && props.modalType === 'ASSET') {
        const apiUrl = getApiUrl(props.asset.contentType || 'combination')
        try {
          const result = await fetch(`${apiUrl}/${props.asset.sentAssetId || props.asset.id}`, { signal: abortController.signal })
          const json = await result.json()
          setUsersSharedWith(json)
        } catch (e) {
          if (!abortController.signal.aborted) {
            console.error('Request failed.')
            console.error(e)
          }
        }
      }
    }
    fetchUsers()
    return () => abortController.abort()
  }, [props.assetType, props.asset?.id, props.modalType])

  useEffect(() => {
    if (props.modalType === 'ASSET') setSharedWith(usersSharedWith)
    if (props.modalType === 'PROJECT') setSharedWith(projectMembers)
  }, [projectMembers, usersSharedWith])

  const handleFilterChange = (res:string) => {
    const value = res.trim().toLowerCase()
    setQuery(value.length > 2 ? value : '')
  }

  function shareAssetWithUser (user: User) {
    if (!props.asset) return
    const apiUrl = getApiUrl(props.asset.contentType || 'combination')
    fetch(`${apiUrl}`, {
      method: 'POST',
      body: JSON.stringify({
        id: props.asset.id,
        userId: user.id
      })
    })
      .then((res) => {
        if (res.status === 200) {
          setUsersSharedWith(usersSharedWith.concat([user]))
          closeVerifyModal()
          const assetType = _capitalize(props.assetType)
          dispatch(notify(`${assetType} was sent to ${user.email}`))
        }
      })
      .catch(function (err) {
        console.error(err)
        dispatch(setErrorNotificationMessage(`Could not send ${props.assetType} to ${user.email}`))
      })
  }

  const closeVerifyModal = () => {
    setIsVerifyModalOpen(false)
    setSelectedUser(null)
  }

  const isOwner = (userId: string) => userId === props.ownerOfObject

  let title = 'Share'
  let subtitle = 'User'
  let addTitle = 'Add new'
  let showOwner = true
  let removeable = true

  if (props.modalType === 'PROJECT') {
    title = 'Share project'
    subtitle = 'Members'
    addTitle = 'Add new members'
    showOwner = true
    removeable = true
  }

  if (props.modalType === 'ASSET' && props.asset) {
    title = `Send ${props.assetType}`
    subtitle = 'Sent to'
    addTitle = `Send ${props.assetType} to...`
    showOwner = false
    removeable = false
  }

  return (
    <Modal
      isOpen={props.isModalOpen}
      onRequestClose={props.handleCloseModal}
      width={700}
    >
      <div data-testid={`${props.modalType === 'PROJECT' ? 'share-project-modal' : 'share-asset-modal'}`} className='left-align'>
        <h2 className={titleClasses}>{title}</h2>
        <h3 className={subtitleClasses}>{subtitle}</h3>
        {removeable && <div className={explanationClasses}><em>Click to remove</em></div>}

        <div className='flex flex-wrap mxn1 mbn1' style={{ minHeight: 34 }}>
          <SharedWith
            users={sharedWith}
            userId={userId}
            modalType={props.modalType}
            removeable={removeable}
            handleShareWithUser={props.handleShareWithUser}
            isOwner={isOwner}
            showAvatar={(userId:string) => !isOwner(userId) || showOwner}
            userSentBy={userSentBy}
          />
        </div>

        <h3 className={subtitleClasses}>{addTitle}</h3>
        <div className={explanationClasses}><em>Start typing to filter user list</em></div>

        <InputText
          placeholder='Filter users...'
          className='px1 py1 width-100'
          onChange={(res:string) => {
            handleFilterChange(res)
            setTextInput(res)
          }}
          focus={true}
          value={textInput}
        />

        <div data-testid='filtered-users' className='border bc-gray-light br-2' style={filteredUsersWrapperStyle}>
          {(filteredUsers || []).map((user) => (
            <div
              key={user.id}
              className={'px2 py1 flex items-center pointer bg-primary-hover opacity-hover-parent'}
              onClick={() => {
                if (props.modalType === 'PROJECT') props.handleShareWithUser && props.handleShareWithUser(user.id)
                else {
                  setSelectedUser(user)
                  setIsVerifyModalOpen(true)
                }
              }}
            >
              <Avatar user={user} size={40} />
              <span className='width-100 ml2 truncate'>
                {_getUserName(user)}
              </span>
              <div className='flex justify-end width-100 opacity-hover-child'><IconPlus /></div>
            </div>
          ))}

          {_get(filteredUsers, 'length', 0) === 0 && (
            <div className={noResultClasses}>No users to share with</div>
          )}
        </div>

        <Button
          btnType='primary'
          className='right mt2'
          onClick={() => {
            setQuery('')
            props.handleCloseModal()
          }}
        >
          Done
        </Button>
      </div>
      {selectedUser && (
        <Modal
          isOpen={isVerifyModalOpen}
          onRequestClose={() => closeVerifyModal()}
          width={600}
        >
          <div className='left-align'>
            <h2 className={titleClasses}>{'This cannot be undone'}</h2>
            <div>{`Once you have sent the asset to ${selectedUser.email} it cannot be undone. Do you want to send it?`}</div>
          </div>
          <div className='flex justify-end items-end mt3' onClick={() => closeVerifyModal()}>
            <Button >
              Cancel
            </Button>
            <Button btnType='primary' className='ml2' onClick={() => {
              shareAssetWithUser(selectedUser)
              setTextInput('')
            }}>
              Send
            </Button>
          </div>
        </Modal>
      )}
    </Modal>
  )
}

export default ShareToUserModal
