import React from 'react'

const IconLayers = props => (
  <svg
    width={props.size}
    height={props.size}
    viewBox='3 3 18 18'
    {...props}
  >
    <g fill={props.color}>
      <path d='M9,16 L20,16 C20.5522847,16 21,16.4477153 21,17 C21,17.5522847 20.5522847,18 20,18 L9,18 C8.44771525,18 8,17.5522847 8,17 C8,16.4477153 8.44771525,16 9,16 Z M9,11 L20,11 C20.5522847,11 21,11.4477153 21,12 C21,12.5522847 20.5522847,13 20,13 L9,13 C8.44771525,13 8,12.5522847 8,12 C8,11.4477153 8.44771525,11 9,11 Z M9,6 L20,6 C20.5522847,6 21,6.44771525 21,7 L21,7 C21,7.55228475 20.5522847,8 20,8 L9,8 C8.44771525,8 8,7.55228475 8,7 L8,7 L8,7 C8,6.44771525 8.44771525,6 9,6 Z M4,16 L4,16 C4.55228475,16 5,16.4477153 5,17 C5,17.5522847 4.55228475,18 4,18 C3.44771525,18 3,17.5522847 3,17 C3,16.4477153 3.44771525,16 4,16 L4,16 Z M4,11 L4,11 C4.55228475,11 5,11.4477153 5,12 C5,12.5522847 4.55228475,13 4,13 C3.44771525,13 3,12.5522847 3,12 C3,11.4477153 3.44771525,11 4,11 L4,11 Z M4,6 L4,6 C4.55228475,6 5,6.44771525 5,7 L5,7 C5,7.55228475 4.55228475,8 4,8 C3.44771525,8 3,7.55228475 3,7 L3,7 L3,7 C3,6.44771525 3.44771525,6 4,6 L4,6 Z' />
    </g>
  </svg>
)

IconLayers.defaultProps = {
  color: 'currentcolor',
  size: '1em'
}

export default IconLayers
