import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'

import _get from 'lodash/get'
import _compact from 'lodash/compact'
import _omitBy from 'lodash/omitBy'
import _isEmpty from 'lodash/isEmpty'
import _isUndefined from 'lodash/isUndefined'
import _isNull from 'lodash/isNull'

import * as fromProjectsSelectors from '../../stores/ducks/projects/selectors'
import * as storageUtils from '../../utils/storage'

import Modal from '../common/modal'

import FormRemove from '../common/form/form-remove'

import Project from './project'
import EditProjectForm, { ProjectFormData } from './edit-project-form'

enum ModalType {
  EDIT = 'EDIT',
  REMOVE = 'REMOVE'
}

type Props = React.HTMLAttributes<HTMLElement> & {
  ids: string[],
  shared?: boolean
  onEdit?: (data: any) => void,
  onOpen: (projectId: string, projectType: string) => void,
  onRemove: (projectId: string) => void
}

const sortByLatestDesc = (a: any, b: any) => (Date.parse(b.createdAt) - Date.parse(a.createdAt))

const selectProjectsWithThumbnails = (ids: string[]) => {
  return createSelector(
    fromProjectsSelectors.getEntries,
    (entries) => {
      const projects: any[] = _compact(ids.map((id) => _get(entries, id)))
      return projects.map(project => {
        return project.merge({
          imageSrc: storageUtils.getImageSrc(project.imageFile, {
            type: 'thumbnail',
            format: 'jpg'
          })
        })
      }).sort(sortByLatestDesc)
    }
  )
}

const ProjectList = ({ ids = [], shared, onEdit, onOpen, onRemove }: Props) => {
  const [modalType, setModalType] = useState<ModalType | null>(null)
  const [project, setProject] = useState<any>(null)

  const projects = useSelector(selectProjectsWithThumbnails(ids))

  const handleCloseModal = () => {
    setModalType(null)
    setProject(null)
  }

  const handleOpenModal = (modalType: ModalType, project: any) => {
    setModalType(modalType)
    setProject(project)
  }

  const handleRemove = () => {
    onRemove(project.id)
    handleCloseModal()
  }

  const handleEdit = (data: ProjectFormData, projectId: string): void => {
    const _data = _omitBy(data, (x) => _isUndefined(x) || _isNull(x))
    if (_isEmpty(_data)) return

    onEdit?.({
      ..._data,
      id: projectId
    })

    handleCloseModal()
  }

  const renderModalContent = () => {
    if (modalType === ModalType.EDIT) {
      return (
        <EditProjectForm
          formTitle='Edit project'
          title={project.title}
          projectTypeId={project.projectTypeId}
          HFB={project.HFB}
          PA={project.PA}
          salesStart={project.salesStart}
          PPM={project.PPM}
          onSubmit={handleEdit}
          onCancel={handleCloseModal}
          projectId={project.id}
        />
      )
    }

    if (modalType === ModalType.REMOVE) {
      return (
        <FormRemove
          formTitle={shared ? 'Unfollow project' : 'Remove project'}
          formDescription={
            shared
              ? 'Do you want to unfollow project?'
              : 'Are you sure you want to remove the project?'
          }
          onCancel={handleCloseModal}
          onConfirm={handleRemove}
          buttonText={shared ? 'Yes, unfollow!' : 'Yes, remove!'}
        />
      )
    }

    return undefined
  }

  if (projects.length === 0) return null

  return (
    <div>
      <div
        className='clearfix mxn1'
        data-testid='dashboard-project-list'
      >
        {projects.map((project) => (
          <div
            key={project.id}
            className='col width-100 xs-width-50 sm-width-33 md-width-25 xl-width-20 xxl-width-12 px3 pb2 mb3'
          >
            <Project
              id={project.id}
              title={project.title}
              modifiedAt={project.modifiedAt}
              createdAt={project.createdAt}
              imageSrc={project.imageSrc}
              shared={shared}
              onModalRemove={() => handleOpenModal(ModalType.REMOVE, project)}
              onModalEdit={() => handleOpenModal(ModalType.EDIT, project)}
              onOpen={() => onOpen(project.id, project.projectType)}
              onEdit={(data: ProjectFormData) => handleEdit(data, project.id)}
            />
          </div>
        ))}
      </div>

      <Modal
        isOpen={!!modalType}
        onRequestClose={() => handleCloseModal()}
        shouldCloseOnOverlayClick
        width={600}
      >
        {renderModalContent()}
      </Modal>
    </div>
  )
}

export default ProjectList
