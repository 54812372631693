import React from 'react'
import { v4 as uuid } from 'uuid'

import _find from 'lodash/find'

import RepeatableField from '../../common/form/repeatable-field'
import Button from '../../common/button'
import Modal from '../../common/modal'
import TextArea from '../../common/form/textarea'

import Label from '../../common/form/label'
import InputText from '../../common/form/input-text'
import StorageApiImage from '../../common/storage-api-image'
import InputFile from '../../common/form/input-file'

const AddEditPostModal = ({ currentPost, isOpen, onRequestClose, onCreate, onUpdate, onUploadContentImage }) => {
  const [postTitle, setPostTitle] = React.useState((currentPost && currentPost.title) || '')
  const [popupTitle, setPopupTitle] = React.useState((currentPost && currentPost.popupTitle) || '')
  const [popupBody, setPopupBody] = React.useState((currentPost && currentPost.popupBody) || '')
  const [repeatableFields, setRepeatableFields] = React.useState(() => {
    if (currentPost && currentPost.content.length >= 1) {
      return currentPost.content
    } else {
      return [{
        type: 'text',
        id: `new_${uuid()}`
      }]
    }
  })
  const [editedContentId, setEditedContentId] = React.useState([])
  const [removedContentId, setRemovedContentId] = React.useState([])

  const [errors, setErrors] = React.useState({
    postTitle: '',
    popUpTitle: ''
  })

  const contentRef = React.useRef()

  const _handleSave = (e, shouldPublish = false) => {
    e.preventDefault()
    if (!postTitle) return setErrors({ postTitle: 'Post title cannot be empty' })
    if (!popupTitle) return setErrors({ popupTitle: 'Popup title cannot be empty' })

    if (currentPost) {
      const _updatedFields = repeatableFields.filter(field => {
        if (field.id) {
          return editedContentId.includes(field.id)
        }
      }) || []

      onUpdate({
        title: postTitle,
        popupTitle,
        popupBody,
        publish: shouldPublish,
        removedContent: removedContentId,
        content: _updatedFields
      })

      setEditedContentId([])
      setRemovedContentId([])
    } else {
      const _filteredRepeatableFields = repeatableFields.filter(field => field.body) || []
      onCreate(
        {
          title: postTitle,
          popupTitle,
          popupBody,
          content: _filteredRepeatableFields,
          publish: shouldPublish
        }
      )
    }
    setErrors({ postTitle: '', popupTitle: '' })
  }

  const _handlePublish = (e) => {
    _handleSave(e, true)
  }

  const _handleAddContent = (type) => {
    const fields = [
      ...repeatableFields,
      {
        type: type,
        id: `new_${uuid()}`
      }
    ]
    setRepeatableFields(fields)
    window.requestAnimationFrame(() => {
      contentRef.current && contentRef.current.scrollTo(0, contentRef.current.scrollHeight)
    })
  }

  return (
    <Modal
      className='flex flex-column'
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      width='80vw'
      height='95vh'
    >

      <div className='flex flex-column height-100'>
        <div className='flex flex-wrap justify-between border-bottom bc-gray-light mb2'>
          <div>
            <h2 className='mt0'>
              {currentPost && currentPost.id ? 'Edit' : 'Write new'} post
            </h2>
            <p className='f7 mt0'>
                Compose post by adding content blocks with text and/or images. The order of the post's content will appear in the same order of the content blocks below.
            </p>
          </div>

          <div className='sm-col sm-col-12 md-col md-col-6'>
            <div className='mb2'>
              <Label className='flex justify-between'>
                <span>Title</span>
                {errors.postTitle && (<span className='c-error regular'>{errors.postTitle}</span>)}
              </Label>
              <InputText
                defaultValue={(currentPost && currentPost.title) || ''}
                onChange={(change) => setPostTitle(change)}
              />
            </div>

            <div className='mb2'>
              <Label className='flex justify-between'>
                <span>Popup title</span>
                {errors.popupTitle && (<span className='c-error regular'>{errors.popupTitle}</span>)}
              </Label>
              <InputText
                defaultValue={(currentPost && currentPost.popupTitle) || ''}
                onChange={(change) => setPopupTitle(change)}
              />
            </div>

            <div className='mb2'>
              <Label className='flex justify-between'>
                <span>Popup description</span>
                <span className='regular'>Maximum 200 letters</span>
              </Label>
              <TextArea
                maxLength={200}
                defaultValue={(currentPost && currentPost.popupBody) || ''}
                onChange={(change) => setPopupBody(change.target.value)}
              />
            </div>
          </div>

          <div className='sm-col sm-col-12 md-col md-col-6 flex flex-auto justify-end items-end my2'>
            <Button
              disabled={currentPost && currentPost.published}
              className='mr1 flex-none'
              onClick={() => _handleAddContent('text')}>
            Add text
            </Button>
            <Button
              className='flex-none'
              disabled={currentPost && currentPost.published}
              onClick={() => _handleAddContent('image')}>
            Add image
            </Button>
          </div>
        </div>

        <div
          ref={contentRef}
          className='flex-1 mb2 pr2 overflow-x-hidden overflow-y-scroll custom-scrollbar border-bottom bc-gray-light'
        >
          {errors.content && (<span className='block c-error regular f7 mb1'>{errors.content}</span>)}
          {repeatableFields.map((field, index) => (
            <RepeatableField
              key={field.id}
              hideRemoveButton={currentPost && currentPost.published}
              noPadding
              noBorder
              onRemove={() => {
                if (currentPost) {
                  if (!removedContentId.includes(field.id)) {
                    setRemovedContentId([...removedContentId, field.id])
                  }
                }
                setRepeatableFields(repeatableFields.filter(
                  field => field.id !== repeatableFields[index].id
                ))
              }}
            >
              {field.type === 'text' && (
                <TextArea
                  resizeable
                  disabled={currentPost && currentPost.published}
                  defaultValue={_find(repeatableFields, ['id', field.id]).body}
                  style={{ width: '100%', minHeight: 120, whiteSpace: 'pre-line' }}
                  onChange={(change) => {
                    if (currentPost) {
                      if (!editedContentId.includes(field.id)) {
                        setEditedContentId([...editedContentId, field.id])
                      }
                    }
                    setRepeatableFields([
                      ...repeatableFields.slice(0, index),
                      { ...repeatableFields[index], body: change.target.value },
                      ...repeatableFields.slice(
                        index + 1,
                        repeatableFields.length
                      )
                    ])
                  }}
                />
              )}
              {field.type === 'image' && (
                <div
                  className='border bc-gray-light br-small p2 width-100'
                >
                  <StorageApiImage
                    className='border bc-gray-light userselect-none'
                    style={{
                      height: 170,
                      objectFit: 'scale-down',
                      objectPosition: 'center'
                    }}
                    file={_find(repeatableFields, ['id', field.id]).body || undefined}
                  />
                  <InputFile
                    disabled={currentPost && currentPost.published}
                    accept='image/*'
                    onChange={async (change) => {
                      const result = await onUploadContentImage(change)
                      if (currentPost) {
                        if (!(editedContentId.includes(field.id))) {
                          setEditedContentId([...editedContentId, field.id])
                        }
                      }
                      setRepeatableFields([
                        ...repeatableFields.slice(0, index),
                        { ...repeatableFields[index], body: result.files[0] },
                        ...repeatableFields.slice(
                          index + 1,
                          repeatableFields.length
                        )
                      ])
                    }}
                  >
                    Upload image
                  </InputFile>
                </div>
              )}
            </RepeatableField>
          ))}
        </div>

        <div className='flex justify-end self-end width-100'>
          {(!currentPost || !currentPost.published) && (
            <Button
              className='mr1'
              onClick={(e) => _handleSave(e, currentPost && currentPost.published)}
            >
            Save draft
            </Button>
          )}
          <Button
            btnType='primary'
            onClick={_handlePublish}
          >
            {!currentPost || (currentPost && !currentPost.published) ? 'Publish' : 'Update post'}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default AddEditPostModal
