import React from 'react'

export default class BlockMeasurer extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      width: 0,
      height: 0
    }

    this.onRef = this.onRef.bind(this)
    this.measureSize = this.measureSize.bind(this)
  }

  componentDidMount () {
    this.measureSize()
    window.addEventListener('resize', this.measureSize)
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.measureSize)
  }

  measureSize () {
    this.setState({
      width: this.rootElement.clientWidth,
      height: this.rootElement.clientHeight
    })
  }

  onRef (rootElement) {
    this.rootElement = rootElement
  }

  render () {
    return (
      <div ref={this.onRef} style={{ height: '100%' }}>
        {this.props.children({
          measuredWidth: this.state.width,
          measuredHeight: this.state.height
        })}
      </div>
    )
  }
}
