import React, { useState } from 'react'
import { connect } from 'react-redux'
import cs from 'classnames'
import { bindActionCreators } from 'redux'
import { createSelector } from 'reselect'

import { SUPPORT_EMAIL } from '../../constants'
import navigate from '../../utils/navigate'

import ZIndexHandler from '../enhancers/zindex'

import Menu from '../common/menu'
import Button from '../common/button'
import ButtonIcon from '../common/button-icon'
import IconSettings from '../common/icons/icon-settings'
import IconLeave from '../common/icons/icon-leave'
import Modal from '../common/modal'
import { MdReport as ReportIcon } from 'react-icons/md'
import NotificationBubble from '../common/notification-bubble'

import HeaderSeparator from '../layout/header-separator'
import HeaderUser from './header-user'
import Grid from '../common/grid/grid'
import css from './header.css'
import NewsNotification from './news-notification'
import ReportForm from './reportModalForm'

import * as fromUsers from '../../stores/ducks/users'
import * as fromUsersSelectors from '../../stores/ducks/users/selectors'
import { getCurrentEntry as getProjectId } from '../../stores/ducks/projects/selectors'

import { sendReport } from '../../stores/ducks/reports/api'
import * as fromThreeviewerUi from '../../stores/ducks/threeviewer/ui'
import * as fromThreeviewerViewer from '../../stores/ducks/threeviewer/viewer'

const navigateToStart = navigate.bind(navigate, '/', false, true)

const Logo = props => (
  <div
    onClick={() => {
      if (props.onBeforeNavigate) {
        return props.onBeforeNavigate(navigateToStart)
      }
      navigateToStart()
    }}
    data-testid='logo'
    className={cs(
      'flex-none',
      'flex',
      'bold',
      'items-center',
      'justify-center',
      'bg-primary',
      'bg-primary-light-hover',
      'pointer',
      'height-100'
    )}
  >
    DPD
  </div>
)

const BreadCrumb = ({
  route,
  title,
  isLastCrumb,
  onBeforeNavigate,
  hardLink = false
}) => [
  <a
    data-testid='back-to-project-view'
    key='link'
    title={title}
    className={`${css.breadcrumb} truncate c-white`}
    onClick={() => {
      if (onBeforeNavigate) {
        return onBeforeNavigate(navigate.bind(navigate, route, hardLink))
      }

      navigate(route, hardLink)
    }}
  >
    {title}
  </a>,
  !isLastCrumb && (
    <div className='px1 c-white' key='separator'>
      {'>'}
    </div>
  )
]

const LOGO_WIDTH = 64
const PATTERN_SETTINGS_WIDTH = 120

const Header = ({
  isTop, // From ZIndexHandler
  setAsTop, // From ZIndexHandler
  currentUser,
  children,
  breadcrumbs,
  onBeforeNavigate,
  isImagePackageProject,
  isCurrentUserAdmin,
  dispatch,
  logout,
  projectId,
  disableKeyboardListeners,
  enableKeyboardListeners
}) => {
  const [reportModalOpen, setReportModalOpen] = useState(false)
  const [reportSent, setReportSent] = useState(false)

  const getMenuItems = () => {
    return [
      isCurrentUserAdmin && {
        text: 'Admin',
        iconInJSX: <IconSettings />,
        onClick: () => {
          if (onBeforeNavigate) {
            return onBeforeNavigate(navigate.bind(navigate, '/superuser'))
          }
          navigate('/superuser')
        }
      },
      {
        text: 'Logout',
        iconInJSX: <IconLeave />,
        onClick: () => {
          if (onBeforeNavigate) {
            return onBeforeNavigate(
              bindActionCreators(fromUsers.logout, dispatch)
            )
          }

          logout()
        }
      }
    ]
  }

  return (
    <>
      <Grid
        className={cs('f6 bg-gray-dark relative', {
          z2: !isTop,
          z3: isTop
        })}
        onClick={setAsTop}
        columns={[LOGO_WIDTH, '1fr']}
        gridGap={0}
        data-testid='layout-header'
      >
        <Logo onBeforeNavigate={onBeforeNavigate} />
        <Grid
          className='height-100 width-100'
          gridGap={0}
          columns={
            children
              ? [
                'minmax(200px, 2fr)',
                isImagePackageProject ? PATTERN_SETTINGS_WIDTH : '50%',
                LOGO_WIDTH,
                'minmax(200px, 2fr)'
              ]
              : ['1fr', '1fr']
          }
        >
          <div className='c-white flex items-center pl2'>
            {breadcrumbs.map((crumb, index) => (
              <BreadCrumb
                isLastCrumb={index === breadcrumbs.length - 1}
                key={crumb.id}
                {...crumb}
              />
            ))}
          </div>
          {children && [
            <div key='content' className='flex justify-center width-100'>
              {children}
            </div>,
            <div key='used-to-place-content-in-perfect-center' />
          ]}
          <div className='flex justify-end height-100 items-center'>
            <NewsNotification />

            <HeaderSeparator />

            <div className='height-50 flex items-center'>
              <ButtonIcon
                onClick={() => {
                  disableKeyboardListeners()
                  setReportModalOpen(true)
                }}
                btnType='transparent'
                icon={<ReportIcon size={24} />}
                padding
                text='report'
                className={css.report}
                textClassName={css.reportText}
                data-testid='report-button'
              />
            </div>

            <HeaderSeparator />

            <a
              href={'/support'}
              title='DPD Help Guide'
              target='_blank'
              className='height-100 flex items-center'
            >
              <Button
                inverted
                pad={false}
                className='flex items-center px2 mx1 height-50'
              >
                Help?
              </Button>
            </a>

            <HeaderSeparator />

            <div className='ml1 c-white hide-when-small'>
              <HeaderUser user={currentUser} />
            </div>
            <Menu
              style={{ width: 40 }}
              position='bottomLeft'
              items={getMenuItems()}
              iconClass={'c-white icon-angle-down'}
              className={'height-100 m1'}
            />
          </div>
        </Grid>
      </Grid>
      <Modal
        isOpen={reportModalOpen}
        onRequestClose={() => {
          setReportModalOpen(false)
          enableKeyboardListeners()
        }}
        shouldCloseOnOverlayClick
        width={600}
      >
        <ReportForm
          onSubmit={({ title, description, models }, signal) => {
            const data = {
              email: SUPPORT_EMAIL,
              userEmail: currentUser.email,
              userId: currentUser.id,
              title: title,
              description: description,
              projectId: projectId,
              models: models
            }
            return sendReport(data, signal)
          }}
          callNotification={() => setReportSent(true)}
          onCancel={() => {
            setReportModalOpen(false)
            enableKeyboardListeners()
          }}
        />
      </Modal>
      <NotificationBubble
        notification={
          reportSent
            ? 'Your incident report has been sent to the DPD support team. A copy of the report will be sent to your registered email.'
            : null
        }
        notifyTimer={8000}
        onClearOrTimeout={() => {
          setReportSent(false)
        }}
      />
    </>
  )
}

Header.defaultProps = {
  breadcrumbs: []
}

const mapStateToProps = createSelector(
  fromUsersSelectors.getCurrent,
  fromUsersSelectors.getHasCurrentScopes('admin'),
  getProjectId,
  (currentUser, isCurrentUserAdmin, selectedProject) => {
    const projectId = selectedProject ? selectedProject.id : ''
    return {
      currentUser: currentUser || {},
      isCurrentUserAdmin,
      projectId: projectId
    }
  }
)

const mapDispatchToProps = dispatch => ({
  logout: () => {
    dispatch(fromUsers.logout())
  },
  disableKeyboardListeners: () => {
    dispatch(fromThreeviewerUi.setKeyBoardBindings(false))
    dispatch(fromThreeviewerViewer.disableKeyboardListeners())
  },
  enableKeyboardListeners: () => {
    dispatch(fromThreeviewerUi.setKeyBoardBindings(true))
    dispatch(fromThreeviewerViewer.enableKeyboardListeners())
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ZIndexHandler(Header))
