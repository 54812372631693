import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { FEATURES } from '../../constants'
import cs from 'classnames'

// lodash
import _differenceWith from 'lodash/differenceWith'
import _find from 'lodash/find'
import _map from 'lodash/map'
import _orderBy from 'lodash/orderBy'

// components
import { MdPalette as IconSurfaces } from 'react-icons/md'
import Grid from '../common/grid/grid'
import IconGeometry from '../common/icons/icon-cube'
import IconImages from '../common/icons/icon-gallery'
import IconVariants from '../common/icons/icon-group'
import IconPatterns from '../common/icons/icon-pattern'
import IconScene from '../common/icons/icon-scene'
import Footer from '../layout/footer'
import Main from '../layout/main'
import Wrapper from '../layout/wrapper'
import type { Item } from './card/Item'
import { AiOutlineDeploymentUnit as IconVP } from 'react-icons/ai'
import FolderList from './folders'
import Header from './Header'
import SideNav from './sidenav'
import TabHeader from './TabHeader'
import Tools from './Tools'
import ProjectContent from './ProjectContent'
import { stylesProjectView as styles } from './projectStyles'
import ProjectModals from './ProjectModals'

// redux
import * as fromProjectsSelectors from '../../stores/ducks/projects/selectors'
import * as fromFoldersSelectors from '../../stores/ducks/folders/selectors'
import * as fromSceneSelectors from '../../stores/ducks/scenes/selectors'
import * as fromColorsSelectors from '../../stores/ducks/colors/selectors'
import { getIsFeatureActive as getIsFeatureActiveSelector } from '../../stores/ducks/threeviewer/selectors'
import { selectItems as selectItemsSelector, dnpPropsSelector } from './selectors/projectSelectors'

import * as fromProjects from '../../stores/ducks/projects'
import * as fromFolders from '../../stores/ducks/folders'
import * as fromScenes from '../../stores/ducks/scenes'
import * as fromExternal from '../../stores/ducks/external'
import * as fromPatternsJson from '../../stores/ducks/patterns/json'
import * as fromProjectView from '../../stores/ducks/interface/project-view'
import * as fromColors from '../../stores/ducks/colors'
import * as fromExports from '../../stores/ducks/export'

// types
import type { RootState } from '../../stores/ducks'
import type { Folder } from '../../stores/ducks/folders/Folder'

export enum ProjectTabs {
  GEOMETRY = 'geometry',
  IMAGES = 'images',
  VARIANTS = 'variants',
  PATTERNS = 'patterns',
  COLORS = 'colors',
  SCENES = 'scenes',
  VIRTUAL_PRODUCTS = 'virtualProducts'
}

export enum GlobalProjectModals {
  GALLERY = 'gallery',
  REMOVE = 'remove',
  SEND_TO_DNP = 'sendToDnp',
  NOTIFICATION = 'notification',
  FOLDER = 'folder',
  REMOVE_AS_ADMIN = 'removeAsAdmin'
}

export enum AssetType {
  GEOMETRY = 'geometry',
  IMAGES = 'image',
  VARIANTS = 'variant',
  PATTERNS = 'pattern',
  SCENE = 'scene',
  FOLDER = 'folder',
  APPERANCE = 'appearance',
  VIRTUAL_PRODUCT = 'virtualProduct',
  EMPTY = ''
}

export type FolderModalType = 'create' | 'edit'
export type ProjectSearchFilters = null | 'local' | 'sent' | 'approved' | 'draft'

function Project ({ projectId }: {
  projectId: string
}) {
  const dispatch = useDispatch()
  const [selectedTab, setSelectedTab] = useState<ProjectTabs>(ProjectTabs.GEOMETRY)
  const [activeModal, setActiveModal] = useState<GlobalProjectModals | null>(null)
  const [activeItem, setActiveItem] = useState<Item | null>(null)
  const [folderIdToRemove, setFolderIdToRemove] = useState<null | string>(null)
  const [folderModalType, setFolderModalType] = useState<FolderModalType>('create')
  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const [currentFolder, setCurrentFolder] = useState<null | Folder>(null)
  const [isDragging, setIsDragging] = useState<boolean>(false)
  const [searchFilter, setSearchFilter] = useState<ProjectSearchFilters>('local')

  const folders = useSelector(fromFoldersSelectors.getEntriesInProject)
  const projectEntry = useSelector(fromProjectsSelectors.getCurrentEntry)
  const selectItems = useSelector(selectItemsSelector)
  const userScenes = useSelector(fromSceneSelectors.getUserScenes)
  const userColors = useSelector((state: RootState) => state.colors)
  const dnpProps = useSelector(dnpPropsSelector)
  const getIsFeatureActive = useSelector(getIsFeatureActiveSelector)
  const searchString = useSelector(fromColorsSelectors.selectColorSearchString)

  useEffect(() => {
    dispatch(fromProjects.setCurrent(projectId))
    dispatch(fromProjects.getProject(projectId, { mergeCombinations: true, force: true }))
    dispatch(fromFolders.getFolders())
    dispatch(fromPatternsJson.getAvailablePatterns())
    dispatch(fromColors.getColorsAdmin())
    dispatch(fromScenes.fetchUserScenes())
    dispatch(fromExternal.getExternalViews(projectId))
    dispatch(fromExports.getAllExportsInProject(projectId))
  }, [])

  useEffect(() => {
    if (!currentFolder) return
    const updatedFolder = _find(folders, { id: currentFolder.id })
    setCurrentFolder(updatedFolder)
  }, [folders])

  function getDoneGeometryCombinations (inProject = false) {
    const geometries = currentFolder
      ? (selectItems.currentFolderCombinations as Item[]).filter(c => c.combinationType === 'convert')
      : selectItems.geometryCombinations
    return (geometries as Item[]).filter(c => inProject ? c.projectId === projectId : true)
  }

  function getDonePatterns (inProject = false) {
    const patterns = currentFolder
      ? selectItems.currentFolderPatterns
      : selectItems.patterns
        .filter(p => p.patternFilePath)
    return (patterns as unknown as Item[]).filter(p => inProject ? p.inCurrentProject : true)
  }

  function getDoneVariantCombinations (inProject = false) {
    const variants = currentFolder
      ? (selectItems.currentFolderCombinations as Item[]).filter(c => c.combinationType === 'variant')
      : selectItems.variantCombinations
    return (variants as Item[]).filter(c => inProject ? c.projectId === projectId : true)
  }

  function getDoneImageCombinations (inProject = false) {
    let images = selectItems.imageCombinations as Item[]

    if (currentFolder) {
      const imagesInFolder = (selectItems.currentFolderCombinations as Item[])
        .filter(c => c.combinationType === 'render')
        .filter(c => inProject ? c.projectId === projectId : true)
      images = _orderBy(
        imagesInFolder,
        ['render.rendered', 'render.renderedAt'],
        ['asc', 'desc']
      )
    }
    return images.filter(c => inProject ? c.projectId === projectId : true)
  }

  function handleNavSelection (tab: ProjectTabs) {
    if (!currentFolder) {
      switch (selectedTab) {
        case ProjectTabs.GEOMETRY: dispatch(fromProjectView.resetGeometries(projectId)); break
        case ProjectTabs.PATTERNS: dispatch(fromProjectView.resetPatterns(projectId)); break
        case ProjectTabs.VARIANTS: dispatch(fromProjectView.resetVariants(projectId)); break
        case ProjectTabs.IMAGES: dispatch(fromProjectView.resetImages(projectId)); break
      }
    }
    setSelectedTab(tab)
    setSearchFilter('local')
    setSelectedIds([])
  }

  function renderCurrentIcon () {
    const size = 24
    switch (selectedTab) {
      case ProjectTabs.GEOMETRY: return <IconGeometry size={size} />
      case ProjectTabs.PATTERNS: return <IconPatterns size={size} />
      case ProjectTabs.VARIANTS: return <IconVariants size={size} />
      case ProjectTabs.IMAGES: return <IconImages size={size} />
      case ProjectTabs.COLORS: return <IconSurfaces size={size} />
      case ProjectTabs.SCENES: return <IconScene size={size} />
      case ProjectTabs.VIRTUAL_PRODUCTS: return <IconVP size={size + 4} />
      default: return null
    }
  }

  function addAssetsToFolder (newFolderId: string, removeFromFolder = false) {
    let selectedIdsLocal = selectedIds
    const assetType = selectedTab === ProjectTabs.PATTERNS ? 'patterns' : 'combinations'
    const folder = _find(folders, folder => folder.id === newFolderId)

    if (removeFromFolder) {
      removeSelectionFromFolder(newFolderId)
      return
    }

    if (folder) {
      const assetIds = _map(Object.keys(folder[assetType]), id => id)
      selectedIdsLocal = _differenceWith(selectedIds, assetIds)
    }

    if (selectedIds.length > 0) {
      if (assetType === 'patterns') {
        dispatch(fromFolders.addConnectedIds([], selectedIdsLocal, newFolderId))
      } else {
        dispatch(fromFolders.addConnectedIds(selectedIdsLocal, [], newFolderId))
      }
      setSelectedIds([])
    } else {
      dispatch(fromProjects.notify('All items already in folder'))
    }
  }

  function removeSelectionFromFolder (folderId: string) {
    if (selectedTab === ProjectTabs.PATTERNS) {
      dispatch(fromFolders.removeConnectedIds([], selectedIds, folderId))
    } else {
      dispatch(fromFolders.removeConnectedIds(selectedIds, [], folderId))
    }
    setSelectedIds([])
  }

  function getIsAllSelected () {
    const searchedItemIds = getSearchedItems(getDoneItems()).map(render => render.id)

    return (selectedIds.length > 0 && selectedIds.length === searchedItemIds.length) ?? false
  }

  function getSearchedItems (items: Item[]) {
    let searchedItems = items
    if (searchFilter) {
      searchedItems = searchedItems.filter(item => {
        if (searchFilter === 'local') { return !item.sentAssetId }
        if (searchFilter === 'sent') { return item.sentAssetId }
        return item
      })
    }

    if (searchString && searchString !== '') {
      searchedItems = searchedItems.filter(item => {
        const reg = new RegExp(searchString, 'ig')
        if (selectedTab === ProjectTabs.SCENES) return reg.test(item.title) || reg.test(item.projectTitle || '')
        return reg.test(item.title)
      })
    }

    return searchedItems
  }

  function getDoneItems () {
    switch (selectedTab) {
      case ProjectTabs.IMAGES: return getDoneImageCombinations()
      case ProjectTabs.GEOMETRY: return getDoneGeometryCombinations()
      case ProjectTabs.VARIANTS: return getDoneVariantCombinations()
      case ProjectTabs.PATTERNS: return getDonePatterns()
      default: return []
    }
  }

  function getIsCurrentPageEmpty () {
    const getIsGeometryPageEmpty = () => {
      const searchedItems = getSearchedItems(getDoneGeometryCombinations() as Item[])
      const hasItemsInProgress = selectItems.geometryInProgress.length > 0
      if (hasItemsInProgress && searchFilter === 'sent') {
        return !searchedItems.length
      }
      return (!hasItemsInProgress || currentFolder) && !searchedItems.length
    }
    const getIsImagePageEmpty = () => {
      const searchedItems = getSearchedItems(getDoneImageCombinations() as Item[])
      const hasItemsInProgress = selectItems.imagesInProgress.length > 0
      if (hasItemsInProgress && searchFilter === 'sent') {
        return !searchedItems.length
      }
      return (!hasItemsInProgress || currentFolder) && !searchedItems.length
    }

    const getIsVariantPageEmpty = () => {
      const searchedItems = getSearchedItems(getDoneVariantCombinations() as Item[])
      const hasItemsInProgress = selectItems.variantsInProgress.length > 0
      if (hasItemsInProgress && searchFilter === 'sent') {
        return !searchedItems.length
      }
      return (!hasItemsInProgress || currentFolder) && !searchedItems.length
    }

    const getIsPatternPageEmpty = () => {
      const searchedItems = getSearchedItems(getDonePatterns() as unknown as Item[])
      const hasItemsInProgress = selectItems.patternsInProgress.length > 0
      if (hasItemsInProgress && searchFilter === 'sent') {
        return !searchedItems.length
      }
      return (!hasItemsInProgress || currentFolder) && !searchedItems.length
    }

    switch (selectedTab) {
      case ProjectTabs.GEOMETRY: return getIsGeometryPageEmpty()
      case ProjectTabs.IMAGES: return getIsImagePageEmpty()
      case ProjectTabs.VARIANTS: return getIsVariantPageEmpty()
      case ProjectTabs.PATTERNS: return getIsPatternPageEmpty()
      default: return false
    }
  }

  function handleToggleSelectAll () {
    if (getIsAllSelected()) setSelectedIds([])
    else {
      // Select all
      const searchedItems = getSearchedItems(getDoneItems()).map(render => render.id)
      setSelectedIds(searchedItems)
    }
  }

  function getItemsInProgress () {
    if (currentFolder) return []
    switch (selectedTab) {
      case ProjectTabs.IMAGES: return selectItems.imagesInProgress
      case ProjectTabs.GEOMETRY: return selectItems.geometryInProgress
      case ProjectTabs.VARIANTS: return selectItems.variantsInProgress
      case ProjectTabs.PATTERNS: return selectItems.patternsInProgress
      default: return []
    }
  }

  function getDoneAssets (type: AssetType) {
    switch (type) {
      case AssetType.GEOMETRY: return getDoneImageCombinations()
      case AssetType.IMAGES: return getDoneImageCombinations()
      case AssetType.VARIANTS: return getDoneVariantCombinations()
      case AssetType.PATTERNS: return getDonePatterns()
      default: return []
    }
  }

  function getCurrentContentName () {
    if (folderIdToRemove) return AssetType.FOLDER
    switch (selectedTab) {
      case ProjectTabs.IMAGES: return AssetType.IMAGES
      case ProjectTabs.GEOMETRY: return AssetType.GEOMETRY
      case ProjectTabs.VARIANTS: return AssetType.VARIANTS
      case ProjectTabs.PATTERNS: return AssetType.PATTERNS
      case ProjectTabs.SCENES: return AssetType.SCENE
      default: return '' as AssetType
    }
  }

  function handleUploaded (data: any) {
    dispatch(fromProjects.receiveCadKey(data, projectId))
  }

  /* Folder functions */
  function handleToggleFolderOpen (folder: Folder) {
    const currentFolderId = currentFolder && currentFolder.id
    if (folder.id === currentFolderId) setCurrentFolder(null)
    else handleOpenFolder(folder)
  }

  function handleOpenFolder (folder: Folder) {
    setCurrentFolder(folder)
    dispatch(fromFolders.setCurrent(folder.id))
  }

  function handleRemoveFolder (id: string) {
    dispatch(fromFolders.remove(id))
    if (currentFolder && id === currentFolder.id) setCurrentFolder(null)
  }

  /** Helper functions */
  const resetSelectedIds = () => setSelectedIds([])

  /** For readability */
  const showOpenDnp = dnpProps.isDnpProject && dnpProps.isDnpUser
  const showSendToDnp = selectedTab === 'images' && dnpProps.isDnpProject

  const virtualProductIsActive = getIsFeatureActive(FEATURES.VIRTUAL_PRODUCT, true)
  const searchedItems = getSearchedItems(getDoneItems() as Item[])

  return (
    <Wrapper>
      <Header />
      <Main>
        <div className='flex height-100 overflow-hidden'>
          <div style={styles.leftBar}>
            <SideNav
              virtualProductIsActive={virtualProductIsActive}
              virtualProductCount={projectEntry.virtualProductCount ?? 0}
              geometryCount={getDoneGeometryCombinations(true).length}
              patternCount={getDonePatterns(true).length}
              variantCount={getDoneVariantCombinations(true).length}
              imageCount={getDoneImageCombinations(true).length}
              sceneCount={userScenes.length}
              colorsCount={Object.values(userColors.colors).filter(color => color.selectable).length}
              onSelection={handleNavSelection}
              showOpenDnp={showOpenDnp}
              onOpenDnp={() => dispatch(fromProjects.goToDnp(projectId))}
              projectId={projectId}
            />
          </div>

          <Grid
            rows={[96, 72, '1fr']}
            columns={['1fr', 216]}
            areas={
              selectedTab === ProjectTabs.SCENES
                ? styles.gridAreas.scenesTab
                : (selectedTab === ProjectTabs.VIRTUAL_PRODUCTS || selectedTab === ProjectTabs.COLORS)
                  ? styles.gridAreas.noFolders
                  : styles.gridAreas.default
            }
            gridGap={0}
            className='width-100 height-100 overflow-hidden'
          >
            <TabHeader
              style={{ gridArea: 'top' }}
              currentTab={selectedTab}
              currentTabIcon={renderCurrentIcon()}
              currentFolder={currentFolder}
              lockedFolder={currentFolder && currentFolder.locked}
              onEditFolder={() => {
                setFolderModalType('edit')
                setActiveModal(GlobalProjectModals.FOLDER)
              }}
              onCloseFolder={() => setCurrentFolder(null)}
            />

            {!['scenes'].includes(selectedTab) && (
              <div style={styles.toolStyles} className='flex items-center justify-end pr2'>
                <Tools
                  getSelectedCombination={(id) => getDoneImageCombinations().find(obj => obj.id === id)}
                  imageCombinations={selectItems.imageCombinations}
                  selectedIds={selectedIds}
                  selectedTab={selectedTab}
                  currentFolder={currentFolder}
                  folders={folders.filter(folder => !folder.locked)}
                  onAddCombsToFolder={addAssetsToFolder}
                  onOpenModal={(modal) => {
                    if (modal === 'remove') setActiveModal(GlobalProjectModals.REMOVE)
                    if (modal === 'sendToDnp') setActiveModal(GlobalProjectModals.SEND_TO_DNP)
                    if (modal === 'createFolder') {
                      setActiveModal(GlobalProjectModals.FOLDER)
                      setFolderModalType('create')
                    }
                  }}
                  allSelected={getIsAllSelected()}
                  disableSelectAll={getIsCurrentPageEmpty() || selectedTab === ProjectTabs.COLORS}
                  onToggleSelectAll={handleToggleSelectAll}
                  onSearchFilterChange={(value) => setSearchFilter(value === searchFilter ? null : value)}
                  searchFilter={searchFilter}
                  searchString={searchString}
                  onSearchChange={(value: string) => dispatch(fromColors.setColorSearchString(value))}
                  onClearSearch={() => dispatch(fromColors.setColorSearchString(''))}
                  showSendToDnp={showSendToDnp}
                  resetSelectedIds={resetSelectedIds}
                />
              </div>
            )}

            {![ProjectTabs.SCENES, ProjectTabs.VIRTUAL_PRODUCTS, ProjectTabs.COLORS].includes(selectedTab) && (
              <div style={{ gridArea: 'folders' }} className='width-100 height-100'>
                <FolderList
                  folders={folders}
                  selectedId={currentFolder?.id || ''}
                  selectedIds={selectedIds}
                  onAdd={() => {
                    setFolderModalType('create')
                    setActiveModal(GlobalProjectModals.FOLDER)
                  }}
                  onOpen={handleToggleFolderOpen}
                  onRemove={(folderId: string) => {
                    setActiveModal(GlobalProjectModals.REMOVE)
                    setFolderIdToRemove(folderId)
                  }}
                  onDrop={addAssetsToFolder}
                  onDropNewFolder={() => {
                    setFolderModalType('create')
                    setActiveModal(GlobalProjectModals.FOLDER)
                  }}
                  isDragging={isDragging}
                />
              </div>
            )}

            <div style={{ gridArea: 'content' }} className={cs({
              'overflow-overlay': selectedTab !== ProjectTabs.SCENES
            })}>
              <ProjectContent
                folders={folders}
                currentFolder={currentFolder}

                searchedItems={searchedItems}
                searchFilter={searchFilter}

                selectedTab={selectedTab}
                selectedIds={selectedIds}
                setSelectedIds={setSelectedIds}

                virtualProductIsActive={virtualProductIsActive}
                searchString={searchString}
                // content
                getItemsInProgress={getItemsInProgress}
                getIsCurrentPageEmpty={getIsCurrentPageEmpty}
                handleUploaded={handleUploaded}
                getDoneAssets={getDoneAssets}

                // helper functions
                getCurrentContentName={getCurrentContentName}
                isDragging={isDragging}
                setIsDragging={setIsDragging}
                setActiveModalAndItem={(modal: (GlobalProjectModals | null), item: Item) => {
                  setActiveModal(modal)
                  setActiveItem(item)
                }}
                projectId={projectId}
                activeItem={activeItem}
                setActiveItem={setActiveItem}
                handlePreview={(comb: Item) => {
                  setActiveModal(GlobalProjectModals.GALLERY)
                  setActiveItem(comb)
                }}
              />
            </div>
            <div style={{ gridArea: 'footer' }}>
              <Footer/>
            </div>
          </Grid>
        </div>
        <ProjectModals
          activeModal={activeModal}
          searchedItems={searchedItems}
          selectedIds={selectedIds}
          activeItem={activeItem}
          selectedTab={selectedTab}
          projectId={projectId}
          folderIdToRemove={folderIdToRemove}
          currentFolder={currentFolder}
          folderModalType={folderModalType}
          getCurrentContentName={getCurrentContentName}
          handleRemoveFolder={handleRemoveFolder}
          setActiveItem={setActiveItem}
          setFolderIdToRemove={setFolderIdToRemove}
          resetActiveModal={() => setActiveModal(null)}
          resetSelectedIds={resetSelectedIds}
        />
      </Main>
    </Wrapper>
  )
}

export default Project
