import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ImmutableObject } from 'seamless-immutable'
import type { RootState } from '../../../stores/ducks'
import * as fromVirtualProducts from '../../../stores/ducks/virtual-products'
import type { PlaceholderType, UpdateVirtualProductPayload, VirtualProduct, VirtualProductProjectListItem } from '../../../stores/ducks/virtual-products/VirtualProduct'
import Button from '../../common/button'
import InputGroup from '../../common/form/input-group'
import InputText from '../../common/form/input-text'
import Label from '../../common/form/label'
import Grid from '../../common/grid/grid'
import KeyboardListener, { KeyBinding, KeyCode } from '../../common/keyboard-listener'
import { PillLarge } from '../../common/Pill'

export interface NullablePlaceholderAttributes {
    productName: PlaceholderType['productName']['productName'] | null
    productType: PlaceholderType['productType']['productTypeName'] | null
    validDesign: PlaceholderType['validDesign']['validDesignText'] | null
    measurement: PlaceholderType['measureReference']['measureReferenceMetric'] | null
  }

export const placeholderAttributes: NullablePlaceholderAttributes = {
  productName: null,
  productType: null,
  validDesign: null,
  measurement: null,
}

interface Props {
  onClose: () => void
  onConnect: () => void
}

export default function CreateVirtualProductModal (props: Props) {
  const dispatch = useDispatch()
  const entries = useSelector((state: RootState) => state.virtualProducts.entries as ImmutableObject<{ [id: string]: VirtualProductProjectListItem }>)
  const projectId = useSelector((state: RootState) => state.projects.currentId as null | string)
  const [creatingVP, setCreatingVP] = React.useState(false)

  const [metadata, setPlaceholderAttributes] = React.useState(placeholderAttributes)
  const [name, setName] = React.useState<VirtualProduct['virtualProductDatabaseData']['name'] | null>(null)
  const [itemNumber, setItemNumber] = React.useState<VirtualProduct['virtualProductDatabaseData']['itemNumber'] | null>(null)
  const [status, setStatus] = React.useState<VirtualProduct['virtualProductDatabaseData']['status']>('Draft')
  const [lifecycle, setLifecycle] = React.useState<VirtualProduct['virtualProductDatabaseData']['lifecycle']>('ProductIdea')

  useEffect(() => {
    // Update is done when new VP has come in
    if (creatingVP && entries) {
      setCreatingVP(false)
      return props.onClose()
    }
  }, [entries])

  const isEmptyField = (value: string | null): boolean => value === null || value.trim().length <= 0

  const handleInputChange = (fieldName: keyof NullablePlaceholderAttributes) => (inputValue: string): void => {
    setPlaceholderAttributes({
      ...metadata,
      [fieldName]: inputValue === '' ? null : inputValue,
    })
  }

  const create = async (): Promise<void> => {
    if (name === null || name.trim().length <= 0) {
      return
    }

    const body: UpdateVirtualProductPayload = {
      name: name.trim(),
      status: status,
      lifecycle: lifecycle,
    }
    if (itemNumber) {
      body.itemNumber = itemNumber.trim()
    }

    const { productName, productType, validDesign, measurement } = metadata
    body.placeholderAttributes = {
      productName: { productName: `${productName ? productName.trim() : ''}` },
      productType: { productTypeName: `${productType ? productType.trim() : ''}` },
      validDesign: { validDesignText: `${validDesign ? validDesign.trim() : ''}` },
      measureReference: { measureReferenceMetric: `${measurement ? measurement.trim() : ''}` },
    }

    if (projectId) {
      setCreatingVP(true)
      dispatch(fromVirtualProducts.createVirtualProduct(projectId, body))
    }
  }

  return (
    <Grid
      className='height-100 width-100'
      areas={[
        'title',
        'inputfields',
        'confirmation'
      ]}
      columns={['1fr']}
      rows={['2rem', '1fr', 'auto']}
      gridGap={16}
      style={{ position: 'relative' }}
    >
      <div style={{ gridArea: 'title' }} className='flex justify-between'>
        <h3 className='m0'>Create Virtual Product</h3>
      </div>

      <div style={{ gridArea: 'inputfields' }}
        className='height-100 overflow-auto' >
        <div>
          <div className='width-100'>
            <Label className='flex justify-between'>Name
              {isEmptyField(name) && <span className='c-error regular'>*Required</span>}
            </Label>
            <InputText value={name ?? ''} onChange={(input: string) => setName(input)}/>
          </div>
          <div className='width-100 my2'>
            <Label>Item number</Label>
            <InputText value={itemNumber ?? ''} onChange={(input: string) => setItemNumber(input)}/>
          </div>
          <div className='width-100 my2'>
            <Label>Product name</Label>
            <InputText value={metadata.productName ?? ''} onChange={handleInputChange('productName')}/>
          </div>
          <div className='width-100 my2'>
            <Label>Design</Label>
            <InputText value={metadata.validDesign ?? ''} onChange={handleInputChange('validDesign')} name='validDesign'/>
          </div>
          <div className='width-100 my2'>
            <Label>Product type</Label>
            <InputText value={metadata.productType ?? ''} onChange={handleInputChange('productType')}/>
          </div>
          <div className='width-100'>
            <Label>Measurement (metric)</Label>
            <InputText value={metadata.measurement ?? ''} onChange={handleInputChange('measurement')}/>
          </div>
        </div>
        <div className='pt3'>
          <div className='pb1'>
            <Label>Life cycle:</Label>
            <div className='flex flex-row flex-wrap'>
              <PillLarge
                className='mr1 mb2'
                active={lifecycle === 'ProductIdea'}
                onClick={() => { setLifecycle('ProductIdea') }}
              >Product Idea</PillLarge>
              <PillLarge
                className='mr1 mb2'
                active={lifecycle === 'DesignConcept'}
                onClick={() => { setLifecycle('DesignConcept') }}
              >Design Concept</PillLarge>
              <PillLarge
                className='mr1 mb2'
                active={lifecycle === 'ProductConcept'}
                onClick={() => { setLifecycle('ProductConcept') }}
              >Product Concept</PillLarge>
              <PillLarge
                className='mr1 mb2'
                active={lifecycle === 'DevelopmentPrototype'}
                onClick={() => { setLifecycle('DevelopmentPrototype') }}
              >Development Prototype</PillLarge>
              <PillLarge
                className='mb2'
                active={lifecycle === 'Cancelled'}
                onClick={() => { setLifecycle('Cancelled') }}
              >Canceled</PillLarge>
            </div>
          </div>
          <InputGroup>
            <Label>Status:</Label>
            <div className='flex sm-flex-column'>
              <PillLarge active={status === 'Draft'} onClick={() => setStatus('Draft')} className='mr1'>Draft</PillLarge>
              <PillLarge active={status === 'Approved'} onClick={() => setStatus('Approved')}>Approved</PillLarge>
            </div>
          </InputGroup>
        </div>
      </div>
      <div className='flex justify-end' style={{ gridArea: 'confirmation' }}>
        <Button className='mr2' onClick={props.onClose}>Cancel</Button>
        <Button
          btnType="primary"
          onClick={create}
          disabled={creatingVP || isEmptyField(name)}
        >Create</Button>
      </div>
      <KeyboardListener
        disabled={creatingVP || isEmptyField(name)}
        bindings={[
          KeyBinding(KeyCode.enter, create)
        ]}
      />
    </Grid>
  )
}
