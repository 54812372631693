import { useSelector } from 'react-redux'
import { ImmutableObject } from 'seamless-immutable'

import _get from 'lodash/get'

import * as fromUsersSelectors from '../users/selectors'
import * as fromProjectsSelectors from '../projects/selectors'

const groupProjectsByType = (projects: ImmutableObject<{}>, userId: string) => {
  const ids = Object.keys(projects).filter((id) => !_get(projects, [id, 'removedAt']))

  return {
    myProjects: ids.filter((id) => _get(projects, [id, 'createdBy']) === userId),
    sharedProjects: ids.filter((id) => _get(projects, [id, 'sharedWith', userId]))
  }
}

type ProjectTab = {
  id: number,
  label: string,
  disabled?: boolean
}

type DashboardData = {
  currentUserId: string,
  isCurrentUserAdmin: boolean,
  myProjects: string[],
  sharedProjects: string[],
  dashboardStatus: string,
  projectsTabs: ProjectTab[]
}

export const useDashboardSelectors = (): DashboardData => {
  const projects = useSelector(fromProjectsSelectors.getEntries)
  const dashboardStatus = useSelector(fromProjectsSelectors.getDashboardStatus)
  const currentUser = useSelector(fromUsersSelectors.getCurrent)
  const isCurrentUserAdmin = useSelector(fromUsersSelectors.getHasCurrentScopes('admin'))

  const { myProjects, sharedProjects } = groupProjectsByType(projects, _get(currentUser, 'id'))

  return {
    currentUserId: _get(currentUser, 'id'),
    isCurrentUserAdmin,
    myProjects,
    sharedProjects,
    dashboardStatus,
    projectsTabs: [
      {
        id: 0,
        label: `MY PROJECTS (${myProjects.length})`
      },
      {
        id: 1,
        label: `SHARED WITH ME (${sharedProjects.length})`,
        disabled: !sharedProjects.length
      }
    ]
  }
}
