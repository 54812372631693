import Immutable from 'seamless-immutable'
import { handleActions, createAction } from 'redux-actions'

import _keyBy from 'lodash/keyBy'
import _get from 'lodash/get'
import _omit from 'lodash/omit'
import _isEmpty from 'lodash/isEmpty'

import * as selectors from './selectors'

import * as fromImagePackages from '../image-packages'
import * as fromCombinations from '../combinations'
import * as fromUploads from '../uploads'
import * as fromRenders from '../renders'

import * as fromMaterialSearch from '../material-search'

const error = createAction('projects/ERROR')
export const setNotificationMessage = createAction('projects/SET_NOTIFICATION_MESSAGE')
export const setErrorNotificationMessage = createAction('projects/SET_ERR_NOTIFICATION_MESSAGE')
export const setCurrent = createAction('projects/SET_CURRENT')
export const removed = createAction('projects/REMOVED')
export const receive = createAction('projects/RECEIVE')
export const clearMarkedAppearances = createAction('projects/CLEAR_MARKED_APPEARANCES')
export const clearMarkedColors = createAction('projects/CLEAR_MARKED_COLORS')
export const setDashboardStatus = createAction('projects/SET_DASHBOARD_STATUS')
const fetching = createAction('projects/FETCH')
const updateFavoritesAction = createAction('projects/UPDATE_FAVORITES')

const shouldFetchProject = ({ project, queryParams, force }) => {
  return (
    !project ||
    queryParams.mergeComments ||
    (queryParams.mergeCombinations && _isEmpty(project.parentCombinationIds)) ||
    force
  )
}

function normalizeData (data) {
  data = [].concat(data) // will ensure data is an array

  return (dispatch, getState) => {
    const currentUserId = getState().users.currentUserId

    const projects = data
      .filter(doc => (doc.createdBy === currentUserId || _get(doc, ['sharedWith', currentUserId])))
      .map((doc) => _omit(doc, 'allCombinations', 'renders', 'uploads', 'imagePackage'))

    const combinations = data.reduce((acc, project) => acc.concat(project.combinations), [])
    const renders = data.reduce((acc, project) => acc.concat(project.renders), [])
    const uploads = data.reduce((acc, project) => acc.concat(project.uploads), [])
    const imagePackages = data.reduce((acc, project) => acc.concat(project.imagePackage), [])

    dispatch(receive(projects))
    dispatch(fromImagePackages.receive(imagePackages))
    dispatch(fromCombinations.receive(combinations))
    dispatch(fromUploads.receive(uploads))
    dispatch(fromRenders.receive(renders))
  }
}

export function getProject (id, params = {}, force) {
  return (dispatch, getState, { api }) => {
    const project = selectors.selectProjectById(id)(getState())

    if (shouldFetchProject({ project, queryParams: params, force })) {
      dispatch(fetching())

      return api.projects.get(id, params)
        .then((json) => dispatch(normalizeData(json)))
    }
  }
}

export function getDashboard () {
  return (dispatch, getState, { api }) => {
    if (selectors.getDashboardStatus(getState()) === statuses.FETCHED) return
    dispatch(setDashboardStatus(statuses.FETCHING))
    dispatch(fetching())
    dispatch(setCurrent(null))
    return api.projects.getDashboard()
      .then(json => {
        dispatch(receive(json))
        dispatch(setDashboardStatus(statuses.FETCHED))
      })
  }
}

export function getProjects () {
  return (dispatch, getState, { api }) => {
    dispatch(fetching())
    dispatch(setCurrent(null))
    return api.projects.getAll()
      .then((json) => dispatch(normalizeData(json)))
  }
}

export function receiveCadKey (data, projectId) {
  return (dispatch, getState, { api }) => {
    return api.projects.getCadManifest(data.manifestKey)
      .then((manifest) => dispatch(postCadManifest(manifest, data, projectId)))
      .catch((err) => dispatch(error(err)))
  }
}

function postCadManifest (manifest, data, projectId) {
  return (dispatch, getState, { api }) => {
    return api.projects.postCadManifest(Object.assign({}, data, {
      manifest: manifest,
      projectId: projectId,
      type: 'cad'
    }))
      .then((json) => dispatch(receive(json)))
      .catch((err) => dispatch(error(err)))
  }
}

export function create (data) {
  return (dispatch, getState, { api }) => {
    return api.projects.create(data)
      .then((json) => dispatch(receive(json)))
      .catch((err) => dispatch(error(err)))
  }
}

export function update (data) {
  return (dispatch, getState, { api }) => {
    dispatch({ type: 'projects/UPDATE', data })
    return api.projects.update(data)
      .then((json) => dispatch(receive(json)))
      .catch((err) => dispatch(error(err)))
  }
}

export function remove (id) {
  return (dispatch, getState, { api }) => {
    return api.projects.remove(id)
      .then(() => dispatch(removed(id)))
      .catch((err) => dispatch(error(err)))
  }
}

export function updateFavorites (data) {
  return (dispatch, getState, { api }) => {
    dispatch(updateFavoritesAction(data))
    return api.projects.updateFavorites(data)
      .then((json) => dispatch(receive(json)))
      .then(() => {
        if (data.markedAppearances) {
          dispatch(fromMaterialSearch.performSearch())
        }
      })
      .catch((err) => dispatch(error(err)))
  }
}

export function toggleShare (projectId, userId) {
  return (dispatch, getState, { api }) => {
    return api.projects.toggleShare(projectId, userId)
      .then((json) => dispatch(receive(json)))
      .catch((err) => dispatch(error(err)))
  }
}

export function removeCombination (projectId, combinationId) {
  return (dispatch, getState, { api }) => {
    return api.combinations.remove(combinationId, projectId)
      .then((json) => {
        dispatch(receive(json))
        dispatch(fromCombinations.remove(combinationId))
      })
      .catch((err) => dispatch(error(err)))
  }
}

export const removeCameraSetting = (id) => (dispatch, getState) => {
  const currentProject = selectors.getCurrentEntry(getState())
  return dispatch(update({
    id: currentProject.id,
    cameraList: { [id]: { removedAt: new Date() } }
  }))
}

export const notify = (message) => {
  return (dispatch) => {
    dispatch(setNotificationMessage(message))
  }
}

export const sendToDnp = (projectId, renderIds) => {
  return (dispatch, _, { api }) => {
    return api.projects.uploadToDnp(projectId, renderIds)
      .then((res) => {
        dispatch(setNotificationMessage(res))
      })
      .catch((err) => {
        dispatch(error(err))
        dispatch(setErrorNotificationMessage('Failed to send to DNP.'))
      })
  }
}

export const goToDnp = (projectId) => {
  return (dispatch, getState, { api }) => {
    const currentUserId = getState().users.currentUserId
    return api.projects.getDnpLoginLink(projectId, currentUserId)
      .then((link) => {
        window.open(link, '_blank')
      })
      .catch((err) => dispatch(error(err)))
  }
}

export const statuses = {
  PENDING: 'PENDING',
  FETCHING: 'FETCHING',
  FETCHED: 'FETCHED',
  FAILED: 'FAILED'
}

const initialState = Immutable({
  entries: {},
  currentId: null,
  error: null,
  notificationMessage: null,
  errorNotificationMessage: null,
  status: statuses.PENDING,
  dashboardStatus: statuses.PENDING
})

export default handleActions({
  [setCurrent]: (state, { payload }) => state.merge({
    currentId: payload
  }),
  [receive]: (state, { payload }) => state.merge({
    status: statuses.FETCHED,
    entries: _keyBy([].concat(payload), 'id')
  }, { deep: true }),
  [removed]: (state, { payload }) => state.merge({
    entries: state.entries.without(payload)
  }),
  [error]: (state, { payload }) => state.merge({
    status: statuses.FAILED,
    error: payload
  }),
  [setNotificationMessage]: (state, { payload }) => state.merge({
    notificationMessage: payload
  }),
  [setErrorNotificationMessage]: (state, { payload }) => state.merge({
    errorNotificationMessage: payload
  }),
  [fetching]: (state) => state.merge({
    status: statuses.FETCHING
  }),
  [clearMarkedAppearances]: (state, { payload }) => state.setIn(['entries', payload, 'markedAppearances'], []),
  [clearMarkedColors]: (state, { payload }) => state.setIn(['entries', payload, 'markedColors'], []),
  [setDashboardStatus]: (state, { payload }) => state.merge({
    dashboardStatus: payload
  })
}, initialState)
