import cs from 'classnames'
import React, { useEffect } from 'react'

type Props = {
  active: boolean
  disabled?: boolean
  label?: string
  id: string | number
  onToggleDefault: () => void
  onToggleActive: (id: string | number) => void
}

function Tab (props: Props) {
  useEffect(() => {
    if (props.active && props.disabled) {
      handleToggleDefault()
    }
  }, [props.active, props.disabled])

  function handleToggleDefault () {
    props.onToggleDefault()
  }

  function handleToggleActive () {
    if (!props.disabled) {
      props.onToggleActive(props.id)
    }
  }

  return (<div
    data-testid={props.id}
    onClick={handleToggleActive}
    className={cs(
      'items-baseline flex-1 px1 py1 pointer bc-secondary border-right f7',
      {
        'bg-gray-light-hover': !props.active,
        'bg-secondary c-white bold pointer-disabled': props.active,
        'pointer-disabled muted': props.disabled && !props.active
      }
    )}>
    {props.label}
  </div>)
}

Tab.defaultProps = {
  label: '',
  disabled: false,
  onToggleDefault: () => {}
}

export default Tab
