import React from 'react'
import { useSelector } from 'react-redux'
import { OutputSelector } from 'reselect'

import { RootState } from '../stores/ducks'

/**
 * Memoizes a single selector created by a factory function.
 * This is useful for creating a selector that needs a rarely changing prop as input
 * Memoization allows for the selector cache to be preserved.
 */
export const useMemoizedSelectorFactory = <T extends any[], R>(
  selectorFactory: (...args: T) => OutputSelector<RootState, R, (...res: any[]) => R>,
  ...args: T
) => {
  const selector = React.useMemo(() => selectorFactory(...args), [...args])
  return useSelector(selector)
}
