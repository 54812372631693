import React from 'react'
import cs from 'classnames'
import useOutsideClick from '../../../hooks/useOutsideClick'
// @ts-ignore
import css from './index.css'
import { clamp, isNumber } from 'lodash'

function getSafeInputValue (value: React.InputHTMLAttributes<HTMLInputElement>['value'], min: number, max: number) {
  if (!isNumber(Number(value))) return min
  return clamp(Number(value), min, max)
}

type Props = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'min' | 'max'> & {
  'data-testid'?: string,
  onChange: (value: number) => void
  min: number
  max: number
  onMouseUp?: () => void
  onMouseDown?: () => void
}

function InputNumericRange (props: Props) {
  const el = React.useRef(null)
  const inputEl = React.useRef<HTMLInputElement | null>(null)

  useOutsideClick(el, () => {
    if (inputEl.current !== null) {
      inputEl.current.blur()
    }
  })

  return (
    <div
      ref={el}
      className='width-100 flex items-stretch'
      onMouseDown={props.onMouseDown}
      onMouseUp={props.onMouseUp}
      style={props.style}
    >
      <input
        data-testid={props['data-testid']}
        ref={inputEl}
        type='range'
        max={props.max}
        min={props.min}
        step={props.step}
        disabled={props.disabled}
        value={getSafeInputValue(props.value, props.min, props.max)}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          props.onChange(getSafeInputValue(Number(event.target.value), props.min, props.max))
        }}
        className={cs({
          [`${css.disabledInputRange} pointer-disabled`]: props.disabled,
          [css.inputRange]: !props.disabled
        })}
      />
    </div>
  )
}

InputNumericRange.defaultProps = {
  style: { fontSize: '0.875rem' },
  step: 0.5,
  onMouseDown () {},
  onMouseUp () { }
}

export default InputNumericRange
