module.exports = {
  alphamap_fragment: require('./ShaderChunk/alphamap_fragment.glsl'),
  emissivemap_fragment: require('./ShaderChunk/emissivemap_fragment.glsl'),
  map_fragment: require('./ShaderChunk/map_fragment.glsl'),
  normal_fragment_maps: require('./ShaderChunk/normal_fragment_maps.glsl'),
  normalmap_pars_fragment: require('./ShaderChunk/normalmap_pars_fragment.glsl'),
  roughnessmap_fragment: require('./ShaderChunk/roughnessmap_fragment.glsl'),
  specularmap_fragment: require('./ShaderChunk/specularmap_fragment.glsl'),
  triplanar_blend_weights_fragment: require('./ShaderChunk/triplanar_blend_weights_fragment.glsl'),
  triplanar_map_pars_fragment: require('./ShaderChunk/triplanar_map_pars_fragment.glsl'),
  triplanar_map_pars_vertex: require('./ShaderChunk/triplanar_map_pars_vertex.glsl'),
  triplanar_map_vertex: require('./ShaderChunk/triplanar_map_vertex.glsl'),
  envmap_pars_vertex: require('./ShaderChunk/envmap_pars_vertex.glsl'),
  envmap_pars_fragment: require('./ShaderChunk/envmap_pars_fragment.glsl'),
  envmap_vertex: require('./ShaderChunk/envmap_vertex.glsl'),
  envmap_physical_pars_fragment: require('./ShaderChunk/envmap_physical_pars_fragment.glsl'),
  model_fading_pars_fragment: require('./ShaderChunk/model_fading_pars_fragment.glsl'),
  model_fading_fragment: require('./ShaderChunk/model_fading_fragment.glsl'),
  template_ao_pars_fragment: require('./ShaderChunk/template_ao_pars_fragment.glsl'),
  decal_map_pars_fragment: require('./ShaderChunk/decal_map_pars_fragment.glsl'),
  decal_map_pars_vertex: require('./ShaderChunk/decal_map_pars_vertex.glsl'),
  worldpos_vertex: require('./ShaderChunk/worldpos_vertex.glsl'),
  decal_map_vertex: require('./ShaderChunk/decal_map_vertex.glsl'),
  transparent_fragment: require('./ShaderChunk/transparent_fragment.glsl'),
  refractmap_fragment: require('./ShaderChunk/refractmap_fragment.glsl'),
  refractmap_pars_fragment: require('./ShaderChunk/refractmap_pars_fragment.glsl'),
  marker_point_fragment: require('./ShaderChunk/marker_point_fragment.glsl'),
  show_diffuse_only_fragment: require('./ShaderChunk/show_diffuse_only_fragment.glsl')
}
