import fetch from '../../../utils/fetch'

export const getAllPublished = () => fetch('/api/news').then(res => res.json())

export const getAll = () => fetch('/api/news/admin').then(res => res.json())

export const getDrafts = () => fetch('/api/news/draft').then(res => res.json())

export const getRemoved = () =>
  fetch('/api/news/removed').then(res => res.json())

export const get = id => fetch(`/api/news/${id}`).then(res => res.json())
export const createArticle = ({ title, popupTitle, popupBody, content, publish }) =>
  fetch('/api/news/create', {
    method: 'put',
    body: JSON.stringify({ title, popupTitle, popupBody, content, publish })
  }).then(res => res.json())

export const addContent = (id, type, body) =>
  fetch(`/api/news/update/${id}`, {
    method: 'put',
    body: JSON.stringify({ type, body })
  }).then(res => res.json())

export const updateArticle = (
  id,
  { title, publish, popupTitle, popupBody, remove }
) =>
  fetch(`/api/news/update/${id}`, {
    method: 'post',
    body: JSON.stringify({ title, publish, popupTitle, popupBody, remove })
  }).then(res => res.json())

export const updateContent = (id, contentId, { body }) =>
  fetch(`/api/news/update/${id}/content/${contentId}`, {
    method: 'post',
    body: JSON.stringify({ body })
  }).then(res => res.json())

export const deleteContent = (id, contentId) =>
  fetch(`/api/news/update/${id}/content/${contentId}`, {
    method: 'delete'
  }).then(res => res.json())
