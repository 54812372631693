import fetch from '../../../utils/fetch'

export const getUserData = () => fetch('/api/reports/users').then((res) => res.json())

export const getProjectData = () => fetch('/api/reports/projects').then((res) => res.json())

export const sendReport = (
  payload: {
    email: string,
    userId: string,
    userEmail:string,
    title:string,
    description:string,
    projectId?:string,
    models?:object
  },
  signal: AbortSignal): any => {
  return fetch('/api/incident', {
    method: 'PUT',
    body: JSON.stringify({
      email: payload.email,
      userId: payload.userId,
      userEmail: payload.userEmail,
      title: payload.title,
      description: payload.description,
      projectId: payload.projectId || '',
      models: payload.models || ''
    }),
    signal: signal
  })
    .then((res) => res.json())
    .catch((e) => { return { error: e.message } })
}
