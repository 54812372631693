import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import _get from 'lodash/get'
import _groupBy from 'lodash/groupBy'
import cs from 'classnames'
import { createSelector } from 'reselect'
import { activateImageTemplateCamera } from '../../../../stores/ducks/threeviewer/imageTemplates'
import AutoFill from '../../../common/grid/AutoFill'
import { StorageApiThumbnail } from '../../../common/storage-api-image'
import { RootState } from '../../../../stores/ducks'
import { Roomset, RoomsetMetadata } from '../../../../stores/ducks/roomsets/Roomset'
import { fetchImageTemplatesAndSetups } from '../../../../stores/ducks/roomsets'
import Label from '../../../common/form/label'
import Badge from '../../../common/Badge'

type ImageTemplateProps = {
  active: boolean
  onSelect: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  thumbnail: RoomsetMetadata['thumbnail']
  title: string
  isDraft: boolean
  disabled: boolean
}

function ImageTemplate (props: ImageTemplateProps) {
  return (
    <div
      title={props.title}
      className={cs(
        'relative',
        'border',
        'bw-4',
        'pointer',
        'bg-white',
        'br-2',
        {
          'bc-transparent': !props.active,
          'bc-secondary': props.active,
          'pointer-disabled': props.disabled
        }
      )}
      onClick={props.onSelect}
    >
      <StorageApiThumbnail
        file={props.thumbnail}
        className='opacity-hover-lighter bg-gray-light'
      />
      {props.isDraft && (
        <Badge className='bg-white absolute' style={{ top: 5, left: 5 }}>
          Draft
        </Badge>
      )}
    </div>
  )
}

const selectTemplates = createSelector(
  (state: RootState) => state.roomsets.entries,
  (roomsets) => {
    return Object.values(roomsets)
      .filter(roomset => roomset.type === 'image-template')
      .filter(roomset => !roomset.removedAt)
      .filter(roomset => !roomset.metadata?.isHidden)
  }
)

const selectGroupedTemplates = createSelector(
  selectTemplates,
  (templates) => {
    const groupedTemplates = Object.entries(_groupBy(templates, 'metadata.category'))
    return groupedTemplates.map(([category, templates]) => ({ category, templates }))
  }
)

const selectActiveTemplate = createSelector(
  (state: RootState) => state.threeviewer.camera.activeCamera.imageTemplate,
  (activeTemplate) => {
    return activeTemplate
  }
)

export default function ImageTemplateList (props: {
  disabled: boolean
}) {
  const dispatch = useDispatch()
  const templates = useSelector(selectTemplates)
  const groupedTemplates = useSelector(selectGroupedTemplates)
  const activeTemplate = useSelector(selectActiveTemplate)

  React.useEffect(() => {
    dispatch(fetchImageTemplatesAndSetups())
  }, [])

  return React.useMemo(() => (
    <>
      {!templates.length && (
        <div className='f7 muted'>
          There are no image templates available.
        </div>
      )}

      {groupedTemplates.map(({ category, templates }) => (
        <div className='mt1' key={category}>
          <Label>{category}</Label>
          <AutoFill width={75} className='pb1'>
            {templates.map((template) => (
              <ImageTemplate
                key={template.id}
                title={template.title!}
                thumbnail={template.metadata!.thumbnail!}
                disabled={props.disabled}
                isDraft={!template.metadata!.isPublished}
                onSelect={() => dispatch(activateImageTemplateCamera(template as Roomset))}
                active={template.id === _get(activeTemplate, 'id', '')}
              />
            ))}
          </AutoFill>
        </div>
      ))}
    </>
  ), [templates, groupedTemplates, activeTemplate, props.disabled, dispatch])
}
