
export default class CanvasImageCapturer {
  constructor (app) {
    this._app = app
  }

  generateFilefromCanvas (fileName, options = {}) {
    const dataUrl = this.generateBase64FromCanvas(options)
    const array = dataUrl.split(',')
    const regex = /:(.*?);/
    const mime = array[0].match(regex)[1]
    const decoded = window.atob(array[1])
    const fileData = new Uint8Array(decoded.length)

    for (let i = 0; i < decoded.length; i++) {
      fileData[i] = decoded.charCodeAt(i)
    }

    return new window.File([fileData], fileName, { type: mime })
  }

  generateBase64FromCanvas ({
    width,
    height,
    imageFormat = 'jpeg',
    imageQuality = 92 // toDataURL default
  } = {}) {
    const app = this._app

    // safeframe resolution is divided by 2, so we multiply by 2
    width = width !== undefined ? width : app.postProcessManager.safeFrame.safeFrameWidth * 2
    height = height !== undefined ? height : app.postProcessManager.safeFrame.safeFrameHeight * 2
    const prevSafeFrameValue = app.postProcessManager.safeFrame.enabled
    const prevOutlineValue = app.postProcessManager.outline.enabled
    const prevTransformOutlineValue = app.postProcessManager.transformOutline.enabled
    const prevOverlaySceneValue = app.postProcessManager.overlayEnabled
    const prevWidth = app.domElementWrapper.offsetWidth
    const prevHeight = app.domElementWrapper.offsetHeight

    app.updateCameraSize(null, width, height, false)
    app.postProcessManager.safeFrame.enabled = false
    app.postProcessManager.outline.enabled = false
    app.postProcessManager.transformOutline.enabled = false
    app.postProcessManager.overlayEnabled = false

    app.postProcessManager.render(app.renderScene, app.camera)
    const dataUrl = app.getCanvas().toDataURL(`image/${imageFormat}`, imageQuality)

    app.updateCameraSize(null, prevWidth, prevHeight, false)
    app.postProcessManager.safeFrame.enabled = prevSafeFrameValue
    app.postProcessManager.outline.enabled = prevOutlineValue
    app.postProcessManager.transformOutline.enabled = prevTransformOutlineValue
    app.postProcessManager.overlayEnabled = prevOverlaySceneValue
    app.doRenderOnNextFrame = true

    return dataUrl
  }
}
