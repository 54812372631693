import { combineReducers } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { Action } from 'redux-actions'
import api from '../api'

import admin from './admin'
import clone from './clone'
import colors from './colors'
import combinations from './combinations'
import materials from './materials'
import projects from './projects'
import folders from './folders'
import renders from './renders'
import selection from './selection'
import threeviewer from './threeviewer'
import tree from './tree'
import undoRedo from './undo-redo'
import uploads from './uploads'
import users from './users'
import visibility from './visibility'
import likes from './likes'
import patterns from './patterns'
import templates from './templates'
import roomsets from './roomsets'
import roomsetsAdmin from './roomsets-admin'
import _interface from './interface'
import reports from './reports'
import comments from './comments'
import gallery from './gallery'
import jobs from './jobs'
import designs from './designs'
import imagePackages from './image-packages'
import materialSearch from './material-search'
import news from './news'
import scenes from './scenes'
import external from './external'
import appearanceGroups from './appearance-groups'
import exportDownload from './export'
import virtualProducts from './virtual-products'

const rootReducer = combineReducers({
  admin,
  clone,
  colors,
  combinations,
  materialSearch,
  materials,
  projects,
  folders,
  renders,
  selection,
  threeviewer,
  tree,
  undoRedo,
  uploads,
  users,
  visibility,
  likes,
  patterns,
  templates,
  roomsets,
  roomsetsAdmin,
  interface: _interface,
  reports,
  comments,
  gallery,
  jobs,
  designs,
  imagePackages,
  news,
  scenes,
  external,
  appearanceGroups,
  exportDownload,
  virtualProducts
})

export type RootState = ReturnType<typeof rootReducer>

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  { api: typeof api },
  Action<any>
>

export default rootReducer
