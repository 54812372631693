import store from '../../stores'

import * as fromCombinations from '../../stores/ducks/combinations'
import * as fromDesigns from '../../stores/ducks/designs'
import * as fromRenders from '../../stores/ducks/renders'
import * as fromProjects from '../../stores/ducks/projects'
import * as fromProjectView from '../../stores/ducks/interface/project-view'

export default function handleCombinations (msg) {
  const {
    render,
    doc,
    project
  } = msg
  const { removedAt } = doc
  const state = store.getState()
  const isCombinationNew = Boolean(!state.combinations.entries[doc.id])

  store.dispatch(fromCombinations.receive([doc]))
  if (project) store.dispatch(fromProjects.receive([project]))
  if (render) store.dispatch(fromRenders.receive([render]))

  if (removedAt) store.dispatch(fromDesigns.checkIfShouldPerformSearch())

  // do not listen to remove events or events that modifies existing combinations
  if (!removedAt && isCombinationNew) {
    store.dispatch(fromProjectView.receiveCombination({ projectId: project.id, id: doc.id, finished: false, type: doc.combinationType, modifiedAt: doc.modifiedAt }))
  }
}
