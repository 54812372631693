const noop = () => {}

export const createRemoveModelCommand = ({
  models,
  removeModel,
  addModel,
  afterUndo = noop,
  afterExecute = noop
}) => ({
  undo () {
    models.forEach((model) => {
      addModel(model, model.userData.params)
    })
    afterExecute()
  },

  execute () {
    models.forEach((model) => {
      removeModel(model)
    })
    afterUndo()
  }
})

export const createAddModelCommand = ({
  models,
  removeModel,
  addModel,
  afterUndo = noop,
  afterExecute = noop
}) => ({
  undo () {
    models.forEach((model) => {
      removeModel(model)
    })
    afterUndo()
  },

  execute () {
    models.forEach((model) => {
      addModel(model, model.userData.params)
    })
    afterExecute()
  }
})
