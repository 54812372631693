import React from 'react'
import _uniq from 'lodash/uniq'
import _intersection from 'lodash/intersection'
import { FlattenedNode, FlattenedNodes, IModelSource, ITreeNode } from '../../../../../stores/ducks/tree/TreeNode'

/**
 * Iterates over nodes and returns all matching the current filter criteria
 * If no filters are applied return all nodes.
 * If a node is of type 'model' add node and children
 * If a node is a group add the group and recursively call itself with the groups children
 * @param nodes TreeNodes to be filtered
 * @param selectedFilters The filters to apply
 */
function recursivelyFilterOnSource (nodes: FlattenedNodes, selectedFilters: IModelSource[]) {
  const res: FlattenedNode[] = []
  if (!nodes) return res
  for (const node of Object.values(nodes)) {
    if (selectedFilters.length === 0) {
      res.push(node)
      // break the iteration as no further checks are needed
      continue
    }
    if (
      node.type === 'group' ||
      (node.type === 'model' && node.modelSource && selectedFilters.includes(node.modelSource))) {
      res.push(node)
    }
  }
  return res
}

/**
* Filter on the source for the model.
* This is only done for the top element of the model as the source of the child doesn't always match its parent or is missing
* Returns a list of parents and all child uuids matching the filters.
* If no filter is selected, return all nodes
* @param nodes TreeNodes to be filtered
* @param flatNodes Flattened nodes that are used to facilitate access node children
* @param selectedFilters The filters to apply
*/
function filterOnModelSource (flatNodes: FlattenedNodes, selectedFilters: IModelSource[]) {
  const filteredNodes: FlattenedNode[] = recursivelyFilterOnSource(flatNodes, selectedFilters)
  const uuids: string[] = []
  filteredNodes.forEach(node => {
    if (node.type === 'group' && selectedFilters.length > 0) {
      // If any filter parameter is applied don't include all children in a group as the modelSource can differ. Children for a group matching filters are included as own nodes
      if (node) uuids.push(node.uuid)
    } else {
      // If model, include all children of the model
      if (node) uuids.push(node.uuid, ...node.children)
    }
  })
  return uuids
}

/**
 * Filter on search term. A match on a node will include all children below it as well as all parents (not siblings of a parent) above it
 * This is done to show the complete path to an element.
 * @param flatNodes The nodes to filter
 * @param searchTerm The search term to apply
 */
function filterOnSearchTerm (flatNodes: FlattenedNodes, searchTerm: string) {
  const res: string[] = []
  Object.values(flatNodes).filter(node => {
    if (node.name?.toLowerCase().includes(searchTerm.toLowerCase())) {
      res.push(node.uuid, ...node.children, ...node.ancestors)
    }
  })
  return _uniq(res)
}

/**
 * Get a list of node uuid which should show according to filters and search term.
 *
 * Filtered nodes will also include nodes that does not explicity match the search term based
 * on the following logic:
 *
 * Include all parents for a child that matches the search term.
 * This is done to show the complete path to a node
 *
 * Include all children to a parent that matches the search term
 * @param nodes TreeNodes to be filtered
 * @param flatNodes Flattened nodes that are used to facilitate access node children
 * @param selectedFilters The filters to apply
 * @param searchTerm The search term to apply
 */
export function getFilteredNodes (flatNodes: FlattenedNodes, selectedFilters: IModelSource[], searchTerm: string) {
  const uuidsFilteredOnSource = filterOnModelSource(flatNodes, selectedFilters)
  if (searchTerm.length === 0) {
    return uuidsFilteredOnSource
  }
  const uuidsFilteredOnSearch = filterOnSearchTerm(flatNodes, searchTerm)
  return _intersection(uuidsFilteredOnSource, uuidsFilteredOnSearch)
}

/**
 * Show material icon if the leaf has an assigned material
 * If the node is not a leaf show the icon if all child leafs have assigned materials
 * @param uuid The uuid of a node
 * @param flatNodes All flattened nodes
 */
export function shouldShowMaterialIcon (uuid: string, flatNodes: FlattenedNodes): boolean {
  const flatNode = flatNodes[uuid]
  if (!flatNode) return false

  if (!flatNode.children.length) {
    return flatNode.hasMaterial
  } else {
    return flatNode.children.every(uuid => flatNodes[uuid].children.length > 0 || flatNodes[uuid].hasMaterial)
  }
}

/**
 * Check if any leaf is missing a carrier assignment
 * If the child is not a leaf, aka has children call this function again
 *
 * @param children List of UUIDs for children
 * @param flatNodes All flattened nodes
 */
function hasAllLeafsCarriers (children: string[], flatNodes: FlattenedNodes): boolean {
  return children.every(uuid => {
    const childNode = flatNodes[uuid]
    return childNode.children.length > 0 ||
      (childNode.hasCarrier || flatNodes[childNode.parent]?.hasCarrier)
  })
}

/**
 * Show carrier icon if the leaf has an assigned carrier
 * If the node is not a leaf show the icon if all child leafs have assigned carriers
 * @param uuid The uuid of a node
 * @param isPcaActive If PCA is active
 * @param flatNodes All flattened nodes
 */
export function shouldShowCarrierIcon (uuid: string, isPcaActive: boolean, flatNodes: FlattenedNodes): boolean {
  if (!isPcaActive) return false
  const flatNode = flatNodes[uuid]
  if (!flatNode.children.length) {
    return flatNode.hasCarrier || flatNodes[flatNode.parent]?.hasCarrier
  } else {
    return hasAllLeafsCarriers(flatNode.children, flatNodes)
  }
}

/**
 * Determines if a mod key is pressed, indicating that multiple nodes should be selected
 * @param event Current mouse event
 */
export function shouldSelectMany (event: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) {
  return event.ctrlKey || event.shiftKey || event.metaKey
}

/**
 * Creates a flat list of all visible nodes, starting at a specific branch/node
 * Also creates a lookup table of node uuids to list index
 *
 * The filtered node uuids, along with the opened node uuids, are used to determine
 * what nodes are actually visible.
 *
 * @param node The node/branch to start from
 * @param filteredNodesUuids The filtered node uuids
 * @param openedNodeUuids The opened node uuids
 * @param allNodes Output array of all flattened nodes (ITreeNodes)
 * @param uuidToIndex Output lookup table of uuids mapped to indexes in the allNodes array
 */
export function flattenVisibleTreeNodes (
  node: ITreeNode,
  filteredNodesUuids: string[],
  openedNodeUuids: string[],
  allNodes: ITreeNode[],
  uuidToIndex: {[uuid: string]: number},
) {
  if (!filteredNodesUuids.includes(node.uuid)) return allNodes

  uuidToIndex[node.uuid] = allNodes.length
  allNodes.push(node)

  // If the node is open and has children, continue recursively
  if (openedNodeUuids.includes(node.uuid) && node.nodes) {
    Object.values(node.nodes).forEach(childNode => {
      flattenVisibleTreeNodes(childNode, filteredNodesUuids, openedNodeUuids, allNodes, uuidToIndex)
    })
  }

  return allNodes
}
