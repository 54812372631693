import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Label from '../../common/form/label'
import InputFile from '../../common/form/input-file'
import InputText from '../../common/form/input-text'
import InputRadioGroup from '../../common/form/input-radio-group'
import { StorageApiThumbnail } from '../../common/storage-api-image'

import UploadProgress from '../admin-roomsets/upload-progress'
import DeadlineProgress from '../admin-roomsets/deadline-progress'
import { Files } from '../admin-roomsets/files'
import { ROOMSET_TYPES } from '../../../constants'

import * as fromRoomsetsAdmin from '../../../stores/ducks/roomsets-admin'
import Dropdown from '../../common/form/input-dropdown'
import Badge from '../../common/Badge'
import Grid from '../../common/grid/grid'
import { Roomset, CATEGORIES } from '../../../stores/ducks/roomsets/Roomset'
import styled from 'styled-components'
import Button from '../../common/button'
import { Errors } from './add-edit-modal'
import { AdditionalInformation } from './additionalInformation'
import * as fromAdminRoomsetsSelectors from '../../../stores/ducks/roomsets-admin/selectors'

type Props = {
  errors: Errors
  active: boolean
  omitErrors: (keys: string[]) => void
  validate: (cb: () => void) => void
}

const INPUT_WIDTH = 300

const MaxWidth = styled.div`
  width: 100%;
  max-width: ${(props: { max: number }) => `${props.max}px`};
`

function Section (props: React.HTMLAttributes<HTMLElement>) {
  return (
    <section className='pb3 mb2 border-bottom bc-gray-light border-last-none' style={props.style}>
      {props.children}
    </section>
  )
}

function TabImageTemplate (props: Props) {
  const dispatch = useDispatch()
  const editingRoomset = useSelector(fromAdminRoomsetsSelectors.selectEditingRoomset)
  const upload = useSelector(fromAdminRoomsetsSelectors.selectUpload)
  const jobStatuses = useSelector(fromAdminRoomsetsSelectors.selectJobStatuses)
  const { uploadDone, uploadConverted, completedConvert } = useSelector(fromAdminRoomsetsSelectors.selectUploadConvertedOrDone)
  const canStartDeadlineJob = useSelector(fromAdminRoomsetsSelectors.selectCanStartDeadlineJob)

  const [disableStartDeadlineJobButton, setDisableStartDeadlineJobButton] = React.useState(false)
  const [deadlineTimeout, setDeadlineTimeout] = React.useState<{ [key: string]: number | undefined }>({})

  React.useEffect(() => {
    return () => {
      if (Object.keys(deadlineTimeout).length) {
        Object.values(deadlineTimeout).forEach(timeout => {
          clearTimeout(timeout)
        })
      }
    }
  }, [])

  React.useEffect(() => {
    dispatch(fromRoomsetsAdmin.setEditingRoomsetProperty({ key: 'type', value: ROOMSET_TYPES.IMAGE_TEMPLATE }))
    dispatch(fromRoomsetsAdmin.setEditingRoomsetProperty({ key: 'resolution', value: { x: 4000, y: 4000 } }))
    dispatch(fromRoomsetsAdmin.setEditingRoomsetProperty({ key: 'aspectRatio', value: { x: 1, y: 1 } }))
  }, [])

  function handleStartConvert (roomset: Roomset) {
    props.validate(async () => {
      const result: any = await dispatch(fromRoomsetsAdmin.createOrUpdate(roomset))
      setDisableStartDeadlineJobButton(true)
      const timeout = setTimeout(() => setDisableStartDeadlineJobButton(false), 4000)
      setDeadlineTimeout({ [result.id]: timeout })
      dispatch(fromRoomsetsAdmin.startRoomsetDeadlineJob(result.id))
    })
  }

  function handleUploadMaxFile (files: FileList) {
    if (files && files.length) {
      props.omitErrors([
        'maxFile',
        'defaultTargetId',
        'defaultCameraId',
        'defaultDummyId',
        'manualAlignmentX',
        'manualAlignmentY',
        'manualAlignmentZ'
      ])
      dispatch(fromRoomsetsAdmin.uploadMaxFile(files))
    }
  }

  function handleUploadThumbnail (files: FileList) {
    if (files && files.length) {
      props.omitErrors(['thumbnail'])
      dispatch(fromRoomsetsAdmin.uploadThumbnail(files))
    }
  }

  function uploadInformationImageFile (files: FileList) {
    dispatch(fromRoomsetsAdmin.uploadImageFiles(files))
  }

  function removeFile (file:any) {
    dispatch(fromRoomsetsAdmin.removeImageFile(file))
  }

  function handleTitleChange (title: string) {
    if (title && title.trim && title.trim().length) {
      props.omitErrors(['title'])
      dispatch(fromRoomsetsAdmin.setEditingRoomsetProperty({ key: 'title', value: title }))
    }
  }

  const thumbnail = editingRoomset.metadata?.thumbnail
  const countDummies = Object.keys(editingRoomset.dummies || {}).length
  const countTargets = Object.keys(editingRoomset.targets || {}).length
  const countCameras = Object.keys(editingRoomset.cameras || {}).length
  const infoFiles = editingRoomset.metadata?.imageFiles || []

  if (!props.active) return null

  return (
    <>
      <Section>
        <MaxWidth max={INPUT_WIDTH}>
          <Label className='flex justify-between'>
            <span>Title</span>
            {props.errors.title && (<span className='c-error regular'>Required</span>)}
          </Label>
          <InputText
            defaultValue={editingRoomset.title}
            onChange={handleTitleChange}
          />
        </MaxWidth>
      </Section>

      <Section>
        <Label className='flex justify-between'>
          <span>Thumbnail</span>
          {props.errors.thumbnail && (<span className='c-error regular'>Required</span>)}
        </Label>
        <div style={{ width: 160 }}>
          <StorageApiThumbnail
            className='border bc-gray-light'
            file={thumbnail}
          />
        </div>
        <InputFile
          accept='image/*'
          onChange={handleUploadThumbnail}
        >
          Upload thumbnail
        </InputFile>
      </Section>

      <Section>
        <Label className='flex justify-between'>
          <span>Category</span>
          {props.errors.category && (<span className='c-error regular'>Required</span>)}
        </Label>
        <MaxWidth max={INPUT_WIDTH}>
          <Dropdown
            className='width-100'
            value={editingRoomset.metadata?.category || 'select'}
            onChange={(val: string) => {
              props.omitErrors(['category'])
              dispatch(fromRoomsetsAdmin.setEditingRoomsetMetadataProperty({ key: 'category', value: val }))
            }}
            nullOption={{
              key: 'select',
              value: 'select',
              label: 'Select category'
            }}
            options={CATEGORIES.sort().map(category => ({
              key: category,
              value: category,
              label: category
            }))}
          />
        </MaxWidth>
      </Section>

      <Section>
        <Label className='flex justify-between'>
          <div>
            <span>File</span>
            {uploadConverted && (<Badge className='ml2 c-success inline-block'>Converted</Badge>)}
          </div>
          {props.errors.maxFile && (<span className='c-error regular'>Required</span>)}
        </Label>

        <div className='mt2 f7 c-gray-accessible'>Supported formats <span className='bold'>.max .zip .tar.gz</span>. Zip must contain one <span className='bold'>.max</span> file and a <span className='bold'>.hdr</span> file.</div>
        {(uploadDone && !completedConvert) && (
          <div className='f7 mt1 c-gray-accessible'>A conversion typically takes a couple of minutes (depending on the size and complexity of the scene).</div>
        )}
        <div className='flex items-end mt2'>
          <InputFile
            style={{ margin: 0, minHeight: 'initial' }}
            accept='.max,application/gzip,application/zip'
            onChange={handleUploadMaxFile}
          >
            Upload file
          </InputFile>

          <Button
            className='ml2'
            disabled={!canStartDeadlineJob || disableStartDeadlineJobButton}
            btnType='primary'
            onClick={() => handleStartConvert(editingRoomset)}
          >
            Start convert
          </Button>
        </div>

        <div className='f7 mt2'>
          <Files
            uploadFile={upload?.filename}
            files={upload?.manifest?.files}
          />
          {!uploadDone && (<UploadProgress uploadProgress={upload} />)}
        </div>

        {(uploadDone && !completedConvert) && (
          <div className='mt2'>
            <DeadlineProgress jobStatuses={jobStatuses} />
          </div>
        )}
      </Section>

      {(uploadConverted && countDummies === 0) && (
        <Section>
          <Label>
            Manual alignment
          </Label>
          <p className='f7 c-gray-accessible'>
            There is no dummy alignment helper in the scene. Either upload the maxfile again with an alignment dummy helper or set the alignment manually below.
          </p>
          <Grid columns={['1fr', '1fr', '1fr']} className='mb2'>
            <div>
              <Label className='flex justify-between'>
                <span>X axis alignment</span>
                {props.errors.manualAlignmentX && (<span className='c-error regular'>Required</span>)}
              </Label>
              <InputRadioGroup
                direction='vertical'
                onChange={(val: number) => {
                  if ([-1, 0, 1].includes(val)) {
                    props.omitErrors(['manualAlignmentX'])
                    const alignment = [...(editingRoomset?.manualAlignment ?? [null, null, null])]
                    alignment[0] = val
                    dispatch(fromRoomsetsAdmin.setEditingRoomsetProperty({ key: 'manualAlignment', value: alignment }))
                  }
                }}
                options={[
                  { key: 'min', value: -1, label: 'Min', selected: editingRoomset?.manualAlignment![0] === -1 },
                  { key: 'center', value: 0, label: 'Center', selected: editingRoomset?.manualAlignment![0] === 0 },
                  { key: 'max', value: 1, label: 'Max', selected: editingRoomset?.manualAlignment![0] === 1 }
                ]}
              />
            </div>

            <div>
              <Label className='flex justify-between'>
                <span>Y axis alignment</span>
                {props.errors.manualAlignmentY && (<span className='c-error regular'>Required</span>)}
              </Label>
              <InputRadioGroup
                direction='vertical'
                onChange={(val: number) => {
                  if ([-1, 0, 1].includes(val)) {
                    props.omitErrors(['manualAlignmentY'])
                    const alignment = [...(editingRoomset?.manualAlignment ?? [null, null, null])]
                    alignment[1] = val
                    dispatch(fromRoomsetsAdmin.setEditingRoomsetProperty({ key: 'manualAlignment', value: alignment }))
                  }
                }}
                options={[
                  { key: 'min', value: -1, label: 'Min', selected: editingRoomset?.manualAlignment![1] === -1 },
                  { key: 'center', value: 0, label: 'Center', selected: editingRoomset?.manualAlignment![1] === 0 },
                  { key: 'max', value: 1, label: 'Max', selected: editingRoomset?.manualAlignment![1] === 1 }
                ]}
              />
            </div>

            <div>
              <Label className='flex justify-between'>
                <span>Z axis alignment</span>
                {props.errors.manualAlignmentZ && (<span className='c-error regular'>Required</span>)}
              </Label>
              <InputRadioGroup
                direction='vertical'
                onChange={(val: number) => {
                  if ([-1, 0, 1].includes(val)) {
                    props.omitErrors(['manualAlignmentZ'])
                    const alignment = [...(editingRoomset?.manualAlignment ?? [null, null, null])]
                    alignment[2] = val
                    dispatch(fromRoomsetsAdmin.setEditingRoomsetProperty({ key: 'manualAlignment', value: alignment }))
                  }
                }}
                options={[
                  { key: 'min', value: -1, label: 'Min', selected: editingRoomset?.manualAlignment![2] === -1 },
                  { key: 'center', value: 0, label: 'Center', selected: editingRoomset?.manualAlignment![2] === 0 },
                  { key: 'max', value: 1, label: 'Max', selected: editingRoomset?.manualAlignment![2] === 1 }
                ]}
              />
            </div>
          </Grid>
        </Section>
      )}

      {(uploadConverted && countCameras > 1) && (
        <Section>
          <Label>
            <span>Select camera</span>
            {props.errors.defaultCameraId && (<span className='c-error regular'>Required</span>)}
          </Label>
          <p className='f7 c-gray-accessible'>
            There are more than one camera in the scene. Please select the one you want to use or remove the unwanted cameras in the maxfile and upload again.
          </p>

          <MaxWidth max={INPUT_WIDTH}>
            <Dropdown
              className='width-100'
              value={editingRoomset.defaultCameraId}
              onChange={(val: string) => {
                if (val !== 'select') {
                  props.omitErrors(['defaultCameraId'])
                  dispatch(fromRoomsetsAdmin.setEditingRoomsetProperty({ key: 'defaultCameraId', value: val }))
                }
              }}
              nullOption={{
                key: 'select',
                value: 'select',
                label: 'Select camera'
              }}
              options={Object.values(editingRoomset?.cameras ?? {}).map((camera: any) => {
                return {
                  key: camera.id,
                  value: camera.id,
                  label: camera.name
                }
              })}
            />
          </MaxWidth>
        </Section>
      )}

      {(uploadConverted && countTargets > 1) && (
        <Section>
          <Label>
            <span>Select target</span>
            {props.errors.defaultTargetId && (<span className='c-error regular'>Required</span>)}
          </Label>
          <p className='f7 c-gray-accessible'>
            There are more than one target in the scene. Please select the one you want to use for product placement or remove the unwanted targets in the maxfile and upload again.
          </p>

          <MaxWidth max={INPUT_WIDTH}>
            <Dropdown
              className='width-100'
              value={editingRoomset.defaultTargetId}
              onChange={(val: string) => {
                if (val !== 'select') {
                  props.omitErrors(['defaultTargetId'])
                  dispatch(fromRoomsetsAdmin.setEditingRoomsetProperty({ key: 'defauultTargetId', value: val }))
                }
              }}
              nullOption={{
                key: 'select',
                value: 'select',
                label: 'Select target'
              }}
              options={Object.values(editingRoomset?.targets ?? {}).map((target: any) => {
                return {
                  key: target.id,
                  value: target.id,
                  label: target.name
                }
              })}
            />
          </MaxWidth>
        </Section>
      )}

      {(uploadConverted && countDummies > 1) && (
        <Section>
          <Label>
            <span>Select alignment dummy</span>
            {props.errors.defaultDummyId && (<span className='c-error regular'>Required</span>)}
          </Label>
          <p className='f7 c-gray-accessible'>
            There are more than one alignment dummy helpers in the scene. Please select the one you want to use or remove the unwanted dummies in the maxfile and upload again.
          </p>

          <MaxWidth max={INPUT_WIDTH}>
            <Dropdown
              className='width-100'
              value={editingRoomset.defaultDummyId}
              onChange={(val: string) => {
                if (val !== 'select') {
                  props.omitErrors(['defaultDummyId'])
                  dispatch(fromRoomsetsAdmin.setEditingRoomsetProperty({ key: 'defaultDummyId', value: val }))
                }
              }}
              nullOption={{
                key: 'select',
                value: 'select',
                label: 'Select alignment dummy'
              }}
              options={Object.values(editingRoomset?.dummies ?? {}).map((dummy: any) => {
                return {
                  key: dummy.id,
                  value: dummy.id,
                  label: dummy.name
                }
              })}
            />
          </MaxWidth>
        </Section>
      )}

      <Section style={{ height: '100%' }}>
        <AdditionalInformation
          label={'Template information'}
          uploadImage={uploadInformationImageFile}
          removeImage={removeFile}
          uploadText={text => dispatch(fromRoomsetsAdmin.uploadInfoText(text))}
          images={infoFiles}
          text={editingRoomset.metadata?.infoText || '' }
          editing={true}
        />
      </Section>
    </>
  )
}

export default TabImageTemplate
