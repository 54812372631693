import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _omit from 'lodash/omit'

import css from './index.css'

class TextArea extends Component {
  get root () {
    return this._root
  }

  render () {
    const props = this.props

    return (
      <textarea
        {..._omit(props, 'fullWidth', 'resizeable')}
        style={Object.assign({}, props.style, {
          resize: props.resizeable ? 'vertical' : 'none'
        })}
        ref={(node) => (this._root = node)}
        className={`${css.textarea} ${props.className} ${props.fullWidth ? 'width-100' : ''}`}
      />
    )
  }
}

TextArea.propTypes = {
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  resizeable: PropTypes.bool
}

TextArea.defaultProps = {
  className: '',
  fullWidth: true,
  resizeable: false
}

export default TextArea
