import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import * as fromAdmin from '../../../stores/ducks/admin'
import * as fromColors from '../../../stores/ducks/colors'
// @ts-ignore
import css from '../../common/form/index.css'

import * as fromAdminSelectors from '../../../stores/ducks/admin/selectors'
import * as fromColorsSelectors from '../../../stores/ducks/colors/selectors'

type Props = {
  color: string
}

const ColorPicker = (props: Props) => {
  const colorPicker = useSelector(fromAdminSelectors.selectColorPicker)
  const collisionError = useSelector(fromColorsSelectors.selectCollisionError)
  const dispatch = useDispatch()

  const rgbClamp = (v: number | string) => {
    if (v > 255) {
      v = 255
    } else if (v < 0) {
      v = 0
    } else if (v === '') {
      v = 0
    }
    return v
  }

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      handleBlur(event)
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    var color = event.target.value
    dispatch(fromAdmin.setColorPicker(color))
    if (collisionError.rgb) {
      dispatch(fromColors.setCollisionProblem({
        name: collisionError.name,
        ncs: collisionError.ncs,
        pantone: collisionError.pantone,
        rgb: false
      }))
    }
  }

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    var color = event.target.value
    const matchColors = /rgb\(\s*(\d*)\s*,\s*(\d*)\s*,\s*(\d*)\s*\)/
    var match = matchColors.exec(color)
    if (match !== null) {
      const r = rgbClamp(match[1])
      const g = rgbClamp(match[2])
      const b = rgbClamp(match[3])
      const value = `rgb(${r}, ${g}, ${b})`

      dispatch(fromAdmin.setColorPicker(value))
    } else {
      dispatch(fromAdmin.setColorPicker('rgb(0, 0, 0)'))
    }
  }
  useEffect(() => {
    dispatch(fromAdmin.setColorPicker(props.color))
    return () => {
      dispatch(fromAdmin.setColorPicker('rgb(0, 0, 0)'))
    }
  }, [])

  return (
    <div className='flex'>
      <input
        spellCheck={false}
        className={css.inputText}
        type='text'
        value={colorPicker || 'rgb(0, 0, 0)'}
        onBlur={handleBlur}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      />
    </div>
  )
}

export default ColorPicker
