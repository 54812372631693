import _difference from 'lodash/difference'
import _xor from 'lodash/xor'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ImmutableArray } from 'seamless-immutable'
// Constants
import {
  GROUPED_ROOMSET_COUNTRIES, mapToKeysValueLabel,
  ROOMSET_TAGS_FULL
} from '../../constants'
import { RootState } from '../../stores/ducks'
import * as fromRoomsets from '../../stores/ducks/roomsets'
import InputCheckbox from '../common/form/input-checkbox'
// JSX Components : Common
import InputGroup from '../common/form/input-group'
import InputRangeDouble from '../common/form/input-range-double'
import InputSelect from '../common/form/input-select'
import Label from '../common/form/label'
import IconArrowDown from '../common/icons/icon-arrow-down'

type Tags = {
  value: string
  label: string
}[]

type Group = {
  label: string
  options: Tags
  value: string
}

const ROOMSET_TAGS = mapToKeysValueLabel(ROOMSET_TAGS_FULL)

const styles = {
  labelMargin: {
    marginBottom: '1.5rem'
  }
}

const Filterbar = () => {
  const dispatch = useDispatch()
  const [openGroups, setOpenGroups] = useState<string[] | null>(null)
  const filters = useSelector((state: RootState) => state.roomsets.filters)

  const isAllGroupOptionsChecked = (subset: string[], superset: ImmutableArray<string>) => (
    // returns true if subset is available in superset
    !_difference(subset, superset).length
  )

  const handleParentChildrenCheckboxRelation = (group: Group) => {
    const groupOptions = group.options.map(option => option.value)
    const allGroupOptionsAreChecked = isAllGroupOptionsChecked(groupOptions, filters.marketArea)
    const unCheckedGroupOptions = _difference(groupOptions, filters.marketArea)

    const _options = !allGroupOptionsAreChecked ? unCheckedGroupOptions : groupOptions

    handleChildCheckbox(_options)
  }

  const handleChildCheckbox = (values: string[]) => {
    // xor toggles value on and off from list
    const marketArea = _xor(filters.marketArea, values)

    dispatch(fromRoomsets.setFilter({ key: 'marketArea', value: marketArea }))
  }

  const handleToggleGroupDropdown = (groupValue: Group['value']) => (
    setOpenGroups(_xor(openGroups, [groupValue]))
  )

  const tags = filters.tags ?? []

  const minArea = filters.area.min ?? 0
  const maxArea = filters.area.max ?? 0

  const minRooms = filters.rooms.min ?? 0
  const maxRooms = filters.rooms.max ?? 0

  const marketArea = filters.marketArea ?? []

  return (
    <div
      className='height-100 flex flex-column pt2 pr2'
      style={{
        width: '30rem'
      }}
    >
      <InputGroup className='mb2'>
        <Label>Search by tags</Label>
        <InputSelect
          closeMenuOnSelect={false}
          isMulti
          options={ROOMSET_TAGS}
          defaultValue={tags.map((tag) => ({ value: tag, label: tag }))}
          onChange={(tags: Tags) => {
            dispatch(fromRoomsets.setFilter({ key: 'tags', value: tags ? tags.map(item => item.value) : [] }))
          }}
        />
      </InputGroup>

      <InputGroup className='mb2'>
        <Label style={styles.labelMargin}>Size</Label>
        <InputRangeDouble
          formatLabel={(value: string) => `${value} m${'\u00B2'}`}
          maxValue={250}
          minValue={10}
          value={{
            min: minArea,
            max: maxArea
          }}
          onChange={(area: string) => dispatch(fromRoomsets.setFilter({ key: 'area', value: area }))}
        />
      </InputGroup>

      <InputGroup className='mb2'>
        <Label style={styles.labelMargin}>Number of rooms</Label>
        <InputRangeDouble
          formatLabel={(value: number) => value}
          maxValue={22}
          minValue={1}
          value={{
            min: minRooms,
            max: maxRooms
          }}
          onChange={(rooms: number) => dispatch(fromRoomsets.setFilter({ key: 'rooms', value: rooms }))}
        />
      </InputGroup>

      <InputGroup
        className='height-100 overflow-hidden flex flex-column'>
        <Label>Market area</Label>
        <div className='height-100 overflow-x-hidden overflow-y-auto custom-scrollbar'>
          {GROUPED_ROOMSET_COUNTRIES.map((group) => (
            <div
              key={group.value}
              className='flex flex-column border bc-gray-light br-2 p1 mb2 bg-white'
            >
              <div
                className='flex justify-between items-center'
              >
                <InputCheckbox
                  name={group.value}
                  label={group.label}
                  checked={isAllGroupOptionsChecked(
                    group.options.map(option => option.value),
                    marketArea
                  )}
                  onChange={() => (handleParentChildrenCheckboxRelation(group))}
                  noMargin
                />
                <IconArrowDown
                  onClick={() => handleToggleGroupDropdown(group.value)}
                  className='border-left c-gray-light bc-gray-light pointer'
                  size={'1.6rem'}
                  style={{
                    paddingLeft: '10px',
                    paddingRight: '2px'
                  }}
                />
              </div>
              {openGroups && openGroups.includes(group.value) && (
                <div className='ml2 pt1'>
                  {group.options.map((item) => {
                    return (<InputCheckbox
                      key={item.value}
                      name={item.value}
                      label={item.label}
                      checked={marketArea.includes(item.value)}
                      onChange={() => (handleChildCheckbox([item.value]))}
                    />)
                  }
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      </InputGroup>
    </div>
  )
}

export default React.memo(Filterbar)
