import React, { Component } from 'react'
import PropTypes from 'prop-types'

import css from './index.css'

class Form extends Component {
  get root () {
    return this._root
  }

  render () {
    const props = this.props

    return (
      <form
        {...props}
        ref={(node) => (this._root = node)}
        className={`${css.form} ${props.className}`}
      >
        {props.children}
      </form>
    )
  }
}

Form.propTypes = {
  className: PropTypes.string
}

Form.defaultProps = {
  className: ''
}

export default Form
