import React from 'react'
import moment from 'moment'
import cs from 'classnames'

import Button from '../../common/button'

import { MdDelete as IconTrash, MdVisibility as IconVisibility } from 'react-icons/md'
import { FaPencilAlt as IconEdit } from 'react-icons/fa'

const PostList = ({
  posts,
  onDelete,
  onEdit,
  onPreview,
  onPublish
}) => {
  return (
    <div style={{ height: '70vh', overflowY: 'auto' }}>
      {posts.map(post => (
        <div
          key={post.id}
          className='flex flex-auto p2 border-bottom bc-gray-light'
        >
          <div className='f6'>
            <div
              className={cs('mb1', 'bold', 'flex items-end', { pointer: !post.published })}
              onClick={(event) => {
                event.preventDefault()
                if (post.published) return
                onEdit(post.id)
              }}
            >
              {post.title}
            </div>

            <div className='flex items-center'>
              <div
                className={'mb1 border-none rounded f8 bg-gray-light c-gray-dark inline bold'}
                style={{
                  padding: '0.3rem 0.5rem',
                  margin: '0.1rem'
                }}
              >
                {post.published ? 'Published' : 'Draft'}
              </div>
              <time className={'ml1 truncate f7 c-gray-accessible'}>{post.published ? 'Published ' : 'Last edited '} {moment(post.updatedAt).fromNow()}</time>
            </div>
          </div>

          <div className='ml-auto flex self-end'>
            <Button
              className='mr2'
              onClick={(event) => {
                event.preventDefault()
                onDelete(post.id)
              }}>
              <IconTrash size='16' />
            </Button>
            <Button
              disabled={post.published}
              className='mr2'
              onClick={(event) => {
                event.preventDefault()
                onEdit(post.id)
              }}>
              <IconEdit size='16' />
            </Button>
            <Button
              className='mr2'
              onClick={(event) => {
                event.preventDefault()
                onPreview(post.id)
              }}>
              <IconVisibility size='16' />
            </Button>
            <Button
              btnType='primary'
              inverted
              disabled={post.published}
              className='mr2'
              onClick={(event) => {
                event.preventDefault()
                onPublish(post.id)
              }}>
              Publish
            </Button>
          </div>
        </div>
      ))}

    </div>
  )
}

export default PostList
