import fetch from '../../../utils/fetch'

export const getColors = () => (
  fetch('/api/colors')
    .then((res) => res.json())
)

export const saveColor = (payload) => (
  fetch('/api/colors/create', {
    method: 'POST',
    body: JSON.stringify(payload)
  })
    .then((res) => res.json())
)

export const editColor = (payload) => (
  fetch('/api/colors/update', {
    method: 'POST',
    body: JSON.stringify(payload)
  })
    .then((res) => res.text())
)

export const removeColor = (payload) => (
  fetch('/api/colors/remove', {
    method: 'POST',
    body: JSON.stringify(payload)
  })
    .then((res) => res.text())
)
