import React, { PureComponent } from 'react'
import autoBind from 'react-autobind'

import InputText from '../common/form/input-text'
import InputGroup from '../common/form/input-group'
import Dropdown from '../common/form/input-dropdown'
import Label from '../common/form/label'
import Button from '../common/button'
import KeyboardListener, { KeyCode, KeyBinding } from '../common/keyboard-listener'

import _sortBy from 'lodash/sortBy'

import {
  HFB,
  PA,
  MONTHS,
  PROJECT_TYPES,
  PROJECT_TYPES_OPTIONS
} from '../../constants'
import InputRadioGroup from '../common/form/input-radio-group'

const DEFAULT_HFB = { label: 'Other', key: 'other', value: 'other' }
const OPTIONS_HFB = [DEFAULT_HFB].concat(
  _sortBy(Object.keys(HFB).map((key) => (
    { label: `${key} - ${HFB[key]}`, key, value: key }
  )), 'key')
)

const OPTIONS_MONTHS = Object.keys(MONTHS).map((key) => (
  { label: MONTHS[key], key, value: key }
))

const START_YEAR = (new Date()).getFullYear() - 5

const OPTIONS_YEARS = (new Array(20)).fill().map((_, index) => ({
  value: START_YEAR + index, key: index, label: START_YEAR + index
}))

const OPTIONS_PROJECT_TYPES = [].concat(PROJECT_TYPES_OPTIONS).sort((a, b) => {
  if (a.id === PROJECT_TYPES.DEFAULT || b.id === PROJECT_TYPES.DEFAULT) {
    return a.id === PROJECT_TYPES.DEFAULT ? -1 : 1
  } else {
    return a.id === b.id ? 0 : (a.id > b.id ? 1 : -1)
  }
}).map((item) => ({
  key: item.id, value: JSON.stringify(item), label: item.label
}))

function getPAOptions (hfbId) {
  const options = Object.keys(PA)
    .filter((key) => (hfbId ? key.substr(0, 2) === hfbId : true))
    .map((key) => ({ label: `${key} - ${PA[key]}`, key, value: key }))

  return _sortBy(options, 'key')
}

class NewProjectForm extends PureComponent {
  constructor (props) {
    super(props)

    autoBind(this)

    this.handleTitleChange = this.handleInputChange.bind(this, 'title')
    this.handleYearChange = this.handleInputChange.bind(this, 'salesStartYear')
    this.handleMonthChange = this.handleInputChange.bind(this, 'salesStartMonth')
    this.handleHFBChange = this.handleInputChange.bind(this, 'HFB')
    this.handlePAChange = this.handleInputChange.bind(this, 'PA')
    this.handlePPMChange = this.handleInputChange.bind(this, 'PPM')
    this.handleProjectConfigChange = this.handleInputChange.bind(this, 'projectConfig')

    this.state = {
      error: {},
      title: undefined,
      HFB: DEFAULT_HFB.value,
      PA: undefined,
      PPM: undefined,
      salesStartYear: undefined,
      salesStartMonth: undefined,
      projectConfig: undefined,
    }
  }

  handleSubmit (event) {
    event.preventDefault()

    const {
      title,
      HFB,
      PA,
      PPM,
      salesStartYear,
      salesStartMonth,
      projectConfig,
    } = this.state

    const data = {
      title,
      HFB,
      PA,
      PPM,
      projectType: PROJECT_TYPES.DEFAULT
    }

    if (projectConfig) {
      const _projectConfig = JSON.parse(projectConfig)
      if (_projectConfig.type !== PROJECT_TYPES.DEFAULT) {
        data.projectType = _projectConfig.type
        data.projectTypeId = _projectConfig.id
      }
    }

    if (!title || PPM === undefined) {
      return this.setState({
        error: {
          title: !title && 'required',
          PPM: PPM === undefined && 'required',
        }
      })
    }

    if (salesStartYear) {
      data.salesStart = `${salesStartYear}-${(salesStartMonth || 1)}-1`
    }
    this.props.onSubmit(data)
  }

  handleInputChange (prop, value) {
    const { error } = this.state
    if (error[prop]) {
      return this.setState({ [prop]: value }, () => {
        this.setState({ error: { ...error, [prop]: null } })
      })
    }
    this.setState({ [prop]: value })
  }

  render () {
    const { error } = this.state

    return (
      <div data-testid='new-project-form'>
        <h2 className='mt0'>
          New project
        </h2>

        <InputGroup>
          <Label className='flex justify-between'>
            <span>Title *</span>
            <span className='c-error regular'>{error.title}</span>
          </Label>
          <InputText
            focus
            onChange={this.handleTitleChange}
            placeholder='Title'
            data-testid='new-project-title'
          />
        </InputGroup>

        <InputGroup>
          <Label>
            Project type
          </Label>
          <Dropdown
            className='width-100'
            onChange={this.handleProjectConfigChange}
            options={OPTIONS_PROJECT_TYPES}
            value={this.state.projectConfig}
            data-testid='new-project-project-types'
          />
        </InputGroup>

        <InputGroup className='flex'>
          <div className='pr1'>
            <Label className='flex justify-between'>
              <span>HFB</span>
            </Label>
            <Dropdown
              className='width-100'
              onChange={(value) => {
                this.handleHFBChange(value)
                // reset chosen PA
                this.handlePAChange(undefined)
              }}
              options={OPTIONS_HFB}
              value={this.state.HFB}
              data-testid='new-project-hfb'
            />
          </div>

          <div className='pl1 width-50'>
            <Label>
              Product area (optional)
            </Label>
            <Dropdown
              disabled={!(this.state.HFB && this.state.HFB !== DEFAULT_HFB.value)}
              className='width-100'
              onChange={this.handlePAChange}
              options={[{ label: 'Choose product area' }].concat(getPAOptions(this.state.HFB))}
              data-testid='new-project-product-area'
            />
          </div>
        </InputGroup>

        <InputGroup>
          <div className='flex'>
            <div className='pr1 width-50'>
              <Label>
            Sales start (optional)
              </Label>
              <div className='flex items-center'>
                <Dropdown
                  className='width-100'
                  onChange={this.handleMonthChange}
                  options={[{ label: 'Month' }].concat(OPTIONS_MONTHS)}
                  data-testid='new-project-sales-start-month'
                />
                <div className='mx1'>/</div>
                <Dropdown
                  className='width-100'
                  onChange={this.handleYearChange}
                  options={[{ label: 'Year' }].concat(OPTIONS_YEARS)}
                  data-testid='new-project-sales-start-year'
                />
              </div>
            </div>
            <div className='width-50'>
              <Label className='flex justify-between'>
                <span>PPM project exist *</span>
                <span className='c-error regular'>{error.PPM}</span>
              </Label>
              <div className='flex items-center'>
                <InputRadioGroup
                  name={'PPM'}
                  options={[
                    {
                      name: 'Yes',
                      label: 'Yes',
                      value: true,
                    },
                    {
                      name: 'No',
                      label: 'No',
                      value: false,
                    }
                  ]}
                  direction={'horizontal'}
                  onChange={(val) => {
                    this.handlePPMChange(val)
                  }}
                  data-testid='new-project-ppm'
                />
              </div>
            </div>
          </div>
        </InputGroup>
        <div className="f8">* required</div>
        <div className='flex justify-end items-end mt3'>
          <Button onClick={this.props.onCancel}>
            Cancel
          </Button>
          <Button
            disabled={!this.state.title || this.state.PPM === undefined}
            btnType='primary' className='ml2'
            onClick={this.handleSubmit}
            data-testid='new-project-submit-button'
          >
            Create
          </Button>
        </div>
        <KeyboardListener
          bindings={[
            KeyBinding(KeyCode.enter, this.handleSubmit)
          ]}
        />

      </div>
    )
  }
}

export default NewProjectForm
