import React from 'react'

import Wrapper from './wrapper'
import Header from './header'
import Footer from './footer'
import Main from './main'
import Grid from '../common/grid/grid'

const TabHeader = ({ currentTabIcon, currentTabName }) => {
  return (
    <div className='flex justify-start items-center pl1 width-100'>
      {currentTabIcon}
      <h1 className='my0 ml2 mr0 f4'>{currentTabName}</h1>
    </div>
  )
}

const styles = {
  gridAreaTop: {
    gridArea: 'top'
  },
  gridAreaTools: {
    gridArea: 'tools'
  },
  gridAreaContent: {
    gridArea: 'content'
  }
}

const LayoutWithSideNav = ({
  children,
  breadcrumbs,
  sideNavComponent,
  currentTabName,
  currentTabIcon,
  toolsComponent
}) => {
  const gridRows = toolsComponent ? [96, 72, 'auto'] : [96, 'auto']
  const gridAreas = toolsComponent ? ['top', 'tools', 'content'] : ['top', 'content']

  return (
    <Wrapper>
      <Header breadcrumbs={breadcrumbs} />
      <Main>
        <div className='flex height-100 overflow-hidden'>
          {sideNavComponent}

          <Grid
            rows={gridRows}
            columns={['1fr']}
            areas={gridAreas}
            gridGap={0}
            className='width-100 height-100 overflow-hidden px2'
          >
            <div
              style={styles.gridAreaTop}
              className='py2 width-100 flex items-center'
            >
              <TabHeader
                currentTabName={currentTabName}
                currentTabIcon={currentTabIcon}
              />
            </div>

            {toolsComponent &&
              <div
                style={styles.gridAreaTools}
                className='flex items-center justify-end'
              >
                {toolsComponent}
              </div>
            }

            <div style={styles.gridAreaContent}>
              <Grid
                rows={['1fr', 'auto']}
                className='height-100'
                gridGap={36}
              >
                {children}
                <div>
                  <Footer noXPadding />
                </div>
              </Grid>
            </div>
          </Grid>
        </div>
      </Main>
    </Wrapper>
  )
}

export default LayoutWithSideNav
