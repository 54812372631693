import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import autoBind from 'react-autobind'

import moment from 'moment'

import Square from '../common/Square'
import Menu from '../common/menu'
import EditableText from '../common/form/editable-text'
import Button from '../common/button'
import Grid from '../common/grid/grid'
import VersionsList from './versions-list'

import css from './combination.css'

const thumbnailStyle = { borderRadius: '2px 0 0 0' }

class Combination extends PureComponent {
  constructor () {
    super()
    autoBind(this)

    this.state = {
      isDownloadingRenders: false
    }
  }

  getStyleByVersion () {
    return {
      backgroundRepeat: 'no-repeat',
      backgroundSize: '90%'
    }
  }

  handleDownloadRenders () {
    this.props.onDownloadRenders()

    this.setIsDownloadingRenders(true)
    setTimeout(
      () => this.setDownloadingDone(),
      4000
    )
  }

  setIsDownloadingRenders (value) {
    this.setState({ isDownloadingRenders: value })
  }

  setDownloadingDone () {
    this.setIsDownloadingRenders(false)
    this._downloadButton._button.blur()
  }

  getHasChildInRenderingState () {
    return this.props.rendersAndPendings.filter(render => !render.isRender).length > 0
  }

  render () {
    const {
      createdAt,
      createdBy,
      modifiedAt,
      onEdit,
      onModalEdit,
      onRemove,
      onVisualize,
      imageSrc,
      id,
      title,
      batchId,
      currentUserId,
      rendersAndPendings,
      disableDownload
    } = this.props

    const {
      isDownloadingRenders
    } = this.state

    const menuItems = [
      { onClick: onModalEdit, text: 'Rename', icon: 'icon-pencil-1' },
      { onClick: onRemove, text: 'Remove', icon: 'icon-trash-empty' },
      { onClick: onVisualize, text: 'Edit master scene', className: 'border-top bc-black' }
    ].filter(item => item)
    return (
      <div className={`${css.root} relative paper-1 br-2 bg-white`} ref={(node) => (this._root = node)}>
        <Grid
          className='p2'
          columns={[140, '1fr', 400]}
        >
          <Square
            backgroundImage={imageSrc}
            className='pointer border bc-gray-light'
            onClick={onVisualize}
            innerStyle={thumbnailStyle}
            hoverable
          />

          <Grid
            columns={['1fr']}
            rows={['auto', '1fr']}
          >
            <EditableText
              editable={createdBy === currentUserId}
              defaultValue={title || 'No title.'}
              onChange={(title) => onEdit({ title })}
              singleClickEdit
              className='f4 bold m0 p0 truncate'
              iconSize={18}
            >
              {(value, props, icon) => (
                <h3 {...props}>{icon} {value}</h3>
              )}
            </EditableText>
            <small>
              {modifiedAt && (<time className='f7'>Updated {moment(modifiedAt).fromNow()}, </time>)}
              <time className='f7 c-gray-accessible'>Started {moment(createdAt).fromNow()}</time>

              <div className='mt2'>
                <div className='bg-secondary br-16 c-white px2 py1 inline-block'>
                  Style used: {batchId}
                </div>
              </div>
            </small>
          </Grid>

          <div className='md-flex items-start justify-end mx2'>
            <Button
              className='mx1 mb1'
              onClick={onVisualize}
            >
              Edit master scene
            </Button>
            <Button
              className='mx1 mb1'
              onClick={this.handleDownloadRenders}
              disabled={disableDownload || isDownloadingRenders || this.getHasChildInRenderingState()}
              ref={node => (this._downloadButton = node)}
            >
              {isDownloadingRenders ? 'Creating ZIP-archive' : 'Download all images'}
            </Button>
          </div>

          {createdBy === currentUserId && (
            <Menu
              className='absolute right-0 top-0 z1'
              buttonClassName={`${css.menuButton}`}
              items={menuItems}
              listStyle={{ width: 200 }}
              style={{ height: 40 }}
              position='bottomLeft'
            />
          )}
        </Grid>

        <VersionsList
          items={rendersAndPendings}
          parentCombination={id}
          designCardTitle={title}
          onVisualizeParent={onVisualize}
        />
      </div>
    )
  }
}

Combination.propTypes = {
  file: PropTypes.object,
  imageSrc: PropTypes.string,
  onVisualize: PropTypes.func,
  onRemove: PropTypes.func,
  onEdit: PropTypes.func,
  title: PropTypes.string
}

export default Combination
