import store from '../../stores'

import * as fromProjects from '../../stores/ducks/projects'
import * as fromUploads from '../../stores/ducks/uploads'

export default function handleUploads (msg) {
  const { doc, project } = msg

  if (project) {
    store.dispatch(fromProjects.receive([project]))
  }
  store.dispatch(fromUploads.receive([doc]))
}
