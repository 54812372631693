import React from 'react'
import styled from 'styled-components'
import { Column, Table, AutoSizer } from 'react-virtualized'

// common
import Modal from '../../../../common/modal'

// icons
import IconSurface from '../../../../common/icons/icon-surface'
import { GoCheck as IconCheck, GoX as IconCross } from 'react-icons/go'
import { AiOutlineApartment } from 'react-icons/ai'

// types
import type { ProductAnalyze } from '../productAnalysisTypes'

const css = require('./index.css')

const HeaderContainer = styled.div`
  height: 12%;
  display: flex;
  justify-content: space-between;
  min-height: 75px;
`
const ListContainer = styled.div`
  height: 90%;
`

const IconDescribeContainer = styled.div`
  display: flex;
  flexDirection: row;
`

type PropTypes = {
  data: ProductAnalyze
  isOpen: boolean
  onRequestClose: (boolean: Boolean) => void
}

function AnalysisInfoModal ({ data, isOpen, onRequestClose }: PropTypes) {
  const getPartNumberFromIndex = (index: number) => data.missingData[index]?.partNumber

  const renderStatusIcons = (hasIcon: boolean) => (hasIcon ? <IconCheck color='#66bb6a' size='16' />
    : <IconCross color='#ef5350' size='16' />)

  const renderDoubleStatusIcons = (hasData1: boolean, hasData2: boolean, rowIndex: number) => {
    const partNumberFromIndex = getPartNumberFromIndex(rowIndex)
    let hasPartCost = false
    if (partNumberFromIndex) {
      hasPartCost = data.allPartsCostInfo.filter(({ partNumber, cost }) => cost ? (partNumber === partNumberFromIndex) : false).length > 0
    }
    const icon1 = renderStatusIcons(partNumberFromIndex ? hasPartCost : hasData1)
    const icon2 = renderStatusIcons(partNumberFromIndex ? hasPartCost : hasData2)
    return <>{icon1}{icon2}</>
  }

  const renderNodeNameAndIcon = (rowIndex: number, cellData: any) => {
    const partNumberFromIndex = getPartNumberFromIndex(rowIndex)
    const icon = partNumberFromIndex ? <AiOutlineApartment size='12' style={{ marginRight: 6 }} /> : <IconSurface size='12' style={{ marginRight: 6 }} />
    return (
      <>
        {icon}
        <span>{cellData}</span>
      </>
    )
  }

  return (
    <Modal
      isOpen={isOpen}
      onAfterOpen={() => { }}
      onRequestClose={() => onRequestClose(true)}
      shouldCloseOnOverlayClick
      width={'80vw'}
      height={'80vh'}
    >
      <HeaderContainer>
        <h3>Material-, appearance-, and part cost information</h3>
        <div className='mt2'>
          <span className='h6 block'>ST = Surface treatment</span>
          <span className='h6 block'>BM = Base material</span>
          <IconDescribeContainer>
            <AiOutlineApartment size='16' />
            <span className='h6'>: Node is (or part of) IKEA Part</span>
          </IconDescribeContainer>
          <IconDescribeContainer>
            <IconSurface size='16' />
            <span className='h6'>: Node is regular geometry</span>
          </IconDescribeContainer>
        </div>
      </HeaderContainer>
      <ListContainer>
        <AutoSizer>
          {({ width, height }: { width: number, height: number }) => (
            <Table
              disableHeader={false}
              rowGetter={({ index }: { index: number }) => data.missingData[index]}
              headerHeight={40}
              rowHeight={30}
              rowCount={data.missingData.length}
              width={width}
              height={height}
              rowClassName={css.row}
              style={{ textAlign: 'center' }}
              gridStyle={{ outline: 'none' }}
            >
              <Column
                width={width / 4}
                label='Node name'
                dataKey='modalName'
                flexGrow={1}
                disableSort
                cellRenderer={({ cellData, rowIndex }: any) => renderNodeNameAndIcon(rowIndex, cellData)}
                style={{ textAlign: 'left', fontSize: 12 }}
              />
              <Column
                width={width / 4}
                label='Base material name'
                dataKey='carrierName'
                flexGrow={1}
                disableSort
                cellRenderer={({ cellData }: any) => cellData || '-'}
                style={{ fontSize: 12 }}
              />
              <Column
                width={width / 8}
                label='BM cost EU / AP'
                dataKey='missingCarrierCost'
                flexGrow={1}
                disableSort
                cellRenderer={({ cellData, rowIndex }: any) => renderDoubleStatusIcons(cellData.EU, cellData.AP, rowIndex)}
              />
              <Column
                width={width / 4}
                label='Appearance name'
                dataKey='appearanceName'
                flexGrow={1}
                disableSort
                cellRenderer={({ cellData }: any) => cellData || '-'}
                style={{ fontSize: 12 }}
              />
              <Column
                width={width / 8}
                label='ST cost EU / AP'
                dataKey='missingAppearanceCost'
                flexGrow={1}
                disableSort
                cellRenderer={({ cellData, rowIndex }: any) => {
                  return renderDoubleStatusIcons(cellData.EU, cellData.AP, rowIndex)
                }}
              />
            </Table>
          )}
        </AutoSizer>
      </ListContainer>
    </Modal >
  )
}

export default AnalysisInfoModal
