import React from 'react'

const IconUser = props => {
  return (
    <svg
      width={props.size || props.width}
      height={props.size || props.height}
      viewBox='0 0 18 18'
    >
      <g transform='translate(-4 -4)' fill={props.color}>
        <path
          d='M12 12c-2.205 0-4-1.795-4-4s1.794-4 4-4 4 1.795 4 4-1.794 4-4 4zm0 1c4.711 0 8 2.467 8 6v1H4v-1c0-3.533 3.29-6 8-6z'
        />
      </g>
    </svg>
  )
}

IconUser.defaultProps = {
  color: 'currentcolor',
  size: '1em',
  width: '1em',
  height: '1em'
}

export default IconUser
