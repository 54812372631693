import fetch from '../../../utils/fetch'

export const getSettings = () => (
  fetch('/api/settings')
    .then((res) => res.json())
)

export const getSplitFaces = (id) => (
  fetch('/api/split-face', {
    method: 'POST',
    body: JSON.stringify({ id })
  })
    .then((res) => res.json())
)
