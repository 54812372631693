import store from '../../stores'

import * as fromJobs from '../../stores/ducks/jobs'
import * as fromAdmin from '../../stores/ducks/admin'

import show from '../show-notification'
import {
  DEADLINE_STATUS
} from '../../constants'
import { fetchByIds } from '../../stores/ducks/roomsets'
import { setEditRoomsetId } from '../../stores/ducks/roomsets-admin'

export default async function handleJobs (msg) {
  const { doc } = msg

  store.dispatch(fromJobs.receive(doc))

  if (doc.roomsetId) {
    if (doc.type === 'roomset-post-script' && doc.status === DEADLINE_STATUS.COMPLETED) {
      show({
        title: 'Roomset convert completed!',
        body: `Roomset ${doc.roomsetId}\nStatus: ${doc.status}`,
        navigateTo: '/roomsets/',
        level: 'success'
      })

      if (store.getState().roomsetsAdmin.currentId === doc.roomsetId) {
        await store.dispatch(fetchByIds([doc.roomsetId]))
        store.dispatch(setEditRoomsetId(doc.roomsetId))
      }
    }
  }

  if (doc.materialId && doc.status && !doc.removedAt) {
    store.dispatch(fromAdmin.setAdminMaterialsInProgress({
      materialId: doc.materialId,
      id: doc.id,
      deadlineStatus: doc.deadlineStatus,
      percentage: doc.percentage,
      createdAt: doc.createdAt,
      completedAt: doc.completedAt,
      status: doc.status,
      type: doc.type
    }))
  }
}
