import { connect } from 'react-redux'
import { v4 as uuid } from 'uuid'

import * as fromInterfaceZindex from '../../stores/ducks/interface/zindex'

export default (Component) => {
  const id = uuid()

  return connect(
    (state, ownProps) => ({
      isTop: id === state.interface.zindex,
      ...ownProps
    }),
    (dispatch) => ({
      setAsTop: () => dispatch(fromInterfaceZindex.setAsTop(id))
    })
  )(Component)
}
