export function updateFloor ({ floor, centerPosition, size, floorBox, viewerUtils }) {
  return () => {
    const shouldUpdateScale = (centerPosition && size)
    let computedScale = null

    if (floorBox !== undefined) {
      var floorBoxBB = viewerUtils.getWorldBoundFromMeshBoundingBoxes(floorBox)
      floor.position.y = floorBox.visible ? floorBoxBB.min.y : 0
    }

    if (shouldUpdateScale) {
      floor.visible = true

      computedScale = Math.max(size.x, size.z) * 3 + 3 // three times as big + initial "Scale" of floor
      floor.userData.computedScale = computedScale
      floor.userData.centerPosition = centerPosition

      floor.scale.set(10, 10, 10)

      floor.position.x = centerPosition.x
      floor.position.z = centerPosition.z

      floor.userData.snappingType = 'EXACT'
      floor.userData.noSourceSnap = true
    }

    floor.traverse((child) => {
      if (child.material) {
        const { material } = child

        if (shouldUpdateScale) {
          child.userData.mapRepeat.copy(material.mapRepeat)
          _setFloorFading(material, { computedScale, centerPosition })
        } else if (child.userData.mapRepeat) {
          material.mapRepeat.copy(child.userData.mapRepeat)
          _setFloorFading(material, floor.userData)
        } else {
          _setFloorFading(material, floor.userData)
        }

        if (!material.useModelFading) { material.useModelFading = true }
      }
    })
  }
}

function _setFloorFading (material, { computedScale, centerPosition }) {
  if (centerPosition === undefined) {
    centerPosition = { x: 0, y: 0, z: 0 }
  }
  centerPosition.y = 0

  if (computedScale >= 50) {
    computedScale = 50
  }
  material.origoFading.copy(centerPosition)
  material.endFading = computedScale / 3
  material.startFading = (computedScale / 3) - 5
}
