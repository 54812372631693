const THREE = require('three')

function pick (arr, indices) {
  const result = indices.reduce((acc, index) => {
    acc.push(arr[index])
    return acc
  }, [])
  return result
}

function uvMat4ToUvMat3 (mat4) {
  const mat3 = new THREE.Matrix3()
  mat3.set.apply(mat3, pick(mat4.elements,
    [0, 4, 12,
      1, 5, 13,
      3, 3, 15]))
  return mat3
}

function uvMat3ToUvMat4 (mat3) {
  const mat4 = new THREE.Matrix4()
  mat4.set.apply(mat4, pick(mat3.elements,
    [0, 3, 2, 6,
      1, 4, 2, 7,
      2, 2, 8, 2,
      2, 2, 2, 8]))
  return mat4
}

module.exports = { uvMat4ToUvMat3, uvMat3ToUvMat4 }
