import * as fromTemplatesSelectors from '../../templates/selectors'
import * as fromThreeviewerSelectors from '../../threeviewer/selectors'

import getTemplatePartScene from './get-template-part-scene'

import { updateWall } from './update-wall'
import { updateFloor } from './update-floor'

function updateWallMaterial () {
  return (dispatch, getState) => {
    const localState = fromTemplatesSelectors.getLocalState(getState())
    const { THREE } = fromThreeviewerSelectors.getViewer(getState())

    const walls = [
      getTemplatePartScene(localState, 'wallLeft'),
      getTemplatePartScene(localState, 'wallRight')
    ].filter(Boolean)

    if (walls.length !== 2) return

    walls.forEach((wall) => {
      dispatch(updateWall({ wall, THREE }))
    })
  }
}

function updateFloorMaterial () {
  return (dispatch, getState) => {
    const state = getState()
    const localState = fromTemplatesSelectors.getLocalState(state)
    const { THREE } = fromThreeviewerSelectors.getViewer(state)

    const floor = getTemplatePartScene(localState, 'floor')
    if (!floor) return
    dispatch(updateFloor({ floor, THREE })) // TODO:  missing parameters?
  }
}

export function updateSkirtingMaterial () {
  return (dispatch, getState) => {
    const localState = fromTemplatesSelectors.getLocalState(getState())

    const skirtings = [
      getTemplatePartScene(localState, 'skirtingLeft'),
      getTemplatePartScene(localState, 'skirtingRight')
    ].filter(Boolean)

    skirtings.forEach((skirting) => {
      var scale = skirting.scale
      skirting.traverse((child) => {
        if (child.material) {
          const { material } = child
          if (material.mapRepeat) {
            const mapRepeat = material.mapRepeat.clone()
            child.userData.mapRepeat.copy(mapRepeat)
            material.mapRepeat.copy(scale)
            material.mapRotationX = 0
            material.mapRotationY = 0
            material.mapRotationZ = 0
          }

          if (material.useModelFading) { material.useModelFading = false }
        }
      })
    })
  }
}

export function updateFloorBoxMaterial (shouldUpdateScale, earlierScale) {
  return (dispatch, getState) => {
    const localState = fromTemplatesSelectors.getLocalState(getState())

    const floorBox = getTemplatePartScene(localState, 'floorBox')

    if (!floorBox) return

    // material
    floorBox.traverse((child) => {
      if (child.material) {
        const { material } = child

        if (shouldUpdateScale) {
          const mapRepeat = material.mapRepeat.clone()

          mapRepeat.divide(earlierScale) // divide with scale before setting scale to get "default" mapRepeat
          mapRepeat.multiply(floorBox.scale)

          child.userData.mapRepeat = mapRepeat
          material.mapRepeat.copy(mapRepeat)
        } else if (child.userData.mapRepeat) {
          material.mapRepeat.copy(child.userData.mapRepeat)
        }

        if (material.useModelFading) { material.useModelFading = false }

        material.wallX0 = floorBox.position.x - 0.5 * floorBox.scale.x
        material.wallX1 = floorBox.position.x + 0.5 * floorBox.scale.x
      }
    })
  }
}

export default function updateMaterials () {
  return (dispatch) => {
    dispatch(updateFloorMaterial())
    dispatch(updateFloorBoxMaterial())
    dispatch(updateWallMaterial())
    dispatch(updateSkirtingMaterial())
  }
}
