import { createSelector } from 'reselect'

import _get from 'lodash/get'

import * as fromImagePackagesSelectors from '../image-packages/selectors'
import * as fromMaterialAndColorSelectors from '../../../components/visualizer/panels/material-and-color/material-and-color-selectors'
import * as fromProjectsSelectors from '../projects/selectors'

import { PROJECT_TYPES } from '../../../constants'
import { RootState } from '..'

export const getCategories = (state: RootState) => state.materialSearch.allMaterialCategories

export const getFilters = createSelector(
  fromMaterialAndColorSelectors.getActiveTab,
  fromMaterialAndColorSelectors.getSelectionMode,
  fromProjectsSelectors.getCurrentEntry,
  fromImagePackagesSelectors.getCurrentEntry,
  (state: RootState) => state.materialSearch.onlyStandardAppearances,
  (state: any) => state.materialSearch.materialCategories,
  (state: any) => state.materialSearch.materialClass,
  (state: any) => state.materialSearch.isUncategorizedSelected,
  fromProjectsSelectors.getCurrentProjectType,
  (activeTab, selectionMode, project, imagePackage, onlyStandardAppearances, materialCategories, materialClass, isUncategorizedSelected, projectType) => {
    let imagePackageMaterialIds = _get(imagePackage, 'materials', [])
    if (_get(project, 'projectType') === PROJECT_TYPES.IMAGE_PACKAGE && imagePackageMaterialIds.length) {
      imagePackageMaterialIds = _get(imagePackage, 'materials')
    }

    return {
      activeTab,
      materialCategories,
      isUncategorizedSelected,
      materialClass,
      selectionMode,
      onlyStandardAppearances,
      projectType,
      ...(imagePackageMaterialIds.length ? { imagePackageMaterialIds } : {})
    }
  }
)
