import React from 'react'
import cs from 'classnames'
import PropTypes from 'prop-types'

import { FaRegThumbsUp as IconLikeEmpty, FaThumbsUp as IconLikeFull } from 'react-icons/fa'

import Menu from '../../common/menu'

const CommentHeader = ({
  commentId,
  author,
  createdAt,
  likes,
  likedByCurrentUser,
  onLike,
  onRemove,
  currentUserId,
  onEdit,
  likeEnabled,
  editEnabled,
  removeEnabled,
  theme
}) => {
  const defaultMenuItems = removeEnabled ? [
    { onClick: () => onRemove(), text: 'Remove', icon: 'icon-trash-empty' }
  ] : []
  const menuItems = editEnabled ? [
    { onClick: () => onEdit(), text: 'Edit', icon: 'icon-pencil-1' },
  ].concat(defaultMenuItems) : defaultMenuItems

  const lightTheme = theme === 'light'

  return (
    <div className={'pb1'}>
      <div className={`flex items-center ${lightTheme ? 'c-gray-accessible' : ''}`}>
        <div>{author && author.email}</div>
        <div className='flex ml-auto'>
          { likeEnabled && <div
            className={cs('pointer', {
              'c-primary-hover': !likedByCurrentUser,
              'c-primary': likedByCurrentUser
            })}
            onClick={() => onLike(commentId)}
          >
            {likedByCurrentUser ? <IconLikeFull size={16} /> : <IconLikeEmpty size={16} />}
            <span> {likes.length}</span>
          </div>}
          {author.id === currentUserId && menuItems.length > 0 &&
            <Menu
              className='height-100 ml1 right-0 c-primary-hover'
              position='bottomLeft'
              items={menuItems}
              listStyle={lightTheme ? { backgroundColor: 'var(--gray)' } : {}}
            />
          }
        </div>
      </div>
      <time className='f8'>{createdAt}</time>
    </div>
  )
}

CommentHeader.propTypes = {
  commentId: PropTypes.string,
  author: PropTypes.object,
  createdAt: PropTypes.string,
  likes: PropTypes.array,
  likedByCurrentUser: PropTypes.bool,
  onLike: PropTypes.func,
  onRemove: PropTypes.func,
  currentUserId: PropTypes.string,
  onEdit: PropTypes.func,
  theme: PropTypes.string
}

export default CommentHeader
