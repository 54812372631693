import Immutable from 'seamless-immutable'
import { createAction, handleActions } from 'redux-actions'

import * as api from './api'
import * as fromColors from '../colors'
import * as fromJobs from '../jobs'

export const setSelectedUser = createAction('admin/SET_SELECTED_USER')
export const setUserProperties = createAction('admin/SET_USER_PROPERTIES')
export const setNewUserModalOpen = createAction('admin/SET_NEW_USER_MODAL_OPEN')
export const setResetPasswordModalOpen = createAction('admin/SET_RESET_PASSWORD_MODAL_OPEN')
export const setDiscardUserChangesModalOpen = createAction('admin/SET_DISCARD_USER_CHANGES_MODAL_OPEN')
export const setRemoveUserModalOpen = createAction('admin/SET_REMOVE_USER_MODAL_OPEN')
export const setGdprRemoveUserModalOpen = createAction('admin/SET_GDPR_REMOVE_USER_MODAL_OPEN')
export const setShowDeleted = createAction('admin/SET_SHOW_DELETED')
export const setUserSearchString = createAction('admin/SET_USER_SEARCH_STRING')
const setAdminMaterials = createAction('admin/SET_ADMIN_MATERIALS')
const setAdminLoader = createAction('admin/SET_ADMIN_LOADER')
export const setNewColorModalOpen = createAction('admin/SET_NEW_COLOR_MODAL_OPEN')
export const setEditColorModalOpen = createAction('admin/SET_EDIT_COLOR_MODAL_OPEN')
export const setColorSearchString = createAction('admin/SET_COLOR_SEARCH_STRING')
export const setRemoveColorModalOpen = createAction('admin/SET_REMOVE_COLOR_MODAL_OPEN')
export const setColorPicker = createAction('admin/SET_COLOR_PICKER')
export const setAdminMaterialsInProgress = createAction('admin/SET_ADMIN_MATERIALS_IN_PROGRESS')

export function getAdminMaterials () {
  return (dispatch) => {
    return api.getMaterials()
      .then((data) => {
        dispatch(setAdminLoader(false))
        dispatch(setAdminMaterials(data))
      })
      .then(() => api.getMaterialJobs())
      .then((data) => {
        dispatch(fromJobs.replace(data))
      })
      .catch((err) => console.log(err))
  }
}

export function deleteAdminMaterial (id) {
  return (dispatch) => {
    const deleteId = {
      id: id
    }
    api.deleteMaterial(deleteId)
      .then(() => {
        dispatch(getAdminMaterials())
      })
      .catch((err) => console.log(err))
  }
}

export function updateAdminMaterial (payload) {
  return (dispatch) => {
    api.updateMaterial(payload)
      .then(() => {
        dispatch(getAdminMaterials())
      })
      .catch((err) => console.log(err))
  }
}

export function adminLoader (payload) {
  return (dispatch) => {
    dispatch(setAdminLoader(payload))
  }
}

export function closeAddColorModal (payload) {
  return (dispatch) => {
    dispatch(fromColors.disposeCollisionProblems())
    dispatch(setNewColorModalOpen(payload))
  }
}

export function closeEditColorModal (payload) {
  return (dispatch) => {
    dispatch(fromColors.disposeCollisionProblems())
    dispatch(setEditColorModalOpen(payload))
  }
}

var initialState = Immutable({
  selectedUser: null,
  newUserModalOpen: false,
  resetPasswordModalOpen: false,
  discardUserChangesModalOpen: false,
  removeUserModalOpen: false,
  gdprRemoveUserModalOpen: false,
  showDeleted: false,
  userSearchString: '',
  adminMaterials: [],
  adminMaterialsInProgress: Immutable({}),
  adminLoader: true,
  newColorModalOpen: false,
  editColorModalOpen: false,
  colorSearchString: '',
  removeColorModalOpen: false,
  colorPicker: 'rgb(0, 0, 0)',
  entries: {}
})

export default handleActions({
  [setShowDeleted]: (state, { payload }) => state.merge({
    showDeleted: payload
  }),
  [setSelectedUser]: (state, { payload }) => state.merge({
    selectedUser: payload
  }),
  [setUserProperties]: (state, { payload }) => state.merge({
    selectedUser: payload
  }, { deep: true }),
  [setNewUserModalOpen]: (state, { payload }) => state.merge({
    newUserModalOpen: payload
  }),
  [setResetPasswordModalOpen]: (state, { payload }) => state.merge({
    resetPasswordModalOpen: payload
  }),
  [setDiscardUserChangesModalOpen]: (state, { payload }) => state.merge({
    discardUserChangesModalOpen: payload
  }),
  [setRemoveUserModalOpen]: (state, { payload }) => state.merge({
    removeUserModalOpen: payload
  }),
  [setGdprRemoveUserModalOpen]: (state, { payload }) => state.merge({
    gdprRemoveUserModalOpen: payload
  }),
  [setUserSearchString]: (state, { payload }) => state.merge({
    userSearchString: payload
  }),
  [setAdminMaterials]: (state, { payload }) => state.merge({
    adminMaterials: payload
  }),
  [setAdminMaterialsInProgress]: (state, { payload }) => state.merge({
    adminMaterialsInProgress: state.adminMaterialsInProgress.merge({
      [payload.id]: payload
    })
  }),
  [setAdminLoader]: (state, { payload }) => state.merge({
    adminLoader: payload
  }),
  [setNewColorModalOpen]: (state, { payload }) => state.merge({
    newColorModalOpen: payload
  }),
  [setEditColorModalOpen]: (state, { payload }) => state.merge({
    editColorModalOpen: payload
  }),
  [setColorSearchString]: (state, { payload }) => state.merge({
    colorSearchString: payload
  }),
  [setRemoveColorModalOpen]: (state, { payload }) => state.merge({
    removeColorModalOpen: payload
  }),
  [setColorPicker]: (state, { payload }) => state.merge({
    colorPicker: payload
  })
}, initialState)
