class Vector {
  constructor (elements) {
    if (elements.length !== 3) {
      throw new Error('A vector can only be og length 3')
    }
    this.elements = elements
  }

  get (i) {
    return this.elements[i]
  }

  static distance (a, b) {
    const sum = [0, 1, 2].reduce((acc, i) => {
      return acc + Math.pow(a.get(i) - b.get(i), 2)
    }, 0)
    return Math.sqrt(sum)
  }
}

class Val {
  constructor (position, value) {
    this.position = new Vector(position)
    this.value = value
  }
}

export default class LshEuclidean {
  constructor () {
    this.dictionary = {}
    this.cellsize = 2
    this.cellsize_half = this.cellsize * 0.5
    this.length = 0
    // this.maxNrOfBuckets = 1000
    // this.primes = [31, 37, 41]
  }

  clone () {
    const helper = new this.constructor(this.cellsize)
    Object.values(this.dictionary).forEach((bucket) => {
      bucket.forEach(v => {
        helper.add(v.position.elements, v.value)
      })
    })
    return helper
  }

  _cellCenter (position) {
    return position.map((n) => {
      return (Math.round(n / this.cellsize) * this.cellsize)
    })
  }

  _voxelCoord (position) {
    return position.map((n) => {
      return Math.floor(n / this.cellsize)
    })
  }

  _voxelsInBounds (min, max) {
    const minVoxel = this._voxelCoord(min)
    const maxVoxel = this._voxelCoord(max)
    // Generate all voxelcoords within bounds
    const voxelsWithinBound = []
    for (let x = minVoxel[0]; x <= maxVoxel[0]; x++) {
      for (let y = minVoxel[1]; y <= maxVoxel[1]; y++) {
        for (let z = minVoxel[2]; z <= maxVoxel[2]; z++) {
          voxelsWithinBound.push([x, y, z])
        }
      }
    }
    return voxelsWithinBound
  }

  _hash (voxelcoord) {
    return `x${voxelcoord[0]}y${voxelcoord[1]}z${voxelcoord[2]}`
    // return (voxelcoord[0] * this.primes[0] + voxelcoord[1] * this.primes[1] + voxelcoord[2] * this.primes[2]) % this.maxNrOfBuckets
  }

  add (position, value) {
    const vc = this._voxelCoord(position)
    const k = this._hash(vc)
    if (!(k in this.dictionary)) {
      this.dictionary[k] = []
    }

    this.length++
    this.dictionary[k].push(new Val(position, value))
    return true
  }

  remove (position, value) {
    const vc = this._voxelCoord(position)
    const k = this._hash(vc)
    const oldLength = this.length
    const newBucket = []
    if (k in this.dictionary) {
      this.dictionary[k].forEach(v => {
        if (v.value === value) {
          this.length--
        } else {
          newBucket.push(v)
        }
      })

      this.dictionary[k] = newBucket
    } else {
      console.warn('No bucket found at position', position)
    }
    return oldLength > this.length
  }

  getAll () {
    const allValues = Object.values(this.dictionary).reduce((xs, x) => { return xs.concat(x) }, [])
    return allValues.map(v => { return v.value })
  }

  allPositions () {
    const allValues = Object.values(this.dictionary).reduce((xs, x) => { return xs.concat(x) }, [])
    return allValues.map(v => { return v.position.elements })
  }

  _bucketsByBounds (min, max) {
    const voxelsWithinBound = this._voxelsInBounds(min, max)
    const hashes = voxelsWithinBound.map(c => { return this._hash(c) })
    return hashes.reduce((buckets, h) => {
      if ((h in this.dictionary)) {
        return buckets.concat(this.dictionary[h])
      }
      return buckets
    }, [])
  }

  getByBounds (min, max) {
    // Make sure min and max point is extremepoints and not bounds.
    const correctMin = [Math.min(min[0], max[0]), Math.min(min[1], max[1]), Math.min(min[2], max[2])]
    const correctMax = [Math.max(min[0], max[0]), Math.max(min[1], max[1]), Math.max(min[2], max[2])]

    const buckets = this._bucketsByBounds(correctMin, correctMax)

    const withinBounds = buckets.filter(v => {
      return min[0] <= v.position.get(0) && v.position.get(0) <= max[0] && min[1] <= v.position.get(1) && v.position.get(1) <= max[1] && min[2] <= v.position.get(2) && v.position.get(2) <= max[2]
    })

    return withinBounds.map(v => { return v.value })
  }

  get (position, distance) {
    distance = distance || this.cellsize

    const distanceHalf = distance / 2
    const min = position.map(e => { return e - distanceHalf })
    const max = position.map(e => { return e + distanceHalf })

    const buckets = this._bucketsByBounds(min, max)
    const origin = new Vector(position)
    const withinThreshold = buckets.filter(v => {
      return Vector.distance(v.position, origin) < distance
    })

    const sorted = withinThreshold.sort((a, b) => {
      const adist = Vector.distance(a.position, origin)
      const bdist = Vector.distance(b.position, origin)
      return adist - bdist
    })

    return sorted.map(v => { return v.value })
  }
}
