import navigate from '../utils/navigate'

if (window.Notification && window.Notification) window.Notification.requestPermission(() => {})

function createNotification (opt) {
  var delay = 5000
  var title = opt.title
  var level = opt.level
  opt.icon = opt.icon || `/${opt.level ? `img/${opt.level}.png` : 'favicon.ico'}`
  delete opt.delay
  delete opt.level
  delete opt.title

  var notificationArgs = {
    title,
    level,
    opt: {
      body: opt.body,
      sticky: true,
      requireInteraction: true,
      icon: opt.icon,
      tag: opt.tag || 'general',
      data: {
        level,
        url: opt.navigateTo
      }
    }
  }

  if (window.navigator.serviceWorker && process.env.NODE_ENV === 'production') {
    window.navigator.serviceWorker.getRegistration().then((reg) => {
      notificationArgs.opt.vibrate = [200, 100, 200, 100, 200, 100, 200]
      reg.showNotification(title, notificationArgs.opt)
        .then(() => {
          setTimeout(() => {
            reg.getNotifications({ tag: notificationArgs.opt.tag })
              .then((notifications) => {
                notifications.forEach((notification) => notification.close())
              })
          }, delay)
        })
    })
  } else {
    var n = new window.Notification(notificationArgs.title, notificationArgs.opt)
    n.onclick = () => {
      if (opt.navigateTo) {
        navigate(opt.navigateTo)
      }
    }
    window.setTimeout(n.close.bind(n), delay)
  }
}

export default function show (opt) {
  if (window.Notification) {
    if (window.Notification.permission === 'granted') return createNotification(opt)
    if (window.Notification.permission !== 'denied') {
      window.Notification.requestPermission((permission) => {
        if (permission === 'granted') createNotification(opt)
      })
    }
  }
}
