import { EventEmitter } from 'events'
import { GLTFXLoader } from './plugins/gltf/index.js'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import * as THREE from 'three'

export default class Loader extends EventEmitter {
  async load (url, useModelMaterial) {
    const extension = this.checkExtension(url)

    if (!extension) {
      throw new Error(`Loader: No extension found in url/path: ${url}`)
    }

    const loader = this.getLoader(extension)

    if (!loader) {
      throw new Error(`Loader: No loader found for extension: ${extension}`)
    }

    let model = null

    try {
      this.emit('onStartLoad', url)

      model = url.name
        ? await this._loadWithFileReader(url, loader)
        : await this._loadWithLoader(url, loader)
    } catch (err) {
      console.error(err)
      throw err
    }

    if (extension === 'dae') {
      return model.scene
    }

    if (extension === 'obj') {
      const mesh = model.children[0]
      if (mesh.geometry) {
        const geometry = mesh.geometry
        mesh.scale.multiplyScalar(0.001)
        mesh.material = null
        if (geometry.isBufferGeometry) {
          if (!geometry.attributes.uv2 && geometry.attributes.uv) {
            geometry.setAttribute('uv2', new THREE.BufferAttribute(geometry.attributes.uv.array, 2))
          }
        } else if (geometry.isGeometry && geometry.faceVertexUvs.length === 1) {
          geometry.faceVertexUvs[1] = geometry.faceVertexUvs[0]
        }
      }
      return model
    }

    if (extension === 'json') {
      const materials = arguments[1]
      const geometry = arguments[0]
      let material

      if (geometry.isBufferGeometry) {
        if (!geometry.attributes.uv2) {
          geometry.setAttribute('uv2', new THREE.BufferAttribute(geometry.attributes.uv.array, 2))
        }
      } else if (geometry.isGeometry && geometry.faceVertexUvs.length === 1) {
        geometry.faceVertexUvs[1] = geometry.faceVertexUvs[0]
      }

      if (!materials || materials.length === 0) {
        material = null
      } else {
        material = materials[0]
      }

      const jsonMesh = new THREE.Mesh(geometry, material)
      return jsonMesh
    }

    if (model.scene) {
      model.scene.traverse(child => {
        if (child.parent && child.parent.parent && child.children.length === 0) {
          if (!child.parent.name) {
            child.parent.name = child.parent.parent.name + '_T1'
            child.parent.userData.materialTransform = child.parent.name
          }
          if (!child.name) {
            child.name = child.parent.parent.name + '_Mesh'
            child.userData.materialTransform = child.parent.parent.name
          }
        }

        if (!useModelMaterial) {
          child.material = null
        }
      })
      return model
    }
  }

  _loadWithFileReader (url, loader) {
    return new Promise((resolve, reject) => {
      const fr = new window.FileReader()
      fr.addEventListener('load', (event) => resolve(loader.parse(event.target.result)))
      fr.addEventListener('error', reject)
      fr.readAsText(url)
    })
  }

  _loadWithLoader (url, loader) {
    return new Promise((resolve, reject) => {
      loader.load(url,
        (model) => resolve(model),
        (progress) => this.emit('onProgress', progress),
        (err) => reject(err)
      )
    })
  }

  getLoader (extension) {
    switch (extension) {
      case 'obj': return new OBJLoader()
      case 'gltf':
      case 'glb':
        return new GLTFXLoader(undefined, this.dracoLoader)
      case 'json': return new THREE.JSONLoader()
      default: return null
    }
  }

  setDracoPath (dracoPath) {
    if (this.dracoLoader === undefined) {
      this.dracoLoader = new DRACOLoader()
      this.dracoLoader.setDecoderPath(dracoPath)
    }
  }

  checkExtension (filename) {
    var temp = filename
    if (filename.name) { temp = filename.name }
    var extension = temp.split('.').pop() || null
    if (extension) {
      return extension.toLowerCase()
    }
    return false
  }

  dispose () {
    this.removeAllListeners()
  }
}
