import React from 'react'
import styled from 'styled-components'
import _omit from 'lodash/omit'
import cs from 'classnames'

type Props = React.HTMLAttributes<HTMLDivElement> & {
  borderRadius?: 0 | 10
  innerStyle?: React.CSSProperties
  innerClass?: string
  pad?: 0 | 2 | 4 | 6
  backgroundImage?: string
  backgroundColor?: string
  hoverable?: boolean
}

const Inner = styled.div<{
  pad: 0 | 2 | 4 | 6
  borderRadius: 0 | 10
  image?: string
  color?: string
}>`
  position: absolute;

  top: ${props => props.pad + 'px'};
  bottom: ${props => props.pad + 'px'};
  left: ${props => props.pad + 'px'};
  right: ${props => props.pad + 'px'};
  border-radius: ${props => props.borderRadius + 'px'};

  background-color: ${props => props.color || ''};
  background-image: ${props => props.image ? `url(${props.image})` : ''};
  background-size: cover;
  background-position: center center;
`

const Wrapper = styled.div`
  position: relative;
  padding-bottom: 100%;
  width: 100%;
`

const Square = (props: Props) => {
  const safeProps = _omit(
    props,
    'backgroundImage',
    'backgroundColor',
    'borderRadius',
    'pad',
    'innerStyle',
    'className',
    'innerClass',
    'hoverable'
  )

  return (
    <Wrapper
      {...safeProps}
      className={cs(props.className || '', { 'opacity-hover-lighter': props.hoverable })}
    >
      <Inner
        className={cs(props.innerClass || '', 'flex items-center')}
        pad={props.pad || 0}
        borderRadius={props.borderRadius || 0}
        color={props.backgroundColor}
        image={props.backgroundImage}
      >
        {props.children}
      </Inner>
    </Wrapper>
  )
}

export default Square
