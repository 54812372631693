import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Tabs from './Tabs'
import { General } from './General'
import { Annotation } from './Annotation'
import Modal from '../../../common/modal'

import * as fromScenes from '../../../../stores/ducks/scenes'
import * as fromSceneSelectors from '../../../../stores/ducks/scenes/selectors'
import type { TagKeys, TempTags } from '../../../../stores/ducks/scenes/Temp'
import FormRemove from '../../../common/form/form-remove'
import { getIsCommenting } from '../../../../stores/ducks/gallery/selectors'

type Props = {
  onRequestClose: () => void
  isOpen: boolean
  scene: any
}

const ShareSceneGloballyModal = ({ onRequestClose, isOpen, scene }: Props) => {
  if (!isOpen) return null

  const dispatch = useDispatch()

  const comments = useSelector(fromSceneSelectors.getTempComments)
  const tempTags = useSelector(fromSceneSelectors.getTempTags)
  const tempTitle = useSelector(fromSceneSelectors.getTempTitle)
  const isCommenting = useSelector(getIsCommenting) as boolean

  const [errorMsg, setErrorMsg] = useState('')
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false)

  const onClose = () => {
    dispatch(fromScenes.setTempTitle({ title: null }))
    dispatch(fromScenes.clearTempTags())
    dispatch(fromScenes.clearTempComments())

    onRequestClose()
  }

  const getTempTagIds = () => {
    const reducerConcatArrays = (accumulator:any[], currentValue:any) => accumulator.concat(currentValue)
    return Object.values(tempTags).reduce(reducerConcatArrays, [])
  }

  const checkRequiredInput = (tags:TempTags) => {
    const requiredKeys = ['REGION', 'PRIORITIES', 'LIVING_SITUATION', 'ACTIVITIES', 'ROOMS']
    const notOk = requiredKeys.filter((key:string) => tags[key as TagKeys] === undefined || tags[key as TagKeys].length < 1)
    return notOk.length === 0
  }

  const preSubmit = () => {
    if ((tempTitle === null || tempTitle.length > 0) && checkRequiredInput(tempTags)) {
      if (scene.id) {
        const tagIds = getTempTagIds()
        const title = tempTitle || scene.title
        dispatch(fromScenes.saveGlobalScene({ id: scene.id, title, tags: tagIds, comments, global: true }))
        setErrorMsg('')
      }
      onClose()
    }
    setErrorMsg('All required fields are not set')
  }

  const openConfirmationModal = () => setConfirmationModalOpen(true)

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={openConfirmationModal}
        shouldCloseOnOverlayClick
        width={'80vw'}
        height={'80vh'}
      >
        <Tabs
          title={'Save scene globally'}
          onSubmit={preSubmit}
          onCancel={openConfirmationModal}
          errorMsg={errorMsg}
          disabledSave={isCommenting}
          componentGeneral={<General errorMsg={errorMsg} selectedTags={getTempTagIds()} title={tempTitle || scene.title} readOnly={false}/>}
          componentAnnotation={<Annotation readOnly={false} combination={scene}/>}
        />
      </Modal>
      <Modal
        isOpen={confirmationModalOpen}
        onRequestClose={() => {}}
        shouldCloseOnOverlayClick={false}
        width={600}
      >
        <FormRemove
          formTitle={'Leave save scene globally'}
          formDescription={'No information will be saved.'}
          onConfirm={onClose}
          onCancel={() => setConfirmationModalOpen(false)}
          buttonText='Leave'
        />
      </Modal>
    </>
  )
}

export default ShareSceneGloballyModal
