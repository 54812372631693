import { createSelector } from 'reselect'

import _every from 'lodash/every'
import _isEmpty from 'lodash/isEmpty'
import _filter from 'lodash/filter'
import _some from 'lodash/some'

import { STATUS } from './index'

import * as fromTemplatesSelectors from '../../templates/selectors'
import { RootState } from '../..'

const getLocalState = (state: RootState) => state.threeviewer
const getFilesState = (state: RootState) => getLocalState(state).files
export const getEntries = (state: RootState) => getFilesState(state).entries
const getEntriesByType = (type: string) => (state: RootState) => _filter(getEntries(state), file => file.type === type)
const getAreCombinationsLoading = (state: RootState) => getFilesState(state).combinationsInProgress.length > 0

export const getIsLoading = createSelector(
  getEntries,
  getAreCombinationsLoading,
  (entries, combinationsLoading) => {
    return combinationsLoading || _some(entries, (file) => ![STATUS.FINISHED, STATUS.FAILED].includes(file.status))
  }
)

export const getIsDefaultTemplateLoading = createSelector(
  fromTemplatesSelectors.getActiveTemplateId,
  getEntriesByType('default-template'),
  (templateId, entries) => {
    return (
      templateId === 'default' &&
      !_isEmpty(entries) &&
      !_every(entries, file => [STATUS.FINISHED, STATUS.FAILED].includes(file.status))
    )
  }
)

const getHasErrors = (state: RootState) => (
  getFilesState(state).progressError
)

export const getIsSceneLoaded = createSelector(
  getHasErrors,
  getIsLoading,
  (hasErrors, isSceneLoading) => {
    return !hasErrors && !isSceneLoading
  }
)
