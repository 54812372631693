import React, { useState } from 'react'

import InputText from '../common/form/input-text'
import InputGroup from '../common/form/input-group'
import Label from '../common/form/label'
import Button from '../common/button'
import KeyboardListener, { KeyCode, KeyBinding } from '../common/keyboard-listener'
import TextArea from '../common/form/textarea'
import Spinner from '../common/spinner'
import NotificationBubble from '../common/notification-bubble'
import { useSelector } from 'react-redux'
import { getNodes } from '../../stores/ducks/tree/selectors'
import { ITreeNodes } from '../../stores/ducks/tree/TreeNode'

type Props = {
  onSubmit: (value: { title: string; description: string, models: ITreeNodes }, signal: AbortSignal) => any
  onCancel: () => void
  callNotification: () => void
}

const ReportForm = ({ onSubmit, onCancel, callNotification }: Props) => {
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [errorMsgTitle, setErrorMsgTitle] = useState('')
  const [errorMsgDescription, setErrorMsgDescription] = useState('')
  const [buttonTitle, setButtonTitle] = useState('Report')
  const [error, setError] = useState(null)

  const models = useSelector(getNodes)

  const [loading, setLoading] = useState(false)
  const controller = React.useRef(new AbortController())
  const descriptionLabel: string = 'Please provide a short description of the incident that you have experienced.'

  const checkInputTitle: () => boolean = () => title.trim && title.trim().length > 0
  const checkInputDescription: () => boolean = () => description.trim && description.trim().length > 0
  const checkInputs: () => boolean = () => checkInputTitle() && checkInputDescription()

  const preCancel = () => {
    controller.current.abort()
    setLoading(false)
    onCancel()
  }

  const postSubmit = (res:any) => {
    if (controller.current.signal.aborted) return onCancel()
    if (res.error) return setError(res.error)
    setLoading(false)
    setButtonTitle('Report sent')
    setTimeout(() => onCancel(), 1000)
    callNotification()
  }

  const preSubmit = async () => {
    const required:string = 'Required'
    if (checkInputs()) {
      setLoading(true)
      const res = await onSubmit({ title, description, models }, controller.current.signal)
      postSubmit(res)
    }
    if (!checkInputTitle()) setErrorMsgTitle(required)
    if (!checkInputDescription()) setErrorMsgDescription(required)
  }

  return (
    <>
      <div data-testid='report-modal'>
        <h2 className='mt0'>
          Report incident to DPD support team
        </h2>

        <InputGroup>
          <Label className='flex justify-between'>
            <span>Incident Title</span>
            {errorMsgTitle.length > 0 && <span data-testid='incident-title-error' className='c-error regular'>{errorMsgTitle}</span>}
          </Label>
          <InputText
            focus
            placeholder='Title'
            onChange={(input: string) => {
              setTitle(input)
              setErrorMsgTitle('')
            }}
          />
          <Label className='flex justify-between'>
            <span>{descriptionLabel}</span>
            {errorMsgDescription.length > 0 && <span data-testid='incident-description-error' className='c-error regular'>{errorMsgDescription}</span>}
          </Label>
          <TextArea
            maxLength={500}
            defaultValue={''}
            onChange={(change: any) => {
              setDescription(change.target.value)
              setErrorMsgDescription('')
            }}
          />
          <p>Your incident will be delivered to the DPD support. An agent will get back to you as soon as possible with your registered email.</p>
          <p>The report function will send an issue report registering the following details if available: UserID, ProjectID, ModelID(s).</p>
        </InputGroup>

        <div className='flex justify-end items-end mt3'>
          <Button onClick={preCancel}>
          Cancel
          </Button>
          <Button btnType='primary' className='ml2' onClick={preSubmit}>
            {buttonTitle}
            {loading && <Spinner spinnersize='f6 ml1'/>}
          </Button>
        </div>
        <KeyboardListener
          bindings={[
            KeyBinding(KeyCode.enter, preSubmit)
          ]}
        />
      </div>
      <NotificationBubble
        notification={'Could not send report'}
        error={error}
        onClearOrTimeout={() => {
          setError(null)
        }}
      />
    </>
  )
}

export default ReportForm
