import React from 'react'
// @ts-ignore
import Client from '@inter-ikea-digital/iig-rpd-dpd-packages-storage-api-client'
import type { StorageApiManifest } from '../utils'
const client = Client('/api/storage')

interface Progress {
    file: string
    loaded: number
    percentage: number
    total: number
}

type ReturnType = [
  {
    error: Error | null,
    hashProgress: Progress | null,
    uploadProgress: Progress | null,
    request: XMLHttpRequest | null,
    manifest: StorageApiManifest | null
  },
  (files: FileList | File[]) => void
]

function useStorageApiUpload (): ReturnType {
  const [manifest, setManifest] = React.useState<StorageApiManifest | null>(null)
  const [uploadProgress, setUploadProgress] = React.useState<Progress | null>(null)
  const [hashProgress, setHashProgress] = React.useState<Progress | null>(null)
  const [request, setRequest] = React.useState<XMLHttpRequest | null>(null)
  const [error, setError] = React.useState<Error | null>(null)
  const [files, setFiles] = React.useState<FileList | File[] | null>(null)

  function uploadFiles (files: FileList | File[]): void {
    setFiles(files)
  }

  React.useEffect(() => {
    if (!files) return
    async function getManifestFile (): Promise<void> {
      try {
        const manifestKey = await client.upload(files, {
          onUploadProgress: (progress: Progress) => setUploadProgress(progress),
          onHashProgress: (progress: Progress) => setHashProgress(progress),
          onRequest: (request: XMLHttpRequest) => setRequest(request)
        })
        const response = await fetch(`/api/storage/get/${manifestKey}.json`)
        if (response.status === 200) {
          const json = await response.json() as StorageApiManifest
          setManifest(json)
        } else {
          throw new Error(`Failed with response status ${response.status}`)
        }
      } catch (e) {
        console.error(e)
        setError(e)
      }
    }
    getManifestFile()
  }, [files])

  return [
    {
      error,
      hashProgress,
      uploadProgress,
      request,
      manifest
    },
    uploadFiles
  ]
}

export default useStorageApiUpload
