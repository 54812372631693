import { handleActions, createAction } from 'redux-actions'

import _map from 'lodash/map'
import _get from 'lodash/get'
import _isUndefined from 'lodash/isUndefined'

// Actions
import * as fromMaterials from '../../materials'
import * as fromThreeviewerUi from '../../threeviewer/ui'

// State Selectors
import * as fromThreeviewerSelectors from '../../threeviewer/selectors'
import * as fromTemplatesSelectors from '../../templates/selectors'

import loadTemplateModel, { loadedTemplate } from './load-template-model'
import getTemplatePartScene from './get-template-part-scene'
import getTemplateModelSettings from './get-template-model-settings'

import { updateFloor } from './update-floor'
import { updateWall } from './update-wall'
import { updateFloorBox } from './update-floor-box'
import { updateSkirting } from './update-skirting'
import updateMaterials from './update-materials'
import { DEFAULT_ENVMAP } from '../../../../constants'

const addLights = createAction('templates/defaulTemplate/ADD_LIGHTS')
export const dispose = createAction('templates/defaultTemplate/DISPOSE')

export function updateTemplateMaterial () {
  return (dispatch, getState) => {
    if (getState().templates.activeId !== 'default') return
    dispatch(updateMaterials())
  }
}

export function load (template, modifications) {
  return (dispatch, getState) => {
    const state = getState()
    const viewer = fromThreeviewerSelectors.getViewer(state)
    const THREE = viewer.THREE
    const settings = fromThreeviewerSelectors.getSettings(state)

    const modelSettings = {
      // Floors
      floor: getTemplateModelSettings({ id: 'floor', type: 'floor', modifications, template }),
      floorBox: getTemplateModelSettings({ id: 'floorBox', type: 'floorBox', modifications, template }),
      // Walls
      wallLeft: getTemplateModelSettings({ id: 'wallLeft', type: 'wall', modifications, template }),
      wallRight: getTemplateModelSettings({ id: 'wallRight', type: 'wall', modifications, template }),
      // Skirtings
      skirtingLeft: getTemplateModelSettings({ id: 'skirtingLeft', type: 'skirting', modifications, template }),
      skirtingRight: getTemplateModelSettings({ id: 'skirtingRight', type: 'skirting', modifications, template })
    }

    dispatch(fromMaterials.setEnvMapSettings(_get(settings, 'envMap')))

    return dispatch(fromMaterials.loadEnvMap(DEFAULT_ENVMAP, '.exr'))
      .then(() => {
        const SceneGraph = viewer.SceneGraph

        viewer.postProcessManager.lut.enabled = false

        viewer.renderer.toneMappingExposure = 1
        viewer.renderer.toneMapping = THREE.ACESFilmicToneMapping

        // Primary Light
        var directionalLight = new SceneGraph.SceneGraphDirectionalLight(settings.lights.directionalLight.color, settings.lights.directionalLight.intensity)
        directionalLight.castShadow = !process.env.NO_SHADOW
        directionalLight.position.fromArray(settings.lights.directionalLight.position)

        // NOTE: Shadow settings from settings.shadowMap are ignored

        // A helper light not included in VRAY-scene. Added to give more vibrence to objects in the scene.
        var supportLight = new SceneGraph.SceneGraphDirectionalLight(settings.lights.supportLight.color, settings.lights.supportLight.intensity)
        supportLight.castShadow = false
        supportLight.position.fromArray(settings.lights.supportLight.position)
        supportLight.name = 'supportLight'

        dispatch(addLights([supportLight, directionalLight]))
        supportLight.userData.defaultSceneLight = true
        directionalLight.userData.defaultSceneLight = true

        const cubeCameraPos = new THREE.Vector3().fromArray(settings.template.cubeCameraPosition)
        viewer.setCubeCameraPosition(cubeCameraPos)
        viewer.scene.add(directionalLight)
        viewer.scene.add(supportLight)
      })
      .then(() => {
        const materialIds = _map(modelSettings, (settings) => _get(settings, 'modifications.parts.0.materialId', _get(settings, 'model.material.id')))
        return dispatch(fromMaterials.prefetchPreloadMaterials(materialIds))
      })
      .then(() => Promise.all(_map(modelSettings, (settings) => dispatch(loadTemplateModel(settings)))))
      .then((scenes) => scenes.filter(Boolean))
      .then((scenes) => {
        const defaultSceneComponents = fromThreeviewerSelectors.getComponents(getState())
        scenes.forEach((scene) => {
          if (scene.name === 'floor') {
            scene.visible = true
            return // skip floor
          } else {
            scene.visible = false
          }

          const earlierVisibility = _get(defaultSceneComponents, [scene.name, 'active'])
          const savedVisibility = _get(modelSettings, `${scene.name}.modifications.visible`)

          let visibility = false

          // If the user has switched back to default scene from another look we want to
          // retain the visibility of default scene components from earlier settings
          // which will take precedence over visibility loaded from render
          if (!_isUndefined(earlierVisibility)) {
            visibility = earlierVisibility
          } else if (!_isUndefined(savedVisibility)) {
            visibility = savedVisibility
          }

          dispatch(fromThreeviewerUi.setDefaultSceneComponentVisibility(scene.name, visibility))
        })
        return scenes
      })
  }
}

export const remove = () => (dispatch, getState) => {
  const state = getState()
  const viewer = fromThreeviewerSelectors.getViewer(state)

  const templates = _get(state, 'templates.defaultTemplate.template', {})
  const lights = _get(state, 'templates.defaultTemplate.lights', [])

  Object.keys(templates).forEach((key) => {
    viewer.removeModel(templates[key].scene)
  })

  lights.forEach((light) => {
    viewer.scene.remove(light)
  })

  dispatch(dispose())

  return Promise.resolve()
}

export function adjustWallInScene (force = false) {
  return (dispatch, getState) => {
    const state = getState()
    const localState = fromTemplatesSelectors.getLocalState(state)
    const viewer = fromThreeviewerSelectors.getViewer(state)
    const { objectTracker, THREE, viewerUtils } = fromThreeviewerSelectors.getViewer(state)

    if (!force && !fromTemplatesSelectors.getDefaultTemplateActive(state)) {
      return
    }

    return Promise.resolve()
      .then(() => {
        const bbJson = _get(fromThreeviewerSelectors.getSettings(state), 'template.originalBB')
        const originalBB = new THREE.Box3()
        originalBB.expandByPoint(new THREE.Vector3(bbJson.min.x, -Infinity, bbJson.min.z))
        originalBB.expandByPoint(new THREE.Vector3(bbJson.max.x, Infinity, bbJson.max.z))

        if (!force && localState.activeId !== 'default') return

        // Floors
        const floor = getTemplatePartScene(localState, 'floor')
        const floorBox = getTemplatePartScene(localState, 'floorBox')

        // // Skirting
        const skirtingLeft = getTemplatePartScene(localState, 'skirtingLeft')
        const skirtingRight = getTemplatePartScene(localState, 'skirtingRight')

        // // Walls
        const wallLeft = getTemplatePartScene(localState, 'wallLeft')
        const wallRight = getTemplatePartScene(localState, 'wallRight')

        if (!wallLeft || !wallRight || !floor || !floorBox || !skirtingLeft || !skirtingRight) {
          return
        }

        if (wallLeft) { wallLeft.wallType = 'originalWall' }
        if (wallRight) {
          wallRight.wallType = 'relativeWall'
          wallRight.rotation.y = Math.PI / 2
        }

        const wallInteractions = _get(objectTracker, 'interactions.wall', {})
        const sceneBB = viewerUtils.getBoundingBox(wallInteractions, { filterProperty: 'visible' })
        sceneBB.union(originalBB)

        const sceneCenter = sceneBB.getCenter(new THREE.Vector3())
        const centerPosition = originalBB.containsBox(sceneBB) ? originalBB.getCenter(new THREE.Vector3()) : sceneCenter
        const size = originalBB.containsBox(sceneBB) ? originalBB.getSize(new THREE.Vector3()) : sceneBB.getSize(new THREE.Vector3())

        dispatch(updateFloorBox({ floorBox, size, centerPosition, viewerUtils, originalBB }))
        dispatch(updateWall({ wall: wallLeft, floorBox, size, centerPosition, viewerUtils }))
        dispatch(updateWall({ wall: wallRight, floorBox, size, centerPosition, viewerUtils }))
        dispatch(updateFloor({ floorBox, floor, size, centerPosition, viewerUtils }))
        dispatch(updateSkirting({ skirting: skirtingRight, parent: wallRight, size, centerPosition, viewerUtils }))
        dispatch(updateSkirting({ skirting: skirtingLeft, parent: wallLeft, size, centerPosition, viewerUtils }))
      })
      .then(() => {
        const bb = viewer.viewerUtils.getSceneBoundingBox(viewer.scene, (node) => {
          return (node.geometry && _get(node, 'userData.materialId') !== 'DEFAULT_VRAYPLANE')
        })
        viewer.cameraManager.setCameraClampingObjectFromBB(bb.expandByVector(bb.getSize(new THREE.Vector3()).multiplyScalar(5)))
      })
  }
}

const initialState = {
  template: {},
  lights: []
}

export default handleActions({
  [dispose]: () => initialState,
  [addLights]: (state, { payload }) => ({
    ...state,
    lights: payload
  }),
  [loadedTemplate]: (state, { payload }) => ({
    ...state,
    template: {
      ...state.template,
      [payload.templateType]: {
        type: payload.templateType,
        scene: payload.scene
      }
    }
  })
}, initialState)
