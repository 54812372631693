import Immutable from 'seamless-immutable'
import { createAction, handleActions } from 'redux-actions'
import _keyBy from 'lodash/keyBy'

const error = createAction('likes/ERROR')
export const receive = createAction('likes/RECEIVE')

export const toggleLike = (payload) => (dispatch, getState, { api }) => {
  return api.likes.toggleLike({
    ...payload,
    userId: getState().users.currentUserId
  })
    .then((json) => dispatch(receive(json)))
    .catch((err) => dispatch(error(err)))
}

const initialState = Immutable({
  error: null,
  entries: {}
})

export default handleActions({
  [receive]: (state, { payload }) => state.merge({
    error: null,
    entries: _keyBy([].concat(payload), 'id')
  }, { deep: true }),
  [error]: (state, { payload }) => state.merge({
    error: payload
  })
}, initialState)
