import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import { bindActionCreators } from 'redux'
import autoBind from 'react-autobind'

import _get from 'lodash/get'
import _filter from 'lodash/filter'

import navigate from '../../utils/navigate'
import colors from '../../../css/colors'

import * as actions from '../../stores/ducks/renders'
import * as fromProjectsSelectors from '../../stores/ducks/projects/selectors'

import Modal from '../common/modal'
import Button from '../common/button'
import Square from '../common/Square'
import ButtonIcon from '../common/button-icon'
import Pending from '../common/pending'

import IconGallery from '../common/icons/icon-gallery'
import IconPlusLarge from '../common/icons/icon-plus-large'
import IconMultipleImages from '../common/icons/icon-multiple-images'

import GalleryModal from '../gallery/modal'

const styles = {
  button: {
    width: '46%'
  },
  image: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: '90%'
  }
}

class VersionsList extends PureComponent {
  constructor (...args) {
    super(...args)

    this.state = {
      isModalOpen: false,
      isGalleryOpen: false,
      version: null
    }

    autoBind(this)
  }

  handleCloseModal () {
    this.setState({
      isModalOpen: false,
      version: null
    })
  }

  handleOpenModal (version) {
    this.setState({
      isModalOpen: true,
      version
    })
  }

  handleViewRender () {
    this.setState({
      isModalOpen: false,
      isGalleryOpen: true
    })
  }

  handleVisualizeRender (version) {
    navigate(`/visualize/${this.props.projectId}/image-package/${version.id}`)
  }

  render () {
    const {
      items,
      onVisualizeParent,
      projectId,
      designCardTitle,
      cancel
    } = this.props

    const {
      isGalleryOpen,
      isModalOpen,
      version
    } = this.state

    return (
      <div className='bg-gray-lighter p2'>
        {items.length > 0 && (<h3 className='f6 m0 mb2'>Images</h3>)}
        <div className='flex flex-wrap width-100'>
          {items.length === 0 && (
            <div className='width-100 flex flex-column justify-center items-center mt2 mb3'>
              <p className='mt1'>No images yet, create one in the Visualizer View.</p>
              <Button
                btnType='primary'
                uppercase
                onClick={onVisualizeParent}
                className='mt1'
              >
                Visualize
              </Button>
            </div>
          )}
          {items.map((item) => (
            <div key={item.id} className='mb1 mr1' style={{ width: 140 }}>
              {item.isRender && (
                <Square
                  onClick={() => this.handleOpenModal(item)}
                  backgroundImage={item.imageSrc}
                  className='relative pointer'
                  hoverable
                  pad={0}
                  innerStyle={styles.image}
                >
                  {item.isVersioned &&
                    <IconMultipleImages
                      size={18}
                      color={colors.secondary}
                      className='absolute top-0 right-0 m1'
                    />
                  }
                </Square>
              )}
              {!item.isRender && (
                <Pending
                  onClick={() => this.handleOpenModal(item.latestCompletedRender)}
                  deadlineStatus={item.render.deadlineStatus}
                  renderStatus={item.render.renderStatus}
                  renderPercentage={item.render.renderPercentage}
                  status={item.render.status}
                  backgroundImage={_get(item, 'latestCompletedRender.imageSrc')}
                  multipleRenders={_filter(item.versions || [], version => !version.isRender).length > 1}
                  onGoToFailedRender={(e) => {
                    e.stopPropagation()
                    this.handleVisualizeRender(item)
                  }}
                  onCancel={() => cancel(item.render.id)}
                  isVersioned={item.isVersioned}
                />
              )}
              <p className='mb1 f8 center' style={{ wordBreak: 'break-all' }}>{item.title}</p>
            </div>
          ))}

          <Modal
            isOpen={isModalOpen}
            onRequestClose={this.handleCloseModal}
            width={600}
          >
            <div>
              <div className='flex width-100 justify-between'>
                <ButtonIcon
                  onClick={this.handleViewRender}
                  style={styles.button}
                  text='Review image versions'
                  icon={<IconGallery size={80} />}
                  padding
                />
                <ButtonIcon
                  onClick={() => this.handleVisualizeRender(version)}
                  style={styles.button}
                  text='Create new version'
                  icon={<IconPlusLarge size={80} />}
                  padding
                />
              </div>
            </div>
          </Modal>

          {isGalleryOpen && (
            <GalleryModal
              activeId={version.id}
              projectId={projectId}
              designCardTitle={designCardTitle}
              parentCombinationId={this.props.parentCombination}
              renderId={version.render.id}
              onBack={() => this.setState({ isGalleryOpen: false })}
              onVisualize={onVisualizeParent}
              backToText='Return to Project'
            />
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = createSelector(
  fromProjectsSelectors.getCurrentId,
  (projectId) => {
    return {
      projectId
    }
  }
)

const mapDispatchToProps = (dispatch) => bindActionCreators({
  retry: actions.retry,
  cancel: actions.remove
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VersionsList)
