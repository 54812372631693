import React from 'react'

const IconSquareProportions = props => (
  <svg height={props.size} viewBox="0 0 16 24" {...props}>
    <path d="M0.923076923,4.92307692 L0.923076923,19.0769231 L15.0769231,19.0769231 L15.0769231,4.92307692 L0.923076923,4.92307692 Z M0.615384615,4 L15.3846154,4 C15.7244829,4 16,4.27551708 16,4.61538462 L16,19.3846154 C16,19.7244829 15.7244829,20 15.3846154,20 L0.615384615,20 C0.275517077,20 0,19.7244829 0,19.3846154 L4.4408921e-16,4.61538462 C4.4408921e-16,4.27551708 0.275517077,4 0.615384615,4 Z" id="square"
      fill={props.color}
    />
  </svg>
)

IconSquareProportions.defaultProps = {
  color: 'currentcolor',
  size: '1em'
}

export default IconSquareProportions
