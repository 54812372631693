import { SceneGraphMesh } from '../scenegraph/SceneGraph'

export default class CloneTool {
  constructor (viewerUtils) {
    this.viewerUtils = viewerUtils
    this._transforms = new Map()
    this._clones = []
  }

  dispose () {
    this.clear()
  }

  clear () {
    this._transforms.delete()
    this._clones = []
  }

  cloneNode (node) {
    const clone = node.clone()
    clone.userData = node.userData
    clone.userData.sourceUuid = node.uuid
    clone.traverse(child => {
      if (child.material) {
        const materialId = child.material.materialId
        child.material = child.material.clone()
        child.material.materialId = materialId
      }
    })
    return clone
  }

  getBoundingBox (nodes) {
    const meshes = []
    nodes.forEach(node => {
      node.traverse(node => {
        if (node instanceof SceneGraphMesh) {
          meshes.push(node)
        }
      })
    })
    return this.viewerUtils.getBoundingBox(meshes)
  }

  clone (sources, params = {}) {
    this._transforms.delete()
    const { axis, distance } = params

    let originBBox = null

    if (axis && distance) {
      originBBox = this.getBoundingBox(sources)
    }

    const clones = []

    sources.forEach(source => {
      const originPosition = source.position
      const originRotation = source.rotation
      const clone = this.cloneNode(source)
      clones.push(clone)

      if (originBBox) {
        var rootModels = []

        clone.traverse(child => {
          if (child.userData.isModelRoot && !rootModels.includes(child)) {
            rootModels.push(child)
          }
        })

        const translation = originBBox.max[axis] - originBBox.min[axis] + distance
        rootModels.forEach(model => {
          model.position[axis] += translation
        })

        this._transforms.set(clone, {
          newPosition: clone.position,
          newRotation: clone.rotation,
          originPosition,
          originRotation
        })
      } else {
        this._transforms.set(clone, {
          originPosition,
          originRotation
        })
      }
    })

    this._clones = clones
    return clones
  }

  duplicate () {
    const sources = this._clones
    const clones = []
    sources.forEach(source => {
      const transform = this._transforms.get(source)
      if (!transform) return

      const clone = this.cloneNode(source)

      const {
        newPosition,
        newRotation,
        originPosition,
        originRotation
      } = transform

      clone.position.add(newPosition.clone().sub(originPosition))
      clone.rotation.x += ((newRotation.x - originRotation.x))
      clone.rotation.y += ((newRotation.y - originRotation.y))
      clone.rotation.z += ((newRotation.z - originRotation.z))

      this._transforms.set(clone, {
        originPosition: source.position,
        originRotation: source.rotation,
        newPosition: clone.position,
        newRotation: clone.rotation
      })

      this._transforms.delete(source)
      clones.push(clone)
    })

    this._clones = clones
    return [sources, clones]
  }

  saveTransform () {
    this._clones.forEach(clone => {
      const transform = this._transforms.get(clone) || {
        originPosition: clone.position,
        originRotation: clone.rotation
      }

      this._transforms.set(clone, {
        ...transform,
        newPosition: clone.position,
        newRotation: clone.rotation
      })
    })
  }
}
