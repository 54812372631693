import fetch from '../../../utils/fetch'

export const get = (id) => (
  fetch(`/api/uploads/${id}`)
    .then((res) => res.json())
)

export const remove = (id) => (
  fetch('/api/uploads/remove', {
    method: 'POST',
    body: JSON.stringify({ id })
  })
    .then((res) => res.json())
)

export const update = (payload) => (
  fetch('/api/uploads/update', {
    method: 'POST',
    body: JSON.stringify(payload)
  })
    .then((res) => (res.json()))
)

export const retryConvert = (id) => (
  fetch('/api/uploads/retryConvert', {
    method: 'POST',
    body: JSON.stringify({ id })
  })
    .then((res) => (res.json()))
)

export const create = (payload) => (
  fetch('/api/uploads/create', {
    method: 'POST',
    body: JSON.stringify(payload)
  })
    .then((res) => (res.json()))
)
