import * as THREE from 'three'

let id = 0
export default class AnimationManager {
  constructor () {
    this._animations = {}
  }

  didAnimate () {
    return Object.keys(this._animations).length > 0
  }

  addAnimation (animation) {
    if (animation instanceof Animation) {
      this._animations[animation.id] = animation
      return animation
    }

    throw new TypeError('Must be an instanceof Animation.')
  }

  removeAnimation (id) {
    delete this._animations[id]
  }

  removeAnimations () {
    this._animations = {}
  }

  update (dt) {
    if (dt === undefined) {
      console.warn('Delta time is ' + dt)
    }

    Object.values(this._animations).forEach((animation) => {
      if (animation.isDone()) {
        this.removeAnimation(animation.id)
      } else {
        animation.update(dt)
      }
    })
  }
}

export class Animation {
  constructor (target) {
    this.id = id++
    this._lerpNumber = false
    this._target = target
    this._endPos = null
    this._time = null
    this._endIfFunc = null
    this._onUpdateFunc = null
    this._onEndFunc = null
    this._currentTime = 0
    this._delay = 0
  }

  delay (time) {
    this._delay = time
    return this
  }

  lerpTo (endPos, time) {
    this._endPos = endPos
    this._time = time

    if (!isNaN(this._target) && !isNaN(this._endPos)) {
      this._target = new THREE.Vector2(this._target, 0)
      this._endPos = new THREE.Vector2(this._endPos, 0)
      this._lerpNumber = true
    }

    return this
  }

  endIf (func) {
    this._endIfFunc = func
    return this
  }

  onUpdate (func) {
    this._onUpdateFunc = func
    return this
  }

  isDone () {
    let isDone = false
    if (this._lerpNumber) {
      isDone = this._endIfFunc(this._target.x, this._endPos.x)
    } else {
      isDone = this._endIfFunc(this._target, this._endPos)
    }

    if (this._onEndFunc && isDone) {
      this._onEndFunc()
    }
    return isDone
  }

  onEnd (func) {
    this._onEndFunc = func
    return this
  }

  update (deltaTime) {
    this._currentTime += deltaTime

    if (this._delay !== 0) {
      if (this._delay - this._currentTime < 0.02) {
        this._delay = 0
        this._currentTime = 0
      } else {
        return
      }
    }

    const interpolationFactor = this._currentTime / this._time

    if (this._target.lerp) {
      this._target.lerp(this._endPos, interpolationFactor)
    } else if (this._target.slerp) {
      this._target.slerp(this._endPos, interpolationFactor)
    } else if (this._target.position) {
      this._target.position.lerp(this._endPos, interpolationFactor)
    }

    if (this._onUpdateFunc) {
      if (this._lerpNumber) {
        this._onUpdateFunc(this._target.x, this._endPos.x, interpolationFactor)
      } else {
        this._onUpdateFunc(this._target, this._endPos, interpolationFactor)
      }
    }
  }
}
