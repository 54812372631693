import React from 'react'

const IconPlusLarge = props => (
  <svg width={props.size} height={props.size} viewBox='0 0 94 94' {...props}>
    <path
      fill={props.color}
      d='M37.6,37.6 L0.454545455,37.6 L0.454545455,37.6 C0.203506932,37.6 -3.07433523e-17,37.8035069 0,38.0545455 L-1.22124533e-15,55.9454545 C-1.19050197e-15,56.1964931 0.203506932,56.4 0.454545455,56.4 L0.454545455,56.4 L37.6,56.4 L37.6,93.5454545 C37.6,93.7964931 37.8035069,94 38.0545455,94 L55.9454545,94 C56.1964931,94 56.4,93.7964931 56.4,93.5454545 L56.4,56.4 L93.5454545,56.4 C93.7964931,56.4 94,56.1964931 94,55.9454545 L94,55.9454545 L94,38.0545455 C94,37.8035069 93.7964931,37.6 93.5454545,37.6 L56.4,37.6 L56.4,0.454545455 C56.4,0.203506932 56.1964931,-1.0162618e-16 55.9454545,-5.55111512e-17 L55.9454545,0 L38.0545455,0 L38.0545455,-1.27675648e-15 C37.8035069,-1.23064145e-15 37.6,0.203506932 37.6,0.454545455 L37.6,37.6 Z'
    />
  </svg>
)

IconPlusLarge.defaultProps = {
  color: 'currentcolor',
  size: '1em'
}

export default IconPlusLarge
