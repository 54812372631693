import { createAction } from 'redux-actions'

import _get from 'lodash/get'
import _merge from 'lodash/merge'

// Actions
import * as fromMaterials from '../../materials'
import * as fromThreeviewerFiles from '../../threeviewer/files'

// State Selectors
import * as fromThreeviewerSelectors from '../../threeviewer/selectors'
import * as fromTemplatesSelectors from '../../templates/selectors'

export const loadedTemplate = createAction('templates/defaulTemplate/LOADED')

const shouldAddTemplateToScene = (state, url) => {
  return !_get(state, ['added', url, 'added'])
}

export default function loadTemplateModel ({ model, modifications }) {
  return (dispatch, getState) => {
    const state = getState()

    const viewer = fromThreeviewerSelectors.getViewer(state)
    const THREE = viewer.THREE

    const ignoreRaycast = _get(model, 'modelInteractions.ignoreRaycast', false)
    const addToPicker = _get(model, 'modelInteractions.addToPicker', true)
    const materialId = _get(modifications, 'parts.0.materialId', model.material.id)
    dispatch(fromThreeviewerFiles.load('default-template', model.url))
    const url = model.url

    return Promise.resolve()
      .then(() => {
        dispatch(fromThreeviewerFiles.loadFile({
          type: 'default-template',
          uris: [url]
        }))
      })
      .then(() => viewer.loader.load({ url }))
      .then((model) => {
        dispatch(fromThreeviewerFiles.fileProgress({
          finalize: false,
          progress: 100,
          file: url,
          type: 'default-template'
        }))
        return model
      })
      .then((model) => {
        var modelScene = model.scene
        var materialChildren = {}

        modelScene.traverse((child) => {
          child.userData.snappingType = 'EXACT'
          child.userData.noSourceSnap = true
          child.userData.vrNoSourceSnap = true

          child.userData.isTemplate = true
          child.userData.modelType = 'template'
          child.userData.materialId = materialId

          if (child instanceof viewer.SceneGraph.SceneGraphMesh) {
            child.receiveShadow = true
            materialChildren[child.uuid] = child
          }
        })

        return [modelScene, materialChildren]
      })
      .then(([modelScene, materialChildren]) => Promise.all([
        modelScene,
        dispatch(fromMaterials.setMaterial(materialId, materialChildren, false))
      ]))
      .then(([modelScene]) => {
        modelScene.visible = false

        if (shouldAddTemplateToScene(fromTemplatesSelectors.getDefaultTemplate(state), model.url)) {
          const params = {
            setToOrigo: true,
            addToPicker: true,
            addToTransformGizmo: false,
            addToNodeList: true,
            localReflections: true,
            interactions: {
              snapTargets: { recursive: true },
              vrSnapTargets: { recursive: true }
            }
          }

          if (model.type === 'floor') {
            params.interactions = {
              ...params.interactions,
              floor: { recursive: true }
            }
          }

          modelScene.name = model.id
          modelScene.userData.addParams = params

          viewer.addModel(modelScene, _merge(
            params,
            model.modelInteractions,
            {
              addToPicker,
              ignoreRaycast
            }
          ))
        }

        modelScene.userData = Object.assign(modelScene.userData || {}, model.userData, {
          originMatrix: modelScene.matrix.clone(),
          originMatrixWorld: modelScene.matrixWorld.clone(),
          originalSize: viewer.viewerUtils.getBoundingBox([modelScene]).getSize(new THREE.Vector3()),
          materialId
        })

        dispatch(loadedTemplate({
          templateType: model.id,
          scene: modelScene
        }))

        dispatch(fromThreeviewerFiles.loaded('default-template', model.url))

        return modelScene
      })
      .catch((err) => {
        console.log(err)
        const error = err.target
          ? { type: 'progress', status: err.target.status, message: err.target.statusText }
          : { message: err.message }

        dispatch(fromThreeviewerFiles.fileError({
          file: url,
          error
        }))
      })
  }
}
