import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import fetch from '../../../../utils/fetch'

// common
import {
  PanelSection,
  PanelSectionHeader
} from '../../../common/panels/panel-section.jsx'
import { ModalButton } from '../../../common/modal.jsx'
import DesignLoader from '../../design-loader.jsx'

// redux
import { getSelection } from '../../../../stores/ducks/selection/selectors'
import { toggleSelection } from '../../../../stores/ducks/selection'

// icons
import IconArrowRight from '../../../common/icons/icon-arrow-right'
import IconArrowDown from '../../../common/icons/icon-arrow-down'
import IconAppearance from '../../../common/icons/icon-material'
import { MdTexture as IconMaterial } from 'react-icons/md'

// internal
import DemocraticLoader from './components/CenteredLoader'
import MissingInfoModal from './components/AnalysisInfoModal'
import { selectNodesWithChildren, selectAppearancesInScene } from './selectNodesWithChildren'
import getAnalyseData from './getAnalyseData'
import EmptyStateDemocraticDesign from './components/EmptyStateDemocraticDesign'

// types
import type { NodesWithChildren } from './selectNodesWithChildren'
import type { SceneGraphNode3d as ISceneGraphNode3d } from '../../../../../../go3dthree/types/SceneGraph'
import type { ProductAnalyze, MaterialData } from './productAnalysisTypes'

const Row = styled.div<{ bg: string, bb: string }>`
  display: flex;
  align-items: center;
  background-color: ${(props) => props.bg};
  cursor: pointer;
  padding: 4px;
  height: 33px;
  border-bottom: ${(props) => props.bb};
`
const ToggleClickArea = styled.div`
  display: flex;
  align-items: center;
  height: 33px;
  width: 80%;
`
const ArrowContainer = styled.div`
  width: 22px;
  height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const CostContainer = styled.div`
  padding: 10px;
  padding-left: 14px;
  & > * {
    margin-top: 0.5rem; 
} 
`

const InfoText = styled.div<{ color: string }>`
  font-size: 10px;
  color: ${(props) => props.color};
`

const ExcelButton = styled.button`
  font-weight: bold;
  font-size: 12px;
  text-align: center;
  transition: transform 100ms;
  cursor: pointer;
  &:hover {
    transform: translateY(-2px);
  }
`

function ProductConceptAnalysisPanel () {
  const [expandedRows, setExpandedRows] = React.useState<string[]>([])
  const [isTargetExpanded, setIsTargetExpanded] = React.useState<string[]>([])
  const nodesWithChildren: NodesWithChildren[] = useSelector(selectNodesWithChildren)
  const allAppearancesInScene: any = useSelector(selectAppearancesInScene)
  const selectionData: string[] = useSelector(getSelection)
  const [productConceptList, setProductConceptList] = useState<ProductAnalyze[]>([])
  const [selectedData, setSelectedData] = useState<ProductAnalyze | null>(null)
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false)

  const dispatch = useDispatch()

  // Later we might only fetch the materialData thats used in the models
  const [materialData, setMaterialData] = useState<undefined | MaterialData[]>(undefined)
  const [loader, setLoader] = useState<boolean>(true)

  const productAnalysis = async () => {
    if (materialData) {
      setLoader(true)
      const analysation = await getAnalyseData(materialData, nodesWithChildren, allAppearancesInScene)
      setProductConceptList(analysation)
      setLoader(false)
    }
  }

  useEffect(() => {
    async function getCarriers () {
      const response = await fetch('/api/materials/carriers')
      if (response.status === 200) {
        const carrierDataResponse = await response.json()
        setMaterialData(carrierDataResponse)
      } else {
        console.error(response)
      }
    }
    getCarriers()
  }, [])

  useEffect(() => {
    productAnalysis()
  }, [nodesWithChildren, materialData])

  /**
   * Keeps track of which drop down menus are expanded or not.
   */
  function handleExpandRow (id: string) {
    if (expandedRows.includes(id)) {
      setExpandedRows(expandedRows.filter((item: string) => item !== id))
      setIsTargetExpanded(isTargetExpanded.filter((item: string) => item !== id))
    } else {
      setExpandedRows([...expandedRows, id])
    }
  }

  /**
   * Handles selection state of geometry inside the visualizer
   */
  function handleSelection (nodeKey: string, children: ISceneGraphNode3d[]) {
    const uuidArray: string[] = []
    Object.values(children).map((data: ISceneGraphNode3d) => {
      uuidArray.push(data.uuid)
    })
    const childPartFound = uuidArray.some((r: string) => selectionData.indexOf(r) >= 0)

    return selectionData.includes(nodeKey) || childPartFound
  }

  /**
   * Handles the modals state and data properties
   */
  function handleModal (data: ProductAnalyze) {
    setSelectedData(data)
    setIsModalOpen(!isModalOpen)
  }

  // TODO add more intervalls for decimals when given from Fredrik
  function handleMathRound (data: number) {
    if (data < 0) return 'Calculation not possible for this model'
    if (data > 0 && data < 5) return data.toFixed(2)
    else return Math.round(data)
  }

  function handleCalculateXls (list: any) {
    // TODO: Move this to stores/api/spreadsheet.js or stores/ducks/spreadsheet/api.js
    // TODO: This exact code is duplicated there (should be converted to helper function)
    // TODO: And add dispatch in src/web/src/assets/js/stores/ducks/spreadsheet/index.js
    fetch('/api/spreadsheet/part-cost/download-data', {
      method: 'POST',
      body: JSON.stringify(list)
    })
      .then(res => Promise.all([
        res.blob(),
        res.headers.get('content-disposition')?.replace('filename=', '')
      ]))
      .catch(() => console.error('Unable to convert response to blob'))
      .then(([blob, filename]: any) => {
        const url = URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.download = filename
        link.href = url
        link.click()
      })
      .catch(() => console.error('unable to download file'))
  }

  return (
    <PanelSection className='overflow-auto'>
      <PanelSectionHeader title='Product Concept Analysis' />
      { loader && <DemocraticLoader />}
      { !loader && productConceptList.length === 0
        ? <ModalButton
          modalContent={(closeModal: () => void) => (
            <DesignLoader
              onClose={closeModal}
              type='load'
            />
          )}
          button={(openModal: () => void) => (
            <EmptyStateDemocraticDesign openModal={openModal} />
          )}
        />
        : <>
          {Object.values(productConceptList).map((data: ProductAnalyze) => {
            const euCost = (data?.materialCost.EU + data?.appearanceCost.EU + data?.partsCost)
            const asiaCost = (data?.materialCost.AP + data?.appearanceCost.AP + data?.partsCost)
            const co2 = data?.co2
            const predictedWeight = data?.weight
            return (
              <div key={data.nodeKey}>
                <Row
                  bg='rgba(0,0,0,0.08)'
                  bb='1px solid lightgray'
                  style={handleSelection(data.nodeKey, data.children) ? { backgroundColor: '#FEF7D6' } : {}}
                >
                  <ArrowContainer onClick={() => handleExpandRow(data.nodeKey)}>
                    {expandedRows.includes(data.nodeKey) ? <IconArrowDown size={11} /> : <IconArrowRight size={11} />}
                  </ArrowContainer>
                  <ToggleClickArea onClick={() => dispatch(toggleSelection(data.nodeKey))}>
                    {data?.showAppearnaceIcon && <IconAppearance style={{ marginLeft: 4 }} />}
                    {data?.showMaterialIcon && <IconMaterial size='20' style={{ marginLeft: 4 }} />}
                    <p className='f7 truncate' style={{ userSelect: 'none', marginLeft: 4, width: '80%' }} >{data.name}</p>
                  </ToggleClickArea>
                </Row>
                {expandedRows.includes(data.nodeKey) &&
                  <CostContainer>
                    <div className='f7'><b>Material cost: </b>{`EU: ${handleMathRound(euCost)}€ / AP: ${handleMathRound(asiaCost)} €`}</div>
                    <div className='f7'><b>Carbon footprint: </b>{`${handleMathRound(co2)} kg CO2 eq`}</div>
                    <div className='f7'><b>Weight: </b>{`${handleMathRound(predictedWeight)} kg`}</div>
                    <div className='flex justify-between'>
                      <div>
                        {data.isMissingData
                          ? <InfoText style={{ cursor: 'pointer' }} color='red' onClick={() => handleModal(data)}>Missing information, <u>additional information</u></InfoText>
                          : <InfoText style={{ cursor: 'pointer' }} color='green' onClick={() => handleModal(data)}><u>Additional information</u></InfoText>
                        }
                      </div>
                      <ExcelButton
                        onClick={() => handleCalculateXls(data.calculationList)}
                      >
                        .xls
                      </ExcelButton>
                    </div>
                  </CostContainer>
                }
              </div>
            )
          })}
          {selectedData && <MissingInfoModal
            data={selectedData}
            isOpen={isModalOpen}
            onRequestClose={() => setIsModalOpen(false)}
          />}
        </>
      }
    </PanelSection>
  )
}

export default ProductConceptAnalysisPanel
