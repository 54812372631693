import React from 'react'

import Modal from '../../common/modal'
import moment from 'moment'
import Button from '../../common/button'
import { MdVisibility as IconVisibility } from 'react-icons/md'

export default function NavigatePostsModal (props) {
  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      width={'50vw'}
    >
      <div
        className='flex flex-column justify-between'
        style={{ height: '70vh' }}
      >
        <h2 className='mt0 mb1 truncate pr3'>Previous notices</h2>

        <div className='overflow-y-auto custom-scrollbar mb2 flex-1'>
          <div className='width-100'>
            {props.articles.map((article) => (
              <div
                key={article.id}
                className='flex justify-between pb1 mb1 border-bottom bc-gray-light'
              >
                <div className='f6'>
                  <div className='bold'>
                    {article.title}
                  </div>
                  <time className={'truncate f7 c-gray-accessible'}>{moment(article.updatedAt).fromNow()}</time>
                </div>
                <Button
                  className='ml2'
                  onClick={(event) => {
                    event.preventDefault()
                    props.onRequestShowNotice(article)
                  }}>
                  <IconVisibility size='16' />
                </Button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Modal>
  )
}
