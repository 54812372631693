const { cloneObjectsPropertyState } = require('./utils')
const noop = () => {}

const createSetPositionAndRotationCommand = ({
  objects,
  gizmo,
  newRotationValuesMap,
  newPositionValuesMap,
  newTrackedRotations,
  optionalOldRotationValuesMap,
  optionalOldPositionValuesMap,
  oldTrackedRotations,
  afterExecute = noop,
  afterUndo = noop
}) => {
  let oldRotationValuesMap
  let oldPositionValuesMap

  if (objects.length) {
    oldRotationValuesMap = cloneObjectsPropertyState(objects, 'rotation')
    oldPositionValuesMap = cloneObjectsPropertyState(objects, 'position')
  }

  if (Object.keys(optionalOldRotationValuesMap).length) {
    oldRotationValuesMap = optionalOldRotationValuesMap
  }
  if (Object.keys(optionalOldPositionValuesMap).length) {
    oldPositionValuesMap = optionalOldPositionValuesMap
  }

  return {
    undo () {
      objects.forEach((object) => {
        object.rotation.copy(oldRotationValuesMap[object.uuid])
        object.position.copy(oldPositionValuesMap[object.uuid])
      })

      oldTrackedRotations && gizmo.setTrackedRotations(oldTrackedRotations)

      afterUndo()
    },

    execute () {
      objects.forEach((object) => {
        object.rotation.copy(newRotationValuesMap[object.uuid])
        object.position.copy(newPositionValuesMap[object.uuid])
      })

      newTrackedRotations && gizmo.setTrackedRotations(newTrackedRotations)

      afterExecute()
    }
  }
}

module.exports = createSetPositionAndRotationCommand
