import React from 'react'
import PropTypes from 'prop-types'
import cs from 'classnames'
import { v4 as uuid } from 'uuid'
import css from './index.css'
import Label from './label'

function InputRadio (props) {
  const {
    name,
    label,
    className,
    value,
    selected,
    onChange,
    disabled,
    onClick
  } = props

  const id = React.useRef(uuid())

  return (
    <div
      onClick={onClick}
      className={cs({
        'pointer-disabled': disabled
      })}
    >
      <input
        id={id.current}
        name={name}
        value={value}
        type='radio'
        className={`${css.inputRadio} ${className}`}
        onChange={onChange}
        defaultChecked={selected}
      />
      <Label
        htmlFor={id.current}
        className={css.label}
      >
        <span className={`
          ${css[disabled ? 'disabledRadioButton' : 'radioButton']}
          ${disabled ? 'bg-gray-light' : ''}
          ${selected ? 'bg-secondary' : ''}
          bc-gray-dark
        `} />
        <span> {label}</span>
      </Label>
    </div>
  )
}

InputRadio.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.any,
  selected: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  onClick: PropTypes.func
}

InputRadio.defaultProps = {
  className: '',
  label: null,
  onChange () {},
  onClick () {}
}

export default InputRadio
