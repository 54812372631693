import React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import * as fromUsers from '../../stores/ducks/users'
import Button from '../common/button'
import Grid from '../common/grid/grid'
import StandardLayout from '../layout/standard-layout'

const Container = styled.div`
  max-width: 52rem;
  margin: 0 auto;
  p {
    letter-spacing: 0.01em;
    line-height: 1.4em;
  }
`

type Props = {
  showConfirmButton?: boolean
}

const title = 'Privacy Statement'

const GDPR = ({ showConfirmButton = true }: Props) => {
  const dispatch = useDispatch()

  return (
    <StandardLayout breadcrumbs={[{ title, id: 0 }]}>
      <Container>
        <h1>IKEA Privacy Statement for Business Partners </h1>
        <div>
          <p className="bold">Thanks for sharing! </p>
          <p>The IKEA vision is to create a better everyday life for the many people. That’s a big job, and one that we at</p>
          <span className="bold">IKEA of Sweden AB</span> take seriously.
          <p>Who are the many people? You are! Whether you’re an IKEA customer, co-worker, or business partner, the IKEA vision is all about you. So naturally we want to get to know you better.</p>
          <p>One way we do that is by collecting your personal data. If you’re reading this, you’re probably curious what data we collect and how we use it. Read on to find out.</p>
          <p>Without your data, our job would be a lot harder. So, thanks for sharing! We hope to make your everyday life better in return.</p>

          <h2>1) How We Respect Your Privacy?</h2>
          <p>Our approach at <span className="bold">IKEA of Sweden AB</span> is to be as open and transparent as possible about how we collect and use personal data.</p>
          <p>
            <span className="bold">First</span>, we let you know exactly what data we collect and why. <span className="bold">Second</span>, we let you know what we do with your data.
            Who do we share it with? What do they do with it? You can read about that in section 2 below. <span className="bold">Third</span>, we protect your data and let you know how long we keep it. Read about that in sections 4 and 5. <span className="bold">Finally</span>, we let you know your rights when it comes to your personal data, including who to contact if you have questions or requests.
            Read about that in sections 6 and 7.
          </p>

          <h2>2) What Data We Collect and Why?</h2>
          <p>
            We want to make your IKEA experience great! To do that we collect several types of data about you.
            This can be anything from your name and address to your bank account number depending on the situation.{' '}
            <a href="img/privacy-statement.pdf"
              rel="noopener noreferrer"
              target="_blank"
              className="bold underline black">Click here</a>
            {' '}to read about some of the types of personal data we may collect as well as the purpose of processing such data and the legal base to do so.
          </p>

          <h2>3) Who We Share Your Data With?</h2>
          <p>
            We do not sell or trade your data to third parties, but we share with others who can help us achieve the IKEA vision.
            That can be other IKEA companies, service providers or business partners.
            We’re all committed to keep your data safe and secure.
          </p>
          <p>We may share personal data with the following types of third parties:</p>
          <Grid gridGap={0} columns={['30%', '1fr']}>
            <p><span className="underline">Within IKEA</span>:</p>
            <p>
              IKEA is one brand but many companies.
              Each plays a special role to achieve the IKEA vision.
              We may share your data with other companies operating under the IKEA brand.
            </p>
            <p><span className="underline">Service providers</span>:</p>
            <p>
              We may also share your data with authorised IKEA service providers.
              Usually these are companies that store data or provide IT services.
            </p>
            <p><span className="underline">Business partners</span>:</p>
            <p>
              We often work together with other companies that can support us achieve the IKEA vision.
              These companies might for example support us to learn more about how people live or how to improve the IKEA product Range.
              Other companies might support us with media, marketing, and social media.
            </p>
          </Grid>

          <h2>4) How We keep Your Personal Data Safe?</h2>
          <p>
            We protect your personal data from all kinds of threats: destruction, loss, alteration, unauthorized disclosure, or access.
            We do this in a variety of ways depending on where we store and use it.
          </p>
          <h3>International Transfers</h3>
          <p>
            We are a part of a global group of companies, and we have service providers and business partners in many parts of the world.
            In some cases, we might have to transfer your data to one of these companies located outside your own country or even outside the European Economic Area.
            We do our best to keep your data safe in these cases. But technology can make it challenge.
            When local laws impose recruitments on transfer of data outside of the country, we will make sure to comply with them.
            For transfers of your data to countries outside the European Economic Area we rely on so-called adequacy decisions from the EU.
            This means that the EU believes a country’s own laws or agreements provide adequate protection for your personal data.
            However, some countries don’t have an adequacy decision and then we often sign EU Standard Contract Clauses with the recipient.
            These are standardized legal texts provided by the EU Commission that expands the protection given by EU law to your data to our partners in territories outside of the EU. You may find them here:{' '}
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc/standard-contractual-clauses-international-transfers_en">
              Standard Contractual Clauses
            </a>.
          </p>

          <h2>5) How Long We Keep Your Personal Data?</h2>
          <p>We delete the data when it is no longer necessary to us unless: </p>
          <ol>
            <li className="my2">Legislation requires us to keep the data for a longer time, or</li>
            <li className="my2">
              If other special circumstances apply. Special circumstances include when the original purpose is still valid for an extended time.
              Then we process the data until the original purpose of why we collected the data have been fulfilled. Examples of special circumstances are:
              <ul>
                <li className="py1">if you have created documents or other works they may be kept indefinitely depending on their purpose.
                If they concern an IKEA product, the document or works may be relevant for us to keep for as long as the product is a part of the IKEA range,
                </li>
                <li className="py1">if you for example have participated in an audio or video recording,
                this material will be kept if it is still relevant for education purposes,
                </li>
                <li className="py1">if you, for example, have participated in contract negotiations via emails,
                the emails may be relevant to keep for as long as the agreement is in force, and even longer depending on the nature of the agreement.
                </li>
              </ul>
            </li>
          </ol>

          <h2>6) Which are Your Privacy Rights? </h2>
          <p>
            You have many rights regarding your personal data. Read more about them below.
            If you would like to exercise them or learn more, feel free to contact us! You can find contact details at the end of this privacy statement.
            Please note that some of the rights may not be applicable to your situation.
          </p>
          <div className="py1">
            <p className="underline">Right of access </p>
            <p>
              You have the right to know what personal data we process and why. That’s why we inform you in advance about our processing activities via this privacy statement.
              If you have any questions, or would you like to learn more about what data we process from you, you are always welcome to contact us, and we will provide you with further information.
            </p>
          </div>
          <div className="py1">
            <p className="underline">Right to rectification</p>
            <p>If you believe we store wrong data about you, such as your name or address, you can ask us to correct this.</p>
          </div>
          <div className="py1">
            <p className="underline">Right to erasure / right to be forgotten </p>
            <p>
              You have the right to tell us to permanently erase your personal data from our records.
              You can do this for example if you believe there’s no longer any need for us to keep it.
              Or, if you previously have given your permission, you can just decide to withdraw it.
            </p>
          </div>
          <div className="py1">
            <p className="underline"> Right to restrict the processing activities</p>
            <p>
              You have the right to restrict our processing activities in certain situations.
              This means we will continue to store your data, but we will temporarily stop any other processing.
              Why would you want to do this? For example, if you’ve asked us to fix incorrect data.
              In this situation you may want us to stop processing until the data is correct.
            </p>
          </div>
          <div className="py1">
            <p className="underline">Right to data portability </p>
            <p>In certain situations, you have the right to ask us to send your personal data in digital form to you, so that you can forward it to someone else.</p>
          </div>
          <div className="py1">
            <p className="underline">Right to object </p>
            <p>
              You have the right to object to the processing of your data, even when we have a legitimate legal reason to process it.
              You can do this when we process your data on the basis of our legitimate interest, and you believe that your personal interest outweighs ours.
              If you do not want us using your data for direct marketing purposes, including profiling, we will comply in any case.
            </p>
          </div>
          <div className="py1">
            <p className="underline">Right to lodge a complaint with a supervisory authority </p>
            <p>If you aren’t satisfied with the way we treat your personal data, you have the right to lodge a complaint about us to the national supervisory authority.</p>
          </div>

          <h2>7) Privacy Statement Updating Process</h2>
          <p>
            We want to give you the best possible experience regarding how we take care of your personal data, adapt to new laws, regulations, and best practices as they appear.
            This means that we will amend this privacy statement from time to time when required by law, or otherwise needed.
            Please make sure to revisit this privacy statement regularly to ensure that you are always up to date with how we process your personal data.
            If you have any questions, please do not hesitate to reach out to us!
          </p>

          <h2>8) Who To Contact?</h2>
          <p>
            If you have questions about our privacy statement or practices, please feel free to get in touch. <br/>
            You can contact us at:
          </p>
          <p>IKEA of Sweden AB</p>
          <p>
            Tulpanvägen 8 <br/>
            343 34 Älmhult
          </p>
          <p>E-mail address:{' '}
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="mailto: IKEA.RangeSupply.Privacy@inter.ikea.com">
                IKEA.RangeSupply.Privacy@inter.ikea.com
            </a>
          </p>
        </div>

        <div className="flex justify-end">
          {showConfirmButton && (
            <Button
              btnType="primary"
              onClick={() => dispatch(fromUsers.acceptGDPR())}>
                I Understand!
            </Button>
          )}
        </div>
      </Container>
    </StandardLayout>
  )
}

export default GDPR
