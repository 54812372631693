import React from 'react'

const IconCamera = ({
  color,
  ...props
}) => (
  <svg
    width='1em'
    height='1em'
    viewBox='2 2 32 32'
    {...props}
  >
    <g fill={color}>
      <path d='M33.4285714,8.18367347 L33.4285714,26.9183673 C33.4285714,27.4005126 33.2047216,27.7391572 32.7570153,27.9343112 C32.6077799,27.9917095 32.4642864,28.0204082 32.3265306,28.0204082 C32.0165801,28.0204082 31.7582918,27.9113531 31.5516582,27.6932398 L24.6122449,20.7538265 L24.6122449,23.6122449 C24.6122449,24.9783232 24.127237,26.1463599 23.1572066,27.1163903 C22.1871763,28.0864207 21.0191395,28.5714286 19.6530612,28.5714286 L7.53061224,28.5714286 C6.16453399,28.5714286 4.9964972,28.0864207 4.02646684,27.1163903 C3.05643648,26.1463599 2.57142857,24.9783232 2.57142857,23.6122449 L2.57142857,11.4897959 C2.57142857,10.1237177 3.05643648,8.95568087 4.02646684,7.98565051 C4.9964972,7.01562015 6.16453399,6.53061224 7.53061224,6.53061224 L19.6530612,6.53061224 C21.0191395,6.53061224 22.1871763,7.01562015 23.1572066,7.98565051 C24.127237,8.95568087 24.6122449,10.1237177 24.6122449,11.4897959 L24.6122449,14.3309949 L31.5516582,7.40880102 C31.7582918,7.19068768 32.0165801,7.08163265 32.3265306,7.08163265 C32.4642864,7.08163265 32.6077799,7.11033135 32.7570153,7.16772959 C33.2047216,7.36288363 33.4285714,7.7015282 33.4285714,8.18367347 Z' id='video-camera---FontAwesome' />
    </g>
  </svg>
)

IconCamera.defaultProps = {
  color: 'currentcolor'
}

export default IconCamera
