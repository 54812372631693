import { DEADLINE_STATUS } from '../constants'
import type { Render } from '../stores/ducks/renders/Render'

export function getDeadlineStatus (renders: Render[]) {
  let error = false
  const notDone = renders.filter((item:any) => {
    if (!item) return false
    switch (item.deadlineStatus) {
      case DEADLINE_STATUS.COMPLETED:
        return false
      case DEADLINE_STATUS.FAILED:
        error = true
        return false
      default:
        return true
    }
  })
  if (notDone.length > 0) return DEADLINE_STATUS.RENDERING
  if (error) return DEADLINE_STATUS.FAILED
  return DEADLINE_STATUS.COMPLETED
}

export function getRenderPercentage (renders: Render[]) {
  if (renders.length < 0) return 0
  let total : number = 0
  const reducer = (accumulator:number, render:Render) => accumulator + (render.renderPercentage || 0)
  total = renders.reduce(reducer, total)
  return total / (renders.length)
}
