import fetch from '../../../utils/fetch'

const assertError = (msg) => (err) => {
  console.error(msg)
  console.error(err)
  throw err
}

export const getAll = () => (
  fetch('/api/image-packages')
    .then((res) => res.json())
    .catch(assertError({ msg: 'unable to fetch all image packages' }))
)

export const get = (id) => (
  fetch(`/api/image-packages/${id}`)
    .then((res) => res.json())
    .catch(assertError({ msg: `unable to fetch ${id} image package` }))
)

export const create = (payload) => (
  fetch('/api/image-packages/create', {
    method: 'POST',
    body: JSON.stringify(payload)
  })
    .then((res) => res.json())
    .catch(assertError({ msg: 'unable to create image package' }))
)

export const update = (payload) => (
  fetch('/api/image-packages/update', {
    method: 'POST',
    body: JSON.stringify(payload)
  })
    .then((res) => res.json())
    .catch(assertError({ msg: `unable to update ${payload.id} image package` }))
)

export const remove = (id) => (
  fetch(`/api/image-packages/remove/${id}`)
    .then((res) => res.json())
    .catch(assertError({ msg: `unable to remove ${id} image package` }))
)

export const createMaster = (payload) => {
  return fetch('/api/image-packages/create-master', {
    method: 'POST',
    body: JSON.stringify(payload)
  })
    .then((res) => res.json())
    .catch(assertError({ msg: 'unable to create image package master', payload }))
}

export const setFinalVersion = (payload) => {
  return fetch('/api/image-packages/set-final-version', {
    method: 'POST',
    body: JSON.stringify(payload)
  })
    .then((res) => res.json())
    .catch(assertError({ msg: 'unable to set combination as default version in parent combination', payload }))
}
