import qs from 'qs'

import fetch from '../../../utils/fetch'

const getQueryString = (params) => {
  const queryString = qs.stringify(params)
  return queryString ? `?${queryString}` : ''
}

export const get = (id, params = {}) => (
  fetch(`/api/projects/${id}${getQueryString(params)}`)
    .then((res) => res.json())
)

export const getAll = () => (
  fetch('/api/projects')
    .then((res) => res.json())
)

export const getDashboard = () => (
  fetch('/api/projects/dashboard')
    .then((res) => res.json())
)

export const getCadManifest = (storageKey) => (
  fetch(`/api/storage/get/${storageKey}.json`)
    .then((res) => res.json())
)

export const postCadManifest = (payload) => (
  fetch('/api/projects/uploaded', {
    method: 'POST',
    body: JSON.stringify(payload)
  })
    .then((res) => res.json())
)

export const create = (payload) => (
  fetch('/api/projects/create', {
    method: 'POST',
    body: JSON.stringify(payload)
  })
    .then((res) => res.json())
)

export const update = (payload) => (
  fetch('/api/projects/update', {
    method: 'POST',
    body: JSON.stringify(payload)
  })
    .then((res) => (res.json()))
)

export const remove = (id) => (
  fetch('/api/projects/remove', {
    method: 'POST',
    body: JSON.stringify({ id })
  })
)

export const toggleShare = (projectId, userId) => (
  fetch('/api/projects/share', {
    method: 'POST',
    body: JSON.stringify({ projectId, userId })
  })
    .then((res) => res.json())
)

export const uploadToDnp = (projectId, renderIds) => {
  return fetch('/api/dnp/uploadToDnp', {
    method: 'POST',
    body: JSON.stringify({ projectId, renderIds })
  })
    .then((res) => res.text())
}

export const getDnpLoginLink = (projectId, userId) => {
  return fetch('/api/dnp/getLoginLink', {
    method: 'POST',
    body: JSON.stringify({ projectId, userId })
  })
    .then((res) => res.text())
}

export const updateFavorites = (payload) => {
  return fetch('/api/projects/updateFavorites', {
    method: 'POST',
    body: JSON.stringify(payload)
  })
    .then((res) => res.json())
}
