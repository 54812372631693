import _keyBy from 'lodash/keyBy'
import _uniq from 'lodash/uniq'

export function dataToMap (data, { isMap = false, keyBy = 'id' }) {
  return isMap ? data : _keyBy([].concat(data), keyBy)
}

export function dataToIds (data, { isMap = false, keyBy = 'id' }) {
  return _uniq(isMap ? Object.keys(data) : [].concat(data).map((x) => x[keyBy]))
}

export function readJsonFile (file, cb) {
  try {
    const reader = new window.FileReader()
    reader.readAsText(file)
    reader.onload = () => cb(null, JSON.parse(reader.result))
    reader.onerror = (err) => cb(err)
  } catch (err) {
    cb(err)
  }
}

export * from './storage'
export * from './fetch'
// export * from './navigate'
export * from './serialize-form'
