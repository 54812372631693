import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ImmutableObject } from 'seamless-immutable'
import _isEqual from 'lodash/isEqual'

import * as fromVirtualProducts from '../../../stores/ducks/virtual-products'
import { setSearchFilterByProp } from '../../../stores/ducks/designs'
import type { RootState } from '../../../stores/ducks'
import type { PlaceholderType, UpdateVirtualProductPayload, VirtualProduct, VirtualProductProjectListItem } from '../../../stores/ducks/virtual-products/VirtualProduct'
import navigate from '../../../utils/navigate'

import Button from '../../common/button'
import InputGroup from '../../common/form/input-group'
import InputText from '../../common/form/input-text'
import Label from '../../common/form/label'
import Grid from '../../common/grid/grid'
import { PillLarge } from '../../common/Pill'
import IconVariants from '../../common/icons/icon-group'

import CombinationCard from './CombinationCard'
import Images from './Images'
import ActionCard from '../card/ActionCard'
import { StorageApiThumbnail } from '../../common/storage-api-image'
import KeyboardListener, { KeyCode, KeyBinding } from '../../common/keyboard-listener'

export interface NullablePlaceholderAttributes {
    productName: PlaceholderType['productName']['productName'] | null
    productType: PlaceholderType['productType']['productTypeName'] | null
    validDesign: PlaceholderType['validDesign']['validDesignText'] | null
    measurement: PlaceholderType['measureReference']['measureReferenceMetric'] | null
  }

export const placeholderAttributes: NullablePlaceholderAttributes = {
  productName: null,
  productType: null,
  validDesign: null,
  measurement: null,
}

interface Props {
  onClose: () => void
  onConnect: () => void
  id: string
}

export default function EditVirtualProductModal (props: Props) {
  const dispatch = useDispatch()
  const virtualProduct: ImmutableObject<VirtualProductProjectListItem> = useSelector((state: RootState) => state.virtualProducts.entries[props.id])
  const projectId = useSelector((state: RootState) => state.projects.currentId as null | string)

  const [metadata, setPlaceholderAttributes] = React.useState(placeholderAttributes)
  const [name, setName] = React.useState<VirtualProduct['virtualProductDatabaseData']['name'] | null>(null)
  const [itemNumber, setItemNumber] = React.useState<VirtualProduct['virtualProductDatabaseData']['itemNumber'] | null>(null)
  const [status, setStatus] = React.useState<VirtualProduct['virtualProductDatabaseData']['status']>('Draft')
  const [lifecycle, setLifecycle] = React.useState<VirtualProduct['virtualProductDatabaseData']['lifecycle']>('ProductIdea')
  const [updatingVP, setUpdatingVP] = React.useState(false)

  const closeTimeout = React.useRef<null | number>(null)

  useEffect(() => {
    return () => {
      if (closeTimeout.current) clearTimeout(closeTimeout.current)
    }
  }, [])

  useEffect(() => {
    // Update is done when new VP has come in
    if (updatingVP && virtualProduct) {
      setUpdatingVP(false)
      return props.onClose()
    }
    if (virtualProduct) {
      const { name, itemNumber, status, lifecycle, placeholderAttributes } = virtualProduct.virtualProductDatabaseData
      setName(name)
      setItemNumber(itemNumber)
      setStatus(status)
      setLifecycle(lifecycle)

      if (placeholderAttributes) {
        setPlaceholderAttributes({
          productName: placeholderAttributes.productName?.productName,
          productType: placeholderAttributes.productType?.productTypeName,
          validDesign: placeholderAttributes.validDesign?.validDesignText,
          measurement: placeholderAttributes.measureReference?.measureReferenceMetric,
        })
      }
    }
  }, [virtualProduct])

  const isEqual = () => {
    const vpPlaceholderAttributes = virtualProduct.virtualProductDatabaseData.placeholderAttributes
    const vpdbData = virtualProduct.virtualProductDatabaseData
    if (vpdbData && vpPlaceholderAttributes) {
      const localState = {
        name,
        itemNumber,
        status,
        lifecycle,
        placeholderAttributes: metadata
      }

      const vpdbState = {
        name: vpdbData.name,
        itemNumber: vpdbData.itemNumber,
        status: vpdbData.status,
        lifecycle: vpdbData.lifecycle,
        placeholderAttributes: {
          measurement: vpPlaceholderAttributes.measureReference?.measureReferenceMetric,
          productName: vpPlaceholderAttributes.productName?.productName,
          validDesign: vpPlaceholderAttributes.validDesign?.validDesignText,
          productType: vpPlaceholderAttributes.productType?.productTypeName
        }
      }
      return _isEqual(localState, vpdbState)
    }
    return null
  }

  const isEmptyField = (value: string | null): boolean => value === null || value.trim().length <= 0

  const handleInputChange = (fieldName: keyof NullablePlaceholderAttributes) => (inputValue: string): void => {
    setPlaceholderAttributes({
      ...metadata,
      [fieldName]: inputValue === '' ? null : inputValue,
    })
  }

  const update = async (): Promise<void> => {
    if (name === null || name.trim().length <= 0) {
      return
    }

    const body: UpdateVirtualProductPayload = {
      name: name.trim(),
      status: status,
      lifecycle: lifecycle,
    }
    if (itemNumber) {
      body.itemNumber = itemNumber.trim()
    }

    const { productName, productType, validDesign, measurement } = metadata
    body.placeholderAttributes = {
      productName: { productName: `${productName ? productName.trim() : ''}` },
      productType: { productTypeName: `${productType ? productType.trim() : ''}` },
      validDesign: { validDesignText: `${validDesign ? validDesign.trim() : ''}` },
      measureReference: { measureReferenceMetric: `${measurement ? measurement.trim() : ''}` },
    }

    if (props.id) {
      setUpdatingVP(true)
      dispatch(fromVirtualProducts.updateVirtualProduct(props.id, body))
    }
  }

  return (
    <Grid
      className='height-100 width-100'
      areas={[
        'title title',
        'variants inputfields',
        'images inputfields',
        'confirmation confirmation'
      ]}
      columns={['1fr', '1fr']}
      rows={['2rem', 'auto', '1fr']}
      gridGap={32}
      style={{ gridRowGap: 16, position: 'relative' }}
    >
      <div style={{ gridArea: 'title' }} className='flex justify-between'>
        <h3 className='m0'>Edit {virtualProduct?.virtualProductDatabaseData.name}</h3>
        <p className='m0 pr3 f7 bold truncate'>Current version: {virtualProduct?.virtualProductDatabaseData.versionData?.versionNr ?? 'n/a'}</p>
      </div>
      <div style={{ gridArea: 'variants', display: 'grid', gridTemplateColumns: '170px 1fr', gridColumnGap: '1rem' }} className='relative p2 border bc-gray-light br-small' >
        <ActionCard
          onAction={() => {
            // When a combination is connected to a virtual product we copy it
            // In order to omit already connected combinations from the Variant search
            // we need to collect all already added combinations by source id and
            // set it in the designs redux state so we can filter out these in the
            // elasticsearch query.
            if (!virtualProduct) return
            dispatch(setSearchFilterByProp({
              idsOmittedFromSearch: [],
              requireThumbnail: false
            }))

            // Opens the Connect Variant search modal
            props.onConnect()
          }}
          actionTitle='Connect new Variant'
          iconComponent={<IconVariants size={64}/>}
        />
        {virtualProduct.currentCombinationId ? <CombinationCard
          connectedAt={virtualProduct.combinations[virtualProduct.currentCombinationId].connectedAt}
          connectedBy={virtualProduct.combinations[virtualProduct.currentCombinationId].connectedBy}
          file={virtualProduct.thumbnail}
          key={virtualProduct.id}
          onClick={() => {
            if (projectId) {
              navigate(`/visualize/${projectId}/combination/${virtualProduct.currentCombinationId}`)
            }
          }}
        />
          : <div style={{ display: 'grid', gridTemplateColumns: '40% 60%', opacity: 0 }}>
            <StorageApiThumbnail file={null}/>
          </div>
        }
      </div>
      <Images
        id={props.id}
        style={{ gridArea: 'images', marginTop: '1rem' }}
      />
      <div style={{ gridArea: 'inputfields', display: 'grid', gridTemplateRows: 'auto 1fr', gridRowGap: '32px' }}
        className='height-100 overflow-auto' >
        <div>
          <div className='flex flex-row pb1'>
            <div className='width-100 mr1'>
              <Label className='flex justify-between'>Name
                {isEmptyField(name) && <span className='c-error regular'>*Required</span>}
              </Label>
              <InputText value={name ?? ''} onChange={(input: string) => setName(input)}/>
            </div>
            <div className='width-100 ml1'>
              <Label>Item number</Label>
              <InputText value={itemNumber ?? ''} onChange={(input: string) => setItemNumber(input)}/>
            </div>
          </div>
          <div className='flex flex-row py1'>
            <div className='width-100 mr1'>
              <Label>Product name</Label>
              <InputText value={metadata.productName ?? ''} onChange={handleInputChange('productName')}/>
            </div>
            <div className='width-100 ml1'>
              <Label>Design</Label>
              <InputText value={metadata.validDesign ?? ''} onChange={handleInputChange('validDesign')} name='validDesign'/>
            </div>
          </div>
          <div className='flex flex-row'>
            <div className='width-100 mr1'>
              <Label>Product type</Label>
              <InputText value={metadata.productType ?? ''} onChange={handleInputChange('productType')}/>
            </div>
            <div className='width-100 ml1'>
              <Label>Measurement (metric)</Label>
              <InputText value={metadata.measurement ?? ''} onChange={handleInputChange('measurement')}/>
            </div>
          </div>
        </div>
        <div>
          <div className='pb1'>
            <Label>Life cycle:</Label>
            <div className='flex flex-row flex-wrap'>
              <PillLarge
                className='mr1 mb2'
                active={lifecycle === 'ProductIdea'}
                onClick={() => { setLifecycle('ProductIdea') }}
              >Product Idea</PillLarge>
              <PillLarge
                className='mr1 mb2'
                active={lifecycle === 'DesignConcept'}
                onClick={() => { setLifecycle('DesignConcept') }}
              >Design Concept</PillLarge>
              <PillLarge
                className='mr1 mb2'
                active={lifecycle === 'ProductConcept'}
                onClick={() => { setLifecycle('ProductConcept') }}
              >Product Concept</PillLarge>
              <PillLarge
                className='mr1 mb2'
                active={lifecycle === 'DevelopmentPrototype'}
                onClick={() => { setLifecycle('DevelopmentPrototype') }}
              >Development Prototype</PillLarge>
              <PillLarge
                className='mb2'
                active={lifecycle === 'Cancelled'}
                onClick={() => { setLifecycle('Cancelled') }}
              >Canceled</PillLarge>
            </div>
          </div>
          <InputGroup>
            <Label>Status:</Label>
            <div className='flex sm-flex-column'>
              <PillLarge active={status === 'Draft'} onClick={() => setStatus('Draft')} className='mr1'>Draft</PillLarge>
              <PillLarge active={status === 'Approved'} onClick={() => setStatus('Approved')}>Approved</PillLarge>
            </div>
          </InputGroup>
        </div>
      </div>
      <div className='flex justify-end' style={{ gridArea: 'confirmation' }}>
        <Button className='mr2' onClick={props.onClose}>Cancel</Button>
        <Button
          btnType="primary"
          onClick={update}
          disabled={updatingVP || isEqual() || isEmptyField(name)}
        >Save</Button>
      </div>
      <KeyboardListener
        disabled={updatingVP || isEqual() || isEmptyField(name)}
        bindings={[
          KeyBinding(KeyCode.enter, update)
        ]}
      />
    </Grid>
  )
}
