import Immutable from 'seamless-immutable'
import { createAction, handleActions } from 'redux-actions'

import _get from 'lodash/get'
import _keyBy from 'lodash/keyBy'
import _omit from 'lodash/omit'

// State Selectors
import * as fromThreeviewerSelectors from '../threeviewer/selectors'
import * as fromMaterialsSelectors from '../materials/selectors'
import * as fromSelectionSelectors from '../selection/selectors'

// Actions
import * as fromThreeviewerUI from '../threeviewer/ui'
import * as fromUndoRedo from '../undo-redo'

import * as fromColorUtils from './utils'
import * as fromColorsSelectors from './selectors'
import * as fromColorCommands from './commands'
import * as fromAdmin from '../admin'
import { getLocalState } from '../renders/selectors'

const receive = createAction('colors/RECEIVE')
export const receiveNotification = createAction('colors/RECEIVE_NOTIFICATION')
export const receiveErrorNotification = createAction('colors/RECEIVE_ERROR_NOTIFICATION')
export const setColorStrengthOpen = createAction('colors/SET_COLOR_STRENGTH_OPEN')
const remove = createAction('colors/REMOVE')

export const setViewColorModalOpen = createAction('colors/SET_VIEW_COLOR_MODAL_OPEN')
const error = createAction('colors/ERROR')
export const setCollisionProblem = createAction('colors/SET_COLLISION_PROBLEM')
export const disposeCollisionProblems = createAction('colors/DISPOSE_COLLISIONS_PROBLEMS')
export const setSearchFilter = createAction('colors/SET_SEARCH_FILTER')
export const setPage = createAction('colors/SET_PAGE')
export const setColorSearchString = createAction('colors/SET_COLOR_SEARCH_STRING')

const receiveColors = (data) => (dispatch, getState) => {
  const { ColorUtils, THREE } = fromThreeviewerSelectors.getViewer(getState())

  const getIkeaColorHexString = (c) => {
    return c && ColorUtils.srgb2lin(new THREE.Color(c.r / 255, c.g / 255, c.b / 255)).getHexString()
  }

  const colors = Object.keys(data).reduce((acc, key) => {
    return acc.concat({
      ...(data[key]),
      ncsInThreeColorHexString: getIkeaColorHexString(_get(data[key], 'ncs.color'))
    })
  }, [])

  dispatch(receive(colors))
}

export function getColors () {
  return (dispatch, getState, { api }) => {
    if (!getLocalState(getState()).fetched) {
      return api.colors.getColors()
        .then((json) => dispatch(receiveColors(json)))
    }
  }
}

export function getColorsAdmin () {
  return (dispatch, getState, { api }) => {
    return api.colors.getColors()
      .then((json) => dispatch(receive(json)))
  }
}

export function addColorsAdmin (payload) {
  return (dispatch, getState, { api }) => {
    return api.colors.saveColor(payload).then(json => {
      dispatch(fromAdmin.setNewColorModalOpen(false))
      dispatch(receive(json))
      dispatch(receiveNotification(`Added color: ${payload.name || payload.ncs.name || payload.pantone.name}`))
    }).catch(err => dispatch(setCollisionProblem(JSON.parse(err))))
  }
}

export function editColorsAdmin (payload) {
  return (dispatch, getState, { api }) => {
    return api.colors.editColor(payload).then(() => {
      dispatch(fromAdmin.setEditColorModalOpen(false))
      dispatch(receive(payload))
      dispatch(receiveNotification(`Edited color: ${payload.name || payload.ncs.name || payload.pantone.name}`))
    }).catch(err => dispatch(setCollisionProblem(JSON.parse(err))))
  }
}

export function removeColorsAdmin (payload) {
  return (dispatch, getState, { api }) => {
    return api.colors.removeColor(payload).then(() => {
      dispatch(remove(payload.id))
      dispatch(receiveNotification(`Removed color: ${payload.name || payload.ncs.name || payload.pantone.name}`))
    })
  }
}

export function setColor ({ id, parts = {}, addToUndoHistory = true }) {
  return (dispatch, getState) => {
    const state = getState()

    const { ColorUtils, THREE } = fromThreeviewerSelectors.getViewer(state)
    const materialsJson = fromMaterialsSelectors.getJsonEntries(state)
    const setMaterialColor = fromColorUtils.createSetMaterialColor(materialsJson, ColorUtils.srgb2lin)
    const nodeList = fromThreeviewerSelectors.getNodeList(state)
    const colors = fromColorsSelectors.getColors(state)
    const selection = fromSelectionSelectors.getSelection(state)

    const item = colors[id]

    const partUuids = Object.keys(parts).map((key) => parts[key].uuid)
    const uuids = fromColorUtils.getUUIDsToSetMaterialOn(
      (partUuids.length > 0 ? partUuids : selection),
      nodeList
    )

    if (addToUndoHistory) {
      const mutableUuids = (uuids.asMutable ? uuids.asMutable() : uuids)

      const oldValuesMap = mutableUuids.reduce((acc, uuid) => {
        const color = nodeList[uuid].material.color
        return Object.assign({}, acc, {
          [uuid]: {
            color: (new THREE.Color()).setRGB(color.r / 255, color.g / 255, color.b / 255),
            colorJson: colors[nodeList[uuid].userData.colorId]
          }
        })
      }, {})

      const command = fromColorCommands.createSetColorCommand({
        newValue: item,
        oldValuesMap,
        nodeList,
        uuids,
        setMaterialColor,
        afterUndo: () => dispatch(fromThreeviewerUI.forceUpdate()),
        afterExecute: () => dispatch(fromThreeviewerUI.forceUpdate())
      })

      command.execute()

      // Add command to undoRedo history
      dispatch(fromUndoRedo.addCommand(command))
    } else {
      uuids.forEach((uuid) => setMaterialColor(nodeList[uuid], item))

      // Force update to sync with color swatch component
      dispatch(fromThreeviewerUI.forceUpdate())
    }
  }
}

export function resetCollisionProblem (value) {
  return (dispatch, getState) => {
    const state = getState()
    dispatch(setCollisionProblem(
      {
        ncs: value === 'ncs' ? false : state.colors.collisionError.ncs,
        pantone: value === 'pantone' ? false : state.colors.collisionError.pantone,
        name: value === 'name' ? false : state.colors.collisionError.name,
        rgb: value === 'ncs' ? false : state.colors.collisionError.rgb
      }
    ))
  }
}

var initialState = Immutable({
  notification: '',
  colors: {},
  error: null,
  colorStrengthOpen: false,
  fetched: false,
  viewColorModalOpen: false,
  collisionError: {
    ncs: false,
    pantone: false,
    name: false,
    rgb: false
  },
  searchFilter: 'standard',
  page: 1,
  colorSearchString: '',
})

export default handleActions({
  [receive]: (state, { payload }) => state.merge({
    fetched: true,
    colors: _keyBy(Array.isArray(payload) ? payload : [payload], 'id')
  }, { deep: true }),
  [error]: (state, { payload }) => state.merge({ error: payload }),
  [disposeCollisionProblems]: (state) => state.setIn(['collisionError'], initialState.collisionError),
  [receiveNotification]: (state, { payload }) => state.merge({ error: '', notification: payload }),
  [receiveErrorNotification]: (state, { payload }) => state.merge({ error: payload, notification: '' }),
  [remove]: (state, { payload }) => {
    const newColors = _omit(state.colors.asMutable(), payload)
    return state.setIn(['colors'], newColors)
  },
  [setViewColorModalOpen]: (state, { payload }) => {
    return state.merge({
      viewColorModalOpen: payload
    })
  },
  [setCollisionProblem]: (state, { payload }) => state.merge({
    collisionError: payload
  }),
  [setColorStrengthOpen]: (state, { payload }) => state.merge({
    colorStrengthOpen: payload
  }),
  [setSearchFilter]: (state, { payload }) => state.merge({
    searchFilter: payload
  }),
  [setPage]: (state, { payload }) => state.merge({
    page: payload
  }),
  [setColorSearchString]: (state, { payload }) => state.merge({
    colorSearchString: payload
  })
}, initialState)
