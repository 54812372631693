export function srgb2lin (color) {
  const output = color.clone()
  const a = 0.055
  output.r = color.r <= 0.045045 ? color.r / 12.92 : Math.pow((color.r + a) / (1.0 + a), 2.4)
  output.g = color.g <= 0.045045 ? color.g / 12.92 : Math.pow((color.g + a) / (1.0 + a), 2.4)
  output.b = color.b <= 0.045045 ? color.b / 12.92 : Math.pow((color.b + a) / (1.0 + a), 2.4)
  return output
}

export function lin2srgb (color) {
  const output = color.clone()
  const a = 0.055
  output.r = color.r <= 0.0031308 ? color.r * 12.92 : (1.0 + a) * Math.pow(color.r, 1.0 / 2.4) - a
  output.g = color.g <= 0.0031308 ? color.g * 12.92 : (1.0 + a) * Math.pow(color.g, 1.0 / 2.4) - a
  output.b = color.b <= 0.0031308 ? color.b * 12.92 : (1.0 + a) * Math.pow(color.b, 1.0 / 2.4) - a
  return output
}
