import { createSelector } from 'reselect'
import _sortBy from 'lodash/sortBy'

import { rgbToHsl } from './utils'

import * as fromProjectsSelectors from '../projects/selectors'
import * as fromColorHelpers from './helpers'

const getLocalState = (state) => state.colors

export const getColors = (state) => getLocalState(state).colors

export const getEntries = getColors

export const selectColorsNotification = (state) => getLocalState(state).notification
export const selectColorsError = (state) => getLocalState(state).error
export const selectCollisionError = (state) => getLocalState(state).collisionError
export const selectSearchFilter = (state) => getLocalState(state).searchFilter
export const selectColorsPage = (state) => getLocalState(state).page
export const selectViewColorModalOpen = (state) => getLocalState(state).viewColorModalOpen
export const selectColorSearchString = (state) => getLocalState(state).colorSearchString

export const selectProjectColors = createSelector(
  getColors,
  selectSearchFilter,
  selectColorSearchString,
  fromProjectsSelectors.getMarkedColors,
  (colors, searchFilter, colorSearchString, markedColorIds) => {
    const colorsAsList =
      Object.values(colors)
        .map((col) => {
          const { r, g, b } = col.ncs.color
          return {
            id: col.id,
            name: col.name,
            ncsTitle: col.ncs.name,
            pantoneTitle: col.pantone.name,
            color: `rgb(${r}, ${g}, ${b})`,
            pantoneColor: 'rgb(' + col.pantone.color.r + ',' + col.pantone.color.g + ',' + col.pantone.color.b + ')',
            hsl: rgbToHsl({ r, g, b }),
            fy: col.fy,
            selectable: col.selectable,
            isPrototypeFabricColor: col.isPrototypeFabricColor
          }
        })

    const sortedSearch = colorsAsList.filter(color => isColorMatchingSearchString(color, colorSearchString))

    function isColorMatchingSearchString (color, searchString) {
      const name = color.name
      const ncs = color.ncsTitle
      const pantone = color.pantoneTitle
      const rgb = color.color
      searchString = searchString.toUpperCase()

      return (
        (name && name.toUpperCase().includes(searchString)) ||
        (ncs && ncs.toUpperCase().includes(searchString)) ||
        (pantone && pantone.toUpperCase().includes(searchString)) ||
        (rgb && rgb.toUpperCase().includes(searchString))
      )
    }

    function putColorsOnTop (colors, names) {
      for (let i = names.length; i >= 0; i--) {
        const index = colors.map(color => color.name).indexOf(names[i])
        if (index !== -1) {
          const color = colors[index]
          colors.splice(index, 1)
          colors.unshift(color)
        }
      }
      return colors
    }

    let sortedColorsAsList = _sortBy(sortedSearch, ['hsl.h', 'hsl.s', 'hsl.l'])
    sortedColorsAsList = putColorsOnTop(sortedColorsAsList, [
      'IKEA BASIC WHITE',
      'IKEA GREY 30',
      'IKEA GREY 28',
      'IKEA GREY 23',
      'IKEA GREY 32',
      'IKEA BLACK 1'
    ])

    if (searchFilter === 'standard') {
      sortedColorsAsList = sortedColorsAsList.filter(color => !color.isPrototypeFabricColor)
    } else {
      sortedColorsAsList = sortedColorsAsList.filter(color => color.isPrototypeFabricColor)
    }

    // Put favorites on top
    sortedColorsAsList = fromColorHelpers.putMarkedColorsOnTop(sortedColorsAsList, markedColorIds)

    return {
      colors: sortedColorsAsList.filter((color) => color.selectable)
    }
  }
)
