import React from 'react'

import UploadFiles from '../../upload-files'
import Modal from '../../common/modal'
import PatternUploadView from '../../upload-files/pattern-upload-view'
import UploadCard from './UploadCard'

const SUPPORTED_FORMATS = [
  '.pdf',
  '.tiff',
  '.tif',
  '.jpg',
  '.jpeg',
  '.png',
  '.eps',
  '.svg',
  '.psd'
]

export default function UploadPattern () {
  const [files, setFiles] = React.useState<File[]>([])
  const reset = () => setFiles([])

  return (
    <div>
      <UploadFiles
        onUpload={(files: File[]) => setFiles(files)}
        supportedFormats={SUPPORTED_FORMATS}
        multiple
      >
        {({ isDragging }: { isDragging: boolean }) => (
          <UploadCard
            type='Pattern'
            isDragging={isDragging}
          />
        )}
      </UploadFiles>
      <Modal
        isOpen={files.length > 0}
        onRequestClose={reset}
      >
        <PatternUploadView
          files={files}
          onReset={reset}
          supportedFormats={SUPPORTED_FORMATS}
        />
      </Modal>
    </div>
  )
}
