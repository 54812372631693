export const DEFAULT_CLUSTER_COLOR = {
  name: 'GREY 50',
  hsl: '0,0%,72%'
}

export const IKEA_COLORS = [
  {
    name: 'PANTONE 12-2903 TCX',
    hsl: '316,40%,87%'
  },
  {
    name: 'IKEA PINK no 7',
    hsl: '352,84%,85%'
  },
  {
    name: 'PANTONE 12-0804 TCX',
    hsl: '50,37%,84%'
  },
  {
    name: 'IKEA YELLOW no 29',
    hsl: '46,63%,78%'
  },
  {
    name: 'IKEA BEIGE no 4 ',
    hsl: '39,42%,77%'
  },
  {
    name: 'PANTONE 14-1318 TCX',
    hsl: '10,71%,77%'
  },
  {
    name: 'PANTONE 13-4200 TCX',
    hsl: '197,43%,77%'
  },
  {
    name: 'IKEA BEIGE 1 ',
    hsl: '42,18%,75%'
  },
  {
    name: 'PANTONE 15-2216 TCX',
    hsl: '342,65%,75%'
  },
  {
    name: 'IKEA GREY 30 ',
    hsl: '40,2%,73%'
  },
  {
    name: 'PAX FY17 Light Turq no1',
    hsl: '178,24%,73%'
  },
  {
    name: 'IKEA BLUE no 58',
    hsl: '198,50%,71%'
  },
  {
    name: 'IKEA YELLOW no 28',
    hsl: '46,83%,70%'
  },
  {
    name: 'IKEA BEIGE 2 ',
    hsl: '38,15%,65%'
  },
  {
    name: 'PANTONE 18-1649 TCX',
    hsl: '1,73%,65%'
  },
  {
    name: 'PANTONE 16-3521 TCX',
    hsl: '312,23%,65%'
  },
  {
    name: 'PANTONE 15-6315 TCX',
    hsl: '77,12%,62%'
  },
  {
    name: 'PANTONE 13-0442 TCX',
    hsl: '54,39%,62%'
  },
  {
    name: 'IKEA PINK no 5',
    hsl: '340,63%,61%'
  },
  {
    name: 'PANTONE 13-0532 TCX',
    hsl: '61,33%,61%'
  },
  {
    name: 'PANTONE 16-0940 TCX',
    hsl: '31,36%,60%'
  },
  {
    name: 'IKEA BEIGE 3  ',
    hsl: '27,10%,58%'
  },
  {
    name: 'PANTONE 15-5218 TCX',
    hsl: '172,33%,57%'
  },
  {
    name: 'IKEA ORANGE no 10',
    hsl: '7,81%,56%'
  },
  {
    name: 'PANTONE 15-4323 TCX',
    hsl: '196,63%,54%'
  },
  {
    name: 'PANTONE 15-6428 TCX',
    hsl: '81,21%,52%'
  },
  {
    name: 'PANTONE 15-0850 TCS',
    hsl: '42,65%,51%'
  },
  {
    name: 'IKEA GREEN no 63',
    hsl: '42,24%,51%'
  },
  {
    name: 'IKEA YELLOW no 24',
    hsl: '49,100%,50%'
  },
  {
    name: 'PANTONE 16-0639 TCX',
    hsl: '48,39%,49%'
  },
  {
    name: 'IKEA YELLOW no 31',
    hsl: '44,100%,48%'
  },
  {
    name: 'PANTONE 16-5412 TCX',
    hsl: '175,30%,47%'
  },
  {
    name: 'PANTONE 16-1454 TCX',
    hsl: '23,100%,45%'
  },
  {
    name: 'PANTONE 18-1550 TCX',
    hsl: '5,71%,45%'
  },
  {
    name: 'PANTONE 17-0123 TCX',
    hsl: '104,18%,45%'
  },
  {
    name: 'IKEA RED 31',
    hsl: '357,77%,42%'
  },
  {
    name: 'PANTONE 17-0330 TCX',
    hsl: '68,27%,42%'
  }
]
