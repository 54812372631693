import React from 'react'
import colors from '../../../../../../css/colors'

type Props = {
  materialClass: string
}

export function AppearanceGroupClassLabel (props: Props) {
  let labelColor: string
  let materialClassName : string
  switch (props.materialClass) {
    case 'dpd':
      materialClassName = 'DPD'
      labelColor = colors.warn
      break
    case 'IKEA Standard':
      materialClassName = 'Standard'
      labelColor = colors.successDark
      break
    case 'Specialized':
      materialClassName = 'Specialized'
      labelColor = colors.warn
      break
    case 'Not set':
      materialClassName = 'Not set'
      labelColor = colors.errorLightAlt
      break
    default:
      materialClassName = 'No class'
      labelColor = colors.errorLightAlt
  }
  return (
    <div style={{
      padding: '2px 6px 2px 6px',
      backgroundColor: '#FFF',
      border: '1px solid ' + labelColor,
      borderRadius: 3,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 11,
      color: labelColor,
      height: 19,
      marginLeft: 4
    }}>
      {materialClassName}
    </div>
  )
}
