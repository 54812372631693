import { receive } from './json'
import error from './error'

export const createImagePackageMaster = (projectId, payload) => {
  return (dispatch, getState, { api }) => {
    return api.imagePackages.createMaster({ projectId, ...payload })
      .then((json) => {
        dispatch(receive(json))
        return json.id
      })
  }
}

export const setImagePackageFinalVersion = (id) => {
  return (dispatch, getState, { api }) => {
    return api.imagePackages.setFinalVersion({ id })
      .then((json) => {
        return dispatch(receive(json))
      })
      .catch((err) => dispatch(error(err)))
  }
}
