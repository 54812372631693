import _get from 'lodash/get'

import store from '../../stores'

import * as fromUsersSelectors from '../../stores/ducks/users/selectors'
import * as fromComments from '../../stores/ducks/comments'

export default function handleComments (msg) {
  if (_get(msg, 'doc.userId') === fromUsersSelectors.getCurrentId(store.getState())) return

  if (msg.status === 'deleted') {
    return store.dispatch(fromComments.confirmRemove(msg.doc))
  }

  store.dispatch(fromComments.receive([msg.doc]))
}
