import * as THREE from 'three'
import _isFinite from 'lodash/isFinite'
import { Transform } from '../../renders/Render'

const tempVector = new THREE.Vector3()

export const getTransform = (matrix: THREE.Matrix4, posMultiple = 1): Transform => {
  const scaleAndRotation = new THREE.Matrix3().setFromMatrix4(matrix).toArray()

  const position = new THREE.Vector3()
    .setFromMatrixPosition(matrix)
    .multiplyScalar(posMultiple)
    .toArray()

  const transform = scaleAndRotation.concat(position)

  if (!transform.every(e => _isFinite(Number(e)))) {
    console.error('Invalid transform', transform)
    throw new Error('Invalid transform')
  }

  return transform as Transform
}

export function lin2srgb (color: THREE.Color) {
  const output = color.clone()
  const a = 0.055
  output.r = color.r <= 0.0031308 ? color.r * 12.92 : (1.0 + a) * Math.pow(color.r, 1.0 / 2.4) - a
  output.g = color.g <= 0.0031308 ? color.g * 12.92 : (1.0 + a) * Math.pow(color.g, 1.0 / 2.4) - a
  output.b = color.b <= 0.0031308 ? color.b * 12.92 : (1.0 + a) * Math.pow(color.b, 1.0 / 2.4) - a
  return output
}

export function quaternionFromVec3 (vector: THREE.Vector3) {
  const euler = new THREE.Euler().setFromVector3(vector, 'XYZ')
  return new THREE.Quaternion().setFromEuler(euler)
}

export const getVolume = (boundingBox: THREE.Box3) => {
  const size = boundingBox.getSize(tempVector)
  return size.x * size.y * size.z
}

export const getMinimalProportion = (value1: number, value2: number) => {
  return value1 > value2
    ? value2 / value1
    : value1 / value2
}
