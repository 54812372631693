import React from 'react'
import cs from 'classnames'
import { StorageApiThumbnail } from '../../../common/storage-api-image'
import AutoFill from '../../../common/grid/AutoFill'
import _map from 'lodash/map'
import _get from 'lodash/get'
import _sortBy from 'lodash/sortBy'
import { connect, ConnectedProps } from 'react-redux'
import { createSelector } from 'reselect'
import { RootState } from '../../../../stores/ducks'
import * as fromThreeviewerSelectors from '../../../../stores/ducks/threeviewer/selectors'
import * as fromRoomsetSelectors from '../../../../stores/ducks/roomsets/selectors'
import * as fromThreeviewerUI from '../../../../stores/ducks/threeviewer/ui'
import * as fromThreeviewerCamera from '../../../../stores/ducks/threeviewer/camera'

type PredefinedCameraProps = {
  camera: any
  disabled: boolean
  onClick: () => void
}

function PredefinedCamera (props: PredefinedCameraProps) {
  return (
    <div
      onClick={props.onClick}
      data-testid={props.camera.name}
      className={cs(
        'border',
        'bw-4',
        'pointer',
        'bg-white',
        {
          'bc-transparent': !props.camera.IsActive,
          'bc-secondary': props.camera.isActive,
          'pointer-disabled': props.disabled
        }
      )}
    >
      <StorageApiThumbnail
        file={props.camera.image}
        className={cs('bg-gray-light', {
          'opacity-hover-lighter': !props.disabled,
          muted: props.disabled
        })} />
      <small className='p1 f8 block truncate'>
        {props.camera.name}
      </small>
    </div>
  )
}

const mapStateToProps = createSelector(
  fromRoomsetSelectors.getRoomsetCameraList,
  fromThreeviewerSelectors.getActiveCameraId,
  fromRoomsetSelectors.getCurrentId,
  (state: RootState) => _get(state, 'threeviewer.camera.mode'),
  (
    roomsetCameraList,
    activeCameraId,
    activeRoomsetId,
    cameraMode,
  ) => {
    const predefinedCameraList: { [id: string]: any } = {}
    Object.entries(roomsetCameraList).forEach(([id, camera]) => {
      if (camera.removedAt || !camera.image) return
      predefinedCameraList[id] = {
        ...camera,
        id,
        isActive: activeCameraId === id
      }
    })

    return {
      cameraMode,
      activeRoomsetId,
      predefinedCameraList: predefinedCameraList
    }
  }
)

const mapDispatchToProps = (dispatch: any) => {
  return {
    disablePredefinedCamera: () => dispatch(fromThreeviewerCamera.disablePredefinedCamera()),
    setCameraSettings: (params: any) => dispatch(fromThreeviewerCamera.setCameraSettings(params)),
    setKeyBoardBindings: (value: boolean) => dispatch(fromThreeviewerUI.toggleKeyBoardBindings(value))
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>
type Props = PropsFromRedux & {
  disabled: boolean
}

function PredefinedCameraList (props: Props) {
  function handleSelect (id: string) {
    const settings = props.predefinedCameraList[id]

    props.setCameraSettings({
      settings: settings,
      cameraId: id,
      isPredefined: true
    })
  }

  return (
    <AutoFill width={100}>
      {_map(_sortBy(props.predefinedCameraList, ['name']), (camera: any) => (
        <PredefinedCamera
          key={camera.id}
          camera={camera}
          onClick={() => {
            if (props.disabled) return
            if (camera.isActive) {
              props.disablePredefinedCamera()
            } else {
              handleSelect(camera.id)
            }
          }}
          disabled={props.disabled}
        />
      ))}
    </AutoFill>
  )
}

export default connector(PredefinedCameraList)
