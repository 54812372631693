import _forEach from 'lodash/forEach'

const noop = () => {}

export const createSetDefaultSceneComponentVisibilityCommand = ({
  id,
  visibility,
  setVisibility,
  undoVisibility,
  afterUndo = noop,
  afterExecute = noop
}) => ({
  undo () {
    _forEach(undoVisibility, ({ active }, id) => {
      setVisibility(id, active)
    })
    afterUndo()
  },
  execute () {
    setVisibility(id, !visibility)
    afterExecute()
  }
})
