import React from 'react'

import { connect, ConnectedProps } from 'react-redux'
import { createSelector } from 'reselect'

import * as fromCombinations from '../../stores/ducks/combinations'
import * as fromThreeViewerFiles from '../../stores/ducks/threeviewer/files'
import * as fromVisualizerSelectors from '../visualizer/visualizer-selectors'

import Button from '../common/button'
import KeyboardListener, { KeyCode, KeyBinding } from '../common/keyboard-listener'

import { fetchAndLoadCombinations } from '../../stores/ducks/combinations/actions/load-combination'
import { RootState } from '../../stores/ducks'
import { getUpplysaLinks } from '../../utils/upplysa'

const EDITOR_URL = getUpplysaLinks().upplysaURL

type OwnProps = {
  selected: string[]
  type: 'load' | 'connect' | 'replace'
  onClose: () => void
  onConfirm?: (ids: string[]) => void
  shouldReapply?: { appearances: boolean, materials: boolean, usingMetadata: boolean, usingBoundingBoxes: boolean }
  children: React.ReactNode
}

const mapStateToProps = createSelector(
  fromVisualizerSelectors.getHasValidSelection,
  (_: RootState, ownProps: OwnProps) => ownProps.selected,
  (_: RootState, ownProps: OwnProps) => ownProps.type,
  (state: RootState) => state.designs.activeTab,
  (hasValidSelection, selected, type, activeTab) => {
    let canConfirm = selected.length

    if (type === 'replace') {
      canConfirm = canConfirm && hasValidSelection
    }

    return {
      confirmText: type || 'load',
      canConfirm,
      activeTab
    }
  }
)

const mapDispatchToProps = (dispatch: any, ownProps: OwnProps) => {
  const { type, onClose } = ownProps
  return {
    confirm: (ids: string[]) => {
      dispatch(fromThreeViewerFiles.disposeLoaded()) // NOTE: This does nothing
      onClose()

      if (type === 'replace') {
        dispatch(
          fromCombinations.replaceModel(
            ids[0],
            ownProps.shouldReapply?.appearances,
            ownProps.shouldReapply?.materials,
            ownProps.shouldReapply?.usingMetadata,
            ownProps.shouldReapply?.usingBoundingBoxes
          )
        )

        return
      }

      dispatch(fetchAndLoadCombinations(ids))

      // Currently only needed for batch render
      dispatch(fromCombinations.setCurrent(ids[0]))
    }
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>
type Props = PropsFromRedux & OwnProps

function ConfirmSelection (props: Props) {
  function handleOnEnter () {
    if (props.selected.length) {
      handleConfirm()
    }
  }

  function handleConfirm () {
    if (props.onConfirm) {
      props.onConfirm(props.selected)
    } else {
      props.confirm(props.selected)
    }
  }

  function handleCancel () {
    props.onClose()
  }

  function openVirtualProductInEditor () {
    if (props.selected.length === 1) {
      window.open(`${EDITOR_URL}/visualizer/combination/${props.selected[0]}`, '_blank', 'noopener,noreferrer')
    }
  }

  return (
    <>
      {props.children}
      <div className='flex'>
        <Button
          className='mr2'
          onClick={handleCancel}
        >
          Cancel
        </Button>
        {props.activeTab === 'virtualProduct' &&
        <Button
          data-testid='open-combination-editor'
          btnType='secondary'
          disabled={props.selected.length !== 1}
          className='mr2'
          onClick={openVirtualProductInEditor}
        >
        Load in Editor
        </Button>
        }
        <Button
          data-testid='confirm-model'
          btnType='primary'
          disabled={!props.canConfirm}
          onClick={handleConfirm}
          style={{ textTransform: 'capitalize' }}
        >
          {props.confirmText}
        </Button>
      </div>

      <KeyboardListener
        keyboardEvent='keypress'
        bindings={[
          KeyBinding(KeyCode.enter, handleOnEnter)
        ]}
      />
    </>
  )
}

export default connector(ConfirmSelection)
