import React from 'react'

import { connect } from 'react-redux'
import { createSelector } from 'reselect'

import * as fromVisualizerSelectors from '../visualizer-selectors'
import * as fromThreeviewerSelectors from '../../../stores/ducks/threeviewer/selectors'
import * as fromPatternsSelectors from '../../../stores/ducks/patterns/selectors'
import * as patternTextureActions from '../../../stores/ducks/patterns/textures'

import _get from 'lodash/get'
import _some from 'lodash/some'

import Label from '../../common/form/label'
import InputNumericRange from '../../common/form/input-numeric-range'

import { toRadians } from '../../../utils/math'
import { PROJECT_TYPES } from '../../../constants'

const MIN = -1
const MAX = 1
const STEP = 0.01

const getInputValue = (eventOrValue) => {
  return Number(isNaN(eventOrValue) ? eventOrValue.target.value : eventOrValue)
}

function PatternSettings (props) {
  const [lastDecalTranslation, setLastDecalTranslation] = React.useState({ x: 0, y: 0 })

  React.useEffect(() => {
    setLastDecalTranslation(
      {
        x: props.userDecalTranslation.x,
        y: props.userDecalTranslation.y
      }
    )
  }, [props.patternId])

  React.useEffect(() => {
    if (props.userDecalTranslation) {
      setLastDecalTranslation(props.userDecalTranslation)
    }
  }, [props.decalMaterialId, props.userDecalTranslation])

  function handleDecalMouseOrKeyDown () {
    props.saveCurrentDecalUVTransform(
      props.userDecalRotation,
      lastDecalTranslation
    )
  }

  function handleDecalMouseOrKeyUp () {
    props.createPatternTransformCommand(
      props.userDecalRotation,
      lastDecalTranslation
    )
  }

  function handleDecalRotationChanged (eventOrValue) {
    if (props.projectType !== PROJECT_TYPES.IMAGE_PACKAGE) {
      var decalRotation = Number(getInputValue(eventOrValue))
      props.rotatePattern(toRadians(decalRotation), decalRotation)
    } else {
      const newForUser = getInputValue(eventOrValue)
      const newForSystem = newForUser === 0 ? 360 : newForUser

      if (eventOrValue.target && (newForUser === '' || newForUser < 0)) {
        props.rotatePattern(toRadians(0), 0)
      } else if (eventOrValue.target && newForUser > 360) {
        props.rotatePattern(toRadians(360), 360)
      } else {
        props.rotatePattern(toRadians(newForSystem), newForUser)
      }
    }
  }

  function handleDecalTranslationX (eventOrValue) {
    const value = getInputValue(eventOrValue)

    handleDecalTranslation(
      value,
      lastDecalTranslation.y
    )
  }

  function handleDecalTranslationY (eventOrValue) {
    const value = getInputValue(eventOrValue)

    handleDecalTranslation(
      lastDecalTranslation.x,
      value
    )
  }

  function handleDecalTranslation (x, y) {
    const dX = x - lastDecalTranslation.x
    const dY = y - lastDecalTranslation.y
    setLastDecalTranslation({ x, y })
    props.translatePattern(dX, dY)
    props.saveCurrentDecalUVTransform(
      props.userDecalRotation,
      { x, y }
    )
  }

  function handleDecalScaleSlider (decalScale) {
    // Using Math.pow here to have an even distribution on the slider since
    // all the minification happens between 0 and 1 and magnification happens above 1
    // Using the slider value as an exponent distributes the numbers more evenly:
    // -3: 12.5%, -2: 25%, -1: 50%, 0: 100%, 1: 200%, 2: 400%, 3: 800%
    // i.e each positive whole number step doubles the size and vice versa.
    //
    // Negation because we want the slider to go the opposite direction,
    // therefore the value on the slider is negated as well in the render function.

    props.scalePattern(Math.pow(2, -decalScale))
  }

  function handleDecalScaleNumberX (event) {
    handleDecalScaleNumber('width', event)
  }

  function handleDecalScaleNumberY (event) {
    handleDecalScaleNumber('height', event)
  }

  function handleDecalScaleNumber (axis, event) {
    const size = getInputValue(event)
    const newScale = props.originalDimensions[axis] / size
    props.scalePattern(newScale)
  }

  const decalTranslation = lastDecalTranslation
  const rotationForUser = props.userDecalRotation
  return (
    <div className='flex justify-around'>

      <div className='pr2 flex flex-column' style={{ width: '200px' }}>
        <div className='flex items-center justify-between f8 mb1' style={{ height: '2em' }}>
          <Label small inverted noMargin>
            <div className='f8'>Horizontal translation</div>
          </Label>
          <input
            type='number'
            className='bc-transparent br-small bg-white-10 f8 c-gray ml1 pl1'
            style={{ height: '2em', width: '6.5em' }}
            disabled={props.disabled}
            min={MIN}
            max={MAX}
            step={STEP}
            value={decalTranslation.x ?? 0}
            onKeyDown={handleDecalMouseOrKeyDown}
            onKeyUp={handleDecalMouseOrKeyUp}
            onChange={handleDecalTranslationX}
          />
        </div>
        <InputNumericRange
          disabled={props.disabled}
          min={MIN}
          max={MAX}
          step={STEP}
          value={decalTranslation.x ?? 0}
          onMouseUp={handleDecalMouseOrKeyUp}
          onMouseDown={handleDecalMouseOrKeyDown}
          onChange={handleDecalTranslationX}
        />
      </div>

      <div className='pr2 flex flex-column' style={{ width: '200px' }}>
        <div className='flex items-center f8 mb1' style={{ height: '2em' }}>
          <Label small inverted noMargin>
            <div className='f8'>Vertical translation</div>
          </Label>
          <input
            type='number'
            className='bc-transparent br-small bg-white-10 f8 c-gray ml1 pl1'
            style={{ height: '2em', width: '6.5em' }}
            disabled={props.disabled}
            min={MIN}
            max={MAX}
            step={STEP}
            value={decalTranslation.y ?? 0}
            onKeyDown={handleDecalMouseOrKeyDown}
            onKeyUp={handleDecalMouseOrKeyUp}
            onChange={handleDecalTranslationY}
          />
        </div>
        <InputNumericRange
          disabled={props.disabled}
          min={MIN}
          max={MAX}
          step={STEP}
          value={decalTranslation.y ?? 0}
          onMouseUp={handleDecalMouseOrKeyUp}
          onMouseDown={handleDecalMouseOrKeyDown}
          onChange={handleDecalTranslationY}
        />
      </div>

      <div className='pr2 flex flex-column' style={{ width: '200px' }}>
        <div className='flex items-center mb1'>
          <Label small inverted noMargin>
            <div className='f8'>Pattern rotation</div>
          </Label>
          <input
            type='number'
            className='bc-transparent br-small bg-white-10 f8 c-gray ml1 pl1'
            style={{ height: '2em', width: '6.5em' }}
            disabled={props.disabled}
            min={0}
            max={360}
            step={1}
            value={isNaN(rotationForUser) ? 0 : rotationForUser}
            onKeyDown={handleDecalMouseOrKeyDown}
            onKeyUp={handleDecalMouseOrKeyUp}
            onChange={handleDecalRotationChanged}
          />
          <div className='f8 c-gray ml1'>°</div>
        </div>
        <InputNumericRange
          disabled={props.disabled}
          min={0}
          max={360}
          step={1}
          value={rotationForUser ?? 0}
          onMouseUp={handleDecalMouseOrKeyUp}
          onMouseDown={handleDecalMouseOrKeyDown}
          onChange={handleDecalRotationChanged}
        />
      </div>

      <div className='flex flex-column'>
        <div className='flex items-center mb1'>
          <Label small inverted noMargin>
            <div className='f8'>Pattern size</div>
          </Label>
          <input
            type='number'
            className='bc-transparent br-small bg-white-10 f8 c-gray ml1 pl1'
            style={{ height: '2em', width: '5.5em' }}
            disabled={props.disabled}
            min={1}
            value={props.dimensions.width ?? 0}
            step={1}
            onKeyDown={handleDecalMouseOrKeyDown}
            onKeyUp={handleDecalMouseOrKeyUp}
            onChange={handleDecalScaleNumberX}
          />
          <div className='c-gray f8 ml1'>x</div>
          <input
            type='number'
            className='bc-transparent br-small bg-white-10 f8 c-gray ml1 pl1'
            style={{ height: '2em', width: '5.5em' }}
            min={1}
            value={props.dimensions.height ?? 0}
            step={1}
            onChange={handleDecalScaleNumberY}
            disabled={props.disabled}
          />
          <div className='c-gray f8 ml1'>mm</div>
        </div>
        <InputNumericRange
          disabled={props.disabled}
          min={-props.scaleRange}
          max={props.scaleRange}
          step={0.3}
          value={-props.decalScale ?? 0} // Negation because we want the slider to go the opposite direction, negated in handleDecalScaleSlider as well.
          onMouseUp={handleDecalMouseOrKeyUp}
          onMouseDown={handleDecalMouseOrKeyDown}
          onChange={handleDecalScaleSlider}
        />
      </div>
    </div>
  )
}

const mapStateToProps = createSelector(
  fromPatternsSelectors.getSavedDecalState,
  fromThreeviewerSelectors.getNodeList,
  fromVisualizerSelectors.getDecalMaterialList,
  fromVisualizerSelectors.getHasPattern,
  fromPatternsSelectors.getJsonEntries,
  fromThreeviewerSelectors.getPickerSelection,
  fromThreeviewerSelectors.getViewer,
  fromThreeviewerSelectors.getForcedUpdate,
  (decalTransform, nodeList, decalMaterialList, someHasPattern, patterns, selections) => {
    const firstDecalNode = _get(nodeList, [decalMaterialList[0]], {})
    const firstDecalMaterial = firstDecalNode.material || {}
    let { decalScale, decalRotation } = firstDecalMaterial

    const patternId = _get(firstDecalNode, 'userData.patternId', '')
    const dimensions = _get(patterns, [patternId, 'dimensions'])

    const translation = firstDecalMaterial.decalUVTransform
      ? firstDecalMaterial.getDecalTranslationFromTransform()
      : { x: 0, y: 0 }

    const userDecalRotation = firstDecalMaterial.userDecalRotation
    const userDecalTranslation = decalTransform ? decalTransform.userDecalTranslation : { x: 0, y: 0 }

    const minSizeInMM = 1

    let scaleRange = 4
    let calculatedScale = 0 // Scale at 100% of original width
    if (firstDecalNode.userData) {
      decalScale = (firstDecalNode.userData.productType === 'hard') ? { x: decalScale.x / 1000, y: decalScale.y / 1000 } : decalScale
    }
    if (dimensions && dimensions.width) {
      calculatedScale = Math.log2(decalScale.x * dimensions.width)
      scaleRange = Math.log2(((dimensions.width / minSizeInMM) / dimensions.width) * dimensions.width)
    }

    const x = _get(decalScale, 'x', 1)
    const y = _get(decalScale, 'y', 1)

    const someDontHavePatternId = _some(selections, (selection) => !selection.userData.patternId)

    return {
      patternId,
      decalMaterialId: firstDecalMaterial.uuid,
      decalRotation,
      userDecalRotation,
      userDecalTranslation,
      scaleRange: Math.max(Math.round(scaleRange), 4),
      disabled: !someHasPattern || someDontHavePatternId,
      hasPattern: someHasPattern,
      dimensions: {
        width: someHasPattern ? Math.max(Math.round(1 / x), 1) : 0,
        height: someHasPattern ? Math.max(Math.round(1 / y), 1) : 0
      },
      originalDimensions: dimensions,
      decalScale: calculatedScale,
      translation
    }
  }
)

export default connect(
  mapStateToProps,
  { ...patternTextureActions }
)(PatternSettings)
