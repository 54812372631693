import React from 'react'
import colors from '../../../css/colors'

export default function NumericPin (props: React.HTMLAttributes<HTMLDivElement> & {number: number, pStyle?: React.CSSProperties}) {
  const { number, pStyle, ...baseProps } = props
  return (
    <div
      {...baseProps}
      style={{ height: 15, minWidth: 25, textAlign: 'center', backgroundColor: colors.black25, borderRadius: 2, marginRight: 8, ...baseProps.style }}
    >
      <p style={{ fontSize: 9, padding: '2px 4px 2px 4px', margin: 0, color: '#FEFEFE', ...pStyle }}>{number}</p>
    </div>
  )
}
