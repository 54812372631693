import { combineReducers } from 'redux'

import modals from './modals'
import zindex from './zindex'
import projectView from './project-view'

export default combineReducers({
  modals,
  zindex,
  projectView
})
