import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { app } from '../../../../go3dthree'
import CameraManager from '../../../../go3dthree/src/scenegraph/CameraManager'
import type { Go3DViewer } from '../../../../go3dthree/types/Go3DViewer'
import { SceneGraphMesh } from '../../../../go3dthree/types/SceneGraph'
import { PROJECT_TYPES, TOP_BAR_HEIGHT } from '../../constants'
import * as fromColors from '../../stores/ducks/colors'
import * as fromCombinations from '../../stores/ducks/combinations'
import type { VisualizedCombination } from '../../stores/ducks/combinations/Combination'
import * as fromMaterials from '../../stores/ducks/materials'
import * as fromTemplates from '../../stores/ducks/templates'
import * as fromThreeviewer from '../../stores/ducks/threeviewer'
import * as fromThreeviewerSettings from '../../stores/ducks/threeviewer/settings'
import * as fromThreeviewerViewer from '../../stores/ducks/threeviewer/viewer'
import { link } from '../common/icons-base64/icon64-link'
import { pipette } from '../common/icons-base64/icon64-pipette'
import OffsetViewSafeFrame from './OffsetViewSafeFrame'
import RectangleSelect from './rectangle-select'
import { SubToolbarPortal } from './toolbar/subtoolbar'

interface FetchedCombination {
  combination: VisualizedCombination
  instanceId: string
}
declare global {
  interface Window {
    viewer: Go3DViewer
  }
}
interface KeyActions {
  [key: string]: string
}

const keyActionMap: KeyActions = {
  p: pipette,
  t: link
}

const canvasWrapperStyle = {
  height: `calc(100vh - ${TOP_BAR_HEIGHT}px)`,
  width: `calc(100vw - ${TOP_BAR_HEIGHT * 2}px)`
}

type Props = {
  projectId: string
  projectType: string
  imagePackageId: string
  combinationId: string
}

const ViewerWrapper = styled.div<{ keyAction: string }>`
  max-height: 100%;
  position: relative;
  cursor: ${(props) => (props.keyAction ? props.keyAction : 'auto')};

  &:focus {
    outline: none;
  }

  canvas:focus {
    outline: none;
  }
`

const Viewer = (props: Props) => {
  const go3dViewer = app as unknown as Go3DViewer // TODO rewrite app to ts in go3dthree/src/app.js
  const dispatch = useDispatch()
  const [keyAction, setKeyAction] = useState<string | null>(null)
  const canvasWrapperRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    window.addEventListener('keyup', handleKeyUp)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
      window.removeEventListener('keyup', handleKeyUp)
    }
  }, [])

  useEffect(() => {
    /**
     * @desc Create and initialize the viewer in visualizer
     *
     * Create a canvas and add it to the DOM.
     * Initialize the viewer with specified settings.
     * If we successfully load a combination the camera will be centered to the combination.
     * If we don't have a combinationId it will load an empty visualizer.
     */

    Promise.resolve()
      .then(() => {
        const canvas = go3dViewer.getCanvas()
        if (canvasWrapperRef.current) {
          canvasWrapperRef.current.appendChild(canvas)
        }

        go3dViewer.init({
          useOrbitControls: false,
          domElementWrapper: canvasWrapperRef.current,
          doRenderAlways: false
        })
        go3dViewer.animate(() => {})
      })
      .then(() => dispatch(fromThreeviewerViewer.receive(go3dViewer)))
      .then(() => dispatch(fromThreeviewerSettings.getSettings()))
      .then(() => dispatch(fromThreeviewerViewer.setup()))
      .then(() => dispatch(fromColors.getColors()))
      .then(() => {
        if (props.projectType === PROJECT_TYPES.IMAGE_PACKAGE) {
          go3dViewer.cameraManager.change({
            behavior: CameraManager.BEHAVIORS.IMAGE_PACKAGE
          })
          go3dViewer.transformGizmo.disable()
        }
      })
      .then(() => {
        if (props.combinationId) {
          return dispatch(fromCombinations.fetchAndLoadCombination(props.combinationId)) as unknown as FetchedCombination
        } else {
          dispatch(fromTemplates.load('default'))
          return null
        }
      })
      .then((result) => {
        if (result && result.combination && !result.combination.roomsetId) {
          const objects = go3dViewer.objectTracker.returnObjectsOfType('combinations')
          if (objects.length) {
            go3dViewer.cameraManager.centerControlsAroundObjects(objects)
          }
        }
      })

    window.viewer = go3dViewer // The viewer is accessible from dev tools log

    return () => {
      go3dViewer.dispose()
      dispatch(fromThreeviewer.dispose())
      dispatch(fromTemplates.dispose())
      dispatch(fromMaterials.dispose())
      dispatch(fromCombinations.connectCombinationsForBatchRender([]))
    }
  }, [])

  const handleKeyUp = (event: KeyboardEvent) => {
    if (event.key) {
      setKeyAction(null)
    }
  }

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key in keyActionMap) {
      setKeyAction(event.key)
    }
  }

  const handleAreaSelect = (start: number[], end: number[], keepSelection: boolean) => {
    const { THREE } = go3dViewer
    const _startPos = new THREE.Vector2()
    const _endPos = new THREE.Vector2()

    _startPos.fromArray(start)
    _endPos.fromArray(end)

    const containedObjs = go3dViewer.viewerUtils.getSelectedObjectsFromMarquee(
      _startPos,
      _endPos,
      canvasWrapperRef.current,
      go3dViewer.objectTracker.returnObjectsOfType('combinations'),
      go3dViewer.camera
    )
      .filter((obj: SceneGraphMesh) => obj.isMesh && obj.visible)

    go3dViewer.picker.selectMany(containedObjs, true, keepSelection)
  }

  const handleActivate = () => {
    go3dViewer.cameraManager.disable('mouse')
  }

  const handleDeactivate = () => {
    go3dViewer.cameraManager.enable('mouse')
  }

  return (
    <ViewerWrapper keyAction={keyAction ? keyActionMap[keyAction] : ''}>
      <div
        className='userselect-none'
        style={canvasWrapperStyle}
        ref={canvasWrapperRef}
      >
        <OffsetViewSafeFrame />
        <SubToolbarPortal />
        <RectangleSelect
          onSelect={handleAreaSelect}
          onDeactivate={handleDeactivate}
          onActivate={handleActivate}
        />
      </div>
    </ViewerWrapper>
  )
}

export default Viewer
