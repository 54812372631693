import React from 'react'
import Grid from '../common/grid/grid'
import DesignSearch from '../designs'

const titleByType = {
  load: 'Load geometry',
  replace: 'Replace geometry'
}

const DesignLoader = ({ onClose, type }) => {
  return (
    <Grid
      className='absolute top-0 left-0 right-0 bottom-0 m3'
      rows={['2rem', '1fr']}
      gridGap={0}
      data-testid='grid-design-search'
    >
      <h2 className='mt0 f4'>{titleByType[type]}</h2>
      <DesignSearch
        onClose={onClose}
        type={type}
        selectOne={type === 'replace'}
      />
    </Grid>
  )
}

export default DesignLoader
