import React from 'react'
import styled from 'styled-components'
import Modal from '../../common/modal'
import Button from '../../common/button'
import type { SearchableColor } from './SearchableColor'

const Value = styled.div`
  height: 1rem;
  margin-top: 3px;
`

const Property = styled.div`
  font-weight: bold;
  margin-top: var(--space-2);
`

const Color = styled.div<{ color: string }>`
  background: ${props => props.color};
  height: 300px;
`

type Props = {
  isOpen: boolean
  onRequestClose: () => void
  width: string | number
  selectedColor: SearchableColor
}

export default function ViewColorModal (props: Props) {
  if (!props.isOpen) return null

  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      width={props.width}
    >
      <div className='flex flex-column pt3 f6'>
        <Color color={props.selectedColor.color} />

        <Property style={{ marginTop: 'var(--space-3)' }}>Title</Property>
        <Value data-testid='color-title'>{props.selectedColor.name}</Value>

        <Property>NCS title</Property>
        <Value data-testid='color-ncs-title'>{props.selectedColor.ncsTitle}</Value>

        <Property>PANTONE title</Property>
        <Value data-testid='color-pantone-title'>{props.selectedColor.pantoneTitle}</Value>

        <Property>RGB value</Property>
        <Value data-testid='color-rgb'>{props.selectedColor.color}</Value>

        <Property>FY</Property>
        <Value data-testid='color-fy'>{props.selectedColor.fy}</Value>
      </div>
      <div className='flex justify-end'>
        <Button data-testid='color-cancel' onClick={props.onRequestClose}>Cancel</Button>
      </div>
    </Modal>
  )
}
