import _get from 'lodash/get'
import _omit from 'lodash/omit'
import { placeCombination } from '../combinations/actions/place'

export const createEnvironment = (viewer, {
  prefix = '/envmap/',
  postfix = '.exr'
}) => {
  return new Promise((resolve) => {
    resolve(viewer.setEnvMap(prefix, postfix))
  })
}

export const placeInstances = (viewer, origoTranslation, omittedInstances = [], inRoomset = false) => {
  const instanceIds = {}

  viewer.scene.traverse((node) => {
    if (_get(node, 'userData.instanceId')) {
      instanceIds[_get(node, 'userData.instanceId')] = null
    }
  })

  Object.keys(_omit(instanceIds, omittedInstances)).forEach((instanceId) => {
    placeCombination({
      viewer,
      instanceId,
      origoChanged: true,
      origoTranslation,
      inRoomset
    })
  })
}
