import Immutable from 'seamless-immutable'
import { createAction, handleActions } from 'redux-actions'

export const toggleSelectPlacedComment = createAction('gallery/comments/TOGGLE_SELECT')
export const selectCommentAnchor = createAction('gallery/comments/SELECT_ANCHOR')
export const placeTemporaryAnchor = createAction('gallery/comments/PLACE_TEMPORARY_ANCHOR')
export const clearTemporaryAnchor = createAction('gallery/comments/CLEAR_TEMPORARY_ANCHOR')
export const focusComment = createAction('gallery/comments/FOCUS_COMMENT')

const initialState = Immutable({
  selectedCommentId: null,
  position: null,
  temporaryAnchor: null,
  isCommenting: false
})

export default handleActions({
  [focusComment]: (state, { payload }) => state.merge({
    isCommenting: payload
  }),
  [toggleSelectPlacedComment]: (state, { payload }) => state.merge({
    selectedCommentId: state.selectedCommentId === payload ? null : payload,
    temporaryAnchor: null
  }),
  [selectCommentAnchor]: (state, { payload }) => state.merge({
    selectedCommentId: payload,
    temporaryAnchor: null
  }),
  [placeTemporaryAnchor]: (state, { payload }) => state.merge({
    position: payload,
    temporaryAnchor: { position: payload, id: 'temporary' },
    selectedCommentId: 'temporary'
  }),
  [clearTemporaryAnchor]: (state) => state.merge({
    position: null,
    temporaryAnchor: null
  })
}, initialState)
