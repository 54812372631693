import React, { useEffect, useState } from 'react'
import cs from 'classnames'
import _get from 'lodash/get'

import SpriteImage from '../../../../common/sprite-image'
import { StorageApiThumbnail } from '../../../../common/storage-api-image'
import css from './../index.css'
import { IconMagnifyingGlass } from '../../../../common/icons/icon-magnifying-glass'
import IconColorWheel from '../../../../common/icons/icon-color-wheel'
import { AiFillStar as IconStar, AiOutlineStar as IconOutlineStar } from 'react-icons/ai'

const MaterialCard = ({
  material,
  disabled,
  onApply,
  onMaterialPreviewClicked,
  onFavoriteClick,
  markedAppearances
}) => {
  const [selected, setSelected] = useState(false)

  useEffect(() => {
    if (markedAppearances && markedAppearances.includes(material.id)) setSelected(true)
  }, [markedAppearances])

  return (
    <div
      data-testid='appearance-card'
      onClick={onApply}
      className={cs('pointer', {
        'muted pointer-disabled': disabled
      })}
      title={`${material.colorSpecificationCode || material.name || 'Not set'}\n${material.displayName || material.description || ''}`}
    >
      <div className={`relative ${css.card}`}>
        <div
          style={{
            position: 'absolute',
            padding: 4,
            right: 0,
            top: 0
          }}
          onClick={(e) => {
            e.stopPropagation()
            if (onMaterialPreviewClicked) {
              onMaterialPreviewClicked()
            }
          }}
        >
          <div title='Details'>
            <IconMagnifyingGlass color='black' className={css.icon} size='1.2em'/>
          </div>
        </div>
        <div
          title='Favorite'
          data-testid='appearance-favorite'
          style={{
            position: 'absolute',
            left: 4,
            top: 4,
            backgroundColor: 'var(--black25)',
            borderRadius: 9,
            width: 18,
            height: 18,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          className={selected ? css.selected : css.markIcon}
          onClick={(e) => {
            e.stopPropagation()
            setSelected(!selected)
            onFavoriteClick()
          }}>
          {selected ? <IconStar size={18} style={{ color: 'var(--primary)' }} title='Remove favorite'/> : <IconOutlineStar size={18} style={{ color: 'var(--primary)' }} title='Add favorite'/>}
        </div>

        {material.canSetColor && (
          <div
            title='Color options'
            style={{
              position: 'absolute',
              padding: 4,
              bottom: 0,
              left: 0
            }}>
            <IconColorWheel width={18} height={18} />
          </div>
        )}

        {material.thumbnailOffset ? (
          <SpriteImage
            src={_get(material.thumbnailOffset, 'spritesheet', material.thumbnail)}
            className={`${css.hoverInset} ${material.selected ? css.selectedInset : ''} `}
            tileSize={128}
            tilesPerRow={8}
            offsetX={material.thumbnailOffset.x}
            offsetY={material.thumbnailOffset.y}
            placeholderSrc={'/img/placeholder-material.jpg'}
          />
        ) : (
          <StorageApiThumbnail
            src={material.thumbnail}
            className={`${css.hoverInset} ${material.selected ? css.selectedInset : ''} ${css.card}`}
            placeholderSrc={'/img/placeholder-material.jpg'}
          />
        )}
      </div>
      <div
        className='f8 mt1'
        style={{
          display: '-webkit-box',
          WebkitLineClamp: 3,
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
      >
        {material.source === 'materialbank' &&
          <div data-testid='appearance-title' className='bold line-height-4'>{material.colorSpecificationCode ? 'CSC ' + material.colorSpecificationCode : 'MAT' + material.id}</div>
        }
        <span data-testid='appearance-description'>{material.displayName || material.description || material.name}</span>
      </div>
    </div>
  )
}

export default MaterialCard
