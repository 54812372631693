import _get from 'lodash/get'
import * as materialUtils from '../materials/utils'

export const getUUIDsToSetMaterialOn = (selectedUuids, nodeList) => {
  const uuids = []

  selectedUuids.forEach(uuid => {
    const node = nodeList[uuid]

    if (!node) return

    node.traverse(child => {
      if (child.material && child.userData.canSetColor) {
        uuids.push(child.uuid)
      }
    })
  })

  return uuids
}

export const createSetMaterialColor = (materialsJson, srgb2lin) => (node, colorJson, isDefaultMaterialColor = false) => {
  const clonedMaterial = node.material.clone()
  clonedMaterial.materialId = node.material.materialId

  var matJson = _get(materialsJson, [clonedMaterial.materialId], false)
  var colorVariant = matJson.colorVariant

  if (!_get(colorJson, [colorVariant, 'active']) || !matJson.canSetColor) {
    return
  }

  node.userData.colorName = colorJson.name
  node.userData.colorId = colorJson.id

  var c = colorJson[colorVariant].color
  clonedMaterial.color.setRGB(c.r / 255, c.g / 255, c.b / 255)

  clonedMaterial.useColorTextureMix = matJson.canSetColor

  // IKEA stores their colors in sRGB while ThreeJS expects linear
  clonedMaterial.color.copy(srgb2lin(clonedMaterial.color))

  node.material = clonedMaterial

  const mixValueObj = new materialUtils.ColorTextureMixVal(1.0)
  node.userData.colorTextureMix = mixValueObj.value
  node.material.colorTextureMix = mixValueObj.value

  if (isDefaultMaterialColor) {
    delete node.userData.colorName
    delete node.userData.colorId
  }
}

export function rgbToHsl ({ r, g, b }) {
  var d, h, l, max, min, s
  r /= 255
  g /= 255
  b /= 255
  max = Math.max(r, g, b)
  min = Math.min(r, g, b)
  h = 0
  s = 0
  l = (max + min) / 2
  if (max === min) {
    h = s = 0
  } else {
    d = max - min
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0)
        break
      case g:
        h = (b - r) / d + 2
        break
      case b:
        h = (r - g) / d + 4
    }
    h /= 6
  }
  h = h * 360
  s = (s * 100) + '%'
  l = (l * 100) + '%'
  return { h, s, l }
}

//* * Not being used but keep if **//

// export function ncsToRgb (nscTitle) {
//   let ncs = nscTitle
//   var black, chroma, bc, percent, black1, chroma1, red1, factor1, blue1, green1, red2, green2, blue2, max, factor2, grey, r, g, b
//   ncs = w3trim(ncs).toUpperCase()
//   ncs = ncs.replace('(', '')
//   ncs = ncs.replace(')', '')
//   ncs = ncs.replace('NCS', 'NCS ')
//   ncs = ncs.replace(/ {2}/g, ' ')
//   if (ncs.indexOf('NCS') === -1) {
//     ncs = 'NCS ' + ncs
//   }
//   ncs = ncs.match(/^(?:NCS|NCS\sS)\s(\d{2})(\d{2})-(N|[A-Z])(\d{2})?([A-Z])?$/)
//   if (ncs === null) return false
//   black = parseInt(ncs[1], 10)
//   chroma = parseInt(ncs[2], 10)
//   bc = ncs[3]
//   if (bc !== 'N' && bc !== 'Y' && bc !== 'R' && bc !== 'B' && bc !== 'G') {
//     return false
//   }
//   percent = parseInt(ncs[4], 10) || 0
//   if (bc !== 'N') {
//     black1 = (1.05 * black - 5.25)
//     chroma1 = chroma
//     if (bc === 'Y' && percent <= 60) {
//       red1 = 1
//     } else if ((bc === 'Y' && percent > 60) || (bc === 'R' && percent <= 80)) {
//       if (bc === 'Y') {
//         factor1 = percent - 60
//       } else {
//         factor1 = percent + 40
//       }
//       red1 = ((Math.sqrt(14884 - Math.pow(factor1, 2))) - 22) / 100
//     } else if ((bc === 'R' && percent > 80) || (bc === 'B')) {
//       red1 = 0
//     } else if (bc === 'G') {
//       factor1 = (percent - 170)
//       red1 = ((Math.sqrt(33800 - Math.pow(factor1, 2))) - 70) / 100
//     }
//     if (bc === 'Y' && percent <= 80) {
//       blue1 = 0
//     } else if ((bc === 'Y' && percent > 80) || (bc === 'R' && percent <= 60)) {
//       if (bc === 'Y') {
//         factor1 = (percent - 80) + 20.5
//       } else {
//         factor1 = (percent + 20) + 20.5
//       }
//       blue1 = (104 - (Math.sqrt(11236 - Math.pow(factor1, 2)))) / 100
//     } else if ((bc === 'R' && percent > 60) || (bc === 'B' && percent <= 80)) {
//       if (bc === 'R') {
//         factor1 = (percent - 60) - 60
//       } else {
//         factor1 = (percent + 40) - 60
//       }
//       blue1 = ((Math.sqrt(10000 - Math.pow(factor1, 2))) - 10) / 100
//     } else if ((bc === 'B' && percent > 80) || (bc === 'G' && percent <= 40)) {
//       if (bc === 'B') {
//         factor1 = (percent - 80) - 131
//       } else {
//         factor1 = (percent + 20) - 131
//       }
//       blue1 = (122 - (Math.sqrt(19881 - Math.pow(factor1, 2)))) / 100
//     } else if (bc === 'G' && percent > 40) {
//       blue1 = 0
//     }
//     if (bc === 'Y') {
//       green1 = (85 - 17 / 20 * percent) / 100
//     } else if (bc === 'R' && percent <= 60) {
//       green1 = 0
//     } else if (bc === 'R' && percent > 60) {
//       factor1 = (percent - 60) + 35
//       green1 = (67.5 - (Math.sqrt(5776 - Math.pow(factor1, 2)))) / 100
//     } else if (bc === 'B' && percent <= 60) {
//       factor1 = (1 * percent - 68.5)
//       green1 = (6.5 + (Math.sqrt(7044.5 - Math.pow(factor1, 2)))) / 100
//     } else if ((bc === 'B' && percent > 60) || (bc === 'G' && percent <= 60)) {
//       green1 = 0.9
//     } else if (bc === 'G' && percent > 60) {
//       factor1 = (percent - 60)
//       green1 = (90 - (1 / 8 * factor1)) / 100
//     }
//     factor1 = (red1 + green1 + blue1) / 3
//     red2 = ((factor1 - red1) * (100 - chroma1) / 100) + red1
//     green2 = ((factor1 - green1) * (100 - chroma1) / 100) + green1
//     blue2 = ((factor1 - blue1) * (100 - chroma1) / 100) + blue1
//     if (red2 > green2 && red2 > blue2) {
//       max = red2
//     } else if (green2 > red2 && green2 > blue2) {
//       max = green2
//     } else if (blue2 > red2 && blue2 > green2) {
//       max = blue2
//     } else {
//       max = (red2 + green2 + blue2) / 3
//     }
//     factor2 = 1 / max
//     r = parseInt((red2 * factor2 * (100 - black1) / 100) * 255, 10)
//     g = parseInt((green2 * factor2 * (100 - black1) / 100) * 255, 10)
//     b = parseInt((blue2 * factor2 * (100 - black1) / 100) * 255, 10)
//     if (r > 255) {
//       r = 255
//     }
//     if (g > 255) {
//       g = 255
//     }
//     if (b > 255) {
//       b = 255
//     }
//     if (r < 0) {
//       r = 0
//     }
//     if (g < 0) {
//       g = 0
//     }
//     if (b < 0) {
//       b = 0
//     }
//   } else {
//     grey = parseInt((1 - black / 100) * 255, 10)
//     if (grey > 255) {
//       grey = 255
//     }
//     if (grey < 0) {
//       grey = 0
//     }
//     r = grey
//     g = grey
//     b = grey
//   }
//   return {
//     r: r,
//     g: g,
//     b: b
//   }
// }
// function w3trim (x) {
//   return x.replace(/^\s+|\s+$/g, '')
// }
