import React from 'react'

import IconArrow from './icon-arrow-up'

const IconArrowLeft = props => (
  <IconArrow
    transform='rotate(-90)'
    {...props}
  />
)

export default IconArrowLeft
