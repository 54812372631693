import React from 'react'
import { connect, ConnectedProps } from 'react-redux'

import _get from 'lodash/get'
import _map from 'lodash/map'
import AutoFill from '../common/grid/AutoFill'
import Design from './design'

import ProgressCard from '../project/card/ProgressCard'
import { createSelector } from 'reselect'

import { MODEL_RESOURCE_TYPES } from '../../constants.js'
import { RootState } from '../../stores/ducks'
import { DesignTypes } from '.'
const {
  RESOURCE_DPD,
  RESOURCE_MODEL_BANK,
  RESOURCE_PROPPING_BANK,
  RESOURCE_VIRTUAL_PRODUCTS
} = MODEL_RESOURCE_TYPES

const mapStateToProps = createSelector(
  (state: RootState) => state.designs.showSizes,
  (state: RootState) => state.designs.searchResults,
  (state: RootState) => state.designs.sizes,
  (showSizes, searchResults, sizes) => {
    return {
      showSizes,
      searchResults,
      // TODO: rewrite src/ducks/designs/index to typescript, and properly type up sizes
      sizes: Array.from(sizes) as any[]
    }
  }
)

const mapDispatchToProps = () => {
  return {
    // TODO: type up _source, should be the elastic search hit
    parseItem: (_source: any) => {
      switch (_source.source) {
        case RESOURCE_DPD:
          return {
            ..._source,
            title: _source.title
          }
        case RESOURCE_PROPPING_BANK:
          return {
            ..._source,
            title: _source.title,
            subTitle: _source.articlenr
          }
        case RESOURCE_MODEL_BANK:
          return {
            ..._source,
            title: _source.title,
            secondTitle: _get(_source, 'metadata.producttype_name'),
            subTitle: _source.articlenr,
            metadata: _source.metadata
          }
        case RESOURCE_VIRTUAL_PRODUCTS: {
          return {
            ..._source,
            title: _source.name,
            subTitle: _source.itemId || '',
            id: _source.combinationId
          }
        }
        default:
          return _source
      }
    }
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>
type Props = PropsFromRedux & {
  hits: any[]
  selected: string[]
  onSelect: (id: string) => void
  resource: any
  projectId: string | null
  itemsInProgress: any[]
  uploadItem: any
  queryString: string
  type: DesignTypes
}

function SearchResults (props: Props) {
  let items = _map(props.hits, props.parseItem)
  if (props.showSizes && props.sizes) {
    const mergedItemsByArticleNr = items.map(t1 => ({ ...t1, ...props.sizes.find(t2 => t2.articlenr === t1.articlenr) }))
    items = mergedItemsByArticleNr.map(item => {
      return {
        ...item,
        subTitle: item.measurementData?.measurementMetric ? item.measurementData.measurementMetric : undefined
      }
    })
  }

  return (
    <AutoFill width={120} >
      {props.uploadItem}
      {props.resource === 'upload' && props.itemsInProgress.map((item, index) => (
        <ProgressCard
          item={item}
          key={`${item.id}_${index}`}
          noShadow
          combinationType='upload'
        />
      ))}
      {items.map((item, index) => (
        <Design
          {...item}
          size={'small'}
          key={`${item.id}_${index}`}

          onSelect={() => props.onSelect(item.id)}
          isSelected={props.selected.includes(item.id)}
          combinationType={item.combinationType}
          shared={item.projectId !== props.projectId}
          disabled={props.type === 'connect' && item.thumbnail === null}
        />
      )
      )}
    </AutoFill>
  )
}

SearchResults.defaultProps = {
  hits: [],
  gridGap: 16,
  onSelect () {},
  isSelected () {}
}

export default connector(SearchResults)
