import { AppThunk } from '..'

import Immutable from 'seamless-immutable'
import { createAction, handleActions } from 'redux-actions'

import _keyBy from 'lodash/keyBy'
import _filter from 'lodash/filter'

import { ExportData } from './ExportData'

const assertError = (msg: string) => (err: Error) => {
  console.error(msg)
  console.error(err)
  throw err
}

const receive = createAction('export/RECEIVE')
export const receiveUpdate = createAction('export/RECEIVE_UPDATE')

export const startExportOfVariant = (combinationId: string, title: string) : AppThunk => (dispatch, getState, { api }) => {
  return api.exportDownload.startExportOfVariant(combinationId, title)
    .then(() => {})
    .catch(assertError('Unable to export'))
}

export const getAllExportsInProject = (projectId: string) : AppThunk => async (dispatch, getState, { api }) => {
  return api.exportDownload.getAllExportsInProject(projectId)
    .then((payload: any) => {
      payload = _filter(payload, externalView => !externalView.removedAt)
      dispatch(receive(payload))
    })
    .catch(assertError('Unable to get all exports by projectId'))
}

const initialState = Immutable<{
  entries: {[id: string]: ExportData},
}>({
  entries: {}
})

type State = typeof initialState

export default handleActions<State, any>({
  [receive.toString()]: (state:State, { payload }:{ payload: ExportData[] }) => state.merge({
    entries: _keyBy(payload ?? [], 'id')
  }, { deep: true }),
  [receiveUpdate.toString()]: (state:State, { payload }:{ payload: ExportData }) => state.setIn(['entries', payload.id], payload)
}, initialState)
