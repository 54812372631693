
import React from 'react'
import { AutoSizer } from 'react-virtualized'
import createTable from './table'

export default function UsersTable ({ numberOfActiveUsers, searchedActiveUsers, nonActiveUsers }) {
  return (
    <AutoSizer >
      {({ width, height }) => {
        const _width = width - 20
        const textStyleBold = { width: _width, fontWeight: 'bold' }
        return (
          <>
            {createTable({
              width: _width,
              height: height / 3,
              data: searchedActiveUsers,
              cols: [
                { label: 'User', key: 'email' },
                { label: 'Project owner', key: 'projects' },
                { label: 'Project member', key: 'invited' },
                { label: 'Renders', key: 'renders', width: 20 },
                { label: 'Latest render', key: 'latestRender', width: 40 }
              ],
              headerTitle: 'Active users',
              noDataText: 'No active users'
            })}

            { numberOfActiveUsers && <div style={textStyleBold} className="pt2">{`Total amount of users:${numberOfActiveUsers}`}</div>}

            {createTable({
              width: _width,
              height: height / 4,
              data: nonActiveUsers,
              cols: [{ label: 'User Email', key: 'email' }],
              headerTitle: 'Inactive users',
              noDataText: 'All users are active',
              tableStyle: { paddingTop: '30px' }
            })}

            {nonActiveUsers && <div style={textStyleBold} className="pt2">{`Total amount of inactive users:${nonActiveUsers.length}`}</div>}

          </>
        )
      }}
    </AutoSizer>)
}
