import { createSelector } from 'reselect'

import _filter from 'lodash/filter'
import _orderBy from 'lodash/orderBy'
import _forEach from 'lodash/forEach'

const getLocalState = (state) => state.news

const getArticles = (state) => getLocalState(state).articles
const getAdminArticles = (state) => getLocalState(state).adminArticles

const getFilteredAdminArticles = createSelector(
  getAdminArticles,
  (adminArticles) => _filter(adminArticles, article => !article.removedAt)
)

export const getAdminArticlesByUpdate = createSelector(
  getFilteredAdminArticles,
  (adminArticles) => (_orderBy(adminArticles, ['updatedAt'], ['desc']))
)

export const getLatestPublishedArticle = createSelector(
  getArticles,
  (articles) => {
    let latestArticle = Object.values(articles)[0]

    _forEach(articles, article => {
      if (article.publishedAt > latestArticle.publishedAt) {
        latestArticle = article
      }
    })

    return latestArticle
  }
)
export const getLatestPublishedArticles = createSelector(
  getArticles,
  (articles) => {
    return Object.values(articles).sort((a, b) => b.publishedAt - a.publishedAt).slice(0, 20)
  }
)
