import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { createSelector } from 'reselect'
import { RootState } from '../../../../stores/ducks'
import Grid from '../../../common/grid/grid'
import InputNumericRange from '../../../common/form/input-numeric-range'
import InputNumber from '../../../common/form/input-number'
import IconAngleWide from '../../../common/icons/icon-angle-wide'
import IconAngleNarrow from '../../../common/icons/icon-angle-narrow'
import { setCameraFOV } from '../../../../stores/ducks/threeviewer/camera'
import * as fromThreeviewerUi from '../../../../stores/ducks/threeviewer/ui'
import * as fromThreeviewerViewer from '../../../../stores/ducks/threeviewer/viewer'

const mapStateToProps = createSelector(
  (state: RootState) => state.threeviewer.camera.fov,
  (fov) => {
    return {
      fov: Math.round(fov)
    }
  }
)

const mapDispatchToProps = (dispatch: any) => ({
  setCameraFOV: (value: any) => dispatch(setCameraFOV(value)),
  disableKeyboardListeners: () => {
    dispatch(fromThreeviewerUi.setKeyBoardBindings(false))
    dispatch(fromThreeviewerViewer.disableKeyboardListeners())
  },
  enableKeyboardListeners: () => {
    dispatch(fromThreeviewerUi.setKeyBoardBindings(true))
    dispatch(fromThreeviewerViewer.enableKeyboardListeners())
  }
})

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>
type Props = PropsFromRedux & {
  disabled: boolean
}

function CameraFovControls (props: Props) {
  return (
    <Grid
      columns={['auto', '1fr', 'auto', 'auto']}
      gridGap={'1em'}
      style={{ alignItems: 'center' }}
    >
      <div className='flex flex-column justify-end items-center'>
        <IconAngleNarrow
          size={'2em'}
          className='mb1' />
        <small className='mx1 f8'>
          10°
        </small>
      </div>
      <InputNumericRange
        data-testid='camera-fov-controls-slider'
        disabled={props.disabled}
        onChange={(value: number) => props.setCameraFOV(value)}
        value={props.fov}
        min={10}
        max={90}
        step={1} />
      <div className='flex flex-column justify-end items-center'>
        <IconAngleWide
          size={'2em'}
          className='mb1' />
        <small className='mx1 f8'>
          90°
        </small>
      </div>
      <div className='flex'>
        <InputNumber
          data-testid='camera-fov-controls-numeric'
          onFocus={props.disableKeyboardListeners}
          onBlur={props.enableKeyboardListeners}
          disabled={props.disabled}
          value={props.fov}
          onChange={(value: string) => props.setCameraFOV(Number(value))}
          min={10}
          max={90} />
        <small className='f8 ml1'>°</small>
      </div>
    </Grid>
  )
}

export default connector(CameraFovControls)
