import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import autoBind from 'react-autobind'
import dragDrop from 'drag-drop'

class DragDrop extends PureComponent {
  constructor (props) {
    super(props)
    autoBind(this, 'cleanup', 'update')
    this._removeDragDrop = null
  }

  update () {
    const {
      onDrop,
      onDragOver,
      onDragLeave,
      onDragEnter
    } = this.props

    this.cleanup()
    this._removeDragDrop = dragDrop(this._root, {
      onDrop,
      onDragOver,
      onDragLeave,
      onDragEnter
    })
  }

  cleanup () {
    this._removeDragDrop && this._removeDragDrop()
    this._removeDragDrop = null
  }

  componentDidMount () { this.update() }
  componentDidUpdate () { this.update() }
  componentWillUnmount () { this.cleanup() }

  render () {
    const {
      className,
      children,
      ...rest
    } = this.props

    return (
      <div className={`drag-drop ${className}`} ref={(node) => (this._root = node)} {...rest} data-testid='common-upload-file'>
        {children}
      </div>
    )
  }
}

DragDrop.propTypes = {
  onDragOver: PropTypes.func,
  onDragLeave: PropTypes.func,
  onDragEnter: PropTypes.func,
  onDrop: PropTypes.func
}

DragDrop.defaultProps = {
  className: '',
  onDragOver () {},
  onDragLeave () {},
  onDragEnter () {},
  onDrop () {}
}

export default DragDrop
