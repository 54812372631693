import React from 'react'
import Grid from '../../../common/grid/grid'
import IconFloorplan from '../../../common/icons/icon-perspective-floorplan'
import Icon3D from '../../../common/icons/icon-perspective-3d'
import { LOCKED_MODES, activateFloorplanView, activate3DView, disablePredefinedCamera } from '../../../../stores/ducks/threeviewer/camera'
import cs from 'classnames'
import colors from '../../../../../css/colors'
import { connect, ConnectedProps } from 'react-redux'
import { RootState } from '../../../../stores/ducks'
import { createSelector } from 'reselect'

const mapStateToProps = createSelector(
  (state: RootState) => state.threeviewer.camera.mode,
  (cameraMode) => {
    return { cameraMode }
  }
)

const mapDispatchToProps = (dispatch: any) => {
  return {
    activateFloorplanView: () => dispatch(activateFloorplanView()),
    activate3DView: () => dispatch(activate3DView()),
    disablePredefinedCamera: () => dispatch(disablePredefinedCamera())
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>
type Props = PropsFromRedux & {}

function CameraViewControls (props: Props) {
  return (
    <Grid
      data-testid='camera-perspective-controls'
      columns={['auto', 'auto']}
      gridGap={'1em'}
      style={{ alignItems: 'end', maxWidth: '12em' }}
    >
      <CameraViewButton
        data-testid='camera-perspective-controls-floorplan'
        disabled={props.cameraMode === LOCKED_MODES.OFFSET_VIEW_CAMERA}
        active={props.cameraMode === LOCKED_MODES.FLOORPLAN}
        onClick={() => {
          if (props.cameraMode !== LOCKED_MODES.FLOORPLAN) {
            props.activateFloorplanView()
          }
        }}
        icon={IconFloorplan}
        title='Floorplan' />
      <CameraViewButton
        data-testid='camera-perspective-controls-3d'
        disabled={props.cameraMode === LOCKED_MODES.OFFSET_VIEW_CAMERA}
        active={!props.cameraMode}
        onClick={() => {
          if (props.cameraMode === LOCKED_MODES.FLOORPLAN) {
            props.activate3DView()
          }

          if (props.cameraMode === LOCKED_MODES.PREDEFINED) {
            props.disablePredefinedCamera()
          }
        }}
        icon={Icon3D}
        title='3D' />
    </Grid>
  )
}

const noop = () => { }

export type CameraViewButtonProps = {
  'data-testid'?: string
  disabled: boolean
  active: boolean
  title: string
  onClick: () => void
  icon: (props: { size: string, color: string, className: string }) => any
}

function CameraViewButton (props: CameraViewButtonProps) {
  return (
    <div
      data-testid={props['data-testid']}
      className={cs('block mx-auto', {
        'pointer-disabled': props.disabled
      })}
      onClick={props.disabled ? noop : props.onClick}
    >
      <props.icon
        size='5em'
        color={props.active ? colors.secondary : colors.grayLight}
        className={props.disabled ? '' : 'pointer'} />
      <small className='block mt2 f8 center'>{props.title}</small>
    </div>
  )
}

export default connector(CameraViewControls)
