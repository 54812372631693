import React, { PureComponent } from 'react'
import { createSelector } from 'reselect'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Immutable from 'seamless-immutable'

import _fpReject from 'lodash/fp/reject'
import _fpFilter from 'lodash/fp/filter'
import _fpMap from 'lodash/fp/map'
import _fpGroupBy from 'lodash/fp/groupBy'
import _fpFlow from 'lodash/fp/flow'
import _orderBy from 'lodash/orderBy'
import _reduce from 'lodash/reduce'
import _keyBy from 'lodash/keyBy'
import _get from 'lodash/get'
import _filter from 'lodash/filter'

import navigate from '../../utils/navigate'

import * as storageUtils from '../../utils/storage'

import * as fromRendersSelectors from '../../stores/ducks/renders/selectors'
import * as fromCombinationsSelectors from '../../stores/ducks/combinations/selectors'
import * as fromProjectsSelectors from '../../stores/ducks/projects/selectors'
import * as fromUsersSelectors from '../../stores/ducks/users/selectors'

import * as fromCombinations from '../../stores/ducks/combinations'
import * as fromProjects from '../../stores/ducks/projects'
import api from '../../stores/api'

import Modal from '../common/modal'
import FormEdit from '../common/form/form-edit'
import FormRemove from '../common/form/form-remove'
import ImagePackageCombination from './combination'
import getImageDownloadFile from '../../utils/getImageDownloadFile'

const MODAL_EDIT = 'EDIT'
const MODAL_REMOVE = 'REMOVE'

class CombinationList extends PureComponent {
  constructor (...args) {
    super(...args)

    this.state = {
      modalType: null,
      activeId: null
    }

    this.handleCloseModal = this.handleCloseModal.bind(this)
    this.handleAfterModalOpen = this.handleAfterModalOpen.bind(this)
  }

  handleCloseModal () {
    this.setState({
      modalType: null,
      activeId: null
    })
  }

  handleAfterModalOpen () {
    if (this.state.modalType === MODAL_EDIT) {
      this._editForm && this._editForm.focusFirstInput()
    }
  }

  handleEdit (combinationId, data) {
    this.props.update(combinationId, data)

    if (this.state.modalType) {
      this.handleCloseModal()
    }
  }

  handleRemove (combinationId) {
    this.props.removeCombination(this.props.currentProjectId, combinationId)
    this.handleCloseModal()
  }

  handleDownloadRenders (combinations, designCardTitle) {
    const files = []
    combinations.forEach(combination => {
      const file = getImageDownloadFile(combination.render, `${designCardTitle}_${combination.title}`)
      if (file) files.push(file)
    })

    if (files.length) {
      this.props.downloadRenderImages(files, designCardTitle)
    }
  }

  handleVisualize (id) {
    navigate(`/visualize/${this.props.currentProjectId}/image-package/${id}`)
  }

  renderEditModal () {
    const {
      combinations
    } = this.props

    const {
      activeId,
      modalType
    } = this.state

    const activeCombination = combinations.find(c => c.id === activeId)

    return (
      <Modal
        isOpen={Boolean(modalType)}
        onAfterOpen={this.handleAfterModalOpen}
        onRequestClose={this.handleCloseModal}
        width={600}
      >
        {modalType === MODAL_EDIT && (
          <FormEdit
            ref={(node) => (this._editForm = node)}
            formTitle='Edit design'
            title={activeCombination.title}
            description={activeCombination.description}
            onSubmit={(data) => this.handleEdit(activeId, data)}
            onCancel={this.handleCloseModal}
          />
        )}
        {modalType === MODAL_REMOVE && (
          <FormRemove
            formTitle='Remove design'
            formDescription='Are you really sure that you want to remove this design?'
            onConfirm={() => this.handleRemove(activeId)}
            onCancel={this.handleCloseModal}
            buttonText='Remove'
          />
        )}
      </Modal>
    )
  }

  render () {
    const {
      rootClassName,
      itemClassName,
      combinations
    } = this.props

    return (
      <div className={rootClassName}>
        {combinations.map((combination) => (
          <div key={combination.id} className={itemClassName}>
            <ImagePackageCombination
              {...combination}
              rendersAndPendings={combination.rendersAndPendings}
              disableDownload={combination.renders.length <= 0}
              onDownloadRenders={() => this.handleDownloadRenders(combination.renders, combination.title)}
              onModalEdit={() => this.setState({ activeId: combination.id, modalType: MODAL_EDIT })}
              onEdit={(data) => this.handleEdit(combination.id, data)}
              onRemove={() => this.setState({ activeId: combination.id, modalType: MODAL_REMOVE })}
              onVisualize={() => this.handleVisualize(combination.masterId || combination.id)}
              currentUserId={this.props.currentUserId}
            />
          </div>
        ))}

        {this.renderEditModal()}
      </div>
    )
  }
}

const getThumbnail = (render) => storageUtils.getImageSrc(_get(render, 'manifest.files.0', {}), { type: 'thumbnail', format: 'jpg' })

const mergeVersions = (entries) => {
  return (groupedyParentId) => _reduce(groupedyParentId, (acc, children, parentId) => {
    return acc.merge({
      [parentId]: _fpFlow(
        _fpGroupBy('originalCombinationId'),
        (groupedByOriginalId) => _reduce(groupedByOriginalId, (acc, children, originalCombinationId) => {
          if (!originalCombinationId || originalCombinationId === 'undefined') {
            return acc.concat(children)
          }
          const _children = children.filter(c => !c.isMaster)
          // TODO sometimes parentVersions is empty
          const parentVersions = _get(entries, [parentId, 'versions'], {})

          // TODO should use _children, but sometimes it's empty
          const versions = _children.length
            ? _orderBy(_children, 'timestamp', ['desc'])
            : _orderBy(children, 'timestamp', ['desc'])

          const savedFinalVersionId = parentVersions[originalCombinationId]
          let finalVersion = children.find(child => child.id === savedFinalVersionId)
          if (!finalVersion) {
            finalVersion = versions[0]
          }

          return acc.concat(finalVersion.merge({
            versions,
            latestCompletedRender: _filter(versions || [], version => version.isRender)[0],
            isVersioned: versions.length > 1
          }))
        }, [])
      )(children)
    })
  }, Immutable({}))
}

const getLatestMasterRender = (masters) => {
  return _get(masters, '0.render.manifest')
    ? _get(masters, '0.render')
    : _get(masters, '1.render')
}

const mapStateToProps = createSelector(
  fromCombinationsSelectors.getEntries,
  fromRendersSelectors.getEntries,
  fromProjectsSelectors.getCurrentEntry,
  fromUsersSelectors.getCurrentId,
  (entries, renders, project, currentUserId) => {
    const rendersByCombinationId = _keyBy(renders, 'combinationId')

    const childrenByParent = _fpFlow(
      _fpMap((entry) => entry.merge({ render: rendersByCombinationId[entry.id] })),
      _fpReject((entry) => _get(entry, 'render.removedAt')),
      _fpReject('removedAt'),
      _fpFilter('parentId'),
      _fpFilter('render'),
      _fpMap((entry) => entry.merge({
        timestamp: Date.parse(entry.createdAt),
        renderId: _get(entry, 'render.id'),
        isRender: !!_get(entry, 'render.manifest.files.0'),
        imageSrc: getThumbnail(entry.render)
      })),
      _fpGroupBy('parentId'),
      mergeVersions(entries)
    )(entries)

    const parents = _fpFlow(
      _fpReject('parentId'),
      _fpReject('removedAt'),
      // in future, could use fromProjectsSelectors.getCombinations to get entries filtered by project instead
      _fpFilter((entry) => entry.projectId === project.id),
      _fpMap((entry) => {
        const children = _get(childrenByParent, entry.id, [])
        const masters = _fpFlow(
          _fpFilter(child => child.parentId === entry.id),
          _fpFilter('isMaster'),
          _fpMap(child => child.merge({
            render: rendersByCombinationId[child.id],
            timestamp: Date.parse(child.createdAt)
          })),
          (masters) => _orderBy(masters, 'timestamp', ['desc'])
        )(entries)

        const masterCombinationId = _get(masters, '0.id')

        const render = rendersByCombinationId[entry.id] || getLatestMasterRender(masters)
        const orderBy = 'title'

        const orderedChildren = _fpFlow(
          _fpReject('isMaster'),
          (children) => _orderBy(children, orderBy, ['desc'])
        )(children)

        return entry.merge({
          render,
          hasChildInRenderingState: orderedChildren.filter(child => !child.isRender).length > 0,
          timestamp: Date.parse(entry.createdAt),
          imageSrc: getThumbnail(render),
          masterId: masterCombinationId,
          childCombinationIds: orderedChildren.map(c => c.id),
          rendersAndPendings: orderedChildren,
          renders: orderedChildren.filter(child => child.isRender),
          pending: orderedChildren
            .filter(child => !child.isRender)
            .map((child) => child.render)
            .map((render) => render.merge({
              status: render.renderStatus === 'Failed'
                ? render.renderStatus
                : render.status
            }))
        })
      }),
      (parents) => _orderBy(parents, 'timestamp', ['desc'])
    )(entries)

    return {
      currentUserId,
      currentProjectId: project.id,
      combinations: parents
    }
  }
)

const mapDispatchToProps = (dispatch) => {
  return {
    ...bindActionCreators({
      update: fromCombinations.update,
      removeCombination: fromProjects.removeCombination
    }, dispatch),
    downloadRenderImages: (files, name) => api.downloadZip.storage([].concat(files), name)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CombinationList)
