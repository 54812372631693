import * as THREE from 'three'
import LshEuclidean from './LshEuclidean'

// Docblocks follows Magento standard:
// https://devdocs.magento.com/guides/v2.3/coding-standards/docblock-standard-javascript.html

const HOLE_CELL_SIZE = 0.08
const SEARCH_AREA = HOLE_CELL_SIZE
const DISTANCE_THRESHOLD = 0.0000001
const SNAP_ALIGN_MAX_SNAP_DISTANCE = 0.2
const SNAP_ALIGN_MIN_SNAP_DISTANCE = 0.01
const SNAP_HOLE_RADIUS = 0.005
const SNAP_HOLE_COLOR = 0x00ff00
const ACTIVE_SNAP_HOLE_COLOR = 0xffff00

class SnappingGroup {
  // TODO: rewrite to not be bi-directional?
  constructor (name) {
    this.name = name
    this.boundingbox = new THREE.Box3()
    this.lsh = new LshEuclidean(HOLE_CELL_SIZE)
    this.snappedTo = null
  }

  add (position) {
    this.lsh.add(position.toArray(), position)
    this.boundingbox.expandByPoint(position)
  }

  clone () {
    const clone = new this.constructor(this.name)
    clone.lsh = this.lsh.clone()
    clone.boundingbox = this.boundingbox.clone()
    clone.snappedTo = null
    return clone
  }
}

function _worldPosition (n) {
  return new THREE.Vector3().setFromMatrixPosition(n.matrixWorld)
}

function _worldSpace (source, target) {
  const inverseTargetWorldMatrix = new THREE.Matrix4().copy(target.matrixWorld.clone()).invert()
  return inverseTargetWorldMatrix.multiply(source.matrixWorld)
}

function _alignedSpace (source, target) {
  const matrix = getAlignedSource(source, target)
  const inverseTargetWorldMatrix = new THREE.Matrix4().copy(target.matrixWorld.clone()).invert()
  return inverseTargetWorldMatrix.multiply(matrix)
}

function getAlignedSource (source, target) {
  function extractBasisVectors (matrix) {
    var x = new THREE.Vector3()
    var y = new THREE.Vector3()
    var z = new THREE.Vector3()
    const rot = new THREE.Matrix4().extractRotation(matrix)
    rot.extractBasis(x, y, z)
    return [x, y, z]
  }

  function getClosestAlignedVector (targetVector, vectors) {
    let bestValue = -1
    let bestVector = null
    let bestIndex = -1
    for (let i = 0; i < vectors.length; ++i) {
      const axis = vectors[i]
      const value = targetVector.dot(axis)
      const sign = Math.sign(value)
      if (Math.abs(value) > bestValue) {
        bestValue = value * sign
        bestVector = axis.clone().multiplyScalar(sign)
        bestIndex = i
      }
    }
    vectors.splice(bestIndex, 1)
    return bestVector
  }

  function createAlignedBasis (source, target) {
    const sourceAxes = extractBasisVectors(source)
    const targetAxes = extractBasisVectors(target)
    const newBasis = []
    for (var i = 0; i < 2; ++i) {
      newBasis[i] = getClosestAlignedVector(targetAxes[i], sourceAxes)
    }
    newBasis[2] = new THREE.Vector3().crossVectors(newBasis[0], newBasis[1])
    return new THREE.Matrix4().makeBasis(newBasis[0], newBasis[1], newBasis[2])
  }

  function alignTransform (source, target) {
    const alignedBasis = createAlignedBasis(source, target)
    const targetRotationMatrix = new THREE.Matrix4().extractRotation(target) // could be faster to use quaternions, but then we have to rewrite to pass in THREE objects maybe
    const sourceTranslation = new THREE.Vector3().set(source.elements[12], source.elements[13], source.elements[14])
    const sourceTranslationMatrix = new THREE.Matrix4().makeTranslation(sourceTranslation.x, sourceTranslation.y, sourceTranslation.z)
    const inverseSourceTranslationMatrix = new THREE.Matrix4().makeTranslation(-sourceTranslation.x, -sourceTranslation.y, -sourceTranslation.z)
    const targetQuat = new THREE.Quaternion().setFromRotationMatrix(targetRotationMatrix)
    const alignedQuat = new THREE.Quaternion().setFromRotationMatrix(alignedBasis)
    const alignedQuatDelta = targetQuat.clone().multiply(alignedQuat.clone().conjugate())
    const alignedBasisDelta = new THREE.Matrix4().makeRotationFromQuaternion(alignedQuatDelta)
    const alignedSource = sourceTranslationMatrix.clone().multiply(alignedBasisDelta.clone().multiply(inverseSourceTranslationMatrix.clone().multiply(source)))
    return alignedSource
  }

  return alignTransform(source.matrixWorld, target.matrixWorld)
}

export const SNAP_RESULT = {
  SNAP: 'SNAP',
  NO_SNAP: 'NO_SNAP',
  BLOCKED: 'BLOCKED',
  NO_RESULT: 'NO_RESULT'
}

export default class SnapHelper {
  constructor (debugScene) {
    this.snappedTransformation = {
      translation: undefined,
      rotation: undefined
    }
    this._debugScene = debugScene
    this.snapstate = SNAP_RESULT.NO_RESULT
    this.snappableModels = new LshEuclidean()
    this._candidateCache = new Map()
    this._modelsAttachedTo = new Map()
    this._srcModel = null
    this._snappingspaceFunc = null
    // this._debug = false

    this._holeMarkerMap = {}

    this._firstBoundingBoxCornerArr = [
      new THREE.Vector3(),
      new THREE.Vector3(),
      new THREE.Vector3(),
      new THREE.Vector3(),

      new THREE.Vector3(),
      new THREE.Vector3(),
      new THREE.Vector3(),
      new THREE.Vector3()
    ]

    this._secondBoundingBoxCornerArr = [
      new THREE.Vector3(),
      new THREE.Vector3(),
      new THREE.Vector3(),
      new THREE.Vector3(),

      new THREE.Vector3(),
      new THREE.Vector3(),
      new THREE.Vector3(),
      new THREE.Vector3()
    ]

    this._firstSelection = {
      found: false,
      model: undefined,
      point: undefined,
      index: undefined
    }

    this._snapHoleGeometry = new THREE.SphereBufferGeometry(SNAP_HOLE_RADIUS, 10, 10)
    this._snapHoleMaterial = new THREE.MeshBasicMaterial()
    this._snapHoleColor = new THREE.Color(SNAP_HOLE_COLOR)
    this._activeSnapHoleColor = new THREE.Color(ACTIVE_SNAP_HOLE_COLOR)
  }

  static get RESULT () {
    return SNAP_RESULT
  }

  fillArrWithBBCorners (bb, arr) {
    arr[0] = bb.min.clone()
    arr[1] = bb.max.clone()
    arr[2].set(bb.max.x, bb.min.y, bb.min.z)
    arr[3].set(bb.max.x, bb.max.y, bb.min.z)
    arr[4].set(bb.max.x, bb.min.y, bb.max.z)
    arr[5].set(bb.min.x, bb.max.y, bb.min.z)
    arr[6].set(bb.min.x, bb.max.y, bb.max.z)
    arr[7].set(bb.min.x, bb.min.y, bb.max.z)
    return arr
  }

  trySnapAlign (object, originalBB, originalCenter, models, getSelectedObjectsBB) {
    const closestOtherCorner = new THREE.Vector3()
    const closestTargetCorner = new THREE.Vector3()
    let closestDistanceSquared = Number.POSITIVE_INFINITY

    // reuse same vectors to avoids creating mutliple vectors every second
    const targetCorners = this.fillArrWithBBCorners(originalBB, this._firstBoundingBoxCornerArr)

    for (let i = 0; i < models.length; i++) {
      const otherBB = getSelectedObjectsBB([models[i]])
      const otherCorners = this.fillArrWithBBCorners(otherBB, this._secondBoundingBoxCornerArr)

      for (let j = 0; j < otherCorners.length; j++) {
        const otherCorner = otherCorners[j]

        for (let k = 0; k < targetCorners.length; k++) {
          const targetCorner = targetCorners[k]

          // optimized by calc squared length
          const distSquared = otherCorner.distanceToSquared(targetCorner)

          // avoid bbs snapping inside each other
          if (k === j) continue

          if (distSquared < closestDistanceSquared) {
            closestDistanceSquared = distSquared
            closestTargetCorner.copy(targetCorner)
            closestOtherCorner.copy(otherCorner)
          }
        }
      }
    }

    //  calculate snapping distance based on size

    const maxSnapDistance = SNAP_ALIGN_MAX_SNAP_DISTANCE
    const minSnapDistance = SNAP_ALIGN_MIN_SNAP_DISTANCE
    const bbMinMaxDist = originalBB.min.distanceTo(originalBB.max)
    const formula = Math.sqrt(bbMinMaxDist) * 0.05 + minSnapDistance
    const snappingThreshold = Math.min(maxSnapDistance, formula)
    const closestDistance = closestTargetCorner.distanceTo(closestOtherCorner)
    const cornerOffset = closestTargetCorner.sub(originalCenter)

    if (closestDistance < snappingThreshold) {
      object.position.copy(closestOtherCorner)
      object.position.sub(cornerOffset)
      return true
    }

    return false
  }

  updateHoleMarkers () {
    for (const key in this._holeMarkerMap) {
      const { mesh, model } = this._holeMarkerMap[key]
      mesh.position.copy(model.position)
      mesh.rotation.setFromQuaternion(model.quaternion)
    }
  }

  /**
   * Written to fit with our transform gizmo implementation.
   * The actual selected models are used to snap but the the transformations will only be set to the dummy.
   * This will return the first hit based on the selections
   *
   * @param {SceneGraphNode3d[]} selections - The selected models that holds hole data
   * @param {SceneGraphNode3d} dummy - The object that transforms are applied to
   * @param {THREE.Vector3} originalCenter - From what center the calculations should be calculated (may differ from selections / dummy)
   * @param {SceneGraphNode3d[]} targetModels - models that selections can snap to
   * @param {boolean} matchInWorldSpace - decides if selections should be rotated to match the target alignment or not
   */
  trySnapMultiToHole (selections, dummy, originalCenter, targetModels, matchInWorldSpace) {
    const ignoreList = selections.map(s => s.userData.dbmodelId).filter(s => s)
    selections.forEach(selection => {
      this.unsnap(selection)
    })

    for (let i = 0; i < selections.length; i++) {
      const selection = selections[i]
      const lastPos = selection.position.clone()
      const positionOffset = new THREE.Vector3().subVectors(selection.position, dummy.position)

      selection.position.copy(originalCenter)
      selection.position.add(positionOffset)

      const candidates = targetModels.filter(model => !ignoreList.includes(selection.userData.dbModelId))

      const isSnapped = this.snap(selection, candidates, matchInWorldSpace)
      const isBlocked = this.snapstate === 'BLOCKED'
      selection.position.copy(lastPos)

      if (isSnapped && !isBlocked) {
        // calculate the delta between selection and snappedTransformation to make sure they are synced
        var delta = new THREE.Quaternion().multiplyQuaternions(this.snappedTransformation.rotation, selection.quaternion.clone().invert())
        var newRotation = delta.multiply(dummy.quaternion)

        dummy.quaternion.copy(newRotation)
        dummy.position.copy(this.snappedTransformation.position)
        dummy.position.sub(positionOffset)
        return true
      }
    }
    return false
  }

  /**
   * @param {SceneGraphNode3d} selection - The selected model that holds hole data
   * @param {SceneGraphNode3d} dummy - The object that transforms are applied to
   * @param {THREE.Vector3} originalCenter - From what center the calculations should be calculated (may differ from selections / dummy)
   * @param {SceneGraphNode3d[]} targetModels - models that selections can snap to
   */
  trySnapToHole (selection, dummy, originalCenter, targetModels) {
    let isSnappingToHole = false

    // extract actual selected mesh and store transform
    const lastPos = selection.position.clone()
    selection.position.copy(originalCenter)
    this.unsnap(selection)

    // prevent models to snap inside another instance of the same model. Can be improved futher by filtering out models to far away
    const candidats = targetModels.filter(model => model.userData.dbModelId !== selection.userData.dbModelId)

    // snap will apply transformations on holeSnapHelper snappedTransformation object and selection
    const isSnapped = this.snap(selection, candidats)
    const isBlocked = this.snapstate === 'BLOCKED'

    if (isSnapped && !isBlocked) {
      // calculate the delta between selection and snappedTransformation to make sure they are synced
      var delta = new THREE.Quaternion().multiplyQuaternions(this.snappedTransformation.rotation, selection.quaternion.clone().invert())
      var newRotation = delta.multiply(dummy.quaternion)

      dummy.quaternion.copy(newRotation)
      dummy.position.copy(this.snappedTransformation.position)
      isSnappingToHole = true
    }

    // reset selection
    selection.position.copy(lastPos)

    return isSnappingToHole
  }

  /**
   * Creates meshes for holemarkers
   * @param {SceneGraphNode3d} model - model which contains feature/holes in userData
   * @param {SnappingGroup} snappingGroup - the snappingGroup containing snap info
   * @param {Boolean} visible - if the holes initial state should be visible or not
  */
  addHoleMarkerModel (snappingGroup, model, visible) {
    const holeMesh = new THREE.InstancedMesh(this._snapHoleGeometry, this._snapHoleMaterial, snappingGroup.lsh.length)
    const dictValues = Object.values(snappingGroup.lsh.dictionary)
    const matrix = new THREE.Matrix4()
    let index = 0

    for (let i = 0; i < dictValues.length; i++) {
      const arr = dictValues[i]
      for (let j = 0; j < arr.length; j++) {
        var position = arr[j].value
        matrix.compose(position, new THREE.Quaternion(), new THREE.Vector3(1, 1, 1))
        holeMesh.setMatrixAt(index, matrix)
        index++
      }
    }

    this._holeMarkerMap[model.uuid] = {
      mesh: holeMesh,
      model
    }

    // Set default color for each instance
    for (let i = 0; i < snappingGroup.lsh.length; i++) {
      holeMesh.setColorAt(i, this._snapHoleColor)
    }

    holeMesh.position.copy(model.position)
    holeMesh.rotation.setFromQuaternion(model.quaternion)
    holeMesh.visible = visible

    this._debugScene.add(holeMesh)
  }

  /**
   * Try to add a model to snappable collection, needs to have feature/holes in userData
   *
   * @param {SceneGraphNode3d} model model which contains feature/holes in userData
   * @return {boolean} returns if model was added
   */
  addSnappableModel (model, showMarker = false) {
    let added = false

    if (model.userData.isGroup) {
      model.children.forEach(node => {
        this.addSnappableModel(node, showMarker)
      })
      return false
    }

    if (!model.userData.isModelRoot) return false

    const holesInitialized = model.userData.snappingGroups

    if (!holesInitialized) {
      const snappingGroups = {}

      const inverseModelWorldMatrix = new THREE.Matrix4().copy(model.matrixWorld.clone()).invert()
      model.traverse(n => {
        if (n.userData && n.userData.feature && n.name.toLowerCase().startsWith('snap')) {
          const name = model.uuid

          if (!(name in snappingGroups)) {
            snappingGroups[name] = new SnappingGroup(name)
          }

          const worldpos = _worldPosition(n)
          const localpos = worldpos.applyMatrix4(inverseModelWorldMatrix)
          snappingGroups[name].add(localpos)
          added = true
        }
      })

      if (added) {
        model.userData.snappingGroups = Object.values(snappingGroups)
      }
    }

    if (added || holesInitialized) {
      this.snappableModels.add(model.position.toArray(), model)
      model.userData.snappingGroups.forEach(group => {
        this.addHoleMarkerModel(group, model, showMarker)
      })
    }
    return added || holesInitialized
  }

  /**
 * Try to remove a model from snappable collection
 *
 * @param {SceneGraphNode3d}
 * @return {boolean} returns if model was removed
 */
  removeSnappableModel (model) {
    if (model.userData.isGroup) {
      model.children.forEach(node => {
        this.removeSnappableModel(node)
      })
      return false
    }

    if (!this.isSnappable(model)) {
      return false
    }

    if (this._holeMarkerMap[model.uuid]) {
      this._debugScene.remove(this._holeMarkerMap[model.uuid].mesh)
      delete this._holeMarkerMap[model.uuid]
    }

    return this.snappableModels.remove(model.position.toArray(), model)
  }

  /**
 * Change visibility of holemarker meshes
 * @param {string[]} uuids - uuids of models to be updated
 * @param {boolean} visible - new state of visibility for model
 */
  setHoleMarkersVisibility (uuids, visible) {
    uuids.forEach((uuid) => {
      if (this._holeMarkerMap[uuid]) {
        const { mesh } = this._holeMarkerMap[uuid]
        mesh.visible = visible
      }
    })
  }

  disableHoleMarkers () {
    for (const key in this._holeMarkerMap) {
      const { mesh } = this._holeMarkerMap[key]
      mesh.visible = false
    }
  }

  enableHoleMarkers () {
    for (const key in this._holeMarkerMap) {
      const { mesh } = this._holeMarkerMap[key]
      mesh.visible = true
    }
  }

  /**
 * Tries to find closest snappable model
 * @param {SceneGraphNode3d} SceneGraphNode to search from
 * @param {?Number} searchArea units from model
 * @param {?Bool} sortByDistance set to have result sorted by distance from model bounding box
 * @return {?SceneGraphNode3d} scenegraphnodes around model, result can be sorted on distance from model boundingbox
 */
  closestSnappableModels (model, searchArea, sortByDistance) {
    if (!this.isSnappable(model)) {
      return []
    }

    const expendBy = searchArea || 0.5
    sortByDistance = sortByDistance !== undefined ? sortByDistance : false
    const bb = model.localBoundingBox.clone()
    bb.expandByScalar(expendBy)
    bb.applyMatrix4(model.matrixWorld)
    const models = this.snappableModels.getByBounds(bb.min.toArray(), bb.max.toArray())

    if (!sortByDistance) {
      return models
    }
    return models.sort((a, b) => {
      const wsbbA = a.localBoundingBox().clone().applyMatrix4(a.matrixWorld)
      const wsbbB = b.localBoundingBox().clone().applyMatrix4(b.matrixWorld)
      return wsbbA.distanceTo(model.position) < wsbbB.distanceTo(model.position)
    })
  }

  /**
 * test if model is snappable
 * @param {SceneGraphNode3d} model
 * @return {boolean} is snappable
 */
  isSnappable (model) {
    return model.userData.snappingGroups
  }

  isSnapped (model) {
    const groups = this._getSnappingGroupsFromModel(model)
    return groups.some(f => f.snappedTo)
  }

  nrOfsnappableModels () {
    return this.snappableModels.length
  }

  _noSnap () {
    this.snappedTransformation = {
      position: undefined,
      rotation: undefined
    }
    this.snapstate = SNAP_RESULT.NO_SNAP
  }

  confirmSnap () {
    if (!this._srcModel) {
      console.warn('No source model to confirm snap, snap() needs to be run first and return true before this function can be run')
      return false
    }

    const modelAttachedTo = new Set()

    var snappingGroups = this._getSnappingGroupsFromModel(this._srcModel).map(group => {
      if (this._candidateCache.has(group.name)) {
        const [targetModel, sg] = this._candidateCache.get(group.name)

        group.snappedTo = sg

        const snappingspaceMatrix = this._snappingspaceFunc(this._srcModel, targetModel)
        const snappingspaceBounds = this._srcModel.localBoundingBox.clone().applyMatrix4(snappingspaceMatrix)

        if (!targetModel.userData.occupiedBounds) {
          targetModel.userData.occupiedBounds = {}
        }

        targetModel.userData.occupiedBounds[this._srcModel.id] = snappingspaceBounds
        modelAttachedTo.add(targetModel)
      }
      return group
    })

    this._srcModel.userData.snappingGroups = snappingGroups

    this._modelsAttachedTo.set(this._srcModel.id, modelAttachedTo)
    return true
  }

  unsnap (source) {
    if (!(this._modelsAttachedTo.has(source.id))) {
      return false
    }

    // clear occupied bounds on other model
    this._modelsAttachedTo.get(source.id).forEach(other => {
      const occupiedBounds = other.userData.occupiedBounds
      occupiedBounds[source.id] = null
      delete occupiedBounds[source.id]
    })

    // set this snappinggrouped snapedTo to null
    const snappingGroups = this._getSnappingGroupsFromModel(source)
    snappingGroups.forEach(sg => { sg.snappedTo = null })
  }

  getAttachedTo (model) {
    if (this._modelsAttachedTo.has(model.id)) {
      return Array.from(this._modelsAttachedTo.get(model.id))
    }
    return []
  }

  _getSnappingGroupsFromModel (model) {
    if (this.isSnappable(model)) {
      return model.userData.snappingGroups
    }
    return []
  }

  /**
   * Checks for snapping hits baased on aligned space or world space.
   * World space is looking if there is a snapping match between the source and canditate in world space
   * Aligned space is instead looking if there is a match where the source is aligned to the cantidate
   * @param {SceneGraphNode3d} source
   * @param {SceneGraphNode3d[]} candidateModels
   * @param {boolean} matchInWorldSpace
   */
  snap (source, candidateModels, matchInWorldSpace) {
    var space = matchInWorldSpace ? _worldSpace : _alignedSpace

    if (this._snapInSpace(source, candidateModels, space)) {
      this._snappingspaceFunc = space
      return true
    }

    return false
  }

  /**
   * The snapping function that based on a space function will find
   * the best match for snapping the source to the candidate models
   * @param {SceneGraphNode3d} source
   * @param {SceneGraphNode3d[]} candidateModels
   * @param {Function} spaceFunction
   */

  _snapInSpace (source, candidateModels, spaceFunction) {
    if (!this.isSnappable(source)) {
      this._noSnap()
      return false
    }

    this._srcModel = source
    this._candidateCache.clear()

    const sourceFeatures = this._getSnappingGroupsFromModel(source)
    const featureSnapMatches = {}
    sourceFeatures.forEach(group => {
      featureSnapMatches[group.name] = []
    })

    // Gather snapping matches
    let anyResult = false
    candidateModels.forEach(candidateModel => {
      const snappingSpace = spaceFunction(source, candidateModel)
      const otherSGs = this._getSnappingGroupsFromModel(candidateModel)
      sourceFeatures.forEach(sourceSG => {
        otherSGs.forEach((otherSG, i) => {
          // otherSG is already snapped to source. avoids creating circular references
          if (otherSG.snappedTo && otherSG.snappedTo.name === sourceSG.name) {
            return
          }

          const p = this._snapSnappingGroup(sourceSG, otherSG, snappingSpace, candidateModel.matrixWorld)
          if (p) {
            const snappingSpaceClone = snappingSpace.clone()
            snappingSpaceClone.premultiply(candidateModel.matrixWorld)

            const o = {
              distance: p.distanceTo(source.position),
              position: p.clone(),
              snappingGroup: otherSG,
              model: candidateModel,
              alignMatrix: snappingSpaceClone
            }
            featureSnapMatches[sourceSG.name].push(o)
            anyResult = true
          }
        })
      })
    })

    if (!anyResult) {
      this._noSnap()
      this.snapstate = SNAP_RESULT.NO_RESULT
      return false
    }

    // Filter out the best matches
    let modelMinDistance = Number.MAX_SAFE_INTEGER
    const bestCandidates = {}
    Object.entries(featureSnapMatches).forEach(([groupName, snappableCandidates]) => {
      let featureMin = Number.MAX_SAFE_INTEGER
      let choosen = SNAP_RESULT.NO_SNAP
      const occupied = false
      snappableCandidates.forEach((candidate) => {
        // NOTE: check isOccupied here if we want to enable that agains
        if (candidate.distance < featureMin) {
          featureMin = candidate.distance
          choosen = candidate
        }
      })

      bestCandidates[groupName] = choosen === SNAP_RESULT.NO_SNAP && occupied ? SNAP_RESULT.BLOCKED : choosen
      modelMinDistance = Math.min(modelMinDistance, featureMin)
    })
    const values = Object.values(bestCandidates)
    if (values.every(e => { return e === SNAP_RESULT.NO_SNAP })) {
      this._noSnap()
      return false
    }

    if (values.some(e => { return e === SNAP_RESULT.BLOCKED }) && !values.some(e => { return e === SNAP_RESULT.SNAP })) {
      this._noSnap()
      this.snapstate = SNAP_RESULT.BLOCKED
      return false
    }

    // Find the best result
    Object.entries(bestCandidates).forEach(([groupName, result], i) => {
      if (result.distance === modelMinDistance) {
        const newRotation = new THREE.Quaternion().setFromRotationMatrix(new THREE.Matrix4().extractRotation(result.alignMatrix))
        this.snappedTransformation.rotation = newRotation
        this.snappedTransformation.position = result.position.clone()
        this._candidateCache.set(groupName, [result.model, result.snappingGroup])
        this.snapstate = SNAP_RESULT.SNAP
      }
    })
    return true
  }

  _isOccupied (source, target, newPosition, spaceFunction) {
    if (!target.userData.occupiedBounds) {
      return false
    }

    const snappingspaceMatrix = spaceFunction(source, target)

    const itr = new THREE.Matrix4().copy(target.matrixWorld.clone()).invert()
    const t = newPosition.clone()
    t.applyMatrix4(itr)

    const snappingspaceBounds = source.localBoundingBox.clone()
    snappingspaceBounds.applyMatrix4(snappingspaceMatrix)

    const boxcenter = new THREE.Vector3()
    snappingspaceBounds.getCenter(boxcenter)
    snappingspaceBounds.translate(new THREE.Vector3().subVectors(t, boxcenter))

    const occupied = Object.entries(target.userData.occupiedBounds).some(([id, bound]) => {
      return bound.intersectsBox(snappingspaceBounds)
    })

    return occupied
  }

  _snapSnappingGroup (source, target, snappingspaceMatrix, targetWorldMatrix) {
    const localPositions = source.lsh.getAll()
    const matchingList = []
    const occupiedList = []

    for (let i = 0; i < localPositions.length; i++) {
      const position = localPositions[i].clone().applyMatrix4(snappingspaceMatrix)
      const candidates = target.lsh.get(position.toArray(), SEARCH_AREA)

      if (candidates.length <= 0) {
        continue
      }

      const transformed = position.clone().sub(candidates[0])

      let matchCount = 0

      const closeCandidates2 = target.lsh.get(position.toArray(), DISTANCE_THRESHOLD)
      if (closeCandidates2.length > 0) {
        matchCount++
      }

      for (let j = 0; j < localPositions.length; j++) {
        if (j === i) continue

        const otherPosition = localPositions[j].clone().applyMatrix4(snappingspaceMatrix)
        const transformedPosition = otherPosition.sub(transformed)
        const matches = target.lsh.get(transformedPosition.toArray(), DISTANCE_THRESHOLD).filter(v => {
          for (let i = 0; i < occupiedList.length; i++) {
            if (occupiedList[i].equals(v)) {
              return false
            }
          }
          return true
        })
        if (matches.length > 0) {
          occupiedList.push(matches[0])
          matchCount++
        }
      }

      matchingList.push({ position: transformed, matchCount })
    }

    if (matchingList.length <= 0) {
      return null
    }

    var closestMatch = matchingList.reduce((acc, next) => {
      return next.matchCount > acc.matchCount ? next : acc
    }, matchingList[0])

    return new THREE.Vector3().setFromMatrixPosition(snappingspaceMatrix).sub(closestMatch.position).applyMatrix4(targetWorldMatrix)
  }

  /**
   * Try to find if a snapping point is clicked by the mouse
   * @param {SceneGraphNode3d} dummy - node to be updated
   * @param {SceneGraphNode3d[]} selections - currently selected nodes
   * @param {SceneGraphNode3d[]} objects - all rootnodes in the scene, not only the ones selected
   * @param {Ray} ray - ray from mouse position into the room
   * @param {boolean} matchInWorldSpace - decides if selections should be rotated to match the target alignment or not. false means selections should be rotated
   * @return {Boolean} returns if a snappoint was found
   */
  clickSnapPoint (dummy, selections, objects, ray, matchInWorldSpace) {
    this._candidateCache.clear()

    // Check if we are selecting the first or the second snap point and choose candidates
    const candidates = this._firstSelection.found ? objects : selections

    const closest = this._findSnapPoint(candidates, ray)

    // If we didn't find a point we do nothing
    if (!closest.found) {
      return false
    }

    // If we already have a snapping point selected from previous iteration snap them together.
    // Unless the new point is already selected
    // After we do a cleanup
    if (this._firstSelection.found) {
      // Check if new object is already selected
      const found = !!selections.find(selection => selection.uuid === closest.model.uuid)

      if (!found) {
        // Rename for better readability
        const source =
        {
          model: this._firstSelection.model,
          point: this._firstSelection.point
        }

        const target = closest

        // Update transformation of dummy
        this._updateTransformation(source, target, dummy, matchInWorldSpace)

        // These states are set in the trySnapMultiToHole function chain. Unsure exactly for what purpose but they are set here aswell to be compatible
        this._srcModel = source.model
        this.snapstate = SNAP_RESULT.SNAP
        this._candidateCache.set(target.groupName, [target.model, target.snappGroup])

        // Reset selection
        this._cancelManualSnap()
        return true
      } else {
        this._cancelManualSnap()
      }
    }

    // Else save the point and continue
    this._firstSelection.point = closest.point
    this._firstSelection.model = closest.model
    this._firstSelection.index = closest.index
    this._firstSelection.found = true

    // Update mesh color to mark as selected
    this._setColorInstancedMesh(this._holeMarkerMap[this._firstSelection.model.uuid].mesh, this._firstSelection.index, this._activeSnapHoleColor)

    return true
  }

  _setColorInstancedMesh (mesh, index, color) {
    mesh.setColorAt(index, color)
    mesh.instanceColor.needsUpdate = true
  }

  // Cancel manual snap by resetting state
  _cancelManualSnap () {
    if (this._firstSelection.found) {
      this._firstSelection.found = false

      // Reset the color of the point
      this._setColorInstancedMesh(this._holeMarkerMap[this._firstSelection.model.uuid].mesh, this._firstSelection.index, this._snapHoleColor)
    }
  }

  // Find closest snappoint using provided ray and objects
  _findSnapPoint (objects, ray) {
    let closestPoint
    let closestPointModel
    let closestPointIndex
    let closestGroupName
    let closestSnappGroup
    const minDistToCamera = Infinity
    let found = false

    // Iterate all objects to find all snapping points
    objects.forEach(object => {
      // Recast ray from the point of view of the model
      const rayModelSpace = ray.clone().applyMatrix4(object.matrixWorld.clone().invert())

      const snappingGroups = this._getSnappingGroupsFromModel(object)

      snappingGroups.forEach((snappGroup, i) => {
        const pos = snappGroup.lsh.getAll()

        for (let i = 0; i < pos.length; i++) {
          const point = pos[i]
          // If pos is intersected by mouse save it as a candidate with a reference to the model as well.
          const dist = rayModelSpace.distanceToPoint(point)

          // Should probably sort out the points that are further from the camera than the closest hit immediately when we find them
          if (dist < SNAP_HOLE_RADIUS) {
            // Check if point is closer to camera than previous
            const distToCamera = rayModelSpace.origin.distanceTo(point)
            if (distToCamera < minDistToCamera) {
              closestPoint = point.clone()
              closestPointModel = object
              closestPointIndex = i
              closestGroupName = snappGroup.name
              closestSnappGroup = snappGroup
              found = true
            }
          }
        }
      })
    })

    const closest =
    {
      model: closestPointModel,
      point: closestPoint,
      index: closestPointIndex,
      groupName: closestGroupName,
      snappGroup: closestSnappGroup,
      found: found
    }

    return closest
  }

  // Prepare the object to be moved by setting correct state of dummy object
  _updateTransformation (source, target, dummy, matchInWorldSpace) {
    // Get correct matrix based on which space the match should be performed
    const snappingSpaceMatrix = matchInWorldSpace ? _worldSpace(source.model, target.model) : _alignedSpace(source.model, target.model)
    snappingSpaceMatrix.premultiply(target.model.matrixWorld)

    // Calculate rotation of dummy model
    const newRotation = new THREE.Quaternion().setFromRotationMatrix(new THREE.Matrix4().extractRotation(snappingSpaceMatrix))
    const delta = new THREE.Quaternion().multiplyQuaternions(newRotation, source.model.quaternion.clone().invert())
    const dummyRotation = delta.clone().multiply(dummy.quaternion)

    // Calculate offset between dummy model and source snap-hole

    const dummyToSource = new THREE.Vector3().copy(source.model.position).sub(dummy.position)

    // Get rotation of source model
    const sourceRotation = new THREE.Quaternion().copy(source.model.quaternion)
    const sourceToPoint = new THREE.Vector3().copy(source.point).applyQuaternion(sourceRotation)

    const dummyToPoint = new THREE.Vector3().copy(dummyToSource).add(sourceToPoint)

    // Rotate offset between dummy and point to match new rotation
    const dummyToPointRotate = new THREE.Vector3().copy(dummyToPoint).applyQuaternion(delta)

    // Calculate new position of the dummy model by putting it at the target snap-hole and then subtracting offset between dummy and source snap-hole
    const dummyNewPosition = new THREE.Vector3().copy(target.point).applyMatrix4(target.model.matrixWorld).sub(dummyToPointRotate)

    // Set correct rotation position and position of selected objects
    dummy.quaternion.copy(dummyRotation)
    dummy.position.copy(dummyNewPosition)
  }

  /**
   * We didn't click on any snappoint - cancel process if any
   */
  cancelClickSnapPoint () {
    if (this._firstSelection.found) {
      this._cancelManualSnap()
    }
  }
}
