var inherits = require('inherits')
var EventEmitter = require('events').EventEmitter
var _pick = require('lodash/pick')
var _get = require('lodash/get')

module.exports = function (THREE) {
  inherits(Loader, EventEmitter)

  function _getFloat (value, defaultValue = 1) {
    return isNaN(parseFloat(value)) ? defaultValue : parseFloat(value)
  }

  function Loader (materialLoader, loader) {
    this.filesLoaded = []
    this.materialLoader = materialLoader
    this.loader = loader
  }

  function loadModelFile (loader, url) {
    return loader.load(url)
  }

  Loader.prototype.load = function (uri, { urlPrefix }) {
    this.urlPrefix = urlPrefix || ''

    return window.fetch(`${urlPrefix}${uri}`, { credentials: 'include' })
      .then((res) => { return res.json() })
      .then((json) => {
        return this.loadPipeLine(json, this)
      })
  }

  Loader.prototype.loadPipeLine = function (json, context) {
    context.json = json

    return Promise.all([
      context.settings(json.settings, context),
      context.materials(json.materials, context)
    ])
      .then(([settings, materials]) => {
        return [settings, materials, context.lights(json.lights, context), context.meshes(materials, json.meshes, context)]
      })
      .then(([settings, materials, lights, meshes]) => {
        return [settings, materials, lights, meshes, context.nodes(meshes, json.nodes, context)]
      })
      .then(([settings, materials, lights, meshes, sceneGraph]) => {
        var scaleNode = new THREE.Object3D()

        if (lights) scaleNode.add(lights)
        if (sceneGraph) scaleNode.add(sceneGraph)

        context.geometries(meshes, json.geometries, context)

        scaleNode.scale.multiplyScalar(1 / context.settings.scaleFactor)

        return scaleNode
      })
  }

  Loader.prototype.settings = function (settings, context) {
    // context._debug = true
    Object.assign(context.settings, settings)

    switch (context.settings.unit) {
      case 'mm':
        context.settings.scaleFactor = 1000
    }

    return null
  }

  Loader.prototype.lights = function (lights, context) {
    if (!lights || Object.keys(lights).length === 0) {
      console.warn('GO3DLOADER2 LIGHTS: No lights or empty lights', lights)
      return null
    }

    var lightParent = new THREE.Object3D()

    Object.keys(lights)
      .forEach((key) => {
        var jsonLight = lights[key]

        var matrix = new THREE.Matrix4().fromArray(jsonLight.transform)

        var P = new THREE.Vector3()
        var Q = new THREE.Quaternion()
        var S = new THREE.Vector3()

        matrix.decompose(P, Q, S)

        if (/spotlight/i.test(jsonLight.type)) {
          var spotLight = new THREE.SpotLight()
          spotLight.intensity = _getFloat(jsonLight.intensity)
          spotLight.decay = 2
          spotLight.penumbra = 1

          spotLight.quaternion.copy(Q)
          spotLight.position.copy(P)
          spotLight.scale.copy(S)

          spotLight.angle = (1 - jsonLight.lightDistribution) * Math.PI

          if (spotLight.angle < 0.1) spotLight.angle = 0.1
          if (spotLight.angle > Math.PI / 2) spotLight.angle = Math.PI / 2

          const dir = new THREE.Vector3()
          spotLight.getWorldDirection(dir).negate()
          const newPos = spotLight.position.clone().add(dir.multiplyScalar(context.settings.scaleFactor))

          const target = new THREE.Object3D()
          target.position.copy(newPos)
          lightParent.add(target)
          spotLight.target = target

          lightParent.add(spotLight)
        } else if (/directional/i.test(jsonLight.type)) {
          const dirLight = new THREE.DirectionalLight()
          dirLight.intensity = _getFloat(jsonLight.intensity)

          dirLight.quaternion.copy(Q)
          dirLight.position.copy(P)
          dirLight.scale.copy(S)

          const dir = new THREE.Vector3()
          dirLight.getWorldDirection(dir).negate()
          const newPos = dirLight.position.clone().add(dir.multiplyScalar(context.settings.scaleFactor))
          const target = new THREE.Object3D()
          target.position.copy(newPos)
          lightParent.add(target)
          dirLight.target = target
          lightParent.add(dirLight)
          /*        } else if (/hemisphere/i.test(jsonLight.type)) {
          let hemisphereLight = new THREE.HemisphereLight()
          hemisphereLight.intensity = _getFloat(jsonLight.intensity)

          hemisphereLight.groundColor.setHex(jsonLight.groundColor || '0xffffff')
          hemisphereLight.color.setHex(jsonLight.color || '0xffffff')

          hemisphereLight.position.copy(P)

          lightParent.add(hemisphereLight)
        } else if (/ambient/i.test(jsonLight.type)) {
          let ambientLight = new THREE.AmbientLight()
          ambientLight.intensity = _getFloat(jsonLight.intensity)

          ambientLight.color.setHex(jsonLight.color || '0xffffff')

          ambientLight.position.copy(P)

          lightParent.add(ambientLight)
        } else { */
          console.warn(`GO3DLOADER2 LIGHTS: ${jsonLight.type} not implemented`)
        }
      })

    return lightParent
  }

  function fileNameToFile (fileName, files) {
    if (files && files.length) {
      for (var i = files.length - 1; i >= 0; i--) {
        var file = files[i]
        if (file.name === fileName) {
          return file
        }
      }
    }
    return fileName
  }

  Loader.prototype.geometries = function (meshes, geometries, context) {
    return Object.keys(geometries).forEach((geometryId) => {
      var geo = geometries[geometryId]

      var file = fileNameToFile(geo.fileName, context.inputFiles)
      var prefixedFile = file.name ? file : `${context.urlPrefix}${file}`
      context.filesLoaded.push(prefixedFile)

      loadModelFile(context.loader, prefixedFile)
        .then((obj) => {
          Object.keys(meshes).forEach((meshId) => {
            if (meshes[meshId].geometryId === geometryId) {
              var meshGeo = meshes[meshId].mesh.geometry
              meshGeo.copy(obj.children[0].geometry)
              geo.objFile = obj.children[0].geometry
            }
          })
        })
    })
  }

  Loader.prototype.materials = function (materials, context) {
    return Object.keys(materials)
      .reduce((memo, key) => {
        var json = materials[key]
        var material = new THREE.MeshStandardMaterial()

        material.metalness = _getFloat(json.metalness, 0)
        material.roughness = _getFloat(json.roughness, 1)
        material.color.setHex(json.color || '0xffffff')

        var maps = _pick(json,
          'map',
          'diffuseMap',
          'normalMap',
          'metalnessMap',
          'roughnessMap')

        if (maps.diffuseMap && !maps.map) {
          maps.map = maps.diffuseMap
          delete maps.diffuseMap
        }

        Object.keys(maps).forEach((key) => {
          var file = fileNameToFile(maps[key], context.inputFiles)
          context.filesLoaded.push(file)

          var prefixedFile = file.name ? file : `${context.urlPrefix}${file}`

          context.materialLoader.loadTexture(prefixedFile).then(map => {
            material[key] = map
            material.needsUpdate = true // We can probably remove this.
          })
        })

        return Object.assign({}, memo, {
          [key]: Object.assign({}, materials[key], { material: material })
        })
      }, {})
  }

  Loader.prototype.meshes = function (materials, meshes, context) {
    return Object.keys(meshes)
      .reduce((memo, key) => {
        return Object.assign({}, memo, {
          [key]: Object.assign(
            {},
            meshes[key],
            {
              mesh: new THREE.Mesh(
                undefined,
                materials[meshes[key].materialId].material
              )
            }
          )
        })
      }, {})
  }

  Loader.prototype.nodes = function (meshes, nodes, context) {
    var parent = new THREE.Object3D()

    function rec (parent, nodes) {
      if (!nodes || !parent) return

      Object.keys(nodes).forEach((key) => {
        var json = nodes[key]
        var child = json.meshId ? meshes[json.meshId].mesh.clone() : new THREE.Object3D()

        var matrix = new THREE.Matrix4().fromArray(json.transform)

        var P = new THREE.Vector3()
        var Q = new THREE.Quaternion()
        var S = new THREE.Vector3()
        matrix.decompose(P, Q, S)
        child.quaternion.copy(Q)
        child.position.copy(P)
        child.scale.copy(S)

        child.name = json.displayName

        child.userData = Object.assign(child.userData, json.metadata || {}, { meshId: json.meshId, nodeId: json.uuid })

        parent.add(child)

        if (context._debug) {
          var geometry = _get(context, ['json', 'geometries', _get(meshes, [json.meshId, 'geometryId'])])

          if (geometry && geometry.boundingBox) {
            var jsonBB = geometry.boundingBox
            var min = new THREE.Vector3(jsonBB.min[0], jsonBB.min[1], jsonBB.min[2])
            var max = new THREE.Vector3(jsonBB.max[0], jsonBB.max[1], jsonBB.max[2])
            var bb = new THREE.Box3(min, max)
            var size = bb.getSize(new THREE.Vector3())
            var boxGeometry = new THREE.BoxGeometry(size.x, size.y, size.z)
            var boxMesh = new THREE.Mesh(
              boxGeometry,
              new THREE.MeshBasicMaterial({ color: Math.ceil(Math.random() * 0xffffff), wireframe: true })
            )
            bb.getCenter(boxMesh.position)

            child.add(boxMesh)
          }
        }

        rec(child, json.children)
      })
    }

    rec(parent, nodes)

    return parent
  }

  return Loader
}
