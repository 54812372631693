import moment from 'moment'
import React from 'react'
import styled from 'styled-components'
import * as fromUsers from '../../../stores/ducks/users/api'
import { StorageApiFile as ManifestFile } from '../../../utils'
import IconDesignView from '../../common/icons/icon-design-view'
import { StorageApiThumbnail } from '../../common/storage-api-image'

type Props = {
    onClick: () => void
    file: ManifestFile | null
    connectedAt: string | null
    connectedBy: string
}

interface User {
  active: boolean
  email: string
  id: string
  firstName?: string
  lastName?: string
}

export default function CombinationCard (props: Props) {
  const [user, setUser] = React.useState<User | null>(null)

  React.useEffect(() => {
    getUserMail(props.connectedBy)
  }, [props.connectedBy])

  const getUserMail = async (id: string) => {
    const users = await fromUsers.getByIds([id])
    setUser(users[0])
  }

  return (
    <div className='paper-1 br-small width-100'
      style={{ display: 'grid', gridTemplateColumns: '40% 60%' }}>
      <div className='relative' onClick={props.onClick}>
        <StorageApiThumbnail
          file={props.file}
          className='pointer'
        >
        </StorageApiThumbnail>
        <HoverOverlay>
          <StyledIcon/>
        </HoverOverlay>
      </div>
      <div className='relative p1 flex flex-column justify-center'>
        {user && <div className='py1 c-gray-accessible f6' style={{ overflowWrap: 'break-word' }}>Connected by {user.firstName || user.email} {user.lastName}</div>}
        <time className='c-gray-accessible f7 truncate'>
          {props.connectedAt && <div className='truncate'>{(moment(props.connectedAt).fromNow())}</div>}
        </time>
      </div>
    </div>
  )
}

const Wrapper = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
`

const StyledIcon = () => {
  return (
    <Wrapper>
      <IconDesignView size={24} />
      <div className='ml2'>Visualize</div>
    </Wrapper>
  )
}

const HoverOverlay = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: var(--primaryTransparent);
    cursor: pointer;
  }
  &:hover ${Wrapper} {
    display: flex;
  }
`
