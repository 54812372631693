import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'

import moment from 'moment'
import Immutable from 'seamless-immutable'
import _isEmpty from 'lodash/isEmpty'
import _fpReject from 'lodash/fp/reject'
import _fpFilter from 'lodash/fp/filter'
import _fpMap from 'lodash/fp/map'
import _fpGroupBy from 'lodash/fp/groupBy'
import _fpFlow from 'lodash/fp/flow'

import _orderBy from 'lodash/orderBy'
import _reduce from 'lodash/reduce'
import _keyBy from 'lodash/keyBy'
import _get from 'lodash/get'
import _filter from 'lodash/filter'
import _find from 'lodash/find'

import { getImageSrc } from '../../utils/storage'
import {
  ROOMSET_RENDER_PRESETS,
  RENDER_PRESETS,
  PROJECT_TYPES,
  DEADLINE_STATUS
} from '../../constants'

// Actions
import * as fromRenders from '../../stores/ducks/renders'
import * as fromComments from '../../stores/ducks/comments'
import * as fromProjects from '../../stores/ducks/projects'
import * as fromCombinations from '../../stores/ducks/combinations'
import * as fromSpreadsheet from '../../stores/ducks/spreadsheet'

import api from '../../stores/api'

// Selectors
import * as fromCommentsSelectors from '../../stores/ducks/comments/selectors'
import * as fromRendersSelectors from '../../stores/ducks/renders/selectors'
import * as fromUsersSelectors from '../../stores/ducks/users/selectors'
import * as fromProjectsSelectors from '../../stores/ducks/projects/selectors'

// JSX
import Gallery from './gallery'

class GalleryContainer extends PureComponent {
  componentDidMount () {
    this.props.fetchDataForGallery(this.props.currentProjectId)
  }

  render () {
    const {
      renderId,
      projectType,
      projectId,
      designCardTitle,
      setImagePackageFinalVersion,
      removeRender,
      downloadSpreadsheet,
      downloadRenderImages,
      onBack,
      currentProjectId,
      combinationMasterId,
      renders,
      finalRenders,
      sendToDnp,
      backToText = 'Go Back',
      isCompleteBatchRendered
    } = this.props

    return (
      <Gallery
        renders={renders}
        finalRenders={finalRenders}
        currentProjectId={currentProjectId}
        activeId={renderId}
        projectType={projectType}
        projectId={projectId}
        designCardTitle={designCardTitle}
        setImagePackageFinalVersion={setImagePackageFinalVersion}
        setDefaultRenderId={this.props.setDefaultRenderId}
        onRemove={removeRender}
        onDownloadSpreadsheet={downloadSpreadsheet}
        onDownloadImages={downloadRenderImages}
        combinationMasterId={combinationMasterId}
        onBack={onBack}
        backToText={backToText}
        onSendToDnp={sendToDnp}
        isCompleteBatchRendered={isCompleteBatchRendered}
      />
    )
  }
}

const getRenderResolution = ({ x, y }) => {
  return `${x} x ${y}px`
}

const getAuthor = (state, userId) => {
  const user = fromUsersSelectors.getById(userId)(state)

  if (_isEmpty(user)) return { name: '', email: '' }

  return {
    name: `${user.firstName || ''} ${user.lastName || ''}`.trim(),
    email: user.email
  }
}

const getThumbnail = (render) => getImageSrc(_get(render, 'manifest.files.0', {}), { format: 'jpg', type: 'thumbnail' })

const mergeVersions = (entries) => {
  return (groupedyParentId) => _reduce(groupedyParentId, (acc, children, parentId) => {
    return acc.merge({
      [parentId]: _fpFlow(
        _fpGroupBy('originalCombinationId'),
        (groupedByOriginalId) => _reduce(groupedByOriginalId, (acc, children, originalCombinationId) => {
          if (!originalCombinationId || originalCombinationId === 'undefined') {
            return acc.concat(children)
          }
          const _children = children.filter(c => !c.isMaster)
          const parent = _find(entries, { id: parentId })
          const parentVersions = _get(parent, 'versions', {})

          const versions = _orderBy(_children, 'timestamp', ['desc'])
          const savedFinalVersionId = parentVersions[originalCombinationId]
          let finalVersion = children.find(child => child.combinationId === savedFinalVersionId)
          if (!finalVersion) {
            finalVersion = versions[0]
          }
          return acc.concat(versions.map(version => version.merge({
            versions: versions,
            latestCompletedRender: _filter(versions || [], version => version.isRender)[0],
            isDefault: version.combinationId === finalVersion.combinationId,
            isVersioned: versions.length > 1
          })))
        }, [])
      )(children)
    })
  }, Immutable({}))
}

const getRenderQualityTitle = (renderQuality) => {
  // Does not take in to account custom presets on roomset doc.
  const roomsetPreset = _get(ROOMSET_RENDER_PRESETS, [renderQuality])
  const defaultPreset = _get(RENDER_PRESETS, [renderQuality])
  return _get(roomsetPreset || defaultPreset, 'title', 'n/a')
}

const mapStateToProps = (state, ownProps) => createSelector(
  fromProjectsSelectors.getCombinations,
  fromRendersSelectors.getEntries,
  fromProjectsSelectors.getCurrentId,
  fromProjectsSelectors.getCurrentProjectType,
  (combinations, renders, currentProjectId, projectType) => {
    const rendersByCombinationId = _keyBy(renders, 'combinationId')
    const _projectType = projectType || PROJECT_TYPES.DEFAULT

    if (_projectType !== PROJECT_TYPES.IMAGE_PACKAGE) {
      let isCompleteBatchRendered = false
      const hasAnyRenderBatchParentId = ownProps.rendersInProject.find(render => render.batchParentId)
      if (hasAnyRenderBatchParentId) {
        let allRenders = []
        ownProps.rendersInProject.forEach(render => {
          allRenders = [...allRenders, ...(render.renders || [render.render])]
        })
        isCompleteBatchRendered = allRenders.length
          ? allRenders.every(render => render.deadlineStatus === DEADLINE_STATUS.COMPLETED)
          : false
      }

      return {
        renders: _orderBy(ownProps.rendersInProject, ['createdAt'], ['desc']),
        finalRenders: _orderBy(ownProps.rendersInProject, ['createdAt'], ['desc']),
        projectType: projectType || PROJECT_TYPES.DEFAULT,
        currentProjectId,
        isCompleteBatchRendered
      }
    }

    const childrenByParent = _fpFlow(
      _fpMap((entry) => entry.merge({ render: rendersByCombinationId[entry.id] })),
      _fpReject((entry) => _get(entry, 'render.removedAt')),
      _fpReject('removedAt'),
      _fpFilter('parentId'),
      _fpFilter('render'),
      _fpMap((entry) => {
        const render = entry.render
        const size = _get(render, 'cameraSettings.resolution', { x: 0, y: 0 })
        const imageFile = _get(render, 'manifest.files', [])
          .filter((file) => /\.(png|jpe?g)+$/i.test(file.name))[0] || {}
        const comments = fromCommentsSelectors.getEntriesByParentEntityId(render.id, state)

        return entry.merge({
          id: render.id,
          timestamp: Date.parse(_get(render, 'createdAt', entry.createdAt)),
          renderId: _get(entry, 'render.id'),
          isRender: !!_get(entry, 'render.manifest.files.0'),
          imageSrc: getThumbnail(entry.render),
          combinationId: render.combinationId,
          combinationTitle: entry.title,
          projectType: _projectType,
          commentsCount: _get(comments, 'length', 0),
          comments,
          size,
          images: {
            large: getImageSrc(imageFile, {
              format: 'jpg',
              type: 'large',
              quality: 90
            }),
            medium: getImageSrc(imageFile, {
              format: 'jpg',
              type: 'medium'
            }),
            thumbnail: getImageSrc(imageFile, {
              format: 'jpg',
              type: 'thumbnail'
            }),
            original: getImageSrc(imageFile, {})
          },
          manifest: render.manifest,
          author: getAuthor(state, _get(render, 'createdBy')),
          renderedAt: moment(_get(render, 'renderedAt')).fromNow(),
          resolution: getRenderResolution(size),
          quality: getRenderQualityTitle(render.renderQuality),
          rendered: render.rendered,
          renderStatus: render.renderStatus,
          renderPercentage: render.renderPercentage,
          deadlineStatus: render.deadlineStatus
        })
      }),
      _fpReject((render) => _get(combinations, [render.parentId, 'removedAt'])),
      _fpGroupBy('parentId'),
      mergeVersions(combinations)
    )(combinations)

    const children = _get(childrenByParent, [ownProps.parentCombinationId], [])
    const combinationMasterId = _orderBy(_filter(combinations, { isMaster: true, parentId: ownProps.parentCombinationId }), 'createdAt', ['desc'])[0].id

    const galleryState = {
      currentProjectId,
      projectType: _projectType,
      renders: _orderBy(children, 'timestamp', ['desc']),
      combinationMasterId,
      finalRenders: _orderBy(children.filter(c => c.isDefault), 'combinationTitle', ['desc'])
    }
    return galleryState
  }
)(state, ownProps)

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchDataForGallery: (currentProjectId) => {
    dispatch(fromComments.getAllComments(currentProjectId))
    if (ownProps.projectId) {
      dispatch(fromProjects.getProject(ownProps.projectId, { mergeCombinations: true, force: true }))
    }
  },
  removeRender: (ids) => dispatch(fromRenders.remove([].concat(ids))),
  downloadSpreadsheet: (ids) => dispatch(fromSpreadsheet.getRenderSpreadsheet(ids)),
  downloadRenderImages: (files, name) => api.downloadZip.storage([].concat(files), name),
  setImagePackageFinalVersion: (id) => dispatch(fromCombinations.setImagePackageFinalVersion(id)),
  sendToDnp: (projectId, renderIds) => dispatch(fromProjects.sendToDnp(projectId, renderIds))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GalleryContainer)
