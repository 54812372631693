import React, { useState } from 'react'
import { MdArrowBack as IconBack } from 'react-icons/md'
import { v4 as uuid } from 'uuid'
// Constants
import {
  mapToValueLabel,
  ROOMSET_COUNTRIES
} from '../../constants'
import type { FilteredRoomset } from '../../stores/ducks/roomsets/Roomset'
import type { StorageApiImageType } from '../../utils/storage'
import { getImageSrc } from '../../utils/storage'
import Button from '../common/button'
import Grid from '../common/grid/grid'
import Image from '../common/image'
import ImageCarousel from '../common/image-carousel/index'

const ROOMSET_LABELS = mapToValueLabel(ROOMSET_COUNTRIES)

interface Props {
  selectedRoomset: FilteredRoomset
  onLoad: (camera: any) => void
  onCloseCameras: () => void
}

const CamerasView = ({
  onLoad,
  onCloseCameras,
  selectedRoomset
}: Props) => {
  const [activeIndex, setActiveIndex] = useState(0)

  const getMarketArea = () => {
    const marketAreas = selectedRoomset.metadata.marketArea

    return marketAreas?.map((area: string) => {
      const country = ROOMSET_LABELS.find((label) => label.value === area)
      return country && country.label
    }).join('/')
  }

  const getCameraImages = () => (
    getImages('large').map((image: any) => {
      return (
        <Image
          key={uuid()}
          src={image}
          style={{
            maxHeight: 'calc(100vh - 370px)',
            objectFit: 'scale-down',
            objectPosition: 'center',
            gridArea: 'image'
          }}
          className='height-100 userselect-none'
        />
      )
    })
  )

  const getImages = (type: StorageApiImageType) => (
    selectedRoomset.cameras.map((camera) => (
      getImageSrc(camera.image, {
        format: 'jpg',
        type: type
      })
    ))
  )

  const cameraImages = React.useMemo(
    () => getCameraImages(),
    [selectedRoomset]
  )

  const marketArea = React.useMemo(
    () => getMarketArea(),
    [selectedRoomset]
  )

  return (
    <Grid
      rows={['1rem', 'auto', '2.5rem']}
      columns={['1fr']}
      areas={[
        'topbar topbar topbar',
        'carousel carousel carousel',
        'confirm confirm confirm'
      ]}
      style={{
        maxHeight: '100%',
        gridRowGap: '1rem',
        gridColumnGap: 0
      }}
      className='height-100 overflow-hidden custom-scrollbar-inverted z2'
    >
      {/* Topbar */}
      <div
        className='flex'
        style={{ gridArea: 'topbar' }}
      >
        <div
          className='pointer flex items-center goBack width-25 truncate'
          onClick={onCloseCameras}
        >
          <IconBack className='mr1' size={'1.2rem'} />
          <p className='truncate'>Filter Results</p>
        </div>

        <div className='flex flex-none items-center justify-center width-50'>
          <h4 className='m0 truncate regular'>
            <span className='bold'>{selectedRoomset.title}</span>,
            {marketArea},
            {selectedRoomset.metadata.area}
              m<sup>2</sup>
          </h4>
        </div>
      </div>

      {/* Carousel */}
      <div
        className='overflow-hidden c-gray-dark'
        style={{ gridArea: 'carousel' }}
      >
        <ImageCarousel
          onChange={(index: number) => setActiveIndex(index)}
          startAt={activeIndex}
          thumbnails={getImages('thumbnail')}
          secondary
        >
          {cameraImages}
        </ImageCarousel>
      </div>

      {/* Confirm/Cancel */}
      <div
        className='flex justify-end'
        style={{ gridArea: 'confirm' }}
      >
        <Button
          className='mr1'
          onClick={onCloseCameras}
        >
              Cancel
        </Button>
        <Button
          btnType='primary'
          onClick={() => onLoad(selectedRoomset.cameras[activeIndex])}
        >
              Load This Camera
        </Button>
      </div>
    </Grid>
  )
}

export default React.memo(CamerasView)
