import {
  BufferAttribute,
  BufferGeometry,
  Mesh,
  MeshBasicMaterial,
  Vector3,
  VertexColors
} from 'three'

/**
 * Possible future improvments would be to avoid using unproject by changing the vertex shader
 */
export default class LineDrawer {
  constructor (scene) {
    this.material = new MeshBasicMaterial({ vertexColors: VertexColors, depthTest: false })
    this.geometry = new BufferGeometry()
    this.scene = scene

    const maxNumLines = 100000
    const maxVertices = maxNumLines * 6

    var verticesAttribute = new BufferAttribute(new Float32Array(maxVertices * 3), 3)
    var colorAttribute = new BufferAttribute(new Float32Array(maxVertices * 3), 3)
    verticesAttribute.dynamic = true
    colorAttribute.dynamic = true
    this.geometry.setAttribute('position', verticesAttribute)
    this.geometry.setAttribute('color', colorAttribute)

    this.lineMesh = new Mesh(this.geometry, this.material)
    this.lineMesh.frustumCulled = false

    scene.add(this.lineMesh)

    this.verticesUsed = 0
    this.geometry.setDrawRange(0, 0)

    this.vertexArray = this.geometry.attributes.position.array
    this.colorArray = this.geometry.attributes.color.array
    const work = new Vector3()

    this.addVertex = (camera, x, y) => {
      work.set(x, y, 0)
      work.unproject(camera)
      this.vertexArray[this.verticesUsed++] = work.x
      this.vertexArray[this.verticesUsed++] = work.y
      this.vertexArray[this.verticesUsed++] = work.z
    }

    this.addVertexColor = (camera, x, y, color) => {
      work.set(x, y, 0)
      work.unproject(camera)
      this.colorArray[this.verticesUsed] = color.r
      this.vertexArray[this.verticesUsed++] = work.x
      this.colorArray[this.verticesUsed] = color.g
      this.vertexArray[this.verticesUsed++] = work.y
      this.colorArray[this.verticesUsed] = color.b
      this.vertexArray[this.verticesUsed++] = work.z
    }
  }

  dispose () {
    this.scene.remove(this.lineMesh)
    this.geometry.dispose()
    this.material.dispose()
  }

  setVisible (visible) {
    this.lineMesh.visible = visible
  }

  // Note: This expansion can be done in a vertex shader
  drawLine (x1, y1, x2, y2, aspect, color, thickness) {
    let perpX = y2 - y1
    let perpY = x1 - x2
    const perpLen = Math.sqrt(perpX * perpX + perpY * perpY)
    perpX *= aspect * thickness / perpLen
    perpY *= thickness / perpLen

    const start = this.verticesUsed
    this.addVertex(this.camera, x1 + perpX, y1 + perpY) // 0
    this.addVertex(this.camera, x2 + perpX, y2 + perpY) // 1
    this.addVertex(this.camera, x2 - perpX, y2 - perpY) // 2
    this.addVertex(this.camera, x1 + perpX, y1 + perpY) // 0
    this.addVertex(this.camera, x2 - perpX, y2 - perpY) // 2
    this.addVertex(this.camera, x1 - perpX, y1 - perpY) // 3

    // We set all colors in one go
    for (let i = start; i < this.verticesUsed;) {
      this.colorArray[i++] = color.r
      this.colorArray[i++] = color.g
      this.colorArray[i++] = color.b
    }
  }

  drawCircle (x1, y1, aspect, outerRadius, innerRadius, outerColor, innerColor, segments = 16) {
    const af0 = ((segments - 1) / segments) * (2.0 * Math.PI)
    let p0ix = Math.cos(af0) * innerRadius * aspect + x1
    let p0iy = Math.sin(af0) * innerRadius + y1
    let p0ox = Math.cos(af0) * outerRadius * aspect + x1
    let p0oy = Math.sin(af0) * outerRadius + y1

    for (let a = 0; a < segments; a++) {
      const af = (a / segments) * (2.0 * Math.PI)
      const caf = Math.cos(af)
      const saf = Math.sin(af)

      const p1ix = caf * innerRadius * aspect + x1
      const p1iy = saf * innerRadius + y1
      const p1ox = caf * outerRadius * aspect + x1
      const p1oy = saf * outerRadius + y1

      // Outer quad
      this.addVertexColor(this.camera, p0ix, p0iy, outerColor)
      this.addVertexColor(this.camera, p0ox, p0oy, outerColor)
      this.addVertexColor(this.camera, p1ox, p1oy, outerColor)
      this.addVertexColor(this.camera, p0ix, p0iy, outerColor)
      this.addVertexColor(this.camera, p1ox, p1oy, outerColor)
      this.addVertexColor(this.camera, p1ix, p1iy, outerColor)

      // Inner quad
      this.addVertexColor(this.camera, x1, y1, innerColor)
      this.addVertexColor(this.camera, p0ix, p0iy, innerColor)
      this.addVertexColor(this.camera, p1ix, p1iy, innerColor)
      this.addVertexColor(this.camera, x1, y1, innerColor)
      this.addVertexColor(this.camera, p1ix, p1iy, innerColor)
      this.addVertexColor(this.camera, x1, y1, innerColor)

      p0ix = p1ix
      p0iy = p1iy
      p0ox = p1ox
      p0oy = p1oy
    }
  }

  drawLineWithCircle (x1, y1, x2, y2, aspect, color, innerCircleColor, outerRadius = 0.05, innerRadius = 0.025, thickness = 0.005) {
    // First draw the line.
    // Line beneath circle is simply overdrawn which is ok as long as we dont need transparency
    this.drawLine(x1, y1, x2, y2, aspect, color, thickness)
    this.drawCircle(x2, y2, aspect, thickness, thickness, color, color)
    this.drawCircle(x1, y1, aspect, outerRadius, innerRadius, color, innerCircleColor)
  }

  beginFrame (camera) {
    this.camera = camera
    this.verticesUsed = 0
  }

  finishFrame () {
    this.geometry.attributes.position.needsUpdate = true
    this.geometry.attributes.color.needsUpdate = true
    this.geometry.verticesNeedUpdate = true
    this.geometry.setDrawRange(0, this.verticesUsed / 3)
  }

  clear () {
    this.geometry.verticesNeedUpdate = true
    this.geometry.setDrawRange(0, 0)
  }
}
