import React from 'react'
import cs from 'classnames'
import PropTypes from 'prop-types'

import Avatar from '../../common/avatar'

import AddComment from '../../common/comments/add-or-update'
import CommentCounter from '../../common/comments/comment-counter'
import CommentHeader from '../../common/comments/comment-header'

// dummy for rendering comments from gdpr deleted users
const deletedUser = {
  active: false,
  firstName: 'deleted user',
  lastName: '',
  email: 'deleted user'
}

function Comment (props) {
  const [isEditing, setIsEditing] = React.useState(false)
  const addCommentRef = React.useRef(null)
  const counterRef = React.useRef(null)

  React.useEffect(() => {
    window.requestAnimationFrame(() => {
      counterRef.current && counterRef.current.scrollIntoViewIfNeeded()
    })
  }, [props.isSelected])

  function handleEdit (newText) {
    const comment = {
      id: props.id,
      parentEntityId: props.activeRenderId,
      comment: newText
    }

    props.onEdit(comment)
  }

  function handleCollapseEdit () {
    setIsEditing(false)
    props.onCommentFocus(false)
  }

  function handleExpandEdit () {
    setIsEditing(true)
    props.onCommentFocus(true)
  }

  function handleAdd (commentText) {
    const comment = {
      comment: commentText,
      parentEntityId: props.activeRenderId,
      parentEntityType: 'renders',
      parentId: props.id
    }
    props.onAdd(comment)
  }

  return (
    <div
      className={cs('relative py1', {
        pl1: !props.isReply
      })}
    >
      <div className={cs('bg-white-10 br-2 c-gray f6 p1', { 'paper-2 pl1': !props.isReply })}>
        <div className='flex'>
          <Avatar user={props.author || deletedUser} />

          <div className='ml1 width-100'>
            <CommentHeader
              commentId={props.id}
              author={props.author || deletedUser}
              currentUserId={props.currentUserId}
              createdAt={props.createdAt}
              likes={props.likes}
              likedByCurrentUser={props.likedByCurrentUser}
              onLike={props.onLike}
              onEdit={handleExpandEdit}
              onRemove={() => props.onRemove(props.id)}
              likeEnabled
              editEnabled
              removeEnabled
            />
            <div
              className='c-gray-lighter mb1'
              ref={addCommentRef}
            >
              {isEditing && (
                <AddComment
                  isUpdate
                  expanded={isEditing}
                  oldComment={addCommentRef.current.textContent}
                  onCancel={handleCollapseEdit}
                  onSubmit={handleEdit}
                  lightBorder={false}
                />
              )}
              {!isEditing && (
                <div style={{ whiteSpace: 'pre-wrap' }}>{props.comment}</div>
              )}
            </div>

            {props.replies.map((reply) => (
              <Comment
                {...reply}
                key={reply.id}
                onCommentFocus={props.onCommentFocus}
                activeRenderId={props.activeRenderId}
                currentUserId={props.currentUserId}
                commentId={reply.id}
                parentCommentId={props.id}
                onEdit={props.onEdit}
                onLike={props.onLike}
                onRemove={() => props.onRemove(reply.id)}
                isReply
              />
            ))}

            {!props.isReply &&
              <AddComment
                isReply
                onExpand={() => props.onCommentFocus(true)}
                onCancel={() => props.onCommentFocus(false)}
                onSubmit={handleAdd}
                lightBorder={false}
              />
            }
          </div>
        </div>
      </div>
      {props.position && (
        <>
          <div
            ref={counterRef}
            className='absolute top-0 left-0'
          />
          <CommentCounter
            className='absolute left-0 top-0'
            size={22}
            isActive={props.isSelected}
            count={props.index}
            onClick={props.onToggleSelect}
          />
        </>
      )}
    </div>
  )
}

Comment.propTypes = {
  likedByCurrentUser: PropTypes.bool,
  id: PropTypes.string,
  isReply: PropTypes.bool,
  likes: PropTypes.array,
  author: PropTypes.object,
  createdAt: PropTypes.string,
  comment: PropTypes.string,
  currentUserId: PropTypes.string,
  position: PropTypes.object,
  activeRenderId: PropTypes.string,
  isSelected: PropTypes.bool,
  index: PropTypes.number,
  onSelectCommentAnchor: PropTypes.func,
  onRemove: PropTypes.func,
  onEdit: PropTypes.func
}

export default Comment
