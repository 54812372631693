import React, { useState, useEffect, useRef } from 'react'
import { Column, Table, AutoSizer } from 'react-virtualized'
import LargeCenteredLoader, { CenteredDiv } from './LargeCenteredLoader'
const css = require('./index.css')

type BocPart = {
  id: string
  name: string
}

type Props = {
  searchText: string
  onClick: (id:string) => void
  waitForCallback: boolean
  triggerSearch: boolean
  setTriggerSearch: React.Dispatch<boolean>
}

const BocSearchResultList = ({ searchText, onClick, waitForCallback, triggerSearch, setTriggerSearch } : Props) => {
  const [selectedIndex, setSelectedIndex] = useState<null | number>(null)
  const [list, setList] = useState<null | BocPart[] >(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const lastAbortController = useRef<AbortController>()

  const abortLastRequest = () => {
    if (lastAbortController.current) {
      lastAbortController.current.abort()
    }
  }

  const requestFinished = () => {
    setLoading(false)
    setTriggerSearch(false)
  }

  const setAbortStatus = () => {
    setError('Request canceled')
    requestFinished()
  }

  const fetchData = async (searchString:string) => {
    setLoading(true)
    setError(null)

    abortLastRequest()

    const currentAbortController = new AbortController()
    lastAbortController.current = currentAbortController

    try {
      const result = await fetch(`/api/plm/search/${searchString}`, {
        signal: currentAbortController.signal,
      })
      const json: any = await result.json()

      if (currentAbortController.signal.aborted) {
        setAbortStatus()
        return
      }

      if (json.length > 0) setList(json)
      else setError('No search match')
    } catch (e) {
      if (currentAbortController.signal.aborted) {
        setAbortStatus()
        return
      }
      setError('Request failed, please try again')
    }
    requestFinished()

    return () => {
      abortLastRequest()
    }
  }

  useEffect(() => {
    if (!triggerSearch) return
    const hasLength = searchText.length > 3
    if (hasLength) fetchData(searchText)
    else if (!hasLength && triggerSearch) {
      setTriggerSearch(false)
      setList(null)
    } else setList(null)
  }, [triggerSearch])

  if (loading) {
    return <LargeCenteredLoader
      abort={() => {
        abortLastRequest()
        setAbortStatus()
      }}
    />
  }
  if (error !== null) return <CenteredDiv>{error}</CenteredDiv>

  return (list ? <AutoSizer>
    {({ width, height } : {width:any, height:any}) => (
      <Table
        disableHeader={false}
        rowGetter={({ index } : { index : number}) => list[index]}
        rowStyle={({ index } : any) => index === selectedIndex ? { border: '2px solid var(--secondary)' } : { outline: 'none' }}
        headerHeight={40}
        rowHeight={30}
        rowCount={list.length}
        width={width}
        height={height}
        gridClassName='bc-black-55 f6'
        onRowClick={({ rowData, index }:{ rowData: BocPart, index: number }) => {
          if (!waitForCallback) {
            setSelectedIndex(index)
            onClick(rowData.id)
          }
        }}
        rowClassName={css.row}
      >
        <Column
          width={3 * width / 4}
          label='Name'
          dataKey='name'
          flexGrow={1}
          disableSort
        />
        <Column
          width={width / 4}
          label='Number'
          dataKey='number'
          flexGrow={1}
          disableSort
        />
      </Table>
    )}

  </AutoSizer> : <CenteredDiv>Search to show available BOC parts</CenteredDiv>)
}

export default BocSearchResultList
