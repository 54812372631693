import { createSelector } from 'reselect'

import _isEmpty from 'lodash/isEmpty'
import _orderBy from 'lodash/orderBy'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _filter from 'lodash/filter'
import _find from 'lodash/find'
import _groupBy from 'lodash/groupBy'
import _every from 'lodash/every'
import _pick from 'lodash/pick'
import _forEach from 'lodash/forEach'
import _includes from 'lodash/includes'

import * as fromProjectsSelectors from '../../../stores/ducks/projects/selectors'
import * as fromUploadsSelectors from '../../../stores/ducks/uploads/selectors'
import * as fromUsersSelectors from '../../../stores/ducks/users/selectors'
import * as fromFoldersSelectors from '../../../stores/ducks/folders/selectors'
import * as fromRendersSelectors from '../../../stores/ducks/renders/selectors'
import * as fromJobsSelectors from '../../../stores/ducks/jobs/selectors'
import * as fromInterfaceSelectors from '../../../stores/ducks/interface/selectors'
import * as fromSceneSelectors from '../../../stores/ducks/scenes/selectors'

import * as fromProjects from '../../../stores/ducks/projects'
import * as storageUtils from '../../../utils/storage'

import { DEADLINE_STATUS } from '../../../constants'
import { getDeadlineStatus } from '../../../utils/imageTemplateUtils'

import type { RootState } from '../../../stores/ducks'
import type { Pattern, ProjectCardPattern } from '../../../stores/ducks/patterns/Pattern'
import type { Job } from '../../../stores/ducks/jobs/Job'
import type { ProjectCardCombination } from '../../../stores/ducks/combinations/Combination'

// selectors used just for the project
const selectPatterns = createSelector(
  fromProjectsSelectors.getCurrentEntry,
  (state: RootState) => {
    const { result, entries } = state.patterns.json
    return result.map(id => entries[id]).filter(Boolean)
  },
  fromJobsSelectors.getEntries,
  fromInterfaceSelectors.getNewPatternIds,
  (
    project,
    patterns,
    patternJobs,
    newPatternIds
  ): ProjectCardPattern[] => {
    const patternJobsBySourceId = _groupBy(patternJobs, 'sourceId')
    const unorderedPatterns: any[] = []

    patterns.forEach((pattern: Pattern) => {
      if (pattern.removedAt) return

      const jobs = _get(patternJobsBySourceId, pattern.id, [])

      if (!_every(jobs, (job) => job.status === 'Completed' && job.percentage === 100)) return

      const { dimensions, manifest } = pattern
      const inCurrentProject = (pattern.projects || []).includes(project.id)

      if (!inCurrentProject && !pattern.sentAssetId) return

      unorderedPatterns.push({
        ..._pick(pattern, [
          'id',
          'imgSource',
          'imgOriginal',
          'title',
          'createdAt',
          'modifiedAt',
          'manifest',
          'sentAssetId',
          'sentBy',
          'patternFilePath'
        ]),
        timestamp: Date.parse(pattern.createdAt),
        imgOriginal: storageUtils.getImageSrc(_get(manifest, 'files.0', {}), {}),
        jobs,
        subtitle: dimensions ? `${dimensions.width}x${dimensions.height}${dimensions.units}` : 'n/a',
        inCurrentProject,
        contentType: 'pattern',
        isNew: _includes(newPatternIds, pattern.id)
      })
    })

    return _orderBy(unorderedPatterns, ['timestamp'], ['desc'])
  }
)

const selectPatternsInProgress = createSelector(
  fromJobsSelectors.getEntries,
  (state: RootState) => state.patterns.json.entries,
  (state: RootState) => state.projects.currentId,
  (jobs, patterns, currentProjectId) => {
    const patternJobsBySourceId = _groupBy(jobs, 'sourceId')
    const patternsInProgress: any[] = []
    _forEach(patternJobsBySourceId, (jobGroup) => {
      const everyJobIsValid = _every(jobGroup, (job) => (
        (job.status === 'Completed' && job.percentage === 100) ||
        job.shouldProcess === false ||
        job.type === 'bake' ||
        job.type === 'ar-model' ||
        job.type === 'ar-model-reduce' ||
        job.type === 'export-combination' ||
        job.type === 'export-combination-reduce'
      ))
      if (!everyJobIsValid) {
        // pattern jobs does not contain uploadMetadata or title
        // so in order for progress-card to show file title
        // it is appended to the jobGroup object
        let title
        const pattern: Pattern | undefined = _get(patterns, jobGroup[0].sourceId)
        if (!pattern) return
        const inCurrentProject = currentProjectId && (pattern.projects || []).includes(currentProjectId || '')
        if (!inCurrentProject) return
        if (pattern) title = pattern.title
        patternsInProgress.push({ ...jobGroup[0], title })
      }
    })
    return patternsInProgress as (Job & { title: string })[]
  }
)

const selectCombinations = createSelector(
  fromProjectsSelectors.getCombinations,
  fromRendersSelectors.getEntries,
  fromSceneSelectors.getUserScenes,
  fromInterfaceSelectors.getNewCombinationsIds,
  (state: RootState) => state.projects.currentId,
  (
    allCombinations,
    renders,
    userScenes,
    newCombinationIds,
    currentProjectId
  ) => {
    const combinationsSavedAsScenes = _map(userScenes, 'combinationId')
    const rendersByCombinationId = _groupBy(renders, 'combinationId')

    const _combinations = Object.values(allCombinations as unknown as ({ [id: string]: ProjectCardCombination }))
      .filter((combination) => {
        return _includes(combinationsSavedAsScenes, combination.id)
          ? true
          : (combination.projectId === currentProjectId || combination.sentAssetId)
      })
      .map((combination) => {
        const _renders = rendersByCombinationId[combination.id]

        if (!_renders) return combination

        let render = _renders[0]

        if (_renders.length > 1) {
          const defaultRender = _renders.find(r => r.id === combination.defaultRenderId)
          if (defaultRender) render = defaultRender
        }

        if (render) {
          return {
            ...combination,
            renders: _renders,
            manifest: render.manifest,
            render: render,
            isNew: _includes(newCombinationIds, combination.id),
            savedAsScene: _includes(combinationsSavedAsScenes, combination.id)
          }
        }
        return combination
      })

    return _orderBy(_combinations, ['createdAt'], ['desc']) as ProjectCardCombination[]
  }
)

export const selectItems = createSelector(
  fromUploadsSelectors.getCompletedCadsInCurrentProject,
  fromFoldersSelectors.getCurrentCombinations,
  fromFoldersSelectors.getCurrentPatterns,
  selectPatterns,
  selectCombinations,
  selectPatternsInProgress,
  (
    uploads,
    currentFolderCombinations,
    currentFolderPatterns,
    patterns,
    combinations,
    patternsInProgress
  ) => {
    const imagesInProgress = _filter(
      combinations,
      (comb) => comb.combinationType === 'render' && _get(comb, 'render.deadlineStatus') !== 'Completed'
    )
    const imageCombinations = _filter(
      combinations,
      (comb) => comb.combinationType === 'render' && _get(comb, 'render.deadlineStatus') === 'Completed'
    )

    const batchRenderItem = (item: ProjectCardCombination) => item.batchParentId || item.connectedBatchRenderCombinationIds

    const rendersInProgress = combinations.filter(item => item.combinationType === 'render')

    const getBatchRendersInProgress = () => {
      const batchRenderParents: ProjectCardCombination[] = []
      const batchRenderChildren: ProjectCardCombination[] = []
      rendersInProgress.map(item => {
        if (item.batchParentId) batchRenderChildren.push(item)
        if (item.connectedBatchRenderCombinationIds) batchRenderParents.push(item)
      })

      const groupedBatchRenderChildren = batchRenderChildren.reduce(function (res, render) {
        if (!render.batchParentId) return res
        res[render.batchParentId] = res[render.batchParentId] || []
        res[render.batchParentId].push(render)
        return res
      }, Object.create(null))

      const batchRendersInProgress = batchRenderParents.reduce((inProgress: ProjectCardCombination[], batchRender:ProjectCardCombination) => {
        let updatedRender
        // add batch children's renders to parent
        if (batchRender.id && groupedBatchRenderChildren[batchRender.id]) {
          updatedRender = Object.assign({}, batchRender)
          const children = groupedBatchRenderChildren[batchRender.id].reduce((acc:any, item:ProjectCardCombination) => acc.concat(item.renders), [])
          updatedRender.batchRenders = updatedRender.renders?.concat(children)
          // if all children and parent is done, filter away
          if (getDeadlineStatus(updatedRender.batchRenders ?? []) !== DEADLINE_STATUS.COMPLETED) return inProgress.concat([updatedRender ?? batchRender])
          return inProgress
        }
        return inProgress.concat([batchRender])
      }, [])
      return batchRendersInProgress
    }

    const getImagesInProgress = () => {
      const standardRendersInProgress : ProjectCardCombination[] = rendersInProgress.filter(item => _get(item, 'render.deadlineStatus') !== 'Completed' && !batchRenderItem(item))
      const batchRendersInProgress = getBatchRendersInProgress().filter(item => !item.sentAssetId)
      return standardRendersInProgress.concat(batchRendersInProgress)
    }

    const imagesInProgressWithBatchRenders = getImagesInProgress()
    const keysForImagesInProgress = imagesInProgressWithBatchRenders.map(item => item.id)

    const imageCombinationsFiltered = _filter(
      combinations,
      comb => {
        if (comb.batchParentId) return false
        if (comb.connectedBatchRenderCombinationIds && comb.connectedBatchRenderCombinationIds?.length > 0 && comb.combinationType === 'render') {
          if (keysForImagesInProgress.includes(comb.id)) {
            return false // child renders of this parent is not done
          }
          return true
        }
        if (comb.combinationType !== 'render') return false
        if (comb.imageTemplateId && comb.renders) {
          return getDeadlineStatus(comb.renders) === DEADLINE_STATUS.COMPLETED
        }
        return _get(comb, 'render.deadlineStatus') === DEADLINE_STATUS.COMPLETED
      }
    )

    return {
      patterns,
      patternsInProgress,
      imagesInProgress: imagesInProgressWithBatchRenders,
      imageCombinations: imageCombinationsFiltered,
      geometryInProgress: _map(_orderBy(uploads, ['createdAt'], ['desc']), (upload) => {
        return upload.merge({ combinationType: 'upload' })
      }),
      geometryCombinations: _filter(combinations, { combinationType: 'convert' }),
      variantsInProgress: _filter(
        combinations,
        comb => comb.combinationType === 'variant' && _get(comb, 'render.deadlineStatus') !== DEADLINE_STATUS.COMPLETED
      ),
      variantCombinations: _filter(
        combinations,
        comb => comb.combinationType === 'variant' && _get(comb, 'render.deadlineStatus') === DEADLINE_STATUS.COMPLETED
      ),
      currentFolderCombinations: (currentFolderCombinations as ProjectCardCombination[])
        .map(comb => _find(combinations, c => c.id === comb.id))
        .filter(Boolean)
        .filter(c => !c || !c.batchParentId),
      currentFolderPatterns: (currentFolderPatterns as ProjectCardPattern[])
        .map(pattern => _find(patterns, (p) => p.id === pattern.id))
        .filter(Boolean),
      combinationsByBatchParentIdInProgress: _groupBy(imagesInProgress, 'batchParentId'),
      combinationsByBatchParentId: _groupBy(imageCombinations, 'batchParentId'),
    }
  }
)
export const dnpPropsSelector = createSelector(
  fromProjectsSelectors.isDnpProject,
  fromUsersSelectors.isDnpUser,
  (
    isDnpProject,
    isDnpUser,
  ) => {
    return {
      isDnpProject,
      isDnpUser,
    }
  }
)

export const isFetchingSelector = createSelector(
  fromProjectsSelectors.getStatus,
  fromProjectsSelectors.getCurrentEntry,
  (status, project) => status === fromProjects.statuses.FETCHING && _isEmpty(project)
)
