import { connect } from 'react-redux'
import { createSelector } from 'reselect'

import _get from 'lodash/get'

// State Selectors
import * as fromProjectsSelectors from '../../stores/ducks/projects/selectors'
import * as fromCombinationsSelectors from '../../stores/ducks/combinations/selectors'

// JSX Components : Layout
import Header from '../layout/header'
import { RootState } from '../../stores/ducks'

type Props = {
  onBeforeNavigate: () => void
}

const mapStateToProps = createSelector(
  fromProjectsSelectors.getCurrentEntry,
  fromCombinationsSelectors.getCurrentEntry,
  fromCombinationsSelectors.getEntries,
  (_: RootState, ownProps: Props) => ownProps.onBeforeNavigate,
  (state: RootState) => {
    return state.scenes.currentId
      ? state.scenes.getIn(['entries', state.scenes.currentId])
      : null
  },
  (currentProject, currentCombination, combinations, onBeforeNavigate, currentScene) => {
    const isImagePackageProject = currentCombination.imagePackageId
    let combinationTitle = currentCombination.title
    const parentCombinationTitle = _get(combinations, [currentCombination.parentId, 'title'])
    if (currentScene && currentScene.combinationId === currentCombination.id) {
      combinationTitle = currentScene.title
    }

    let breadcrumbs = [
      {
        id: 0,
        route: `/project/${currentProject.id}/show`,
        title: currentProject.title,
        onBeforeNavigate,
        hardLink: false
      },
      {
        id: 1,
        title: combinationTitle || (parentCombinationTitle && `Image of ${parentCombinationTitle}`) || 'New design'
      }
    ]

    if (isImagePackageProject) {
      const currentCombinationTitle = currentCombination.isMaster ? 'Master scene' : currentCombination.title
      breadcrumbs = [
        {
          id: 0,
          route: `/image-package-project/${currentProject.id}/show`,
          title: currentProject.title,
          onBeforeNavigate,
          hardLink: false
        },
        {
          id: 1,
          title: parentCombinationTitle ? parentCombinationTitle + ': ' + currentCombinationTitle : 'New image package'
        }
      ]
    }

    return {
      breadcrumbs,
      isImagePackageProject
    }
  }
)

export default connect(
  mapStateToProps
)(Header)
