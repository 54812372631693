import Immutable from 'seamless-immutable'
import { handleActions, createAction } from 'redux-actions'

import { v4 as uuid } from 'uuid'
import _isEmpty from 'lodash/isEmpty'
import _keyBy from 'lodash/keyBy'

import * as fromLikes from '../likes'

const assertError = (msg) => (err) => {
  console.error(msg)
  console.error(err)
  throw err
}

const failed = createAction('comments/ERROR')
const fetching = createAction('comments/FETCHING')
export const confirmRemove = createAction('comments/CONFIRM_REMOVE')
export const receive = createAction('comments/RECEIVE')

export const status = {
  PENDING: 'PENDING',
  FETCHING: 'FETCHING',
  FETCHED: 'FETCHED',
  FAILED: 'FAILED'
}

export const getAllComments = (id) => (dispatch, getState, { api }) => {
  return api.comments.getAllComments(id)
    .then((payload) => {
      dispatch(receive(payload.comments))

      const likes = payload.likes
      dispatch(fromLikes.receive(likes))
    })
    .catch((err) => dispatch(failed(err)))
}

export const create = (comment) => (dispatch, getState, { api }) => {
  const _comment = {
    ...comment,
    userId: getState().users.currentUserId,
    id: uuid()
  }

  return api.comments.create(_comment)
    .then((payload) => dispatch(receive([payload])))
    .catch(assertError('Unable to create comment', (err) => dispatch(failed(err))))
}

export const update = (comment) => (dispatch, getState, { api }) => {
  return api.comments.update(comment)
    .then((payload) => dispatch(receive(payload)))
    .catch(assertError('Unable to update comment', (err) => dispatch(failed(err))))
}

export const remove = (comment) => (dispatch, getState, { api }) => {
  return api.comments.remove(comment)
    .then((payload) => dispatch(confirmRemove(payload)))
    .catch(assertError('Unable to remove comment', (err) => dispatch(failed(err))))
}

const initialState = Immutable({
  status: status.PENDING,
  error: null,
  entries: Immutable({})
})

export default handleActions({
  [fetching]: (state) => state.merge({
    status: status.FETCHING
  }),
  [failed]: (state, { payload }) => state.merge({
    error: _isEmpty(payload) ? payload : { msg: 'Something went wrong :(' },
    status: status.FAILED
  }),
  [confirmRemove]: (state, { payload }) => state.merge({
    entries: state.entries.without((entry) => {
      return [entry.id, entry.parentId].includes(payload.id)
    })
  }),
  [receive]: (state, { payload }) => state.merge({
    status: status.FETCHED,
    entries: _keyBy([].concat(payload), 'id')
  }, { deep: true })
}, initialState)
