import _get from 'lodash/get'

import { updateSkirtingMaterial } from './update-materials'

export function updateSkirting ({ skirting, parent, viewerUtils }) {
  return (dispatch) => {
    const shouldUpdateScale = (parent && viewerUtils && skirting)

    dispatch(updateSkirtingMaterial())

    var parentBB = viewerUtils.getWorldBoundFromMeshBoundingBoxes(parent)

    if (shouldUpdateScale) {
      // scale
      var wallOriginalSizeX = _get(parent, 'userData.originalSize.x', 1)
      var skirtingOriginalSizeX = _get(skirting, 'userData.originalSize.x', 1)
      var scaleRatio = wallOriginalSizeX / skirtingOriginalSizeX

      skirting.scale.x = parent.scale.x * scaleRatio
      skirting.scale.y = 1

      // Rotate
      if (parent.wallType === 'relativeWall') {
        skirting.rotation.y = (Math.PI / 2) * -1

        skirting.position.set(
          parent.position.x,
          parent.position.y,
          parent.position.z
        )
      } else {
        skirting.position.set(
          parent.position.x,
          parent.position.y,
          parentBB.max.z
        )
      }
    }
  }
}
