import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../stores/ducks'
import * as fromThreeviewerSelectors from '../../../stores/ducks/threeviewer/selectors'
import { getSelection } from '../../../stores/ducks/selection/selectors'
import { FormSingleInputField, FormThreeInputFields } from './subtoolbar-inputField'
import { MathUtils } from 'three'

/*
  Delta rotation works like the transform gizmo tool. With multiple objects selected they are rotated around a central point
  Absolute rotation rotates each object individually.
*/
export default function RotateSettings () {
  const gizmo = useSelector((state: RootState) => fromThreeviewerSelectors.getTransformGizmo(state))
  const { x, y, z } = useSelector((state: RootState) => fromThreeviewerSelectors.getToolbarRotationFields(state))
  const selection = useSelector((state: RootState) => getSelection(state)) as [string]
  const nodelist = useSelector((state: RootState) => fromThreeviewerSelectors.getNodeList(state))

  const onlyWallsSelected = selection.every(id => (nodelist[id].userData.isTemplate || nodelist[id].userData.kvadratMeshId))

  const noSelection = selection.length < 1 || onlyWallsSelected // If selection is empty or contains only walls, the input fields should be read only

  return (
    <div className='flex justify-between f8 c-gray'>

      <div style={{ paddingRight: '50px' }}>
        <div style={{ textAlign: 'center' }}>Offset rotation</div>
        <div className='flex items-center'>

          <div className='flex items-center c-gray f8'>
            <FormSingleInputField
              readOnly = {noSelection}
              borderColor = '#ff0000'
              data-testid="RotateSettingsInputX"
              fieldName = 'x'
              value = {'0'}
              onSubmitCallback = { (value: number) => {
                if (!isNaN(value)) {
                  gizmo.offsetAxisRotationOnSelected('x', value)
                }
              }}
            />

            <FormSingleInputField
              readOnly = {noSelection}
              borderColor = '#00ff00'
              fieldName = 'y'
              data-testid="RotateSettingsInputY"
              value = {'0'}
              onSubmitCallback = { (value: number) => {
                if (!isNaN(value)) {
                  gizmo.offsetAxisRotationOnSelected('y', value)
                }
              }}
            />

            <FormSingleInputField
              readOnly = {noSelection}
              borderColor = '#0000ff'
              data-testid="RotateSettingsInputY"
              fieldName = 'z'
              value = {'0'}
              onSubmitCallback = { (value: number) => {
                if (!isNaN(value)) {
                  gizmo.offsetAxisRotationOnSelected('z', value)
                }
              }}
            />
          </div>
        </div>
      </div>

      <div style={{ paddingLeft: '50px' }}>
        <div style={{ textAlign: 'center' }}>Set rotation (applied in the order X, Y, Z)</div>
        <div className='flex items-center'>
          <div className='flex items-center c-gray f8'>
            <FormThreeInputFields
              propX = {isNaN(x) || noSelection ? '-' : MathUtils.radToDeg(x).toFixed(1)}
              propY = {isNaN(y) || noSelection ? '-' : MathUtils.radToDeg(y).toFixed(1)}
              propZ = {isNaN(z) || noSelection ? '-' : MathUtils.radToDeg(z).toFixed(1)}
              readOnly = {noSelection}
              onSubmitCallback = { (xArg: number, yArg: number, zArg: number) => {
                gizmo.setRotationOnSelected(xArg, yArg, zArg)
              }
              }/>
          </div>
        </div>
      </div>

    </div>
  )
}
