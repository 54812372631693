import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import _omit from 'lodash/omit'

import Image from './image'

const SPRITESHEET_PROPS = ['zoom', 'offsetX', 'offsetY', 'tileSize', 'tilesPerRow']
const styles = {
  spinnerContainer: {
    paddingBottom: '100%'
  },
  spinnerIcon: {
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -55%)'
  }
}

const renderSpinner = (size) => {
  let iconSize = 'f1' // large
  if (size === 'small') iconSize = 'f6'
  if (size === 'medium') iconSize = 'f3'

  return (
    // Styling relative to Image component
    <div className={'width-100 relative overflow-hidden"'} style={styles.spinnerContainer}>
      <div className='absolute' style={styles.spinnerIcon}>
        <i className={`icon-spin1 c-gray-light ${iconSize}`}/>
      </div>
    </div>
  )
}

class SpriteImage extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      imageStatus: 'loading',
      showSpinner: true,
      showError: false
    }
  }

  handleImageLoaded () {
    const { onLoad } = this.props
    onLoad && onLoad()
    this.setState({
      imageStatus: 'loaded',
      showSpinner: false,
      showError: false
    })
  }

  handleImageError () {
    this.setState({
      imageStatus: 'failed to load',
      showSpinner: false,
      showError: true
    })
  }

  calcTileError (offset) {
    const {
      tileSize,
      tilesPerRow
    } = this.props

    const imageSize = tileSize * tilesPerRow
    const imageRatio = tileSize / imageSize
    const maximumError = (tileSize - 1) / tileSize

    const n = (offset / tileSize)
    const currentTileRatio = (n / (tilesPerRow - 1))

    const errorRatio = currentTileRatio * maximumError
    const sumErrorRatio = errorRatio * imageRatio

    return sumErrorRatio
  }

  componentDidMount () {
    const {
      tileSize,
      tilesPerRow,
      zoom,
      offsetX,
      offsetY
    } = this.props
    const imageSize = tileSize * tilesPerRow
    const backgroundSize = zoom * tilesPerRow
    const backgroundPositionX = (100 * offsetX) / imageSize + 100 * this.calcTileError(offsetX)
    const backgroundPositionY = (100 * offsetY / imageSize) + 100 * this.calcTileError(offsetY)

    const spriteStyle = {
      backgroundRepeat: 'no-repeat',
      backgroundSize: `${backgroundSize}% ${backgroundSize}%`,
      backgroundPositionX: `${backgroundPositionX}%`,
      backgroundPositionY: `${backgroundPositionY}%`
    }

    this.setState({ spriteStyle })
  }

  render () {
    const {
      src,
      placeholderSrc,
      spinnerSize,
      ...rest
    } = this.props

    const {
      spriteStyle
    } = this.state

    return (
      <div>
        {!!this.state.showSpinner && (renderSpinner(spinnerSize))}
        {!this.state.showSpinner && (
          <Image
            {..._omit(rest, SPRITESHEET_PROPS)}
            src={src}
            style={src && spriteStyle}
            constrainRatio={[1, 1]}
          />
        )}

        {/* invisible div to capture image load events */}
        <img
          src={src || placeholderSrc}
          onLoad={this.handleImageLoaded.bind(this)}
          onError={this.handleImageError.bind(this)}
          className={'display-none'}
        />
      </div>
    )
  }
}

SpriteImage.propTypes = {
  src: PropTypes.string,
  placeholderSrc: PropTypes.string,
  style: PropTypes.object,

  // spritesheet settings
  tileSize: PropTypes.number,
  tilesPerRow: PropTypes.number,
  offsetX: PropTypes.number,
  offsetY: PropTypes.number,
  zoom: PropTypes.number,

  spinnerSize: PropTypes.oneOf(['small', 'medium', 'large'])
}

SpriteImage.defaultProps = {
  placeholderSrc: '/img/placeholder-project.jpg',

  // Adds a 1% zoom to avoid edges from other sprites
  zoom: 101,
  tileSize: 128,
  tilesPerRow: 32,

  spinnerSize: 'medium'
}

export default SpriteImage
