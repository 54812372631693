import styled from 'styled-components'

interface Props {
  readonly location?: 'over' | 'under';
};

export const Tooltip = styled.div<Props>`
  position: absolute;
  top: ${props => props.location === 'over' ? '-130%' : '135%'};
  left: ${props => props.location === 'over' ? '' : '50%'};
  transform: ${props => props.location === 'over' ? '' : 'translate(-50%, -50%)'};
  padding: 5px 10px;

  font-size: 0.8rem;
  text-transform: initial;
  text-align: center;
  white-space: nowrap;
  color: var(--white);

  border-radius: 2px;
  opacity: 0;
  background: var(--todo_333);

  pointer-events: none;

  transition: transform 100ms ease-in-out, opacity 50ms linear;
  transition-delay: 10ms;

  &:before {
    content: '';
    position: absolute;
    top: ${props => props.location === 'over' ? '1rem' : '-0.2rem'};
    left: 50%;
    border-radius: 2px;
    margin-left: -0.5rem;
    width: 1rem;
    height: 1rem;
    transform: rotate(45deg);
    z-index: -1;
    background: var(--todo_333);
  }
`

export const TooltipWrapper = styled.div<Props>`
  position: relative;
  &:hover ${Tooltip} {
    opacity: 1;
    transform: ${props => props.location === 'over' ? '' : 'translate(-50%, 0)'};
  }
`
