export type StorageApiFile = {
  name: string
  key: string
  size: number
}

export type StorageApiManifest = {
  files: StorageApiFile[]
}

export type StorageApiImageType = 'thumbnail' | 'medium' | 'large'

type GMQueryObject = { [key: string]: string | number | (string | number)[] }

const IMAGE_TYPES = {
  thumbnail: '256, 256',
  medium: '500, 500',
  large: '1000, 1000'
}

function applyGMQuery (currentString: string, queryObj: GMQueryObject) {
  let newString = currentString

  Object.keys(queryObj).forEach((key) => {
    const escapedValue = window.escape(`(${queryObj[key]})`)
    const operator = newString.indexOf('?') !== -1 ? '&' : '?'
    newString += operator + key + '=' + escapedValue
  })

  return newString
}

/**
 * @param resize A string or an array of length 1 for square resize, 2 for non-square resize
 */
function getResizeString (resize: string | [number] | [number, number]) {
  if (typeof resize === 'string') return resize

  if (resize && Array.isArray(resize)) {
    return ((resize.length === 1 && [resize[0], resize[0]]) || resize.slice(0, 2)).join(', ')
  }

  return null
}

function getImgUrl (fileOrSrc: StorageApiFile | string, type?: string, format?: string) {
  format = format || (type && 'jpg')
  return typeof fileOrSrc === 'string'
    ? `${fileOrSrc.split('.')[0]}.${format}`
    : `/api/storage/get/${fileOrSrc.key}.${format || fileOrSrc.name.split('.').slice(-1)[0]}`
}

export const getImageSrc = (fileOrSrc?: StorageApiFile | string, options?: {
  resize?: string | [number] | [number, number]
  type?: StorageApiImageType
  format?: string
  quality?: string | number
  geometry?: string | (string | number)[]
  crop?: [number, number]
  gravity?: string
  placeholderSrc?: string
}) => {
  options = options || {}
  if (!fileOrSrc || (typeof fileOrSrc !== 'string' && !fileOrSrc.key && !fileOrSrc.name)) {
    return options.placeholderSrc || '/img/placeholder-project.jpg'
  }

  if (typeof fileOrSrc === 'string' && !fileOrSrc.includes('api/storage/get')) {
    return fileOrSrc
  }
  let imgSrc = getImgUrl(fileOrSrc, options.type, options.format)

  if (options.format === 'jpg') imgSrc = applyGMQuery(imgSrc, { flatten: '' })
  if (options.quality) imgSrc = applyGMQuery(imgSrc, { quality: options.quality })
  if (options.gravity) imgSrc = applyGMQuery(imgSrc, { gravity: options.gravity })
  if (options.geometry) imgSrc = applyGMQuery(imgSrc, { geometry: options.geometry })
  if (options.crop) imgSrc = applyGMQuery(imgSrc, { crop: options.crop })

  // Resize goes before type!
  if (options.resize) {
    const resize = getResizeString(options.resize)
    if (resize) return applyGMQuery(imgSrc, { resize })
  }
  if (options.type) return applyGMQuery(imgSrc, { resize: IMAGE_TYPES[options.type] })

  return imgSrc
}
