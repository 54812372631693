import { createAction, handleActions } from 'redux-actions'

import _forEach from 'lodash/forEach'
import _get from 'lodash/get'
import _isNil from 'lodash/isNil'

import {
  getLocalState,
  getJsonEntries,
  getTextureEntries
} from './selectors'

import * as fromThreeviewerSelectors from '../threeviewer/selectors'

import * as fromThreeviewerFiles from '../threeviewer/files'
import * as fromThreeviewerUI from '../threeviewer/ui'
import * as fromUndoRedo from '../undo-redo'

export function storageUrl (file) {
  return `/api/storage/get/${file.key}.${(file.name || '').split('.').slice(-1)[0]}`
}

// 3 Actions
const receive = createAction('patterns/textures/RECEIVE')
const saveDecalState = createAction('patterns/textures/SAVE_DECAL_STATE')
const error = createAction('patterns/textures/ERROR')

export const patternChanged = createAction('patterns/textures/PATTERN_CHANGED')

const toRadians = (degrees) => {
  return degrees * (Math.PI / 180)
}

const loadTexture = (patternJson, patternId) => (dispatch, getState) => {
  const state = getState()
  const settings = fromThreeviewerSelectors.getSettings(state)
  const viewer = fromThreeviewerSelectors.getViewer(state)

  const size = settings.patternSize || 1024
  const uri = `${storageUrl(patternJson.manifest.files[0])}?resize=${window.escape(`(${size},${size})`)}`

  dispatch(fromThreeviewerFiles.loadFile({
    id: patternJson.id,
    name: patternJson.title,
    type: 'material',
    uris: [uri]
  }))

  return new Promise((resolve, reject) => {
    viewer.materialLoader.loadDecal({ ...patternJson, uri }, (err, decal) => {
      if (err) { return reject(err) }

      dispatch(fromThreeviewerFiles.fileProgress({
        file: uri,
        progress: 100,
        type: 'material'
      }))

      resolve(Object.assign(decal, {
        name: patternJson.title
      }))
    })
  })
    .then((decal) => {
      dispatch(receive({ [patternId]: decal }))
      return decal
    })
    .catch((err) => dispatch(error(err)))
}

export const transformPattern = ({ translation, rotation, scaleMultiplier, userRotation }, parts) => (dispatch, getState) => {
  const state = getState()
  const viewer = fromThreeviewerSelectors.getViewer(state)

  const nodes = parts || viewer.picker.selection
  const jsonEntries = getJsonEntries(state)
  const THREE = viewer.THREE

  const _setTransformPattern = (obj) => {
    if (obj.material && obj.material.isTriplanarMaterial) {
      const patternJson = jsonEntries[obj.userData.patternId]
      const dimensions = patternJson && patternJson.dimensions
      const _translation = translation || new THREE.Vector2()

      const _scale = scaleMultiplier ? new THREE.Vector2(
        (1 / dimensions.width) * scaleMultiplier,
        (1 / dimensions.height) * scaleMultiplier
      ) : obj.material.decalScale
      obj.material.mirrorRotation = obj.userData.uvFlipped
      const _rotation = (rotation || obj.material.decalRotation)

      obj.material.decalDimensions = dimensions
      obj.material.transformDecal(_translation, _scale, _rotation, userRotation)
    }
  }

  _forEach(nodes, (obj) => {
    obj.traverse((child) => {
      _setTransformPattern(child)
    })
  })

  dispatch(patternChanged())
}

export const translatePattern = (x, y) => (dispatch, getState) => {
  const state = getState()
  const viewer = fromThreeviewerSelectors.getViewer(state)
  const THREE = viewer.THREE
  const nodes = viewer.picker.selection

  const translation = new THREE.Vector2(x, y)

  _forEach(nodes, (node) => node.traverse((child) => {
    if (child.material && child.material.isTriplanarMaterial) {
      child.material.decalTranslation = translation
    }
  }))

  dispatch(patternChanged())
}

export const rotatePattern = (rotation, userRotation, parts) => (dispatch) => {
  dispatch(transformPattern({ rotation, userRotation }, parts))
}

export const scalePattern = (scaleMultiplier) => (dispatch, getState) => {
  if (!isNaN(scaleMultiplier) && Number.isFinite(scaleMultiplier) && scaleMultiplier > 0) {
    dispatch(transformPattern({ scaleMultiplier }))
    const state = getState()
    const viewer = fromThreeviewerSelectors.getViewer(state)

    const nodes = viewer.picker.selection
    const jsonEntries = getJsonEntries(state)
    const THREE = viewer.THREE

    _forEach(nodes, (node) => node.traverse((child) => {
      if (child.material && child.material.isTriplanarMaterial) {
        const patternJson = jsonEntries[child.userData.patternId]
        const dimensions = patternJson && patternJson.dimensions
        const isModelbank = _get(child, 'userData.modelSource', '') === 'modelbank'

        const nonTriplanarScale = isModelbank ? 1000 : 1
        const s = child.material._useTriplanar ? 1000 : nonTriplanarScale

        const x = (1 / dimensions.width) * scaleMultiplier
        const y = (1 / dimensions.height) * scaleMultiplier

        const sx = x * s
        const sy = y * s

        const scale = scaleMultiplier && Number.isFinite(x) && Number.isFinite(y)
          ? new THREE.Vector2(sx, sy)
          : child.material.decalScale

        child.userData.decalScale = scale
        node.userData.decalScale = scale

        child.material.decalScaleUi = new THREE.Vector2(x, y)
        child.material.decalScale = scale
      }
    }))

    dispatch(patternChanged())
  }
}

const removePatternFromNode = (node) => {
  if (node.userData.patternId) {
    node.userData.patternId = null
    delete node.userData.patternFilePath
  }
  if (node.material && node.material.isTriplanarMaterial) {
    node.material.removeDecal && node.material.removeDecal()
  } else {
    node.traverse((childNode) => {
      if (node.material && node.material.isTriplanarMaterial) {
        if (childNode.userData && childNode.userData.patternId) {
          childNode.userData.patternId = null
          delete childNode.userData.patternFilePath
        }
        childNode.material.removeDecal && childNode.material.removeDecal()
      }
    })
  }
}

const extractNodePatternData = (node) => {
  return {
    patternId: _get(node, 'userData.patternId'),
    decalMap: _get(node, 'material.decalMap'),
    decalScale: _get(node, 'material.decalScale'),
    decalUVTransform: _get(node, 'material.decalUVTransform')
  }
}

export const removePattern = () => (dispatch, getState) => {
  const state = getState()
  const viewer = fromThreeviewerSelectors.getViewer(state)
  const selection = viewer.picker.selection

  let patternFound = false
  _forEach(selection, (node) => {
    if (node.userData.patternId !== undefined) {
      patternFound = true
    }
  })
  if (!patternFound) return

  const newNodeValues = {}
  const oldNodeValues = {}

  _forEach(selection, (node) => (oldNodeValues[node.uuid] = extractNodePatternData(node)))
  _forEach(selection, removePatternFromNode)
  _forEach(selection, (node) => (newNodeValues[node.uuid] = extractNodePatternData(node)))

  const afterCommand = () => {
    // Force update to sync with pattern swatch component
    dispatch(patternChanged())
  }
  afterCommand()

  // set up command with saved node values
  const { createSetPatternCommand } = viewer.commands
  var command = createSetPatternCommand({
    nodes: selection,
    newNodeValues,
    oldNodeValues,
    afterExecute: afterCommand,
    afterUndo: afterCommand
  })
  dispatch(fromThreeviewerUI.forceUpdate())
  dispatch(fromUndoRedo.addCommand(command))
}

export const setPattern = (patternId) => (dispatch, getState) => {
  const state = getState()
  const viewer = fromThreeviewerSelectors.getViewer(state)
  const selection = viewer.picker.selection

  return dispatch(setPatternOnParts({
    patternId,
    parts: selection,
    addToHistory: true
  }))
}

export const setPatternOnParts = ({
  patternId,
  parts,
  decalUVTransform = null,
  userDecalRotation,
  addToHistory = false
}) => (dispatch, getState) => {
  const state = getState()
  const jsonEntries = getJsonEntries(state)
  const textureEntries = getTextureEntries(state)
  const viewer = fromThreeviewerSelectors.getViewer(state)

  const THREE = viewer.THREE
  const patternJson = jsonEntries[patternId]
  const patternTexture = textureEntries[patternId]

  return Promise.resolve()
    .then(() => {
      if (!patternTexture && patternJson) {
        return dispatch(loadTexture(patternJson, patternId))
      }
      return patternTexture
    })
    .then((decal) => {
      if (!decal) return
      const clonedDecal = decal.clone()

      clonedDecal.wrapS = THREE.RepeatWrapping
      clonedDecal.wrapT = THREE.RepeatWrapping

      clonedDecal.needsUpdate = true

      var decalScale = viewer.materialLoader.calculateDecalScale(patternJson)

      // save old and new data for undo/redo command
      const newNodeValues = {}
      const oldNodeValues = {}
      const newMaterials = {}
      const setRecursePattern = (nodes) => {
        _forEach(nodes, (node) => {
          if (!_get(node, 'material.isTriplanarMaterial')) {
            return node.children.length && setRecursePattern(node.children)
          }

          let materialClone = _get(newMaterials, node.material.uuid)
          if (!materialClone) {
            materialClone = node.material.clone()
            newMaterials[node.material.uuid] = materialClone
          }

          const changedPattern = node.userData.patternId && node.userData.patternId !== patternId
          const nonTriplanarScale = (_get(node, 'userData.modelSource') === 'modelbank') ? 1000.0 : 1.0
          const meterScale = node.material.useTriplanar ? 1000.0 : nonTriplanarScale
          const nodeUuid = node.uuid
          oldNodeValues[nodeUuid] = extractNodePatternData(node)
          node.userData.patternId = patternId
          node.userData.patternFilePath = _get(patternJson, ['patternFilePath'])
          materialClone.decalMap = clonedDecal
          materialClone.decalDimensions = patternJson.dimensions

          if (changedPattern) {
            materialClone.decalDimensions = patternJson.dimensions
            materialClone.userDecalRotation = 0
            materialClone.decalUVTransform = new THREE.Matrix4()
            node.userData.decalUVTransform = materialClone.decalUVTransform
            materialClone.decalScale = decalScale.clone().multiplyScalar(meterScale)
            if (node.userData.uvFlipped) {
              materialClone.decalMap.flipY = false
              materialClone.decalRotation = toRadians(materialClone.userDecalRotation)
            }

            newNodeValues[nodeUuid] = extractNodePatternData(node)
          } else {
            userDecalRotation = _isNil(userDecalRotation)
              ? parseInt(materialClone.getDecalRotationFromTransform() * 180 / Math.PI)
              : userDecalRotation
            materialClone.userDecalRotation = userDecalRotation
            if (decalUVTransform) {
              let decalUVTransform_ = decalUVTransform
              // Matching a flip here in get-changed-models
              if (materialClone.isTriplanarMaterial && materialClone.useTriplanar) {
                decalUVTransform_ = [...decalUVTransform]
                decalUVTransform_[13] = -decalUVTransform_[13]
              }
              materialClone.decalUVTransform = new THREE.Matrix4().fromArray(decalUVTransform_)
            } else {
              materialClone.decalScale = decalScale.clone().multiplyScalar(meterScale)
            }
            if (node.userData.uvFlipped) {
              materialClone.decalMap.flipY = false
              materialClone.mirrorRotation = true
              materialClone.decalRotation = toRadians(userDecalRotation)
            }
            newNodeValues[nodeUuid] = extractNodePatternData(node)
          }
          node.material = materialClone
        })
        dispatch(fromThreeviewerUI.forceUpdate())
      }
      setRecursePattern(parts)
      const afterCommand = () => {
        // Force update to sync with pattern swatch component
        dispatch(patternChanged())
      }
      afterCommand()

      if (!addToHistory) return

      // set up command with saved node values
      const { createSetPatternCommand } = viewer.commands
      var command = createSetPatternCommand({
        nodes: parts,
        newNodeValues,
        oldNodeValues,
        afterExecute: afterCommand,
        afterUndo: afterCommand
      })

      dispatch(fromUndoRedo.addCommand(command))
    })
}

export const createPatternTransformCommand = function (userDecalRotation, userDecalTranslation) {
  return (dispatch, getState) => {
    const state = getState()
    const viewer = fromThreeviewerSelectors.getViewer(state)
    const localState = getLocalState(state)
    const { createPatternTransformCommand } = viewer.commands
    const selection = viewer.picker.selection

    const decalMaterialsMap = {}

    const newTransformMap = {}
    _forEach(selection, (node) => {
      node.traverse((child) => {
        if (child.material && child.material.isTriplanarMaterial) {
          const uuid = child.material.uuid
          decalMaterialsMap[uuid] = child.material
          newTransformMap[uuid] = child.material.decalUVTransform
        }
      })
    })

    newTransformMap.userDecalRotation = userDecalRotation
    newTransformMap.userDecalTranslation = userDecalTranslation

    const afterCommand = () => { dispatch(patternChanged()) }

    var command = createPatternTransformCommand({
      decalMaterialsMap,
      newValuesMap: newTransformMap,
      oldValuesMap: localState.textures.savedDecalState,
      afterExecute: afterCommand,
      afterUndo: afterCommand
    })

    dispatch(fromUndoRedo.addCommand(command))
    dispatch(saveDecalState(newTransformMap))
  }
}

export const saveCurrentDecalUVTransform = function (userDecalRotation, userDecalTranslation) {
  return (dispatch, getState) => {
    const state = getState()
    const viewer = fromThreeviewerSelectors.getViewer(state)
    const selection = viewer.picker.selection

    const oldTransformsMap = {}
    _forEach(selection, (node) => {
      node.traverse((child) => {
        if (child.material && child.material.isTriplanarMaterial) {
          oldTransformsMap[child.material.uuid] = child.material.decalUVTransform
        }
      })
    })

    oldTransformsMap.userDecalRotation = userDecalRotation
    oldTransformsMap.userDecalTranslation = userDecalTranslation

    dispatch(saveDecalState(oldTransformsMap))
  }
}

// 4 Reducer
const initialState = {
  patternChanged: 0,
  entries: {},
  error: null
}

export default handleActions({
  [patternChanged]: (state) => ({ ...state, patternChanged: Math.random() }),
  [receive]: (state, action) => ({ ...state, entries: Object.assign(state.entries, action.payload) }),
  [error]: (state, action) => ({ ...state, error: action.payload }),
  [saveDecalState]: (state, action) => {
    return {
      ...state,
      savedDecalState: action.payload
    }
  }
}, initialState)
