import React from 'react'
import cs from 'classnames'
import { MdCenterFocusStrong as IconImageTemplate } from 'react-icons/md'

type Props = {
  showStack: boolean
  stackStep: number
  size: number
  iconSize: number
  isBatchRender?: boolean
}

export default function ImageTemplateIcon (props: Props) {
  return (
    <div
      title={props.isBatchRender ? 'Image package' : 'Template image'}
      className='absolute'
      style={{
        right: props.showStack ? props.stackStep : 0,
        bottom: props.showStack ? props.stackStep : 0,
        width: props.size,
        height: props.size
      }}
    >
      {props.showStack && (<>
        <div
          className={cs('absolute br-2 border', {
            'bc-gray bg-gray-light': !props.isBatchRender,
            'bg-gray-dark c-white': props.isBatchRender
          })}
          style={{
            width: props.size,
            height: props.size,
            top: props.stackStep,
            left: props.stackStep
          }} />
        <div
          className={cs('absolute br-2 border', {
            'bc-gray bg-gray-light': !props.isBatchRender,
            'bg-gray-dark c-white': props.isBatchRender
          })}
          style={{
            width: props.size,
            height: props.size,
            top: props.stackStep / 2,
            left: props.stackStep / 2
          }}
        />
      </>)}
      <div
        style={{
          width: props.size,
          height: props.size
        }}
        className={cs('flex center-items absolute br-2 border', {
          'bg-gray-light bc-gray': !props.isBatchRender,
          'bg-gray-dark c-white': props.isBatchRender
        })}
      >
        <IconImageTemplate size={props.iconSize} />
      </div>
    </div>
  )
}
