import { connect } from 'react-redux'
import { createSelector } from 'reselect'

import _isEmpty from 'lodash/isEmpty'
import _map from 'lodash/map'
import _get from 'lodash/get'
import _keyBy from 'lodash/keyBy'

import * as fromSelection from '../../../../stores/ducks/selection'
import * as fromColors from '../../../../stores/ducks/colors'

import * as storageUtils from '../../../../utils/storage'

import { getSelection } from '../../../../stores/ducks/selection/selectors'
import * as fromThreeviewerSelectors from '../../../../stores/ducks/threeviewer/selectors'
import * as fromThreeviewerUi from '../../../../stores/ducks/threeviewer/ui'
import * as fromThreeviewerViewer from '../../../../stores/ducks/threeviewer/viewer'
import * as fromMaterialsSelectors from '../../../../stores/ducks/materials/selectors'
import * as fromColorsSelectors from '../../../../stores/ducks/colors/selectors'
import * as fromCombinationSelectors from '../../../../stores/ducks/combinations/selectors'
import * as fromCombinations from '../../../../stores/ducks/combinations'
import * as fromPatterns from '../../../../stores/ducks/patterns/selectors'
import * as fromAppearanceGroups from '../../../../stores/ducks/appearance-groups'
import * as fromMaterials from '../../../../stores/ducks/materials'
import * as fromPatternsTextures from '../../../../stores/ducks/patterns/textures'
import * as fromMaterialAndColorSelectors from '../material-and-color/material-and-color-selectors'

const ignoreNode = (node: any) => (
  !node.geometry ||
  !node.material ||
  node.userData.isTemplate ||
  node.userData.ignore ||
  node.parent?.userData.ignore ||
  _get(node, 'userData.modelSource') === 'ugabank'
)

function getColorId (node: any, colorsByIkeaHexString: any) {
  const userDataColorId = _get(node, 'userData.colorId')
  if (userDataColorId) {
    return userDataColorId
  }
  if (_get(node, 'userData.modelSource') === 'modelbank') return
  const color = _get(node, 'material.color')

  if (color) {
    return _get(colorsByIkeaHexString, [color.getHexString(), 'id'])
  }
}

export type Part = {
  id: string
  name: string
  area: number | undefined
  volume: number | undefined
  isSelected: boolean
}

export type PartsGroup = {
  materialId: string
  colorId: string | undefined
  patternId: string | undefined
  parts: Part[]
  area: number
  volume: number
}
/**
 * Group all parts with with the same materialId and colorId
 * ColorID can be undefined
 * Parts without materialId and colorId are grouped together
 *
 * @param nodeList All nodes in the scene
 * @param colorsByNcsThreeHexString Color of the material
 */
function getPartsGroups (nodeList: any, colorsByNcsThreeHexString: any, pickerSelection: any): PartsGroup[] {
  return Object.keys(nodeList).reduce((acc: PartsGroup[], partUUID) => {
    const node = nodeList[partUUID]
    if (ignoreNode(node)) return acc

    const materialId = _get(node, 'userData.materialId')
    const patternId = _get(node, 'userData.patternId') === null ? undefined : _get(node, 'userData.patternId')
    const colorId = getColorId(node, colorsByNcsThreeHexString)
    const groupIndex = acc.findIndex(group => {
      const groupPatternId = group.patternId === null ? undefined : group.patternId
      return group.colorId === colorId && group.materialId === materialId && groupPatternId === patternId
    })
    const volume = _get(node, 'userData.volume')
    const area = _get(node, 'userData.area')

    if (groupIndex > -1) {
      acc = [
        ...acc.slice(0, groupIndex),
        {
          ...acc[groupIndex],
          volume: (volume || 0) + acc[groupIndex].volume,
          area: (area || 0) + acc[groupIndex].area,
          parts: acc[groupIndex].parts.concat({
            id: partUUID,
            area: area,
            volume: volume,
            name: getPartName(partUUID, nodeList),
            isSelected: pickerSelection.includes(partUUID)
          })
        },
        ...acc.slice(groupIndex + 1, acc.length)
      ]
    } else {
      acc.push({
        colorId,
        materialId,
        patternId: node.userData.patternId,
        volume: volume,
        area: area,
        parts: [{
          id: partUUID,
          area: area,
          volume: volume,
          name: getPartName(partUUID, nodeList),
          isSelected: pickerSelection.includes(partUUID)
        }]
      })
    }
    return acc
  }, [])
}
/**
 * Get the part name from a part UUID
 * Part name is a combination of the parts name and direct part/material parent
 *
 * @param partUUID UUID of the part
 * @param nodeLists All nodes in the scene
 */
function getPartName (partUUID: string, nodeLists: any) {
  const part = nodeLists[partUUID]
  let partName = part.name || partUUID
  if (part.parent && part.parent.name) {
    partName = `${part.parent.name}-${partName}`
  }
  return partName
}

function getMaterialClass (material: any) {
  if (material.metadata?.tag_items?.appearance_class && material.metadata.tag_items.appearance_class.length > 0) {
    return (material.metadata?.tag_items?.appearance_class[0])
  }
  if (material.source === 'dpd') {
    return (material.source)
  }
  // Materials with source set as "file" will also have class dpd
  if (material.source === 'file') {
    return ('dpd')
  }
  return ('Specialized')
}

type Annotation = {
  id: string
  annotationText: string
  materialId: string
  colorId?: string
  patternId?: string
}

export type AppearanceGroup = {
  id: string
  materialId: string
  name: string
  isSelected: boolean
  materialClass: string
  areaCost: number
  volumeCost: number
  totalCost: number
  annotation: Annotation
  parts: Part[],
  thumbnail: string
  thumbnailOffset: {
    spritesheet: string,
    x: number,
    y: number
  }
  pattern: { id: string, name: string, thumbnailSrc: string } | undefined
  color: {
    name: string | undefined
    backgroundColor: string | undefined
    id: string | undefined
    style: {
      background: string | undefined
    }
    ncsName: string | undefined
    pantoneTitle: string | undefined
    isPrototypeFabricColor?: boolean
  } | undefined
}
const mapStateToProps = createSelector(
  getSelection,
  fromPatterns.getEntries,
  fromThreeviewerSelectors.getNodeList,
  fromThreeviewerSelectors.getAnnotationsActive,
  fromMaterialsSelectors.getJsonEntries,
  fromColorsSelectors.getColors,
  fromThreeviewerSelectors.getForcedUpdate, // This is required to correctly retrieve all updates for example picker selection until visualizer is clicked.
  fromCombinationSelectors.getCurrentEntry,
  state => state.appearanceGroups,
  fromMaterialAndColorSelectors.getSelectionMode,
  (pickerSelection, patterns, nodeList, annotationsActive, materials, colors, forceUpdate, currentEntry, appearanceGroupsState, getSelectionMode): AppearanceGroupsStateProps => {
    const colorsByNcsThreeHexString = _keyBy(colors, 'ncsInThreeColorHexString')
    const partsGroups = getPartsGroups(nodeList, colorsByNcsThreeHexString, pickerSelection)
    const appearanceGroups: AppearanceGroup[] = []

    _map(partsGroups, (partsGroup) => {
      const materialId = partsGroup.materialId
      const material = materials[materialId]
      const annotationId = `${partsGroup.materialId}${partsGroup.colorId || ''}${partsGroup.patternId || ''}`
      const annotation: Annotation | undefined = currentEntry.annotations && currentEntry.annotations[annotationId]
      if (!material) return
      const areaCost: number = _get(currentEntry, ['costEstimations', materialId, 'areaCost'], 0)
      const volumeCost: number = _get(currentEntry, ['costEstimations', materialId, 'volumeCost'], 0)
      let colorData
      let pattern
      if (partsGroup.colorId && colors[partsGroup.colorId] !== undefined) {
        const colorFromState = colors[partsGroup.colorId]
        const { r, g, b } = _get(colorFromState, 'ncs.color', { r: 0, g: 0, b: 0 })
        colorData = {
          ...colorFromState,
          style: {
            backgroundColor: colorFromState.backgroundColor || `rgb(${r}, ${g}, ${b})`
          }
        }
      }
      if (partsGroup.patternId && patterns[partsGroup.patternId] !== undefined) {
        pattern = {
          id: partsGroup.patternId,
          name: patterns[partsGroup.patternId].title || '',
          thumbnailSrc: storageUtils.getImageSrc(_get(patterns[partsGroup.patternId], 'manifest.files.0'), {
            resize: [18, 18],
            format: 'jpg'
          })
        }
      }

      const materialName =
        _get(material, 'metadata.tag_items.Designation') ||
        _get(material, 'displayName') ||
        _get(material, 'metadata.description') ||
        _get(material, 'name', material.id)

      appearanceGroups.push({
        id: `${material.id}${partsGroup.colorId || ''}${partsGroup.patternId || ''}`,
        materialId: material.id,
        name: materialName,
        isSelected: partsGroup.parts.some(part => pickerSelection.includes(part.id)),
        areaCost,
        volumeCost,
        annotation: annotation || {
          id: annotationId,
          materialId: material.id,
          annotationText: `${materialName}${colorData ? `, ${colorData.name ? colorData.name : colorData.ncs.name}` : ''}${pattern ? `, ${pattern.name}` : ''}`,
          colorId: partsGroup.colorId,
          patternId: partsGroup.patternId
        },
        totalCost: areaCost * (partsGroup.area ? partsGroup.area : 0) + volumeCost * (partsGroup.volume ? partsGroup.volume : 0),
        parts: partsGroup.parts,
        thumbnail: material.thumbnail,
        thumbnailOffset: material.thumbnailOffset,
        materialClass: getMaterialClass(material),
        pattern,
        color: colorData ? {
          name: colorData.name || undefined,
          backgroundColor: colorData.background || undefined,
          id: partsGroup.colorId || undefined,
          style: colorData.style || undefined,
          ncsName: colorData.ncs ? colorData.ncs.name : undefined,
          pantoneTitle: colorData?.pantone.name,
          isPrototypeFabricColor: colorData?.isPrototypeFabricColor
        } : undefined
      })
    })
    return {
      pickerSelection: pickerSelection,
      isNoMaterialsAssigned: _isEmpty(appearanceGroups),
      appearanceGroups: appearanceGroups,
      notSetPartsGroup: partsGroups.find(group => !group.materialId),
      annotationsActive,
      isNoAppearanceSetOpen: appearanceGroupsState.isNoAppearanceSetOpen,
      isAppearanceGroupOpen: appearanceGroupsState.isAppearanceGroupOpen,
      isPartsGroupOpen: appearanceGroupsState.isPartsGroupOpen,
      isCostAndAnnotationsOpen: appearanceGroupsState.isCostAndAnnotationsOpen,
      getSelectionMode: getSelectionMode
    }
  }
)

const mapDispatchToProps = (dispatch: any): AppearanceGroupsDispatchProps => ({
  fetchColors: () => dispatch(fromColors.getColors()),
  selectFromUuids: (uuids, appendSelection) => dispatch(fromSelection.selectFromUuids(uuids, appendSelection)),
  toggleAnnotations: (annotationsActive) => {
    annotationsActive ? dispatch(fromThreeviewerUi.deactivateAnnotations()) : dispatch(fromThreeviewerUi.activateAnnotations())
  },
  disableKeyboardListeners: () => {
    dispatch(fromThreeviewerUi.setKeyBoardBindings(false))
    dispatch(fromThreeviewerViewer.disableKeyboardListeners())
  },
  enableKeyboardListeners: () => {
    dispatch(fromThreeviewerUi.setKeyBoardBindings(true))
    dispatch(fromThreeviewerViewer.enableKeyboardListeners())
  },
  updateAnnotation: (materialGroup, annotationText) => dispatch(fromThreeviewerUi.updateAnnotation(materialGroup, annotationText)),
  updateCostEstimation: (id, areaCost, volumeCost) => dispatch(fromCombinations.receiveCostEstimation({
    id,
    areaCost,
    volumeCost
  })),
  toggleIsNoAppearanceSetOpen: () => dispatch(fromAppearanceGroups.toggleIsNoAppearanceSetOpen()),
  toggleIsMaterialGroupOpen: (appearanceGroupId: string) => dispatch(fromAppearanceGroups.toggleIsAppearanceGroupOpen(appearanceGroupId)),
  toggleIsPartsGroupOpen: (appearanceGroupId: string) => dispatch(fromAppearanceGroups.toggleIsPartsGroupOpen(appearanceGroupId)),
  toggleIsCostAndAnnotationsOpen: (appearanceGroupId: string) => dispatch(fromAppearanceGroups.toggleIsCostAndAnnotationsOpen(appearanceGroupId)),
  setMaterial: (id: string) => dispatch(fromMaterials.setMaterial(id)),
  setColor: (id: string) => dispatch(fromColors.setColor({ id })),
  setPattern: (id: string) => dispatch(fromPatternsTextures.setPattern(id)),
})

type AppearanceGroupsStateProps = {
  pickerSelection: string[]
  isNoMaterialsAssigned: boolean
  appearanceGroups: AppearanceGroup[]
  notSetPartsGroup: PartsGroup | undefined
  annotationsActive: boolean
  isNoAppearanceSetOpen: boolean
  isAppearanceGroupOpen: string[]
  isPartsGroupOpen: string[]
  isCostAndAnnotationsOpen: string[]
  getSelectionMode: any
}

type AppearanceGroupsDispatchProps = {
  selectFromUuids: (uuids: string[], appendSelection?: boolean) => void
  fetchColors: () => void
  disableKeyboardListeners: () => void
  enableKeyboardListeners: () => void
  toggleAnnotations: (annotationsActive: boolean) => void
  updateAnnotation: (materialGroup: AppearanceGroup, annotationText: string) => void
  updateCostEstimation: (id: string, areaCost: number, volumeCost: number) => void
  toggleIsNoAppearanceSetOpen: () => void
  toggleIsMaterialGroupOpen: (appearanceGroupId: string) => void
  toggleIsPartsGroupOpen: (appearanceGroupId: string) => void
  toggleIsCostAndAnnotationsOpen: (appearanceGroupId: string) => void
  setMaterial: (partId: string) => void
  setColor: (id: string) => void
  setPattern: (id: string) => void
}

export default connect<AppearanceGroupsStateProps, AppearanceGroupsDispatchProps>(
  mapStateToProps,
  mapDispatchToProps
)
