import React from 'react'
import { useDispatch } from 'react-redux'

import * as fromCombinations from '../../../stores/ducks/combinations'
import * as fromProjects from '../../../stores/ducks/projects'

import KeyboardListener, { KeyCode, KeyBinding } from '../../common/keyboard-listener'
import InputGroup from '../../common/form/input-group'
import InputText from '../../common/form/input-text'
import Button from '../../common/button'
import Label from '../../common/form/label'
import NotificationBubble from '../../common/notification-bubble'

type PropType = {
  onClose: () => void
}

const CreateVariantModal = ({ onClose }:PropType) => {
  const defaultTitle = `Variant ${new Date().toISOString().split('T')[0]}`
  const [title, setTitle] = React.useState(defaultTitle)
  const [variantCreated, setVariantCreated] = React.useState(false)
  const dispatch = useDispatch()

  const handleCreateRender = async () => {
    await dispatch(
      fromCombinations.postRender({
        combinationType: 'variant',
        title: (title.split(' ').join('').length > 1) ? title : defaultTitle,
        renderSettings: {
          useEnvironment: false,
          ignoreAnnotations: true
        },
      })
    )
    onClose()
    dispatch(fromProjects.notify('Creating variant'))
  }

  return <>
    <h2 className='mt0'>
      {'Create Variant'}
    </h2>

    <InputGroup>
      <Label className='flex justify-between'>
        <span>Title</span>
      </Label>
      <InputText
        onChange={(value:string) => setTitle(value)}
        placeholder={defaultTitle}
        value={title}
        testid='variantNameInputField'
      />
    </InputGroup>

    <div className='mt2 clearfix flex justify-end items-baseline'>
      <Button className='mr2' onClick={onClose}>Cancel</Button>
      <Button btnType='primary' onClick={handleCreateRender}>
        Create
      </Button>
    </div>
    <KeyboardListener
      bindings={[
        KeyBinding(KeyCode.enter, handleCreateRender)
      ]}
    />
    <NotificationBubble
      notification={variantCreated ? 'Created variant' : null}
      notifyTimer={8000}
      onClearOrTimeout={() => {
        setVariantCreated(false)
      }}
    />
  </>
}

export default CreateVariantModal
