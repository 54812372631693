import React from 'react'

import { lerp } from '../../../../utils/math'
import * as materialUtils from '../../../../stores/ducks/materials/utils'

import Grid from '../../../common/grid/grid'
import { PanelSectionHeader } from '../../../common/panels/panel-section'
import styled from 'styled-components'
import Swatch from './swatch'

function diplayValue (value) {
  const mixValueObj = new materialUtils.ColorTextureMixVal(value)
  return `${mixValueObj.displayValue * 100}%`
}

const OpacityColor = styled(Swatch).attrs((props) => ({
  title: `${props.name || 'N/A'} - ${props.ncsTitle || 'N/A'} - ${props.pantoneTitle || 'N/A'}`
}))`
    background: ${props => `rgb(${props.color.r}, ${props.color.g}, ${props.color.b})`};
    &:hover {
      cursor: pointer;
    };
  `

export const StainStrengthList = (props) => {
  return (
    <div className='py2 bc-gray-light border-right overflow-hidden'>
      <div className='px2 overflow-auto height-100'>
        <div className='flex justify-center pb2'>
          <PanelSectionHeader noPadding title='Opacity' />
        </div>
        <Grid columns={['1fr']}>
          {props.values.map(stainsStrength => {
            return (
              <div key={stainsStrength}>
                <OpacityColor
                  color={{
                    r: lerp(255, props.color.r, stainsStrength),
                    g: lerp(255, props.color.g, stainsStrength),
                    b: lerp(255, props.color.b, stainsStrength)
                  }}

                  selected={props.selectedStrengths.includes(stainsStrength)}
                  onClick={() => {
                    props.onStainStrengthSelected(stainsStrength)
                  }}
                />
                <div
                  className='f8'
                  style={{
                    marginTop: 5,
                    textAlign: 'center'
                  }}
                >
                  {diplayValue(stainsStrength)}
                </div>
              </div>
            )
          })}
        </Grid>
      </div>
    </div>
  )
}
