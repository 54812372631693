import React from 'react'
import { getImageSrc, StorageApiFile, StorageApiImageType, StorageApiManifest } from '../../utils/storage'

interface Props extends React.HTMLAttributes<HTMLDivElement | HTMLImageElement> {
  file?: StorageApiFile | null
  manifest?: StorageApiManifest
  src?: string
  type?: StorageApiImageType
  format?: string
  placeholderSrc?: string
  resize?: string | [number] | [number, number]
  gravity?: string
  geometry?: string | (string|number)[]
  quality?: string | number
  constrainRatio?: [number, number]
  crop?: [number, number]
  children?: React.ReactNode
}

function findImageFile (manifest: StorageApiManifest) {
  return manifest.files.find(file => file.name.match(/\.(png|jpe?g)$/gi))
}

function getStyle (src: string, props: Props) {
  return props.constrainRatio
    ? {
      backgroundImage: `url(${src})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      paddingBottom: `${(props.constrainRatio[0] / props.constrainRatio[1] || 1) * 100}%`,
      ...props.style
    }
    : (props.style || {})
}

function getClassName (props: Props) {
  return props.constrainRatio
    ? `width-100 ${props.className}`
    : `img-responsive ${props.className}`
}

function getFileOrSrc (props: Props) {
  if (props.src) return props.src
  if (props.file) return props.file
  if (props.manifest) return findImageFile(props.manifest)
  return props.placeholderSrc || '/img/placeholder-project.jpg'
}

export default function StorageApiImage (props: Props) {
  const onClick = props.onClick ? props.onClick : () => {}
  const { onMouseEnter, onMouseLeave } = props
  const className = getClassName(props)
  const [srcAndStyle, setSrcAndStyle] = React.useState<[string, React.HTMLAttributes<HTMLElement>['style']]>(['/img/placeholder-project.jpg', {}])

  const fileOrSrc = getFileOrSrc(props)

  React.useEffect(() => {
    const src = getImageSrc(fileOrSrc, {
      type: props.type,
      format: props.format,
      resize: props.resize,
      placeholderSrc: props.placeholderSrc,
      gravity: props.gravity,
      geometry: props.geometry,
      quality: props.quality,
      crop: props.crop
    })

    const style = getStyle(src, props)
    setSrcAndStyle([src, style])
  }, [props.file, props.src, props.manifest])

  if (props.constrainRatio) {
    return (
      <div
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className={className}
        style={srcAndStyle[1]}
      >{props.children}</div>
    )
  }

  return (
    <img
      onClick={onClick}
      style={srcAndStyle[1]}
      className={className}
      src={srcAndStyle[0]}
    />
  )
}

export function StorageApiThumbnail (props: Props) {
  return (
    <StorageApiImage
      constrainRatio={[1, 1]}
      crop={[256, 256]}
      geometry={[256, 256, '"^"']} // ^ means width or height are treated as minimum values rather than maximum values
      format='jpg'
      gravity='"center"'
      {...props}
    />
  )
}
