import React from 'react'
import InputSearch from '../../../../common/form/input-search'
import _debounce from 'lodash/debounce'

type Props = {
  onFocus: () => void,
  onBlur: () => void,
  onChange: (searchTerm: string) => void, // Debouned
  placeHolder: string,
  debounce: number,
}

/**
 * A component for handling searches with a debounced callback
 * This is separated from the geometry tree to avoid updating the tree on every key typed.
 * This allows the tree to only be re-rendered when the debounced callback triggers
 */
export const DebouncedInputSearch = ({ onFocus, onBlur, onChange, placeHolder, debounce }: Props) => {
  const [searchTerm, setSearchTerm] = React.useState('')

  const onChangeDebounced = React.useMemo(
    () => _debounce((searchTerm: string) => onChange(searchTerm), debounce),
    []
  )

  return (
    <InputSearch
      onFocus={onFocus}
      onBlur={onBlur}
      value={searchTerm}
      placeholder={placeHolder}
      onClearSearch={() => {
        setSearchTerm('')
        onChange('')
      }}
      onChange={(value: string) => {
        setSearchTerm(value)
        onChangeDebounced(value)
      }}
    />
  )
}
