import { createSelector } from 'reselect'

import _curry from 'lodash/curry'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _filter from 'lodash/filter'
import _difference from 'lodash/difference'
import _orderBy from 'lodash/orderBy'
import _sortBy from 'lodash/sortBy'

import { status } from './index'
import { Roomset, FilteredRoomset } from './Roomset'
import { getActiveTemplateId } from '../templates/selectors'
import { ROOMSET_TYPES } from '../../../constants'
import { RootState } from '..'
import { ImmutableArray, ImmutableObject } from 'seamless-immutable'

export const getIsFetching = (state: RootState): boolean => state.roomsets.status === status.FETCHING

export const getEntries = (state: RootState) => state.roomsets.entries

export const getActiveEntries = createSelector(
  getEntries,
  (entries) => {
    const activeEntries = Object.values(entries).filter(entry => {
      return entry && !entry.removedAt
    })
    return _orderBy(activeEntries, ['createdAt', 'title'], ['desc'])
  }
)

const getActiveEntriesWithPresentationCameras = createSelector(
  getActiveEntries,
  (entries) => _map(entries, (entry) => {
    return {
      ...entry,
      cameras: _sortBy(_filter(entry.cameras, 'image'), ['name'])
    }
  })
)

export const getEntryById = _curry((id: string, state: RootState): ImmutableObject<Roomset> | undefined => state.roomsets.entries.getIn([id]))
export const getCurrentId = (state: RootState): string => state.roomsets.currentId || ''
export const getCurrentEntry = (state: RootState): ImmutableObject<Roomset> | undefined => state.roomsets.entries.getIn([getCurrentId(state)])

export const getRoomsetCameraList = (state: RootState): ImmutableObject<Roomset> | {} => {
  const roomsetId = getActiveTemplateId(state)
  const roomset = getEntryById(roomsetId)(state)
  return roomset ? (roomset.cameras || {}) : {}
}

export const getIsRoomsetActive = (state: RootState): boolean => {
  const activeTemplateId = getActiveTemplateId(state)
  return activeTemplateId && !['default', 'custom-home'].includes(getActiveTemplateId(state))
}

export const getFilters = (state: RootState) => state.roomsets.filters

export const getFilteredEntries = createSelector(
  getFilters,
  getActiveEntriesWithPresentationCameras,
  (filters, activeEntries) => {
    const roomsMin = _get(filters, 'rooms.min', 0)
    const roomsMax = _get(filters, 'rooms.max', Number.MAX_SAFE_INTEGER)

    const areaMin = _get(filters, 'area.min', 0)
    const areaMax = _get(filters, 'area.max', Number.MAX_SAFE_INTEGER)

    const selectedMarketArea = _get(filters, 'marketArea')
    const selectedTags = _get(filters, 'tags')

    const filteredEntries = _filter(activeEntries, (entry) => {
      if (
        !entry.metadata ||
        !entry.combinationId ||
        _get(entry, 'metadata.isHidden') ||
        entry.type === ROOMSET_TYPES.IMAGE_TEMPLATE ||
        // TODO: should assign all roomsets a type. Roomsets appearing in load home could be set to type "home".
        entry.type !== undefined
      ) {
        return false
      }

      const {
        area,
        rooms,
        tags,
        marketArea
      } = entry.metadata

      return (area! >= areaMin && area! <= areaMax) &&
        (rooms! >= roomsMin && rooms! <= roomsMax) &&
        (isSelectedTags(tags!, selectedTags)) &&
        isSelectedMarketArea(marketArea!, selectedMarketArea)
    })

    return filteredEntries as unknown as FilteredRoomset[]
  }
)

const isSelectedTags = (tags: ImmutableArray<string>, selectedTags: ImmutableArray<string>) => {
  if (!selectedTags.length) return true
  return _difference(selectedTags, tags).length === 0
}

const isSelectedMarketArea = (marketArea: ImmutableArray<string>, selectedMarketArea: ImmutableArray<string>) => {
  if (!selectedMarketArea.length) return true
  return selectedMarketArea.some((area) => marketArea.includes(area))
}

export const getCamera = _curry((roomsetId: string, cameraId: string, state: RootState) => {
  return _get(getEntryById(roomsetId, state), ['cameras', cameraId])
})

export const getCurrentPresets = (state: RootState): Roomset['presets'] | undefined => {
  const currentEntry = getCurrentEntry(state)
  return _get(currentEntry, 'presets')
}
