import React from 'react'
import _isNumber from 'lodash/isNumber'
import KeyboardListener, { KeyBinding, KeyCode } from '../keyboard-listener'
import { clamp } from 'lodash'

type Props = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'min' | 'max'> & {
  onChange: (value: number | '') => void
  min?: number
  max?: number
}

export function InputNumber (props: Props) {
  const inputRef = React.useRef<HTMLInputElement | null>(null)

  function onEnter () {
    if (inputRef.current) {
      inputRef.current.blur()
    }
  }

  function onKeyUp (event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.keyCode === 13) {
      event.currentTarget.blur()
    }
  }

  function onChange (event: React.ChangeEvent<HTMLInputElement>) {
    if (!event.target.value) return props.onChange('')

    const value = event.target.value.trim()
    if (value.length && _isNumber(Number(value))) {
      if (props.min && props.max) {
        props.onChange(clamp(Number(value), props.min, props.max))
      } else {
        props.onChange(Number(value))
      }
    } else {
      props.onChange(0)
    }
  }

  return (
    <>
      <input
        ref={inputRef}
        type='number'
        className={props.className}
        style={props.style}
        value={props.value}
        min={props.min}
        max={props.max}
        step={props.step}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        onKeyUp={onKeyUp}
        onChange={onChange}
      />

      <KeyboardListener
        bindings={[
          KeyBinding(KeyCode.enter, onEnter)
        ]}
      />
    </>
  )
}
