import fetch from '../../../utils/fetch'

const assertError = (err, msg) => {
  console.error(msg)
  console.error(err)
  throw err
}

/**
 * @param {Object} data any folder data
 * @param {Array} data.combinationIds optional array, use to add combinations when creating the folder
 */
export const create = ({ data, projectId }) => {
  return fetch('/api/folders/create', {
    method: 'POST',
    body: JSON.stringify(Object.assign({}, data, { projectId }))
  })
    .then((res) => res.json())
    .catch((err) => assertError(err, { msg: `unable to create folder in project ${projectId}`, data }))
}

export const update = ({ data, id, projectId }) => {
  return fetch('/api/folders/update', {
    method: 'POST',
    body: JSON.stringify(Object.assign({}, data, { id, projectId }))
  })
    .then((res) => res.json())
    .catch((err) => assertError(err, { msg: 'unable to update folder', id, data }))
}

export const remove = (id) => {
  return fetch('/api/folders/remove', {
    method: 'POST',
    body: JSON.stringify({
      id
    })
  })
    .then((res) => res.json())
    .catch((err) => assertError(err, { msg: 'unable to remove folder', id }))
}

export const addConnectedIds = ({
  combinationIds,
  patternIds,
  folderId
}) => {
  return fetch('/api/folders/addConnectedIds', {
    method: 'POST',
    body: JSON.stringify({
      folderId,
      combinationIds,
      patternIds
    })
  })
    .then((res) => res.json())
    .catch((err) => assertError(err, { msg: `unable to add combinations ${combinationIds} to folder ${folderId}` }))
}

export const removeConnectedIds = ({
  folderId,
  combinationIds,
  patternIds
}) => {
  return fetch('/api/folders/removeConnectedIds', {
    method: 'POST',
    body: JSON.stringify({
      folderId,
      combinationIds,
      patternIds
    })
  })
    .then((res) => res.json())
    .catch((err) => assertError(err, { msg: `unable to remove combinations ${combinationIds} from folder ${folderId}` }))
}

export const getByProject = (projectId) => {
  return fetch(`/api/folders/getByProject/${projectId}`)
    .then((res) => res.json())
    .catch((err) => assertError(err, { msg: `unable to get folders in project ${projectId}` }))
}

export const get = (id) => {
  return fetch(`/api/folders/${id}`)
    .then((res) => res.json())
    .catch((err) => assertError(err, { msg: `unable to get folder ${id}` }))
}
