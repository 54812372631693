import React from 'react'

const IconPattern = props => (
  <svg viewBox='0 0 20 20' width={props.width || props.size} height={props.height || props.size} {...props}>
    <g fill={props.color} fillRule='evenodd'>
      <path d="M18.6 0H1.4C.6 0 0 .6 0 1.4v17.2c0 .8.6 1.4 1.4 1.4h17.2c.8 0 1.4-.6 1.4-1.4V1.4c0-.8-.6-1.4-1.4-1.4zm-2.4 9.5h-5.8V7.2H19v2.3h-2.8zm-15.3 1h8.6v2.3H.9v-2.3zm9.6-6.3H19v2.1h-8.5V4.2zm-1-.5v5.8H7.2V.9h2.3v2.8zM1 13.7h8.5v2.1H1v-2.1zm9.5 2.6v-5.8h2.3v8.6h-2.3v-2.8zm8.6-14.9v1.9h-8.6V.9h8.1c.3 0 .5.2.5.5zM6.3.9v8.6H4.2V.9h2.1zM1.4.9h1.9v8.6H.9V1.4c0-.3.2-.5.5-.5zM.9 18.6v-1.9h8.6V19H1.4c-.3.1-.5-.1-.5-.4zm12.8.5v-8.6h2.1v8.6h-2.1zm4.9 0h-1.9v-8.6h2.4v8.1c0 .3-.2.5-.5.5z"/>
    </g>
  </svg>
)

IconPattern.defaultProps = {
  color: 'currentcolor',
  size: '1em'
}

export default IconPattern
