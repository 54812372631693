import React from 'react'

const IconUndo = props => (
  <svg width='1em' height='1em' viewBox='0 0 20 17' {...props}>
    <path
      d='M8 5.033V0L0 7l8 7V8.9c1.741-.13 5.393-.205 8 1.1 3.539 1.77 3 7 3 7s2.298-8-2-10c-3.199-1.488-7.175-1.87-9-1.967z'
      fill={props.color}
    />
  </svg>
)

IconUndo.defaultProps = {
  color: 'currentcolor'
}

export default IconUndo
