import React, { PureComponent } from 'react'
import autoBind from 'react-autobind'
import { createSelector } from 'reselect'
import { connect } from 'react-redux'

import _throttle from 'lodash/throttle'
import _get from 'lodash/get'

import {
  RENDER_PRESETS,
  ROOMSET_RENDER_PRESETS
} from '../../../../constants'

import * as fromCombinations from '../../../../stores/ducks/combinations'
import * as fromRoomsetSelectors from '../../../../stores/ducks/roomsets/selectors'

// JSX Components : Common
import Button from '../../../common/button'
import Form from '../../../common/form/form'
import InputText from '../../../common/form/input-text'
import InputGroup from '../../../common/form/input-group'
import Label from '../../../common/form/label'
import KeyboardListener, { KeyCode, KeyBinding } from '../../../common/keyboard-listener'

// Should be connected
class SaveDesign extends PureComponent {
  constructor (props) {
    super(props)
    autoBind(this, 'handleSubmit')
    this.state = { title: '' }
    this.handleChange = _throttle(this.handleChange.bind(this), 200)
  }

  handleSubmit (event) {
    event.preventDefault()

    const title = _get(this, '_title.root.value', '').trim()
    if (!title) return
    this.props.onClose()

    this.props.postRender({
      renderSettings: {
        preset: this.props.preset,
        useEnvironment: false,
        ignoreAnnotations: true
      },
      title,
      dontAddParent: true,
      combinationType: 'variant'
    })
  }

  handleChange (value) {
    this.setState({ title: (value || '').trim() })
  }

  render () {
    return (
      <div>
        <h2 className='mt0'>
          Save Variant (temp)
        </h2>

        <Form onSubmit={this.handleSubmit}>
          <InputGroup>
            <Label>Title</Label>
            <InputText
              name='title'
              ref={(node) => (this._title = node)}
              onChange={this.handleChange}
              placeholder='Title'
              focus
            />
          </InputGroup>

          <div className='mt2 clearfix flex justify-end items-baseline'>
            <Button
              className='mr2'
              onClick={this.props.onClose}
            >
              Cancel
            </Button>
            <Button
              disabled={!this.state.title}
              btnType='primary'
              onClick={this.handleSubmit}
            >
              Save
            </Button>
          </div>

          <KeyboardListener
            bindings={[
              KeyBinding(KeyCode.enter, this.handleSubmit)
            ]}
          />

        </Form>
      </div>
    )
  }
}

const getDefaultPreset = createSelector(
  fromRoomsetSelectors.getIsRoomsetActive,
  fromRoomsetSelectors.getCurrentPresets,
  (isRoomsetActive, currentRoomsetPresets) => {
    const presets = isRoomsetActive
      ? currentRoomsetPresets || ROOMSET_RENDER_PRESETS
      : RENDER_PRESETS

    return Object.values(presets)
      .reduce((acc, preset) => {
        return preset.isDefault ? preset : acc
      }, Object.values(presets)[0])
  }
)

const mapStateToProps = createSelector(
  getDefaultPreset,
  (preset) => ({ preset })
)

const mapDispatchToProps = {
  postRender: fromCombinations.postRender
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SaveDesign)
