import React from 'react'

import CardShell from '../project/card/CardShell'
import colors from '../../../css/colors'

import IconPantoneColor from './icons/pantone-color/icon-pantone-color'
import styled from 'styled-components'

interface Props {
  name: string
  ncsTitle: string,
  pantoneTitle: string
  color: string
  onClick: () => void
  className?: string
  isPrototypeFabricColor?: boolean
}

const Wrapper = styled.div`
cursor: pointer;

&:hover {
  opacity: 0.8;
}
`

const ColorCard = ({
  name,
  ncsTitle,
  pantoneTitle,
  color,
  onClick,
  className,
  isPrototypeFabricColor
}: Props) => {
  return (
    <Wrapper
      data-testid='common-color-card'
      className={className}
      onClick={onClick}
      title={`${!name || name.length === 0 ? 'N/A' : name} - ${!ncsTitle || ncsTitle.length === 0 ? 'N/A' : ncsTitle} - ${!pantoneTitle || pantoneTitle.length === 0 ? 'N/A' : pantoneTitle}`}
    >
      <CardShell noShadow style={{ border: `solid 1px ${colors.grayLight}`, display: 'grid', gridTemplateColumns: '24px 1fr' }} innerClass='br-big'>
        <div style={{ backgroundColor: color }} />
        <div className='px1 py1 relative' style={{ minWidth: '0px' }}>
          <div data-testid='color-card-title' className='py0 px0 f7 bold truncate'>{name || ncsTitle || pantoneTitle}</div>
          <div data-testid='color-card-rgb' className='c-gray-accessible f7 truncate'>{color}</div>
          {isPrototypeFabricColor &&
          <div title='Non-standard textile color' data-testid='color-card-pantone-icon' className='absolute bottom-0 right-0' style={{ padding: '0 0.25rem 0.25rem 0' }}>
            <IconPantoneColor/>
          </div>}
        </div>
      </CardShell>
    </Wrapper>
  )
}

export default ColorCard
