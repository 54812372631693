import React from 'react'
import moment from 'moment'

import _get from 'lodash/get'

import { tabs } from '../constants'
import { getImageSrc } from '../../../utils/storage'

import Grid from '../../common/grid/grid'
import Image from '../../common/image'

import Avatar from '../../common/avatar'

import Comments from '../comments'
import CommentImage from '../../common/comments/comment-image'
import Pending from '../../common/pending'
import { DEADLINE_STATUS } from '../../../constants'
import { VisualizedCombination } from '../../../stores/ducks/combinations/Combination'
import { Render } from '../../../stores/ducks/renders/Render'
import MultipleRendersTab from './MultipleRendersTab'
import { UserWithName } from '../../../stores/ducks/users/User'

import { createSelector } from 'reselect'
import _filter from 'lodash/filter'
import _orderBy from 'lodash/orderBy'
import _groupBy from 'lodash/groupBy'
import { RootState } from '../../../stores/ducks'

const getOriginal = (render: Render) => getImageSrc(_get(render, 'manifest.files.0', {}), { format: 'jpg', type: 'large' })

const selectPlacedComments = createSelector(
  (state: RootState, id: string) => _groupBy(state.comments.entries, 'parentEntityId')[id] || [],
  (state: RootState) => state.gallery.temporaryAnchor,
  (comments, temporaryAnchor) => {
    const output: any[] = _orderBy(_filter(comments, 'position'), 'createdAt', ['asc'])
    if (temporaryAnchor) output.push(temporaryAnchor)
    return output
  }
)

type CommentsTabProps = {
  render: Render
  id: string
  quality: string
  resolution: { x: number, y: number }
  size: { x: number, y: number }
  author: UserWithName
}

function CommentsTab (props: CommentsTabProps) {
  return (
    <>
      <CommentImage
        id={props.id}
        src={getOriginal(props.render)}
        size={props.size}
        commentSelector={(state: RootState) => selectPlacedComments(state, props.id)}
        bgColor={'bg-black-40'}
        disableAnnotation={false}
      />
      <Grid
        gridGap={0}
        rows={['auto', '1fr']}
        columns={[400]}
        className='ml1 overflow-hidden c-gray f6'
        style={{ gridArea: 'info' }}
      >
        <div className='ml1'>
          <div className='flex'>
            <Avatar user={props.author} />
            <div className='ml1'>
              <div>{props.author.name}</div>
              <div>{props.author.email}</div>
              <time className='f8'>{moment(props.render.renderedAt).fromNow()}</time>
            </div>
          </div>

          <div className='mt2'>
            <div style={{ margin: '4px 0' }}>{props.resolution}</div>
            <div>{props.quality}</div>
          </div>
          <div className='c-gray-lighter mt3 mb2'>Click on image to place a comment.</div>
        </div>

        <Comments id={props.id || (props.render && props.render.id)} />
      </Grid>
    </>
  )
}

function ImageTab ({ render }: { render: Render }) {
  const pendingSize = 300

  return (
    <div
      className='relative'
      style={{ minWidth: pendingSize }}
    >
      <div
        className='absolute'
        style={{
          width: pendingSize,
          height: pendingSize,
          left: `calc(50% - ${pendingSize / 2}px)`,
          bottom: `calc(50% - ${pendingSize / 2}px)`
        }}
      >
        {render.deadlineStatus !== DEADLINE_STATUS.COMPLETED &&
          <Pending
            {...render}
            hideTitle
            disableButtons
          />
        }
      </div>
      <Image
        src={getOriginal(render)}
        style={{
          gridArea: 'image',
          maxHeight: 'calc(100vh - 196px)',
          objectFit: 'scale-down',
          objectPosition: 'center'
        }}
        className='bg-black-40 height-100 paper-2 userselect-none'
      />
    </div>
  )
}

type Props = {
  id: string
  size: { x: number, y: number }
  author: UserWithName
  resolution: { x: number, y: number }
  quality: string
  render: Render
  combination: VisualizedCombination
  tab: string
  isCompleteBatchRendered: boolean
}

const Preview = (props: Props) => {
  return (
    <>
      {(props.tab !== tabs.COMMENTS && !props.combination.imageTemplateId) && (
        <ImageTab render={props.render} />
      )}

      {(props.tab !== tabs.COMMENTS && props.combination.imageTemplateId) && (
        <MultipleRendersTab
          combination={props.combination}
          isCompleteBatchRendered={props.isCompleteBatchRendered}
        />
      )}

      {props.tab === tabs.COMMENTS && (
        <CommentsTab
          id={props.id}
          size={props.size}
          render={props.render}
          resolution={props.resolution}
          quality={props.quality}
          author={props.author}
        />
      )}
    </>
  )
}

export default Preview
