import React, { Component } from 'react'
import PropTypes from 'prop-types'
import autoBind from 'react-autobind'
import _omit from 'lodash/omit'

import IconCross from '../icons/icon-cross'

import css from './index.css'

const getClassName = ({ fullWidth, fullHeight, className, mode }) => {
  let output = `${className} ${css.inputText}`

  if (fullWidth) {
    output = `${output} block width-100`
  }

  if (fullHeight) {
    output = `${output} height-100`
  }

  if (mode) {
    return `${output} ${css[mode]}`
  }

  return output
}

const styles = {
  iconPosition: {
    top: 10,
    right: 10
  }
}

class InputSearch extends Component {
  constructor (props) {
    super(props)
    autoBind(this)
  }

  get root () {
    return this._root
  }

  handleChange (event) {
    this.props.onChange && this.props.onChange(event.target.value)
  }

  componentDidMount () {
    if (this.props.focus) {
      this._root.focus()
    }
  }

  componentDidUpdate () {
    if (this.props.focusOnUpdate) {
      this._root.focus()
    }
  }

  render () {
    const {
      value,
      placeholder,
      onClearSearch,
      className
    } = this.props

    return (
      <div className={`relative width-100 ${className}`}>
        <input
          {..._omit(
            this.props,
            'mode',
            'fullWidth',
            'fullHeight',
            'focus',
            'focusOnUpdate',
            'onClearSearch'
          )}
          type='search'
          data-testid='common-search'
          value={value}
          placeholder={placeholder}
          onChange={this.handleChange}
          ref={(node) => (this._root = node)}
          className={getClassName(this.props)}
        />
        {!!value && <div
          className='absolute c-gray pointer'
          style={styles.iconPosition}
          data-testid='common-search-clear'
          onClick={() => onClearSearch()}
        >
          <IconCross size={16} />
        </div>}
      </div>
    )
  }
}

InputSearch.propTypes = {
  mode: PropTypes.oneOf(['error', false]),
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  fullHeight: PropTypes.bool,
  focus: PropTypes.bool,
  focusOnUpdate: PropTypes.bool,
  onClearSearch: PropTypes.func
}

InputSearch.defaultProps = {
  className: '',
  fullWidth: true,
  fullHeight: false,
  focus: false,
  focusOnUpdate: false,
  placeholder: '',
  onClearSearch: () => {}
}

export default InputSearch
