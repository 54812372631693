import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Spinner from '../spinner'
import { toggleSelectPlacedComment, placeTemporaryAnchor, clearTemporaryAnchor } from '../../../stores/ducks/gallery'
import useWindowResize from '../../../hooks/useWindowResize'
import useImageLoaded from '../../../hooks/useImageLoaded'
import { getSelectedCommentId } from '../../../stores/ducks/gallery/selectors'
import CommentAnchor from './comment-anchor'
import type { Comment } from '../../../stores/ducks/scenes/Temp'

type CommentImageProps = {
  id: string
  src: string
  size: { x: number, y: number }
  commentSelector: any
  bgColor: string
  disableAnnotation: boolean
}

function CommentImage (props: CommentImageProps) {
  const dispatch = useDispatch()
  const loaded = useImageLoaded(props.src)
  const [imageSize, setImageSize] = React.useState([0, 0])
  const [style, setStyle] = React.useState({ top: 0, left: 0, width: 0, height: 0 })
  const selectedCommentId = useSelector(getSelectedCommentId)
  const placedComments:Comment[] = useSelector(props.commentSelector)
  const rootRef = React.useRef<HTMLDivElement | null>(null)
  const imageRef = React.useRef<HTMLImageElement | null>(null)

  const handlePlaceComment = React.useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault()
    event.stopPropagation()

    if (imageRef.current) {
      const rect = imageRef.current.getBoundingClientRect()
      const x = ((event.clientX - rect.left) / rect.width) * 100
      const y = ((event.clientY - rect.top) / rect.height) * 100
      dispatch(placeTemporaryAnchor({ x, y }))
    }
  }, [dispatch])

  const onResize = React.useCallback(() => {
    let width = 0
    let rootWidth = 0
    let rootHeight = 0

    if (imageRef && imageRef.current) {
      width = imageRef.current.naturalWidth
    }
    if (rootRef && rootRef.current) {
      rootHeight = rootRef.current.clientHeight
      rootWidth = rootRef.current.clientWidth
    }

    const aspectRatio = props.size.y / props.size.x
    let imageWidth = Math.min(width, rootWidth)
    let imageHeight = imageWidth * aspectRatio
    if (imageHeight > rootHeight) {
      imageHeight = rootHeight
      imageWidth = imageHeight / aspectRatio
    }

    setImageSize([imageWidth, imageHeight])

    setStyle({
      top: (rootHeight * 0.5) - (imageHeight * 0.5),
      left: (rootWidth * 0.5) - (imageWidth * 0.5),
      width: imageWidth,
      height: imageHeight
    })
  }, [])

  useWindowResize(onResize)
  React.useEffect(onResize, [loaded])
  React.useEffect(() => {
    return () => {
      dispatch(clearTemporaryAnchor())
    }
  }, [])

  return (
    <div
      className={`relative height-100 paper-0 userselect-none ${props.bgColor}`}
      ref={rootRef}
    >
      {!loaded && (
        <div className='height-100 width-100 flex justify-center items-center c-white'>
          <Spinner className='c-white-10' />
        </div>
      )}
      {loaded && (
        <>
          <img
            className='absolute'
            ref={imageRef}
            src={props.src}
            style={style}
          />

          <div
            className='absolute'
            style={style}
            onClick={props.disableAnnotation ? () => {} : handlePlaceComment}
          >
            {placedComments.map((comment, index) => (
              <CommentAnchor
                key={comment.id}
                isActive={comment.id === selectedCommentId}
                posX={(comment.position.x / 100) * imageSize[0]}
                posY={(comment.position.y / 100) * imageSize[1]}
                count={index + 1}
                onClick={() => dispatch(toggleSelectPlacedComment(comment.id))}
              />
            ))}
          </div>
        </>
      )}
    </div>
  )
}

export default CommentImage
