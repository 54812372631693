import { PureComponent } from 'react'
import autoBind from 'react-autobind'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as fromThreeviewerFilesSelectors from '../../../../stores/ducks/threeviewer/files/selectors'
import * as fromUndoRedo from '../../../../stores/ducks/undo-redo'

function noop () { }

class UndoRedoContainer extends PureComponent {
  constructor (props) {
    super(props)
    autoBind(this, 'handleKeyDown')
  }

  componentDidMount () {
    window.addEventListener('keydown', this.handleKeyDown)
  }

  componentWillUnmount () {
    this.props.dispose()
    window.removeEventListener('keydown', this.handleKeyDown)
  }

  handleKeyDown (event) {
    // command + z
    if ((event.ctrlKey || event.metaKey) && event.keyCode === 90) {
      if (this.props.hasFuture && event.shiftKey) {
        this.props.redo()
      } else if (this.props.hasPast) {
        this.props.undo()
      }
    }
  }

  render () {
    const {
      hasPast,
      hasFuture,
      undo,
      redo
    } = this.props

    return this.props.children({
      canUndo: hasPast,
      canRedo: hasFuture,
      undo: hasPast ? undo : noop,
      redo: hasFuture ? redo : noop
    })
  }
}

const mapStateToProps = (state) => {
  const isSceneLoaded = fromThreeviewerFilesSelectors.getIsSceneLoaded(state)
  return {
    hasFuture: isSceneLoaded && state.undoRedo.redos.length,
    hasPast: isSceneLoaded && state.undoRedo.undos.length
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators(
  fromUndoRedo, dispatch
)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UndoRedoContainer)
