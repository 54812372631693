import React, { useState } from 'react'

import InputText from '../../../common/form/input-text'
import InputGroup from '../../../common/form/input-group'
import Label from '../../../common/form/label'
import Button from '../../../common/button'
import KeyboardListener, { KeyCode, KeyBinding } from '../../../common/keyboard-listener'

type Props = {
  onSubmit: (title: string) => void
  onCancel: () => void
}

const SaveSceneForm = ({ onSubmit, onCancel }: Props) => {
  const [title, setTitle] = useState('')
  const [errorMsg, setErrorMsg] = useState('')

  const preSubmit = () => {
    if (title.trim && title.trim().length > 0) return onSubmit(title)
    setErrorMsg('Required')
  }

  return (
    <div>
      <h2 className='mt0'>
        Save image as scene
      </h2>

      <InputGroup>
        <Label className='flex justify-between'>
          <span>Title</span>
          {errorMsg.length > 0 && <span className='c-error regular'>{errorMsg}</span>}
        </Label>
        <InputText
          focus
          placeholder='Title'
          onChange={(input: string) => {
            setTitle(input)
            setErrorMsg('')
          }
          }
        />
      </InputGroup>

      <div className='flex justify-end items-end mt3'>
        <Button onClick={onCancel}>
        Cancel
        </Button>
        <Button btnType='primary' className='ml2' onClick={preSubmit}>
        Create
        </Button>
      </div>
      <KeyboardListener
        bindings={[
          KeyBinding(KeyCode.enter, preSubmit)
        ]}
      />

    </div>
  )
}

export default SaveSceneForm
