import { createSelector } from 'reselect'

import _get from 'lodash/get'
import _curry from 'lodash/curry'

const getLocalState = (state) => state.materials

export const getShouldFetch = (initialFetch) => createSelector(
  getLocalState,
  (materialsState) => {
    if (initialFetch && materialsState.hasFetchedInitial) {
      return false
    }
    return true
  }
)

export const getJsonEntries = (state) => _get(getLocalState(state), 'materialsJson', {})
const getMaterials = (state) => _get(getLocalState(state), 'materials', {})
export const getEnvMap = (state) => _get(getLocalState(state), 'envMap', {})
export const getJsonEntryById = _curry((id, state) => _get(getJsonEntries(state), id))
export const getMaterialById = _curry((id, state) => _get(getMaterials(state), id))
