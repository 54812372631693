import { createSelector } from 'reselect'

import _get from 'lodash/get'
import _has from 'lodash/has'
import _intersection from 'lodash/intersection'
import { getIsFeatureActive } from '../threeviewer/selectors'

const getLocalState = (state) => state.users

export const getCurrentUserId = (state) => _get(getLocalState(state), 'currentUserId')
export const getCurrentId = (state) => _get(getLocalState(state), 'currentUserId')
export const getEntries = (state) => getLocalState(state).entries

export const getById = (id) => (state) => _get(getEntries(state), id)
export const getCurrent = (state) => getById(getCurrentUserId(state))(state)

export const getNotification = (state) => _get(getLocalState(state), 'notification')
export const getError = (state) => _get(getLocalState(state), 'error')

export const getHasCurrentScopes = (...scopes) => (state) => {
  const userScopes = _get(getCurrent(state), 'scopes', [])
  return _intersection(scopes, userScopes).length === scopes.length
}

export const isDnpUser = createSelector(
  getCurrent,
  (currentUser) => {
    return currentUser.apiUserData && _has(currentUser, ['apiUserData', 'dnp'])
  }
)

export const selectSelectedUser = (state) => state.admin.selectedUser

export const selectIsFeatureActive = createSelector(
  (state) => getIsFeatureActive(state),
  (getIsFeatureActive) => {
    return {
      isFeatureActive: (feature) => getIsFeatureActive(feature, true)
    }
  }
)
