import React, { useState, useEffect, useRef } from 'react'
import { Column, Table, AutoSizer } from 'react-virtualized'
import LargeCenteredLoader, { CenteredDiv } from './LargeCenteredLoader'

export type BocListItem = {
  id: string
  cadNumber: string,
  name: string,
  coatingIds: string[]
  materialIds: string[]
  icomId: any
}

type Props = {
  bocId: null | string
  setBocListLoading: React.Dispatch<boolean>
  setBocList: React.Dispatch<null | BocListItem[] >
  clearSelectedBocPart: () => void
}

const BocList = ({ bocId, setBocListLoading, setBocList, clearSelectedBocPart } : Props) => {
  const [list, setList] = useState<null | BocListItem[] >(null)

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const lastAbortController = useRef<AbortController>()

  const abortLastRequest = () => {
    if (lastAbortController.current) {
      lastAbortController.current.abort()
    }
  }

  const setAbortStatus = () => {
    setError(false)
    setList(null)
    clearSelectedBocPart()
  }

  useEffect(() => {
    setBocList(list)
  }, [list])

  useEffect(() => {
    const fetchData = async (selectedBocPart:string) => {
      setLoading(true)
      setError(false)

      abortLastRequest()

      const currentAbortController = new AbortController()
      lastAbortController.current = currentAbortController

      try {
        const result = await fetch(`/api/plm/getAppearances/${selectedBocPart}`, {
          signal: currentAbortController.signal
        })
        const json: any = await result.json()

        if (currentAbortController.signal.aborted) {
          setAbortStatus()
          return
        }

        setList(json)
      } catch (e) {
        if (currentAbortController.signal.aborted) {
          setAbortStatus()
          return
        }
        setError(true)
      }
      setLoading(false)
    }
    if (bocId) fetchData(bocId)
    else setList(null)

    return () => {
      abortLastRequest()
    }
  }, [bocId])

  useEffect(() => {
    setBocListLoading(loading)
  }, [loading])

  if (loading) {
    return <LargeCenteredLoader abort={() => {
      abortLastRequest()
      setAbortStatus()
      setLoading(false)
    }}/>
  }
  if (error) return <CenteredDiv>Data is not available from PLM</CenteredDiv>

  return (list ? <AutoSizer>
    {({ width, height } : {width:any, height:any}) => (
      <Table
        disableHeader={false}
        rowGetter={({ index } : { index : number }) => list[index]}
        headerHeight={40}
        rowHeight={30}
        rowCount={list.length}
        width={width}
        height={height}
        gridClassName='bc-black-55 f6'
        gridStyle={{ outline: 'none' }}
      >
        <Column
          width={width / 2}
          label='PLM part'
          dataKey='name'
          disableSort
        />
        <Column
          width={width / 2}
          label='Appearance'
          dataKey='icomName'
          disableSort
        />
      </Table>
    )}

  </AutoSizer> : <CenteredDiv>Select BOC part to show BOC list</CenteredDiv>)
}

export default BocList
