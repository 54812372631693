import { connect } from 'react-redux'
import { createSelector } from 'reselect'

import * as fromProjectsSelectors from '../../stores/ducks/projects/selectors'
import * as fromCombinationsSelectors from '../../stores/ducks/combinations/selectors'

import Header from '../layout/header'

const mapStateToProps = createSelector(
  fromProjectsSelectors.getCurrentEntry,
  fromCombinationsSelectors.getCurrentEntry,
  (currentProject, currentCombination) => {
    const isImagePackageProject = currentCombination.imagePackageId

    const breadcrumbs = [
      {
        id: 0,
        title: currentProject.title
      }
    ]

    return {
      breadcrumbs,
      isImagePackageProject
    }
  }
)

export default connect(
  mapStateToProps
)(Header)
