import fetch from '../../../utils/fetch'
import { Pattern } from '../patterns/Pattern'
import type { PostRenderData } from './actions/post-render'
import { Combination, VisualizedCombination } from './Combination'

const assertError = (msg: { msg: string, id?: string, ids?: string[], projectId?: string, parentId?: string, data?: any }) => (err: Error) => {
  console.error(msg)
  console.error(err)
  throw err
}

export const update = ({ data, id }: { data: Partial<Combination>, id: string }) => (
  fetch('/api/combinations/update', {
    method: 'POST',
    body: JSON.stringify(Object.assign({}, data, { id }))
  })
    .then((res) => res.json())
    .catch(assertError({ msg: 'unable to update combination', id, data }))
)

export const create = (data: PostRenderData) => {
  return fetch('/api/combinations/create', {
    method: 'POST',
    body: JSON.stringify(data)
  })
    .then((res) => res.json())
    .catch(assertError({ msg: 'unable to create combination', data }))
}

export async function visualizeMany (ids: string[]) {
  try {
    const res = await fetch('/api/combinations/visualize', {
      method: 'POST',
      body: JSON.stringify({ ids })
    })
    return res.json() as unknown as ({
      docs: VisualizedCombination[]
      modelFiles: string[]
      materials: any
      patterns: Pattern[]
    })
  } catch (err) {
    assertError({ msg: 'unable to fetch combination', ids })(err)
    throw err
  }
}

export async function visualize (id: string) {
  try {
    const res = await fetch(`/api/combinations/visualize/${id}`)
    return res.json() as unknown as VisualizedCombination
  } catch (err) {
    assertError({ msg: 'unable to fetch combination', id })(err)
    throw err
  }
}

export async function fetchRoomHoleModels () {
  try {
    const res = await fetch('/api/combinations/room-hole-models')
    const result = await res.json()
    return result as unknown as ({
      docs: VisualizedCombination[]
      modelFiles: string[]
      materials: any
      patterns: Pattern[]
    })
  } catch (err) {
    assertError({ msg: 'unable to fetch room hole models' })(err)
    throw err
  }
}

export const get = (id: string) => {
  return fetch(`/api/combinations/${id}`)
    .then((res) => res.json())
    .catch(assertError({ msg: 'unable to fetch combination', id }))
}

export const getChildrenByParent = (parentId: string) => (
  fetch(`/api/combinations/${parentId}/children`)
    .then((res) => res.json())
    .catch(assertError({ msg: 'unable to fetch combination', parentId }))
)

export const remove = (id: string, projectId: string) => (
  fetch('/api/combinations/remove', {
    method: 'POST',
    body: JSON.stringify({
      id: id,
      projectId: projectId,
      // TODO remove this when bedsets no longer uses project.combinations in frontend
      removeFromProject: true
    })
  })
    .then((res) => res.json())
    .catch(assertError({ msg: 'unable to remove combination', id, projectId }))
)

export const createImagePackage = (payload: any) => (
  fetch('/api/combinations/deprecated-create-image-package', {
    method: 'POST',
    body: JSON.stringify(payload)
  })
    .then((res) => res.json())
    .catch(assertError({ msg: 'unable to create image package', data: payload }))
)

export const shareCombination = (payload: { id: string, userId: string }) => (
  fetch('/api/send-assets/combinations', {
    method: 'POST',
    body: JSON.stringify({
      id: payload.id,
      userId: payload.userId
    })
  })
    .then((res) => res.text())
)

export const updateBatchRenderTitle = ({ data, id }: { data: Partial<Combination>, id: string }) => (
  fetch('/api/combinations/updateBatchRenderTitle', {
    method: 'POST',
    body: JSON.stringify(Object.assign({}, data, { id }))
  })
    .then((res) => res.json())
    .catch(assertError({ msg: 'unable to update combination', id, data }))
)
