import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { MdDelete as IconTrash } from 'react-icons/md'
import { ImmutableArray, ImmutableObject } from 'seamless-immutable'

import * as fromVirtualProducts from '../../../stores/ducks/virtual-products'
import { RootState } from '../../../stores/ducks'
import { Attachment, VirtualProductProjectListItem } from '../../../stores/ducks/virtual-products/VirtualProduct'
import { StorageApiThumbnail } from '../../common/storage-api-image'
import Autofill from '../../common/grid/AutoFill'
import Info from '../../common/info'
import UploadImage from './UploadImage'

interface Props {
  style: React.CSSProperties
  id: string
}

export default function Images ({ style, id }: Props) {
  const dispatch = useDispatch()
  const virtualProduct:ImmutableObject<VirtualProductProjectListItem> = useSelector((state: RootState) => state.virtualProducts.entries[id])
  const [attachments, setAttachments] = useState<ImmutableArray<Attachment> | null>(null)
  const [isUploading, setStatus] = useState(false)

  useEffect(() => {
    setAttachments(virtualProduct.attachments)
  }, [virtualProduct])

  const removeSelected = (dpdKey: string, vpdbKey: string) => {
    dispatch(fromVirtualProducts.deleteVirtualProductAttachment(id, dpdKey, vpdbKey))
  }

  const renderImages = (attachments: ImmutableArray<Attachment>) => {
    return attachments
      .asMutable()
      .reverse()
      .filter(attachment => attachment.type === 'image')
      .map((attachment, i) =>
        <Image
          key={i}
          file={attachment.file}
          onIconClick={() => removeSelected(attachment.file.key, attachment.vpdbKey)}
        />
      )
  }

  return (
    <div className='overflow-auto height-100' style={style}>
      <Autofill className='p2 border bc-gray-light br-small' width={140}>
        <UploadImage id={id} onUploading={(uploadingStatus) => setStatus(uploadingStatus)}/>
        {isUploading && <Info icon='block-before icon-spin1 animate-spin my2 c-gray-light' />}
        {attachments && renderImages(attachments)}
      </Autofill>
    </div>
  )
}

interface ImageProps {
  file: any
  onIconClick: () => void
}

const Image = ({ file, onIconClick }: ImageProps) => {
  const [hover, setHover] = React.useState<boolean>(false)
  return (
    <StorageApiThumbnail
      file={file}
      constrainRatio={[5, 4]}
      className='pointer relative'
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {hover &&
      <Wrapper>
        <StyledIcon onClick={(event) => {
          onIconClick()
          event.stopPropagation()
        }
        }>
          <IconTrash size={32}/>
        </StyledIcon>
      </Wrapper>
      }
    </StorageApiThumbnail>
  )
}

const StyledIcon = styled.div`
  display: flex;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  cursor: pointer;
  background-color: rgba(255,255,255,0.2);
  `

const Wrapper = styled.div`
  position: absolute;
  inset: 0;
`
