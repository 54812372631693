import React from 'react'
import SpriteImage from '../../../../common/sprite-image'
import { AppearanceGroup } from '../appearance-groups-connect'
import _get from 'lodash/get'

type Props = {
  appearanceGroup: AppearanceGroup
  styles?: React.CSSProperties
}

export function AppearanceGroupThumbnail (props: Props) {
  return (
    <div style={props.styles}>
      {
        props.appearanceGroup.thumbnailOffset ? (
          <SpriteImage
            src={_get(props.appearanceGroup, 'thumbnailOffset.spritesheet', props.appearanceGroup.thumbnail)}
            tileSize={128}
            tilesPerRow={8}
            offsetX={props.appearanceGroup.thumbnailOffset.x}
            offsetY={props.appearanceGroup.thumbnailOffset.y}
            placeholderSrc={'/img/placeholder-material.jpg'}
            spinnerSize='small'
          />
        ) : (
          <img
            src={props.appearanceGroup.thumbnail || '/img/placeholder-material.jpg'}
            style={props.styles}
          />
        )
      }
    </div>
  )
}
