import { createSelector } from 'reselect'

import { RootState } from '..'
import * as fromSelectionSelectors from '../selection/selectors'
import * as fromThreeviewerFilesSelectors from '../threeviewer/files/selectors'
import * as fromThreeviewerSelectors from '../threeviewer/selectors'

import { FlattenedNodes } from './TreeNode'
import { CAMERA_MODES } from '../threeviewer/camera'
import { FEATURES } from '../../../constants'

import { overlaps, shouldShowCarrierIcon, shouldShowMaterialIcon } from './utils'

const getLocalState = (state: RootState) => state.tree

export const getNodes = (state: RootState) => getLocalState(state).nodes
export const getIsDragging = (state: RootState) => getLocalState(state).isDragging
export const getOpenedNodeUuids = (state: RootState) => getLocalState(state).openedNodeUuids
export const getScrollToNode = (state: RootState) => getLocalState(state).scrollToNode

export const getFlatNodes = (state: RootState) => getLocalState(state).flatNodes as unknown as FlattenedNodes
export const getFlatNode = (state: RootState, uuid: string) => getFlatNodes(state)[uuid]

/**
 * Determines if the current selection can be grouped and/or ungrouped
 */
export const getCanGroupAndUngroup = createSelector(
  fromSelectionSelectors.getSelection,
  fromThreeviewerSelectors.getNodeList,
  fromThreeviewerSelectors.getViewer,
  (selection, nodeList, viewer) => {
    const canGroup: string[] = []
    let canUngroup = false

    const uuids: string[] = Array.from(selection)

    for (let index = 0; index < uuids.length; index++) {
      const node = nodeList[uuids[index]]
      if (!node) continue

      if (node.userData.isGroup) {
        canUngroup = true

        if (!canGroup.includes(node.uuid)) {
          canGroup.push(node.uuid)
        }

        continue
      }

      const rootNode = viewer.viewerUtils.findRootNode(node)
      if (rootNode && !canGroup.includes(rootNode.uuid)) {
        canGroup.push(rootNode.uuid)
      }
    }

    return {
      canGroup: canGroup.length > 1,
      canUngroup
    }
  }
)

export const getCanGroup = createSelector(
  getCanGroupAndUngroup,
  fromThreeviewerFilesSelectors.getIsSceneLoaded,
  ({ canGroup }, isSceneLoaded) => canGroup && isSceneLoaded
)

export const getCanUngroup = createSelector(
  getCanGroupAndUngroup,
  fromThreeviewerFilesSelectors.getIsSceneLoaded,
  ({ canUngroup }, isSceneLoaded) => canUngroup && isSceneLoaded
)

/**
 * The tree is disabled if annotations are active, or if the scene is not fully loaded yet
 */
export const getIsDisabled = createSelector(
  fromThreeviewerSelectors.getAnnotationsActive,
  fromThreeviewerFilesSelectors.getIsSceneLoaded,
  (annotationsActive, isSceneLoaded) => !!annotationsActive || !isSceneLoaded
)

/**
 * Isolation is not enabled in certain camera modes
 */
export const getIsIsolationEnabled = createSelector(
  fromThreeviewerSelectors.getCameraMode,
  (cameraMode) => ![CAMERA_MODES.OFFSET_VIEW_CAMERA, CAMERA_MODES.PREDEFINED].includes(cameraMode)
)

/**
 * Ensures that the flattened nodes are only recalculated when absolutely necessary, i.e
 * every time forced update changes AND the scene is fully loaded. While loading, the
 * flattened tree is rarely recalculated.
 */
export const getUpdateFlatNodes = createSelector(
  fromThreeviewerFilesSelectors.getIsSceneLoaded,
  getNodes,
  fromThreeviewerSelectors.getForcedUpdateTree,
  (isSceneLoaded) => !isSceneLoaded ? 0 : Math.random()
)

export const getHasNodes = createSelector(
  getNodes,
  (nodes) => Object.keys(nodes).length > 0
)

/**
 * A node is considered grouped if the closest parent is a variant or group
 */
export const getIsGrouped = createSelector(
  getFlatNodes,
  (_: RootState, uuid: string) => uuid,
  (flatNodes, uuid) => {
    const node = flatNodes[uuid]
    if (node && node.parent) {
      const parent = flatNodes[node.parent]
      if (parent) {
        return parent.type === 'group' || parent.type === 'variant'
      }
    }
    return false
  }
)

// Selector factories

// Since the geometry nodes need to derive some redux state, often with their own uuid as an argument,
// caching becomes a bit more complicated. Selectors created using reselect have (typically) a cache of
// only 1. Therefore, creating a unique selector for each geometry node is a good idea. This allows each
// node to keep their own cache.

/**
 * Checks if the specified node is opened
 * @param uuid A node
 */
export const createIsOpenedSelector = (uuid: string) => createSelector(
  getOpenedNodeUuids,
  (uuids) => uuids.includes(uuid)
)

/**
 * Checks if the specified node is selected
 * @param uuid A node
 */
export const createIsSelectedSelector = (uuid: string) => createSelector(
  fromSelectionSelectors.getSelection,
  (selection) => selection.includes(uuid) as boolean
)

/**
 * Checks if the specified node is hidden
 * @param uuid A node
 */
export const createIsHiddenSelector = (uuid: string) => createSelector(
  fromSelectionSelectors.getHidden,
  (hidden) => hidden.includes(uuid) as boolean
)

/**
 * Checks if a single child node (no matter how deep down the tree) is hidden
 *
 * @param uuid The source node
 */
export const createIsChildrenHiddenSelector = (uuid: string) => createSelector(
  fromSelectionSelectors.getHidden,
  getFlatNodes,
  (hidden, flatNodes) => overlaps(hidden, flatNodes[uuid]?.children ?? [])
)

/**
 * Checks if a single child node (no matter how deep down the tree) is selected
 *
 * @param uuid The source node
 */
export const createIsChildrenSelectedSelector = (uuid: string) => createSelector(
  fromSelectionSelectors.getSelection,
  getFlatNodes,
  (selection, flatNodes) => overlaps(selection, flatNodes[uuid]?.children ?? [])
)

/**
 * Checks if a single ancestor node is hidden
 *
 * @param uuid The source node
 */
export const createIsAncestorHiddenSelector = (uuid: string) => createSelector(
  fromSelectionSelectors.getHidden,
  getFlatNodes,
  (hidden, flatNodes) => overlaps(hidden, flatNodes[uuid]?.ancestors ?? [])
)

/**
 * Checks if a single ancestor node is selected
 *
 * @param uuid The source node
 */
export const createIsAncestorSelectedSelector = (uuid: string) => createSelector(
  fromSelectionSelectors.getSelection,
  getFlatNodes,
  (selection, flatNodes) => overlaps(selection, flatNodes[uuid]?.ancestors ?? [])
)

/**
 * Check if the node is a parent of any selected node
 * @param uuid The source node
 */
export const createIsNodeParentToSelectionSelector = (uuid: string) => createSelector(
  getFlatNodes,
  fromSelectionSelectors.getSelection,
  (flatNodes, selection: string[]) => {
    if (!flatNodes) return false
    return !!selection.find(selectedUuid => flatNodes[selectedUuid]?.parent === uuid)
  }
)

/**
 * Checks if the specified node is a child to a selected group
 *
 * @param uuid A node
 */
export const createIsNodeChildToSelectedGroupSelector = (uuid: string) => createSelector(
  getFlatNodes,
  fromSelectionSelectors.getSelection,
  (flatNodes, selection) => {
    const dropNode = flatNodes[uuid]
    if (!dropNode) return false
    return Boolean(selection.find((selectionUuid: string) => (
      (flatNodes[selectionUuid]?.type === 'group' || flatNodes[selectionUuid]?.type === 'variant') && dropNode.ancestors.includes(selectionUuid)
    )))
  }
)

export const createFlatNodeSelector = (uuid: string) => createSelector(
  getFlatNodes,
  (flatNodes) => flatNodes[uuid]
)

/**
 * Determine if a node is droppable. Based on the following logic:
 * Mesh and parts are not dropable
 * Models are dropable if they are not grouped i.e. in the root of the tree
 * A model can't be dropped on the direct parent group or other models already in a group
 * Groups can't be dropped on its own child groups
 */
export const createIsDroppableSelector = (uuid: string) => {
  const isSelectedSelector = createIsSelectedSelector(uuid)
  const flatNodeSelector = createFlatNodeSelector(uuid)
  const isNodeParentToSelectionSelector = createIsNodeParentToSelectionSelector(uuid)
  const isNodeChildToSelectedGroupSelector = createIsNodeChildToSelectedGroupSelector(uuid)
  const isGroupedSelector = (state: RootState) => getIsGrouped(state, uuid)
  return createSelector(
    getIsDragging,
    isGroupedSelector,
    isSelectedSelector,
    flatNodeSelector,
    isNodeParentToSelectionSelector,
    isNodeChildToSelectedGroupSelector,
    (
      isDragging,
      isGrouped,
      selected,
      flatNode,
      isNodeParentToSelection,
      isNodeChildToSelectedGroup,
    ) => {
      if (!isDragging || selected) return false

      // parts and meshes are not supposed to be droppable (or dragable)
      if (flatNode?.type === 'mesh' || flatNode?.type === 'part') {
        return false
      }
      // Prevent dragging items to be dropped on a parent which they already belong to. If dragging items contains groups prevent them from being dropped on groups that are children of the selected groups
      if (isNodeParentToSelection || isNodeChildToSelectedGroup) {
        return false
      }
      if (['group', 'variant'].includes(flatNode?.type || '')) {
        return true
      }

      // NOTE: It makes more sense to return true here. This allows sibling nodes to be grouped together by dragging.
      // At the moment, sibling nodes cannot be grouped by dragging, however they can be grouped by selecting both and using
      // the action modal.
      return !isGrouped
    }
  )
}

/**
 * Determines if the material icon should be visible
 *
 * @param uuid A node
 */
export const createShouldShowMaterialIconSelector = (uuid: string) => createSelector(
  getFlatNodes,
  (flatNodes) => shouldShowMaterialIcon(uuid, flatNodes)
)

/**
 * Determines if the carrier icon should be visible
 *
 * @param uuid A node
 */
export const createShouldShowCarrierIconSelector = (uuid: string) => createSelector(
  getFlatNodes,
  (state: RootState) => fromThreeviewerSelectors.getIsFeatureActive(state)(FEATURES.PRODUCT_CONCEPT_ANALYSIS, true),
  (flatNodes, isPcaActive) => shouldShowCarrierIcon(uuid, isPcaActive, flatNodes)
)
