import React from 'react'

const CardWithIcon = ({ children, icon, className }) => (
  <div className={`paper-1 px4 py3 height-100 bg-white c-gray-light pointer flex items-center clearfix ${className}`}>
    <div className='col col-4 sm-hide xs-hide md-hide pr3'>
      {icon}
    </div>
    <div className='col col-12 lg-col-8 c-gray-dark'>
      {children}
    </div>
  </div>
)

export default CardWithIcon
