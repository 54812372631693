import { EventEmitter } from 'events'

export default navigate

function navigate (url, hardLink, pushState = true) {
  if (!hardLink) {
    if (pushState) {
      window.history.pushState(null, null, url)
    }
    navigate.emitter.emit('navigate')
  } else {
    window.location.assign(url)
  }
}

navigate.emitter = new EventEmitter()

navigate.onPopstate = (event) => {
  navigate.emitter.emit('navigate', event)
}

window.addEventListener('popstate', navigate.onPopstate)
