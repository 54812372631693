import React, { useState } from 'react'
import InputText from '../../common/form/input-text'
import styled from 'styled-components'
import Button from '../../common/button'
import Label from '../../common/form/label'
import {
  MdErrorOutline as IconError,
} from 'react-icons/md'

interface Props {
  fw: string,
  color?: string
}

const Container = styled.div`
  width: 100%;
  height: 400px;
`
const Input = styled(InputText)`
  width: 360px;
  height: 40px;
`
const InputContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 500px;
  height: 60px;
`

const Card = styled.div`
  width: 640px;
  height: 150px;
  background-color: #edeff2;
  border-radius: 4px;
  margin-left: 10px;
  display: flex;
`
const Text = styled(Label)<Props>`
  font-size: 14px;
  padding-left: 10px;
  padding-top: 10px;
  font-weight: ${p => p.fw};
  color: ${p => p.color};
`

export default function SupportTools () {
  const [projectId, setProjectId] = useState<string>('')
  const [currentProject, setCurrentProject] = useState<any>(null)
  const [responseCodeGetProject, setResponseCodeGetProject] = useState<number>(0)
  const [responseShareProject, setResponseShareProject] = useState<number>(0)

  const getProject = async (projectId: string) => {
    const res = await fetch('/api/support-tools/get-project', {
      method: 'POST',
      body: JSON.stringify({ projectId: projectId })
    })
    if (res.status === 200) {
      const response = await res.json()
      setCurrentProject(response)
      setResponseCodeGetProject(200)
    } else {
      setResponseCodeGetProject(res.status)
      setResponseShareProject(0)
    }
  }

  const shareProject = async () => {
    const res = await fetch('/api/support-tools/share-project', {
      method: 'POST',
      body: JSON.stringify({
        projectId: currentProject.id,
      })
    })
    if (res.status === 200) setResponseShareProject(200)
    else setResponseShareProject(res.status)
  }

  return (
    <Container>
      <Label style={{ marginLeft: 10, marginBottom: 20 }}>Share project</Label>
      <InputContainer>
        <Input
          onChange={setProjectId}
          placeholder="Add project ID here..."
        />
        <Button
          pad={2}
          disabled={false}
          inverted={false}
          btnType={'primary'}
          onClick={() => getProject(projectId)}
          style={{ display: 'inline', height: 40 }}
        >Get project</Button>
      </InputContainer>
      {responseShareProject === 200 && <Text fw="bold" color="#4BB543">Project successfully shared with admin!</Text>}
      {responseCodeGetProject !== 0 && (
        <Card>
          {responseCodeGetProject === 200
            ? <>
              <div style={{ width: 480, height: 20 }}>
                <div style={{ display: 'flex' }}>
                  <Text fw="bold">Project name: </Text>
                  <Text fw="normal">{currentProject?.title}</Text>
                </div>
                <div style={{ display: 'flex' }}>
                  <Text fw="bold">Project ID: </Text>
                  <Text fw="normal">{currentProject?.id}</Text>
                </div>
                <div style={{ display: 'flex' }}>
                  <Text fw="bold">Created by: </Text>
                  <Text fw="normal">{currentProject?.createdBy}</Text>
                </div>
                <div style={{ display: 'flex' }}>
                  <Text fw="bold">Created at: </Text>
                  <Text fw="normal">{currentProject?.createdAt}</Text>
                </div>
              </div>
              <div style={{ width: 160, height: 200 }}>
                <Button
                  pad={2}
                  disabled={false}
                  inverted={false}
                  btnType={'secondary'}
                  onClick={() => shareProject()}
                  style={{ display: 'inline', marginLeft: 30, marginTop: 10 }}
                >Share to me</Button>
              </div>
            </>
            : <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: 640 }}>
              <IconError style={{ fontSize: 24 }}/>
              <Text fw="bold">No project found</Text>
              <Text fw="normal">{responseCodeGetProject}</Text>
            </div>
          }
        </Card>
      )}
    </Container>
  )
}
