import Immutable from 'seamless-immutable'
import registerServiceWorker from '../../../sw-register'
const CHANGE = 'visibility/CHANGE'

function receive (visible) {
  return {
    type: CHANGE,
    data: visible
  }
}

export function change (visible) {
  return (dispatch, getState) => {
    dispatch(receive(visible))
    const { currentUserId } = getState().users
    if (!visible && currentUserId) {
      registerServiceWorker(currentUserId)
    }
  }
}

const initialState = Immutable({ visible: true })

export default function visibilityReducer (state = initialState, action) {
  switch (action.type) {
    case CHANGE:
      return state.merge({ visible: action.data })

    default:
      return state
  }
}
