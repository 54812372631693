import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  deactivateImageTemplateCamera,
  setAutoFitEnabled,
  imageTemplateRotateStagedProducts,
  setImageTemplateDebugEnabled,
  setDesignRegistrationChange
} from '../../../../stores/ducks/threeviewer/imageTemplates'
import { getHasCurrentScopes as getHasCurrentUserScopes } from '../../../../stores/ducks/users/selectors'
import type { RootState } from '../../../../stores/ducks'

import { PanelSection, PanelSectionHeader, PanelSectionContent } from '../../../common/panels/panel-section'
import Button from '../../../common/button'
import Grid from '../../../common/grid/grid'
import Scrollable from '../../../common/scrollable'
import InputCheckbox from '../../../common/form/input-checkbox'

import SubToolbar from '../../toolbar/subtoolbar'
import ManualControls from './ManualControls'
import ImageTemplateList from './ImageTemplateList'
import UseImageTemplate from './SavedSettings'
import ConnectGeometry from './ConnectGeometry'
import { getDesignRenderActive, getIsFeatureActive } from '../../../../stores/ducks/threeviewer/selectors'
import { FEATURES } from '../../../../constants'

import styled from 'styled-components'

const DesignRenderWarning = styled.p`
  font-size: 10px;
  padding: 0 44px;
  color: red;
`

const selectActiveTemplate = (state: RootState) => state.threeviewer.camera.activeCamera.imageTemplate
const selectDebugEnabled = (state: RootState) => state.threeviewer.imageTemplates.debugImageTemplateEnabled
const selectAutoFitEnabled = (state: RootState) => state.threeviewer.imageTemplates.autoFitEnabled
const selectDisabled = (state: RootState) => Object.keys(state.tree.nodes).length === 0
const selectDesignRenderEnabled = (state: RootState) => state.threeviewer.imageTemplates.designRenderEnabled

type Props = {
  active: boolean // true if panel is visible, false if not
}

const ImageTemplatePanel = ({ active: panelActive }: Props) => {
  const dispatch = useDispatch()
  const active = useSelector(selectActiveTemplate)
  const autoFitEnabled = useSelector(selectAutoFitEnabled)
  const disabled = useSelector(selectDisabled)
  const showDebug = useSelector(getHasCurrentUserScopes('admin'))
  const debugEnabled = useSelector(selectDebugEnabled)
  const isBatchRenderFeatureActive = useSelector(state => getIsFeatureActive(state)(FEATURES.BATCH_RENDER))
  const designRenderEnabled = useSelector(selectDesignRenderEnabled)
  const designRenderActive = useSelector(state => getDesignRenderActive(state)())

  const onDeactivate = React.useCallback(() => dispatch(deactivateImageTemplateCamera()), [dispatch])
  const onDebugChange = React.useCallback((value: boolean) => dispatch(setImageTemplateDebugEnabled(value)), [dispatch])
  const onRotate90Deg = React.useCallback(() => dispatch(imageTemplateRotateStagedProducts(90)), [dispatch])
  const onAutoFitChange = React.useCallback((value: boolean) => dispatch(setAutoFitEnabled(value)), [dispatch])
  const onDesignRegistrationChange = React.useCallback((value: boolean) => dispatch(setDesignRegistrationChange(value)), [dispatch])

  React.useEffect(() => {
    if (!designRenderActive) {
      onDesignRegistrationChange(false)
    };
  }, [designRenderActive])

  return (
    <>
      <SubToolbar
        open={!!active}
        className='p1 flex justify-center pointer-disabled'
      >
        <div>
          <Button
            disabled={!active}
            pad={1}
            btnType='secondary'
            className='pointer-enabled'
            onClick={onDeactivate}
          >
            Leave image template
          </Button>
        </div>
      </SubToolbar>
      <Grid
        className='height-100 overflow-auto'
        rows={[47, '1fr', 88]}
        gridGap={0}
      >
        <PanelSection fullHeight className='pt2'>
          <PanelSectionHeader title='Image templates' includeTestId={panelActive} />
        </PanelSection>

        <Scrollable>
          <PanelSection>
            <PanelSectionContent>
              <ImageTemplateList disabled={disabled} />
            </PanelSectionContent>
          </PanelSection>
          <PanelSection>
            <PanelSectionContent>
              <div className='flex justify-between items-center'>
                <Button
                  disabled={!active}
                  pad={1}
                  btnType='secondary'
                  onClick={onDeactivate}
                >
                  Leave image template
                </Button>

                {showDebug && (
                  <InputCheckbox
                    name='debug'
                    label='Debug'
                    checked={debugEnabled}
                    onChange={onDebugChange}
                  />
                )}
              </div>
            </PanelSectionContent>
          </PanelSection>

          <PanelSection>
            <PanelSectionContent>
              <Button
                pad={1}
                disabled={!active}
                onClick={onRotate90Deg}
              >
                Rotate 90°
              </Button>
            </PanelSectionContent>
          </PanelSection>

          <PanelSection className='pt1'>
            <PanelSectionContent>
              <InputCheckbox
                name='design-registration'
                label='Render for Design Registration'
                checked={designRenderEnabled}
                disabled={!designRenderActive || !active}
                onChange={onDesignRegistrationChange}
              />
            </PanelSectionContent>
            { !designRenderActive && active &&
            <DesignRenderWarning> Currently it is only possible to render a design registration of a single Geometry </DesignRenderWarning>
            }
          </PanelSection>

          <PanelSection className='pt1'>
            <PanelSectionContent>
              <InputCheckbox
                name='autofit'
                label='Autofit'
                checked={autoFitEnabled}
                disabled={!active}
                onChange={onAutoFitChange}
              />
            </PanelSectionContent>
          </PanelSection>

          <PanelSection>
            <PanelSectionContent>
              <ManualControls />
            </PanelSectionContent>
          </PanelSection>

          <PanelSection>
            <PanelSectionHeader title='Saved template setting' color='gray-dark' />
            <PanelSectionContent>
              <UseImageTemplate />
            </PanelSectionContent>
          </PanelSection>

          {isBatchRenderFeatureActive && active && (
            <PanelSection>
              <PanelSectionHeader title='Image package' color='gray-dark' />
              <PanelSectionContent>
                <ConnectGeometry />
              </PanelSectionContent>
            </PanelSection>
          )}

        </Scrollable>
        <div className='bg-gray-light flex items-center px2 f7'>
          {active ? (
            <div>Click <span className='italic inline'>Create Image</span> to start rendering template images.</div>
          ) : (
            <>Select a image template to start.</>
          )}
        </div>
      </Grid>
    </>
  )
}

export default React.memo(ImageTemplatePanel)
