import _find from 'lodash/find'
import _filter from 'lodash/filter'
import _isEmpty from 'lodash/isEmpty'

import {
  EXTRACT_STATUSES,
  DEADLINE_STATUS
} from '../../../constants'

const isMaxFile = (fileName: string) => {
  return (/\.(max)$/).test(fileName)
}

export const hasMaxFile = (files: any[]) => {
  return _find(files, (file) => isMaxFile(file.path))
}

export const hasFailedJobs = (jobs: any[]) => {
  const failedJobs = _filter(jobs, { status: DEADLINE_STATUS.FAILED })
  return !_isEmpty(failedJobs)
}

export const hasCompletedAllJobs = (jobs: any[]) => {
  const completedJobs = _filter(jobs, { status: DEADLINE_STATUS.COMPLETED })
  // 1. submit, 2. export, 3. ar pipe, 4. post script
  return completedJobs.length >= 4
}

export const hasOkExtractStatus = (extractStatus: string) => {
  return ![EXTRACT_STATUSES.EXTRACTING, EXTRACT_STATUSES.EXTRACT_FAILED].includes(extractStatus)
}
