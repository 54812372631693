import { SceneGraph as ISceneGraph, SceneGraphMesh as ISceneGraphMesh, SceneGraphNode3d as ISceneGraphNode3d } from '../../../../../go3dthree/types/SceneGraph'

const noop = () => {}

type GroupAndUngroupCommand = {
  groupId: string
  nodesToGroup: string[]
  groupNode: any,
  group: (nodesToGroup: string[], groupNode: any) => void,
  ungroup: (groupId: string) => void,
  afterUndo?: () => void,
  afterExecute?: () => void
}

export function createUngroupCommand ({
  nodesToGroup,
  groupNode,
  group,
  ungroup,
  afterUndo = noop,
  afterExecute = noop
}: GroupAndUngroupCommand) {
  return ({
    undo () {
      ungroup(groupNode.uuid)
      afterUndo()
    },

    execute () {
      group(nodesToGroup, groupNode)
      afterExecute()
    }
  })
}

export function createGroupCommand ({
  nodesToGroup,
  groupNode,
  group,
  ungroup,
  afterUndo = noop,
  afterExecute = noop
}: GroupAndUngroupCommand) {
  return ({
    undo () {
      group(nodesToGroup, groupNode)
      afterUndo()
    },
    execute () {
      ungroup(groupNode.uuid)
      afterExecute()
    }
  })
}

type MoveNodeCommand = {
  node: ISceneGraphNode3d | ISceneGraphMesh,
  destination: ISceneGraphNode3d | ISceneGraph,
  source: ISceneGraphNode3d | ISceneGraph,
  moveNode: (node: ISceneGraphNode3d | ISceneGraphMesh, destination: ISceneGraphNode3d | ISceneGraph
    ) => void,
  afterUndo?: () => void,
  afterExecute?: () => void
}

export function createMoveNodeCommand ({
  node,
  destination,
  source,
  moveNode,
  afterUndo = noop,
  afterExecute = noop
}: MoveNodeCommand) {
  return ({
    undo () {
      moveNode(node, source)
      afterUndo()
    },
    execute () {
      moveNode(node, destination)
      afterExecute()
    }
  })
}
