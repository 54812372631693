import Immutable from 'seamless-immutable'
import { createAction, handleActions } from 'redux-actions'

import {
  add as addToTree,
  remove as removeFromTree,
  scrollToNodeUuid
} from '../tree'

import { getViewer, getNodeList } from '../threeviewer/selectors'
import { addCommand as addCommandToUndoHistory } from '../undo-redo'
import { select, hide } from '../selection'
import * as fromDefaultTemplate from '../templates/default-template'

import {
  findLowestGroup
} from '../tree/utils'

const confirmClear = createAction('clone/CLEAR')
const confirmClone = createAction('clone/CLONE')

function findHiddenNodes (nodes = []) {
  const hidden = []
  nodes.forEach(node => {
    node.traverse(child => {
      if (!child.visible) {
        hidden.push(child.uuid)
      }
    })
  })
  return hidden
}

function addSnappableModels (models, viewer) {
  for (let i = 0; i < models.length; i++) {
    const model = models[i]
    if (model.userData.isGroup) {
      addSnappableModels(model.children)
    } else {
      delete model.userData.snappingGroups
      viewer.transformGizmo.addSnappableModel(model)
    }
  }
}

function addClonedModels (clones, sources) {
  return (dispatch, getState) => {
    const state = getState()
    const viewer = getViewer(state)

    const execute = () => {
      clones.forEach(clone => {
        const source = sources.find(source => source.uuid === clone.userData.sourceUuid)
        const parent = source.parent
        viewer.addModel(clone, clone.userData.params)
        if (parent.parent === null) {
          // Parent is Scenegraph
          dispatch(addToTree(clone))
        } else {
          parent.addChild(clone)
          const lowestGroup = findLowestGroup(clone)
          dispatch(addToTree(lowestGroup))
        }
        clone.userData = { ...clone.userData }

        addSnappableModels([clone], viewer)
        dispatch(confirmClone(clones.map(c => c.uuid)))
      })

      dispatch(select(clones, false, false))
      dispatch(scrollToNodeUuid(clones[0].uuid))
      dispatch(hide(findHiddenNodes(clones)))
      dispatch(fromDefaultTemplate.adjustWallInScene())
    }

    const undo = () => {
      clones.forEach(clone => {
        dispatch(removeFromTree(clone.uuid))
        clone.parent.remove(clone)
        viewer.objectTracker.removeObject(clone)
        viewer.renderOnNextFrame()
      })
      dispatch(select(sources, false))
      dispatch(fromDefaultTemplate.adjustWallInScene())
      dispatch(clear())
    }

    execute()

    dispatch(addCommandToUndoHistory({
      undo,
      execute
    }))
  }
}

export function clone (dragging = false) {
  return (dispatch, getState) => {
    const state = getState()
    const viewer = getViewer(state)
    const nodeList = getNodeList(state)
    const selection = state.selection.selection

    const sources = []
    selection.forEach(uuid => {
      const node = nodeList[uuid]
      const source = node.userData.isGroup
        ? node
        : viewer.viewerUtils.findRootNode(node)
      const isCloneable = viewer.objectTracker.typeContainsObjects(source, 'cloneTool')

      if (isCloneable && !sources.includes(source)) {
        sources.push(source)
      }
    })

    const params = dragging ? {} : { axis: 'x', distance: 0.1 }
    const clones = viewer.cloneTool.clone(sources, params)

    dispatch(addClonedModels(clones, sources))
  }
}

export function duplicate () {
  return (dispatch, getState) => {
    const viewer = getViewer(getState())
    const [sources, clones] = viewer.cloneTool.duplicate()
    dispatch(addClonedModels(clones, sources))
  }
}

export function clear () {
  return (dispatch, getState) => {
    const viewer = getViewer(getState())
    viewer.cloneTool.clear()
    dispatch(confirmClear())
  }
}

export const dispose = clear

var initialState = Immutable({
  uuids: []
})

export default handleActions({
  [confirmClone]: (state, action) => state.setIn(['uuids'], action.payload),
  [confirmClear]: () => initialState
}, initialState)
