import React from 'react'

const IconMaterialGroup = props => (
  <svg width={props.size} height={props.size} viewBox='0 0 15 15' {...props}>
    <path
      d='M10 5h2.5A2.5 2.5 0 0 1 15 7.5v5a2.5 2.5 0 0 1-2.5 2.5h-5A2.5 2.5 0 0 1 5 12.5V10H2.5A2.5 2.5 0 0 1 0 7.5v-5A2.5 2.5 0 0 1 2.5 0h5A2.5 2.5 0 0 1 10 2.5V5zm-2.213 9.167h2.167V10H5.787v2.167a2 2 0 0 0 2 2zm4.38-8.38H10v4.167h4.167V7.787a2 2 0 0 0-2-2zm-9.38 3.38h2.167V5H.787v2.167a2 2 0 0 0 2 2zm4.38-8.38H5v4.167h4.167V2.787a2 2 0 0 0-2-2z'
      fill={props.color}
    />
  </svg>
)

IconMaterialGroup.defaultProps = {
  color: 'currentcolor',
  size: '1em'
}

export default IconMaterialGroup
