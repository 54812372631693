import { InstancedMesh } from 'three'

const THREE = require('three')
const RayCaster = require('./RayCaster').default

function isFlagSet (flags, mask) {
  return !!(flags & mask)
}

function roundDecimalVEC3 (n, _precision = 8) {
  var factor = Math.pow(10, _precision)
  n.x = (Math.round(n.x * factor) / factor)
  n.y = (Math.round(n.y * factor) / factor)
  n.z = (Math.round(n.z * factor) / factor)
  return n
}

const assetTypeEnum = Object.freeze({ mesh: 1, directional: 2, pointlight: 3, spotlight: 4 })
const lightForward = new THREE.Vector3(0, 0, 1) // NOTE: This vector is 'arbitrary' and can be changed

const tempVector3 = new THREE.Vector3()

export default class RenderScene {
  constructor (renderer, assetManager, camera, renderOnNextFrameFunction) {
    this.renderOnNextFrame = renderOnNextFrameFunction
    this.renderer = renderer
    this.assetManager = assetManager
    this.scene = new THREE.Scene()
    this.scene.autoUpdate = false
    this.scene.renderSceneAlwaysInLocalReflectionScene = true
    this.outlineObjects = []
    this.transformOutlineObjects = []
    this.meshes = []
    this.nonMeshes = []
    this.lights = []
    this.meshesFreeIds = []
    this.lightsFreeIds = []
    this.rayCaster = new RayCaster(camera)
    this.meshGroups = new Map()
    this.instanceGroups = []
    this.instanceMeshGroups = new Map()
    this.instanceMaterialUpdates = []

    // Create cube render target
    var cubeRenderTarget = new THREE.WebGLCubeRenderTarget(128, { format: THREE.RGBFormat, generateMipmaps: true, minFilter: THREE.LinearMipmapLinearFilter })

    this.lastUpdateLocaLReflectionsTime = undefined
    this.localReflectionDirty = true
    this.localReflectioCubeCamera = new THREE.CubeCamera(0.01, 25, cubeRenderTarget)
    this.localReflectioCubeCamera.visible = false
    this.localReflectioCubeCamera.renderSceneAlwaysInLocalReflectionScene = true
    this.scene.add(this.localReflectioCubeCamera)
  }

  add (geometry, material, meshInstGroup) {
    const featureFlags = RenderScene.UPDATE_VISIBLE
    const id = this.allocateAssetId(assetTypeEnum.mesh)
    this.updateMesh(id, RenderScene.UPDATE_CREATE, geometry, material._renderMaterialId, new THREE.Matrix4(), featureFlags)
    return id
  }

  addNonMesh (nonMesh) {
    this.nonMeshes.push(nonMesh)
    this.scene.add(nonMesh)
    this.renderOnNextFrame()
  }

  removeNonMeshes () {
    this.nonMeshes.forEach(o => {
      this.scene.remove(o)
    })
    this.nonMeshes = []
    this.renderOnNextFrame()
  }

  // Remove a single mesh from the Scene
  removeMesh (smesh) {
    const mesh = this.meshes[smesh._instancedMeshId]
    if (!mesh) return
    const index = smesh._instancedIndex
    let instGroup = smesh._instGroup
    if (smesh._oldInstGroup) {
      instGroup = smesh._oldInstGroup
    }
    const outlineMesh = mesh.userData?.utilityMeshes.get(index)

    if (outlineMesh) {
      if (outlineMesh.renderSceneHasOutline) {
        this.removeOutlineMesh(outlineMesh)
      }

      if (outlineMesh.renderSceneHasTransformOutline) {
        this.removeTransformOutlineMesh(outlineMesh)
      }
    }

    // Remove instancedMesh from scene if it has only one instance - otherwise remove instance and update count
    if (mesh.count < 2) {
      this.scene.remove(mesh)
      this.assetManager.unregisterMeshMaterials(mesh)
      this.rayCaster.remove(mesh)

      this.removeFromMeshList(smesh._instancedMeshId)

      delete smesh._renderId
    } else {
      const toDeleteMatrix = new THREE.Matrix4()
      const toReplaceMatrix = new THREE.Matrix4()
      mesh.getMatrixAt(index, toDeleteMatrix)
      mesh.getMatrixAt(mesh.count - 1, toReplaceMatrix)
      mesh.setMatrixAt(index, toReplaceMatrix)
      mesh.instanceMatrix.needsUpdate = true

      // Replace removed instance in instancedSceneGraphIDs and utilityMeshes
      mesh.userData.instancedSceneGraphIDs.set(index, mesh.userData.instancedSceneGraphIDs.get(mesh.count - 1))
      mesh.userData.instancedSceneGraphIDs.delete(mesh.count - 1)
      mesh.userData.utilityMeshes.set(index, mesh.userData.utilityMeshes.get(mesh.count - 1))
      mesh.userData.utilityMeshes.delete(mesh.count - 1)

      // Get scenegraphmesh that replaces the deleted instance and update the _instancedIndex
      const toReplaceUUID = mesh.userData.instancedSceneGraphIDs.get(index)
      let toReplaceMesh
      this.meshGroups.get(instGroup).some(function (m) {
        if (m.uuid === toReplaceUUID) {
          m._instancedIndex = index
          toReplaceMesh = m
          return toReplaceMesh === m
        }
      })

      // Update count
      mesh.count--
    }

    // Remove from meshGroups
    if (this.meshGroups.get(instGroup)) {
      this.removeFromMeshGroups(instGroup, smesh)
    }

    if (mesh.visible && mesh.castShadow) {
      this.renderer.shadowMap.needsUpdate = true
    }
  }

  removeFromMeshGroups (instGroup, mesh) {
    // Remove scenegraphmesh from meshGroups
    const index = this.meshGroups.get(instGroup).indexOf(mesh)
    if (index > -1) {
      this.meshGroups.get(instGroup).splice(index, 1)
    }

    if (this.meshGroups.get(instGroup).length === 0) {
      this.meshGroups.delete(instGroup)
      this.instanceMeshGroups.delete(instGroup)
      const ind = this.instanceGroups.indexOf(instGroup)
      if (ind > -1) {
        this.instanceGroups.splice(ind, 1)
      }
    }
  }

  updateMeshGroups (mesh) {
    let collectMeshes = []
    const key = mesh.geometry.uuid + '_' + mesh.material._hash
    if (!this.meshGroups.get(key)) {
      collectMeshes.push(mesh)
    } else {
      collectMeshes = this.meshGroups.get(key)
      if (!collectMeshes.includes(mesh)) {
        collectMeshes.push(mesh)
      }
    }
    this.meshGroups.set(key, collectMeshes)
    if (mesh._instGroup) {
      mesh._oldInstGroup = mesh._instGroup
    }
    mesh._instGroup = key
  }

  removeFromMeshList (meshId) {
    this.meshes[meshId].dispose()
    if (meshId === this.meshes.length - 1) {
      this.meshes.pop()
    } else {
      this.meshes[meshId] = null
      this.meshesFreeIds.push(meshId)
    }
  }

  removeLight (id) {
    const light = this.lights[id]
    this.scene.remove(light)
  }

  addOutlineMesh (mesh) {
    if (mesh.renderSceneHasOutline) return
    this.outlineObjects.push(mesh)
    mesh.renderSceneHasOutline = true
  }

  addTransformOutlineMesh (mesh) {
    if (mesh.renderSceneHasTransformOutline) return
    this.transformOutlineObjects.push(mesh)
    mesh.renderSceneHasTransformOutline = true
  }

  removeTransformOutlineMesh (mesh) {
    if (!mesh.renderSceneHasTransformOutline) return
    mesh.renderSceneHasTransformOutline = false
    const meshUUID = mesh.uuid
    const index = this.transformOutlineObjects.findIndex(function (e) {
      return e.uuid === meshUUID
    })
    if (index === -1) {
      console.warn('RenderScene.removeOutlineMesh; Mesh that was supposed to have outline was not in .outlineObjects')
      return
    }
    if (index !== this.transformOutlineObjects.length - 1) {
      // Move last entry into the hole that will be created
      this.transformOutlineObjects[index] = this.transformOutlineObjects[this.transformOutlineObjects.length - 1]
    }
    this.transformOutlineObjects.length--
  }

  removeOutlineMesh (mesh) {
    if (!mesh.renderSceneHasOutline) return
    mesh.renderSceneHasOutline = false
    const meshUUID = mesh.uuid
    const index = this.outlineObjects.findIndex(function (e) {
      return e.uuid === meshUUID
    })
    if (index === -1) {
      console.warn('RenderScene.removeOutlineMesh; Mesh that was supposed to have outline was not in .outlineObjects')
      return
    }
    if (index !== this.outlineObjects.length - 1) {
      // Move last entry into the hole that will be created
      this.outlineObjects[index] = this.outlineObjects[this.outlineObjects.length - 1]
    }
    this.outlineObjects.length--
  }

  addLinesFromBrepMesh (mesh) {
    const edgesGeometry = new THREE.EdgesGeometry(this.meshes[mesh._instancedMeshId].geometry, 179)
    const lineMaterial = new THREE.LineBasicMaterial({
      color: 0x000000
    })
    const lineSegments = new THREE.LineSegments(edgesGeometry, lineMaterial)
    lineSegments.matrixWorld = mesh._matrixWorld
    this.addNonMesh(lineSegments)
  }

  getRenderMaterial (materialId) {
    if (Array.isArray(materialId)) {
      return materialId.map(id => this.assetManager.getThreeMaterial(id))
    } else {
      return this.assetManager.getThreeMaterial(materialId)
    }
  }

  allocateAssetId (type, uuid) {
    let assetList, freeList
    let newObject = null

    if (type === assetTypeEnum.mesh) {
      assetList = this.meshes
      freeList = this.meshesFreeIds
      newObject = new InstancedMesh(new THREE.BufferGeometry(), new THREE.MeshBasicMaterial(), 1000)
    } else if (type === assetTypeEnum.directional || type === assetTypeEnum.spotlight || type === assetTypeEnum.pointlight) {
      assetList = this.lights
      freeList = this.lightsFreeIds
      if (type === assetTypeEnum.directional) {
        newObject = new THREE.DirectionalLight()
      } else if (type === assetTypeEnum.pointlight) {
        newObject = new THREE.PointLight()
      } else {
        newObject = new THREE.SpotLight()
      }
    }

    let result
    if (freeList.length !== 0) {
      result = freeList.pop()
    } else {
      result = assetList.length
      assetList.push(null)
    }

    if (newObject !== null) {
      newObject.matrixAutoUpdate = false
      newObject.renderSceneMeshID = result
      newObject.sceneGraphID = uuid // Used for going from THREE.Mesh to SceneGraphMesh
      newObject.userData.instancedSceneGraphIDs = new Map()
      newObject.userData.utilityMeshes = new Map()
    }

    assetList[result] = newObject

    return result
  }

  updateMesh (meshId, updateFlags, geometry, material, matrixWorld, featureFlags, meshInstGroup, smesh, outline) {
    let shadowMapUpdate = false

    // localProbeUpdate is set to true if there was a change that would show in local probes if the mesh was there
    // This is to exclude changes such as outline that re-renders the main view but not the local probe
    let localProbeUpdate = false
    let renderOnNextFrame = false
    let index = 0
    let mesh, updateVis, utilityMesh
    /*
      Feature flags has the same set of enum as updateFlags.
      If the visible flag in updateFlags is set then it is updated.
      The new value can be found at the same position in featureFlags
    */
    const create = isFlagSet(updateFlags, RenderScene.UPDATE_CREATE)

    if (typeof smesh._instancedMeshId !== 'undefined' && typeof smesh._instancedIndex !== 'undefined') {
      mesh = this.meshes[smesh._instancedMeshId]
      index = smesh._instancedIndex
      updateVis = true
      utilityMesh = mesh.userData.utilityMeshes.get(index)
    } else {
      mesh = this.meshes[meshId]
    }

    if (create) {
      if (this.instanceGroups.includes(meshInstGroup)) {
        const instMeshId = this.instanceMeshGroups.get(meshInstGroup)
        mesh = this.meshes[instMeshId]
        const newCount = mesh.count + 1
        mesh.count = newCount
        index = mesh.count - 1
        smesh._instancedMeshId = instMeshId
        smesh._instancedIndex = index

        this.updateInstUserData(mesh, index, smesh.uuid)

        // Remove instancedMeshes from meshes array
        if (meshId !== instMeshId) {
          this.removeFromMeshList(meshId)
        }
      } else {
        // Add new instancedMesh
        mesh.renderSceneHasRaycaster = false
        mesh.renderSceneInLocalReflectionScene = false
        mesh.count = 1
        mesh.instanceMatrix.setUsage(THREE.DynamicDrawUsage)
        this.scene.add(mesh)

        smesh._instancedMeshId = meshId
        smesh._instancedIndex = index

        this.updateInstUserData(mesh, index, smesh.uuid)

        this.instanceGroups.push(meshInstGroup)
        this.instanceMeshGroups.set(meshInstGroup, meshId)
      }
    }

    const wasInLocalReflectionScene = mesh.renderSceneInLocalReflectionScene

    if (isFlagSet(updateFlags, RenderScene.UPDATE_GEOMETRY) || create) {
      mesh.geometry = this.assetManager.geometries.get(geometry)
      mesh.userData.utilityMeshes.get(index).geometry = mesh.geometry
      if (mesh.visible) {
        renderOnNextFrame = true
        localProbeUpdate = true
        if (mesh.castShadow) {
          shadowMapUpdate = true
        }
      }
    }

    if (isFlagSet(updateFlags, RenderScene.UPDATE_MATERIAL) || create) {
      if (mesh.materialId !== smesh._material._hash) {
        if (isFlagSet(updateFlags, RenderScene.UPDATE_MATERIAL)) {
          this.instanceMaterialUpdates.push(smesh)
        } else {
          this.assetManager.unregisterMeshMaterials(mesh)
          mesh.material = this.getRenderMaterial(material)
          mesh.renderMaterial = material
          mesh.materialId = smesh._material._hash
          this.assetManager.registerMeshMaterials(material, mesh)

          if (mesh.visible) {
            localProbeUpdate = true
            renderOnNextFrame = true
          }
        }
      }
    }

    if (isFlagSet(updateFlags, RenderScene.UPDATE_TRANSFORM) || create) {
      mesh.setMatrixAt(index, matrixWorld)
      mesh.instanceMatrix.setUsage(THREE.DynamicDrawUsage)
      mesh.instanceMatrix.needsUpdate = true
      // Update the matrices of debug-gizmos etc that are parented to mesh.
      mesh.traverse(child => { child.updateMatrixWorld() })
      mesh.userData.utilityMeshes.get(index).matrixWorld = matrixWorld
      if (mesh.visible) {
        renderOnNextFrame = true
        localProbeUpdate = true
        if (mesh.castShadow) {
          shadowMapUpdate = true
        }
      }
    }

    if (isFlagSet(updateFlags, RenderScene.UPDATE_VISIBLE) || create || updateVis) {
      const visible = isFlagSet(featureFlags, RenderScene.UPDATE_VISIBLE)
      // Update visibility on instance
      let matrixToUpdate
      if (visible && smesh.visible) {
        matrixToUpdate = matrixWorld
        if (utilityMesh) utilityMesh.visible = true
      } else {
        matrixToUpdate = matrixWorld.clone().scale(new THREE.Vector3(0, 0, 0))
        if (utilityMesh) utilityMesh.visible = false
      }

      mesh.setMatrixAt(index, matrixToUpdate)
      mesh.instanceMatrix.needsUpdate = true

      renderOnNextFrame = true
      localProbeUpdate = true
      if (mesh.castShadow) {
        shadowMapUpdate = true
      }
    }

    if (isFlagSet(updateFlags, RenderScene.UPDATE_IGNORE_RAYCAST) || create) {
      const ignoreRaycast = isFlagSet(featureFlags, RenderScene.UPDATE_IGNORE_RAYCAST)
      if (mesh._ignoreRaycast !== ignoreRaycast) {
        if (ignoreRaycast) {
          this.rayCaster.remove(mesh)
        } else {
          this.rayCaster.add(mesh)
        }
        mesh._ignoreRaycast = ignoreRaycast
      }
    }

    if (isFlagSet(updateFlags, RenderScene.UPDATE_CAST_LOCAL_REFLECTION) || create) {
      const newValue = isFlagSet(featureFlags, RenderScene.UPDATE_CAST_LOCAL_REFLECTION)
      if (newValue !== mesh.renderSceneInLocalReflectionScene) {
        mesh.renderSceneInLocalReflectionScene = newValue
        localProbeUpdate = true
      }
    }

    if (isFlagSet(updateFlags, RenderScene.UPDATE_CAST_SHADOW) || create) {
      mesh.castShadow = isFlagSet(featureFlags, RenderScene.UPDATE_CAST_SHADOW)
      if (mesh.visible) {
        shadowMapUpdate = true
        renderOnNextFrame = true
        localProbeUpdate = true
      }
    }

    if (isFlagSet(updateFlags, RenderScene.UPDATE_RECEIVE_SHADOW) || create) {
      mesh.receiveShadow = isFlagSet(featureFlags, RenderScene.UPDATE_RECEIVE_SHADOW)
      if (mesh.visible) {
        renderOnNextFrame = true
        localProbeUpdate = true
      }
    }

    if (isFlagSet(updateFlags, RenderScene.UPDATE_OUTLINE) || create) {
      utilityMesh = mesh.userData.utilityMeshes.get(index)
      let wantOutline = isFlagSet(featureFlags, RenderScene.UPDATE_OUTLINE)
      const hadOutline = utilityMesh.renderSceneHasOutline
      if (outline) {
        wantOutline = true
      }

      if (wantOutline === hadOutline) {
      } else if (wantOutline) {
        this.addOutlineMesh(utilityMesh)
      } else {
        this.removeOutlineMesh(utilityMesh)
      }
      if (utilityMesh.visible) {
        renderOnNextFrame = true
      }
    }

    if (isFlagSet(updateFlags, RenderScene.UPDATE_TRANSFORM_OUTLINE) || create) {
      utilityMesh = mesh.userData.utilityMeshes.get(index)
      let wantOutline = isFlagSet(featureFlags, RenderScene.UPDATE_TRANSFORM_OUTLINE)
      const hadOutline = utilityMesh.renderSceneHasTransformOutline
      if (outline) {
        wantOutline = true
      }
      if (wantOutline !== hadOutline) {
        if (wantOutline) {
          this.addTransformOutlineMesh(utilityMesh)
        } else {
          this.removeTransformOutlineMesh(utilityMesh)
        }
      }
      if (utilityMesh.visible) {
        renderOnNextFrame = true
      }
    }

    if (renderOnNextFrame) {
      this.renderOnNextFrame()
    }
    if (shadowMapUpdate) {
      this.renderer.shadowMap.needsUpdate = true
    }
    if (renderOnNextFrame && localProbeUpdate && (wasInLocalReflectionScene || mesh.renderSceneInLocalReflectionScene)) {
      // There are so few objects in the local reflection scene so we don't track it for each update
      // If something wanted a new render and it involved an object that either was in local reflection or is in it now we mark local reflections as dirty
      this.localReflectionDirty = true
    }
  }

  updateInstUserData (mesh, index, smeshUUID) {
    mesh.userData.instancedSceneGraphIDs.set(index, smeshUUID)
    const utilityMesh = new THREE.Mesh()
    utilityMesh.matrixAutoUpdate = false
    utilityMesh.renderSceneHasOutline = false
    utilityMesh.renderSceneHasTransformOutline = false
    mesh.userData.utilityMeshes.set(index, utilityMesh)
  }

  updateInstances () {
    for (const mesh of this.instanceMaterialUpdates) {
      const instMesh = this.meshes[mesh._instancedMeshId]
      const index = mesh._instancedIndex
      let outline
      if (instMesh.userData.utilityMeshes.get(index)?.renderSceneHasOutline) outline = instMesh.userData.utilityMeshes.get(index).renderSceneHasOutline

      // Remove old instance
      this.removeMesh(mesh)
      this.updateMeshGroups(mesh)

      delete mesh._instancedIndex
      delete mesh._instancedMeshId
      delete mesh._oldInstGroup

      const featureFlags = RenderScene.UPDATE_VISIBLE
      const id = this.allocateAssetId(assetTypeEnum.mesh, mesh.uuid)
      this.updateMesh(id, RenderScene.UPDATE_CREATE, mesh.geometry.uuid, mesh.material._renderMaterialId, mesh.matrixWorld, featureFlags, mesh._instGroup, mesh, outline)
    }
  }

  calculateMeshVolume (matrixWorld, id) {
    function signedVolumeOfTriangle (p1, p2, p3) {
      const volumeOfTri = p1.dot(p2.cross(p3)) / 6.0
      return volumeOfTri
    }

    function readVec3 (buffer, offset) {
      const o = offset * 3
      return [buffer[o], buffer[o + 1], buffer[o + 2]]
    }

    const positionVec = new THREE.Vector3()
    const quat = new THREE.Quaternion()
    let scale = new THREE.Vector3()
    matrixWorld.decompose(positionVec, quat, scale)
    scale = roundDecimalVEC3(scale)

    const normalizeMatrix = new THREE.Matrix4()
    normalizeMatrix.makeScale(scale.x, scale.y, scale.z)

    const meshGeometry = this.meshes[id].geometry
    const vertPositions = meshGeometry.attributes.position.array
    const index = meshGeometry.index.array
    const len = index.length

    let center = new THREE.Vector3()
    meshGeometry.boundingBox.getCenter(center)
    center = roundDecimalVEC3(center)
    normalizeMatrix.makeTranslation(-center.x, -center.y, -center.z)

    let sum = 0
    const p1 = new THREE.Vector3()
    const p2 = new THREE.Vector3()
    const p3 = new THREE.Vector3()

    for (let i = 0; i < len; i += 3) {
      const vertPos1 = p1.fromArray(roundDecimalVEC3(readVec3(vertPositions, index[i]))).clone().applyMatrix4(normalizeMatrix)
      const vertPos2 = p2.fromArray(roundDecimalVEC3(readVec3(vertPositions, index[i + 1]))).clone().applyMatrix4(normalizeMatrix)
      const vertPos3 = p3.fromArray(roundDecimalVEC3(readVec3(vertPositions, index[i + 2]))).clone().applyMatrix4(normalizeMatrix)
      const v = signedVolumeOfTriangle(vertPos1, vertPos2, vertPos3)
      sum += v
    }
    return sum
  }

  calculateMeshArea (matrixWorld, id) {
    function readVec3 (buffer, offset) {
      const o = offset * 3
      return [buffer[o], buffer[o + 1], buffer[o + 2]]
    }

    const positionVec = new THREE.Vector3()
    const quat = new THREE.Quaternion()
    let scale = new THREE.Vector3()
    matrixWorld.decompose(positionVec, quat, scale)
    scale = roundDecimalVEC3(scale)
    const normalizeMatrix = new THREE.Matrix4()
    normalizeMatrix.makeScale(scale.x, scale.y, scale.z)

    const meshGeometry = this.meshes[id].geometry
    const vertPositions = meshGeometry.attributes.position.array
    const index = meshGeometry.index.array
    const len = index.length

    let center = new THREE.Vector3()
    meshGeometry.boundingBox.getCenter(center)
    center = roundDecimalVEC3(center)
    normalizeMatrix.makeTranslation(-center.x, -center.y, -center.z)

    let sum = 0
    const p1 = new THREE.Vector3()
    const p2 = new THREE.Vector3()
    const p3 = new THREE.Vector3()

    for (let i = 0; i < len; i += 3) {
      const vertPos1 = p1.fromArray(roundDecimalVEC3(readVec3(vertPositions, index[i]))).clone().applyMatrix4(normalizeMatrix)
      const vertPos2 = p2.fromArray(roundDecimalVEC3(readVec3(vertPositions, index[i + 1]))).clone().applyMatrix4(normalizeMatrix)
      const vertPos3 = p3.fromArray(roundDecimalVEC3(readVec3(vertPositions, index[i + 2]))).clone().applyMatrix4(normalizeMatrix)
      const triangleArea = new THREE.Triangle(vertPos1, vertPos2, vertPos3).getArea()
      sum += triangleArea
    }
    return sum
  }

  updateLight (lightId, updateFlags, matrixWorld, intensity, color, direction, distance, decay, penumbra, angle, featureFlags, shadow) {
    let shadowMapUpdate = false
    let renderOnNextFrame = false

    const create = isFlagSet(updateFlags, RenderScene.UPDATE_CREATE)

    const light = this.lights[lightId]
    if (create) {
      light.renderSceneInLocalReflectionScene = true
      this.scene.add(light)
    }

    if (isFlagSet(updateFlags, RenderScene.UPDATE_TRANSFORM) || create) {
      light.matrix = matrixWorld
      light.matrixWorld = matrixWorld
      // We encode direction in the transform, but THREE does it using a target object
      // We transform the 'light space' forward vector into world space
      // NOTE: This might seem backwards but it is all to accomodate THREE.
      const target = light.target
      target.position.copy(lightForward)
      target.position.transformDirection(matrixWorld) // NOTE: We assume outside handle non-uniform scaling issues here
      target.position.normalize()
      tempVector3.setFromMatrixPosition(light.matrixWorld)
      target.position.add(tempVector3)
      target.updateMatrix()
      target.updateMatrixWorld()
      if (light.visible && light.castShadow) {
        shadowMapUpdate = true
      }
      if (light.visible) {
        renderOnNextFrame = true
      }
    }

    if (isFlagSet(updateFlags, RenderScene.UPDATE_INTENSITY) || create) {
      light.intensity = intensity
      if (light.visible) {
        renderOnNextFrame = true
      }
    }

    if (isFlagSet(updateFlags, RenderScene.UPDATE_COLOR) || create) {
      light.color.copy(color)
      if (light.visible) {
        renderOnNextFrame = true
      }
    }

    if (isFlagSet(updateFlags, RenderScene.UPDATE_CAST_SHADOW) || create) {
      light.castShadow = isFlagSet(featureFlags, RenderScene.UPDATE_CAST_SHADOW)
      if (light.castShadow) {
        light.shadow.bias = shadow.bias || -0.0002

        // If WebGLRenderer.shadowMap.type is set to PCFSoftShadowMap,
        // radius has no effect and it is recommended to increase
        // softness by decreasing mapSize instead.
        light.shadow.radius = shadow.radius || 1

        if (light.isDirectionalLight) {
          light.shadow.mapSize.set(shadow.mapSizeX || 2048, shadow.mapSizeY || 2048)
        } else if (light.isSpotLight) {
          light.shadow.mapSize.set(shadow.mapSizeX || 512, shadow.mapSizeX || 512)
        }

        if (shadow.camera) {
          light.shadow.camera.bottom = shadow.camera.bottom || -5
          light.shadow.camera.left = shadow.camera.left || -5
          light.shadow.camera.right = shadow.camera.right || 5
          light.shadow.camera.top = shadow.camera.top || 5
          light.shadow.camera.updateProjectionMatrix()
        }

        light.shadow.needsUpdate = true
      }

      if (light.visible) {
        renderOnNextFrame = true
      }
      shadowMapUpdate = true
    }

    if (decay !== undefined && (isFlagSet(updateFlags, RenderScene.UPDATE_DECAY) || create)) {
      light.decay = decay
      if (light.visible) renderOnNextFrame = true
    }

    if (distance !== undefined && (isFlagSet(updateFlags, RenderScene.UPDATE_DISTANCE) || create)) {
      light.distance = distance
      if (light.visible) renderOnNextFrame = true
    }

    if (angle !== undefined && (isFlagSet(updateFlags, RenderScene.UPDATE_ANGLE) || create)) {
      light.angle = angle
      if (light.visible) renderOnNextFrame = true
    }

    if (penumbra !== undefined && (isFlagSet(updateFlags, RenderScene.UPDATE_PENUMBRA) || create)) {
      light.penumbra = penumbra
      if (light.visible) renderOnNextFrame = true
    }

    if (isFlagSet(updateFlags, RenderScene.UPDATE_VISIBLE) || create) {
      light.visible = isFlagSet(featureFlags, RenderScene.UPDATE_VISIBLE)
      if (light.castShadow) {
        shadowMapUpdate = true
      }
      renderOnNextFrame = true
    }

    if (renderOnNextFrame) {
      this.renderOnNextFrame()
      this.localReflectionDirty = true // We changed a visible light, update local reflection probe
    }
    if (shadowMapUpdate) {
      this.renderer.shadowMap.needsUpdate = true
    }
  }

  setLocalReflectionsCubeCameraPosition (newPosition) {
    this.localReflectionDirty = true
    this.localReflectioCubeCamera.position.copy(newPosition)
    this.localReflectioCubeCamera.updateMatrix()
    this.localReflectioCubeCamera.updateMatrixWorld()
  }

  setEnvMap (prefix, postfix) {
    // Sets skybox as well
    const scope = this

    if (this.envMapPrefix === prefix && this.envMapPostfix === postfix) return
    this.envMapPrefix = prefix
    this.envMapPostfix = postfix

    // We don't return the promise chain; we handle it ourselves so application can continue

    this.assetManager.loadEnvMap(prefix, postfix)
      .then(({ cubeMap, filtered }) => {
      // If there was another setEnvMap call that finished before us we shouldn't apply this update
        if (prefix !== scope.envMapPrefix || postfix !== scope.envMapPostfix) return

        // scope.setSkyBox(cubeMap) // TODO: We need to fix setSkyBox when re-enabling local reflections, see issue #36
        scope.assetManager.setEnvMap(filtered, null, null)
        scope.localReflectionDirty = true
      })
  }

  setSkyBox (cubeMap) {
    if (this.skybox === undefined) {
      const dimensions = new THREE.Vector3(100, 100, 100)
      const shader = THREE.ShaderLib.cube
      var material = new THREE.ShaderMaterial({
        fragmentShader: shader.fragmentShader,
        vertexShader: shader.vertexShader,
        uniforms: shader.uniforms,
        depthWrite: false,
        side: THREE.BackSide
      })

      this.skybox = new THREE.Mesh(new THREE.CubeGeometry(dimensions.x, dimensions.y, dimensions.z), material)
      this.skybox.visible = false
      this.skybox.frustumCulled = false
      this.skybox.renderSceneInLocalReflectionScene = true
      this.skybox.renderSceneAlwaysInLocalReflectionScene = true
      this.scene.add(this.skybox)
    }
    this.localReflectionDirty = true // We have to assume the content changed even if same texture object so no check
    this.skybox.material.uniforms.tCube.value = cubeMap
  }

  // NOTE: this is currently not called due to update to R116 breaking it, see #36
  updateLocalReflections () {
    if (!this.localReflectionDirty) return
    if (this.assetManager.envMap === null) return

    // Make sure we don't update too often (only every 2 seconds)
    const lastTime = this.lastUpdateLocaLReflectionsTime
    const currentTime = window.performance.now()
    if (lastTime !== undefined && currentTime < lastTime + 2000) {
      return
    }
    this.lastUpdateLocaLReflectionsTime = currentTime

    // NOTE: Currently we assume that materials that are rendererd in the reflections scene has the global env map assigned
    //       This is important since we don't want to both read from and render to the local probe

    // Re-render the reflection cubemap
    this.localReflectionDirty = false

    this.scene.traverse((node) => {
      node.visibleRestore = node.visible
      const a = node.renderSceneAlwaysInLocalReflectionScene
      if (a !== undefined && a) {
        node.visible = true
        return
      }
      if (node.renderSceneInLocalReflectionScene === undefined) {
        node.visible = false
        return
      }
      node.visible = node.visible && node.renderSceneInLocalReflectionScene
    })

    const renderer = this.renderer

    // TODO: There are many steps to this function.
    //       We could divide the work up over many frames and do a little each frame
    //       We could restart that work if something new changes...
    const oldShadowUpdate = this.renderer.shadowMap.needsUpdate
    this.renderer.shadowMap.needsUpdate = false
    this.localReflectioCubeCamera.update(renderer, this.scene)
    this.renderer.shadowMap.needsUpdate = oldShadowUpdate

    const cubeMap = this.localReflectioCubeCamera.renderTarget.texture

    // Time to filter it so we can use it
    cubeMap.encoding = THREE.GammaEncoding

    var pmremGenerator = new THREE.PMREMGenerator(cubeMap)
    pmremGenerator.update(renderer)

    var pmremCubeUVPacker = new THREE.PMREMCubeUVPacker(pmremGenerator.cubeLods)
    pmremCubeUVPacker.update(renderer)

    var cubeRenderTarget = pmremCubeUVPacker.CubeUVRenderTarget
    cubeRenderTarget.texture.name = 'CubeCamera'

    pmremCubeUVPacker.CubeUVRenderTarget = null
    pmremGenerator.dispose()
    pmremCubeUVPacker.dispose()

    // Update all materials that uses this envMap
    this.assetManager.setEnvMap(this.assetManager.envMapFiltered, cubeRenderTarget.texture, this.localReflectioCubeCamera.position)

    // Show objects that were hidden
    this.scene.traverse((node) => {
      node.visible = node.visibleRestore
    })

    this.renderOnNextFrame()
  }

  printStats () {
    const instances = new Map()
    const baseMaterials = new Set()
    const materialVariations = new Set()
    const geometries = new Set()
    let numMeshes = 0
    // We only traverse root meshes since they can have children used for debugging
    this.scene.children.forEach(mesh => {
      if (!(mesh instanceof THREE.Mesh)) return
      const geometry = mesh.debugGeometry === undefined ? mesh.geometry : mesh.debugGeometry
      const material = mesh.debugMaterial === undefined ? mesh.material : mesh.debugMaterial

      materialVariations.add(material.uuid)
      geometries.add(geometry.uuid)
      baseMaterials.add(material.name)
      numMeshes++

      // We do 'perfect' sharing of materials so if same they have the same uuid
      const key = geometry.uuid + '_' + material.uuid
      if (!instances.has(key)) {
        const numVertices = geometry.attributes.position.count
        let numTriangles = Math.floor(numVertices / 3)
        if (geometry.index !== null) {
          numTriangles = Math.floor(geometry.index.count / 3)
        }
        instances.set(key, { count: 0, triangles: numTriangles, vertices: numVertices, geometryId: geometry.name, materialId: material.name, materialVariation: material.uuid })
      }
      const value = instances.get(key)
      value.count++
    })

    const sorted = [...instances.entries()].sort((aId, bId) => {
      const a = aId[1]
      const b = bId[1]
      const costA = a.triangles * a.count
      const costB = b.triangles * b.count
      if (costA !== costB) return costB - costA
      if (a.vertices !== b.vertices) return b.vertices - a.vertices
      return false
    })

    console.log('Scene statistics:')
    console.log('* Num meshes', numMeshes)
    console.log('* Num unique meshes', instances.size)
    console.log('* Meshes per instance', numMeshes / instances.size)
    console.log('* Num base materials', baseMaterials.size)
    console.log('* Num material variations', materialVariations.size)
    console.log('* Num geometries', geometries.size)
    console.log('CSV:')
    console.log('instanceCount;numTriangles;numVertices;baseMaterial;materialVariation;geometry')
    sorted.forEach(a => {
      const e = a[1]
      console.log(`${e.count};${e.triangles};${e.vertices};${e.materialId};${e.materialVariation};${e.geometryId}`)
    })
  }

  // Update flags
  static get UPDATE_GEOMETRY () { return 1 << 0 }
  static get UPDATE_MATERIAL () { return 1 << 1 }
  static get UPDATE_TRANSFORM () { return 1 << 2 }
  static get UPDATE_VISIBLE () { return 1 << 3 }
  static get UPDATE_IGNORE_RAYCAST () { return 1 << 4 }
  static get UPDATE_CAST_SHADOW () { return 1 << 5 }
  static get UPDATE_RECEIVE_SHADOW () { return 1 << 6 }
  static get UPDATE_OUTLINE () { return 1 << 7 }
  static get UPDATE_CREATE () { return 1 << 8 }
  static get UPDATE_INTENSITY () { return 1 << 9 }
  static get UPDATE_COLOR () { return 1 << 10 }
  static get UPDATE_DISTANCE () { return 1 << 11 }
  static get UPDATE_DECAY () { return 1 << 12 }
  static get UPDATE_ANGLE () { return 1 << 13 }
  static get UPDATE_PENUMBRA () { return 1 << 14 }
  static get UPDATE_CAST_LOCAL_REFLECTION () { return 1 << 15 }
  static get UPDATE_TRANSFORM_OUTLINE () { return 1 << 16 }

  static get AssetTypeEnum () { return assetTypeEnum }
  static get lightForward () { return lightForward }
}
