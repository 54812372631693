import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { createSelector } from 'reselect'
import { RootState } from '../../../../stores/ducks'
import cs from 'classnames'
import _get from 'lodash/get'
import { ModalButton } from '../../../common/modal'
import IconPlus from '../../../common/icons/icon-plus'
import IconTrash from '../../../common/icons/icon-trash'
import IconReplace from '../../../common/icons/icon-replace'
import Button from '../../../common/button'
import InputText from '../../../common/form/input-text'
import InputGroup from '../../../common/form/input-group'
import Label from '../../../common/form/label'
import KeyboardListener, { KeyCode, KeyBinding } from '../../../common/keyboard-listener'
import * as fromThreeviewerSelectors from '../../../../stores/ducks/threeviewer/selectors'
import * as fromProjectsSelectors from '../../../../stores/ducks/projects/selectors'
import * as fromRoomsetSelectors from '../../../../stores/ducks/roomsets/selectors'
import * as fromThreeviewerUI from '../../../../stores/ducks/threeviewer/ui'
import * as fromProjects from '../../../../stores/ducks/projects'
import { getCurrentEntry as getCurrentCombination } from '../../../../stores/ducks/combinations/selectors'
import { setCameraSettings, createNewCameraSetting } from '../../../../stores/ducks/threeviewer/camera'
import { StorageApiThumbnail } from '../../../common/storage-api-image'
import Grid from '../../../common/grid/grid'

const mapStateToProps = createSelector(
  fromProjectsSelectors.getCameraList,
  fromThreeviewerSelectors.getActiveCameraId,
  fromRoomsetSelectors.getCurrentId,
  (state: RootState) => _get(state, 'threeviewer.camera.mode'),
  getCurrentCombination,
  (state: RootState) => {
    return state.scenes.currentId
      ? state.scenes.getIn(['entries', state.scenes.currentId])
      : null
  },
  (
    cameraList,
    activeCameraId,
    activeRoomsetId,
    cameraMode,
    currentCombination,
    currentScene
  ) => {
    const _cameraList: { [id: string]: any } = {}
    Object.entries(cameraList).forEach(([id, camera]: [string, any]) => {
      if (activeRoomsetId && camera.roomsetId !== activeRoomsetId) return
      if (!activeRoomsetId && camera.roomsetId) return
      if (camera.removedAt) return
      _cameraList[id] = { ...camera, id }
    })

    let combinationTitle = currentCombination.title
    if (currentScene && currentScene.combinationId === currentCombination.id) {
      combinationTitle = currentScene.title
    }

    return {
      renderCameraSettings: _get(currentCombination, 'render.cameraSettings'),
      renderedManifest: _get(currentCombination, 'render.manifest'),
      activeCameraId,
      combinationTitle,
      cameraMode,
      activeRoomsetId,
      cameraList: _cameraList
    }
  }
)

const mapDispatchToProps = (dispatch: any) => {
  return {
    setCameraSettings: (params: any) => dispatch(setCameraSettings(params)),
    createNewCameraSetting: (id: string | undefined, title: string, roomsetId: string | null) => {
      dispatch(createNewCameraSetting(id, title, roomsetId))
    },
    removeCameraSetting: (id: string) => dispatch(fromProjects.removeCameraSetting(id)),
    setKeyBoardBindings: (value: boolean) => dispatch(fromThreeviewerUI.toggleKeyBoardBindings(value))
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>
type Props = PropsFromRedux & {
  'data-testid'?: string,
  disabled: boolean
}

function SavedCameraList (props: Props) {
  function handleCreate (payload: { id?: string; title?: string }) {
    const { activeRoomsetId } = props
    const title = payload.title || _get(props, ['cameraList', payload.id || '', 'title'])
    props.createNewCameraSetting(payload.id, title, activeRoomsetId)
  }

  function handleSelect (id: string) {
    const cameraSettings = id === 'rendered' ? props.renderCameraSettings : props.cameraList[id]
    props.setCameraSettings({
      settings: cameraSettings,
      cameraId: cameraSettings.id || id,
      isPredefined: !!cameraSettings.isPredefined
    })
  }

  return (
    <div data-testid={props['data-testid']}>
      {props.renderCameraSettings && (
        <Grid
          data-testid={props['data-testid'] ? `${props['data-testid']}-item` : undefined}
          columns={[48, 'auto']}
          className={cs('bg-gray-light-hover mb1', {
            pointer: !props.disabled,
            'pointer-disabled muted': props.disabled
          })}
          onClick={() => handleSelect('rendered')}
        >
          <StorageApiThumbnail
            className='border bc-gray-light bg-white'
            manifest={props.renderedManifest}
          />
          <div className='flex items-center'>
            {props.combinationTitle || 'Image'}
          </div>
        </Grid>
      )}
      <ul className='p0 my0 mxn1 f6'>
        {Object.keys(props.cameraList).length > 0 && Object.values(props.cameraList).map((camera: any) => (
          <CameraSetting
            key={camera.id}
            disabled={props.disabled}
            title={camera.title}
            locked={camera.locked}
            isActive={camera.id === props.activeCameraId}
            onSelect={() => handleSelect(camera.id)}
            onRemove={() => props.removeCameraSetting(camera.id)}
            onUpdate={() => handleCreate({ id: camera.id })}
          />
        ))}
      </ul>
      <ModalButton
        className='mt2'
        small
        modalContent={(closeModal: () => void) => (
          <SaveCameraModal
            onClose={() => {
              props.setKeyBoardBindings(true)
              closeModal()
            }}
            onSaveCamera={(title: string) => handleCreate({ title })} />
        )}
        button={(openModal: () => void) => (
          <Button
            data-testid='camera-panel-save-new-perspective'
            pad={1}
            onClick={() => {
              props.setKeyBoardBindings(false)
              openModal()
            }}
            disabled={!!props.cameraMode}
            icon={<IconPlus width='1.2em' height='1.2em' />}
          >
            Save current
          </Button>
        )}
        onModalClose={() => props.setKeyBoardBindings(true)} />
    </div>
  )
}

type CameraSettingProps = {
  title: string
  disabled: boolean
  onSelect: () => void
  onUpdate?: () => void
  locked?: boolean
  onRemove?: () => void
  isActive: boolean
}

function CameraSetting (props: CameraSettingProps) {
  return (
    <li
      className={cs('flex items-center p1', {
        pointer: !props.disabled,
        'pointer-disabled muted': props.disabled,
        'c-gray-dark bg-gray-light-hover c-gray-dark-hover': !props.isActive,
        'bg-secondary bg-secondary-light-hover c-gray-lighter c-gray-lighter-hover': props.isActive
      })}
    >
      <div className='width-100 flex items-center'>
        <span className='truncate f6 flex-auto pr2' onClick={props.onSelect}>
          {props.title}
        </span>

        <div
          title='update camera with current camera view'
          onClick={props.onUpdate}
          className={cs('pointer', {
            hidden: props.locked,
            'c-gray-dark c-secondary-hover': !props.isActive,
            'c-gray-lighter c-gray-light-hover': props.isActive
          })}
        >
          <IconReplace className='ml1' />
        </div>

        <div
          title='delete camera'
          onClick={props.onRemove}
          className={cs('pointer', {
            hidden: props.locked,
            'c-gray-dark c-secondary-hover': !props.isActive,
            'c-gray-lighter c-gray-light-hover': props.isActive
          })}
        >
          <IconTrash className='ml1' />
        </div>
      </div>
    </li>
  )
}

type SaveCameraModalProps = {
  onClose: () => void
  onSaveCamera: (title: string) => void
}

function SaveCameraModal (props: SaveCameraModalProps) {
  const title = React.useRef<InputText>(null)

  React.useEffect(() => {
    if (title && title.current && title.current.root) {
      title.current.root.focus()
    }
  }, [])

  function saveCamera (title: string) {
    // TODO: Should be possible to submit when hitting enter if there is a title value.
    // But the onClose action is not fired correctly atm.
    props.onClose()
    props.onSaveCamera(title)
  }

  function handleSubmit (event: any) {
    event.preventDefault()
    if (title && title.current && title.current.root) {
      const value = (title.current.root.value || '').trim()
      if (value) saveCamera(value)
    }
  }

  return (
    <div>
      <h2 className='mt0'>
        Save camera
      </h2>

      <div>
        <InputGroup>
          <Label>Title</Label>
          <InputText
            name='title'
            ref={title}
            placeholder='Title'
          />
        </InputGroup>

        <div className='mt2 clearfix flex justify-end items-baseline'>
          <Button className='mr2' onClick={props.onClose}>Cancel</Button>
          <Button btnType='primary' onClick={handleSubmit}>
            Save
          </Button>
        </div>
      </div>
      <KeyboardListener bindings={[KeyBinding(KeyCode.enter, handleSubmit)]} />
    </div>
  )
}

export default connector(SavedCameraList)
