var ViveController = require('three-vive-controller')
var THREE = require('three')

var addLocomotionHandler = require('./handlers/addLocomotionHandler')
var addMaterialEditorHandler = require('./handlers/addMaterialEditorHandler')
var addPolaroidHandler = require('./handlers/addPolaroidHandler')
var addSpecialSnappingHandler = require('./handlers/addSpecialSnappingHandler')

function HTCViveController (num, color, controllerRoot, app) {
  var VC = ViveController(THREE, controllerRoot)

  VC.prototype = Object.assign(VC.prototype,
    controllerPrototype,
    addLocomotionHandler,
    addMaterialEditorHandler,
    addPolaroidHandler,
    addSpecialSnappingHandler
  )

  var controller = new VC(num, app.vive.VRControls)
  controller._savedUpdate = controller.update
  controller.update = function () {
    this._savedUpdate()

    Object.keys(this._update).forEach((key) => {
      this._update[key]()
    })
  }
  controller._update = {}
  controller.app = app
  controller.standingMatrix = app.vive.VRControls.getStandingMatrix()

  var geometry = new THREE.Geometry()
  geometry.vertices.push(
    new THREE.Vector3(0, 0, 0),
    new THREE.Vector3(0, 0, -5)
  )
  var line = new THREE.Line(
    geometry,
    new THREE.LineBasicMaterial({
      color: color
    }))
  controller.add(line)
  controller.visible = false
  line.position.set(0, 0, 0)

  this.controller = controller
}

var controllerPrototype = {}

controllerPrototype.getRaycaster = function () {
  var raycaster = new THREE.Raycaster()
  var origin = this.getWorldPosition()
  var direction = this.getWorldDirection()
  raycaster.set(origin, direction.negate())

  return raycaster
}

controllerPrototype.detectDrag = function (handlers) {
  var sumVec = new THREE.Vector2()

  this.on(this.PadDragged, (dx, dy) => sumVec.add(new THREE.Vector2(dx, dy)))
  this.on(this.PadTouched, () => (sumVec = new THREE.Vector2()))

  this.on(this.PadUntouched, () => {
    if (sumVec.length() < 1) {
      sumVec = new THREE.Vector2()
      return
    }

    if (Math.abs(sumVec.x) > Math.abs(sumVec.y)) {
      handlers.dragHorizontal(sumVec.x > 0 ? 1 : -1)
    }
    sumVec = new THREE.Vector2()
  })
}

controllerPrototype.antiEvents = {
  PadTouched: 'PadUntouched',
  PadPressed: 'PadUnpressed',
  TriggerClicked: 'TriggerUnclicked',
  MenuClicked: 'MenuUnclicked',
  Gripped: 'Ungripped',
  MenuPressed: 'MenuUnpressed',
  Connected: 'Disconnected'
}

module.exports = HTCViveController
