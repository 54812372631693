import _set from 'lodash/set'
import { SearchParams } from 'elasticsearch'

const DEFAULT_PAGE_SIZE = 40

export type Filters = {
  activeTab: string
  selectionMode: string | boolean | undefined
  imagePackageMaterialIds?: string[]
  onlyStandardAppearances: boolean
  materialCategories: string[]
  materialClass: string[]
  isUncategorizedSelected: boolean
  projectType: string
}

export const buildDefaultQuery = (
  filters: Filters,
  options = {
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  },
  isAdmin: boolean,
  markedAppearances: string[]
) => {
  const { page, pageSize } = options
  const query: SearchParams = {}
  let sort: any = []

  if (markedAppearances.length > 0) {
    sort = [
      '_score',
      { defaultSortIndex: { order: 'asc' } },
      { 'displayName.keyword': { order: 'asc' } },
    ]
    _set(query, ['sort'], sort)
    const matchId = [
      ...markedAppearances.map((id) => (
        _set({}, ['match', 'id'], {
          query: id,
          boost: 1,
        })
      ))
    ]
    _set(query, ['query', 'bool', 'should'], matchId)
  } else {
    sort = [
      { defaultSortIndex: { order: 'asc' } },
      { 'displayName.keyword': { order: 'asc' } },
    ]
    _set(query, ['sort'], sort)
  }

  _set(query, ['size'], options.pageSize)

  const materialClassFilter = buildMaterialClass(filters, isAdmin)
  const categorieFilters = buildCategories(filters)
  const ignoreTemplateMaterials = buildIgnoreTemplateMaterials()
  queryWrapper(materialClassFilter, categorieFilters, ignoreTemplateMaterials, query, filters)

  // pagination
  const size = page * pageSize

  // Note: for future optimization, set from = page and size = pageSize. This requires redux support however...
  _set(query, ['from'], 0)
  _set(query, ['size'], size)

  return query
}

export const buildQuery = (
  queryString: string,
  filters: Filters,
  options = {
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  },
  isAdmin: boolean,
  markedAppearances: string[],
) => {
  const { page, pageSize } = options

  const should = [
    // fuzziness per word match, allow some misspellings
    _set({}, ['match', 'name'], {
      query: queryString,
      operator: 'and',
      fuzziness: 1,
    }),

    // prefix match per word
    _set({}, ['match', 'name.ngrams'], {
      query: queryString,
    }),

    // exact word match with up to 3 missing/jumbled words
    _set({}, ['match_phrase', 'name'], {
      query: queryString,
      slop: 3,
      boost: 3,
    }),

    // prefix match per word
    _set({}, ['match', 'description.ngrams'], {
      query: queryString,
    }),

    // exact word match with up to 3 missing/jumbled words
    _set({}, ['match_phrase', 'description'], {
      query: queryString,
      slop: 3,
      boost: 3,
    }),

    // id within querystring search
    _set({}, ['match', 'id.search'], {
      query: queryString,
      fuzziness: 1,
    }),

    // exact id match, most important
    _set({}, ['match', 'id'], {
      query: queryString,
      boost: 1,
    }),

    // exact id match with material id displaying in MOM.
    _set({}, ['match', 'id'], {
      query: queryString.replace(/^0+/, ''),
      boost: 5,
    }),

    // exact colorSpecificationCode match, most important
    _set({}, ['match', 'colorSpecificationCode'], {
      query: queryString,
      boost: 6,
    }),
    // colorSpecificationCode within querystring search
    _set({}, ['match', 'colorSpecificationCode.search'], {
      query: queryString,
      fuzziness: 1,
      boost: 6
    }),
    ...markedAppearances.map((id) => (
      _set({}, ['match', 'id'], {
        query: id,
      })
    ))
  ]

  const queryInt = queryString.replace(/\D/g, '')
  if (queryInt) {
    should.push(
      _set({}, ['match', 'material_id'], {
        query: queryInt,
        boost: 11
      }),
      _set({}, ['match', 'colorSpecificationCode'], {
        query: queryInt,
        boost: 12,
        fuzziness: 1
      }),
    )
  }

  const query: SearchParams = queryString
    ? _set({}, ['query', 'bool', 'should'], should)
    : {}

  const materialClassFilter = buildMaterialClass(filters, isAdmin)
  const categorieFilters = buildCategories(filters)
  const ignoreTemplateMaterials = buildIgnoreTemplateMaterials()

  queryWrapper(materialClassFilter, categorieFilters, ignoreTemplateMaterials, query, filters)

  // pagination
  const size = page * pageSize

  // Note: for future optimization, set from = page and size = pageSize. This requires redux support however...
  _set(query, ['from'], 0)

  _set(query, ['size'], size)

  _set(query, ['min_score'], 0.1)

  return query
}

function queryWrapper (
  materialClassFilter: any,
  categorieFilters: any,
  ignoreTemplateMaterials: any,
  query: any,
  filters: any
) {
  const { selectionMode, imagePackageMaterialIds, projectType } = filters
  let terms: any = []

  if (imagePackageMaterialIds && imagePackageMaterialIds.length && projectType === 'image-package') {
    terms = [
      { ids: { values: imagePackageMaterialIds } },
      { term: { isHidden: false } },
    ]
    _set(query, ['query', 'bool', 'filter'], terms)
    return
  } else if (selectionMode === 'template') {
    terms = [
      { term: { templateMaterial: true } },
      { term: { isHidden: false } },
    ]
    _set(query, ['query', 'bool', 'filter'], terms)
    return
  }

  const mustArray = [
    { bool: { should: categorieFilters } },
    { bool: { should: materialClassFilter } },
    { bool: { must_not: ignoreTemplateMaterials } }
  ]

  _set(query, ['query', 'bool', 'filter'], mustArray)
}

const buildMaterialClass = (filters: Filters, isAdmin: boolean) => {
  const { materialClass } = filters
  let terms: any = []
  const isMaterialClassNotSelected = filters.materialClass.length === 0

  if (materialClass.includes('DPD') || isMaterialClassNotSelected) {
    const adminTerm: any = {
      bool: {
        must: [
          { terms: { source: ['file'] } },
          { term: { isHidden: false } },
          { term: { templateMaterial: false } }
        ]
      }
    }
    if (!isAdmin) {
      adminTerm.bool.must = [...adminTerm.bool.must, { term: { isPublished: true } }]
    }

    terms = [...terms,
      {
        bool: {
          must: [
            { terms: { source: ['dpd'] } },
            { term: { isHidden: false } },
            { term: { templateMaterial: false } }
          ]
        }
      }, adminTerm
    ]
  }

  if (materialClass.includes('Standard') || isMaterialClassNotSelected) {
    terms = [
      ...terms,
      {
        bool: {
          must: [
            { term: { 'tag_items.appearance_class': 'IKEA Standard' } },
            { term: { active: true } },
            { term: { status: 'Done' } }
          ],
        },
      },
    ]
  }

  if (materialClass.includes('Specialized') || isMaterialClassNotSelected) {
    terms = [
      ...terms,
      {
        bool: {
          must: [
            { term: { 'tag_items.appearance_class': 'Specialized' } },
            { term: { status: 'Done' } }
          ],
        },
      },
    ]
  }
  return terms
}

const buildCategories = (filters: Filters) => {
  const { materialCategories, isUncategorizedSelected } = filters
  let terms: any = []

  if (materialCategories.length >= 1) {
    terms = [...terms, { terms: { categories: materialCategories } }]
  }

  if (isUncategorizedSelected) {
    terms = [
      ...terms,
      {
        script: {
          script: {
            source: "doc['categories'].size() < 1",
          },
        },
      },
    ]
  }

  return terms
}

/**
 * Filters out templateMaterial when uncategorized is selected
 */
const buildIgnoreTemplateMaterials = () => {
  let terms: any = []
  terms = [{ term: { templateMaterial: true } }]
  return terms
}

export const categoryQuery = (isAdmin: boolean) => {
  const query: SearchParams = {}
  const getAllCategorieFilters = {
    field: 'categories',
    size: 50
  }

  const dpdCatFilter = { isHidden: false }
  const dpdCatIsPublished = { isPublished: true }
  const ikeaCatFilter = { source: 'materialbank' }

  _set(query, ['aggs', 'agg01', 'aggs', 'categories', 'terms'], getAllCategorieFilters)
  if (isAdmin) _set(query, ['aggs', 'agg01', 'filter', 'term'], dpdCatFilter)
  if (!isAdmin) _set(query, ['aggs', 'agg01', 'filter', 'bool', 'must'], [{ term: dpdCatFilter }, { term: dpdCatIsPublished }])

  _set(query, ['aggs', 'agg02', 'aggs', 'categories', 'terms'], getAllCategorieFilters)
  _set(query, ['aggs', 'agg02', 'filter', 'term'], ikeaCatFilter)
  return query
}
