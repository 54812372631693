import React from 'react'
import cs from 'classnames'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode
}
export function AppearanceGroupRow (props: Props) {
  const { children, className, ...baseProps } = props
  return (
    <div
      {...baseProps}
      className={cs(`
        flex
        items-center
        border-top
        bc-black-10
        opacity-hover-lighter 
        pointer
        p1
        ease-in-out
      `, className)}
    >
      {children}
    </div>
  )
}
