import React from 'react'
import { connect, ConnectedProps } from 'react-redux'

import * as fromVisualizerSelectors from '../visualizer-selectors'
import * as fromThreeviewerSelectors from '../../../stores/ducks/threeviewer/selectors'
import * as fromThreeviewerFilesSelectors from '../../../stores/ducks/threeviewer/files/selectors'
import { setPickerSelection, leaveIsolate } from '../../../stores/ducks/selection'

import { ModalButton } from '../../common/modal'
import Button from '../../common/button'
import ToolbarButton from './toolbar-button'
import Separator from '../../layout/header-separator'
import DesignLoader from '../design-loader'
import PatternSettings from './subtoolbar-pattern'
import TriplanarSettings from './subtoolbar-triplanar'

import MoveSettings from './subtoolbar-move'
import TranslateSettings from './subtoolbar-translate'
import RotateSettings from './subtoolbar-rotate'

import DisablePredefinedCamera from './subtoolbar-fixedcamera'
import SubToolbar from './subtoolbar'

import IconPosition from '../../common/icons/icon-position-new'
import IconMove from '../../common/icons/icon-move'
import IconRotate from '../../common/icons/icon-rotate'
import DuplicateButton from './duplicate-button'
import IconReplace from '../../common/icons/icon-replace'
import IconAlign from '../../common/icons/icon-align'
import IconRotateMaterial from '../../common/icons/icon-rotate-material'
import IconPattern from '../../common/icons/icon-pattern'
import IconGroup from '../../common/icons/icon-group'
import IconSettings from '../../common/icons/icon-settings'

import { CAMERA_MODES, activateVariantMode, deactivateVariantMode, disablePredefinedCamera, CameraMode } from '../../../stores/ducks/threeviewer/camera'
import CreateVariantModal from './create-variant-modal'

import KeyboardListener, {
  KeyCode,
  KeyBinding
} from '../../common/keyboard-listener'
import { RootState } from '../../../stores/ducks'
import type { Go3DViewer } from '../../../../../go3dthree/types/Go3DViewer'
import * as fromThreeviewerUi from '../../../stores/ducks/threeviewer/ui'
import * as fromThreeviewerViewer from '../../../stores/ducks/threeviewer/viewer'

const TOOL = {
  gizmo: 'gizmo',
  align: 'align',
  snap: 'snap',
  triplanar: 'triplanar',
  isolation: 'isolation',
  pattern: 'pattern',
  split: 'split'
}

const SUB_TOOLBAR = {
  pattern: 'pattern',
  isolation: 'isolation',
  triplanar: 'triplanar',
  split: 'split'
}

const SETTINGS = {
  snapping: 'settings-snapping',
  translate: 'settings-translate',
  rotate: 'settings-rotate'
}

const mapStateToProps = (state: RootState) => {
  const viewer = fromThreeviewerSelectors.getViewer(state)
  const cameraMode = fromThreeviewerSelectors.getCameraMode(state)
  const isIsolationActive = state.selection.isIsolationActive

  const hasValidSelection = fromVisualizerSelectors.getHasValidSelection(state)

  return {
    viewer,
    hasValidSelection: hasValidSelection,
    hasValidTriplanarSelection: fromVisualizerSelectors.getHasValidTriplanarSelection(state),
    hasValidPatternSelection: fromVisualizerSelectors.getHasPattern(state),
    hasValidAlignSelection: fromVisualizerSelectors.getHasValidAlignSelection(state),
    keyBoardBindingsEnabled: fromThreeviewerSelectors.getKeyBoardBindingsEnabled(state),
    isSceneLoaded: fromThreeviewerFilesSelectors.getIsSceneLoaded(state),
    cameraMode: cameraMode,
    gizmoMode: viewer.transformGizmo ? viewer.transformGizmo.getMode() : 'snapping',
    toolbarButtonsDisabled: cameraMode === CAMERA_MODES.OFFSET_VIEW_CAMERA,
    isIsolationActive: isIsolationActive
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    disablePredefinedCamera: () => dispatch(disablePredefinedCamera()),
    activateVariantMode: () => dispatch(activateVariantMode()),
    deactivateVariantMode: () => dispatch(deactivateVariantMode()),
    deactivateTriplanar: (viewer: Go3DViewer) => {
      viewer.triplanarTool.disable()
      viewer.transformGizmo.enable()
      dispatch(setPickerSelection())
    },
    disableKeyboardListeners: () => {
      dispatch(fromThreeviewerUi.setKeyBoardBindings(false))
      dispatch(fromThreeviewerViewer.disableKeyboardListeners())
    },
    enableKeyboardListeners: () => {
      dispatch(fromThreeviewerUi.setKeyBoardBindings(true))
      dispatch(fromThreeviewerViewer.enableKeyboardListeners())
    },
    leaveIsolate: () => dispatch(leaveIsolate())
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>
type Props = PropsFromRedux & {}

function Toolbar (props: Props) {
  const initialActive = {
    cameraMode: null,
    tool: TOOL.gizmo,
    subToolbar: null,
    settings: null
  }

  const isSettingsActive = (settings: string) => {
    return active.settings === settings && gizmoMode !== null
  }

  const isSubToolbarActive = (subToolbar: string) => {
    return active.subToolbar === subToolbar
  }

  const [active, setActive] = React.useState<{
    cameraMode: CameraMode | null
    tool: string
    subToolbar: null | string,
    settings: null | string
  }>(initialActive)
  const [gizmoMode, setGizmoMode] = React.useState(props.gizmoMode)

  function _setActive ({ tool, subToolbar, cameraMode, settings }: { tool: string, subToolbar: string | null, cameraMode: CameraMode | null, settings: string | null }) {
    const shouldDeactivate = (check: string) => active.tool === check && tool !== check
    const shouldActivate = (check: string) => active.tool !== check && tool === check

    if (shouldActivate(TOOL.align)) activateAlign(props.viewer)
    if (shouldDeactivate(TOOL.align)) deactivateAlign(props.viewer)
    if (shouldActivate(TOOL.snap)) activateSnap(props.viewer)
    if (shouldDeactivate(TOOL.snap)) deactivateSnap(props.viewer)
    if (shouldActivate(TOOL.triplanar)) activateTriplanar(props.viewer)
    if (shouldDeactivate(TOOL.triplanar)) props.deactivateTriplanar(props.viewer)
    if (shouldActivate(TOOL.pattern)) activatePattern(props.viewer)
    if (shouldDeactivate(TOOL.pattern)) deactivatePattern(props.viewer)

    if (active.cameraMode === CAMERA_MODES.PREDEFINED && cameraMode !== CAMERA_MODES.PREDEFINED) {
      props.disablePredefinedCamera()
    }

    if (active.cameraMode === CAMERA_MODES.VARIANT && cameraMode !== CAMERA_MODES.VARIANT) {
      props.deactivateVariantMode()
    }

    if (active.cameraMode !== CAMERA_MODES.VARIANT && cameraMode === CAMERA_MODES.VARIANT) {
      props.activateVariantMode()
    }

    setActive({ tool, subToolbar, cameraMode, settings })
  }

  function LeaveIsolateButton () {
    return (
      <div className="inline-flex p1">
        <Button btnType="secondary" pad={1} onClick={props.leaveIsolate}>
          Leave isolate
        </Button>
      </div>
    )
  }

  const resetActive = React.useCallback(() => {
    _setActive(initialActive)
  }, [active.tool, active.subToolbar, active.cameraMode])

  React.useEffect(() => {
    setGizmoMode(props.gizmoMode)
  }, [props.gizmoMode])

  const onPickerDeselect = React.useCallback(() => {
    if (active.tool === TOOL.triplanar || active.tool === TOOL.pattern) {
      _setActive({
        ...active,
        subToolbar: null,
        tool: TOOL.gizmo
      })
      props.viewer.transformGizmo.detach()
    }
  }, [active.tool])

  const onAlignDeselectOrAligned = React.useCallback(() => {
    _setActive({ ...active, tool: TOOL.gizmo })
  }, [active.tool, active.subToolbar, active.cameraMode])

  React.useEffect(() => {
    if (!props.viewer.alignTool || active.tool !== TOOL.align) return
    props.viewer.alignTool.on('aligned', onAlignDeselectOrAligned)
    props.viewer.alignTool.on('deselect', onAlignDeselectOrAligned)
    return () => {
      props.viewer.alignTool.removeListener('aligned', onAlignDeselectOrAligned)
      props.viewer.alignTool.removeListener('deselect', onAlignDeselectOrAligned)
    }
  }, [active.tool])

  React.useEffect(() => {
    if (!props.viewer.picker) return
    props.viewer.picker.on('deselect', onPickerDeselect)
    return () => {
      props.viewer.picker.removeListener('deselect', onPickerDeselect)
    }
  }, [active.tool])

  React.useEffect(() => {
    if (props.cameraMode === CAMERA_MODES.PREDEFINED) {
      return _setActive({
        ...initialActive,
        cameraMode: CAMERA_MODES.PREDEFINED as CameraMode
      })
    }

    if (active.cameraMode === CAMERA_MODES.PREDEFINED) {
      _setActive(initialActive)
    }
  }, [props.cameraMode])

  React.useEffect(() => {
    if (!props.viewer.triplanarTool || active.tool !== TOOL.triplanar) return
    if (!props.hasValidTriplanarSelection) {
      _setActive({
        ...active,
        subToolbar: null,
        tool: TOOL.gizmo
      })
    }
  }, [props.hasValidTriplanarSelection, props.viewer.triplanarTool, active.tool])

  React.useEffect(() => {
    if (active.tool !== TOOL.pattern) return
    if (!props.hasValidPatternSelection) {
      _setActive({
        ...active,
        tool: TOOL.gizmo,
        subToolbar: null
      })
    }
  }, [props.hasValidPatternSelection, active.tool])

  return (
    <React.Fragment>
      <KeyboardListener
        disabled={!props.keyBoardBindingsEnabled}
        bindings={[KeyBinding(KeyCode.esc, resetActive)]}
      />
      <div
        className="flex justify-center items-center height-100"
      >
        <ToolbarButton
          title=""
          testid="SettingsToolBarBtn"
          icon={<IconSettings width={15} height={20} />}
          active={
            props.hasValidSelection &&
            active.tool === TOOL.gizmo &&
            active.settings !== null
          }
          disabled={!props.hasValidSelection || props.toolbarButtonsDisabled || (gizmoMode !== 'snapping' && gizmoMode !== 'translate' && gizmoMode !== 'rotate')}
          onClick={() => {
            if (active.settings === null) {
              _setActive({
                ...active,
                settings: 'settings-' + gizmoMode
              })
            } else {
              _setActive({
                ...active,
                settings: null
              })
            }
          }}
        />
        <ToolbarButton
          title="Move"
          testid="MoveToolBarBtn"
          icon={<IconMove width={20} height={20} />}
          active={
            props.hasValidSelection &&
            active.tool === TOOL.gizmo &&
            gizmoMode === 'snapping'
          }
          disabled={!props.hasValidSelection || props.toolbarButtonsDisabled}
          onClick={() => {
            _setActive({
              ...active,
              subToolbar: null,
              tool: TOOL.gizmo,
              settings: active.settings === null ? null : SETTINGS.snapping
            })
            props.viewer.transformGizmo.setMode('snapping')
            setGizmoMode(props.viewer.transformGizmo.control.getMode())
          }}
        />
        <ToolbarButton
          title="Position"
          testid="PositionToolBarBtn"
          icon={<IconPosition width={20} height={20} />}
          active={
            props.hasValidSelection &&
            active.tool === TOOL.gizmo &&
            gizmoMode === 'translate'
          }
          disabled={!props.hasValidSelection || props.toolbarButtonsDisabled}
          onClick={() => {
            _setActive({
              ...active,
              subToolbar: null,
              tool: TOOL.gizmo,
              settings: active.settings === null ? null : SETTINGS.translate
            })
            props.viewer.transformGizmo.setMode('translate')
            setGizmoMode(props.viewer.transformGizmo.control.getMode())
          }}
        />
        <ToolbarButton
          title="Rotate"
          testid='RotateToolBarBtn'
          icon={<IconRotate width={20} height={20} />}
          active={
            props.hasValidSelection &&
            active.tool === TOOL.gizmo &&
            gizmoMode === 'rotate'
          }
          disabled={!props.hasValidSelection || props.toolbarButtonsDisabled}
          onClick={() => {
            _setActive({
              ...active,
              subToolbar: null,
              tool: TOOL.gizmo,
              settings: active.settings === null ? null : SETTINGS.rotate
            })
            props.viewer.transformGizmo.setMode('rotate')
            setGizmoMode(props.viewer.transformGizmo.control.getMode())
          }}
        />

        <Separator />

        <DuplicateButton
          disabled={!props.isSceneLoaded || props.toolbarButtonsDisabled}
        />

        <ModalButton
          className="height-100"
          modalContent={(closeModal: () => void) => (
            <DesignLoader onClose={closeModal} type="replace" />
          )}
          button={(openModal: () => void) => (
            <ToolbarButton
              onClick={() => {
                props.disableKeyboardListeners()
                resetActive()
                openModal()
              }}
              disabled={!props.hasValidSelection || props.toolbarButtonsDisabled}
              icon={<IconReplace width={20} height={20} />}
              title="Replace"
              testid="ReplaceToolBarBtn"
            />
          )}
          onModalClose={() => {
            props.enableKeyboardListeners()
          }}
        />

        <Separator />

        <ToolbarButton
          title="Align"
          testid="AlignBtnToolBarBtn"
          icon={<IconAlign width={20} height={20} />}
          active={active.tool === TOOL.align}
          disabled={
            !props.hasValidAlignSelection ||
            !props.isSceneLoaded ||
            props.toolbarButtonsDisabled
          }
          onClick={() => {
            _setActive({
              ...active,
              subToolbar: null,
              tool: active.tool === TOOL.align ? TOOL.gizmo : TOOL.align,
              settings: null
            })
          }}
        />

        <ToolbarButton
          title="Appearance"
          testid="AppearanceToolBarBtn"
          icon={<IconRotateMaterial width={24} height={21} />}
          active={active.tool === TOOL.triplanar}
          disabled={
            !props.isSceneLoaded ||
            !props.hasValidTriplanarSelection ||
            props.toolbarButtonsDisabled
          }
          onClick={() => {
            _setActive({
              ...active,
              subToolbar: active.subToolbar === SUB_TOOLBAR.triplanar ? null : SUB_TOOLBAR.triplanar,
              tool: active.tool === TOOL.triplanar ? TOOL.gizmo : TOOL.triplanar,
              settings: null
            })
          }}
        />

        <ToolbarButton
          title='Pattern'
          testid="PatternToolBarBtn"
          icon={<IconPattern width={20} height={20} />}
          active={active.tool === TOOL.pattern}
          disabled={
            !props.isSceneLoaded ||
            !props.hasValidPatternSelection ||
            props.toolbarButtonsDisabled
          }
          onClick={() => {
            _setActive({
              ...active,
              tool: active.tool === TOOL.pattern ? TOOL.gizmo : TOOL.pattern,
              subToolbar: active.tool === TOOL.pattern ? null : SUB_TOOLBAR.pattern,
              settings: null
            })
          }}
        />

        <Separator />
        <ModalButton
          small
          modalContent={(closeModal: () => void) => (
            <CreateVariantModal onClose={() => {
              _setActive({
                ...active,
                cameraMode: null
              })
              closeModal()
            }} />
          )}
          button={(openModal: () => void) => (
            <ToolbarButton
              onClick={() => {
                props.disableKeyboardListeners()
                _setActive({
                  ...active,
                  cameraMode: active.cameraMode === CAMERA_MODES.VARIANT ? null : CAMERA_MODES.VARIANT as CameraMode
                })
                openModal()
              }}
              disabled={
                !props.isSceneLoaded ||
                !props.hasValidSelection ||
                props.toolbarButtonsDisabled
              }
              icon={<IconGroup width={20} height={20} />}
              title="Create Variant"
              testid="createVariantToolBarBtn"
            />
          )}
          onModalClose={() => {
            props.enableKeyboardListeners()
          }}
        />
      </div>
      <SubToolbar
        open={
          ((props.hasValidSelection || props.hasValidPatternSelection || props.hasValidTriplanarSelection) && (!!active.subToolbar || !!active.settings)) ||
          active.cameraMode === CAMERA_MODES.PREDEFINED || props.isIsolationActive
        }
        className='flex justify-center p1 c-white bg-black-70 userselect-text'
      >
        { (props.hasValidSelection || props.hasValidPatternSelection || props.hasValidTriplanarSelection) && (!!active.subToolbar || !!active.settings) && (
          <>
            {isSubToolbarActive(SUB_TOOLBAR.triplanar) && (<TriplanarSettings disabled={!props.hasValidTriplanarSelection} />)}
            {isSubToolbarActive(SUB_TOOLBAR.pattern) && (<PatternSettings />)}
            {isSettingsActive(SETTINGS.snapping) && (<MoveSettings />)}
            {isSettingsActive(SETTINGS.translate) && (<TranslateSettings />)}
            {isSettingsActive(SETTINGS.rotate) && (<RotateSettings />)}
          </>
        )}
        {active.cameraMode === CAMERA_MODES.PREDEFINED && (<DisablePredefinedCamera />)}
        {props.isIsolationActive && (<LeaveIsolateButton />)}
      </SubToolbar>
    </React.Fragment>
  )
}

export default connector(Toolbar)

// Snap
function activateSnap (viewer: Go3DViewer) {
  viewer.snappingTool.enable()
  viewer.transformGizmo.disable()
  viewer.picker.clearSelection()
  viewer.triplanarTool.disable()
  viewer.cameraManager.useSnappingCamera = true
}

function deactivateSnap (viewer: Go3DViewer) {
  viewer.snappingTool.disable()
  viewer.picker.enable()
  viewer.transformGizmo.enable()
  if (Object.keys(viewer.picker.selection).length) {
    viewer.transformGizmo.attach(Object.values(viewer.picker.selection), {
      attachToRoot: true
    })
  }
  viewer.cameraManager.useSnappingCamera = false
}

// Triplanar
function activateTriplanar (viewer: Go3DViewer) {
  viewer.triplanarTool.enable()
  viewer.transformGizmo.disable()
  viewer.snappingTool.disable()
}

// Align
function activateAlign (viewer: Go3DViewer) {
  viewer.alignTool.enable()
  viewer.picker.enable()
  viewer.triplanarTool.disable()
  viewer.transformGizmo.disable()
  viewer.snappingTool.disable()
  viewer.transformGizmo.disable()
}

function deactivateAlign (viewer: Go3DViewer) {
  viewer.alignTool.disable()
  viewer.transformGizmo.enable()
  if (Object.keys(viewer.picker.selection).length) {
    viewer.transformGizmo.attach(Object.values(viewer.picker.selection), {
      attachToRoot: true
    })
  }
}

// Pattern
function activatePattern (viewer: Go3DViewer) {
  viewer.transformGizmo.detach()
  viewer.renderOnNextFrame()
}

function deactivatePattern (viewer: Go3DViewer) {
  if (Object.keys(viewer.picker.selection).length) {
    viewer.transformGizmo.attach(Object.values(viewer.picker.selection), {
      attachToRoot: true
    })
  }
  viewer.renderOnNextFrame()
}
