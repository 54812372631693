import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {
  zoomFixed,
  zoom,
  ZOOM_LEVELS
} from '../../../../stores/ducks/threeviewer/imageTemplates'

import InputNumericRange from '../../../common/form/input-numeric-range'
import { RootState } from '../../../../stores/ducks'
import { MdAdd, MdRemove } from 'react-icons/md'
import styled from 'styled-components'

const ZoomButton = styled.div<{ disabled: boolean }>`
  pointer-events: ${props => props.disabled ? 'none' : 'initial'};
  color: ${props => props.disabled ? 'grey' : 'black'};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover { opacity: 0.8; }
`

export default function ManualControls () {
  const zoomLevel = useSelector((state: RootState) => state.threeviewer.imageTemplates.zoomLevel)
  const autoFitEnabled = useSelector((state: RootState) => state.threeviewer.imageTemplates.autoFitEnabled)
  const active = useSelector((state: RootState) => state.threeviewer.camera.activeCamera.imageTemplate)
  const dispatch = useDispatch()

  const disabled = Boolean(!active || autoFitEnabled)

  const onZoomOut = React.useCallback(() => dispatch(zoomFixed('out')), [dispatch])
  const onZoomIn = React.useCallback(() => dispatch(zoomFixed('in')), [dispatch])
  const onZoomChange = React.useCallback((value: number) => dispatch(zoom(value)), [dispatch])
  const onZoom100 = React.useCallback(() => dispatch(zoom(1)), [dispatch])

  return React.useMemo(() => (
    <div>
      <div className='flex flex-start items-center mb2'>
        <ZoomButton
          title='Zoom out'
          disabled={disabled}
          onClick={onZoomOut}
        >
          <MdRemove size={24} />
        </ZoomButton>

        <ZoomButton
          title='Zoom in'
          disabled={disabled}
          onClick={onZoomIn}
        >
          <MdAdd size={24} />
        </ZoomButton>

        <InputNumericRange
          disabled={disabled}
          style={{ width: '100%' }}
          onChange={onZoomChange}
          value={zoomLevel}
          min={ZOOM_LEVELS[0]}
          max={ZOOM_LEVELS[ZOOM_LEVELS.length - 1]}
          step={0.01}
        />

        <ZoomButton
          title='Zoom to 100%'
          disabled={disabled}
          className='f7 pl1'
          onClick={onZoom100}
        >
          {(zoomLevel * 100).toFixed(0)}%
        </ZoomButton>
      </div>
    </div>
  ), [
    zoomLevel,
    autoFitEnabled,
    active,
    dispatch,
    disabled,
    onZoomOut,
    onZoomIn,
    onZoomChange,
    onZoom100,
  ])
}
