import Immutable from 'seamless-immutable'
import { createAction, handleActions } from 'redux-actions'
import _keyBy from 'lodash/keyBy'

import * as fromAdmin from '../admin'

const error = createAction('jobs/ERROR')
export const receive = createAction('jobs/RECEIVE')
export const replace = createAction('jobs/REPLACE')

const initialState = Immutable({
  error: null,
  entries: {}
})

export const fetchJobsByIds = (ids) => (dispatch, getState, { api }) => {
  return api.jobs.getByIds(ids)
    .then((json) => dispatch(receive(json)))
    .catch((err) => dispatch(error(err)))
}

export const removeJobsById = (id) => (dispatch, getState, { api }) => {
  return api.jobs.removeById(id)
    .then(() => dispatch(fromAdmin.getAdminMaterials()))
    .catch((err) => dispatch(error(err)))
}

export default handleActions({
  [receive]: (state, { payload }) => state.merge({
    error: null,
    entries: _keyBy([].concat(payload || []), 'id')
  }, { deep: true }),
  [replace]: (state, { payload }) => state.merge({
    error: null,
    entries: _keyBy([].concat(payload || []), 'id')
  }),
  [error]: (state, { payload }) => state.merge({
    error: payload
  })
}, initialState)
