import fetch from '../../../utils/fetch'

export const startRoomsetDeadlineJob = (id: string) => {
  return fetch('/api/roomsets/startRoomsetDeadlineJob', {
    method: 'post',
    body: JSON.stringify({ id })
  }).then((res) => res.json())
}

export const startRoomsetSetupDeadlineJob = (payload: any) => {
  return fetch('/api/roomsets/startRoomsetSetupDeadlineJob', {
    method: 'post',
    body: JSON.stringify(payload)
  }).then((res) => res.json())
}
