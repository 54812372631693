import React from 'react'

const IconPlus = props => (
  <svg width={props.size} height={props.size} viewBox='0 0 24 24' {...props}>
    <path
      fill={props.color}
      d='M23.356 13l-.001-2H13.042V.688l-2-.001V11H.726l.001 2h10.314v10.313l2 .001V13z'
    />
  </svg>
)

IconPlus.defaultProps = {
  color: 'currentcolor',
  size: '1em'
}

export default IconPlus
