import { createAction, handleActions } from 'redux-actions'
import Immutable from 'seamless-immutable'

import _get from 'lodash/get'
import _map from 'lodash/map'
import _set from 'lodash/set'
import { AppThunk } from '..'

import * as fromDesignsSelectors from './selectors'
import * as fromProjectsSelectors from '../projects/selectors'
import * as fromUsersSelectors from '../users/selectors'
import * as queryBuilder from './query-builder'

import { MODEL_RESOURCE_TYPES } from '../../../constants'
const {
  RESOURCE_DPD,
  RESOURCE_MODEL_BANK,
  RESOURCE_VARIANT,
  RESOURCE_VIRTUAL_PRODUCTS
} = MODEL_RESOURCE_TYPES

const create = (action: string) => createAction(`designs/${action}`)
export const clearSearchResults = create('CLEAR_SEARCH_RESULTS')
export const incrementPage = create('INCREMENT_PAGE')
export const setActiveTab = create('SET_ACTIVE_TAB')
export const setResourceFilters = create('SET_RESOURCE_FILTERS')
export const resetResourceFilters = create('RESET_RESOURCE_FILTERS')
export const setQueryString = create('SET_QUERY_STRING')
export const setShouldPerformFirstDefaultSearch = create('SET_SHOULD_PERFORM_DEFAULT_SEARCH')
export const setIsModalOpen = create('SET_IS_MODAL_OPEN')
export const setShowSizes = create('SET_SHOW_SIZES')
export const setSearchFilterByProp = create('SET_IDS_OMITTED_FROM_SEARCH')
const setIsSearching = create('SET_IS_SEARCHING')
const setSearchResults = create('SET_SEARCH_RESULTS')
const setSizes = create('SET_SIZES')
const updateAggs = create('UPDATE_AGGS')
const setError = create('SET_ERROR')

interface AggBucket {
  key: string
  // eslint-disable-next-line
  doc_count: number
  name: any
}

interface FormatAgg extends AggBucket{
  name: string
}

// some of the values end with . , or space
// eslint-disable-next-line no-useless-escape
const IKEA_BUCKET_NAME_SANITIZER = /[\.\, ]+$/
const formatAgg = (aggBuckets: AggBucket[]) => {
  const result: { [key: string]: FormatAgg } = {}

  Object.values(aggBuckets).forEach(bucket => {
    const name = _get(bucket, 'name.buckets.0.key') || _get(bucket, 'name.buckets.1.key') || ''
    result[bucket.key] = {
      ...bucket,
      name: name ? name.replace(IKEA_BUCKET_NAME_SANITIZER, '') : bucket.key
    }
  })

  return result
}

export const fetchAggs = (): AppThunk => {
  return (dispatch, _, { api }) => {
    const query = _set({}, ['aggs'], queryBuilder.queryAggregations[RESOURCE_MODEL_BANK])

    api.designs.fetchAggs(query)
      .then((data) => {
        const aggregations = _get(data, 'aggregations')
        const aggs = {
          selectableHfb: formatAgg(_get(aggregations, 'hfb.buckets')),
          selectablePra: formatAgg(_get(aggregations, 'pra.buckets')),
          selectablePa: formatAgg(_get(aggregations, 'pa.buckets')),
          selectableStylegroups: formatAgg(_get(aggregations, 'stylegroup.buckets'))
        }
        dispatch(updateAggs({ resource: RESOURCE_MODEL_BANK, aggs }))
      })
      .catch((err) => {
        dispatch(setError(err.message))
      })
  }
}

export const fetchAggsUploads = (): AppThunk => {
  return (dispatch, getState, { api }) => {
    const { terms, mustNotTerms } = queryBuilder.buildFilters({
      activeTab: RESOURCE_DPD,
      userId: fromUsersSelectors.getCurrentId(getState())
    })

    const aggsQuery = {
      size: 0,
      query: {
        bool: {
          filter: terms,
          must_not: mustNotTerms
        }
      },
      aggs: queryBuilder.queryAggregations[RESOURCE_DPD]
    }

    api.designs.fetchAggs(aggsQuery)
      .then((data) => {
        const aggs = { selectableProject: formatAgg(_get(data, 'aggregations.projectId.buckets')) }
        dispatch(updateAggs({ resource: RESOURCE_DPD, aggs }))
      })
      .catch((err) => {
        dispatch(setError(err.message))
      })
  }
}

export const fetchAggsVariants = (): AppThunk => {
  return (dispatch, getState, { api }) => {
    const { terms, mustNotTerms } = queryBuilder.buildFilters({
      activeTab: RESOURCE_VARIANT,
      userId: fromUsersSelectors.getCurrentId(getState())
    })

    const variantAggsQuery = {
      size: 0,
      query: {
        bool: {
          filter: terms,
          must_not: mustNotTerms
        }
      },
      aggs: queryBuilder.queryAggregations[RESOURCE_VARIANT]
    }

    api.designs.fetchAggs(variantAggsQuery)
      .then((data) => {
        const aggs = { selectableProject: formatAgg(_get(data, 'aggregations.projectId.buckets')) }
        dispatch(updateAggs({ resource: RESOURCE_VARIANT, aggs }))
      })
      .catch((err) => {
        dispatch(setError(err.message))
      })
  }
}

const calculateSearchPageSize = () => {
  const res720p = 1280 * 720
  const res1080p = 1920 * 1080
  const res1440p = 2560 * 1440
  const res2160p = 3840 * 2160 // 4K
  const res4320p = 7680 * 4320 // 8K

  // NOTE: does not take into account zoom level
  const resolution = window.screen.height * window.screen.width

  // NOTE: it is possible to make the limit a function of the screen resolution
  // but since the width of the model search window has an upper bound, this would result in excessively large queries and spritesheet fetches
  if (resolution <= res720p) {
    return 40
  } else if (resolution <= res1080p) {
    return 80
  } else if (resolution <= res1440p) {
    return 100
  } else if (resolution <= res2160p) {
    return 150
  } else if (resolution <= res4320p) {
    return 230
  }

  return 100
}

const performModelsSearch = (): AppThunk => {
  return (dispatch, getState, { api }) => {
    const state = getState()
    const userId = fromUsersSelectors.getCurrentUserId(state)
    const projectId = fromProjectsSelectors.getCurrentId(state)
    const filterValues = fromDesignsSelectors.getFilterValues(state)
    const {
      activeTab,
      error,
      queryString,
      page,
      pageSize,
      showSizes
    } = state.designs

    const filters = {
      ...state.designs.filters,
      ...filterValues,
      activeTab,
      projectId,
      userId
    }

    const options = { pageSize, page }
    let query
    if (!queryString) {
      query = queryBuilder.buildDefaultQuery(filters, options)
    } else {
      query = queryBuilder.buildQuery(queryString, filters, options)
    }

    dispatch(setIsSearching(true))
    if (error) {
      dispatch(setError(initialState.error))
    }

    api.designs.searchModels(query)
      .then((data) => {
        const fetchedArticlenrs = _get(state, 'designs.sizes', [])
          .map((value: any) => value.articlenr)
        const sanitizedResults = _map(_get(data, 'hits.hits'), '_source')
        const articleNumbers = sanitizedResults
          .map(result => { return result.articlenr })
          .filter(value => value && !fetchedArticlenrs.includes(value))

        dispatch(setSearchResults(sanitizedResults))
        dispatch(setIsSearching(false))
        if (showSizes && activeTab === RESOURCE_MODEL_BANK) {
          dispatch(getSizes(articleNumbers))
        }
      })
      .catch((err) => {
        if (err.name === 'AbortError') {
          return // this is ok, caught to avoid spamming log
        }
        dispatch(setError(err.message))
        dispatch(setIsSearching(false))
      })
  }
}

function performVirtualProductsSearch (): AppThunk {
  return (dispatch, getState, { api }) => {
    const state = getState()
    const {
      activeTab,
      error,
      queryString,
      page,
      pageSize
    } = state.designs

    const filters: any = {
      ...state.designs.virtualProduct.filters,
      activeTab,
    }

    const options = { pageSize, page }
    let query
    if (!queryString) {
      query = queryBuilder.buildDefaultQuery(filters, options)
    } else {
      query = queryBuilder.buildQuery(queryString, filters, options)
    }

    dispatch(setIsSearching(true))
    if (error) {
      dispatch(setError(initialState.error))
    }

    api.designs.searchVirtualProducts(query)
      .then((data) => {
        const sanitizedResults = _map(_get(data, 'hits.hits'), '_source').map(doc => {
          return {
            ...doc,
            source: RESOURCE_VIRTUAL_PRODUCTS
          }
        })
        dispatch(setSearchResults(sanitizedResults))
        dispatch(setIsSearching(false))
      })
      .catch((err) => {
        if (err.name === 'AbortError') {
          return // this is ok, caught to avoid spamming log
        }
        dispatch(setError(err.message))
        dispatch(setIsSearching(false))
      })
  }
}

export const performSearch = (): AppThunk => {
  return (dispatch, getState) => {
    const activeTab = getState().designs.activeTab

    if (activeTab === RESOURCE_VIRTUAL_PRODUCTS) {
      dispatch(performVirtualProductsSearch())
    } else {
      dispatch(performModelsSearch())
    }
  }
}

export const getSizes = (results: any): AppThunk => {
  return (dispatch) => {
    fetch('/api/pia/measure', {
      method: 'POST',
      body: JSON.stringify({ ids: results }),
    }).then((response) => response.json()).then((result) => {
      dispatch(setSizes(result))
    })
  }
}
export const checkIfShouldPerformSearch = (): AppThunk => {
  return (dispatch, getState) => {
    const activeTab = getState().designs.activeTab
    const isModalOpen = getState().designs.isModalOpen
    if (activeTab === RESOURCE_DPD && isModalOpen) {
      dispatch(performSearch())
    }
  }
}

const initialState = Immutable({
  activeTab: 'upload',
  page: 1,
  pageSize: calculateSearchPageSize(),
  queryString: '',
  isSearching: false,
  shouldPerformFirstDefaultSearch: true,
  searchResults: [],
  error: null,
  isModalOpen: false,
  showSizes: false,
  sizes: [],
  filters: {
    requireThumbnail: false,
    idsOmittedFromSearch: []
  },
  // data per tab
  upload: {
    filters: {
      globalUploads: 'local',
      filterProjectId: null,
      filterProjectLabel: 'All'
    },
    availableAggs: {
      selectableProject: {}
    }
  },
  variant: {
    filters: {
      globalVariants: 'local',
      filterProjectId: null,
      filterProjectLabel: 'All'
    },
    availableAggs: {
      selectableProject: {}
    }
  },
  modelbank: {
    availableAggs: {
      selectableHfb: {},
      selectablePra: {},
      selectablePa: {},
      selectableStylegroups: {}
    },
    filters: {
      endSalesDateStatus: 'hide ended',
      businessArea: null,
      productRangeArea: null,
      productArea: null,
      stylegroup: null
    }
  },
  virtualProduct: {
    filters: {
      status: null
    }
  },
  ugabank: {},
  'non-cad': {}
})

type State = typeof initialState

export default handleActions<State, any>({
  [clearSearchResults.toString()]: (state) => state.merge({
    searchResults: [],
    sizes: []
  }),
  [incrementPage.toString()]: (state) => state.merge({
    page: state.page + 1
  }),
  [setActiveTab.toString()]: (state, { payload }) => state.merge({
    activeTab: payload
  }),
  [setIsSearching.toString()]: (state, { payload }) => state.merge({
    isSearching: payload
  }),
  [setQueryString.toString()]: (state, { payload }) => state.merge({
    page: 1,
    queryString: payload
  }),
  [setSearchResults.toString()]: (state, { payload }) => state.merge({
    searchResults: payload
  }),
  [setSizes.toString()]: (state, { payload }) => state.merge({
    sizes: state.sizes.concat(payload)
  }),
  [setShowSizes.toString()]: (state, { payload }) => state.merge({
    showSizes: payload,
  }),
  [setShouldPerformFirstDefaultSearch.toString()]: (state, { payload }) => state.merge({
    shouldPerformFirstDefaultSearch: payload
  }),
  [setError.toString()]: (state, { payload }) => state.merge({
    error: payload
  }),
  [setResourceFilters.toString()]: (state, { payload }) => {
    return state.merge({
      [payload.resource]: {
        filters: { ...payload.filters }
      }
    }, { deep: true })
  },
  [resetResourceFilters.toString()]: (state, { payload }: { payload: {
    resource: 'upload' | 'variant' | 'modelbank' | 'virtualProduct' }
  }) => {
    return state.merge({
      [payload.resource]: {
        filters: initialState[payload.resource].filters
      }
    }, { deep: true })
  },
  [updateAggs.toString()]: (state, { payload }) => {
    if (payload.resource === RESOURCE_VARIANT) return state.setIn([RESOURCE_VARIANT, 'availableAggs'], payload.aggs)
    if (payload.resource === RESOURCE_DPD) return state.setIn([RESOURCE_DPD, 'availableAggs'], payload.aggs)
    return state.merge({
      [payload.resource]: {
        availableAggs: payload.aggs
      }
    }, { deep: true })
  },
  [setSearchFilterByProp.toString()]: (state, { payload }) => {
    return state.merge({ filters: payload })
  },
  [setIsModalOpen.toString()]: (state, { payload }) => state.merge({
    isModalOpen: payload
  })
}, initialState)
