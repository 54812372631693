import * as fromThreeviewerSelectors from '../threeviewer/selectors'

var lights = []

// TODO we should not save lights here at all, save them in the store
export const disposeLights = () => {
  lights = []
}

export const collectLights = () => (dispatch, getState) => {
  const viewer = fromThreeviewerSelectors.getViewer(getState())
  viewer.scene.traverse((child) => {
    if (child.visible && child.isLight && (child.isSpotLight || child.isDirectionalLight) && child.castShadow) {
      child.originalPosition = child.position.clone()
      child.originalCastShadow = child.castShadow
      lights.push(child)
    } else if (child.isMesh) {
      child.castShadow = true
      child.receiveShadow = true
    }
  })

  var counter = 10

  lights.forEach(function (light) {
    if (counter >= 0) {
      light.visible = true
    } else {
      light.visible = false
    }

    if (light.isSpotLight) {
      light.angle = Math.min(light.angle, 0.8)
    }

    counter--
  })
}
