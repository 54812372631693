import React from 'react'

const IconRectangles = props => (
  <svg viewBox='0 0 20 18' width={props.size} height={props.size} {...props}>
    <defs>
      <path d='M17 19H7v1.502a.493.493 0 0 1-.498.498H2.498A.493.493 0 0 1 2 20.502v-4.004c0-.275.215-.498.498-.498H4V8H2.498A.493.493 0 0 1 2 7.502V3.498C2 3.223 2.215 3 2.498 3h4.004c.275 0 .498.215.498.498V5h10V3.498c0-.275.215-.498.498-.498h4.004c.275 0 .498.215.498.498v4.004a.493.493 0 0 1-.498.498H20v8h1.502c.275 0 .498.215.498.498v4.004a.493.493 0 0 1-.498.498h-4.004a.493.493 0 0 1-.498-.498V19zm0-1v-1.502c0-.275.215-.498.498-.498H19V8h-1.502A.493.493 0 0 1 17 7.502V6H7v1.502A.493.493 0 0 1 6.502 8H5v8h1.502c.275 0 .498.215.498.498V18h10zm-3-8h2.497c.278 0 .503.228.503.491v5.018a.502.502 0 0 1-.503.491h-5.994a.503.503 0 0 1-.503-.491V14H7.503A.503.503 0 0 1 7 13.509V8.491C7 8.22 7.233 8 7.503 8h5.994c.278 0 .503.228.503.491V10zm0 1v2.509a.502.502 0 0 1-.503.491H11v.51c0 .275.223.49.498.49h4.004a.49.49 0 0 0 .498-.49v-3.02a.491.491 0 0 0-.498-.49H14zM8 9.49v3.02c0 .275.223.49.498.49h4.004a.49.49 0 0 0 .498-.49V9.49a.491.491 0 0 0-.498-.49H8.498A.49.49 0 0 0 8 9.49zm10-4.995v2.01c0 .28.222.495.495.495h2.01c.28 0 .495-.222.495-.495v-2.01A.492.492 0 0 0 20.505 4h-2.01a.492.492 0 0 0-.495.495zm-15 0v2.01c0 .28.222.495.495.495h2.01C5.785 7 6 6.778 6 6.505v-2.01A.492.492 0 0 0 5.505 4h-2.01A.492.492 0 0 0 3 4.495zm0 13v2.01c0 .28.222.495.495.495h2.01c.28 0 .495-.222.495-.495v-2.01A.492.492 0 0 0 5.505 17h-2.01a.492.492 0 0 0-.495.495zm15 0v2.01c0 .28.222.495.495.495h2.01c.28 0 .495-.222.495-.495v-2.01a.492.492 0 0 0-.495-.495h-2.01a.492.492 0 0 0-.495.495z' id='a'/>
    </defs>
    <use fill={props.color} xlinkHref='#a' transform='translate(-2 -3)' fillRule='evenodd'/>
  </svg>
)

IconRectangles.defaultProps = {
  color: 'currentcolor',
  size: '1em'
}

export default IconRectangles
