const THREE = require('three')

class ObjectNormalMaterial extends THREE.ShaderMaterial {
  constructor () {
    super({
      vertexShader: `
        varying vec3 vUv;
        
        void main() {
          vUv = normal;
          gl_Position = projectionMatrix * viewMatrix * modelMatrix * instanceMatrix * vec4(position, 1.0);
        }
      `,
      fragmentShader: `
        varying vec3 vUv;
        
        void main() {
          gl_FragColor = vec4(vUv, 1.0);
        }
      `
    })
  }
}

module.exports = ObjectNormalMaterial
