import React from 'react'
import PropTypes from 'prop-types'

const InputGroup = ({
  className,
  children
}) => (
  <div className={`${className} py1`}>
    {children}
  </div>
)

InputGroup.propTypes = {
  className: PropTypes.string
}

InputGroup.defaultProps = {
  className: ''
}

export default InputGroup
