import Immutable from 'seamless-immutable'
import { createAction, handleActions } from 'redux-actions'

import _uniq from 'lodash/uniq'
import _keyBy from 'lodash/keyBy'

import * as fromJobs from '../jobs'

const request = createAction('patterns/json/REQUEST')
const confirmReceive = createAction('patterns/json/CONFIRM_RECEIVE')

export const receive = (payload) => (dispatch) => {
  dispatch(confirmReceive(payload))

  const jobs = [].concat(payload).reduce((acc, entry) => {
    return acc.concat(entry.jobs || [])
  }, [])

  dispatch(fromJobs.receive(jobs))
}

const error = createAction('patterns/json/ERROR')

export const addEntities = (payload) => (dispatch) => {
  dispatch(receive(payload))
}

export const getPatterns = (ids) => (dispatch, _, { api }) => {
  return api.patterns.getAll(ids)
    .then((json) => dispatch(receive(json)))
    .catch((err) => dispatch(error(err)))
}

export const getPatternsForProject = () => (dispatch, getState, { api }) => {
  dispatch(request())
  return api.patterns.getAvailable(getState().projects.currentId)
    .then((json) => dispatch(receive(json)))
    .catch((err) => dispatch(error(err)))
}

export const getAvailablePatterns = () => (dispatch, getState, { api }) => {
  const projectId = getState().projects.currentId
  dispatch(request())
  return api.patterns.getAvailable(projectId)
    .then((json) => dispatch(receive(json)))
    .catch((err) => dispatch(error(err)))
}

export const remove = (id) => (dispatch, getState, { api }) => (
  api.patterns.remove(id)
    .then((json) => dispatch(receive([json])))
)

export const update = (payload) => (dispatch, getState, { api }) => (
  api.patterns.update(payload)
    .then((json) => dispatch(receive([json])))
)

const initialState = Immutable({
  entries: {},
  result: [],
  error: null,
  isFetching: false,
  doneFetching: false
})

export default handleActions({
  [confirmReceive]: (state, action) => {
    const entries = _keyBy([].concat(action.payload || []), 'id')

    return state.merge({
      result: _uniq(state.result.concat(Object.keys(entries))),
      entries: state.entries.merge(entries, { deep: true }),
      isFetching: false,
      doneFetching: true
    })
  },
  [request]: (state) =>
    state.merge({
      isFetching: true,
      doneFetching: false
    }),
  [error]: (state, action) => state.merge({ error: action.payload })
}, initialState)
