import React from 'react'

import Modal from '../../common/modal'
import FormRemove from '../../common/form/form-remove'

class DiscardChangesModal extends React.PureComponent {
  render () {
    const props = this.props

    return (
      <Modal
        isOpen={props.isOpen}
        onRequestClose={props.onRequestClose}
        shouldCloseOnOverlayClick
        width={props.width}
      >
        <FormRemove
          formTitle={'Discard user changes'}
          formDescription={
            `You are currently editing user ${props.selectedUserEmail} - are you sure you want to discard these changes?`
          }
          onCancel={props.onCancel}
          onConfirm={props.onConfirm}
          buttonText={'Discard changes'}
        />
      </Modal>
    )
  }
}

export default DiscardChangesModal
