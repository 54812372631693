import React from 'react'

import cs from 'classnames'
import _map from 'lodash/map'

import colors from '../../../../css/colors'
import Avatar from '../../common/avatar'
import IconCross from '../../common/icons/icon-cross'
import type { User } from '../../../stores/ducks/users/User'

function CrossedAvatar (props: { hidden: boolean }) {
  return (
    <div
      className={cs('flex-none opacity-hover-triggee pointer-disabled absolute top-0 bottom-0 left-0 right-0 z1 circle bg-gray-light pointer', {
        hidden: props.hidden
      })}
    >
      <IconCross
        size={12}
        className='c-error'
        style={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          margin: 'auto'
        }}
      />
    </div>
  )
}

type Props = {
  modalType: 'ASSET' | 'PROJECT'
  handleShareWithUser?: (id: string) => void
  isOwner: (user: string) => boolean
  showAvatar: (user: string) => boolean
  users: User[]
  userId: string
  removeable: boolean
  userSentBy?: User | undefined
}

export default function SharedWith (props: Props) {
  const loggedInUser = props.userId

  const getUserAvatar = (user:User) => {
    const userId = user.id
    const isCurrentUser = userId === loggedInUser
    return (
      <div
        data-testid={`${(!isCurrentUser && !props.isOwner(userId)) ? 'shared-with' : 'is-project-owner'}`}
        key={userId}
        className={cs('relative mx1 mb1 z3-hover', {
          'pointer opacity-hover-trigger': (!isCurrentUser && !props.isOwner(userId))
        })}
        onClick={() => {
          if (props.modalType === 'PROJECT' && !isCurrentUser && !props.isOwner(userId)) {
            props.handleShareWithUser && props.handleShareWithUser(userId)
          }
        }}
      >
        {props.showAvatar(userId) && (
          <>
            <Avatar
              key={userId}
              user={user}
              withTooltip
              tooltipLocation='under'
              style={{ border: props.isOwner(userId) ? `2px solid ${colors.primary}` : '' }}
            />
            {props.removeable && (<CrossedAvatar hidden={isCurrentUser || props.isOwner(userId)} />)}
          </>
        )}
      </div>)
  }

  if (props.users.length) {
    return (
      <>
        {_map(props.users, (user) => {
          return getUserAvatar(user)
        })}
      </>
    )
  }

  return (
    <div className='f7 mb2 pl1'>
      <em>{props.modalType === 'ASSET' && 'Not sent to anybody yet.'}</em>
    </div>
  )
}
