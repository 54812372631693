import Immutable from 'seamless-immutable'
import { createAction, handleActions } from 'redux-actions'
import _get from 'lodash/get'
import { DEFAULT_ENVMAP, DEFAULT_ROOMSET_ENVMAP } from '../../../constants'

import { loadEnvMap } from '../materials'
import { getIsRoomsetActive } from '../roomsets/selectors'
import { adjustWallInScene } from '../templates/default-template'

import { AppThunk } from '..'
import { Roomset } from '../roomsets/Roomset'
import { VisualizedCombination } from '../combinations/Combination'
import CameraManager from '../../../../../go3dthree/src/scenegraph/CameraManager'

import { setCameraSettings, backupCamera, useCameraBackup, setupCamera } from './camera'

import { resetIsolate } from '../selection/index'
export const ZOOM_LEVELS = [0.05, 0.1, 0.15, 0.2, 0.33, 0.5, 0.75, 1, 1.1, 1.25, 1.5, 2, 2.5, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]

export const dispose = createAction('threeviewer/imageTemplates/DISPOSE_IMAGE_TEMPLATE')
const confirmAutoFitEnabled = createAction<boolean>('threeviewer/imageTemplates/confirmAutoCropEnabled')
const confirmImageTemplateDebugEnabled = createAction<boolean>('threeviewer/imageTemplates/confirmImageTemplateDebugEnabled')
const confirmSetZoomLevel = createAction<number>('threeviewer/imageTemplates/confirmSetZoomLevel')
const confirmDesignRenderEnable = createAction<boolean>('threeviewer/imageTemplates/confirmDesignRender')

function getNextZoomLevel (zoomLvl: number, direction: 'in' | 'out') {
  let zoomIn = ZOOM_LEVELS[ZOOM_LEVELS.length - 1]
  let zoomOut = ZOOM_LEVELS[0]
  ZOOM_LEVELS.forEach(lvl => {
    zoomIn = zoomLvl < lvl ? Math.min(zoomIn, lvl) : zoomIn
    zoomOut = zoomLvl > lvl ? Math.max(zoomOut, lvl) : zoomOut
  })
  if (direction === 'in') return zoomIn
  if (direction === 'out') return zoomOut
  return zoomLvl
}

export function setup (): AppThunk {
  return (dispatch, getState) => {
    const viewer = getState().threeviewer.viewer
    if (!viewer) return
    const imageTemplateManager = viewer.imageTemplateManager
    if (imageTemplateManager) {
      imageTemplateManager.on('change', (offsetView: any) => {
        dispatch(confirmSetZoomLevel(offsetView.zoom))
      })
    }
  }
}

export function zoomFixed (direction: 'in' | 'out'): AppThunk {
  return (_, getState) => {
    const viewer = getState().threeviewer.viewer
    if (!viewer) return
    const nextZoom = getNextZoomLevel(viewer.imageTemplateManager.offsetView.zoom, direction)
    viewer.imageTemplateManager.zoom(nextZoom)
  }
}

export function zoom (value: number): AppThunk {
  return (_, getState) => {
    const viewer = getState().threeviewer.viewer
    if (!viewer) return
    viewer.imageTemplateManager.zoom(value)
  }
}

export function imageTemplateRotateStagedProducts (degrees: number): AppThunk {
  return (_, getState) => {
    const viewer = getState().threeviewer.viewer
    if (!viewer) return

    viewer.imageTemplateManager.rotateStagedProducts(degrees)

    if (viewer.imageTemplateManager.autoCropEnabled) {
      viewer.imageTemplateManager.cropToStagedOrSelected()
    }
  }
}

function setupTransformGizmoForImageTemplate (rootNodes: any[]): AppThunk {
  return (_, getState) => {
    const state = getState()
    const viewer = state.threeviewer.viewer
    if (!viewer) return
    viewer.transformGizmo.attach(rootNodes)
  }
}

function getPlacement (imageTemplate: Roomset): [number, number, number] {
  let dummyPosition = [0, 0, 0]
  let targetPosition = [0, 0, 0]

  if (imageTemplate.dummies && Object.keys(imageTemplate.dummies).length) {
    const dummy = _get(imageTemplate.dummies, imageTemplate.defaultDummyId || '', Object.values(imageTemplate.dummies)[0])
    dummyPosition = [dummy.transform[9], dummy.transform[10], dummy.transform[11]]
  } else if (
    imageTemplate.manualAlignment &&
    imageTemplate.manualAlignment.length &&
    imageTemplate.manualAlignment.every(num => typeof num === 'number')
  ) {
    return imageTemplate.manualAlignment as [number, number, number]
  }

  if (imageTemplate.targets && Object.keys(imageTemplate.targets).length) {
    const target = _get(imageTemplate.targets, imageTemplate.defaultTargetId!, Object.values(imageTemplate.targets)[0])
    targetPosition = [target.transform[9], target.transform[10], target.transform[11]]
  }

  const x = targetPosition[0] - dummyPosition[0]
  const y = targetPosition[1] - dummyPosition[1]
  const z = targetPosition[2] - dummyPosition[2]

  const threshold = 0.001

  return [
    Math.abs(x) < threshold ? 0 : Math.sign(x),
    Math.abs(y) < threshold ? 0 : Math.sign(y),
    Math.abs(z) < threshold ? 0 : Math.sign(z)
  ]
}

export function activateImageTemplateCamera (imageTemplate: Roomset): AppThunk {
  return (dispatch, getState) => {
    const state = getState()
    const viewer = state.threeviewer.viewer
    const isRoomsetActive = getIsRoomsetActive(state)
    const activeCamera = state.threeviewer.camera.activeCamera
    if (!viewer) return

    if (!state.threeviewer.camera.cameraBackup) {
      const cameraSettings = viewer.cameraManager.getCameraSettings()

      if (state.selection.isIsolationActive) {
        dispatch(resetIsolate())
      }

      dispatch(backupCamera({
        settings: {
          ...cameraSettings,
          fovDeg: viewer.camera.fov,
          aspectRatio: viewer.postProcessManager.aspectRatio
        },
        activeCamera: activeCamera
      }))
    }

    if (state.threeviewer.imageTemplates.autoFitEnabled === null) {
      dispatch(setAutoFitEnabled(true))
    }

    const settings = _get(imageTemplate.cameras, imageTemplate.defaultCameraId || '', Object.values(imageTemplate.cameras!)[0])

    dispatch(setCameraSettings({
      settings: settings,
      cameraId: settings.id,
      imageTemplate
    }))

    const target = _get(imageTemplate.targets, imageTemplate.defaultTargetId || '', Object.values(imageTemplate.targets!)[0])
    const placement = getPlacement(imageTemplate)

    if (!activeCamera.imageTemplate) {
      const rootModels: any[] = []

      Object.values(viewer.picker.selection).forEach(node => {
        const rootNode = viewer.viewerUtils.findRootNode(node)
        if (
          !rootModels.includes(rootNode) &&
          rootNode.userData.isModelRoot &&
          rootNode.userData.isCombination
        ) {
          rootModels.push(rootNode)
        }
      })

      if (!rootModels.length) {
        viewer.scene.traverse((node: any) => {
          if (node.userData.isModelRoot && node.userData.isCombination) {
            rootModels.push(node)
          }
        })
      }

      dispatch(setupTransformGizmoForImageTemplate(rootModels))

      viewer.imageTemplateManager
        .enable()
        .setProductRatioToFullSize(3500 / 4000)
        .stageProducts(rootModels, (node: any) => !node.isLight && node.name !== 'floor' && !node.userData.isGroup)
        .setupTargetAndPlacement(target, placement)
        .moveProductsToTarget()
        .cropToStagedOrSelected()
    }

    if (activeCamera.imageTemplate) {
      viewer.imageTemplateManager
        .enable()
        .resetStagedProductsPositionAndRotation()
        .setupTargetAndPlacement(target, placement)
        .moveProductsToTarget()
        .cropToStagedOrSelected()
    }

    if (isRoomsetActive) {
      dispatch(loadEnvMap(DEFAULT_ENVMAP, '.exr'))
    }

    viewer.cameraManager.change({ lockedMode: CameraManager.LOCKED_MODES.OFFSET_VIEW_CAMERA })
  }
}

export function loadSavedImageTemplateCameraSettings (imageTemplate: Roomset, combination: VisualizedCombination, resetNodeTransforms = true): AppThunk {
  return (dispatch, getState) => {
    const cameraSettings = combination.cameraSettings
    const state = getState()
    const viewer = state.threeviewer.viewer
    let settings = imageTemplate.cameras![cameraSettings?.id || '']
    if (!viewer) return

    // The camera used in the render might have been removed after a new upload and convert of the image template.
    // Fallback 1 to defaultCameraId (theoretically only exists on a template with multiple cameras).
    // Fallback 2 to the first camera in the cameras object (should theoretically only be one in this case).
    if (!settings) {
      settings = _get(imageTemplate.cameras, imageTemplate.defaultCameraId || '', Object.values(imageTemplate.cameras!)[0])
    }

    if (!settings) return

    if (!state.threeviewer.camera.cameraBackup) {
      dispatch(backupCamera({
        settings: {
          ...cameraSettings,
          fovDeg: viewer.camera.fov
        },
        activeCamera: state.threeviewer.camera.activeCamera
      }))
    }

    if (state.threeviewer.imageTemplates.autoFitEnabled === null) {
      dispatch(setAutoFitEnabled(true))
    }

    dispatch(setCameraSettings({
      settings: settings,
      cameraId: settings.id,
      imageTemplate: imageTemplate
    }))

    const rootModels: any[] = []
    viewer.scene.traverse((node: any) => {
      if (node.userData.isModelRoot && node.userData.isCombination) {
        rootModels.push(node)
      }
    })

    const target = _get(imageTemplate.targets, imageTemplate.defaultTargetId || '', Object.values(imageTemplate.targets!)[0])
    const placement = getPlacement(imageTemplate)

    dispatch(setupTransformGizmoForImageTemplate(rootModels))

    viewer.imageTemplateManager
      .enable()
      .setProductRatioToFullSize(3500 / 4000)
      .stageProducts(rootModels, (node: any) => !node.isLight && node.name !== 'floor' && !node.userData.isGroup)
      .setupTargetAndPlacement(target, placement)
      .setOffsetView(
        cameraSettings?.horizontal_offset || 0,
        cameraSettings?.vertical_offset || 0,
        cameraSettings?.zoom_factor || 1
      )

    viewer.cameraManager.change({ lockedMode: CameraManager.LOCKED_MODES.OFFSET_VIEW_CAMERA })

    if (resetNodeTransforms) {
      const state = getState()
      const viewer = state.threeviewer.viewer
      if (!viewer) return
      const rootNodes: any[] = []

      viewer.scene.children.forEach((node: any) => {
        if (node.userData.isCombination) {
          node.traverse((child: any) => (child.userData.isModelRoot) && rootNodes.push(child))
        }
      })

      rootNodes.forEach(node => {
        const nodeData = combination.nodes.find(n => n.id === node.userData.modelId)
        if (nodeData && node.modifications.transform) {
          const transform = nodeData.modifications.transform!
          const matrix = new viewer.THREE.Matrix4()
          matrix.set(
            transform[0], transform[3], transform[6], transform[9],
            transform[1], transform[4], transform[7], transform[10],
            transform[2], transform[5], transform[8], transform[11],
            0, 0, 0, 1
          )
          node.setLocalMatrix(matrix)
        }
      })
    }
  }
}

export function deactivateImageTemplateCamera (): AppThunk {
  return (dispatch, getState) => {
    const state = getState()
    const viewer = state.threeviewer.viewer
    const isRoomsetActive = getIsRoomsetActive(state)
    if (!viewer) return

    if (state.threeviewer.camera.cameraBackup) {
      dispatch(useCameraBackup(state.threeviewer.camera.cameraBackup))
    } else if (state.threeviewer.settings.cameraSettings) {
      // load default camera settings
      setupCamera(viewer, state.threeviewer.settings.cameraSettings, dispatch)
      dispatch(backupCamera(null))
    }

    viewer.cameraManager.change({ lockedMode: null })

    viewer.imageTemplateManager
      .clear()
      .disable()

    if (isRoomsetActive) {
      dispatch(loadEnvMap(DEFAULT_ROOMSET_ENVMAP, '.exr'))
    } else {
      dispatch(adjustWallInScene())
    }
  }
}

export function setDesignRegistrationChange (enabled: boolean): AppThunk {
  return (dispatch, getState) => {
    const viewer = getState().threeviewer.viewer
    if (!viewer) {
      return
    }
    dispatch(confirmDesignRenderEnable(enabled))
  }
}

export function setAutoFitEnabled (enabled: boolean): AppThunk {
  return (dispatch, getState) => {
    const viewer = getState().threeviewer.viewer
    if (!viewer) return
    viewer.imageTemplateManager.autoCropEnabled = enabled
    if (!viewer.imageTemplateManager.autoCropEnabled) {
      viewer.imageTemplateManager.enableManualControls()
    } else {
      viewer.imageTemplateManager.disableManualControls()
      viewer.imageTemplateManager.cropToStagedOrSelected()
    }
    dispatch(confirmAutoFitEnabled(viewer.imageTemplateManager.autoCropEnabled))
  }
}

export function setImageTemplateDebugEnabled (enabled: boolean): AppThunk {
  return (dispatch, getState) => {
    const viewer = getState().threeviewer.viewer
    if (!viewer) return
    viewer.imageTemplateManager.debug = enabled
    dispatch(confirmImageTemplateDebugEnabled(viewer.imageTemplateManager.debug))
  }
}

export function panStart (direction: 'up' | 'down' | 'left' | 'right'): AppThunk {
  return (_, getState) => {
    const viewer = getState().threeviewer.viewer
    if (!viewer) return
    viewer.imageTemplateManager.panStart(direction)
  }
}

export function panEnd (direction: 'up' | 'down' | 'left' | 'right'): AppThunk {
  return (_, getState) => {
    const viewer = getState().threeviewer.viewer
    if (!viewer) return
    viewer.imageTemplateManager.panEnd(direction)
  }
}

const initialState = Immutable<{
  autoFitEnabled: null | boolean
  debugImageTemplateEnabled: boolean
  zoomLevel: number
  designRenderEnabled: boolean
}>({
  autoFitEnabled: true,
  debugImageTemplateEnabled: false,
  zoomLevel: 1,
  designRenderEnabled: false,
})

type State = typeof initialState

export default handleActions<State, any>({
  [confirmSetZoomLevel.toString()]: (state, action) => state.merge({ zoomLevel: action.payload }),
  [confirmAutoFitEnabled.toString()]: (state, action) => state.merge({ autoFitEnabled: action.payload }),
  [confirmDesignRenderEnable.toString()]: (state, action) => state.merge({ designRenderEnabled: action.payload }),
  [confirmImageTemplateDebugEnabled.toString()]: (state, action) => state.merge({ debugImageTemplateEnabled: action.payload }),
  [dispose.toString()]: () => initialState
}, initialState)
