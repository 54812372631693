import * as process from 'process'

const SW_VERSION = process.env.SW_VERSION

export default function () {
  if (window.navigator.serviceWorker && process.env.NODE_ENV === 'production') {
    window.navigator.serviceWorker
      .register(SW_VERSION ? `/js/${SW_VERSION}/sw.js` : '/js/sw.js', {
        scope: '/'
      })
      .then(reg => {
        if (window.navigator.serviceWorker.controller) {
          if (reg.update) reg.update()
        }
        reg.onupdatefound = () => {
          const installing = reg.installing
          if (installing) {
            installing.onstatechange = () => {
              if (
                installing.state === 'installed' &&
                window.navigator.serviceWorker.controller
              ) {
                window.location.reload()
              }
            }
          }
        }
      })
      .catch(err => console.error(err))
    window.navigator.serviceWorker.oncontrollerchange = () =>
      window.location.reload()
  }
}
