import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import EditableText from '../common/form/editable-text'
import Square from '../common/Square'
import Menu from '../common/menu'

import colors from '../../../css/colors'
import css from './project.css'

class Project extends Component {
  constructor () {
    super()
    this.state = {}
  }

  render () {
    const {
      title,
      modifiedAt,
      createdAt,
      imageSrc,
      onModalRemove,
      onModalEdit,
      onEdit,
      onOpen,
      shared
    } = this.props

    const menuItems = [
      !shared && { onClick: onModalEdit, text: 'Edit', icon: 'icon-pencil-1' },
      !shared && { onClick: onModalRemove, text: 'Remove', icon: 'icon-trash-empty' },
      shared && { onClick: onModalRemove, text: 'Leave project', icon: 'icon-eye-off' },
      { onClick: onOpen, text: 'Open', className: 'border-top' }
    ].filter(item => item)

    return (
      <div data-testid='project-card' className='paper-1 overflow-hidden br-2'>
        <div
          className={`${css.image} relative pointer border-bottom`}
          style={{ borderColor: colors.todo_eee }}
          onClick={onOpen}
        >
          <Square
            backgroundImage={imageSrc}
            innerStyle={{ borderRadius: '2px 2px 0 0' }}
            hoverable />
        </div>

        <div className={'relative flex'}>
          <div className={`px1 py1 ${css.title}`}>
            <EditableText
              defaultValue={title || 'Untitled Project'}
              onChange={(title) => onEdit({ title })}
              editable={!shared}
              singleClickEdit
              className='width-100 block f7 py0 px0 bold truncate'
              iconSize={12}
            >
              {(value, props) => (
                <div {...props}>{value}</div>
              )}
            </EditableText>
            <time className={'truncate f7 c-gray-accessible'}>{moment(modifiedAt || createdAt).fromNow()}</time>
          </div>

          <Menu
            style={{ width: 22 }}
            listClassName='brtl-2'
            buttonClassName='brbr-2 bg-white bg-gray-lighter-hover'
            position='topLeft'
            items={menuItems}
          />
        </div>
      </div>
    )
  }
}

Project.propTypes = {
  title: PropTypes.string
}

Project.defaultProps = {}

export default Project
