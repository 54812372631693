
import _get from 'lodash/get'
import _curry from 'lodash/curry'

import * as fromProjectsSelectors from '../projects/selectors'

const getLocalState = (state) => state.uploads

export const getEntries = (state) => _get(getLocalState(state), 'entries', {})

// This actually gets the uploads that are in progress.
export const getCompletedCadsInCurrentProject = (state) => {
  const entries = getEntries(state)
  const projectId = fromProjectsSelectors.getCurrentId(state)

  return Object.values(entries)
    .filter(upload => {
      return upload &&
        upload.type === 'cad' &&
        upload.projectId === projectId &&
        !upload.removedAt &&
        upload.status !== 'Completed'
    })
}

export const getEntryById = _curry((id, state) => _get(getEntries(state), id, {}))
