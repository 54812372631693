var THREE

module.exports = {
  addPolaroidHandler (params) {
    THREE = this.app.THREE
    var MODE = 'polaroid'
    let isActive = false
    const parent = new THREE.Object3D()
    const renderTarget = new THREE.WebGLRenderTarget(512, 512, { format: THREE.RGBFormat })

    const camera = this.app.camera.clone()
    const aspectRatio = 1.5

    camera.position.copy(new THREE.Vector3())
    camera.quaternion.copy(new THREE.Quaternion())
    camera.fov = 60
    camera.aspect = aspectRatio
    camera.updateProjectionMatrix()

    const flash = new THREE.AmbientLight(0x404040)
    flash.intensity = 1

    const size = 0.2

    const geometry = new THREE.PlaneGeometry(size * aspectRatio, size, 4)
    const material = new THREE.MeshStandardMaterial({
      color: 0x000000,
      side: THREE.DoubleSide,
      emissiveMap: renderTarget.texture,
      emissive: 0xffffff
    })

    const plane = new THREE.Mesh(geometry, material)
    const topIndicator = new THREE.Mesh(
      new THREE.SphereGeometry(0.01, 16, 16),
      new THREE.MeshStandardMaterial({ color: 0x333333 })
    )

    parent.add(topIndicator)
    parent.add(camera)
    parent.add(plane)

    parent.rotateX(-Math.PI / 2)
    parent.position.set(-0.2, 0, 0.06)

    topIndicator.position.set(0, 0.14, 0)

    this.app.scene.add(flash)

    const startFlashLife = 50
    let flashLife = startFlashLife
    let triggered = false

    parent.visible = false
    this.add(parent)

    const steps = 1 / startFlashLife

    const updatePolaroid = () => {
      plane.visible = false
      this.app.renderer.setRenderTarget(renderTarget)
      this.app.renderer.clear()
      this.app.renderer.render(this.app.scene, camera)
      plane.visible = true

      if (triggered) {
        if (--flashLife >= 0) {
          const a = 1 - (steps * flashLife)
          let intensity = 0

          if (a <= 0.2) {
            intensity = THREE.Math.smoothstep(a, 0, 0.2)
          } else {
            intensity = 1 - THREE.Math.smoothstep(a, 0.2, 1)
          }

          flash.intensity = 20 * intensity
        } else {
          triggered = false
          flash.intensity = 0
          flashLife = startFlashLife
        }
      }
    }

    this.on(this.TriggerClicked, () => {
      if (isActive) {
        triggered = true
        params.onTriggered(camera)
      }
    })

    this.on(this.MenuPressed, () => {
      if (this.mode && this.mode !== MODE) return

      if (isActive) {
        // Deactivate
        parent.visible = false
        isActive = false
        this.mode = null
        delete this._update.polaroid
      } else {
        // Activate
        parent.visible = true
        isActive = true
        this.mode = MODE
        this._update.polaroid = updatePolaroid
      }
    })
  }
}
