import React from 'react'
import PropTypes from 'prop-types'
import colors from '../../../css/colors'

const clamp = (val, min, max) => Math.min(Math.max(min, val), max)

const TYPES = {
  info: colors.todo_007BFF,
  warning: colors.primary,
  error: colors.todo_rgb255_13_25,
  success: colors.success
}

const STYLES = {
  wrapper: {
    color: colors.white,
    background: 'rgba(0,0,0,.05)'
  },
  inner: {
    overflow: 'hidden',
    maxWidth: '100%',
    minHeight: '1.2em',
    transition: 'padding 200ms linear'
  },
  striped: {
    backgroundImage: 'linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent)',
    backgroundSize: '1rem 1rem'
  },
  animated: {
    animation: 'progress-bar-stripes 1s linear infinite'
  }
}

const ProgressBar = ({
  percentage,
  pad,
  hideLabel,
  animated,
  striped,
  type
}) => {
  const clampedPercentage = clamp(percentage, 0, 100)

  let innerStyle = {
    ...STYLES.inner,
    backgroundColor: TYPES[type],
    width: `${clampedPercentage}%`
  }

  if (striped) {
    innerStyle = { ...innerStyle, ...STYLES.striped }
  }

  if (animated) {
    innerStyle = { ...innerStyle, ...STYLES.striped, ...STYLES.animated }
  }

  return (
    <div style={STYLES.wrapper}>
      <div className={`py${pad} px1`} style={innerStyle}>
        {!hideLabel && `${clampedPercentage}%`}
      </div>
    </div>
  )
}

ProgressBar.propTypes = {
  type: PropTypes.oneOf(Object.keys(TYPES)),
  striped: PropTypes.bool,
  percentage: PropTypes.number,
  pad: PropTypes.oneOf([0, 1, 2, 3, 4]),
  hideLabel: PropTypes.bool,
  animated: PropTypes.bool
}

ProgressBar.defaultProps = {
  percent: 100,
  type: 'info',
  striped: false,
  pad: 1,
  hideLabel: false,
  animated: false
}

export default ProgressBar
