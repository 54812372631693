import React from 'react'

import SubHeader from './sub-header'
import Header from './header'
import Main from './main'
import Wrapper from './wrapper'
import Footer from './footer'

type Props = {
  children: React.ReactNode
  subheaderContent?: any
  breadcrumbs?: any[]
  contentClassname?: string
}

const StandardLayout = ({
  children,
  subheaderContent = null,
  breadcrumbs,
  contentClassname = ''
}: Props) => (
  <Wrapper subheader={!!subheaderContent}>
    {/* @ts-ignore TODO: need to refactor layout header */}
    <Header breadcrumbs={breadcrumbs}/>
    {subheaderContent && (
      <SubHeader>
        {subheaderContent}
      </SubHeader>
    )}
    <Main>
      <div className={`p2 ${contentClassname}`}>
        {children}
      </div>
      <Footer />
    </Main>
  </Wrapper>
)

export default StandardLayout
