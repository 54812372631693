
class VariantManager {
  constructor (app) {
    this.THREE = app.THREE
    this.scene = app.scene
    this.utils = app.viewerUtils
    this.camera = app.camera
    this.picker = app.picker
    this.cameraManager = app.cameraManager
    this.visibilityList = []
    this.enabled = false

    this.prevPosition = undefined
    this.prevTarget = undefined

    this._center = new this.THREE.Vector3()
    this._cameraPositions = [
      new this.THREE.Vector3(),
      new this.THREE.Vector3(),
      new this.THREE.Vector3(),
      new this.THREE.Vector3()
    ]
  }

  _isSelected (object) {
    let selected = false

    Object.values(this.picker.selection).forEach(selection => {
      const selectionRoot = this.utils.findRootNode(selection)
      if (selectionRoot.id === object.id) {
        selected = true
      }
    })
    return selected
  }

  _zoomToObjects (point, delay) {
    const newPosition = this.cameraManager.getFitBBInScreenPosition(point.clone(), this.bb)
    this.cameraManager.moveToPoint(newPosition, () => {}, {
      delay,
      onUpdate: () => {
        this.cameraManager.cameraLookAt(this.bb.getCenter(this._center))
      }
    })
  }

  _getClosestPoint (target, points) {
    return points.reduce((prevPoint, point) => {
      if (point.distanceTo(target) < prevPoint.distanceTo(target)) {
        return point
      }
      return prevPoint
    }, points[0])
  }

  reset () {
    this.visibilityList.forEach(({ object, visible }) => {
      object.visible = visible
    })

    this.visibilityList = []
    this.enabled = false
    this.bb = undefined
  }

  resetCamera () {
    this.cameraManager.moveToPoint(this.prevPosition, () => {}, {
      delay: 0,
      onUpdate: () => {
        this.prevTarget && this.cameraManager.cameraLookAt(this.prevTarget)
      }
    })
  }

  incrementCameraPosition () {
    this.currentCameraPositionIndex = (this.currentCameraPositionIndex + 1) % this._cameraPositions.length
    this._zoomToObjects(this._cameraPositions[this.currentCameraPositionIndex], 0)
  }

  decrementCameraPosition () {
    this.currentCameraPositionIndex = (this.currentCameraPositionIndex - 1) < 0
      ? this._cameraPositions.length - 1
      : this.currentCameraPositionIndex - 1
    this._zoomToObjects(this._cameraPositions[this.currentCameraPositionIndex], 0)
  }

  activate () {
    const combinations = []

    // save prev
    this.prevPosition = this.camera.position.clone()
    this.prevTarget = this.cameraManager.controls.target

    this.scene.children.forEach(child => {
      // handle combinations seperatly
      if (child.userData.isCombination) {
        combinations.push(child)
        return
      }
      // keep lights and the floor
      if (child.isLight || child.name === 'floor') {
        return
      }

      this.visibilityList.push({ visible: child.visible, object: child })
      child.visible = false
    })

    combinations.forEach(comb => {
      comb.traverse(o => {
        if (o.userData.isModelRoot) {
          if (!this._isSelected(o)) {
            this.visibilityList.push({ visible: o.visible, object: o })
            o.visible = false
          }
        }
      })
    })

    // variables used to place camera to approx good angles for render
    const panOffsetRatio = 0.28
    const heightOffsetRatio = 0.06

    const rootModels = Object.values(this.picker.selection)
      .map(selection => {
        return this.utils.findRootNode(selection)
      })

    const uniqueRootModels = [...new Set(rootModels)]
    const bb = this.utils.getBoundingBox(uniqueRootModels)
    const center = bb.getCenter(this._center)
    const width = bb.max.x - bb.min.x
    const height = bb.max.y - bb.min.y
    const length = bb.max.z - bb.min.z

    // Use the longest side to calc position, handling thin long objects.
    // Change to width and height to use actual bb bounds
    const longest = width > length ? width : length
    const _width = longest
    const _length = longest

    this._cameraPositions[0].set(center.x + _width * panOffsetRatio, center.y + height * heightOffsetRatio, center.z + _length / 2)
    this._cameraPositions[1].set(center.x - _width * panOffsetRatio, center.y + height * heightOffsetRatio, center.z + _length / 2)
    this._cameraPositions[2].set(center.x - _width * panOffsetRatio, center.y + height * heightOffsetRatio, center.z - _length / 2)
    this._cameraPositions[3].set(center.x + _width * panOffsetRatio, center.y + height * heightOffsetRatio, center.z - _length / 2)
    this.bb = bb

    const closestCameraPosition = this._getClosestPoint(this.camera.position, this._cameraPositions)
    this.currentCameraPositionIndex = this._cameraPositions.indexOf(closestCameraPosition)
    this._zoomToObjects(this._cameraPositions[this.currentCameraPositionIndex])
    this.enabled = true
  }
}

export default VariantManager
