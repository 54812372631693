import store from '../../stores'

import * as fromPatterns from '../../stores/ducks/patterns/json'
import * as fromProjectView from '../../stores/ducks/interface/project-view'

export default function handlePatterns (msg) {
  const { doc } = msg
  if (!doc) return

  const { removedAt } = doc

  const state = store.getState()
  // Update to a pattern differs from combinations as it comes in 4 batches instead of just 1. The final event contains a manifest key
  // This is used to determine, compared to previous updates, if the pattern is new.
  const pattern = state.patterns.json.entries[doc.id]
  const isPatternNew = Boolean((pattern && !pattern.manifest) && doc.manifest)

  store.dispatch(fromPatterns.receive(doc))

  // do not listen to remove events or events that modifies existing patters
  if (doc.projects && !removedAt && isPatternNew) {
    doc.projects.forEach(projectId => {
      store.dispatch(fromProjectView.receivePattern({ projectId, id: doc.id }))
    })
  }
}
