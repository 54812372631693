import React, { Component, PureComponent } from 'react'
import PropTypes from 'prop-types'
import autoBind from 'react-autobind'

import ReactModal from 'react-modal'
import colors from '../../../css/colors'
import IconCross from './icons/icon-cross'
import _omit from 'lodash/omit'

import css from './modal.css'

const assign = Object.assign

class ReactModalWithoutUnmountError extends ReactModal {
  componentWillUnmount () {
    setTimeout(() => {
      // This removes error when using confirm in modal to navigate from view
      // Error: Uncaught Error: React DOM tree root should always have a node reference.
      super.componentWillUnmount()
    }, 50)
  }
}

class Modal extends Component {
  constructor (props, context) {
    super(props, context)
    autoBind(this)
  }

  componentDidUpdate () {
    if (this.props.isOpen) {
      document.body.classList.add('ReactModal__Body--open')
    }
  }

  render () {
    const props = this.props

    return (
      <ReactModalWithoutUnmountError
        {...props}
        ariaHideApp={false}
        className={`${css.modal} ${props.className}`}
        overlayClassName={`${css.overlay} z3`}
      >
        <div
          className={`${css.inner} bg-white fixed ${props.innerCss}`}
          style={assign({}, {
            padding: props.noPadding ? 0 : '2rem',
            width: props.width,
            height: props.height
          }, props.innerStyle)}
          data-testid='common-modal'
        >
          {props.shouldCloseOnOverlayClick && (
            <IconCross
              data-testid='common-close-modal'
              color={colors.black}
              size={'1em'}
              className='scale pointer absolute z1 mx3 my3 top-0 right-0'
              onClick={props.onRequestClose}
            ></IconCross>
          )}
          {props.children}
        </div>
      </ReactModalWithoutUnmountError>
    )
  }
}

Modal.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  background: PropTypes.string,
  className: PropTypes.string,
  onAfterOpen: PropTypes.func,
  onRequestClose: PropTypes.func.isRequired,
  closeTimeoutMS: PropTypes.number,
  shouldCloseOnOverlayClick: PropTypes.bool,
  noPadding: PropTypes.bool
}

Modal.defaultProps = {
  width: '50%',
  height: 'initial',
  background: colors.white,
  className: '',
  innerCss: '',
  innerStyle: {},
  closeTimeoutMS: 0,
  // to improve a11y, required in ReactModal
  contentLabel: 'Modal',
  // Prop used by ReactModal, already set to true by default. Set to false if you want to force the user to perform an action in modal.
  shouldCloseOnOverlayClick: true
}

export default Modal

const _modalProps = {
  small: {
    width: 600
  },
  big: {
    width: 1200,
    height: '90vh',
    innerStyle: { padding: 0, zIndex: 1 }
  },
  fullSize: {
    width: 2200,
    height: '90vh',
    innerStyle: { padding: 0, zIndex: 1 }
  }
}

export class ModalButton extends PureComponent {
  constructor (props) {
    super(props)

    autoBind(this)
    this.state = { isModalOpen: false }
  }

  handleOpenModal () {
    this.setState({ isModalOpen: true })
  }

  handleCloseModal () {
    this.setState({ isModalOpen: false })
    this.props.onModalClose && this.props.onModalClose()
  }

  render () {
    const {
      small,
      fullSize,
      modalContent,
      button,
      modalProps,
      ...rest
    } = this.props
    const { isModalOpen } = this.state
    let modalSize = 'big'
    if (small) { modalSize = 'small' }
    if (fullSize) { modalSize = 'fullSize' }
    const props = {
      ...(_modalProps[modalSize]),
      ...(modalProps)
    }

    return (
      <div {..._omit(rest, 'onModalClose')}>
        {button(this.handleOpenModal)}
        <Modal
          isOpen={isModalOpen}
          onRequestClose={this.handleCloseModal}
          {...props}
        >
          {modalContent(this.handleCloseModal)}
        </Modal>
      </div>
    )
  }
}

ModalButton.propTypes = {
  button: PropTypes.func.isRequired,
  modalContent: PropTypes.func.isRequired,
  modalProps: PropTypes.object,
  onModalClose: PropTypes.func
}
