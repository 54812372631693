/* eslint-disable */
/**
 * THREE.GLTFXLoader created by rapidimages
 *
 * Based on GLTFLoader.js from three.js (R115)
 * @author Rich Tibbett / https://github.com/richtr
 * @author mrdoob / http://mrdoob.com/
 * @author Tony Parisi / http://www.tonyparisi.com/
 * @author Takahiro / https://github.com/takahirox
 * @author Don McCurdy / https://www.donmccurdy.com
 */

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { LegacyGLTFLoader } from '../gltf1/index.js'

import * as THREE from 'three'

var GLTFXLoader = ( function () {

	var EXTENSIONS = {
		KHR_BINARY_GLTF: 'KHR_binary_glTF',
		KHR_DRACO_MESH_COMPRESSION: 'KHR_draco_mesh_compression',
		KHR_LIGHTS_PUNCTUAL: 'KHR_lights_punctual',
		KHR_MATERIALS_CLEARCOAT: 'KHR_materials_clearcoat',
		KHR_MATERIALS_PBR_SPECULAR_GLOSSINESS: 'KHR_materials_pbrSpecularGlossiness',
		KHR_MATERIALS_UNLIT: 'KHR_materials_unlit',
		KHR_TEXTURE_TRANSFORM: 'KHR_texture_transform',
		KHR_MESH_QUANTIZATION: 'KHR_mesh_quantization',
		MSFT_TEXTURE_DDS: 'MSFT_texture_dds'
	};

    function GLTFXLoader( manager, dracoLoader = undefined ) {
        this.manager = ( manager !== undefined ) ? manager : THREE.DefaultLoadingManager
        this.dracoLoader = dracoLoader
    }

    GLTFXLoader.prototype = {

		constructor: GLTFXLoader,

		crossOrigin: 'Anonymous',

        load: function ( url, onLoad, onProgress, onError ) {

            var scope = this;

            var path = this.path && ( typeof this.path === "string" ) ? this.path : THREE.LoaderUtils.extractUrlBase( url );

            var loader = new THREE.FileLoader( scope.manager );

            loader.setResponseType( 'arraybuffer' );

            loader.load( url, function ( data ) {

                try {
                    var version = scope.parse( data, path, onLoad, onError );

                    switch (version[0]) {
                    case '0':
                    case '1':
                        this.loader = new LegacyGLTFLoader();
                        this.loader.parse(data, path, (result) => {
                            onLoad(result)
                        });
                        break;
                    case '2':
                        this.loader = new GLTFLoader();
                        if (scope.dracoLoader !== undefined) {
                            this.loader.setDRACOLoader(scope.dracoLoader)
                        }
                        this.loader.parse(data, path, (result) => {
                          result.scene.userData.uvFlipped = true
                          onLoad(result)
                        }, onError);
                        break;
                    default:
                        throw new Error( 'THREE.GLTFXLoader: Unknown version.' );
                    }

                } catch ( e ) {

                    if ( onError !== undefined ) {

                        onError( e );

                    } else {

                        throw e;

                    }

                }

            }, onProgress, onError );

        },

        parse: function ( data, path, onLoad, onError ) {

            var content;
            var extensions = {};

            if ( typeof data === 'string' ) {

                content = data;

            } else {

                var magic = THREE.LoaderUtils.decodeText( new Uint8Array( data, 0, 4 ) );

                if ( magic === BINARY_EXTENSION_HEADER_MAGIC ) {

                    try {

                        extensions[ EXTENSIONS.KHR_BINARY_GLTF ] = new GLTFBinaryExtension( data );

                    } catch ( error ) {

                        if ( onError ) onError( error );
                        return;

                    }

                    content = extensions[ EXTENSIONS.KHR_BINARY_GLTF ].content;

                } else {

                    content = THREE.LoaderUtils.decodeText( new Uint8Array( data ) );

                }

            }

            var json = JSON.parse( content );

            if ( json.asset !== undefined && json.asset.version !== undefined) {
                return json.asset.version;
            } else {
                return undefined;
            }
        }
    };

    /* BINARY EXTENSION */
	var BINARY_EXTENSION_HEADER_MAGIC = 'glTF';
	var BINARY_EXTENSION_HEADER_LENGTH = 12;
	var BINARY_EXTENSION_CHUNK_TYPES = { JSON: 0x4E4F534A, BIN: 0x004E4942 };

    function GLTFBinaryExtension( data ) {

		this.name = EXTENSIONS.KHR_BINARY_GLTF;
		this.content = null;
		this.body = null;

		var headerView = new DataView( data, 0, BINARY_EXTENSION_HEADER_LENGTH );

		this.header = {
			magic: THREE.LoaderUtils.decodeText( new Uint8Array( data.slice( 0, 4 ) ) ),
			version: headerView.getUint32( 4, true ),
			length: headerView.getUint32( 8, true )
		};

		if ( this.header.magic !== BINARY_EXTENSION_HEADER_MAGIC ) {

			throw new Error( 'THREE.GLTFLoader: Unsupported glTF-Binary header.' );

		} else if ( this.header.version < 2.0 ) {

			throw new Error( 'THREE.GLTFLoader: Legacy binary file detected. Use LegacyGLTFLoader instead.' );

		}

		var chunkView = new DataView( data, BINARY_EXTENSION_HEADER_LENGTH );
		var chunkIndex = 0;

		while ( chunkIndex < chunkView.byteLength ) {

			var chunkLength = chunkView.getUint32( chunkIndex, true );
			chunkIndex += 4;

			var chunkType = chunkView.getUint32( chunkIndex, true );
			chunkIndex += 4;

			if ( chunkType === BINARY_EXTENSION_CHUNK_TYPES.JSON ) {

				var contentArray = new Uint8Array( data, BINARY_EXTENSION_HEADER_LENGTH + chunkIndex, chunkLength );
				this.content = THREE.LoaderUtils.decodeText( contentArray );

			} else if ( chunkType === BINARY_EXTENSION_CHUNK_TYPES.BIN ) {

				var byteOffset = BINARY_EXTENSION_HEADER_LENGTH + chunkIndex;
				this.body = data.slice( byteOffset, byteOffset + chunkLength );

			}

			// Clients must ignore chunks with unknown types.

			chunkIndex += chunkLength;

		}

		if ( this.content === null ) {

			throw new Error( 'THREE.GLTFLoader: JSON content not found.' );

		}

	}

    return GLTFXLoader;

} )();

export { GLTFXLoader };
