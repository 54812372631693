import React from 'react'
import PropTypes from 'prop-types'
import cs from 'classnames'

import PanelButton from './panel-button'
import PanelContent from './panel-content'
import Scrollable from '../scrollable'

function PanelContainer (props) {
  const [width, setWidth] = React.useState(props.defaultWidth)
  const [activeIndex, setActiveIndex] = React.useState(null)

  React.useEffect(() => {
    setActiveIndex(props.defaultIndex)
  }, [props.defaultIndex])

  function handleUpdateWidth (delta) {
    const nextWidth = Math.max(props.minWidth, Math.min(props.maxWidth, width + delta))
    if (nextWidth !== width) {
      setWidth(nextWidth)
    }
  }

  function toggleActiveIndex (nextIndex) {
    setActiveIndex(activeIndex === nextIndex ? null : nextIndex)
  }

  return (
    <div
      className={cs('relative', props.className)}
      onClick={props.onClick}
      style={{
        borderRight: props.position === 'left' ? '1px solid #E5E5E5' : 'none',
        borderLeft: props.position === 'right' ? '1px solid #E5E5E5' : 'none'
      }}
    >
      <Scrollable>
        <div className={'height-100 bg-gray-light flex flex-column justify-between'}>
          <div className={'border-bottom bc-black-10'}>
            {props.tabs.map((tab, index) => (
              <PanelButton
                key={index}
                disabled={tab.disabled}
                position={props.position}
                active={activeIndex === index && !tab.disabled}
                onClick={() => toggleActiveIndex(index)}
                title={tab.title}
                icon={tab.icon}
              />
            ))}
          </div>
          <div>
            {props.children}
          </div>
        </div>
      </Scrollable>

      {props.tabs.map((tab, index) => (
        <PanelContent
          key={index}
          position={props.position}
          onWidthChange={handleUpdateWidth}
          width={width}
          minWidth={props.minWidth}
          maxWidth={props.maxWidth}
          active={activeIndex === index && !tab.disabled}
          grid={tab.grid}
          keepChildrenMounted={tab.keepMounted}
          onBeforeNavigate={props.onBeforeNavigate}
        >
          {tab.content}
        </PanelContent>
      ))}
    </div>
  )
}

PanelContainer.defaultProps = {
  tabs: []
}

PanelContainer.propTypes = {
  position: PropTypes.oneOf(['left', 'right']).isRequired,
  tabs: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    icon: PropTypes.element,
    content: PropTypes.element
  }))
}

export default PanelContainer
