import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import Modal from '../../../../common/modal'
import Button from '../../../../common/button'
import InputGroup from '../../../../common/form/input-group'
import Label from '../../../../common/form/label'
import InputSearch from '../../../../common/form/input-search'
import Grid from '../../../../common/grid/grid'
import Scrollable from '../../../../common/scrollable'
import KeyboardListener, { KeyCode, KeyBinding } from '../../../../common/keyboard-listener'

import BocList from './BocList'
import BocSearchResultList from './BocSearchResultList'
import type { BocListItem } from './BocList'
import { setMaterialFromPartId } from '../../../../../stores/ducks/materials'

import * as fromThreeviewerUi from '../../../../../stores/ducks/threeviewer/ui'

type Props = {
  isOpen: boolean
  onClose: () => void
}

const BocModal = ({ isOpen, onClose } : Props) => {
  const dispatch = useDispatch()

  const [searchText, setSearchText] = useState('')
  const [_searchText, _setSearchText] = useState('')

  const [selectedBocPart, setSelectedBocPart] = useState<null | string >(null)
  const [triggerSearch, setTriggerSearch] = useState(false)

  const [bocListLoading, setBocListLoading] = useState(false)
  const [bocList, setBocList] = useState<null | BocListItem[] >(null)

  const [errorToShort, setErrorToShort] = useState(false)

  const setKeyBoardBindings = (value:boolean) => dispatch(fromThreeviewerUi.toggleKeyBoardBindings(value))

  useEffect(() => {
    if (isOpen) setKeyBoardBindings(false)
    return () => {
      setKeyBoardBindings(true)
    }
  }, [isOpen])

  const preClose = () => {
    setSearchText('')
    _setSearchText('')
    setSelectedBocPart(null)
    onClose()
  }

  const handleSearch = () => {
    if (_searchText.length < 4) {
      setErrorToShort(true)
      return
    }
    setErrorToShort(false)
    setSearchText(_searchText)
    setSelectedBocPart(null)
    setTriggerSearch(true)
  }

  const applyAppearances = () => {
    dispatch(setMaterialFromPartId(bocList?.filter(item => item.icomId)) ?? [])
    preClose()
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={preClose}
      shouldCloseOnOverlayClick={true}
      width={'80vw'}
      height={'80vh'}
    >
      <div className='width-100 height-100'>
        <Grid rows={['auto', 'auto', '1fr', 'auto']} className='mb2' style={{ height: '100%' }}>
          <h2 className='mt0'>
            Appearance from PLM
          </h2>
          <InputGroup>
            <Label>
              <span>Search BOC part</span>
            </Label>
            <div className='flex'>
              <InputSearch
                onChange={(input: string) => _setSearchText(input.length > 0 ? input : '')}
                onClearSearch={() => _setSearchText('')}
                value={_searchText}
                placeholder={'Enter BOC id or search product'}
                className='mr1'
                fullHeight
                fullWidth
                focus
              />
              <Button
                className='flex'
                onClick={handleSearch}
                disabled={bocListLoading || triggerSearch}
              >Search</Button>
            </div>
            <KeyboardListener
              disabled={bocListLoading || triggerSearch}
              keyboardEvent='keyup'
              bindings={[
                KeyBinding(KeyCode.enter, handleSearch)
              ]}
            />
            <Label>
              <span className='c-error regular' style={{ visibility: errorToShort ? 'visible' : 'hidden' }}>Search has to be at least 4 characters long</span>
            </Label>
          </InputGroup>

          <Grid gridGap={24} columns={['1fr', '2fr']} className='mb2' style={{ height: '100%', width: '100%' }}>
            <Scrollable innerClassName="bg-gray-light" overflow='hidden'>
              <BocSearchResultList
                searchText={searchText}
                onClick={(id:string) => setSelectedBocPart(id) }
                waitForCallback={bocListLoading}
                triggerSearch={triggerSearch}
                setTriggerSearch={setTriggerSearch}
              />
            </Scrollable>
            <Scrollable innerClassName="bg-gray-light" overflow='hidden'>
              <BocList bocId={selectedBocPart} setBocListLoading={setBocListLoading} setBocList={setBocList} clearSelectedBocPart={() => setSelectedBocPart(null)}/>
            </Scrollable>
          </Grid>

          <div className='flex justify-end items-end' >
            <Button onClick={preClose} style={{ marginTop: 'auto' }}>
            Cancel
            </Button>
            <Button
              btnType='primary'
              className='ml2'
              style={{ marginTop: 'auto' }}
              onClick={applyAppearances}
              disabled={!selectedBocPart || bocListLoading}
            >
            Apply BOC list
            </Button>
          </div>
        </Grid>
      </div>
    </Modal>
  )
}

export default BocModal
