var _ = require('lodash')

var ObjectTracker = function () {
  this.interactions = {}
}

ObjectTracker.prototype.addObject = function (obj, interactions) {
  Object.keys(interactions).forEach((type) => {
    if (!interactions[type]) return
    var prop = _.get(interactions, [type, 'property'], 'uuid')

    if (!this.interactions[type]) {
      this.interactions[type] = {}
    }

    if (interactions[type].recursive) {
      obj.traverse((child) => {
        this.interactions[type][child[prop]] = child
      })
    } else {
      this.interactions[type][obj[prop]] = obj
    }
  })
}

ObjectTracker.prototype.removeObject = function (object) {
  Object.keys(this.interactions).forEach((type) => {
    const interaction = this.interactions[type]
    object.traverse((child) => {
      delete interaction[child.uuid]
    })
  })
}

ObjectTracker.prototype.returnObjectsOfType = function (type) {
  return _.map(_.get(this, ['interactions', type], {}))
}

ObjectTracker.prototype.getObject = function (type, prop) {
  return _.get(this, ['interactions', type, prop])
}

ObjectTracker.prototype.typeContainsObjects = function (obj, type) {
  if (!Array.isArray(obj)) {
    obj = [obj]
  }

  return _(obj).every((val) => {
    return _.get(this, ['interactions', type, val.uuid], false)
  })
}

ObjectTracker.prototype.dispose = function () {
  this.interactions = {}
}

module.exports = new ObjectTracker()
