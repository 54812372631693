import { createWall, setObjectOutlineError } from './helpers'
import { Object3D, Vector2, Vector3, Box3, Line3, MathUtils } from 'three'
import { Hole, HoleDescription } from './WallHoles'
import { WallNodes } from './WallNodes'

const wallObject = createWall()

export const WALL_THICKNESS = 0.3349232375621799
const PADDING = 0.1

function getPoint (start: Vector2, direction: Vector2, offset: number, length: number) {
  return start.clone().add(direction.clone().multiplyScalar((offset / length) * length))
}

export type WallId = string

export class Wall {
  holes = new Set<Hole>()

  public from: number
  public to: number
  public id: WallId
  public length = 0
  public object = wallObject.clone()
  public direction = new Vector2()
  public rotation = 0

  private v0a = new Vector3()
  private v1a = new Vector3()
  private v0b = new Vector2()
  private v1b = new Vector2()

  base = 0
  height: number
  thickness = WALL_THICKNESS

  private center = new Vector3()
  private wallPoint = new Vector3()
  private line = new Line3()

  worldBoundingBox = new Box3()
  isHoleOffsetValid = true

  constructor (from: number, to: number, id: WallId, height: number) {
    this.from = from
    this.height = height
    this.to = to
    this.id = id
  }

  set (v0: Vector2, v1: Vector2) {
    const wallDelta = v1.clone().sub(v0)
    const direction = wallDelta.clone().normalize()
    const wallLength = wallDelta.length()
    const center = getPoint(v0, direction, wallLength * 0.5, wallLength)
    const rotateZ = -Math.atan2(direction.y, direction.x)

    const object = this.object
    object.scale.set(wallLength, object.scale.y, object.scale.z)
    object.position.set(center.x, 0, center.y)
    object.rotation.z = rotateZ
    object.updateMatrixWorld()

    this.worldBoundingBox.copy(object.userData.boundingBox).applyMatrix4(object.matrixWorld)

    this.v0a.set(v0.x, 0, v0.y)
    this.v1a.set(v1.x, 0, v1.y)
    this.v0b.copy(v0)
    this.v1b.copy(v1)

    this.line.set(this.v0a, this.v1a)
    this.length = v1.distanceTo(v0)

    this.direction.copy(direction)
    this.rotation = -Math.atan2(direction.y, direction.x)

    const length = this.length

    this.holes.forEach(hole => {
      const center = getPoint(this.v0b, this.direction, hole.position.x + hole.size.x * 0.5, this.length)
      hole.set(hole.position.x, this.center.set(center.x, 0, center.y), this.rotation)

      if (
        (hole.position.x + hole.size.x > length - WALL_THICKNESS - PADDING * 2) ||
        (hole.position.x < WALL_THICKNESS + PADDING * 2)
      ) {
        hole.visible = false
      } else {
        hole.visible = true
      }
    })
  }

  addHoleFromDescription (description: HoleDescription, offset?: number) {
    const validOffset = this.getValidHolePositionX(offset || description.position.x, description.size.x)
    if (this.isHoleOffsetValid) {
      const hole = new Hole(description, this.id)
      this.holes.add(hole)
      hole.wallId = this.id
      this.setHole(hole, validOffset)
      return hole
    }
    return false
  }

  addHoleFromSchema (description: HoleDescription) {
    // this.getValidHolePositionX(description.position.x, description.size.x)

    // if (this.isHoleOffsetValid) {
    const hole = new Hole(description, this.id)
    this.holes.add(hole)
    hole.wallId = this.id
    this.setHole(hole, description.position.x)
    return hole
    // }
    // return false
  }

  addHole (hole: Hole) {
    this.holes.add(hole)
    hole.wallId = this.id
    this.setHole(hole, hole.position.x)
  }

  setHole (hole: Hole, offset: number) {
    const center = getPoint(this.v0b, this.direction, offset + hole.size.x * 0.5, this.length)
    hole.set(offset, this.center.set(center.x, 0, center.y), this.rotation)
  }

  removeHole (hole: Hole) {
    this.holes.delete(hole)
  }

  getValidHolePositionX (offsetFromStart: number, width: number, holeId?: number) {
    let x = offsetFromStart - (width * 0.5)
    const length = this.length
    this.isHoleOffsetValid = true

    // Hole is wider than allowed space wall
    if (width > length - WALL_THICKNESS - PADDING * 2) {
      this.isHoleOffsetValid = false
      return MathUtils.clamp(x, 0, length - width)
    }

    // Hole is placed before allowed start of wall
    if (x < WALL_THICKNESS + PADDING * 2) {
      this.isHoleOffsetValid = false
      return MathUtils.clamp(x, 0, length - width)
    }

    // Hole is placed after allowed end of wall
    if (x > length - WALL_THICKNESS - PADDING * 2 - width) {
      this.isHoleOffsetValid = false
      return MathUtils.clamp(x, 0, length - width)
    }

    // Clamp to wall
    x = MathUtils.clamp(
      x,
      WALL_THICKNESS + PADDING * 2,
      length - WALL_THICKNESS - PADDING * 2 - width
    )

    // Find closest free placement
    const bb: [number, number] = [x - PADDING, x + width + PADDING]

    let isIntersectingOtherHoles = false

    const holes = Array.from(this.holes)

    for (let index = 0; index < holes.length; index++) {
      const a = holes[index]

      if (a.id === holeId) continue

      if (intersectsOnLine([a.position.x - PADDING, a.position.x + a.size.x + PADDING], bb)) {
        isIntersectingOtherHoles = true
        break
      }

      if (x === a.position.x) {
        isIntersectingOtherHoles = true
        break
      }
    }

    if (isIntersectingOtherHoles) {
      this.isHoleOffsetValid = false
    }

    return x
  }

  offsetOnWall (point: Vector3) {
    return this.length * this.line.closestPointToPointParameter(point, false)
  }

  pointOnWall (point: Vector3) {
    this.line.closestPointToPoint(point, false, this.wallPoint)
    return new Vector2(this.wallPoint.x, this.wallPoint.z)
  }
}

export class Walls {
  private id = 0;
  private walls: Wall[] = [];
  private _objectToWall = new Map<Object3D, Wall>();
  private _pointIndexToWalls = new Map<number, Wall[]>();
  private _byId = new Map<WallId, Wall>();

  values () {
    return this.walls
  }

  sort () {
    this.walls.sort((a, b) => (a.from - b.from))
  }

  update (points: WallNodes) {
    this.walls.forEach(wall => {
      const from = points.at(wall.from)
      const to = points.at(wall.to)
      if (!from || !to) {
        console.warn('Missing to or from wall nodes.', wall.id, { from, to })
      } else {
        wall.set(from, to)
      }
    })
  }

  updateWallHeight (wallHeight: number) {
    this.walls.forEach((wall) => {
      wall.height = wallHeight
    })
  }

  clear () {
    this.walls = []
    this.id = 0
    this._objectToWall.clear()
    this._pointIndexToWalls.clear()
    this._byId.clear()
  }

  byId (id: WallId) {
    return this._byId.get(id)
  }

  register (wall: Wall) {
    this._objectToWall.set(wall.object, wall)
    this._pointIndexToWalls.set(wall.from, (this._pointIndexToWalls.get(wall.from) || []).concat(wall))
    this._pointIndexToWalls.set(wall.to, (this._pointIndexToWalls.get(wall.to) || []).concat(wall))
    this._byId.set(wall.id, wall)

    this.walls.push(wall)
  }

  deregister (wall: Wall) {
    this._objectToWall.delete(wall.object)
    this._pointIndexToWalls.set(wall.from, (this._pointIndexToWalls.get(wall.from) || []).filter(w => w !== wall))
    this._pointIndexToWalls.set(wall.to, (this._pointIndexToWalls.get(wall.to) || []).filter(w => w !== wall))
    this._byId.delete(wall.id)
    this.walls = this.walls.filter(w => w !== wall)
  }

  moveHole (hole: Hole, point: Vector3, from: WallId | null, to: WallId | null, confirmMove: boolean) {
    const fromWall = from !== null && this.byId(from)
    const toWall = to !== null && this.byId(to)

    setObjectOutlineError(hole.object, false)

    if (fromWall && from !== to) fromWall.removeHole(hole)

    const wall = toWall || fromWall

    if (confirmMove) {
      const positionX = hole.getLastValidPositionX()
      const lastValidWallId = hole.getLastValidWallId()
      const lastValidWall = this.byId(lastValidWallId || '')
      if (positionX && lastValidWall) {
        lastValidWall.setHole(hole, positionX)
        lastValidWall.addHole(hole)
      } else {
        hole.visible = false
        if (fromWall) fromWall.removeHole(hole)
        console.warn('Hole has no valid position.')
      }
      return
    }

    if (wall) {
      const offsetOnWall = wall.offsetOnWall(point)
      const positionX = wall.getValidHolePositionX(offsetOnWall, hole.size.x, hole.id)
      if (wall.isHoleOffsetValid) {
        hole.setLastValidPositionX(positionX)
        hole.setLastValidWallId(wall.id)
        wall.setHole(hole, positionX)
        if (from !== to && toWall) toWall.addHole(hole)
      } else {
        setObjectOutlineError(hole.object, true)
        wall.setHole(hole, positionX)
      }
    } else {
      console.warn('When will this happen?')
    }
  }

  add (from: number, to: number, height: number) {
    const wall = new Wall(from, to, `wall_${this.id++}`, height)
    this.register(wall)
    return wall
  }

  split (wall: Wall, point: Vector2, from: number, to: number) {
    const newWall = this.add(from, to, wall.height)
    const offsetOnWall = wall.offsetOnWall(new Vector3(point.x, 0, point.y))

    wall.holes.forEach(hole => {
      if (hole.position.x > offsetOnWall) {
        wall.removeHole(hole)
        newWall.addHole(hole)
        hole.position.x = hole.position.x - offsetOnWall
      }
    })

    return newWall
  }

  move (wall: Wall, oldIndices: [number, number], newIndices: [number, number]) {
    wall.from = newIndices[0]
    wall.to = newIndices[1]

    oldIndices.forEach(index => {
      this._pointIndexToWalls.set(index, (this._pointIndexToWalls.get(index) || []).filter(w => w !== wall))
    })

    newIndices.forEach(index => {
      this._pointIndexToWalls.set(index, (this._pointIndexToWalls.get(index) || []).concat(wall))
    })
  }

  objectToWall (object: Object3D) {
    return this._objectToWall.get(object)
  }

  findClosestWallToPoint (point: Vector3) {
    let minDistance: null | number = null
    let closest: Wall | null = null

    const len = this.walls.length
    for (let index = 0; index < len; index++) {
      const wall = this.walls[index]
      const distance = wall.worldBoundingBox.distanceToPoint(point)
      if (minDistance === null || distance < minDistance) {
        minDistance = distance
        closest = wall
      }
    }
    return closest
  }
}

function intersectsOnLine (a: [number, number], b: [number, number]) {
  return (
    (b[0] >= a[0] && b[0] <= a[1]) ||
    (b[1] >= a[0] && b[1] <= a[1])
  )
}
