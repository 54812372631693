import * as React from 'react'
import Button from '../common/button'

export default function ErrorInfo (props) {
  let message = (<p>An unexpected error occured.</p>)
  if (!props.sceneError) {
    message = (
      <p>Failed to load {props.errorFileCount} {props.errorFileCount === 1 ? 'geometry' : 'geometries'} - Please contact support and share the URL (webaddress) of your project.</p>)
  }

  return (
    <React.Fragment>
      <h2 className='mt0'>Oops!</h2>
      <div className='mb3' style={{ userSelect: 'text' }}>
        {message}
        <div className='flex flex-row flex-justify'>
          <Button
            onClick={props.goBack}
            btnType='primary'
            pad={2}
            className='mx-auto'
          >
            Leave
          </Button>
          <Button
            onClick={props.continue}
            btnType='secondary'
            pad={2}
            className='mx-auto'
          >
            Continue anyway
          </Button>
        </div>
      </div>
    </React.Fragment>
  )
}
