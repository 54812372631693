import React from 'react'

import IconUndo from './icon-undo'

const IconRedo = props => (
  <IconUndo transform='scale(-1, 1)' {...props} />
)

IconRedo.defaultProps = {
  color: 'currentcolor'
}

export default IconRedo
