import * as THREE from 'three'
import { xor, isEmpty } from 'lodash'

const DEFAULT_EPSILON = 0.00001

export const approxEquality = (a: number, b: number, epsilon: number = DEFAULT_EPSILON): boolean => {
  return Math.abs(a - b) <= epsilon
}

export const arrayContentEqual = (array1: any[], array2: any[]): boolean => {
  return isEmpty(xor(array1, array2))
}

export const approxEqualityVector3 = (v1: THREE.Vector3, v2: THREE.Vector3, epsilon: number = DEFAULT_EPSILON): boolean => {
  return (
    approxEquality(v1.x, v2.x, epsilon) &&
    approxEquality(v1.y, v2.y, epsilon) &&
    approxEquality(v1.z, v2.z, epsilon)
  )
}

/**
 * Compares two quaternion orientations. NOTE: The rotation of the quaternion can still differ.
 *
 * Based on this comment: https://gamedev.stackexchange.com/a/75108
 */
export const approxEqualityQuaternion = (q1: THREE.Quaternion, q2: THREE.Quaternion, epsilon: number = DEFAULT_EPSILON) : boolean => {
  return Math.abs(q1.dot(q2)) > (1 - epsilon)
}
