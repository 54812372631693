import React, { useState, useEffect } from 'react'
import * as fromColorsHelpers from '../../../../stores/ducks/colors/helpers'
import Button from '../../../common/button'
import ColorCard from '../../../common/color-card'
import InputSearch from '../../../common/form/input-search'
import AutoFill from '../../../common/grid/AutoFill'
import Modal from '../../../common/modal'
import Pill from '../../../common/Pill'
import { StarSelectIcon, Wrapper } from './Color'
import type { SearchableColor } from '../../../project/colors/SearchableColor'

interface Props {
  isOpen: boolean
  onRequestClose: () => void
  colors: any []
  onApply: (id: string) => void
  onSelectColor: (id: string) => void
  markedColorIds: string[]
  onFavoriteClick: (id: string) => void
}

const ColorSearchModal = (props: Props) => {
  const [selected, setSelected] = useState(false)
  const [colorSearchString, setColorSearchString] = useState('')
  const [colorsPage, setColorsPage] = useState(1)
  const [colorsArray, setColorsArray] = useState<SearchableColor[] | undefined>(undefined)
  const [pages, setPages] = useState(1)

  const ITEMS_PER_PAGE = window.innerWidth > 1920 ? 100 : 80

  useEffect(() => {
    const sortedSearch = props.colors.filter(color => fromColorsHelpers.isColorMatchingSearchString(color, colorSearchString))
    setPages(Math.ceil(sortedSearch.length / ITEMS_PER_PAGE))
    const page = pages < colorsPage ? 1 : colorsPage
    pagination(page, sortedSearch, ITEMS_PER_PAGE)
  }, [props.colors, colorsPage, colorSearchString])

  function pagination (page:number, items:any, imagesPerPage:number) {
    setColorsPage(page)
    setColorsArray(items.slice((page - 1) * imagesPerPage, (page * imagesPerPage)))
  }

  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      width={1200}
    >
      <h2 className='mt0'>Search color</h2>
      <InputSearch
        placeholder='Search...'
        onChange={(input: string) => setColorSearchString(input)}
        onClearSearch={() => setColorSearchString('') }
        value={colorSearchString}
      />
      <div
        style={{ height: '50vh ' }}
        className='py2 bc-gray-light overflow-hidden'
      >
        <div className='overflow-auto height-100'>
          <AutoFill width={180}>
            {colorsArray && colorsArray.map(color => (
              <Wrapper key={color.id}>
                <ColorCard
                  {...color}
                  onClick={() => {
                    props.onApply(color.id)
                    props.onRequestClose()
                    props.onSelectColor(color.id)
                  }}
                  isPrototypeFabricColor={color.isPrototypeFabricColor}
                />
                <StarSelectIcon
                  onClick={() => {
                    props.onFavoriteClick(color.id)
                    setSelected(!selected)
                  }}
                  selected={!!props.markedColorIds.includes(color.id)}/>
              </Wrapper>
            ))}
          </AutoFill>

          {pages > 1 && (
            <div className="width-100 flex flex-wrap justify-center p2">
              {[...Array(pages)].map((unused, i) =>
                <Pill style={{ margin: '2px' }} key={i} active={colorsPage === i + 1} onClick={() => { setColorsPage(i + 1) }}>{i + 1}</Pill>
              )}
            </div>
          )}
        </div>
      </div>
      <div className='flex justify-end'>
        <Button
          onClick={props.onRequestClose}
        >Cancel</Button>
      </div>
    </Modal>
  )
}

export default ColorSearchModal
