import React from 'react'
import Avatar from '../common/avatar'

function User (props) {
  return (
    <div className='flex items-center'>
      <Avatar user={props.user} />
    </div>
  )
}

export default User
