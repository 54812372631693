import _get from 'lodash/get'

const logErrorMiddleware = () => (next) => (action) => {
  const result = next(action)

  if (action.type.indexOf('ERROR') >= 0) {
    const errorObject = action.data || action.err || (_get(action, 'payload.error', action.payload || action))
    const error = new Error(JSON.stringify(errorObject))
    console.error(action.type, error)
  }

  return result
}

export default logErrorMiddleware
