import Immutable from 'seamless-immutable'
import * as THREE from 'three'

import _uniq from 'lodash/uniq'
import _difference from 'lodash/difference'
import _map from 'lodash/map'
import _isEqual from 'lodash/isEqual'
import _xor from 'lodash/xor'
import _isEmpty from 'lodash/isEmpty'

import * as commands from './commands'

import { getHidden, getLocalState, getSelection, getIsIsolationActive } from './selectors'

import * as fromThreeviewerUI from '../threeviewer/ui'
import * as fromThreeviewerSelectors from '../threeviewer/selectors'
import * as fromTreeSelectors from '../tree/selectors'
import * as fromVisualizerSelectors from '../../../components/visualizer/visualizer-selectors'

import {
  findChildUUIDs,
  findOwnParentUUIDs,
  fitObjectsInScreen,
  getCombinationNodes,
  hideNodes,
  showNodes
} from './utils'

import { addCommand as addCommandToUndoHistory } from '../undo-redo'

export const SELECT = 'selection/SELECT'
export const SHOW = 'selection/SHOW'
const TOGGLE_SELECTION = 'selection/TOGGLE_SELECTION'
const CLEAR_SELECTION = 'selection/CLEAR_SELECTION'
const CLEAR = 'selection/CLEAR'
const HIDE = 'selection/HIDE'
const IS_ISOLATION_ACTIVE = 'selection/IS_ISOLATION_ACTIVE'
const SELECT_FROM_UUIDS = 'selection/SELECT_FROM_UUIDS'
const DESELECT_AFTER_REMOVE = 'selection/DESELECT_AFTER_REMOVE'

// 3 Actions
export function clear () {
  return {
    type: CLEAR
  }
}

export function getVisibleNodes () {
  return (dispatch, getState) => {
    const state = getState()

    const { selection, hidden } = getLocalState(state)
    const nodeList = fromThreeviewerSelectors.getNodeList(state)
    const allSelectedUUIDs = findChildUUIDs(nodeList, selection)
    const allHiddenUUIDs = findChildUUIDs(nodeList, hidden)

    const visibleNodes = _difference(allSelectedUUIDs, allHiddenUUIDs).reduce(
      (acc, uuid) => {
        const node = nodeList[uuid]
        if (!node) return acc
        return node.geometry ? acc.concat(node) : acc
      },
      []
    )

    return visibleNodes
  }
}

// selection
export function setPickerSelection (dblClick) {
  return (dispatch, getState) => {
    const state = getState()

    const nodeList = fromThreeviewerSelectors.getNodeList(state)
    const viewer = fromThreeviewerSelectors.getViewer(state)
    const { transformGizmo, triplanarTool } = viewer
    const { selection, hidden } = getLocalState(state)

    if (!transformGizmo.control.dragging) {
      transformGizmo.detach()
    }

    const allSelectedUUIDs = findChildUUIDs(nodeList, selection)
    const allHiddenUUIDs = findChildUUIDs(nodeList, hidden)

    const visibleNodes = _difference(allSelectedUUIDs, allHiddenUUIDs).reduce(
      (acc, uuid) => {
        const node = nodeList[uuid]
        if (!node) return acc
        return node.geometry ? acc.concat(node) : acc
      },
      []
    )

    const combinationNodes = getCombinationNodes(visibleNodes)

    triplanarTool.select(combinationNodes[0])

    if (triplanarTool.enabled) {
      return
    }

    viewer.setSelection(visibleNodes)

    if (dblClick) {
      viewer.cameraManager._handlePickerDblClick(visibleNodes)
    } else {
      viewer.cameraManager._handlePickerSelect(visibleNodes)
    }

    if (combinationNodes.length) {
      transformGizmo.attach(combinationNodes, { attachToRoot: true })
    }

    transformGizmo.selectionUpdate()
  }
}

export function selectFromUuids (uuids, appendSelection = false, scrollToNode = true) {
  return (dispatch, getState) => {
    const oldSelection = getLocalState(getState()).selection

    dispatch({
      type: SELECT,
      uuids,
      appendSelection,
      scrollToNode
    })

    dispatch(fromThreeviewerUI.forceUpdate())

    const newSelection = getLocalState(getState()).selection
    const command = commands.createSelectCommand({
      oldValue: oldSelection,
      newValue: newSelection,
      select: (uuids) => {
        dispatch({ type: SELECT, uuids, scrollToNode })
        dispatch(setPickerSelection())
        dispatch(fromThreeviewerUI.forceUpdate())
      }
    })

    command.execute()
    dispatch(addCommandToUndoHistory(command))
    dispatch(setPickerSelection())
  }
}

export function doubleClickSelection (uuid, clearRedos = true, scrollToNode = true) {
  return (dispatch, getState) => {
    const oldSelection = getLocalState(getState()).selection
    dispatch({
      type: SELECT,
      uuids: [uuid],
      scrollToNode
    })
    const newSelection = getLocalState(getState()).selection

    const command = commands.createSelectCommand({
      oldValue: oldSelection,
      newValue: newSelection,
      select: (uuids) => {
        dispatch({ type: SELECT, uuids, scrollToNode })
        dispatch(setPickerSelection())
      }
    })

    dispatch(addCommandToUndoHistory(command, clearRedos))

    dispatch(setPickerSelection(true))
  }
}

export function toggleSelection (uuid, selectMany, clearRedos = true, scrollToNode = true) {
  return (dispatch, getState) => {
    const oldSelection = getLocalState(getState()).selection

    dispatch({
      type: TOGGLE_SELECTION,
      uuid,
      selectMany,
      scrollToNode
    })

    const newSelection = getLocalState(getState()).selection

    const command = commands.createSelectCommand({
      oldValue: oldSelection,
      newValue: newSelection,
      select: (uuids) => {
        dispatch({ type: SELECT, uuids, scrollToNode })
        dispatch(setPickerSelection())
        dispatch(fromThreeviewerUI.forceUpdate())
      }
    })

    dispatch(addCommandToUndoHistory(command, clearRedos))

    dispatch(setPickerSelection())
  }
}

export function deselectAfterRemove (node) {
  return (dispatch, getState) => {
    const viewer = fromThreeviewerSelectors.getViewer(getState())

    node.traverse((child) => {
      if (child.isMesh) {
        delete viewer.picker.selection[child.uuid]
        child.outline = false
      }
    })

    if (Object.keys(viewer.picker.selection).length === 0) {
      viewer.transformGizmo.detach()
    } else {
      viewer.transformGizmo.attach(Object.values(viewer.picker.selection), {
        attachToRoot: true
      })
    }

    viewer.renderOnNextFrame()

    dispatch({
      type: DESELECT_AFTER_REMOVE,
      uuid: node.uuid
    })
  }
}

export function select (nodes, addToUndoHistory = true, attachToGroup = false, scrollToNode = true) {
  return (dispatch, getState) => {
    const state = getState()
    const { transformGizmo } = fromThreeviewerSelectors.getViewer(state)

    // Save old selection before updating state
    const oldSelection = getLocalState(state).selection
    const combinationNodes = getCombinationNodes(nodes)

    const uuids = _map(nodes, 'uuid')

    // Do not update selection if nothing has changed
    if (_isEmpty(_xor(oldSelection, uuids))) return

    if (!combinationNodes.length) {
      transformGizmo.detach()
    } else {
      if (attachToGroup) {
        transformGizmo.attachToGroup(combinationNodes, { attachToRoot: true })
      } else {
        transformGizmo.attach(combinationNodes, { attachToRoot: true })
      }
    }

    dispatch({
      type: SELECT,
      uuids,
      scrollToNode
    })

    dispatch(fromThreeviewerUI.forceUpdate())

    if (addToUndoHistory) {
      const command = commands.createSelectCommand({
        oldValue: oldSelection,
        newValue: uuids,
        select: (uuids) => {
          dispatch({ type: SELECT, uuids, scrollToNode })
          dispatch(setPickerSelection())
          dispatch(fromThreeviewerUI.forceUpdate())
        }
      })

      dispatch(addCommandToUndoHistory(command))
    } else {
      dispatch(setPickerSelection())
    }
  }
}

export function clearSelection () {
  return {
    type: CLEAR_SELECTION
  }
}

/**
 * Toggle isolation for given node uuids. Isolate is a temporary state which uses the hidden attributes on nodes. It stores the original hidden state to be able to
 * revert back to it when leaving isolate
 * Nodes isolated will be zoomed to fit the visualizer and when "leaving" the camera will be reverted to its previous state.
 * If selection contains invalid nodes, such as room set or template isolation wont trigger
 *
 * @param {string[]} [uuids] Uuids of nodes to toggle isolate on, or undefined if the current selection should be isolated
 */
export function toggleIsolation (uuids) {
  return (dispatch, getState) => {
    const state = getState()
    const viewer = fromThreeviewerSelectors.getViewer(state)

    // If no uuids are passed, use the current selection instead
    if (!uuids) {
      uuids = getSelection(state)

      // If no nodes are selected, disable isolation mode instead
      if (!uuids || !uuids.length) {
        dispatch(leaveIsolate())
        return
      }
    }

    // TODO do not isolate in certain camera modes!!
    const invalidSelection = Object.keys(viewer.picker.selection).some((key) => {
      return (
        viewer.picker.selection[key].userData.modelType === 'template' ||
        viewer.picker.selection[key].userData.modelType === 'roomset'
      )
    })
    if (invalidSelection) return

    const topNodes = state.tree.nodes
    const hidden = getLocalState(state).hidden
    const isIsolationActive = getLocalState(state).isIsolationActive

    // Check if note has children
    const flatNodes = fromTreeSelectors.getFlatNodes(getState())
    const nodesToIsolate = []
    uuids.forEach((uuid) => {
      const flatNode = flatNodes[uuid]
      if (flatNode) {
        nodesToIsolate.push(
          flatNode.uuid,
          ...flatNode.children,
          ...flatNode.ancestors
        )
      }
    })

    const allUuids = []
    for (const key of Object.keys(topNodes)) {
      allUuids.push(key, ...(flatNodes[key]?.children || []))
    }

    const nodesToHide = allUuids.filter(
      (uuid) => !nodesToIsolate.includes(uuid)
    )
    if (isIsolationActive && _isEqual(hidden, nodesToHide)) {
      // leave isolation if it is triggered on already shown and isolated nodes
      dispatch(leaveIsolate())
    } else {
      // start isolate or change isolate target
      dispatch(show(allUuids))
      dispatch(hide(nodesToHide))
      if (isIsolationActive) dispatch(setPickerSelection())
      const visibleNodes = dispatch(getVisibleNodes())

      fitObjectsInScreen(viewer, visibleNodes)
    }
    // If isolation is not active save the current hidden state
    if (!isIsolationActive) {
      dispatch({
        type: IS_ISOLATION_ACTIVE,
        hiddenBeforeIsolate: hidden,
        isIsolationActive: true,
        savedIsolationCamera: {
          position: viewer.camera.position.clone(),
          target: viewer.cameraManager._orbitControls.target.clone(),
          rotation: viewer.camera.rotation.clone()
        }
      })
    }
  }
}
/**
 * Restore camera to the savedIsolationCamera state
 */
export function resetIsolateCamera () {
  return (dispatch, getState) => {
    const state = getState()
    const viewer = fromThreeviewerSelectors.getViewer(state)
    const savedIsolationCamera = state.selection.savedIsolationCamera

    if (savedIsolationCamera) {
      const position = savedIsolationCamera.position
      const visibleNodes = dispatch(getVisibleNodes())
      viewer.cameraManager.moveToPoint(
        new THREE.Vector3(position.x, position.y, position.z),
        () => {
          if (savedIsolationCamera.target) {
            viewer.cameraManager.useOrbitControls(savedIsolationCamera.target)
            viewer.cameraManager.centerControlsAroundObjects(visibleNodes)
          }
        },
        { delay: 0, rotation: savedIsolationCamera.rotation }
      )
    }
  }
}

/**
 * Resets the isolation state in redux and dispatches show/hide nodes
 * Does _not_ reset the camera state.
 */
export function resetIsolate () {
  return (dispatch, getState) => {
    const state = getState()
    const hiddenBeforeIsolate = getLocalState(state).hiddenBeforeIsolate
    const topNodes = state.tree.nodes
    const flatNodes = fromTreeSelectors.getFlatNodes(getState())

    const allUuids = []
    for (const key of Object.keys(topNodes)) {
      allUuids.push(key, ...(flatNodes[key]?.children || []))
    }

    dispatch(show(allUuids))
    dispatch(hide(hiddenBeforeIsolate))
    dispatch({
      type: IS_ISOLATION_ACTIVE,
      hiddenBeforeIsolate: undefined,
      isIsolationActive: false,
      savedIsolationCamera: undefined
    })
  }
}

/**
 * Resets all state concurring isolate including redux, show/hide nodes and camera.
 */
export function leaveIsolate () {
  return (dispatch, getState) => {
    if (!getIsIsolationActive(getState())) return
    dispatch(resetIsolateCamera())
    dispatch(resetIsolate())
  }
}

/**
 * Handles hide functionality for hide/show hotkey.
 * Builds the hideUUIDS list depending on different criterias and dispatches it to handleHotKeyVisibility.
 */
export function handleHotkeyHide (uuids) {
  return (dispatch, getState) => {
    const nodeList = fromThreeviewerSelectors.getNodeList(getState())
    const isIsolationActive = getIsIsolationActive(getState())
    if (!nodeList || isIsolationActive) return

    // If no uuids are passed, use the current selection instead
    uuids = uuids || getSelection(getState())
    const hidden = getHidden(getState())

    const hideUUIDS = []
    uuids.forEach((uuid) => {
      const node = nodeList[uuid]
      const userData = node.userData
      if (hidden.includes(uuid)) return
      if (userData.modelType === 'template') return
      if (userData.modelType === 'roomset') return
      if (userData.modelSource === undefined || userData.modelSource === 'modelbank') {
        if (userData.nodeId || userData.modelId || userData.isGroup) {
          hideUUIDS.push(uuid)
        }
      } else {
        userData.modelSource === 'ugabank' && hideUUIDS.push(getParentFromUga(node))
      }
    })
    hideUUIDS.length > 0 && dispatch(handleHotkeyVisibility(hideUUIDS, 'hide'))
  }
}

/**
 * Handles show functionality for hide/show hotkey.
 * Builds the showUUIDS list depending on different criterias and dispatches it to handleHotKeyVisibility.
 */
export function handleHotkeyShow (uuids) {
  return (dispatch, getState) => {
    const nodeList = fromThreeviewerSelectors.getNodeList(getState())
    const isIsolationActive = getIsIsolationActive(getState())
    if (!nodeList || isIsolationActive) return

    // If no uuids are passed, use the current selection and hidden nodes to determine which nodes to show
    if (!uuids) {
      const hidden = getHidden(getState())
      const selection = getSelection(getState())
      uuids = selection.length ? selection : hidden
    }

    const showUUIDS = []
    uuids.forEach((uuid) => {
      const node = nodeList[uuid]
      if (!node._parentsVisible) return
      if (node.userData.nodeId || node.userData.modelId || node.userData.isGroup) {
        showUUIDS.push(uuid)
      }
    })

    showUUIDS.length > 0 && dispatch(handleHotkeyVisibility(showUUIDS, 'show'))
  }
}

/**
 * Checks for root models UUID on uga bank model.
 */
function getParentFromUga (obj) {
  if (obj.userData.modelSource === 'ugabank' && obj.userData.isModelRoot) {
    return obj.uuid
  }
  if (
    obj.parent.userData.modelSource === 'ugabank' &&
    obj.parent.userData.isModelRoot
  ) {
    return obj.parent.uuid
  }
  return getParentFromUga(obj.parent)
}

export function handleHotkeyVisibility (uuids, action) {
  return (dispatch, getState) => {
    const state = getState()
    const { hidden } = getLocalState(state)
    const { transformGizmo, scene, picker } = fromThreeviewerSelectors.getViewer(state)

    let command

    const onAfterHide = () => {
      const { selection } = getLocalState(getState())

      if (selection.length > 0) {
        transformGizmo.reattachObjects()
      }
    }

    const onAfterShow = () => {
      const { selection } = getLocalState(getState())

      if (selection.length > 0) {
        transformGizmo.reattachObjects()
      } else {
        transformGizmo.detach()
      }
    }

    if (hidden.indexOf(uuids) < 0 && action === 'hide') {
      const deselectedNodes = uuids.map((data) => {
        const node = scene.find(data)
        return node
      })
      picker.deselectMeshes(deselectedNodes)
      command = commands.createHideCommand({
        uuids: uuids,
        hide (uuids) {
          dispatch(hide(uuids))
        },
        show (uuids) {
          dispatch(show(uuids))
        },
        afterUndo: onAfterHide,
        afterExecute: onAfterHide
      })
    } else if (action === 'show') {
      command = commands.createShowCommand({
        uuids: uuids,
        hide (uuids) {
          dispatch(hide(uuids))
        },
        show (uuids) {
          dispatch(show(uuids))
        },
        afterUndo: onAfterShow,
        afterExecute: onAfterShow
      })
    }

    command.execute()
    dispatch(addCommandToUndoHistory(command))
  }
}

// visibility
export function toggleVisibility (uuid) {
  return (dispatch, getState) => {
    const state = getState()
    const { hidden } = getLocalState(state)
    const { transformGizmo, scene, picker } = fromThreeviewerSelectors.getViewer(state)

    let command

    const onAfterHide = () => {
      const { selection } = getLocalState(getState())

      if (selection.length > 0) {
        transformGizmo.reattachObjects()
      }
    }

    const onAfterShow = () => {
      const { selection } = getLocalState(getState())

      if (selection.length > 0) {
        transformGizmo.reattachObjects()
      } else {
        transformGizmo.detach()
      }
    }

    if (hidden.indexOf(uuid) < 0) {
      const node = scene.find(uuid)
      picker.deselectMeshes([node])
      command = commands.createHideCommand({
        uuids: [uuid],
        hide (uuids) {
          dispatch(hide(uuids))
        },
        show (uuids) {
          dispatch(show(uuids))
        },
        afterUndo: onAfterHide,
        afterExecute: onAfterHide
      })
    } else {
      command = commands.createShowCommand({
        uuids: [uuid],
        hide (uuids) {
          dispatch(hide(uuids))
        },
        show (uuids) {
          dispatch(show(uuids))
        },
        afterUndo: onAfterShow,
        afterExecute: onAfterShow
      })
    }

    command.execute()
    dispatch(addCommandToUndoHistory(command))
  }
}

export function show (uuids) {
  return (dispatch, getState) => {
    const state = getState()
    const { transformGizmo } = fromThreeviewerSelectors.getViewer(state)
    const nodeList = fromThreeviewerSelectors.getNodeList(getState())

    const allUuidsToShow = uuids.concat(findChildUUIDs(nodeList, uuids))
    showNodes(nodeList, allUuidsToShow)

    // If holesnapping is active we also need to update visibility of hole-markers
    if (transformGizmo.control.holeSnappingEnabled) {
      transformGizmo.holeSnapHelper.setHoleMarkersVisibility(allUuidsToShow, true)
    }

    dispatch({
      type: SHOW,
      uuids: allUuidsToShow
    })
  }
}

export function hide (uuids) {
  return (dispatch, getState) => {
    const state = getState()
    const { triplanarTool, transformGizmo } = fromThreeviewerSelectors.getViewer(state)
    const nodeList = fromThreeviewerSelectors.getNodeList(state)

    const allHiddenUuids = uuids.concat(findChildUUIDs(nodeList, uuids))

    if (
      allHiddenUuids.includes(triplanarTool.source && triplanarTool.source.uuid)
    ) {
      triplanarTool.disable()
    }

    hideNodes(nodeList, allHiddenUuids)

    // If holesnapping is active we also need to update visibility of hole-markers
    if (transformGizmo.control.holeSnappingEnabled) {
      transformGizmo.holeSnapHelper.setHoleMarkersVisibility(allHiddenUuids, false)
    }

    dispatch({
      type: HIDE,
      uuids
    })
  }
}

export function applyHiddenModification (uuids = []) {
  return (dispatch, getState) => {
    const nodeList = fromThreeviewerSelectors.getNodeList(getState())

    uuids = findOwnParentUUIDs(nodeList, uuids).concat(uuids)
    hideNodes(nodeList, uuids)

    dispatch({
      type: HIDE,
      uuids
    })
  }
}

/**
 * Assembles pre-aligned models. This is useful for models that belong together and should always have
 * the same relative position and orientation. Any two models can be assembled, but the action will only
 * make sense for models that have been prepared for assembly.
 *
 * By assembling two models, they are placed in the same location and rotated to have the same orientation.
 * A parent group is also added to more easily manage the assembled models as one.
 */
export function assembleSelectedModels () {
  return (dispatch, getState) => {
    const state = getState()
    const assembleTool = fromThreeviewerSelectors.getAssembleTool(state)

    if (
      !fromVisualizerSelectors.getHasValidAssembleSelection(state) ||
      !assembleTool.assembleSelectedModels() // Actually assembles the models
    ) return false

    const viewer = fromThreeviewerSelectors.getViewer(state)
    const nodes = viewer.transformGizmo.objs

    fitObjectsInScreen(viewer, nodes)

    // Select one of the nodes parent, which should be their shared parent group
    const parent = nodes[0].parent
    parent.userData.isGroup && dispatch(select([parent]))

    dispatch(setPickerSelection())
    dispatch(fromThreeviewerUI.forceUpdateTree())

    return true
  }
}

const initialState = Immutable({
  selection: [],
  hidden: [],
  hiddenBeforeIsolate: undefined,
  isIsolationActive: false,
  savedIsolationCamera: undefined
})

export default function reducer (state = initialState, action) {
  switch (action.type) {
    case SELECT_FROM_UUIDS: {
      return state.merge({
        selection: action.uuids
      })
    }

    case DESELECT_AFTER_REMOVE: {
      return state.merge({
        selection: state.selection.filter((_uuid) => _uuid !== action.uuid)
      })
    }

    case TOGGLE_SELECTION: {
      const { selectMany, uuid } = action
      if (selectMany) {
        return state.merge({
          selection:
            state.selection.indexOf(uuid) >= 0
              ? state.selection.filter((_uuid) => _uuid !== uuid)
              : state.selection.concat(uuid)
        })
      }

      return state.merge({
        selection: state.selection.indexOf(uuid) >= 0 ? [] : [uuid]
      })
    }

    case HIDE:
      return state.merge({
        hidden: state.hidden.concat(action.uuids)
      })

    case IS_ISOLATION_ACTIVE: {
      return state.merge({
        hiddenBeforeIsolate: action.hiddenBeforeIsolate,
        isIsolationActive: action.isIsolationActive,
        savedIsolationCamera: action.savedIsolationCamera
          ? {
            position: action.savedIsolationCamera.position,
            target: action.savedIsolationCamera.target,
            rotation: action.savedIsolationCamera.rotation
          }
          : undefined
      })
    }

    case SHOW:
      return state.merge({
        hidden: _difference(state.hidden, action.uuids)
      })

    case SELECT: {
      const { appendSelection, uuids } = action
      if (appendSelection) {
        return state.merge({
          selection: _uniq(state.selection.concat(uuids))
        })
      }
      return state.merge({
        selection: _uniq(uuids)
      })
    }
    case CLEAR_SELECTION:
      return state.merge({
        selection: []
      })

    case CLEAR:
      return initialState

    default:
      return state
  }
}
