
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'

import * as fromCombinations from '../../../../stores/ducks/combinations'

import VersionList from './version-list'
import Image from '../../../common/image'
import Button from '../../../common/button'

import { DEADLINE_STATUS } from '../../../../constants'

import autoBind from 'react-autobind'
import Pending from '../../../common/pending'

class PreviewImagePackage extends React.Component {
  constructor (props) {
    super(props)
    autoBind(this)
  }

  handleMakeDefaultVersion () {
    const combinationId = this.props.combinationId
    this.props.setImagePackageFinalVersion(combinationId)
  }

  render () {
    const {
      images,
      versions,
      isRender,
      id,
      isDefault,
      combinationTitle,
      onSelectedVersion,
      renderStatus,
      renderPercentage,
      deadlineStatus,
      onUpKeyEvent,
      onDownKeyEvent
    } = this.props

    return ([
      <div
        key='image-container'
        className='bg-black-40 relative height-100 paper-2'
        style={{
          gridArea: 'image'
        }}
      >
        {isRender
          ? <Image
            src={images.large}
            style={{
              maxHeight: 'calc(100vh - 196px)',
              objectFit: 'scale-down',
              objectPosition: 'center'
            }}
            className='height-100 userselect-none'
          />
          : <div
            className={'mt4 mx-auto'}
            style={{ width: '300px', height: '300px' }}
          >
            <Pending
              deadlineStatus={deadlineStatus}
              renderPercentage={renderPercentage}
              renderStatus={renderStatus}
              disableButtons
            />
          </div>
        }
        <p className='c-gray center f7 c-white bold m1'>{combinationTitle}</p>
        <div className='absolute bottom-0 right-0 m2'>
          <Button
            btnType='secondary'
            className='mb2'
            disabled={(versions && (versions.length === 1 || isDefault)) || deadlineStatus === DEADLINE_STATUS.FAILED}
            style={{
              height: 50,
              width: 134
            }}
            onClick={this.handleMakeDefaultVersion}
          >
            Select as final
          </Button>
          <Button
            btnType='primary'
            style={{
              height: 50,
              width: 134
            }}
            onClick={this.props.onVisualize}
          >
            + New version
          </Button>
        </div>
      </div>,
      <VersionList
        key='version-list'
        versions={versions}
        onSelectedVersion={onSelectedVersion}
        activeId={id}
        onUpKeyEvent={onUpKeyEvent}
        onDownKeyEvent={onDownKeyEvent}
      />
    ])
  }
}

PreviewImagePackage.propTypes = {
  images: PropTypes.object,
  id: PropTypes.string,
  size: PropTypes.object,
  author: PropTypes.object,
  resolution: PropTypes.string,
  quality: PropTypes.string,
  renderedAt: PropTypes.string,
  tab: PropTypes.string,
  onUpKeyEvent: PropTypes.func,
  onDownKeyEvent: PropTypes.func
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  setImagePackageFinalVersion: fromCombinations.setImagePackageFinalVersion
}, dispatch)

export default connect(
  () => ({}),
  mapDispatchToProps
)(PreviewImagePackage)
