import React from 'react'
import DragDrop from '../common/drag-drop'

interface Props {
  supportedFormats?: string[],
  multiple?: boolean
  children: ({ isDragging }: { isDragging: boolean }) => React.ReactElement
  onUpload: (files: File[]) => void
}

export default function UploadFiles (props: Props) {
  const inputRef = React.useRef<HTMLInputElement | null>(null)
  const [isDragging, setIsDragging] = React.useState(false)
  const accept = !props.supportedFormats ? 'file' : props.supportedFormats.join(',')

  function resetInput () {
    if (inputRef.current) inputRef.current.value = ''
  }

  function handleInputChange (event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.files) handleUpload(event.target.files)
    resetInput()
  }

  function handleUpload (files: FileList) {
    const validFiles = Object.values(files).filter(file => checkIfValid(file, props.supportedFormats || null))
    props.onUpload(validFiles)
  }

  return (
    <DragDrop
      className='bg-white'
      onDragOver={() => setIsDragging(true)}
      onDragLeave={() => setIsDragging(false)}
      onDrop={handleUpload}
    >
      <label>
        <input
          data-testid='upload-file'
          ref={inputRef}
          className='hidden'
          accept={accept}
          onChange={(event) => handleInputChange(event)}
          type='file'
          multiple={props.multiple}
        />
        {props.children({ isDragging })}
      </label>
    </DragDrop>
  )
}

function checkIfValid (file: File, supportedFormats: string[] | null) {
  if (file.size < 1) return false
  if (!supportedFormats) return true

  supportedFormats = supportedFormats
    .map(x => x.toLowerCase())
  const parts = file.name.split('.')

  const fileEndingSupported = supportedFormats
    .includes('.' + parts[parts.length - 1].toLowerCase())

  const typeSupported = supportedFormats
    .filter(x => {
      return x.includes('*')
        ? file.type.split('/')[0] === x.split('/')[0]
        : file.type === x
    }).length > 0

  return fileEndingSupported || typeSupported
}
