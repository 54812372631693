import _startCase from 'lodash/startCase'
import React from 'react'
import { FaPencilAlt as IconPencil } from 'react-icons/fa'
import type { Folder } from '../../stores/ducks/folders/Folder'
import IconCross from '../common/icons/icon-cross'

type Props = React.HTMLAttributes<HTMLDivElement> & {
  lockedFolder: any
  currentTab: string
  currentTabIcon: any
  currentFolder: Folder | null
  onEditFolder: () => void
  onCloseFolder: () => void
}

const TabHeader = ({
  lockedFolder,
  currentTab,
  currentTabIcon,
  currentFolder,
  onEditFolder,
  onCloseFolder,
  style
}: Props) => {
  const editableFolder = currentFolder && !lockedFolder

  return (
    <div
      style={{
        ...style,
        backgroundColor: currentFolder ? `hsl(${currentFolder.color.hsl})` : ''
      }}
      className='p2 transition-bg-color relative flex items-center justify-between truncate'
    >
      <div className='pl1 flex'>
        {currentTabIcon}
        <h1 className='my0 ml2 mr0 f4'>{_startCase(currentTab)}</h1>
      </div>
      <div className={`truncate relative ${editableFolder && 'mr3'}`} data-testid='edit-folder-title'>
        <h1
          className={`m0 f3 truncate px3 ${editableFolder && 'pointer'}`}
          onClick={editableFolder ? onEditFolder : () => {}}
        >
          {(currentFolder && currentFolder.title)}
          {editableFolder && (
            <div
              className='absolute bottom-0 right-0'>
              <IconPencil size={18} style={{ verticalAlign: 'initial' }}/>
            </div>
          )}
        </h1>
      </div>

      {currentFolder && (
        <div className='absolute top-0 right-0 p2' data-testid='edit-folder-close'>
          <IconCross
            size={16}
            className='pointer'
            onClick={onCloseFolder}
          />
        </div>
      )}
    </div>
  )
}

export default TabHeader
